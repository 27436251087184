import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownSubLevelPloButton from 'views/Components/DropDownSubLevelPloButton';
import ErrorModal from "views/Components/ErrorModal";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { deleteSubLevelPLos, fetchSubLevelPLos, getId } from '../../../../reduxToolkit/features/SubLevelPlo/SubLevelPloSlice';

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const SubLevelPloExcerpt = ({ subLevelPlo }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()

  const sendId = (id) => {
    dispatch(getId(id))
  }

  const deleteUniv = (id) => {
    dispatch(deleteSubLevelPLos(id))
  }

  return (
    <GridContainer
      md={4}
      xs={12} sm={12}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <GridItem key={subLevelPlo.id}>
        <Card product className={classes.cardHover} >
          <CardBody style={{ width: 375, height: 200 }}>
            <GridContainer >
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={10}>
                    <h4
                      className={classes.cardProductTitle}
                      style={{ textAlign: "start", color: "blue" }}
                    >
                      <a style={{ color: "black" }}>
                        {t('Sub Level')} : {subLevelPlo.level}
                      </a>
                      <br></br><br></br>
                      <a style={{ color: "black" }}>
                        {t('Code')} : {subLevelPlo.code_level}
                      </a>
                      <br></br><br></br>
                      <a style={{ background: "green", padding: '5px', borderRadius: '30px' }}>
                        <small style={{ color: '#fff' }}>{subLevelPlo?.levelPlo_details?.level}</small>
                      </a>
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <DropDownSubLevelPloButton id={subLevelPlo.id} />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem >
    </GridContainer >
  )
}

export default function SubLevelPlosPage() {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const subLevelPlosStatus = useSelector(state => state.subLevelPlo.subLevelPLosStatus)

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchAllsubLevelPlos()
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllsubLevelPlos = async () => {
    try {
      const resultAction = await dispatch(fetchSubLevelPLos()).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const subLevelPlos = useSelector(state => state.subLevelPlo.subLevelPLos)
  const error = useSelector(state => state.subLevelPlo.error)
  let content

  if (subLevelPlosStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (subLevelPlosStatus === 'succeeded') {
    content = subLevelPlos && subLevelPlos.map(subLevelPlo => (
      <SubLevelPloExcerpt key={subLevelPlo.id} subLevelPlo={subLevelPlo} />
    ))
  } else if (subLevelPlosStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item >
              {t('Configurations')}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                <span > {t('Program Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program/SubLevelPlos/`}>
                <span > {t('PLO Sub Levels')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/configuration/program/SubLevelPlos/create" >
        <Button color="info">
          {t('  Add')}
        </Button>
      </Link>
      <GridContainer>
        {
          content
        }
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
