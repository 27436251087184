import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import EditAssessmentMethodInstructor from "./EditAssessmentMethodInstuctor";
import { unwrapResult } from "@reduxjs/toolkit";
import ClearIcon from '@material-ui/icons/Clear';
import { fetchAssessmentById } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import {
    deleteAttachmentAssessmentEvidence,
  } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice.js'
import { deleteCourseInstructor } from "reduxToolkit/features/CourseInstructor/CourseInstructorSlice";
import { fetchCourseInstructorById } from "reduxToolkit/features/CourseInstructor/CourseInstructorSlice";
const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
));

export default function DropdownEditCourseInstructors(props) {
    const { t } = useTranslation();
    const buttonClasses = useButtonStyles();
    const [open, setOpen] = React.useState(false);
    const [openSection, setOpenSection] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenDeleteModal = () => {
        setOpen(true);
    };
    const handleCloseDeleteModal = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };
    const handleDelete = async (id) => {
        console.log('deleteAttachmentAssessmentEvidence', id);
        dispatch(deleteAttachmentAssessmentEvidence(id))
          .then(unwrapResult)
          
          .then(() => {
            dispatch(fetchAssessmentById(props.Assessmentid))
            openNotificationWithIcon('success', `File Deleted successfully`)
          })
      }
    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [open, setOpen] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <div>
          
               <ClearIcon
                      onClick={() => {
                        // handleOpenDeleteAttachment(attachment && attachment.id)
                        handleOpenDeleteModal();
                      }}
                      style={{ fontSize: '14px', fontWeight: 'bold' }}
                    />
           
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
            <SuperGeneralDeleteModal
                delete_Element={handleDelete}
                Element_ID={props.attachment.id}
                open={open}
                handleClose={handleCloseDeleteModal}
            />
        </div>
    );
}
