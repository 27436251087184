import {
  DeleteOutlined
} from "@ant-design/icons";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteAnnualProgramAnalysis, fetchAnnualProgramAnalysissByProgram } from "../../../../../reduxToolkit/features/annualProgramAnalysis/annualProgramAnalysisSlice";
import AddAnnualProgramAnalysisModal from "../../../../Components/AddAnnualProgramAnalysisModal";
import useResearchs from "../../../../Components/useResearchs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  primaryColor
} from "../../../../../assets/jss/material-dashboard-pro-react";
import EditAnalysisOfProgram from "../../../../Components/EditAnalysisOfProgram";


const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const headCells = [
  { id: "Strengths", label: "Strengths" },
  { id: "Areas for Improvement", label: "Areas for Improvement" },
  { id: "Priorities for Improvement", label: "Priorities for Improvement" },
  // { id: "Compensating Actions", label: "Compensating Actions" },
  { id: "delete", label: "" },
];
// const classes = useStyles();
export default function ResultAnalysisOfCourseReports(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const dispatch = useDispatch();
  let analysisType = "CourseReport"
  const [records, setRecords] = [];
  const [resultat, setResult] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useResearchs(
    records,
    headCells,
    filterFn
  );
  const [rows, setRows] = useState([]);
  const [array_analysis, setArray_analysis] = useState([]);
  let program_id =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].id;
  let obj = {
    program_id,
    analysisType
  }
  useEffect(() => {
    dispatch(fetchAnnualProgramAnalysissByProgram(obj))
    .then(unwrapResult)
      .then((analysis) => {
        setArray_analysis([...analysis]);
      });
  }, []);
  const analysis = useSelector(
    (state) => state.annualProgramAnalysis.annualProgramAnalysissByProgram
  );
const fetchAnalysis =()=>{
  dispatch(fetchAnnualProgramAnalysissByProgram(obj))
  .then(unwrapResult)
    .then((analysis) => {
      setArray_analysis([...analysis]);
    });
}
  console.log("analysis", analysis);
  useEffect(() => {
    dispatch(fetchAnnualProgramAnalysissByProgram(obj));
  }, []);
  useEffect(() => {
    dispatch(fetchAnnualProgramAnalysissByProgram(obj))
      .then(unwrapResult)
      .then((res) => {
        console.log("res", res);
        setRows([...res]);
      });
  }, []);

  // useEffect(() => {

  //   dispatch(fetchAnnualProgramAnalysissByProgram(obj))
      
  // }, [dispatch, obj]);

  const res_analysis = useSelector(
    (state) => state.annualProgramAnalysis.annualProgramAnalysissByProgram
  );
  // const DeleteFunction = (id) => {
  //   dispatch(deleteAnnualProgramAnalysis(id))
  //     .then(unwrapResult)
  //     .then(() => {
  //       dispatch(fetchAnnualProgramAnalysissByProgram(obj)).then(unwrapResult)
  //         .then((res2) => {
  //           console.log('reeeeeeeeeeeees2222', res2)
  //           setResult(res2);

  //         });
  //     });
  // };
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          {/* <AddAnnualProgramAnalysisModal
            type={analysisType}
            program_id={
              props.AnnualProgramReport &&
              props.AnnualProgramReport.programs &&
              props.AnnualProgramReport.programs[0] &&
              props.AnnualProgramReport.programs[0].id
            }
          /> */}
        </GridItem>
      </GridContainer>
      <Card style={{ marginRight: "10px" }}>
        <CardBody>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={10}>
              <p style={{ height: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>Strengths</strong> :
                <p>{array_analysis &&
                  array_analysis.map((item) => (
                    item.strength
                  ))}
                </p>
              </p>
              <p style={{ height: "150px" }}>  <strong style={{ color: primaryColor[0] }}>Priorities for improvement</strong> :
                <p>{array_analysis &&
                  array_analysis.map((item) => (
                    item.priorities_for_improvement
                  ))}</p>
              </p>
              <p style={{ height: "150px" }}>  <strong style={{ color: primaryColor[0] }}>Areas for improvement</strong> :
                <p>{array_analysis &&
                  array_analysis.map((item) => (
                    item.areas_for_improvement
                  ))}</p>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} style={{ textAlign: "end" }}>
              <EditAnalysisOfProgram program_id={
                props.AnnualProgramReport &&
                props.AnnualProgramReport.programs &&
                props.AnnualProgramReport.programs[0] &&
                props.AnnualProgramReport.programs[0].id
              } 
               fetchAnnualProgramAnalysissByProgram={fetchAnnualProgramAnalysissByProgram}
               type={analysisType} fetchAnalysis={fetchAnalysis}/>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
  
    </div>
  );
}
