import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';
const initialState = {
  CourseQualityEvaluations: [],
  CourseQualityEvaluationStatus: 'idle',
  CourseQualityEvaluationsStatus: 'idle',
  error: null,
  CourseQualityEvaluation: null,

  id: null
}


export const fetchCourseQualityEvaluations = createAsyncThunk('CourseQualityEvaluationSpec/fetchCourseQualityEvaluations',
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.get(BaseUrl + `/courseQualityEvaluationSpec/?courseSpec=${course_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      console.log(response.data, 'response.data')

      return response.data
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
    })

export const fetchCourseQualityEvaluationById = createAsyncThunk(
  'CourseQualityEvaluationSpec/fetchCourseQualityEvaluationById', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl + `/courseQualityEvaluationSpec/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);
export const addNewCourseQualityEvaluation = createAsyncThunk(
  'CourseQualityEvaluationSpec/addNewCourseQualityEvaluation',
  async (initialCourseQualityEvaluation, { rejectWithValue }) => {
    console.log('initial CourseQualityEvaluation', initialCourseQualityEvaluation)
    const token = localStorage.getItem('token')
    try {
    const response = await axios.post(BaseUrl + `/courseQualityEvaluationSpec/create/`, initialCourseQualityEvaluation, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data)
    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);
export const CourseQualityEvaluationUpdate = createAsyncThunk(
  'CourseQualityEvaluationSpec/CourseQualityEvaluationUpdate',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/courseQualityEvaluationSpec/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedCourseQualityEvaluation')
      console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseQualityEvaluation = createAsyncThunk(
  'CourseQualityEvaluationSpec/deleteCourseQualityEvaluation', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl + `/courseQualityEvaluationSpec/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  console.log(response.data, ' delete response.data')

  return response.data;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);
const CourseQualityEvaluationSlice = createSlice({
  name: 'CourseQualityEvaluationSpec',
  initialState,

  extraReducers: {
    [fetchCourseQualityEvaluations.pending]: (state, action) => {
      state.CourseQualityEvaluationsStatus = 'loading'
    },
    [fetchCourseQualityEvaluations.fulfilled]: (state, action) => {
      state.CourseQualityEvaluationsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.CourseQualityEvaluations = action.payload
    },
    [fetchCourseQualityEvaluations.rejected]: (state, action) => {
      state.CourseQualityEvaluationsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseQualityEvaluationById.pending]: (state, action) => {
      state.CourseQualityEvaluationStatus = 'loading'
    },
    [fetchCourseQualityEvaluationById.fulfilled]: (state, action) => {
      state.CourseQualityEvaluationStatus = 'succeeded'

      // Add any fetched posts to the array
      state.CourseQualityEvaluation = action.payload
    },
    [fetchCourseQualityEvaluationById.rejected]: (state, action) => {
      state.CourseQualityEvaluationStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewCourseQualityEvaluation.fulfilled]: (state, action) => {
      state.CourseQualityEvaluations.push(action.payload)
    },
    [deleteCourseQualityEvaluation.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [CourseQualityEvaluationUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [addNewCourseQualityEvaluation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseQualityEvaluation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [CourseQualityEvaluationUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

  }
})

export default CourseQualityEvaluationSlice.reducer

export const selectAllCourseContent = state => state.CourseQualityEvaluationSpec.CourseQualityEvaluations

