import React, { useEffect, useState } from 'react'

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
} from '@ant-design/icons'
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Breadcrumb,
  Form,
  Input,
  notification,
  Select,
  Space,
  InputNumber,
} from 'antd'

import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { fetchPersons } from 'reduxToolkit/features/person/PersonsSlice'
import { addStudent } from 'reduxToolkit/features/Student/StudentSlice.js'
import { fetchUserGroups } from 'reduxToolkit/features/userGroups/UserGroupSlice'
import teacher from '../../../../assets/img/teacher.png'
import ErrorModal from '../../../Components/ErrorModal'
import {
  updateStudent,
  fetchStudentById,
} from 'reduxToolkit/features/Student/StudentSlice'
const useStyles = makeStyles(styles)

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
}
const EditStudentInstructorForm = ({student}) => {
    
    const { course_id, Assessmentid, AssessmentName, group_id } = useParams()
    const groupAssessment = useSelector((state) => state.group.groupAssessment)

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)

  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  console.log(location.state)
  console.log(location?.state?.values?.id)
  const onFinish = (values) => {
    console.log(values)
    dispatch(updateStudent({ ...values, id: location?.state.values?.id }))
      .unwrap()
      .then((originalPromiseResult) => {
        //etPing(!ping)
        openNotificationWithIcon('success', `updated Successfully`)
        history.push(
          `/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/${group_id}`,
        )
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  useEffect(() => {
    dispatch(fetchStudentById(location?.state?.values?.id))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult)
        form.setFieldsValue({
          student_identification: originalPromiseResult?.student_identification,
        })
        //setPing(!ping)
        //openNotificationWithIcon('success', `Added Successfully`)
        //history.push(`/citrine/assessmentsQuestionsDetails/program/${location?.state?.ProgramId}/course/${location?.state?.course_id}/assessmentMethod/${location?.state.Assessmentid}/${location?.state?.AssessmentName}/Groups/${location?.state?.group_id}`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })

    
  }, [])
  return (
    <div>
      <GridContainer>
        <GridItem>
        <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                to={`/citrine/faculty/assessmentsQuestions_list/course/${course_id}`}
              >
                {t('Assessment Methods')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: 'orange' }}>
              <Link
                to={`/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/`}
              >
                {t('Groups')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
            <Link
                to={`/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/${group_id}`}
              >
                 {groupAssessment && groupAssessment?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: 'orange' }}>
                {t('Edit')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridItem xs={12} sm={12} md={10}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="student_identification"
                  label="Student Identification"
                  rules={[
                    {
                      required: true,
                      message: 'student_identification is required',
                    },
                  ]}
                >
                  <Input
                    autoComplete="new-password"
                    style={{ width: '130%' }}
                    size="large"
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: '20px' }}
                    color="primary"
                    onClick={() =>
                      history.push(`/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/${group_id}`)
                    }
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default EditStudentInstructorForm
