import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, notification, Table } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { DeleteCLOSpecWeight } from "../../../../reduxToolkit/features/CLO/WeightCloSpecSlice";
import {
  fetchGraduateAttributeSpecById
} from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "../../../Components/ErrorModal";
import GAIndicatorDropDownButton from "../../../Components/GAIndicatorDropDownButton";
import GAIndictorsModal from "../../../Components/GAIndictorsModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";






const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const GAIndictorExcerpt = ({ GAInd, key, disabledStatus }) => {
  //   const { t } = useTranslation();
  //   const dispatch = useDispatch();
  //   const classes = useStyles();
  //   const cardClasses = useCardStyles();
  //   let { programId } = useParams();
  //   let { GAId } = useParams();

  //   const [openErrorModal, setOpenErrorModal] = React.useState(false);
  //   const [handledError, setHandledError] = React.useState("");




  //   const [expanded, setExpanded] = React.useState(false);

  //   const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };


  //   return (
  //     <GridItem xs={12} sm={12} md={10}>
  //       <GridContainer alignItems="center">
  //         <GridItem xs={12} sm={12} md={10}>
  //           <Accordion
  //             expanded={expanded === `panel${key}`}
  //             onChange={handleChange(`panel${key}`)}
  //           >
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               // aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <GridContainer>
  //                 <GridItem>
  //                   <Link
  //                     to={`/citrine/programSpec/${programId}/GAs/${GAId}/GAIndicators`}
  //                   >
  //                     <Typography>{GAInd.name}</Typography>
  //                   </Link>
  //                 </GridItem>
  //               </GridContainer>
  //             </AccordionSummary>
  //             <AccordionDetails>
  //               <Typography>
  //                 {GAInd &&
  //                   GAInd.closSpec &&
  //                   GAInd.closSpec.map((ind) => <div> * {ind.title}</div>)}
  //               </Typography>
  //             </AccordionDetails>
  //           </Accordion>
  //         </GridItem>
  //         <GridItem xs={12} sm={12} md={2}>
  //           <GAIndicatorDropDownButton
  //             disabledStatus={disabledStatus}
  //             GAInd={GAInd}
  //             GAId={GAId}
  //             programId={programId}
  //           />
  //         </GridItem>

  //       </GridContainer>
  //     </GridItem>
  //   );
  // };
  const { t } = useTranslation();
  const dispatch = useDispatch()

  let { programId } = useParams();
  let { GAId } = useParams();
  const cardClasses = useCardStyles();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Level',
      dataIndex: 'code_level',
      key: 'code_level',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record) => <Button onClick={() => { handleClickOpen(record.id) }} color="primary" simple>Delete</Button>,
    },

  ];

  const data = GAInd.closSpec



  const [toDeleteCLOId, setToDeleteCLOId] = React.useState(null);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setToDeleteCLOId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ids = {
    "cloSpec_id": toDeleteCLOId,
    "GAISpec_id": GAInd.id,
  }

  const handleDeleteCLO = async (ids) => {
    dispatch(DeleteCLOSpecWeight(ids))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log('originalPromiseResult', originalPromiseResult)
        dispatch(fetchGraduateAttributeSpecById(GAId))
        openNotificationWithIcon('success', `Deleted`)

      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2
    });
  };

  return (
    <GridItem
      xs={12}
      sm={12}
      md={11}
    // key={program.id}
    >
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <h4 className={cardClasses.cardProductTitle}>
                <div> {GAInd.name} </div>
              </h4>
              <br />
              <div>
                {/* {props.ploInd.closSpec &&
                props.ploInd.closSpec.map((clo) => <h4><small>* {clo.title}</small></h4>)} */}
                <Table columns={columns} dataSource={data} />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              {!disabledStatus && (
                <GAIndicatorDropDownButton
                  disabledStatus={disabledStatus}
                  GAInd={GAInd}
                  GAId={GAId}
                  programId={programId}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteCLO}
        Element_ID={ids}
        open={open}
        handleClose={handleClose}

      />

    </GridItem>
  );
};

export default function GraduateAttributesDetails() {
  const { t } = useTranslation();

  console.log("programId", programId);
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const dispatch = useDispatch();

  let { programId } = useParams();
  let { GAId } = useParams();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const [statusProgram, setStatusProgram] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchGraduateAttributeIndicatorsSpec = async () => {
    try {
      const resultAction = await dispatch(
        fetchGraduateAttributeSpecById(GAId)
      ).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchGraduateAttributeIndicatorsSpec();
    dispatch(fetchProgramById(programId))
    setStatusProgram(localStorage.getItem('state'))
    if (localStorage.getItem('state') != "Draft") {
      setDisabledStatus(true)
    }
  }, [dispatch]);

  const GraduateAttribute = useSelector(
    (state) => state.GraduateAttributeSpec.GraduateAttribute
  );
  const GraduateAttributeStatus = useSelector(
    (state) => state.GraduateAttributeSpec.GraduateAttributeStatus
  );
  const program = useSelector((state) => state.programSpec.program);


  let content;

  if (GraduateAttributeStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (GraduateAttributeStatus === "succeeded") {
    if (
      GraduateAttribute && GraduateAttribute && GraduateAttribute.gaIndicatorsSpec && GraduateAttribute.gaIndicatorsSpec.length
    ) {
      content =
        GraduateAttribute && GraduateAttribute && GraduateAttribute.gaIndicatorsSpec && GraduateAttribute.gaIndicatorsSpec.map((GAInd) => (
          <GAIndictorExcerpt
            key={GAInd.id}
            GAInd={GAInd}
            disabledStatus={disabledStatus}
          />
        ));
    } else {
      content = <h5>{t("This Program has no GAs Indictors to display!")}</h5>;
    }
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/programSpec`}>{t("Programs")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/programSpec/${programId}`}
            >
              <span>
                {`${program &&
                  program.program_degree_name &&
                  program.program_degree_name
                  }`}
              </span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/programSpec/${programId}/GraduateAttributes`}>
              {t("Graduate Attributes")}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/programSpec/${programId}/GAs/${GAId}/GAIndicators`}
              style={{ color: "orange" }}
            >
              {t("Graduate Attributes Indicators")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <GridContainer>
        <GridItem>
          <GAIndictorsModal
            GraduateAttribute={GraduateAttribute}
            programId={programId}
            GAId={GAId}
            disabledStatus={disabledStatus}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
