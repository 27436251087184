import Slide from "@material-ui/core/Slide";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { Breadcrumb } from "antd";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, {forwardRef, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  deleteMenu, fetchMenus
} from "reduxToolkit/features/menu/MenuSlice";
import useTable from "../../Components/useTable";

const useTitleStyles = makeStyles(titleStyles);
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MenuList() {

  const { t } = useTranslation();
  const menusStatus = useSelector((state) => state.menu.menusStatus);
  const dispatch = useDispatch();

  const menus = useSelector((state) => state.menu.menus);
  const error = useSelector((state) => state.menu.error);

  const history = useHistory();
  const [checked, setChecked] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState("");

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    if (menusStatus === "idle") {
      dispatch(fetchMenus());
    }
  }, []);

  const headCells = [
    { id: "name", label: "Name" },
    { id: "group_ids", label: "Groups" },
  ];

  const [records, setRecords] = [];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  const titleClasses = useTitleStyles();

  const columns = [
    {
      field: "name",
      headerName: "Menu Name",
      width: 350,
    },
    {
      field: "groups",
      headerName: "Groups",
      width: 780,
      renderCell: (cellValues) => {
        const {groups} = cellValues.row;
        return groups.map((g, key) => {
          return <h5 key={key}>{g.name} {key < groups.length - 1 ? ',' : ''}</h5>;
        });
      },
    },
  ];

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setSelectedItemId(newChecked[newChecked.length - 1].toString());
  };

  return (
    <GridContainer>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/configurations`}>{t("Configurations")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("Access Control")}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/menuAccessControl/`}>{t("Menus List")}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader
            color="rose"
            icon
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <GridItem xs={12} sm={12} md={6}>
              <h4>{t("Menus List")}</h4>
            </GridItem>
          </CardHeader>
          <CardBody>
            <GridContainer
              style={{
                height: 590,
                width: '100%',
              }}
            >
              <DataGrid rows={menus && menus} columns={columns} />
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
