import React, { useState } from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { Link, useParams } from 'react-router-dom'

import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import { unwrapResult } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Button from 'components/CustomButtons/Button'
import SuperGeneralDeleteModal from '../../../../Components/SuperGeneralDeleteModal'
import {
  deleteKPIConfig,
  fetchKPIs,
} from '../../../../../reduxToolkit/features/KPI/KPIConfigSlice'
import ErrorModal from 'views/Components/ErrorModal'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))
const DropDownKPIs = ({ id }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleDeleteItem = async (id) => {
    console.log('id : ', id)
    dispatch(deleteKPIConfig(id))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchKPIs())
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={`/citrine/configuration/KPI/${id}`}>Edit </Link>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />{' '}
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}

export default DropDownKPIs
