import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
  Summary,
  TotalItem,
  GroupItem,
} from "devextreme-react/data-grid";
import CheckBox from "devextreme-react/check-box";
import { analysis, details, allAreasAnalysis, chart } from "./data.js";
import {
  grayColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import { Tabs, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Export,
  Tooltip,
  Title,
} from "devextreme-react/chart";
import {
  fetchCampaignAnalysis,
  fetchCampaignChart,
  fetchCampaignSummary,
} from "../../../../reduxToolkit/features/Survey/SurveySlice";
import ErrorModal from "../../../Components/ErrorModal";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import Pivot from "./Pivot";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const { TabPane } = Tabs;

function GroupedAnalysis(props) {
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  )

  const dispatch = useDispatch();

  const fetchAnalysis = async () => {
    try {
      const resultAction = await dispatch(
        fetchCampaignAnalysis(props.campaignId)
      ).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchChart = async () => {
    try {
      const resultAction = await dispatch(
        fetchCampaignChart(props.campaignId)
      ).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchSummary = async () => {
    try {
      const resultAction = await dispatch(
        fetchCampaignSummary(props.campaignId)
      ).unwrap();

    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchAnalysis();
    fetchChart();
    fetchSummary();
  }, []);

  const campaignAnalysis = useSelector(
    (state) => state.survey.campaignAnalysis
  );

  const campaignChart = useSelector((state) => state.survey.campaignChart);
  console.log("campaignChart", campaignChart && campaignChart)

  const campaignSummary = useSelector((state) => state.survey.campaignSummary);
  console.log("campaignSummary", campaignSummary && campaignSummary);

  const TPN = campaignSummary
    ? `Total participants number= ${campaignSummary[0].totalParticipantsNumber}`
    : "Total participants number= -";
  const TSR = campaignSummary
    ? `Total satisfaction rate= ${campaignSummary[0].totalSatisfactionRate}`
    : "Total satisfaction rate= -";

  // const TPN = details
  //   ? `Total participants number= ${details[0].totalParticipantsNumber}`
  //   : "Total participants number= -";
  // const TSR = details
  //   ? `Total satisfaction rate= ${details[0].totalSatisfactionRate}`
  //   : "Total satisfaction rate= -";

  const renderColumnHeader = (data) => {
    return (
      <p style={{ fontWeight: "bold", color: primaryColor[0] }}>
        {data.column.caption}
      </p>
    );
  };
  // const onExporting = (e, area) => {
  //   const workbook = new Workbook();
  //   const worksheet = workbook.addWorksheet("Main sheet");

  //   exportDataGrid({
  //     component: e.component,
  //     worksheet,
  //     autoFilterEnabled: true,
  //   }).then(() => {
  //     workbook.xlsx.writeBuffer().then((buffer) => {
  //       saveAs(
  //         new Blob([buffer], { type: "application/octet-stream" }),
  //         `${area}.xlsx`
  //       );
  //     });
  //   });
  //   e.cancel = true;
  // };

  // const customizeAverage=(data)=> {
  //   return`Total Average : ${data.value.toFixed(2)}`;
  // }

  // const customizeAreaAverage=(data)=> {
  //   return` Area Average : ${data.value.toFixed(2)}`;
  // }
  // const content =
  // campaignAnalysis &&
  // campaignAnalysis.map((areaAnalysis) => {
  //     return (
  //       <div>
  //         <h3
  //           style={{
  //             fontWeight: "bold",
  //             color: primaryColor[0],
  //             textAlign: "center",
  //           }}
  //         >
  //           {areaAnalysis.area}
  //         </h3>
  //         <DataGrid
  //           dataSource={areaAnalysis.analysis}
  //           keyExpr="ID"
  //           showBorders={true}
  //           allowColumnResizing={true}
  //           columnResizingMode={"widget"}
  //           columnMinWidth={100}
  //           columnAutoWidth={true}
  //           onExporting={(e) => {
  //             onExporting(e, areaAnalysis.area);
  //           }}
  //         >
  //           <Column
  //             dataField="question"
  //             caption="Question"
  //             dataType="string"
  //             width={300}
  //             headerCellRender={renderColumnHeader}
  //           />
  //           <Column
  //             dataField="stronglyAgree"
  //             caption="Strongly agree"
  //             dataType="string"
  //             headerCellRender={renderColumnHeader}
  //           />
  //           <Column
  //             dataField="agree"
  //             caption="Agree"
  //             dataType="string"
  //             headerCellRender={renderColumnHeader}
  //           />
  //           <Column
  //             dataField="disagree"
  //             caption="Disagree"
  //             dataType="string"
  //             headerCellRender={renderColumnHeader}
  //           />
  //           <Column
  //             dataField="stronglyDisagree"
  //             caption="Strongly disagree"
  //             dataType="string"
  //             headerCellRender={renderColumnHeader}
  //           />
  //           <Column
  //             dataField="notApplicable"
  //             caption="Not applicable"
  //             dataType="string"
  //             headerCellRender={renderColumnHeader}
  //           />
  //           <Column
  //             dataField="answered"
  //             caption="Answered"
  //             dataType="number"
  //             alignment="left"
  //             headerCellRender={renderColumnHeader}
  //           />
  //           <Column
  //             dataField="average"
  //             caption="Average"
  //             dataType="number"
  //             alignment="left"
  //             headerCellRender={renderColumnHeader}
  //           />

  //           <Column
  //             dataField="subarea"
  //             caption=""
  //             dataType="string"
  //             groupIndex={0}
  //             headerCellRender={renderColumnHeader}
  //           />

  //           <Summary>
  //             {/* <TotalItem column="average" summaryType="avg" /> */}
  //             <TotalItem column="average" summaryType="avg"   customizeText={customizeAverage} />
  //             <GroupItem
  //               column="average"
  //               summaryType="avg"
  //               showInGroupFooter={false}
  //               alignByColumn={true}
  //               customizeText={customizeAreaAverage}
  //             />
  //           </Summary>

  //           <Export enabled={true} />
  //         </DataGrid>
  //       </div>
  //     );
  //   });

  const customizeTooltip = (arg) => {
    return {
      text: `${arg.percentText} responses: ${arg.valueText}`,
    };
  };

  return (
    <div>
      {campaignSummary ?
        <Tabs type="card">
          <TabPane tab="Summary" key="1">
            <DataGrid
              dataSource={campaignSummary || []}
              keyExpr="ID"
              showBorders={true}
              allowColumnResizing={true}
              columnResizingMode={"widget"}
              columnMinWidth={50}
              columnAutoWidth={true}
            >
              <Column
                dataField="studentsNumber"
                caption="Students number"
                alignment="left"
                headerCellRender={renderColumnHeader}
              />
              <Column
                dataField="totalParticipantsNumber"
                caption={TPN}
                alignment="left"
                headerCellRender={renderColumnHeader}
              >
                <Column
                  dataField="localParticipantsNumber"
                  caption="Local"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
                <Column
                  dataField="foreignParticipantsNumber"
                  caption="Foreign"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
                <Column
                  dataField="maleParticipantsNumber"
                  caption="Male"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
                <Column
                  dataField="femaleParticipantsNumber"
                  caption="Female"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
              </Column>
              <Column
                dataField="responseRate"
                caption="Response rate"
                alignment="left"
                headerCellRender={renderColumnHeader}
              />

              <Column
                dataField="totalSatisfactionRate"
                caption={TSR}
                alignment="left"
                headerCellRender={renderColumnHeader}
              >
                <Column
                  dataField="localSatisfactionRate"
                  caption="Local"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
                <Column
                  dataField="foreignSatisfactionRate"
                  caption="Foreign"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
                <Column
                  dataField="maleSatisfactionRate"
                  caption="Male"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
                <Column
                  dataField="femaleSatisfactionRate"
                  caption="Female"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
              </Column>

              <Column
                dataField="average"
                caption="Average"
                alignment="left"
                headerCellRender={renderColumnHeader}
              />
              <Column
                dataField="weightedAverage"
                caption="Weighted average"
                alignment="left"
                headerCellRender={renderColumnHeader}
              >
                <Column
                  dataField="weightedAverageByNationality"
                  caption="Nationality"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
                <Column
                  dataField="weightedAverageByGender"
                  caption="Gender"
                  alignment="left"
                  headerCellRender={renderColumnHeader}
                />
              </Column>
            </DataGrid>
            <br />
            <br />

            {props.campaign.resource === "course" ?
              <Chart id="chart" palette="Soft" dataSource={campaignChart || []}>
                <Title text="Responses percentages" subtitle="(per question)" />
                <CommonSeriesSettings
                  argumentField="questionCode"
                  type="fullstackedbar"
                />
                <Series valueField="stronglyAgree" name="Strongly agree" />
                <Series valueField="agree" name="Agree" />
                <Series valueField="disagree" name="Disagree" />
                <Series valueField="stronglyDisagree" name="Strongly disagree" />
                <Series valueField="notApplicable" name="Not applicable" />
                <Legend
                  verticalAlignment="top"
                  horizontalAlignment="center"
                  itemTextPosition="right"
                />
                <Export enabled={true} />
                <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
              </Chart>
              :
              <Chart id="chart" palette="Soft" dataSource={campaignChart || []}>
                <Title text="Responses percentages" subtitle="(per question)" />
                <CommonSeriesSettings
                  argumentField="questionCode"
                  type="fullstackedbar"
                />
                <Series valueField="stronglyAgree" name="Strongly agree" />
                <Series valueField="agree" name="Agree" />
                <Series valueField="disagree" name="Disagree" />
                <Series valueField="stronglyDisagree" name="Strongly disagree" />
                <Series valueField="notApplicable" name="Not applicable" />
                <Series valueField="trueSometimes" name="True Sometimes" />
                <Legend
                  verticalAlignment="top"
                  horizontalAlignment="center"
                  itemTextPosition="right"
                />
                <Export enabled={true} />
                <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
              </Chart>}
            <br />
          </TabPane>
          {/* <TabPane tab="Details" key="2">
          {content}
        </TabPane> */}
          <TabPane tab="Details" key="2">
            <Pivot campaignId={props.campaignId} campaign={props.campaign} campaignAnalysis={campaignAnalysis && campaignAnalysis} />
          </TabPane>
        </Tabs>
        :
        <GridContainer justify="center"
          alignItems="center">
          <GridItem>
            <Spin indicator={antIcon} />
          </GridItem>
        </GridContainer>

      }

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}

export default GroupedAnalysis;
