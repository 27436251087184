import {
  CheckOutlined
} from "@ant-design/icons";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPreviousActionPlans
} from "../../reduxToolkit/features/ActionPlan/ActionPlanSlice";
import EditPreviousActionPlanRecommendationModal from "../Components/EditPreviousActionPlanRecommendationModal";
import useTable from "../Components/useTable";
import "./improvement.css";











const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const headCells = [

  { id: "PlannedActions", label: "Planned Actions" },
  { id: "ResponsibilityOfAction", label: "Responsible" },
  { id: "PlannedCompletionDate", label: "Completion Date" },
  { id: "Completed", label: "Completed" },
  { id: "NotCompleted", label: "Not Completed" },
  { id: "Reasons", label: "Reasons  (If not completed) " },
  { id: "ProposedActions", label: "Proposed Actions  (If not completed)" },
  { id: "delete", label: "" },
];

export default function PreviousActionPlan(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn);
  let obj = {
    resource_id: props.resource_id,
    resource: props.resource
  }
  console.log('propppos obj', obj)
  let program_id = 22

  useEffect(() => {
    dispatch(fetchPreviousActionPlans(obj));
  }, []);
  const [array_res, setArray_res] = useState([]);
  const PreviousActionPlans = useSelector(
    (state) => state.ActionPlan.PreviousActionsPlan
  );
  const fetchAnalysis = () => {
    dispatch(fetchPreviousActionPlans(obj))
      .then(unwrapResult)
      .then((analysis) => {
        setArray_res([...analysis]);
      });
  }
  const [pageSize, setPageSize] = React.useState(10);
  return (
    <div>


      <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
        <TblHead />
        <TableBody>
          {PreviousActionPlans &&
            PreviousActionPlans.map((item) => (
              <TableRow key={item.id}>


                <TableCell
                  style={{
                    borderLeft: "1px solid lightgray",
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      maxWidth: "200px",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item && item.previous_action}
                  </p>
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.responsible_details && item.responsible_details.username}
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.completion_date}
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.iscompleted ? (
                    <CheckOutlined style={{ color: "green" }} />
                  ) : (
                    <p></p>
                  )}
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.iscompleted ? (
                    <p></p>
                  ) : (
                    <CheckOutlined style={{ color: "red" }} />
                  )}
                </TableCell>

                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.reasons}
                </TableCell>

                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.proposed_actions}
                </TableCell>

                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  <EditPreviousActionPlanRecommendationModal id={item.id} obj={obj} fetchAnalysis={fetchAnalysis} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TblContainer>
    </div>
  );
}
