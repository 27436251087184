import { EditingState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table, TableEditRow, TableHeaderRow, TableInlineCellEditing
} from "@devexpress/dx-react-grid-material-ui";
// editable array
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ScoreIcon from "@material-ui/icons/Score";
import { unwrapResult } from "@reduxjs/toolkit";
//import Button from "components/CustomButtons/Button.js";
import { Breadcrumb, Button } from "antd";
import "antd/dist/antd.css";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import randomSeed from "../../../../assets/demo-data/random";
import {
  fetchCloById, fetchScoreCLOById, fetchTuplesNumberStudentsByRange
} from "../../../../reduxToolkit/features/CalculNotes/CalculNotesSlice";
import { fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice";










const useStyles = makeStyles(styles);

const useStylesAlert = makeStyles(stylesAlert);

const ResultCalculClosNotesFaculty = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const classesAlert = useStylesAlert();

  // const id = useSelector((state) => state.course.id)
  // const clo_id = useSelector((state) => state.calcul.cloId)

  const { clo_id } = match.params;
  const { id } = match.params;

  const course = useSelector((state) => state.course.course);
  const clo = useSelector((state) => state.calcul.clo);
  const Assessmentid = useSelector((state) => state.assessment.assessmentId);
  const CourseAssessmentMethodNotesCLO = useSelector(
    (state) => state.assessment.CourseAssessmentMethodNotesCLO
  );
  const ScoreClo = useSelector((state) => state.calcul.ScoreClo);

  const [columns, setColumns] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [notesClos, setNotesClos] = useState([]);
  const [rows, setRows] = useState([]);
  const [editable, setEditable] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const [oncommit, setOncommit] = React.useState(true);
  const [onrefresh, setOnrefresh] = React.useState(false);
  const [score, setScore] = React.useState(null);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [validationStatus, setValidationStatus] = useState({});

  useEffect(() => {
    dispatch(fetchCourseById(id));
    dispatch(fetchCloById(clo_id));
  }, [dispatch]);

  useEffect(() => {
    // dispatch(fetchCourseById(id))

    let obj_score = {
      clo_id: clo_id,
      semester_id: course && course.semester_id && course.semester_id,
      course_id: course && course.id && course.id,
    };
    dispatch(fetchScoreCLOById(obj_score));

    // console.log(ScoreClo, "scoreClo");

    // console.log(clo_id, "clo_id");
    // console.log(id, "id course");
    // console.log(course && course, " course");

    let arr = [];
    let lastArray = [];
    clo &&
      clo.courseAssessmentMethods.map((item, id) => {
        let obj = {
          name: `${item &&
            item.assessmentMethod_name &&
            item.assessmentMethod_name.map((el) => el.name)
            }`,
          title: `${item &&
            item.assessmentMethod_name &&
            item.assessmentMethod_name.map((el) => el.name)
            }`,
        };
        arr = [...arr, obj];
        lastArray = [{ name: "Range", title: "Range" }, ...arr];
        setColumns(lastArray);
      });
    // console.log(lastArray, "lastArray");
    // console.log(course, "course 101");

    // console.log(
    //   course && course.assessmentRanges && course.assessmentRanges,
    //   "course &&course.assessmentRanges"
    // );

    //  var sorted_students = course && course.assessmentRanges.sort(function(a, b) {
    //   return a.start_of_interval - b.start_of_interval;
    // });

    // console.log(sorted_students,"sorted_students");

    let sorted = [];
    course &&
      course.assessmentRanges.map((item) => {
        let newarr = [];
        sorted = [...sorted, item];
        sorted = sorted.sort(
          (a, b) => a.start_of_interval - b.start_of_interval
        );
        // console.log(sorted, "sorted");

        for (let k = 0; k < sorted.length; k++) {
          newarr.push(
            `${sorted[k].type} ${sorted[k].start_of_interval}-${sorted[k].end_of_interval}%`
          );

          newarr = Array.from(new Set(newarr));
          // console.log(newarr, "newarr");

          setRanges(newarr);
        }
      });

    let obj = {
      clo_id: clo_id,
      semester_id: course && course.semester_id,
    };

    dispatch(fetchTuplesNumberStudentsByRange(obj))
      .then(unwrapResult)
      .then((RangeNumbers) => {
        setNotesClos(RangeNumbers);
      });
    // let idClo = clo_id;
    // let promisesNotes = clo && clo.courseAssessmentMethods &&clo.courseAssessmentMethods.map((item, id) => {
    //     let obj1 = {
    //       courseAssessmentMethod_id: item.id,
    //       clo_id: idClo,
    //       semester_id: course && course.semester_id,
    //     };
    //     return dispatch(fetchTuplesNumberStudentsByRange(obj1));
    //   });
    // let newarrNote = [];
    // promisesNotes &&
    //   Promise.all(promisesNotes).then((array) => {
    //     //console.log(array, "array");
    //     array &&
    //       array.map((item) => {
    //         newarrNote = [
    //           ...newarrNote,
    //           item &&
    //             item.payload &&
    //             item.payload.totalRange &&
    //             item.payload.totalRange,
    //         ];
    //         setNotesClos(newarrNote);
    //       });
    //   });
    // console.log(newarrNote, "newarrNote");
  }, [course, clo]);

  useEffect(() => {
    // console.log(columns, " columns");
    // console.log(ranges, " ranges");
    console.log(notesClos, "notesClos");

    const RangesRwo = [...ranges];
    let rangesList = [];
    RangesRwo &&
      RangesRwo.map((item) => {
        rangesList = [...rangesList, item];
        rangesList = rangesList.sort((a, b) =>
          a.start_of_interval > b.start_of_interval
            ? 1
            : b.start_of_interval > a.start_of_interval
              ? -1
              : 0
        );
      });

    const requiredRule = {
      isValid: (value) => value?.trim().length > 0,
      errorText: "This field is required",
    };
    let objt = {};
    let array1 = [];
    columns.forEach((item, id) => {
      array1 = [...array1, item.name];
      for (var i = 0; i < array1.length; i++) {
        objt[array1[i]] = requiredRule;
      }
    });

    const validationRules = objt;
    const validate = (changed, validationStatus) =>
      Object.keys(changed).reduce((status, id) => {
        let rowStatus = validationStatus[id] || {};
        if (changed[id]) {
          rowStatus = {
            ...rowStatus,
            ...Object.keys(changed[id]).reduce((acc, field) => {
              const isValid = validationRules[field].isValid(
                changed[id][field]
              );
              return {
                ...acc,
                [field]: {
                  isValid,
                  error: !isValid && validationRules[field].errorText,
                },
              };
            }, {}),
          };
        }

        return { ...status, [id]: rowStatus };
      }, {});

    const defaultColumnValues = {};

    function generateRows({
      columnValues = defaultColumnValues,

      length,
      random = randomSeed(329972281),
    }) {
      //console.log(columnValues, 'columnValues')
      const data = [];
      const columns = Object.keys(columnValues);
      for (let i = 0; i < length; i++) {
        const record = {};
        columns.forEach((column) => {
          let values = columnValues[column];
          //  console.log(values, 'values')
          if (typeof values === "function") {
            record[column] = values({ random, index: i, record });
            // console.log('type fonction ')
            //  console.log(values({ random, index: i, record }), 'values({ random, index: i, record }); ')
            return;
          }
          while (values.length === 2 && typeof values[1] === "object") {
            //  console.log('type object1 ')
            values = values[1][record[values[0]]];
          }
          const value = values[i];
          if (typeof value === "object") {
            // console.log('type object1 ')
            record[column] = { ...value };
          } else {
            //   console.log('else ')
            record[column] = value;
            //console.log(record,'record ')
          }
        });
        data.push(record);
      }
      //console.log(data, 'data')
      return data;
    }

    // let totalarray = []

    // for (let i = 0; i < notesClos.length; i++) {
    //     let array7 = []
    //     //   console.log(notesClos[i], ' notesClos[i]')
    //     let table = clo && clo.courseAssessmentMethods.map((item) => item)
    //     // console.log(table, ' table')
    //     if (table && notesClos[i]) {
    //         for (let j = 0; j < table.length; j++) {
    //             for (let k = 0; k < notesClos[i].length; k++) {
    //                 array7[k] = parseInt((Number(notesClos[i][k].note) / Number(table[j].total_marks)) * 100)

    //             }
    //         }
    //         // console.log(array7, 'array7')
    //         totalarray = [...totalarray, array7]
    //         // console.log(totalarray, 'totalarray')
    //     }
    // }

    // for (let l = 0; l < totalarray.length; l++) {
    //     let array8 = [0, 0, 0, 0, 0]
    //     let veryBad = []
    //     //  console.log(veryBad.length, "veryBad.length out side ")
    //     let Bad = []
    //     let UnSatisfactory = []
    //     let Developing = []
    //     let Satisfactory = []
    //     if (totalarray[l]) {
    //         totalarray[l] && totalarray[l].forEach((item) => {
    //             if (0 < Number(item) && Number(item) < 20) {
    //                 veryBad = [...veryBad, Number(item)]
    //                 array8[0] = parseInt(veryBad.length)
    //                 if (veryBad.length == 0) {
    //                     array8[0] = 0
    //                 }
    //             }
    //             if (Number(item) >= 20 && Number(item) < 40) {
    //                 Bad = [...Bad, Number(item)]
    //                 array8[1] = Bad.length
    //             }
    //             if (Number(item) >= 40 && Number(item) < 60) {
    //                 UnSatisfactory = [...UnSatisfactory, Number(item)]
    //                 array8[2] = UnSatisfactory.length
    //             }
    //             if (Number(item) >= 60 && Number(item) < 80) {
    //                 Developing = [...Developing, Number(item)]
    //                 array8[3] = Developing.length
    //             }
    //             if (Number(item) >= 80 && Number(item) <= 100) {
    //                 Satisfactory = [...Satisfactory, Number(item)]
    //                 array8[4] = Satisfactory.length
    //             }
    //         })
    //     }

    //     //console.log(array8, 'array8')
    //     Fianlarray = [...Fianlarray, array8]
    //     console.log(Fianlarray, 'Fianlarray')
    // }

    //let Fianlarray = []
    const Fianlarray = [...notesClos];
    let NotesValues = {};
    let NameColumn;
    if (clo) {
      for (let i = 0; i < clo.courseAssessmentMethods.length; i++) {
        NameColumn = `${clo.courseAssessmentMethods[
          i
        ].assessmentMethod_name.map((el) => el.name)}`;
        // console.log(NameColumn, "NameColumn");
        // console.log(notesClos && notesClos[i], "notesClos[i]");
        NotesValues[NameColumn.toString()] = Fianlarray[i];
      }
    }
    // console.log(NotesValues, "NotesValues");

    // let scoreArray = []
    // for (let x = 0; x < Fianlarray.length; x++) {
    //     let array9 = []
    //     if (Fianlarray[x]) {
    //         for (let z = 0; z < Fianlarray[x].length; z++) {
    //             console.log(Fianlarray[x][0], "Fianlarray[x][z]")
    //             let el0 = Fianlarray[x][0]
    //             let el1 = Fianlarray[x][1]
    //             let el2 = Fianlarray[x][2]
    //             let el3 = Fianlarray[x][3]
    //             let el4 = Fianlarray[x][4]
    //             array9[x] = (el0 * 1 + el1 * 2 + el2 * 3 + el3 * 4 + el4 * 5) / (el0 + el1 + el2 + el3 + el4)
    //         }
    //     }
    //     console.log(array9, 'array9')
    //     scoreArray = [...scoreArray, array9[x]]
    //     console.log(scoreArray, 'scoreArray')

    // }

    // let finalScore = 0;
    // let array10 = []
    // for (let w = 0; w < scoreArray.length; w++) {
    //     let table = clo && clo.courseAssessmentMethods && clo.courseAssessmentMethods.map((item) => item)
    //     let examScore = scoreArray[w] && scoreArray[w] * (table[w] && table[w].weight && table[w].weight / 100).toFixed(2)
    //     // console.log(examScore, 'examScore')
    //     array10 = [...array10, examScore]
    //     console.log(array10, 'array10')

    // }
    // for (let v = 0; v < array10.length; v++) {
    //     finalScore = finalScore + array10[v]
    // }
    // console.log(finalScore, 'finalScore')
    // setScore(finalScore.toFixed(2))

    // console.log(rangesList, "rangesList 368");
    const Ranges = rangesList.map((el) => el);

    const RangeIdentification = {
      Range: Ranges,
    };

    const finalResult = { ...RangeIdentification, NotesValues };
    const result = Object.values(finalResult)[1];

    const result_str_json = JSON.parse(JSON.stringify(result));

    const range_Json = JSON.parse(JSON.stringify(RangeIdentification));

    var lastResult_one = Object.assign(range_Json, result_str_json);

    const lastResult = JSON.parse(JSON.stringify(lastResult_one));

    // console.log(lastResult, "lastResult");

    const tab = generateRows({
      columnValues: { ...lastResult },
      length: Ranges.length,
    });

    // console.log(tab, "tab");
    let arr2 = [];
    for (let i = 0; i < tab.length; i++) {
      const newObj = Object.fromEntries(
        Object.entries(tab[i]).map(([k, v]) => [JSON.stringify(k), Number(v)])
      );
      arr2.push(newObj);
    }
    const tab_Json = JSON.parse(JSON.stringify(tab));
    if(tab_Json==="You do not have permission")
    {setRows([]);}
    else{
    setRows(tab_Json);}
  }, [notesClos, ranges, columns]);

  console.log(rows, "rows");

  const commitChanges = async ({ changed }) => {
    // console.log(changed, "changed");
  };

  const Cell = React.useCallback(
    (props) => {
      const {
        tableRow: { rowId },
        column: { name: columnName },
      } = props;
      const columnStatus = validationStatus[rowId]?.[columnName];
      const valid = !columnStatus || columnStatus.isValid;
      const style = {
        ...(!valid ? { border: "1px solid red" } : null),
      };
      const title = valid ? "" : validationStatus[rowId][columnName].error;

      return <Table.Cell {...props} style={style} title={title} />;
    },
    [validationStatus]
  );

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
        <Breadcrumb.Item>
            <Link
              to={`/citrine/courseCoordinatorWorkspace/assessment/`}

            >
              {t("Courses List ")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/courseCoordinatorWorkspace/clos_list/course/${id}`}>
              {t("Clos List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseCoordinatorWorkspace/result_calcul_Clos/course/${id}/clo/${clo_id}`}
              style={{ color: "orange" }}
            >
              {t("Students Number By Range")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      {rows ? (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card justify="space-between">
                <CardHeader
                  color="info"
                  icon
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <GridContainer
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <GridItem xs={6}>
                      <CardIcon color="warning">
                        <ScoreIcon />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("Course Learning Outcomes Score")}
                      </h4>
                    </GridItem>
                    <GridItem style={{ padding: "10px", margin: "10px 15%" }}>
                      <Button
                        type="primary"
                        shape="round"
                        style={{
                          width: "170px",
                          height: "45px",
                          fontSize: "18px",
                          marginBottom: "10px",
                        }}
                      >
                        <strong>
                          <p>
                            {" "}
                            {t("Score Clo")} :{" "}
                            {ScoreClo &&
                              ScoreClo.score_by_clo &&
                              ScoreClo.score_by_clo.toFixed(2)}
                          </p>
                        </strong>
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>
            <Paper>
              {alert}
              <Grid rows={rows} columns={columns}>
                <EditingState
                  editingRowIds={editingRowIds}
                  onEditingRowIdsChange={setEditingRowIds}
                  rowChanges={rowChanges}
                  onRowChangesChange={setRowChanges}
                  onCommitChanges={commitChanges}
                  columnEditingEnabled={editable}
                />
                <Table cellComponent={Cell} />
                <TableHeaderRow />
                <TableEditRow />
                <TableInlineCellEditing />
              </Grid>
            </Paper>
          </GridContainer>
        </div>
      ) : (
        <p>loading ...</p>
      )}
    </div>
  );
};
export default ResultCalculClosNotesFaculty;
