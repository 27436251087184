import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  groupStates: [],
  groupStatesByReport: [],
  groupStatesStatus: 'idle',
  groupStatesByReportStatus: 'idle',
  groupStateStatus: 'idle',
  error: null,
  groupState: null,
  loading: 'idle',
  currentRequestId: undefined,
}

export const fetchGroupStatesByReport = createAsyncThunk('groupStates/fetchGroupStatesByReport', async (type, { getState, requestId, rejectWithValue }) => {
  const { currentRequestId, loading } = getState().groupState
  if (loading !== 'pending' || requestId !== currentRequestId) {
    return
  }

  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(BaseUrl + `/groupStates/by_report/?model=${type}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    console.log("response.data", response.data)
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})


export const fetchGroupStates = createAsyncThunk(
  'groupStates/fetchGroupStates',
  async (type) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/groupStates/?model=${type}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    console.log(response.data, 'response.data of groups')

    return response.data
  },
)

export const addNewGroupStates = createAsyncThunk(
  'groupStates/addNewGroupStates',
  async (bodyGroup, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + '/groupStates/create/',
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      // console.log(response.data, "response.data of groups");

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const deleteGroupState = createAsyncThunk(
  'groupStates/deleteGroupState',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(
        BaseUrl + `/groupStates/${id}/delete/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log(response)
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  },
)

export const updateGroupState = createAsyncThunk(
  'group/updateGroup',
  async (bodyGroup, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(
        BaseUrl + `/groupStates/${bodyGroup.id}/edit/`,
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      //console.log(response.data, 'response.data of groups')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

// export const getGroupsByProgram = createAsyncThunk(
//   "group/getGroupsByProgram", async (id,{ rejectWithValue }) => {
//     try {

//       const token = localStorage.getItem('token')
//       const response = await axios.get(BaseUrl +`/groupAssessment/byProgram/?program_id=${id}`,
//         {
//           headers: {
//             'Authorization': `Token ${token}`
//           }
//         }

//       )
//       console.log('response.data', response.data)
//       return response.data

//     } catch (err) {
//       let error = err // cast the error for access
//       if (!error.response) {
//         throw err
//       }
//       // We got validation errors, let's return those so we can reference in our component and set form errors
//       console.log('error.response from inside the CAT', error.response)
//       return rejectWithValue(error.response)
//     }
//   }
// );

const groupStatesSlice = createSlice({
  name: ' groupState',
  initialState,
  extraReducers: {
    [fetchGroupStatesByReport.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.groupStatesByReportStatus = 'loading'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchGroupStatesByReport.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.groupStatesByReportStatus = 'succeeded'
        state.groupStatesByReport = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchGroupStatesByReport.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.groupStatesByReportStatus = 'failed'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    [fetchGroupStates.pending]: (state, action) => {
      state.groupStatesStatus = 'loading'
    },
    [fetchGroupStates.fulfilled]: (state, action) => {
      state.groupStatesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.groupStates = action.payload
    },
    [fetchGroupStates.rejected]: (state, action) => {
      state.groupStatesStatus = 'failed'
      state.error = action.payload
    },

    //     [getGroupsByProgram.pending]: (state, action) => {
    //       state.groupsByProgramStatus = "loading";
    //     },
    //     [getGroupsByProgram.fulfilled]: (state, action) => {
    //       state.groupsByProgramStatus = "succeeded";
    //       // Add any fetched posts to the array
    //       state.groupsByProgram = action.payload;
    //     },
    //     [getGroupsByProgram.rejected]: (state, action) => {
    //       state.groupsByProgramStatus = "failed";
    //     },

    //     // [addNewStudent.fulfilled]: (state, action) => {},

    [addNewGroupStates.fulfilled]: (state, action) => {
      state.groupStatesStatus = 'succeeded'
      state.groupStates.push(action.payload)
    },

    [addNewGroupStates.loading]: (state, action) => {
      state.groupStatesStatus = 'loading'
    },
    [addNewGroupStates.rejected]: (state, action) => {
      state.groupStatesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteGroupState.pending]: (state) => {
      state.groupStateStatus = 'pending'
    },
    [deleteGroupState.fulfilled]: (state, action) => {
      state.groupStateStatus = 'fulfilled'
    },
    [deleteGroupState.rejected]: (state, action) => {
      state.groupStateStatus = 'rejected'
      state.error = action.payload ? action.payload : action.error
    },
    [updateGroupState.pending]: (state, action) => {
      state.groupStateStatus = 'pending'
    },
    [updateGroupState.fulfilled]: (state, action) => {
      state.groupStateStatus = 'fulfilled'
      console.log(action)
    },
    [updateGroupState.rejected]: (state, action) => {
      state.groupStateStatus = 'rejected'
      console.log(action)
      //state.error = action.payload ? action.payload : action.error
      state.error = action.error
    }

  },
})

export default groupStatesSlice.reducer
// export const  groups = state => state.group.groups
