import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// nodejs library to set properties for components
import { useHistory } from "react-router-dom";
import {
    deleteMajorTrack,
    fetchMajorTracks
} from "../../reduxToolkit/features/majorTracks/MajorTracksSlice";
import GeneralDeleteModal from "../Components/GeneralDeleteModal";
import HandelError from "./HandelError";


const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

export default function MajorTrackDropDownButton(props) {
    const { t } = useTranslation();
    const buttonClasses = useButtonStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const programSpec_id = props.programID;
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };
    const dispatch = useDispatch();
    const history = useHistory();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
       
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const classes = useStyles();

    const deleteItem = (id) => {
        dispatch(deleteMajorTrack(id))
            .then(unwrapResult)
            .then((response) => {
                HandelError(response.status)
            })
            .then(() => {
                // do additional work
                dispatch(fetchMajorTracks(programSpec_id));
            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                console.log(
                    "rejectedValueOrSerializedError",
                    rejectedValueOrSerializedError
                );
            });
    };
    return (
        <span>
            <Button
                color="primary"
                round
                ref={anchorRef}
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
            >
                <MoreVert />
            </Button>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">

                                    <MenuItem>

                                        <GeneralDeleteModal
                                            delete_Element={deleteItem}
                                            Element_ID={props.MajorTrack_ID}
                                        />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </span>
    );
}
