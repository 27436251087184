import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  accessControls: [],
  accessControl: null,
  accessControlStatus: 'idle',
  accessControlsStatus: 'idle',
  models: [],
  modelsStatus: 'idle',
  modelStatus: 'idle',
  error: null,
  model: null,
  id: null,
}

export const fetchModels = createAsyncThunk('model/fetchModels', async (_,{rejectWithValue}) => {
  try {
    const token = localStorage.getItem('token')
  const {data} = await axios.get(BaseUrl + '/models/', {
    headers: {
      Authorization: `Token ${token}`,
    },
  })

  return data
  } catch (err) {
    let error = err 
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
  
})

export const fetchAccessControlModels = createAsyncThunk(
  'model/fetchAccessControlModels',
  async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/models/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    //console.log(response.data, 'response.data')

    return response.data
  },
)

export const fetchModelById = createAsyncThunk('model/fetchModelById',async (id,{rejectWithValue}) => {
  try {
    const token = localStorage.getItem('token')
    const {data} = await axios.get(BaseUrl + `/models/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    return data
  } catch (err) {
    let error = err 
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
    
  },
)
export const addNewModel = createAsyncThunk(
  'model/addNewModel',
  async (initialModel) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(
      BaseUrl + `/models/create/`,
      initialModel,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    console.log(response.data)
    return response.data
  },
)
export const modelUpdate = createAsyncThunk(
  'model/modelUpdate',
  async (obj, thunkAPI) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/models/${obj.id}/edit/`,
        obj.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //    console.log(data,'updatedModel')
      //  console.log(response.data,'response.data')
      return response.data
    } catch (error) {
      console.error('Failed to update the model: ', error)
    }
  },
)
export const deleteModel = createAsyncThunk('model/deleteModel', async (id) => {
  const token = localStorage.getItem('token')
  const response = await axios.delete(BaseUrl + `/models/${id}/delete/`, {
    headers: {
      Authorization: `token ${token}`,
    },
  })
  return response.data
})



export const addAccessControlModel = createAsyncThunk(
  'model/addAccessControlModel',
  async (data) => {
      const token = localStorage.getItem('token')
    //try {
      const response = await axios.post(
        BaseUrl + `/modelsPermissions/create/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //console.log(response.data)
      return response.data
   //} catch (err) {console.log(err)}
  }
)

const modelSlice = createSlice({
  name: ' model',
  initialState,
  extraReducers: {
    [fetchModels.pending]: (state, action) => {
      state.modelsStatus = 'loading'
    },
    [fetchModels.fulfilled]: (state, action) => {
      state.modelsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.models = action.payload
    },
    [fetchModels.rejected]: (state, action) => {
      state.modelsStatus = 'failed'
      state.error = action.payload
    },

    [fetchModelById.pending]: (state, action) => {
      state.modelStatus = 'loading'
    },
    [fetchModelById.fulfilled]: (state, action) => {
      state.modelStatus = 'succeeded'

      // Add any fetched posts to the array
      state.model = action.payload
    },
    [fetchModelById.rejected]: (state, action) => {
      state.modelStatus = 'failed'
      state.error = action.payload
    },
    [addNewModel.fulfilled]: (state, action) => {
      state.models.push(action.payload)
    },
    [modelUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [deleteModel.fulfilled]: (state, action) => {
      console.log(action)
    },
    /*fetch access control model */
    [fetchAccessControlModels.pending]: (state, action) => {
      state.accessControlsStatus = 'loading'
    },
    [fetchAccessControlModels.fulfilled]: (state, action) => {
      state.accessControlsStatus = 'succeeded'
      state.accessControls = action.payload
    },
    [fetchAccessControlModels.rejected]: (state, action) => {
      state.accessControlsStatus = 'failed'
      state.error = action.payload
    },
    /*add access control model */
    [addAccessControlModel.pending]: (state, action) => {
      state.accessControlsStatus = 'loading'
    },
    [addAccessControlModel.fulfilled]: (state, action) => {
      state.accessControlsStatus = 'succeeded'
      //state.accessControls.push(action.payload)
      state.accessControls = action.payload
    },
    [addAccessControlModel.rejected]: (state, action) => {
      state.accessControlsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        //console.log("action.payload from inside extra add access control reducer", action.payload)
      } else {
        state.error = action.error
        //console.log("action.error from inside extra add access control reducer", action.error)
      }
    },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload
    },

    modelAdded: {
      reducer(state, action) {
        state.models.push(action.payload)
      },
      prepare(
        name,
        university_id,
        mission,
        phoneNumber,
        email,
        director,
        image,
        adress,
        city,
        postalCode,
      ) {
        // omit prepare logic
      },
    },
    modelUpdated(state, action) {
      const { id, name, groups_ids, description } = action.payload
      const existingModel = state.models.find((model) => model.id === id)
      if (existingModel) {
        existingModel.name = name
        existingModel.groups_ids = groups_ids
        existingModel.description = description
      }
    },
  },
})

export const { modelAdded, modelUpdated, getId } = modelSlice.actions

export default modelSlice.reducer

export const selectAllModels = (state) => state.model.models
