import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {

  InternationalResearchConfigs: [],
  InternationalResearchConfigsStatus: 'idle',
  InternationalResearchConfigsByType: [],
  InternationalResearchConfigsByTypeStatus: 'idle',
  InternationalResearchConfig: null,
  InternationalResearchConfigStatus: 'idle',

}

export const fetchInternationalResearchConfigs = createAsyncThunk('internationalConfig/fetchInternationalResearchConfigs', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/internationalResearch/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    //console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response)
  }
})

export const addInternationalResearchConfig = createAsyncThunk(
  'internationalConfig/addInternationalResearchConfig',
  async (toAddActivity, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/internationalResearch/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response)
    }
  }
)

export const deleteInternationalResearchConfig = createAsyncThunk(
  'internationalConfig/deleteInternationalResearchConfig',
  async (id, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(BaseUrl + `/internationalResearch/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response)
    }
  }
)

const internationalResearchConfigSlice = createSlice({
  name: 'internationalConfig',
  initialState,
  extraReducers: {

    [fetchInternationalResearchConfigs.pending]: (state, action) => {
      state.InternationalResearchConfigsStatus = 'loading'
    },
    [fetchInternationalResearchConfigs.fulfilled]: (state, action) => {
      state.InternationalResearchConfigsStatus = 'succeeded'

      state.InternationalResearchConfigs = action.payload
    },
    [fetchInternationalResearchConfigs.rejected]: (state, action) => {
      state.InternationalResearchConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

  },

})

export default internationalResearchConfigSlice.reducer

