import { Avatar, Grid } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { Breadcrumb, Progress } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAssessmentId } from "../../../../reduxToolkit/features/assessment/AssessmentSlice";
import { fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import CourseReportModal from "../../../Components/CourseReportModal";
import CreateSurveyModal from "../../../Components/CreateSurveyModal";
import DropDownButtonUserView from "../../../Components/DropDownButtonUserView";










const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);

export default function AssessmentsListFaculty({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const dispatch = useDispatch();
  // const id = useSelector((state) => state.course.id)

  const AssessmentMethod = useSelector(
    (state) => state.course.AssessmentMethod
  );
  const { id } = match.params;
  const { ProgramId } = match.params;

  const [assessmentMethodsArry, setAssessmentMethodsArry] = useState([]);
  let arr = [];
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    // console.log("res")
    // console.log(course.id, 'id course in dropdwon')
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    console.log(id, "id");
    console.log(ProgramId, "ProgramId");
    dispatch(fetchCourseById(id));
    dispatch(fetchProgramById(ProgramId));
  }, [dispatch, course]);

  const sendID = (id) => {
    dispatch(getAssessmentId(id));
    // console.log(id, 'id CourseAssessment method inn the assessment list')
  };
  const course = useSelector((state) => state.course.course);
  const program = useSelector((state) => state.program.program);
  //   let filtredAss = [];
  // const user_id = localStorage.getItem('userId')
  // console.log('user_id',user_id)
  //   if (user_id == 72) {
  //     console.log(user_id, 'its him let do filter')
  //     course && course.assessmentMethod_details && course.assessmentMethod_details.map((cr)=> {

  //          if(cr.id == 673)
  //           {
  //             filtredAss.push(cr);
  //           }


  //       });
  //      // menus = currentUser.menus
  //     // console.log(menus, 'menus')
  //    }
  //    else {
  //     course && course.assessmentMethod_details && course.assessmentMethod_details.map((cr)=> {


  //         filtredAss.push(cr);



  //    });
  //    }
  // console.log(course, 'course')
  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/assessments_list/course/${id}`}
              style={{ color: "orange" }}
            >
              {t("Assessments Methods")}
            </Link>
          </Breadcrumb.Item>

        </Breadcrumb>
      </GridItem>

      <GridItem>
        <Card>
          <CardHeader
            color="info"
            icon
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <CardIcon color="primary" style={{ height: "60px" }}>
              <SchoolRounded />
            </CardIcon>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={2}>
                <div>
                
                    <h4
                      style={{
                        color: "#8F6682",
                        height: "13px",
                        fontSize: "14px",
                        marginRight: "20px",
                      }}
                    >
                      <strong>
                        {course &&
                          course.program_detail &&
                          course.program_detail.map((el) => el.code)}{" "}
                      </strong>
                    </h4>
               
                
                    <h4
                      style={{
                        color: "#6C7B9D",
                        height: "13px",
                        fontSize: "14px",
                        marginRight: "20px",
                      }}
                    >
                      <strong>{course && course.code && course.code}</strong>
                    </h4>
               
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={5}>
                <div>
                 
                    <h4
                      style={{
                        color: "#8F6682",
                        fontSize: "14px",
                        height: "13px",
                      }}
                    >
                      <strong>
                        {course &&
                          course.program_detail &&
                          course.program_detail.map(
                            (el) => el.program_degree_name
                          )}
                      </strong>
                    </h4>
                
                 
                    <h4
                      style={{
                        color: "#6C7B9D",
                        fontSize: "14px",
                        height: "13px",
                      }}
                    >
                      <strong>{course && course.title}</strong>
                    </h4>
                 
                </div>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={5}
                style={{
                  padding: "5px",
                  margin: "10px 0px",
                  textAlign: "right",
                }}
              >
                {/* <GridContainer>
                  <GridItem>
                    <CourseReportModal
                      handleMenuItemClick={handleMenuItemClick}
                      courseID={course && course.id && course.id}
                      handleToggle={handleToggle}
                    />
                  </GridItem>
                  <GridItem>
                    <CreateSurveyModal
                      courseID={course && course.id && course.id}
                      course={course && course}
                    />
                  </GridItem>
                </GridContainer> */}
              </GridItem>
            </GridContainer>
          </CardHeader>
        </Card>
      </GridItem>

      <GridContainer>
        {course &&
          course.assessmentMethod_details &&
          course.assessmentMethod_details.map((item, index) => (
            <GridItem xs={12} sm={6} md={4} onClick={() => sendID(item.id)}>
              <Card>
                <CardHeader style={{ textAlign: "start", height: "100%" }}>
                  <h4
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "20px",
                    }}
                  >
                    <Link
                      to={`/citrine/faculty/assessmentsDetails/course/${course.id}/assessmentMethod/${item.id}/${item.name}`}
                    >
                      <strong>
                        <p className={classes2.cardCategory}>{item.name}</p>
                      </strong>
                    </Link>
                    <DropDownButtonUserView
                      AssessmentMethodID={item.id}
                      courseID={course.id}
                      course={course}
                    />
                  </h4>
                  {/* <h4>Instructor : {item.instructor.map((el) => el.first_name + " " + el.last_name)}</h4>
                                <h4>Responsible Of Inputs : {item.responsible_of_inputs.map((el) => el.first_name + " " + el.last_name)}</h4>
                                <h4>Date Of Inputs : {Moment(item.date_of_input).format('d MMM YYYY')} </h4>
                                <h4>Groups Names  :
                                    {item && item.groups && item.groups.map((el) =>
                                        <Badge
                                            className="site-badge-count-109"
                                            count={el.name}
                                            style={{ backgroundColor: '#52c41a' }}
                                        />

                                    )}
                                </h4> */}

                  {/* <h4>{item && item.groupAssessment_ids && item.groups.map((el) => (
                        <Badge
                          className="site-badge-count-109"
                          count={el.name}
                          style={{ backgroundColor: "#52c41a" }}
                        />
                      ))}
                  </h4> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "40px",
                      marginEnd: "10px",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: red[600],
                          height: 56,
                          width: 56,
                        }}
                        style={{ backgroundColor: "red" }}
                      >
                        {/* 5 */}
                      </Avatar>
                      <p style={{ marginTop: "10px", fontSize: "14px" }}>
                        {t("Absent Students")}
                      </p>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: "success",
                          height: 56,
                          width: 56,
                        }}
                        style={{ backgroundColor: "green" }}
                      >
                        {item.total_marks}
                      </Avatar>
                      <p style={{ marginTop: "10px", fontSize: "14px" }}>
                        {" "}
                        {t("Total Marks")}
                      </p>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Progress type="circle" percent={75} width={40} />
                      <p style={{ marginTop: "10px", fontSize: "14px" }}>
                        {" "}
                        {t("Percent of Input")}
                      </p>
                    </Grid>
                  </div>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
