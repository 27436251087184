import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'


const initialState = {
  accreditationDates: [],
  accreditationDatesStatus: 'idle',
  error: null,
}

export const fetchAccreditationDates = createAsyncThunk('accreditationDate/fetchAccreditationDates', async (parameter,{ rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/accreditationInformation/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})


export const fetchAccreditationDatesByProgramId = createAsyncThunk('accreditationDate/fetchAccreditationDateById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/accreditationInformation/?programSpec_id=${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchAccreditationDateById = createAsyncThunk('accreditationDate/fetchAccreditationDateById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/accreditationInformation/${id}/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const addNewAccreditationDate = createAsyncThunk(
  'accreditationDate/addNewAccreditationDate',
  async (toAdd, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/accreditationInformation/create/`, toAdd, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const updateAccreditationDate = createAsyncThunk(
  'accreditationDate/updateAccreditationDate',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/accreditationInformation/${data.id}/edit/`, data.updated, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const deleteAccreditationDate = createAsyncThunk(
  "accreditationDate/deleteAccreditationDate",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/accreditationInformation/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


const accreditationDatesSlice = createSlice({
  name: ' accreditationDate',
  initialState,
  extraReducers: {

    [fetchAccreditationDatesByProgramId.pending]: (state, action) => {
      state.accreditationDatesStatus = 'loading'
    },
    [fetchAccreditationDatesByProgramId.fulfilled]: (state, action) => {
      state.accreditationDatesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.accreditationDates = action.payload
    },
    [fetchAccreditationDatesByProgramId.rejected]: (state, action) => {
      state.accreditationDatesStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    
    [addNewAccreditationDate.fulfilled]: (state, action) => {
      state.accreditationDates.push(action.payload)
    },
    [addNewAccreditationDate.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
  },

})

export default accreditationDatesSlice.reducer