import React, { useEffect, useState } from 'react'
import GridItem from "../../../components/Grid/GridItem";
import {Breadcrumb, Button} from "antd";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchProgramById,
    updateAreaWeightScale
} from "../../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "../../Components/ErrorModal";
import {DataGrid} from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import {fetchAreasByTemplateId} from "../../../reduxToolkit/features/SurveyArea/SurveyAreaSlice";
import {
    getSurveyAreaResourceLinks, linkSurveyAreaFromResource,
    unlinkSurveyAreaFromResource
} from "../../../reduxToolkit/features/SurveyMapping/SurveyMappingSlice";
import Stack from "@mui/material/Stack";
import Chip from "@material-ui/core/Chip";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import FormControl from "@mui/material/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";

export default function SurveyTemplatesMapping() {

    const { t } = useTranslation();

    let { id, idST } = useParams()

    const dispatch = useDispatch();

    const [surveyTemplate, setSurveyTemplate] = useState(null);
    const [handledError, setHandledError] = useState("");
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [weight, setWeight] = useState(0);
    const [resource, setResource] = useState("");
    const [resources, setResources] = useState([]);
    const [program, setProgram] = useState(null);
    const [selectedCell, setSelectedCell] = useState(null);
    const [rows, setRows] = useState([]);
    const [submited, setSubmited] = useState(false);

    const programStatus = useSelector((state) => state.programSpec.programStatus);
    const surveyAreasStatus = useSelector((state) => state.surveyArea.loading);
    const surveyMappingStatus = useSelector((state) => state.surveyMapping.loading);

    const columns = [
        {
            id: 1,
            field: 'area',
            headerName: 'Area',
            type: 'string',
            flex: 1,
        },
        {
            id: 2,
            field: 'ploi', // DO NOT CHANGE THIS FIELD,
            headerName: 'PLO Indicator',
            type: 'string',
            flex: 1,
            valueFormatter: (params) => {
                const {value} = params;
                return value.name ?? '';
            },
            renderCell: (params) => <CustomCell params={params}/>
        },
        {
            id: 3,
            field: 'ga', // DO NOT CHANGE THIS FIELD
            headerName: 'GA',
            type: 'string',
            flex: 1,
            valueFormatter: (params) => {
                const {value} = params;
                return value.name ?? '';
            },
            renderCell: (params) => <CustomCell params={params}/>
        },
        {
            id: 4,
            field: 'peo', // DO NOT CHANGE THIS FIELD
            headerName: 'PEO',
            type: 'string',
            flex: 1,
            valueFormatter: (params) => {
                const {value} = params;
                return value.name ?? '';
            },
            renderCell: (params) => <CustomCell params={params}/>
        },
    ]

    const getSlicedText = (text) => {
        if (text && text.length > 30)
            text = text.slice(0, 30) + ' ...';
        return text;
    }

    const CustomCell = ({params}) => {
        const {row, id, field} = params;
        const {mappingId, weight, name} = row[field];
        const isValid = weight > 0 && name;
        const color = isValid ? field === 'peo' ? 'lightcyan' : field === 'ploi' ? 'lightgoldenrodyellow' : field === 'ga' ? 'linen' : 'transparent' : 'transparent';
        return (
            isValid ?
                    <Stack flex={1} style={{padding: 10, backgroundColor: color}} direction="row" alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                            <Tooltip title={name ?? ''} aria-label="cell">
                                <span style={{color: 'brown'}}>{getSlicedText(name) ?? ''}</span>
                            </Tooltip>
                            {isValid && <Chip label={weight}/>}
                        </Stack>
                        {isValid && <IconButton
                            color="primary"
                            aria-label="unlink"
                            component="span"
                            onClick={() => removeSurveyAreaLinkFromResource(field, id, mappingId)}
                        >
                            <CloseIcon color={'error'} fontSize={'small'}/>
                        </IconButton>
                        }
                    </Stack> :
                    <Stack>
                        <IconButton
                            color="primary"
                            aria-label="link"
                            component="span"
                            onClick={() => {handleOpen(params)}}
                        >
                            <AddIcon color={'primary'} fontSize={'small'}/>
                        </IconButton>
                    </Stack>
        );
    }

    useEffect(() => {
        fetchProgram();
    }, []);

    useEffect(() => {
        fetchSurveyAreas();
    }, []);

    const AreaWeightScale = () => {
        const [areaWeightScale, setAreaWeightScale] = useState(program?.areaWeightScale);
        const areaWeightScaleStatus = useSelector((state) => state.programSpec.areaWeightScaleStatus);

        const handleChange = async (event) => {
            const {value} = event.target;
            await dispatch(updateAreaWeightScale({id, areaWeightScale: value})).unwrap();
            setAreaWeightScale(value);
        };

        return(
            <FormControl variant="filled" size="small">
                <InputLabel id="area-weight-scale-label">Area Weight Scale</InputLabel>
                <Select
                    disabled={areaWeightScaleStatus === 'loading'}
                    labelId="area-weight-scale-label"
                    id="area-weight-scale"
                    value={areaWeightScale ?? ''}
                    label="Area Weight Scale"
                    onChange={handleChange}
                >
                    <MenuItem value={''}>
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={'percent'}>Percentage</MenuItem>
                    <MenuItem value={'number'}>Number</MenuItem>
                </Select>
            </FormControl>
        )
    }

    const findSurveyTemplate = (surveyTemplates = []) => {
        if(surveyTemplates && surveyTemplates?.length > 0) {
            const surveyT = surveyTemplates.find(item => {
                return item.id == idST;
            });
            setSurveyTemplate(surveyT);
        }
    }

    const handleOpen = ($event) => {
        const {id, field, value, row} = $event;
        if (field === 'area') // `peo` or `ga` or `ploi`
            return
        const {areaId} = row;
        const {weight, mappingId, name} = value;
        setSelectedCell({
            field,
            cellId: id,
            areaId
        });
        setResource(name ?? '');
        setWeight(weight ?? 0);
        setOpen(true);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleClose = () => {
        setSubmited(false);
        setWeight(0);
        setOpen(false);
    };

    const getResourceIdByCode = (code, type) => {
        const list = resources[type];
        if (list && list?.length) {
            return list.find((item) => item.code === code)?.id || null;
        }
        return null;
    }

    const onSubmit = async () => {
        setSubmited(true)
        if (weight == 0 || resource == null) {
            return;
        }
        const {areaId, cellId, field} = selectedCell;
        const resourceType = field === 'peo' ? 'educationalObjectives' : field === 'ploi' ? 'ploIndicatorsSpec' : field === 'ga' ? 'graduateAttributeSpec' : null;
        const resourceId = getResourceIdByCode(resource, field);
        const payload = {
            surveyArea: areaId,
            resource: resourceType,
            resource_id: resourceId,
            weight
        }
        await dispatch(linkSurveyAreaFromResource({
            id: idST,
            payload
        })).unwrap().then((res) => {
            const createdId = res.id;
            let rowsToUpdate = rows;
            switch (resourceType) {
                case 'ploIndicatorsSpec':
                    rowsToUpdate[cellId].ploi.name = resource;
                    rowsToUpdate[cellId].ploi.mappingId = createdId;
                    rowsToUpdate[cellId].ploi.resourceId = resourceId;
                    rowsToUpdate[cellId].ploi.weight = weight;
                    break;
                case 'educationalObjectives':
                    rowsToUpdate[cellId].peo.name = resource;
                    rowsToUpdate[cellId].peo.mappingId = createdId;
                    rowsToUpdate[cellId].peo.resourceId = resourceId;
                    rowsToUpdate[cellId].peo.weight = weight;
                    break;
                case 'graduateAttributeSpec':
                    rowsToUpdate[cellId].ga.name = resource;
                    rowsToUpdate[cellId].ga.mappingId = createdId;
                    rowsToUpdate[cellId].ga.resourceId = resourceId;
                    rowsToUpdate[cellId].ga.weight = weight;
                    break;
                default:
            }
            setRows(rowsToUpdate);
        });
        handleClose();
    }

    const onChangeResource = (e) => {
        setResource(e.target.value)
    }

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const fetchProgram = async () => {
        try {
            const res=await dispatch(fetchProgramById(id)).unwrap();
            setProgram(res);
            const {surveytemplate_details, peosSpec, graduationsSpec_detail, plosSpec} = res;
            setResources({
                peo: peosSpec,
                ploi: plosSpec.flatMap(obj => obj.ploIndicators),
                ga: graduationsSpec_detail
            })
            findSurveyTemplate(surveytemplate_details);
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    const fetchSurveyAreas = async () => {
        try {
            const surveyAreas = await dispatch(fetchAreasByTemplateId(idST)).unwrap();
            const sMapping = await dispatch(getSurveyAreaResourceLinks(idST)).unwrap();
            const mappedRows= surveyAreas.map((item, index) => {
                return (
                    {
                        id: index,
                        area: item.name,
                        areaId: item.id,
                        ploi: {
                            name: null,
                            weight: 0,
                            mappingId: null,
                            resourceId: null,
                        },
                        ga: {
                            name: null,
                            weight: 0,
                            mappingId: null,
                            resourceId: null,
                        },
                        peo: {
                            name: null,
                            weight: 0,
                            mappingId: null,
                            resourceId: null,
                        },
                    }
                )
            })
            sMapping.forEach(item => {
                const {id, weight, surveyArea_details} = item;
                const {area, code} = surveyArea_details;
                let index = mappedRows.findIndex((item) => item.area === area?.name);
                if(index !== -1) {
                    switch (item.resource) {
                        case 'educationalObjectives':
                            mappedRows[index].peo.name = code;
                            mappedRows[index].peo.weight = weight;
                            mappedRows[index].peo.resourceId = surveyArea_details.id;
                            mappedRows[index].peo.mappingId = id;
                            break;
                        case 'ploIndicatorsSpec':
                            mappedRows[index].ploi.name = code;
                            mappedRows[index].ploi.weight = weight;
                            mappedRows[index].ploi.resourceId = surveyArea_details.id;
                            mappedRows[index].ploi.mappingId = id;
                            break;
                        case 'graduateAttributeSpec':
                            mappedRows[index].ga.name = code;
                            mappedRows[index].ga.weight = weight;
                            mappedRows[index].ga.resourceId = surveyArea_details.id;
                            mappedRows[index].ga.mappingId = id;
                            break;
                        default:
                    }
                }
            })
            setRows(mappedRows);
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    const removeSurveyAreaLinkFromResource = async (field, cellId, id) => {
        let rowsToUpdate = rows;
        if (field === 'ploi') {
            rowsToUpdate[cellId].ploi.name = null;
            rowsToUpdate[cellId].ploi.weight = 0;
            rowsToUpdate[cellId].ploi.resourceId = null;
            rowsToUpdate[cellId].ploi.mappingId = null;
        } else if (field === 'peo') {
            rowsToUpdate[cellId].peo.name = null;
            rowsToUpdate[cellId].peo.weight = 0;
            rowsToUpdate[cellId].peo.resourceId = null;
            rowsToUpdate[cellId].peo.mappingId = null;
        } else if (field === 'ga') {
            rowsToUpdate[cellId].ga.name = null;
            rowsToUpdate[cellId].ga.weight = 0;
            rowsToUpdate[cellId].ga.resourceId = null;
            rowsToUpdate[cellId].ga.mappingId = null;
        }
        try {
            await dispatch(unlinkSurveyAreaFromResource(id)).unwrap().then(() => {
                setRows(rowsToUpdate);
            });
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    const onChangeWeight = (e) => {
        const {value} = e.target;
        isNaN(value) ? setWeight(0) : setWeight(value > 100 ? 100 : value);
    }

    const MappingAccordion = () => {
        const resultObject = {ploi: [], ga: [], peo: []};
        rows.forEach(item => {
            const { ploi, ga, peo } = item;
            const addEntry = (category, name, area, weight) => {
                if (!resultObject[category]) {
                    resultObject[category] = [];
                }
                const existingEntry = resultObject[category].find(entry => entry.name === name);
                if (existingEntry) {
                    existingEntry.areas.push({ area, weight });
                } else {
                    resultObject[category].push({
                        name,
                        areas: [{ area, weight }],
                    });
                }
            };
            if (ploi && ploi.name) {
                addEntry('ploi', ploi.name, item.area, ploi.weight);
            }
            if (ga && ga.name) {
                addEntry('ga', ga.name, item.area, ga.weight);
            }
            if (peo && peo.name) {
                addEntry('peo', peo.name, item.area, peo.weight);
            }
        });
        const {ploi, peo, ga} = resultObject;

        const CustomAccordion = ({name, list = []}) => {
            return (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={`panel-${name}-content`}
                        id={`panel-${name}-header`}
                    >
                        <span style={{textTransform: 'uppercase'}}>{name}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            list.map((item, index) => {
                                return (
                                    <Accordion style={{width:'100%'}} key={`${name}-${index}`}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls={`panel-${name}-${index}-content`}
                                            id={`panel-${name}-${index}-header`}
                                        >
                                            {item?.name}
                                        </AccordionSummary>
                                        <AccordionDetails style={{width:'100%'}}>
                                            <Stack direction="column" spacing={1}>
                                                {item?.areas.map((elm, key) => {
                                                    return(
                                                        <Card key={key}>
                                                            <CardBody>
                                                                <Stack direction="row" spacing={1} alignItems={'center'}>
                                                                    <span>{elm?.area ?? ''}</span>
                                                                    <Chip label={elm?.weight ?? 0}/>
                                                                </Stack>
                                                            </CardBody>
                                                        </Card>
                                                    )
                                                })}
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })

                        }
                    </AccordionDetails>
                </Accordion>
            )
        }

        return (
            <>
                <CustomAccordion name={'ploi'} list={ploi} />
                <CustomAccordion name={'peo'} list={peo} />
                <CustomAccordion name={'ga'} list={ga} />
            </>
        )
    }

    return (
        <GridContainer>
            <GridItem>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to={`/citrine/programSpec`}>{t("Programs List")}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/programSpec/${id}`}>
                            {program?.program_degree_name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/citrine/specification/program/${id}/surveyTemplates/`}
                        >
                            {t("Survey templates List")}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/citrine/surveyTemplates/${idST}`}
                        >
                            {surveyTemplate?.name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/specification/program/${id}/surveyTemplates/${idST}/mapping/`}
                            style={{ color: "orange" }}
                        >
                            {t("Survey template mapping")}
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader
                        color="rose"
                        icon
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <GridItem xs={12} sm={12} md={12}>
                            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                                <h4>{t("Survey Template Mapping")}</h4>
                                {(programStatus !== "loading") && <AreaWeightScale />}
                            </Stack>
                        </GridItem>
                    </CardHeader>
                    <CardBody>
                        <GridContainer
                            style={{
                                height: 400,
                                width: '100%',
                            }}
                        >
                            <DataGrid
                                loading={(programStatus === "loading" || surveyAreasStatus === "pending" || surveyMappingStatus === 'pending')}
                                rows={rows}
                                columns={columns}
                                editMode="row"
                                pageSize={5}
                                pageSizeOptions={[5, 10, 25]}
                            />
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <Card>
                <CardBody>
                    <MappingAccordion />
                </CardBody>
            </Card>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={handleClose}
            >
                <DialogTitle>Set Mapping</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="weight"
                        name="weight"
                        label="Weight"
                        type="number"
                        fullWidth
                        style={{marginBottom: 20}}
                        variant="standard"
                        onChange={onChangeWeight}
                        InputProps={{ inputProps: { min: "0", max: "100", step: "1" } }}
                        value={weight}
                    />
                    {(weight < 1 && submited)  && <span style={{fontSize: '12px', color: 'red'}}>Weight need to be more than 0</span>}
                    <InputLabel style={{textTransform: 'capitalize'}} id="resource-label">{selectedCell?.field ?? 'Resource'} *</InputLabel>
                    <Select
                        id="resource"
                        fullWidth
                        required
                        labelId="resource-label"
                        value={resource ?? ''}
                        label="resource"
                        onChange={onChangeResource}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            (resources && selectedCell) && resources[selectedCell.field]?.map((i, index) =>
                                <MenuItem key={index} value={i.code}>{i.code}</MenuItem>
                            )
                        }
                    </Select>
                    {((resource === '' || resource == null) && submited)  && <span style={{fontSize: '12px', color: 'red'}}>Resource is required</span>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit}>Add</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </GridContainer>
    );
}
