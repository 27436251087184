import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Input, notification, Select } from 'antd';
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNewJob, fetchJobs
} from '../../reduxToolkit/features/jobs/JobsSlice';
import { fetchProgramById } from '../../reduxToolkit/features/program/ProgramSpecSlice';
import ErrorModal from "./ErrorModal";



export default function AddJobModal(props) {
    const { t } = useTranslation();
    const { Option } = Select;
    //console.log('program id in add job modal', programSpec_id)
    const programSpec_id  = props.programSpec_id;
    console.log('programSpec_id in add job modal', programSpec_id)
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [target, setTarget] = useState(null)

    const { error } = useSelector((state) => state.ploSpec)
    const dispatch = useDispatch()


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };
    const jobsStatus = useSelector((state) => state.jobs.jobsStatus)
    useEffect(() => {
        if (jobsStatus === "idle") {
            dispatch(fetchJobs());
        }
    }, [jobsStatus, dispatch]);

    const jobs = useSelector((state) => state.jobs.jobs)
    console.log('levelJobsStatus', jobsStatus)
    console.log('levelJobs', jobs)
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // message.error('Submit failed!');
    };

    const onFinish = async () => {
        // const target = parseInt(target)
        const resultAction = await dispatch(
            addNewJob({ name, code, programSpec_id })
        )

        if (addNewJob.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            const job = resultAction.payload
            console.log('success', `Added ${job.name}`)
            openNotificationWithIcon('success', `Added ${job.name}`)
            handleClose()
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                handleClose()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                handleClose()
            }
        }
        dispatch(fetchProgramById(programSpec_id))
    }



    return (
        <div>

           
                <Button color="primary" onClick={handleClickOpen}>
                    {t('Add')}
                </Button>
            
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><center>{t('Add a Job')} </center> </DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={8} sm={8} md={12}>
                            
                        </GridItem>
                        <GridItem xs={10} sm={10} md={12}>

                            <Form
                                name="basic"
                                layout="vertical"
                                //   labelCol={{ span: 8 }}
                                //   wrapperCol={{ span: 16 }}
                                //   initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                //   autoComplete="off"
                                scrollToFirstError
                            >

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Form.Item
                                            label={t('Name')}
                                            name="name"
                                            // hasFeedback
                                            rules={[{ required: true, message: "Please input the job's name" }]}

                                        >
                                            <Input
                                                id="name"
                                                value={name}
                                                onChange={event => {
                                                    setName(event.target.value);
                                                }}
                                            />
                                        </Form.Item>

                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>
                                        <Form.Item
                                            label={t("Code")}
                                            name="code"
                                            rules={[{ required: true, message: "Please input the job's code" }]}
                                        >
                                            <Input
                                                id="code"
                                                value={code}
                                                onChange={event => {
                                                    setCode(event.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>


                                {/* </FormControl> */}
                                <GridContainer justify="flex-end">
                                    <GridItem>

                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button color="primary" onClick={() => { handleClose() }}>
                                                        {t('Cancel')}
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t('Save')}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>

                                    </GridItem>
                                </GridContainer>

                            </Form>
                        </GridItem>

                    </GridContainer>

                </DialogContent>
            </Dialog>

            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />

        </div>
    );
}
