import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import MoreVert from "@material-ui/icons/MoreVert";
import GAIndicatorsAndCLOsMapModal from "./GAIndicatorsAndCLOsMapModal";

import { useTranslation } from "react-i18next";



import UpdateInstructorModal from "./updateInstructor";
import UpdateResponsileInputsModal from "./updateResponsileInputsModal";



const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDwonButtonGoToGroup(props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  console.log("props", props.GAInd);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <UpdateResponsileInputsModal
          id={props.CourseAssessmentMethodID}
          courseID={props.courseID}
          handleClose={handleClose}
        />


      </StyledMenu>
    </div>
  );
}
