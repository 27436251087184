import "@lourenci/react-kanban/dist/styles.css";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActualLearningHours,
  fetchTotalActualLearningHour,
} from "../../../../../reduxToolkit/features/ActualLearningHours/ActualLearningHourSlice";
import { fetchCourseById } from "../../../../../reduxToolkit/features/course/CoursesSlice";
import useTable from "../../../../Components/useTable";
import "../../improvement.css";

import ActualHours from './ActualHours'

import { DataGrid } from '@mui/x-data-grid';
const columns = [
  {
    field: 'Activity',
    headerName: 'Activity',
    width: 250,
  },
  {
    field: 'Type',
    headerName: 'Type',
    width: 250,
  },
  {
    field: 'Actual',
    headerName: 'Actual Hours',
    type: 'number',
    width: 180,
    editable: true,
  },
  {
    field: 'Planned',
    headerName: 'Planned Hours',
    type: 'number',
    width: 190,
  }
];


const { TabPane } = Tabs;
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
const headCells = [
  { id: "Activity", label: "Activity" },
  { id: "Type", label: "Type" },
  { id: "Actual", label: "Actual Hours" },
  { id: "Planned", label: "Planned Hours" },
];
export default function ContactHours(props) {
  const [ping, setPing] = useState(false)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchCourseById(props.idCourse))
      .then(unwrapResult)
      .then((course) => {
        dispatch(fetchActualLearningHours(course && course.id));
        dispatch(
          fetchTotalActualLearningHour({ course_id: course && course.id })
        );
      });
  }, [dispatch, ping]);
  const course = useSelector((state) => state.course.course);
  const Total = useSelector((state) => state.ActualLearningHour.Total);
  const ActualLearningHours = useSelector((state) => state.ActualLearningHour.ActualLearningHours);
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  //console.log("ActualLearningHours", ActualLearningHours);
  //console.log("props.idCourse", props.idCourse);
  //console.log("Total", Total);
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);




  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>

          <div
            style={{
              margin: "0px 10px 10px 10px ",
              borderRight: "1px solid lightgrey",
              borderLeft: "1px solid lightgrey",
              //height: 400,


            }}
          >

            <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
              <TblHead />
              <TableBody>
                {ActualLearningHours &&
                  ActualLearningHours.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell
                        style={{ borderRight: "1px solid lightgrey" }}
                      >
                        {item &&
                          item.activity_details &&
                          item.activity_details[0] &&
                          item.activity_details[0].name}
                      </TableCell>
                      <TableCell
                        style={{ borderRight: "1px solid lightgrey" }}
                      >
                        {item &&
                          item.type_details &&
                          item.type_details[0] &&
                          item.type_details[0].name}
                      </TableCell>

                      <TableCell

                        style={{ borderRight: "1px solid lightgrey" }}
                      >
                        {/* {item && item.actual_hours} */}
                        <ActualHours ping={ping} setPing={setPing} actualLearningHours={item} />

                      </TableCell>

                      <TableCell>{item && item.learning_hours}</TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell style={{ textAlign: "end" }}>
                    {"Total"}
                  </TableCell>
                  <TableCell></TableCell>

                  <TableCell

                    style={{
                      borderLeft: "1px solid lightgrey",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    {Total &&
                      Total.total_actual_hours &&
                      Total.total_actual_hours}
                  </TableCell>
                  <TableCell>
                    {Total &&
                      Total.total_learning_hours &&
                      Total.total_learning_hours}
                  </TableCell>
                </TableRow>
              </TableBody>
            </TblContainer>

          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
