import '@lourenci/react-kanban/dist/styles.css'
import { TableBody, TableCell, TableRow } from '@material-ui/core'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { Tabs } from 'antd'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlosAssessmentResult } from '../../../../../reduxToolkit/features/PLO/PLOsSlice'
import useTablePloResults from '../../../../Components/useTablePloResults'
import Comments from '../Comments'
import '../../improvement.css'
import AddCommentsOnAnnualProgram from '../../../../Components/AddCommentsOnAnnualProgram'
import {
  deleteComments,
  fetchCommentsOnAnnualProgramByProgramAndType,
} from 'reduxToolkit/features/Comments/CommentsSlice'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]

const useStyles2 = makeStyles({
  table: {
    minWidth: 700,
  },
})

const { TabPane } = Tabs
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center',
  },
  cardCategory: {
    margin: '0',
    color: '#999999',
  },
}

const useStyles = makeStyles(styles)

export default function PLOsAssessmentResults(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const classes2 = useStyles2()

  const [plosResultsMatrix, setPlosResultsMatrix] = useState([])
  let program_id =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].id

  useEffect(() => {
    dispatch(fetchPlosAssessmentResult(program_id))
      .then(unwrapResult)
      .then((PlosResults) => {
        console.log(PlosResults, 'PlosResults')
        setPlosResultsMatrix([...PlosResults])
      })
  }, [])

  const PlosResults = useSelector((state) => state.plo.PlosAssessmentResult)

  const [records, setRecords] = []
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const headCells = [
    { id: 'Level Code', label: 'Level Code' },
    {
      id: 'Program Learning Outcomes',
      label: 'Program Learning Outcomes',
    },
    { id: 'Assessment Methods', label: 'Assessment Methods' },
    { id: 'Performance Target', label: 'Performance Target' },
    { id: 'Result', label: 'Result' },
  ]

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTablePloResults(records, headCells, filterFn)
  let comment_resource = 'PlosResult'

  return (
    // <div>
    //   <TableContainer component={Paper}>
    //     <Table className={classes.table} aria-label="customized table">
    //       <TableHead>
    //         <TableRow>
    //           <StyledTableCell>Level Code</StyledTableCell>
    //           <StyledTableCell align="right">
    //             Program Learning Outcomes
    //           </StyledTableCell>
    //           <StyledTableCell align="right">
    //             Assessment Methods
    //           </StyledTableCell>
    //           <StyledTableCell align="right">
    //             Performance Target
    //           </StyledTableCell>
    //           <StyledTableCell align="right">Result</StyledTableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {PlosResults &&
    //           PlosResults.map((item, index) => (
    //             <>
    //               {item.level}

    //               {item &&
    //                 item.plos &&
    //                 item.plos.map((el) => (
    //                   <TableRow key={el.id}>
    //                     <TableCell
    //                       style={{
    //                         borderRight: '1px solid lightgray',
    //                         //width: '20%',
    //                       }}
    //                     >
    //                       {el.code_level}
    //                     </TableCell>
    //                     <TableCell
    //                       style={{
    //                         borderRight: '1px solid lightgray',
    //                         //width: '20%',
    //                       }}
    //                     >
    //                       {el.name}
    //                     </TableCell>

    //                     <TableCell
    //                       style={{
    //                         borderRight: '1px solid lightgray',
    //                         //width: '20%',
    //                       }}
    //                     >
    //                       {el &&
    //                         el.Assessment_Methods &&
    //                         el.Assessment_Methods.map((el) => (
    //                           <span> {el.name}</span>
    //                         ))}
    //                     </TableCell>
    //                     <TableCell
    //                       style={{
    //                         borderRight: '1px solid lightgray',
    //                         //width: '20%',
    //                       }}
    //                     >
    //                       {el.target}
    //                     </TableCell>

    //                     <TableCell
    //                       style={{
    //                         borderRight: '1px solid lightgray',
    //                         //width: '20%',
    //                       }}
    //                     >
    //                       {el.score}%
    //                     </TableCell>
    //                   </TableRow>
    //                 ))}
    //             </>
    //           ))}
    //         {/* {rows.map((row) => (
    //         <StyledTableRow key={row.name}>
    //           <StyledTableCell component="th" scope="row">
    //             {row.name}
    //           </StyledTableCell>
    //           <StyledTableCell align="right">{row.calories}</StyledTableCell>
    //           <StyledTableCell align="right">{row.fat}</StyledTableCell>
    //           <StyledTableCell align="right">{row.carbs}</StyledTableCell>
    //           <StyledTableCell align="right">{row.protein}</StyledTableCell>
    //         </StyledTableRow>
    //       ))} */}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // </div>
    <div>
      <TblHead />
      <TblContainer>
        <TableBody style={{ borderLeft: '1px solid lightgray' }}>
          {PlosResults &&
            PlosResults.map((item) => (
              <React.Fragment key={item.id}>
                <div
                  style={{
                    backgroundColor: 'rgb(175,131,183,0.6)',
                    color: 'white',
                    width: '100%',
                  }}
                >
                  {item.level}
                </div>

                {item &&
                  item.plos &&
                  item.plos.map((el) => (
                    <TableRow

                      key={el.id}
                    >
                      <TableCell
                        style={{
                          borderRight: '1px solid lightgray',
                          width: '20%',
                        }}
                      >
                        {el.code_level}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: '1px solid lightgray',
                          width: '20%',
                        }}
                      >
                        {el.name}
                      </TableCell>

                      <TableCell
                        style={{
                          borderRight: '1px solid lightgray',
                          width: '20%',
                        }}
                      >
                        {el &&
                          el.Assessment_Methods &&
                          el.Assessment_Methods.map((el) => (
                            <span> {el.name}</span>
                          ))}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: '1px solid lightgray',
                          width: '20%',
                        }}
                      >
                        {el.target}
                      </TableCell>

                      <TableCell
                        style={{
                          borderRight: '1px solid lightgray',
                          width: '20%',
                        }}
                      >
                        {el.score}%
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            ))}
        </TableBody>
      </TblContainer>

      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={12}>
          <Comments
            AnnualProgramReport={
              props.AnnualProgramReport && props.AnnualProgramReport
            }
            type={comment_resource}
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}
