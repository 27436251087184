import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import token from "assets/utils/accessToken";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  ActualLearningHours: [],
  ActualLearningHourStatus: "idle",
  ActualLearningHourStatus: "idle",
  error: null,
  ActualLearningHour: null,
  Total: null,
  TypesActualLearningHour: [],
  ActivitiesActualLearningHour: [],
  id: null,
};

export const fetchActualLearningHoursSpec = createAsyncThunk(
  " ActualLearningHour/fetchActualLearningHoursSpec",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/actualLearningHoursSpec/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchActivities_actualLearningHourSpec_ByCourse = createAsyncThunk(
  "ActualLearningHour/fetchActivities_actualLearningHourSpec_ByCourse",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
        `/actualLearningHoursSpec/level_filtered/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data fetchActivities ");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchTypes_actualLearningHourSpec_ByCourse = createAsyncThunk(
  "ActualLearningHour/fetchTypes_actualLearningHourSpec_ByCourse",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
        `/actualLearningHoursSpec/type_filtered/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchActualLearningHourById = createAsyncThunk(
  "ActualLearningHour/fetchActualLearningHourById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/actualLearningHoursSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewActualLearningHour = createAsyncThunk(
  "ActualLearningHour/addNewActualLearningHour",
  async (initialActualLearningHour, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      console.log("initial ActualLearningHour", initialActualLearningHour);
      const response = await axios.post(
        BaseUrl + `/actualLearningHoursSpec/create/`,
        initialActualLearningHour,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const ActualLearningHourUpdateSpec = createAsyncThunk(
  "ActualLearningHour/ActualLearningHourUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/actualLearningHoursSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteActualLearningHourSpec = createAsyncThunk(
  "ActualLearningHour/deleteActualLearningHourSpec",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/actualLearningHoursSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data, ' delete response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const ActualLearningHourSpecSlice = createSlice({
  name: "ActualLearningHour",
  initialState,

  extraReducers: {
    [fetchActualLearningHoursSpec.pending]: (state, action) => {
      state.ActualLearningHourStatus = "loading";
    },
    [fetchActualLearningHoursSpec.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = "succeeded";
      // Add any fetched posts to the array
      state.ActualLearningHours = action.payload;
    },
    [fetchActualLearningHoursSpec.rejected]: (state, action) => {
      state.ActualLearningHourStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchActivities_actualLearningHourSpec_ByCourse.pending]: (
      state,
      action
    ) => {
      state.ActualLearningHourStatus = "loading";
    },
    [fetchActivities_actualLearningHourSpec_ByCourse.fulfilled]: (
      state,
      action
    ) => {
      state.ActualLearningHourStatus = "succeeded";
      // Add any fetched posts to the array
      state.ActivitiesActualLearningHour = action.payload;
    },
    [fetchActivities_actualLearningHourSpec_ByCourse.rejected]: (
      state,
      action
    ) => {
      state.ActualLearningHourStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchTypes_actualLearningHourSpec_ByCourse.pending]: (state, action) => {
      state.ActualLearningHourStatus = "loading";
    },
    [fetchTypes_actualLearningHourSpec_ByCourse.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = "succeeded";
      // Add any fetched posts to the array
      state.TypesActualLearningHour = action.payload;
    },
    [fetchTypes_actualLearningHourSpec_ByCourse.rejected]: (state, action) => {
      state.ActualLearningHourStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchActualLearningHourById.pending]: (state, action) => {
      state.ActualLearningHourStatus = "loading";
    },
    [fetchActualLearningHourById.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = "succeeded";

      // Add any fetched posts to the array
      state.ActualLearningHour = action.payload;
    },
    [fetchActualLearningHourById.rejected]: (state, action) => {
      state.ActualLearningHourStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewActualLearningHour.fulfilled]: (state, action) => {
      state.ActualLearningHours.push(action.payload);
    },
    [deleteActualLearningHourSpec.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [ActualLearningHourUpdateSpec.fulfilled]: (state, action) => {
      console.log(action);
    },
    [addNewActualLearningHour.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteActualLearningHourSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [ActualLearningHourUpdateSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});

export default ActualLearningHourSpecSlice.reducer;


