import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  questions: [],
  questionsStatus: "idle",
  questionStatus: "idle",
  question: null,
  error: null,
  id: null,
};


export const addNewQuestion = createAsyncThunk(
  "Question/addNewQuestion",
  async (initialAssessmentMode, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/examQuestion/create/`,
        initialAssessmentMode,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
  console.log(response.data,"response.data add question")
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);



export const deleteQuestion = createAsyncThunk(
  "Question/deleteQuestion",
  async (body, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/examQuestion/assessment/delete/`,body,
        
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  'Question/updateQuestion',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log(data);
    try {
      const response = await axios.put(BaseUrl + `/examQuestion/${data.id}/edit/`, data.updatedQuestion, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      //console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

const QuestionsSlice = createSlice({
  name: " Question",
  initialState,
  extraReducers: {


    [addNewQuestion.fulfilled]: (state, action) => {
      state.questions = action.payload;
    },

    [addNewQuestion.rejected]: (state, action) => {
      state.questionsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [deleteQuestion.fulfilled]: (state, action) => {},

    [deleteQuestion.rejected]: (state, action) => {
      state.questionsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [updateQuestion.fulfilled]: (state, action) => {
      // const result = state.programs.filter(program => program.id !== action.payload.id);
      // result.push(action.payload)
      // state.programs = result
      //console.log("action.payload.id from slice", action.payload)
    },
    [updateQuestion.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
      //  console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
       // console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

  },
});

export default QuestionsSlice.reducer;
