import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {

  fetchCourseById
} from "../../../../../reduxToolkit/Course/coursesSpecSlice";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";



import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { Breadcrumb } from "antd";
import { fetchAlignmentOfCLOWithTMAndAMs } from "../../../../../reduxToolkit/features/AlignmentOfCLOWithTMAndAM/AlignmentOfCLOWithTMAndAMSlice";

import { notification } from "antd";
import { useTranslation } from "react-i18next";
import DropDownACWTMAM from "../../../../../views/Components/DropDownACWTMAM";
import ErrorModal from "../../../../Components/ErrorModal";


const useStyles = makeStyles(styles);

export default function AlignmentOfCLOWithTMAndAMList({ match }) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { id } = match.params;
  const dispatch = useDispatch();

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const AlignmentOfCLOWithTMAndAMs = useSelector(
    (state) => state.AlignmentOfCLOWithTMAndAM.AlignmentOfCLOWithTMAndAMs
  );
  console.log("course to be updated", course);

  const courseStatus = useSelector(
    (state) => state.CourseSpec.courseSpecificationStatus
  );
  const error = useSelector((state) => state.course.error);
  console.log("course", course);
  console.log("AlignmentOfCLOWithTMAndAMs", AlignmentOfCLOWithTMAndAMs);
  // if (!course) {
  //     return (
  //         <section>
  //             <h2>Course not found!</h2>
  //         </section>
  //     )
  // }
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllAlignmentOfCLOWithTMAndAMs = async () => {
    try {
      const resultAction = await dispatch(
        fetchAlignmentOfCLOWithTMAndAMs(id)
      ).unwrap();
      dispatch(fetchCourseById(id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchAllAlignmentOfCLOWithTMAndAMs();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
         

          <Breadcrumb.Item>
            <Link to={`/citrine/CourseSpecificationList/`}>
              {t("Courses List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseSpecDetails/${course && course.id && course.id
                }`}
            >
              {course && course.title && course.title}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/specification/course/${course && course.id && course.id
                }/AlignmentOfCLOWithTMAndAMs`}
              style={{ color: "orange" }}
            >
              {" "}
              {t("Alignment Of CLO With TS And AM List ")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridContainer>
        <GridItem>
          {disabledStatus ? (
            <p></p>
          ) : (
            <Link
              to={`/citrine/specification/course/${course && course.id && course.id
                }/AlignmentOfCLOWithTMAndAMs/create`}
            >
              <Button color="info" className={classes.updateProfileButton}>
                {t("Add")}
              </Button>
            </Link>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer>
        {AlignmentOfCLOWithTMAndAMs &&
          AlignmentOfCLOWithTMAndAMs.map((Element) => (
            <GridItem key={Element.id} xs={12} sm={12} md={4}>
              <Card style={{ height: 200, textAlign: "start" }}>
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={10} sm={10} md={10}>
                      <h4
                        className={classes.cardProductTitle}
                        style={{ textAlign: "start" }}
                      >
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          {t("Teaching Strategies")} :{" "}
                          {Element &&
                            Element.teaching_strategy_details &&
                            Element.teaching_strategy_details[0] &&
                            Element.teaching_strategy_details[0].name}
                        </a>
                      </h4>
                      <h4
                        className={classes.cardProductTitle}
                        style={{ textAlign: "start" }}
                      >
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          {t("Code Level")} : {Element && Element.code_level}
                        </a>
                      </h4>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2} style={{ zIndex: 1 }}>
                      {disabledStatus ? <p></p> :
                        <DropDownACWTMAM
                          AlignmentOfCLOWithTMAndAM_ID={Element && Element.id}
                          courseID={course && course.id}
                        />
                      }
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
