import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const ComputingResources = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>FACILITIES: Computing Resources</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of any computing resources (workstations,
        servers, storage, networks including software), in addition to those
        described in the laboratories in the previous section, which are used by
        the students in the program. The following also:
      </p>
      <ul>
        <li>
          discusses the accessibility of university-wide computing resources
          available to all students via various locations such as student
          housing, library, student union, off-campus,
        </li>
        <li>
          states the hours the various computing facilities are open to
          students.
        </li>
        <li>
          Assess the adequacy of these facilities to support the scholarly and
          professional activities of the students and faculty in the program.
        </li>
      </ul>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
    </>
  );
};

export default ComputingResources;
