import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  addCourseSection,
  DeleteSelectedCoursePlanning,
  fetchCourses,
} from "reduxToolkit/features/course/CoursesSlice.js";
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import AddCoordinator from "./AddCoordinator";
import AddCourse from "./AddCourse.jsx";
import AddSectionConfirmation from "./AddSectionConfirmation";
import AddTemplate from "./AddTemplate";
import UpdateDates from "./updateDates.jsx";
import DeactivateCourse from "./DeactivateCourse";
import UpdateCLOSuccessCriteria from "./UpdateCLOSuccessCriteria"
const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

export default function DropDownPlanningCourseAssessment(props) {

  const { t } = useTranslation();
  const buttonClasses = useButtonStyles();

  const [open, setOpen] = useState(false);
  const [openSection, setOpenSection] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleOpenSectionModal = () => {
    setOpenSection(true);
  };

  const handleCloseSectionModal = () => {
    setOpenSection(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleDelete = async () => {
    if (props.selectedRows.length === 1) {
      let courses_delete_ids = props.selectedRows.toString();
      dispatch(DeleteSelectedCoursePlanning({ courses_delete_ids }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          // handle result here
          openNotificationWithIcon("success", `Deleted`);
          props.refreshCourses();
          dispatch(fetchCourses());
        })
        .catch((rejectedValueOrSerializedError) => {
          setHandledError(rejectedValueOrSerializedError);
          handleOpenErrorModal();
        });
    }
    else {
      let courses_delete_ids = props.selectedRows.join();
      dispatch(DeleteSelectedCoursePlanning({ courses_delete_ids }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          openNotificationWithIcon("success", `Deleted`);
          props.refreshCourses();
          dispatch(fetchCourses());
        })
        .catch((rejectedValueOrSerializedError) => {
          setHandledError(rejectedValueOrSerializedError);
          handleOpenErrorModal();
        });
    }
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const addSection = async (id) => {
    const resultAction = await dispatch(addCourseSection({ id }));
    if (addCourseSection.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Added`);
      handleClose();
      refreshCourses();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  const refreshCourses = () => {
    dispatch(fetchCourses())
      .then(unwrapResult)
      .then((courses) => {
        setRows(courses);
      });
    setSelectionModel([]);
  };
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
        style={{ marginRight: "50px" }}
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <UpdateDates
          disabled={!props.selectedRows.length}
          handleDropDownButtonClose={handleClose}
          refreshCourses={props.refreshCourses}
          selectedRows={props.selectedRows}
        />
        <AddCoordinator
          handleDropDownButtonClose={handleClose}
          disabled={props.selectedRows.length > 1 || props.selectedRows.length === 0}
          refreshCourses={props.refreshCourses}
          selectedRows={props.selectedRows}
          coordinator={props.coordinator}
          theory={props.theory}
        />
        <AddTemplate
          handleDropDownButtonClose={handleClose}
          disabled={!props.selectedRows.length}
          refreshCourses={props.refreshCourses}
          selectedRows={props.selectedRows}
        />
        <UpdateCLOSuccessCriteria
          disabled={props.selectedRows.length > 1 || props.selectedRows.length === 0}
          handleDropDownButtonClose={handleClose}
          refreshCourses={props.refreshCourses}
          selectedRows={props.selectedRows}
        />
        <AddSectionConfirmation
          handleDropDownButtonClose={handleClose}
          disabled={!props.selectedRows.length}
          refreshCourses={props.refreshCourses}
          delete_Element={addSection}
          selectedRows={props.selectedRows}
        />
        <AddCourse
          handleDropDownButtonClose={handleClose}
          refreshCourses={props.refreshCourses}
          program_id={props.program_id}
          semester_id={props.semester_id}
        />
        <DeactivateCourse
          handleDropDownButtonClose={handleClose}
          refreshCourses={props.refreshCourses}
          selectedRows={props.selectedRows}
        />
        <MenuItem
          disabled={!props.selectedRows.length}
          onClick={() => {
            handleOpenDeleteModal();
            handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDelete}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
}
