import React from "react";

import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
  Summary,
  TotalItem,
  Export
} from "devextreme-react/data-grid";
import CheckBox from "devextreme-react/check-box";
import { analysis, details, allAreasAnalysis } from "./data.js";
import {
  grayColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import { Tabs } from "antd";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
const { TabPane } = Tabs;

function SeparateAnalysis() {
  const TPN = `Total participants number= ${details[0].TotalParticipantsNumber}`;
  const TSR = `Total satisfaction rate= ${details[0].totalSatisfactionRate}`;

  const onExporting=(e,area)=> {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${area}.xlsx`);
      });
    });
    e.cancel = true;
  }

  const renderColumnHeader = (data) => {
    return (
      <p style={{ fontWeight: "bold", color: primaryColor[0] }}>
        {data.column.caption}
      </p>
    );
  };
  const content = allAreasAnalysis.map((areaAnalysis) => {
    return (
      <div>
        <h3
          style={{
            fontWeight: "bold",
            color: primaryColor[0],
            textAlign: "center",
          }}
        >
          {areaAnalysis.area}
        </h3>
        <DataGrid
          dataSource={areaAnalysis.analysis}
          keyExpr="ID"
          showBorders={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          columnMinWidth={100}
          columnAutoWidth={true}
          onExporting={(e)=>{onExporting(e,areaAnalysis.area)}}

        >
          <Column
            dataField="question"
            dataType="string"
            width={300}
            headerCellRender={renderColumnHeader}
          />
          <Column
            dataField="stronglyAgree"
            dataType="string"
            headerCellRender={renderColumnHeader}
          />
          <Column
            dataField="agree"
            dataType="string"
            headerCellRender={renderColumnHeader}
          />
          <Column
            dataField="disagree"
            dataType="string"
            headerCellRender={renderColumnHeader}
          />
          <Column
            dataField="stronglyDisagree"
            dataType="string"
            headerCellRender={renderColumnHeader}
          />
          <Column
            dataField="notApplicable"
            dataType="string"
            headerCellRender={renderColumnHeader}
          />
          <Column
            dataField="answered"
            dataType="number"
            alignment="left"
            headerCellRender={renderColumnHeader}
          />
          <Column
            dataField="average"
            dataType="number"
            alignment="left"
            headerCellRender={renderColumnHeader}
          />

          <Column
            dataField="subarea"
            dataType="string"
            groupIndex={0}
            headerCellRender={renderColumnHeader}
          />

          <Summary>
            <TotalItem column="average" summaryType="avg" />
          </Summary>
          
          <Export enabled={true}/>

        </DataGrid>
      </div>
    );
  });

  return (
    <div>
      <Tabs type="card">
        <TabPane tab="Summary" key="1">
          <DataGrid
            dataSource={details}
            keyExpr="ID"
            showBorders={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            columnMinWidth={50}
            columnAutoWidth={true}
          >
            <Column
              dataField="studentsNumber"
              caption="Students number"
              alignment="left"
              headerCellRender={renderColumnHeader}
            />
            <Column
              dataField="TotalParticipantsNumber"
              caption={TPN}
              alignment="left"
              headerCellRender={renderColumnHeader}
            >
              <Column
                dataField="localParticipantsNumber"
                caption="Local participants number"
                alignment="left"
                headerCellRender={renderColumnHeader}
              />
              <Column
                dataField="foreignParticipantsNumber"
                caption="Foreign participants number"
                alignment="left"
                headerCellRender={renderColumnHeader}
              />
            </Column>
            <Column
              dataField="responseRate"
              caption="Response rate"
              alignment="left"
              headerCellRender={renderColumnHeader}
            />

            <Column
              dataField="totalSatisfactionRate"
              caption={TSR}
              alignment="left"
              headerCellRender={renderColumnHeader}
            >
              <Column
                dataField="localSatisfactionRate"
                caption="Local satisfaction rate"
                alignment="left"
                headerCellRender={renderColumnHeader}
              />
              <Column
                dataField="foreignSatisfactionRate"
                caption="Foreign satisfaction rate"
                alignment="left"
                headerCellRender={renderColumnHeader}
              />
            </Column>

            <Column
              dataField="average"
              caption="Average"
              alignment="left"
              headerCellRender={renderColumnHeader}
            />
            <Column
              dataField="weightedAverage"
              caption="Weighted average"
              alignment="left"
              headerCellRender={renderColumnHeader}
            />

            {/* <Summary>
          <TotalItem
            column="TotalParticipantsNumber"
            summaryType="sum"
          />

        </Summary> */}
          </DataGrid>
        </TabPane>
        <TabPane tab="Local participants" key="2">
          {content}
        </TabPane>
        <TabPane tab="Foreign participants" key="3">
          {content}
        </TabPane>
        <TabPane tab="All participants" key="4">
          {content}
        </TabPane>
      </Tabs>
    </div>
  );
}

export default SeparateAnalysis;
