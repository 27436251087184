import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import {Breadcrumb} from 'antd'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { grayColor, primaryColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchUniversities, selectAllUniversities,deleteUniversity } from '../../../../reduxToolkit/features/university/UniversitiesSlice';
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { Link, useParams } from 'react-router-dom'

import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import { unwrapResult } from '@reduxjs/toolkit'
import { notification } from 'antd'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import ErrorModal from 'views/Components/ErrorModal'


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const UniversityExcerpt = ({ university }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()

  return (
    <GridItem xs={12} sm={6} md={4}>
      <Card style={{ height: 300 }} >
        <CardHeader>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Link to={`/citrine/organizations/universities/${university.id}`} >
                <h3 style={{ color: `${primaryColor[0]}` }}>
                  {university.name}
                </h3>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}><DropDownUniversity id={university.id}/></GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem>
              <h4>
                {`${university.mission.substring(0, 100)}...`}
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem>
              <Link to={`/citrine/organizations/universities/${university.id}`} ><Button color='primary'>View</Button></Link>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function UniversitiesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const universitiesStatus = useSelector(state => state.university.universitiesStatus)

  useEffect(() => {
    if (universitiesStatus === 'idle') {
      dispatch(fetchUniversities())
    }
  }, [universitiesStatus, dispatch])

  const universities = useSelector(selectAllUniversities)

  const error = useSelector(state => state.university.error)

  let content

  if (universitiesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (universitiesStatus === 'succeeded') {
    content = universities && universities.map(university => (
      <UniversityExcerpt key={university.id} university={university} />
    ))
  } else if (universitiesStatus === 'failed') {
    content = <div>An error has accured</div>
  }

  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
        <Breadcrumb separator=">">
            
            <Breadcrumb.Item>
              <Link to={`/citrine/organizations`}>
                <span > {t('Organizations')} </span>
              </Link>
            </Breadcrumb.Item>
            
            <Breadcrumb.Item>
            <Link to={`/citrine/organizations/universities`}>
                <span > {t('Universities')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          {/* <span className={titleClasses.title}>
            <Link to={`/citrine/organizations`}>{t('Organizations')}</Link>
          </span>
          <span className={titleClasses.title}>
            <Link to={`/citrine/organizations/universities`}>{t('/Universities')}</Link>
          </span> */}
        </GridItem>
      </GridContainer>
      <Link to="/citrine/organizations/universities/create/:id" >
        <Button color="primary">
          {t(' Add')}
        </Button>
      </Link>
      <GridContainer xs={12} sm={12} md={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {
          content
        }
      </GridContainer>
    </div>
  );
}

const DropDownUniversity = ({ id }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleDeleteItem = async (id) => {
    console.log('id : ', id)
    dispatch(deleteUniversity(id))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchUniversities())
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />{' '}
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
