import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
// core components
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";
import { Select, Form, notification } from "antd";
import "antd/dist/antd.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "antd";

import { useTranslation } from "react-i18next";
import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import ErrorModal from "../Components/ErrorModal.jsx"
import {
  warningColor,
} from "../../assets/jss/material-dashboard-pro-react";
import {
  FormOutlined,
} from "@ant-design/icons";
import {updateFeedbackSurvey , getFeedbackById ,
    fetchEvaluationList
} from "../../reduxToolkit/features/CourseEvaluation/CourseEvaluationSlice"

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useStyles4 = makeStyles(styles4);

export default function EditFeedbackSurvey(props) {

  const { Option } = Select;

  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();

  const clo= useSelector((state) => state.clo.clo);
  const feedbackbyid = useSelector((state) => state.CourseEvaluation.feedBackById)

  const [type, setType] = useState("SR");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const history = useHistory();
  const [form] = Form.useForm();

  const classes4 = useStyles4();

  useEffect(() => {
   dispatch(getFeedbackById(props.idFeedback)).then(unwrapResult)
   .then((clo) => {
     form.setFieldsValue({
        comments_response:
       clo && clo.comments_response
     })
   })
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const updated = { ...values };
    // let obj = {
    //   course_id: props.idCourse,
    // };
    const resultAction = await dispatch(
    updateFeedbackSurvey({
        id:props.idFeedback,
        updatedFeedback: updated
    })
    );
    if (updateFeedbackSurvey.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Edit successfully`);
    //   dispatch(fetchCloResults(obj));
      dispatch(fetchEvaluationList(props.obj));
    //   props.fetchCloResultsMethod();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FormOutlined
        onClick={(event) => {
          handleClickOpen(event);
        }}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {t("Edit Comment")}
        </DialogTitle>
        <DialogContent>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="comments_response"
              label={t("Comments")}
              rules={[
                {
                  required: true,
                  message: "Please input the Comments field.",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: "20px" }}
                color="danger"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="primary" color="info" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
    </div>
  );
}
