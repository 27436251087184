import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const NonAcademicSupportUnits = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>Institutional Summary: Non-academic Support Units</h3>

      <p className={styles.reportParagraphTitle}>
        The following is the List of the individuals responsible for each of the
        units that provide non-academic support to the program being evaluated,
        e.g., library, computing facilities, placement, tutoring, etc:
      </p>
      <ul>
        {data?.NonAcademicSupportUnits?.map((unit, index) => (
          <li
            key={index}
          >{`${unit?.unit}, ${unit?.responsible?.name}, ${unit?.responsible?.title}`}</li>
        ))}
      </ul>
    </>
  );
};

export default NonAcademicSupportUnits;
