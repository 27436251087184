import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  courses: [],
  coursesByPage: [],
  DeactivateCourses: [],
  coursesStatus: 'idle',
  DeactivateCoursesStatus: 'idle',
  course: null,
  courseStatus: 'idle',
  error: null,
  notGeneratedCoursesByProgram: [],
  notGeneratedCoursesByProgramStatus: 'idle',
  coursesByUserAndSemester: [],
  DeleteSelectedCoursePlanning: [],
  DeleteSelectedCoursePlanningStatus: 'idle',

}
export const fetchCourseByProgramNextPage = createAsyncThunk(
  "CourseSpecification/fetchCourseByProgramNextPage",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      //  console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchCoursesByPage = createAsyncThunk('course/fetchCoursesByPage', async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/course/ByPage/?page=${data.page}&records=${data.records}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    //console.log(response.data,'response.data course')

    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

export const fetchCourses = createAsyncThunk('course/fetchCourses', async (params, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/course/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    //console.log(response.data,'response.data course')

    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

export const fetchCoursesByUserAndSemester = createAsyncThunk('course/fetchCoursesByUserAndSemester', async (params, { rejectWithValue }) => {
  const semester_id = localStorage.getItem('semester_id');
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/course/bySemestre/?semester_id=${semester_id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    //console.log(response.data,'response.data course')

    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

export const fetchDeactivateCourses =
  createAsyncThunk('course/fetchDeactivateCourses', async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/course/deactivate/?program_id=${id}`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })

      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
  );


export const fetchCoursesByProgramByPage =
  createAsyncThunk('course/fetchCoursesByProgramByPage', async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/course/ByProgram/?program_id=${data.program_id}&page=${data.page}&records=${data.records}`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })

      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
  );

export const UpdateDeactivateCoursePlanning = createAsyncThunk(
  'course/UpdateDeactivateCoursePlanning',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.post(BaseUrl + `/course/deactivate/update/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


export const fetchCourseById =
  createAsyncThunk('course/fetchCourseById', async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/course/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      //console.log(response.data,'response.data course')

      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
  );

export const fetchNotGeneratedCoursesByProgram = createAsyncThunk(
  'course/fetchNotGeneratedCoursesByProgram',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/course/not_generated/?program_id=${id}`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const courseUpdate = createAsyncThunk(
  'course/courseUpdate',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/course/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateCoursePlanning = createAsyncThunk(
  'course/updateCoursePlanning',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/course/${data.id}/update/planning/`, data.updated, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const updateCourseDevelopmentalRecommendations = createAsyncThunk(
  'course/updateCourseDevelopmentalRecommendations',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/course/${data.id}/developmental/update/`, data.updated, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


export const affectTemplateToCourse = createAsyncThunk(
  'course/affectTemplateToCourse',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/course/${data.id}/template/planning/`, data.updated, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)
export const addCourseSection = createAsyncThunk(
  'course/addCourseSection',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.post(BaseUrl + `/course/section/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const regenerateCourse = createAsyncThunk(
  'course/regenerateCourse',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.post(BaseUrl + `/course/generate/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const courseUpdateToGroupAssessment = createAsyncThunk(
  'course/courseUpdateToGroupAssessment',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/course/${data.id}/update/`, data.modifiedCourse, {
        headers: {
          'Authorization': `token ${token}`
        }
      })

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



// export const updateSelectedCoursePlanning = createAsyncThunk(
//   'course/updateSelectedCoursePlanning',
//   async (data, { rejectWithValue }) => {
//     const token = localStorage.getItem('token')

//     try {
//       const response = await axios.post(BaseUrl + `/course/update/planning/`, data.updated, {
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })
//       console.log(response.data, 'response.data')
//       return response.data

//     } catch (err) {
//       let error = err // cast the error for access
//       if (!error.response) {
//         throw err
//       }
//       // We got validation errors, let's return those so we can reference in our component and set form errors
//       console.log('error.response from inside the CAT', error.response)
//       return rejectWithValue(error.response)
//     }
//   }
// )

export const updateSelectedCoursesPlanning = createAsyncThunk(
  'course/updateSelectedCoursesPlanning',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.post(BaseUrl + `/course/planning/updateSelected/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const addLabInstructorCoursesPlanning = createAsyncThunk(
  'course/addLabInstructorCoursesPlanning',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.post(BaseUrl + `/course/addLabInstructor/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const addTemplate = createAsyncThunk(
  'course/addTemplate',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.post(BaseUrl + `/course/addTemplate/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const addCoordinatorAndTheory = createAsyncThunk(
  'course/addCoordinatorAndTheory',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.post(BaseUrl + `/course/addCoordinatorAndTheory/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)
/****************************************************************************
 * 
 * 
 * 
 * 
 **************************************************************************/
export const addCoordinatorToCourseAss = createAsyncThunk(
  'course/addCoordinatorToCourseAss',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/course/update/${data.courses_ids}/`, data.data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)
/************************************************************************** */
export const DeleteSelectedCoursePlanning = createAsyncThunk(
  'course/DeleteSelectedCoursePlanning',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.delete(BaseUrl + `/course/planning/delete/${data.courses_delete_ids}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response, 'response')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


export const CalculScoreCourse = createAsyncThunk(
  "clo/CalculScoreCourse",
  async (initialClo, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + `/calculationScoreByCourse/create/`, initialClo, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


export const updateCLOSuccessCriteria = createAsyncThunk(
  "course/updateCLOSuccessCriteria",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(BaseUrl + `/course/${data.course_id}/update_clo_Success_Criteria/`, data.CLOSuccessCriteria, {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const rerunCalculations = createAsyncThunk(
  "course/rerunCalculations",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + `/scoreCourseByQuestion/run_clo/`, data, {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

const CoursesSlice = createSlice({
  name: ' course',
  initialState,
  extraReducers: {
    [fetchCourseByProgramNextPage.pending]: (state, action) => {
      state.coursesStatus = "loading";
    },
    [fetchCourseByProgramNextPage.fulfilled]: (state, action) => {
      state.coursesStatus = "succeeded";
      state.coursesByPage = action.payload;
    },
    [fetchCourseByProgramNextPage.rejected]: (state, action) => {
      state.coursesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchCoursesByPage.pending]: (state, action) => {
      state.coursesStatus = 'loading'
    },
    [fetchCoursesByPage.fulfilled]: (state, action) => {
      state.coursesStatus = 'succeeded'

      // Add any fetched posts to the array
      state.coursesByPage = action.payload
    },
    [fetchCoursesByPage.rejected]: (state, action) => {
      state.coursesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchCourses.pending]: (state, action) => {
      state.coursesStatus = 'loading'
    },
    [fetchCourses.fulfilled]: (state, action) => {
      state.coursesStatus = 'succeeded'

      // Add any fetched posts to the array
      state.courses = action.payload
    },
    [fetchCourses.rejected]: (state, action) => {
      state.coursesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchCoursesByUserAndSemester.pending]: (state, action) => {
      state.coursesStatus = 'loading'
    },
    [fetchCoursesByUserAndSemester.fulfilled]: (state, action) => {
      state.coursesStatus = 'succeeded'

      // Add any fetched posts to the array
      state.coursesByUserAndSemester = action.payload
    },
    [fetchCoursesByUserAndSemester.rejected]: (state, action) => {
      state.coursesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchDeactivateCourses.pending]: (state, action) => {
      state.DeactivateCoursesStatus = 'loading'
    },
    [fetchDeactivateCourses.fulfilled]: (state, action) => {
      state.DeactivateCoursesStatus = 'succeeded'

      // Add any fetched posts to the array
      state.DeactivateCourses = action.payload
    },
    [fetchDeactivateCourses.rejected]: (state, action) => {
      state.DeactivateCoursesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCoursesByProgramByPage.pending]: (state, action) => {
      state.coursesStatus = 'loading'
    },
    [fetchCoursesByProgramByPage.fulfilled]: (state, action) => {
      state.coursesStatus = 'succeeded'

      // Add any fetched posts to the array
      state.coursesByPage = action.payload
    },
    [fetchCoursesByProgramByPage.rejected]: (state, action) => {
      state.coursesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },


    [fetchCourseById.pending]: (state, action) => {
      state.courseStatus = 'loading'
    },
    [fetchCourseById.fulfilled]: (state, action) => {
      state.courseStatus = 'succeeded'

      // Add any fetched posts to the array
      state.course = action.payload
    },
    [fetchCourseById.rejected]: (state, action) => {
      state.courseStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchNotGeneratedCoursesByProgram.pending]: (state, action) => {
      state.notGeneratedCoursesByProgramStatus = 'loading'
    },
    [fetchNotGeneratedCoursesByProgram.fulfilled]: (state, action) => {
      state.notGeneratedCoursesByProgramStatus = 'succeeded'
      // Add any fetched posts to the array
      state.notGeneratedCoursesByProgram = action.payload
    },
    [fetchNotGeneratedCoursesByProgram.rejected]: (state, action) => {
      state.notGeneratedCoursesByProgramStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [courseUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [UpdateDeactivateCoursePlanning.fulfilled]: (state, action) => {
      console.log(action)
    },


    [courseUpdateToGroupAssessment.fulfilled]: (state, action) => {
      console.log(action)
    },
    [addLabInstructorCoursesPlanning.fulfilled]: (state, action) => {
      console.log(action)
    },


    [updateCourseDevelopmentalRecommendations.fulfilled]: (state, action) => {
      console.log(action)
    },
    [updateCourseDevelopmentalRecommendations.fulfilled]: (state, action) => {
      console.log(action)
    },



    [addLabInstructorCoursesPlanning.rejected]: (state, action) => {

      if (action.payload) {

        state.error = action.payload

      } else {
        state.error = action.error

      }
    },
    [addCoordinatorAndTheory.fulfilled]: (state, action) => {
      console.log(action)
    },
    [addCoordinatorAndTheory.rejected]: (state, action) => {

      if (action.payload) {

        state.error = action.payload

      } else {
        state.error = action.error

      }
    },
    [addTemplate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [addTemplate.rejected]: (state, action) => {

      if (action.payload) {

        state.error = action.payload

      } else {
        state.error = action.error

      }
    },
    [DeleteSelectedCoursePlanning.pending]: (state, action) => {
      state.DeleteSelectedCoursePlanningStatus = 'loading'
    },
    [DeleteSelectedCoursePlanning.fulfilled]: (state, action) => {
      state.DeleteSelectedCoursePlanningStatus = 'succeeded'
      // Add any fetched posts to the array
      state.DeleteSelectedCoursePlanning = action.payload
    },
    [DeleteSelectedCoursePlanning.rejected]: (state, action) => {
      state.DeleteSelectedCoursePlanningStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
  reducers: {
    courseUpdated(state, action) {
      const { id, title, code, academic_year, level, credit_hours, description, main_objectives, student_Academic_Counseling_and_Support, program_id, department_id, university_id, person_id, prerequisites, corequisites, coordinator_id, instructor_id } = action.payload
      const existingCourse = state.courses.find(course => course.id === id)
      if (existingCourse) {
        existingCourse.title = title
        existingCourse.code = code
        existingCourse.academic_year = academic_year
        existingCourse.level = level
        existingCourse.credit_hours = credit_hours
        existingCourse.description = description
        existingCourse.main_objectives = main_objectives
        existingCourse.student_Academic_Counseling_and_Support = student_Academic_Counseling_and_Support
        existingCourse.program_id = program_id
        existingCourse.department_id = department_id
        existingCourse.university_id = university_id
        existingCourse.person_id = person_id
        existingCourse.prerequisites = prerequisites
        existingCourse.corequisites = corequisites
        existingCourse.coordinator_id = coordinator_id
        existingCourse.instructor_id = instructor_id

      }
    },
    courseUpdatedToGroupAssessment(state, action) {
      const { id, groupAssessment_ids } = action.payload
      const existingCourse = state.courses.find(course => course.id === id)
      if (existingCourse) {
        existingCourse.groupAssessment_ids = groupAssessment_ids


      }
    }
  }
})

export const { courseUpdated, courseUpdatedToGroupAssessment } = CoursesSlice.actions

export default CoursesSlice.reducer
