import {
    DeleteOutlined
} from "@ant-design/icons";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteComments, fetchCommentsOnAnnualProgramByProgramAndType } from "reduxToolkit/features/Comments/CommentsSlice";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import AddCommentsOnAnnualProgram from "../../../Components/AddCommentsOnAnnualProgram";
import useCommentsTable from "../../../Components/useCommentsTable";
import "../improvement.css";

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "fit-content",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        "& svg": {
            margin: theme.spacing(1.5),
        },
        "& hr": {
            margin: theme.spacing(0, 0.5),
        },
    },
}));
const useStyles1 = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const headCells = [
    { id: "Comments", label: "Comments" },
    { id: "delete", label: "" },
];

export default function Comments(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const classes1 = useStyles1();
    const classes2 = useStyles2();
    const classes3 = useStyles3();
    const dispatch = useDispatch();
    const [records, setRecords] = [];

    const [filterFn, setFilterFn] = useState({
        fn: (items) => {
            return items;
        },
    });
    const [array_comments, setArray_comments] = useState([]);
    const [pageSize, setPageSize] = useState(10);

    const comments = useSelector((state) => state.CommentsOnAnnualProgram.CommentsOnAnnualProgramByProgramAndType);

    const { TblContainer, TblHead } = useCommentsTable(records, headCells, filterFn);
    let program_id =
        props.AnnualProgramReport &&
        props.AnnualProgramReport.programs &&
        props.AnnualProgramReport.programs[0] &&
        props.AnnualProgramReport.programs[0].id;
    let object = {
        resource: 'AnnualProgramReport',
        resource_id: program_id,
        type: props.type,
    }

    useEffect(() => {
        dispatch(fetchCommentsOnAnnualProgramByProgramAndType(object))
            .then(unwrapResult)
            .then((comments) => {
                setArray_comments([...comments]);
            });
    }, []);

    const fetchAnalysis = () => {
        dispatch(fetchCommentsOnAnnualProgramByProgramAndType(object))
            .then(unwrapResult)
            .then((comments) => {
                setArray_comments([...comments]);
            });
    }

    const DeleteCommentsOnAnnualProgram = (id) => {
        dispatch(deleteComments(id))
            .then(unwrapResult)
            .then(() => {
                dispatch(fetchCommentsOnAnnualProgramByProgramAndType(object)).then(unwrapResult)
                    .then((comments) => {
                        setArray_comments([...comments]);
                    });
            });
    };
    let resource = ""
    return (
        <div>
            <GridContainer xs={12} sm={12} md={12}>
                <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
                <GridItem key={Element.id} xs={12} sm={12} md={1}>
                    <AddCommentsOnAnnualProgram
                        object={object} fetchAnalysis={fetchAnalysis}
                    />
                </GridItem>
            </GridContainer>

            <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
                <TblHead />
                <TableBody>
                    {array_comments &&
                        array_comments.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell
                                    style={{
                                        borderLeft: "1px solid lightgray",
                                        borderRight: "1px solid lightgray",
                                    }}
                                >
                                    <p
                                        style={{
                                            display: "-webkit-box",
                                            maxWidth: "300px",
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {item && item.Comment}
                                    </p>
                                </TableCell>

                                <TableCell
                                    style={{
                                        borderRight: "1px solid lightgray"
                                    }}
                                >
                                    <DeleteOutlined

                                        onClick={() => DeleteCommentsOnAnnualProgram(item && item.id)}
                                        style={{ color: "red" }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </TblContainer>
        </div>
    );
}
