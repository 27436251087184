import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DataGrid } from "@material-ui/data-grid";
import { Form, Input, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import clsx from "clsx";
import {addNewCourseSpecPerformancePlo} from "reduxToolkit/features/PLO/CourseSpecPerformancePlo.jsx";
import {
  fetchPlosByProgramId,
} from "reduxToolkit/features/PLO/PLOSpecSlice";

import ErrorModal from "../../../Components/ErrorModal.jsx";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function PLOAddCourses(props) {
  const { t } = useTranslation();
  const { Option } = Select;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [courseSpec_id, setCourseSpec_id] = useState([]);
  const [performance, setPerformance] = useState([]);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async () => {
       const resultAction = await dispatch(
        addNewCourseSpecPerformancePlo({
          ploSpec_id: props.plo.id,
          courseSpec_id,
          performance
        })
      );
  
      if (addNewCourseSpecPerformancePlo.fulfilled.match(resultAction)) {
        // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
        const plo = resultAction.payload;
        dispatch(fetchPlosByProgramId(props.programId));
        openNotificationWithIcon("success", `update successfully!`);
        handleClose();
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
          handleClose();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
          handleClose();
        }
       } 
  };
  function onChangeCourses(value) {
    setCourseSpec_id(value);
  }
  function onChangePerformance(value) {
    setPerformance(value);
  }
  //console.log(selectedCourses)

  return (
    <div>
      <MenuItem
        onClick={() => {
          handleClickOpen();
          props.handleDropDownButtonClose();
        }}
      >
        <ListItemText primary="Add Course" />
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Add Course")}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Course")} name="courseSpec_id">
                      <Select
                        showSearch
                        showArrow
                        style={{ width:'100%' }}
                        optionFilterProp="children"
                        name="courseSpec_id"
                        value={courseSpec_id}
                        dropdownStyle={{ zIndex: 10000 }}
                        onChange={onChangeCourses}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.courses &&
                          props.courses.map((c) => (
                            <Option key={c.id} value={c.id}>
                              {c.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Performance")} name="performance">
                      <Select
                        showSearch
                        showArrow
                        style={{ width:'100%' }}
                        optionFilterProp="children"
                        name="performance"
                        value={performance}
                        dropdownStyle={{ zIndex: 10000 }}
                        onChange={onChangePerformance}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                            <Option key={1} value={"Introduced"}>
                              {"Introduced"}
                            </Option>
                            <Option key={2} value={"Practiced"}>
                              {"Practiced"}
                            </Option>
                            <Option key={3} value={"Mastered"}>
                              {"Mastered"}
                            </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
