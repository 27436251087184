import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, InputNumber, notification, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import ErrorModal from 'views/Components/ErrorModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAccessControlModels,
  fetchModels,
  fetchModelById,
} from 'reduxToolkit/features/model/ModelSlice'
import {
  addNewSection,
  updateSection,
} from 'reduxToolkit/features/Section/SectionSlice'
const { Option, OptGroup } = Select

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const useButtonStyles = makeStyles(buttonStyles)

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
const SectionModal = ({
  openSectionModal,
  handleClose,
  disabled,
  selected,
  ping,
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [sectionMethod, setSectionMethod] = useState('post')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const model = useSelector((state) => state.model.model)

  const onFinish = (values) => {
    console.log({ ...values, id: selected })

    dispatch(addNewSection({ ...values, model: selected }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchModels())
          .unwrap()
          .then((res) => {
            console.log(res)
            form.resetFields()
          })
          .catch((err) => {
            console.log(err)
          })
        openNotificationWithIcon('success', `Added Successfully`)
        handleClose()
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  // useEffect(() => {
  //   dispatch(fetchModelById(selected))
  //     .unwrap()
  //     .then(({ sections }) => {
  //       console.log(sections[0])
  //       form.setFieldsValue({
  //         name: sections[0].name,
  //         code: sections[0].code,
  //         Directfield: sections[0].Directfield,
  //         ChildObjects: sections[0].ChildObjects,
  //       })
  //     })
  //     .catch((err) => {
  //       setSectionMethod('put')
  //       console.log(err)
  //     })
  // }, [ping])

  return (
    <Dialog
      open={openSectionModal}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <center> {t('Add Section')}</center>
      </DialogTitle>
      <DialogContent style={{ width: '600px' }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name field.',
                  },
                ]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="code"
                label="Code"
                rules={[
                  {
                    required: true,
                    message: 'Please input the code field.',
                  },
                ]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                name="Directfield"
                label="Direct Field"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input the directField.',
                //   },
                // ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Direct Field"
                  dropdownStyle={{ zIndex: 10000 }}
                >


                  {model &&
                    model.Directfield &&
                    model?.Directfield?.map((r, index) => (
                      <Option key={index} value={r}>
                        {r}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="ChildObjects"
                label="Child Field"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input the childField.',
                //   },
                // ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Child Field"
                  dropdownStyle={{ zIndex: 10000 }}
                >
                  {model &&
                    model.ChildObjects &&
                    model?.ChildObjects?.map((r, index) => (
                      <Option key={index} value={r}>
                        {r}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  style={{ marginRight: '20px' }}
                  color="danger"
                  onClick={() => {
                    form.resetFields()
                    handleClose()
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" color="info">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </Dialog>
  )
}

export default SectionModal
