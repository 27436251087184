import React, { useEffect, useState, useRef, useCallback } from "react";

import moment from "moment";

import Typography from "@material-ui/core/Typography";
// core components
import { Breadcrumb, Table, notification } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchAssessmentQuestionsMethods } from "../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import DropDownQuestionAssessment from "../../../Components/DropDownQuestionAssessment";
import EditAssessmentDates from "../../../Components/EditAssessmentDates";

import ErrorModal from "../../../Components/ErrorModal";
import AddAssessmentMethodModal from "./AddAssessmentMethodModal";
import { fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import { fetchCourseInstructorById } from "reduxToolkit/features/CourseInstructor/CourseInstructorSlice";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import saveAs from "file-saver";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import { MediaBaseUrl, MediaBaseUrlAvatar } from "assets/utils/accessToken";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  primaryColor,
  greyColor,
} from "assets/jss/material-dashboard-pro-react";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Button from "components/CustomButtons/Button";
import { loadLMSImportedMarks } from "reduxToolkit/features/LMSIntegration/LMSIntegrationSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(styles);

const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "rgb(0,0,0,0.7)",
    display: "flex",
    flexDirection: "column",
  },
}));

const useAvatarStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
const AssessmentQuestionMethodExcerpt = ({
  assessmentQuestionsMethod,
  program,
  course_id,
  course,
  semester_id,
  loadMarksFromLMSHandler,
}) => {
  const loadMarksIsEnabled =
    assessmentQuestionsMethod?.id &&
    assessmentQuestionsMethod?.course_id_lms &&
    course_id &&
    semester_id &&
    assessmentQuestionsMethod?.clos_list?.length > 0 &&
    assessmentQuestionsMethod?.assessmentActivities?.id_lms;

  const classes = useStyles();

  let { ProgramId } = useParams();

  const [showQuestions, setShowQuestions] = React.useState(false);
  const [showInstructors, setShowInstructors] = React.useState(false);
  const [showDates, setShowDates] = React.useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      name: "question",
      width: 200,
    },
    {
      title: "Clo",
      dataIndex: "code",
      name: "code",
      width: 200,
    },
    {
      title: "Actual weight",
      dataIndex: "actual_weight",
      name: "actual_weight",
      width: 200,
    },
    {
      title: "Correction mark",
      dataIndex: "correction_mark",
      name: "correction_mark",
      width: 200,
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDelete, setDisabledDelete] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  // const [listInstructors, setListInstructors] = useState([])
  // const [listInstructorAssessment, setListInstructorsAssessment] = useState([])
  // const [listGroups, setListGroups] = useState([])
  const [selectionType, setSelectionType] = useState("checkbox");

  const handleDisable = (selectedRows) => {
    selectedRows.length === 1 ? setDisabled(false) : setDisabled(true);
  };

  const handleDisableDelete = (selectedRows) => {
    selectedRows.length > 0
      ? setDisabledDelete(false)
      : setDisabledDelete(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRowData(Object.assign({}, selectedRow[0]));
      setSelectedRows(selectedRowKeys.join(","));
      handleDisable(selectedRowKeys);
      handleDisableDelete(selectedRowKeys);
    },
  };

  const data = assessmentQuestionsMethod.rows;

  // const exporterRef = useRef(null)
  // const startExport = useCallback(() => {
  //   exporterRef.current.exportGrid()
  // }, [exporterRef])

  // const onSave = (workbook) => {
  //   workbook.xlsx.writeBuffer().then((buffer) => {
  //     saveAs(
  //       new Blob([buffer], { type: 'application/octet-stream' }),
  //       'DataGrid.xlsx',
  //     )
  //   })
  // }

  // useEffect(() => {
  //   dispatch(
  //     fetchCourseInstructorById({
  //       course_id,
  //       exam_id: assessmentQuestionsMethod?.id,
  //     }),
  //   )
  // .then((res) => {
  //   if (res?.payload?.length > 0) {
  // const constructors_IDS = res?.payload?.map((el) => {
  //   return el?.group_details?.instructors[0]?.id
  // })
  // const listInst = persons
  //   ?.filter((el) => constructors_IDS?.includes(el.person_id))
  //   .map((el) => el.person_details[0])
  //   .map(({ first_name, last_name, avatar }) => (
  //     <>
  //       <MenuItem>
  //         <Avatar
  //           title={'assignee'}
  //           src={MediaBaseUrl + `${avatar}`}
  //           className={classes3.small}
  //         >
  //           {first_name
  //             .split(' ')
  //             .map((word) => word[0])
  //             .join('')}
  //         </Avatar>
  //         &nbsp; &nbsp;
  //         {first_name} {last_name}
  //       </MenuItem>
  //     </>
  //   ))
  // const listGroup = res?.payload?.map((el) => (
  //   <>
  //     <MenuItem>{el?.group_details?.group_name}</MenuItem>
  //   </>
  // ))
  // const listInstAssessment = persons
  //   ?.filter((el) => constructors_IDS?.includes(el.person_id))
  //   .map((el) => el.person_details[0])
  // setListInstructors(listInst)
  // setListGroups(listGroup)
  // setListInstructorsAssessment(listInstAssessment)
  // }
  // })
  // }, [])
  const dateFormat = "YYYY-MM-DD";

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <Link
                to={`/citrine/assessmentsQuestionsDetailsByGroup/program/${
                  program && program.id
                }/course/${course_id}/assessmentMethod/${
                  assessmentQuestionsMethod && assessmentQuestionsMethod.id
                }/${
                  assessmentQuestionsMethod && assessmentQuestionsMethod.name
                }`}
              >
                <Typography>
                  {assessmentQuestionsMethod.name} (
                  {assessmentQuestionsMethod.mode})
                </Typography>
              </Link>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <DropDownQuestionAssessment
                course={course}
                course_id={course_id}
                assessmentQuestionsMethod={assessmentQuestionsMethod}
                assessmentMethod_id={assessmentQuestionsMethod.id}
                assessmentQuestionsMethod_name={assessmentQuestionsMethod.name}
                selectedRows={selectedRows}
                disabled={disabled}
                disabledDelete={disabledDelete}
                selectedRowData={selectedRowData}
                programId={ProgramId}
                selectedRowsToDelete={data}
                loadMarksFromLMSHandler={loadMarksFromLMSHandler}
                loadMarksIsEnabled={loadMarksIsEnabled}

                // export={startExport}
              />
            </GridItem>
          </GridContainer>

          <Button
            style={{
              color: showInstructors ? greyColor[5] : greyColor[3],
              fontWeight: showInstructors ? "bold" : "normal",
              fontSize: 13,
            }}
            className={classes.noPadding}
            simple
            onClick={() => {
              setShowInstructors((prev) => !prev);
              setShowQuestions(false);
              setShowDates(false);
            }}
          >
            Instructors and Groups{" "}
            {showInstructors ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </Button>
          <Button
            style={{
              color: showQuestions ? greyColor[5] : greyColor[3],
              fontWeight: showQuestions ? "bold" : "normal",
              fontSize: 13,
            }}
            className={classes.noPadding}
            simple
            onClick={() => {
              setShowQuestions((prev) => !prev);
              setShowInstructors(false);
              setShowDates(false);
            }}
          >
            Questions{" "}
            {showQuestions ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </Button>
          <Button
            style={{
              color: showDates ? greyColor[5] : greyColor[3],
              fontWeight: showDates ? "bold" : "normal",
              fontSize: 13,
            }}
            className={classes.noPadding}
            simple
            onClick={() => {
              setShowDates((prev) => !prev);
              setShowInstructors(false);
              setShowQuestions(false);
            }}
          >
            Dates{" "}
            {showDates ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Button>

          <div style={{ margin: 15 }}>
            {showInstructors && (
              <>
                {assessmentQuestionsMethod?.instructors?.length == 0 ? (
                  <p>This assessment has no instructors yet</p>
                ) : (
                  <>
                    {assessmentQuestionsMethod?.instructors.map((el) => (
                      <Instructors instructor={el} />
                    ))}
                  </>
                )}
              </>
            )}

            {showQuestions && (
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
              />
            )}

            {showDates && (
              <EditAssessmentDates
                assessmentQuestionsMethod={assessmentQuestionsMethod}
                course={course}
                course_id={course_id}
                assessmentMethod_id={assessmentQuestionsMethod?.assessmentActivities?.id}
              />
            )}
          </div>
        </CardBody>
      </Card>
      {/* <GridExporter
        ref={exporterRef}
        rows={data}
        columns={columns}
        onSave={onSave}
      /> */}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </GridItem>
  );
};

const Instructors = ({ instructor }) => {
  const classes = useAvatarStyles();

  const [openCollapse, setOpenCollapse] = useState(false);

  const handleClick = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <GridContainer alignItems="center">
      <GridItem>
        <GridContainer alignItems="center">
          <GridItem>
            <Avatar
              className={classes.small}
              src={MediaBaseUrlAvatar + `${instructor.avatar}`}
            >
              {instructor.first_name.trim().charAt(0)}
            </Avatar>
          </GridItem>

          <GridItem>
            <strong>
              {instructor.first_name} {instructor.last_name}:
            </strong>
          </GridItem>
        </GridContainer>
      </GridItem>

      {instructor &&
        instructor.groups &&
        instructor.groups.map((group, index) => (
          <GridItem>
            <Chip size="small" label={group?.group_name} />
          </GridItem>
        ))}
    </GridContainer>
  );
};

export default function AssessmentQuestionList({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;
  const { ProgramId } = match.params;
  const backdropClasses = useBackdropStyles();

  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const AssessmentQuestionsMethods = async () => {
    try {
      await dispatch(fetchAssessmentQuestionsMethods(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    dispatch(fetchStaff());
    AssessmentQuestionsMethods();
    dispatch(fetchCourseById(id));
    dispatch(fetchProgramById(ProgramId));
  }, []);

  const assessmentQuestionsMethods = useSelector(
    (state) => state.AssessmentMethods.assessmentQuestionsMethods
  );
  const assessmentQuestionsMethodsStatus = useSelector(
    (state) => state.AssessmentMethods.assessmentQuestionsMethodsStatus
  );
  const program = useSelector((state) => state.program.program);
  const course = useSelector((state) => state.course.course);

  // const loadMarksFromLMSHandler = async (data) => {
  //   try {
  //     await dispatch(loadLMSImportedMarks(data)).unwrap();
  //     // handle result here
  //   } catch (rejectedValueOrSerializedError) {
  //     // handle error here
  //     console.log("error in component", rejectedValueOrSerializedError);
  //     setHandledError(rejectedValueOrSerializedError);
  //     handleOpenErrorModal();
  //   }
  // };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const loadMarksFromLMSHandler = async (data) => {
    const resultAction = await dispatch(loadLMSImportedMarks(data));

    if (loadLMSImportedMarks.fulfilled.match(resultAction)) {
      if (resultAction.payload === "succeeded") {
        openNotificationWithIcon(
          "success",
          `Loaded assessment's data successfully`
        );
      } else {
        openNotificationWithIcon("error", `Loading assessment's data failed`);
      }
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const { loading } = useSelector((state) => state.LMSIntegration);

  const semester_id = localStorage.getItem("semester_id");

  let content;
  if (assessmentQuestionsMethodsStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (assessmentQuestionsMethodsStatus === "succeeded") {
    if (
      assessmentQuestionsMethods &&
      assessmentQuestionsMethods === "You do not have permission"
    ) {
      content = <h5>{t("You do not have permission!")}</h5>;
    } else if (assessmentQuestionsMethods?.length === 0) {
      content = (
        <h5>
          {t("This course has no assessment Questions Methods to display!")}
        </h5>
      );
    } else {
      content = assessmentQuestionsMethods.map((assessmentQuestionsMethod) => {
        console.log("assessmentQuestionsMethod", assessmentQuestionsMethod);
        const data = [
          {
            key: "id_lms_course",
            value: assessmentQuestionsMethod.course_id_lms?.toString(),
          },
          {
            key: "id_lms_clo",
            value: JSON.stringify(assessmentQuestionsMethod.clos_list),
          },
          {
            key: "id_lms_assessmentMethod",
            value:
              assessmentQuestionsMethod?.assessmentActivities?.id_lms?.toString(),
          },
          {
            key: "id_citrine_assessmentMethod",
            value: assessmentQuestionsMethod.id.toString(),
          },
          {
            key: "id_citrine_course",
            value: id.toString(),
          },
          {
            key: "id_citrine_semester",
            value: semester_id.toString(),
          },
        ];

        return (
          <AssessmentQuestionMethodExcerpt
            program={program}
            course_id={id}
            course={course}
            key={assessmentQuestionsMethod.id}
            assessmentQuestionsMethod={assessmentQuestionsMethod}
            loadMarksFromLMSHandler={() => loadMarksFromLMSHandler(data)}
            semester_id={semester_id}
          />
        );
      });
    }
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/program/${ProgramId}`}>
            {program &&
              program.program_degree_name &&
              program.program_degree_name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/assessmentsQuestions_list/program/${ProgramId}/course/${id}`}
            style={{ color: "orange" }}
          >
            {t("Assessments Questions")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <br />

      <AddAssessmentMethodModal course_id={id} />

      <GridContainer>{content}</GridContainer>

      <Backdrop
        className={backdropClasses.backdrop}
        open={loading === "pending"}
      >
        <CircularProgress color="inherit" />
        <br />
        <br />

        <p>
          Loading assessments' data from LMS into Citrine, this may take a few
          minutes...
        </p>
      </Backdrop>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
