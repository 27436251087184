import React from 'react'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export default function InvalidLogin({ handleClose }) {
    return (
        <GridContainer
            justifyContent='space-between'
            alignItems="center"
            style={{
                backgroundColor: "#FCF1EF",
                borderRadius: "8px",
                padding: "10px"
            }}>
            <GridItem >
                <GridContainer
                    justifyContent='flex-start'
                    alignItems="center"
                >
                    <GridItem>
                        <ErrorOutlineIcon />
                    </GridItem>
                    <GridItem>
                        <p>
                            login and password can not be empty
                        </p>
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem>
                <CloseIcon onClick={handleClose} />
            </GridItem>
        </GridContainer>
    )
}
