// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { SchoolRounded } from '@material-ui/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchColleges } from 'reduxToolkit/features/college/CollegesSlice'
import ErrorModal from '../../../Components/ErrorModal'
import {
  addNewDepartment,
  departmentUpdate,
  fetchDepartmentById,
  fetchDepartments,
} from 'reduxToolkit/features/department/DepartmentsSlice'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
export default function CreateDepartmentPage({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const [form] = Form.useForm()
  console.log('id', id)

  const isAddMode = isNaN(id)
  console.log('id from match is add mode', isAddMode)
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const useTitleStyles = makeStyles(titleStyles)

  const titleClasses = useTitleStyles()
  const [name, setName] = useState('')
  const [college_id, setCollege_id] = useState('')
  const [college, setCollege] = useState('')
  const [mission, setMission] = useState('')
  const [vision, setVision] = useState('')
  const [goals, setGoals] = useState('')
  const [values, setValues] = useState('')
  const [phone_number, setPhone_number] = useState('')
  const [email, setEmail] = useState('')
  const [chief_department_id, setChief_department] = useState('')
  const [chief_department, setChief_departmentID] = useState('')
  const [image, setImage] = useState('')
  const [address, setAdress] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [postal_code, setPostal_code] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const onNameChanged = (e) => setName(e.target.value)
  const onCollege_idChanged = (e) => setCollege_id(e.target.value)
  const onMissionChanged = (e) => setMission(e.target.value)
  const onVisionChanged = (e) => setVision(e.target.value)
  const onGoalsChanged = (e) => setGoals(e.target.value)
  const onValuesChanged = (e) => setValues(e.target.value)
  const onPhone_numberChanged = (e) => setPhone_number(e.target.value)
  const onEmailChanged = (e) => setEmail(e.target.value)
  const onChief_departmentChanged = (e) => setChief_department(e.target.value)

  const onAdressChanged = (e) => setAdress(e.target.value)
  const onCityChanged = (e) => setCity(e.target.value)
  const onCountryChanged = (e) => setCountry(e.target.value)
  const onPostal_codeChanged = (e) => setPostal_code(e.target.value)

  const dispatch = useDispatch()
  const collegesStatus = useSelector((state) => state.college.collegesStatus)

  const { Option } = Select

  const history = useHistory()
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  const onFinish = async (values) => {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(addNewDepartment(values))
      unwrapResult(resultAction)
      openNotificationWithIcon('success', `Added with success`)
      dispatch(fetchDepartments())
      history.push('/citrine/organizations/departments')
      form.resetFields()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    } finally {
      setAddRequestStatus('idle')
    }
  }
  const onSaveDepartmentClicked = async () => {
    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onsave')
      const resultAction = await dispatch(
        addNewDepartment({
          name,
          college_id,
          mission,
          vision,
          goals,
          values,
          phone_number,
          email,
          chief_department_id,
          address,
          city,
          country,
          postal_code,
        }),
      )
      unwrapResult(resultAction)
      setName('')
      setCollege_id('')
      setMission('')
      setVision('')
      setGoals('')
      setValues('')
      setPhone_number('')
      setEmail('')
      setChief_department('')

      setAdress('')
      setCity('')
      setCountry('')
      setPostal_code('')
      dispatch(fetchDepartments())
      history.push('/citrine/organizations/departments')
    } catch (err) {
      console.log('department', addNewDepartment)
      console.error('Failed to save the department: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }
  const onUpdateDepartmentClicked = async () => {
    try {
      setAddRequestStatus('pending')
      console.log('onupdate')
      const resultAction = await dispatch(
        departmentUpdate({
          id: id,
          modified: {
            name,
            college,
            mission,
            vision,
            goals,
            values,
            phone_number,
            email,
            chief_department,
            address,
            city,
            country,
            postal_code,
          },
        }),
      )
      unwrapResult(resultAction)
      setName('')
      setCollege('')
      setMission('')
      setVision('')
      setGoals('')
      setValues('')
      setPhone_number('')
      setEmail('')

      setAdress('')
      setCity('')
      setCountry('')
      setPostal_code('')
      setChief_departmentID('')
      dispatch(fetchDepartments())
      history.push('/citrine/organizations/departments')
    } catch (err) {
      console.log('department', departmentUpdate)
      console.error('Failed to save the department: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }
  const personsStatus = useSelector((state) => state.user.usersStatus)
  useEffect(() => {
    if (personsStatus === 'idle') {
      dispatch(fetchStaff())
    }
    if (collegesStatus === 'idle') {
      dispatch(fetchColleges())
    }
  }, [personsStatus, collegesStatus, dispatch])
  const persons = useSelector((state) => state.user.staffList)
  console.log('persons', persons)

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchDepartmentById(id))
    }
  }, [id])
  const colleges = useSelector((state) => state.college.colleges)
  const department = useSelector((state) => state.department.department)
  console.log('department ', department)

  useEffect(() => {
    if (!isAddMode) {
      setName(department && department.name)
      setCollege(department && department.college_id)
      setMission(department && department.mission)
      setVision(department && department.vision)
      setGoals(department && department.goals)
      setValues(department && department.values)
      setPhone_number(department && department.phone_number)
      setChief_departmentID(department && department.chief_department_id)
      setEmail(department && department.email)

      setAdress(department && department.address)
      setCity(department && department.city)
      setCountry(department && department.country)

      setPostal_code(department && department.postal_code)
    }
  }, [department, dispatch])
  function onChange(value) {
    console.log(`selected ${value}`)
    setCollege_id(value)
    setCollege(value)
  }

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  function onChangeDirector(value) {
    console.log(`selected ${value}`)
    setChief_department(value)
    setChief_departmentID(value)
  }
  return (
    <div>
      {isAddMode && (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <Breadcrumb separator=">">
                      <Breadcrumb.Item>
                        <Link to={`/citrine/organizations`}>
                          <span> {t('Organizations')} </span>
                        </Link>
                      </Breadcrumb.Item>

                      <Breadcrumb.Item>
                        <Link to={`/citrine/organizations/departments`}>
                          <span> {t('Departments')} </span>
                        </Link>
                      </Breadcrumb.Item>

                      <Breadcrumb.Item>
                        <span> {t('Create')} </span>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    {/* <span className={titleClasses.title}>
                      {' '}
                      <Link to={`/citrine/configurations`}>
                        {t('Configuration')}
                      </Link>{' '}
                      <Link to={`/citrine/organizations/departments`}>
                        {' '}
                        {t('/Departments')}
                      </Link>{' '}
                      {t('/Create')}{' '}
                    </span> */}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError
                  >
                    <GridContainer
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <GridItem xs={6} sm={6} md={6}>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item
                            label={t('College')}
                            name="college_id"
                            rules={[
                              {
                                required: false,
                                message: 'Please select College',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              style={{ width: 400 }}
                              optionFilterProp="children"
                              onFocus={onFocus}
                              onBlur={onBlur}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {colleges.map((college) => (
                                <option key={college.id} value={college.id}>
                                  {college.name}
                                </option>
                              ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <Form.Item
                          label={t('Name')}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label={t('Mission')}
                          name="mission"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a mission!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label={t('Vision')}
                          name="vision"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a vision!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label={t('Goals')}
                          name="goals"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter goals!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label={t('Values')}
                          name="values"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter values!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Department Chief')}
                            name="chief_department_id"
                            rules={[
                              {
                                required: false,
                                message: 'Please select chief_department_id',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              style={{ width: 400 }}
                              optionFilterProp="children"
                              onFocus={onFocus}
                              onBlur={onBlur}
                              onSearch={onSearch}
                              // filterOption={(input, option) =>
                              //   option.children
                              //     .toLowerCase()
                              //     .indexOf(input.toLowerCase()) >= 0
                              // }
                            >
                              {/* {persons &&
                                persons.map((p) => (
                                  <Option key={p.id} value={p.id}>
                                    {`${
                                      p.person_details &&
                                      p.person_details[0].first_name
                                    } ${
                                      p.person_details &&
                                      p.person_details[0].last_name
                                    } `}
                                  </Option>
                                ))} */}
                              {persons &&
                                persons.map((p) => (
                                  <Option key={p.id} value={p.id}>
                                    {!p?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {p?.person_details[0]?.first_name?.charAt(0)?.toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                        }}
                                        src={`${MediaBaseUrl}${p?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${
                                      p.person_details &&
                                      p.person_details[0].first_name
                                    } ${
                                      p.person_details &&
                                      p.person_details[0].last_name
                                    } `}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <GridItem>
                          <Form.Item
                            name="phone_number"
                            label="Phone number"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a phone number!',
                              },
                            ]}
                          >
                            <InputNumber
                              //autoSize={true}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem>
                          <Form.Item
                            name="email"
                            label="Email address"
                            rules={[
                              {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                              },
                              {
                                required: true,
                                message: 'Please input your E-mail!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                        <GridItem>
                          <Form.Item
                            name="address"
                            label="address"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an address!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="city"
                            label="City"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a city!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name="country"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a country!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="postal_code"
                            label="Postal Code"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a postal_code!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="flex-end">
                      <GridItem>
                        <Form.Item>
                          <Button
                            onClick={() =>
                              history.push('/citrine/organizations/departments')
                            }
                            style={{ marginRight: '20px' }}
                              color="danger"
                          >
                            {t('Cancel')}
                          </Button>
                          <Button
                            type="submit"
                            color="info"
                            className={classes.updateProfileButton}
                          >
                            {t('Save')}
                          </Button>
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </Form>
                </CardBody>
                {/* <CardBody>
                    <form>
                      <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <GridItem xs={6} sm={6} md={6}>
                          <GridItem xs={12} sm={12} md={6} >
                            <label>{t('College  ')}</label>
                            <Select
                              showSearch
                              style={{ width: 200 }}


                              optionFilterProp="children"
                              name="college_id"
                              value={college_id}
                              onChange={onChange}
                              onFocus={onFocus}
                              onBlur={onBlur}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }

                            >
                              {colleges.map((college) => (
                                <option key={college.id} value={college.id}>
                                  {college.name}
                                </option>
                              ))}

                            </Select>
                          </GridItem>


                          <CustomInput
                            labelText={t('Name ')}
                            id="name"
                            type="text"
                            name="name"
                            value={name}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a name!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: event => {
                                setName(event.target.value);
                              }
                            }}
                          />


                          <CustomInput
                            labelText={t('Mission')}
                            id="mission"
                            value={mission}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a mission!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: event => {
                                setMission(event.target.value);
                              }
                            }}
                          />


                          <CustomInput
                            labelText={t('Vision ')}
                            id="vision"
                            value={vision}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a vision!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: event => {
                                setVision(event.target.value);
                              }
                            }}
                          />


                          <CustomInput
                            labelText={t('Goals ')}
                            id="goals"
                            value={mission}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter goals!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: event => {
                                setGoals(event.target.value);
                              }
                            }}
                          />


                          <CustomInput
                            labelText={t('Values ')}
                            id="values"
                            value={values}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter values!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: event => {
                                setValues(event.target.value);
                              }
                            }}
                          />
                          <GridItem xs={12} sm={12} md={6}>

                            <label>{t('Department Chief')}</label>
                            <Select
                              showSearch
                              style={{ width: 400 }}
                              optionFilterProp="children"
                              name="chief_department_id"
                              value={chief_department_id}
                              onChange={onChangeDirector}
                              onFocus={onFocus}
                              onBlur={onBlur}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }

                            >
                              {persons && persons.map((p) => (
                                <Option key={p.id} value={p.id}>
                                   
                                  {`${p.person_details && p.person_details[0].first_name} ${p.person_details && p.person_details[0].last_name} `}
                                </Option>
                              ))}

                            </Select>
                          </GridItem>




                        </GridItem>

                        <GridItem xs={6} sm={6} md={6}>


                          <GridItem>
                            <CustomInput
                              labelText={t('Phone number ')}
                              id="Phone_number"
                              type="number"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a phone number!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setPhone_number(event.target.value);
                                }
                              }}
                            />

                          </GridItem>

                          <GridItem >
                            <CustomInput
                              labelText={t('Email address ')}
                              id="Email"
                              value={email}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter an email!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setEmail(event.target.value);
                                }
                              }}
                            />

                            <CustomInput
                              labelText={t('Adress ')}
                              id="Adress"
                              value={address}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter an adress!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setAdress(event.target.value);
                                }
                              }}
                            />


                            <CustomInput
                              labelText={t('City ')}
                              id="City"
                              value={city}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a city!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setCity(event.target.value);
                                }
                              }}
                            />


                            <CustomInput
                              labelText={t('Country ')}
                              id="Country"
                              value={country}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a country!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setCountry(event.target.value);
                                }
                              }}
                            />


                            <CustomInput
                              labelText={t('Postal Code')}
                              id="Postal_code"
                              value={postal_code}
                              formControlProps={{
                                fullWidth: true
                              }}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a postal code!',
                                },
                              ]}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setPostal_code(event.target.value);
                                }
                              }}
                            />
                          </GridItem>

                          <GridItem>
                            <Button color="info"
                              className={classes.updateProfileButton}
                              onClick={onSaveDepartmentClicked}
                            >
                              {t('Save')}
                            </Button></GridItem>
                        </GridItem>

                      </GridContainer>
                    </form>

                  </CardBody> */}
              </Card>
            </GridItem>
          </GridContainer>

          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      )}
      {!isAddMode && department && (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>

                  <h4 className={classes.cardIconTitle}>
                    <span className={titleClasses.title}>
                      {' '}
                      <Link to={`/citrine/configurations`}>
                        {t('Configuration')}
                      </Link>{' '}
                      <Link to={`/citrine/organizations/departments`}>
                        {t(' /Departments')}
                      </Link>
                      {t('/Update:')} {name}{' '}
                    </span>
                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <GridItem xs={6} sm={6} md={6}>
                        <GridItem>
                          <label>{t('College ')} </label>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            name="college"
                            value={college}
                            onChange={onChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {colleges.map((college) => (
                              <option key={college.id} value={college.id}>
                                {college.name}
                              </option>
                            ))}
                          </Select>
                        </GridItem>
                        <CustomInput
                          labelText={t('Name ')}
                          id="name"
                          //onChange={onNameChanged}
                          type="text"
                          name="name"
                          value={name}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            //disabled: false,
                            onChange: (event) => {
                              setName(event.target.value)
                            },
                            value: name,
                          }}
                        />

                        <CustomInput
                          labelText={t('Mission')}
                          id="mission"
                          value={mission}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a mission!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setMission(event.target.value)
                            },
                            value: mission,
                          }}
                        />

                        <CustomInput
                          labelText={t('Vision ')}
                          id="vision"
                          value={vision}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a vision!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setVision(event.target.value)
                            },
                            value: vision,
                          }}
                        />

                        <CustomInput
                          labelText={t('Goals ')}
                          id="goals"
                          value={mission}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter goals!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setGoals(event.target.value)
                            },
                            value: goals,
                          }}
                        />

                        <CustomInput
                          labelText={t('Values ')}
                          id="values"
                          value={values}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter values!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setValues(event.target.value)
                            },
                            value: values,
                          }}
                        />

                        <CustomInput
                          labelText={t('Phone number ')}
                          id="Phone_number"
                          // onChange={onPhoneNumberChanged}
                          type="number"
                          // value={Phone_number}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a phone number!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setPhone_number(event.target.value)
                            },
                            value: phone_number,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText={t('Email address ')}
                          id="Email"
                          value={email}
                          //     onChange={onEmailChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an email!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setEmail(event.target.value)
                            },
                            value: email,
                          }}
                        />

                        <GridItem xs={12} sm={12} md={6}>
                          <label>{t('Department Chief')}</label>
                          <Select
                            showSearch
                            style={{ width: 400 }}
                            optionFilterProp="children"
                            name="chief_department"
                            value={chief_department}
                            onChange={onChangeDirector}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                          >
                            {persons &&
                              persons.map((p) => (
                                <Option key={p.id} value={p.id}>
                                  {!p?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {p?.person_details[0]?.first_name?.charAt(0)?.toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                      }}
                                      src={`${MediaBaseUrl}${p?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    p.person_details &&
                                    p.person_details[0].first_name
                                  } ${
                                    p.person_details &&
                                    p.person_details[0].last_name
                                  } `}
                                </Option>
                              ))}
                          </Select>
                        </GridItem>

                        <CustomInput
                          labelText={t('Adress ')}
                          id="Adress"
                          value={address}
                          //       onChange={onAdressChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an adress!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setAdress(event.target.value)
                            },
                            value: address,
                          }}
                        />

                        <CustomInput
                          labelText={t('City ')}
                          id="City"
                          value={city}
                          //         onChange={onCityChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a city!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setCity(event.target.value)
                            },
                            value: city,
                          }}
                        />

                        <CustomInput
                          labelText={t('Country ')}
                          id="Country"
                          value={country}
                          //         onChange={onCountryChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a country!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setCountry(event.target.value)
                            },
                            value: country,
                          }}
                        />

                        <CustomInput
                          labelText={t('Postal Code ')}
                          id="Postal_code"
                          value={postal_code}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          //       onChange={onPostalCodeChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a postal code!',
                            },
                          ]}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setPostal_code(event.target.value)
                            },
                            value: postal_code,
                          }}
                        />
                      </GridItem>

                      <Button
                        color="info"
                        className={classes.updateProfileButton}
                        onClick={onUpdateDepartmentClicked}
                      >
                        {t('Update')}
                      </Button>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  )
}
