import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAssessmentModes } from '../../../../../reduxToolkit/features/assessmentMode/AssessmentModeSpecSlice';
import DropDownAssessmentModeButton from "../../../../Components/DropDownAssessmentModeButton";



const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);


const AssessmentModeExcerpt = ({ assessmentMode }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();


  return (

    <GridItem xs={12} sm={12} md={4} key={assessmentMode.id} >
      <Card style={{ maxWidth: "380px", minWidth: "380px", maxHeight: "180px", minHeight: "180px" }} cardHover >
        <CardHeader>
          <GridContainer direction="row"
            justifyContent="space-between"
            alignItems="baseline">
            <GridItem xs={10} sm={10} md={10}>
              {/* <Link to={`/citrine/programSpec/${program.id}`}> */}
              <h4 className={cardClasses.cardProductTitle}>
                {assessmentMode.name}
              </h4>
              {/* </Link> */}
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <DropDownAssessmentModeButton id={assessmentMode.id} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        {/* <CardBody smallCardBody>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}> Departments:
            {program.department_detail && program.department_detail.map((department) => {
              return <div style={{ fontWeight: "normal" }}> {department.name} </div>

            }
            )}
          </div>


        </CardBody> */}
        <CardFooter>
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="center">
            {/* <GridItem> <span style={{ color: (program.state) === "Draft" ? grayColor[1] : "#FFCB3B" }}>{program.state}</span> </GridItem> */}
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

export default function AssessmentModes() {
  const [search, setSearch] = useState("")
  const { t } = useTranslation();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)


  const dispatch = useDispatch()

  const assessmentModesStatus = useSelector(state => state.assessmentModeSpec.assessmentModesStatus)
  useEffect(() => {
    if (assessmentModesStatus === 'idle') {
      dispatch(fetchAssessmentModes())
    }
  }, [
    assessmentModesStatus,
    dispatch])

  const assessmentModes = useSelector(state => state.assessmentModeSpec.assessmentModes)
  const error = useSelector(state => state.assessmentModeSpec.error)
  console.log(assessmentModes, "assessmentModes")
  let content

  const searchButton =
    navBarClasses.top +
    " " +
    navBarClasses.searchButton

  if (assessmentModesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (assessmentModesStatus === 'succeeded') {
    content = assessmentModes && assessmentModes.filter((assessmentMode) => {
      if (search == '') {
        return assessmentMode
      } else if (assessmentMode.name.toLowerCase().includes(search.toLowerCase())) {
        return assessmentMode
      }
    }).map(assessmentMode => (
      <AssessmentModeExcerpt key={assessmentMode.id} assessmentMode={assessmentMode} />
    ))
  } else if (assessmentModesStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer

        md={12}
        xs={12} sm={12}
        direction="row"
        // style={{ width:300 ,height:150}}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>

          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span > {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course/assessmentMode`}>
                <span > {t('Assessment Modes')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>


      <GridContainer
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Link to="/citrine/configuration/course/assessmentMode/createAssessmentMode/:id">
            <Button color="primary">
              {t(' Add')}
            </Button>
          </Link>
        </GridItem>
        <GridItem >
          <CustomInput
            inputProps={{
              onChange: event => {
                setSearch(event.target.value);
              },
              value: search,
              placeholder: "Search"
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
          >
            <Search className={navBarClasses.headerLinksSvg + " " + navBarClasses.searchIcon} />
          </Button>
        </GridItem>
      </GridContainer>

      <GridContainer >
        {content}
      </GridContainer>
      {/* <Link to={"/citrine/programs/test"} >
    <p>here</p>
    </Link> */}
    </div>
  );
}













