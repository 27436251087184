
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchAttachments } from "reduxToolkit/features/attachment/AttachmentsSlice";
import { fetchMailTemplates } from "reduxToolkit/features/MailTemplate/MailTemplateSlice";
import { fetchOutGoingMailServers } from "reduxToolkit/features/outgoingMailServer/OutGoingMailServerSlice";
// @material-ui/icons
import { addNewMail } from "../../../reduxToolkit/features/Mail/MailSlice";


const useStyles = makeStyles(styles);

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
// const classes = useStyles();
export default function AddEditMail() {
    const { t } = useTranslation();
    const classes = useStyles();
    const useTitleStyles = makeStyles(titleStyles);

    const titleClasses = useTitleStyles();

    const [auto_delete, setAuto_delete] = useState(true)
    const [body_html, setBody_html] = useState('')
    const [to, setTo] = useState('')
    const [subject, setSubject] = useState('')
    const [attachement_ids, setAttachement_ids] = useState([])
    const [author_id, setAuthor] = useState('')
    const [outGoingMailServer_id, setOutGoingMailServer_id] = useState(null)
    const [mailTemplate_id, setMailTemplate_id] = useState(null)
    const onBodyChanged = (e) => setBody_html(e.target.value)

    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const dispatch = useDispatch()

    const history = useHistory();
    useEffect(() => {

        dispatch(fetchAttachments())
        dispatch(fetchOutGoingMailServers())
        dispatch(fetchMailTemplates())
        setAuthor(author);
    }, []);
    const author = localStorage.getItem("userId");
    console.log('author', author)

    const attachements = useSelector((state) => state.attachment.attachments)
    const servers = useSelector((state) => state.OutGoingMailServer.outGoingMailServers)
    const templates = useSelector((state) => state.MailTemplate.mailTemplates)
    const onSaveMailClicked = async () => {

        if (mailTemplate_id === null && outGoingMailServer_id !== null) {
            try {
                setAddRequestStatus('pending')
                console.log('onsave')
                const resultAction = await dispatch(
                    addNewMail({
                        author_id, to, outGoingMailServer_id, subject,
                        auto_delete, body_html, attachement_ids
                    })
                )
                unwrapResult(resultAction)

                setAuthor('')
                setTo('')
                setSubject('')
                setOutGoingMailServer_id('')
                setMailTemplate_id('')
                setAuto_delete('')
                setBody_html('')
                setAttachement_ids('')
                history.push('/citrine/mails');
            } catch (err) {
                console.error('Failed to save the mail: ', err)
            } finally {
                setAddRequestStatus('idle')
            }
        }
        if (outGoingMailServer_id === null && mailTemplate_id !== null) {
            try {
                setAddRequestStatus('pending')
                console.log('onsave')
                const resultAction = await dispatch(
                    addNewMail({
                        author_id, to, mailTemplate_id, subject,
                        auto_delete, body_html, attachement_ids
                    })
                )
                unwrapResult(resultAction)

                setAuthor('')
                setTo('')
                setSubject('')
                setOutGoingMailServer_id('')
                setMailTemplate_id('')
                setAuto_delete('')
                setBody_html('')
                setAttachement_ids('')
                history.push('/citrine/mails');
            } catch (err) {
                console.error('Failed to save the mail: ', err)
            } finally {
                setAddRequestStatus('idle')
            }
        }
        if (mailTemplate_id !== null && outGoingMailServer_id !== null) {
            try {
                setAddRequestStatus('pending')
                console.log('onsave')
                const resultAction = await dispatch(
                    addNewMail({
                        author_id, to, outGoingMailServer_id, mailTemplate_id, subject,
                        auto_delete, body_html, attachement_ids
                    })
                )
                unwrapResult(resultAction)

                setAuthor('')
                setTo('')
                setSubject('')
                setOutGoingMailServer_id('')
                setMailTemplate_id('')
                setAuto_delete('')
                setBody_html('')
                setAttachement_ids('')
                history.push('/citrine/mails');
            } catch (err) {
                console.error('Failed to save the mail: ', err)
            } finally {
                setAddRequestStatus('idle')
            }
        }
        // if(outGoingMailServer_id !== null){
        //         try {
        //             setAddRequestStatus('pending')
        //             console.log('onsave')
        //             const resultAction = await dispatch(
        //                 addNewMail({
        //                     author_id, to, outGoingMailServer_id, mailTemplate_id,
        //                     auto_delete, body_html, attachement_ids
        //                 })
        //             )
        //             unwrapResult(resultAction)

        //             setAuthor('')
        //             setTo('')
        //             setOutGoingMailServer_id('')
        //             setMailTemplate_id('')
        //             setAuto_delete('')
        //             setBody_html('')
        //             setAttachement_ids('')
        //             history.push('/citrine/mails');
        //         } catch (err) {
        //             console.error('Failed to save the mail: ', err)
        //         } finally {
        //             setAddRequestStatus('idle')
        //         }

        //     }
        // if(mailTemplate_id !== null && outGoingMailServer_id !== null){
        //             try {
        //                 setAddRequestStatus('pending')
        //                 console.log('onsave')
        //                 const resultAction = await dispatch(
        //                     addNewMail({
        //                         author_id, to, outGoingMailServer_id, mailTemplate_id,
        //                         auto_delete, body_html, attachement_ids
        //                     })
        //                 )
        //                 unwrapResult(resultAction)

        //                 setAuthor('')
        //                 setTo('')
        //                 setOutGoingMailServer_id('')
        //                 setMailTemplate_id('')
        //                 setAuto_delete('')
        //                 setBody_html('')
        //                 setAttachement_ids('')
        //                 history.push('/citrine/mails');
        //             } catch (err) {
        //                 console.error('Failed to save the mail: ', err)
        //             } finally {
        //                 setAddRequestStatus('idle')
        //             }
        //         } 
        if (mailTemplate_id === null && outGoingMailServer_id === null) {
            try {
                setAddRequestStatus('pending')
                console.log('onsave')
                const resultAction = await dispatch(
                    addNewMail({
                        author_id, to, subject,
                        auto_delete, body_html, attachement_ids
                    })
                )
                unwrapResult(resultAction)

                setAuthor('')
                setTo('')
                setSubject('')
                setOutGoingMailServer_id('')
                setMailTemplate_id('')
                setAuto_delete('')
                setBody_html('')
                setAttachement_ids('')
                history.push('/citrine/mails');
            } catch (err) {
                console.error('Failed to save the mail: ', err)
            } finally {
                setAddRequestStatus('idle')
            }

        }

    }



    const [checkedDelete, setCheckedDelete] = React.useState();
    const handleChangeDelete = (event) => {
        setCheckedDelete(event.target.checked);
        setAuto_delete(event.target.checked);
    };

    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    function onChangeAttachements_ids(value) {
        console.log(`selected ${value}`);
        setAttachement_ids(value);
    }
    function onChangeMailTemplate_id(value) {
        console.log(`selected ${value}`);
        setMailTemplate_id(value);
    }
    function onChangeOutGoingMailServer_id(value) {
        console.log(`selected ${value}`);
        setOutGoingMailServer_id(value);


    }
    return (


        <div>

            <div>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <SchoolRounded />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    <span className={titleClasses.title}>      <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/mails`}>{t(' /Mails List')}</Link>{t(' /Create')} </span>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <form>
                                    <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <GridContainer xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                                <GridItem xs={12} sm={12} md={8}>


                                                    <CustomInput
                                                        labelText={t("To")}

                                                        id="to"
                                                        //  onChange={onNameChanged}
                                                        type="text"
                                                        name="to"
                                                        value={to}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter a name!',
                                                            },
                                                        ]}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            disabled: false,
                                                            onChange: event => {
                                                                setTo(event.target.value);
                                                            }
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={8}>


                                                    <CustomInput
                                                        labelText={t("Subject")}

                                                        id="subject"
                                                        //  onChange={onNameChanged}
                                                        type="text"
                                                        name="subject"
                                                        value={subject}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter a name!',
                                                            },
                                                        ]}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            disabled: false,
                                                            onChange: event => {
                                                                setSubject(event.target.value);
                                                            }
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={8} >
                                                    <label>{t('OutGoing Mail Server')} </label>

                                                    <Select
                                                        onChange={onChangeOutGoingMailServer_id}
                                                        size={100}
                                                        placeholder={t("Please select")}
                                                        style={{ width: '100%' }}>
                                                        {servers.map((a) => (
                                                            <option key={a.id} value={a.id}>
                                                                {a.name}
                                                            </option>
                                                        ))}
                                                    </Select>

                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={8} >
                                                    <label>{t('Template ')} </label>

                                                    <Select
                                                        onChange={onChangeMailTemplate_id}
                                                        size={100}
                                                        placeholder={t("Please select")}
                                                        style={{ width: '100%' }}>
                                                        {templates.map((a) => (
                                                            <option key={a.id} value={a.id}>
                                                                {a.name}
                                                            </option>
                                                        ))}
                                                    </Select>

                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={8} >
                                                    <label>{t('Attachments')} </label>
                                                    {/* <CustomInput labelText="Prerequisites *"
                                           ></CustomInput> */}
                                                    <Select
                                                        mode="multiple"
                                                        onChange={onChangeAttachements_ids}
                                                        size={100}
                                                        placeholder={t("Please select")}
                                                        style={{ width: '100%' }}>
                                                        {attachements.map((a) => (
                                                            <option key={a.id} value={a.id}>
                                                                {a.name}
                                                            </option>
                                                        ))}
                                                    </Select>

                                                </GridItem>

                                            </GridContainer>
                                            <GridContainer xs={12} sm={12} md={8} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>


                                                <GridItem xs={12} sm={12} md={8} >
                                                    <label>{t('Message ')} </label>

                                                    <textarea
                                                        id="body_html"
                                                        onChange={onBodyChanged}
                                                        type="textarea"
                                                        name="body_html"
                                                        rows="20"
                                                        cols="100"
                                                        //  value={body_html}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter a name!',
                                                            },
                                                        ]}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    // inputProps={{
                                                    //     disabled: false,
                                                    //     onChange: event => {
                                                    //         setBody_html(event.target.value);
                                                    //     }
                                                    // }}
                                                    />

                                                </GridItem>

                                            </GridContainer>
                                        </GridContainer>



                                        <GridItem>
                                            <Button color="primary"
                                                className={classes.updateProfileButton}
                                                onClick={onSaveMailClicked}
                                            >
                                                {t(' Save')}
                                            </Button></GridItem>


                                    </GridContainer>
                                </form>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>


        </div>

    );



}


