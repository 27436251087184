// @material-ui/core components
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ScoreCourseByQuestion } from "../../../../reduxToolkit/features/assessment/AssessmentSlice";
import {
  CalculScoreCourse,
  fetchCourseById,
} from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchInstructorCourseClos } from "../../../../reduxToolkit/features/CourseInstructor/CourseInstructorSlice";
//import DropDownButtonCourseReport_Survey from "../../../Components/DropDownButtonCourseReport_Survey";
import DropDownButtonCourseReportSurveyInstructor from "./DropDownButtonCourseReportSurveyInstructor";
import CardBlue from "views/componentsnew/Cardblue.jsx";

export default function ClosQuestionListFaculty(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstructorCourseClos());
  }, [dispatch]);

  const instructorCourseClosStatus = useSelector(
    (state) => state.CourseInstructor.instructorCourseClosStatus
  );
  const clos = useSelector(
    (state) => state.CourseInstructor.instructorCourseClos
  );
  console.log("instructorCoursesClos", clos);

  let content;
  let clos_count;

  if (instructorCourseClosStatus === "loading") {
    content = <div>Loading...</div>;
  } else if (instructorCourseClosStatus === "succeeded") {
    if (clos === "You do not have permission") {
      content = <div>You do not have permission to access courses list</div>;
    } else {
      if (clos?.length) {
        content = clos?.map((item) => <CardBlue item={item} key={item.id} />);
      } else {
        content = <div>No assessment Methods found.</div>;
      }
    }
  }

  return (
    <div>
      <GridContainer>{content}</GridContainer>
    </div>
  );
}
