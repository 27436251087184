import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAdmissionRequirements } from 'reduxToolkit/features/admissionRequirement/AdmissionRequirementSpecSlice';
import { fetchGraduateAttributeConfigs } from "reduxToolkit/features/graduateAttributeConfig/GraduateAttributeConfigSlice";
import { fetchGraduationRequirements } from 'reduxToolkit/features/graduationRequirement/GraduationRequirementSpecSlice';
import { fetchLevelPlos } from "reduxToolkit/features/levelPlo/LevelPloSlice";
import { fetchSubLevelPLos } from "reduxToolkit/features/SubLevelPlo/SubLevelPloSlice";
import { fetchTotalLevels } from 'reduxToolkit/features/totalLevel/TotalLevelSlice';
import { fetchFields } from '../../../../reduxToolkit/features/field/FieldSpecSlice';
import { fetchSpecializations } from '../../../../reduxToolkit/features/specialization/SpecializationSpecSlice';
import { fetchTransferRequirements as fetchTransferStudentsAcceptanceRequirements } from '../../../../reduxToolkit/features/transferAcceptanceRequirements/TransferStudentsAcceptanceRequirements';
import { fetchTransferRequirements as fetchTransferCreditsAcceptanceRequirements } from '../../../../reduxToolkit/features/transferAcceptanceRequirements/TransferCreditsAcceptanceRequirements';


const useStyles = makeStyles(styles);

export default function ProgramConfigurations() {
    const { t } = useTranslation();
    const classes = useStyles();
    const useNavBarStyles = makeStyles(navBarStyles);
    const useTitleStyles = makeStyles(titleStyles);
    const useCardStyles = makeStyles(cardStyles);
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();



    //   const keyWords = useSelector((state) => state.keyword.keyWords)
    //   console.log("keyWords", keyWords)
    const dispatch = useDispatch()

    const specializationsStatus = useSelector(state => state.specialization.specializationsStatus)
    const fieldsStatus = useSelector(state => state.field.fieldsStatus)
    const admissionRequirementsStatus = useSelector(state => state.admissionRequirement.admissionRequirementsStatus)
    //  console.log('status of admission requirements ', admissionRequirementsStatus)
    const graduationRequirementsStatus = useSelector(state => state.graduationRequirement.graduationRequirementsStatus)
    //   const keyWordsStatus = useSelector((state) => state.keyword.keyWordsStatus)

    useEffect(() => {

        dispatch(fetchSpecializations())
        dispatch(fetchGraduateAttributeConfigs())
        dispatch(fetchFields())
        dispatch(fetchAdmissionRequirements())
        dispatch(fetchGraduationRequirements())
        dispatch(fetchTotalLevels())
        dispatch(fetchLevelPlos())
        dispatch(fetchSubLevelPLos())
        dispatch(fetchTransferStudentsAcceptanceRequirements())
        dispatch(fetchTransferCreditsAcceptanceRequirements())
    }, [])

    const admissionRequirements = useSelector((state) => state.admissionRequirementSpec.admissionRequirements)
    const graduationRequirements = useSelector((state) => state.graduationRequirementSpec.graduationRequirements)
    const fields = useSelector((state) => state.fieldSpec.fields)
    const specializations = useSelector((state) => state.specializationSpec.specializations)
    const totalLevels = useSelector((state) => state.totalLevel.totalLevels)
    const levelPlos = useSelector((state) => state.levelPlos.levelPlos)
    const subLevelPlos = useSelector((state) => state.subLevelPlo.subLevelPLos)
    const graduateAttributeConfigs = useSelector((state) => state.graduateAttributeConfig.graduateAttributeConfigs)
    const transferStudentsAcceptanceRequirement = useSelector((state) => state.transferStudentsAcceptanceRequirement.acceptanceRequirements)
    const transferCreditsAcceptanceRequirement = useSelector((state) => state.transferCreditsAcceptanceRequirement.acceptanceRequirements)

    return (
        <div>
            <GridContainer md={12} xs={12} sm={12}
                direction="row"
                justify="space-between"
                alignItems="center"
            >

                <GridItem>

                    <Breadcrumb separator=">">
                        <Breadcrumb.Item >

                            {t('Configuration')}

                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/configuration/program`}>
                                <span > {t('Programs Configuration')} </span>
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>

                <GridContainer>

                    <GridItem  >
                        <Link to={`/citrine/configuration/program/admissionRequirements`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }} >

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/admissionRequirements`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/admissionRequirements/">
                                                <Link to={`/citrine/configuration/program/admissionRequirements`}>{admissionRequirements.length} {t('Admission Requirements')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/admissionRequirements/create" >
                                        <Button color="info">
                                            {t(' Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>

                    <GridItem >
                        <Link to={`/citrine/configuration/program/graduationRequirements`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }} >

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/graduationRequirements`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/program/graduationRequirements">
                                                <Link to={`/citrine/configuration/program/graduationRequirements`}>{graduationRequirements.length}{t(' Graduation Requirements')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/graduationRequirements/create" >
                                        <Button color="info">
                                            {t('Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>

                    <GridItem  >
                        <Link to={`/citrine/fields/`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/fields/`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/fields/">
                                                <Link to={`/citrine/fields/`}>{fields.length} {t('Fields')} </Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/createField/:id" >
                                        <Button color="info">
                                            {t('Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>

                    <GridItem  >
                        <Link to={`/citrine/specializations/`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/specializations/`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/specializations/">
                                                <Link to={`/citrine/specializations/`}>{specializations.length} {t('Specializations')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/createSpecialization/:id" >
                                        <Button color="info">
                                            {t('Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>

                    <GridItem  >
                        <Link to={`/citrine/configuration/program/totalLevel`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/totalLevel`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/program/totalLevel">
                                                <Link to={`/citrine/configuration/program/totalLevel`}>{totalLevels.length} {t('Total Levels')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/totalLevel/createTotalLevel/:id" >
                                        <Button color="info">
                                            {t(' Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>

                    <GridItem  >
                        <Link to={`/citrine/configuration/program/levelPlos/`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/levelPlos/`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/program/levelPlos/">
                                                <Link to={`/citrine/configuration/program/levelPlos/`}>{levelPlos.length} {t('Code Level Plos')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/levelPlos/create/" >
                                        <Button color="info">
                                            {t(' Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>

                    <GridItem  >
                        <Link to={`/citrine/configuration/program/subLevelPlos/`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/subLevelPlos/`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/program/subLevelPlos/">
                                                <Link to={`/citrine/configuration/program/subLevelPlos/`}>{subLevelPlos.length} {t('Code SubLevel Plos')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/subLevelPlos/create/" >
                                        <Button color="info">
                                            {t(' Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>

                    <GridItem  >
                        <Link to={`/citrine/configuration/program/graduateAttributeConfigs/`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/graduateAttributeConfigs/`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/program/graduateAttributeConfigs/">
                                                <Link to={`/citrine/configuration/program/graduateAttributeConfigs/`}>{graduateAttributeConfigs.length} {t('Graduate Attribute Configs')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/graduateAttributeConfigs/create/" >
                                        <Button color="info">
                                            {t(' Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>






                    <GridItem  >
                        <Link to={`/citrine/configuration/program/transferStudentsAccepptanceRequirements`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/transferStudentsAccepptanceRequirements`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/program/transferStudentsAccepptanceRequirements">
                                                <Link to={`/citrine/configuration/program/transferStudentsAccepptanceRequirements`}>{transferStudentsAcceptanceRequirement.length} {t('Transfer Students Accepptance Requirements')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/transferStudentsAccepptanceRequirements/create/" >
                                        <Button color="info">
                                            {t(' Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>


                    <GridItem  >
                        <Link to={`/citrine/configuration/program/transferCreditsAccepptanceRequirements`} >
                            <Card product className={classes.cardHover} style={{ width: 350, height: 200 }}>

                                <CardBody>
                                    <Link to={`/citrine/configuration/program/transferCreditsAccepptanceRequirements`} >
                                        <h4 className={classes.cardProductTitle}>
                                            <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/program/transferCreditsAccepptanceRequirements">
                                                <Link to={`/citrine/configuration/program/transferCreditsAccepptanceRequirements`}>{transferCreditsAcceptanceRequirement.length} {t('Transfer Credits Accepptance Requirements')}</Link>
                                            </a>
                                        </h4>
                                    </Link>
                                    <Link to="/citrine/configuration/program/transferCreditsAccepptanceRequirements/create/" >
                                        <Button color="info">
                                            {t(' Add')}
                                        </Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Link>
                    </GridItem>
                </GridContainer>
            </GridContainer>

        </div>
    );
}













