import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewPloReport,
  fetchPloReports,
} from "../../reduxToolkit/features/PloReporte/PloReporteSlice";
//import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorModal from "../Components/ErrorModal";

export default function PloReportModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [type, setType] = React.useState("NCAAA");
  const handleChange = (event) => {
    console.log(event.target.value);
    setType(event.target.value);
  };

  const handleSend = async () => {
    let PloReport = {
      program_id: props.ProgramID,
    };
    dispatch(addNewPloReport(PloReport))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        props.handleCloseReportModal();
        dispatch(fetchPloReports());
        history.push(`/citrine/reports/${type}/PLO`);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
    // try {
    //   let PloReport = {
    //     program: props.ProgramID,
    //   }
    //   console.log('PloReport', PloReport)
    //   setUpdateRequestStatus('pending')
    //   const resultAction = await dispatch(
    //     addNewPloReport(PloReport),
    //     props.handleToggle(),
    //     fetchPloReports(),
    //   )
    //   unwrapResult(resultAction)

    //   history.push(`/citrine/CourseImprovementList/`)
    //   const resultAction1 = await dispatch(fetchPloReports())
    //   unwrapResult(resultAction1)
    // } catch (err) {
    //   console.log('Failed to save the CourseReport: ', err)
    // } finally {
    //   history.push(`/citrine/PloImprovementList/`)
    //   fetchPloReports()

    //   setUpdateRequestStatus('idle')
    // }
    // fetchPloReports()
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleCloseReportModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Create PLO Report")}</center>
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <h4>Accreditation body</h4>
            <RadioGroup
              aria-label="Accreditation body"
              name="accreditation_body"
              value={type}
              onChange={handleChange}
            >
              <FormControlLabel
                value="NCAAA"
                control={<Radio size="small" color="primary" />}
                label="NCAAA"
              />
              <FormControlLabel
                value="CTI"
                control={<Radio size="small" color="primary" />}
                label="CTI"
              />
              <FormControlLabel
                value="ABET"
                control={<Radio size="small" color="primary" />}
                label="ABET"
              />
            </RadioGroup>
          </FormControl>
          <DialogActions>
            <Button onClick={props.handleCloseReportModal} color="primary">
              {t(" Cancel")}
            </Button>
            <Button
              onClick={() => {
                props.handleCloseReportModal();
                handleSend();
              }}
              color="primary"
            >
              {t(" Confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
