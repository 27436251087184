import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Form, Input, Radio, Select, notification } from 'antd'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchSections } from 'reduxToolkit/features/Section/SectionSlice'
import ErrorModal from 'views/Components/ErrorModal'
import { addNewActionTypes } from '../../../../reduxToolkit/features/actionType/ActionTypeSlice.js'
import { fetchActionsApprovals } from 'reduxToolkit/features/ActionApproval/ActionApprovalSlice.js'
const { TextArea } = Input
const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 14,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
export default function AddActionTypeConfig() {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [showComponent, setShowComponent] = React.useState(false)
  const { Option } = Select
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleClose = () => {
    history.push(`/citrine/configuration/others/actiontypepage`)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onFinish = async (values) => {
    console.log(values)

    try {
      const resultAction = await dispatch(addNewActionTypes(values))
      unwrapResult(resultAction)
      handleClose()
      form.resetFields()
      openNotificationWithIcon('success', `Added successfully`)
    } catch (err) {
      console.log(err)
    }
  }
  const sections = useSelector((state) => state.section.sections)
  const actionsApprovals = useSelector(
    (state) => state.actionApproval.actionsApprovals,
  )
  useEffect(() => {
    dispatch(fetchActionsApprovals())
    dispatch(fetchSections())
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
  }, [])
  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/actiontypepage`}>
                <span> {t('actionTypePage')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/addActionTypeConfig`}>
                <span> {t('Add ActionTypePage')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <br />
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name.',
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Code.',
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>
                <Form.Item label={t('Category')} name="category">
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Category"
                    //onChange={handleChange}
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    <Option key={1} value={'course'}>
                      {'course'}
                    </Option>
                    <Option key={1} value={'program'}>
                      {'program'}
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item label={t('Section')} name="section_ids">
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Section"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {sections &&
                      sections.map((section) => (
                        <Option key={section.id} value={section.id}>
                          {section.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('Approval Process')}
                  name="actionApprovalProcess"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the approval process.',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Action"
                    dropdownStyle={{ zIndex: 10000 }}
                   
                  >
                    {actionsApprovals?.map((actionApproval) => (
                      <Option key={actionApproval.id} value={actionApproval.id}>
                        {actionApproval.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Generate Version" name="generate_version">
                  <Radio.Group
                    onChange={(e) =>
                      setShowComponent(e.target.value === 'true')
                    }
                  >
                    <Radio value="true"> true </Radio>
                    <Radio value="false"> false </Radio>
                  </Radio.Group>
                </Form.Item>
                <br />
                {showComponent && (
                  <>
                    <Form.Item label="Generate Version" name="version_type">
                      <Radio.Group>
                        <Radio value="major"> major </Radio>
                        <Radio value="minor"> minor </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
