import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import { Breadcrumb } from "antd";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  primaryColor
} from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchAcademicYears } from "../../../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import CreateAcademicYearModal from "./CreateAcademicYearModal.jsx";
import DropDownPlanning from "views/Components/DropDownPlanning";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const AcademicYearExcerpt = ({ academicYear,id }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch();

  return (
    <GridItem xs={12} sm={6} md={4}>
      
      <Card style={{ height: 350 }}>
        <div style={{marginLeft:'350px'}}>
        <DropDownPlanning academicYear={academicYear} id={id}/>
          </div>

        <CardBody>
       
          <GridContainer justify="center">
          
            <GridItem>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${academicYear.id}`}
              >
                <h3 style={{ color: `${primaryColor[0]}`}}>
                  {academicYear.name}
  
                </h3>
              </Link>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem>
              <h4 >
                {`${academicYear.start_date}   /   ${academicYear.end_date}`}
                
              </h4>
            </GridItem>
          </GridContainer>
          {academicYear.active ? (
            <GridContainer justify="center">
              <GridItem>
                <div>Active</div>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer justify="center">
              <GridItem>
                <div>Archived</div>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer justify="center">
            <GridItem>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${academicYear.id}`}
              >
                <Button color="primary">View</Button>
              </Link>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default function AcademicYears() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const academicYearsStatus = useSelector(
    (state) => state.academicYear.academicYearsStatus
  );

  useEffect(() => {
    dispatch(fetchAcademicYears());
  }, [dispatch]);

  const academicYears = useSelector(
    (state) => state.academicYear.academicYears
  );
    
  let content;

  if (academicYearsStatus === "loading") {
    content = <div className="loader">Loading...</div>;
  } else if (academicYearsStatus === "succeeded") {
    content =
      academicYears && academicYears.length
      && academicYears.map((academicYear) => (
        <AcademicYearExcerpt
          key={academicYear.id}
          id={academicYear.id}
          academicYear={academicYear}
        />
      ));
  } else if (academicYearsStatus === "failed") {
    content = <div>An error has accured</div>;
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>{t("Planning")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("Academic Calender")}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/planning/academicCalender/academicYears`}>
            {t("Academic years")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <CreateAcademicYearModal />
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        direction="row"
        spacing={3}
        alignItems="center"
      >
        {content}
      </GridContainer>
    </div>
  );
}
