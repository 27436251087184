import { EditingState } from "@devexpress/dx-react-grid";
import {
    Grid,
    Table, TableEditRow, TableHeaderRow, TableInlineCellEditing
} from "@devexpress/dx-react-grid-material-ui";
// editable array
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ScoreIcon from "@material-ui/icons/Score";
import { unwrapResult } from "@reduxjs/toolkit";
//import Button from "components/CustomButtons/Button.js";
import { Breadcrumb, Button } from "antd";
import "antd/dist/antd.css";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import randomSeed from "../../../../assets/demo-data/random";
import {
    fetchCloById, fetchScoreCLOById, fetchTuplesNumberStudentsByRange
} from "../../../../reduxToolkit/features/CalculNotes/CalculNotesSlice";
import { fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import {
    DataGrid,
    GridColumnGroupingModel
} from "@mui/x-data-grid";









const useStyles = makeStyles(styles);

const useStylesAlert = makeStyles(stylesAlert);

const SurveyQuestionByCloList = ({ }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const classesAlert = useStylesAlert();
    const [columns, setColumns] = useState([
        {
            field: "area",
            headerName: "Area",
            width: 200,
        },
        {
            field: "code",
            headerName: "Code",
            width: 200,
        },
        {
            field: "description",
            headerName: "Description",
            width: 200,
        },
        {
            field: "weight",
            headerName: "Weight",
            width: 200,
        },

        {
            field: "response",
            headerName: "Response",
            width: 200,
        },
        {
            field: "responseweight",
            headerName: "Response Weight",
            width: 200,
        },


    ]);
    const data = [
        {
            template_name: "ces",
            questions: [{
                "id": 1,
                "area": "about instructor",
                "code": "1.1",
                "description": "question 1",
                "weight": "10",
                "response": "5 response",
                "responseweight": "25",
            },
            {
                "id": 2,
                "area": "about instructor",
                "code": "1.2",
                "description": "question 2",
                "weight": "20",
                "response": "5 response",
                "responseweight": "25",
            }]
        }


    ]
    const columnGroupingModel = [

        {
            groupId: 'Template ',
            children: [
                {
                    groupId: 'Area',
                    children: [],
                    groupId: 'Question',
                    children: [
                        {
                            field: "code",
                            headerName: "Code",
                            width: 200,
                        },
                        {
                            field: "description",
                            headerName: "Description",
                            width: 200,
                        },
                        {
                            field: "weight",
                            headerName: "Weight",
                            width: 200,
                        },
                        {
                            groupId: 'Response Type',
                            children: [
                                {
                                    field: "response",
                                    headerName: "Response",
                                    width: 200,
                                },
                                {
                                    field: "responseweight",
                                    headerName: "Weight",
                                    width: 200,
                                },
                            ],
                        },
                    ],

                },
            ],
        },
    ];
    console.log('questions', data[0].questions)
    const rows = [];
    return (
        <div>
            <DataGrid
                //key={key}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupingModel}
                autoHeight
                rows={data && data[0].questions}
                columns={columns}

                pageSize={5}
                rowsPerPageOptions={[5]}
            />
        </div>


    );
};
export default SurveyQuestionByCloList;
