import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
    PEOs_PLO: [],
    PEOs_PLOStatus: 'idle',
    PloWeight: null,
    error: null,
}

export const fetchNotAffectedPLOsToPEO = createAsyncThunk('PLOWeight/fetchNotAffectedPLOsToPEO', async (ids, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + `/weightPloSpec/ploSpec_filtered/?programSpec_id=${ids.programId}&peoSpec_id=${ids.peoId}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }

        )
        console.log(response.data, 'response.data')
        return response.data

    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})



export const addNewPLOWeight = createAsyncThunk(
    'PLOWeight/addNewPLOWeight',
    async (toAdd, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/weightPloSpec/create/`, toAdd, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)

export const DeletePLOWeight = createAsyncThunk(
    'PLOWeight/DeletePLOWeight',
    async (ids, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/weightPloSpec/delete/`, ids, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)
export const EditPEOPLOWeight = createAsyncThunk(
    'PLOWeight/EditPEOPLOWeight',
    async (data, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/weightPlo/peo/`, data, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)




const PLOWeightSlice = createSlice({
    name: 'PLOWeight',
    initialState,
    extraReducers: {

        [fetchNotAffectedPLOsToPEO.pending]: (state, action) => {
            state.PEOs_PLOStatus = 'loading'
        },
        [fetchNotAffectedPLOsToPEO.fulfilled]: (state, action) => {
            state.PEOs_PLOStatus = 'succeeded'
            // Add any fetched posts to the array
            state.PEOs_PLO = action.payload
        },
        [fetchNotAffectedPLOsToPEO.rejected]: (state, action) => {
            state.PEOs_PLOStatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error
                console.log("action.error from inside extra add plo reducer", action.error)
            }
        },
        [EditPEOPLOWeight.pending]: (state, action) => {
            state.PEOs_PLOStatus = 'loading'
          },
          [EditPEOPLOWeight.fulfilled]: (state, action) => {
            state.PEOs_PLOStatus = 'succeeded'
            state.PloWeight = action.payload
          },
          [EditPEOPLOWeight.rejected]: (state, action) => {
            state.PEOs_PLOStatus = 'failed'
            state.error = action.payload
          },
    },

})


export default PLOWeightSlice.reducer


