import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchActualLearningHoursConfig } from 'reduxToolkit/features/ActualLearningHours/ActualLearningConfigSlice';
import { fetchActualLearningHoursConfigType } from 'reduxToolkit/features/ActualLearningHours/ActualLearningHourConfigTypeSlice';
import { fetchAreas } from 'reduxToolkit/features/area/AreaSpecSlice';
import { fetchAssessmentMethods } from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec';
import { fetchAssessmentModes } from 'reduxToolkit/features/assessmentMode/AssessmentModeSpecSlice';
import { fetchCodeLevels } from 'reduxToolkit/features/CodeLevel/CodeLevelConfigSlice';
import { fetchFacilitiesRequiredsConfig } from 'reduxToolkit/features/FacilitiesRequired/FacilitiesRequiredConfigSlice';
import { fetchLevels } from 'reduxToolkit/features/level/LevelSlice';
import { fetchModeOfInstructionConfigs } from 'reduxToolkit/features/modeOfInstructionConfig/ModeOfInstructionConfigSlice';
import { fetchResourceTypeConfigs } from 'reduxToolkit/features/resourceTypeConfig/ResourceTypeConfigSlice';
import { fetchSubAreas } from 'reduxToolkit/features/subarea/SubAreaSpecSlice';
import { fetchSubCodeLevels } from 'reduxToolkit/features/SubCodeLevel/SubCodeLevelConfigSlice';
import { fetchTeachingStrategiesConfig } from 'reduxToolkit/features/TeachingStrategies/TeachingStrategiesConfigSlice';
import { fetchTypes } from 'reduxToolkit/features/type/TypeSlice';
import { fetchVerificationCredibilityStudentsResultsConfigs } from 'reduxToolkit/features/VerificationCredibilityStudentsResultsConfig/VerificationCredibilityStudentsResultsConfigSlice';
import { fetchYearSemesters } from "reduxToolkit/features/yearSemester/YearSemesterSlice";







const useStyles = makeStyles(styles);

export default function CourseConfigurations() {
  const { t } = useTranslation();
  const classes = useStyles();
  const useNavBarStyles = makeStyles(navBarStyles);
  const useTitleStyles = makeStyles(titleStyles);
  const useCardStyles = makeStyles(cardStyles);
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();






  const dispatch = useDispatch()

  //const specializationsStatus = useSelector(state => state.specialization.specializationsStatus)

  const areasStatus = useSelector((state) => state.area.areasStatus)
  const resourceTypeConfigsStatus = useSelector((state) => state.resourceTypeConfig.resourceTypeConfigsStatus)
  const VerificationCredibilityStudentsResultsConfigsStatus = useSelector((state) => state.verificationCredibilityStudentsResultsConfig.VerificationCredibilityStudentsResultsConfigsStatus)
  const subAreasStatus = useSelector((state) => state.subArea.subAreasStatus)
  const yearSemestersStatus = useSelector((state) => state.yearSemesters.yearSemestersStatus)


  const assessmentMethodsStatus = useSelector((state) => state.assessmentMethod.assessmentMethodsStatus)
  const ModeOfInstructionConfigsStatus = useSelector((state) => state.ModeOfInstructionConfig.ModeOfInstructionConfigsStatus)


  useEffect(() => {
    dispatch(fetchAreas())
    dispatch(fetchSubAreas())
    dispatch(fetchResourceTypeConfigs())
    dispatch(fetchVerificationCredibilityStudentsResultsConfigs())
    dispatch(fetchAssessmentMethods())
    dispatch(fetchModeOfInstructionConfigs())
    dispatch(fetchActualLearningHoursConfig())
    dispatch(fetchActualLearningHoursConfigType())
    dispatch(fetchFacilitiesRequiredsConfig())
    dispatch(fetchTeachingStrategiesConfig())
    dispatch(fetchTypes())
    dispatch(fetchLevels())
    dispatch(fetchAssessmentModes())
    dispatch(fetchCodeLevels())
    dispatch(fetchSubCodeLevels())
    dispatch(fetchYearSemesters())
  }, [])
  const areas = useSelector((state) => state.areaSpec.areas)
  const resources = useSelector((state) => state.resourceTypeConfig.resourceTypeConfigs)
  console.log('resource', resources)
  const verifs = useSelector((state) => state.verificationCredibilityStudentsResultsConfig.verificationCredibilityStudentsResultsConfigs)
  console.log('resource', verifs)
  const subAreas = useSelector((state) => state.subAreaSpec.subAreas)
  const modes = useSelector((state) => state.ModeOfInstructionConfig.ModeOfInstructionConfigs)
  const TeachingStrategiesConfig = useSelector((state) => state.TeachingStrategieConfig.TeachingStrategiesApprovementConfig)
  const ActualLearningHoursConfig = useSelector((state) => state.ActualLearningHourConfig.ActualLearningHours)
  const ActualLearningHoursConfigType = useSelector((state) => state.ActualLearningHourConfigType.ActualLearningHoursConfigTypes)
  const FacilitiesRequiredsConfig = useSelector((state) => state.FacilitiesRequiredConfig.FacilitiesRequiredsConfig)
  const assessmentMethods = useSelector((state) => state.assessmentMethod.assessmentMethods)
  const types = useSelector((state) => state.type.types)
  const levels = useSelector((state) => state.level.levels)
  const assessmentModes = useSelector((state) => state.assessmentModeSpec.assessmentModes)
  const codeLevels = useSelector((state) => state.CodeLevel.codeLevels)
  const subCodeLevels = useSelector((state) => state.subLevelClo.SubCodeLevels)
  const yearSemesters = useSelector((state) => state.yearSemesters.yearSemesters)
  return (
    <GridContainer

      md={12}
      xs={12} sm={12}
      direction="row"
      // style={{ width:300 ,height:150}}
      justify="space-between"
      alignItems="center"
    >
      <GridItem>

        <Breadcrumb separator=">">
          <Breadcrumb.Item >

            {t('Configuration')}

          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/configuration/course`}>
              <span > {t('Courses Configuration')} </span>
            </Link>
          </Breadcrumb.Item>

        </Breadcrumb>
      </GridItem>




      <GridContainer>


        <GridItem>
          <Link to={`/citrine/configuration/course/areas/`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }} >

              <CardBody>
                <Link to={`/citrine/configuration/course/areas/`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/course/areas/">
                      <Link to={`/citrine/configuration/course/areas/`}>{areas.length} {t('Areas')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/configuration/course/areas/create" >
                  <Button color="info">
                    {t('Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        <GridItem>
          <Link to={`/citrine/configuration/course/subAreas/`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/configuration/course/subAreas/`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/course/subAreas/">
                      <Link to={`/citrine/configuration/course/subAreas/`}>{subAreas.length} {t('Sub-Areas')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/configuration/course/subAreas/create" >
                  <Button color="info">
                    {t('Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>


        <GridItem>
          <Link to={`/citrine/configuration/course/type`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/configuration/course/type`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/course/type">
                      <Link to={`/citrine/configuration/course/type`}>{types.length}{t(' Types')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/configuration/course/type/createType/:id" >
                  <Button color="info">
                    {t('Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem>
          <Link to={`/citrine/configuration/course/level`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/configuration/course/level`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/course/level">
                      <Link to={`/citrine/configuration/course/level`}>{levels.length} {t('Levels')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/configuration/course/level/createLevel/:id" >
                  <Button color="info">
                    {t(' Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>



        <GridItem>
          <Link to={`/citrine/configuration/course/assessmentMode`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/configuration/course/assessmentMode`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/course/assessmentMode">
                      <Link to={`/citrine/configuration/course/assessmentMode`}>{assessmentModes.length} {t('Assessment Modes')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/configuration/course/assessmentMode/createAssessmentMode/:id" >
                  <Button color="info">
                    {t(' Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        <GridItem>
          <Link to={`/citrine/TeachingStrategiesConfigList`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/TeachingStrategiesConfigList`} >
                  <h4 className={classes.cardProductTitle}>

                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/TeachingStrategiesConfigList">
                      <Link to={`/citrine/TeachingStrategiesConfigList`}>{TeachingStrategiesConfig.length} {t('Teaching Strategies/ Training Activities')} </Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/CreateTeachingStrategiesConfig/:id" >
                  <Button color="info">
                    {t(' Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>



        <GridItem>
          <Link to={`/citrine/ActualLeaningConfigList`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/ActualLeaningConfigList`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/ActualLeaningConfigList">
                      <Link to={`/citrine/ActualLeaningConfigList`}>{ActualLearningHoursConfig.length} {t('Actual Learning Activities')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/CreateActualLeaningConfig/:id" >
                  <Button color="info">
                    {t('Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>



        <GridItem>
          <Link to={`/citrine/ActualLeaningConfigType`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/ActualLeaningConfigType`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/ActualLeaningConfigType">
                      <Link to={`/citrine/ActualLeaningConfigType`}>{ActualLearningHoursConfigType.length} {t('Actual Learning Type')}
                      </Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/CreateActualLeaningConfigType/:id" >
                  <Button color="info">
                    {t('Add')}
                  </Button>


                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem>
          <Link to={`/citrine/verificationCredibilityStudentsResultsConfigs`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/verificationCredibilityStudentsResultsConfigs`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/verificationCredibilityStudentsResultsConfigs">
                      <Link to={`/citrine/verificationCredibilityStudentsResultsConfigs`}>{verifs.length} {t('Verification of Credibility of Student Results')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/createVerificationCredibilityStudentsResultsConfig/:id" >
                  <Button color="info">
                    {t(' Add')}
                  </Button>

                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>


        <GridItem>
          <Link to={`/citrine/FacilitiesRequiredConfigList`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }}>

              <CardBody>
                <Link to={`/citrine/FacilitiesRequiredConfigList`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/FacilitiesRequiredConfigList">
                      <Link to={`/citrine/FacilitiesRequiredConfigList`}>{FacilitiesRequiredsConfig.length} {t('Facilities Required Item')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/CreateFacilitiesRequiredConfig/:id" >
                  <Button color="info">
                    {t('Add')}
                  </Button>

                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>




        <GridItem>
          <Link to={`/citrine/assessmentMethods/`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }} >

              <CardBody>
                <Link to={`/citrine/assessmentMethods/`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/assessmentMethods/">
                      <Link to={`/citrine/assessmentMethods/`}>{assessmentMethods.length} {t('Assessment Methods')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/createAssessmentMethod/:id" >
                  <Button color="info">
                    {t('Add')}
                  </Button>

                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem>
          <Link to={`/citrine/ModeOfInstructionConfigs/`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }} >

              <CardBody>
                <Link to={`/citrine/ModeOfInstructionConfigs/`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/ModeOfInstructionConfigs/">
                      <Link to={`/citrine/ModeOfInstructionConfigs/`}>{modes.length}{t(' Mode Of Instruction')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/createModeOfInstructionConfig/:id" >
                  <Button color="info">
                    {t(' Add')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem>
          <Link to={`/citrine/resourceTypeConfigs/`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }} >

              <CardBody>
                <Link to={`/citrine/resourceTypeConfigs/`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/resourceTypeConfigs/">
                      <Link to={`/citrine/resourceTypeConfigs/`}>{resources.length} {t('Resource Type')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/createResourceTypeConfig/:id" >
                  <Button color="info">
                    Add
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>




        <GridItem>
          <Link to={`/citrine/codeLevels`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }} >

              <CardBody>
                <Link to={`/citrine/codeLevels`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/codeLevels">
                      <Link to={`/citrine/codeLevels`}>{codeLevels.length} {t('Clo Level Code')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/createCodeLevel/:id" >
                  <Button color="info">
                    Add
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        <GridItem>
          <Link to={`/citrine/configuration/course/subCodeLevels`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }} >

              <CardBody>
                <Link to={`/citrine/configuration/course/subCodeLevels`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/course/subCodeLevels">
                      <Link to={`/citrine/configuration/course/subCodeLevels`}>{subCodeLevels.length} {t(' Clo Sub Level Code')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/configuration/course/subCodeLevels/create" >
                  <Button color="info">
                    Add
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        <GridItem>
          <Link to={`/citrine/configuration/course/yearSemesters`} >
            <Card product className={classes.cardHover} style={{ width: 250, height: 140 }} >

              <CardBody>
                <Link to={`/citrine/configuration/course/yearSemesters`} >
                  <h4 className={classes.cardProductTitle}>
                    <a href="#pablo" onClick={e => e.preventDefault()} Link="/citrine/configuration/course/yearSemesters">
                      <Link to={`/citrine/configuration/course/yearSemesters`}>{yearSemesters.length} {t(' Year / Semester')}</Link>
                    </a>
                  </h4>
                </Link>
                <Link to="/citrine/configuration/course/yearSemesters/create" >
                  <Button color="info">
                    Add
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
}