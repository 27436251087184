import { DownOutlined } from "@ant-design/icons";
import { Avatar } from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { SchoolRounded } from "@material-ui/icons";
import { Breadcrumb } from "antd";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import Card from '@material-ui/core/Card';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchGraduateAttributeById, Score_Question_GA } from "reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";
//chartist
import Ballot from "@material-ui/icons/Ballot";
import { simpleBarChart } from "variables/charts.js";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { fetchCourses } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import WeightGAIndicatorModal from "../../../../views/Components/WeightGAIndicatorModal";
import WeightCloGAIndictoorsModal from "../../../Components/WeightCloGAIndictoorsModal";


import { unwrapResult } from "@reduxjs/toolkit";


const useStyles3 = makeStyles(styles1);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

export default function GADetails({ match }) {
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = match.params;
  const { idGA } = match.params;

  const [scoreGA, setScoreGA] = useState("");

  let obj = {};
  useEffect(() => {
    dispatch(fetchProgramById(id));
    dispatch(fetchGraduateAttributeById(idGA));

    dispatch(Score_Question_GA({ id: idGA }))
      .then(unwrapResult)
      .then((score) => {
        setScoreGA(score.score_by_question);
      });
  }, [dispatch]);

  const program = useSelector((state) => state.program.program);
  const GraduateAttribute = useSelector(
    (state) => state.GraduateAttribute.GraduateAttribute
  );

  const coursesStatus = useSelector((state) => state.course.coursesStatus);
  useEffect(() => {
    if (coursesStatus === "idle") {
      dispatch(fetchCourses());
    }
  }, [coursesStatus, dispatch]);
  const courses = useSelector((state) => state.course.courses);
  const error = useSelector((state) => state.course.error);
  const score = useSelector((state) => state.ploIndicator.ScorePloIndicator);
  var dataHistoricPlo = {
    labels: ["2016", "2017", "2018", "2019", "2020", "2021"],
    series: [
      [3.2, 3, 4.3, 3, 4.7, 5],
      [3.4, 3.1, 4.2, 3.8, 4.5, 3.6],
    ],
  };
  var dataHistoricPloIndicators = {
    labels: ["2016", "2017", "2018", "2019", "2020", "2021"],
    series: [
      [3.2, 3, 4.3, 3, 4.7, 5],
      [3.4, 3.1, 4.2, 3.8, 4.5, 3.6],
    ],
  };
  const [shownClos, setShownClos] = useState({});

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log("res");
  };
  const toggleClo = (id) => {
    setShownClos((prevShownClos) => ({
      ...prevShownClos,
      [id]: !prevShownClos[id],
    }));
  };

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">" params={id}>
          <Breadcrumb.Item>
            <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/program/${id}`}>
              {program &&
                program.program_degree_name &&
                program.program_degree_name}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/program/${id}/GAListPage`}>
              {t("Graduate Attributes  List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/program/${id}/GA/${idGA}`}
              style={{ color: "orange" }}
            >
              {t("GA Details")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <br></br>
      <Card>
        <CardHeader
          color="info"
          icon
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CardIcon
            color="primary"
            style={{ height: "60px", marginTop: "3px" }}
          >
            <SchoolRounded />
          </CardIcon>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <Link to={`/citrine/programs_assessment`}>
                  <h4
                    style={{
                      color: "#8F6682",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>{program && program.code}</strong>
                  </h4>
                </Link>
                <h4
                  style={{
                    color: "#6C7B9D",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong> {t("University")}</strong>
                </h4>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <div>
                <Link to={`/citrine/programs_assessment`}>
                  <h4
                    style={{
                      color: "#8F6682",
                      fontSize: "14px",
                      height: "13px",
                    }}
                  >
                    <strong>
                      {program &&
                        program.program_degree_name &&
                        program.program_degree_name}
                    </strong>
                  </h4>
                </Link>
                <h4
                  style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                >
                  <strong>
                    {program &&
                      program.university_detail &&
                      program.university_detail.map((el) => el.name)}
                  </strong>
                </h4>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <h4
                  style={{
                    color: "orange",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong> {t("College")}</strong>
                </h4>
                <h4
                  style={{
                    color: "grey",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong> {t("Department")}</strong>
                </h4>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={8}>
              <div>
                <h4
                  style={{ color: "orange", fontSize: "14px", height: "13px" }}
                >
                  <strong>
                    {program &&
                      program.college_detail &&
                      program.college_detail.map((el) => el.name)}
                  </strong>
                </h4>
                <h4 style={{ color: "grey", fontSize: "14px", height: "13px" }}>
                  <strong>
                    {program &&
                      program.department_detail &&
                      program.department_detail.map((el) => el.name)}
                  </strong>
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
      <br></br>

      <Card>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
            <strong>
              <p style={{ color: "black", fontSize: "18px" }}>
                {" "}
                {t("Graduate Attribute")}{" "}
              </p>
            </strong>
            <strong>
              <p style={{ color: "gray", fontSize: "16px" }}>
                {GraduateAttribute && GraduateAttribute.graduateAttributeConfig_details.name}
              </p>
            </strong>

            {/* {program && program.assessment_calculation_method === "clo" ? ( */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginEnd: "10px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: red[600],
                      height: 56,
                      width: 56,
                    }}
                    style={{ backgroundColor: "#6C7B9D" }}
                  >
                    {GraduateAttribute &&
                      GraduateAttribute.target &&
                      GraduateAttribute.target}
                  </Avatar>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {" "}
                    {t("target")}
                  </p>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: orange[600],
                      height: 56,
                      width: 56,
                    }}
                    style={{ backgroundColor: "#6C7B9D" }}
                  >
                    {GraduateAttribute &&
                      GraduateAttribute.gaIndicators_count &&
                      GraduateAttribute.gaIndicators_count}
                  </Avatar>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {" "}
                    {t("GA Indicators")}
                  </p>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: "info",
                      height: 56,
                      width: 56,
                    }}
                    style={{ backgroundColor: "#6C7B9D" }}
                  >
                    {GraduateAttribute &&
                      GraduateAttribute.clos_count &&
                      GraduateAttribute.clos_count}
                  </Avatar>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {" "}
                    {t("Aligned CLOs")}
                  </p>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: green[600],
                      height: 56,
                      width: 56,
                    }}
                    style={{ backgroundColor: "#6C7B9D" }}
                  >
                    {GraduateAttribute &&
                      GraduateAttribute.g_a_score &&
                      GraduateAttribute.g_a_score.toFixed(2)}
                  </Avatar>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {" "}
                    {t("Score")}
                  </p>
                </Grid>
              </div>
            {/* ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginEnd: "10px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: red[600],
                      height: 56,
                      width: 56,
                    }}
                    style={{ backgroundColor: "#6C7B9D" }}
                  >
                    {GraduateAttribute &&
                      GraduateAttribute.target &&
                      GraduateAttribute.target}
                  </Avatar>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {" "}
                    {t("Target")}
                  </p>
                </Grid>

                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: "info",
                      height: 56,
                      width: 56,
                    }}
                    style={{ backgroundColor: "#6C7B9D" }}
                  >
                    {GraduateAttribute &&
                      GraduateAttribute.plos_count &&
                      GraduateAttribute.plos_count}
                  </Avatar>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {" "}
                    {t("Aligned PLOs")}
                  </p>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: green[600],
                      height: 56,
                      width: 56,
                    }}
                    style={{ backgroundColor: "#6C7B9D" }}
                  >
                    {scoreGA && scoreGA.toFixed(0)}%
                  </Avatar>
                  <p
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {" "}
                    {t("Score GA")}
                  </p>
                </Grid>
              </div>
            )} */}
          </GridItem>

          <GridItem xs={12} sm={12} md={6} style={{ padding: "15px" }}>
            <ChartistGraph
              className="ct-chart-white-colors"
              data={dataHistoricPlo}
              type="Bar"
              options={simpleBarChart.options}
              responsiveOptions={simpleBarChart.responsiveOptions}
              listener={simpleBarChart.animation}
              style={{ backgroundColor: "#EA9E4D" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#EA9E4D",
              }}
            >
              <p
                style={{
                  backgroundColor: "aliceblue",
                  width: "30px",
                  marginTop: "7px",
                  height: "7px",
                }}
              ></p>
              <p style={{ marginRight: "10px" }}> {t("Score")}</p>
              <p
                style={{
                  backgroundColor: "#e94e49",
                  width: "30px",
                  marginTop: "7px",
                  height: "7px",
                }}
              ></p>
              <p> {t("Objective")}</p>
            </div>
          </GridItem>
        </GridContainer>
      </Card>

      {/* {program && program.assessment_calculation_method === "clo" ? ( */}
        <GridContainer xs={12} sm={12} md={12}>
          {GraduateAttribute &&
            GraduateAttribute.gaIndicators &&
            GraduateAttribute.gaIndicators.map((obj, i) => (
              <div key={obj.id} style={{ width: "100%", marginRight: "10px" }}>
                <Card key={i}>
                  <CardBody>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={6}>
                        <strong>
                          {" "}
                          {t("GA_Indicator")} {i}
                        </strong>
                        <Link to={`/citrine/PloListPage`}>
                          <strong>
                            <p
                              style={{
                                color: "gray",
                                textAlign: "start",
                                fontSize: "14px",
                              }}
                            >
                              <Link to={`/citrine/programs_assessment`}>
                                <Tooltip
                                  id="tooltip-top"
                                  title={obj.name}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                  style={{ fontSize: "14px" }}
                                >
                                  <div simple color="info" justIcon>
                                    {obj.name}
                                  </div>
                                </Tooltip>
                              </Link>
                            </p>
                          </strong>
                        </Link>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginEnd: "10px",
                          }}
                        >
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <strong
                              style={{ display: "flex", marginTop: "10px" }}
                            >
                              {obj.weight_ga}%
                              <p>
                                <WeightGAIndicatorModal
                                  handleMenuItemClick={handleMenuItemClick}
                                  GAIndicator={obj}
                                  GAId={idGA}
                                  GAIndicatorID={obj.id}
                                  PrgramId={id}
                                  handleToggle={handleToggle}
                                />
                              </p>
                            </strong>
                            <p
                              style={{
                                marginTop: "10px",
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              {" "}
                              {t("weight")}
                            </p>
                          </Grid>

                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{
                                backgroundColor: red[600],
                                height: 56,
                                width: 56,
                              }}
                              style={{ backgroundColor: "#6C7B9D" }}
                            >
                              {obj.target}
                            </Avatar>
                            <p
                              style={{
                                marginTop: "10px",
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              {" "}
                              {t("Target")}
                            </p>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{
                                backgroundColor: "info",
                                height: 56,
                                width: 56,
                              }}
                              style={{ backgroundColor: "#6C7B9D" }}
                            >
                              {obj.alignment_clos}
                            </Avatar>
                            <p
                              style={{
                                marginTop: "10px",
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              {" "}
                              {t("Aligned CLOs")}
                            </p>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{
                                backgroundColor: green[600],
                                height: 56,
                                width: 56,
                              }}
                              style={{ backgroundColor: "#6C7B9D" }}
                            >
                              {obj.g_a_indicators_score}
                            </Avatar>
                            <p
                              style={{
                                marginTop: "10px",
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              {" "}
                              {t("Score")}
                            </p>
                          </Grid>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer xs={12} sm={12} md={12}>
                      {shownClos[obj.id] ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={8}>
                            {obj.clos.map((el) => (
                              <GridContainer xs={12} sm={12} md={12}>
                                <GridItem xs={12} sm={12} md={8}>
                                  <ul>
                                    <li style={{ color: "gray" }}>
                                      {el.code + " :" + el.title}
                                    </li>
                                  </ul>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}></GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                  <strong style={{ display: "flex" }}>
                                    <p>{el.weight_ga}%</p>
                                    <p>
                                      <WeightCloGAIndictoorsModal
                                        handleMenuItemClick={
                                          handleMenuItemClick
                                        }
                                        cloID={el.id}
                                        clo={el}
                                        GAId={idGA}
                                        GAIndicatorID={obj.id}
                                        PrgramId={id}
                                        handleToggle={handleToggle}
                                      />
                                    </p>
                                  </strong>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                  <strong>
                                    <p>
                                      {el &&
                                        el.score_by_clo &&
                                        el.score_by_clo.map(
                                          (item) => item.score_by_clo
                                        )}
                                    </p>
                                  </strong>
                                </GridItem>
                              </GridContainer>
                            ))}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <div>
                              <ChartistGraph
                                className="ct-chart-white-colors"
                                data={dataHistoricPlo}
                                type="Bar"
                                options={simpleBarChart.options}
                                responsiveOptions={
                                  simpleBarChart.responsiveOptions
                                }
                                listener={simpleBarChart.animation}
                                style={{ backgroundColor: "#EA9E4D" }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  backgroundColor: "#EA9E4D",
                                }}
                              >
                                <p
                                  style={{
                                    backgroundColor: "aliceblue",
                                    width: "30px",
                                    marginTop: "7px",
                                    height: "7px",
                                  }}
                                ></p>
                                <p style={{ marginRight: "10px" }}>
                                  {" "}
                                  {t("Score")}
                                </p>
                                <p
                                  style={{
                                    backgroundColor: "#e94e49",
                                    width: "30px",
                                    marginTop: "7px",
                                    height: "7px",
                                  }}
                                ></p>
                                <p> {t("Objective")}</p>
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                    </GridContainer>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={10}></GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginRight: "10px",
                            marginBottom: "0px",
                          }}
                        >
                          {obj.clos ? (
                            <DownOutlined onClick={() => toggleClo(obj.id)} />
                          ) : null}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            ))}
        </GridContainer>
      {/* ) : (
        <GridContainer xs={12} sm={12} md={12}>
          {GraduateAttribute &&
            GraduateAttribute.plos &&
            GraduateAttribute.plos.map((obj, i) => (
              <GridItem xs={12} sm={12} md={4} key={i}>
                <Card>
                  <CardHeader>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={3}>
                        <div
                          style={{
                            backgroundColor: "#8F6682",
                            height: "60px",
                            width: "60px",
                            padding: "0px",
                            borderRadius: "3px",
                            textAlign: "center",
                          }}
                        >
                          <Ballot
                            style={{
                              fontSize: 50,
                              color: "aliceblue",
                              marginTop: "10%",
                            }}
                          />
                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={9}>
                        <strong>
                          {t("PLO")} {i}
                        </strong>

                        <strong>
                          <p
                            style={{
                              color: "gray",
                              textAlign: "start",
                              fontSize: "14px",
                            }}
                          >
                            <Tooltip
                              id="tooltip-top"
                              title={obj.name}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                              style={{ fontSize: "14px" }}
                            >
                              <div simple color="info" justIcon>
                                {obj.name}
                              </div>
                            </Tooltip>
                          </p>
                        </strong>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>

                  <CardBody>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginEnd: "10px",
                          }}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              style={{
                                backgroundColor: "#6C7B9D",
                                width: "48px",
                                height: "48px",
                              }}
                            >
                              {obj.target}
                            </Avatar>
                            <p
                              style={{
                                marginTop: "10px",
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              {t("Target")}
                            </p>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              style={{
                                backgroundColor: "#6C7B9D",
                                width: "48px",
                                height: "48px",
                              }}
                            >
                              {obj.score_by_question &&
                                obj.score_by_question.toFixed(0)}{" "}
                              %
                            </Avatar>
                            <p
                              style={{
                                marginTop: "10px",
                                fontSize: "14px",
                                color: "gray",
                              }}
                            >
                              {t("score PLO")}
                            </p>
                          </Grid>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      )} */}
      <br></br>
    </div>
  );
}
