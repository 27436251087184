import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  KpiStandards: [],
  KpiStandardsStatus: 'idle',
  KpiStandard: null,
  KpiStandardStatus: 'idle',
  error: null,
}

export const fetchKpiStandards = createAsyncThunk(
  'KpiStandard/fetchKpiStandards',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/kpiStandardConfigType/`, {
        headers: { Authorization: `token ${token}` },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchKpiStandardById = createAsyncThunk(
  'KpiStandard/fetchKpiStandardById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(
        BaseUrl + `/kpiStandardConfigType/${id}/`,
        {
          headers: { Authorization: `token ${token}` },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewKpiStandard = createAsyncThunk('KpiStandard/addNewKpiStandard',async (KpiStandard, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post(BaseUrl + `/kpiStandardConfigType/create/`,KpiStandard,{headers: {Authorization: `token ${token}`}})
        return data
      } catch (err) {
        let error = err
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
      }
    },
)

export const deleteKpiStandard = createAsyncThunk('KpiStandard/deleteNewKpiStandard',async (id, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem('token')
      const {data} = await axios.delete(BaseUrl + `/kpiStandardConfigType/${id}/delete/`, {headers: {Authorization: `token ${token}`}})
      return data
    } catch (err) {
        let error = err
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
    }
    },
)

export const updateKpiStandard = createAsyncThunk('KpiStandard/updateKpiStandard',async (KpiStandard, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.put(BaseUrl + `/kpiStandardConfigType/${KpiStandard.id}/edit/`,KpiStandard,{headers: {Authorization: `token ${token}`}})
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const KpiStandardSlice = createSlice({
  name: 'KpiStandard',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchKpiStandards.pending]: (state, action) => {
      state.KpiStandardsStatus = 'loading'
    },
    [fetchKpiStandards.fulfilled]: (state, action) => {
      state.KpiStandardsStatus = 'succeeded'
      state.KpiStandards = action.payload
    },
    [fetchKpiStandards.rejected]: (state, action) => {
      state.KpiStandardsStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },

    [fetchKpiStandardById.pending]: (state, action) => {
      state.KpiStandardStatus = 'loading'
    },
    [fetchKpiStandardById.fulfilled]: (state, action) => {
      state.KpiStandardStatus = 'succeeded'
      state.KpiStandard = action.payload
    },
    [fetchKpiStandardById.rejected]: (state, action) => {
      state.KpiStandardStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },
  },
})

export default KpiStandardSlice.reducer