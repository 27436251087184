import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';
const initialState = {
    CourseContents: [],
    CourseContenttatus: 'idle',
    CourseContentStatus: 'idle',
    error: null,
    CourseContent: null,

    id: null
}


export const fetchCourseContents = createAsyncThunk(
  'CourseContent/fetchCourseContents', async (course_id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/courseContentSpec/?courseSpec=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    console.log(response.data, 'response.data')

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

  export const fetchCourseContentById = createAsyncThunk(
    'CourseContent/fetchCourseContentById', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/courseContentSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewCourseContent = createAsyncThunk(
    'CourseContent/addNewCourseContent',
    async (initialCourseContent, { rejectWithValue }) => {
        console.log('initial CourseContent', initialCourseContent)
        const token=localStorage.getItem('token')
        try {
        const response = await axios.post(BaseUrl+`/courseContentSpec/create/`, initialCourseContent, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
  export const CourseContentUpdate = createAsyncThunk(
    'CourseContent/CourseContentUpdate',
    async (data, { rejectWithValue }) => {
        const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/courseContentSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedCourseContent')
      console.log(response.data,'response.data')   
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseContent = createAsyncThunk(
  'CourseContent/deleteCourseContent', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(BaseUrl+`/courseContentSpec/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
     console.log(response.data,' delete response.data')

     return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const CourseContentSlice = createSlice({
    name: 'CourseContent',
    initialState,

    extraReducers: {
        [fetchCourseContents.pending]: (state, action) => {
            state.CourseContentStatus = 'loading'
        },
        [fetchCourseContents.fulfilled]: (state, action) => {
            state.CourseContentStatus = 'succeeded'
            // Add any fetched posts to the array
            state.CourseContents = action.payload
        },
        [fetchCourseContents.rejected]: (state, action) => {
            state.CourseContentStatus = 'failed'
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },

          [fetchCourseContentById.pending]: (state, action) => {
            state.CourseContentStatus = 'loading'
          },
          [fetchCourseContentById.fulfilled]: (state, action) => {
            state.CourseContentStatus = 'succeeded'

            // Add any fetched posts to the array
            state.CourseContent = action.payload
          },
          [fetchCourseContentById.rejected]: (state, action) => {
            state.CourseContentStatus = 'failed'
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },
        [addNewCourseContent.fulfilled]: (state, action) => {
            state.CourseContents.push(action.payload)
        },
        [deleteCourseContent.fulfilled]: (state, action) => {
           // state.SpecificationAprovalDatas.push(action.payload)
        },
        [addNewCourseContent.rejected]: (state, action) => {
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
        },
      [deleteCourseContent.rejected]: (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
          [CourseContentUpdate.fulfilled]: (state, action) => {
            console.log(action) 
          },
          [CourseContentUpdate.rejected]: (state, action) => {
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },



        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },


            // CourseContentUpdated(state, action) {
            //     const { id, name } = action.payload
            //     const existingCourseContent = state.CourseContents.find(CourseContent => CourseContent.id === id)
            //     if (existingCourseContent) {
            //         existingCourseContent.name = name


            //     }
            // }
        }
    }
})

export const {
    //CourseContentAdded, 
    //CourseContentUpdated, 
    getId } = CourseContentSlice.actions

export default CourseContentSlice.reducer


