import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
// import { getCourseId } from "../../reduxToolkit/features/course/CoursesSlice"
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  deleteKpiReport,
  fetchKpiReports,
} from "../../reduxToolkit/features/KpiReport/KpiReportSlice.js";
import { useTranslation } from "react-i18next";
import AccessAndRoleModal from "./AccessAndRoleModal";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal.js";
import { ListItemText } from "@material-ui/core";

const useButtonStyles = makeStyles(buttonStyles);

export default function DropDownButtonKpiReport(props) {
  const { t } = useTranslation();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);

  const dispatch = useDispatch();

  const delete_KPIReport = (id) => {
    console.log("click for delete");
    try {
      dispatch(deleteKpiReport(id))
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchKpiReports())
            .then(unwrapResult)
            .then((KPIReports) => {
              props.KpiWorkFlow(KPIReports);
            });
        });
    } catch (err) {
      console.log("Failed to delete  the delete KPI Reports : ", err);
    } finally {
    }
  };
  /*******************************************************************/
  React.useEffect(() => {
    dispatch(fetchStaff());
  }, [dispatch]);

  const staffStatus = useSelector((state) => state.user.staffStatus);

  const staffList = useSelector((state) => state.user.staffList);
  /******************************************************************/
  /******************************************************************
   *                          Handle Menu
   *****************************************************************/
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosebtn = () => {
    setAnchorEl(null);
  };
  //const [op_en, setOpen] = React.useState(Boolean(anchorEl));
  const op_en = Boolean(anchorEl);
  // console.log(anchorEl)
  /*****************************************************************/
  return (
    <span>
      <Button
        info
        round
        ref={anchorRef}
        aria-controls={op_en ? "basic-menu" : undefined}
        aria-expanded={op_en ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="true"
        onClick={handleClick}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
        id="basic-button"
      >
        <MoreVert />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={op_en}
        onClose={handleClosebtn}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <AccessAndRoleModal
          id={props.id}
          handleDropDownButtonClose={handleClosebtn}
          NameOfComponentUsed="KPIs-Report"
          interface="reports"
          disabled={false}
          data={props.data}
          staffList={staffList}
          fetchKPIReports={props.fetchKPIReports}
        />

        <MenuList id="basic-menu">
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal();
            //handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
        <SuperGeneralDeleteModal
           delete_Element={delete_KPIReport}
           Element_ID={props.KpiReport_ID}
           open={open}
           handleClose={handleCloseDeleteModal}
        />
          {/* <MenuItem>
            <p onClick={() => delete_KPIReport(props.KpiReport_ID)}>
              {t("Delete")}
            </p>
          </MenuItem> */}
        </MenuList>
      </Menu>
    </span>
  );
}
