import React, {useState, useEffect, useRef} from 'react'
import Button from '../../../../components/CustomButtons/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ListItemText from '@material-ui/core/ListItemText'
import { notification } from 'antd'
import Menu from '@material-ui/core/Menu'
import { Form } from 'antd'

import { withStyles } from '@material-ui/core/styles'
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import { fetchAllResearchAndInnovationActivities, deleteResearchAndInnovationActivity } from '../../../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice'
import {unwrapResult} from "@reduxjs/toolkit";


const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)

const { Option } = Select

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const ActivityDown = ({ selected, modelSelected}) => {

  const { t } = useTranslation()
  const buttonClasses = useButtonStyles()

  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openActivity, setOpenActivity] = useState(false)
  const [handledError, setHandledError] = React.useState('')

  const dispatch = useDispatch()

  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const [form] = Form.useForm()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDeleteActivityModal = () => {
    setOpenActivity(true)
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleCloseDeleteActivityModal = () => {
    setOpenActivity(false)
  }

  const handleDeleteItem = async () => {
    try {
      const resultAction = await dispatch(
          deleteResearchAndInnovationActivity(modelSelected[0]),
      )
      unwrapResult(resultAction)
      handleClose()
      openNotificationWithIcon('success', `Deleted`)
      window.location.reload();
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    Height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={selected} onClick={handleClose}>
          <Link
            to={`/citrine/faculty/teachingStaff/researchAndInnovationActivities/update/${modelSelected[0]}`}
          >
            <ListItemText style={{ color: 'black' }} primary="Edit" />
          </Link>
        </MenuItem>
        <MenuItem
            disabled={selected}
            onClick={() => {
              handleOpenDeleteActivityModal()
              handleClose()
            }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
      />
      <SuperGeneralDeleteModal
          delete_Element={handleDeleteItem}
          //Element_ID={id}
          open={openActivity}
          handleClose={handleCloseDeleteActivityModal}
      />
    </div>
  )
}

export default ActivityDown
