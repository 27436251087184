import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import Search from '@material-ui/icons/Search'
import { unwrapResult } from '@reduxjs/toolkit'
import { notification } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { grayColor } from '../../../assets/jss/material-dashboard-pro-react'
import {
  deleteSurveyTemplate,
  fetchSurveyTemplates,
} from '../../../reduxToolkit/features/Survey/SurveySlice'
import SuperGeneralDeleteModal from '../../Components/SuperGeneralDeleteModal'
import EditTemplateModal from './EditTemplateModal'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const SurveyTemplateDropDownButton = ({ template }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleOpenEditModal = () => {
    setOpenEdit(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseEdit = ()=>{
    setOpenEdit(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleDelete = async () => {
    dispatch(deleteSurveyTemplate(template.id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon('success', `Deleted`)
        dispatch(fetchSurveyTemplates())
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        openNotificationWithIcon(
          'error',
          `${rejectedValueOrSerializedError?.statusText} Cannot delete SurveyTemplate because they are referenced through protected foreign keys`,
        )
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push(`/citrine/surveyTemplates/${template.id}/editor`)
          }}
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenEditModal();
            handleClose()
          }}
        >
          {t('Edit mail template')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
          }}
        >
          <Link
            to={`/citrine/surveyTemplates/${template.id}/addWeightToQuestions`}
          >
            <ListItemText primary="Add weight to questions" />
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
          }}
        >
          <Link to={`/citrine/surveyTemplates/${template.id}/editWeights`}>
            <ListItemText primary="Edit weights" />
          </Link>
        </MenuItem>
      </StyledMenu>
      <SuperGeneralDeleteModal
        delete_Element={handleDelete}
        Element_ID={template.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
      <EditTemplateModal
         open={openEdit}
         handleClose={handleCloseEdit}
         id={template.id}
      />
    </div>
  )
}

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const TemplateExcerpt = ({ template }) => {

  const cardClasses = useCardStyles()

  return (
    <GridItem xs={12} sm={12} md={4} key={template.id}>
      <Card
        style={{ maxWidth: '380px', minWidth: '380px', height: 150 }}
        cardHover
      >
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/surveyTemplates/${template.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {`${template?.surveyTemplateTypeConfig_detail?.category} survey template : ${template?.surveyTemplateTypeConfig_detail?.name} `}
                </h4>
                <h4 className={cardClasses.cardProductTitle}>
                  {`${template?.name}`}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <SurveyTemplateDropDownButton template={template} />
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
    </GridItem>
  )
}

export default function SurveyTemplates() {

  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const dispatch = useDispatch();

  const surveyTemplatesStatus = useSelector((state) => state.survey.surveyTemplatesStatus,)

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  useEffect(() => {
    if (surveyTemplatesStatus === 'idle') {
      dispatch(fetchSurveyTemplates())
    }
  }, [])

  const surveyTemplates = useSelector((state) => state.survey.surveyTemplates)

  let content

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  if (surveyTemplatesStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (surveyTemplatesStatus === 'succeeded') {
    content =
      surveyTemplates &&
      surveyTemplates
        ?.filter((template) => {
          if (search === '') {
            return template
          } else if (
            template?.name?.toLowerCase()?.includes(search?.toLowerCase())
          ) {
            return template
          }
        })
        ?.map((template) => (
          <TemplateExcerpt key={template.id} template={template} />
        ))
  }
  return (
    <div>
      <GridContainer
        alignItems="center"
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
          minHeight: '50px',
          maxHeight: '50px',
        }}
      >
        <GridItem>
          <Link to={`/citrine/surveyTemplatesNavigationPage`}>
            <span className={titleClasses.title}>
              {' '}
              {t('Survey templates ')}{' '}
            </span>
          </Link>
        </GridItem>
        <GridItem>
          <Link to={`/citrine/surveyTemplates`}>
            <span className={titleClasses.title}>{t('List')}</span>
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer justify="space-between" alignItems="center">
        <GridItem>
          <Link to="/citrine/surveyTemplates/add">
            <Button color="primary">{t('Add')}</Button>
          </Link>
        </GridItem>
        <GridItem>
          <CustomInput
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value)
              },
              value: search,
              placeholder: 'Search',
            }}
          />
          <Button color="white" justIcon round className={searchButton}>
            <Search
              className={
                navBarClasses.headerLinksSvg + ' ' + navBarClasses.searchIcon
              }
            />
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>{content}</GridContainer>
    </div>
  )
}
