import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Input, InputNumber, notification, Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewPEOReportDocument, fetchPEOReportDocumentsByProgramId } from "reduxToolkit/features/PEOReportDocument/PEOReportDocumentSlice";
import ErrorModal from "./ErrorModal.jsx";
import Stack from "@mui/material/Stack";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));

const layout = {
    labelCol: {
        span: 3,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 10,
        span: 16,
    },
};

export default function CreateReportPEOImprovementDocument(props) {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        const resultAction = await dispatch(
            addNewPEOReportDocument({
                ...values,
                ressource: "peoImprovement",
                ressourceId: props.programId,
            })
        );

        if (
            addNewPEOReportDocument.fulfilled.match(resultAction)
        ) {
            openNotificationWithIcon("success", `Added successfully`);
            dispatch(
                fetchPEOReportDocumentsByProgramId(
                    props.programId
                )
            );
            handleClose();
        } else {
            if (resultAction.payload) {
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        }
    };

    return (
        <div style={{backgroundColor: 'rgba(128,128,128,0.22)'}}>
            <MenuItem
                color="primary"
                onClick={(event) => {
                    handleClickOpen(event);
                }}
            >
                <Stack direction="row" spacing={2}>
                    <AddIcon />
                    <span style={{fontSize: 13}}>{props.title}</span>
                </Stack>
            </MenuItem>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth="true"
            >
                <DialogTitle id="form-dialog-title">
                    {" "}
                    Create PEOs Improvement Document Report
                </DialogTitle>
                <DialogContent>
                    <Form
                        {...layout}
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="name"
                            label="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="version"
                            label="version"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button
                                style={{ marginRight: "20px" }}
                                color="primary"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button type="primary" color="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </DialogContent>
            </Dialog>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    );
}
