import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import { unwrapResult } from '@reduxjs/toolkit'
import CustomInput from "components/CustomInput/CustomInput.js";
import { updatePloIndicator } from '../../reduxToolkit/features/PLOIndicator/PLOIndicatorsSlice'
import { fetchPloById } from '../../reduxToolkit/features/PLO/PLOsSlice'
import { useTranslation } from 'react-i18next';

export default function WeightploIndicatorModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const [weight, setWeight] = useState()

    const handleSend = async () => {
        if (props.ploIndicator.id) {
            try {
                const resultAction = await dispatch(
                    updatePloIndicator({ id: props.ploIndicator.id, updatedPloIndicator: { weight, plo_id: props.ploID } }),
                )
                unwrapResult(resultAction)

            } catch (err) {

                console.log('Failed to save the clo : ', err)
            } finally {
                dispatch(fetchPloById(props.ploID))
                setWeight(null)

            }
        }
    }

    return (
        <Dialog open={props.openEditPLOIndWeightModal} onClose={props.handleCloseEditPLOIndWeightModal}>
            <DialogTitle>  {t(`Edit PLO indicator's Weight `)} </DialogTitle>
            <DialogContent>
                <form>
                    <CustomInput
                        labelText={t('weight*')}
                        id="weight"
                        type="text"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a weight ',
                            },
                        ]}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => {
                                setWeight(event.target.value);
                            },
                            value: weight
                        }}
                    />

                </form>

                <DialogActions>
                    <Button onClick={props.handleCloseEditPLOIndWeightModal} color="info">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={() => { props.handleCloseEditPLOIndWeightModal(); handleSend() }} color="info">
                        {t(' Save')}
                    </Button>
                </DialogActions>

            </DialogContent>

        </Dialog>
    );
}

