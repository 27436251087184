import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
//import { DataGrid,GridRowId } from '@material-ui/data-grid'
import { DataGrid } from '@mui/x-data-grid'
import Chip from '@material-ui/core/Chip'
import { Box, Typography } from '@mui/material/'
import { Breadcrumb } from 'antd'
import 'assets/css/styles.css'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchModels } from 'reduxToolkit/features/model/ModelSlice'
import { fetchUserGroups } from 'reduxToolkit/features/userGroups/UserGroupSlice'
import ModelDown from './ModelDown'
import EditSectionModal from './EditSectionModal'
import SuperGeneralDeleteModal from '../../Components/SuperGeneralDeleteModal'
import ErrorModal from 'views/Components/ErrorModal'
import { deleteSection } from 'reduxToolkit/features/Section/SectionSlice'
import { notification } from 'antd'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.2),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.2),
  },
}))


export default function GroupList() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [selectionModel, setSelectionModel] = useState([])
  const [selected, setSelected] = useState(true)
  const [open, setOpen] = useState(false)
  const [openEditSectionModal, setOpenEditSectionModal] = useState(false)
  const [sectionId, setSectionId] = useState('')
  const [modelId, setModelId] = useState('')
  const [sectionIdDelete, setSectionIdDelete] = useState('')
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [ping, setPing] = useState(false)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const handleClickOpenEditSectionModal = () => {
    setOpenEditSectionModal(true)
  }
  const handleCloseEditSectionModal = () => {
    setOpenEditSectionModal(false)
  }

  const onSelectionModelChange = (newSelectionModel, details) => {
    if (newSelectionModel.length > 0) {
      setSelected(false)
    } else {
      setSelected(true)
    }
    setSelectionModel((prevModel) =>
      newSelectionModel.filter((newId) => !prevModel.includes(newId)),
    )
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const handleDeleteSection = () => {
    console.log(sectionIdDelete)
    dispatch(deleteSection(sectionIdDelete))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchModels())
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  useEffect(() => {
    dispatch(fetchUserGroups())
    dispatch(fetchModels())
  }, [])

  const groups = useSelector((state) => state.userGroup.userGroups)
  const models = useSelector((state) => state.model.models)



  const columns = [
    {
      field: 'name',
      headerName: 'Model Name',
      width: 220,
      sortable: false,
    },
    {
      field: 'access',
      headerName: 'Access  Control Fields',
      minWidth: 480,
      sortable: false,
      renderCell: ({ row }) => {
        let read
        let update

        // if(row.modelsPermissions!=null){
        //   console.log(row.modelsPermissions.read)
        //   console.log(typeof row.modelsPermissions.read)
        //   //console.log(Object.values(JSON.parse(row.modelsPermissions.read).fields).join())
        // }
        if (
          (row?.modelsPermissions?.read != null ||
            row?.modelsPermissions?.read != undefined)
        ) {
          read = row?.modelsPermissions?.read?.fields?.join(', ')
        }


        if (
          (row?.modelsPermissions?.update != null ||
            row?.modelsPermissions?.update != undefined)
        ) {
          update = row?.modelsPermissions?.update?.fields?.join(', ')
        }

        return (
          <Box>
            <Typography>
              {read?.length > 0 && 'Read : '} {read && read.toString()}{' '}
            </Typography>
            <Typography>
              {update?.length > 0 && 'Update : '} {update && update.toString()}{' '}
            </Typography>
          </Box>
        )
      },
    },
    {
      minWidth: 400,
      field: 'sections',
      headerName: 'Sections',
      renderCell: ({ row }) => {
        //console.log(row)
        return row.sections.map((el, key) => (
          <>
            <li key={el.id} className={classes.root}>
              {/* <EditSectionModal
              key={el.id}
              openEditSectionModal={openEditSectionModal}
              handleClickOpenEditSectionModal={handleClickOpenEditSectionModal}
              handleCloseEditSectionModal={handleCloseEditSectionModal}
              section={el}
            /> */}
              <Chip
                onClick={() => {
                  setPing(!ping)
                  setSectionId(el.id)
                  setModelId(row.id)
                  handleClickOpenEditSectionModal()
                }}
                onDelete={() => {
                  setSectionIdDelete(el.id)
                  handleOpenDeleteModal()
                }}
                label={el?.name}
                className={classes.chip}
              />
            </li>

          </>
        ))

      },
    },
  ]


  return (
    <div>
      <GridContainer>
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/configurations`}>{t('Configurations')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/models/`}>{t('Models List')}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              color="rose"
              icon
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <GridItem xs={12} sm={12} md={9}>
                <h4>{t('Models List')}</h4>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <ModelDown
                  selected={selected}
                  modelSelected={selectionModel}
                  groups={groups}
                />
              </GridItem>
            </CardHeader>
            <CardBody>
              <GridContainer
                style={{
                  height: 590,
                  width: '100%',
                  //width:1150
                }}
              >
                <DataGrid
                  disableSelectionOnClick
                  checkboxSelection
                  rowHeight={80}
                  rows={models && models}
                  columns={columns}
                  onSelectionModelChange={onSelectionModelChange}
                  selectionModel={selectionModel}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <EditSectionModal
        ping={ping}
        sectionId={sectionId}
        modelId={modelId}
        openEditSectionModal={openEditSectionModal}
        handleCloseEditSectionModal={handleCloseEditSectionModal}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteSection}
        //Element_ID={el.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
