import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  menus: [],
  menusStatus: 'idle',
  menuStatus: 'idle',
  error: null,
  menu:null,
  id:null
}


export const fetchMenus = createAsyncThunk('menu/fetchMenus', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/menus/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
 
  export const fetchMenuById = createAsyncThunk('menu/fetchMenuById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/menus/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     console.log(response.data,'response.data')

    return response.data
  })
  export const addNewMenu = createAsyncThunk(
    'menu/addNewMenu',
    async (initialMenu) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/menus/create/`,initialMenu ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const menuUpdate = createAsyncThunk(
    'menu/menuUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/menus/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedMenu')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the menu: ', error)

}
    }
  )
  export const deleteMenu = createAsyncThunk('menu/deleteMenu', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/menus/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     

    return response.data
  })
const  menuSlice = createSlice({
  name: ' menu',
  initialState,
  extraReducers: {
  
    [fetchMenus.pending]: (state, action) => {
        state.menusStatus = 'loading'
      },
      [fetchMenus.fulfilled]: (state, action) => {
        state.menusStatus = 'succeeded'
        // Add any fetched posts to the array
        state.menus = action.payload
      },
      [fetchMenus.rejected]: (state, action) => {
        state.menusStatus = 'failed'
        state.error = action.payload
      },

      [fetchMenuById.pending]: (state, action) => {
        state.menuStatus = 'loading'
      },
      [fetchMenuById.fulfilled]: (state, action) => {
        state.menuStatus = 'succeeded'

        // Add any fetched posts to the array
        state.menu = action.payload
      },
      [fetchMenuById.rejected]: (state, action) => {
        state.menuStatus = 'failed'
        state.error = action.payload
      },
    [addNewMenu.fulfilled]: (state, action) => {
      state.menus.push(action.payload)
    },
    [menuUpdate.fulfilled]: (state, action) => {
      console.log(action) 
    },
    [deleteMenu.fulfilled]: (state, action) => {
     console.log(action)
    },
  },

  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
    menuAdded: {
      reducer(state, action) {
        state. menus.push(action.payload)
      },
      prepare(name,university_id, mission, phoneNumber, email, director, image, adress, city, postalCode) {
        // omit prepare logic
      }
    },
    menuUpdated(state, action) {
      const { id,name, groups_ids, description } = action.payload
      const existingMenu = state.menus.find(menu => menu.id === id)
      if (existingMenu) {
        existingMenu.name = name
        existingMenu.groups_ids = groups_ids
        existingMenu.description = description
        

      }
    }
  }
})

export const { menuAdded, menuUpdated, getId } = menuSlice.actions

export default menuSlice.reducer

export const selectAllMenus = state => state.menu.menus
