import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb, notification } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DropDownSubAreaButton from 'views/Components/DropDownSubAreaButton'
import ErrorModal from 'views/Components/ErrorModal'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import {
  deleteSubArea,
  fetchSubAreas,
  getId,
} from '../../../../reduxToolkit/features/subarea/SubAreaSpecSlice'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const SubAreaExcerpt = ({ subArea }) => {
  console.log(subArea)
  const cardClasses = useCardStyles()

  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getId(id))
  }
  const deleteUniv = (id) => {
    dispatch(deleteSubArea(id))
  }

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card style={{ maxHeight: '200px', minHeight: '200px' }} cardHover>
        <CardBody>
          <GridContainer alignItems="center">
            <GridItem xs={12} sm={12} md={10}>
              
                <h4 className={cardClasses.cardProductTitle}>
                  {subArea &&
                    subArea.name &&
                    subArea.code &&
                    `${subArea.name} (${subArea.code})`}
                </h4>
                <h4
                      className={classes.cardProductTitle}
                      // style={{ width: 108 }}
                    >
                      {subArea &&
                        subArea.areaSpec_detail &&
                        subArea.areaSpec_detail.name &&
                        `Area: ${subArea.areaSpec_detail.name}`}
                    </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <DropDownSubAreaButton id={subArea.id} />
            </GridItem>
          </GridContainer>
          
        </CardBody>
      </Card>
    </GridItem>
    // <GridContainer
    //   md={4}
    //   xs={12}
    //   sm={12}
    //   justify="space-between"
    //   alignItems="center"
    // >
    //   <GridItem key={subArea.id}>
    //     <Card product className={classes.cardHover}>
    //       <CardBody style={{ width: 300, height: 150 }}>
    //         <GridContainer>
    //           <GridItem xs={12} sm={12} md={12}>
    //             <GridContainer justify="space-between">
    //               <GridItem xs={12} sm={12} md={10}>
    //                 <h4
    //                   className={classes.cardProductTitle}
    //                   style={{ width: 108 }}
    //                 >
    //                   {subArea &&
    //                     subArea.name &&
    //                     subArea.code &&
    //                     `${subArea.name} (${subArea.code})`}
    //                   {/* {subArea.name} {subArea.code} */}
    //                 </h4>
    //                 <h4
    //                   className={classes.cardProductTitle}
    //                   style={{ width: 108 }}
    //                 >
    //                   {subArea &&
    //                     subArea.areaSpec_detail &&
    //                     subArea.areaSpec_detail.name &&
    //                     `Area: ${subArea.areaSpec_detail.name}`}
    //                 </h4>
    //               </GridItem>

    //               <GridItem xs={12} sm={12} md={2}>
    //                 <DropDownSubAreaButton id={subArea.id} />
    //               </GridItem>
    //             </GridContainer>
    //           </GridItem>
    //         </GridContainer>
    //       </CardBody>
    //     </Card>
    //   </GridItem>
    // </GridContainer>
  )
}

export default function SubAreasPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const subAreasStatus = useSelector(
    (state) => state.subAreaSpec.subAreasStatus,
  )
  console.log('status subareas', subAreasStatus)

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchAllSubAreas = async () => {
    try {
      const resultAction = await dispatch(fetchSubAreas()).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  useEffect(() => {
    fetchAllSubAreas()
  }, [dispatch])

  const subAreas = useSelector((state) => state.subAreaSpec.subAreas)
  console.log('*******subareas', subAreas)
  const error = useSelector((state) => state.subAreaSpec.error)

  let content

  if (subAreasStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (subAreasStatus === 'succeeded') {
    content =
      subAreas &&
      subAreas.map((subArea) => (
        <SubAreaExcerpt key={subArea.id} subArea={subArea} />
      ))
  } else if (subAreasStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span> {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/configuration/course/subAreas/`}
                style={{ color: 'orange' }}
              >
                {t('SubAreas')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Link to="/citrine/configuration/course/subAreas/create">
        <Button color="info">{t(' Add')}</Button>
      </Link>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
