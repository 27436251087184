import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import TranscriptsFiles from "./TranscriptsFiles";

const RecordsOfStudentWorkOrTranscripts = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>STUDENTS: Records of Student Work/Transcripts</h3>
      <p className={styles.reportParagraphTitle}>
        Bellow are transcripts from some of the most recent
        graduates. Transcripts are accompanied by copies of degree audits and/or
        other explanations for interpreting the transcripts:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br/>
      <TranscriptsFiles/>
    </>
  );
};

export default RecordsOfStudentWorkOrTranscripts;
