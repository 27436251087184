import { Tabs } from 'antd';
import Card from "components/Card/Card"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import { greyColor } from 'assets/jss/material-dashboard-pro-react';
import { Link } from "react-router-dom"
import React from 'react';
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import ContactInformation from "./ContactInformation";
import PreviousEvaluations from "./PreviousEvaluations";
import ProgramHistory from "./ProgramHistory";
import Options from "./Options";
import ProgramDeliveryModes from "./ProgramDeliveryModes";
import ProgramLocations from "./ProgramLocations";
import PublicDisclosure from "./PublicDisclosure";

const onChange = (key) => {
    console.log(key);
};

const BackgroundInformation = ({data}) => (
    <>
        {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

        <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            items={[
                {
                    label: `Contact Information`,
                    key: '1',
                    children: <ContactInformation  data={data.ContactInformation}/>
                    ,
                },
                {
                    label: `Program history`,
                    key: '2',
                    children: <ProgramHistory data={data.ProgramHistory}/>,
                },
                {
                    label: `Options`,
                    key: '3',
                    children: <Options data={data.Options}/>,
                },
                {
                    label: `Program delivery modes`,
                    key: '4',
                    children: <ProgramDeliveryModes data={data.ProgramDeliveryModes}/>,
                },
                {
                    label: `program locations`,
                    key: '5',
                    children: <ProgramLocations data={data.ProgramLocations}/>,
                },
                {
                    label: `Public disclosure`,
                    key: '6',
                    children: <PublicDisclosure data={data.PublicDisclosure}/>,
                },
                {
                    label: `Previous evaluations`,
                    key: '7',
                    children: <PreviousEvaluations data={data.PreviousEvaluations}/>,
                },
            ]}
        />
    </>

);
export default BackgroundInformation;