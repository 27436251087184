// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Space } from 'antd';

// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
import { Breadcrumb, Form, notification, Select } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link
} from "react-router-dom";
import DropDownButtonStudenSurvey from "../../../Pages/Surveys/StudentSurvey/DropDownButtonStudenSurvey.jsx";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { deleteCampaign, fetchCampaigns } from '../../../../reduxToolkit/features/campaign/CampaignsSlice';

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);

const { Option } = Select;

const CampaignExcerpt = ({ campaign }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const percentage = campaign && campaign.closed_survey && campaign.closed_survey.response_rate;

  const handleDeletestudentsSurvey = () => {dispatch(deleteCampaign(campaign.id));}

  return (
    <GridItem xs={12} sm={12} md={3}>
      <Card style={{height: '400px'}} chart className={classes.cardHover}>
        <CardBody>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link to={`/citrine/surveys/studentsSurvey/${campaign.id}`}>
              <h4><strong>{campaign && campaign.type_details} - {campaign && campaign.resource_name} </strong></h4>
              <h5><strong>{campaign && campaign.academic_year} -{campaign && campaign.semester} </strong></h5>
            </Link>
            <Space direction="vertical">
              <Space wrap>
                <DropDownButtonStudenSurvey id={campaign?.id}/>
              </Space>
            </Space>
          </div>
          <p className={classes.cardCategory}>
            {t("Created at")}: {campaign.creation_date}
          </p>
        </CardBody>
        <CardHeader >
          <div style={{ width: 150, margin: '5px auto' }}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              strokeWidth={5}
            />
          </div>
        </CardHeader>
        <CardFooter chart>
          <div className={classes.stats}>
            <AccessTime /> {campaign && campaign.closed_survey && campaign.closed_survey.state}
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 30,
    span: 26,
  },
};

export default function StudentSurveyCampaignList() {

  const { t } = useTranslation();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const [form] = Form.useForm();

  const [search, setSearch] = useState("")
  const [rows, setRows] = useState([]);
  const [type, setType] = useState("");
  const [searchByDrop, setSearchByDrop] = useState("All Types");
  const [searchFiltred, setSearchFiltred] = useState("");
  const [filtredCampaigns, setFiltredCampaigns] = useState([]);
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const dispatch = useDispatch()

  const campaignsStatus = useSelector(state => state.campaign.campaignsStatus)

  const campaigns = useSelector(state => state.campaign.campaigns)
  const error = useSelector(state => state.campaign.error)

  useEffect(() => {
    dispatch(fetchCampaigns())
  }, [])

  const onhandleChangedrop = (e) => {
    setSearchByDrop(e.target.value)
  }

  const dropdownfilter = campaigns?.filter((campaign) => {
    if (searchByDrop === 'course') {
      return campaign.type_category === 'course'
    } else if (searchByDrop === 'program') {
      return campaign.type_category === 'program'
    } else {
      return campaign
    }
  })

  const filteredContent = dropdownfilter?.filter((item) => {
    return searchFiltred.toLowerCase() === '' ? item : item?.type_details?.toLowerCase()?.includes((searchFiltred))
  })

  let content

  if (campaignsStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (campaignsStatus === 'succeeded') {
    content = filteredContent && filteredContent.map(campaign => (
         <CampaignExcerpt key={campaign.id} campaign={campaign} />
    ))
  } else if (campaignsStatus === 'failed') {
    content = <div>{error}</div>
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  return (
    <div>
      <GridContainer
          style={{borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"}}
          justify={'space-between'}>
        <GridItem xs={12} sm={12} md={4}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/surveys/studentsSurvey`}>
                {t("Internal Survey")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
        <select onChange={onhandleChangedrop} style={{ backgroundColor: '#EEEEEE', color: 'gray', border: '1px solid black', borderRadius: '8px', padding: '10px', width: '200px' }}>
            <option value="All Types">All Types</option>
            <option value="program">program</option>
            <option value="course">course</option>
          </select>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
          <input placeholder='Search' style={{ backgroundColor: '#eeeeee', border: 'none', borderBottom: '1px solid #BDBDBD', marginRight: '50px', width: '250px', height: "30px" }} type='text' onChange={(e) => setSearchFiltred(e.target.value)} value={searchFiltred} />
          </GridItem>
      </GridContainer>
      <GridContainer>
        <br></br>
      </GridContainer>
      <GridContainer>
        {content}
      </GridContainer>
    </div>
  );
}
