import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { unwrapResult } from "@reduxjs/toolkit";
import { Select } from "antd";
import Card from "components/Card/Card.js";

import GridItem from "components/Grid/GridItem.js";

import { Link } from "react-router-dom";
import {
  fetchGraduateAttributes,
  updateGraduateAttributePlanning
} from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";

import { Breadcrumb, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { fetchProgramById } from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchAcademicYearById } from "../../../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import { fetchSemesterById } from "../../../../reduxToolkit/features/semester/SemestersSlice.js";

import moment from "moment";
import ErrorModal from "../../../Components/ErrorModal";
import "./index.css";

const { Option } = Select;

export default function GA({ match }) {

  const { id } = match.params;
  const { program_id } = match.params;
  const { semesterId } = match.params;

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(9);
  const [openSpinner, setOpenSpinner] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const dispatch = useDispatch();

  const semesterStatus = useSelector((state) => state.semester.semesterStatus);
  const semester = useSelector((state) => state.semester.semester);
  const academicYear = useSelector((state) => state.academicYear.academicYear);
  const program = useSelector((state) => state.program.program)
  const AllGAs = useSelector((state) => state.GraduateAttribute.GraduateAttributes);

  useEffect(() => {
    dispatch(fetchGraduateAttributes(program_id))
        .then(unwrapResult)
        .then((GAs) => {
          handleCloseSpinner();
        });
  }, []);

  useEffect(() => {
    fetchSemester(semesterId);
    fetchAcademicYear(id);
    fetchProgram(program_id);
  }, []);

  const handleCloseSpinner = () => {
    setOpenSpinner(false);
  };

  const fetchSemester = async (id) => {
    try {
      const resultAction = await dispatch(fetchSemesterById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchProgram = async (id) => {
    try {
      const resultAction = await dispatch(fetchProgramById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchAcademicYear = async (id) => {
    try {
      const resultAction = await dispatch(fetchAcademicYearById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onCellEditCommit = async (event) => {
    if (event.value !== null) {
      const value =
        event.field === "target" ||
          event.field === "external_benchmark" ||
          event.field === "internal_benchmark"
          ? event.value
          : moment(event.value).format("YYYY-MM-DD");
      const field = event.field;
      const updated = {
        [field]: value,
      };
      const id = event.id;
      const resultAction = await dispatch(
        updateGraduateAttributePlanning({ id: id, updated: updated })
      );
      if (updateGraduateAttributePlanning.fulfilled.match(resultAction)) {
        openNotificationWithIcon("success", `Updated successfully`);
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
        }
      }
    }
  };

  return (
    <div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openSpinner}
          onClick={handleCloseSpinner}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>
        <GridItem style={{ marginBottom: "5px" }}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Planning")}</Breadcrumb.Item>
            <Breadcrumb.Item>{t("Academic Calender")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/planning/academicCalender/academicYears`}>
                {t("Academic years")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}`}
              >
                {t(`${academicYear && academicYear.name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("semesters")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}`}
              >
                {t(`${semester && semester.name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}`}
              >
                {t(`${program && program.program_degree_name}`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/planning/programAssessment/GA`}>
                {t("GAs Planning ")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <Card>
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={AllGAs && AllGAs}
              columns={columns}
              onCellEditCommit={onCellEditCommit}
              showCellRightBorder
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 80]}
              pagination
            />
          </div>
        </Card>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    field: "name",
    headerName: "Graduate Attribute",
    width: 250,
    sortable: false,
    renderCell: (cellValues) => {
      return (<div>
        {cellValues && cellValues.row && cellValues.row.graduateAttributeConfig_details
          && cellValues.row.graduateAttributeConfig_details
          && cellValues.row.graduateAttributeConfig_details.name
        }
      </div>
      );
    }
  },
  {
    field: "assessment_start_date",
    headerName: "Assessment Start Date",
    type: "date",
    width: 180,
    editable: true,
    sortable: true,
  },
  {
    field: "assessment_due_date",
    headerName: "Assessment Due Date",
    type: "date",
    width: 180,
    editable: true,
    sortable: true,
  },
  {
    field: "improvement_due_date",
    headerName: "Improvement Due Date",
    type: "date",
    width: 180,
    editable: true,
    sortable: true,
  },
  {
    field: "external_benchmark",
    headerName: "External Benchmark",
    width: 180,
    editable: true,
    sortable: true,
  },
  {
    field: "internal_benchmark",
    headerName: "Internal Benchmark",
    width: 180,
    editable: true,
    sortable: true,
  },

  {
    field: "target",
    headerName: "Target",
    width: 80,
    editable: true,
    sortable: true,
    type: 'number',
  },
];
