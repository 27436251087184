import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  clo: null,
  cloId: null,
  CourseAssessmentMethodNotesCLO: null,
  ScoreClo: null,
};

export const fetchCloById = createAsyncThunk(
  "assessment/fetchCloById",
  async (id, { rejectWithValue }) => {
    try {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/clo/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    // console.log(
    //   response.data,
    //   "response.data fetchCloById in calcul notes slice"
    // );

    return response.data;
  } catch (err) {
    console.log('err from slice', err)
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})


export const fetchNotesByCloIdAndAssessmentIdForCalcul = createAsyncThunk(
  "assessment/fetchNotesByCloIdAndAssessmentIdForCalcul",
  async (obj, { rejectWithValue }) => {
    try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl +
        `/courseAssessmentNoteClo/?clo=${obj.clo_id}&assessment=${obj.assessment_id}&semester=${obj.semester_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    // console.log(response.data, "response.data");

    return response.data;
  } catch (err) {
    console.log('err from slice', err)
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

export const fetchTuplesNumberStudentsByRange = createAsyncThunk(
  "assessment/fetchTuplesNumberStudentsByRange",
  async (obj, { rejectWithValue }) => {
    try {
    const token = localStorage.getItem("token");
    console.log(token, 'token ')
    console.log(obj, 'obj')
    const response = await axios.post( BaseUrl +`/courseAssessmentNoteClo/tuple/?clo_id=${obj.clo_id}&semester_id=${obj.semester_id}`,{},
      {
        headers: {
            Authorization:  `token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data TuplesNumberStudents");

    return response.data;
  } catch (err) {
    console.log('err from slice', err)
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

export const fetchScoreCLOById = createAsyncThunk(
  "assessment/fetchScoreCLOById",
  async (Object, { rejectWithValue }) => {
    try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/calculationScoreByClo/create/`,
      Object,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data");

    return response.data;
  } catch (err) {
    console.log('err from slice', err)
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

const calculNotessSlice = createSlice({
  name: "calcul",
  initialState,
  extraReducers: {},

  reducers: {
    getCloId: (state, action) => {
      state.cloId = action.payload;
    },
  },
  extraReducers: {
    [fetchCloById.pending]: (state, action) => {
      state.calculStatus = "loading";
    },
    [fetchCloById.fulfilled]: (state, action) => {
      state.calculStatus = "succeeded";
      state.clo = action.payload;
    },
    [fetchCloById.rejected]: (state, action) => {
      state.calculStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchNotesByCloIdAndAssessmentIdForCalcul.pending]: (state, action) => {
      state.courseStatus = "loading";
    },
    [fetchNotesByCloIdAndAssessmentIdForCalcul.fulfilled]: (state, action) => {
      state.courseStatus = "succeeded";
      // Add any fetched posts to the array
      state.CourseAssessmentMethodNotesCLO = action.payload;
    },
    [fetchNotesByCloIdAndAssessmentIdForCalcul.rejected]: (state, action) => {
      state.courseStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchScoreCLOById.pending]: (state, action) => {
      state.assessmentStatus = "loading";
    },
    [fetchScoreCLOById.fulfilled]: (state, action) => {
      state.assessmentStatus = "succeeded";

      // Add any fetched posts to the array
      state.ScoreClo = action.payload;
    },
    [fetchScoreCLOById.rejected]: (state, action) => {
      state.assessmentStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
});

export const { getCloId } = calculNotessSlice.actions;

export default calculNotessSlice.reducer;
