import { Breadcrumb } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useState } from 'react'

import Menu from '@material-ui/core/Menu'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '../../../../components/CustomButtons/Button'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchKpiStandards } from 'reduxToolkit/features/kpiStandardConfigType/kpiStandardConfigTypeSlice'
import KpiStandardTypeDown from './KpiStandardTypeDown'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Search from '@material-ui/icons/Search'
import CardBody from 'components/Card/CardBody.js'
import { grayColor, infoColor } from 'assets/jss/material-dashboard-pro-react'
import ErrorModal from '../../../Components/ErrorModal'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const KpiStandardType = () => {
  const [searchFiltred, setSearchFiltred] = useState('')
  const navBarClasses = useNavBarStyles()

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const cardClasses = useCardStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const titleClasses = useTitleStyles()
  const classes = useStyles()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const onFinish = async () => {
    handleClose()
  }
  const searchFilteredKpi = (e) => {
    setSearchFiltred(e.target.value)
  }
  const KPIsStandard = useSelector((state) => state.KpiStandard.KpiStandards)
  console.log(KPIsStandard)
  const KPIsStandardStatus = useSelector(
    (state) => state.KpiStandard.KpiStandardsStatus,
  )
  const filteredContent = KPIsStandard?.filter((KPI) => {
    return searchFiltred.toLowerCase() === ''
      ? KPI
      : KPI?.name?.toLowerCase()?.includes(searchFiltred) ||
          KPI?.code?.toLowerCase()?.includes(searchFiltred)
  })

  let content

  if (KPIsStandardStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (KPIsStandardStatus === 'succeeded') {
    content =
      filteredContent.length > 0 ? (
        filteredContent &&
        filteredContent.map((KPI) => (
          <GridItem xs={12} sm={12} md={4}>
            <Card style={{ maxHeight: '200px', minHeight: '200px' }} cardHover>
              <CardBody>
                <GridContainer alignItems="center">
                  <GridItem xs={12} sm={12} md={10}>
                    <Link to={`/citrine/configuration/EditKpiStandardConfig/${KPI && KPI.id}`}>
                      <h4 className={cardClasses.cardProductTitle}>
                        <strong style={{ color: infoColor[0] }}>
                          {KPI.code}
                        </strong>
                      </h4>
                    </Link>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <KpiStandardTypeDown id={KPI.id} />
                  </GridItem>
                </GridContainer>
                <Link to={`/citrine/configuration/KPI/${KPI && KPI.id}`}>
                  <p>
                    {KPI?.name?.length > 200
                      ? `${KPI.name.substring(0, 200)}...`
                      : KPI.name}
                  </p>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        ))
      ) : (
        <h4>
           <b>{searchFiltred}</b> 
        </h4>
      )
  }
  useEffect(() => {
    dispatch(fetchKpiStandards())
  }, [dispatch])
  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/KpiStandardType`}>
                <span> {t('Kpi Standard Type Page')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Link to={`/citrine/configuration/others/AddKpiStandardConfig`}>
            <Button color="info">{t('Add')}</Button>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}></GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            formControlProps={{
              className: navBarClasses.top + ' ' + navBarClasses.search,
            }}
            inputProps={{
              onChange: (e) => {
                searchFilteredKpi(e)
              },
              value: searchFiltred,
              placeholder: 'Search',
              inputProps: {
                'aria-label': 'Search',
                className: navBarClasses.searchInput,
              },
            }}
          />

          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
          >
            <Search
              className={
                navBarClasses.headerLinksSvg + ' ' + navBarClasses.searchIcon
              }
            />
          </Button>
        </GridItem>
      </GridContainer>

      <GridContainer>
        {content}
        {/* {EvidenceTypeStatesConfigsItem &&
          EvidenceTypeStatesConfigsItem.map((i) => (
            <GridItem xs={12} sm={12} md={4} key={i.id}>
              <Card
                style={{
                  maxWidth: '380px',
                  minWidth: '380px',
                  maxHeight: '10px',
                  minHeight: '100px',
                }}
              >
                <CardHeader>
                  <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <GridItem xs={10} sm={10} md={10}>
                      <h4 className={cardClasses.cardProductTitle}>
                        {`${i.type}`}
                      </h4>
                    </GridItem>

                    <GridItem xs={2} sm={2} md={2}>
                      <KpiStandardTypeDown id={i.id} />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>
          ))} */}
      </GridContainer>
    </div>
  )
}

export default KpiStandardType
