import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import React, { useEffect } from "react";
// core components
import { Breadcrumb } from "antd";
import {
  grayColor,
  primaryColor
} from "assets/jss/material-dashboard-pro-react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchStaffById } from "../../../../../reduxToolkit/features/user/UserSlice";

import CardFooter from "components/Card/CardFooter";
import ProfessionalDevelopmentDropdownButton from "views/Components/ProfessionalDevelopmentDropdown";
import { fetchProfessionalDevelopment } from "../../../../../reduxToolkit/features/ProfessionalDevelopment/ProfessionalDevelopmentSlice";
import ErrorModal from "../../../../Components/ErrorModal";



const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const ProfessionalDevelopmentExcerpt = ({ activity, personId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch();

  return (
    <GridItem xs={12} sm={6} md={4} key={activity.id}>
      <Card style={{ maxHeight: "380px", minHeight: "380px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/faculty/teachingStaff/${personId}/professionalDevelopmentActivity/${activity.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {activity && activity.title}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <ProfessionalDevelopmentDropdownButton id={activity.id} program={activity} personId={personId} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          <GridContainer xs={12} sm={12} md={12}>
            <GridContainer
              xs={12}
              sm={12}
              md={6}
              style={{
                flexDirection: "column",
              }}
            >
            </GridContainer>

            <GridContainer
              xs={12}
              sm={12}
              md={6}
              style={{
                flexDirection: "column",
              }}
            >
            </GridContainer>

          </GridContainer>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Organization")}:
            <span style={{ fontWeight: "normal" }}> {activity && activity.organization} </span>
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Theme")}:
            <span style={{ fontWeight: "normal" }}>
              {activity && activity.theme}
            </span>
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Type")}:
            <span style={{ fontWeight: "normal" }}>
              {activity && activity.type}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Start Date")}:
            <span style={{ fontWeight: "normal" }}>
              {activity && activity.start_date}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("End Date")}:
            <span style={{ fontWeight: "normal" }}>
              {activity && activity.end_date}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Description")}:
            <span style={{ fontWeight: "normal" }}>
              {activity && activity.description}
            </span>
          </div>



        </CardBody>
        <CardFooter>
          <GridContainer xs={2} sm={2} md={12}>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "start" }}>
              <span
                style={{
                  color: "#FFCB3B",
                }}
              >
                {/* V {program && program.version} */}
              </span>
            </GridItem>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "end" }}>
              <span
                style={{
                  color: activity.state === "Draft" ? grayColor[1] : "#FFCB3B",
                }}
              >
                {activity && activity.state}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function ProfessionalDevelopment({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const { id } = match.params;

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchPDActivitiesCustomFunction = async (id) => {
    try {
      const resultAction = await dispatch(
        fetchProfessionalDevelopment(id)
      ).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    dispatch(fetchStaffById(id));
    fetchPDActivitiesCustomFunction(id);
  }, []);

  const person = useSelector((state) => state.user.staff);
  const professionalDevelopmentActivities = useSelector(
    (state) =>
      state.professionalDevelopmentActivities.professionalDevelopmentActivities
  );
  const professionalDevelopmentActivitiesStatus = useSelector(
    (state) =>
      state.professionalDevelopmentActivities
        .professionalDevelopmentActivitiesStatus
  );
  console.log(
    "professionalDevelopmentActivities",
    professionalDevelopmentActivities
  );

  let content;

  if (professionalDevelopmentActivitiesStatus === "loading") {
    content = <div className="loader">Loading...</div>;
  } else if (professionalDevelopmentActivitiesStatus === "succeeded") {
    content =
      professionalDevelopmentActivities &&
      professionalDevelopmentActivities.map((dev) => (
        <ProfessionalDevelopmentExcerpt key={dev.id} activity={dev} personId={id} />
      ));
  } else if (professionalDevelopmentActivitiesStatus === "failed") {
    content = <div>An error has accured</div>;
  }

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff`}>{t("Teaching Staff")}</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff/${id}`}>
            {`${person && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link
            to={`/citrine/faculty/teachingStaff/${id}/professionalDevelopmentActivities`}
          >
            {t("Professional development")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Link
        to={`/citrine/faculty/teachingStaff/${id}/professionalDevelopmentActivities/create`}
      >
        <Button color="primary">{t(" Add")}</Button>
      </Link>
      {/* <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          <ImportScopusModal person_id={person.id} />
        </GridItem>
      </GridContainer> */}
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {content}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}

