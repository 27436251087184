import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseById, fetchCourses, Specific_CoursesSpecUpdate
} from "../../../../reduxToolkit/Course/coursesSpecSlice";

import { fetchProgramsByCourseSpec } from "reduxToolkit/features/program/ProgramSpecSlice";
import { primaryColor } from "../../../../assets/jss/material-dashboard-pro-react";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";

import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider } from "antd";
import "antd/dist/antd.css";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Button from "components/CustomButtons/Button.js";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchActivities } from "../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import {
  fetchCourseSpecReportDocumentsByCourseSpecId
} from "../../../../reduxToolkit/features/CourseSpecificationReportDocument/CourseSpecificationReportDocumentSlice";
import {
  fetchCourseSyllabusReportDocumentsByCourseSpecId
} from "../../../../reduxToolkit/features/CourseSyllabusReportDocument/CourseSyllabusReportDocumentSlice";
import ErrorModal from "../../../Components/ErrorModal";
import "./courseDetails.css";

import { notification } from "antd";

import {
  blackColor,
  hexToRgb, whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
import { GoReport } from "react-icons/go";
import {FaHistory, FaProjectDiagram, FaTasks} from "react-icons/fa"
import VersionningCourseSpecModal from "../../../Components/VersionningCourseSpecModal";

import DropDownDocumentCourseSpecReport from "../../../Components/DropDownDocumentCourseSpecReport";

import DropDownButtonInCourseDetails from "../../../Components/DropDownButtonInCourseDetails";
import DropDownCourseSpecReportButton from "../../../Components/DropDownCourseSpecReportButton";
import DropDownCourseSyllabusReportButton from "../../../Components/DropDownCourseSyllabusReportButton";

const useCardStyles = makeStyles(cardStyles);
const useButtonStyles = makeStyles(buttonStyles);
const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CourseSepecificationDetailsCoordinator({ match }) {
  const cardClasses = useCardStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const { id } = match.params;

  const dispatch = useDispatch();
  const [isEditor, setIsEditor] = useState(true);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [current, setCurrent] = React.useState(0);
  const [stateName, setStateName] = useState("");
  const [course_type, setCourse_type] = useState("");

  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(true);
  const [showDiagram, setShowDiagram] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const renderTasks = () => {
    setShowTasks(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowReports(false);
  };
  const renderActivities = () => {
    setShowActivities(true);
    setShowDiagram(false);
    setShowTasks(false);
    setShowReports(false);
  };
  const renderReports = () => {
    setShowReports(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
  };

  //bmpn Modal
  const handleClickOpen = () => {
    setOpen(true);
    //setShowDiagram(true);
    //setShowActivities(false);
    //setShowTasks(false);
    setShowReports(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDiagram(false);
    setShowActivities(true);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderRow = () => {
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: "gray" }}
              >
                {Moment(item.DueDate).format("YYYY-MM-DD")}
              </Divider>

              <div
                style={{
                  backgroundColor: "#edeff1",
                  borderBottom: "1px solid #cfd5db",
                  
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={2}>
                    <Avatar
                      style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={10}>
                    <p>
                      <strong>{item.AssignedTo}</strong>
                    </p>
                    <ul>
                      <li>{item.Summary}</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (showTasks) {
      return <div>Tasks</div>;
    } else {
      return (
        <div>
          <div style={{ zIndex: 10000 }}>
            {isEditor && (
              <DropDownDocumentCourseSpecReport CourseId={course && course.id} CourseType={course && course.type} />
            )}
          </div>
          {courseSpecReportDocuments &&
            courseSpecReportDocuments.map((courseSpecReportDocument) => (
              <Card style={{ backgroundColor: grayColor[8] }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px",
                  }}
                >
                  <Link
                    to={`/citrine/CourseSpecDetails/${id}/CourseSpecificationReportDocument/${courseSpecReportDocument.id}`}
                    style={{ paddingTop: "20px" }}
                  >
                    <h7> {courseSpecReportDocument.name}</h7>
                  </Link>
                  {isEditor && (
                    <DropDownCourseSpecReportButton
                      id={courseSpecReportDocument && courseSpecReportDocument.id}
                      courseId={course && course.id && course.id}
                    />
                  )}
                </div>

                <div>
                  <h7>Status: {courseSpecReportDocument.state}</h7>
                  <br></br>
                  <h7>
                    Created By:{" "}
                    {courseSpecReportDocument &&
                      courseSpecReportDocument.user_details &&
                      courseSpecReportDocument.user_details.username}
                  </h7>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h7>
                      Created on:
                      {Moment(courseSpecReportDocument.created_at).format(
                        "YYYY-MM-DD"
                      )}
                    </h7>
                    <h7>
                      {" "}
                      <Link>V {courseSpecReportDocument.version}</Link>
                    </h7>
                  </div>
                </div>
              </Card>
             
            ))}
          {courseSyllabusReportDocuments &&
            courseSyllabusReportDocuments.map((courseSyllabusReportDocument) => (
              <Card style={{ backgroundColor: grayColor[8] }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px",
                
                  }}
                >
                  <Link
                    to={`/citrine/CourseSpecDetails/${id}/CourseSyllabusReportDocument/${courseSyllabusReportDocument.id}`}
                    style={{ paddingTop: "20px" }}
                  >
                    <h7> {courseSyllabusReportDocument.name}</h7>
                  </Link>
                  {isEditor && (
                    <DropDownCourseSyllabusReportButton
                      id={courseSyllabusReportDocument && courseSyllabusReportDocument.id}
                      courseId={course && course.id}
                    />
                  )}
                </div>

                <div>
                  <h7>Status: {courseSyllabusReportDocument.state}</h7>
                  <br></br>
                  <h7>
                    Created By:{" "}
                    {courseSyllabusReportDocument &&
                      courseSyllabusReportDocument.user_details &&
                      courseSyllabusReportDocument.user_details.username}
                  </h7>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h7>
                      Created on:
                      {Moment(courseSyllabusReportDocument.created_at).format(
                        "YYYY-MM-DD"
                      )}
                    </h7>
                    <h7>
                      {" "}
                      <Link>V {courseSyllabusReportDocument.version}</Link>
                    </h7>
                  </div>
                </div>
              </Card>
              
            ))}
        </div>
      );
    }
  };

  const fetchCourse = async () => {
    try {
      const courses = await dispatch(fetchCourseById(id))
        .then(unwrapResult)
        .then((course) => {
          localStorage.setItem("CourseState", course.state);
          setCourse_type(course.type);
        });
      if (course && course.state != "Draft" || !isEditor) {
        setDisabledStatus(true);
      }
      console.log("success", `Fetched`);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
      console.log("error", `Fetch failed: ${err}`);
    }
  };
  useEffect(() => {
    fetchCourse();
    dispatch(fetchActivities());
    dispatch(fetchCourseSpecReportDocumentsByCourseSpecId(id));
    dispatch(fetchCourseSyllabusReportDocumentsByCourseSpecId(id));

    dispatch(fetchProgramsByCourseSpec(id))

  }, [dispatch]);
  const programs = useSelector((state) => state.programSpec.programsByCourseSpec)
  console.log('programs', programs)
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const Activities = useSelector((state) => state.Activity.Activities);
  console.log(Activities)
  console.log(course)
  const courseSpecReportDocuments = useSelector(
    (state) => state.CourseSpecReportDocument.courseSpecReportDocuments
  );
  const courseSyllabusReportDocuments = useSelector(
    (state) => state.CourseSyllabusReportDocument.courseSyllabusReportDocuments
  );
  useEffect(() => {
    setStateName(course && course.state);
    console.log("stateName", stateName);
  }, [course]);



  const handleClose = () => {
    setAnchorEl(null);
  };

  const Approve = () => {
    setShow(true);
    setStateName("Approved");
    localStorage.setItem("CourseState", "Approved");
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Approved" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Approved");
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourses());
    }
  };
  const SendforApproval = () => {
    setShow(true);

    setStateName("Waiting for approval");
    localStorage.setItem("CourseState", "Waiting for approval");
    setDisabledStatus(true);
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Waiting for approval" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Waiting for approval");
      dispatch(fetchCourses());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourses());
      setDisabledStatus(true);
    }
  };
  const ResetTODraft = () => {
    setShow(true);
    setStateName("Draft");
    localStorage.setItem("CourseState", "Draft");
    setDisabledStatus(false);
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Draft" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Draft");
      setDisabledStatus(false);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourses());
      setDisabledStatus(false);
    }
  };

  const Archive = () => {
    setShow(true);
    setStateName("Archived");
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Archived" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Archived");
      dispatch(fetchCourses());
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourses());
    }
  };

  let contentPage;
  if (stateName === "Draft") {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <Button
                color="primary"
                size="sm"
                className={classes.marginRight}
                onClick={() => SendforApproval()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Send for approval")}
                </span>
              </Button>
            )}
          </div>

          <ol class="progress-bar" style={{ width: "550px", height: "40px" }}>
            <li
              class="progress-bar__steps current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>

            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (
    stateName &&
    stateName.toLowerCase().includes("waiting for approval")
  ) {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <Button
                color="primary"
                size="sm"
                className={classes.marginRight}
                onClick={() => Approve()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Approve")}
                </span>
              </Button>
            )}
          </div>

          <div>
            {isEditor && (
              <Button
                style={{ backgroundColor: "lightgery" }}
                size="sm"
                className={classes.marginRight}
                onClick={() => ResetTODraft()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Reset tO draft")}
                </span>
              </Button>
            )}
          </div>
          <ol class="progress-bar" style={{ width: "550px", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>

            <li
              class="progress-bar__steps current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Approved") {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <VersionningCourseSpecModal
                CourseSpec_id={course && course.id}
                course={course}
              />
            )}
          </div>
          <div>
            {isEditor && (
              <Button
                color="primary"
                size="sm"
                className={classes.marginRight}
                onClick={() => Archive()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Archive")}
                </span>
              </Button>
            )}
          </div>

          <ol class="progress-bar" style={{ width: "550px", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>

            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Archived") {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <Button
                style={{ backgroundColor: "lightgery" }}
                size="sm"
                className={classes.marginRight}
                onClick={() => ResetTODraft()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Reset to draft")}
                </span>
              </Button>
            )}
          </div>
          <ol class="progress-bar" style={{ width: "650px", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>

            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Archived")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  let content;
  if (
    course_type &&
    course_type.toLowerCase().includes("conventional course")
  ) {
    content = (
      <div>
        <GridContainer>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link to={`/citrine/courses/${id}/clos`}>
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Course Learning Outcomes")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/ModeOfInstructionList/${course && course.id && course.id
                  }`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Mode of Instruction")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link to={`/citrine/specification/course/${id}/LearningResources`}>
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Learning Resources")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/specification/course/${id}/FacilitiesRequireds`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Facilities Required")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/ActualLearningHoursViews`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Actual Learning Hours")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link to={`/citrine/specification/course/${id}/CourseContentViews`}>
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Course Content")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link to={`/citrine/specification/course/${id}/CourseContacts`}>
                <Card
                  style={{
                    height: '150px',
                    textAlign: 'center',
                    border: '1px solid lightgray',
                    borderRaduis: '10px',
                    boxShadow: '2px 2px 2px lightgray',
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: '50px' }}>
                        {t('Course Contact')}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link to={`/citrine/specification/course/${id}/CourseOutlines`}>
                <Card
                  style={{
                    height: '150px',
                    textAlign: 'center',
                    border: '1px solid lightgray',
                    borderRaduis: '10px',
                    boxShadow: '2px 2px 2px lightgray',
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: '50px' }}>
                        {t('Course Outlines')}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/specification/course/${id}/AssessmentTasksForStudents`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Assessment Tasks For Students")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/specification/course/${id}/CourseQualityEvaluations`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Course Quality Evaluation")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>

            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/TeachingStrategies`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Teaching Strategies")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Activities/Assessment Methods")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>

            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link
                to={`/citrine/specification/course/${id}/VerificationCredibilities`}
              >
                <Card
                  style={{
                    height: "150px",
                    textAlign: "center",
                    border: "1px solid lightgray",
                    borderRaduis: "10px",
                    boxShadow: "2px 2px 2px lightgray",
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: "50px" }}>
                        {t("Verification of Credibility of Students’ Results")}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>

          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
                
            <GridItem className="HoverCard" xs={12} sm={12} md={3}>
              <Link to={`/citrine/specification/course/${id}/ranges`}>
                <Card
                  style={{
                    height: '150px',
                    textAlign: 'center',
                    border: '1px solid lightgray',
                    borderRaduis: '10px',
                    boxShadow: '2px 2px 2px lightgray',
                  }}
                >
                  <CardBody>
                    <strong>
                      <h4 style={{ marginTop: '50px' }}>
                        {t('Assessment Ranges')}
                      </h4>
                    </strong>
                  </CardBody>
                </Card>
              </Link>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </div>
    );
  }
  if (course_type && course_type.toLowerCase().includes("field experience")) {
    content = (
      <div>
        <GridContainer>
          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link to={`/citrine/courses/${id}/clos`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Course Learning Outcomes")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${id}/AssessmentTasksForStudents`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Assessment Tasks For Students")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${id}/CourseQualityEvaluations`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("course quality evaluation")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${course && course.id && course.id
                }/TeachingStrategies`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "50px" }}>
                      {t("Training Activities")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Activities/Assessment Methods")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>

          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${id}/VerificationCredibilities`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Verification of Credibility of Students’ Results")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>

          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link to={`/citrine/specification/course/${id}/ranges`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Assessment Ranges")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>

          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${id}/FieldExperienceLocations`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Field experience locations")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>

          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${id}/AssessmentResponsibilities`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Assessment Responsibilities")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/courseCoordinatorWorkspace/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
           
              <Link
                style={{ color: "orange" }}
                to={`/citrine/courseSpecDetails/${id}`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={9}>
          <GridContainer xs={12} sm={12} md={12}>
            {contentPage}
          </GridContainer>

          <GridContainer style={{ border: "none", marginTop: "10px" }}>
            <div className="card scrollable">
              <div
                style={{
                  padding: "10px",
                  borderRadius: "0px",
                  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                  background: whiteColor,
                  width: "100%",
                  boxShadow:
                    "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "0",
                  wordWrap: "break-word",
                  fontSize: ".875rem",
                  // borderRight: "1px solid lightgray"
                }}
              >
                <GridContainer>
                  <div>
                    <Card>
                      <CardBody>
                        <GridContainer justify="space-between">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            style={{ borderLeft: "1px solid gold" }}
                          >
                            <GridContainer>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Program")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {" "}
                                  {course?.program?.map((p)=>{
                                    return (
                                      <>
                                        {p?.name}
                                      </>
                                    )
                                  })}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Course")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {" "}
                                  {course && course.title && course.title}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div>{t("Prerequisites")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={9}>
                                <div>
                                  {course &&
                                    course.prerequisites_detail &&
                                    course.prerequisites_detail.map(
                                      (el) =>
                                        <h7>
                                          {el && el.title}
                                          <br />
                                        </h7>


                                    )}
                                </div>
                              </GridItem>

                              <GridItem xs={3} sm={3} md={3}>
                                <div>{t("Co-requisites")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={9}>
                                <div>
                                  {course &&
                                    course.co_requisites_detail &&
                                    course.co_requisites_detail.map(
                                      (el) =>
                                        <h7>
                                          {el && el.title}
                                          <br />
                                        </h7>
                                    )}
                                </div>
                              </GridItem>
                              {/* <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Code")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {" "}
                                  {course && course.code && course.code}
                                </div>
                              </GridItem> */}
                              {/* <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Year")}: </div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course &&
                                    course.yearSemesterConfig &&
                                    course.yearSemesterConfig[0].libelle}
                                </div>
                              </GridItem> */}

                            </GridContainer>
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            style={{ borderLeft: "1px solid gold" }}
                          >
                            <GridContainer>
                              {/* <GridItem xs={3} sm={3} md={3}>
                                <div>{t("Institution")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={9}>
                                <div>
                                  {course &&
                                    course.institution_detail &&
                                    course.institution_detail[0].name &&
                                    course.institution_detail[0].name}
                                </div>
                              </GridItem> */}
                                <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Level")}: </div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course && course.level_details
                                    && course.level_details
                                    [0].name}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={4}>
                                <div> {t("Credit hours")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course &&
                                    course.credit_hours &&
                                    course.credit_hours}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div>{t("Course Cordinator")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={9}>
                                <div>
                                  {
                                    course?.coordinator_detail?.map(
                                      (el) =>{return <>{el?.first_name } { el?.last_name}</>}
                                    )}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div>{t("Department")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={9}>
                                <div>
                                  {
                                    course?.department_detail?.map(
                                      (el) =>{return <>{el?.name + ' '}</>}
                                    )}
                                </div>
                              </GridItem>

                            
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={1}>
                            {disabledStatus ? (
                              <p></p>
                            ) : (
                              <DropDownButtonInCourseDetails
                                courseID={course && course.id}
                              />
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            border: "1px solid lightgray",
                            padding: "5px",
                            borderRadius: "5px",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ color: "orange" }}>
                            {" "}
                            {t("Description")}:{" "}
                          </div>
                          <div>
                            {course && course.description && course.description}
                          </div>
                        </GridContainer>
                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            border: "1px solid lightgray",
                            padding: "5px",
                            borderRadius: "5px",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ color: "orange" }}>
                            {" "}
                            {t("Main objectives")}:{" "}
                          </div>
                          <div>
                            {course &&
                              course.main_objectives &&
                              course.main_objectives}
                          </div>
                        </GridContainer>

                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            border: "1px solid lightgray",
                            padding: "5px",
                            borderRadius: "5px",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ color: "orange" }}>
                            {t("student Academic Counseling and Support")}:
                          </div>
                          <div>
                            {course &&
                              course.student_academic_counseling_and_support &&
                              course.student_academic_counseling_and_support}
                          </div>
                        </GridContainer>
                      </CardBody>
                    </Card>

                    <br></br>
                    {content}
                  </div>
                  <br></br>
                  <ErrorModal
                    open={openErrorModal}
                    handleClose={handleCloseErrorModal}
                    handledError={handledError}
                  />
                </GridContainer>
              </div>
            </div>
          </GridContainer>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={3}>
          <div
            style={{
              padding: "10px",
              borderRadius: "0px",
              color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
              background: whiteColor,
              width: "100%",
              boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              minWidth: "0",
              wordWrap: "break-word",
              fontSize: ".875rem",
              height: 615,
              border: "1px solid lightgray",
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-around">
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderActivities}
                    >
                      <FaHistory
                        color={showActivities ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem>  */}
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderTasks}
                    >
                      <FaTasks color={showTasks ? primaryColor[0] : "black"} />
                    </Button>
                  </GridItem> */}
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={(event) => {
                        handleClickOpen(event);
                      }}
                    >
                      <FaProjectDiagram
                        color={showDiagram ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem> */}
                  <GridItem>
                     <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderReports}
                    >
                      <GoReport
                        color={showReports ? primaryColor[0] : "black"}
                      />
                    </Button> 
                  </GridItem>
                </GridContainer>
              </GridItem>
              <div
                className={classes1.root}
                style={{
                  height: "530px",
                  paddingBottom: "0px",
                  overflowY: "auto",
                  maxHeight: "565px",
                  width: "100%",
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <div>
                    <div>{renderRow()}</div>
                  </div>
                </GridItem>
              </div>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
