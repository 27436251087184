import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from "antd";
import { Link, useParams } from "react-router-dom";
import { DatePicker, Form, Input, message, notification, Select } from "antd";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import { infoColor } from "assets/jss/material-dashboard-pro-react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewCommunityServiceActivity } from "reduxToolkit/features/CommunityServices/CommunityServicesSlice";
import {
  fetchPrograms
} from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchCurrentSemester } from "reduxToolkit/features/semester/SemestersSlice";

import ErrorModal from "../../../../Components/ErrorModal";


import TextArea from "antd/lib/input/TextArea";
import { fetchStaff, fetchStaffById } from "reduxToolkit/features/user/UserSlice";
import { fetchAuthorityAccordingToType, fetchAuthorityConfigs } from "../../../../../reduxToolkit/features/AuthorityConfig/AuthorityConfigSlice";

import { fetchStaffFaculty, fetchStudents } from "reduxToolkit/features/user/UserSlice";
import { fetchColleges } from "../../../../../reduxToolkit/features/college/CollegesSlice";
import { fetchCourses } from "../../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchDepartments } from "../../../../../reduxToolkit/features/department/DepartmentsSlice";
import { MediaBaseUrl } from "assets/utils/accessToken";

const useStyles = makeStyles(styles);
const useTitleStyles = makeStyles(titleStyles);

export default function CreateCommunityServicesActivity({ match }) {

  let { id } = useParams();

  const { t } = useTranslation();
  let history = useHistory();
  const titleClasses = useTitleStyles();
  const [form] = Form.useForm();

  const [selectedProgram, setSelectedProgram] = React.useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [levelType, setLevelType] = React.useState("");

  const [university_id, setUniversity_id] = React.useState(null);

  const dispatch = useDispatch();

  const fieldsStatus = useSelector((state) => state.fieldSpec.fieldsStatus);

  const admissionRequirementsStatus = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirementsStatus
  );

  const graduationRequirementsStatus = useSelector(
    (state) => state.graduationRequirementSpec.graduationRequirementsStatus
  );

  const person = useSelector((state) => state.user.staff)
  const persons = useSelector((state) => state.user.staffList);
  const programs = useSelector((state) => state.program.programs);
  const courses = useSelector((state) => state.course.courses);
  const students = useSelector((state) => state.user.students);
  const faculty = useSelector((state) => state.user.staffFaculty);
  const currentSemester = useSelector((state) => state.semester.currentSemester);
  const configAcoordingtoType = useSelector((state) => state.authorityConfig.AuthorityConfigs);
  const colleges = useSelector((state) => state.college.colleges);
  const departments = useSelector((state) => state.department.departments);

  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [communityType, setCommunityType] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  let semester_id = currentSemester && currentSemester?.semester_id;
  let academicYear_id = currentSemester?.academicYear_id;

  useEffect(() => {
    dispatch(fetchStaffById(id));
    const userId= localStorage.getItem('userId');
    dispatch(fetchCurrentSemester(userId))
    dispatch(fetchStaff());
    dispatch(fetchStudents());
    dispatch(fetchStaffFaculty());
    dispatch(fetchAuthorityConfigs());
  }, []);

  function onHandleActivityLevelType(communityString) {
    setLevelType(communityString);
    //dispatch(fetchAuthorityAccordingToType(communityString.toString()))
    if (communityString === "department") {
      dispatch(fetchDepartments())
    } else if (communityString === "college") {
      dispatch(fetchColleges())
    }
    else if (communityString === "program") {
      dispatch(fetchCurrentSemester(localStorage.getItem('userId')))
        .unwrap()
        .then((semester) => {
          dispatch(fetchPrograms(semester.semester_id));
        })
        .catch((err) =>{
          setHandledError(err);
          handleOpenErrorModal()
      })
    }
    else if (communityString == "course") {
      dispatch(fetchCourses())
    }
  }

  function onHandleCommunityType(communityString) {
    setCommunityType(communityString);
    dispatch(fetchStudents())
    dispatch(fetchStaff());
  }

  function onStartDateChange(value, dateString) {
    setStartDate(dateString);
  }

  function onEndDateChange(value, dateString) {
    setEndDate(dateString);
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Submit failed!");
  };

  const onFinish = async (values) => {
    const faculty_id = parseInt(id);
    const toAddFacultyActivity = {
      ...values,
      start_date,
      end_date,
      semester_id,
      academicYear_id,
      faculty_id,
    };

    const resultAction = await dispatch(
      addNewCommunityServiceActivity(toAddFacultyActivity)
    );

    if (addNewCommunityServiceActivity.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Added successfully`);
      history.push(`/citrine/faculty/teachingStaff/${id}/communityServicesActivities`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const { Option } = Select;

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/communityServicesActivities`}
            >
              {t("Community services")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Add a new community service
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Type")}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size={200} showArrow>
                        <Option value="training">Training</Option>
                        <Option value="workshop">Workshop</Option>
                        <Option value="event">Event</Option>
                        <Option value="volunteering">Volunteering</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Activity Level")}
                      name="authorityLevelSelect"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size={200} showArrow
                        onChange={onHandleActivityLevelType}
                      >
                        <Option value="university">
                          university
                        </Option>
                        <Option value="college">
                          college
                        </Option>
                        <Option value="program">
                          program
                        </Option>
                        <Option value="course">
                          course
                        </Option>
                        <Option value="department">
                          department
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  {levelType === "college" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Colleges")} name="college_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {colleges &&
                            colleges.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)}
                  {levelType === "department" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Departments")} name="department_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {departments &&
                            departments.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)}
                  {levelType === "course" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Courses")} name="course_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {courses &&
                            courses.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.title}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)}
                  {levelType == "program" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Programs")} name="program_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {programs &&
                            programs.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.program_degree_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)}
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Title")}
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Community")}
                      name="community"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size={200} showArrow
                        onChange={onHandleCommunityType}
                      >
                        <Option value="students">Students</Option>
                        <Option value="faculty">Faculty</Option>
                        <Option value="staff">Staff</Option>
                        <Option value="external participants">
                          External participants
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  {communityType === "students" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item
                        label={t("Students")}
                        name="students_ids"
                      >
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          size={200}
                          showArrow
                        >
                          {students && students.map((student) => (
                            <Option key={student.id} value={student.id}>
                              {student.student_identification} {student.student_details && student.student_details.first_name} {student.student_details && student.student_details.last_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </GridItem>) : (<p></p>)
                  }
                  {communityType === "faculty" ? (<GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Faculty")}
                      name="faculty_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      >
                        {faculty && faculty.map((student) => (
                          <Option key={student.id} value={parseInt(student.id)}>
                            {/* {`${person.first_name} ${person.last_name}`} */}
                            {student.person_details && student.person_details[0].first_name} { }  {student.person_details && student.person_details[0].last_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    </GridItem>) : [<p></p>]
                  }
                  {communityType === "external participants" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item
                        label={t("External participants")}
                        name="external_participants_ids"
                        rules={[
                          {
                            message: "Please enter the external participants",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>
                  ) : [<p></p>]}
                  {communityType === "staff" ? (<GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Staff")}
                      name="staff_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      >
                        {persons.map((student) => (
                          <Option key={student.id} value={parseInt(student.id)}>
                            {/* {`${person.first_name} ${person.last_name}`} */}
                            {student.person_details && student.person_details[0].first_name} { }  {student.person_details && student.person_details[0].last_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>) : [<p></p>]}
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Start date")}
                      name="start_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the start date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onStartDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("End date")}
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the end date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onEndDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Description")}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the description",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Theme")}
                      name="theme"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the theme",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Location")}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the location",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Authority Person")}
                      name="authorityLevelConfig_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select the authors",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                      >
                        {configAcoordingtoType &&
                          configAcoordingtoType.map((community) => (
                            <Option key={community.id} value={community.id}>
                              {community && `${community.level_details.name}/${community.role_details.role}`}

                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Organization body")}
                      name="organization_body_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                        optionFilterProp="children"
                      >
                        {persons.map((student) => (
                          <Option key={student.id} value={parseInt(student.id)}>
                            {!student?.person_details[0]?.avatar ? (<span style={{
                              backgroundColor: 'rgb(143, 102, 130)',
                              width: '23px',
                              height: '23px',
                              color: 'white',
                              borderRadius: '50%',
                              paddingLeft: '7px',
                              marginRight: '15px',
                              display: 'inline-block',
                              lineHeight: '23px'
                            }}>{student?.person_details[0]?.first_name.charAt(0).toUpperCase() + '     '}</span>) : (<img style={{ width: '20px', height: "20px" }} src={`${MediaBaseUrl}${student?.person_details[0]?.avatar}`} />)}
                            {student.person_details && student.person_details[0].first_name} {student.person_details && student.person_details[0].last_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
