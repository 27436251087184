import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewSurveyTemplate,
  fetchSurveyTemplates,
} from '../../../reduxToolkit/features/Survey/SurveySlice'
import { fetchSurveyTemplatesTypeConfigs } from '../../../reduxToolkit/features/surveyTemplateTypeConfig/SurveyTemplateTypeConfigSlice'
import ReactJson from 'react-json-view'
import ErrorModal from '../../Components/ErrorModal'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
const useStyles = makeStyles(styles)
const useTitleStyles = makeStyles(titleStyles)
const { TextArea } = Input
const { Option } = Select

export default function CreateSurveyTemplate() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [template, setTemplate] = useState({})
  const [unparsedTemplate, setUnparsedTemplate] = useState('')
  const [name, setName] = useState('')
  const [surveyTemplateTypeConfig_id, setType] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  // useEffect(() => {
  //     dispatch(fetchSurveyTemplateById(id))
  //     .then(unwrapResult)
  //         .then((template) => {
  //             // do additional work
  //             console.log("originalPromiseResult", template)

  //             setStringifiedTemplate(JSON.stringify(template.template))
  //             console.log("template.template", template.template)

  //             setType(template.type)

  //         })
  // }, [dispatch])

  // const surveyTemplate = useSelector((state) => state.survey.surveyTemplate)

  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [jsonTemplate, setJsonTemplate] = useState({
    logoPosition: '',
    description: '',
    title: '',
    pages: [],
  })

  const canSave =
    [template, name, surveyTemplateTypeConfig_id].every(Boolean) &&
    addRequestStatus === 'idle'

  const onSaveTemplateClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(
          addNewSurveyTemplate({ name, template, surveyTemplateTypeConfig_id }),
        )

        unwrapResult(resultAction)
        setType('')
        setTemplate('')
      } catch (err) {
        console.error('Failed to save the template: ', err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
    dispatch(fetchSurveyTemplates())
  }

  const classes = useStyles()
  const titleClasses = useTitleStyles()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const surveyTemplatesTypesStatus = useSelector(
    (state) => state.surveyTemplatesTypeConfig.surveyTemplatesTypeConfigsStatus,
  )
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    message.error('Submit failed!')
  }
  const editSurvey = ({ updated_src }) => {
    setJsonTemplate(updated_src)
  }
  const addSurvey = ({ updated_src }) => {
    setJsonTemplate(updated_src)
  }
  const deleteSurvey = ({ updated_src }) => {
    setJsonTemplate(updated_src)
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onFinish = async (values) => {
    console.log({ ...values, template: jsonTemplate })
    dispatch(addNewSurveyTemplate({ ...values, template: jsonTemplate }))
      .unwrap()
      .then((res) => {
        dispatch(fetchSurveyTemplates())
        openNotificationWithIcon('success', `Added successfully`)
        history.push('/citrine/surveyTemplates')
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }
  useEffect(() => {
    if (surveyTemplatesTypesStatus === 'idle') {
      dispatch(fetchSurveyTemplatesTypeConfigs())
    }
  }, [surveyTemplatesTypesStatus, dispatch])
  const surveyTemplatesTypes = useSelector(
    (state) => state.surveyTemplatesTypeConfig.surveyTemplatesTypeConfigs,
  )

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={10}>
        <Link to={`/citrine/surveyTemplates`}>
          <span className={titleClasses.title}> {t('Survey templates')} </span>
        </Link>
        <Link to={`/citrine/surveyTemplates/add`}>
          <span className={titleClasses.title}> {`> ${t(' add')}`} </span>
        </Link>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <h4 className={classes.cardIconTitle}>
              {t('Create Survey Template')}
            </h4>
          </CardHeader>
          <CardBody>
            <Form
              name="basic"
              form={form}
              layout="vertical"
              initialValues={{ prerequisites_ids: [], co_requisites_ids: [] }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              scrollToFirstError
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <Form.Item
                    label={t('Name')}
                    name="name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Form.Item
                    label={t('Type')}
                    name="surveyTemplateTypeConfig_id"
                    rules={[
                      {
                        required: true,
                        //message: "Please select type",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {surveyTemplatesTypes &&
                        surveyTemplatesTypes.map((type) => (
                          <Option key={type.id} value={type.id}>
                            {`${type.category} /  ${type.name}`}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <ReactJson
                    src={jsonTemplate}
                    theme="monokai"
                    displayDataTypes={false}
                    onDelete={deleteSurvey}
                    onAdd={addSurvey}
                    onEdit={editSurvey}
                    name={null}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify="flex-end">
                <Form.Item>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridContainer>
            </Form>
            {/* <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                <label>{t('Name *')}</label>
                <TextArea
                  rows={4}
                  autoSize={true}
                  onChange={(event) => {
                    setName(event.target.value)
                  }}
                  value={name}
                />

                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label>{t('Type *')}</label>
                <br />
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={t('Select a type')}
                  optionFilterProp="children"
                  value={surveyTemplateTypeConfig_id}
                  onChange={(value) => {
                    setType(value)
                    console.log('typevalue', value)
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {surveyTemplatesTypes &&
                    surveyTemplatesTypes.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {`${type.category} /  ${type.name}`}
                      </Option>
                    ))}
                </Select>

                <br />
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <label>{t('Template *')}</label>
                <TextArea
                  rows={20}
                  autoSize={false}
                  onChange={(event) => {
                    setUnparsedTemplate(event.target.value)
                    //setTemplate(JSON.parse(event.target.value));

                    console.log('updating template', event.target.value)
                  }}
                  value={unparsedTemplate}
                />

                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.formCategory}>
                  <small>*</small> {t('Required fields')}
                </div>
                <Link to={`/citrine/surveyTemplates`}>
    <Button
                    color="primary"
                    onClick={onSaveTemplateClicked}
                    className={classes.registerButton}
                  >
                    {t('Save')}
                  </Button>
                </Link>
             </GridItem>
            </GridContainer> */}
          </CardBody>
        </Card>
      </GridItem>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </GridContainer>
  )
}
