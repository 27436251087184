import { DataGrid } from '@mui/x-data-grid';
import { Breadcrumb, notification } from 'antd';
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchQuestionsBySurveyTemplate, fetchSurveyTemplateById, updateQuestionWeight } from '../../../reduxToolkit/features/Survey/SurveySlice';
import ErrorModal from "../../Components/ErrorModal";




const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 90
    },
    {
        field: 'area',
        headerName: 'area',
        width: 150,
        editable: true,
    },
    {
        field: 'sub_area',
        headerName: 'sub_area',
        width: 150,
        editable: true,
    },
    {
        field: 'question',
        headerName: 'question',
        width: 500,
        editable: true,
    },
    {
        field: 'weight',
        headerName: 'weight',
        type: 'number',
        width: 150,
        editable: true,
    },
];


export default function AddWeightToSurveyQuestions({ match }) {
    const { id } = match.params
    const { t } = useTranslation();


    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSurveyTemplateById(id))

        dispatch(fetchQuestionsBySurveyTemplate(id))

    }, [dispatch])

    const surveyTemplate = useSelector((state) => state.survey.surveyTemplate)
    const questions = useSelector((state) => state.survey.questions)

    console.log("questions", questions)

    // cellMode: "edit"
    // colDef: {width: 150, minWidth: 50, hide: false, sortable: true, resizable: true, …}
    // field: "weight"
    // formattedValue: 0
    // getValue: ƒ ()
    // hasFocus: true
    // id: 47
    // isEditable: true
    // row: {id: 47, weight: 0, area: 'Scientific Subject', sub_area: 'CourseObjectivesAndComponents', question: 'OrganizingAndArrangingTheLecture'}
    // tabIndex: 0
    // value: 3

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };

    const onCellEditCommit = async (event) => {
        console.log("onCellEditCommit", event)
        if (event.field === "weight") {
            const value = event.value
            const updatedQuestion = {
                weight: value
            }
            const id = event.id
            const resultAction = await dispatch(
                updateQuestionWeight({ id: id, updatedQuestion: updatedQuestion })
            )
            if (updateQuestionWeight.fulfilled.match(resultAction)) {
                // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
                const question = resultAction.payload
                console.log('success', `Updated`)
                openNotificationWithIcon('success', `Updated successfully`)
            } else {
                if (resultAction.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                    console.log('resultAction.payload from component', resultAction.payload)
                    setHandledError(resultAction.payload)
                    handleOpenErrorModal()
                } else {
                    console.log('resultAction.error  from component', resultAction.error)
                    setHandledError(resultAction.error)
                    handleOpenErrorModal()
                }
            }
        }
    }


    return (
        <GridContainer>
            <GridContainer
                style={{
                    borderBottom: `1px solid  ${grayColor[15]}`,
                    borderTop: `1px solid  ${grayColor[15]}`,
                }}
                justify="space-between"
                alignItems="center"
            >
                <GridItem>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to={`/citrine/surveyTemplates`}>
                                {t('Survey templates')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/surveyTemplates/${id}`}>
                                {surveyTemplate && surveyTemplate.type}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link style={{ color: "orange" }}
                                to={`/citrine/surveyTemplates/${id}/addWeightToQuestions`}>
                                {t('Add weights')}
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>
            </GridContainer>

            <div style={{ height: 600, width: '100%', margin: 20 }}>
                {questions &&
                    <DataGrid
                        rows={questions}
                        columns={columns}
                        autoPageSize={true}
                        columnBuffer={0}
                        checkboxSelection
                        disableSelectionOnClick
                        onCellEditCommit={onCellEditCommit}
                    />}
            </div>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />

        </GridContainer >

    );
}
