import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles'
// material-ui icons
import MoreVert from '@material-ui/icons/MoreVert'
import { notification } from 'antd'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Button from 'components/CustomButtons/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ErrorModal from '../../../Components/ErrorModal'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import EditAssessmentMethodGroup from './EditAssessmentMethodGroup'
import { unwrapResult } from '@reduxjs/toolkit'
import { deleteCourseInstructor,fetchCourseInstructorById } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { deleteGroupAssessment } from 'reduxToolkit/features/group/GroupSlice'
const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

export default function DropdownEditCourseGroups({
  courseGroup,ping,setPing
}) {
    
  const { t } = useTranslation()
  const buttonClasses = useButtonStyles()
  const [open, setOpen] = React.useState(false)
  const [openSection, setOpenSection] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleDelete = async (id) => {
    
    console.log(id)
    dispatch(deleteGroupAssessment(id))
      .then(unwrapResult)
      .then(async (originalPromiseResult) => {
        openNotificationWithIcon('success', `Deleted successfully`)
        setPing(!ping)
        
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  // const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([])
  const [selectionModel, setSelectionModel] = React.useState([])
  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
        style={{ marginRight: '50px' }}
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <EditAssessmentMethodGroup
          handleClose={handleClose}
          courseGroup={courseGroup}
          ping={ping}
          setPing={setPing}
        
        />

        {/* <MenuItem disabled={!props.selectedRows.length}>Update Dates</MenuItem> */}

        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDelete}
        Element_ID={courseGroup.group_id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
