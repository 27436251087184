import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  KPICategories: [],
  KPICategoriesStatus: 'idle',
  error: null,
}

export const fetchKPICategories = createAsyncThunk('KPICategory/fetchKPICategories', async (param, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/kpiCategoryConfig/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})

const KPICategorySlice = createSlice({
  name: 'KPICategory',
  initialState,
  extraReducers: {
    [fetchKPICategories.pending]: (state, action) => {
      state.KPICategoriesStatus = 'loading'
    },
    [fetchKPICategories.fulfilled]: (state, action) => {
      state.KPICategoriesStatus = 'succeeded'
      state.KPICategories = action.payload
    },
    [fetchKPICategories.rejected]: (state, action) => {
      state.KPICategoriesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

})


export default KPICategorySlice.reducer


