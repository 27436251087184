import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  peosStatus: "idle",
  peos: [],
  peoStatus: "idle",
  peo: null,
  error: null,
};

export const fetchPeos = createAsyncThunk("peoSpec/fetchPeos", async ( { rejectWithValue }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(BaseUrl + "/peoSpec/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    // console.log(response.data,'response.data')

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
});

export const fetchPeoById = createAsyncThunk(
  "peoSpec/fetchPeoById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/peoSpec/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewPeo = createAsyncThunk(
  "peoSpec/addNewPeo",
  async (initialPeo, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/peoSpec/create/`,
        initialPeo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

  export const updatePEO = createAsyncThunk(
    'peoSpec/updatePEO',
    async (data,{rejectWithValue}) => {

      try{
        const token = localStorage.getItem("token");
        const response = await axios.put(BaseUrl + `/peoSpec/${data.id}/edit/`,data.updatedPeo ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data,'updatedPEO')
      console.log(response.data,'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
}
    }
  )

export const deletePeo = createAsyncThunk(
  "peoSpec/deletePeo",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(BaseUrl + `/peoSpec/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

const peosSlice = createSlice({
  name: "peoSpec",
  initialState,
  extraReducers: {
    [fetchPeos.pending]: (state, action) => {
      state.peosStatus = "loading";
    },
    [fetchPeos.fulfilled]: (state, action) => {
      state.peosStatus = "succeeded";
      // Add any fetched posts to the array
      state.peos = state.peos.concat(action.payload);
    },
    [fetchPeos.rejected]: (state, action) => {
      state.peosStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [fetchPeoById.pending]: (state, action) => {
      state.peoStatus = "loading";
    },
    [fetchPeoById.fulfilled]: (state, action) => {
      state.peoStatus = "succeeded";

      // Add any fetched posts to the array
      state.peo = action.payload;
    },
    [fetchPeoById.rejected]: (state, action) => {
      state.peoStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [addNewPeo.fulfilled]: (state, action) => {
      state.peos.push(action.payload);
    },
    [addNewPeo.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [deletePeo.fulfilled]: (state, action) => {
      // console.log(action.payload)
    },
    [deletePeo.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)

      }
    },

    [updatePEO.pending]: (state, action) => {
      state.peoStatus = "loading";
    },
    [updatePEO.fulfilled]: (state, action) => {
      state.peosStatus = "succeeded";
    },
    [updatePEO.rejected]: (state, action) => {
      state.peosStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },
  },
});

export default peosSlice.reducer;

export const selectAllPeos = (state) => state.peoSpec.peos;
