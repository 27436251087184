import { Avatar } from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { SchoolRounded } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Breadcrumb } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import Card from '@material-ui/core/Card';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PloReportModal from "views/Components/PloReportModal";
import { fetchPlos, rerunPLOsScores } from "../../../../reduxToolkit/features/PLO/PLOsSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));


export default function PloListPage({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = match.params;
  const semester_id = localStorage.getItem('semester_id')

  const [open, setOpen] = React.useState(false);
  const handleOpenReportModal = () => {
    setOpen(true);
  };
  const handleCloseReportModal = () => {
    setOpen(false);
  };


  const rerunPLOs = () => {
    if (semester_id) {

      dispatch(rerunPLOsScores({ semester_id, program_id: id }))
        .unwrap()
        .then((originalPromiseResult) => {
          // handle result here
          dispatch(fetchPlos(id));
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
        })
    }
  }

  useEffect(() => {
    dispatch(fetchProgramById(id))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        dispatch(fetchPlos(id)).unwrap()
      }).then(() => {
        rerunPLOs()
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(rejectedValueOrSerializedError)
      })
  }, []);

  const program = useSelector((state) => state.program.program);
  const plos = useSelector((state) => state.plo.plos);




  return (
    <>
      <Breadcrumb separator=">" params={id}>
        <Breadcrumb.Item>
          <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/program/${id}`}>
            {program?.program_degree_name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/program/${id}/PloListPage/`}
            style={{ color: "orange" }}
          >
            {t("Plo List")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br></br>
      <Card style={{ height: "fit-content", paddingBottom: 10 }}>
        <CardHeader
          color="info"
          icon
        >
          <GridContainer alignItems="center">

            <GridItem xs={12} sm={2} md={2}>
              <CardIcon
                color="primary"
              >
                <SchoolRounded />
              </CardIcon>
            </GridItem>

            <GridItem xs={12} sm={10} md={10}>
              <GridContainer justifyContent="space-between" alignItems="center" wrap="nowrap">
                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>{program?.code}</strong>
                  </h4>
                  <h4
                    style={{
                      color: "#6C7B9D",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>
                      {program?.program_degree_name}
                    </strong>
                  </h4>
                </GridItem>

                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      fontSize: "14px",
                      height: "13px",
                    }}
                  >
                    <strong> {t("University")} </strong>

                  </h4>
                  <h4
                    style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                  >
                    <strong>
                      {program?.university_detail?.map((el) => el.name)}
                    </strong>
                  </h4>
                </GridItem>

                <GridItem>
                  <CustomDropdown
                    dropPlacement="bottom-end"
                    isButtonIcon
                    dropdownList={[
                      { text: "Rerun PLOs Scores", onClickHandler() { rerunPLOs() } },
                      { text: "Create PLO Report", onClickHandler() { handleOpenReportModal() } },
                    ]}
                  />
                  {/* <p>last refreshed at : 22/02/2024</p> */}
                </GridItem>

              </GridContainer>
            </GridItem>
          </GridContainer>

        </CardHeader>
      </Card>
      <br></br>
      <GridContainer xs={12} sm={12} md={12}>
        {plos?.map((item, i) => (
          <GridItem xs={12} sm={12} md={4} key={i}>
            <Card style={{ height: "230px", zIndex: 0 }}>
              <CardHeader>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={3}>
                    <div
                      style={{
                        backgroundColor: "#8F6682",
                        height: "60px",
                        width: "60px",
                        padding: "0px",
                        borderRadius: "3px",
                        textAlign: "center",
                      }}
                    >
                      <AssignmentIcon
                        style={{
                          fontSize: 50,
                          color: "aliceblue",
                          marginTop: "10%",
                        }}
                      />
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={9}>
                    <strong>
                      {item.code}
                    </strong>
                    <Link to={`/citrine/PloListPage`}>
                      <strong>
                        <p
                          style={{
                            color: "gray",
                            textAlign: "start",
                            fontSize: "14px",
                          }}
                        >
                          <Link to={`/citrine/program/${id}/Plo/${item.id}`}>
                            <Tooltip
                              id="tooltip-top"
                              title={item.name}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                              style={{ fontSize: "14px" }}
                            >
                              <div simple color="info" justIcon>
                                {item.name.split("").splice(0, 70)}...
                              </div>
                            </Tooltip>
                          </Link>
                        </p>
                      </strong>
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {/* {program?.assessment_calculation_method === "clo" ? ( */}
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginEnd: "10px",
                      }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: red[600],
                            height: 56,
                            width: 56,
                          }}
                          style={{ backgroundColor: "#6C7B9D" }}
                        >
                          {item.target}
                        </Avatar>
                        <p style={{ marginTop: "10px", fontSize: "14px" }}>
                          {t("target")}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: orange[600],
                            height: 56,
                            width: 56,
                          }}
                          style={{ backgroundColor: "#6C7B9D" }}
                        >
                          {item.ploIndicators_count}
                        </Avatar>
                        <p style={{ marginTop: "10px", fontSize: "14px" }}>
                          {t("PLO Indicators")}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "info",
                            height: 56,
                            width: 56,
                          }}
                          style={{ backgroundColor: "#6C7B9D" }}
                        >
                          {item.clos_count}
                        </Avatar>
                        <p style={{ marginTop: "10px", fontSize: "14px" }}>
                          {t("Aligned CLOs")}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: green[600],
                            height: 56,
                            width: 56,
                          }}
                          style={{ backgroundColor: "#6C7B9D" }}
                        >
                          {item?.plo_score ? `${item.plo_score.toFixed()}%`:""}
                          {/* {item &&
                              item.score_by_question &&
                              item.score_by_question.toFixed(0)} */}
                          {/* % */}
                        </Avatar>
                        <p style={{ marginTop: "10px", fontSize: "14px" }}>
                          {t("Score")}
                        </p>
                      </Grid>
                    </div>
                  </GridItem>
                {/* ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginEnd: "10px",
                      }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#6C7B9D",
                            width: "48px",
                            height: "48px",
                          }}
                        >
                          {item.target}
                        </Avatar>
                        <p style={{ marginTop: "10px", fontSize: "14px" }}>
                          {t("Target")}
                        </p>
                      </Grid>

                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#6C7B9D",
                            width: "48px",
                            height: "48px",
                          }}
                        >
                          {item?.courses_count}
                        </Avatar>
                        <p style={{ marginTop: "10px", fontSize: "14px" }}>
                          {t("Aligned Courses")}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#6C7B9D",
                            width: "48px",
                            height: "48px",
                          }}
                        >
                          {item?.score_by_question ? `${item.score_by_question.toFixed()}%`:""}

                        </Avatar>
                        <p style={{ marginTop: "10px", fontSize: "14px" }}>
                          {t("Score PLO")}
                        </p>
                      </Grid>
                    </div>
                  </GridItem>
                )} */}
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
      <PloReportModal
        ProgramID={program && program.id && program.id}
        handleCloseReportModal={handleCloseReportModal}
        open={open}
      />
    </>
  );
}
