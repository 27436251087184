import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
function PreviousEvaluations({ data }) {
  const rows = [
    {
      id: 1,
      subject: "Curriculum Breadth",
      descripton:
        "The curriculum provides strong coverage of core engineering concepts, but there is limited exposure to emerging technologies such as artificial intelligence and data analytics, which are increasingly important in modern engineering practice.",
      type: "concern",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "1",
          feedbackId: "1",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "2",
          feedbackId: "1",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "3",
          feedbackId: "1",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
    {
      id: 2,
      subject: "Outcomes Assessment",
      descripton:
        "The program has not adequately demonstrated a structured and comprehensive approach to assessing student outcomes. There is no clear evidence that student performance is systematically evaluated against the stated program outcomes.",
      type: "deficiency",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "4",
          feedbackId: "2",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "5",
          feedbackId: "2",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "6",
          feedbackId: "2",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
    {
      id: 3,
      subject: "Advising and Mentorship",
      descripton:
        "While academic advising is available, there is a weakness in the advising process, as students have expressed concerns about the lack of personalized guidance and mentorship to help them navigate their academic and career pathways.",
      type: "weakness",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "7",
          feedbackId: "3",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "8",
          feedbackId: "3",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "9",
          feedbackId: "3",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
    {
      id: 4,
      subject: "Strong Industry Partnerships",
      descripton:
        "The program has established strong partnerships with local industries, offering students valuable internship opportunities and industry-sponsored projects that enhance their professional readiness.",
      type: "strength",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "10",
          feedbackId: "4",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "11",
          feedbackId: "4",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "12",
          feedbackId: "4",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
    {
      id: 5,
      subject: "Expand Emerging Technology Offerings",
      descripton:
        "To keep pace with industry trends, the program should consider introducing electives or modules focused on emerging areas such as artificial intelligence, robotics, and renewable energy.",
      type: "recommendation for improvement",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "13",
          feedbackId: "5",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "14",
          feedbackId: "5",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "15",
          feedbackId: "5",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
  ];

  const columns = [
    { field: "subject", headerName: "Subject", flex: 1, minWidth: 150 },
    { field: "descripton", headerName: "Descripton", flex: 2, minWidth: 300 },
    { field: "type", headerName: "Type", flex: 0.5, minWidth: 75 },
    {
      field: "assignees",
      headerName: "Assignees",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {params.value.map((assignee) => (
            <Chip
              key={assignee.id}
              label={assignee.name}
              size="small"
              variant="outlined"
            />
          ))}
        </Box>
      ),
    },
    { field: "startdate", headerName: " Start Date", flex: 0.5, minWidth: 75 },
    { field: "enddate", headerName: "End Date", flex: 0.5, minWidth: 75 },

    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      minWidth: 300,
      renderCell: (params) => (
        <List dense>
          {params.value.map((action) => (
            <GridContainer key={action.id} alignItems="center">
              <GridItem xs={11} sm={11} md={11}>
                <ListItem key={action.id}>
                  <ListItemText
                    primary={action.subject}
                    secondary={
                      <>
                        <Typography variant="body2" color="text.secondary">
                          {action.descripton}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Date: {action.date}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <Button
                  justIcon
                  round
                  color="transparent"
                  onClick={() => {
                    console.log("clicked");
                  }}
                >
                  <EditIcon />
                </Button>
              </GridItem>
            </GridContainer>
          ))}
        </List>
      ),
    },
  ];
  return (
    <>
      <h3>Background Information: Previous Evaluations</h3>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={data.feedback || []}
          columns={columns}
          getRowHeight={() => "auto"}
          autoHeight
          autoWidth
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
}

export default PreviousEvaluations;
