import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";

import { Column, TreeList } from "devextreme-react/tree-list";


const expandedRowKeys = [-1];

export default function CourseContentsTreeList({ id }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [disabledStatus, setDisabledStatus] = useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCourseByIdMethod = async () => {
    try {
      const resultAction = await dispatch(fetchCourseById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchCourseByIdMethod();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const [view, setView] = useState("list");

  return (
    <div>
      <GridContainer justify="space-between" alignItems="baseline">
        <GridContainer>
          <GridItem>
            {disabledStatus ? (
              <p></p>
            ) : (
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/CourseContents/create`}
              >
                <Button color="info">{t("Add")}</Button>
              </Link>
            )}
          </GridItem>
        </GridContainer>
        <TreeList
          style={{ maxHeight: "440px" }}
          dataSource={CourseContent}
          rootValue={-1}
          defaultExpandedRowKeys={expandedRowKeys}
          showRowLines={true}
          showBorders={true}
          columnAutoWidth={true}
          keyExpr="ID"
          parentIdExpr="Head_ID"
        >
          <Column
            dataField="type"
            caption="Type" />
          <Column dataField="name" />
          <Column dataField="hours" />

        </TreeList>
      </GridContainer>
    </div>
  );
}
const CourseContent = [
  {
    ID: 100,
    Head_ID: -1,
    name: "",
    type: "Theory",
    hours: "100",
  },

  {
    ID: 101,
    Head_ID: -1,
    name: "",
    type: "Laboratory",
    hours: "80",
  },

  {
    ID: 1,
    Head_ID: 100,
    name: "hTML",
    type: "",
    hours: "80",
  },
  {
    ID: 2,
    Head_ID: 100,
    name: "CSS",
    type: "",
    hours: "20",
  },


  {
    ID: 3,
    Head_ID: 101,
    name: "Lab 1",
    type: "",
    hours: "60",
  },
  {
    ID: 4,
    Head_ID: 101,
    name: "Lab 2",
    type: "",
    hours: "20",
  },
];