import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

export const fetchLocations = createAsyncThunk(
    "locations/fetchLocations",
    async (_, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().locations
        if (loading !== 'pending' || requestId !== currentRequestId) {
            return
        }
        const token = localStorage.getItem("token");
        // try {
        const response = await axios.get(
            BaseUrl + `/locationSpec/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;
        // } catch (err) {
        //     console.log("err",err)
        //     let error = err; // cast the error for access
        //     console.log("error",error)

        //     if (!error.response) {
        //         throw err;
        //     }
        //     return rejectWithValue(error?.response?.data);
        // }
    }
);

export const fetchLocation = createAsyncThunk(
    "locations/fetchLocation",
    async (id, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().locations
        if (loading !== 'pending' || requestId !== currentRequestId) {
            return
        }
        const token = localStorage.getItem("token");
        // try {
        const response = await axios.get(
            BaseUrl + `/locationSpec/${id}`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;
        // } catch (err) {
        //     let error = err; // cast the error for access
        //     if (!error.response) {
        //         throw err;
        //     }
        //     return rejectWithValue(error?.response?.data);
        // }
    }
);

export const createLocation = createAsyncThunk(
    "locations/createLocation",
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                BaseUrl + `/locationSpec/create/`,
                data,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
);

export const updateLocation = createAsyncThunk(
    "locations/updateLocation",
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        try {
            const { id, ...fields } = data

            const response = await axios.put(
                BaseUrl + `/locationSpec/${id}/edit/`,
                fields,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
);

export const deleteLocation = createAsyncThunk(
    "locations/deleteLocation",
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        const response = await axios.delete(
            BaseUrl + `/locationSpec/${id}/delete/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;

    }
);
const LocationsSlice = createSlice({
    name: "locations",
    initialState: {
        locations: [],
        location: null,
        loading: "idle",
        currentRequestId: undefined,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocations.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchLocations.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.locations = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchLocations.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        builder
            .addCase(fetchLocation.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchLocation.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.location = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchLocation.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        builder.addCase(createLocation.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        builder.addCase(updateLocation.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        builder.addCase(deleteLocation.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
    },
});

export default LocationsSlice.reducer;
