import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  FieldExperienceLocations: [],
  FieldExperienceLocationsStatus: "idle",
  FieldExperienceLocationStatus: "idle",
  error: null,
  FieldExperienceLocation: null,
  id: null,
};

export const fetchFieldExperienceLocations = createAsyncThunk(
  "FieldExperienceLocation/fetchFieldExperienceLocations",
  async (courseSpec_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/locationRequirement/?courseSpec_id=${courseSpec_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const fetchFieldExperienceLocationById = createAsyncThunk(
  "FieldExperienceLocation/fetchFieldExperienceLocationById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/locationRequirement/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewFieldExperienceLocation = createAsyncThunk(
  "FieldExperienceLocation/addNewFieldExperienceLocation",
  async (initialFieldExperienceLocation, { rejectWithValue }) => {
    console.log("initial FieldExperienceLocation", initialFieldExperienceLocation);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/locationRequirement/create/`,
        initialFieldExperienceLocation,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const FieldExperienceLocationUpdate = createAsyncThunk(
  "FieldExperienceLocation/updateFieldExperienceLocation",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/locationRequirement/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updated");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteFieldExperienceLocation = createAsyncThunk(
  "FieldExperienceLocation/deleteFieldExperienceLocation",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/locationRequirement/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const FieldExperienceLocationSlice = createSlice({
  name: "FieldExperienceLocation",
  initialState,

  extraReducers: {

    [fetchFieldExperienceLocations.pending]: (state, action) => {
      state.FieldExperienceLocationsStatus = "loading";
    },
    [fetchFieldExperienceLocations.fulfilled]: (state, action) => {
      state.FieldExperienceLocationsStatus = "succeeded";
      state.FieldExperienceLocations = action.payload;
    },
    [fetchFieldExperienceLocations.rejected]: (state, action) => {
      state.FieldExperienceLocationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    
    [fetchFieldExperienceLocationById.pending]: (state, action) => {
      state.FieldExperienceLocationStatus = "loading";
    },
    [fetchFieldExperienceLocationById.fulfilled]: (state, action) => {
      state.FieldExperienceLocationStatus = "succeeded";
      state.FieldExperienceLocation = action.payload;
    },
    [fetchFieldExperienceLocationById.rejected]: (state, action) => {
      state.FieldExperienceLocationStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewFieldExperienceLocation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewFieldExperienceLocation.fulfilled]: (state, action) => {
      state.FieldExperienceLocations.push(action.payload);
    },
    [deleteFieldExperienceLocation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteFieldExperienceLocation.fulfilled]: (state, action) => {
     
    },
    [FieldExperienceLocationUpdate.fulfilled]: (state, action) => {
      console.log(action);
    },
    [FieldExperienceLocationUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    reducers: {
 
    },
  },
});

export const {} = FieldExperienceLocationSlice.actions;

export default FieldExperienceLocationSlice.reducer;

