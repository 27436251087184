// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.js";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchMajorTracks } from "../../../../reduxToolkit/features/majorTracks/MajorTracksSlice";
import {

    fetchProgramById
} from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "../../../Components/ErrorModal";
import MajorTrackDropDownButton from "../../../Components/MajorTrackDropDownButton";
import MajorTracksModal from "../../../Components/MajorTracksModal";
import Chip from '@material-ui/core/Chip'










const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
//const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.2),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.2),
    },
  }))
const MajorTrackExcerpt = ({ Element, program, id, disabledStatus, isEditor }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const cardClasses = useCardStyles();
    const buttonClasses = useButtonStyles();

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const [smallModal, setSmallModal] = React.useState(false);

    return (
        <GridContainer xs={12} sm={12} md={4}>
            <GridItem key={Element.id} xs={12} sm={12} md={12}>
                <Card style={{ height: 200 }}>
                    <CardBody>
                        <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={10}>
                                <h4
                                    className={classes.cardProductTitle}

                                >

                                    <a
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}

                                    >
                                        {t("Pathway")}:  {Element && Element.pathway}
                                    </a>
                                </h4>



                                <h4
                                    className={classes.cardProductTitle}

                                >
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        {t("Credit Hours")} : {Element.credit_hours}
                                    </a>
                                </h4>
                                <h4
                                    className={classes.cardProductTitle}

                                >
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        {t("Potential Jobs")} 
                                    </a>
                                </h4>
                                {Element?.jobs_details?.map((job) => <Chip
              label={job?.name}
              className={classes.chip}
            />)}
                                


                            </GridItem>
                            {isEditor && (
                                <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }}>
                                    {disabledStatus ? <p></p> :
                                        <MajorTrackDropDownButton
                                            MajorTrack_ID={Element.id}
                                            programID={Element.programSpec_id}
                                        />
                                    }
                                </GridItem>
                            )}
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </GridContainer>
    );
};

export default function MajorTrackList({ match }) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();

    const { id } = match.params;
    const dispatch = useDispatch();
    const [isEditor, setIsEditor] = useState(true);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const fetchAllMajorTracks = async (id) => {
        try {
            const resultAction = await dispatch(fetchMajorTracks(id)).unwrap();
            dispatch(fetchProgramById(id));

        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    useEffect(() => {
        fetchAllMajorTracks(id);
        if (localStorage.getItem("state") != "Draft") {
            setDisabledStatus(true);
        }

    }, [dispatch]);

    const program = useSelector((state) => state.programSpec.program);
    const majorTracks = useSelector(
        (state) => state.majorTracks.majorTracks
    );

    const MajorTracksStatus = useSelector(
        (state) => state.majorTracks.majorTracksStatus
    );

    let content;

    if (MajorTracksStatus === "loading") {
        content = <div className="loader">{t("Loading...")}</div>;
    } else if (MajorTracksStatus === "succeeded") {
        if (majorTracks && majorTracks.length) {
            content = majorTracks.map((Element) => (
                <MajorTrackExcerpt
                    key={Element.id}
                    Element={Element}
                    program={program}
                    disabledStatus={disabledStatus}
                    isEditor={isEditor}
                />
            ));
        } else {
            content = <h5>{t("This Program has no major tracks to display!")}</h5>;
        }
    }
    return (
        <div>
            <GridItem>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to={`/citrine/programSpec`}>{t("Programs List")}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/citrine/programSpec/${program && program.id && program.id
                                }`}
                        >
                            {program && program.program_degree_name && program.program_degree_name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/citrine/specification/program/${program && program.id && program.id}
              /majorTracks/`}
                            style={{ color: "orange" }}
                        >

                            {t("Major Tracks List")}
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </GridItem>
            <GridContainer>
                {isEditor && (
                    <GridItem>

                        {disabledStatus ? <p></p> :
                            <MajorTracksModal programSpec_id={id} disabledStatus={disabledStatus} />
                        }
                    </GridItem>
                )}
            </GridContainer>

            <GridContainer>{content}</GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    );
}
