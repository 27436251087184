
import { makeStyles } from "@material-ui/core/styles";
import {
  DataGrid
} from "@mui/x-data-grid"; // core components
import { Breadcrumb, notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CourseOutlineUpdate, fetchCourseOutlines
} from "reduxToolkit/features/CourseOutline/CourseOutlineSlice";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";
import ErrorModal from "../../../../Components/ErrorModal";

const useStyles = makeStyles(styles);

export default function CourseOutlineList({ match }) {
  const [disabledStatus, setDisabledStatus] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = match.params;

  const columns = [
    // {
    //   field: "id",
    //   headerName: "id",
    //   width: 50,
    //   sortable: false,
    // },
    {
      field: "week",
      headerName: "Week",
      flex: 1,
      editable: disabledStatus ? false:true,
      sortable: false,
    },
    {
      field: "courseSpecContent",
      headerName: "Topic",
      flex: 1,
      editable: disabledStatus ? false:true,
      sortable: false,
    },
    {
      field: "teachingStrategiesSpec",
      headerName: "Class plan",
      flex: 1,
      editable: disabledStatus ? false:true,
      sortable: false,
    },
    {
      field: "learningResourceSpec",
      headerName: "Reading materials",
      flex: 1,
      editable: disabledStatus ? false:true,
      sortable: false,
    },
    {
      field: "assessmentMethodSpec",
      headerName: "Tasks and assignments",
      flex: 2,
      editable: disabledStatus ? false:true,
      sortable: false,
    },
  ];

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllCourseOutlines = async () => {
    try {
      const resultAction = await dispatch(fetchCourseOutlines(id)).unwrap();
      dispatch(fetchCourseById(id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    dispatch(fetchCourseById(id));
    fetchAllCourseOutlines();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const CourseOutlines = useSelector(
    (state) => state.CourseOutline.CourseOutlines
  );

  console.log("CourseOutlinesh", CourseOutlines);
  // if (!course) {
  //     return (
  //         <section>
  //             <h2>Course not found!</h2>
  //         </section>
  //     )
  // }

  const onCellEditCommit = async (event) => {
    console.log("onCellEditCommit", event);

    const value = event.value;
    const field = event.field;
    const id = event.id;
    const modified = { [field]: value !== "" ? value : null };
    console.log('modified', modified)
    if (event.cellMode) {
      const resultAction = await dispatch(CourseOutlineUpdate({ id, modified }))
      if (CourseOutlineUpdate.fulfilled.match(resultAction)) {
        fetchAllCourseOutlines();
        openNotificationWithIcon("success", `Updated successfully`);
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
        } else {
          console.log("resultAction.error  from component", resultAction.error);
          setHandledError(resultAction.error);
          handleOpenErrorModal();
        }
      }
    }

    // if (value && value !== event.row[field]) {

    //   const resultAction = await dispatch(
    //     CourseOutlineUpdate({ id, modified })
    //   );
    //   if (CourseOutlineUpdate.fulfilled.match(resultAction)) {
    //     fetchAllCourseOutlines();
    //     openNotificationWithIcon("success", `Updated successfully`);
    //   } else {
    //     if (resultAction.payload) {
    //       console.log(
    //         "resultAction.payload from component",
    //         resultAction.payload
    //       );
    //       setHandledError(resultAction.payload);
    //       handleOpenErrorModal();
    //     } else {
    //       console.log("resultAction.error  from component", resultAction.error);
    //       setHandledError(resultAction.error);
    //       handleOpenErrorModal();
    //     }
    //   }
    // }
  };

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/CourseSpecificationList/`}>
              {t("Courses List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseSpecDetails/${course && course.id && course.id
                }`}
            >
              {course && course.title && course.title}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/specification/course/${course && course.id && course.id
                }/CourseOutlines`}
              style={{ color: "orange" }}
            >
              {" "}
              {t("Course Outlines List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridContainer>
        <GridItem>
          {/* <Button color="info" className={classes.updateProfileButton}>
            {t("Saveeee")}
          </Button> */}
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
                {CourseOutlines && CourseOutlines.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 200, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={10} sm={10} md={10}>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                {t('Week')} : {Element.week}
                                            </a>

                                        </h4>


                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{ zIndex: 1 }} >
                                        {disabledStatus ? <p></p> :
                                            <DropDownButtonCourseOutline CourseOutline_ID={Element.id} courseID={course && course.id} />
                                        }
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer> */}

      <Card simple>
        <CardBody simple>
          <div style={{ height: 600, width: "100%"}}>
            <DataGrid
              rowHeight={50}
              rows={CourseOutlines || []}
              columns={columns}
              autoPageSize={true}
              columnBuffer={0}
              disableSelectionOnClick
              onCellEditCommit={onCellEditCommit}
              showCellRightBorder



            // disableColumnMenu
            // showColumnRightBorder
            // checkbox
            //   checkboxSelection
            //   onSelectionModelChange={(newSelectionModel) => {
            //     setSelectionModel(newSelectionModel);
            //     console.log("newSelectionModel", newSelectionModel);
            //   }}
            //   selectionModel={selectionModel}
            />
          </div>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
