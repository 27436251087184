import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import {deleteStandard} from "../standardConfig/StandardConfigSlice";

const initialState = {
  TypesConfigs: [],
  TypesConfigsStatus: 'idle',
  TypesConfig: null,
  TypesConfigStatus: 'idle',
}

export const fetchTypesConfigs = createAsyncThunk('typesConfig/fetchTypesConfigs', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/typesConfig/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const addTypesConfig = createAsyncThunk(
  'typesConfig/addTypesConfig',
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/typesConfig/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
)

export const deleteTypesConfig = createAsyncThunk(
    'typesConfig/deleteTypesConfig',
    async (id, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.delete(BaseUrl + `/typesConfig/${id}/delete/`, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        return response?.data
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
      }
    })

const typesConfigSlice = createSlice({
  name: 'typesConfig',
  initialState,
  extraReducers: {
    [fetchTypesConfigs.pending]: (state, action) => {
      state.TypesConfigsStatus = 'loading'
    },
    [fetchTypesConfigs.fulfilled]: (state, action) => {
      state.TypesConfigsStatus = 'succeeded'

      state.TypesConfigs = action.payload
    },
    [fetchTypesConfigs.rejected]: (state, action) => {
      state.TypesConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteTypesConfig.fulfilled]: (state, action) => {},
    [deleteTypesConfig.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

})

export default typesConfigSlice.reducer

