import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons

import { Link } from "react-router-dom";
import {
  addNewLearningResource,
  fetchLearningResources
} from "../../../../../reduxToolkit/features/LearningResource/LearningResourceSlice";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import "antd/dist/antd.css";


import { Breadcrumb } from "antd";
import {

  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";


import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import {
  fetchLearningResourceById, LearningResourceUpdate
} from "reduxToolkit/features/LearningResource/LearningResourceSlice";
import { fetchLearningResourcesByCourse } from "../../../../../reduxToolkit/features/LearningResource/LearningResourceSlice";
import ErrorModal from "../../../../Components/ErrorModal";

const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

export default function CreateLearningResource({ match }) {
  const { t } = useTranslation();
  const useTitleStyles = makeStyles(titleStyles);

  const [form] = Form.useForm();
  const [filteredResources, setFilteredResources] = useState([]);
  let tab = [];


  const titleClasses = useTitleStyles();
  const { id } = match.params;
  console.log("idCourse", id);
  const { idLR } = match.params;
  console.log("idLR", idLR);
  const isAddMode = isNaN(idLR);
  console.log("id from match is add mode", isAddMode);

  const [resource_type_id, setResource_type] = useState("");
  const [resource_type, setResourcetype] = useState("");
  const [resource, setResource] = useState("");
  const [chapter_ids, setChapter_ids] = useState([]);
  const [courseSpec_id, setCourseSpec_id] = useState(id);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");


  const onResource_typeChanged = (e) => setResource_type(e.target.value);
  const oResourceChanged = (e) => setResource(e.target.value);
  const onCourseSpec_idChanged = (e) => setCourseSpec_id(e.target.value);

  const dispatch = useDispatch();
  const history = useHistory();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  useEffect(() => {
    dispatch(fetchLearningResourcesByCourse(id))
      .then(unwrapResult)
      .then(() => dispatch(fetchCourseById(id)));
  }, [dispatch]);



  const LearningResourceStatus = useSelector(
    (state) => state.LearningResource.LearningResourceStatus
  );
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  console.log("course area", course);

  const resources = useSelector((state) => state.LearningResource.LearningResourcesByCourse);
  console.log("resources areas", resources);

  const { Option } = Select;
  const canSave =
    [resource_type_id, resource, courseSpec_id].every(Boolean) &&
    addRequestStatus === "idle";

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewLearningResource({ ...values, courseSpec_id })
    );
    if (addNewLearningResource.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchLearningResources(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/LearningResources`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {

    const resultAction = await dispatch(
      LearningResourceUpdate({
        id: idLR,

        modified: {
          ...values,
          courseSpec: parseInt(courseSpec_id),
        },
      })
    );
    if (LearningResourceUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchLearningResources(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/LearningResources`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchLearningResourceById(idLR))
        .then(unwrapResult)
        .then((LR) => {
          console.log(LR, "LR");
          form.setFieldsValue({
            resource_type: LR.resource_type_id,
            resource: LR.resource,
            courseSpec_id: LR.courseSpec_id,
          });
        });
    }
  }, [idLR]);

  const res = useSelector((state) => state.LearningResource.LearningResource);
  console.log("res ", res);

  function onChangeResource_type(value) {
    //  console.log(`selected ${value}`);
    setResource_type(value);
  }

  const handleCourse_id = (id) => {
    setCourseSpec_id(id);
    //   console.log('course_id', id)
    dispatch(fetchCourseById(id));
  };
  function onChangeResourceType(value) {
    console.log(`selected ${value}`);
    setResourcetype(value);

  }

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${id}/LearningResources`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Learning Resource List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="resource_type_id"
                        label="Resource Type"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select Resource Type" allowClear>
                          {resources &&
                            resources.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="resource"
                        label="Resource"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${id}/LearningResources`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                   

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${id}/LearningResources`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Learning Resource List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="resource_type"
                        label="Resource Type"
                        onChange={onChangeResourceType}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select  Resource Type" allowClear>
                          {resources &&
                            resources.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="resource"
                        label="Resource"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${id}/LearningResources`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
