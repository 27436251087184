//import Input from "@material-ui/core/Input";
//import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
} from '@ant-design/icons'
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Form, Input, Select, Space, notification } from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchPersons } from 'reduxToolkit/features/person/PersonsSlice'
import {
  addNewUser,
  fetchUsers,
  getUserById,
  updateUser,
} from 'reduxToolkit/features/user/UserSlice'
import { fetchUserGroups } from 'reduxToolkit/features/userGroups/UserGroupSlice'
import teacher from '../../../../assets/img/teacher.png'
import ErrorModal from '../../../Components/ErrorModal'
import EditAvatarModal from '../../Faculty/CvTech/EditAvatarModal.js'
import Avatar from 'components/Avatar/Avatar'

const useStyles = makeStyles(styles)

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
}

export default function AddEditUser({ match }) {

  const { id } = match.params

  const useTitleStyles = makeStyles(titleStyles)
  const classes = useStyles()
  const { t } = useTranslation()
  const isAddMode = isNaN(id)
  const [form] = Form.useForm()
  const titleClasses = useTitleStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [person_id, setPerson_id] = useState('')
  const [bio, setBio] = useState('')
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')
  const [groups, setGroups_ids] = useState('')
  const [user, setUser] = useState(null)
  const [handleErrorText, setHandleErrorText] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState({password: '', showPassword: false,})
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const { Option } = Select

  const dispatch = useDispatch()
  const history = useHistory()

  const persons = useSelector((state) => state.person.persons)
  const groupsList = useSelector((state) => state.userGroup.userGroups)
  const person = useSelector((state) => state.person.person)

  useEffect(() => {
    dispatch(fetchUserGroups())
    dispatch(fetchPersons())
  }, [])

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getUserById(id))
        .then(unwrapResult)
        .then((user) => {
          setUser(user)
          user.groups.map((group) => {
            groups_ids.push(group.id)
          })
          form.setFieldsValue({
            username: user.username,
            password: user.password,
            person_id: user.person,
            groups: user.groups,
          })
        })
    }
  }, [])

  function onChange(value) {
    setPerson_id(value)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
    setPassword(event.target.value)
  }

  function onChangeGroups_ids(value) {
    setGroups_ids(value)
  }

  let groups_ids = []

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinish = async (values) => {
    dispatch(addNewUser(values))
      .unwrap()
      .then((res) => {
        openNotificationWithIcon('success', `Added successfully`)
        form.resetFields()
        dispatch(fetchUsers())
        history.push(`/citrine/users`)
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }

  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      updateUser({
        id: id,
        modified: {
          ...values,
        },
      }),
    )
    if (updateUser.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `edit successfully`)
      form.resetFields()
      dispatch(fetchUsers())
      history.push(`/citrine/users/`)
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {isAddMode && (
        <div>
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/citrine/configurations`}>
                  {t('Configurations')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/users/`}>{t('Users List')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Create')}</Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridContainer>
            <Card>
              <CardBody>
                <GridItem xs={12} sm={12} md={10}>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="username"
                      label="Username"
                      rules={[
                        {
                          required: true,
                          message: 'Username is required',
                        },
                      ]}
                    >
                      <Input
                        autoComplete="new-password"
                        style={{ width: '130%' }}
                        size="large"
                        prefix={<UserOutlined />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Password is required',
                        },
                        {
                          min: 8,
                          message: 'Your password must be at least 8 characters',
                        },
                      ]}
                    >
                      <Space direction="vertical" style={{ width: '130%' }}>
                        <Input.Password
                          placeholder="input password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          autoComplete="new-password"
                        />
                      </Space>
                    </Form.Item>
                    <Form.Item
                        name="passwordConfirmation"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                          }),
                        ]}
                    >
                      <Space direction="vertical" style={{ width: '130%' }}>
                        <Input.Password
                            placeholder="retype password"
                            iconRender={(visible) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                            autoComplete="confirm-password"
                        />
                      </Space>
                    </Form.Item>
                    <Form.Item
                      label={t('Person')}
                      name="person_id"
                      rules={[
                        { required: true, message: 'Please select a person' },
                      ]}
                    >
                      <Select
                        showSearch={false}
                        style={{ width: 350 }}
                        optionFilterProp="children"
                        name="person_id"
                        value={person_id}
                        onChange={onChange}
                        filterOption={(input, option) => {
                          /*option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0*/
                        }
                        }
                      >
                        {persons && persons?.map((p) => (
                          <Option key={p.id} value={p.id}>
                            <GridContainer
                              justifyContent="flex-start"
                              alignItems="baseline"
                            >
                              <GridItem>
                                <Avatar url={p.avatar} />
                              </GridItem>
                              <GridItem>
                                {' '}
                                <p>
                                  {p.first_name} {p.last_name}
                                </p>
                              </GridItem>
                            </GridContainer>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={t('Groups')}
                      name="groups"
                      rules={[
                        { required: true, message: 'Please select a group' },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: 350 }}
                        optionFilterProp="children"
                        placeholder={t('Groups')}
                        name="groups"
                        value={groups}
                        onChange={onChangeGroups_ids}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {groupsList &&
                          groupsList.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: '20px' }}
                        color="primary"
                        onClick={() => history.push(`/citrine/users`)}
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </GridItem>
              </CardBody>
            </Card>
          </GridContainer>
        </div>
      )}
      {!isAddMode && user && (
        <div>
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/citrine/configurations`}>
                  {t('Configurations')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/users/`}>{t('Users List')}</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>{t('Edit')}</Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={2}>
              <Card>
                <CardBody>
                  <Button class="btn btn-light" onClick={handleClickOpen}>
                    {user &&
                    user.person_details &&
                    user.person_details[0].avatar === '' ? (
                      <img src={teacher} width="100%" height="100%" />
                    ) : (
                      <img
                        src={
                          MediaBaseUrl +
                          `${
                            user &&
                            user.person_details &&
                            user.person_details[0].avatar
                          }`
                        }
                        width="100%"
                        height="100%"
                      />
                    )}
                  </Button>
                  <EditAvatarModal
                    person_id={
                      user && user.person_details && user.person_details[0].id
                    }
                    person={user}
                    open={open}
                    handleClose={handleClose}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={9}>
              <Card>
                <CardBody>
                  <GridItem xs={8} sm={8} md={8}>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <br></br>
                      <br></br>
                      <h1>
                        {user &&
                          user.person_details &&
                          user.person_details[0].first_name}{' '}
                        {user &&
                          user.person_details &&
                          user.person_details[0].last_name}
                      </h1>
                      <br></br>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value = '') {
                              if (value.length < 8) {
                                return Promise.reject('Minimum 8 characters')
                              } else {
                                return Promise.resolve()
                              }
                            },
                          }),
                        ]}
                      >
                        <Space direction="vertical" style={{ width: '130%' }}>
                          <Input.Password
                            placeholder="input password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            autoComplete="new-password"
                          />
                        </Space>
                      </Form.Item>
                      <Form.Item
                        label={t('Groups')}
                        name="groups"
                        rules={[
                          { required: false, message: 'Please select a group' },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: 350 }}
                          optionFilterProp="children"
                          placeholder={t('Groups')}
                          name="groups"
                          value={groups}
                          onChange={onChangeGroups_ids}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {groupsList &&
                            groupsList.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: '20px' }}
                          color="primary"
                          onClick={() => history.push(`/citrine/users`)}
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </GridItem>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
        handleErrorText={handleErrorText}
      />
    </div>
  )
}
