// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, Form, Input, notification, Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
//import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/assessmentMethodSlice";
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";
import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec";
import { fetchCourseContents } from "reduxToolkit/features/CourseContent/CourseContentSlice";
import { fetchLearningResources } from "reduxToolkit/features/LearningResource/LearningResourceSlice";
import { fetchTeachingStrategiesSpec } from "reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import { addNewCourseOutline, CourseOutlineUpdate, fetchCourseOutlineById, fetchCourseOutlines } from '../../../../../reduxToolkit/features/CourseOutline/CourseOutlineSlice';
import ErrorModal from "../../../../Components/ErrorModal";



const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

// const classes = useStyles();
export default function CreateCourseOutline({ match }) {
  const { id } = match.params
  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const [form] = Form.useForm();
  const contentsStatus = useSelector(state => state.CourseContent.CourseContentStatus)
  const strategiesStatus = useSelector(state => state.TeachingStrategieSpec.TeachingStrategiesSpecStatus)
  const resourcesStatus = useSelector(state => state.LearningResource.LearningResourcesStatus)
  const assessmentMethodsStatus = useSelector(state => state.assessmentMethod.assessmentMethodsStatus)
  console.log('resourcesStatus', resourcesStatus)
  console.log('strategiesStatus', strategiesStatus)
  useEffect(() => {
    if (contentsStatus === 'idle') {
      dispatch(fetchCourseContents(id))
    }
    if (strategiesStatus === 'idle') {
      dispatch(fetchTeachingStrategiesSpec(id))

    }
    if (resourcesStatus === 'idle') {
      dispatch(fetchLearningResources(id))
    }
    if (assessmentMethodsStatus === 'idle') {
      dispatch(fetchAssessmentMethods(id))
    }
  }, [contentsStatus, strategiesStatus, resourcesStatus, assessmentMethodsStatus, dispatch])
  const courseContents = useSelector(state => state.CourseContent.CourseContents)
  const strategies = useSelector(state => state.TeachingStrategieSpec.TeachingStrategiesSpec)
  const resources = useSelector(state => state.LearningResource.LearningResources)
  const assessmentMethods = useSelector(state => state.assessmentMethod.assessmentMethods)
  console.log('resources', resources)
  console.log('strategies', strategies)


  const titleClasses = useTitleStyles();

  const { idCC } = match.params
  const isAddMode = isNaN(idCC);
  const [week, setWeek] = useState('')
  const [courseSpecContent, setCourseContent_id] = useState('')
  const [courseSpec_id, setCourseSpec_id] = useState(id)
  const [teachingStrategiesSpec, setTeachingStrategy_id] = useState('')
  const [learningResourceSpec, setResourceType_id] = useState('')
  const [assessmentMethodSpec, setAssessmentMethod_id] = useState(false)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')



  const dispatch = useDispatch()
  const history = useHistory();

  const { Option } = Select;


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewCourseOutline({ ...values, courseSpec_id })
    );
    if (addNewCourseOutline.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchCourseOutlines(courseSpec_id));
      history.push(`/citrine/specification/course/${courseSpec_id}/CourseOutlines`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      CourseOutlineUpdate({
        id: idCC,
        modified: {
          ...values,
        },
      })
    );
    if (CourseOutlineUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchCourseOutlines(courseSpec_id));
      history.push(`/citrine/specification/course/${courseSpec_id}/CourseOutlines`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchCourseOutlineById(idCC))
        .then(unwrapResult)
        .then((CC) => {
          console.log(CC, "CC");
          form.setFieldsValue({
            week: CC.week,
            courseContent: CC.courseContent,
            teachingStrategiesSpec: CC.teachingStrategiesSpec,
            assessmentMethodSpec: CC.assessmentMethodSpec,
            learningResourceSpec: CC.learningResourceSpec,
            courseSpec_id: CC.courseSpec_id,
          });
        });
    }
  }, [idCC]);

  const res = useSelector((state) => state.CourseOutline.CourseOutline)
  console.log('res ', res)

  const onChangeCourseContent = async (event) => {
    setCourseContent_id(event);
    console.log('event', event)

  }
  const onChangeTeachingStrategy = async (event) => {
    setTeachingStrategy_id(event);
    console.log('event', event)

  }
  const onChangeAssessmentMethod = async (event) => {
    setAssessmentMethod_id(event);
    console.log('event', event)

  }
  const onChangeResourceType = async (event) => {
    setResourceType_id(event);
    console.log('event', event)

  }
  const courseStatus = useSelector(state => state.course.courseStatus)




  const handleCourse_id = id => {
    setCourseSpec_id(id);
    dispatch(fetchCourseById(id))

  };

  const course = useSelector((state) => state.CourseSpec.courseSpecification)
  return (
    <div>
      {isAddMode &&
        <div>
          <div>
            <GridContainer >
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">

                    

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>{course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/specification/course/${course && course.id && course.id}/CourseOutlines`} style={{ color: "orange" }} > {t('Course Outline List')}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {t('Create')}

                    </Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>

                  </CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="week"
                        label="Week"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="courseSpecContent"
                        label="Topic"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />

                      </Form.Item>
                      <Form.Item
                        name="teachingStrategiesSpec"
                        label="Class Plan"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="learningResourceSpec"
                        label="Reading Material"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="assessmentMethodSpec"
                        label="Task and Assingment"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>



                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(`/citrine/specification/course/${course && course.id && course.id}/CourseOutlines`)
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>


                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />

          </div>
        </div>
      }
      {!isAddMode && res &&
        <div>

          <div>
            <GridContainer >
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">

                  

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>{course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/specification/course/${course && course.id && course.id}/CourseOutlines`} style={{ color: "orange" }} > {t('Course Outline List')}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {t('Edit')}

                    </Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>

                  </CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="week"
                        label="Week"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="courseSpecContent"
                        label="Topic"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="teachingStrategiesSpec"
                        label="Class Plan"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="learningResourceSpec"
                        label="Reading Material"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="assessmentMethodSpec"
                        label="Task and Assingment"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>


                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(`/citrine/specification/course/${course && course.id && course.id}/CourseOutlines`)
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>

                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>

        </div>
      }
    </div>
  );



}


