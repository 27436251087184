import React from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Form, Input, notification } from 'antd'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Primary from 'components/Typography/Primary'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import ErrorModal from 'views/Components/ErrorModal'
import { createApprovalLevel } from '../../../../reduxToolkit/features/Configuration/ApprovalLevel/ApprovalLevelSlice'

const layout = {
    labelCol: {
        span: 3,
    },
    wrapperCol: {
        span: 14,
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 12,
        span: 0,
    },
}
export default function CreateApprovalLevel() {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [openErrorModal, setOpenErrorModal] = React.useState(false)
    const [handledError, setHandledError] = React.useState('')
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true)
    }
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false)
    }
    const handleClose = () => {
        history.push(`/citrine/configuration/others/approvalLevels`)
    }
    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        })
    }
    const onFinish = async (values) => {
        const resultAction = await dispatch(createApprovalLevel(values))
        console.log('resultAction', resultAction)

        if (createApprovalLevel.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            const approvalLevel = resultAction.payload
            openNotificationWithIcon(
                'success',
                `Added ${approvalLevel.name}`,
            )
            form.resetFields()
            handleClose()
        } else {
            console.log('resultAction 2', resultAction)

            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                console.log('resultAction.payload', resultAction.payload)

            } else {
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                console.log('resultAction.error', resultAction.error)

            }
        }
    }
    return (
        <>
            <GridContainer>
                <GridItem>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/configuration/others`}>
                                <span> {t('Other Configurations')} </span>
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/configuration/others/approvalLevels`}>
                                <span> {t('Approval Levels')} </span>
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span> {t('Create')} </span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card withPadding>
                        <CardHeader>
                            <GridContainer>
                                <GridItem>
                                    <Primary>Create Approval Level</Primary>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <br />
                        <br />

                        <CardBody>
                            <Form
                                {...layout}
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="code"
                                    label="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Code.',
                                        },
                                    ]}
                                >
                                    <Input maxLength={300} />
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the name.',
                                        },
                                    ]}
                                >
                                    <Input maxLength={300} />
                                </Form.Item>

                                <Form.Item {...tailLayout}>
                                    <Button
                                        color="danger"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" color="info">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </>
    )
}
