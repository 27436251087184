import MenuItem from "@material-ui/core/MenuItem";
import React, { useState, useEffect } from "react";
import Button from "../../components/CustomButtons/Button";
// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import DeleteStudenSurvey from '../Pages/Surveys/StudentSurvey/DeleteStudenSurvey.jsx';
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker, Form, Input, notification } from "antd";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment";
import { deleteAcademicYear, fetchAcademicYearById, fetchAcademicYears, updateAcademicYear } from "../../reduxToolkit/features/academicYear/AcademicYearsSlice.js";
import ErrorModal from "./ErrorModal";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal";
import isDate2GreaterThanDate1 from "assets/utils/helperFunction";

const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownPlanning(props) {
  const dateFormat = "YYYY-MM-DD"
  const { t } = useTranslation();
  const buttonClasses = useButtonStyles();
  const [open, setOpen] = React.useState(false);
  const [openn, setOpenn] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [start_date, setStart_date] = React.useState("");
  const [end_date, setEnd_date] = React.useState("");
  const [end_date_validation_status, setEnd_date_validation_status] = useState("");

  const dispatch = useDispatch();

  const classes = useStyles();
  const [form] = Form.useForm();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClickOpenn = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosee = () => {
    setOpen(false);
  };
  const handleOpenDeleteModal = () => {
    setOpen(true);
  };
  const handleOpenDeleteModale = () => {
    setOpenn(true);
  };
  const handleCloseDeleteModale = () => {
    setOpenn(false);
  };


  const handleCloseDeleteModal = () => {
    setOpen(false);
  };
  function onStart_dateChange(value, dateString) {
    setStart_date(dateString);
  }
  function onEnd_dateChange(value, dateString) {
    setEnd_date(dateString);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // message.error('Submit failed!');
  };


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleDeleteItem = async (id) => {
    dispatch(deleteAcademicYear(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Deleted`);

        console.log("originalPromiseResult", originalPromiseResult);
        dispatch(fetchAcademicYears());
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onFinish = async (values) => {
    if (end_date_validation_status === "") {


    let data
    let id = props.id;
    //const { start, end, ...valuesRest } = values;
    //console.log("valuesRest", valuesRest);

    data = { ...values, start_date: moment(values.start_date).format(dateFormat), end_date: moment(values.end_date).format(dateFormat), id }

    const resultAction = await dispatch(
      updateAcademicYear(data)
    );

    if (updateAcademicYear.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const AY = resultAction.payload;
      openNotificationWithIcon("success", `Update ${AY.name}`);
      dispatch(fetchAcademicYears());
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
    setEnd_date_validation_status("")

  }
  };
  useEffect(() => {
    const fetchAcademicYearData = async () => {
      try {
        const academicYear = await dispatch(fetchAcademicYearById(props.id))
        const academicYeardata = academicYear.payload
        console.log(academicYeardata)
        form.setFieldsValue({
          name: academicYeardata?.name,
          start_date: moment(academicYeardata?.start_date, dateFormat),
          end_date: moment(academicYeardata?.end_date, dateFormat)


        });
      } catch (err) {
        console.log(err)
      }

    }
    fetchAcademicYearData()
  }, [dispatch, props.id])

  useEffect(() => {
    if (start_date && end_date) {
      isDate2GreaterThanDate1(start_date, end_date) ? setEnd_date_validation_status("") : setEnd_date_validation_status('error')
    }

  }, [start_date, end_date])
  
  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Update academic year")}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                form={form}
                name="basic"
                layout="vertical"
                //   labelCol={{ span: 8 }}
                //   wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Start date")}
                      name="start_date"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker onChange={onStart_dateChange} style={{ width: "100%" }} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("End date")}
                      name="end_date"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      validateStatus={end_date_validation_status}
                      help="The academic year end date should be greater than the start date"

                    >
                      <DatePicker onChange={onEnd_dateChange} style={{ width: "100%" }} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClosee();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          handleClickOpenn();
          //handleClose();
        }}>Edit</MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteModale();
            handleClose();

          }}
        >
          Delete
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        open={openn}
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        handleClose={handleCloseDeleteModale}
      />
 
    </div>
  );
}
