import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Specific_CoursesSpecUpdate,
  fetchCourseById,
  fetchCourses,
} from "../../../../reduxToolkit/Course/coursesSpecSlice";

import { fetchProgramsByCourseSpec } from "reduxToolkit/features/program/ProgramSpecSlice";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";

import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider } from "antd";
import "antd/dist/antd.css";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Button from "components/CustomButtons/Button.js";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { fetchActivitiesAssessmentMethodsSpec } from "reduxToolkit/features/ActivitiesAssessmentMethod/ActivitiesAssessmentMethodSpecSlice";
import { fetchActualLearningHoursSpec } from "reduxToolkit/features/ActualLearningHours/ActualLearningHoursSpecSlice";
import { fetchAssessmentTasksForStudents } from "reduxToolkit/features/AssessmentTasksForStudent/AssessmentTasksForStudentSlice";
import { fetchCourseContacts } from "reduxToolkit/features/CourseContact/CourseContactSlice";
import { fetchCourseContents } from "reduxToolkit/features/CourseContent/CourseContentSlice";
import { fetchCourseOutlines } from "reduxToolkit/features/CourseOutline/CourseOutlineSlice";
import { fetchCourseQualityEvaluations } from "reduxToolkit/features/CourseQualityEvaluation/CourseQualityEvaluationSpecSlice";
import { fetchCourseSpecReportDocumentsByCourseSpecId } from "reduxToolkit/features/CourseSpecificationReportDocument/CourseSpecificationReportDocumentSlice";
import { fetchCourseSyllabusReportDocumentsByCourseSpecId } from "reduxToolkit/features/CourseSyllabusReportDocument/CourseSyllabusReportDocumentSlice";
import { fetchFacilitiesRequireds } from "reduxToolkit/features/FacilitiesRequired/FacilitiesRequiredSlice";
import { fetchLearningResources } from "reduxToolkit/features/LearningResource/LearningResourceSlice";
import { fetchModeOfInstructions } from "reduxToolkit/features/ModeOfInstruction/ModeOfInstructionSlice";
import { fetchTeachingStrategiesSpec } from "reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import { fetchVerificationOfCredibilitiesSpec } from "reduxToolkit/features/VerificationOfCredibility/VerificationOfCredibilitySpecSlice";
import { fetchRanges } from "reduxToolkit/features/range/RangeSpecSlice";
import {
  grayColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchActivities } from "../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import ErrorModal from "../../../Components/ErrorModal";
import "./courseDetails.css";

import {
  blackColor,
  hexToRgb,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import VersionningCourseSpecModal from "../../../Components/VersionningCourseSpecModal";

import DropDownButtonInCourseDetails from "../../../Components/DropDownButtonInCourseDetails";
import DropDownCourseSpecReportButton from "../../../Components/DropDownCourseSpecReportButton";
import DropDownCourseSyllabusReportButton from "../../../Components/DropDownCourseSyllabusReportButton";
import CreateReportCourseDocument from "../../../Components/CreateReportCourseDocument";
import CreateReportCourseSyllabusDocument from "../../../Components/CreateReportCourseSyllabusDocument";
import { GoReport } from "react-icons/go";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CourseSpecDetails({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const { id } = match.params;

  const dispatch = useDispatch();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [stateName, setStateName] = useState("");
  const [course_type, setCourse_type] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(true);
  const [showDiagram, setShowDiagram] = useState(false);
  const [open, setOpen] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const ModeOfInstructions = useSelector(
    (state) => state.ModeOfInstruction.ModeOfInstructions
  );
  const LearningResources = useSelector(
    (state) => state.LearningResource.LearningResources
  );
  const FacilitiesRequireds = useSelector(
    (state) => state.FacilitiesRequired.FacilitiesRequireds
  );
  const ActualLearningHours = useSelector(
    (state) => state.ActualLearningHourSpec.ActualLearningHours
  );
  const CourseQualityEvaluations = useSelector(
    (state) => state.CourseQualityEvaluationSpec.CourseQualityEvaluations
  );
  const VerificationOfCredibilitiesSpec = useSelector(
    (state) => state.VerificationOfCredibility.VerificationOfCredibilitiesSpec
  );
  const ranges = useSelector((state) => state.rangeSpec.ranges);
  const TeachingStrategiesSpec = useSelector(
    (state) => state.TeachingStrategieSpec.TeachingStrategiesSpec
  );
  const ActivitiesAssessmentMethodsSpec = useSelector(
    (state) =>
      state.ActivitiesAssessmentMethodSpec.ActivitiesAssessmentMethodsSpec
  );
  const AssessmentTasksForStudents = useSelector(
    (state) => state.AssessmentTasksForStudent.AssessmentTasksForStudents
  );
  const CourseContents = useSelector(
    (state) => state.CourseContent.CourseContents
  );
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const Activities = useSelector((state) => state.Activity.Activities);
  const courseSpecReportDocuments = useSelector(
    (state) => state.CourseSpecReportDocument.courseSpecReportDocuments
  );
  const CourseOutlines = useSelector(
    (state) => state.CourseOutline.CourseOutlines
  );
  const CourseContacts = useSelector(
    (state) => state.CourseContact.CourseContacts
  );
  const courseSyllabusReportDocuments = useSelector(
    (state) => state.CourseSyllabusReportDocument.courseSyllabusReportDocuments
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchCourse();
    dispatch(fetchActivities());
    dispatch(fetchCourseSpecReportDocumentsByCourseSpecId(id));
    dispatch(fetchCourseSyllabusReportDocumentsByCourseSpecId(id));
    dispatch(fetchModeOfInstructions(id));
    dispatch(fetchLearningResources(id));
    dispatch(fetchFacilitiesRequireds(id));
    dispatch(fetchActualLearningHoursSpec(id));
    dispatch(fetchCourseContents(id));
    dispatch(fetchCourseContacts(id));
    dispatch(fetchCourseOutlines(id));
    dispatch(fetchAssessmentTasksForStudents(id));
    dispatch(fetchCourseQualityEvaluations(id));
    dispatch(fetchTeachingStrategiesSpec(id));
    dispatch(fetchActivitiesAssessmentMethodsSpec(id));
    dispatch(fetchVerificationOfCredibilitiesSpec(id));
    dispatch(fetchRanges(id));
    dispatch(fetchProgramsByCourseSpec(id));
  }, []);

  useEffect(() => {
    setStateName(course && course.state);
  }, [course]);

  useEffect(() => {
    fetchCourse();
    dispatch(fetchActivities());
    dispatch(fetchCourseSpecReportDocumentsByCourseSpecId(id));
    dispatch(fetchCourseSyllabusReportDocumentsByCourseSpecId(id));
    dispatch(fetchModeOfInstructions(id));
    dispatch(fetchLearningResources(id));
    dispatch(fetchFacilitiesRequireds(id));
    dispatch(fetchActualLearningHoursSpec(id));
    dispatch(fetchCourseContents(id));
    dispatch(fetchCourseContacts(id));
    dispatch(fetchCourseOutlines(id));
    dispatch(fetchAssessmentTasksForStudents(id));
    dispatch(fetchCourseQualityEvaluations(id));
    dispatch(fetchTeachingStrategiesSpec(id));
    dispatch(fetchActivitiesAssessmentMethodsSpec(id));
    dispatch(fetchVerificationOfCredibilitiesSpec(id));
    dispatch(fetchRanges(id));
    dispatch(fetchProgramsByCourseSpec(id));
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const renderReports = () => {
    setShowReports(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
  };

  const renderRow = () => {
    if (showActivities) {
      return (
        <GridContainer xs={12} sm={12} md={12}>
          <h4 style={{ marginBottom: "20px" }}>Activities</h4>
          <GridItem key={Element.id} xs={12} sm={12} md={6}>
            {Activities.map((item) => (
              <div>
                <Divider
                  type="horizontal"
                  orientation="center"
                  style={{ color: "gray" }}
                >
                  {Moment(item.DueDate).format("YYYY-MM-DD")}
                </Divider>

                <div
                  style={{
                    backgroundColor: "#edeff1",
                    borderBottom: "1px solid #cfd5db",
                  }}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem key={Element.id} xs={12} sm={12} md={2}>
                      <Avatar
                        style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                      >
                        {item.avatar}
                      </Avatar>
                    </GridItem>
                    <GridItem key={Element.id} xs={12} sm={12} md={10}>
                      <p>
                        <strong>{item.AssignedTo}</strong>
                      </p>
                      <ul>
                        <li>{item.Summary}</li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            ))}
          </GridItem>
        </GridContainer>
      );
    } else if (showTasks) {
      return <div>Tasks</div>;
    } else {
      return (
        <div>
          <div style={{ zIndex: 10000 }}>
            <GridContainer>
              <GridItem
                style={{ backgroundColor: "rgba(0,0,0,0.04)" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Stack direction="row" alignItems={"center"} spacing={2}>
                  <span
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: primaryColor[0],
                    }}
                  >
                    {t("Course Specifications")}
                  </span>
                  {course?.state !== "Approved" && (
                    <CreateReportCourseDocument
                      CourseType={course && course.type}
                      CourseId={course && course.id}
                      handleDropDownButtonClose={handleClose}
                    />
                  )}
                </Stack>
                {courseSpecReportDocuments &&
                courseSpecReportDocuments.length > 0 ? (
                  courseSpecReportDocuments.map((courseSpecReportDocument) => (
                    <Card
                      style={{ backgroundColor: grayColor[10], padding: 10 }}
                    >
                      <GridContainer
                        justify="space-between"
                        alignItems="center"
                      >
                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          alignItems="baseline"
                        >
                          <GridItem xs={10} sm={10} md={10}>
                            <Link
                              to={`/citrine/CourseSpecDetails/${id}/CourseSpecificationReportDocument/${courseSpecReportDocument.id}`}
                            >
                              <h7> {courseSpecReportDocument.name}</h7>
                            </Link>
                          </GridItem>
                          <GridItem>
                            <DropDownCourseSpecReportButton
                              id={
                                courseSpecReportDocument &&
                                courseSpecReportDocument.id
                              }
                              courseId={course && course.id && course.id}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridItem>
                          <h7>Status: {courseSpecReportDocument.state}</h7>
                          <br />
                          <h7>
                            Created By:{" "}
                            {courseSpecReportDocument &&
                              courseSpecReportDocument.user_details &&
                              courseSpecReportDocument.user_details.username}
                          </h7>
                          <br />
                          <h7>
                            Created on:
                            {Moment(courseSpecReportDocument.created_at).format(
                              "YYYY-MM-DD"
                            )}
                          </h7>
                          <br />
                          <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={12}>
                              <Link>V {courseSpecReportDocument.version}</Link>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </Card>
                  ))
                ) : (
                  <div
                    style={{
                      color: "gray",
                      textAlign: "center",
                      paddingTop: 40,
                    }}
                  >
                    No data
                  </div>
                )}
              </GridItem>
              <GridItem
                style={{ backgroundColor: "rgba(0,0,0,0.04)" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Stack direction="row" alignItems={"center"} spacing={2}>
                  <span
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: primaryColor[0],
                    }}
                  >
                    {t("Course Syllabus")}
                  </span>
                  {course?.state !== "Approved" && (
                    <CreateReportCourseSyllabusDocument
                      CourseId={course && course.id}
                      handleDropDownButtonClose={handleClose}
                    />
                  )}
                </Stack>
                {courseSyllabusReportDocuments &&
                courseSyllabusReportDocuments.length > 0 ? (
                  courseSyllabusReportDocuments.map(
                    (courseSyllabusReportDocument) => (
                      <Card
                        style={{ backgroundColor: grayColor[10], padding: 10 }}
                      >
                        <GridContainer
                          justify="space-between"
                          alignItems="center"
                        >
                          <GridContainer
                            xs={12}
                            sm={12}
                            md={12}
                            alignItems="baseline"
                          >
                            <GridItem xs={10} sm={10} md={10}>
                              <Link
                                to={`/citrine/CourseSpecDetails/${id}/CourseSyllabusReportDocument/${courseSyllabusReportDocument.id}`}
                                style={{ paddingTop: "20px" }}
                              >
                                <h7> {courseSyllabusReportDocument.name}</h7>
                              </Link>
                            </GridItem>
                            <GridItem
                              style={{ paddingTop: "0px" }}
                              xs={2}
                              sm={2}
                              md={2}
                            >
                              <DropDownCourseSyllabusReportButton
                                id={
                                  courseSyllabusReportDocument &&
                                  courseSyllabusReportDocument.id
                                }
                                courseId={course && course.id}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridItem>
                            <h7>
                              Status: {courseSyllabusReportDocument.state}
                            </h7>
                            <br></br>
                            <h7>
                              Created By:{" "}
                              {courseSyllabusReportDocument &&
                                courseSyllabusReportDocument.user_details &&
                                courseSyllabusReportDocument.user_details
                                  .username}
                            </h7>
                            <br />
                            <h7>
                              Created on:
                              {Moment(
                                courseSyllabusReportDocument.created_at
                              ).format("YYYY-MM-DD")}
                            </h7>
                            <br />
                            <GridContainer xs={12} sm={12} md={12}>
                              <GridItem xs={12} sm={12} md={12}>
                                <Link>
                                  V {courseSyllabusReportDocument.version}
                                </Link>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </Card>
                    )
                  )
                ) : (
                  <div
                    style={{
                      color: "gray",
                      textAlign: "center",
                      paddingTop: 40,
                    }}
                  >
                    No data
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      );
    }
  };

  const fetchCourse = async () => {
    try {
      const courses = await dispatch(fetchCourseById(id))
        .then(unwrapResult)
        .then((course) => {
          localStorage.setItem("CourseState", course.state);
          setCourse_type(course.type);
        });
      if (course && course.state !== "Draft") {
        setDisabledStatus(true);
      }
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const Approve = () => {
    setShow(true);
    setStateName("Approved");
    localStorage.setItem("CourseState", "Approved");
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Approved" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Approved");
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourses());
    }
  };

  const SendforApproval = () => {
    setShow(true);
    setStateName("Waiting for approval");
    localStorage.setItem("CourseState", "Waiting for approval");
    setDisabledStatus(true);
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Waiting for approval" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Waiting for approval");
      dispatch(fetchCourses());
      setDisabledStatus(true);
    } catch (err) {
    } finally {
      dispatch(fetchCourses());
      setDisabledStatus(true);
    }
  };

  const ResetTODraft = () => {
    setShow(true);
    setStateName("Draft");
    localStorage.setItem("CourseState", "Draft");
    setDisabledStatus(false);
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Draft" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Draft");
      setDisabledStatus(false);
    } catch (err) {
    } finally {
      dispatch(fetchCourses());
      setDisabledStatus(false);
    }
  };

  const Archive = () => {
    setShow(true);
    setStateName("Archived");
    try {
      const resultAction = dispatch(
        Specific_CoursesSpecUpdate({
          id: course && course.id,
          modified: { state: "Archived" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Archived");
      dispatch(fetchCourses());
    } catch (err) {
    } finally {
      dispatch(fetchCourses());
    }
  };

  let contentPage;
  if (stateName === "Draft") {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            <Button
              color="primary"
              size="sm"
              className={classes.marginRight}
              onClick={() => SendforApproval()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Send for approval")}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: "550px", height: "40px" }}>
            <li
              class="progress-bar__steps current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (
    stateName &&
    stateName.toLowerCase().includes("waiting for approval")
  ) {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            <Button
              color="primary"
              size="sm"
              className={classes.marginRight}
              onClick={() => Approve()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Approve")}
              </span>
            </Button>
          </div>
          <div>
            <Button
              style={{ backgroundColor: "lightgery" }}
              size="sm"
              className={classes.marginRight}
              onClick={() => ResetTODraft()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Reset tO draft")}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: "550px", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Approved") {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            <VersionningCourseSpecModal
              CourseSpec_id={course && course.id}
              course={course}
            />
          </div>
          <div>
            <Button
              color="primary"
              size="sm"
              className={classes.marginRight}
              onClick={() => Archive()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Archive")}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: "550px", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Archived") {
    contentPage = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            <Button
              style={{ backgroundColor: "lightgery" }}
              size="sm"
              className={classes.marginRight}
              onClick={() => ResetTODraft()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Reset to draft")}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: "650px", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>

            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Archived")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  }
  /********** content page 2 for test */
  let contentPage2;
  {
    course &&
      course.states_actions &&
      course.states_actions.map((item) => {
        if (stateName === "Draft" && item.state === "Draft") {
          contentPage2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {course &&
                    course.states_actions &&
                    course.states_actions.map((item) => {
                      return item.state === "Draft" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (
          stateName &&
          stateName.toLowerCase().includes("waiting for approval") &&
          item.state &&
          item.state.toLowerCase().includes("waiting for approval")
        ) {
          contentPage2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {course &&
                    course.states_actions &&
                    course.states_actions.map((item) => {
                      return item.state === "Waiting for approval" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Approved" && item.state === "Approved") {
          contentPage2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("versionning")
                    ) {
                      return (
                        <VersionningCourseSpecModal
                          CourseSpec_id={course && course.id}
                          course={course}
                        />
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {course &&
                    course.states_actions &&
                    course.states_actions.map((item) => {
                      return item.state === "Approved" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Archived" && item.state === "Archived") {
          contentPage2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  //margin: "0px 25px 0px 20px",
                  paddingLeft: "10px",
                }}
              >
                {/* stateName === 'waiting for approval'  : Coming soon !!!! */}
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {course &&
                    course.states_actions &&
                    course.states_actions.map((item) => {
                      return item.state === "Archived" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        }
      });
  }

  let content;
  if (
    course_type &&
    course_type.toLowerCase().includes("conventional course")
  ) {
    content = (
      <div>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Activities/Assessment Methods")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {ActivitiesAssessmentMethodsSpec?.length}
                    {""} {t("activities/assessment")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/courses/${id}/clos`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Course Learning Outcomes")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {course?.closSpec.length}
                    {""} {t("CLOs")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/ModeOfInstructionList/${
                course && course.id && course.id
              }`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Mode of Instruction")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {ModeOfInstructions?.length}
                    {""} {t("modes ")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/specification/course/${id}/LearningResources`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Learning Resources")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {LearningResources?.length}
                    {""} {t("learning resources ")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/FacilitiesRequireds`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Facilities Required")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {FacilitiesRequireds?.length}
                    {""} {t("facilities ")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${
                course && course.id && course.id
              }/ActualLearningHoursViews`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Actual Learning Hours")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {ActualLearningHours?.length}
                    {""} {t("learning hours ")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/specification/course/${id}/CourseContentViews`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Course Content")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {CourseContents?.length}
                    {""} {t("course content ")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/specification/course/${id}/CourseContacts`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Course Contact")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {CourseContacts?.length}
                    {""} {t("course contact ")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/specification/course/${id}/CourseOutlines`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Course Outlines")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {CourseOutlines?.length}
                    {""} {t("course outlines")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/AssessmentTasksForStudents`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Assessment Tasks For Students")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {AssessmentTasksForStudents?.length}
                    {""} {t("assessment")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/CourseQualityEvaluations`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Course Quality Evaluation")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {CourseQualityEvaluations?.length}
                    {""} {t("course quality")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${
                course && course.id && course.id
              }/TeachingStrategies`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Teaching Strategies")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {TeachingStrategiesSpec?.length}
                    {""} {t("teaching strategies")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/VerificationCredibilities`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>
                    {t("Verification of Credibility of Students’ Results")}
                  </h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {VerificationOfCredibilitiesSpec
                      ? VerificationOfCredibilitiesSpec?.length
                      : 0}
                    {""} {t("credibility")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/specification/course/${id}/ranges`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardHeader>
                  <h4>{t("Assessment Ranges")}</h4>
                  <br />

                  <h5 className={classes.cardTitle}>
                    {ranges?.length}
                    {""} {t("assessment ranges")}
                  </h5>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
  if (course_type && course_type.toLowerCase().includes("field experience")) {
    content = (
      <div>
        <GridContainer>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Activities/Assessment Methods")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/courses/${id}/clos`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Course Learning Outcomes")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/AssessmentTasksForStudents`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Assessment Tasks For Students")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/CourseQualityEvaluations`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("course quality evaluation")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${
                course && course.id && course.id
              }/TeachingStrategies`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "50px" }}>
                      {t("Training Activities")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/VerificationCredibilities`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Verification of Credibility of Students’ Results")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link to={`/citrine/specification/course/${id}/ranges`}>
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Assessment Ranges")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={4}>
            <Link
              to={`/citrine/specification/course/${id}/FieldExperienceLocations`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Field experience locations")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem className="HoverCard" xs={12} sm={12} md={3}>
            <Link
              to={`/citrine/specification/course/${id}/AssessmentResponsibilities`}
            >
              <Card
                style={{
                  height: "150px",
                  textAlign: "center",
                  border: "1px solid lightgray",
                  borderRaduis: "10px",
                  boxShadow: "2px 2px 2px lightgray",
                }}
              >
                <CardBody>
                  <strong>
                    <h4 style={{ marginTop: "10%" }}>
                      {t("Assessment Responsibilities")}
                    </h4>
                  </strong>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                style={{ color: "orange" }}
                to={`/citrine/courseSpecDetails/${id}`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={12}>
          <GridContainer xs={12} sm={12} md={12}>
            {contentPage2}
          </GridContainer>
          <GridContainer style={{ border: "none", marginTop: "10px" }}>
            <div>
              <div
                style={{
                  padding: "10px",
                  borderRadius: "0px",
                  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                  background: whiteColor,
                  width: "100%",
                  boxShadow:
                    "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "0",
                  wordWrap: "break-word",
                  fontSize: ".875rem",
                }}
              >
                <GridContainer>
                  <div>
                    <Card>
                      <CardBody>
                        <GridContainer justify="space-between">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            style={{ borderLeft: "1px solid gold" }}
                          >
                            <GridContainer>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Program")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course?.programs?.map((p) => {
                                    return (
                                      <>
                                        <spin style={{ marginRight: "5px" }}>
                                          {p?.code}
                                        </spin>
                                        <spin
                                          style={{
                                            marginLeft: "1px",
                                            marginRight: "15px",
                                            backgroundColor: "#9bff9b",
                                            borderRadius: "8px",
                                            color: "white",
                                            width: "35px",
                                            display: "inline-block",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {p?.version}
                                        </spin>
                                      </>
                                    );
                                  })}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Course")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {" "}
                                  {course && course.title && course.title}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Prerequisites")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course &&
                                    course.prerequisites_detail &&
                                    course.prerequisites_detail.map((el) => (
                                      <h7>
                                        {el && el.title}
                                        <br />
                                      </h7>
                                    ))}
                                </div>
                              </GridItem>

                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Co-requisites")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course &&
                                    course.co_requisites_detail &&
                                    course.co_requisites_detail.map((el) => (
                                      <h7>
                                        {el && el.title}
                                        <br />
                                      </h7>
                                    ))}
                                </div>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            style={{ borderLeft: "1px solid gold" }}
                          >
                            <GridContainer>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Level")}: </div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course &&
                                    course.level_details &&
                                    course.level_details[0].name}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={4}>
                                <div> {t("Credit hours")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course &&
                                    course.credit_hours &&
                                    course.credit_hours}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Course Cordinator")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course?.coordinator_detail?.map((el) => {
                                    return (
                                      <>
                                        {el?.first_name} {el?.last_name}
                                      </>
                                    );
                                  })}
                                </div>
                              </GridItem>
                              <GridItem xs={3} sm={3} md={4}>
                                <div>{t("Department")}:</div>
                              </GridItem>
                              <GridItem xs={9} sm={9} md={8}>
                                <div>
                                  {course?.department_detail?.map((el) => {
                                    return <>{el?.name + " "}</>;
                                  })}
                                </div>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={1}>
                            {disabledStatus ? (
                              <p></p>
                            ) : (
                              <DropDownButtonInCourseDetails
                                courseID={course && course.id}
                              />
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            border: "1px solid lightgray",
                            padding: "5px",
                            borderRadius: "5px",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ color: "orange" }}>
                            {" "}
                            {t("Description")}:{" "}
                          </div>
                          <div>
                            {course && course.description && course.description}
                          </div>
                        </GridContainer>
                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            border: "1px solid lightgray",
                            padding: "5px",
                            borderRadius: "5px",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ color: "orange" }}>
                            {" "}
                            {t("Main objectives")}:{" "}
                          </div>
                          <div>
                            {course &&
                              course.main_objectives &&
                              course.main_objectives}
                          </div>
                        </GridContainer>
                        <GridContainer
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            border: "1px solid lightgray",
                            padding: "5px",
                            borderRadius: "5px",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ color: "orange" }}>
                            {t("student Academic Counseling and Support")}:
                          </div>
                          <div>
                            {course &&
                              course.student_academic_counseling_and_support &&
                              course.student_academic_counseling_and_support}
                          </div>
                        </GridContainer>
                      </CardBody>
                    </Card>
                    <br></br>
                    {content}
                  </div>
                  <br></br>
                  <ErrorModal
                    open={openErrorModal}
                    handleClose={handleCloseErrorModal}
                    handledError={handledError}
                  />
                </GridContainer>
              </div>
            </div>
          </GridContainer>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </GridItem>
      </GridContainer>
      <div
        style={{
          padding: "10px",
          borderRadius: "0px",
          color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
          background: whiteColor,
          width: "100%",
          boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          minWidth: "0",
          wordWrap: "break-word",
          fontSize: ".875rem",
          //height: 650,
          border: "1px solid lightgray",
        }}
      >
        {renderRow()}
      </div>
    </div>
  );
}
