import { Tabs } from 'antd';
import Card from "components/Card/Card"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import { greyColor } from 'assets/jss/material-dashboard-pro-react';
import { Link } from "react-router-dom"
import React from 'react';
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import CourseSyllabi from './CourseSyllabi';
import ProgramCurriculum from './ProgramCurriculum';
import Criterion1Analysis from "../criterion1/Analysis"

const onChange = (key) => {
    console.log(key);
};

const Criterion5 = ({data}) => (
    <>
        {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

        <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            items={[
                {
                    label: `Program Curriculum`,
                    key: '1',
                    children: <ProgramCurriculum data={data.ProgramCurriculum}/>
                    ,
                },
                {
                    label: `Course Syllabi`,
                    key: '2',
                    children: <CourseSyllabi data={data.CourseSyllabi}/>,
                },
                {
                    label: `Analysis`,
                    key: '3',
                    children: <Criterion1Analysis data={data.Analysis}/>,
                },
            ]}
        />
    </>

);
export default Criterion5;