import { Avatar, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import PDF from "../../../media/images.png";
import { DeleteDoc } from "../../../reduxToolkit/features/docslice/DocSlice";
import "./FileCard.css";
import WebViewer from '@pdftron/webviewer'
import { useHistory } from "react-router-dom";
import SuperGeneralDeleteModal from "../SuperGeneralDeleteModal";
import { unwrapResult } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {DocumentBaseUrl} from "../../../assets/utils/accessToken";
const FileCard = ({ el, setPing, ping,  fetchDocsCustomFunction }) => {
  const history = useHistory();
  const viewerDiv = useRef(null);
  const dispatch = useDispatch();
  const handelDel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `${el.name} will be deleted!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteDoc(el._id, dispatch));
        fetchDocsCustomFunction();
        setPing(!ping);
        //Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  const handleShow = () => {
   // setShow(true);
     handleGo();
  };

  const handleGo = () => {
    window.open(DocumentBaseUrl +`/${el?.document?.substring(
      8
    )}`, '_blank');
   
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };
  const handleDeleteItem = async (id) => {
    dispatch(DeleteDoc(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon('success', `Deleted`)

        console.log('originalPromiseResult', originalPromiseResult)
        fetchDocsCustomFunction();

      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2
    });
  };
  return (
    <div className="fileCard">
      <div className="fileCardtop">
        {/* {el.file.fileType === "application/pdf" && ( */}
        <Document
          onClick={handleShow}
          file={DocumentBaseUrl +`/${el?.document?.substring(
            8
          )}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page height={150} width={170} pageNumber={pageNumber} />
        </Document>
      
        <span className="drop-file-preview__item__del" onClick={() => {
            handleOpenDeleteModal(); handleClose();
          }}>
          <DeleteIcon style={{ color: red[600] }} />
        </span>
      </div>
      <div className="fileCardbottom">
        <Avatar className="imgs" src={PDF} alt="" />
       
        {el.name.length > 20 ? (
          <p data-title={el.name}>{`${el.name.substring(0, 20)}...`}</p>
        ) : (
          <p>{el.name}</p>
        )}
      </div>
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={el._id}
        open={open}
        handleClose={handleCloseDeleteModal}

      />
    </div>
  );
};

export default FileCard;
