import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  deleteGraduateAttributeSpec,
  fetchGraduateAttributesSpec,
} from "reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice.js";
import { fetchProgramById } from "reduxToolkit/features/program/ProgramSpecSlice";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal.js";
import ErrorModal from "../../../Components/ErrorModal.jsx";
import GA_PLOMapModal from "./GA_PLOMapModal.jsx";
import GAEditModal from "./GAEditModal";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function GADropDownButton(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //   const handleDeleteGA = (id) => {
  //     dispatch(deleteGraduateAttributeSpec(id))
  //       .then(unwrapResult)
  //       .then((originalPromiseResult) => {
  //         // handle result here
  //         console.log("originalPromiseResult", originalPromiseResult);
  //         dispatch(fetchGraduateAttributesSpec(props.programId));
  //         openNotificationWithIcon("success", `Deleted`);
  //       })
  //       .catch((rejectedValueOrSerializedError) => {
  //         // handle error here
  //         console.log(
  //           "rejectedValueOrSerializedError",
  //           rejectedValueOrSerializedError
  //         );
  //         setHandledError(rejectedValueOrSerializedError);
  //         handleOpenErrorModal();
  //       });
  //   };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <GAEditModal
          handleDropDownButtonClose={handleClose}
          GA={props.GA}
          programId={props.programId}
          disabledStatus={props.disabledStatus}
        />
        <GA_PLOMapModal
          handleDropDownButtonClose={handleClose}
          GA={props.GA}
          programId={props.programId}
          disabledStatus={props.disabledStatus}
        />

        <MenuItem onClick={handleOpenDeleteModal}>
          <ListItemText primary="Delete" />
        </MenuItem>

        <SuperGeneralDeleteModal
          delete_Element={props.handleDeleteGA}
          Element_ID={props.GA.id}
          open={open}
          handleClose={handleCloseDeleteModal}
        />
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </StyledMenu>
    </div>
  );
}
