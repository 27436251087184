// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import { unwrapResult } from "@reduxjs/toolkit";
//import Select from "@material-ui/core/Select";
import { Breadcrumb, Select } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchTotalLevelById, fetchTotalLevels, updateTotalLevel
} from "reduxToolkit/features/totalLevel/TotalLevelSlice";
import { fetchFields } from "../../../../../reduxToolkit/features/field/FieldSpecSlice";
import { addNewTotalLevel } from "../../../../../reduxToolkit/features/totalLevel/TotalLevelSlice";






export default function CreateTotalLevel({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;

  console.log("id", id);

  const isAddMode = isNaN(id);
  console.log("id from match is add mode", isAddMode);
  const [total_levels, setTotal_levels] = useState("");
  const [fieldSpec_ids, setFieldSpec_ids] = useState([]);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();

  const fieldsStatus = useSelector((state) => state.fieldSpec.fieldsStatus);

  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(fetchFields());
    }
  }, [fieldsStatus, dispatch]);

  const fields = useSelector((state) => state.fieldSpec.fields);

  const { Option } = Select;

  function handleField_ids(value) {
    //  console.log(`selected ${value}`);
    setFieldSpec_ids(value);
  }

  const onSaveTotalLevelClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        addNewTotalLevel({ total_levels, fieldSpec_ids })
      );
      unwrapResult(resultAction);
      dispatch(fetchTotalLevels());
    } catch (err) {
      console.error("Failed to save the totalLevel: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };

  const onUpdateTotalLevelClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        updateTotalLevel({ id: id, modified: { total_levels, fieldSpec_ids } })
      );
      unwrapResult(resultAction);
      dispatch(fetchTotalLevels());
    } catch (err) {
      console.error("Failed to save the totalLevel: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };
  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchTotalLevelById(id));
    }
  }, [id]);

  const tl = useSelector((state) => state.totalLevel.totalLevel);
  console.log("tl ", tl);

  useEffect(() => {
    if (!isAddMode) {
      setTotal_levels(tl && tl.total_levels);
      setFieldSpec_ids(tl && tl.fieldSpec_ids);
    }
  }, [tl, dispatch]);

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span> {t("Program Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/totalLevels`} style={{ color: "orange" }}>
                      {t("Total levels")}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Add TotalLevel")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="total_levels"
                            labelText={t("Total levels ")}
                            inputProps={{
                              value: total_levels,
                              onChange: (event) => {
                                setTotal_levels(event.target.value);
                              },
                              type: "text",
                            }}
                          />

                          <GridItem>
                            <label>{t("Field ")}</label>

                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "100%" }}
                              placeholder={t("Select a field")}
                              optionFilterProp="children"
                              value={fieldSpec_ids}
                              onChange={handleField_ids}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {fields &&
                                fields.map((field) => (
                                  <Option key={field.id} value={field.id}>
                                    {field.name}
                                  </Option>
                                ))}
                            </Select>
                          </GridItem>

                          <br />
                          <br />
                          <Link to={`/citrine/configuration/program/totalLevel`}>
                            <Button
                              color="primary"
                              onClick={onSaveTotalLevelClicked}
                            >
                              {t("Save")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      {!isAddMode && tl && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span> {t("Program Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/configuration/program/totalLevel`}
                      style={{ color: "orange" }}
                    >
                      {t("Total levels")}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Edit TotalLevel")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="total_levels"
                            labelText={t("Total_levels ")}
                            inputProps={{
                              value: total_levels,
                              onChange: (event) => {
                                setTotal_levels(event.target.value);
                              },
                              type: "text",
                            }}
                          />

                          <GridItem>
                            <label>{t("Field ")}</label>

                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "100%" }}
                              placeholder={t("Select a field")}
                              optionFilterProp="children"
                              value={fieldSpec_ids}
                              onChange={handleField_ids}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {fields &&
                                fields.map((field) => (
                                  <Option key={field.id} value={field.id}>
                                    {field.name}
                                  </Option>
                                ))}
                            </Select>
                          </GridItem>

                          <br />
                          <br />
                          <Link to={`/citrine/configuration/program/totalLevel`}>
                            <Button
                              color="primary"
                              onClick={onUpdateTotalLevelClicked}
                            >
                              {t(" Update")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
    </div>
  );
}
