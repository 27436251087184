import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  KpiReports: [],
  KpiReportsStatus: 'idle',
  KpiReportStatus: 'idle',
  error: null,
  KpiReport: null,
  id: null,
  currentRequestId: undefined,
  loading: 'idle',
}

export const fetchKpiReports = createAsyncThunk('KpiReport/fetchKpiReports', async (id, { getState, requestId, rejectWithValue }) => {
  const { currentRequestId, loading } = getState().KpiReport
  if (loading !== 'pending' || requestId !== currentRequestId) {
    console.log("****************stopped here**************",)

    return
  }

  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(BaseUrl + `/kpiReport/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    console.log("****************response.data**************", response.data)
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})

export const fetchKpiReportById = createAsyncThunk(
  'KpiReport/fetchKpiReportById',
  async (id, { rejectWithValue }) => {
    try {
      console.log('trying fetchKpiReportById')
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/kpiReport/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      console.log(response.data, 'response.data fetchKpiReportById')

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      //console.log('error.response fetchKpiReportById', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const addNewKpiReport = createAsyncThunk(
  'KpiReport/addNewKpiReport',
  async (initialKPIReport, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.post(
        BaseUrl + `/kpiReport/create/`,
        initialKPIReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const deleteKpiReport = createAsyncThunk(
  'KpiReport/deleteKpiReport',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/kpiReport/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    console.log(response.data, ' delete response.data')

    return response.data
  },
)

export const UpdateKpiReport = createAsyncThunk(
  'KpiReport/UpdateKpiReport',
  async (data, thunkAPI) => {
    console.log(data, 'updatedKpiReport')
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(
        BaseUrl + `/kpiReport/${data.id}/edit/`,
        data.updatedKpiReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(data, 'updatedKpiReport')
      console.log(response.data, 'response.data')
      return response.data
    } catch (error) {
      console.error('Failed to update the courseReport: ', error)
    }
  },
)
export const AffectCoordinatorToKPIsReport = createAsyncThunk(
  'KPIsReport/AffectCoordinatorToKPIsReport',
  async (data, { rejectWithValue }) => {
    console.log(data)
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/kpiReport/update/${data.id}/`,
        data.data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //  console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

const KpiReportSlice = createSlice({
  name: 'KpiReport',
  initialState,
  extraReducers: {},
  reducers: {},
  extraReducers: {
    // fetchPloReports
    [fetchKpiReports.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.KpiReportsStatus = 'loading'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchKpiReports.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.KpiReportsStatus = 'succeeded'
        state.KpiReports = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchKpiReports.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.KpiReportsStatus = 'failed'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    // fetchPloReportById
    [fetchKpiReportById.pending]: (state, action) => {
      state.KpiReportStatus = 'loading'
    },
    [fetchKpiReportById.fulfilled]: (state, action) => {
      state.KpiReportStatus = 'succeeded'
      state.KpiReport = action.payload
    },
    [fetchKpiReportById.rejected]: (state, action) => {
      state.KpiReportStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    // addNewPloReport
    [addNewKpiReport.fulfilled]: (state, action) => { },
    // deletePloReport
    [deleteKpiReport.fulfilled]: (state, action) => {
      // console.log(action)
    },
    //PloReportUpdate
    [UpdateKpiReport.fulfilled]: (state, action) => {
      // console.log(action)
    },
  },
})

export const { KpiReportAdded, KpiReportUpdate } = KpiReportSlice.actions

export default KpiReportSlice.reducer
