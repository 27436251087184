import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
// core components
import { unwrapResult } from '@reduxjs/toolkit';
import { Form, notification, Select } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchCollegeById } from '../../../../reduxToolkit/features/college/CollegesSlice';
import { addNewKPICollege, deleteKPICollege, fetchKPIsCollegeList, fetchNotAffectedKPIConfigToCollege } from '../../../../reduxToolkit/features/KPI/KPICollegeSlice';
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";

import { fetchSemesters } from 'reduxToolkit/features/semester/SemestersSlice';










const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);



const KPIExcerpt = ({ KPI, key }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const classes = useStyles();
    const cardClasses = useCardStyles();
    let { id } = useParams();


    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ?
            panel : false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const ids = {
        id: KPI.id,
        college_id: id,
        kpiConfig_id: KPI.kpiConfig
    }
    const handleDeleteKPICollege = async (ids) => {
        dispatch(deleteKPICollege(ids))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                // handle result here
                console.log('originalPromiseResult', originalPromiseResult)
                dispatch(fetchNotAffectedKPIConfigToCollege(id))
                dispatch(fetchKPIsCollegeList(id))
                openNotificationWithIcon('success', `Deleted`)

            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
                setHandledError(rejectedValueOrSerializedError)
                handleOpenErrorModal()
            })
    }

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };

    return (

        <GridItem xs={12} sm={12} md={10}
        >
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={11} sm={11} md={11}>
                            <Typography >{KPI.kpiConfig_name}</Typography>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                justIcon
                                round
                                onClick={handleClickOpen}
                            >
                                <DeleteIcon />
                            </Button>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
            <SuperGeneralDeleteModal
                delete_Element={handleDeleteKPICollege}
                Element_ID={ids}
                open={open}
                handleClose={handleClose}
            />
        </GridItem>
    )
}

export default function CollegeKPIs() {
    const { t } = useTranslation();
    let { id } = useParams();
    console.log('id', id);
    const classes = useStyles();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const collegeStatus = useSelector((state) => state.college.collegeStatus)

    const dispatch = useDispatch()

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const fetchKPIsByCollege = async () => {
        try {
            const resultAction = await dispatch(fetchKPIsCollegeList(id))
                .unwrap()
                .then((originalPromiseResult) => {
                    // handle result here
                    console.log('originalPromiseResult', originalPromiseResult)
                })
            console.log('success')
        } catch (err) {
            setHandledError(err)
            handleOpenErrorModal()
        }
    }

    useEffect(() => {
        dispatch(fetchCollegeById(id))
        fetchKPIsByCollege()
    }, [dispatch])

    const college = useSelector((state) => state.college.college)
    const { KPIsList, KPIsStatus } = useSelector((state) => state.KPICollege)

    console.log('college', college)
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton


    let content

    if (KPIsStatus === 'loading') {
        content = <div className="loader">{t('Loading...')}</div>
    } else if (KPIsStatus === 'succeeded') {
        if (KPIsList && KPIsList.length) {
            content = KPIsList.map(KPI => (<KPIExcerpt key={KPI.id} KPI={KPI} />))
        } else {
            content = <h5>{t('This college has no KPIs to display!')}</h5>

        }
    }

    return (
        <div>
            <GridContainer style={{
                borderBottom: `1px solid  ${grayColor[15]}`,
                borderTop: `1px solid  ${grayColor[15]}`,
            }}
                justify="space-between"
                alignItems="center">
                <GridItem>
                    <Link to={`/citrine/organizations`}>
                        <span className={titleClasses.title}>{t('Organizations')}</span>
                    </Link>
                    <Link to={`/citrine/organizations/colleges`}>
                        <span className={titleClasses.title}> {`>${t('Colleges')}`} </span>
                    </Link>
                    <Link to={`/citrine/organizations/colleges/${id}`}>
                        <span className={titleClasses.title}> {`> ${college && college.name}`} </span>
                    </Link>
                    <span className={titleClasses.title}>{t(`> KPIs`)} </span>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem>
                    <KPIModal college_id={id} />
                </GridItem>
            </GridContainer>

            <GridContainer>
                {content}
            </GridContainer>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />

        </div>
    );
}








function KPIModal({ college_id }) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };




    useEffect(() => {
        dispatch(fetchNotAffectedKPIConfigToCollege(college_id))
        dispatch(fetchSemesters());
        // .unwrap()
        // .then((kpis) => {
        //   // handle result here
        //   const filteredKpis = kpis.filter(kpi => kpi.kpiCategoryConfig_detail.includes('program'));
        //   setList(filteredKpis)
        // })
    }, [dispatch])

    const filteredKPIconfig = useSelector((state) => state.KPICollege.filteredKPIconfig)
    console.log("filteredKPIconfig", filteredKPIconfig)
    const semesters = useSelector((state) => state.semester.semesters)
    const [semester_id, setSemester_id] = React.useState('');
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };
    function handleChangeSemester(value) {
        console.log("value", value);
        setSemester_id(value)
    }

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };
    const { Option } = Select;


    const [kpiConfig_id, setkpiConfig_id] = React.useState([]);

    function handleChange(value) {
        console.log("value", value);
        setkpiConfig_id(value)
    }


    const onFinish = async () => {
        const resultAction = await dispatch(addNewKPICollege({ college_id, kpiConfig_id, semester_id }))

        if (addNewKPICollege.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            dispatch(fetchNotAffectedKPIConfigToCollege(college_id))
            dispatch(fetchKPIsCollegeList(college_id))
            history.push(`/citrine/organizations/colleges/${college_id}/KPIs`);
            openNotificationWithIcon('success', `Affected successfully`)
            handleClose()


        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                handleClose()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                handleClose()
            }
        }

    }


    return (
        <div>

            <Button color='primary'
                onClick={(event) => {
                    handleClickOpen(event)
                }}>

                Add KPI
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}>
                <DialogTitle id="form-dialog-title">{t('Add KPI')}</DialogTitle>
                <DialogContent>

                    <Form
                        name="basic"
                        layout="vertical"
                        //   labelCol={{ span: 8 }}
                        //   wrapperCol={{ span: 16 }}
                        //   initialValues={{ remember: true }}
                        onFinish={onFinish}
                        //   autoComplete="off"
                        scrollToFirstError
                    >

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("KPI")}
                                    name="kpiConfig_id"
                                    rules={[{ required: true, message: "Please select one KPI" }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ zIndex: 5 }}
                                        dropdownStyle={{ zIndex: 2000 }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={handleChange}
                                    >
                                        {filteredKPIconfig && filteredKPIconfig.length && filteredKPIconfig.map((KPI) => (
                                            <Option key={KPI.id} value={KPI.id}>
                                                {`${KPI.name.substring(0, 50)}...`}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={t("Semester")}
                                    name="semester_id"
                                    rules={[{ required: true, message: "Please select one semester" }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ zIndex: 5 }}
                                        dropdownStyle={{ zIndex: 2000 }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={handleChangeSemester}
                                    >
                                        {semesters && semesters.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.academicYear_detail}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>



                            {/* <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t('Weight')}
                                    name="weight"
                                    rules={[{ required: true, message: "Please input the weight!" }]}
                                >
                                    <Input
                                        id="weight"
                                        value={weight}
                                        onChange={event => {
                                            setWeight(event.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </GridItem> */}
                        </GridContainer>


                        {/* </FormControl> */}
                        <GridContainer justify="flex-end">
                            <GridItem>

                                <Form.Item>
                                    <GridContainer>
                                        <GridItem>
                                            <Button color="primary" onClick={() => { handleClose() }}>
                                                {t('Cancel')}
                                            </Button>
                                        </GridItem>
                                        <GridItem>
                                            <Button type="submit" color="primary">
                                                {t('Save')}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Form.Item>

                            </GridItem>
                        </GridContainer>

                    </Form>
                </DialogContent>
            </Dialog>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
        </div>
    );
}






