import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import { default as CardBody, default as CardHeader } from "components/Card/CardBody.js";
import Button from 'components/CustomButtons/Button';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchBranches } from '../../../reduxToolkit/features/branche/BranchesSlice';
import { fetchColleges } from '../../../reduxToolkit/features/college/CollegesSlice';
import { fetchDepartments } from '../../../reduxToolkit/features/department/DepartmentsSlice';
import { fetchUniversities } from '../../../reduxToolkit/features/university/UniversitiesSlice';





const useStyles = makeStyles(styles);

export default function Organizations() {
  const { t } = useTranslation();
  const dispatch = useDispatch()


  const useNavBarStyles = makeStyles(navBarStyles);
  const useTitleStyles = makeStyles(titleStyles);
  const useCardStyles = makeStyles(cardStyles);

  const titleClasses = useTitleStyles();

  const universitiesStatus = useSelector(state => state.university.universitiesStatus)
  const collegesStatus = useSelector(state => state.college.collegesStatus)
  const departmentsStatus = useSelector(state => state.department.departmentsStatus)
  const branchesStatus = useSelector(state => state.branches.branchesStatus)
  useEffect(() => {
    if (universitiesStatus === 'idle') {
      dispatch(fetchUniversities())
    }
    if (departmentsStatus === 'idle') {
      dispatch(fetchDepartments())
    }
    if (collegesStatus === 'idle') {
      dispatch(fetchColleges())
    }
    if (branchesStatus === 'idle') {
      dispatch(fetchBranches())
    }

  }, [universitiesStatus, departmentsStatus, collegesStatus, branchesStatus, dispatch])

  const universities = useSelector((state) => state.university.universities)
  const colleges = useSelector((state) => state.college.colleges)
  const departments = useSelector((state) => state.department.departments)
  const branches = useSelector(state => state.branches.branches)


  return (
    <GridContainer xs={12} sm={12} md={12}
      direction="row"
      justify="space-between"
    >
      <GridItem xs={12} sm={12} md={12}>
        <span style={{color:'RGB(137, 34, 155)'}} className={titleClasses.title}>{t(' Organizations')} </span>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ height: 250 }} >
              <CardHeader>
                <GridContainer justify="center">
                  <GridItem>
                    <Link to={`/citrine/organizations/universities`} >
                      <h3 style={{ color: `${primaryColor[0]}` }}>
                        {t(' Universities')}
                      </h3>
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem>
                    <h4>
                      {universities.length}{t(' Universities')}
                    </h4>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem>
                    <Link to={`/citrine/organizations/universities`} ><Button color='primary'>View</Button></Link>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ height: 250 }}>
              <CardHeader>
                <GridContainer justify="center">
                  <GridItem>
                    <Link to={`/citrine/organizations/colleges`} >
                      <h3 style={{ color: `${primaryColor[0]}` }}>
                        {t('Colleges')}
                      </h3>
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem>
                    <h4>
                      {colleges.length} {t('Colleges')}
                    </h4>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center"><GridItem>
                  <Link to={`/citrine/organizations/colleges`} ><Button color='primary'>View</Button></Link>
                </GridItem></GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ height: 250 }}>
              <CardHeader>
                <GridContainer justify="center">
                  <GridItem>
                    <Link to={`/citrine/organizations/departments`} >
                      <h3 style={{ color: `${primaryColor[0]}` }}>
                        {t('Departments')}
                      </h3>
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center"><GridItem>
                  <h4>
                    {departments.length} {t('Departments')}
                  </h4>
                </GridItem></GridContainer>
                <GridContainer justify="center"><GridItem>
                  <Link to={`/citrine/organizations/departments`} ><Button color='primary'>View</Button></Link>
                </GridItem></GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ height: 250 }}>
              <CardHeader>
                <GridContainer justify="center">
                  <GridItem>
                    <Link to={`/citrine/organizations/branches`} >
                      <h3 style={{ color: `${primaryColor[0]}` }}>
                        {t('Branches')}
                      </h3>
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center"><GridItem>
                  <h4>
                    {branches.length} {t('Branches')}
                  </h4>
                </GridItem></GridContainer>
                <GridContainer justify="center"><GridItem>
                  <Link to={`/citrine/organizations/branches`} ><Button color='primary'>View</Button></Link>
                </GridItem></GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}













