import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { primaryColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import "assets/css/styles.css";
import "antd/dist/antd.css";
import {
  fetchImprovementByResourceAndResourceIdAndType,
  deleteAnnualProgramAnalysis,
} from "../../../../../reduxToolkit/features/annualProgramAnalysis/annualProgramAnalysisSlice";
import { useTranslation } from "react-i18next";
import EditAssessmentAnalysis from "./EditAssessmentAnalysis";
import AddAssessmentAnalysis from "./AddAssessmentAnalysis";

export default function AssessmentAnalysis(props) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.activeChildTabKey === "5") {
      props.fetchAnalysis()
    }
  }, [props.activeChildTabKey]);

  const improvementByResourceAndResourseIdAndType = useSelector(
    (state) =>
      state.annualProgramAnalysis.improvementByResourceAndResourseIdAndType
  );

  return (
    <div>
      <Card style={{ marginRight: "10px" }}>
        <CardBody>
          {improvementByResourceAndResourseIdAndType &&
            improvementByResourceAndResourseIdAndType.length == 0 && (
              <AddAssessmentAnalysis
                AnnualProgramReport={props.AnnualProgramReport}
                improvementByResourceAndResourseIdAndType={
                  improvementByResourceAndResourseIdAndType &&
                  improvementByResourceAndResourseIdAndType
                }
              />
            )}
          <br />
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <p style={{ minHeight: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>Strengths</strong> :
                <p>
                  {improvementByResourceAndResourseIdAndType &&
                    improvementByResourceAndResourseIdAndType[0] &&
                    improvementByResourceAndResourseIdAndType[0].strength}
                </p>
              </p>
            </GridItem>

            {improvementByResourceAndResourseIdAndType &&
              improvementByResourceAndResourseIdAndType[0] && (
                <GridItem xs={1} sm={1} md={1}>
                  <EditAssessmentAnalysis
                    improvementByResourceAndResourseIdAndType={
                      improvementByResourceAndResourseIdAndType &&
                      improvementByResourceAndResourseIdAndType
                    }
                    AnnualProgramReport={props.AnnualProgramReport}
                    option={"strength"}
                  />
                </GridItem>
              )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <p style={{ minHeight: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>
                  Priorities for improvement
                </strong>
                :
                <p>
                  {improvementByResourceAndResourseIdAndType &&
                    improvementByResourceAndResourseIdAndType[0] &&
                    improvementByResourceAndResourseIdAndType[0].priorities_for_improvement_details?.map(
                      (p) => <p>{p.recommendation}</p>
                    )}
                </p>
              </p>
            </GridItem>

            {improvementByResourceAndResourseIdAndType &&
              improvementByResourceAndResourseIdAndType[0] && (
                <GridItem xs={1} sm={1} md={1}>
                  <EditAssessmentAnalysis
                    improvementByResourceAndResourseIdAndType={
                      improvementByResourceAndResourseIdAndType &&
                      improvementByResourceAndResourseIdAndType
                    }
                    AnnualProgramReport={props.AnnualProgramReport}
                    option={"priorities_for_improvement"}
                  />
                </GridItem>
              )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <p style={{ minHeight: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>
                  Areas for improvement
                </strong>
                :
                <p>
                  {improvementByResourceAndResourseIdAndType &&
                    improvementByResourceAndResourseIdAndType[0] &&
                    improvementByResourceAndResourseIdAndType[0]
                      .areas_for_improvement}
                </p>
              </p>
            </GridItem>

            {improvementByResourceAndResourseIdAndType &&
              improvementByResourceAndResourseIdAndType[0] && (
                <GridItem xs={1} sm={1} md={1}>
                  <EditAssessmentAnalysis
                    improvementByResourceAndResourseIdAndType={
                      improvementByResourceAndResourseIdAndType &&
                      improvementByResourceAndResourseIdAndType
                    }
                    AnnualProgramReport={props.AnnualProgramReport}
                    option={"areas_for_improvement"}
                  />
                </GridItem>
              )}
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}
