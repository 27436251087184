import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const EducationalUnit = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>Institutional Summary: Educational Unit</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the educational unit in which the
        program is located including the administrative chain of responsibility
        from the individual responsible for the program to the chief executive
        officer of the institution.
      </p>

      <p className={styles.reportParagraphTitle}>
        An organization chart may be included.
      </p>

      <p className={styles.reportParagraphTitle}>
        The educational unit is the administrative unit having academic
        responsibility for the program(s) being reviewed by a given Commission
        of ABET.
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
    </>
  );
};

export default EducationalUnit;
