import { Breadcrumb } from "antd";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";
import CourseContentCard from "./CourseContentCard";

export default function CourseContentViews({ match }) {

  const { id } = match.params;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const course = useSelector((state) => state.CourseSpec.courseSpecification);

  const [disabledStatus, setDisabledStatus] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [view, setView] = useState("card");

  useEffect(() => {
    fetchCourseByIdMethod();
    if (localStorage.getItem("CourseState") !== "Draft") {
      setDisabledStatus(true);
    }
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCourseByIdMethod = async () => {
    try {
      const resultAction = await dispatch(fetchCourseById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  return (
    <div>
      <GridContainer justify="space-between" alignItems="baseline">
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/CourseContents`}
                style={{ color: "orange" }}
              >
                {" "}
                {t("Course Content List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      {view === "card" && (
        <div>
          <CourseContentCard id={id} />
        </div>
      )}
    </div>
  );
}
