import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  areas: [],
  areasStatus: 'idle',
  areaStatus: 'idle',
  error: null,
  area:null,
 
  id:null
}


export const fetchAreas = createAsyncThunk('area/fetchAreas', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/area/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchAreaById = createAsyncThunk('area/fetchAreaById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/area/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewArea = createAsyncThunk(
    'area/addNewArea',
    async (initialArea) => {
      console.log('initial area', initialArea)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/area/create/`,initialArea ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const areaUpdate = createAsyncThunk(
    'area/areaUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/area/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedArea')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the area: ', error)

}
    }
  )

  export const deleteArea = createAsyncThunk('area/deleteArea', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/area/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
const  areasSlice = createSlice({
  name: ' area',
  initialState,
  extraReducers: {
    [fetchAreas.pending]: (state, action) => {
        state.areasStatus = 'loading'
      },
      [fetchAreas.fulfilled]: (state, action) => {
        state.areasStatus = 'succeeded'
        // Add any fetched posts to the array
        state.areas = action.payload
      },
      [fetchAreas.rejected]: (state, action) => {
        state.areasStatus = 'failed'
        state.error = action.payload
      },

      [fetchAreaById.pending]: (state, action) => {
        state.areaStatus = 'loading'
      },
      [fetchAreaById.fulfilled]: (state, action) => {
        state.areaStatus = 'succeeded'

        // Add any fetched posts to the array
        state.area = action.payload
      },
      [fetchAreaById.rejected]: (state, action) => {
        state.areaStatus = 'failed'
        state.error = action.payload
      },
      [addNewArea.fulfilled]: (state, action) => {
        state.areas.push(action.payload)
      },
      [areaUpdate.fulfilled]: (state, action) => {
        console.log(action) 
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    areaUpdated(state, action) {
      const { id,name } = action.payload
      const existingArea = state.areas.find(area => area.id === id)
      if (existingArea) {
        existingArea.name = name
       

      }
    }
  }
}})

export const { areaAdded, areaUpdated, getId} = areasSlice.actions

export default areasSlice.reducer

export const selectAllAreas = state => state.area.areas
