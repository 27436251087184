import { Breadcrumb } from 'antd';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramSpecSlice';
import OldAccreditationDates from "./OldAccreditationDates";
import PlannedAccreditationDates from "./PlannedAccreditationDates";


export default function AccreditationDates({ match }) {
    const { t } = useTranslation();
    const { programId } = match.params
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchProgramById(programId))
    }, [dispatch])
    const program = useSelector((state) => state.programSpec.program)

    return (

        <GridContainer>
           
            <GridItem xs={12} sm={12} md={12}>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to={`/citrine/programSpec`}>{t("Programs")}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/citrine/programSpec/${programId}`}
                        >
                            <span>
                                {program && program.program_degree_name}
                            </span>
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/citrine/specification/program/${programId}/accreditationDates`}
                            style={{ color: "orange" }}
                        >
                            {t("Accreditation Dates")}
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <h4>Accreditation Dates</h4>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <OldAccreditationDates />
            </GridItem>

            <br /> <br /> <br />

            <GridItem xs={12} sm={12} md={12}>
                <h4> Planned Accreditation Dates</h4>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <PlannedAccreditationDates />
            </GridItem>
        </GridContainer>



    );
}
