import React from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Badge, Breadcrumb } from "antd";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import CardHeader from "components/Card/CardHeader.js";
//import Card from '@material-ui/core/Card';
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import { useEffect, useState } from "react";
// react plugin for creating charts
import Card from "components/Card/Card.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CourseLogo from "../../../../assets/img/CourseLogo.png";
//chartist
import { Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DropDownButtonCoordinatorView from "views/Components/DropDownButtonCoordinatorView";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {
  fetchCoursesByUserAndSemester
} from "../../../../reduxToolkit/features/course/CoursesSlice";
const useStyles3 = makeStyles(styles1);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);



const CourseExcerpt = ({ course }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (

    <GridItem xs={12} sm={12} md={6} key={course.id}>
      <Card style={{ height: 470 }}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={5}
            style={{ paddingTop: "50px" }}
          >
            <GridContainer>
              <img src={CourseLogo} width="70%" height="145" />
            </GridContainer>
            <GridContainer>
              {course &&
                course.theory_instructor &&
                course.theory_instructor[0] &&
                course.theory_instructor[0].username &&
                course.theory_instructor[0].username.length ? (
                <p>
                  Theory Instructor:
                  {course &&
                    course.theory_instructor &&
                    course.theory_instructor[0] &&
                    course.theory_instructor[0].username}
                </p>
              ) : (
                <p></p>
              )}
            </GridContainer>

            <GridContainer>
              {course &&
                course.course_coordinator &&
                course.course_coordinator[0] &&
                course.course_coordinator[0].username &&
                course.course_coordinator[0].username.length ? (
                <p>
                  Course Coordinator:
                  {course &&
                    course.course_coordinator &&
                    course.course_coordinator[0] &&
                    course.course_coordinator[0].username}
                </p>
              ) : (
                <p></p>
              )}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={7}>
            <CardHeader>
              <div>
                <div style={{ textAlign: "end", zIndex: "1000" }}>
                  <DropDownButtonCoordinatorView
                    courseID={course.id}
                    course={course}
                  //program={program}
                  // semester_id={program.semester_id}
                  />
                </div>
                {/* <h4
                          style={{
                            display: "flex",
                            paddingTop: "5px",
                            color: "grey",
                          }}
                        >
                          <strong
                            style={{ fontSize: "14px", paddingRight: "10px" }}
                          >
                            <p>{program && program.code && program.code}</p>
                          </strong>
                          <strong>
                            <p>
                              {program &&
                                program.program_degree_name &&
                                program.program_degree_name}
                            </p>
                          </strong>
                        </h4> */}
                <h4
                  style={{
                    display: "flex",
                    paddingTop: "5px",
                    color: "grey",
                  }}
                >
                  <strong
                    style={{ fontSize: "14px", paddingRight: "10px" }}
                  >
                    <p>{course.code}</p>
                  </strong>
                  <strong>
                    <p>{course.title}</p>
                  </strong>
                </h4>
              </div>

              <h4 style={{ fontSize: "14px" }}>
                {course &&
                  course.description &&
                  course?.description?.slice(0, 200)}
                ...
              </h4>

              <h4>
                {course &&
                  course.course_groups &&
                  course?.course_groups?.map((el) =>
                    el?.student_count != 0 ? (
                      <Badge
                        className="site-badge-count-109"
                        count={el.name}
                        style={{ backgroundColor: "#52c41a" }}
                      />
                    ) : (
                      <Badge
                        className="site-badge-count-109"
                        count={el.name}
                        style={{ backgroundColor: "#A0A0A0" }}
                      />
                    )
                  )}
              </h4>
            </CardHeader>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "40px",
                marginEnd: "10px",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "red",
                    height: "50px",
                    width: "50px",
                  }}
                >
                  {course && course.clos_count && course.clos_count}
                </Avatar>
                <p style={{ marginTop: "10px", fontSize: "14px" }}>
                  {t("CLOs")}
                </p>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "orange",
                    height: "50px",
                    width: "50px",
                  }}
                >
                  {course &&
                    course.students_count &&
                    course.students_count}
                </Avatar>
                <p style={{ marginTop: "10px", fontSize: "14px" }}>
                  {t("Students")}
                </p>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "deepskyblue",
                    height: "50px",
                    width: "50px",
                  }}
                >
                  {course &&
                    course.assessments_by_question_count &&
                    course.assessments_by_question_count}
                </Avatar>
                <p style={{ marginTop: "10px", fontSize: "14px" }}>
                  {t("Assessments")}
                </p>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "mediumseagreen",
                    height: "50px",
                    width: "50px",
                  }}
                >
                  {course &&
                    course?.assessment_calculation_method &&
                    course?.assessment_calculation_method === "clo" ? (
                    <p>
                      {course &&
                        course?.score_by_course &&
                        course?.score_by_course[0] &&
                        course?.score_by_course[0]?.score_by_course}
                    </p>
                  ) : (
                    <p>
                      {course &&
                        course?.score_course_by_question &&
                        course?.score_course_by_question[0] &&
                        course?.score_course_by_question[0]?.score_by_course.toFixed(
                          0
                        )}
                      %
                    </p>
                  )}
                </Avatar>
                <p style={{ marginTop: "10px", fontSize: "14px" }}>
                  {t("Score")}
                </p>
              </Grid>
            </div>
          </GridItem>
        </GridContainer>
      </Card>
    </GridItem>

  )
}

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 30,
    span: 26,
  },
};
export default function CoordinatorCoursesListAssessmentPage() {
  const courses = useSelector((state) => state.course.coursesByUserAndSemester);
  const {currentSemester} = useSelector((state) => state.semester);
  
  const error = useSelector((state) => state.course.error);



  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("")
  const coursesStatus = useSelector((state) => state.course.coursesStatus);


  const id = currentSemester?.semester_id
  //const semester_id = localStorage.getItem('semester_id');
  useEffect(() => {
    
    dispatch(fetchCoursesByUserAndSemester(id));
    

  }, [dispatch,id]);
  


  let content;

  if (coursesStatus === 'loading') {
    content = <div>Loading...</div>
  } else if (coursesStatus === 'succeeded') {
    if (courses === "You do not have permission") {
      let courses = [];
      content = <div>You do not have permission to access courses list</div>;
    }
    else {


      if (courses && courses.length) {
        content = courses && courses.map(course => <CourseExcerpt key={course.id} course={course} />);
      } else {
        content = <div>No courses found.</div>;
      }
    }
  }
  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">

          <Breadcrumb.Item>
            Courses List
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <br></br>
      <GridContainer>

        {content}


      </GridContainer>
    </div>
  );
}
