import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../assets/utils/accessToken";
const initialState = {
  coursesSpecification: [],
  filterdCourseByTitle: [],
  coursesSpecificationStatus: "idle",
  courseSpecificationStatus: "idle",
  error: null,
  courseSpecification: null,
  coursesSpecificationByPage: []
};

export const fetchCourses = createAsyncThunk(
  "CourseSpecification/fetchCourses",
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + "/courseSpec/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCoursesByPage = createAsyncThunk(
  "CourseSpecification/fetchCoursesByPage",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/courseSpec/ByPage/?page=${data.page}&records=${data.records}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseNextPage = createAsyncThunk(
  "CourseSpecification/fetchCourseNextPage",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchActiveCourses = createAsyncThunk(
  "CourseSpecification/fetchActiveCourses",
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + "/courseSpec/filtered_by_state/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseById = createAsyncThunk(
  "CourseSpecification/fetchCourseById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/courseSpec/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const courseUpdateWeeks = createAsyncThunk(
  "CourseSpecification/courseUpdateWeeks",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/courseSpec/editWeeks/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const addNewCourse = createAsyncThunk(
  "CourseSpecification/addNewCourse",
  async (initialCourse, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/courseSpec/create/`,
        initialCourse,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);
export const courseUpdate = createAsyncThunk(
  "CourseSpecification/courseUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/courseSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const addCourseSyllabus = createAsyncThunk(
  "CourseSpecification/addCourseSyllabus",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/courseSpec/${data.id}/addCourseSyllabus/`,
        data.syllabus,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const Specific_CoursesSpecUpdate = createAsyncThunk(
  "CourseSpecification/courseUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/courseSpec/${data.id}/update/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "CourseSpecification/deleteCourse",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/courseSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const VersionningCourseSpec = createAsyncThunk(
  'programSpec/VersionningCourseSpec',
  async (initialProgram, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/courseSpec/copy/`, initialProgram, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
)

/***************************************************************
 * updated 07/11/2022
 * Add coordinator to courses specification
 ****************************************************************/
 export const AffectCoordinatorToCourse = createAsyncThunk(
  'courseSpec/AffectCoordinatorToCourse',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/courseSpec/update/${data.id}/`, data.data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
)

export const fetchCourseByTitle = createAsyncThunk(
  "CourseSpecification/fetchCourseByTitle",
  async (title, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/courseSpec/search/?title=${title}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

const CoursesSpecSlice = createSlice({
  name: " CourseSpecification",
  initialState,
  extraReducers: {
    [fetchCourses.pending]: (state, action) => {
      state.coursesSpecificationStatus = "loading";
    },
    [fetchCourses.fulfilled]: (state, action) => {
      state.coursesSpecificationStatus = "succeeded";
      state.coursesSpecification = action.payload;
    },
    [fetchCourses.rejected]: (state, action) => {
      state.coursesSpecificationStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCoursesByPage.pending]: (state, action) => {
      state.coursesSpecificationStatus = "loading";
    },
    [fetchCoursesByPage.fulfilled]: (state, action) => {
      state.coursesSpecificationStatus = "succeeded";
      state.coursesSpecificationByPage = action.payload;
    },
    [fetchCoursesByPage.rejected]: (state, action) => {
      state.coursesSpecificationStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseNextPage.pending]: (state, action) => {
      state.coursesSpecificationStatus = "loading";
    },
    [fetchCourseNextPage.fulfilled]: (state, action) => {
      state.coursesSpecificationStatus = "succeeded";
      state.coursesSpecificationByPage = action.payload;
    },
    [fetchCourseNextPage.rejected]: (state, action) => {
      state.coursesSpecificationStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseById.pending]: (state, action) => {
      state.courseSpecificationStatus = "loading";
    },
    [fetchCourseById.fulfilled]: (state, action) => {
      state.courseSpecificationStatus = "succeeded";
      // Add any fetched posts to the array
      state.courseSpecification = action.payload;
    },
    [fetchCourseById.rejected]: (state, action) => {
      state.courseSpecificationStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [VersionningCourseSpec.fulfilled]: (state, action) => {
    },
    [VersionningCourseSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewCourse.fulfilled]: (state, action) => {
      state.coursesSpecification.push(action.payload);
    },
    [addNewCourse.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [courseUpdateWeeks.fulfilled]: (state, action) => {
    },
    [courseUpdateWeeks.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [Specific_CoursesSpecUpdate.fulfilled]: (state, action) => {
    },
    [Specific_CoursesSpecUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [courseUpdate.fulfilled]: (state, action) => {
    },
    [courseUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseByTitle.pending]: (state, action) => {
      state.coursesSpecificationStatus = "loading";
    },
    [fetchCourseByTitle.fulfilled]: (state, action) => {
      state.coursesSpecificationStatus = "succeeded";
      state.coursesSpecificationByPage = action.payload;
    },
    [fetchCourseByTitle.rejected]: (state, action) => {
      state.coursesSpecificationStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});

export default CoursesSpecSlice.reducer;
