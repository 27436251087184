import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import "antd/dist/antd.css";




import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../../Components/ErrorModal";

import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";


import { Breadcrumb } from 'antd';
import { Link, useHistory } from "react-router-dom";
import {
  addStandardConfig
} from "../../../../reduxToolkit/features/standardConfig/StandardConfigSlice";


import { fetchKpiStandards } from 'reduxToolkit/features/kpiStandardConfigType/kpiStandardConfigTypeSlice';
import { fetchStandardConfigs } from "../../../../reduxToolkit/features/standardConfig/StandardConfigSlice";


const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

export default function AddStandardPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const KPIsStandard = useSelector((state) => state.KpiStandard.KpiStandards)
  console.log(KPIsStandard)
  useEffect(() => {
    dispatch(fetchKpiStandards())
  }, [dispatch])
  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);
  const history = useHistory();


  const { Option } = Select;
  const { TextArea } = Input;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push(`/citrine/configuration/others/StandardPage`);
  };
  const [person_id, setPerson_id] = React.useState(props.person_id);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };



  const onFinish = async (values) => {
    console.log(values);
    const resultAction = await dispatch(
      addStandardConfig({
        ...values
      })

    );
    if (addStandardConfig.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);

      dispatch(fetchStandardConfigs())
      form.resetFields();
      history.push(`/citrine/configuration/others/StandardPage`);
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  return (
    <div>
      <GridContainer
        md={12}
        xs={12}
        sm={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t("other Configurations")} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/StandardPage`}>
                <span> {t("Standards Page")} </span>
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/AddStandard`}>
                <span> {t("ADD Standard")} </span>
              </Link>
            </Breadcrumb.Item>

          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <br></br>
            <Form.Item
              label={t("type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select the type",
                },
              ]}
            >
              <Select style={{ width: "100%" }} size={200} showArrow>
                {/* <Option value="mission, vision and strategic planning">Mission, vision and strategic planning</Option>
                <Option value="governance, leadership, and management">Governance, leadership, and management</Option>
                <Option value="teaching and learning">Teaching and learning</Option>
                <Option value="students">Students</Option>
                <Option value="faculty and staff">Faculty and staff</Option>
                <Option value="institutional resources">Institutional resources</Option>
              <Option value="community partnerships">Community partnerships</Option> */}
                {KPIsStandard?.map((item) => (
                  <Option value={item?.id}>{item?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <br></br>
            <br></br>
            <Form.Item
              name="code"
              label="Code"
              rules={[
                {
                  required: true,
                  message: "Please enter the code.",
                },
              ]}

            >
              <Input />
            </Form.Item>
            <br></br>
            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: "20px" }}
                color="danger"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="primary" color="info" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </GridItem>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
