import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Input, notification } from "antd";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useDispatch, useSelector } from "react-redux";

// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";

import { useHistory } from "react-router-dom";
import { fetchProgramById, VersionningPorgramSpec } from "../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "./ErrorModal";

import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";

export default function VersionningProgramSpecModal({
  programSpec_id,
  program,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [ploSpec, setPloSpec] = useState(true);
  const [peoSpec, setPeoSpec] = useState(true);
  const [GASpec, setGASpec] = useState(true);
  const [kpiSpec, setKpiSpec] = useState(true);
  const [major, setMajor] = useState(false);
  const [minor, setMinor] = useState(false);
  const [code, setCode] = useState(program.code);
  const { error } = useSelector((state) => state.ploSpec);
  const dispatch = useDispatch();

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  function onChangePlos(e) {
    console.log(`checked Plos  = ${e.target.checked}`);
    setPloSpec(e.target.checked);
  }
  function onChangePEOs(e) {
    console.log(`checked PEOs = ${e.target.checked}`);
    setPeoSpec(e.target.checked);
  }
  function onChangeGAs(e) {
    console.log(`checked GAs = ${e.target.checked}`);
    setGASpec(e.target.checked);
  }

  function onChangeKpis(e) {
    console.log(`checked GAs = ${e.target.checked}`);
    setKpiSpec(e.target.checked);
  }

  function onChangeMajor(e) {
    console.log(`checked Major = ${e.target.checked}`);
    setMajor(e.target.checked);
  }
  function onChangeMinor(e) {
    console.log(`checked Minor = ${e.target.checked}`);
    setMinor(e.target.checked);
  }
  function onChangeCode(e) {

    setCode(e.target.value);
  }
  const onFinishFailed = (errorInfo) => { };

  const onFinish = async () => {
    let BodyVersion = {
      id: programSpec_id,
      ploSpec: ploSpec,
      peoSpec: peoSpec,
      GASpec: GASpec,
      major: major,
      minor: minor,
      kpiSpec: kpiSpec,
      code: code,
    };
    console.log(BodyVersion, "BodyVersion");
    const resultAction = await dispatch(VersionningPorgramSpec(BodyVersion));

    if (VersionningPorgramSpec.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Version Added successfully`);
      console.log('resultAction', resultAction)
      dispatch(fetchProgramById(resultAction.payload.id));
      handleClose();
      history.push(`/citrine/programSpec/${resultAction.payload.id}/`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  return (
    <div>
      <Button size="sm" color="info" onClick={handleClickOpen}>
        <span style={{ color: "white", fontSize: "11px" }}>
          {t("Versionning")}
        </span>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">
          {t("Create New Version")}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={3}>
                    <span> Data To Generate</span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item name="ploSpec">
                          <Checkbox onChange={onChangePlos} checked={ploSpec}>PLOs</Checkbox>
                        </Form.Item>
                        <Form.Item name="peoSpec">
                          <Checkbox onChange={onChangePEOs} checked={peoSpec}>PEOs</Checkbox>
                        </Form.Item>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item name="GASpec">
                          <Checkbox onChange={onChangeGAs} checked={GASpec}>GAs</Checkbox>
                        </Form.Item>
                        <Form.Item name="kpiSpec">
                          <Checkbox onChange={onChangeKpis} checked={kpiSpec}>KPIs</Checkbox>
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>

                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={3}>
                    <span> Versionning</span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <Form.Item name="major">
                      <Checkbox onChange={onChangeMajor}>Major</Checkbox>
                    </Form.Item>
                    <Form.Item name="minor">
                      <Checkbox onChange={onChangeMinor}>Minor</Checkbox>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={3}>
                    <span> Code </span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <Form.Item name="code" value={code} initialValue={program.code}>
                      <Input onChange={(event) => {
                        setCode(event.target.value);
                      }} />
                    </Form.Item>

                  </GridItem>
                </GridContainer>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Create")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
