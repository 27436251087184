// @material-ui/core components
import { SchoolRounded } from "@material-ui/icons";
import { Breadcrumb } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import Card from '@material-ui/core/Card';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchPeoById } from "reduxToolkit/features/PEO/PEOsSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";


export default function PEODetails({ match }) {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = match.params;
  const { idPeo } = match.params;

  useEffect(() => {
    dispatch(fetchProgramById(id));
    dispatch(fetchPeoById(idPeo));
  }, []);

  const program = useSelector((state) => state.program.program);
  const Peo = useSelector((state) => state.peo.peo);

  const score = useSelector((state) => state.ploIndicator.ScorePloIndicator);

  const [shownClos, setShownClos] = useState({});

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log("res");
  };
  const toggleClo = (id) => {
    setShownClos((prevShownClos) => ({
      ...prevShownClos,
      [id]: !prevShownClos[id],
    }));
  };

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">" params={id}>
          <Breadcrumb.Item>
            <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/program/${id}`}>
              {program &&
                program.program_degree_name &&
                program.program_degree_name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/program/${id}/PeoListPage/`}>
              {t("Peo List")}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/program/${id}/Peo/${idPeo}`}
              style={{ color: "orange" }}
            >
              {t("Peo Details")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <br></br>
      <Card>
        <CardHeader
          color="info"
          icon
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CardIcon
            color="primary"
            style={{ height: "60px", marginTop: "3px" }}
          >
            <SchoolRounded />
          </CardIcon>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <Link to={`/citrine/programs_assessment`}>
                  <h4
                    style={{
                      color: "#8F6682",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>{program && program.code}</strong>
                  </h4>
                </Link>
                <h4
                  style={{
                    color: "#6C7B9D",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong> {t("University")}</strong>
                </h4>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <div>
                <Link to={`/citrine/programs_assessment`}>
                  <h4
                    style={{
                      color: "#8F6682",
                      fontSize: "14px",
                      height: "13px",
                    }}
                  >
                    <strong>
                      {program &&
                        program.program_degree_name &&
                        program.program_degree_name}
                    </strong>
                  </h4>
                </Link>
                <h4
                  style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                >
                  <strong>
                    {program &&
                      program.university_detail &&
                      program.university_detail.map((el) => el.name)}
                  </strong>
                </h4>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <h4
                  style={{
                    color: "orange",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong> {t("College")}</strong>
                </h4>
                <h4
                  style={{
                    color: "grey",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong> {t("Department")}</strong>
                </h4>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={8}>
              <div>
                <h4
                  style={{ color: "orange", fontSize: "14px", height: "13px" }}
                >
                  <strong>
                    {program &&
                      program.college_detail &&
                      program.college_detail.map((el) => el.name)}
                  </strong>
                </h4>
                <h4 style={{ color: "grey", fontSize: "14px", height: "13px" }}>
                  <strong>
                    {program &&
                      program.department_detail &&
                      program.department_detail.map((el) => el.name)}
                  </strong>
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
      <br></br>

      <GridContainer xs={12} sm={12} md={12}>
        {/* {Peo &&
            Peo.plos &&
            Peo.plos.map((obj, i) => (
              <div key={obj.id} style={{ width: "100%", marginRight: "10px" }}>
                <Card key={i}>
                  <CardBody>
                    <GridContainer xs={12} sm={12} md={12}></GridContainer>
                  </CardBody>
                </Card>
              </div>
            ))} */}

        <Card>
          <CardBody>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={10}>
                PEO : Graduates will continue lifelong learning that builds on
                the introduction
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div
                  style={{
                    width: "170px",
                    height: "45px",
                    fontSize: "16px",
                    borderRadius: "10px",
                    backgroundColor: "orange",
                    paddingTop: "10px",
                    paddingLeft: "20px",
                  }}
                >
                  Score Peo : <storage>3.1</storage>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>PLO</GridItem>
              <GridItem xs={12} sm={12} md={3}>Weight</GridItem>
              <GridItem xs={12} sm={12} md={3}>Score</GridItem>
            </GridContainer>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                Plo 0 : this Plo just a plo1 example !
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>40</GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div
                  type="primary"
                  shape="round"
                  style={{
                    width: "170px",
                    height: "45px",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  <storage>4.2</storage>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
}
