import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(0),
    "& thead th": {
      fontWeight: "600",
      color: "white",
      backgroundColor: "#AF83B7",
    },
    "& tbody td": {
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
}));

export default function useMatrixTable(
  records,
  headCells,
  TheoryheadCells,
  LabheadCells,
  filterFn
) {
  const classes = useStyles();

  const pages = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = (props) => (
    <Table className={classes.table}>{props.children}</Table>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    return (
     
      <TableHead>
   
   {headCells.map((headCell) => (
              <TableCell key={headCell.id} style={{ color:'white',borderRight: "1px solid lightgray",backgroundColor:"#AF83B7" }}>
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <p> {headCell.label}</p>
                )}
              </TableCell>
            ))}


{TheoryheadCells.map((headCell) => (
    
                <TableCell key={headCell.id} style={{color:'white', textAlign: "center",borderRight: "1px solid lightgray",color:'white'}}>
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <p style={{height:"47px"}}> {headCell.label}</p>
                  )}
                </TableCell>
              ))}

{LabheadCells.map((headCell) => (
              <TableCell key={headCell.id} style={{color:'white', borderRight: "1px solid lightgray",backgroundColor:"#AF83B7"}}>
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <p> {headCell.label}</p>
                )}
              </TableCell>
            ))}




































{/* 


        <div style={{ display: "flex",height:'115px',border:"1px solid red" }}>


          <div style={{ borderRight: "1px solid lightgray" ,height: "114px",backgroundColor:"#AF83B7"}}>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} style={{ color:'white',height: "114px",borderLeft: "1px solid lightgray",backgroundColor:"#AF83B7" }}>
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <p> {headCell.label}</p>
                )}
              </TableCell>
            ))}
          </div>

          <div style={{ borderRight: "1px solid lightgray"}}>
          
              <div style={{ textAlign: "center",backgroundColor:"#AF83B7",borderBottom:"1px solid lightgray",color:"white"}}> Theory</div>

              {TheoryheadCells.map((headCell) => (
                <TableCell key={headCell.id} style={{color:'white', textAlign: "center",borderRight: "1px solid lightgray",color:'white'}}>
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <p style={{height:"47px"}}> {headCell.label}</p>
                  )}
                </TableCell>
              ))}

        
          </div>

          <div style={{ height: "91px",backgroundColor:"#AF83B7" }}>
          <div style={{ textAlign: "center", height: "23px",backgroundColor:"#AF83B7",borderBottom:"1px solid lightgray",color:"white"}}> Lab</div>
            {LabheadCells.map((headCell) => (
              <TableCell key={headCell.id} style={{color:'white', borderRight: "1px solid lightgray",height: "91px",backgroundColor:"#AF83B7"}}>
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <p> {headCell.label}</p>
                )}
              </TableCell>
            ))}
          </div>
        </div> */}

      </TableHead>
      
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  // function stableSort(array, comparator) {
  //     const stabilizedThis = array.map((el, index) => [el, index]);
  //     stabilizedThis.sort((a, b) => {
  //         const order = comparator(a[0], b[0]);
  //         if (order !== 0) return order;
  //         return a[1] - b[1];
  //     });
  //     return stabilizedThis.map((el) => el[0]);
  // }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    // return stableSort(filterFn.fn(records), getComparator(order, orderBy))
    //     .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
