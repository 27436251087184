import { useState } from 'react'
import { Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom'
// antdesign components
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
// style for this view
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import { infoColor } from 'assets/jss/material-dashboard-pro-react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchPrograms } from 'reduxToolkit/features/program/ProgramsSlice.js'
import { addNewResearchOutPut } from 'reduxToolkit/features/ResearchAndDevelopmentActivity/ResearchAndInnovationOutputsSlice.js'
import { MediaBaseUrl } from 'assets/utils/accessToken'

import {
  fetchStaff,
  fetchStaffById,
  fetchStudents,
} from '../../../../../reduxToolkit/features/user/UserSlice'
import {
  fetchCurrentSemester,
  fetchSemesters,
} from 'reduxToolkit/features/semester/SemestersSlice'
import { fetchColleges } from '../../../../../reduxToolkit/features/college/CollegesSlice'
import { fetchDepartments } from '../../../../../reduxToolkit/features/department/DepartmentsSlice'
import { fetchPublisherConfigs } from '../../../../../reduxToolkit/features/Publisher/publisherSlice'
import { fetchResearchDirectionConfigs } from '../../../../../reduxToolkit/features/ResearchDirection/researchDirectionSlice'
import ErrorModal from '../../../../Components/ErrorModal'
import { fetchIndexConfigs } from '../../.././../../reduxToolkit/features/indexResearchConfig/indexResearchConfigSlice'
import { fetchInternationalResearchConfigs } from '../../.././../../reduxToolkit/features/InternationalSearchConfig/internationalResearchConfigSlice'
import { fetchAllResearchAndInnovationActivities } from '../../.././../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice'
import { fetchUtilizedConfigs } from '../../.././../../reduxToolkit/features/UtilizedRsearch/utilizedResearchSlice'

export default function CreateResearchAndInnovationOutputs({ match }) {
  const { t } = useTranslation()
  let history = useHistory()
  let { id } = useParams()
  const [form] = Form.useForm()

  //console.log('id', id)

  const [type, setType] = React.useState('')
  const [levelType, setLevelType] = React.useState('')
  const [peer_reviewed, setPeer_reviewed] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const [students_involved, setStudents_involved] = React.useState(false)
  const [semester_id, setSemester_id] = useState()
  const [academicYear_id, setAcademicYear_id] = useState()

  const [
    collaboration_with_researchers,
    setCollaboration_with_researchers,
  ] = React.useState(false)

  const [
    collaboration_with_students,
    setCollaboration_with_students,
  ] = React.useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCurrentSemester(localStorage.getItem('userId')))
      .unwrap()
      .then((semester) => {
        setAcademicYear_id(semester.academicYear_id)
        setSemester_id(semester.semester_id)
        dispatch(fetchPrograms(semester.semester_id))
      })
      .catch((err) => console.log(err))
    dispatch(fetchDepartments())
    dispatch(fetchColleges())
    //const semester_id = localStorage.getItem('semester_id')
    //dispatch(fetchPrograms(semester_id))

    dispatch(fetchStaffById(id))
    dispatch(fetchStaff())
    dispatch(fetchStudents())
    dispatch(fetchPublisherConfigs())
    dispatch(fetchResearchDirectionConfigs())
    dispatch(fetchAllResearchAndInnovationActivities())
      .unwrap()
      .then((res) => {
        console.log(res)
      })
      .catch((err) => console.log(err))
    dispatch(fetchIndexConfigs())
    dispatch(fetchUtilizedConfigs())
    dispatch(fetchInternationalResearchConfigs())
  }, [dispatch])

  const users = useSelector((state) => state.user.staffList)
  const students = useSelector((state) => state.user.students)
  //console.log(students)
  const publishers = useSelector(
    (state) => state.PublisherConfig.PublisherConfigs,
  )

  const ResearchDirections = useSelector(
    (state) => state.ResearchDirection.researchDirectionConfigs,
  )

  const internationalResearch = useSelector(
    (state) => state.internationalConfig.InternationalResearchConfigs,
  )
  // console.log('internationalResearch', internationalResearch)
  const indexResearch = useSelector(
    (state) => state.indexConfig.IndexResearchConfigs,
  )
  const utilizedResearch = useSelector(
    (state) => state.utilizedConfig.UtilizedConfigs,
  )

  const AllResearchAndInnovationActivities = useSelector(
    (state) =>
      state.researchAndInnovationActivities.AllResearchAndInnovationActivities,
  )
  console.log(AllResearchAndInnovationActivities)

  const person = useSelector((state) => state.user.staff)
  const persons = useSelector((state) => state.user.staffList)
  const currentSemester = useSelector((state) => state.semester.currentSemester)
  const programs = useSelector((state) => state.program.programs)

  //const authorsList = useSelector((state) => state.authorityConfig.AuthorityConfigsByType)
  const authorsList = useSelector(
    (state) => state.authorityConfig.AuthorityConfigs,
  )

  const configAcoordingtoType = useSelector(
    (state) => state.authorityConfig.AuthorityConfigsByType,
  )
  const colleges = useSelector((state) => state.college.colleges)
  const departments = useSelector((state) => state.department.departments)
  const courses = useSelector((state) => state.course.courses)

  //console.log('authorsList', authorsList)

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [start_date, setStartDate] = React.useState('')
  const [end_date, setEndDate] = React.useState('')
  const [authors_ids, setAuthors_ids] = React.useState([parseInt(id)])
  const [faculty_id, setFaculty_id] = React.useState(id)

  const [date_of_registration, setDate_of_registration] = React.useState(
    '2002-10-10',
  )
  const [main_auther_id, SetMain_auther] = React.useState('')

  const [expiration_date, setExpiration_date] = React.useState('2002-10-10')

  const handleChange = (value) => {
    console.log('value', value)
    if (value.length > 0) {
      SetMain_auther(value[0])
    }
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    message.error('Submit failed!')
  }

  function onStartDateChange(value, dateString) {
    setStartDate(dateString)
  }
  function onEndDateChange(value, dateString) {
    setEndDate(dateString)
  }

  //let semester_id = localStorage.getItem('semester_id')
  //console.log(localStorage.getItem('semester_id'))
  // let academicYear_id = currentSemester.academicYear_id;
  //let academicYear_id = 1

  const onFinish = async (values) => {
    console.log(academicYear_id, semester_id)
    console.log('Received values of form: ', values)

    const toAddActivity = {
      ...values,
      type,
      date_of_registration,
      semester_id,
      academicYear_id,
      peer_reviewed,
      checked,
      students_involved,
      collaboration_with_researchers,
      collaboration_with_students,
      authors_ids,
      expiration_date,
      main_auther_id,
      faculty_id,
    }
    console.log('toAddActivity: ', toAddActivity)

    const resultAction = await dispatch(addNewResearchOutPut(toAddActivity))

    if (addNewResearchOutPut.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon('success', `Added successfully`)
      history.push(
        `/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivitiesOutputs`,
      )
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log('resultAction.payload from component', resultAction.payload)
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        console.log('resultAction.error  from component', resultAction.error)
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  function onHandleCommunityType(communityString) {
    console.log(communityString)
    setType(communityString)
  }

  function onChangeResearchersCollaborationCheckbox(e) {
    console.log(`checked = ${e.target.checked}`)
    setCollaboration_with_researchers(e.target.checked)
  }

  function onChangeStudentsCollaborationCheckbox(e) {
    console.log(`checked = ${e.target.checked}`)
    setCollaboration_with_students(e.target.checked)
  }

  function onChangeStudents_involvedCheckbox(e) {
    console.log(`checked = ${e.target.checked}`)
    setStudents_involved(e.target.checked)
  }

  function onChangeReviewedCheckbox(e) {
    console.log(`checked = ${e.target.checked}`)
    setPeer_reviewed(!peer_reviewed)
    console.log(`peer_reviewed `, peer_reviewed)
  }

  function onChange_Checked_Checkbox(e) {
    console.log(`checked = ${e.target.checked}`)
    setChecked(e.target.checked)
  }

  function onChangeRegistrationDate(date, dateString) {
    console.log(date, dateString)
    setDate_of_registration(dateString)
  }

  function onChangeExpirationDate(date, dateString) {
    console.log(date, dateString)
    setExpiration_date(dateString)
  }
  const { Option } = Select

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t('Faculty')}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t('Teaching Staff')}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${
                person && person.person_details[0].last_name
              }`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`}
            >
              {t('Research and innovation Activities')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Add a new research and innovation activity
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('College')}
                        name="college_id"
                        rules={[
                          {
                            required: true,
                            message: 'Please select the College',
                          },
                        ]}
                      >
                        <Select
                          showArrow
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {colleges &&
                            colleges.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Department')}
                        name="department_id"
                        rules={[
                          {
                            required: true,
                            message: 'Please select the Department',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          showArrow
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {departments &&
                            departments.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  </GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Type')}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the type',
                        },
                      ]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        size={200}
                        showArrow
                        onChange={onHandleCommunityType}
                      >
                        <Option value="Book">Book</Option>
                        <Option value="Book Chapter">Book Chapter</Option>
                        <Option value="Conference proceeding">
                          Conference proceeding
                        </Option>
                        <Option value="Journal Articles">
                          Journal Articles
                        </Option>
                        <Option value="Patents and IP">Patent & IP</Option>
                        <Option value="Citations">Citations</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  {type == 'Book' ? (
                    <p style={{ width: '100%' }}>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Title')}
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Book Title')}
                            name="book_title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Book Title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('isbn')}
                            name="isbn"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the ISBN',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("ISI/ABDC")}
                            name="i_s_i"
                            rules={[
                              {
                                required: true,
                                message: "Please select the ISI/ABDC",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_researchers">
                            <Checkbox
                              onChange={
                                onChangeResearchersCollaborationCheckbox
                              }
                              checked={collaboration_with_researchers}
                            >
                              Collaboration With Researchers
                            </Checkbox>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_students">
                            <Checkbox
                              onChange={onChangeStudentsCollaborationCheckbox}
                              checked={collaboration_with_students}
                            >
                              Collaboration With Researchers Students
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      {collaboration_with_students ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('UG Students')}
                              name="ug_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the UG Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                      {student.student_details.first_name} {student.student_details.last_name}

                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('GS Students')}
                              name="gs_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the GS Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                      {student.student_details.first_name} {student.student_details.last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      {collaboration_with_researchers ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (Internal)')}
                              name="researchers_internal"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (Internal)',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {/* {users &&
                                  users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                      {user &&
                                        user.person_details &&
                                        user.person_details[0].first_name}{' '}
                                      { }{' '}
                                      {user.person_details &&
                                        user.person_details[0].last_name}
                                    </Option>
                                  ))} */}
                                {users &&
                                  users.map((person) => (
                                    <Option value={person && person.id}>
                                      {!person?.person_details[0]?.avatar ? (
                                        <span
                                          style={{
                                            backgroundColor:
                                              'rgb(143, 102, 130)',
                                            width: '23px',
                                            height: '23px',
                                            color: 'white',
                                            borderRadius: '50%',
                                            paddingLeft: '7px',
                                            marginRight: '15px',
                                            display: 'inline-block',
                                            lineHeight: '23px',
                                          }}
                                        >
                                          {person?.person_details[0]?.first_name
                                            .charAt(0)
                                            .toUpperCase() + '     '}
                                        </span>
                                      ) : (
                                        <img
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                          src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                        />
                                      )}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].first_name
                                      }`}{' '}
                                      {`${''}`}{' '}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].last_name
                                      }`}
                                    </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (External)')}
                              name="researchers_external"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (External)',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (Internal)')}
                            name="authors_internal"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (Internal)',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {users &&
                                  users.map((person) => (
                                    <Option value={person && person.id}>
                                      {!person?.person_details[0]?.avatar ? (
                                        <span
                                          style={{
                                            backgroundColor:
                                              'rgb(143, 102, 130)',
                                            width: '23px',
                                            height: '23px',
                                            color: 'white',
                                            borderRadius: '50%',
                                            paddingLeft: '7px',
                                            marginRight: '15px',
                                            display: 'inline-block',
                                            lineHeight: '23px',
                                          }}
                                        >
                                          {person?.person_details[0]?.first_name
                                            .charAt(0)
                                            .toUpperCase() + '     '}
                                        </span>
                                      ) : (
                                        <img
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                          src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                        />
                                      )}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].first_name
                                      }`}{' '}
                                      {`${''}`}{' '}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].last_name
                                      }`}
                                    </Option>
                                ))}
                              {/* {users &&
                                users.map((user) => (
                                  <Option key={user.id} value={user.id}>
                                    {user &&
                                      user.person_details &&
                                      user.person_details[0].first_name}{' '}
                                    {}{' '}
                                    {user.person_details &&
                                      user.person_details[0].last_name}
                                  </Option>
                                ))} */}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (External)')}
                            name="authors_external"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (External)',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Publisher')}
                            name="publisher"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Publisher',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {publishers &&
                                publishers.map((publisher) => (
                                  <Option
                                    key={publisher.id}
                                    value={publisher.id}
                                  >
                                    {publisher.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Linked research activity ')}
                            name="linked_research_activity"
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {AllResearchAndInnovationActivities &&
                                AllResearchAndInnovationActivities.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.title}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Citations')}
                            name="citations"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Citations',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Index')}
                            name="indexResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Index',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {indexResearch &&
                                indexResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Utilized research labs')}
                            name="utilizedResearchLabsConfig_id"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Utilized research labs',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {utilizedResearch &&
                                utilizedResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("SCOPUS ")}
                            name="scopus"
                            rules={[
                              {
                                required: true,
                                message: "Please select the SCOPUS",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Hardware')}
                            name="harware"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Hardware',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Software')}
                            name="software"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Software',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('International Research')}
                            name="internationalResearchConfig_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the',
                              },
                            ]}
                          >
                            <Select
                              //mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {internationalResearch &&
                                internationalResearch.map(
                                  (ResearchDirection) => (
                                    <Option
                                      key={ResearchDirection.id}
                                      value={ResearchDirection.id}
                                    >
                                      {ResearchDirection.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            name="peer_reviewed"
                            onChange={onChangeReviewedCheckbox}
                          >
                            <Checkbox
                              onChange={onChangeReviewedCheckbox}
                              checked={peer_reviewed}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the  Peer Reviewed',
                                },
                              ]}
                            >
                              Peer Reviewed
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ) : (
                    <p></p>
                  )}

                  {type == 'Book Chapter' ? (
                    <p style={{ width: '100%' }}>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Title')}
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Book Title')}
                            name="book_title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Book Title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Book Chapter Title')}
                            name="book_chapter_title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Alumnae')}
                            name="alumnae"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Book Title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('isbn')}
                            name="isbn"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the ISBN',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("ISI/ABDC")}
                            name="i_s_i"
                            rules={[
                              {
                                required: true,
                                message: "Please select the ISI/ABDC",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_researchers">
                            <Checkbox
                              onChange={
                                onChangeResearchersCollaborationCheckbox
                              }
                              checked={collaboration_with_researchers}
                            >
                              Collaboration With Researchers
                            </Checkbox>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_students">
                            <Checkbox
                              onChange={onChangeStudentsCollaborationCheckbox}
                              checked={collaboration_with_students}
                            >
                              Collaboration With Researchers Students
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      {collaboration_with_students ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('UG Students')}
                              name="ug_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the UG Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                      {student.student_details.first_name} {student.student_details.last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('GS Students')}
                              name="gs_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the GS Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                      {student.student_details.first_name} {student.student_details.last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      {collaboration_with_researchers ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (Internal)')}
                              name="researchers_internal"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (Internal)',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {users &&
                                  users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                      {user &&
                                        user.person_details &&
                                        user.person_details[0].first_name}{' '}
                                      {}{' '}
                                      {user.person_details &&
                                        user.person_details[0].last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (External)')}
                              name="researchers_external"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (External)',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (Internal)')}
                            name="authors_internal"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (Internal)',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {/* {users &&
                                users.map((user) => (
                                  <Option key={user.id} value={user.id}>
                                    {user &&
                                      user.person_details &&
                                      user.person_details[0].first_name}{' '}
                                    {}{' '}
                                    {user.person_details &&
                                      user.person_details[0].last_name}
                                  </Option>
                                ))} */}
                                {users &&
                                  users.map((person) => (
                                    <Option value={person && person.id}>
                                      {!person?.person_details[0]?.avatar ? (
                                        <span
                                          style={{
                                            backgroundColor:
                                              'rgb(143, 102, 130)',
                                            width: '23px',
                                            height: '23px',
                                            color: 'white',
                                            borderRadius: '50%',
                                            paddingLeft: '7px',
                                            marginRight: '15px',
                                            display: 'inline-block',
                                            lineHeight: '23px',
                                          }}
                                        >
                                          {person?.person_details[0]?.first_name
                                            .charAt(0)
                                            .toUpperCase() + '     '}
                                        </span>
                                      ) : (
                                        <img
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                          src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                        />
                                      )}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].first_name
                                      }`}{' '}
                                      {`${''}`}{' '}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].last_name
                                      }`}
                                    </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (External)')}
                            name="authors_external"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (External)',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Publisher')}
                            name="publisher"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Publisher',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {publishers &&
                                publishers.map((publisher) => (
                                  <Option
                                    key={publisher.id}
                                    value={publisher.id}
                                  >
                                    {publisher.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Linked research activity ')}
                            name="linked_research_activity"
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {AllResearchAndInnovationActivities &&
                                AllResearchAndInnovationActivities.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.title}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Citations')}
                            name="citations"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Citations',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Index')}
                            name="indexResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Index',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {indexResearch &&
                                indexResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Utilized research labs')}
                            name="utilizedResearchLabsConfig_id"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Utilized research labs',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {utilizedResearch &&
                                utilizedResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("SCOPUS ")}
                            name="scopus"
                            rules={[
                              {
                                required: true,
                                message: "Please select the SCOPUS",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Hardware')}
                            name="harware"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Hardware',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Software')}
                            name="software"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Software',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('International Research')}
                            name="internationalResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the',
                              },
                            ]}
                          >
                            <Select
                              //mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {internationalResearch &&
                                internationalResearch.map(
                                  (ResearchDirection) => (
                                    <Option
                                      key={ResearchDirection.id}
                                      value={ResearchDirection.id}
                                    >
                                      {ResearchDirection.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            name="peer_reviewed"
                            valuePropName="checked"
                          >
                            <Checkbox
                              // onChange={onChangeReviewedCheckbox}
                              // checked={peer_reviewed}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the  Peer Reviewed',
                                },
                              ]}
                            >
                              Peer Reviewed
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ) : (
                    <p></p>
                  )}

                  {type == 'Conference proceeding' ? (
                    <p style={{ width: '100%' }}>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Conference Title')}
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the conference_title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Conference Paper Title')}
                            name="conference_paper_title"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select  conference paper title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Location')}
                            name="location"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the location',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Conference')}
                            name="conference"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the conference',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Doi')}
                            name="doi"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the doi',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("ISI/ABDC")}
                            name="i_s_i"
                            rules={[
                              {
                                required: true,
                                message: "Please select the ISI/ABDC",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_researchers">
                            <Checkbox
                              onChange={
                                onChangeResearchersCollaborationCheckbox
                              }
                              checked={collaboration_with_researchers}
                            >
                              Collaboration With Researchers
                            </Checkbox>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_students">
                            <Checkbox
                              onChange={onChangeStudentsCollaborationCheckbox}
                              checked={collaboration_with_students}
                            >
                              Collaboration With Researchers Students
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      {collaboration_with_students ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('UG Students')}
                              name="ug_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the UG Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                      {student.student_details.first_name} {student.student_details.last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('MSEE')}
                              name="msee_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the GS Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                                                            {student.student_details.first_name} {student.student_details.last_name}

                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      {collaboration_with_researchers ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (Internal)')}
                              name="researchers_internal"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (Internal)',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {users &&
                                  users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                      {user &&
                                        user.person_details &&
                                        user.person_details[0].first_name}{' '}
                                      {}{' '}
                                      {user.person_details &&
                                        user.person_details[0].last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (External)')}
                              name="researchers_external"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (External)',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('ORCID Link')}
                            name="link"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Publisher',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Linked research activity ')}
                            name="linked_research_activity"
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {AllResearchAndInnovationActivities &&
                                AllResearchAndInnovationActivities.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.title}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Conference Page Number')}
                            name="conference_page _number"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Conference Page Number',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Alumnae')}
                            name="alumnae"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Alumnae',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Utilized research labs')}
                            name="utilizedResearchLabsConfig_id"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Utilized research labs',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {utilizedResearch &&
                                utilizedResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("SCOPUS ")}
                            name="scopus"
                            rules={[
                              {
                                required: true,
                                message: "Please select the SCOPUS",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Hardware')}
                            name="harware"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Hardware',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Software')}
                            name="software"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Software',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t(
                              'Conference Proceeding First Author (internal)',
                            )}
                            name="authors_internal"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Conference Proceeding First Author',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {students &&
                                students.map(({ id, student_details }) => (
                                  <Option key={id} value={id}>
                                    {student_details.first_name}{' '}
                                    {student_details.last_name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t(
                              'Conference Proceeding First Author (external)',
                            )}
                            name="authors_external"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Author',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Citations')}
                            name="citations"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Citation',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('International Research')}
                            name="internationalResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the',
                              },
                            ]}
                          >
                            <Select
                              //mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {internationalResearch &&
                                internationalResearch.map(
                                  (ResearchDirection) => (
                                    <Option
                                      key={ResearchDirection.id}
                                      value={ResearchDirection.id}
                                    >
                                      {ResearchDirection.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_researchers">
                            <Checkbox
                              onChange={
                                onChangeResearchersCollaborationCheckbox
                              }
                              checked={collaboration_with_researchers}
                            >
                              Collaboration With Researchers
                            </Checkbox>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="students_involved">
                            <Checkbox
                              onChange={onChangeStudents_involvedCheckbox}
                              checked={students_involved}
                            >
                              Students/Alumni involved
                            </Checkbox>
                          </Form.Item>
                        </GridItem> */}

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            name="peer_reviewed"
                            valuePropName="checked"
                          >
                            <Checkbox
                              // onChange={onChangeReviewedCheckbox}
                              checked={peer_reviewed}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the  Peer Reviewed',
                                },
                              ]}
                            >
                              {' '}
                              Peer Reviewed
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ) : (
                    <p></p>
                  )}

                  {type == 'Journal Articles' ? (
                    <p style={{ width: '100%' }}>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Title')}
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Journal Title')}
                            name="journal_title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select journal title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Journal Paper Title')}
                            name="journal_paper_title"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the journal_paper_title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Journal Paper Volume')}
                            name="journal_paper_volume"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Journal Paper Volume',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (Internal)')}
                            name="authors_internal"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (Internal)',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {/* {users &&
                                users.map((user) => (
                                  <Option key={user.id} value={user.id}>
                                    {user &&
                                      user.person_details &&
                                      user.person_details[0].first_name}{' '}
                                    {}{' '}
                                    {user.person_details &&
                                      user.person_details[0].last_name}
                                  </Option>
                                ))} */}
                                {users &&
                                  users.map((person) => (
                                    <Option value={person && person.id}>
                                      {!person?.person_details[0]?.avatar ? (
                                        <span
                                          style={{
                                            backgroundColor:
                                              'rgb(143, 102, 130)',
                                            width: '23px',
                                            height: '23px',
                                            color: 'white',
                                            borderRadius: '50%',
                                            paddingLeft: '7px',
                                            marginRight: '15px',
                                            display: 'inline-block',
                                            lineHeight: '23px',
                                          }}
                                        >
                                          {person?.person_details[0]?.first_name
                                            .charAt(0)
                                            .toUpperCase() + '     '}
                                        </span>
                                      ) : (
                                        <img
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                          src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                        />
                                      )}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].first_name
                                      }`}{' '}
                                      {`${''}`}{' '}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].last_name
                                      }`}
                                    </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (External)')}
                            name="authors_external"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (External)',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_researchers">
                            <Checkbox
                              onChange={
                                onChangeResearchersCollaborationCheckbox
                              }
                              checked={collaboration_with_researchers}
                            >
                              Collaboration With Researchers
                            </Checkbox>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_students">
                            <Checkbox
                              onChange={onChangeStudentsCollaborationCheckbox}
                              checked={collaboration_with_students}
                            >
                              Collaboration With Researchers Students
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      {collaboration_with_students ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('UG Students')}
                              name="ug_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the UG Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                                                            {student.student_details.first_name} {student.student_details.last_name}

                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('GS Students')}
                              name="gs_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the GS Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                                                            {student.student_details.first_name} {student.student_details.last_name}

                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      {collaboration_with_researchers ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (Internal)')}
                              name="researchers_internal"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (Internal)',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {users &&
                                  users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                      {user &&
                                        user.person_details &&
                                        user.person_details[0].first_name}{' '}
                                      {}{' '}
                                      {user.person_details &&
                                        user.person_details[0].last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (External)')}
                              name="researchers_external"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (External)',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Journal Issue')}
                            name="journal_issue"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the journal_issue',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('PP to (pages)')}
                            name="pp_to_pages"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the pp_to_pages',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("ISI/ABDC")}
                            name="i_s_i"
                            rules={[
                              {
                                required: true,
                                message: "Please select theISI/ABDC",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("Scopus")}
                            name="scopus"
                            rules={[
                              {
                                required: true,
                                message: "Please select the Scopus",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('ORCID Link')}
                            name="link"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the link',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Linked research activity ')}
                            name="linked_research_activity"
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {AllResearchAndInnovationActivities &&
                                AllResearchAndInnovationActivities.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.title}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Citations')}
                            name="citations"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Citations',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('INDEX')}
                            name="indexResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the index',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {indexResearch &&
                                indexResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('DOI')}
                            name="doi"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the DOI',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Alumnae')}
                            name="alumnae"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Alumnae',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Utilized research labs')}
                            name="utilizedResearchLabsConfig_id"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Utilized research labs',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {utilizedResearch &&
                                utilizedResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Hardware')}
                            name="harware"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Hardware',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Software')}
                            name="software"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Software',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('International Research')}
                            name="internationalResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the',
                              },
                            ]}
                          >
                            <Select
                              //mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {internationalResearch &&
                                internationalResearch.map(
                                  (ResearchDirection) => (
                                    <Option
                                      key={ResearchDirection.id}
                                      value={ResearchDirection.id}
                                    >
                                      {ResearchDirection.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ) : (
                    <p></p>
                  )}

                  {type == 'Patents and IP' ? (
                    <p style={{ width: '100%' }}>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Project title')}
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Patent/IP Title')}
                            name="patent_ip_title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select patent_ip_title',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Title of the research project')}
                            name="title_of_research_project"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the title_of_research_project',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Patent Office')}
                            name="patent_office"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Patent Office',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Funded')}
                            name="funded"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Funded',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Funder name')}
                            name="funder_name"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Funder name',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (Internal)')}
                            name="authors_internal"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (Internal)',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {/* {users &&
                                users.map((user) => (
                                  <Option key={user.id} value={user.id}>
                                    {user &&
                                      user.person_details &&
                                      user.person_details[0].first_name}{' '}
                                    {}{' '}
                                    {user.person_details &&
                                      user.person_details[0].last_name}
                                  </Option>
                                ))} */}
                                {users &&
                                  users.map((person) => (
                                    <Option value={person && person.id}>
                                      {!person?.person_details[0]?.avatar ? (
                                        <span
                                          style={{
                                            backgroundColor:
                                              'rgb(143, 102, 130)',
                                            width: '23px',
                                            height: '23px',
                                            color: 'white',
                                            borderRadius: '50%',
                                            paddingLeft: '7px',
                                            marginRight: '15px',
                                            display: 'inline-block',
                                            lineHeight: '23px',
                                          }}
                                        >
                                          {person?.person_details[0]?.first_name
                                            .charAt(0)
                                            .toUpperCase() + '     '}
                                        </span>
                                      ) : (
                                        <img
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                          src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                        />
                                      )}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].first_name
                                      }`}{' '}
                                      {`${''}`}{' '}
                                      {`${
                                        person &&
                                        person.person_details &&
                                        person.person_details[0].last_name
                                      }`}
                                    </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Authors (External)')}
                            name="authors_external"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Authors (External)',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Potential commercialization')}
                            name="potential_commercialization"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Potential commercialization',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Funding agency')}
                            name="funding_agency"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Funding agency',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("ISI/ABDC")}
                            name="i_s_i"
                            rules={[
                              {
                                required: true,
                                message: "Please select theISI/ABDC",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t("Scopus")}
                            name="scopus"
                            rules={[
                              {
                                required: true,
                                message: "Please select the Scopus",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem> */}
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_researchers">
                            <Checkbox
                              onChange={
                                onChangeResearchersCollaborationCheckbox
                              }
                              checked={collaboration_with_researchers}
                            >
                              Collaboration With Researchers
                            </Checkbox>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="collaboration_with_students">
                            <Checkbox
                              onChange={onChangeStudentsCollaborationCheckbox}
                              checked={collaboration_with_students}
                            >
                              Collaboration With Researchers Students
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      {collaboration_with_students ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('UG Students')}
                              name="ug_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the UG Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                                                           {student.student_details.first_name} {student.student_details.last_name}

                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('GS Students')}
                              name="gs_students_ids"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select the GS Students',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                
                                {students &&
                                  students.map((student) => (
                                    <Option key={student.id} value={student.id}>
                                                                            {student.student_details.first_name} {student.student_details.last_name}

                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      {collaboration_with_researchers ? (
                        <GridContainer xs={12} sm={12} md={12}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (Internal)')}
                              name="researchers_internal"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (Internal)',
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                showArrow
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {users &&
                                  users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                      {user &&
                                        user.person_details &&
                                        user.person_details[0].first_name}{' '}
                                      {}{' '}
                                      {user.person_details &&
                                        user.person_details[0].last_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Researchers (External)')}
                              name="researchers_external"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please select the Researchers (External)',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <p></p>
                      )}

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('ORCID Link')}
                            name="link"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the link',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Linked research activity ')}
                            name="linked_research_activity"
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {AllResearchAndInnovationActivities &&
                                AllResearchAndInnovationActivities.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.title}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('DOI')}
                            name="doi"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the DOI',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('INDEX')}
                            name="indexResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the index',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {indexResearch &&
                                indexResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Utilized research labs')}
                            name="utilizedResearchLabsConfig_id"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Utilized research labs',
                              },
                            ]}
                          >
                            <Select
                              // mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {utilizedResearch &&
                                utilizedResearch.map(
                                  (ResearchAndInnovationActivity) => (
                                    <Option
                                      key={ResearchAndInnovationActivity.id}
                                      value={ResearchAndInnovationActivity.id}
                                    >
                                      {ResearchAndInnovationActivity.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Field')}
                            name="field"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the field',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Hardware')}
                            name="harware"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Hardware',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Software')}
                            name="software"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Software',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Registration number')}
                            name="registration_number"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the registration_number',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Date of registration')}
                            name="date_of_registration"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Date of registration',
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={onChangeRegistrationDate}
                              style={{ zIndex: '10000  !important' }}
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode
                              }}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="ksa_directions"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the ksa_directions',
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {ResearchDirections &&
                                ResearchDirections.map((ResearchDirection) => (
                                  <Option
                                    key={ResearchDirection.id}
                                    value={ResearchDirection.id}
                                  >
                                    {ResearchDirection.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('International Research')}
                            name="internationalResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the',
                              },
                            ]}
                          >
                            <Select
                              //mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {internationalResearch &&
                                internationalResearch.map(
                                  (ResearchDirection) => (
                                    <Option
                                      key={ResearchDirection.id}
                                      value={ResearchDirection.id}
                                    >
                                      {ResearchDirection.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Expiration Date')}
                            name="expiration_date"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Date of expiration date',
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={onChangeExpirationDate}
                              style={{ zIndex: '10000  !important' }}
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode
                              }}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ) : (
                    <p></p>
                  )}

                  {type == 'Citations' ? (
                    <p style={{ width: '100%' }}>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Name')}
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Name',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('PT/FT')}
                            name="p_t_f_t"
                            rules={[
                              {
                                required: true,
                                message: 'Please select PT/FT',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value={'Full-time'}>Full-time </Option>
                              <Option value={'Part-time'}>Part-time </Option>
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Google Scholar Page')}
                            name="effat_google_scholar_page"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the google_scholar_page',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Google Citations')}
                            name="google_citations"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Google Citations',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Scopus Page ')}
                            name="effat_scopus_page "
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Scopus Page ',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Scopus Citations')}
                            name="sopus_citations"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the Scopus Citations',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Web of Science Page')}
                            name="effat_web_of_science_page "
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the web_of_science_page ',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Web of Science Citations')}
                            name="web_of_science_citations"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please select the Web of Science Citations',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('International Research')}
                            name="internationalResearch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select the',
                              },
                            ]}
                          >
                            <Select
                              //mode="multiple"
                              showSearch
                              showArrow
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {internationalResearch &&
                                internationalResearch.map(
                                  (ResearchDirection) => (
                                    <Option
                                      key={ResearchDirection.id}
                                      value={ResearchDirection.id}
                                    >
                                      {ResearchDirection.name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item name="checked">
                            <Checkbox
                              onChange={onChange_Checked_Checkbox}
                              checked={checked}
                            >
                              Checked
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ) : (
                    <p></p>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
