import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, notification, Select } from 'antd';
import Button from 'components/CustomButtons/Button';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AffectCoursesToProgram, fetchNotAffectedCourses, fetchProgramById, fetchProgramLevels } from '../../../../reduxToolkit/features/program/ProgramSpecSlice';
import ErrorModal from "../../../Components/ErrorModal";




export default function AffectCoursesToProgramModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()


    useEffect(() => {
        // if (programsStatus === 'idle') {
        dispatch(fetchNotAffectedCourses(props.programId))
        //}
        dispatch(fetchProgramLevels(props.programId))
    }, [
        //programsStatus,
        dispatch])

    const courses = useSelector(state => state.programSpec.courses)
    const programLevels = useSelector(state => state.programSpec.programLevels)
    
    console.log("not affected courses", courses)
    console.log("programLevels", programLevels)

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };

    const onFinish = async (values) => {
        const resultAction = await dispatch(AffectCoursesToProgram({ id: props.programId, courseSpec_ids: values.courseSpec_ids, level_number: values.level_id }))

        console.log("values", values)

        if (AffectCoursesToProgram.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            console.log('resultAction', resultAction)
            console.log('resultAction.payload', resultAction.payload)

            openNotificationWithIcon('success', `Affected`)
            props.handleClose()
            dispatch(fetchNotAffectedCourses(props.programId))
            dispatch(fetchProgramById(props.programId))
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                props.handleClose()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                props.handleClose()
            }
        }

    }


    const { Option } = Select;

    return (
        <div>

            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
                maxWidth="sm">
                <DialogTitle id="form-dialog-title">{t('Add Course')}</DialogTitle>
                <DialogContent>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        scrollToFirstError
                    >

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("Level")}
                                    name="level_id"
                                    rules={[{ required: true, message: "Please select a level!" }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        showArrow="true"
                                        dropdownStyle={{ zIndex: 10000 }}
                                        placeholder={t('select level')}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {programLevels && programLevels.map(l => <Option value={l.id}>{`${l.level}`}</Option>)}
                                    </Select>
                                </Form.Item>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("Courses")}
                                    name="courseSpec_ids"
                                    rules={[{ required: true, message: "Please select courses!" }]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        showArrow="true"
                                        dropdownStyle={{ zIndex: 10000 }}
                                        placeholder={t('select courses')}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {courses && courses.map(course => <Option value={course.id}>{`${course.title} /   Version  ${course.version}`}</Option>)}
                                    </Select>
                                </Form.Item>
                            </GridItem>

                        </GridContainer>

                        {/* </FormControl> */}
                        <GridContainer justify="flex-end">
                            <GridItem>
                                <Form.Item>
                                    <GridContainer>
                                        <GridItem>
                                            <Button color="primary" onClick={() => { props.handleClose() }}>
                                                {t('Cancel')}
                                            </Button>
                                        </GridItem>
                                        <GridItem>
                                            <Button type="submit" color="primary">
                                                {t('Save')}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Form.Item>
                            </GridItem>
                        </GridContainer>
                    </Form>
                </DialogContent>
            </Dialog>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
        </div>
    );
}
