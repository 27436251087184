import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const FileDownload = require("js-file-download");
const initialState = {
  courseSpecReportDocuments: [],
  courseSpecReportDocumentsStatus: 'idle',
  courseSpecReportDocument: null,
  courseSpecReportDocumentStatus: 'idle',
  courseSpecReportDocumentPDFs: [],
  courseSpecReportDocumentPDFsStatus: 'idle',
  error: null,

}

export const fetchCourseSpecReportDocuments = createAsyncThunk('courseSpecReportDocument/fetchCourseSpecReportDocuments',
  async ({ rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + '/htmlTemplateCourseSpecReport/',
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }

      )
      console.log(response.data, 'response.data')


      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseSpecReportDocumentsByCourseSpecId =
  createAsyncThunk('courseSpecReportDocument/fetchCourseSpecReportDocumentsByCourseSpecId',
    async (id, { rejectWithValue }) => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(BaseUrl + `/htmlTemplateCourseSpecReport/by_courseSpec/?courseSpec_id=${id}`,
          {
            headers: {
              'Authorization': `Token ${token}`
            }
          }

        )
        console.log(response.data, 'response.data')


        return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );

export const fetchCourseSpecReportDocumentById =
  createAsyncThunk('courseSpecReportDocument/fetchCourseSpecReportDocumentById',
    async (id, { rejectWithValue }) => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(BaseUrl + `/htmlTemplateCourseSpecReport/${id}/`, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        // console.log(response.data,'response.data')

        return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );
export const addNewCourseSpecReportDocument = createAsyncThunk(
  'courseSpecReportDocument/addNewCourseSpecReportDocument',
  async (initialCourseSpecReportDocument, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/htmlTemplateCourseSpecReport/create/`, initialCourseSpecReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }

  }
)
export const addNewFieldExperienceReportDocument = createAsyncThunk(
  'courseSpecReportDocument/addNewFieldExperienceReportDocument',
  async (initialCourseSpecReportDocument, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/htmlTemplateCourseSpecReport/create/fieldExperience/`, initialCourseSpecReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }

  }
)
export const addNewPDF = createAsyncThunk(
  'courseSpecReportDocument/addNewPDF',
  async (obj, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/convert_html_pdf/?ressource=${obj.ressource}&ressource_id=${obj.ressourceId}`, obj, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }

  }
)
export const downloadPDF = createAsyncThunk(
  'courseSpecReportDocument/downloadPDF',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/attachment/${id}`, id, {
        headers: {
          'Authorization': `token ${token}`,
          'ContentType': 'application/json'
        }
      }).then(response => response.blob()).then(response => {
        FileDownload(response, "doc.pdf");
        console.log("success!", response);
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }

  }
)
export const updateCourseSpecReportDocument = createAsyncThunk(
  'courseSpecReportDocument/updateCourseSpecReportDocument',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/htmlTemplateCourseSpecReport/${data.id}/edit/`, data.updatedCourseSpecReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseSpecReportDocument = createAsyncThunk('courseSpecReportDocument/deleteCourseSpecReportDocument',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(BaseUrl + `/htmlTemplateCourseSpecReport/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const VersionningDocumentReportCourseSpec = createAsyncThunk(
  'courseSpecReportDocument/VersionningDocumentReportCourseSpec',
  async (initialCourse, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/htmlTemplateCourseSpecReport/version/`, initialCourse, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


const courseSpecReportDocumentsSlice = createSlice({
  name: ' courseSpecReportDocument',
  initialState,
  extraReducers: {

    [fetchCourseSpecReportDocuments.pending]: (state, action) => {
      state.courseSpecReportDocumentsStatus = 'loading'
    },
    [fetchCourseSpecReportDocuments.fulfilled]: (state, action) => {
      state.courseSpecReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.courseSpecReportDocuments = action.payload
    },
    [fetchCourseSpecReportDocumentsByCourseSpecId.rejected]: (state, action) => {
      state.courseSpecReportDocumentsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseSpecReportDocumentsByCourseSpecId.pending]: (state, action) => {
      state.courseSpecReportDocumentsStatus = 'loading'
    },
    [fetchCourseSpecReportDocumentsByCourseSpecId.fulfilled]: (state, action) => {
      state.courseSpecReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.courseSpecReportDocuments = action.payload
    },
    [fetchCourseSpecReportDocumentsByCourseSpecId.rejected]: (state, action) => {
      state.courseSpecReportDocumentsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },


    [fetchCourseSpecReportDocumentById.pending]: (state, action) => {
      state.courseSpecReportDocumentStatus = 'loading'
    },
    [fetchCourseSpecReportDocumentById.fulfilled]: (state, action) => {
      state.courseSpecReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.courseSpecReportDocument = action.payload
    },
    [fetchCourseSpecReportDocumentById.rejected]: (state, action) => {
      state.courseSpecReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewCourseSpecReportDocument.fulfilled]: (state, action) => {
      state.courseSpecReportDocuments.push(action.payload);

    },
    [addNewFieldExperienceReportDocument.fulfilled]: (state, action) => {
      state.courseSpecReportDocuments.push(action.payload);

    },
    [addNewPDF.fulfilled]: (state, action) => {
      state.courseSpecReportDocumentPDFs.push(action.payload);

    },
    [deleteCourseSpecReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("CourseSpecReportDocument delete action.payload", action.payload)
    },
    [updateCourseSpecReportDocument.fulfilled]: (state, action) => {

      console.log("CourseSpecReportDocument update action.payload", action.payload)

    },
    [addNewCourseSpecReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewFieldExperienceReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewPDF.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseSpecReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [updateCourseSpecReportDocument.rejected]: (state, action) => {

      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [VersionningDocumentReportCourseSpec.fulfilled]: (state, action) => {
    },
    [VersionningDocumentReportCourseSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

  }

})


export default courseSpecReportDocumentsSlice.reducer


