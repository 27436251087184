import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import {
  Breadcrumb, DatePicker, Form,
  Input,
  message, notification, Select
} from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// @material-ui/icons
import { Link, useHistory } from "react-router-dom";
import { addNewAcademicYear } from "../../../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import ErrorModal from "../../../Components/ErrorModal";





const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
export default function CreateAcademicYear() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // useEffect(() => {
  //     if (universitiesStatus === 'idle') {
  //         dispatch(fetchUniversities())
  //     }

  // }, [universitiesStatus, dispatch])

  // const universities = useSelector((state) => state.university.universities)

  const history = useHistory();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Submit failed!");
  };

  const onFinish = async (values) => {
    const initialAcademicYear = { ...values, start_date, end_date };
    console.log("Received values of form: ", initialAcademicYear);

    const resultAction = await dispatch(
      addNewAcademicYear(initialAcademicYear)
    );

    if (addNewAcademicYear.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const academicYear = resultAction.payload;
      console.log("success", `Added ${academicYear.name}`);
      openNotificationWithIcon("success", `Added ${academicYear.name}`);
      history.push(`/citrine/academicCalender/`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  function onChangeStartDate(date, dateString) {
    setStart_date(dateString);
  }
  function onChangeEndDate(date, dateString) {
    setEnd_date(dateString);
  }

  const { Option } = Select;

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link style={{ color: "orange" }} to={`/citrine/AcademicCalender/`}>
            {" "}
            {t("Academic Calender")}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link style={{ color: "orange" }} to={`/citrine/CreateAcademicYear/`}>
            {" "}
            {t("create")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        //   labelCol={{ span: 8 }}
        //   wrapperCol={{ span: 16 }}
        // initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        //   autoComplete="off"
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <LibraryBooksIcon />
                </CardIcon>
                <h4>Create a new academic year</h4>
              </CardHeader>
            </Card>
          </GridItem>
          {/* 
        <GridItem xs={12} sm={12} md={10}>
            <h3>
                <small style={{ color: `${infoColor[0]}` }}>
                    Course General Information
                </small>
            </h3>
        </GridItem> */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the academic year's name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Start Date")}
                      name="start_date"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the academic year's start date",
                        },
                      ]}
                    >
                      <DatePicker onChange={onChangeStartDate} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("End Date")}
                      name="end_date"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the academic year's end date",
                        },
                      ]}
                    >
                      <DatePicker onChange={onChangeEndDate} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* </FormControl> */}

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
