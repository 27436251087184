import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Select } from "antd";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewActionPlan, fetchActionPlans } from "../../reduxToolkit/features/ActionPlan/ActionPlanSlice";
import { fetchPeoActionsRecommendationsByProgram } from "../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import useTable from "./useTable";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);
const headCells = [
  { id: "Actions", label: "Actions" },
  { id: "Due Date", label: "Due Date" },
  { id: "Assigned To", label: "Assigned To" },
  { id: "Priority", label: "Priority" },
];

export default function GenerateActionsPeoModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const buttonClasses = useButtonStyles();

  const [open, setOpen] = React.useState(false);
  const [actionRecommendation_ids, setActionRecommendation_ids] = useState([]);
  const anchorRef = React.useRef(null);

  const { Option, OptGroup } = Select;
  const { TextArea } = Input;

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn);

  useEffect(() => {
    let object = {
      program_id:
        props.PeoReport && props.PeoReport.programs && props.PeoReport.programs[0] && props.PeoReport.programs[0].id,
    };
    dispatch(fetchPeoActionsRecommendationsByProgram(object))
      .then(unwrapResult)
      .then((ActionsRecommendations) => {
        let tab =
          ActionsRecommendations &&
          ActionsRecommendations.map((item) => item.id);

        setActionRecommendation_ids(tab);
      });
  }, [ActionsRecommendations]);

  const ActionsRecommendations = useSelector(
    (state) => state.ActionRecommendation.ActionRecommendations
  );
  const handleSend = async () => {
    let object = {
      program_id:
        props.PeoReport && props.PeoReport.programs && props.PeoReport.programs[0] && props.PeoReport.programs[0].id,
    };

    try {
      let tab = [];
      dispatch(fetchPeoActionsRecommendationsByProgram(object))
        .then(unwrapResult)
        .then((ActionsRecommendations) => {
          console.log(ActionsRecommendations, "unwrapResult");
          tab = ActionsRecommendations && ActionsRecommendations.map((item) => item.id);
          // console.log(tab, "tab");

          let actionPlan = {
            //(course name or program name)
            name: props.PeoReport && props.PeoReport.programs && props.PeoReport.programs[0].program_degree_name,
            program_id: props.PeoReport && props.PeoReport.programs && props.PeoReport.programs[0].id,
            semester_id: props.PeoReport && props.PeoReport.programs && props.PeoReport.programs[0].semester.id,
            resource: "Peo",
            resource_id: props.PeoReport_id,
            actionRecommendation_ids: tab,
          }
          console.log(actionPlan, "actionPlan");
          dispatch(addNewActionPlan(actionPlan)).then(() => {
            dispatch(fetchActionPlans());
            history.push(`/citrine/actionPlan/Workflow`);
          });

        })
        .then(() => {

        });
    } catch (err) {
      console.error("", err);
    } finally {

    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#D1CF4B",
          marginLeft: "20px",
          marginTop: "5px",
        }}
        size="sm"
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <strong style={{ color: "white", fontSize: "11px" }}>
          {t("Generate Actions")}
        </strong>
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth="true" maxWidth="sm">
        <DialogContent>
          <div
            style={{
              margin: "0px 10px 10px 10px ",
            }}
          >
            <h4> {t("Are you sure you want to Generate Action Plan ?")}</h4>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleSend();
            }}
            color="primary"
          >
            {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
