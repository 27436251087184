import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  communityServices: [],
  AllCommunityServices: [],
  communityServicesStatus: "idle",
  communityService: null,
  communityServiceStatus: "idle",
  AllCommunityServiceStatus: "idle",
  communityServicesByProgram: [],
  communityServicesByProgramStatus: "idle",
};

export const fetchCommunityServices = createAsyncThunk(
  "communityServicesFaculty/fetchCommunityServices",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/communityServicesFaculty/byStaff/?staff_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);
export const fetchCommunityServicesByProgram = createAsyncThunk(
  "communityServicesFaculty/fetchCommunityServicesByProgram",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/communityServicesFaculty/by_program/?program_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);
export const fetchAllCommunityServicesActivities = createAsyncThunk(
  "communityServicesFaculty/fetchAllCommunityServicesActivities",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/communityServicesFaculty/all/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const addNewCommunityServiceActivity = createAsyncThunk(
  "communityServicesFaculty/addNewCommunityServiceActivity",
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/communityServicesFaculty/create/`,
        toAddActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCommunityServiceById = createAsyncThunk(
  "communityServicesFaculty/fetchCommunityServiceById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/communityServicesFaculty/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const updateCommunityServiceActivityState = createAsyncThunk(
  "communityServicesFaculty/updateCommunityServices",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/communityServicesFaculty/${data.id}/edit/`,
        data.updatedCommunityServiceActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      //console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const updateCommunityServiceActivity = createAsyncThunk(
  "communityServicesFaculty/updateCommunityServices",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/communityServicesFaculty/${data.id}/update/`,
        data.updatedCommunityServiceActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      //console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCommunityServiceActivity = createAsyncThunk(
  "communityServicesFaculty/deleteCommunityServiceActivity",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/communityServicesFaculty/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

const communityServicesFacultySlice = createSlice({
  name: "communityServicesFacultySlice",
  initialState,
  extraReducers: {
    [fetchCommunityServices.pending]: (state, action) => {
      state.communityServicesStatus = "loading";
    },
    [fetchCommunityServices.fulfilled]: (state, action) => {
      state.communityServicesStatus = "succeeded";
      // Add any fetched posts to the array
      state.communityServices = action.payload;
    },
    [fetchCommunityServices.rejected]: (state, action) => {
      state.communityServicesStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    [fetchCommunityServicesByProgram.pending]: (state, action) => {
      state.communityServicesByProgramStatus = "loading";
    },
    [fetchCommunityServicesByProgram.fulfilled]: (state, action) => {
      state.communityServicesByProgramStatus = "succeeded";
      // Add any fetched posts to the array
      state.communityServicesByProgram = action.payload;
    },
    [fetchCommunityServicesByProgram.rejected]: (state, action) => {
      state.communityServicesByProgramStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    [fetchCommunityServiceById.pending]: (state, action) => {
      state.communityServiceStatus = "loading";
    },
    [fetchCommunityServiceById.fulfilled]: (state, action) => {
      state.communityServiceStatus = "succeeded";
      // Add any fetched posts to the array
      state.communityService = action.payload;
    },
    [fetchCommunityServiceById.rejected]: (state, action) => {
      state.communityServiceStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },



    [fetchAllCommunityServicesActivities.pending]: (state, action) => {
      state.AllCommunityServiceStatus = 'loading'
    },
    [fetchAllCommunityServicesActivities.fulfilled]: (state, action) => {
      state.AllCommunityServiceStatus = 'succeeded'
      // Add any fetched posts to the array
      state.AllCommunityServices = action.payload
    },
    [fetchAllCommunityServicesActivities.rejected]: (state, action) => {
      state.AllCommunityServiceStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    // [fetchProgramById.pending]: (state, action) => {
    //   state.programStatus = 'loading'
    // },
    // [fetchProgramById.fulfilled]: (state, action) => {
    //   state.programStatus = 'succeeded'

    //   // Add any fetched posts to the array
    //   state.program = action.payload
    // },
    // [fetchProgramById.rejected]: (state, action) => {
    //   state.programStatus = 'failed'
    //   if (action.payload) {
    //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
    //     state.error = action.payload
    //     //console.log("action.payload from inside extra add plo reducer", action.payload)
    //   } else {
    //     state.error = action.error
    //     //console.log("action.error from inside extra add plo reducer", action.error)
    //   }
    // },
    // [addNewProgram.fulfilled]: (state, action) => {
    //   state.programs.push(action.payload)
    // },
    // [addNewProgram.rejected]: (state, action) => {
    //   if (action.payload) {
    //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
    //     state.error = action.payload
    //     //console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
    //   } else {
    //     state.error = action.error
    //     //console.log("action.error from inside extra add plo reducer", action.error)
    //   }
    // },
    // [updateProgram.fulfilled]: (state, action) => {
    //   // const result = state.programs.filter(program => program.id !== action.payload.id);
    //   // result.push(action.payload)
    //   // state.programs = result
    //   //console.log("action.payload.id from slice", action.payload)
    // },
    // [updateProgram.rejected]: (state, action) => {
    //   if (action.payload) {
    //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
    //     state.error = action.payload
    //   //  console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
    //   } else {
    //     state.error = action.error
    //    // console.log("action.error from inside extra add plo reducer", action.error)
    //   }
    // },
  },
});

export default communityServicesFacultySlice.reducer;
