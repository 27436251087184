import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip,
} from 'devextreme-react/chart';

import PivotGrid, {
  FieldChooser,
  Export,
} from 'devextreme-react/pivot-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'.
//  We recommend that you use the official 'file-saver' package in your applications.
import { exportPivotGrid } from 'devextreme/excel_exporter';

// import { sales } from './sales.js';

import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCampaignAnalysis,

} from "../../../../reduxToolkit/features/Survey/SurveySlice";
import ErrorModal from "../../../Components/ErrorModal";

function Pivot(props) {

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const dispatch = useDispatch();

  // const fetchAnalysis = async () => {
  //   try {
  //     const resultAction = await dispatch(
  //       fetchCampaignAnalysis(props.campaignId)
  //     ).unwrap();

  //     console.log("success");
  //   } catch (err) {
  //     setHandledError(err);
  //     handleOpenErrorModal();
  //   }
  // };

  let _chart = useRef(null),
    _pivotGrid = useRef(null);

  useEffect(() => {
    _pivotGrid.current.instance.bindChart(_chart.current.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });

    // fetchAnalysis();

  }, []);


  // const campaignAnalysis = useSelector(
  //   (state) => state.survey.campaignAnalysis
  // );

  // console.log("campaignAnalysis from pivot", campaignAnalysis && campaignAnalysis);





  const dataSource = props.campaign.resource === "course" ?
    new PivotGridDataSource({
      fields: [
        {
          caption: 'Area',
          width: 120,
          dataField: 'area',
          area: 'row',
        },
        {
          caption: 'Subarea',
          dataField: 'subarea',
          width: 150,
          area: 'row'
        },
        {
          caption: 'Question',
          dataField: 'question',
          width: 150,
          area: 'row'
        },
        {
          dataField: 'nationality',
          dataType: 'string',
          area: 'column'
        },
        {
          dataField: 'gender',
          dataType: 'string',
          area: 'column'
        },
        {
          caption: 'studentAverage',
          dataField: 'studentAverage',
          dataType: 'number',
          summaryType: 'avg',
          area: 'data',
          format: {
            type: 'fixedPoint',
            precision: 2
          }

        },
        {
          caption: 'StronglyAgree',
          dataField: 'stronglyAgree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',

          // visible: false,

        },
        {
          caption: 'Agree',
          dataField: 'agree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',

          // visible: false,

        }, {
          caption: 'Disagree',
          dataField: 'disagree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',

          // visible: false,

        }, {
          caption: 'StronglyDisagree',
          dataField: 'stronglyDisagree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',

          // visible: false,

        }, {
          caption: 'NotApplicable',
          dataField: 'notApplicable',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',

          // visible: false,

        },

      ],
      store: props.campaignAnalysis
    })
    :
    new PivotGridDataSource({
      fields: [
        {
          caption: 'Area',
          width: 120,
          dataField: 'area',
          area: 'row',
        },
        {
          caption: 'Subarea',
          dataField: 'subarea',
          width: 150,
          area: 'row'
        },
        {
          caption: 'Question',
          dataField: 'question',
          width: 150,
          area: 'row'
        },
        {
          dataField: 'nationality',
          dataType: 'string',
          area: 'column'
        },
        {
          dataField: 'gender',
          dataType: 'string',
          area: 'column'
        },

        {
          caption: 'studentAverage',
          dataField: 'studentAverage',
          dataType: 'number',
          summaryType: 'avg',
          area: 'data',
          format: {
            type: 'fixedPoint',
            precision: 2
          }
        },
        {
          caption: 'StronglyAgree',
          dataField: 'stronglyAgree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
          // visible: false,

        },
        {
          caption: 'Agree',
          dataField: 'agree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
          // visible: false,

        },
        {
          caption: 'TrueSometimes',
          dataField: 'trueSometimes',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
          // visible: false,

        },
        {
          caption: 'Disagree',
          dataField: 'disagree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
          // visible: false,

        }, {
          caption: 'StronglyDisagree',
          dataField: 'stronglyDisagree',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
          // visible: false,

        }, {
          caption: 'NotApplicable',
          dataField: 'notApplicable',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
          // visible: false,

        },

      ],
      store: props.campaignAnalysis
    })

    const onExporting = React.useCallback((e) => {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Survey_Analysis');
  
      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Survey_Analysis.xlsx');
        });
      });
      e.cancel = true;
    });

  return (
    <React.Fragment>

      {props.campaignAnalysis && <PivotGrid
        id="pivotgrid"
        dataSource={dataSource}
        // allowSorting={true}
        // allowSortingBySummary={true}
        allowFiltering={true}
        allowExpandAll={true}
        showBorders={true}
        showColumnTotals={false}
        showColumnGrandTotals={false}
        showRowTotals={false}
        showRowGrandTotals={true}
        onExporting={onExporting}
        ref={_pivotGrid}
      >
        <FieldChooser enabled={true} height={600} />
        <Export enabled={true} />

      </PivotGrid>}
      <br />


      {props.campaignAnalysis && <Chart ref={_chart}>
        <Size height={1000} />
        <Tooltip enabled={true} />
        <CommonSeriesSettings type="bar" />
        <AdaptiveLayout width={450} />
      </Chart>}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </React.Fragment>
  );

}




export default Pivot;