import { Breadcrumb } from "antd";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDashboardCustomize, MdTableRows } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";
import ActualLearningHourCard from "./ActualLearningHourCard";
import ActualLearningHourTreeList from "./ActualLearningHourTreeList";

export default function ActualLearningHourViews({ match }) {
  const { id } = match.params;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [disabledStatus, setDisabledStatus] = useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCourseByIdMethod = async () => {
    try {
      const resultAction = await dispatch(fetchCourseById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchCourseByIdMethod();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const [view, setView] = useState("card");

  return (
    <div>
      <GridContainer justify="space-between" alignItems="baseline">
        <GridItem>
          <Breadcrumb separator=">">
            
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseSpecDetails/${course && course.id && course.id
                  }`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                style={{ color: "orange" }}
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/ActualLearningHoursViews`}
              >
                {t("Actual Learning Hours List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

        <GridItem>
          <Button
            color={view === "list" ? "primary" : "transparent"}
            // simple
            justIcon
            onClick={() => setView("list")}
          >
            <MdTableRows />
          </Button>

          <Button
            color={view === "card" ? "primary" : "transparent"}
            // simple
            justIcon
            onClick={() => setView("card")}
          >
            <MdDashboardCustomize />
          </Button>
        </GridItem>
      </GridContainer>

      {view === "card" && (
        <div>
          <ActualLearningHourCard id={id} />
        </div>
      )}
      {view === "list" && (
        <div>
          <ActualLearningHourTreeList id={id} />
        </div>
      )}
    </div>
  );
}
