import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import token from "assets/utils/accessToken";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  ModeOfInstructions: [],
  ModeOfInstructionsStatus: "idle",
  ModeOfInstructionStatus: "idle",
  error: null,
  ModeOfInstruction: null,

  id: null,
};

export const fetchModeOfInstructions = createAsyncThunk(
  "ModeOfInstruction/fetchModeOfInstructions",
  async (courseSpec_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/modeOfInstructionSpec/?courseSpec=${courseSpec_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchModeOfInstructionById = createAsyncThunk(
  "ModeOfInstruction/fetchModeOfInstructionById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/modeOfInstructionSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewModeOfInstruction = createAsyncThunk(
  "ModeOfInstruction/addNewModeOfInstruction",
  async (initialModeOfInstruction, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/modeOfInstructionSpec/create/`,
        initialModeOfInstruction,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const ModeOfInstructionUpdate = createAsyncThunk(
  "ModeOfInstruction/ModeOfInstructionUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/modeOfInstructionSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedModeOfInstruction");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteModeOfInstruction = createAsyncThunk(
  "ModeOfInstruction/deleteModeOfInstruction",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/modeOfInstructionSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response, " delete response.data");

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const ModeOfInstructionSlice = createSlice({
  name: "ModeOfInstruction",
  initialState,

  extraReducers: {
    [fetchModeOfInstructions.pending]: (state, action) => {
      state.ModeOfInstructionsStatus = "loading";
    },
    [fetchModeOfInstructions.fulfilled]: (state, action) => {
      state.ModeOfInstructionsStatus = "succeeded";
      // Add any fetched posts to the array
      state.ModeOfInstructions = action.payload;
    },
    [fetchModeOfInstructions.rejected]: (state, action) => {
      state.ModeOfInstructionsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchModeOfInstructionById.pending]: (state, action) => {
      state.ModeOfInstructionStatus = "loading";
    },
    [fetchModeOfInstructionById.fulfilled]: (state, action) => {
      state.ModeOfInstructionStatus = "succeeded";

      // Add any fetched posts to the array
      state.ModeOfInstruction = action.payload;
    },
    [fetchModeOfInstructionById.rejected]: (state, action) => {
      state.ModeOfInstructionStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewModeOfInstruction.fulfilled]: (state, action) => {
      state.ModeOfInstructions.push(action.payload);
    },
    [addNewModeOfInstruction.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteModeOfInstruction.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [deleteModeOfInstruction.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [ModeOfInstructionUpdate.fulfilled]: (state, action) => {},
    [ModeOfInstructionUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },

      ModeOfInstructionUpdated(state, action) {
        const { id, mode_of_instruction, contact_hours, percentage } =
          action.payload;
        const existingModeOfInstruction = state.ModeOfInstructions.find(
          (ModeOfInstruction) => ModeOfInstruction.id === id
        );
        if (existingModeOfInstruction) {
          existingModeOfInstruction.mode_of_instruction = mode_of_instruction;
          existingModeOfInstruction.contact_hours = contact_hours;
          existingModeOfInstruction.percentage = percentage;
        }
      },
    },
  },
});

export const { ModeOfInstructionAdded, ModeOfInstructionUpdated, getId } =
  ModeOfInstructionSlice.actions;

export default ModeOfInstructionSlice.reducer;

export const selectAllModeOfInstruction = (state) =>
  state.ModeOfInstruction.ModeOfInstructions;
