import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";
import {Form, Input, Select, notification, InputNumber} from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

import {
  warningColor,
} from "../../../../../assets/jss/material-dashboard-pro-react";
import {
  FormOutlined,
} from "@ant-design/icons";
import {
  updateStaff,
  fetchStaffById,
} from "../../../../../reduxToolkit/features/user/UserSlice";

const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function EditContactInformationModal(props) {

  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const courseId = props.courseId;
  const [form] = Form.useForm();

  const [question, setQuestion] = useState("");
  const [actual_weight, setActual_weight] = useState("");
  const [correction_mark, setCorrection_mark] = useState("");
  const [clo_id, setClo_id] = useState();
  const [assessmentMethod_id, setAssessmentMethod_id] = useState(props.assessmentMethod_id);
  const [course_id, setCourse_id] = useState(props.course_id);
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const course = useSelector((state) => state.course.course);

  const { Option } = Select;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      work_mobile:
        props.person && props.person.work_mobile && props.person.work_mobile,
      work_phone:
        props.person && props.person.work_phone && props.person.work_phone,
      work_email:
        props.person && props.person.work_email && props.person.work_email,
      work_location:
        props.person &&
        props.person.work_location &&
        props.person.work_location,
      address: props.person && props.person.address && props.person.address,
    });
  }, [props.person]);

  const onFinish = async (values) => {
    const updated = { ...values };
    const resultAction = await dispatch(
      updateStaff({ id: props.person_id, updated : { ...values } })
    );
    if (updateStaff.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Edit successfully`);
      dispatch(fetchStaffById(props.person_id));
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <FormOutlined
        onClick={handleClickOpen}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Edit Contact Information")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="work_mobile"
                  label="Work mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please input the work mobile field.",
                    },
                  ]}
                >
                  <InputNumber style={{ marginLeft: "10px", width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="work_phone"
                  label="Work phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input the work phone field.",
                    },
                  ]}
                >
                  <InputNumber style={{ marginLeft: "10px", width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="work_email"
                  label="Work email"
                  rules={[
                    {
                      required: true,
                      message: "Please input the work email field.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>
                <Form.Item
                  name="work_location"
                  label="Work location"
                  rules={[
                    {
                      required: true,
                      message: "Please input the work location field.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Please input the  Address field.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
