// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAcademicYears } from "reduxToolkit/features/academicYear/AcademicYearsSlice";
import DropDownAcademicYear from "../../../Components/DropDownAcademicYear";



const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);


export default function AcademicYearList(props) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(fetchAcademicYears())

    }, [dispatch])


    const AcademicYears = useSelector((state) => state.academicYear.academicYears)
    console.log('AcademicYears', AcademicYears)


    if (!AcademicYears) {
        return (
            <section>
                <h2>{t('AcademicYears not found!')}</h2>
            </section>
        )
    }

    return (
        <div>

            <GridItem>
                <Breadcrumb separator=">">

                    {/* <Breadcrumb.Item >
                        <Link to={`/citrine/OthersConfigurations`} >
                            Others Configurations
                        </Link>
                    </Breadcrumb.Item> */}

                    <Breadcrumb.Item>
                        <Link style={{ color: "orange" }} to={`/citrine/AcademicYearList/`}>{t('Academic Year List')}
                        </Link>
                    </Breadcrumb.Item>

                </Breadcrumb>
            </GridItem>

            <GridContainer>
                {AcademicYears && AcademicYears.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 150, textAlign: 'start' }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={10}>
                                    <Link to={`/citrine/semesterList/${Element.id}`}>
                                        <CardBody  >
                                            <GridContainer xs={12} sm={12} md={12}>
                                                <GridItem xs={12} sm={12} md={11}>
                                                    <Link style={{ color: "orange" }} to={`/citrine/semesterList/${Element.id}`}>
                                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >

                                                            <Link style={{ color: "orange" }} to={`/citrine/semesterList/${Element.id}`}>
                                                                <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                                    {t('Academic Year')} : {Element.name}
                                                                </a>
                                                            </Link>


                                                        </h4>
                                                    </Link>
                                                    <h4 className={classes.cardProductTitle} style={{ textAlign: 'start' }} >

                                                        <a href="#pablo" onClick={e => e.preventDefault()} >
                                                            {t('start date')} :  {Moment(Element.start_date).format("YYYY-MM-DD")}
                                                        </a>

                                                    </h4>
                                                    <h4 className={classes.cardProductTitle} style={{ textAlign: 'start' }} >

                                                        <a href="#pablo" onClick={e => e.preventDefault()} >
                                                            {t('end date')}      :  {Moment(Element.end_date).format("YYYY-MM-DD")}
                                                        </a>

                                                    </h4>

                                                </GridItem>

                                            </GridContainer>



                                        </CardBody>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                    <DropDownAcademicYear AcademicYear_ID={Element.id} />
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>


        </div >

    );

}




