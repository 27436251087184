import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {

  AuthorityConfigs: [],
  AuthorityConfigsStatus: 'idle',
  AuthorityConfigsByType: [],
  AuthorityConfigsByTypeStatus: 'idle',
  AuthorityConfig: null,
  AuthorityConfigStatus: 'idle',

}

export const fetchAuthorityConfigs = createAsyncThunk('authorityConfig/fetchAuthorityConfig', async (params,{ rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/authorityLevelConfig/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    //console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
   // console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})


// export const fetchResearchAndInnovationActivityById = createAsyncThunk('researchAndInnovationActivities/fetchResearchAndInnovationActivityById', async (id, { rejectWithValue }) => {
//   try {

//     const token = localStorage.getItem('token')
//     const response = await axios.get(BaseUrl + `/researchAndInnovationFaculty/${id}/`, {
//       headers: {
//         'Authorization': `token ${token}`
//       }
//     })
//     // console.log(response.data,'response.data')

//     return response.data
//   } catch (err) {
//     let error = err // cast the error for access
//     if (!error.response) {
//       throw err
//     }
//     // We got validation errors, let's return those so we can reference in our component and set form errors
//    // console.log('error.response from inside the CAT', error.response)
//     return rejectWithValue(error.response)
//   }
// })

export const addAuthorityConfig = createAsyncThunk(
  'authorityConfig/addAuthorityConfig',
  async (toAddActivity, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/authorityLevelConfig/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
     // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


export const fetchAuthorityAccordingToType = createAsyncThunk(
  "authorityConfig/fetchAuthorityAccordingToType",
  async (type,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/authorityLevelConfig/by_level/?level=${type}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    console.log(response.data,'response.data of persons')

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);




// export const updateResearchAndInnovationActivity = createAsyncThunk(
//   "researchAndInnovationActivities/updateResearchAndInnovationActivity",
//   async (data, { rejectWithValue }) => {
//     const token = localStorage.getItem("token");

//     try {
//       const response = await axios.put(
//         BaseUrl + `/researchAndInnovationFaculty/${data.id}/edit/`,
//         data.updatedResearchAndInnovationActivity,
//         {
//           headers: {
//             Authorization: `token ${token}`,
//           },
//         }
//       );
//       //console.log(response.data, 'response.data')
//       return response.data;
//     } catch (err) {
//       let error = err; // cast the error for access
//       if (!error.response) {
//         throw err;
//       }
//       // We got validation errors, let's return those so we can reference in our component and set form errors
//       //console.log('error.response from inside the CAT', error.response)
//       return rejectWithValue(error.response);
//     }
//   }
// );


const authorityConfigSlice = createSlice({
  name: 'authorityConfig',
  initialState,
  extraReducers: {

    [fetchAuthorityConfigs.pending]: (state, action) => {
      state.AuthorityConfigsStatus = 'loading'
    },
    [fetchAuthorityConfigs.fulfilled]: (state, action) => {
      state.AuthorityConfigsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.AuthorityConfigs = action.payload
    },
    [fetchAuthorityConfigs.rejected]: (state, action) => {
      state.AuthorityConfigsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
       // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    [fetchAuthorityAccordingToType.pending]: (state, action) => {
      state.AuthorityConfigsByTypeStatus = 'loading'
    },
    [fetchAuthorityAccordingToType.fulfilled]: (state, action) => {
      state.AuthorityConfigsByTypeStatus = 'succeeded'

      // Add any fetched posts to the array
      state.AuthorityConfigsByType = action.payload
    },
    [fetchAuthorityAccordingToType.rejected]: (state, action) => {
      state.AuthorityConfigsByTypeStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

  },

})

export default authorityConfigSlice.reducer

