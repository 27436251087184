import React, { useState, useEffect, Fragment } from "react";

import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { Tabs } from "antd";
import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";
// import "./improvement.css"

import {
  fetchAnnualProgramReportById,
  UpdateAnnualProgramReport,
} from "../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import Moment from "moment";

import Card from "components/Card/Card.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  blackColor,
  cardTitle,
  grayColor,
  hexToRgb,
  primaryColor,
  whiteColor,
  warningColor,
} from "assets/jss/material-dashboard-pro-react.js";
import {
  PicCenterOutlined,
  FileDoneOutlined,
  PhoneOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Avatar } from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

export default function Identification({ kpiReport }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const callback = (key) => {
    console.log(key);
  };

  return (
    <div style={{ display: "flex" }}>
      <Card style={{ marginRight: "10px" }}>
        <GridContainer xs={12} sm={12} md={12}>
          <p
            style={{
              paddingLeft: "10px",
              color: warningColor[0],
              fontSize: "14px",
            }}
          >
            <strong style={{ color: warningColor[0] }}>
              <PicCenterOutlined style={{ color: warningColor[0] }} />
              Identification
            </strong>
          </p>
          <GridContainer
            xs={12}
            sm={12}
            md={12}
            style={{ borderRadius: "6px", backgroundColor: whiteColor }}
          >
            <GridItem xs={12} sm={12} md={5}>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}> Name : </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {kpiReport && kpiReport.details}
                </span>
              </div>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>Type : </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {kpiReport && kpiReport.type && kpiReport.type}
                </span>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>
                    Academic Year:{" "}
                  </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {kpiReport &&
                    kpiReport.academic_year_name &&
                    kpiReport.academic_year_name}
                </span>
              </div>

              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>Semester: </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {kpiReport &&
                    kpiReport.semester_name &&
                    kpiReport.semester_name}
                </span>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}></GridItem>
          </GridContainer>
        </GridContainer>
      </Card>

      <Card>
        <GridContainer xs={12} sm={12} md={12}>
          <p
            style={{
              paddingLeft: "10px",
              color: warningColor[0],
              fontSize: "14px",
            }}
          >
            <strong style={{ color: warningColor[0] }}>
              <FileDoneOutlined style={{ color: warningColor[0] }} />
              Kpis Results
            </strong>
          </p>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <Avatar
                sx={{
                  backgroundColor: red[600],
                  height: 56,
                  width: 56,
                }}
                style={{ backgroundColor: "#6C7B9D" }}
              >
                {kpiReport &&
                  kpiReport.average &&
                  kpiReport.average.avg_int_Benchmark &&
                  kpiReport.average.avg_int_Benchmark}
                %
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "14px" }}>
                {t("Avg In Benchmark")}
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Avatar
                sx={{
                  backgroundColor: orange[600],
                  height: 56,
                  width: 56,
                }}
                style={{ backgroundColor: "#6C7B9D" }}
              >
                {kpiReport &&
                  kpiReport.average &&
                  kpiReport.average.avg_ext_Benchmark &&
                  kpiReport.average.avg_ext_Benchmark}
                %
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "14px" }}>
                {t("Avg Ex Benchmark")}
              </p>
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <Avatar
                sx={{
                  backgroundColor: orange[600],
                  height: 56,
                  width: 56,
                }}
                style={{ backgroundColor: "#6C7B9D" }}
              >
                {kpiReport &&
                  kpiReport.average &&
                  kpiReport.average.avg_target &&
                  kpiReport.average.avg_target}
                %
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "14px" }}>
                {t("Avg Target")}
              </p>
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <Avatar
                sx={{
                  backgroundColor: orange[600],
                  height: 56,
                  width: 56,
                }}
                style={{ backgroundColor: "#6C7B9D" }}
              >
                {kpiReport &&
                  kpiReport.average &&
                  kpiReport.average.avg_actual &&
                  kpiReport.average.avg_actual}
                %
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "14px" }}>
                {t("Avg Actual")}
              </p>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </Card>
    </div>
  );
}
