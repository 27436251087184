import "@lourenci/react-kanban/dist/styles.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from 'antd';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ClosAssessmentResults from '../CourseLearningOutcomes/ClosAssessmentResults';
import CourseArticulationMatrix from '../CourseLearningOutcomes/CourseArticulationMatrix';
import '../../improvement.css';


const { TabPane } = Tabs
const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};

const useStyles = makeStyles(styles);


export default function CourseLearningOutcomes(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const classes = useStyles();


    return (
        <div>

            <Tabs tabPosition={"top"}
                style={{
                    border: "1px solid lightgray",
                    padding: "0px 15px "
                }} >
                <TabPane tab={t("Course Articulation Matrix")} key="1">
                    <CourseArticulationMatrix idCourse={props.idCourse} />
                </TabPane>
                <TabPane tab={t("CLOs Assessment Results")} key="2">
                    <ClosAssessmentResults idCourse={props.idCourse} />
                </TabPane>
            </Tabs>





        </div >

    );

}




