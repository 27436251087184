// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {

  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";
// @material-ui/icons
import { fetchFacilitiesRequiredConfigById } from "../../../../../reduxToolkit/features/FacilitiesRequired/FacilitiesRequiredConfigSlice";
import {
  addNewFacilitiesRequired, facilitiesRequiredUpdate, fetchFacilitiesRequiredById, fetchFacilitiesRequireds, fetchFacilitiesRequiredsByCourse
} from "../../../../../reduxToolkit/features/FacilitiesRequired/FacilitiesRequiredSlice";
import ErrorModal from "../../../../Components/ErrorModal";

const { TextArea } = Input;
const useStyles = makeStyles(styles);

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

export default function CreateFacilitiesRequired({ match }) {

  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);

  const titleClasses = useTitleStyles();
  const { idFR } = match.params;
  const isAddMode = isNaN(idFR);
  const [resources, setResources] = useState("");
  const [item, setItem] = useState("");
  const [item_id, setItem_id] = useState("");
  const [isComputing, setIsComputing] = useState(false);
  const [computing_resources, setComputingResources] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const FacilitiesRequiredStatus = useSelector((state) => state.FacilitiesRequired.FacilitiesRequiredStatus);
  const Facilities = useSelector((state) => state.FacilitiesRequired.FacilitiesRequiredsByCourse);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const res = useSelector((state) => state.FacilitiesRequired.FacilitiesRequired);

  const { id } = match.params;
  const [form] = Form.useForm();
  const courseSpec_id = id;
  const { Option } = Select;

  useEffect(() => {
    dispatch(fetchFacilitiesRequiredsByCourse(id))
        .then(unwrapResult)
        .then(() => dispatch(fetchCourseById(id))).then((course) => {
    });
  }, []);

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchFacilitiesRequiredById(idFR))
          .then(unwrapResult)
          .then((FR) => {
            form.setFieldsValue({
              item: FR.item_id,
              computing_resource: FR.computing_resource,
              courseSpec_id: FR.courseSpec_id,
              resources: FR.resources,
            });
            console.log("here", FR)
            setIsComputing(FR.computing_resource)
          });
    }
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewFacilitiesRequired({ ...values, courseSpec_id })
    );
    if (addNewFacilitiesRequired.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchFacilitiesRequireds(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/FacilitiesRequireds`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      facilitiesRequiredUpdate({
        id: idFR,
        modified: {
          ...values,
          courseSpec: parseInt(courseSpec_id),
        },
      })
    );
    if (facilitiesRequiredUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchFacilitiesRequireds(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/FacilitiesRequireds`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const onChangeItem = (value) => {
    setItem_id(value);
    setItem(value);
    dispatch(fetchFacilitiesRequiredConfigById(value))
      .then(unwrapResult)
      .then((fr) => {
        console.log("change", fr)
        setIsComputing(fr.computing_resource)
      });
  }

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${id}/FacilitiesRequireds`}
                      >
                        {t("Facilities Requireds")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="item_id"
                        label="Facilities Item"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select Item" allowClear onChange={onChangeItem}>
                          {Facilities &&
                            Facilities.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="resources"
                        label="Resources"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      {isComputing && (<div>
                        <Form.Item
                          name="computing_resource"
                          label="Computing Resources"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </div>)}
                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${id}/FacilitiesRequireds`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">


                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${id}/FacilitiesRequireds`}
                      >
                        {t("Facilities Requireds")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="item"
                        label="Facilities Item"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select Item" allowClear onChange={onChangeItem}>
                          {Facilities &&
                            Facilities.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="resources"
                        label="Resources"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      {isComputing && (<div>
                        <Form.Item
                          name="computing_resource"
                          label="Computing Resources"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </div>)}
                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${id}/FacilitiesRequireds`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
