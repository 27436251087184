import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  CourseEvaluationList: [],

  CourseEvaluationListStatus: "idle",


  feedBackById : null,
  feedbackByIdStatus : "idle",

  error: null,
};

// export const fetchCourseEvaluationList = createAsyncThunk(
//   "evaluation/fetchCourseEvaluationList",
//   async (course_id, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.get(BaseUrl +  `/evaluation/?resource_id=${course_id}&resource='course'`, {
//         headers: {
//           Authorization: `Token ${token}`,
//         },
//       });
//       //console.log(response.data,'response.data of persons')

//       return response.data;
//     } catch (err) {
//       let error = err;
//       if (!error.response) {
//         throw err;
//       }
//       console.log("error.response from inside the CAT", error.response);
//       return rejectWithValue(error.response);
//     }
//   }
// );
export const fetchEvaluationList = createAsyncThunk(
  "evaluation/fetchEvaluationList",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl +  `/evaluation/?resource_id=${obj.resource_id}&resource=${obj.resource}&type=${obj.type}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      //console.log(response.data,'response.data of persons')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const updateCommentsResponse = createAsyncThunk(
  "person/updateCommentsResponse",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/persson/${data.id}/edit/`,
        data.updatedPerson,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "data");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateFeedbackSurvey =  createAsyncThunk(
  "person/updateFeedbackResponse",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/surveyFeedback/${data.id}/edit/`,
        data.updatedFeedback,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "data");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const getFeedbackById = createAsyncThunk(
  "evaluation/getFeedbackById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl +  `/surveyFeedback/${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      //console.log(response.data,'response.data of persons')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);




const CourseEvaluationSlice = createSlice({
  name: " CourseEvaluation",
  initialState,
  extraReducers: {
    // [fetchCourseEvaluationList.pending]: (state, action) => {
    //   state.CourseEvaluationListStatus = "loading";
    // },
    // [fetchCourseEvaluationList.fulfilled]: (state, action) => {
    //   state.CourseEvaluationListStatus = "succeeded";
    //   // Add any fetched posts to the array
    //   state.CourseEvaluationList = action.payload;
    // },
    // [fetchCourseEvaluationList.rejected]: (state, action) => {
    //   state.CourseEvaluationListStatus = "failed";
    //   if (action.payload) {
    //     state.error = action.payload;
    //   } else {
    //     state.error = action.error;
    //   }
    // },
    [fetchEvaluationList.pending]: (state, action) => {
      state.CourseEvaluationListStatus = "loading";
    },
    [fetchEvaluationList.fulfilled]: (state, action) => {
      state.CourseEvaluationListStatus = "succeeded";
      // Add any fetched posts to the array
      state.CourseEvaluationList = action.payload;
    },
    [fetchEvaluationList.rejected]: (state, action) => {
      state.CourseEvaluationListStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

   


    [getFeedbackById.pending]: (state, action) => {
      state.feedbackByIdStatus = "loading";
    },
    [getFeedbackById.fulfilled]: (state, action) => {
      state.feedbackByIdStatus = "succeeded";
      // Add any fetched posts to the array
      state.feedBackById= action.payload;
    },
    [getFeedbackById.rejected]: (state, action) => {
      state.feedbackByIdStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },












    [updateCommentsResponse.fulfilled]: (state, action) => {
      console.log(action);
    },
    [updateCommentsResponse.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },

  reducers: {},
});

export const {} = CourseEvaluationSlice.actions;

export default CourseEvaluationSlice.reducer;
