// @material-ui/core components
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
} from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";
// @material-ui/icons;
import { fetchActivitiesAssessmentMethodsSpec } from "../../../../../reduxToolkit/features/ActivitiesAssessmentMethod/ActivitiesAssessmentMethodSpecSlice";
import {
  addNewClo,
  fetchCloById,
  updateClo,
} from "../../../../../reduxToolkit/features/CLO/CloSpecSlice";
import { fetchSubCodeLevels } from "../../../../../reduxToolkit/features/SubCodeLevel/SubCodeLevelConfigSlice";
import {
  fetchTeachingStrategiesByCourse,
  fetchTeachingStrategiesSpec,
} from "../../../../../reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import ErrorModal from "../../../../Components/ErrorModal";
import AddAMModal from "./AddAModal.jsx";
import AddTSModal from "./AddTSModal.jsx";

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 20,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export default function CreateCourseClo({ match }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { Option } = Select;
  const { t } = useTranslation();
  const history = useHistory();

  const { id, idClo } = match.params;

  const isAddMode = isNaN(idClo);

  const [teaching_strategy_ids, setTeaching_strategy_ids] = useState([]);
  const [assessmentMethodSpec_ids, setAssessmentMethodSpec_ids] = useState([]);
  const [ploSpec_ids, setPloSpec_ids] = useState([]);
  const [level, setLevel] = useState();
  const [levelCode, setLevelCode] = useState();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        if (course.type == "Field Experience") {
          setField(true);
        } else {
          setField(false);
        }
      });

    dispatch(fetchTeachingStrategiesByCourse(id));

    if (idClo && !isAddMode) {
      dispatch(fetchCloById(idClo))
        .then(unwrapResult)
        .then((clo) => {
          console.log("clo", clo);
          setTeaching_strategy_ids(clo.teaching_strategy_ids);
          setAssessmentMethodSpec_ids(clo.assessmentMethodSpec_ids);
          setPloSpec_ids(clo.ploSpec_ids);
          setLevel(clo.levelClo_id);
          setLevelCode(clo.code_level);

          form.setFieldsValue({
            title: clo.title,
            code: clo.code,
            description: clo.description,
            target: clo.target,
            subLevelClo_id: clo.subLevelClo_id,
            teaching_strategy_ids: clo.teaching_strategy_ids,
            assessmentMethodSpec_ids: clo.assessmentMethodSpec_ids,
          });
        });
    }
  }, []);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const clo = useSelector((state) => state.cloSpec.clo);
  const TeachingStrategieByCourse = useSelector(
    (state) => state.TeachingStrategieSpec.TeachingStrategieByCourse
  );

  useEffect(() => {
    dispatch(fetchSubCodeLevels());
    dispatch(fetchTeachingStrategiesSpec(id));
    dispatch(fetchActivitiesAssessmentMethodsSpec(id));
  }, []);

  const subCodeLevels = useSelector((state) => state.subLevelClo.SubCodeLevels);
  const TeachingStrategies = useSelector(
    (state) => state.TeachingStrategieSpec.TeachingStrategiesSpec
  );
  console.log("subCodeLevels", subCodeLevels);
  const ActivitiesAssessmentMethodsSpec = useSelector(
    (state) =>
      state.ActivitiesAssessmentMethodSpec.ActivitiesAssessmentMethodsSpec
  );

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewClo({
        ...values,
        levelClo: level,
        ploSpec_ids,
        courseSpec_id: id,
        code_level: levelCode,
      })
    );
    if (addNewClo.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchCourseById(id));
      history.push(`/citrine/courses/${id}/clos`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      updateClo({
        id: idClo,
        updatedClo: {
          ...values,
          levelClo: level,
          code_level: levelCode,
          courseSpec_id: id,
        },
      }),
      console.log("updateClo", updateClo.updateClo)
    );
    if (updateClo.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `update successfully`);
      form.resetFields();
      dispatch(fetchCourseById(id));
      history.push(`/citrine/courses/${id}/clos`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  function onChangeSubLevel(value) {
    const selectedSublevel = subCodeLevels.find(
      (subLevel) => subLevel.id == value
    );
    setLevel(selectedSublevel.levelClo_id);
  }
  function onChangeAM(value) {
    setAssessmentMethodSpec_ids(value);
  }
  function onChangeTS(value) {
    setTeaching_strategy_ids(value);
  }
  const [field, setField] = useState(false);

  return (
    <div>
      {isAddMode && (
        <div>
          <GridContainer direction="row">
            <GridItem xs={12} sm={12} md={12}>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to={`/citrine/CourseSpecificationList/`}>
                    {t("Courses List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/courseSpecDetails/${id}`}>
                    {course && course.title && course.title}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={`/citrine/courses/${id}/clos`}
                    style={{ color: "orange" }}
                  >
                    {t("Clos List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    style={{ color: "orange" }}
                    to={`/citrine/CreateCourseClo/${id}/:idClo`}
                  >
                    {t("Create")}
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>
          </GridContainer>

          <Card>
            <CardBody>
              <GridContainer justifyContent="center" alignItems="baseline">
                <GridItem xs={10} sm={10} md={10}>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="code"
                      label="Code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="description"
                      label="Description"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label={t("Level/Sub Level")}
                      name="subLevelClo_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the clo's level and subLevel",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeSubLevel}
                      >
                        {subCodeLevels &&
                          subCodeLevels.map((subLevel) => (
                            <Option key={subLevel.id} value={subLevel.id}>
                              {`${subLevel.levelClo_level} /  ${subLevel.subLevelClo_level}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={t("Assessment methods")}
                      name="assessmentMethodSpec_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the clo's assessment Method",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeAM}
                      >
                        {ActivitiesAssessmentMethodsSpec?.map((AM) => (
                          <Option
                            key={
                              AM.planned_assessment_method_spec_details?.[0]?.id
                            }
                            value={
                              AM.planned_assessment_method_spec_details?.[0]?.id
                            }
                          >
                            {`${AM.planned_assessment_method_spec_details?.[0]?.name}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="teaching_strategy_ids"
                      label="Teaching Strategies"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeTS}
                      >
                        {TeachingStrategies &&
                          TeachingStrategies.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item &&
                                item.planned_teaching_strategy_detail &&
                                item.planned_teaching_strategy_detail.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="target"
                      label="Target"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/courses/${id}/clos`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button type="primary" color="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>{" "}
                  <br></br> <br></br> <br></br> <br></br>
                  <AddAMModal
                    courseSpec_id={id}
                    assessmentMethodsSpec={ActivitiesAssessmentMethodsSpec}
                    assessmentMethodSpec_ids={assessmentMethodSpec_ids}
                    setAssessmentMethodSpec_ids={setAssessmentMethodSpec_ids}
                    // TeachingStrategiesConfig={TeachingStrategieByCourse}
                    // setTeaching_strategy_ids={setTeaching_strategy_ids}
                    // teaching_strategy_ids={teaching_strategy_ids}
                  />
                  <br></br>
                  <AddTSModal
                    courseSpec_id={id}
                    TeachingStrategiesConfig={TeachingStrategieByCourse}
                    setTeaching_strategy_ids={setTeaching_strategy_ids}
                    teaching_strategy_ids={teaching_strategy_ids}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      )}
      {!isAddMode && clo && (
        <div>
          <GridContainer direction="row">
            <GridItem xs={12} sm={12} md={12}>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to={`/citrine/CourseSpecificationList/`}>
                    {t("Courses List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/courseSpecDetails/${id}`}>
                    {course && course.title && course.title}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={`/citrine/courses/${id}/clos`}
                    style={{ color: "orange" }}
                  >
                    {t("Clos List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>
          </GridContainer>

          <Card>
            <CardBody>
              <Form {...layout} form={form} onFinish={onFinishUpdate}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="code"
                  label="Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t("Level/Sub Level")}
                  name="subLevelClo_id"
                  rules={[
                    {
                      required: true,
                      message: "Please input the clo's level and subLevel",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onChangeSubLevel}
                  >
                    {subCodeLevels &&
                      subCodeLevels.map((subLevel) => (
                        <Option key={subLevel.id} value={subLevel.id}>
                          {`${subLevel.levelClo_level} /  ${subLevel.subLevelClo_level}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t("Assessment methods")}
                  name="assessmentMethodSpec_ids"
                  rules={[
                    {
                      required: true,
                      message: "Please input the clo's assessment Method",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onChangeAM}
                  >
                    {ActivitiesAssessmentMethodsSpec?.map((AM) => (
                      <Option
                        key={AM.planned_assessment_method_spec_details?.[0]?.id}
                        value={
                          AM.planned_assessment_method_spec_details?.[0]?.id
                        }
                      >
                        {AM.planned_assessment_method_spec_details?.[0]?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={10}>
                    <Form.Item
                      name="teaching_strategy_ids"
                      label="Teaching Strategies"
                      rules={[
                        {
                          required: true,
                          message: "Please input the clo's teaching strategy",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeTS}
                      >
                        {TeachingStrategies?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.planned_teaching_strategy_detail.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <AddTSModal
                      courseSpec_id={id}
                      TeachingStrategiesConfig={TeachingStrategieByCourse}
                      setTeaching_strategy_ids={setTeaching_strategy_ids}
                      teaching_strategy_ids={teaching_strategy_ids}
                    />
                  </GridItem>
                </GridContainer>

                <Form.Item
                  name="target"
                  label="Target"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: "20px" }}
                    color="primary"
                    onClick={() => history.push(`/citrine/courses/${id}/clos`)}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </CardBody>
          </Card>

          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      )}
    </div>
  );
}
