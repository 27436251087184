
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownlevelPloButton from 'views/Components/DropDownlevelPloButton';
import ErrorModal from "views/Components/ErrorModal";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { deleteLevelPlos, fetchLevelPlos, getId } from '../../../../reduxToolkit/features/levelPlo/LevelPloSlice';


const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);


const LevelPloExcerpt = ({ levelPlo }) => {
  const { t } = useTranslation();
  console.log(levelPlo)
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getId(id))

  }
  const deleteUniv = (id) => {
    dispatch(deleteLevelPlos(id))
  }

  return (

    <GridContainer
      md={4}
      xs={12} sm={12}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <GridItem key={levelPlo.id}>

        <Card product className={classes.cardHover} >
          <CardBody style={{ width: 375, height: 200 }}>
            <GridContainer >

              {/* < GridItem  xs={12} sm={12} md={6}>
                <img src={priceImage1} alt="..." width="100%" height="160" />
              </GridItem> */}

              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={10}>

                    <h4
                      className={classes.cardProductTitle}
                      style={{ textAlign: "start", color: "blue" }}
                    >
                      <a style={{ color: "black" }}>
                        {t('Level')} : {levelPlo.level}
                      </a>
                      <br></br><br></br>
                      <a style={{ color: "black" }}>
                        {t('Code')} : {levelPlo.code_level}
                      </a>
                    </h4>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={1}>
                    <DropDownlevelPloButton id={levelPlo.id} />
                  </GridItem>

                </GridContainer>
              </GridItem>

            </GridContainer>
          </CardBody>

        </Card>




      </GridItem >
    </GridContainer >

  )
}

export default function LevelPlosPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const levelPlosStatus = useSelector(state => state.levelPlos.levelPlosStatus)


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAlllevelPlos = async () => {
    try {
      const resultAction = await dispatch(fetchLevelPlos()).unwrap();

    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchAlllevelPlos()
  }, [dispatch])

  const levelPlos = useSelector(state => state.levelPlos.levelPlos)
  console.log('levelPlos', levelPlos)
  const error = useSelector(state => state.levelPlos.error)
  let content

  if (levelPlosStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (levelPlosStatus === 'succeeded') {
    content = levelPlos && levelPlos.map(levelPlo => (

      <LevelPloExcerpt key={levelPlo.id} levelPlo={levelPlo} />
    ))
  } else if (levelPlosStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>

          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                <span > {t('Program Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program/LevelPlos/`}>
                <span > {t('PLO Levels')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>

      <Link to="/citrine/configuration/program/LevelPlos/create" >
        <Button color="info">
          {t('  Add')}
        </Button>
      </Link>

      <GridContainer>
        {
          content

        }

      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
