import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import token from "assets/utils/accessToken";

const initialState = {
  TeachingStrategiesApprovement: [],
  TeachingStrategiesStatus: "idle",
  TeachingStrategiesStatus: "idle",
  error: null,
  DifficultiesAndChallenges: null,

  id: null,
};

export const fetchTeachingStrategies = createAsyncThunk(
  "TeachingStrategie/fetchTeachingStrategies",
  async (course_id,{rejectWithValue}) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/teachingStrategies/?course_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewTeachingStrategie = createAsyncThunk(
  "TeachingStrategie/addNewTeachingStrategie",
  async (initialTeachingStrategie,{rejectWithValue}) => {
    try {
      console.log("initialTeachingStrategie", initialTeachingStrategie);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/conclusionsDifficulties/create/`,
        initialTeachingStrategie,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteTeachingStrategie = createAsyncThunk(
  "TeachingStrategie/deleteTeachingStrategie",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/teachingStrategies/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data, " delete response.data");

    return response.data;
  }
);
export const updateTeachingStrategies = createAsyncThunk(
  "TeachingStrategie/updateTeachingStrategies",
  async (data, { rejectWithValue }) => {
    console.log(data, "data");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/teachingStrategies/${data.id}/edit/`,
        data.updated,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const TeachingStrategieSlice = createSlice({
  name: "TeachingStrategie",
  initialState,

  extraReducers: {
    [fetchTeachingStrategies.pending]: (state, action) => {
      state.TeachingStrategiesStatus = "loading";
    },
    [fetchTeachingStrategies.fulfilled]: (state, action) => {
      state.TeachingStrategiesStatus = "succeeded";

      state.TeachingStrategiesApprovement = action.payload;
      //state.TeachingStrategiesSpec =  jsonData
    },
    [fetchTeachingStrategies.rejected]: (state, action) => {
      state.TeachingStrategiesStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewTeachingStrategie.fulfilled]: (state, action) => {
      state.DifficultiesAndChallenges= action.payload;
    },
    [addNewTeachingStrategie.rejected]: (state, action) => {
        if (action.payload) {
            state.error = action.payload
          } else {
            state.error = action.error
          }
    },
    [deleteTeachingStrategie.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [updateTeachingStrategies.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [updateTeachingStrategies.rejected]: (state, action) => {
        if (action.payload) {
            state.error = action.payload
          } else {
            state.error = action.error
          }
    },
    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },
      TeachingStrategieUpdated(state, action) {
        const { id, suggested_action, difficulties } = action.payload;
        const existingTeachingStrategie = state.TeachingStrategiesApprovement.find(
          (ts) => ts.id === id
        );
        if (existingTeachingStrategie) {
          existingTeachingStrategie.suggested_action = suggested_action;
          existingTeachingStrategie.difficulties = difficulties;
        }
      },
    },
  },
});

export const { getId } = TeachingStrategieSlice.actions;

export default TeachingStrategieSlice.reducer;
