import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import "./EvidenceFileUploaderStyle.css"
import Button from 'components/CustomButtons/Button.js'
import { notification } from 'antd'
import {
    fetchAssessmentById
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice';
import {
    addNewAttachmentAssessmentEvidence,
    deleteAttachmentAssessmentEvidence
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice.js'
import ErrorModal from "../../../Components/ErrorModal";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumbStyle = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


export default function EvidenceFileUploader(props) {
    const dispatch = useDispatch()
    const { Assessmentid } = props

    //error modal
    const [errorModalIsOpen, setErrorModalIsOpen] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setErrorModalIsOpen(true);
    };
    const handleCloseErrorModal = () => {
        setErrorModalIsOpen(false);
    };

    const [file, setFile] = useState(null);
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        // accept: {
        //     'image/*': [],
        //     'application/pdf': []
        // },
        onDrop: acceptedFiles => {
            console.log("acceptedFiles", acceptedFiles[0])
            const first = acceptedFiles[0]
            setFile(
                Object.assign(first, {
                    preview: URL.createObjectURL(first),
                })
            );
        }
    });
    let preview = null
    if (file) {
        preview = file.type.split('/')[0] === 'image' ? <div style={thumbStyle} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div> : <div>{file.path} - {file.size} bytes</div>
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => file => URL.revokeObjectURL(file.preview);
    }, []);

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        })
    }

    const handleSubmission = () => {
        const evidenceType_id = props.type_id
        const resource = props.resource
        const resourceId = props.resource_id
        const uploadData = new FormData()
        uploadData.append('document', file)
        uploadData.append('resource', resource)
        uploadData.append('resourceId', resourceId)
        uploadData.append('evidenceAssessment_id', evidenceType_id)

        dispatch(addNewAttachmentAssessmentEvidence(uploadData))
            .then(unwrapResult)
            .then(() => {
                dispatch(fetchAssessmentById(Assessmentid))
                openNotificationWithIcon('success', `File Added successfully`)
            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                setHandledError(rejectedValueOrSerializedError);
                handleOpenErrorModal();
            });
        setFile(null)
    }
    console.log(file)
    return (
        <>
            <section className="container">
                <div {...getRootProps({ className: 'fileUploadArea' })}>
                    <input {...getInputProps()} />
                    <p>Drag and drop or click select your file</p>
                </div>
                <aside style={thumbsContainer}>
                    {preview}
                </aside>
                {file && <Button color="primary" onClick={handleSubmission}>
                    Save
                </Button>}
            </section>
            <ErrorModal
                open={errorModalIsOpen}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </>
    );
}