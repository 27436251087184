import { EyeOutlined } from '@ant-design/icons';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownPGRButton from 'views/Components/DropDownPGRButton';
import ErrorModal from "views/Components/ErrorModal";
import { grayColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import { deleteGraduationRequirement, fetchGraduationRequirements, getId } from '../../../../../reduxToolkit/features/graduationRequirement/GraduationRequirementSpecSlice';


const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);


const useButtonStyles = makeStyles(buttonStyles);
const GraduationRequirementExcerpt = ({ graduationRequirement }) => {
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation();
  console.log(graduationRequirement)
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getId(id))

  }
  const deleteCol = (id) => {
    dispatch(deleteGraduationRequirement(id))
  }
  const [showMore, setShowMore] = useState(false);
  let data = ("");

  data = graduationRequirement.title
  console.log('data from admission req', data)


  const [smallModal, setSmallModal] = React.useState(false);
  return (

    <GridItem xs={12} sm={12} md={4} key={graduationRequirement.id} >
      <Card style={{ maxWidth: "380px", minWidth: "380px", maxHeight: "180px", minHeight: "180px" }} cardHover >
        <CardHeader>
          <GridContainer direction="row"
            justifyContent="space-between"
            alignItems="baseline">
            <GridItem xs={10} sm={10} md={10}>

              {showMore ? data : `${data.substring(0, 110)}`}
              {data.length > 110 &&
                <div>

                  <Button
                    className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
                    aria-label="showMore"
                    color="white"
                    justIcon
                    onClick={() => setSmallModal(true)}
                  // style={{ width:"10%"}}
                  >
                    <EyeOutlined />
                  </Button>
                  <Dialog
                    classes={{
                      root: classes.center + " " + classes.modalRoot,
                      paper: classes.modal + " " + classes.modalSmall
                    }}
                    open={smallModal}
                    //TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setSmallModal(false)}
                    aria-labelledby="small-modal-slide-title"
                    aria-describedby="small-modal-slide-description"
                  >
                    <DialogTitle
                      id="small-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >

                    </DialogTitle>
                    <DialogContent
                      id="small-modal-slide-description"
                      className={
                        classes.modalBody + " " + classes.modalSmallBody
                      }
                    >
                      <h5>{t('Program graduation requirements')}</h5>


                      <h4 className={cardClasses.cardProductTitle}>
                        {graduationRequirement.title}
                      </h4>

                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => { setSmallModal(false) }} color="info">
                        {t('Close')}
                      </Button>

                    </DialogActions>

                  </Dialog>

                </div>
              }
              <h4 className={cardClasses.cardProductTitle}>
                {graduationRequirement.code}
              </h4>
              {/* </Link> */}
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <DropDownPGRButton id={graduationRequirement.id} />

            </GridItem>
          </GridContainer>
        </CardHeader>
        {/* <CardBody smallCardBody>

        <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}> Departments:
          {program.department_detail && program.department_detail.map((department) => {
            return <div style={{ fontWeight: "normal" }}> {department.name} </div>

          }
          )}
        </div>


      </CardBody> */}
        <CardFooter>
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="center">
            {/* <GridItem> <span style={{ color: (program.state) === "Draft" ? grayColor[1] : "#FFCB3B" }}>{program.state}</span> </GridItem> */}
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>

  )
}

export default function GraduationRequirementPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const graduationRequirementsStatus = useSelector(state => state.graduationRequirementSpec.graduationRequirementsStatus)

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const fetchAllGraduationRequirements = async () => {
    try {
      const resultAction = await dispatch(fetchGraduationRequirements()).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {

    fetchAllGraduationRequirements()

  }, [dispatch])

  const graduationRequirements = useSelector(state => state.graduationRequirementSpec.graduationRequirements)
  const error = useSelector(state => state.graduationRequirementSpec.error)

  let content

  if (graduationRequirementsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (graduationRequirementsStatus === 'succeeded') {
    if (graduationRequirements && graduationRequirements.length) {
      content = graduationRequirements.map(graduationRequirement => (

        <GraduationRequirementExcerpt key={graduationRequirement.id}
          graduationRequirement={graduationRequirement} />
      ))
    } else {
      content = <h5>{t("There is no graduation requirements to display!")}</h5>;
    }
  }
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                <span > {t('Program Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <Link to={`/citrine/configuration/program/graduationRequirements`} style={{ color: "orange" }}>
                {t('Graduation Requirements')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/configuration/program/graduationRequirements/create" >
        <Button color="info">

          {t('Add')}
        </Button>
      </Link>

      <GridContainer>
        {
          content

        }
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}










