import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { notification } from "antd";

import MoreVert from "@material-ui/icons/MoreVert";
import {
  deleteCourse
} from "../../reduxToolkit/Course/coursesSpecSlice";
import AccessAndRoleModal from "./AccessAndRoleModal";
import ErrorModal from "./ErrorModal";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal";

import { useTranslation } from "react-i18next";
import VersionControl from '../../views/Pages/Specifications/course/VersionControl'

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownButtonCourse(props) {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleDeleteCourse = async () => {
    let obj = {
      id: props.programId,
      courseSpec_delete_id: props.courseID
    }
    dispatch(deleteCourse(props.courseID))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Deleted`);

        console.log("originalPromiseResult", originalPromiseResult);
        //dispatch(fetchCourses());
        props.setPing(!props.ping)
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={`/citrine/courseSpecUpdate/${props.courseID}`}>
            {t("Edit Specifications")}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={`/citrine/specification/course/${props.courseID}/courseSyllabus`}
          >
            {t("Course syllabus")}
          </Link>
        </MenuItem>
        {/**************************************************************************************************************************************************************
         * in 'ProgramCoordinatorModal' Component i am passed 'disabled', 'NameOfComponentUsed' and 'style' as a props to manage this component in different interfaces
         *
         * updated 27/09/2022
         **************************************************************************************************************************************************************/}
        <AccessAndRoleModal
          id={props.id}
          handleDropDownButtonClose={handleClose}
          style={{ color: "#8f6682" }}
          NameOfComponentUsed="Courses"
          data={props.data}
          staffList={props.staffList}

        />
        {/* <VersionControl
        id={props.id}
        pingUseEffect={props.pingUseEffect}
          setPingUseEffect={props.setPingUseEffect}
          handleDropDownButtonClose={handleClose}
          refresh={refresh}
          setRefresh={setRefresh}
        /> */}
        {/*************************************************************************************************************************************************************/}

        <MenuItem>
          <Link
            to={`/citrine/specification/course/${props.courseID}/CourseOutlines`}
          >
            {t("Course outline")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal();
            handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteCourse}
        Element_ID={props.courseID}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
}
