import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";

const ProgramEducationalObjectives = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3> PROGRAM EDUCATIONAL OBJECTIVES: Program Educational Objectives</h3>

      <p className={styles.reportParagraphTitle}>
        The PEOs for the {data.programName} program are as
        follows:
      </p>
      {/* <GridItem xs={1} sm={1} md={1}>
          <Button
            justIcon
            round
            color="transparent"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <EditIcon />
          </Button>
        </GridItem> */}
      <ul>
        {data?.PEOs?.map((peo, index) => (
          <li key={index}>{peo}</li>
        ))}
      </ul>

      <br />

      <p className={styles.reportParagraphTitle}>
        The following is information about the program educational objectives
        and the places where they may be found by the general public:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />

    </>
  );
};

export default ProgramEducationalObjectives;
