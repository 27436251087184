import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, Input, notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updatePEO } from "../../reduxToolkit/features/PEO/PEOSpecSlice.jsx";
import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "./ErrorModal";
export default function SuperGeneralUpdateModal({ peo, programId: programSpec_id, disabledStatus }) {



  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [handledError, setHandledError] = React.useState("");
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [name, setName] = useState(peo?.name);
  const [code, setCode] = useState(peo?.code);
  const [open, setOpen] = React.useState(false);
  const [form] = Form.useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      name: peo?.name,
      code: peo?.code,
    });
  }, []);




  const onFinish = async () => {

    setAddRequestStatus("pending");
     let updatedPeo = { name: name, code: code };

    const resultAction = await dispatch(
      updatePEO({ id: peo.id, updatedPeo })
    );
    console.log(resultAction);

    if (updatePEO.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const peo = resultAction.payload;
      console.log(peo);
      console.log("success", `Edited ${peo?.name}`);
      openNotificationWithIcon("success", `Edited ${peo?.name}`);
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload?.data?.name[0]
        );
        setHandledError(resultAction.payload);
        openNotificationWithIcon("error", `${resultAction.payload?.data?.name[0]}`);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);

        handleOpenErrorModal();
        handleClose();
      }
    }
    dispatch(fetchProgramById(programSpec_id));
    handleClose();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // message.error('Submit failed!');
  };
  return (
    <div>
      <MenuItem
        onClick={disabledStatus ? {} :
          handleClickOpen
        }
      >
        <ListItemText primary="Update" />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Update PEO")}</DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            onFinish={onFinish} 
            onFinishFailed={onFinishFailed}
            scrollToFirstError
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <DialogContentText>
                  {t(
                    "Program Educational Objectives describe the career and professional accomplishments that programs are preparing graduates to attain within few years of graduation"
                  )}
                </DialogContentText>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[
                    { message: "Please input the PEO's name!" },
                  ]}
                >
                  <Input
                    id="name"
                    value={peo?.name}
                    onChange={(event) => {
                      
                      setName(event.target.value);
                    }}
                  />
                </Form.Item>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Code")}
                  name="code"
                  rules={[
                    { message: "Please input the PEO's code!" },
                  ]}
                >
                  <Input
                    id="code"
                    value={peo?.code}
                    onChange={(event) => {
                      console.log('New code:', event.target.value);
                      setCode(event.target.value);
                    }}
                  />
                </Form.Item>
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="primary">
                        {t("Update")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
