import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  grayColor
} from "../../../../assets/jss/material-dashboard-pro-react";
import ErrorModal from "../../../Components/ErrorModal";

import imagefiles from '../../../../assets/img/imageFolder/download.png';
import imagefilesPeople from '../../../../assets/img/imageFolder/people.png';
import imagefilePerson from '../../../../assets/img/imageFolder/person.png';



import { IconContext } from "react-icons";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function AllActivities() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };


  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/Activities`}>{t("Activities")}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        {/* <GridItem>
          <CustomInput
            formControlProps={{
              className: navBarClasses.top + " " + navBarClasses.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: navBarClasses.searchInput,
              },
            }}
          />
        </GridItem> */}
      </GridContainer>


      <GridContainer>
        <GridItem className="HoverCard" xs={12} sm={12} md={3}>
          <Link
            to={`/citrine/faculty/Activities/AllResearchAndInnovationActivities`}
          >
            <Card
              style={{
                height: "150px",
                textAlign: "center",
                border: "1px solid lightgray",
                borderRaduis: "10px",
                boxShadow: "2px 2px 2px lightgray",
              }}
            >
              <CardBody>
                <IconContext.Provider value={{ size: "3em" }}>
                  {/* <GiArchiveResearch /> */}
                  <img src={imagefiles} style={{ width: "60px", marginTop: '-40px', marginLeft: '-190px' }} />
                </IconContext.Provider>
                <strong>
                  <h4 style={{ marginTop: "10%" }}>
                    {t("Research && innovation")}
                  </h4>
                </strong>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        <GridItem className="HoverCard" xs={12} sm={12} md={3}>
          <Link to={`/citrine/faculty/Activities/AllCommunityServicesActivities`}>
            <Card
              style={{
                height: "150px",
                textAlign: "center",
                border: "1px solid lightgray",
                borderRaduis: "10px",
                boxShadow: "2px 2px 2px lightgray",
              }}
            >
              <CardBody>
                <IconContext.Provider value={{ size: "3em" }}>
                  {/* <IoIosPeople /> */}
                  <img src={imagefilesPeople} style={{ width: "60px", marginTop: '-40px', marginLeft: '-190px' }} />
                </IconContext.Provider>
                <strong>
                  <h4 style={{ marginTop: "10%" }}>
                    {t("Community services")}
                  </h4>
                </strong>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        <GridItem className="HoverCard" xs={12} sm={12} md={3}>
          <Link
            to={`/citrine/faculty/Activities/AllProfessionalDevelopmentActivities`}
          >
            <Card
              style={{
                height: "150px",
                textAlign: "center",
                border: "1px solid lightgray",
                borderRaduis: "10px",
                boxShadow: "2px 2px 2px lightgray",
              }}
            >
              <CardBody>
                <IconContext.Provider value={{ size: "3em" }}>
                  {/* <GiStairsGoal /> */}
                  <img src={imagefilePerson} style={{ width: "60px", marginTop: '-40px', marginLeft: '-190px' }} />
                </IconContext.Provider>
                <strong>
                  <h4 style={{ marginTop: "10%" }}>
                    {t("Professional development")}
                  </h4>
                </strong>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
