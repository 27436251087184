import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Breadcrumb, notification, Table, Tabs } from "antd";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Search from "@material-ui/icons/Search";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchfacultyActivitiesByProgram,
  updateFacultyActivity,
} from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import ErrorModal from "../../../Components/ErrorModal";
import { useParams } from "react-router-dom";
import { fetchFacultyActivityById } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import {
  primaryColor,
  roseColor,
  infoColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import EventIcon from "@material-ui/icons/Event";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

export default function Planned(props) {
  const { t } = useTranslation();
  let studentsColumns = [
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Identification",
      dataIndex: "student_identification",
      key: "student_identification",
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  let nonStudentsColumns = [
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const { TabPane } = Tabs;

  return (
    <div>
      <Card style={{ padding: 20 }}>
        <Tabs type="card">
          <TabPane tab="Details" key="1">
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              {`${t("Activity dascription")}:       `}
              <span
                style={{
                  color: "black",
                  fontWeight: "normal",
                }}
              >
                {props.facultyActivity.description}
              </span>
            </div>
            <br />
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              {`${t("Type")}:       `}

              <span
                style={{
                  color: "black",
                  fontWeight: "normal",
                }}
              >
                {props.facultyActivity.activity_type}
              </span>
            </div>
            <br />
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              {`${t("Start date")}:       `}

              <span
                style={{
                  color: "black",
                  fontWeight: "normal",
                }}
              >
                {props.facultyActivity.start_date}
              </span>
            </div>
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              {`${t("End date")}:       `}

              <span
                style={{
                  color: "black",
                  fontWeight: "normal",
                }}
              >
                {props.facultyActivity.end_date}
              </span>
            </div>
            <br />
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              {`${t("Assignee")}:       `}

              <span
                style={{
                  color: "black",
                  fontWeight: "normal",
                }}
              >
                {props.facultyActivity.assigned_to_details.username}
              </span>
            </div>
          </TabPane>
          <TabPane tab="Student participants" key="2">
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              <div>{t("Student participants")}:</div>
              <br />
              <Table
                dataSource={
                  props.facultyActivity.student_participants_details || []
                }
                columns={studentsColumns}
                pagination={false}
              />
            </div>
          </TabPane>
          <TabPane tab="Other participants" key="3">
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              <div>{t("Faculty participants")}:</div>
              <br />
              <Table
                dataSource={
                  props.facultyActivity.faculty_participants_details || []
                }
                columns={nonStudentsColumns}
                pagination={false}
              />
            </div>
            <br />
            <br />
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              <div>{t("Staff participants")}:</div>
              <br />
              <Table
                dataSource={props.facultyActivity.staff_participants_details || []}
                columns={nonStudentsColumns}
                pagination={false}
              />
            </div>
            <br />
            <br />
            <div
              style={{
                color: `${primaryColor[0]}`,
                fontWeight: "bold",
              }}
            >
              {`${t("External participants")}:       `}

              <span
                style={{
                  color: "black",
                  fontWeight: "normal",
                }}
              >
                {props.facultyActivity.external_participants}
              </span>
            </div>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}
