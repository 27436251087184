import React, { useEffect,useState } from 'react'
import { Box, Modal, Typography } from '@material-ui/core'

// import Timeline from "@mui/lab/Timeline";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import Comp from './Comp';
import StepConnector, {
    stepConnectorClasses
  } from "@mui/material/StepConnector";
import {fetchActionApprovalProcessConfigById} from "../../../reduxToolkit/features/ActionApprovalProcess/ActionApprovalProcessSlice"
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function ModalOpen(props) {
const [data , setData] = useState()
const dispatch = useDispatch();

async function fetchdata(){
 const dataing = await dispatch(fetchActionApprovalProcessConfigById(props?.id)) 
 setData(dataing)
}
console.log(data)
// console.log(data?.approvalAuthority?.level?.name)

  useEffect(()=>{
    fetchdata()
   
  },[])

 const {actionApprovalProcessConfig,actionsApprovalProcessStatusConfig} =  useSelector((state)=>state.actionApprovalProcess)

 console.log(actionApprovalProcessConfig)
    const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
        color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center",
        ...(ownerState.active && {
          color: "#784af4"
        }),
        "& .QontoStepIcon-completedIcon": {
          color: "#784af4",
          zIndex: 1,
          fontSize: 18
        },
        "& .QontoStepIcon-circle": {
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: "currentColor"
        }
      }));
      function QontoStepIcon(props) {
        const { active, completed, className } = props;
      
        return (
          <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
              <Check className="QontoStepIcon-completedIcon" />
            ) : (
              <div className="QontoStepIcon-circle" />
            )}
          </QontoStepIconRoot>
        );
      }
      QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool
      };
      const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
          }
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
          }
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
          borderRadius: 1
        }
      }));
      
      const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
        backgroundColor:
          theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 150,
        height: 50,
        padding:0,
        display: "flex",
        borderRadius: "5%",
        justifyContent: "center",
        alignItems: "center",
        ...(ownerState.active && {
          backgroundImage:
            "linear-gradient(90deg, rgba(86,86,255,1) 0%, rgba(17,215,255,1) 100%)",
          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
        }),
        ...(ownerState.completed && {
          backgroundImage:
            "linear-gradient(90deg, rgba(86,86,255,1) 0%, rgba(17,215,255,1) 100%)"
        })
      }));
    
      
          
          
    /**
     * styling
     */
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 960,
        height: 350,
        borderRadius:"8px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };
   /**
     * styling
     */
  return (
   <div>
     <Modal
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    
    <Box sx={style}>
    {/* <p>Name Level :{actionApprovalProcessConfig?.approvalAuthority?.level?.name}</p>
    <p>Code :{actionApprovalProcessConfig?.approvalAuthority?.level?.code}</p>
    <p>Role : {actionApprovalProcessConfig?.approvalAuthority?.role?.role}</p> */}
    <p style={{textAlign:"center",fontWeight:"bold"}}>{actionApprovalProcessConfig?.name}</p>

    <Timeline
    style={{marginLeft:"-50px"}}
      position="right"
      sx={{
        
          flex: 0,
          padding: '-50px'
        
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            color="primary"
            variant="outlined"
            style={{ width: "50px", height: "140px", borderRadius: "5%" ,position:"relative"}}
          >
            {actionsApprovalProcessStatusConfig === "succeeded" ?<p style={{transform: "rotate(90deg)",position: "absolute",top:" 80px",left: "-29px",width: "108px",fontWeight:"bold"}}>{actionApprovalProcessConfig?.approvalAuthority?.level?.name} </p> :"Loading..."} 
          </TimelineDot>
          <div style={{backgroundColor:'#8F6682',position: "absolute",
    top: "69px",
    left: "4px",
    rotate: "-90deg",
    borderRadius: "26px 26px 0px 0px",
    width: "100px",
    textAlign: "center"}}><p style={{color:'white'}}>{actionApprovalProcessConfig?.approvalAuthority?.role?.role}</p></div>

          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }} style={{display:"flex",justifyContent:"space-between",alignItems:"center",flex:"20"}}>
              {actionApprovalProcessConfig?.steps?.map((label) => (
                 <div
                 
               >
                <div style={{width:"130px",height:"50px",backgroundColor:"white" ,textAlign:"center",borderRadius:"8px",lineHeight:"50px",boxShadow: `0px 3px 10px  ${label?.state?.color}`}}> 
                    {label?.state?.name}
            </div>
              </div>
              ))}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
    </Box>
  </Modal>
   </div>
  )
}
