import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  codeLevels: [],
  codeLevelsStatus: 'idle',
  CodeLevel: null,
  error: null,
  id: null,
}

export const fetchCodeLevels = createAsyncThunk(
  'CodeLevel/fetchCodeLevels',
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + '/levelClos/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchCodeLevelById = createAsyncThunk(
  'CodeLevel/fetchCodeLevelById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/levelClos/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewCodeLevel = createAsyncThunk(
  'CodeLevel/addNewCodeLevel',
  async (initialAssessmentMode, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/levelClos/create/`,
        initialAssessmentMode,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateCodeLevel = createAsyncThunk(
  'CodeLevel/updateCodeLevel',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/levelClos/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteCodeLevel = createAsyncThunk(
  'CodeLevel/deleteCodeLevel',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/levelClos/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
const CodeLevelSlice = createSlice({
  name: ' CodeLevel',
  initialState,
  extraReducers: {
    [fetchCodeLevels.pending]: (state, action) => {
      state.codeLevelsStatus = 'loading'
    },
    [fetchCodeLevels.fulfilled]: (state, action) => {
      state.codeLevelsStatus = 'succeeded'
      state.codeLevels = action.payload
    },
    [fetchCodeLevels.rejected]: (state, action) => {
      state.codeLevelsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchCodeLevelById.pending]: (state, action) => {
      state.codeLeveltatus = 'loading'
    },
    [fetchCodeLevelById.fulfilled]: (state, action) => {
      state.codeLeveltatus = 'succeeded'
      state.CodeLevel = action.payload
    },
    [fetchCodeLevelById.rejected]: (state, action) => {
      state.codeLeveltatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewCodeLevel.fulfilled]: (state, action) => {
      state.codeLevels.push(action.payload)
    },
    [addNewCodeLevel.rejected]: (state, action) => {
      state.codeLeveltatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteCodeLevel.fulfilled]: (state, action) => {},
    [deleteCodeLevel.rejected]: (state, action) => {
      state.codeLeveltatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateCodeLevel.fulfilled]: (state, action) => {
      const result = state.codeLevels.filter(
          (CodeLevel) => CodeLevel.id !== action.payload.id,
      )
      result.push(action.payload)
      state.codeLevels = result
    },
    [updateCodeLevel.rejected]: (state, action) => {
      state.codeLeveltatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default CodeLevelSlice.reducer
