import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  PeoReports: [],
  PeoReportsStatus: 'idle',
  PeoStatus: 'idle',
  PeoStatus: 'idle',
  error: null,
  PeoReport: null,
  id: null,
  currentRequestId: undefined,
  loading: 'idle',
}
export const fetchPeoReports = createAsyncThunk('PeoReport/fetchPeoReports', async (id, { getState, requestId, rejectWithValue }) => {
  const { currentRequestId, loading } = getState().PeoReport
  if (loading !== 'pending' || requestId !== currentRequestId) {
    console.log("****************stopped here**************",)

    return
  }

  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(BaseUrl + `/peoReport/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    console.log("****************response.data**************", response.data)
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})

export const fetchPeoReportById = createAsyncThunk(
  'PeoReport/fetchPeoReportById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/peoReport/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    ///console.log(response.data, 'response.data fetch course by id')

    return response.data
  },
)
export const addNewPeoReport = createAsyncThunk(
  'PeoReport/addNewPeoReport',
  async (initialPeoReport, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/peoReport/create/`,
        initialPeoReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const deletePeoReport = createAsyncThunk(
  'PeoReport/deletePeoReport',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/peoReport/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    console.log(response.data, ' delete response.data')

    return response.data
  },
)

export const UpdatePeoReport = createAsyncThunk(
  'PeoReport/UpdatePeoReport',
  async (data, thunkAPI) => {
    console.log(data, 'UpdatedPeoReport')
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(
        BaseUrl + `/peoReport/${data.id}/edit/`,
        data.updatedPeoReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(data, 'updatedpeoReport')
      console.log(response.data, 'response.data')
      return response.data
    } catch (error) {
      console.error('Failed to update the courseReport: ', error)
    }
  },
)
const PeoReportSlice = createSlice({
  name: ' PeoReport',
  initialState,
  extraReducers: {},
  reducers: {
    PeoReportUpdate(state, action) {
      const { id, program, state_object, due_date, sent_to } = action.payload
      const existingPeoReport = state.PeoReports.find(
        (PeoReport) => PeoReport.id === id,
      )
      if (existingPeoReport) {
        existingPeoReport.program = program
        existingPeoReport.state_object = state_object
        existingPeoReport.due_date = due_date
        existingPeoReport.sent_to = sent_to
      }
    },
  },
  extraReducers: {
    // fetchPeoReports

    [fetchPeoReports.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.PeoReportsStatus = 'loading'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchPeoReports.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.PeoReportsStatus = 'succeeded'
        state.PeoReports = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchPeoReports.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.PeoReportsStatus = 'failed'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },
    // fetchPeoReportById
    [fetchPeoReportById.pending]: (state, action) => {
      state.PeoStatus = 'loading'
    },
    [fetchPeoReportById.fulfilled]: (state, action) => {
      state.PeoStatus = 'succeeded'
      state.PeoReport = action.payload
    },
    [fetchPeoReportById.rejected]: (state, action) => {
      state.PeoStatus = 'failed'
      state.error = action.payload
    },
    // addNewPeoReport
    [addNewPeoReport.fulfilled]: (state, action) => {
      //state.PeoReports.push(action.payload)
    },
    // deletePeoReport
    [deletePeoReport.fulfilled]: (state, action) => {
      // console.log(action)
    },
    //PeoReportUpdate
    [UpdatePeoReport.fulfilled]: (state, action) => {
      // console.log(action)
    },
  },
})

export const { PeoReportAdded, PeoReportUpdate } = PeoReportSlice.actions

export default PeoReportSlice.reducer
