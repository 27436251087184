import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  ActionPlans: [],
  ActionPlansbyApprovalProcess: [],
  PreviousActionsPlan: [],
  ActionPlansStatus: "idle",
  ActionPlanStatus: "idle",
  PreviousActionsPlanStatus: "idle",
  ActionsByProgramId: [],
  ActionsByProgramIdStatus: [],
  ActionsByResourceId: [],
  ActionsByResourceIdStatus:"idle",
  error: null,
  ActionPlan: null,
  id: null,
};


export const fetchActionPlans = createAsyncThunk(
  "ActionPlan/fetchActionPlans",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/actionPlanned/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    // const response = jsonData
    console.log(response.data, "response.data course");

    return response.data;
  }
);
export const fetchActionPlansbyApprovalProcess = createAsyncThunk(
  "ActionPlan/fetchActionPlansbyApprovalProcess",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/actionPlanned/${id}/byApprovalProcess`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    // const response = jsonData
    console.log(response.data, "response.data course");

    return response.data;
  }
);
export const fetchActionPlanById = createAsyncThunk(
  "ActionPlan/fetchActionPlanById",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/actionPlanned/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    ///console.log(response.data, 'response.data fetch course by id')

    return response.data;
  }
);

export const fetchActionsByProgramId = createAsyncThunk(
  "ActionPlan/fetchActionsByProgramId",
  async (data) => {
    console.log('data' , data)
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/actionPlanned/by_resource/?resource_id=${data.id}&resource=${data.resource}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    ///console.log(response.data, 'response.data fetch course by id')

    return response.data;
  }
);


export const fetchActionsByResourceId = createAsyncThunk(
  "ActionPlan/fetchActionsByResourceId",
  async (data) => {
    console.log('data' , data)
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/actionPlanned/by_resource/?resource_id=${data.id}&resource=${data.resource}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    ///console.log(response.data, 'response.data fetch course by id')

    return response.data;
  }
);









export const addNewActionPlan = createAsyncThunk("ActionPlan/addNewActionPlan",
  async (initialActionPlan,{rejectWithValue}) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + `/actionPlanned/create/`,initialActionPlan,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        console.log(response)
    } catch (err) {
      let error = err; 
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
      
    }
    // console.log("initialActionPlan", initialActionPlan);
    // const token = localStorage.getItem("token");
    // const response = await axios.post(
    //   BaseUrl + `/actionPlanned/create/`,
    //   initialActionPlan,
    //   {
    //     headers: {
    //       Authorization: `token ${token}`,
    //     },
    //   }
    // );
    // console.log(response.data);
    // return response.data;
  }
);

export const addNewPreviousActionPlan = createAsyncThunk(
  "ActionPlan/addNewPreviousActionPlan",
  async (initialActionPlan) => {
    console.log("initialActionPlan", initialActionPlan);
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/previousActionPlan/create/`,
      initialActionPlan,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  }
);

export const fetchPreviousActionPlans = createAsyncThunk(
  "ActionPlan/fetchPreviousActionPlans",
  async (obj) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/previousActionPlan/?resource_id=${obj.resource_id}&resource=${obj.resource}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    // const response = jsonData
    console.log(response.data, "response.data course");

    return response.data;
  }
);


export const deletePreviousAction = createAsyncThunk(
  "ActionPlan/deletePreviousAction",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/previousActionPlan/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data, " delete response.data");

    return response.data;
  }
);
export const deleteActionPlan = createAsyncThunk(
  "ActionPlan/deleteActionPlant",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/actionPlanned/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data, " delete response.data");

    return response.data;
  }
);
export const UpdatePreviousAction = createAsyncThunk(
  "ActionPlan/UpdatePreviousAction",
  async (data, thunkAPI) => {
    console.log(data, "UpdatedActionPlan");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/previousActionPlan/${data.id}/edit/`,
        data.updatedPreviousActionPlan,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedActionPlan");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the courseReport: ", error);
    }
  }
);
export const UpdateActionPlan = createAsyncThunk(
  "ActionPlan/UpdateActionPlan",
  async (data, thunkAPI) => {
    console.log(data, "UpdatedActionPlan");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/actionPlanned/${data.id}/edit/`,
        data.updatedActionPlan,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedActionPlan");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the courseReport: ", error);
    }
  }
);
const ActionPlanSlice = createSlice({
  name: " ActionPlan",
  initialState,
  extraReducers: {},
  reducers: {
    ActionPlanUpdate(state1, action) {
      const { id, name, due_date } = action.payload;
      const existingActionPlan = state1.ActionPlans.find(
        (ActionPlan) => ActionPlan.id === id
      );
      if (existingActionPlan) {
        existingActionPlan.name = name;
        existingActionPlan.due_date = due_date;
      }
    },
  },
  extraReducers: {
    // fetchPloReports
    [fetchActionPlans.pending]: (state, action) => {
      state.ActionPlanStatus = "loading";
    },
    [fetchActionPlans.fulfilled]: (state, action) => {
      state.ActionPlanStatus = "succeeded";
      state.ActionPlans = action.payload;
    },
    [fetchActionPlans.rejected]: (state, action) => {
      state.ActionPlanStatus = "failed";
      state.error = action.payload;
    },
    [fetchActionPlansbyApprovalProcess.pending]: (state, action) => {
      state.ActionPlanStatus = "loading";
    },
    [fetchActionPlansbyApprovalProcess.fulfilled]: (state, action) => {
      state.ActionPlanStatus = "succeeded";
      state.ActionPlansbyApprovalProcess = action.payload;
    },
    [fetchActionPlansbyApprovalProcess.rejected]: (state, action) => {
      state.ActionPlanStatus = "failed";
      state.error = action.payload;
    },

    //fetchPreviousActionPlans
    [fetchPreviousActionPlans.pending]: (state, action) => {
      state.PreviousActionsPlanStatus = "loading";
    },
    [fetchPreviousActionPlans.fulfilled]: (state, action) => {
      state.PreviousActionsPlanStatus = "succeeded";
      state.PreviousActionsPlan = action.payload;
    },
    [fetchPreviousActionPlans.rejected]: (state, action) => {
      state.PreviousActionsPlanStatus = "failed";
      state.error = action.payload;
    },

    // fetchPloReportById
    [fetchActionPlanById.pending]: (state, action) => {
      state.ActionPlanStatus = "loading";
    },
    [fetchActionPlanById.fulfilled]: (state, action) => {
      state.ActionPlanStatus = "succeeded";
      state.ActionPlan = action.payload;
    },
    [fetchActionPlanById.rejected]: (state, action) => {
      state.ActionPlanStatus = "failed";
      state.error = action.payload;
    },
    // addNewPloReport
    [addNewActionPlan.fulfilled]: (state, action) => {
      state.ActionPlans.push(action.payload);
    },

    [addNewPreviousActionPlan.fulfilled]: (state, action) => {
      state.PreviousActionsPlan.push(action.payload);
    },




    [fetchActionsByProgramId.pending]: (state, action) => {
      state.ActionsByProgramIdStatus = "loading";
    },
    [fetchActionsByProgramId.fulfilled]: (state, action) => {
      state.ActionsByProgramIdStatus = "succeeded";
      state.ActionsByProgramId = action.payload;
    },
    [fetchActionsByProgramId.rejected]: (state, action) => {
      state.ActionsByProgramIdStatus = "failed";
      state.error = action.payload;
    },


    
    [fetchActionsByResourceId.pending]: (state, action) => {
      state.ActionsByResourceIdStatus = "loading";
    },
    [fetchActionsByResourceId.fulfilled]: (state, action) => {
      state.ActionsByResourceIdStatus = "succeeded";
      state.ActionsByResourceId = action.payload;
    },
    [fetchActionsByResourceId.rejected]: (state, action) => {
      state.ActionsByResourceIdStatus = "failed";
      state.error = action.payload;
    },







    
    // addNewPloReport
    [addNewActionPlan.fulfilled]: (state, action) => {
      state.ActionPlans.push(action.payload);
    },

    [addNewPreviousActionPlan.fulfilled]: (state, action) => {
      state.PreviousActionsPlan.push(action.payload);
    },

















    [deleteActionPlan.fulfilled]: (state, action) => {
      // console.log(action)
    },

    [deletePreviousAction.fulfilled]: (state, action) => {
      // console.log(action)
    },
    //PloReportUpdate
    [UpdateActionPlan.fulfilled]: (state, action) => {
      // console.log(action)
    },
  },
});

export const { ActionPlanAdded, ActionPlanUpdate } = ActionPlanSlice.actions;

export default ActionPlanSlice.reducer;
