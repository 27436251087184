import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from "antd";

import { Avatar } from "@material-ui/core";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchApprovedNotGeneratedPrograms } from "reduxToolkit/features/program/ProgramSpecSlice";
import ProgramLogo from "../../../../assets/img/programs.jpg";
import { infoColor, primaryColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchAcademicYearById } from "../../../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import { fetchSemesterById } from "../../../../reduxToolkit/features/semester/SemestersSlice.js";
import ErrorModal from "../../../Components/ErrorModal";
import DropDownSemesterButton from "./DropDownSemesterButton";
import GenerateModal from "./GenerateModal.jsx";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const ProgramExcerpt = ({ ping, setPing, program, semesterId, id }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  console.log('semesterIdsemesterId', semesterId)
  console.log('idid', id)
  console.log(program)
  const dispatch = useDispatch();

  return (
    <GridItem xs={12} sm={6} md={4} key={program.id}>
      <Card product className={cardClasses.cardHover}>
        <CardHeader>
          <div style={{ marginTop: "10px" }}><img src={ProgramLogo} width="100%" height="auto" /></div>
          <GridContainer justifyContent="space-between" wrap="nowrap">
            <GridItem>
              <Link to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  <strong style={{ color: primaryColor[0] }}>
                    {program.program_degree_name}
                  </strong>
                </h4>
              </Link>
            </GridItem>
            <GridItem>
              <DropDownSemesterButton
                id={program.id}
                semesterId={semesterId}
                ping={ping}
                setPing={setPing}
              />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <br />
        <CardBody>
          <GridContainer justifyContent="center">
            <GridItem>
              <Avatar
                style={{
                  backgroundColor: "#AEBDCA",
                  height: "50px",
                  width: "50px",
                }}
              >
                {program && program.plos_count && program.plos_count}
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "12px", color: "#AEBDCA", textAlign: "center" }}>
                PLOs
              </p>
            </GridItem>

            <GridItem>
              <Avatar
                style={{
                  backgroundColor: "#B6CEC7",
                  height: "50px",
                  width: "50px",
                }}
              >
                {program &&
                  program.kpis_count &&
                  program.kpis_count}
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "12px", color: "#B6CEC7", textAlign: "center" }}>
                KPIs
              </p>
            </GridItem>

            <GridItem>
              <Avatar
                style={{
                  backgroundColor: "#86A3C3",
                  height: "50px",
                  width: "50px",
                }}
              >
                {program &&
                  program.peos_count &&
                  program.peos_count}
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "12px", color: "#86A3C3", textAlign: "center" }}>
                PEOs
              </p>
            </GridItem>

            <GridItem>
              <Avatar
                style={{
                  backgroundColor: "#7268A6",
                  height: "50px",
                  width: "50px",
                }}
              >
                {program &&
                  program.gas_count &&
                  program.gas_count
                }
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "12px", color: "#7268A6", textAlign: "center" }}>
                GAs
              </p>
            </GridItem>

            <GridItem>
              <Avatar
                style={{
                  backgroundColor: "#6B3074",
                  height: "50px",
                  width: "50px",
                }}
              >
                {program &&
                  program.courses_count &&
                  program.courses_count
                }
              </Avatar>
              <p style={{ marginTop: "10px", fontSize: "12px", color: "#6B3074", textAlign: "center" }}>
                Courses
              </p>
            </GridItem>
          </GridContainer>
        </CardBody>

        <CardFooter>
          <GridContainer direction="row" justify="flex-end" alignItems="center">
            <GridItem>
              <strong style={{ color: primaryColor[0] }}>
                {program.state}</strong>
            </GridItem>
            <GridItem>
              <span style={{ color: primaryColor[0] }}> V{program.version}</span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function Semester({ match }) {
  const [toDeleteProgramId, setToDeleteProgramId] = React.useState(null);
  const { id } = match.params;
  const { semesterId } = match.params;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [programs, setPrograms] = useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [search, setSearch] = React.useState("");
  const [ping, setPing] = useState(false);
  const programsStatus = useSelector((state) => state.program.programsStatus);
  const error = useSelector((state) => state.program.error);
  const fetchPrograms = (id) => {
    dispatch(fetchApprovedNotGeneratedPrograms(id))
      .unwrap()
      .then((originalPromiseResult) => {
        setPrograms(
          originalPromiseResult.filter(
            (program) => program.state === "Approved"
          )
        );
      });

  };

  useEffect(() => {
    fetchPrograms(semesterId)
  }, [ping]);
  console.log("programs", programs);

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  // const handleDeleteProgram = async (id) => {
  //   dispatch(deleteProgram(id))
  //     .unwrap()
  //     .then((originalPromiseResult) => {
  //       // handle result here
  //       console.log("originalPromiseResult", originalPromiseResult);
  //       dispatch(fetchSemesterById(semesterId));
  //       fetchPrograms(semesterId)
  //       openNotificationWithIcon("success", `Deleted`);
  //     })
  //     .catch((rejectedValueOrSerializedError) => {
  //       // handle error here
  //       console.log(
  //         "rejectedValueOrSerializedError",
  //         rejectedValueOrSerializedError
  //       );
  //       setHandledError(rejectedValueOrSerializedError);
  //       handleOpenErrorModal();
  //     });
  // };

  const columns = [

    {
      field: "code",
      headerName: "Code",
      width: 200,
      sortable: false,
    },
    {
      field: "program_degree_name",
      headerName: "Program",
      flex: 1,
      sortable: false,
    },
    {
      field: "active",
      headerName: "Active?",
      type: "boolean",
      width: 100,
      sortable: false,
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   flex: 0.25,
    //   renderCell: (params) => (
    //     <Button
    //       simple
    //       size="lg"
    //       color="primary"
    //       onClick={() => {
    //         setToDeleteProgramId(params.row.id);
    //         handleClickOpen();
    //       }}
    //     >
    //       Delete
    //     </Button>
    //   ),
    //   sortable: false,
    // },
  ];

  const semesterStatus = useSelector((state) => state.semester.semesterStatus);
  //   const [semester, setSemester] = React.useState(null);

  const fetchSemester = async (id) => {
    try {
      const resultAction = await dispatch(fetchSemesterById(id)).unwrap();
      //   setSemester(resultAction);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchAcademicYear = async (id) => {
    try {
      const resultAction = await dispatch(fetchAcademicYearById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchSemester(semesterId);
    fetchAcademicYear(id);
  }, []);
  console.log('semesterIdsemesterId component', semesterId)
  console.log('idid component', id)
  const semester = useSelector((state) => state.semester.semester);
  const academicYear = useSelector((state) => state.academicYear.academicYear);

  console.log("semester", semester && semester);

  let content;

  if (semesterStatus === "loading") {
    content = <div className="loader">Loading...</div>;
  } else if (semesterStatus === "succeeded") {
    //   content = semester && semester.programs && (
    //     <Card>
    //       <CardBody>
    //         <div style={{ height: 600, width: "100%", margin: 20 }}>
    //           <DataGrid
    //             rows={semester.programs}
    //             columns={columns}
    //             autoPageSize={true}
    //             columnBuffer={0}
    //            // disableColumnMenu
    //             disableSelectionOnClick
    //             showCellRightBorder
    //             showColumnRightBorder
    //             pagination
    //               paginationMode="server"
    //               pageSize={rowsPerPage}
    //           />
    //         </div>
    //       </CardBody>
    //     </Card>
    //   );
    // } else if (semesterStatus === "failed") {
    //   content = <div>An error has accured</div>;
    // }
    if (search !== "") {
      content =
        semester && semester.programs.filter((program) => {
          // no search key  & no  selected states
          if (search == "") {
            return program;
          }
          // select state and search key
          else if
            (program &&
            program.program_degree_name
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return program;
          }

        })
          .map(
            (program) =>
              program && (
                <ProgramExcerpt ping={ping} setPing={setPing} key={program.id} program={program} id={semester.academicYear_id} semesterId={semester.id} />
              )
          );
    }
    else {
      content =
        semester && semester.programs

          .filter((program) => {
            // no search key  & no  selected states
            if (search == "") {
              return program;
            }
            // select state and search key
            else if
              (program &&
              program.program_degree_name
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return program;
            }

          })
          .map(
            (program) =>
              program && (
                <ProgramExcerpt ping={ping} setPing={setPing} key={program.id} program={program} id={semester.academicYear_id} semesterId={semester.id} />
              )
          );
    }


  } else if (programsStatus === "failed") {
    content = <div>{error}</div>;
  }
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>{t("Planning")}</Breadcrumb.Item>
        <Breadcrumb.Item>{t("Academic Calender")}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/planning/academicCalender/academicYears`}>
            {t("Academic years")}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/planning/academicCalender/academicYears/${id}`}
          >
            {t(`${academicYear && academicYear.name}`)}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("semesters")}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}`}
          >
            {t(`${semester && semester.name}`)}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <GenerateModal semesterId={semesterId} programs={programs} fetchPrograms={fetchPrograms} />
      <br />
      <GridContainer
        direction="row"
        spacing={3}
        alignItems="center"
      >
        {content}

      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      {/* <SuperGeneralDeleteModal
        delete_Element={handleDeleteProgram}
        Element_ID={toDeleteProgramId}
        open={open}
        handleClose={handleClose}
      /> */}
    </div>
  );
}
