import {createSlice, nanoid, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    assessmentModes: [],
    assessmentModesStatus: 'idle',
    assessmentModeStatus: 'idle',
    error: null,
    assessmentMode: null,
    id: null
}

export const fetchAssessmentModes =
    createAsyncThunk('assessmentModeSpec/fetchAssessmentModes', async (param, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(BaseUrl + '/assessmentModeSpec/',
                {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                }
            )
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const fetchAssessmentModeById =
    createAsyncThunk('assessmentModeSpec/fetchAssessmentModeById',
        async (id, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.get(BaseUrl + `/assessmentModeSpec/${id}/`, {
                    headers: {
                        'Authorization': `token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

export const addNewAssessmentMode = createAsyncThunk(
    'assessmentModeSpec/addNewAssessmentMode',
    async (initialAssessmentMode, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.post(BaseUrl + `/assessmentModeSpec/create/`, initialAssessmentMode, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const updateAssessmentMode = createAsyncThunk(
    'assessmentModeSpec/updateAssessmentMode',
    async (data, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.put(BaseUrl + `/assessmentModeSpec/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const deleteAssessmentMode =
    createAsyncThunk('assessmentModeSpec/deleteAssessmentMode', async (id, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.delete(BaseUrl + `/assessmentModeSpec/${id}/delete/`, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

const assessmentModesSlice = createSlice({
    name: ' assessmentModeSpec',
    initialState,
    extraReducers: {
        [fetchAssessmentModes.pending]: (state, action) => {
            state.assessmentModesStatus = 'loading'
        },
        [fetchAssessmentModes.fulfilled]: (state, action) => {
            state.assessmentModesStatus = 'succeeded'
            state.assessmentModes = action.payload
        },
        [fetchAssessmentModes.rejected]: (state, action) => {
            state.assessmentModesStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [fetchAssessmentModeById.pending]: (state, action) => {
            state.assessmentModestatus = 'loading'
        },
        [fetchAssessmentModeById.fulfilled]: (state, action) => {
            state.assessmentModestatus = 'succeeded'
           state.assessmentMode = action.payload
        },
        [fetchAssessmentModeById.rejected]: (state, action) => {
            state.assessmentModestatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [addNewAssessmentMode.fulfilled]: (state, action) => {
            state.assessmentModes.push(action.payload)
        },
        [addNewAssessmentMode.rejected]: (state, action) => {
            state.assessmentModestatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

      [deleteAssessmentMode.fulfilled]: (state, action) => {},
      [deleteAssessmentMode.rejected]: (state, action) => {
            state.assessmentModestatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

      [updateAssessmentMode.fulfilled]: (state, action) => {
        const result = state.assessmentModes.filter(assessmentMode => assessmentMode.id !== action.payload.id);
        result.push(action.payload)
        state.assessmentModes = result
      },
      [updateAssessmentMode.rejected]: (state, action) => {
            state.assessmentModestatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },
    },

})

export default assessmentModesSlice.reducer

export const selectAllAssessmentModes = state => state.assessmentModeSpec.assessmentModes
