import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AddPlosToClo from "views/Pages/Specifications/course/clos/AddPlosToClo";
import Button from "../../components/CustomButtons/Button";
import { fetchCourseById } from "../../reduxToolkit/Course/coursesSpecSlice";
import {
  deleteCLO
} from "../../reduxToolkit/features/CLO/CloSpecSlice";
import ErrorModal from "../Components/ErrorModal.jsx";
import SuperGeneralDeleteModal from "../Components/SuperGeneralDeleteModal";

const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownButtonClo(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const delete_Clo = (id) => {
    dispatch(deleteCLO(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        dispatch(fetchCourseById(props.courseID));
        openNotificationWithIcon("success", `successfully Deleted`)
      })
      .catch(
        (rejectedValueOrSerializedError) => {
          // handle error here
          console.log(
            "rejectedValueOrSerializedError",
            rejectedValueOrSerializedError
          );
          setHandledError(rejectedValueOrSerializedError);
          handleOpenErrorModal();
        }
      );
  };
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/*<Link to={{
          pathname: `/citrine/editDefaultQuestion/course/${props.courseID}/clo/${props.clo_id}`, state: {
            clo_id: props.clo_id, courseID: props.courseID
          }
        }}>
          <MenuItem disabled={props.selectedRows && props.selectedRows.length !== 1 && props.viewList}>
            {t("Edit default question")}
          </MenuItem>
        </Link>*/}
        <Link to={`/citrine/CreateCourseClo/course/${props.courseID}/clo/${props.clo_id}`}>
          <MenuItem disabled={props.selectedRows && props.selectedRows.length !== 1 && props.viewList}>
            {t("Edit")}
          </MenuItem>
        </Link>
        {/* <AddPlosToClo ping={props.ping} setPing={props.setPing} selectedRows={props.selectedRows} viewList={props.viewList} handleDropDownButtonClose={handleClose} cloId={props.clo_id} clo={props.clo} courseId={props.courseID} /> */}
        <MenuItem
          disabled={props.selectedRows && props.selectedRows.length !== 1 && props.viewList}
          onClick={() => {
            handleOpenDeleteModal();
            handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
        <SuperGeneralDeleteModal
          delete_Element={() => {
            delete_Clo(props.clo_id);
          }}
          Element_ID={props.clo_id}
          open={open}
          handleClose={handleCloseDeleteModal}
        />
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </StyledMenu>
    </div>
  );
}
