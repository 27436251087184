import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  specializations: [],
  specializationsStatus: 'idle',
  specializationStatus: 'idle',
  error: null,
  specialization:null,
}

export const fetchSpecializations = createAsyncThunk('specialization/fetchSpecializations', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/specialization/', 
    {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
      
      )
      // console.log(response.data,'response.data')

    return response.data
  })

  export const fetchSpecializationById = createAsyncThunk('specialization/fetchSpecializationById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/specialization/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewSpecialization = createAsyncThunk(
    'specialization/addNewSpecialization',
    async (initialSpecialization) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/specialization/create/`,initialSpecialization ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )

  export const updateSpecialization = createAsyncThunk(
    'specialization/updateSpecialization',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/specialization/${data.id}/edit/`,data.updatedSpecialization ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedSpecialization')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the Specialization: ', error)

}
    }
  )


//   export const additionalUpdateProgram = createAsyncThunk(
//     'program/additionalUpdateProgram',
//     async (data,thunkAPI) => {
      
//       try{
//         const response = await axios.put(`http://127.0.0.1:8000/api/program/${data.id}/update/`,data.updatedField ,{
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })   
//       console.log(data,'updated')
//       console.log(response.data,'response.data')   
//       return response.data


//     } catch (error) {
// console.error('Failed to update the program: ', error)

// }
//     }
//   )

//   export const deleteProgram = createAsyncThunk('program/deleteProgram', async (id) => {
//     const response = await axios.delete(`http://127.0.0.1:8000/api/program/${id}/delete/`, {
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })
//       // console.log(response.data,'response.data')

//     return response.data
//   })
const  specializationsSlice = createSlice({
  name: ' specialization',
  initialState,
  extraReducers: {

    [fetchSpecializations.pending]: (state, action) => {
        state.specializationsStatus = 'loading'
      },
      [fetchSpecializations.fulfilled]: (state, action) => {
        state.specializationsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.specializations = state.specializations.concat(action.payload)
      },
      [fetchSpecializations.rejected]: (state, action) => {
        state.specializationsStatus = 'failed'
        state.error = action.payload
      },

      [fetchSpecializationById.pending]: (state, action) => {
        state.specializationStatus = 'loading'
      },
      [fetchSpecializationById.fulfilled]: (state, action) => {
        state.specializationStatus = 'succeeded'

        // Add any fetched posts to the array
        state.specialization = action.payload
      },
      [fetchSpecializationById.rejected]: (state, action) => {
        state.specializationStatus = 'failed'
        state.error = action.payload
      },
      [addNewSpecialization.fulfilled]: (state, action) => {
        state.specializations.push(action.payload)
      },
    //   [deleteProgram.fulfilled]: (state, action) => {
    //     // state.programs.push(action.payload)
    //   },
      [updateSpecialization.fulfilled]: (state, action) => {
        const result = state.specializations.filter(specialization => specialization.id !== action.payload.id);
        // console.log("program id from filter from slice",program.id)
        result.push(action.payload)
        state.specializations = result       
        // console.log("action.payload.id from slice",action.payload.id)

      },
    //   [additionalUpdateProgram.fulfilled]: (state, action) => {

    //   console.log("result",action.payload)
    //   },
  },

  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    

//     programUpdated(state, action) {
//       const { id,name, code, description,period,start_date,end_date } = action.payload
//       const existingProgram = state.programs.find(program => program.id === id)
//       if (existingProgram) {
//         existingProgram.name = name
//         existingProgram.code = code
//         existingProgram.description = description
//         existingProgram.period = period
//         existingProgram.start_date = start_date
//         existingProgram.end_date = end_date

//       }
//     }
  }
})

 export const { specializationAdded, specializationUpdated, getId } = specializationsSlice.actions

export default specializationsSlice.reducer

export const selectAllSpecializations = state => state.specialization.specializations

// export const selectProgramBySlug = (state, slug) =>
//   state.program.programs.find(program => program.slug === slug)