// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, notification } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchTeachingStrategiesSpec } from "reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import { fetchCourseById } from '../../../../../reduxToolkit/Course/coursesSpecSlice';
import DropDownButtonTS from "../../../../Components/DropDownButtonTS";
import ErrorModal from "../../../../Components/ErrorModal";



const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);


export default function TeachingStrategiesList({ match }) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();

    const { id } = match.params

    const dispatch = useDispatch()


    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const TeachingStrategiesSpec = useSelector((state) => state.TeachingStrategieSpec.TeachingStrategiesSpec)
    console.log('course to be updated', course)

    console.log('course', course)

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);
    const [course_type, setCourse_type] = useState('')
    const [expanded, setExpanded] = React.useState(false);
    const [field, setField] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };


    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const fetchAllTeachingStrategiesSpec = async () => {
        try {
            const resultAction = await dispatch(fetchTeachingStrategiesSpec(id)).unwrap();
            dispatch(fetchCourseById(id)).then(unwrapResult)
                .then((course) => {
                    setCourse_type(course.type)
                })
            if (course && course.type == 'Field Experience') {
                setField(true)
                console.log('Field Experience', field)
            }
            else {
                setField(false);
            }
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };
    useEffect(() => {
        fetchAllTeachingStrategiesSpec();

        if (localStorage.getItem("CourseState") != "Draft") {
            setDisabledStatus(true);
        }
    }, [dispatch]);

    return (
        <div>
            {field && (
                <div>
                    <GridItem>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}>{course && course.title && course.title}
                                </Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>
                                <Link to={`/citrine/specification/course/${course && course.id && course.id}/TeachingStrategies`} style={{ color: "orange" }} > {t('Training Activities List')}
                                </Link>
                            </Breadcrumb.Item>

                        </Breadcrumb>
                    </GridItem>
                    <GridContainer >
                        <GridItem >
                            {disabledStatus ? <p></p> :
                                <Link to={`/citrine/specification/course/${course && course.id && course.id}/TeachingStrategies/create`}>
                                    <Button color="info"
                                        className={classes.updateProfileButton}
                                    >
                                        {t('Add')}
                                    </Button>
                                </Link>
                            }
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        {TeachingStrategiesSpec && TeachingStrategiesSpec.map(Element => (
                            <GridItem key={Element.id} xs={12} sm={12} md={4} >
                                <Card style={{ height: 150, textAlign: 'start' }}>
                                    <CardBody >
                                        <GridContainer xs={12} sm={12} md={12}>
                                            <GridItem xs={12} sm={12} md={10}>
                                                <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                                    <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                        {t('Planned Training Activity')} : {Element && Element.planned_teaching_strategy_detail && Element.planned_teaching_strategy_detail.name}
                                                    </a>
                                                </h4>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                                {disabledStatus ? <p></p> :
                                                    <DropDownButtonTS TeachingStrategies_ID={Element.id} courseID={course && course.id} />
                                                }
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        ))}
                    </GridContainer>
                    <ErrorModal
                        open={openErrorModal}
                        handleClose={handleCloseErrorModal}
                        handledError={handledError}
                    />


                </div>
            )}
            {!field && (
                <div>
                    <GridItem>
                        <Breadcrumb separator=">">

                            

                            <Breadcrumb.Item>
                                <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}>{course && course.title && course.title}
                                </Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>
                                <Link to={`/citrine/specification/course/${course && course.id && course.id}/TeachingStrategies`} style={{ color: "orange" }} > {t('Teaching Strategies List')}
                                </Link>
                            </Breadcrumb.Item>

                        </Breadcrumb>
                    </GridItem>
                    <GridContainer >
                        <GridItem >
                            {disabledStatus ? <p></p> :
                                <Link to={`/citrine/specification/course/${course && course.id && course.id}/TeachingStrategies/create`}>
                                    <Button color="info"
                                        className={classes.updateProfileButton}
                                    >
                                        {t('Add')}
                                    </Button>
                                </Link>
                            }
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        {TeachingStrategiesSpec && TeachingStrategiesSpec.map(Element => (
                            <GridItem key={Element.id} xs={12} sm={12} md={4} >
                                <Card style={{ height: 150, textAlign: 'start' }}>
                                    <CardBody >
                                        <GridContainer xs={12} sm={12} md={12}>
                                            <GridItem xs={12} sm={12} md={10}>
                                                <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                                    <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                        {t('Planned Teaching Strategies')} : {Element && Element.planned_teaching_strategy_detail && Element.planned_teaching_strategy_detail.name}
                                                    </a>
                                                </h4>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                                {disabledStatus ? <p></p> :
                                                    <DropDownButtonTS TeachingStrategies_ID={Element.id} courseID={course && course.id} />
                                                }
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        ))}
                    </GridContainer>
                    <ErrorModal
                        open={openErrorModal}
                        handleClose={handleCloseErrorModal}
                        handledError={handledError}
                    />

                </div >
            )}
        </div >
    );

}




