import React, { useEffect, useRef } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, notification, Select } from "antd";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { MediaBaseUrl } from "assets/utils/accessToken";
import { useState } from "react";
import { AffectCoordinatorToCourse } from "reduxToolkit/Course/coursesSpecSlice";
import { AffectCoordinatorToAnnualProgramReport } from "reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import { AffectCoordinatorToCourseReport } from "reduxToolkit/features/CourseImprovement/courseImprovementSlice";
import { AffectCoordinatorToGAReport } from "reduxToolkit/features/GAReport/GAResportSlice";
import { AffectCoordinatorToKPIsReport } from "reduxToolkit/features/KpiReport/KpiReportSlice";
import { AffectCoordinatorToPLOReport } from "reduxToolkit/features/PloReporte/PloReporteSlice";
import { AffectCoordinatorToProgramAss } from "reduxToolkit/features/program/ProgramsSlice";
import {
  AffectCoordinatorToProgram,
  fetchPrograms,
} from "../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorsModal from "./ErrorsModal";

export default function AccessAndRoleModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // console.log(props)
  const checkIfReportSectionActive =
    props.interface === "reports"
      ? props?.data?.sent_to
      : props?.data?.coordinator_id;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    props.handleDropDownButtonClose();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleDropDownButtonClose();

    /*  setCoordinator_id(checkIfReportSectionActive);
     setInterims_ids(props?.data?.interims_ids);
     setEditors_ids(props?.data?.editors);
     setWatchers_ids(props?.data?.watchers); */
  };

  /************************************************************
 *Handle: 
        *CoordinatorIDs
        *InterimsIDs
        *EditorsIDs
        *WatchersIDs
 
 *updated 27/09/2022
 ************************************************************/
  const [saved, setSaved] = React.useState(false);
  const changeCoordToAssignTo =
    props.interface === "reports" ? "assigned_to" : "Coordinator";
  /*  const [assignTo, setAssignTo] = React.useState(1);
   const [coordinator_id, setCoordinator_id] = React.useState(
     checkIfReportSectionActive
   );
   // const [contacts, setContacts] = React.useState(props?.data?.watchers);
   const [contacts, setContacts] = React.useState();
 
   const [interims_ids, setInterims_ids] = React.useState(
     props?.data?.interims_ids
   );
   const [editors, setEditors_ids] = React.useState(props?.data?.editors);
   const [watchers, setWatchers_ids] = React.useState(props?.data?.watchers);
   */

  /*   const onCoordinatorChange = (value) => {
      setCoordinator_id(value);
      setAssignTo(value);
    };
    function handleChange(value) {
      setInterims_ids(value);
    }
    function handleChangeEditors(value) {
      setEditors_ids(value);
    }
    function handleChangeWatchers(value) {
      setWatchers_ids(value);
    }
    function handleChangeContacts(value) {
      setContacts(value);
    } */
  /************************************************************/
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const prgrame = useSelector((state) => state.programSpec.programs);

  /**************************************************************
   * Handle Submitting of different components
   *
   *************************************************************/
  const handleFormSubmit = (values) => {
    switch (props.NameOfComponentUsed) {
      case "Programs":
        return Add_Coordinator_To_Program(values);
      case "Courses":
        return Add_Coordinator_To_Courses(values);
      case "ProgramAss":
        return Add_Coordinator_To_Program_Ass(values);
      case "Course-Report":
        return Add_Coordinator_To_CourseReport(values);
      case "KPIs-Report":
        return Add_Coordinator_To_KPIs(values);
      case "PLOs-Report":
        return Add_Coordinator_To_PLOReport(values);
      case "GAs-Report":
        return Add_Coordinator_To_GAReport(values);
      case "AP-Report":
        return Add_Coordinator_To_APReport(values);
      default:
        return console.log("didn't found any component");
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR Programs
   *
   ****************************************************************/

  const Add_Coordinator_To_Program = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToProgram({
        id: props.id,
        data: Data,
      })
    );

    if (AffectCoordinatorToProgram.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Updated`);
      handleClose();
      setSaved(true);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR Courses
   *
   ****************************************************************/
  const Add_Coordinator_To_Courses = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToCourse({
        id: props.id,
        data: Data,
      }) //      coordinator_id
    );
    if (AffectCoordinatorToCourse.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      // console.log("resultAction", resultAction);
      // console.log("resultAction.payload", resultAction.payload);

      openNotificationWithIcon("success", `Updated`);

      handleClose();
      setSaved(true);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // console.log(
        //   "resultAction.payload from component",
        //   resultAction.payload
        // );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        // console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR Program Assessment
   *
   ****************************************************************/

  let data = {
    page: props.page,
    records: props.rowsPerPage,
  };

  const Add_Coordinator_To_Program_Ass = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToProgramAss({
        id: props.id,
        data: Data,
      })
    );
    if (AffectCoordinatorToProgramAss.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      // console.log("resultAction", resultAction);
      // console.log("resultAction.payload", resultAction.payload);

      openNotificationWithIcon("success", `Updatedss`);

      props.fetchProgAss(data);
      handleClose();
      props.handleDropDownButtonClose();
      setSaved(true);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // console.log(
        //   "resultAction.payload from component",
        //   resultAction.payload
        // );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        // console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR Course-Report
   *
   ****************************************************************/
  const Add_Coordinator_To_CourseReport = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToCourseReport({
        id: props.id,
        data: {
          sent_to: Data.coordinator_id,
          watchers: Data.watchers,
          editors: Data.editors,
        },
      })
    );

    if (AffectCoordinatorToCourseReport.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const program = resultAction.payload;

      // console.log("resultAction", resultAction);
      // console.log("resultAction.payload", resultAction.payload);

      openNotificationWithIcon("success", `Updated`);

      handleClose();
      setSaved(true);
      props.handleDropDownButtonClose();
      props.fetchCourseReports();
      //dispatch(fetchProgramById(props.id)).then((res)=>console.log(res));
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // console.log(
        //   "resultAction.payload from component",
        //   resultAction.payload
        // );

        // dispatch(fetchProgramById(props.id));
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        // console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR KPIs-Report
   *
   ****************************************************************/
  const Add_Coordinator_To_KPIs = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToKPIsReport({
        id: props.id,
        data: {
          sent_to: Data.coordinator_id,
          watchers: Data.watchers,
          editors: Data.editors,
        },
      })
    );

    if (AffectCoordinatorToKPIsReport.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      // console.log("resultAction", resultAction);
      // console.log("resultAction.payload", resultAction.payload);

      openNotificationWithIcon("success", `Updated`);

      handleClose();
      setSaved(true);
      props.fetchKPIReports();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // console.log(
        //   "resultAction.payload from component",
        //   resultAction.payload
        // );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        // console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR PLOs-Report
   *
   ****************************************************************/
  const Add_Coordinator_To_PLOReport = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToPLOReport({
        id: props.id,
        data: {
          sent_to: Data.coordinator_id,
          watchers: Data.watchers,
          editors: Data.editors,
        },
      })
    );

    if (AffectCoordinatorToPLOReport.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const program = resultAction.payload;

      // console.log("resultAction", resultAction);
      // console.log("resultAction.payload", resultAction.payload);

      openNotificationWithIcon("success", `Updated`);

      handleClose();
      setSaved(true);
      props.handleDropDownButtonClose();
      props.fetchPLOReports();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // console.log(
        //   "resultAction.payload from component",
        //   resultAction.payload
        // );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        // console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR GAs-Report
   *
   ****************************************************************/
  const Add_Coordinator_To_GAReport = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToGAReport({
        id: props.id,
        data: {
          sent_to: Data.coordinator_id,
          watchers: Data.watchers,
          editors: Data.editors,
        },
      })
    );

    if (AffectCoordinatorToGAReport.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      // console.log("resultAction", resultAction);
      // console.log("resultAction.payload", resultAction.payload);

      openNotificationWithIcon("success", `Updated`);

      handleClose();
      setSaved(true);
      props.handleDropDownButtonClose();
      props.fetchGAReports();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // console.log(
        //   "resultAction.payload from component",
        //   resultAction.payload
        // );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        // console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************
   *
   * This function related to ADD COORDINATOR AP-Report
   *
   ****************************************************************/
  const Add_Coordinator_To_APReport = async (Data) => {
    const resultAction = await dispatch(
      AffectCoordinatorToAnnualProgramReport({
        id: props.id,
        data: {
          sent_to: Data.coordinator_id,
          watchers: Data.watchers,
          editors: Data.editors,
        },
      })
    );

    if (AffectCoordinatorToAnnualProgramReport.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      // console.log("resultAction", resultAction);
      // console.log("resultAction.payload", resultAction.payload);

      openNotificationWithIcon("success", `Updated`);
      handleClose();
      setSaved(true);
      props.handleDropDownButtonClose();
      // props.fetchAnnualProgramReports();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // console.log(
        //   "resultAction.payload from component",
        //   resultAction.payload
        // );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        // console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  /****************************************************************/

  const { Option } = Select;

  const handleCancelBtn = () => {
    /*  if (props.NameOfComponentUsed !== "Programs") {
       if (saved == false) {
         setWatchers_ids(props?.data?.watchers);
         setCoordinator_id(props?.data?.coordinator_id);
         //setInterims_ids(props.program.interims_ids);
         setEditors_ids(props?.data?.editors);
       } else {
         setWatchers_ids(watchers);
         setCoordinator_id(coordinator_id);
         //setInterims_ids(interims_ids);
         setEditors_ids(editors);
       }
     } else {
       if (saved == false) {
         setWatchers_ids(props?.data?.watchers);
         setCoordinator_id(props?.data?.coordinator_id);
         setInterims_ids(props?.data?.interims_ids);
         setEditors_ids(props?.data?.editors);
       } else {
         setWatchers_ids(watchers);
         setCoordinator_id(coordinator_id);
         setInterims_ids(interims_ids);
         setEditors_ids(editors);
       }
     } */
  };
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const handleKeyDown = (event, ref) => {
    if (event.key === "Tab" && ref && ref.current) {
      ref.current.focus();
    }
  };
  const [coordinator_id, setCoordinator_id] = useState();
  const [editors, setEditors] = useState();

  useEffect(() => {
    form.setFieldsValue({
      coordinator_id: checkIfReportSectionActive,
      contacts: props?.data?.interims_ids,
      interims_ids: props?.data?.interims_ids,
      editors: props?.data?.editors,
      watchers: props?.data?.watchers,
    });
    //console.log(props.data);
  }, [props?.data]);

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          if (
            props.NameOfComponentUsed === "Programs" ||
            props.NameOfComponentUsed === "Courses"
          ) {
            handleClickOpen();
          }
          setOpen(true);
        }}
        style={props.style}
      >
        {t("Access Control")}
      </MenuItem>

      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          props.handleDropDownButtonClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Access Control")}</DialogTitle>
        <DialogContent>
          <Form
            form={form}
            name="control-hooks"
            layout="vertical"
            onFinish={handleFormSubmit}
            scrollToFirstError
          >
            <GridContainer>
              {/****************************** Coordinator  ************************************************* */}

              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t(changeCoordToAssignTo)}
                  name="coordinator_id"
                  rules={[
                    {
                      required: true,
                      message: `Please select ${changeCoordToAssignTo}`,
                    },
                  ]}
                  // initialValue={coordinator_id}
                >
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    dropdownStyle={{ zIndex: 10000 }}
                    value={coordinator_id}
                    placeholder={t(`Select ${changeCoordToAssignTo}`)}
                    ref={input1Ref}
                    onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                    optionFilterProp="children"
                    //  onChange={onCoordinatorChange}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                    disabled={props.disabled}
                  >
                    {props.staffList &&
                      props.staffList.map((staff, key) => (
                        <Option value={staff && staff.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (
                            <span
                              style={{
                                backgroundColor: "rgb(143, 102, 130)",
                                width: "23px",
                                height: "23px",
                                color: "white",
                                borderRadius: "50%",
                                paddingLeft: "7px",
                                marginRight: "15px",
                                display: "inline-block",
                                lineHeight: "23px",
                              }}
                            >
                              {staff?.person_details[0]?.first_name
                                .charAt(0)
                                .toUpperCase() + "     "}
                            </span>
                          ) : (
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                            />
                          )}
                          {` ${staff?.person_details[0]?.first_name} 
                          ${" "}
                          ${staff?.person_details[0]?.last_name}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
              {/****************************** Contacts && Interims ************************************************* */}

              {props.NameOfComponentUsed == "Programs" ? (
                <>
                  <GridItem xs={12} sm={12} md={12}>
                    {/* <Form.Item
                      label={t("Contacts")}
                      name="contacts"
                    > */}
                    {/* <Select
                        mode="multiple"
                        showArrow="true"
                        dropdownStyle={{ zIndex: 10000 }}
                        placeholder={t("select Contact")}
                        optionFilterProp="children"
                      // ref={input4Ref}
                      // onKeyDown={(event) => handleKeyDown(event, input3Ref)}
                      //onChange={handleChangeContacts}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      >
                        {props.staffList &&
                          props.staffList.map((staff, key) => (
                            <Option value={staff && staff.id} key={key}>
                              {!staff?.person_details[0]?.avatar ? (<span style={{
                                backgroundColor: 'rgb(143, 102, 130)',
                                width: '23px',
                                height: '23px',
                                color: 'white',
                                borderRadius: '50%',
                                paddingLeft: '7px',
                                marginRight: '15px',
                                display: 'inline-block',
                                lineHeight: '23px'
                              }}>{staff?.person_details[0]?.first_name.charAt(0).toUpperCase() + '     '}</span>) : (<img style={{ width: '20px', height: "20px" }} src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`} />)}
                              {` ${staff &&
                                staff.person_details &&
                                staff.person_details[0] &&
                                staff.person_details[0].first_name
                                } 
                         ${" "}
                         ${staff &&
                                staff.person_details &&
                                staff.person_details[0] &&
                                staff.person_details[0].last_name
                                }`}
                            </Option>
                          ))}
                      </Select> */}
                    {/* </Form.Item> */}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Interims")}
                      name="interims_ids"
                      /*  rules={[
                    {
                      required: true,
                      message: "Please select the interims!",
                    },
                  ]} */
                      //   initialValue={interims_ids}
                    >
                      <Select
                        mode="multiple"
                        showArrow="true"
                        dropdownStyle={{ zIndex: 10000 }}
                        placeholder={t("select Interims")}
                        optionFilterProp="children"
                        //ref={input3Ref}
                        //onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                        //  onChange={handleChange}
                        // filterOption={(input, option) =>
                        //   option.children
                        //     .toLowerCase()
                        //     .indexOf(input.toLowerCase()) >= 0
                        // }
                      >
                        {props.staffList &&
                          props.staffList.map((staff, key) => (
                            <Option value={staff && staff.id} key={key}>
                              {!staff?.person_details[0]?.avatar ? (
                                <span
                                  style={{
                                    backgroundColor: "rgb(143, 102, 130)",
                                    width: "23px",
                                    height: "23px",
                                    color: "white",
                                    borderRadius: "50%",
                                    paddingLeft: "7px",
                                    marginRight: "15px",
                                    display: "inline-block",
                                    lineHeight: "23px",
                                  }}
                                >
                                  {staff?.person_details[0]?.first_name
                                    .charAt(0)
                                    .toUpperCase() + "     "}
                                </span>
                              ) : (
                                <img
                                  style={{ width: "20px", height: "20px" }}
                                  src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                                />
                              )}
                              {`${staff?.person_details[0]?.first_name} ${staff?.person_details[0]?.last_name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </>
              ) : props.NameOfComponentUsed == "ProgramAss" ? (
                <>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Interims")}
                      name="interims_ids"
                      /*  rules={[
                    {
                      required: true,
                      message: "Please select the interims!",
                    },
                  ]} */
                      //    initialValue={interims_ids}
                    >
                      <Select
                        mode="multiple"
                        showArrow="true"
                        dropdownStyle={{ zIndex: 10000 }}
                        placeholder={t("select Interims")}
                        optionFilterProp="children"
                        //ref={input1Ref}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Tab" && event && event.current) { // "Tab" key
                        //     event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        //     input2Ref.current.focus(); // Focus the next DatePicker component
                        //   }
                        // }}
                        //   onChange={handleChange}
                        // filterOption={(input, option) =>
                        //   option.children
                        //     .toLowerCase()
                        //     .indexOf(input.toLowerCase()) >= 0
                        // }
                      >
                        {props.staffList &&
                          props.staffList.map((staff, key) => (
                            <Option value={staff && staff.id} key={key}>
                              {!staff?.person_details[0]?.avatar ? (
                                <span
                                  style={{
                                    backgroundColor: "rgb(143, 102, 130)",
                                    width: "23px",
                                    height: "23px",
                                    color: "white",
                                    borderRadius: "50%",
                                    paddingLeft: "7px",
                                    marginRight: "15px",
                                    display: "inline-block",
                                    lineHeight: "23px",
                                  }}
                                >
                                  {staff?.person_details[0]?.first_name
                                    .charAt(0)
                                    .toUpperCase() + "     "}
                                </span>
                              ) : (
                                <img
                                  style={{ width: "20px", height: "20px" }}
                                  src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                                />
                              )}
                              {`${staff?.person_details[0]?.first_name} ${staff?.person_details[0]?.last_name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </>
              ) : (
                <></>
              )}
              {/****************************** Editors  ************************************************* */}

              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Editors")}
                  name="editors"
                  /*  rules={[
                  { required: true, message: "Please select Editors!" },
                ]} */
                  //  initialValue={editors}
                >
                  <Select
                    mode="multiple"
                    showArrow="true"
                    value={editors}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t("select Editors")}
                    optionFilterProp="children"
                    ref={input2Ref}
                    onKeyDown={(event) => handleKeyDown(event, input3Ref)}
                    //  onChange={(e) => handleChangeEditors(e)}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {props.staffList &&
                      props.staffList.map((staff, key) => (
                        <Option value={staff && staff.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (
                            <span
                              style={{
                                backgroundColor: "rgb(143, 102, 130)",
                                width: "23px",
                                height: "23px",
                                color: "white",
                                borderRadius: "50%",
                                paddingLeft: "7px",
                                marginRight: "15px",
                                display: "inline-block",
                                lineHeight: "23px",
                              }}
                            >
                              {staff?.person_details[0]?.first_name
                                .charAt(0)
                                .toUpperCase() + "     "}
                            </span>
                          ) : (
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                            />
                          )}
                          {`${staff?.person_details[0]?.first_name} ${staff?.person_details[0]?.last_name}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
              {/************************************************************************************************************
               * New Updates 27/09/2022
               * Add Editors and Watchers Fields
               ***********************************************************************************************************/}
              {/****************************** Watchers  ************************************************* */}

              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Watchers")}
                  name="watchers"
                  /*  rules={[
                  { required: true, message: "Please select Watchers!" },
                ]} */
                  //   initialValue={watchers}
                >
                  <Select
                    mode="multiple"
                    showArrow="true"
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t("select Watchers")}
                    optionFilterProp="children"
                    ref={input3Ref}
                    onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                    // onChange={handleChangeWatchers}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {props.staffList &&
                      props.staffList.map((staff, key) => (
                        <Option value={staff && staff.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (
                            <span
                              style={{
                                backgroundColor: "rgb(143, 102, 130)",
                                width: "23px",
                                height: "23px",
                                color: "white",
                                borderRadius: "50%",
                                paddingLeft: "7px",
                                marginRight: "15px",
                                display: "inline-block",
                                lineHeight: "23px",
                              }}
                            >
                              {staff?.person_details[0]?.first_name
                                .charAt(0)
                                .toUpperCase() + "     "}
                            </span>
                          ) : (
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                            />
                          )}
                          {`${staff?.person_details[0]?.first_name} ${staff?.person_details[0]?.last_name}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
            </GridContainer>

            {/* </FormControl> */}
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleClose();
                          props.handleDropDownButtonClose();
                          handleCancelBtn();
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="primary" color="primary" htmlType="submit">
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorsModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
