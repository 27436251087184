import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  gaReportDocuments: [],
  gaReportDocumentsStatus: 'idle',
  gaReportDocument: null,
  gaReportDocumentStatus: 'idle',
  error: null,

}

export const fetchGAReportDocuments = createAsyncThunk('gaReportDocument/fetchGAReportDocuments', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/htmlTemplateGAReport/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchGAReportDocumentsByProgramId = createAsyncThunk('gaReportDocument/fetchGAReportDocumentsByProgramId', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/htmlTemplateGAReport/by_program/?program_id=${id}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchGAReportDocumentById = createAsyncThunk('gaReportDocument/fetchGAReportDocumentById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/htmlTemplateGAReport/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewGAReportDocument = createAsyncThunk(
  'gaReportDocument/addNewGAReportDocument',
  async (initialGAReportDocument) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/htmlTemplateGAReport/create/`, initialGAReportDocument, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateGAReportDocument = createAsyncThunk(
  'gaReportDocument/updateGAReportDocument',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/htmlTemplateGAReport/${data.id}/edit/`, data.updatedGAReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the GAReportDocument: ', error)

    }
  }
)

export const deleteGAReportDocument = createAsyncThunk('gaReportDocument/deleteGAReportDocument', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/htmlTemplateGAReport/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})


const gaReportDocumentsSlice = createSlice({
  name: ' gaReportDocument',
  initialState,
  extraReducers: {
    

    [fetchGAReportDocumentsByProgramId.pending]: (state, action) => {
      state.gaReportDocumentsStatus = 'loading'
    },
    [fetchGAReportDocumentsByProgramId.fulfilled]: (state, action) => {
      state.gaReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.gaReportDocuments = action.payload
    },
    [fetchGAReportDocumentsByProgramId.rejected]: (state, action) => {
      state.gaReportDocumentsStatus = 'failed'
      state.error = action.payload
    },

    [fetchGAReportDocumentById.pending]: (state, action) => {
      state.gaReportDocumentStatus = 'loading'
    },
    [fetchGAReportDocumentById.fulfilled]: (state, action) => {
      state.gaReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.gaReportDocument = action.payload
    },
    [fetchGAReportDocumentById.rejected]: (state, action) => {
      state.gaReportDocumentStatus = 'failed'
      state.error = action.payload
    },
    [addNewGAReportDocument.fulfilled]: (state, action) => {
    //   state.HTMLTemplates.push(action.payload)
    console.log("GAReportDocument add action.payload",action.payload)

    },
    [deleteGAReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("GAReportDocument delete action.payload",action.payload)
    },
    [updateGAReportDocument.fulfilled]: (state, action) => {
    //   const result = state.HTMLTemplates.filter(HTMLTemplate => HTMLTemplate.id !== action.payload.id);
    //   // console.log("HTMLTemplate id from filter from slice",HTMLTemplate.id)
    //   result.push(action.payload)
    //   state.HTMLTemplate = result
      console.log("GAReportDocument update action.payload",action.payload)

    },

  },

})


export default gaReportDocumentsSlice.reducer


