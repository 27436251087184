import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons

import { Link } from "react-router-dom";
import {
  addNewVerificationOfCredibilitySpec, fetchVerificationOfCredibilitiesByCourse, fetchVerificationOfCredibilitiesSpec, fetchVerificationOfCredibilitySpecById,
  verificationOfCredibilitySpecUpdate
} from "../../../../../reduxToolkit/features/VerificationOfCredibility/VerificationOfCredibilitySpecSlice";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import { Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";



import { Breadcrumb } from "antd";
import {

  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";


import { useTranslation } from "react-i18next";

import { Form, notification } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};
// const classes = useStyles();
export default function CreateVerificationCredibility({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { Option } = Select;

  const { id } = match.params;
  const { idVerif } = match.params;
  console.log("idVerif", idVerif);
  const isAddMode = isNaN(idVerif);
  console.log("id from match is add mode", isAddMode);

  const [value, setValue] = React.useState(1);
  const [method_verificationSpec_id, setMethodVerificationSpec] = useState("");
  const [courseSpec_id, setCourse_id] = useState(id);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    dispatch(fetchVerificationOfCredibilitiesByCourse(id))
      .then(unwrapResult)
      .then(() => dispatch(fetchCourseById(id)));
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  console.log("course", course);
  const verifications = useSelector(
    (state) =>
      state.VerificationOfCredibility.VerificationOfCredibilitiesByCourse
  );

  const res = useSelector(
    (state) =>
      state.VerificationOfCredibility.VerificationOfCredibilitySpec
  );
  console.log("verifications", verifications);

  const canSave =
    [method_verificationSpec_id, courseSpec_id].every(Boolean) &&
    addRequestStatus === "idle";

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewVerificationOfCredibilitySpec({ ...values, courseSpec_id })
    );
    if (addNewVerificationOfCredibilitySpec.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(
        fetchVerificationOfCredibilitiesSpec(course && course.id && course.id)
      );
      history.push(
        `/citrine/specification/course/${id}/VerificationCredibilities`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      verificationOfCredibilitySpecUpdate({
        id: idVerif,
        modified: {
          ...values,
        },
      })
    );
    if (verificationOfCredibilitySpecUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(
        fetchVerificationOfCredibilitiesSpec(course && course.id && course.id)
      );
      history.push(
        `/citrine/specification/course/${id}/VerificationCredibilities`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchVerificationOfCredibilitySpecById(idVerif))
        .then(unwrapResult)
        .then((Verif) => {
          console.log(Verif, "Verif");
          form.setFieldsValue({
            method_verificationSpec_id: Verif.method_verificationSpec_id,
            courseSpec_id: Verif.courseSpec_id,
          });
        });
    }
  }, [idVerif]);

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/VerificationCredibilities`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Verification Of Credibilities Spec")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="method_verificationSpec_id"
                        label="Method of verification"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select Method" allowClear>
                          {verifications &&
                            verifications.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/VerificationCredibilities`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/VerificationCredibilities`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Verification Of Credibilities Spec")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="method_verificationSpec_id"
                        label="Method of verification"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select Method" allowClear>
                          {verifications &&
                            verifications.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/VerificationCredibilities`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
