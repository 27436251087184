import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';

export const fetchAreasByTemplateId = createAsyncThunk('surveyMapping/fetchAreasByTemplateId', async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/template/${id}/areas/`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const fetchSubareasByAreasIds = createAsyncThunk('surveyMapping/fetchSubareasByAreasIds', async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/template/${ids.templateId}/subareas/?areasIds=${ids.areasIds}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const fetchQuestionsBySubareasIds = createAsyncThunk('surveyMapping/fetchQuestionsBySubareasIds', async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/template/${ids.templateId}/questions/?subareasIds=${ids.subareasIds}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const affectSurveyTemplateToProgram = createAsyncThunk('surveyMapping/affectSurveyTemplateToProgram', async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.post(BaseUrl + `/programSpec/addSurveyTemplate/`,
            ids,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const generateSurveyQuestions = createAsyncThunk('surveyMapping/generateSurveyQuestions', async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.post(BaseUrl + `/template/${ids}/create/`,
            ids,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const unlinkSurveyTemplatefromProgram = createAsyncThunk('surveyMapping/unlinkSurveyTemplatefromProgram', async (ids, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.post(BaseUrl + `/programSpec/deleteSurveyTemplate/`,
            ids,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const getSurveyQuestionsByResourceGroupedByTemplate = createAsyncThunk('surveyMapping/getSurveyQuestionsByResourceGroupedByTemplate', async (params, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/surveyWeightSpec/by_resource/?resource=${params.resource}&resource_id=${params.resourceId}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const linkSurveyQuestionsToResource = createAsyncThunk('surveyMapping/linkSurveyQuestionsToResource', async (params, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.post(BaseUrl + `/surveyWeightSpec/create/`,
            params,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const getSurveyAreaResourceLinks = createAsyncThunk('surveyMapping/getSurveyAreaResourceLinks', async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyMapping
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/surveyWeightSpec/${id}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response.data)
    }
})

export const linkSurveyAreaFromResource = createAsyncThunk(
    'surveyMapping/linkSurveyAreaFromResource',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.post(
                BaseUrl + `/surveyWeightSpec/create/`,
                data.payload,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                },
            )
            return response.data
        } catch (err) {
            let error = err
            if (!error.response) {
                throw err
            }
            return rejectWithValue(error.response)
        }
    },
)

export const unlinkSurveyAreaFromResource = createAsyncThunk(
    'surveyMapping/unlinkSurveyAreaFromResource',
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.delete(
                BaseUrl + `/surveyWeightSpec/${id}/delete/`,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                },
            )
            return response.data
        } catch (err) {
            let error = err
            if (!error.response) {
                throw err
            }
            return rejectWithValue(error.response)
        }
    },
)

const surveyMappingSlice = createSlice({
    name: 'surveyMapping',
    initialState: {
        areasByTemplate: [],
        subareasByAreas: [],
        questionsBySubareas: [],
        surveyWeightByTemplate: [],
        SurveyQuestionsByResourceGroupedByTemplate: null,
        loading: 'idle',
        currentRequestId: undefined,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAreasByTemplateId.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchAreasByTemplateId.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.areasByTemplate = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchAreasByTemplateId.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(fetchSubareasByAreasIds.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchSubareasByAreasIds.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.subareasByAreas = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchSubareasByAreasIds.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(fetchQuestionsBySubareasIds.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchQuestionsBySubareasIds.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.questionsBySubareas = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchQuestionsBySubareasIds.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(affectSurveyTemplateToProgram.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(affectSurveyTemplateToProgram.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                }
            })
            .addCase(affectSurveyTemplateToProgram.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(getSurveyQuestionsByResourceGroupedByTemplate.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(getSurveyQuestionsByResourceGroupedByTemplate.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.SurveyQuestionsByResourceGroupedByTemplate = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(getSurveyQuestionsByResourceGroupedByTemplate.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(generateSurveyQuestions.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(generateSurveyQuestions.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                }
            })
            .addCase(generateSurveyQuestions.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(unlinkSurveyTemplatefromProgram.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(unlinkSurveyTemplatefromProgram.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                }
            })
            .addCase(unlinkSurveyTemplatefromProgram.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(linkSurveyQuestionsToResource.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(linkSurveyQuestionsToResource.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                }
            })
            .addCase(linkSurveyQuestionsToResource.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(getSurveyAreaResourceLinks.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(getSurveyAreaResourceLinks.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.surveyWeightByTemplate = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(getSurveyAreaResourceLinks.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(unlinkSurveyAreaFromResource.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(unlinkSurveyAreaFromResource.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                }
            })
            .addCase(unlinkSurveyAreaFromResource.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })

            .addCase(linkSurveyAreaFromResource.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(linkSurveyAreaFromResource.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                }
            })
            .addCase(linkSurveyAreaFromResource.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
    },
})

export default surveyMappingSlice.reducer
