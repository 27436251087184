import React, { useEffect, useState } from "react";
import {
  fetchAccessControlModels,
  fetchModels,
  fetchModelById,
  addAccessControlModel,
} from "reduxToolkit/features/model/ModelSlice";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
//import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "../../../components/CustomButtons/Button";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import { Form, notification, Select } from "antd";
import GridContainer from "components/Grid/GridContainer.js";
import ErrorModal from "../../Components/ErrorModal";
import { unwrapResult } from "@reduxjs/toolkit";

export default function AddModel(props) {
  const [fields, setFields] = useState([]);
  const [create_fields, setCreateField] = useState([]);
  const [read_fields, setReadField] = useState([]);
  const [update_fields, setUpdateField] = useState([]);
  const [delete_fields, setDeleteField] = useState([]);
  const [securityGroup, setSecurityGroup] = useState([]);
  const [ManagerGroup, setManagerGroup] = useState([]);
  const [ReaderGroup, setReaderGroup] = useState([]);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { t } = useTranslation();
  //console.log(props);
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const handleCancelClose = () => {
    form.resetFields();

  };
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // message.error('Submit failed!');
  };
  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 12,
      span: 0,
    },
  };

  const models = useSelector((state) => state.model.models);
  const checkEmptyObj = (a, b, c, d) => {
    return (
      a === undefined && b === undefined && c === undefined && d === undefined
    );
  };
  /*******************************************************
   * Add Access Contole to Models
   *
   ******************************************************/
  const onFinish = async (values) => {
    const create = values?.create_fields == undefined ? {} : values?.create_fields.length == 0 ? {} : { fields: values?.create_fields };
    const read = values?.read_fields == undefined ? {} : values?.read_fields.length == 0 ? {} : { fields: values?.read_fields };
    const update = values?.update_fields == undefined ? {} : values?.update_fields.length == 0 ? {} : { fields: values?.update_fields };
    const delet = values?.delete_fields == undefined ? {} : values?.delete_fields.length == 0 ? {} : { fields: values?.delete_fields };
    console.log({
      model_id: props.selected[0],
      security_group_ids: securityGroup,
      manager_group_ids: ManagerGroup,
      reader_group_ids: ReaderGroup,
      create: create,
      read: read,
      update: update,
      delete: delet,
    })
    const resultAction = await dispatch(
      addAccessControlModel({
        model_id: props.selected[0],
        security_group_ids: securityGroup,
        manager_group_ids: ManagerGroup,
        reader_group_ids: ReaderGroup,
        create: create,
        read: JSON.stringify(read || {}),
        update: JSON.stringify(update || {}),
        delete: delet,
      })
    );
    if (addAccessControlModel.fulfilled.match(resultAction)) {
      if (
        checkEmptyObj(
          values?.create_fields,
          values?.read_fields,
          values?.update_fields,
          values?.delete_fields
        )
      ) {
        openNotificationWithIcon("success", `Add successfully`);
      } else {
        openNotificationWithIcon("success", `Add successfully`);
      }
      dispatch(fetchModels());
      props.handleClose();
      handleCancelClose()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        props.handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        props.handleClose();
      }
    }
  };

  /*******************************************************
   * get data from database and set as an initialValue
   *
   ******************************************************/
  // const testing =  dispatch(fetchModelById(props.selected))
  //console.log(testing)
  useEffect(() => {
    if (props.selected.length != 0) {
      dispatch(fetchModelById(props.selected))
        .then(unwrapResult)
        .then((model) => {
          //console.log(`${model} testing`)
          form.setFieldsValue({
            create_fields: model?.modelsPermissions?.create?.fields,
            read_fields: model?.modelsPermissions?.read?.fields,
            update_fields: model?.modelsPermissions?.update?.fields,
            delete_fields: model?.modelsPermissions?.delete?.fields,
            security_group: model?.security_group_ids,
            reader_group: model?.reader_group_ids,
            manager_group: model?.manager_group_ids
          });
          setSecurityGroup(model.security_group_ids)
          setReaderGroup(model.reader_group_ids)
          setManagerGroup(model.manager_group_ids)
        });
    }
    dispatch(fetchModels());
    dispatch(fetchAccessControlModels());
  }, [props.selected]);
  /* useEffect(()=>{
    dispatch(fetchModels());
    dispatch(fetchAccessControlModels());


  },[dispatch]) */

  const modelListSelected =
    models && models.filter((model) => model.id == props.selected);
  const fieldMap =
    models &&
    models
      .filter((model) => model.id == props.selected)[0]
      ?.fields_models?.map((el) => (
        <Option key={el} value={el}>
          {el}
        </Option>
      ));
  const handleSecurityGroup = (e) => {
    setSecurityGroup(e);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose();
          handleCancelClose();
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <center> {t("Add Access Control")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <Form.Item
                  label="Object"
                  name="id"
                  rules={[
                    {
                      message: "Please select the Model",
                    },
                  ]}
                >
                  <Select
                    disabled
                    style={{ width: "80%", height: "50px" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={modelListSelected[0]?.name}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  ></Select>
                </Form.Item>
                {/***************************************************************** */}
                <Form.Item
                  label="Security group"
                  name="security_group"
                  rules={[
                    {
                      required: true,
                      message: "Please select the Security group",
                    },
                  ]}
                  initialValue={securityGroup}
                >
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Add a security group"
                    showSearch
                    value={securityGroup}
                    onChange={handleSecurityGroup}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {props.groups &&
                      props.groups.map((group, key) => (
                        <Option key={key} value={group.id}>
                          {group.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Manager group"
                  name="manager_group"
                  rules={[
                    {
                      required: true,
                      message: "Please select the Manager group",
                    },
                  ]}
                  initialValue={ManagerGroup}

                >
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Add a manager group"
                    showSearch
                    // value={ManagerGroup}

                    onChange={(e) => setManagerGroup(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {securityGroup.map(
                      (m, key) =>
                        props.groups &&
                        props.groups.map((item) =>
                          item.id == m ? (
                            <Select.Option key={key} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ) : (
                            <></>
                          )
                        )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Reader group"
                  name="reader_group"
                  rules={[
                    {
                      required: true,
                      message: "Please select the Reader group",
                    },
                  ]}
                  initialValue={ReaderGroup}

                >
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Add a reader group"
                    showSearch
                    //value={ReaderGroup}

                    onChange={(e) => setReaderGroup(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {securityGroup.map(
                      (m, key) =>
                        props.groups &&
                        props.groups.map((item) =>
                          item.id == m ? (
                            <Select.Option key={key} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ) : (
                            <></>
                          )
                        )
                    )}
                  </Select>
                </Form.Item>
                {/***************************************************************** */}
                {/* <Form.Item
                  label="Create Fields"
                  name="create_fields"
                  rules={[
                    {
                      required: false,
                      message: "Please select the Create Fields",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Create Fields"
                    showSearch
                    onChange={(e) => setCreateField(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fieldMap}
                  </Select>
                </Form.Item> */}

                <Form.Item
                  label="Read Fields"
                  name="read_fields"
                  rules={[
                    {
                      required: false,
                      message: "Please select the Read Fields",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Read Fields"
                    showSearch
                    onChange={(e) => setReadField(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fieldMap}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Update Fields"
                  name="update_fields"
                  rules={[
                    {
                      required: false,
                      message: "Please select the Update Fields",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Update Fields"
                    showSearch
                    onChange={(e) => setUpdateField(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fieldMap}
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  label="Delete Fields"
                  name="delete_fields"
                  rules={[
                    {
                      required: false,
                      message: "Please select the Delete Fields",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: "80%" }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Delete Fields"
                    showSearch
                    onChange={(e) => setDeleteField(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fieldMap}
                  </Select>
                </Form.Item> */}
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={() => {
                      props.handleClose();
                      handleCancelClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" /*htmlType="submit"*/>
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
