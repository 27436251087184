import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb, notification } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Chip from '@material-ui/core/Chip'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, usePrams } from 'react-router-dom'
import AddStateActionGroupModal from 'views/Components/AddStateActionGroupModal'
import DropDownGroupStateButton from './DropDownGroupStateButton'
import ErrorModal from 'views/Components/ErrorModal'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { fetchGroupStates } from 'reduxToolkit/features/groupState/GroupStateSlice'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const GroupStateExcerpt = ({ ping, setPing, groupState, Report }) => {
  const { t } = useTranslation()
  console.log(groupState);
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const dispatch = useDispatch()
  const groupsList = useSelector((state) => state.userGroup.userGroups)

  console.log(groupsList)

  const groups = groupsList?.filter((el) =>
    groupState?.group_ids?.includes(el.id),
  )
  //const groups = groupState.group_ids
  console.log(groups)
  return (
    <GridContainer
      md={4}
      xs={12}
      sm={12}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <GridItem key={groupState.id} Report={Report}>
        <Card product className={classes.cardHover}>
          <CardBody style={{ width: 375, height: 200 }}>
            <GridContainer>
              {/* < GridItem  xs={12} sm={12} md={6}>
                <img src={priceImage1} alt="..." width="100%" height="160" />
              </GridItem> */}

              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardProductTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <Link
                          to={{
                            pathname: `/citrine/groupActions/states/${Report}/${groupState.id}/actions/`,
                            state: { action: groupState.name_details },
                          }}
                        >
                          {groupState?.name_details}
                        </Link>
                      </a>
                    </h4>
                    <span>
                      {' '}
                      <strong style={{ color: '#89229B' }}>Index :</strong>{' '}
                      {groupState?.index}
                    </span>{' '}
                    <br />
                    <span>
                      <strong style={{ color: '#89229B' }}>Groups : </strong>{' '}
                      {groups &&
                        groups.map((el) => {
                          return <span>{el?.name}, </span>
                        })}
                    </span>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <DropDownGroupStateButton
                      groupState={groupState}
                      ping={ping}
                      setPing={setPing}
                      id={groupState.id}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default function CourseReportStates({ match }) {
  const { t } = useTranslation()
  const [ping, setPing] = useState(false)
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const { Report } = match.params
  console.log('Reporteeeeeeeeeeeeeeeeel', Report)
  const groupStatesStatus = useSelector(
    (state) => state.groupState.groupStatesStatus,
  )

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const fetchAllGroupStates = async () => {
    try {
      const resultAction = await dispatch(fetchGroupStates(Report)).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  useEffect(() => {
    fetchAllGroupStates()
  }, [ping])

  const groupStates = useSelector((state) => state.groupState.groupStates)
  console.log('groupStates', groupStates)
  const error = useSelector((state) => state.groupState.error)
  let content

  if (groupStatesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (groupStatesStatus === 'succeeded') {
    content =
      groupStates &&
      groupStates.map((groupState) => (
        <GroupStateExcerpt
          ping={ping}
          setPing={setPing}
          key={groupState.id}
          groupState={groupState}
          Report={Report}
        />
      ))
  } else if (groupStatesStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/groupActions/`}>{t('Models')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{Report}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <AddStateActionGroupModal model={Report} />

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
