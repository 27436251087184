import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePlo,
  fetchPlos,
} from "../../reduxToolkit/features/PLO/PLOSpecSlice";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select, DatePicker, Input } from "antd";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import { fetchPeoById } from "../../reduxToolkit/features/PEO/PEOSpecSlice";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { fetchPloRecommendations } from "../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { addNewActionRecommendation } from "../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { fetchAllUsers } from "../../reduxToolkit/features/user/UserSlice";
import { fetchActionTypes } from "reduxToolkit/features/actionType/ActionTypeSlice";
import Avatar from "components/Avatar/Avatar"


const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);
export default function ActionRecommendationModal(props) {
  //console.log(props.program_id)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { Option, OptGroup } = Select;
  const { TextArea } = Input;

  const [start_date, setStart_date] = useState([]);
  const [due_date, setDue_date] = useState([]);
  const [description, setDescription] = useState(null);
  const [priority, setPriority] = useState(null);
  const [type_action, setType_action] = useState(null);
  const [assign_to_id, setAssign_to_id] = useState(null);
  const [recommendation_id, setRecommendation_id] = useState(
    props.recommendation_id
  );
  const [resource, setResource] = useState("Plo");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  function handleChange(value) {
    console.log("value", value);
    setType_action(value);
  }
  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");

  useEffect(() => {
    dispatch(fetchActionTypes())
  }, [dispatch])
  const { actionTypes } = useSelector((state) => state.ActionType)
  console.log(actionTypes)

  const actionsTypes = useSelector((state) => state.ActionType.actionTypes)
  console.log(actionsTypes)
  const handleSend = async () => {
    //let resource_id = props.idPloReport



    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        addNewActionRecommendation({
          type_action,
          priority,
          due_date,
          start_date,
          description,
          assign_to_id,
          recommendation_id,
          resource: "program",
          resource_id: props.program_id
        })
      ).then(() => {
        dispatch(fetchPloRecommendations(props.plo_id))
          .then(unwrapResult)
          .then((Recommendations) => {
            props.setPloRecommendations(Recommendations);
          });
      });
      setDue_date("");
      setStart_date("");
      setDescription("");
      setType_action("");
      setAssign_to_id("");
      setPriority("");
      setResource("");
    } catch (err) {
      console.error("Failed to save the action: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  function onChangeDue_date(date, dateString) {
    setDue_date(dateString);
  }
  function onChangeStart_date(date, dateString) {
    setStart_date(dateString);
  }

  function onOk(value) {
    console.log("onOk: ", value);
  }

  function onChange(value) {
    console.log(`selected Assign_to_id ${value}`);
    setAssign_to_id(value);
  }
  function onChangePriority(value) {
    console.log(`selected Priority ${value}`);
    setPriority(value);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  console.log('props.users', props.users)

  return (
    <div>
      <Button
        color="primary"
        style={{
          border: "1px solid #D1CF4B",
          backgroundColor: "#D1CF4B",
          borderRadius: "30px",
          fontSize: "10px",
          height: "30px",
          minWidth: "30px",
          width: "30px",
          marginTop: "-10px",
          marginBottom: "5px",
        }}
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <AddIcon style={{ color: "white", fontSize: "15px" }} />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Add Action Recommendation")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Select Type Action*")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              {/* <Select
                showArrow="true"
                style={{ width: 400 }}
                dropdownStyle={{ zIndex: 10000 }}
                placeholder={t("Select Type")}
                defaultValue="Select Type"
                onChange={handleChange}
                name="type_action"
                value={type_action}
              >
                {actionsTypes?.map((actionType)=>(
                      <Option key={actionType.id} value={actionType.id}>
                      {actionType.name}
                    </Option>
                    ))}
                {/* <OptGroup label="Teachning Process">
                  <Option value={t("Teachning Strategy")}>
                    {t("Teachning Strategy")}
                  </Option>
                  <Option value={t("Weekly Distribution")}>
                    {t("Weekly Distribution")}
                  </Option>
                  <Option value={t("Teachning Material")}>
                    {t("Teachning Material")}
                  </Option>
                  <Option value={t("Mode Of Instructions")}>
                    {t("Mode Of Instructions")}
                  </Option>
                  <Option value={t("Learning Resources")}>
                    {t("Learning Resources")}
                  </Option>
                  <Option value={t("Facilities")}>{t("Facilities")}</Option>
                </OptGroup>
                <OptGroup label="Assessment">
                  <Option value={t("Planning")}>{t("Planning")}</Option>
                  <Option value={t("Sampling")}>{t("Sampling")}</Option>
                  <Option value={t("Assessment Method")}>
                    {t("Assessment Method")}
                  </Option>
                </OptGroup>
                <OptGroup label="Curriculum">
                  <Option value={t("Update Syllabus")}>
                    {t("Update Syllabus")}
                  </Option>
                  <Option value={t("Update Extra-curricular Activities")}>
                    {t("Update Extra-curricular Activities")}
                  </Option>
                </OptGroup>
                <OptGroup label="Alignment">
                  <Option value={t("Mapping CLO-Assessment Method")}>
                    {t("Mapping CLO-Assessment Method")}
                  </Option>
                  <Option value={t("Mapping PLO Ind - CLO")}>
                    {t("Mapping PLO Ind - CLO")}
                  </Option>
                  <Option value={t("Mapping GA Ind - CLO")}>
                    {t("Mapping GA Ind - CLO")}
                  </Option>
                  <Option value={t("Mapping PLO Ind - PLO")}>
                    {t("Mapping PLO Ind - PLO")}
                  </Option>

                  <Option value={t("Mapping GA Ind - GA")}>
                    {t("Mapping GA Ind - GA")}
                  </Option>

                  <Option value={t("Mapping PEO - PLO/GA")}>
                    {t("Mapping PEO - PLO/GA")}
                  </Option>
                </OptGroup>
              </Select> */}
              <Select
                showArrow="true"
                style={{ width: 400 }}
                dropdownStyle={{ zIndex: 10000 }}
                onChange={handleChange}
                placeholder={t("Select Type")}
              >


                {actionTypes?.filter(actionType => actionType.category === "program").map((item) => (
                  <Option value={item.id}>{item.name}</Option>
                ))}

              </Select>
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Assign to * ")}</label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <Select
                showArrow="true"
                style={{ width: 400 }}
                dropdownStyle={{ zIndex: 10000 }}
                placeholder={t("Select user")}
                defaultValue="Select user"
                onChange={onChange}
                name="assign_to_id"
                value={assign_to_id}
              >
                {props.users?.map((p) => (
                  <Option key={p.id} value={p.id}>
                    <GridContainer justifyContent='flex-start' alignItems="baseline"><GridItem><Avatar url={p.person_details?.[0]?.avatar} /></GridItem><GridItem> <p>{p.person_details?.[0]?.first_name}  {p.person_details?.[0]?.last_name}</p></GridItem></GridContainer>
                  </Option>
                ))}
              </Select>
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Priority* ")}</label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <Select
                showArrow="true"
                style={{ width: 400 }}
                dropdownStyle={{ zIndex: 10000 }}
                placeholder={t("Select Priority")}
                defaultValue="Select Priority"
                onChange={onChangePriority}
                name="priority"
                value={priority}
              >
                <Option value={`${1}`}>{t("High")}</Option>

                <Option value={`${2}`}>{t("Medium")}</Option>

                <Option value={`${3}`}>{t("Low")}</Option>
              </Select>
            </GridItem>
          </GridContainer>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Description*")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <TextArea
                rows={4}
                id="description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Start date* ")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <DatePicker
                //showTime
                onChange={onChangeDue_date}
                onOk={onOk}
                style={{ zIndex: "10000  !important" }}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Due Date* ")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <DatePicker
                //showTime
                onChange={onChangeStart_date}
                onOk={onOk}
                style={{ zIndex: "10000  !important" }}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </GridItem>
          </GridContainer>


        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleSend();
            }}
            color="primary"
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
