import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import Button from '../../components/CustomButtons/Button'
// nodejs library to set properties for components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import GridItem from 'components/Grid/GridItem.js'

import GridContainer from 'components/Grid/GridContainer.js'
//import { Checkbox } from 'antd'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Select from '@mui/material/Select'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// material-ui icons
import MoreVert from '@material-ui/icons/MoreVert'


import { unwrapResult } from '@reduxjs/toolkit'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { deleteAssessmentMethod, fetchAssessmentMethods } from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import { fetchEvidenceTypeStatesConfigs } from 'reduxToolkit/features/EvidenceType/EvidenceTypeSlice'
import ErrorModal from './ErrorModal'
import SuperGeneralDeleteModal from './SuperGeneralDeleteModal'

const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)
const { Option } = Select

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))
export default function DropDownAssessmentMethodButton(props) {
  const { t } = useTranslation()
  const buttonClasses = useButtonStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const dispatch = useDispatch()

  const classes = useStyles()
  const modesStatus = useSelector(
    (state) => state.assessmentModeSpec.assessmentModesStatus,
  )

  useEffect(() => {
    if (modesStatus === 'idle') {
      //dispatch(fetchAssessmentModes())
      dispatch(fetchEvidenceTypeStatesConfigs())
    }
  }, [modesStatus, dispatch])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleDeleteItem = async (id) => {
    dispatch(deleteAssessmentMethod(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon('success', `Deleted`)

        console.log('originalPromiseResult', originalPromiseResult)
        dispatch(fetchAssessmentMethods())
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          'rejectedValueOrSerializedError',
          rejectedValueOrSerializedError,
        )
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    Height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const onChangeRequiredEvidence = (e) => {
    setRequiredEvidence(e.target.checked)
    //console.log(e.target.checked)
  }
  const onChangeEvidenceTypeValue = (e) => {
    //console.log(e.target.value)
    setEvidenceTypeValue(e.target.value)
  }
  const [openModalEvidence, setOpenModalEvidence] = useState(false)
  const handleOpenEvidence = () => setOpenModalEvidence(true)
  const handleCloseEvidence = () => setOpenModalEvidence(false)
  const [evidenceTypeValue, setEvidenceTypeValue] = useState('')
  const [requiredEvidence, setRequiredEvidence] = useState(false)

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  const types = useSelector(
    (state) => state.EvidenceTypeStatesConfig.EvidenceTypeStatesConfigs,
  )

  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleOpenEvidence}><ListItemText primary="Evidence Type" /></MenuItem> */}
        <MenuItem><Link to={`/citrine/createAssessmentMethod/${props.id}/`}>{t('Edit')}</Link></MenuItem>
        <MenuItem onClick={() => { handleOpenDeleteModal(); handleClose() }} ><ListItemText primary="Delete" /> </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
      {/*Add Evidence Modal */}
      <Modal
        open={openModalEvidence}
        onClose={handleCloseEvidence}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h2>Add Evidence Type</h2>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <form>
                      <GridContainer
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <GridItem xs={1} sm={6} md={6}>
                          <label>{t('Evidence Type ')}</label>
                          <Select
                            //multiple
                            showSearch
                            style={{ width: 350 }}
                            size={200}
                            optionFilterProp="children"
                            name="mode_id"
                            //value={evidenceTypeValue}
                            onChange={onChangeEvidenceTypeValue}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {types.map((el) => (
                              <MenuItem value={el.type}>{el.type}</MenuItem>
                            ))}
                          </Select>

                          <FormGroup>
                            <FormControlLabel
                              onChange={onChangeRequiredEvidence}
                              control={<Checkbox defaultChecked />}
                              label="Required"
                            />
                          </FormGroup>
                        </GridItem>
                        <GridItem>
                          <Button style={{ bottom: 3, float: 'right' }}
                            onClick={() => {
                              alert(evidenceTypeValue + ' ' + requiredEvidence)
                            }}
                            color="info"
                          //className={classes.updateProfileButton}
                          >
                            {t(' Save')}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}
