import "@lourenci/react-kanban/dist/styles.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "../../improvement.css";
import Comments from "../Student Results/Comments";
import DistributionOfGrades from "../Student Results/DistributionOfGrades";


const { TabPane } = Tabs;
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
export default function StudentResultsPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => { }, [dispatch]);

  return (
    <div>
      <Tabs
        tabPosition={"top"}
        style={{
          border: "1px solid lightgray",
          flex: "1",
          paddingLeft: "15px",
        }}
      >
        <TabPane
          tab={t("Distribution of Grades")}
          key="1"
          style={{ marginLeft: "10px" }}
        >
          <DistributionOfGrades idCourse={props.idCourse} />
        </TabPane>
        <TabPane tab={t("Comment on Student Results")} key="2">
          <Comments idCourse={props.idCourse} />
        </TabPane>
      </Tabs>
    </div>
  );
}
