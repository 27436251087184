import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect } from "react";
// core components
import {
  grayColor,
  primaryColor
} from "assets/jss/material-dashboard-pro-react";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Tabs } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

import ResearchAndInnovationOutputsDropdownButton from "views/Components/ResearchAndInnovationOutputsDropdownButton";
const { TabPane } = Tabs;
const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const ResearchAndInnovationExcerpt = ({ research, personId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch();

  return (
    <GridItem xs={12} sm={4} md={4} key={research.id}>
      <Card style={{ maxHeight: "280px", minHeight: "200px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link
                to={`/citrine/faculty/teachingStaff/${personId}/researchAndInnovationActivityOutputs/${research.id}/${research.type}`}
              >
                <strong
                  style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}
                >
                  <h4 className={cardClasses.cardProductTitle}>
                    {research && research.title}
                  </h4>
                </strong>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <ResearchAndInnovationOutputsDropdownButton
                id={research.id}
                research={research}
                person_id={personId}
              />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Type Of Research")}:
            <span style={{ fontWeight: "normal" }}>
              {" "}
              {research && research.type && research.type}
            </span>
          </div>
          {/* <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("College")}:
            <span style={{ fontWeight: "normal" }}>
              {research &&
                research.colleges_details &&
                research.colleges_details[0] &&
                research.colleges_details[0].name}
            </span>
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Department")}:
            <span style={{ fontWeight: "normal" }}>
              {research &&
                research.departments_details &&
                research.departments_details[0] &&
                research.departments_details[0].name}
            </span>
          </div> */}

        </CardBody>
        <CardFooter>
          <GridContainer xs={2} sm={2} md={12}>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "start" }}>
              <span
                style={{
                  color: "#FFCB3B",
                }}
              ></span>
            </GridItem>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "end" }}>
              <span
                style={{
                  color: research.state === "Draft" ? grayColor[1] : "#FFCB3B",
                }}
              >
                {research && research.state}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function BookChapter(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const callback = (key) => {
    console.log(key);
  };
  const { id } = props.id;
  let person_id = id
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  useEffect(() => {
    //  dispatch(fetchStaffById(person_id))
    // dispatch(fetchResearchAndDevelopmentOutputs(person_id));
  }, []);

  const person = useSelector((state) => state.user.staff);
  const ResearchAndDevelopmentOutputsStatus = useSelector(
    (state) =>
      state.ResearchAndDevelopmentOutputs.ResearchAndDevelopmentOutputsStatus
  );
  //   const ResearchAndDevelopmentOutputs = useSelector(
  //     (state) => state.ResearchAndDevelopmentOutputs.ResearchAndDevelopmentOutputs
  //   );

  //   console.log("ResearchAndDevelopmentOutputs", ResearchAndDevelopmentOutputs);

  let content;

  if (ResearchAndDevelopmentOutputsStatus === "loading") {
    content = <div className="loader">Loading...</div>;
  } else if (ResearchAndDevelopmentOutputsStatus === "succeeded") {
    content =
      props.ResearchAndDevelopmentOutputs?.filter(item => item.type === "Book Chapter").map(research => {
        return <ResearchAndInnovationExcerpt
          key={research && research.id}
          research={research}
          personId={id}
        />
      });
  } else if (ResearchAndDevelopmentOutputsStatus === "failed") {
    content = <div>An error has accured </div>;
  }
  console.log('props.ResearchAndDevelopmentOutputs', props.ResearchAndDevelopmentOutputs)
  return (
    <div>

      <GridContainer xs={12} sm={12} md={12}>

        {content}
      </GridContainer>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
