import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
export default function StudentDetailsModal(props) {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}

            >
                <DialogTitle id="alert-dialog-title">
                    <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem><h4>Student</h4></GridItem>
                        <GridItem>
                            <Button
                                // aria-describedby={id}
                                aria-controls="customized-menu"
                                variant="contained"
                                color="danger"
                                onClick={props.handleClose}
                                justIcon
                                round
                                aria-haspopup="true"
                            >
                                <CloseIcon />
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        student details
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="danger" autoFocus>
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}