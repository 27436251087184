import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import token from 'assets/utils/accessToken'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  ActualLearningHours: [],
  ActualLearningHourStatus: 'idle',
  ActualLearningHourStatus: 'idle',
  error: null,
  ActualLearningHour: null,
  Total: null,

  id: null,
}

export const fetchActualLearningHours = createAsyncThunk(
  ' ActualLearningHour/fetch ActualLearningHours',
  async (course_id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl + `/actualLearningHours/?course_id=${course_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    )
    console.log(response.data, 'response.data')

    return response.data
  },
)

export const fetchActualLearningHourById = createAsyncThunk(
  'ActualLearningHour/fetchActualLearningHourById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl + `/actualLearningHoursSpec/${id}/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data,'response.data')

    return response.data
  },
)
export const addNewActualLearningHour = createAsyncThunk(
  'ActualLearningHour/addNewActualLearningHour',
  async (initialActualLearningHour) => {
    const token = localStorage.getItem('token')
    console.log('initial ActualLearningHour', initialActualLearningHour)
    const response = await axios.post(
      BaseUrl + `/actualLearningHoursSpec/create/`,
      initialActualLearningHour,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data)
    return response.data
  },
)

export const fetchTotalActualLearningHour = createAsyncThunk(
  'ActualLearningHour/fetchTotalActualLearningHour',
  async (initialActualLearningHour) => {
    const token = localStorage.getItem('token')
    console.log('initial ActualLearningHour', initialActualLearningHour)
    const response = await axios.post(
      BaseUrl + `/actualLearningHours/Total/`,
      initialActualLearningHour,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data)
    return response.data
  },
)

export const ActualLearningHourUpdate = createAsyncThunk(
  'ActualLearningHour/ActualLearningHourUpdate',
  async (data, {rejectWithValue}) => {
    console.log(data)
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/actualLearningHours/${data.id}/edit/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(data, 'updatedActualLearningHour')
      console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      console.error('Failed to update the ActualLearningHour: ', err)
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const deleteActualLearningHour = createAsyncThunk(
  'ActualLearningHour/deleteActualLearningHour',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(
      BaseUrl + `/actualLearningHoursSpec/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data, ' delete response.data')

    return response.data
  },
)
const ActualLearningHourSlice = createSlice({
  name: 'ActualLearningHour',
  initialState,

  extraReducers: {
    [fetchActualLearningHours.pending]: (state, action) => {
      state.ActualLearningHourStatus = 'loading'
    },
    [fetchActualLearningHours.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ActualLearningHours = action.payload
    },
    [fetchActualLearningHours.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      state.error = action.payload
    },

    [fetchTotalActualLearningHour.pending]: (state, action) => {
      state.ActualLearningHourStatus = 'loading'
    },
    [fetchTotalActualLearningHour.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = 'succeeded'
      state.Total = action.payload
    },
    [fetchTotalActualLearningHour.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      state.error = action.payload
    },
    [fetchActualLearningHourById.pending]: (state, action) => {
      state.ActualLearningHourStatus = 'loading'
    },
    [fetchActualLearningHourById.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = 'succeeded'

      // Add any fetched posts to the array
      state.ActualLearningHour = action.payload
    },
    [fetchActualLearningHourById.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      state.error = action.payload
    },
    [addNewActualLearningHour.fulfilled]: (state, action) => {
      state.ActualLearningHours.push(action.payload)
    },
    [deleteActualLearningHour.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [ActualLearningHourUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [ActualLearningHourUpdate.rejected]: (state, action) => {
      console.log(action)
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default ActualLearningHourSlice.reducer

export const selectAllFacilitiesRequired = (state) =>
  state.ActualLearningHour.ActualLearningHours
