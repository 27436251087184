import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';
const initialState = {
    AssessmentTasksForStudents: [],
    AssessmentTasksForStudentStatus: 'idle',
    AssessmentTasksForStudentStatus: 'idle',
    error: null,
    AssessmentTasksForStudent: null,
    id: null
}


export const fetchAssessmentTasksForStudents = createAsyncThunk(
  'AssessmentTasksForStudent/fetchAssessmentTasksForStudents', 
  async (course_id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/assessmentTasksForStudentsSpec/?courseSpec=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    });

    console.log(response.data, 'response.data')

    return response.data;
  }
  catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);
  export const fetchAssessmentTasksForStudentById = 
  createAsyncThunk(
    'AssessmentTasksForStudent/fetchAssessmentTasksForStudentById', 
    async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/assessmentTasksForStudentsSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data /////')

    
       return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );
export const addNewAssessmentTasksForStudent = createAsyncThunk(
    'AssessmentTasksForStudent/addNewAssessmentTasksForStudent',
    async (initialAssessmentTasksForStudent, { rejectWithValue }) => {
        console.log('initial AssessmentTasksForStudent', initialAssessmentTasksForStudent)
        const token=localStorage.getItem('token')
        try {
        const response = await axios.post(BaseUrl+`/assessmentTasksForStudentsSpec/create/`, initialAssessmentTasksForStudent, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
  export const AssessmentTasksForStudentUpdate = createAsyncThunk(
    'AssessmentTasksForStudent/AssessmentTasksForStudentUpdate',
    async (data, { rejectWithValue }) => {
        const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/assessmentTasksForStudentsSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedAssessmentTasksForStudent')
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteAssessmentTasksForStudent = createAsyncThunk(
  'AssessmentTasksForStudent/deleteAssessmentTasksForStudent', 
  async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(
      BaseUrl+`/assessmentTasksForStudentsSpec/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
     console.log(response.data,' delete response.data')

     return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const AssessmentTasksForStudentSlice = createSlice({
    name: 'AssessmentTasksForStudent',
    initialState,

    extraReducers: {
        [fetchAssessmentTasksForStudents.pending]: (state, action) => {
            state.AssessmentTasksForStudentStatus = 'loading'
        },
        [fetchAssessmentTasksForStudents.fulfilled]: (state, action) => {
            state.AssessmentTasksForStudentStatus = 'succeeded'
            // Add any fetched posts to the array
            state.AssessmentTasksForStudents = action.payload
        },
        [fetchAssessmentTasksForStudents.rejected]: (state, action) => {
            state.AssessmentTasksForStudentStatus = 'failed'
            if (action.payload) {
              state.error = action.payload;
           } else {
              state.error = action.error;
            }
          },

          [fetchAssessmentTasksForStudentById.pending]: (state, action) => {
            state.AssessmentTasksForStudentStatus = 'loading'
          },
          [fetchAssessmentTasksForStudentById.fulfilled]: (state, action) => {
            state.AssessmentTasksForStudentStatus = 'succeeded'

            // Add any fetched posts to the array
            state.AssessmentTasksForStudent = action.payload
          },
          [fetchAssessmentTasksForStudentById.rejected]: (state, action) => {
            state.AssessmentTasksForStudentStatus = 'failed'
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },
        [addNewAssessmentTasksForStudent.fulfilled]: (state, action) => {
            state.AssessmentTasksForStudents.push(action.payload)
        },
        [deleteAssessmentTasksForStudent.fulfilled]: (state, action) => {
           // state.SpecificationAprovalDatas.push(action.payload)
        },
          [AssessmentTasksForStudentUpdate.fulfilled]: (state, action) => {
            console.log(action) 
          },
          [addNewAssessmentTasksForStudent.rejected]: (state, action) => {
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },
        [deleteAssessmentTasksForStudent.rejected]: (state, action) => {
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
        },
          [AssessmentTasksForStudentUpdate.rejected]: (state, action) => {
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },

        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },


            // AssessmentTasksForStudentUpdated(state, action) {
            //     const { id, name } = action.payload
            //     const existingAssessmentTasksForStudent = state.AssessmentTasksForStudents.find(AssessmentTasksForStudent => AssessmentTasksForStudent.id === id)
            //     if (existingAssessmentTasksForStudent) {
            //         existingAssessmentTasksForStudent.name = name


            //     }
            // }
        }
    }
})

export const {
    //AssessmentTasksForStudentAdded, 
    //AssessmentTasksForStudentUpdated, 
    getId } = AssessmentTasksForStudentSlice.actions

export default AssessmentTasksForStudentSlice.reducer

export const selectAllAssessmentTasksForStudent = state => state.AssessmentTasksForStudentss.AssessmentTasksForStudents

