import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  PloReports: [],
  PLOReportsStatus: 'idle',
  PloStatus: 'idle',
  error: null,
  PloReport: null,
  id: null,
}

export const fetchPloReports = createAsyncThunk(
  'PloReport/fetchPloReports',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/ploReport/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    // const response = jsonData
    console.log(response.data, 'response.data course')

    return response.data
  },
)
export const fetchPloReportById = createAsyncThunk(
  'PloReport/fetchPloReportById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/ploReport/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })

    return response.data
  },
)
export const addNewPloReport = createAsyncThunk(
  'PloReport/addNewPloReport',
  async (initialPloReport, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/ploReport/create/`,
        initialPloReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const deletePloReport = createAsyncThunk(
  'PloReport/deletePloReport',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/ploReport/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    console.log(response.data, ' delete response.data')

    return response.data
  },
)

export const UpdatePloReport = createAsyncThunk(
  'PloReport/UpdatePloReport',
  async (data, thunkAPI) => {
    console.log(data, 'UpdatedPloReport')
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(
        BaseUrl + `/ploReport/${data.id}/edit/`,
        data.updatedPloReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(data, 'updatedploReport')
      console.log(response.data, 'response.data')
      return response.data
    } catch (error) {
      console.error('Failed to update the courseReport: ', error)
    }
  },
)

export const AffectCoordinatorToPLOReport = createAsyncThunk(
  'ploReport/AffectCoordinatorToPLOReport',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/ploReport/update/${data.id}/`,
        data.data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //  console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
const PloReportSlice = createSlice({
  name: ' PloReport',
  initialState,
  extraReducers: {},
  reducers: {
    PloReportUpdate(state, action) {
      const { id, program, state_object, due_date, sent_to } = action.payload
      const existingPloReport = state.PloReports.find(
        (PloReport) => PloReport.id === id,
      )
      if (existingPloReport) {
        existingPloReport.program = program
        existingPloReport.state_object = state_object
        existingPloReport.due_date = due_date
        existingPloReport.sent_to = sent_to
      }
    },
  },
  extraReducers: {
    // fetchPloReports
    [fetchPloReports.pending]: (state, action) => {
      state.PLOReportsStatus = 'loading'
    },
    [fetchPloReports.fulfilled]: (state, action) => {
      state.PLOReportsStatus = 'succeeded'
      state.PloReports = action.payload
    },
    [fetchPloReports.rejected]: (state, action) => {
      state.PLOReportsStatus = 'failed'
      state.error = action.payload
    },
    // fetchPloReportById
    [fetchPloReportById.pending]: (state, action) => {
      state.PloStatus = 'loading'
    },
    [fetchPloReportById.fulfilled]: (state, action) => {
      state.PloStatus = 'succeeded'
      state.PloReport = action.payload
    },
    [fetchPloReportById.rejected]: (state, action) => {
      state.PloStatus = 'failed'
      state.error = action.payload
    },
    // addNewPloReport
    [addNewPloReport.fulfilled]: (state, action) => {
      //state.PloReports.push(action.payload)
    },
    // deletePloReport
    [deletePloReport.fulfilled]: (state, action) => {
      // console.log(action)
    },
    //PloReportUpdate
    [UpdatePloReport.fulfilled]: (state, action) => {
      // console.log(action)
    },
  },
})

export const { PloReportAdded, PloReportUpdate } = PloReportSlice.actions

export default PloReportSlice.reducer
