// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice';





const useStyles = makeStyles(styles);
export default function ProgramAssessmentDetails({ match }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = match.params


    const dispatch = useDispatch()
    const programStatus = useSelector((state) => state.program.programStatus)
    const error = useSelector((state) => state.program.error)

    useEffect(() => {
        dispatch(fetchProgramById(id))

    }, [dispatch])


    const program = useSelector((state) => state.program.program)

    console.log('program', program)

    if (!program) {
        return (
            <section>
                <h2>Program not found!</h2>
            </section>
        )
    }

    return (
        <div>

        </div>
    )
}