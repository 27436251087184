import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  branches: [],
  branchesStatus: 'idle',
  brancheStatus: 'idle',
  error: null,
  branche: null,
  id: null
}

export const fetchBranches = createAsyncThunk('branche/fetchBranches', async (param, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {

  const response = await axios.get(BaseUrl+'/branches/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

   return response.data

  } catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
  }
  })
  

export const fetchBrancheById = createAsyncThunk('branche/fetchBrancheById', 
async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/branches/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data

} catch (err) {
let error = err;
if (!error.response) {
  throw err;
}
console.log("error.response from inside the CAT", error.response);
return rejectWithValue(error.response);
}
})
export const addNewBranche = createAsyncThunk(
  'branche/addNewBranche',
  async (initialBranche, { rejectWithValue }) => {
   const token=localStorage.getItem('token')
   try {
    const response = await axios.post(BaseUrl+`/branches/create/`, initialBranche, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data

  } catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
  }
  })
  

export const updateBranche = createAsyncThunk(
  'branche/updateBranche',
  async (data, { rejectWithValue }) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/branches/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedBranche')
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
    }
    })

export const deleteBranche = createAsyncThunk('branche/deleteBranche', async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl+`/branches/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data

} catch (err) {
let error = err;
if (!error.response) {
  throw err;
}
console.log("error.response from inside the CAT", error.response);
return rejectWithValue(error.response);
}
})
const branchesSlice = createSlice({
  name: ' branche',
  initialState,
  extraReducers: {

    [fetchBranches.pending]: (state, action) => {
      state.branchesStatus = 'loading'
    },
    [fetchBranches.fulfilled]: (state, action) => {
      state.branchesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.branches = action.payload
    },
    [fetchBranches.rejected]: (state, action) => {
      state.branchesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchBrancheById.pending]: (state, action) => {
      state.branchestatus = 'loading'
    },
    [fetchBrancheById.fulfilled]: (state, action) => {
      state.branchestatus = 'succeeded'

      // Add any fetched posts to the array
      state.branche = action.payload
    },
    [fetchBrancheById.rejected]: (state, action) => {
      state.branchestatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewBranche.fulfilled]: (state, action) => {
      state.branches.push(action.payload)
    },
    [deleteBranche.fulfilled]: (state, action) => {
      // state.branches.push(action.payload)
    },
    [updateBranche.fulfilled]: (state, action) => {
      const result = state.branches.filter(branche => branche.id !== action.payload.id);
      // console.log("branche id from filter from slice",branche.id)
      result.push(action.payload)
      state.branches = result
      console.log("action.payload.id from slice",action.payload)

    },
    [addNewBranche.rejected]: (state, action) => {
      state.branchestatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteBranche.rejected]: (state, action) => {
      state.branchestatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateBranche.rejected]: (state, action) => {
      state.branchestatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

})


export default branchesSlice.reducer

 export const selectAllBranches = state => state.branche.branches