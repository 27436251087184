import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Input, notification, Select, InputNumber } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchLevelPlos, fetchLevelPlosById } from "reduxToolkit/features/levelPlo/LevelPloSlice";
import {
  addNewPlo,
  fetchPlosByProgramId
} from "reduxToolkit/features/PLO/PLOSpecSlice";
import { fetchSubLevelPLos } from "reduxToolkit/features/SubLevelPlo/SubLevelPloSlice";
import ErrorModal from "../../../Components/ErrorModal.jsx";

export default function PLOModal({ programId, disabledStatus }) {
  const { t } = useTranslation();
  const { Option } = Select;
  const [open, setOpen] = React.useState(false);
  const { TextArea } = Input;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const [levelPlo_id, setLevelPlo_id] = useState(null);
  const [levelPlo, setLevelPlo] = useState("");
  const [filtredSubLevels, setFiltredSubLevels] = useState([]);
  const dispatch = useDispatch();

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    dispatch(fetchSubLevelPLos());
    dispatch(fetchLevelPlos());

  }, [dispatch]);

  const levelPlos = useSelector((state) => state.levelPlos.levelPlos);
  const subLevelPlos = useSelector((state) => state.subLevelPlo.subLevelPLos);


  const onFinish = async (values) => {
    console.log("values", values);
    const courses_ids = []
    const resultAction = await dispatch(
      addNewPlo({ ...values, programSpec_id: programId, courses_ids })
    );

    if (addNewPlo.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const plo = resultAction.payload;
      dispatch(fetchPlosByProgramId(programId));
      openNotificationWithIcon("success", `Added ${plo.name}`);
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  function onChangeLevelPlo(value) {
    setLevelPlo_id(value);
    setFiltredSubLevels(
      subLevelPlos &&
      subLevelPlos.filter((sub) =>
        sub.levelPlo_id === value)
    )
  }
  return (
    <div>
      {!disabledStatus && (
        <Button color="primary" onClick={handleClickOpen}>
          {t("Add")}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Add a PLO")}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={8} sm={8} md={12}>
              <DialogContentText>
                {t(
                  " Program Learning Objectives describe the career and professional accomplishments that programs are preparing graduates to attain within few years of graduation"
                )}
              </DialogContentText>
            </GridItem>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's name",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Code")}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Target")}

                      name="target"
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's target",
                        },
                      ]}
                    >
                      <InputNumber min={0} max={100} style={{ width: "100%" }} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Level")}
                      name="levelPlo_id"
                      rules={[
                        {
                          required: false,
                          message: "Please input the plo's levelPlo_id",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        onChange={onChangeLevelPlo}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        dropdownStyle={{ zIndex: 10000 }}
                      >
                        {levelPlos &&
                          levelPlos.map((level) => (
                            <Option key={level.id} value={level.id}>
                              {level.level}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  {levelPlo_id == null ? (<GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Level/Sub Level")}
                      name="subLevelPlo_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the plo's level and subLevel",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        dropdownStyle={{ zIndex: 10000 }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >

                        {subLevelPlos &&
                          subLevelPlos.map((subLevel) => (
                            <Option key={subLevel.id} value={subLevel.id}>
                              {`${subLevel.levelPlo_details.level} /  ${subLevel.level}`}
                            </Option>
                          ))}

                      </Select>
                    </Form.Item>
                  </GridItem>) : (
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t("Level/Sub Level")}
                        name="subLevelPlo_id"
                        rules={[
                          {
                            required: true,
                            message: "Please input the plo's level and subLevel",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          optionFilterProp="children"
                          dropdownStyle={{ zIndex: 10000 }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {filtredSubLevels &&
                            filtredSubLevels.map((subLevel) => (
                              <Option key={subLevel.id} value={subLevel.id}>
                                {`${subLevel.levelPlo_details.level} /  ${subLevel.level}`}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  )}
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
