import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '../../../components/CustomButtons/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
// nodejs library to set properties for components
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'

import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
//import { Checkbox } from 'antd'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import Tooltip from '@material-ui/core/Tooltip'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import ListItemText from '@material-ui/core/ListItemText'
import { notification } from 'antd'
import Menu from '@material-ui/core/Menu'
import { Form } from 'antd'

import { withStyles } from '@material-ui/core/styles'
import { deleteAssessmentMethod } from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import AddModel from './AddModel'
import AddAccessControl from './AddAccessControl'
import SectionModal from './SectionModal'

const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)
const { Option } = Select

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))
export default function DropDownAssessmentMethodButton(props) {
  const [open, setOpen] = useState(false)
  const [openSectionModal, setOpenSectionModal] = useState(false)
  const [ping, setPing] = useState(false)

  const [openAddAccess, setOpenAddAccess] = useState(false)
  const anchorRef = useRef(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const [form] = Form.useForm()

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseAddAccess = () => {
    setOpenAddAccess(false)
  }
  const handleOpenAddAccess = () => {
    setOpenAddAccess(true)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  const handleOpenSectionModal = () => {
    setOpenSectionModal(true)
  }

  const handleCloseSectionModal = () => {
    setOpenSectionModal(false)
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={props.selected}>
          <Link
            to={`/citrine/models/addAccessControl/${props?.modelSelected[0]}`}
          >
            Access Control{' '}
          </Link>
        </MenuItem>

        <MenuItem
          disabled={props.selected}
          onClick={() => {
            setPing(!ping)
            handleOpenSectionModal()
            handleClose()
          }}
        >
          Add Section
        </MenuItem>

        {/* <MenuItem disabled ={props.selected} onClick={() => {handleOpenDeleteModal(); handleClose()}} ><ListItemText primary="Access Control" /> </MenuItem> */}
      </StyledMenu>
      <SectionModal
        openSectionModal={openSectionModal}
        handleClose={handleCloseSectionModal}
        disabled={props.selected}
        selected={props.modelSelected[0]}
        ping={ping}
        
      />
      <AddModel
        selected={props.modelSelected}
        open={open}
        handleClose={handleCloseDeleteModal}
        groups={props.groups}
      />
    </div>
  )
}
