import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardFooter from 'components/Card/CardFooter'
import CardHeader from 'components/Card/CardHeader.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { fetchAffectEvidenceTypeToAssessmentMethod, fetchAssessmentById } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import { fetchEvidenceTypeStatesConfigs } from '../../../../reduxToolkit/features/EvidenceType/EvidenceTypeSlice'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import AddEvidence from './AddEvidence'
import DropDown from './DropDown'
const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  Height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const AssessmentModeExcerpt = ({ assessmentMode }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const cardClasses = useCardStyles()

  return (
    <GridItem xs={12} sm={12} md={4} key={assessmentMode.id}>
      <Card
        style={{
          maxWidth: '380px',
          minWidth: '380px',
          maxHeight: '180px',
          minHeight: '180px',
        }}
        cardHover
      >
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <h4 className={cardClasses.cardProductTitle}>
                {assessmentMode.evidence_type}
              </h4>
              <h4>{'Required : ' + JSON.stringify(assessmentMode.required)}</h4>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <DropDown id={assessmentMode.id} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardFooter>
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="center"
          ></GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

const AssessementDetails = (props) => {
  const [open, setOpen] = React.useState(false)

  function onBlur() {
    console.log('')
  }

  function onFocus() {
    console.log('')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  let { id, name } = useParams()
  const [search, setSearch] = useState('')
  const { t } = useTranslation()
  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const dispatch = useDispatch()

  const types = useSelector(
    (state) => state.EvidenceTypeStatesConfig.EvidenceTypeStatesConfigs,
  )
  const assessment = useSelector((state) => state.AssessmentMethods.assessmentById)
  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  const handleDeleteItem = async (id) => {
    console.log(id)
  }

  useEffect(() => {
    dispatch(fetchAssessmentById(id))
    dispatch(fetchAffectEvidenceTypeToAssessmentMethod())
    dispatch(fetchEvidenceTypeStatesConfigs())
  }, [dispatch, id])
  return (
    <div>
      <GridContainer
        md={12}
        xs={12}
        sm={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span> {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/assessmentMethods`}>
                <span> {t('Assessment Methods')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span> {t(name)} </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <GridContainer justify="space-between" alignItems="center">
        <GridItem>
          <AddEvidence id={id} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        {assessment ? (
          assessment?.map((item) => (
            <AssessmentModeExcerpt key={item.id} assessmentMode={item} />
          ))
        ) : (
          <div>...</div>
        )}
      </GridContainer>
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}

export default AssessementDetails
