import { UserOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Avatar, Breadcrumb } from 'antd'
import Button from 'components/CustomButtons/Button.js'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGroupAssessmentById } from 'reduxToolkit/features/group/GroupSlice'
import { getGroupsByAssessmentMethodAndCourse } from 'reduxToolkit/features/group/GroupSlice'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import CardBody from 'components/Card/CardBody'
import DropDwonAssessmentMethodGroup from './courseAssessments/DropDwonAssessmentMethodGroup'
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
export default function AssessmentMethodGroupsCoordinatorGroup({ match }) {
  const {
    ProgramId,
    course_id,
    Assessmentid,
    AssessmentName,
    groupid,
  } = match.params
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const [ping, setPing] = useState(false)
  const [selected, setSelected] = useState(true)
  const [selectionModel, setSelectionModel] = useState([])
  const [group, setGroup] = useState()
  const program = useSelector((state) => state.program.program)

  const selectionModelChange = (newSelectionModel, details) => {
    console.log(newSelectionModel)
    console.log(details)
    if (newSelectionModel.length > 0) {
      setSelected(false)
    } else {
      setSelected(true)
    }
    setSelectionModel((prevModel) =>
      newSelectionModel.filter((newId) => !prevModel.includes(newId)),
    )
  }
  const columns = [
    {
      field: 'avatar',
      headerName: 'Avatar',
      width: 70,
      renderCell: ({ row }) => {
        if (row.avatar === null || row.avatar === '') {
          return <Avatar icon={<UserOutlined />}></Avatar>
        } else {
          return <Avatar src={MediaBaseUrl + `${row.avatar}`}></Avatar>
        }
      },
    },
    {
      field: 'student_identification',
      headerName: 'Identification',
      width: 200,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 150,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 150,
    //   type: "actions",
    //   cellClassName: "actions",
    //   renderCell: (cellValues) => {
    //     return (
    //       <Popconfirm
    //         title="Sure to delete?"
    //         onConfirm={() => handleDeleteClick(cellValues.id)}
    //         okButtonProps={{
    //           style: { backgroundColor: "#8F6682", borderColor: "#8F6682" },
    //         }}
    //         cancelButtonProps={{
    //           style: { color: color, borderColor: borderColor },
    //           onMouseEnter: () => {
    //             setColor("#8f6682");
    //             setBorderColor("#8f6682");
    //           },
    //           onMouseLeave: () => {
    //             setColor("");
    //             setBorderColor("");
    //           },
    //         }}
    //       >
    //         <IconButton
    //           aria-label="delete"
    //           onClick={() => setPersonToDelete(`${cellValues.row.first_name} ${cellValues.row.last_name}`)}
    //         >
    //           <DeleteIcon size="medium" />
    //         </IconButton>
    //       </Popconfirm>
    //     );
    //   },
    //},
  ]

  useEffect(() => {
    let obj = {
      course_id: course_id,
      assessmentMethod_id: Assessmentid,
      semester_id: localStorage.getItem('semester_id'),
    }
    dispatch(getGroupsByAssessmentMethodAndCourse(obj))
    // }
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchGroupAssessmentById(groupid))
      .then(unwrapResult)
      .then((group) => {
        setGroup(group.name)
        console.log(group.name)
        setRows(group.students_details)
      })
  }, [dispatch, ping])

  const courseGroups = useSelector((state) => state.group.groupAssessments)
  const courseGroup = useSelector((state) => state.group.groupAssessment)
  console.log(courseGroups)
  console.log(courseGroup)
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/courseCoordinatorWorkspace/assessment/`}>
                {t('Courses List ')}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseCoordinatorWorkspace/assessmentsQuestions_list/course/${course_id}`}
              >
                {t('Assessment Methods')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/`}
              >
                {t('Groups ')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: 'orange' }}>
              {group && group}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Card>
        <CardHeader>
          <GridContainer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={11}>
              <h4>
                {AssessmentName} : {group && group}
              </h4>
            </GridItem>
            {/* <GridItem> <Button color="primary" startIcon={<AddIcon />}>
        {t("Add")}
      </Button></GridItem> */}
            {/* <GridItem xs={12} sm={12} md={1}><AddAssessmentMethodGroup course_id={courseId} Assessmentid={Assessmentid} /></GridItem> */}
          </GridContainer>
        </CardHeader>
      </Card>

      <GridContainer>
        <Card>
          <CardBody>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={10} sm={11} md={11}>
                <Link
                  to={{
                    pathname: `/citrine/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/${groupid}/addStudent`,
                    state: {
                      groupid,
                      AssessmentName,
                      Assessmentid,
                      ProgramId,
                      course_id,
                      programName: program?.program_degree_name,
                    },
                  }}
                >
                  <Button color="primary">{t('Add')}</Button>
                </Link>
              </GridItem>
              <GridItem xs={2} sm={1} md={1}>
                <DropDwonAssessmentMethodGroup
                  selected={selected}
                  student={selectionModel[0]}
                  ping={ping}
                  setPing={setPing}
                  stateLink={{
                    groupid,
                    AssessmentName,
                    Assessmentid,
                    ProgramId,
                    course_id,
                    programName: program?.program_degree_name,
                  }}
                />
                {/* <DropDownPersonList
                selectedItemId={selectedItemId}
                id={selectedItemId.toString()}
              /> */}
              </GridItem>
            </GridContainer>

            <GridContainer
              style={{
                height: 500,
                width: '100%',
              }}
            >
              <DataGrid
                columns={columns}
                rows={rows && rows}
                //pageSize={5}
                //pagination
                checkboxSelection
                rowsPerPageOptions={[5, 10, 50, 100]}
                onSelectionModelChange={selectionModelChange}
                selectionModel={selectionModel}
              />
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  )
}
