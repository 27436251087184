// import React from 'react';
// import { TreeList, Column } from 'devextreme-react/tree-list';

// const expandedRowKeys = [1];
// const employees = [{
//     ID: 1,
//     Head_ID: -1,
//     Full_Name: 'John Heart',
//     Prefix: 'Mr.',
//     Title: 'CEO',
//     City: 'Los Angeles',
//     State: 'California',
//     Email: 'jheart@dx-email.com',
//     Skype: 'jheart_DX_skype',
//     Mobile_Phone: '(213) 555-9392',
//     Birth_Date: '1964-03-16',
//     Hire_Date: '1995-01-15',
//   }, {
//     ID: 2,
//     Head_ID: 1,
//     Full_Name: 'Samantha Bright',
//     Prefix: 'Dr.',
//     Title: 'COO',
//     City: 'Los Angeles',
//     State: 'California',
//     Email: 'samanthab@dx-email.com',
//     Skype: 'samanthab_DX_skype',
//     Mobile_Phone: '(213) 555-2858',
//     Birth_Date: '1966-05-02',
//     Hire_Date: '2004-05-24',
//   }, {
//     ID: 3,
//     Head_ID: 1,
//     Full_Name: 'Arthur Miller',
//     Prefix: 'Mr.',
//     Title: 'CTO',
//     City: 'Denver',
//     State: 'Colorado',
//     Email: 'arthurm@dx-email.com',
//     Skype: 'arthurm_DX_skype',
//     Mobile_Phone: '(310) 555-8583',
//     Birth_Date: '1972-07-11',
//     Hire_Date: '2007-12-18',
//   }, {
//     ID: 4,
//     Head_ID: 1,
//     Full_Name: 'Robert Reagan',
//     Prefix: 'Mr.',
//     Title: 'CMO',
//     City: 'Bentonville',
//     State: 'Arkansas',
//     Email: 'robertr@dx-email.com',
//     Skype: 'robertr_DX_skype',
//     Mobile_Phone: '(818) 555-2387',
//     Birth_Date: '1974-09-07',
//     Hire_Date: '2002-11-08',
//   }, {
//     ID: 6,
//     Head_ID: 2,
//     Full_Name: 'Brett Wade',
//     Prefix: 'Mr.',
//     Title: 'IT Manager',
//     City: 'Reno',
//     State: 'Nevada',
//     Email: 'brettw@dx-email.com',
//     Skype: 'brettw_DX_skype',
//     Mobile_Phone: '(626) 555-0358',
//     Birth_Date: '1968-12-01',
//     Hire_Date: '2009-03-06',
//   }, {
//     ID: 30,
//     Head_ID: 2,
//     Full_Name: 'Ken Samuelson',
//     Prefix: 'Dr.',
//     Title: 'Ombudsman',
//     City: 'St. Louis',
//     State: 'Missouri',
//     Email: 'kents@dx-email.com',
//     Skype: 'kents_DX_skype',
//     Mobile_Phone: '(562) 555-9282',
//     Birth_Date: '1972-09-11',
//     Hire_Date: '2009-04-22',
//   }, {
//     ID: 31,
//     Head_ID: 30,
//     Full_Name: 'chifaa chelly',
//     Prefix: 'Dr.',
//     Title: 'Ombudsman',
//     City: 'St. Louis',
//     State: 'Missouri',
//     Email: 'kents@dx-email.com',
//     Skype: 'kents_DX_skype',
//     Mobile_Phone: '(562) 555-9282',
//     Birth_Date: '1972-09-11',
//     Hire_Date: '2009-04-22',
//   }];

// class SurveyAnalysis extends React.Component {
//   render() {
//     return (
//       <TreeList
//         id="employees"
//         dataSource={employees}
//         rootValue={-1}
//         defaultExpandedRowKeys={expandedRowKeys}
//         showRowLines={true}
//         showBorders={true}
//         columnAutoWidth={true}
//         keyExpr="ID"
//         parentIdExpr="Head_ID"
//       >
//         <Column
//           dataField="Title"
//           caption="Position" />
//         <Column
//           dataField="Full_Name" />
//         <Column
//           dataField="City" />
//         <Column
//           dataField="State" />
//         <Column
//           dataField="Mobile_Phone" />
//         <Column
//           dataField="Hire_Date"
//           dataType="date" />
//       </TreeList>
//     );
//   }
// }

// export default SurveyAnalysis;

import React from "react";

import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import CheckBox from "devextreme-react/check-box";
import { analysis, details, allAreasAnalysis } from "./data.js";
import {
  grayColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import SeparateAnalysis from "./SeparateAnalysis";
import GroupedAnalysis from "./GroupedAnalysis";

function SurveyAnalysis(props) {
  const TPN = `Total participants number= ${details[0].TotalParticipantsNumber}`;
  const TSR = `Total satisfaction rate= ${details[0].totalSatisfactionRate}`;

  const separateByNationality = false;
  const content = separateByNationality ? (
    <SeparateAnalysis />
  ) : (
    <GroupedAnalysis campaignId={props.campaignId} campaign={props.campaign}/>
  );
  return <div>{content}</div>;
}

export default SurveyAnalysis;
