import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Space, Breadcrumb, Alert, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { SchoolRounded } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import { fetchInstructorCourseAssessmentGroup } from '../../../../reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { fetchCourseById } from 'reduxToolkit/features/course/CoursesSlice'
import { fetchProgramById } from 'reduxToolkit/features/program/ProgramsSlice'
import {
  fetchExamByQuestionAndNotes,
  fetchQuestions_ByAssessmentMethodById,
  GetAssessmentMethodById,
} from 'reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import { addNewNoteQuestion } from 'reduxToolkit/features/assessment/AssessmentSlice'
import { useDispatch, useSelector } from 'react-redux'
import ErrorModal from 'views/Components/ErrorModal'
import { unwrapResult } from '@reduxjs/toolkit'
import saveAs from 'file-saver'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { DataGrid, GridCellEditStopReasons } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'
import ListEvidence from './listEvidenceV2'
import DropDownButtonExportImportQuestionNotes from '.././../../Components/DropDownButtonExportImportQuestionNotes'

const AssessmentQuestion = ({
  obj_Assessment,
  rows,
  groupId,
  examId,
  courseId,
  semesterId,
  columns,
}) => {
  const dispatch = useDispatch()

  const exporterRef = useRef(null)

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx',
      )
    })
  }

  function isNumber(value) {
    return !Number.isNaN(Number(value));
  }

  /************************ States ****************************/
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  /***** handle modal err ***/
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  /*****************************************************************
   * 'event' contains all informations about the cell input
   * Api 'addnewNoteQuestion' to submit the newest note in database
   * catch used to handle errors and shows modal contains err
   *****************************************************************/

  const onCellEditStop = async (params, event) => {

    const { value } = event.target;
    console.log('value', value)
    console.log('params', params)

    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
      event.defaultMuiPrevented = true
    }

    if (value && isNumber(value.trim()) && value.trim() > 0) {
      const submitedNoteQuestion = {
        groupAssessment_id: groupId,
        assessmentMethod_id: examId,
        course_id: courseId,
        note: value.trim(),
        student_id: params.id,
        question_name: params.field,
        semester_id: semesterId,
      }
      try {
        const resultAction = await dispatch(
          addNewNoteQuestion(submitedNoteQuestion),
        )
        unwrapResult(resultAction)
      } catch (err) {
        setHandledError(err)
        handleOpenErrorModal()
      } finally {
        dispatch(fetchExamByQuestionAndNotes(obj_Assessment))
      }
    } else { console.log('**********************BINGO*************************') }
  }
  /**************************************************************/
  return (
    <>
      <Card withPadding>
        {/* <CardHeader>
          <GridContainer justifyContent="space-between" alignItems="center">
            <GridItem>
              <Typography>
                <strong style={{
                  color: '#6C7B9D',
                  textAlign: "center"
                }}>Grades</strong>
              </Typography>
            </GridItem>
            <GridItem>
              <DropDownButtonExportImportQuestionNotes
                courseID={courseId}
                course={course}
                program={program}
                semester_id={semesterId}
                export={startExport}
                group_name={''}
                examID={examId}
                obj_Assessment={obj_Assessment}
              />
            </GridItem>
          </GridContainer>
        </CardHeader> */}
        <CardBody style={{ height: '500px' }}>
          <DataGrid
            //autoHeight
            rows={rows || []}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5, 50]}
            onCellEditStop={onCellEditStop}
            // processRowUpdate={(before, after) => {
            //   console.log("before", before)
            //   console.log("after", after)

            // }}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </CardBody>
      </Card>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  )
}

const AssessmentMethodDetailsInstructor = () => {
  let { id } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([
    {
      name: 'student_identification',
      title: 'Student ID',
      field: 'student_identification',
      headerName: 'Student ID',
      width: 200,
    },
  ])

  const [Assessmentid, setAssessmentId] = useState(null)
  const [course_id, setCourseId] = useState(null)
  const [group_id, setGroup_id] = useState(null)

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  /***** handle modal err ***/
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  useEffect(() => {
    dispatch(fetchInstructorCourseAssessmentGroup(id))
      .then(unwrapResult)
      .then((res) => {
        setAssessmentId(res.assessmentMethod_id)
        setCourseId(res.course_id)
        setGroup_id(res.group_id)
        let arr = []
        dispatch(
          fetchQuestions_ByAssessmentMethodById({
            id: res.assessmentMethod_id,
            course_id: res.course_id,
          }),
        )
          .then(unwrapResult)
          .then((AssessmentMethodQuestions) => {
            if (AssessmentMethodQuestions.length > 0) {
              arr = AssessmentMethodQuestions.map((item) => {
                let obj = {
                  /***those 2 fields for export data only **/

                  name: `${item.question}`,
                  title: `${item.question}  (/${item.correction_mark})`,
                  /*************************************** */

                  field: `${item.question}`,
                  headerName: `${item.question}  (/${item.correction_mark})`,

                  editable: true,
                }
                return obj
              })
              setColumns([...columns, ...arr])
            }
          })

        dispatch(fetchCourseById(res.course_id))
        dispatch(
          fetchExamByQuestionAndNotes({
            id: res.assessmentMethod_id,
            course_id: res.course_id,
          }),
        )
      })
  }, [])

  const course = useSelector((state) => state.course.course)

  const ExamByQuestionAndNotesMethodstatus = useSelector(
    (state) => state.AssessmentMethods.ExamByQuestionAndNotesStatus,
  )
  const ExamByQuestionAndNotesMethod = useSelector(
    (state) => state.AssessmentMethods.ExamByQuestionAndNotesMethod,
  )
  const QuestionsByAssessmentMethod = useSelector(
    (state) => state.AssessmentMethods.QuestionsByAssessmentMethod,
  )

  const data = useSelector(
    (state) => state.CourseInstructor.instructorCourseAssessmentGroup,
  )


  let content
  if (data) {
    if (QuestionsByAssessmentMethod?.length) {
      if (ExamByQuestionAndNotesMethod === 'You do not have permission') {
        content = <h5>{t('You do not have permission!')}</h5>
      } else {
        // if (ExamByQuestionAndNotesMethod?.length) {
        content = (
          <AssessmentQuestion
            obj_Assessment={{
              id: Assessmentid,
              course_id: course_id,
            }}
            groupeName={ExamByQuestionAndNotesMethod?.find(obj => obj.groupId === group_id)?.groupName}
            rows={ExamByQuestionAndNotesMethod?.find(obj => obj.groupId === group_id)?.rows}
            groupId={ExamByQuestionAndNotesMethod?.find(obj => obj.groupId === group_id)?.groupId}
            examId={Assessmentid}
            courseId={course_id}
            semesterId={course?.semester_id}
            columns={columns}
          />
        )
        // }
      }
    } else {
      content = <p>There are no questions yet</p>
    }
  }

  const items = [
    {
      key: '1',
      label: `Evidence List`,
      children: (
        <ListEvidence
          resource={'course'}
          resourceId={course_id}
          Assessmentid={Assessmentid}
        />
      ),
    },
    {
      key: '2',
      label: `Grades`,
      children: content,
    },
  ]

  const onChange = (key) => {
    console.log(key)
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/citrine/faculty/CoursesAssessmentsGroups/">
            Courses’ Assessments’ Groups
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/coursesAssessmentsGroups/${id}/grades`}>
            {`${data?.assessmentMethod_details?.name} ${data?.course_details?.code}`}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/coursesAssessmentsGroups/${id}/grades`}>
            {` ${data?.group_details?.group_name}`}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>Grades</Breadcrumb.Item>
      </Breadcrumb>

      {data && <Tabs defaultActiveKey="1" items={items} onChange={onChange} />}

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  )
}

export default AssessmentMethodDetailsInstructor
