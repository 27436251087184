import React from "react";
import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import GenerateActionsPeoModal from "../../../Components/GenerateActionsPeoModal";
import {
    blackColor,
    hexToRgb,

} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);

const Stepper = ({ PeoReport, ResetTODraft, stateName, Approve, SendforApproval, Archive }) => {

    const { t } = useTranslation();

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    console.log("PeoReport inside stepper", PeoReport || "not here yet")

    return (
        <div style={{ padding: "5px 5px" }}>
            {
                PeoReport?.states_actions?.map((item) => {
                    if (stateName === "Created" && item.state === "Created") {
                        return (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                    borderRadius: "3px",
                                    boxShadow:
                                        "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    {item.actions?.map((action) => {
                                        if (
                                            action.name.toLowerCase().includes("send for approval")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => SendforApproval()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Send for approval")}
                                                    </span>
                                                </Button>
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("generate actions")
                                        ) {
                                            return (
                                                <GenerateActionsPeoModal
                                                    handleDropDownButtonClose={handleClose}
                                                    PeoReport_id={PeoReport.id}
                                                    PeoReport={PeoReport}
                                                />
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("approve")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => Approve()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Approve")}
                                                    </span>
                                                </Button>
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("reset to draft")
                                        ) {
                                            return (
                                                <Button
                                                    style={{ backgroundColor: "grey" }}
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => ResetTODraft()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Reset to draft")}
                                                    </span>
                                                </Button>
                                            );
                                        }
                                    })}

                                    <ol
                                        class="progress-bar"
                                        style={{ width: "550px", height: "40px" }}
                                    >
                                        {PeoReport.states_actions?.map((item) => {
                                            return item.state === "Created" ? (
                                                <li
                                                    class="progress-bar__steps current"
                                                    style={{
                                                        width: "auto",
                                                        paddingTop: "10px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            ) : (
                                                <li
                                                    class="progress-bar__steps not-current"
                                                    style={{ width: "auto", paddingTop: "10px" }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </div>
                            </div>
                        )
                    } else if (
                        stateName.toLowerCase().includes("waiting for approval") &&
                        item.state.toLowerCase().includes("waiting for approval")
                    ) {
                        return (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                    borderRadius: "3px",
                                    boxShadow:
                                        "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    {item.actions?.map((action) => {
                                        if (
                                            action.name.toLowerCase().includes("send for approval")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => SendforApproval()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Send for approval")}
                                                    </span>
                                                </Button>
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("generate actions")
                                        ) {
                                            return (
                                                <GenerateActionsPeoModal
                                                    handleDropDownButtonClose={handleClose}
                                                    PeoReport_id={PeoReport.id}
                                                    PeoReport={PeoReport}
                                                />
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("approve")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => Approve()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Approve")}
                                                    </span>
                                                </Button>
                                            );
                                        } else {
                                            return (
                                                <Button
                                                    style={{ backgroundColor: "grey" }}
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => ResetTODraft()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Reset to draft")}
                                                    </span>
                                                </Button>
                                            );
                                        }
                                    })}

                                    <ol
                                        class="progress-bar"
                                        style={{ width: "550px", height: "40px" }}
                                    >
                                        {PeoReport.states_actions?.map((item) => {
                                            return item.state
                                                .toLowerCase()
                                                .includes("waiting for approval") ? (
                                                <li
                                                    class="progress-bar__steps current"
                                                    style={{
                                                        width: "auto",
                                                        paddingTop: "10px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            ) : (
                                                <li
                                                    class="progress-bar__steps not-current"
                                                    style={{ width: "auto", paddingTop: "10px" }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </div>
                            </div>
                        );
                    } else if (stateName === "Approved" && item.state === "Approved") {
                        return (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                    borderRadius: "3px",
                                    boxShadow:
                                        "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    {item.actions?.map((action) => {
                                        if (
                                            action.name.toLowerCase().includes("send for approval")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => SendforApproval()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Send for approval")}
                                                    </span>
                                                </Button>
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("generate actions")
                                        ) {
                                            return (
                                                <GenerateActionsPeoModal
                                                    handleDropDownButtonClose={handleClose}
                                                    PeoReport_id={PeoReport.id}
                                                    PeoReport={PeoReport}
                                                />
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("approve")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => Approve()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Approve")}
                                                    </span>
                                                </Button>
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("archive")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => Archive()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Archive")}
                                                    </span>
                                                </Button>
                                            );
                                        } else {
                                            return (
                                                <Button
                                                    style={{ backgroundColor: "grey" }}
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => ResetTODraft()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Reset to draft")}
                                                    </span>
                                                </Button>
                                            );
                                        }
                                    })}

                                    <ol
                                        class="progress-bar"
                                        style={{ width: "550px", height: "40px" }}
                                    >
                                        {PeoReport.states_actions?.map((item) => {
                                            return item.state === "Approved" ? (
                                                <li
                                                    class="progress-bar__steps current"
                                                    style={{
                                                        width: "auto",
                                                        paddingTop: "10px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            ) : (
                                                <li
                                                    class="progress-bar__steps not-current"
                                                    style={{ width: "auto", paddingTop: "10px" }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </div>
                            </div>
                        );
                    } else if (stateName === "Archived" && item.state === "Archived") {
                        return (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                    borderRadius: "3px",
                                    boxShadow:
                                        "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    {item.actions?.map((action) => {
                                        if (
                                            action.name.toLowerCase().includes("send for approval")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => SendforApproval()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Send for approval")}
                                                    </span>
                                                </Button>
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("generate actions")
                                        ) {
                                            return (
                                                <GenerateActionsPeoModal
                                                    handleDropDownButtonClose={handleClose}
                                                    PeoReport_id={PeoReport.id}
                                                    PeoReport={PeoReport}
                                                />
                                            );
                                        } else if (
                                            action.name.toLowerCase().includes("approve")
                                        ) {
                                            return (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => Approve()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Approve")}
                                                    </span>
                                                </Button>
                                            );
                                        } else {
                                            return (
                                                <Button
                                                    style={{ backgroundColor: "grey" }}
                                                    size="sm"
                                                    className={classes.marginRight}
                                                    onClick={() => ResetTODraft()}
                                                >
                                                    <span style={{ color: "white", fontSize: "11px" }}>
                                                        {t("Reset to draft")}
                                                    </span>
                                                </Button>
                                            );
                                        }
                                    })}

                                    <ol
                                        class="progress-bar"
                                        style={{ width: "550px", height: "40px" }}
                                    >
                                        {PeoReport.states_actions?.map((item) => {
                                            return item.state === "Archived" ? (
                                                <li
                                                    class="progress-bar__steps current"
                                                    style={{
                                                        width: "auto",
                                                        paddingTop: "10px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            ) : (
                                                <li
                                                    class="progress-bar__steps not-current"
                                                    style={{ width: "auto", paddingTop: "10px" }}
                                                >
                                                    <span class="progress-bar__steps--text">
                                                        {item.state}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </div>
                            </div>
                        );
                    }
                })
            }
        </div>
    );
}

export default Stepper;