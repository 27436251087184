import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPeoReports,
  UpdatePeoReport
} from "../../../../reduxToolkit/features/PeoReport/PeoReportSlice";
import { fetchGroupStatesByReport } from "reduxToolkit/features/groupState/GroupStateSlice";
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Link, useParams } from 'react-router-dom'
import '@lourenci/react-kanban/dist/styles.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Card } from 'antd'
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next'
import DropDownButtonPeoReport from "../../../Components/DropDownButtonPeoReport";
import ErrorModal from "../../../Components/ErrorModal";
import Info from "components/Typography/Info.js";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import DNDBoard from "../../../../components/dnd/Board";
import Moment from "moment";

export default function PEOImprovementList(props) {
  const initialBoard = { columns: [] }
  let content
  const { t } = useTranslation()
  const { type } = useParams();

  const dispatch = useDispatch()

  const [board, setBoard] = useState(initialBoard)
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  useEffect(() => {
    peoReportsAccordingToStates()
    dispatch(fetchStaff())
  }, [])

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  function deepCopy(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        newObj[key] = deepCopy(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  const peoReportsAccordingToStates = () => {
    dispatch(fetchGroupStatesByReport('PEOReport'))
      .then(unwrapResult)
      .then((states) => {
        if (states?.colums?.length > 0) {
          draftBoard = deepCopy(states);
          dispatch(fetchPeoReports())
            .then(unwrapResult)
            .then((PEOReports) => {
              if (type !== "NCAAA") { PEOReports = [] }
              if (PEOReports?.length > 0) {
                PEOReports.forEach(report => {
                  let stateObject = report.state_object;
                  let column = draftBoard?.colums?.find(col => col.title === stateObject);
                  if (column && column.cards) { // Ensure cards array exists
                    column.cards.push(report);
                  }
                });
              }
              setBoard(draftBoard)
            }).catch((error) => {
              setHandledError(error);
              handleOpenErrorModal();
            })
        }
      }).catch((error) => {
        setHandledError(error);
        handleOpenErrorModal();
      })
  }

  const handleDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    if (!destination || source.droppableId == destination.droppableId) return;
    const destinationState = board.colums.find(state => state.id == destination.droppableId);
    const report = PeoReport.find(report => report.id == draggableId);
    const state_object = destinationState?.title;
    if (state_object && report) {
      // Dispatch the update request
      try {
        dispatch(UpdatePeoReport({
          id: report.id,
          UpdatePeoReport: { ...report, state_object },
        })).unwrap()
        // handle result here
        // If the request is successful, update the state
        const updatedColumns = board.colums.map(column => {
          if (column.id == source.droppableId) {
            // Remove the report from the source column
            column.cards = column.cards.filter(card => card.id !== report.id);
          } else if (column.id == destination.droppableId) {
            // Add the report to the destination column
            column.cards.push(report);
          }
          return column;
        });

        // Update the state with the new columns structure
        setBoard(prevBoard => ({ ...prevBoard, colums: updatedColumns }));
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      }
    }
  };

  const renderCardContent = (report, showDropDown) => <div>
    <GridContainer alignItems="baseline" style={{ minHeight: "80px" }}>
      <GridItem key={Element.id} xs={showDropDown ? 12 : 12} sm={showDropDown ? 9 : 12} md={showDropDown ? 9 : 12} lg={showDropDown ? 10 : 12}>
        <Link
          to={`/citrine/reports/${type}/PEO/${report.id}`}
        >
          <strong>
            <p style={{ marginTop: "10px" }}>
              {report?.program_detail?.program_degree_name}
            </p>
          </strong>
        </Link>
        {/* {Moment(report.date).format("YYYY-MM-DD")} */}

      </GridItem>
      {showDropDown && <GridItem key={Element.id} xs={12} sm={3} md={3} lg={2}>
        <DropDownButtonPeoReport
          data={report}
          id={report.id}
          staffList={staffList}
          orderReportsAccordingToStates={peoReportsAccordingToStates}
        />
      </GridItem>}
    </GridContainer>
    <p style={{ fontSize: '12px' }}>
      {Moment(report.date).format("YYYY-MM-DD")}
    </p>
  </div>

  useEffect(() => {
    dispatch(fetchGroupStatesByReport('PEOReport')).then(unwrapResult).then((PEOReport_StateBoard) => {
      if (PEOReport_StateBoard?.colums?.length > 0) {
        PEOReport_StateBoard.colums.map((item) => {
          initialBoard.columns.push({
            id: item.id,
            title: item.title,
            cards: [],
          })
        })
      }
    }).catch((error) => {
      setHandledError(error);
      handleOpenErrorModal();
    })
  }, []);

  let draftBoard
  useEffect(() => {
    peoReportsAccordingToStates()
    dispatch(fetchStaff())

  }, [])

  const staffList = useSelector((state) => state.user.staffList);
  const PeoReportsStatus = useSelector((state) => state.PeoReport.PeoReportsStatus,)
  const PEOReport_StateBoard = useSelector((state) => state.groupState.groupStatesByReport,)
  const groupStatesByReportStatus = useSelector((state) => state.groupState.groupStatesByReportStatus,)
  const PeoReport = useSelector((state) => state.PeoReport.PeoReport,)

  if (PeoReportsStatus === "loading" || groupStatesByReportStatus === "loading") {
    content = <GridContainer style={{ height: "100%" }} justifyContent="center" alignItems="center"><GridItem><CircularProgress color="inherit" /></GridItem></GridContainer>
  }

  if (PeoReportsStatus === "failed" || groupStatesByReportStatus === "failed") {
    return (
      <GridContainer justifyContent="center" alignItems="center"><GridItem style={{ width: "60%" }}><Info>Oops! Something went wrong.
        We're having trouble retrieving the annual programs reports. Please try again later. If the issue persists, contact support for assistance.
      </Info></GridItem></GridContainer>
    )
  }

  if (groupStatesByReportStatus === "succeeded") {
    if (PEOReport_StateBoard?.colums?.length == 0) {
      content = <GridContainer justifyContent="center" alignItems="center"><GridItem style={{ width: "60%" }}><Info>Oops! Something went wrong.
        We could not find any annual programs reports states. Please add the needed states in the configuration menu or contact support for assistance.
      </Info></GridItem></GridContainer>
    }

    if (PeoReportsStatus === "succeeded") {
      content = <DNDBoard
        columns={board}
        renderCardContent={renderCardContent}
        handleDragEnd={handleDragEnd}
      />
    }
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/reports/${type}/PEO`}>
            {t('PEO Report')}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {content}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  )
}

