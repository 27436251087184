import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Avatar } from "antd";
import { greyColor } from "assets/jss/material-dashboard-pro-react.js";

const Container = styled.div`
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px #ECEDF0;
    padding: 8px;
    color: #000;
    min-height: 120px;
    margin: 5px;
    // background-color: ${(props) => bgcolorChange(props)};
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const TextContent = styled.div``;

const Icons = styled.div`
    display: flex;
    justify-content: end;
    padding: 2px;
`;
function bgcolorChange(props) {
    return props.isDragging
        ? "lightgreen"
        : props.isDraggable
            ? props.isBacklog
                ? "#F2D7D5"
                : "#DCDCDC"
            : props.isBacklog
                ? "#F2D7D5"
                : "#EAF4FC";
}

export default function Card({ task, index, renderCardContent }) {
    const [showDropDown, setShowDropDown] = useState(false)

    return (
        <Draggable draggableId={`${task.id}`} key={task.id} index={index}>
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    <div onMouseEnter={() => setShowDropDown(true)} onMouseLeave={() => setShowDropDown(false)}>
                        {renderCardContent(task, showDropDown)}
                    </div>
                    {provided.placeholder}
                </Container>
            )}
        </Draggable>
    );
}