import { Breadcrumb, notification, Tabs } from 'antd'
// import Button from "components/CustomButtons/Button.js";
import { DataGrid } from '@mui/x-data-grid'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import Card from 'components/Card/Card.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  fetchPossibleType_3ResponsesBySurveyTemplate,
  fetchPossibleType_5ResponsesBySurveyTemplate,
  fetchSurveyTemplateById,
  updateResponseWeight,
} from '../../../reduxToolkit/features/Survey/SurveySlice'
import ErrorModal from '../../Components/ErrorModal'

export default function EditResponsesWeights(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { TabPane } = Tabs
  let { id } = useParams()

  const columns = [
    // {
    //   field: "id",
    //   headerName: "id",
    //   width: 50,
    //   sortable: false,
    // },
    {
      field: 'response',
      headerName: 'Response',
      flex: 0.25,
      editable: false,
      sortable: false,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      flex: 0.25,
      editable: true,
      sortable: false,
    },
  ]

  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const [type3_responses, setType3_responses] = React.useState(null)
  const [type5_responses, setType5_responses] = React.useState(null)

  const fetchType_3ResponsesCustomFunction = async () => {
    try {
      const resultAction = await dispatch(
        fetchPossibleType_3ResponsesBySurveyTemplate(id),
      ).unwrap()
      //console.log('success')
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  const fetchType_5ResponsesCustomFunction = async () => {
    try {
      const resultAction = await dispatch(
        fetchPossibleType_5ResponsesBySurveyTemplate(id),
      ).unwrap()
      //console.log('success')
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    fetchType_3ResponsesCustomFunction()
    fetchType_5ResponsesCustomFunction()
    dispatch(fetchSurveyTemplateById(id))
  }, [dispatch])

  const surveyTemplate = useSelector((state) => state.survey.surveyTemplate)

  const possibleResponsesType_3 = useSelector(
    (state) => state.survey.possibleResponsesType_3,
  )

  const possibleResponsesType_5 = useSelector(
    (state) => state.survey.possibleResponsesType_5,
  )

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 10,
    })
  }

  const onCellEditCommit = async (event) => {
    console.log('onCellEditCommit', event)
    const value = event.value

    const field = event.field

    const id = event.id
    if(event.cellMode){
      const resultAction = await dispatch(
        updateResponseWeight({ id: id, updatedResponse: { [field]: value } }),
      )
      if (updateResponseWeight.fulfilled.match(resultAction)) {
        // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
        fetchType_5ResponsesCustomFunction()
        fetchType_5ResponsesCustomFunction()
        openNotificationWithIcon('success', `Updated successfully`)
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          //console.log('resultAction.payload from component', resultAction.payload)
          setHandledError(resultAction.payload)
          handleOpenErrorModal()
        } else {
          //console.log('resultAction.error  from component', resultAction.error)
          setHandledError(resultAction.error)
          handleOpenErrorModal()
        }
      }
    }

   

  }

  const onCellEditStop = async (params, event) => {
    //console.log('event.target.value', event.target.value)
    //console.log('params', params)
    const value = event.value

    const field = event.field

    const id = event.id
    // if (params.reason === GridCellEditStopReasons.cellFocusOut) {
    //   event.defaultMuiPrevented = true
    // }

    dispatch(
      updateResponseWeight({ id: id, updatedResponse: { [field]: value } }),
    )
      .unwrap()
      .then((res) => {
        fetchType_5ResponsesCustomFunction()
      fetchType_5ResponsesCustomFunction()
      openNotificationWithIcon('success', `Updated successfully`)
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        //justify="space-between"
        //alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/surveyTemplates`}>
                {t('Survey templates')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/surveyTemplates/${id}`}>
                {surveyTemplate && surveyTemplate.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                style={{ color: 'orange' }}
                to={`/citrine/surveyTemplates/${id}/editWeights`}
              >
                {t('Edit weights')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={10} sm={10} md={12}>
          <Card withPadding>
            {/* <Tabs type="card">
              <TabPane tab="5-response-type" key="1">
                <GridContainer>
                  <GridItem xs={10} sm={10} md={10}>
                    <div style={{ height: 600, width: '100%' }}>
                      <DataGrid
                        rowHeight={50}
                        rows={possibleResponsesType_5 || []}
                        columns={columns}
                        autoPageSize={true}
                        columnBuffer={0}
                        disableColumnMenu
                        disableSelectionOnClick
                        onCellEditCommit={onCellEditCommit}
                        //onCellEditStop={onCellEditStop}
                        showCellRightBorder
                        showColumnRightBorder
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </TabPane>
              <TabPane tab="3-response-type" key="2">
                <GridContainer>
                  <GridItem xs={10} sm={10} md={10}>
                    <div style={{ height: 600, width: '100%' }}>
                      <DataGrid
                        rowHeight={50}
                        rows={possibleResponsesType_3 || []}
                        columns={columns}
                        autoPageSize={true}
                        columnBuffer={0}
                        disableColumnMenu
                        disableSelectionOnClick
                        onCellEditCommit={onCellEditCommit}
                        showCellRightBorder
                        showColumnRightBorder
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </TabPane>
            </Tabs> */}
            <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `5-response-type`,
                children: (
                  <GridContainer>
                  <GridItem xs={10} sm={10} md={10}>
                    <div style={{ height: 600, width: '100%' }}>
                      <DataGrid
                        rowHeight={50}
                        rows={possibleResponsesType_5 || []}
                        columns={columns}
                        autoPageSize={true}
                        columnBuffer={0}
                        disableColumnMenu
                        disableSelectionOnClick
                        onCellEditCommit={onCellEditCommit}
                        //onCellEditStop={onCellEditStop}
                        showCellRightBorder
                        showColumnRightBorder
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                ),
              },
              {
                key: '2',
                label: `3-response-type`,
                children: (
                  <GridContainer>
                  <GridItem xs={10} sm={10} md={10}>
                    <div style={{ height: 600, width: '100%' }}>
                      <DataGrid
                        rowHeight={50}
                        rows={possibleResponsesType_3 || []}
                        columns={columns}
                        autoPageSize={true}
                        columnBuffer={0}
                        disableColumnMenu
                        disableSelectionOnClick
                        onCellEditCommit={onCellEditCommit}
                        showCellRightBorder
                        showColumnRightBorder
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                ),
              },

              
              
            ]}
          />
          </Card>
        </GridItem>
      </GridContainer>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
