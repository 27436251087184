import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import Button from 'components/CustomButtons/Button.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import SuperGeneralDeleteModal from './SuperGeneralDeleteModal'
import ErrorModal from './ErrorModal'
import { notification } from 'antd'

import { deleteResearchAndInnovationActivity } from '../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice'
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function ResearchAndInnovationDropdownButton(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDeleteResearchAndInnovation = async () => {
    dispatch(deleteResearchAndInnovationActivity(props.id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        openNotificationWithIcon('success', `Deleted`)
        props.setPing(!props.ping)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
        
          // to={`/citrine/faculty/teachingStaff/researchAndInnovationActivities/update/${props.id}`}
          to={{
            pathname:`/citrine/faculty/teachingStaff/researchAndInnovationActivities/update/${props.id}`,
            state: {personId:props?.personId }
          }}

        >
          <MenuItem>
            <ListItemText style={{ color: 'black' }} primary="Edit" />
          </MenuItem>
        </Link>
        {/* <Link
          to={{
            pathname: `/citrine/groupActions/states/${Report}/${groupState.id}/actions/`,
            state: { action: groupState.name_details },
          }}
        >
          {groupState?.name_details}
        </Link> */}

        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText style={{ color: 'black' }} primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteResearchAndInnovation}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
