import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import pagesHeaderstyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchHTMLTemplates } from '../../../../reduxToolkit/features/HTMLTemplate/HTMLTemplateSlice';





const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);


const TemplateExcerpt = ({ template }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();


  return (

    <GridItem xs={12} sm={12} md={4} key={template.id} >
      <Card style={{ maxWidth: "380px", minWidth: "380px" }} cardHover >
        <CardHeader>
          <GridContainer direction="row"
            justifyContent="space-between"
            alignItems="baseline">
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/HTMLTemplates/${template.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {`${template.type} report template`}
                </h4>
              </Link>
            </GridItem>
            <Link to={`/citrine/HTMLTemplates/${template.id}/editor`}>
              <GridItem xs={2} sm={2} md={2}>
                <Edit />
              </GridItem>
            </Link>
          </GridContainer>
        </CardHeader>
      </Card>
    </GridItem>
  )
}

export default function HTMLTemplatesList() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("")

  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)


  const dispatch = useDispatch()

  const HTMLTemplatesStatus = useSelector(state => state.HTMLTemplate.HTMLTemplatesStatus)
  useEffect(() => {
    if (HTMLTemplatesStatus === 'idle') {
      dispatch(fetchHTMLTemplates())
    }
  }, [
    HTMLTemplatesStatus,
    dispatch])
  const HTMLTemplates = useSelector(state => state.HTMLTemplate.HTMLTemplates)
    
  console.log("HTMLTemplates", HTMLTemplates && HTMLTemplates)
  let content

  const searchButton =
    navBarClasses.top +
    " " +
    navBarClasses.searchButton

  if (HTMLTemplatesStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (HTMLTemplatesStatus === 'succeeded') {
    content = HTMLTemplates && HTMLTemplates.filter((template) => {
      if (search == '') {
        return template
      } else if (template.type.toLowerCase().includes(search.toLowerCase())) {
        return template
      }
    }).map(template => (
      <TemplateExcerpt key={template.id} template={template} />
    ))
  }
  const cardClasses = useCardStyles();
  return (
    // <div>
    //   <GridContainer
    //     alignItems="center"
    //     style={{
    //       borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
    //     }}>
    //     <GridItem>
    //       <Link to={`/citrine/surveyTemplates`}> 
    //       <span className={titleClasses.title}> {t('HTML templates')} </span>
    //       </Link> 

    //     </GridItem>

    //   </GridContainer>

    //   <GridContainer
    //     justify="space-between"
    //     alignItems="center">
    //     <GridItem>
    //       <Link to="/citrine/HTMLTemplates/HTMLTemplate/add">
    //         <Button color="primary">
    //           {t('Add')}
    //         </Button>
    //       </Link>
    //     </GridItem>
    //     <GridItem >
    //       <CustomInput
    //         inputProps={{
    //           onChange: event => {
    //             setSearch(event.target.value);
    //           },
    //           value: search,
    //           placeholder: "Search"
    //         }}
    //       />
    //       <Button
    //         color="white"
    //         aria-label="edit"
    //         justIcon
    //         round
    //         className={searchButton}
    //       >
    //         <Search className={navBarClasses.headerLinksSvg + " " + navBarClasses.searchIcon} />
    //       </Button>
    //     </GridItem>
    //   </GridContainer>

    //   <GridContainer >
    //     {content}
    //   </GridContainer>
    // </div>
    <>
      <div>
        <GridContainer
          alignItems="center"
          style={{
            borderTop: `1px solid  ${grayColor[15]}`,
            minHeight: "50px",
            maxHeight: "50px",
          }}
        >
          <GridItem>
            {/* <Link to={`/citrine/surveyTemplates`}> */}
            <span className={titleClasses.title}>
              {" "}
              {t("Templates ")}{" "}
            </span>
            {/* </Link> */}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card
              style={{ maxWidth: "380px", minWidth: "380px", height: 150 }}
              cardHover
            >
              <CardHeader>
                <GridContainer
                  direction="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <GridItem xs={10} sm={10} md={10}>
                    <Link to={`/citrine/SurveyTemplateConfList/`}>
                      <h4 className={cardClasses.cardProductTitle}>
                        Surveys
                      </h4>

                    </Link>
                  </GridItem>


                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card
              style={{ maxWidth: "380px", minWidth: "380px", height: 150 }}
              cardHover
            >
              <CardHeader>
                <GridContainer
                  direction="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  {/* <GridItem xs={10} sm={10} md={10}>
                    <Link to={`/citrine/surveyTemplates/types/`}>
                      <h4 className={cardClasses.cardProductTitle}>
                        Mails
                      </h4>

                    </Link>
                  </GridItem> */}
                  <GridItem xs={10} sm={10} md={10}>
                    <Link to={`/citrine/surveyTemplates/SurveyMails/`}>
                      <h4 className={cardClasses.cardProductTitle}>
                        Mails
                      </h4>

                    </Link>
                  </GridItem>

                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card
              style={{ maxWidth: "380px", minWidth: "380px", height: 150 }}
              cardHover
            >
              <CardHeader>
                <GridContainer
                  direction="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <GridItem xs={10} sm={10} md={10}>
                    <Link to={`/citrine/mailetemplist`}>
                      {/* /citrine/mailtemplates */}
                      <h4 className={cardClasses.cardProductTitle}>
                        Reports
                      </h4>

                    </Link>
                  </GridItem>


                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        {/* <SuperGeneralDeleteModal
delete_Element={handleDelete}
Element_ID={props.id}
open={open}
handleClose={handleCloseDeleteModal}
/> */}
      </div>
    </>
  );
}













