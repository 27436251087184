import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  DatesAndTimesAllocations: [],
  DatesAndTimesAllocationsStatus: "idle",
  DatesAndTimesAllocationStatus: "idle",
  error: null,
  DatesAndTimesAllocation: null,
  id: null,
};

export const fetchDatesAndTimesAllocations = createAsyncThunk(
  "DatesAndTimesAllocation/fetchDatesAndTimesAllocations",
  async (courseSpec_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/datesAndTimes/?courseSpec_id=${courseSpec_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const fetchDatesAndTimesAllocationById = createAsyncThunk(
  "DatesAndTimesAllocation/fetchDatesAndTimesAllocationById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/datesAndTimes/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewDatesAndTimesAllocation = createAsyncThunk(
  "DatesAndTimesAllocation/addNewDatesAndTimesAllocation",
  async (initialDatesAndTimesAllocation, { rejectWithValue }) => {
    console.log("initial DatesAndTimesAllocation", initialDatesAndTimesAllocation);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/datesAndTimes/create/`,
        initialDatesAndTimesAllocation,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const DatesAndTimesAllocationUpdate = createAsyncThunk(
  "DatesAndTimesAllocation/updateDatesAndTimesAllocation",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/datesAndTimes/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updated");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteDatesAndTimesAllocation = createAsyncThunk(
  "DatesAndTimesAllocation/deleteDatesAndTimesAllocation",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/datesAndTimes/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const DatesAndTimesAllocationSlice = createSlice({
  name: "DatesAndTimesAllocation",
  initialState,

  extraReducers: {

    [fetchDatesAndTimesAllocations.pending]: (state, action) => {
      state.DatesAndTimesAllocationsStatus = "loading";
    },
    [fetchDatesAndTimesAllocations.fulfilled]: (state, action) => {
      state.DatesAndTimesAllocationsStatus = "succeeded";
      state.DatesAndTimesAllocations = action.payload;
    },
    [fetchDatesAndTimesAllocations.rejected]: (state, action) => {
      state.DatesAndTimesAllocationsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    
    [fetchDatesAndTimesAllocationById.pending]: (state, action) => {
      state.DatesAndTimesAllocationStatus = "loading";
    },
    [fetchDatesAndTimesAllocationById.fulfilled]: (state, action) => {
      state.DatesAndTimesAllocationStatus = "succeeded";
      state.DatesAndTimesAllocation = action.payload;
    },
    [fetchDatesAndTimesAllocationById.rejected]: (state, action) => {
      state.DatesAndTimesAllocationStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewDatesAndTimesAllocation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewDatesAndTimesAllocation.fulfilled]: (state, action) => {
      state.DatesAndTimesAllocations.push(action.payload);
    },
    [deleteDatesAndTimesAllocation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteDatesAndTimesAllocation.fulfilled]: (state, action) => {
     
    },
    [DatesAndTimesAllocationUpdate.fulfilled]: (state, action) => {
      console.log(action);
    },
    [DatesAndTimesAllocationUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    reducers: {
 
    },
  },
});

export const {} = DatesAndTimesAllocationSlice.actions;

export default DatesAndTimesAllocationSlice.reducer;

