// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb } from 'antd';
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// @material-ui/icons
import {
  addNewCodeLevel, fetchCodeLevelById, fetchCodeLevels, updateCodeLevel
} from "../../../../reduxToolkit/features/CodeLevel/CodeLevelConfigSlice";

const useStyles = makeStyles(styles);

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
// const classes = useStyles();
export default function CreatedCodeLevel({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;
  const dispatch = useDispatch();
  console.log("id", id);

  const isAddMode = isNaN(id);
  console.log("id from match is add mode", isAddMode);
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);

  const titleClasses = useTitleStyles();

  const [level, setLevel] = useState("");
  const [code_level, setCode_level] = useState("");

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const onLevelChanged = (e) => setLevel(e.target.value);

  const onCode_levelChanged = (e) => setCode_level(e.target.value);

  const history = useHistory();

  const onSaveAssessmentMethodClicked = async () => {
    // if (canSave) {
    try {
      setAddRequestStatus("pending");
      console.log("onsave");
      const resultAction = await dispatch(
        addNewCodeLevel({ level, code_level })
      );
      unwrapResult(resultAction);
      setLevel("");
      setCode_level("");

      dispatch(fetchCodeLevels());
      history.push("/citrine/codeLevels");
    } catch (err) {
      console.error("Failed to save the codeLevels: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };
  const onUpdateClicked = async () => {
    //  if (canSave) {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        updateCodeLevel({ id: id, modified: { level, code_level } })
      );
      unwrapResult(resultAction);
      setLevel("");
      setCode_level("");
      dispatch(fetchCodeLevels());
      history.push("/citrine/codeLevels");
    } catch (err) {
      console.error("Failed to update the codeLevels: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
    //   }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchCodeLevelById(id));
    }
  }, [id]);

  const CodeLevel = useSelector((state) => state.CodeLevel.CodeLevel);
  console.log("CodeLevel ", CodeLevel);

  useEffect(() => {
    if (!isAddMode) {
      setLevel(CodeLevel && CodeLevel.level);
      setCode_level(CodeLevel && CodeLevel.code_level);
    }
  }, [CodeLevel, dispatch]);

  return (
    <div>
      {isAddMode && (
        <div>
          <GridContainer

            md={12}
            xs={12} sm={12}
            direction="row"
            // style={{ width:300 ,height:150}}
            justify="space-between"
            alignItems="center"
          >
            <GridItem>

              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}>
                    <span > {t('Course Configurations')} </span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/codeLevels`}>
                    <span > {t('Code Levels')} </span>
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>

          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>


                    {t("Create")}

                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >

                      <CustomInput
                        labelText={t("Level")}
                        id="level"
                        //  onChange={onNameChanged}
                        type="text"
                        name="level"
                        value={level}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a name!",
                          },
                        ]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setLevel(event.target.value);
                          },
                        }}
                      />

                      <CustomInput
                        labelText={t("Level Code")}
                        id="code_level"
                        //  onChange={onNameChanged}
                        type="text"
                        name="code_level"
                        value={code_level}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a name!",
                          },
                        ]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setCode_level(event.target.value);
                          },
                        }}
                      />

                      <GridItem>
                        <Button
                          color="info"
                          className={classes.updateProfileButton}
                          onClick={onSaveAssessmentMethodClicked}
                        >
                          {t(" Save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
      {!isAddMode && CodeLevel && (
        <div>
          <GridContainer

            md={12}
            xs={12} sm={12}
            direction="row"
            // style={{ width:300 ,height:150}}
            justify="space-between"
            alignItems="center"
          >
            <GridItem>

              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}>
                    <span > {t('Course Configurations')} </span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/codeLevels`}>
                    <span > {t('Code Levels')} </span>
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>

          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>

                    {t("Edit")}

                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >

                      <CustomInput
                        labelText={t("level ")}
                        id="level"
                        //  onChange={onNameChanged}
                        type="text"
                        name="level"
                        value={level}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a name!",
                          },
                        ]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setLevel(event.target.value);
                          },
                          value: level,
                        }}
                      />
                      <CustomInput
                        labelText={t("code_level ")}
                        id="code_level"
                        //  onChange={onNameChanged}
                        type="text"
                        name="code_level"
                        value={code_level}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a name!",
                          },
                        ]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setCode_level(event.target.value);
                          },
                          value: code_level,
                        }}
                      />

                      <GridItem>
                        <Button
                          color="info"
                          className={classes.updateProfileButton}
                          onClick={onUpdateClicked}
                        >
                          {t(" Update")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}
