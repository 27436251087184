import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import { Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProgramLogo from "../../../../assets/img/ProgramLogo.png";
import {
  grayColor,
  infoColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import {
  // getId,
  deleteProgram,
  fetchPrograms,
  selectAllPrograms,
} from "../../../../reduxToolkit/features/program/ProgramsSlice";
import ErrorModal from "../../../Components/ErrorModal";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function AllResearchAndInnovationActivities() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const fetchProgramsBySemester = async () => {
    try {
      const semester_id = localStorage.getItem('semester_id');
      dispatch(fetchPrograms(semester_id));
      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const programsStatus = useSelector((state) => state.program.programsStatus);

  useEffect(() => {
    fetchProgramsBySemester();
  }, [dispatch]);

  return (
    <div>
        AllTeachingLoadActivities 
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
