import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DataGrid, useGridApiRef } from "@material-ui/data-grid";
import { Form, Input, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchLevelPlos } from "reduxToolkit/features/levelPlo/LevelPloSlice";

import {
  deletePlo,
  fetchPlosByProgramId,
  updatePlo,
  fetchPloById,
} from "reduxToolkit/features/PLO/PLOSpecSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchNotAffectedCourses } from "reduxToolkit/features/program/ProgramSpecSlice";
import { fetchSubLevelPLos } from "reduxToolkit/features/SubLevelPlo/SubLevelPloSlice";
import ErrorModal from "../../../Components/ErrorModal";
import MenuItem from "@material-ui/core/MenuItem";
export default function PLOModal(props) {
  const { t } = useTranslation();
  const { Option } = Select;
  //console.log('program id in add plo modal', program)
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [code_level, setCode_level] = useState("");
  const [levelPlo, setLevelPlo_id] = useState("");
  const [subLevelPlo, setSubLevelPlo_id] = useState("");
  const [performance, setPerformance] = useState("");
  const [target, setTarget] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [introduced, setIntroduced] = useState([]);
  const [practiced, setPracticed] = useState([]);
  const [mastered, setMastered] = useState([]);
  const { error } = useSelector((state) => state.ploSpec);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPloById(props.plo.id))
      .then(unwrapResult)
      .then((plo) => {
        // do additional work
        console.log("originalPromiseResult", plo);

        form.setFieldsValue({
          programSpec_id: plo.programSpec_id,
          name: plo.name,
          code: plo.code,
          code_level: plo.code_level,
          target: plo.target,
          levelPlo: plo.levelPlo_id,
          subLevelPlo: plo.subLevelPlo_id,
        });
      });
  }, [dispatch]);
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const levelPlosStatus = useSelector(
    (state) => state.levelPlos.levelPlosStatus
  );
  const subLevelPlosStatus = useSelector(
    (state) => state.subLevelPlo.subLevelPLosStatus
  );
  const courses = useSelector((state) => state.programSpec.courses);

  useEffect(() => {
    dispatch(fetchSubLevelPLos());
    if (levelPlosStatus === "idle") {
      dispatch(fetchLevelPlos());
    }
    //dispatch(fetchNotAffectedCourses(programSpec_id));
  }, [levelPlosStatus, dispatch]);

  const levelPlos = useSelector((state) => state.levelPlos.levelPlos);
  const subLevelPlos = useSelector((state) => state.subLevelPlo.subLevelPLos);

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
    // message.error('Submit failed!');
  };
  let selected_list = [];

  let introduced_list = [];
  let practiced_list = [];
  let mastered_list = [];
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      updatePlo({
        id: props.plo.id,
        ...values,
      })
    );

    if (updatePlo.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const plo = resultAction.payload;
      dispatch(fetchPlosByProgramId(props.plo.programSpec_id));
      openNotificationWithIcon("success", `Edited ${plo.name}`);
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };
  function onChangeLevel(value) {
    setLevelPlo_id(value);
  }
  function onChangeSubLevelPlo(value) {
    setSubLevelPlo_id(value);
    setLevelPlo_id(value);
  }
  let courses_list = [];
  function onChangeCourses(value) {
    setSelectedCourses(value);
  }
  function onChangePerformance(value) {
    console.log(`selected perf++++ ${value}`);
    setPerformance(value);
  }
  courses &&
    courses.map((c) => {
      var index = selectedCourses.indexOf(c.id);
      if (index > -1) {
        selected_list.push(c);
      }
    });

  const apiRef = useGridApiRef();
  const columns = [
    {
      field: "title",
      headerName: "Course",
      width: 330,
      valueGetter: (params) => params.value,
    },
    {
      field: "performance",
      headerName: "Performance",
      width: 180,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Introduced", "Practiced", "Mastered"],
      preProcessEditCellProps: (params) => {
        const editRowsModel = apiRef.current.getEditRowsModel();

        return { ...params.props };
      },
    },
  ];
  const onCellEditCommit = async (event) => {
    if (introduced_list.indexOf(event.id) > -1) {
      introduced_list.splice(introduced_list.indexOf(event.id), 1);
    }
    if (practiced_list.indexOf(event.id) > -1) {
      practiced_list.splice(practiced_list.indexOf(event.id), 1);
    }
    if (mastered_list.indexOf(event.id) > -1) {
      mastered_list.splice(mastered_list.indexOf(event.id), 1);
    }
    if (event.value === "Introduced") {
      introduced_list.push(event.id);
    }
    if (event.value === "Practiced") {
      practiced_list.push(event.id);
    }
    if (event.value === "Mastered") {
      mastered_list.push(event.id);
    }
  };

  const [sortModel, setSortModel] = React.useState([
    {
      field: "title",
      sort: "asc",
    },
  ]);

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
        }}
      >
        {t(" Edit")}
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Edit PLO")}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                form={form}
                //   labelCol={{ span: 8 }}
                //   wrapperCol={{ span: 16 }}
                //   initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                //   autoComplete="off"
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's name",
                        },
                      ]}
                    >
                      <Input
                        id="name"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Code Level")}
                      name="code_level"
                      // rules={[
                      //   {

                      //   },
                      // ]}
                    >
                      <Input
                        id="code_level"
                        value={code_level}
                        //readOnly
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Code")}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's code",
                        },
                      ]}
                    >
                      <Input
                        id="code"
                        value={code}
                        onChange={(event) => {
                          setCode(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Target")}
                      name="target"
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's target",
                        },
                      ]}
                    >
                      <Input
                        id="target"
                        value={target}
                        onChange={(event) => {
                          setTarget(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Level")}
                      name="levelPlo"
                      rules={[
                        {
                          required: false,
                          message: "Please input the plo's levelPlo_id",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="levelPlo"
                        value={levelPlo}
                        onChange={onChangeLevel}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        dropdownStyle={{ zIndex: 10000 }}
                      >
                        {levelPlos &&
                          levelPlos.map((level) => (
                            <Option key={level.id} value={level.id}>
                              {level.level}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Level/Sub Level")}
                      name="subLevelPlo"
                      rules={[
                        {
                          required: true,
                          message: "Please input the plo's level and subLevel",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeSubLevelPlo}
                        dropdownStyle={{ zIndex: 10000 }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {subLevelPlos &&
                          subLevelPlos.map((subLevel) => (
                            <Option key={subLevel.id} value={subLevel.id}>
                              {`${subLevel.levelPlo_id} /  ${subLevel.level}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>


                  {/* <GridItem xs={12} sm={12} md={10}>
                    <h4>
                      <small>
                        Desired levels of performance
                      </small>
                    </h4>
                  </GridItem> */}
                  {/* <GridItem xs={4} sm={4} md={12}>
                    <Form.Item label={t("Courses")} name="courses">
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="courses"
                        value={courses}
                        dropdownStyle={{ zIndex: 10000 }}
                        onChange={onChangeCourses}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {courses &&
                          courses.map((c) => (
                            <Option key={c.id} value={c.id}>
                              {c.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <h6> Desired levels of performance</h6>

                    <GridContainer
                      style={{
                        height: 300,
                        width: 520,
                      }}
                    >
                      <DataGrid
                        rows={selected_list && selected_list}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[]}
                        hideFooterPagination
                        editMode="row"
                        Options={{ singleClickEdit: true }}
                        onCellEditCommit={onCellEditCommit}
                        apiRef={apiRef}
                      />
                    </GridContainer>
                  </GridItem> */}
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
