import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import { makeStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import { Breadcrumb, Tabs } from "antd";
import "antd/dist/antd.css";
import {
  blackColor, hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { MediaBaseUrl } from "assets/utils/accessToken";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import teacher from "../../../../assets/img/teacher.png";
import "./cvTech.css";
const { TabPane } = Tabs;
const useNavBarStyles = makeStyles(navBarStyles);
const TeachingStaffExcerpt = ({ item }) => {
  const { t } = useTranslation();
  return (
    <GridItem xs={12} sm={12} md={4} key={item.id}>
      <Link to={`/citrine/faculty/teachingStaff/${item && item.id}`}>
        <div
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            height: "130px",
            margin: "20px",
          }}
        >
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem key={item.id} xs={12} sm={12} md={4}>
              {item && item.person_details && item.person_details[0].avatar === "" ? (
                <img src={teacher} width="100%" height="120px" />
              ) : (
                <img src={MediaBaseUrl + `${item && item.person_details && item.person_details[0].avatar}`} width="100%" height="120px" />
              )}
            </GridItem>
            <GridItem key={item.id} xs={12} sm={12} md={8}>
              <div>
                {item && item.person_details && item.person_details[0].first_name} {item && item && item.person_details && item.person_details[0].last_name}
              </div>
              <div>{item && item.person_details && item.person_details[0].role}</div>
              <div>{item && item.person_details && item.person_details[0].email}</div>
              <div>
                (+216)
                {item && item.person_details && item.person_details[0].phone_number}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Link>
    </GridItem>
  );
};

export default function TeachingStaff(props) {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStaff());
  }, []);

  const persons = useSelector((state) => state.user.staffList);
  const staffStatus = useSelector((state) => state.user.staffStatus);

  const [search, setSearch] = useState("");

  const navBarClasses = useNavBarStyles();
  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;
  let content;
  if (staffStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (staffStatus === "succeeded") {
    content =
      persons &&
      persons.filter((p) => {
        // no search key  & no  selected states
        if (p && p.person_details && p.person_details[0].first_name && p.person_details && p.person_details[0].first_name.toLowerCase().includes(search.toLowerCase())) {
          return p;
        }
        else if (p && p.person_details && p.person_details[0].last_name && p.person_details && p.person_details[0].last_name.toLowerCase().includes(search.toLowerCase())) { return p; }
        // select state and search key
      }).map((item) => <TeachingStaffExcerpt key={item.id} item={item} />);
  }

  return (
    <div>
      <GridItem>
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/teachingStaff`}>
                {t("Teaching Staff")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridItem>

      <Card>
        <CardHeader
          color="info"
          icon
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "60px",
          }}
        >
          <CardIcon
            color="warning"
            style={{
              height: "58px",
              borderRadius: "7px",
              width: "58px",
              marginTop: "-10px",
            }}
          >
            <GroupIcon />
          </CardIcon>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <h4
                  style={{
                    color: "#6C7B9D",
                    height: "13px",
                    fontSize: "16px",
                    marginRight: "20px",
                  }}
                >
                  <strong> {t("Teaching Staff Directory")} </strong>
                </h4>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}></GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                inputProps={{
                  onChange: (event) => {
                    setSearch(event.target.value);
                  },
                  value: search,
                  placeholder: "Search",
                }}
              />
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>

      <GridContainer xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ height: "68vh", backgroundColor: "#F8F8F8" }}>
            <div class="card" class="scrollable">
              <div
                style={{
                  padding: "10px",
                  borderRadius: "0px",
                  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                  background: "#F8F8F8",
                  width: "100%",
                  boxShadow:
                    "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "0",
                  wordWrap: "break-word",
                  fontSize: ".875rem",
                }}
              >
                <GridContainer>{content}</GridContainer>
              </div>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
