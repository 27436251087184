import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Input, notification, Select } from 'antd';
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobs } from 'reduxToolkit/features/jobs/JobsSlice';
import {
    addNewMajorTrack, fetchMajorTracks
} from '../../reduxToolkit/features/majorTracks/MajorTracksSlice';
import { fetchProgramById } from '../../reduxToolkit/features/program/ProgramSpecSlice';
import ErrorModal from "./ErrorModal";



export default function MajorTracksModal(props) {
    const { t } = useTranslation();
    const { Option } = Select;
   
    const programSpec_id = props.programSpec_id;
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const [pathway, setPathway] = useState('')
    const [credit_hours, setCredit_hours] = useState('')
    const [jobs, setJobs_ids] = useState('')
    const [target, setTarget] = useState(null)

    const { error } = useSelector((state) => state.majorTracks)
    const dispatch = useDispatch()


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };
    const majorTracksStatus = useSelector((state) => state.majorTracks.majorTracksStatus)
    const jobsStatus = useSelector((state) => state.jobs.jobsStatus)
    useEffect(() => {
        if (majorTracksStatus === "idle") {
            dispatch(fetchMajorTracks(programSpec_id));
        }
        if (jobsStatus === "idle") {
            dispatch(fetchJobs(programSpec_id));
        }
    }, [majorTracksStatus, jobsStatus, dispatch]);

    const majorTracks = useSelector((state) => state.majorTracks.majorTracks)
    const jobsList = useSelector((state) => state.jobs.jobs)
   
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // message.error('Submit failed!');
    };

    const onFinish = async () => {
        // const target = parseInt(target)
        const resultAction = await dispatch(
            addNewMajorTrack({ pathway, credit_hours, jobs, programSpec_id })
        )

        if (addNewMajorTrack.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            const majorTrack = resultAction.payload
         
            openNotificationWithIcon('success', `Added ${majorTrack.pathway}`)
            handleClose()
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
               
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                handleClose()
            } else {
             
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                handleClose()
            }
        }
        dispatch(fetchProgramById(programSpec_id))
    }

    function onChangeJobs_ids(value) {
        setJobs_ids(value)
    }

    return (
        <div>


            <Button color="primary" onClick={handleClickOpen}>
                {t('Add')}
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('Add a Major Track')}</DialogTitle>
                <DialogContent>
                    <GridContainer>

                        <GridItem xs={10} sm={10} md={12}>

                            <Form
                                name="basic"
                                layout="vertical"
                                //   labelCol={{ span: 8 }}
                                //   wrapperCol={{ span: 16 }}
                                //   initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                //   autoComplete="off"
                                scrollToFirstError
                            >

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Form.Item
                                            label={t('Pathway')}
                                            name="pathway"
                                            // hasFeedback
                                            rules={[{ required: true, message: "Please input the majorTrack's pathway" }]}

                                        >
                                            <Input
                                                id="pathway"
                                                value={pathway}
                                                onChange={event => {
                                                    setPathway(event.target.value);
                                                }}
                                            />
                                        </Form.Item>

                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>
                                        <Form.Item
                                            label={t("Credit hours")}
                                            name="credit_hours"
                                            rules={[{ required: true, message: "Please input the majorTrack's credit_hours" }]}
                                        >
                                            <Input
                                                id="credit_hours"
                                                type="number"
                                                value={credit_hours}
                                                onChange={event => {
                                                    setCredit_hours(event.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={4} sm={4} md={6}>
                                        <Form.Item
                                            label={t('Jobs')}
                                            name="jobs"
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                showArrow
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                                name="jobs"
                                                value={jobs}
                                                onChange={onChangeJobs_ids}
                                                dropdownStyle={{ zIndex: 10000 }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >

                                                {jobsList && jobsList.map(job => (

                                                    <Option key={job.id} value={job.id}>
                                                        {job.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>


                                {/* </FormControl> */}
                                <GridContainer justify="flex-end">
                                    <GridItem>

                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button color="primary" onClick={() => { handleClose() }}>
                                                        {t('Cancel')}
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t('Save')}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>

                                    </GridItem>
                                </GridContainer>

                            </Form>
                        </GridItem>

                    </GridContainer>

                </DialogContent>
            </Dialog>

            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />

        </div>
    );
}
