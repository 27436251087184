import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { useDispatch, useSelector } from 'react-redux'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import 'assets/css/styles.css'
import 'antd/dist/antd.css'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  fetchGroupsAssessment,
  affectNewGroup,
} from 'reduxToolkit/features/group/GroupSlice'
import { useTranslation } from 'react-i18next'
import { Form, Input, Select, notification } from 'antd'
import ErrorModal from '../../../Components/ErrorModal'
import AddIcon from '@material-ui/icons/Add'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'
import { addNewGroupAssessment, } from 'reduxToolkit/features/group/GroupSlice'
import { Radio } from 'antd'

export default function AddAssessmentMethodGroup(props) {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [open, setOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [value, setValue] = useState(1)

  const groupsAssessments = useSelector((state) => state.group.groupsByAssessment,)

  useEffect(() => {
    dispatch(fetchGroupsAssessment(props.course_id))
    dispatch(fetchStaff())
  }, [])

  const { Option } = Select

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeHandler = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setSelectedFile(selectedFile)
      } else {
        alert('Please select a valid Excel file (xlsx).');
        event.target.value = null;
      }
    }
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
      zIndex: 1000,
    })
  }

  const onFinishFailed = (errorInfo) => { }

  const onFinishUploadMode = async (values) => {

    let resource_id = props.course_id
    let resource = 'course'
    let model = 'student'
    let document = selectedFile
    let name = values.name
    let assessmentMethod_id = props.Assessmentid
    let semester_id = localStorage.getItem('semester_id')
    const uploadData = new FormData()

    uploadData.append('resource_id', resource_id)
    uploadData.append('resource', resource)
    uploadData.append('model', model)
    uploadData.append('document', document)
    uploadData.append('name', name)
    uploadData.append('assessmentMethod_id', assessmentMethod_id)
    uploadData.append('semester_id', semester_id)

    const resultAction = await dispatch(addNewGroupAssessment(uploadData))

    if (addNewGroupAssessment.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `Added successfully`)
      let obj = {
        course_id: props.course_id,
        assessmentMethod_id: props.Assessmentid,
        semester_id: localStorage.getItem('semester_id'),
      }
      props.setPing(prevState => !prevState)
      form.resetFields()
      handleClose()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      }
    }
  }

  const onFinishSelectedMode = async (values) => {
    dispatch(
      affectNewGroup({
        ...values,
        resource_id: props.course_id,
        assessmentMethod_id: props.Assessmentid,
        semester_id: localStorage.getItem('semester_id')
      }))
      .unwrap()
      .then((res) => {
        openNotificationWithIcon('success', `Affected successfully`)
        props.setPing(prevState => !prevState)
        form.resetFields()
        handleClose()
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen} startIcon={<AddIcon />}>
        {t('Add')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Add Group')}</center>
        </DialogTitle>
        <DialogContent>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>{t('Upload New Group')}</Radio>
            <Radio value={2} style={{ marginRight: '20px' }}>
              {t('Select Existing Group')}
            </Radio>
          </Radio.Group>
          <br />
          {value === 1 ?
            (
              <GridContainer justifyContent="center">
                <GridItem>
                  <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinishUploadMode}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError
                  >
                    <Form.Item
                      label={t('Name')}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the group name',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <GridContainer alignItems='baseline' >
                      <GridItem xs={12} sm={12} md={4}>
                        <strong
                          style={{ fontSize: '14px' }}
                        >
                          {t('Students List csv')} :
                        </strong><br />
                        <small style={{ color: 'red' }}>Accept only <bold>xlsx</bold> files !</small><br />
                        <small style={{ color: "gray", fontStyle: "italic" }}>Download file format&nbsp;
                          <a
                            style={{ color: 'orange' }}
                            href={require('../../../../assets/example/group-students.xlsx')}
                            download="group-students-example"
                            target="_blank"
                            rel="noreferrer"
                          >
                            here
                          </a>
                        </small>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <input
                          type="file"
                          name="file"
                          onChange={changeHandler}
                          accept=".xlsx"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ width: 500, textAlign: 'end' }}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ display: 'flex', justifyContent: 'end' }}
                      >
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                          style={{ marginRight: '20px' }}
                        >
                          <Button
                            color="danger"
                            type="submit"
                            onClick={handleClose}
                          >
                            {t('Cancel')}
                          </Button>
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                        >
                          <Button color="info" type="submit">
                            {t('Submit')}
                          </Button>
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </Form>
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer justifyContent="center">
                <GridItem>{props.assessmentQuestionsMethod_name}</GridItem>
                <GridItem>
                  <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinishSelectedMode}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="group_id"
                      label="Groups"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the State field.',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Groups "
                        dropdownStyle={{ zIndex: 10000 }}
                      >
                        {groupsAssessments &&
                          groupsAssessments.map(({ group_details }) => (
                            <Option key={group_details.id} value={group_details.id}>
                              {group_details.group_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <GridContainer style={{ width: 500, textAlign: 'end' }}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ display: 'flex', justifyContent: 'end' }}
                      >
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                          style={{ marginRight: '20px' }}
                        >
                          <Button
                            color="danger"
                            // type="submit"
                            onClick={handleClose}
                          >
                            {t('Cancel')}
                          </Button>
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                        >
                          <Button color="info" type="submit">
                            {t('Submit')}
                          </Button>
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </Form>
                </GridItem>
              </GridContainer>
            )}
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
