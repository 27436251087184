import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  userGroups: [],
  userGroupsStatus: 'idle',
  userGroupStatus: 'idle',
  error: null,
  userGroup: null,
  id: null,
  permissionsList: [],
}

export const fetchUserGroups = createAsyncThunk('userGroup/fetchUserGroups', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/group/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
 //  console.log(response.data,'response.data')

  return response.data
})

export const fetchUserGroupById = createAsyncThunk('userGroup/fetchUserGroupById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/group/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
 console.log(response.data,'response.data group by id')

  return response.data
})

export const permissionsToGroup = createAsyncThunk(
  'userGroup/permissionsToGroup',
  async (initialUserGroup) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/group/permission/create/`, initialUserGroup, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const addNewUserGroup = createAsyncThunk(
  'userGroup/addNewUserGroup',
  async (initialUserGroup) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/group/create/`, initialUserGroup, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateUserGroup = createAsyncThunk(
  'userGroup/updateUserGroup',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')
    console.log('data from slice', data)
    try {
      const response = await axios.put(BaseUrl+`/group/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedUserGroup')
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the userGroup: ', error)

    }
  }
)

export const fetchPermissions = createAsyncThunk('group/fetchPermissions', async () => {
    const token=localStorage.getItem('token')
      const response = await axios.get(BaseUrl+'/group/permission', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
    //     console.log(response.data,'permissions group')
  
      return response.data
})

export const deleteUserGroup = createAsyncThunk('userGroup/deleteUserGroup', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/group/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  console.log(response.data,'response.data')

  return response.data
})

const userGroupsSlice = createSlice({
  name: ' userGroup',
  initialState,
  extraReducers: {

    [fetchUserGroups.pending]: (state, action) => {
      state.userGroupsStatus = 'loading'
    },
    [fetchUserGroups.fulfilled]: (state, action) => {
      state.userGroupsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.userGroups = action.payload
    },
    [fetchUserGroups.rejected]: (state, action) => {
      state.userGroupsStatus = 'failed'
      state.error = action.payload
    },
    [fetchPermissions.pending]: (state, action) => {
        state.userGroupsStatus = 'loading'
      },
      [fetchPermissions.fulfilled]: (state, action) => {
        state.userGroupsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.permissionsList = action.payload
      //  console.log('state permissions', state.permissionsList)
      },
      [fetchPermissions.rejected]: (state, action) => {
        state.userGroupsStatus = 'failed'
        state.error = action.payload
      },
  
    [fetchUserGroupById.pending]: (state, action) => {
      state.userGroupstatus = 'loading'
    },
    [fetchUserGroupById.fulfilled]: (state, action) => {
      state.userGroupstatus = 'succeeded'

      // Add any fetched posts to the array
      state.userGroup = action.payload
    },
    [fetchUserGroupById.rejected]: (state, action) => {
      state.userGroupstatus = 'failed'
      state.error = action.payload
    },
    [addNewUserGroup.fulfilled]: (state, action) => {
      state.userGroups.push(action.payload)
    },
    [permissionsToGroup.fulfilled]: (state, action) => {
      state.userGroups.push(action.payload)
    },
    
    [deleteUserGroup.fulfilled]: (state, action) => {
      // state.userGroups.push(action.payload)
    },
    [updateUserGroup.fulfilled]: (state, action) => {
      // const result = state.userGroups.filter(userGroup => userGroup.id !== action.payload.id);
      // result.push(action.payload)
      // state.userGroups = result

    },
  },

})


export default userGroupsSlice.reducer

 export const selectAllUserGroups = state => state.userGroup.userUserGroups