import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  fields: [],
  fieldsStatus: 'idle',
  fieldStatus: 'idle',
  error: null,
  field:null,
}

export const fetchFields = createAsyncThunk('fieldSpec/fetchFields', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/fieldSpec/', 
    {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
      
      )
      // console.log(response.data,'response.data')

    return response.data
  })

  export const fetchFieldById = createAsyncThunk('fieldSpec/fetchFieldById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/fieldSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  
  export const addNewField = createAsyncThunk(
    'fieldSpec/addNewField',
    async (initialField) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/fieldSpec/create/`,initialField ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )

  export const updateField = createAsyncThunk(
    'fieldSpec/updateField',
    async (data,thunkAPI) => {
      console.log(data,'updatedField')
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/fieldSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
     
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the Field: ', error)

}
    }
  )
  export const deleteField = createAsyncThunk('fieldSpec/deleteField', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/fieldSpec/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')

    return response.data
})

const  fieldsSlice = createSlice({
  name: ' fieldSpec',
  initialState,
  extraReducers: {

    [fetchFields.pending]: (state, action) => {
        state.fieldsStatus = 'loading'
      },
      [fetchFields.fulfilled]: (state, action) => {
        state.fieldsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.fields = action.payload
      },
      [fetchFields.rejected]: (state, action) => {
        state.fieldsStatus = 'failed'
        state.error = action.payload
      },

      [fetchFieldById.pending]: (state, action) => {
        state.fieldStatus = 'loading'
      },
      [fetchFieldById.fulfilled]: (state, action) => {
        state.fieldStatus = 'succeeded'

        // Add any fetched posts to the array
        state.field = action.payload
      },
      [fetchFieldById.rejected]: (state, action) => {
        state.fieldStatus = 'failed'
        state.error = action.payload
      },
      [addNewField.fulfilled]: (state, action) => {
        state.fields.push(action.payload)
      },
      [deleteField.fulfilled]: (state, action) => {
        // state.programs.push(action.payload)
      },
      [updateField.fulfilled]: (state, action) => {
            
         console.log("update fulfilled")

      },

  },


})

export const { fieldAdded, fieldUpdated, getId } = fieldsSlice.actions

export default fieldsSlice.reducer

export const selectAllFields = state => state.fieldSpec.fields

