import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  peoIndicatorsStatus: 'idle',
  peoIndicators: [],
  peoIndicatorStatus: 'idle',
  peoIndicator:null,
  error: null,
}

export const fetchPeoIndicators = createAsyncThunk('peoIndicatorSpec/fetchPeoIndicators', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/peoIndicatorsSpec/', 
    {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
      
      )
      // console.log(response.data,'response.data')

    return response.data
  })

  export const fetchPeoIndicatorById = createAsyncThunk('peoIndicatorSpec/fetchPeoIndicatorById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/peoIndicatorsSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })

  export const addNewPeoIndicator = createAsyncThunk(
    'peoIndicatorSpec/addNewPeoIndicator',
    async (initialPeoIndicator) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/peoIndicatorsSpec/create/`,initialPeoIndicator ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )

//   export const updateProgram = createAsyncThunk(
//     'program/updateProgram',
//     async (data,thunkAPI) => {
      
//       try{
//         const response = await axios.put(`http://127.0.0.1:8000/api/program/${data.id}/edit/`,data.updatedProgram ,{
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })   
//       console.log(data,'updatedProgram')
//       console.log(response.data,'response.data')   
//       return response.data


//     } catch (error) {
// console.error('Failed to update the program: ', error)

// }
//     }
//   )

  export const deletePeoIndicator = createAsyncThunk('peoIndicatorSpec/deletePeoIndicator', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(BaseUrl+`/peoIndicatorsSpec/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err.response.data)
  }
  })

const  peoIndicatorsSlice = createSlice({
  name: 'peoIndicatorSpec',
  initialState,
  extraReducers: {

    [fetchPeoIndicators.pending]: (state, action) => {
        state.peoIndicatorsStatus = 'loading'
      },
      [fetchPeoIndicators.fulfilled]: (state, action) => {
        state.peoIndicatorsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.peoIndicators = state.peoIndicators.concat(action.payload)
      },
      [fetchPeoIndicators.rejected]: (state, action) => {
        state.peoIndicatorsStatus = 'failed'
        state.error = action.payload
      },

      [fetchPeoIndicatorById.pending]: (state, action) => {
        state.peoIndicatorStatus = 'loading'
      },
      [fetchPeoIndicatorById.fulfilled]: (state, action) => {
        state.peoIndicatorStatus = 'succeeded'

        // Add any fetched posts to the array
        state.peoIndicator = action.payload
      },
      [fetchPeoIndicatorById.rejected]: (state, action) => {
        state.peoIndicatorStatus = 'failed'
        state.error = action.payload
      },

      [addNewPeoIndicator.fulfilled]: (state, action) => {
        state.peoIndicators.push(action.payload)
      },

      [deletePeoIndicator.fulfilled]: (state, action) => {
        console.log(action.payload)
      },
//       [updateProgram.fulfilled]: (state, action) => {
//         const result = state.programs.filter(program => program.id !== action.payload.id);
//         // console.log("program id from filter from slice",program.id)
//         result.push(action.payload)
//         state.programs = result       
//         // console.log("action.payload.id from slice",action.payload.id)

//       },

  },

})

// export const { programAdded, programUpdated, getId } = programsSlice.actions

export default peoIndicatorsSlice.reducer

export const selectAllPeoIndicators = state => state.peoIndicatorSpec.peoIndicators
