import { ExclamationCircleOutlined } from "@ant-design/icons";
import { EditingState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table, TableEditRow, TableHeaderRow, TableInlineCellEditing
} from "@devexpress/dx-react-grid-material-ui";
// editable array
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { SchoolRounded } from "@material-ui/icons";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import EditIcon from "@material-ui/icons/Edit";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, notification } from "antd";
import "antd/dist/antd.css";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import randomSeed from "../../../../assets/demo-data/random";
import {
  addNewNoteClo
} from "../../../../reduxToolkit/features/assessment/AssessmentSlice";
import {
  fetchAssessmentMethodById,
  fetchClos_InAssessmentMethodById
} from "../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import { fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import FileUploader from "../../../Components/Uploaders/PDFUploader";







const useStyles = makeStyles(styles);

const useStylesAlert = makeStyles(stylesAlert);

const AssessmentsDetails = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const classesAlert = useStylesAlert();

  const course = useSelector((state) => state.course.course);

  let { id, Assessmentid, ProgramId, AssessmentName } = useParams();


  const [columns, setColumns] = useState([]);
  const [students, setStudents] = useState([]);
  const [notesClos, setNotesClos] = useState([]);
  const [rows, setRows] = useState([]);
  const [editable, setEditable] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const [oncommit, setOncommit] = React.useState(true);
  const [onrefresh, setOnrefresh] = React.useState(false);

  const [editingRowIds, setEditingRowIds] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [validationStatus, setValidationStatus] = useState({});

  useEffect(() => {
    dispatch(fetchProgramById(ProgramId));
    // dispatch(fetchCourseAssessmentMethodById(Assessmentid));
    let arr = [];
    let lastArray = [];
    let obj_Assessment = {
      id: Assessmentid,
      course_id: id,
    };

    dispatch(fetchClos_InAssessmentMethodById(obj_Assessment))
      .then(unwrapResult)
      .then((AssessmentMethodClos) => {
        AssessmentMethodClos &&
          AssessmentMethodClos.map((item, id) => {
            let obj = {
              name: `${item && item.code_level && item.code_level}`,
              title: `${item && item.code_level && item.code_level}  (/${item.total_marks
                })`,
            };
            arr = [...arr, obj];
            lastArray = [{ name: "StudentId", title: "Student ID" }, ...arr];

            setColumns(lastArray);
          });
      });

    setNotesClos([]);
    let notesArray = [];
    dispatch(fetchAssessmentMethodById(obj_Assessment))
      .then(unwrapResult)
      .then((AssessmentMethod) => {
        AssessmentMethod &&
          AssessmentMethod.map((item, id) => {
            notesArray = [...notesArray, item];
            setNotesClos(notesArray);
          });
        console.log(notesArray, "notesArray");
      });

    let newarr = [];
    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        course.course_students.map((item) => {
          newarr = [...newarr, item.student_identification];
        });
        setStudents(newarr);
      });
  }, [dispatch]);


  const AssessmentMethodClos = useSelector(
    (state) => state.AssessmentMethods.AssessmentMethodClos
  );
  const CourseAssessmentMethod = useSelector(
    (state) => state.assessment.courseAssessmentMethod
  );
  const AssessmentMethod = useSelector(
    (state) => state.AssessmentMethods.assessmentMethod
  );
  console.log(AssessmentMethod, "AssessmentMethod")
  const program = useSelector((state) => state.program.program);


  console.log(AssessmentMethod, "Assessment Method ");

  useEffect(() => {
    console.log(notesClos, " notesClos");
    const StudentsRwo = [...students];
    let studentsList = [];

    StudentsRwo &&
      StudentsRwo.map((item) => {
        studentsList = [...studentsList, item];
      });

    const requiredRule = {
      isValid: (value) => value?.trim().length > 0,
      errorText: "This field is required",
    };
    let objt = {};
    let array1 = [];
    columns.forEach((item, id) => {
      array1 = [...array1, item.name];
      for (var i = 0; i < array1.length; i++) {
        objt[array1[i]] = requiredRule;
      }
    });

    const validationRules = objt;
    const validate = (changed, validationStatus) =>
      Object.keys(changed).reduce((status, id) => {
        let rowStatus = validationStatus[id] || {};
        if (changed[id]) {
          rowStatus = {
            ...rowStatus,
            ...Object.keys(changed[id]).reduce((acc, field) => {
              const isValid = validationRules[field].isValid(
                changed[id][field]
              );
              return {
                ...acc,
                [field]: {
                  isValid,
                  error: !isValid && validationRules[field].errorText,
                },
              };
            }, {}),
          };
        }

        return { ...status, [id]: rowStatus };
      }, {});

    const defaultColumnValues = {};

    function generateRows({
      columnValues = defaultColumnValues,

      length,
      random = randomSeed(329972281),
    }) {
      //console.log(columnValues, 'columnValues')
      const data = [];
      const columns = Object.keys(columnValues);
      for (let i = 0; i < length; i++) {
        const record = {};
        columns.forEach((column) => {
          let values = columnValues[column];
          //  console.log(values, 'values')
          if (typeof values === "function") {
            record[column] = values({ random, index: i, record });
            // console.log('type fonction ')
            //  console.log(values({ random, index: i, record }), 'values({ random, index: i, record }); ')
            return;
          }
          while (values.length === 2 && typeof values[1] === "object") {
            //  console.log('type object1 ')
            values = values[1][record[values[0]]];
          }
          const value = values[i];
          if (typeof value === "object") {
            // console.log('type object1 ')
            record[column] = { ...value };
          } else {
            //   console.log('else ')
            record[column] = value;
            //console.log(record,'record ')
          }
        });
        data.push(record);
      }
      //console.log(data, 'data')
      return data;
    }

    let totalarray = [];

    for (let i = 0; i < notesClos.length; i++) {
      let array7 = [];
      console.log(notesClos[i], " notesClos[i]");
      let table = course && course.course_students.map((item) => item);
      console.log(table, " table");

      if (table) {
        for (let j = 0; j < table.length; j++) {
          notesClos[i] &&
            notesClos[i].forEach((el) => {
              if (table[j].id === el.student_id) {
                array7[j] = el.note;
              }
              if (!array7[j]) {
                array7[j] = "";
              }
            });
        }
        console.log(array7, "array7");
        totalarray = [...totalarray, array7];
        // totalarray = [...totalarray, array7];
        console.log(totalarray, "totalarray");
      }
    }

    let totalarray1 = [];
    for (let i = 0; i < totalarray.length; i++) {
      totalarray1 = [...new Set(totalarray)];
    }
    console.log(totalarray1, "totalarray1");
    // console.log(course && course.clos, "course.clos");

    let NotesValues = {};
    let NameColumn;
    let CLosLength = AssessmentMethodClos && AssessmentMethodClos.length;
    // console.log("CLosLength", CLosLength);
    for (let i = 0; i < CLosLength; i++) {
      // console.log("hello");
      NameColumn = `${AssessmentMethodClos[i].code_level}`;
      // console.log(NameColumn, "NameColumn");
      // console.log(NotesValues[NameColumn], "  NotesValues[NameColumn] 0");
      NotesValues[NameColumn] = totalarray1[i];
      // console.log(NotesValues[NameColumn], "  NotesValues[NameColumn] 1");
      // console.log(NotesValues, "NotesValues1");
    }
    console.log(NotesValues, "NotesValues");
    const Students = studentsList.map((el) => el);
    const StudentIdentification = {
      StudentId: Students,
    };

    const finalResult = { ...StudentIdentification, NotesValues };
    const result = Object.values(finalResult)[1];

    const result_str_json = JSON.parse(JSON.stringify(result));
    const student_Json = JSON.parse(JSON.stringify(StudentIdentification));
    var lastResult_one = Object.assign(student_Json, result_str_json);

    const lastResult = JSON.parse(JSON.stringify(lastResult_one));

    const tab = generateRows({
      columnValues: { ...lastResult },
      length: Students.length,
    });
    let arr2 = [];
    for (let i = 0; i < tab.length; i++) {
      const newObj = Object.fromEntries(
        Object.entries(tab[i]).map(([k, v]) => [JSON.stringify(k), Number(v)])
      );
      arr2.push(newObj);
    }
    const tab_Json = JSON.parse(JSON.stringify(tab));
    setRows(tab_Json);
  }, [notesClos, students, columns, course]);

  console.log(rows, "rows");

  const commitChanges = async ({ changed }) => {
    let changedRows;
    let changedEdit = [];
    //     console.log(changed, 'changed')
    let changedNote = Object.values(changed)[0];

    if (
      changedNote &&
      Object.values(changedNote)[0].trim().length > 0 &&
      Number(Object.values(changedNote)[0]) <= 100
    ) {
      setOncommit(true);

      let cloID;
      let CourseAssessmentMethodID;
      let TotalMarks;
      AssessmentMethodClos &&
        AssessmentMethodClos.map((el) => {
          // console.log(el,"el in assessment")
          if (el.code_level === Object.keys(changedNote)[0]) {
            cloID = el.clo_id;
            CourseAssessmentMethodID = el.c_a_m_id;
            TotalMarks = el.total_marks;
          }
        });

      let student_identificationID;
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );

      let rowId = Object.keys(changed)[0];

      course &&
        course.course_students.map((item) => {
          if (
            `${item.student_identification}` === changedRows[rowId].StudentId
          ) {
            student_identificationID = item.id;
          }
        });
      let submitedNoteClo = {
        note: Object.values(changedNote)[0],
        student_id: student_identificationID,
        clo_id: cloID,
        courseAssessmentMethod_id: CourseAssessmentMethodID,
        semester_id: course.semester_id,
        submission_date: "2020-02-02",
      };
      try {
        console.log(submitedNoteClo, "submitedNoteClo");
        const resultAction = await dispatch(addNewNoteClo(submitedNoteClo));
        unwrapResult(resultAction);
      } catch (err) {
      } finally {
      }

      for (let i = 0; i < changedRows.length; i++) {
        for (let key in changedRows[i]) {
          if (changed[i] && key === Object.keys(changedNote)[0]) {
            changedRows[i][key] = Number(Object.values(changedNote)[0]);
            changedEdit = [...changedRows];
          } else if (changed[i] && key != Object.keys(changedNote)[0]) {
            changedRows[i][Object.keys(changedNote)[0]] = Number(
              Object.values(changedNote)[0]
            );
            changedEdit = [...changedRows];
          } else if (changed[i] && Object.values(changedNote)[0].length === 0) {
            //            console.log(Object.values(changedNote)[0], 'no changes')
          }
        }
      }

      setRows(changedEdit);
      //   console.log(changedEdit, 'changedEdit after edit')
    }
    if (changedNote && Object.values(changedNote)[0].trim().length === 0) {
      setOncommit(true);
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
      let cloID;
      let CourseAssessmentMethodID;
      let TotalMarks;
      AssessmentMethodClos &&
        AssessmentMethodClos.map((el) => {
          // console.log(el,"el in assessment")
          if (el.code_level === Object.keys(changedNote)[0]) {
            cloID = el.clo_id;
            CourseAssessmentMethodID = el.c_a_m_id;
            TotalMarks = el.total_marks;
          }
        });

      let student_identification;
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );

      let rowId = Object.keys(changed)[0];

      course &&
        course.course_students.map((item) => {
          if (
            `${item.student_identification}` === changedRows[rowId].StudentId
          ) {
            student_identification = item.id;
          }
        });

      let submitedDeletedNoteClo = {
        note: "",
        student_id: student_identification,
        clo_id: cloID,
        courseAssessmentMethod_id: CourseAssessmentMethodID,
        semester_id: course.semester_id,
        submission_date: "2020-02-02",
      };
      try {
        console.log(submitedDeletedNoteClo, "submitedNoteClo");
        const resultAction = await dispatch(
          addNewNoteClo(submitedDeletedNoteClo)
        );
        unwrapResult(resultAction);
      } catch (err) {
      } finally {
        //     console.log('done')
      }
      for (let i = 0; i < changedRows.length; i++) {
        for (let key in changedRows[i]) {
          if (changed[i] && Object.values(changedNote)[0] === "") {
            changedRows[i][Object.keys(changedNote)[0]] =
              Object.values(changedNote)[0];
            changedEdit = [...changedRows];
          }
        }
      }

      setRows(changedEdit);
      //   console.log(changedEdit, 'changedEdit in delete case ')
    }
    if (
      changedNote &&
      Object.values(changedNote)[0].trim().length > 0 &&
      Number(Object.values(changedNote)[0]) > 100
    ) {
      setAlert(
        setAlert(
          notification.open({
            message: "Incorrect Note",
            style: {
              zIndex: "-1",
              marginTop: "50px",
              backgroundColor: "#f1fed3",
            },
            description: `please enter a note between 0 and the Total Marks !`,
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          })
        )
      );
      setOncommit(false);
    }
  };

  const Cell = React.useCallback(
    (props) => {
      const {
        tableRow: { rowId },
        column: { name: columnName },
      } = props;
      const columnStatus = validationStatus[rowId]?.[columnName];
      const valid = !columnStatus || columnStatus.isValid;
      const style = {
        ...(!valid ? { border: "1px solid red" } : null),
      };
      const title = valid ? "" : validationStatus[rowId][columnName].error;

      return <Table.Cell {...props} style={style} title={title} />;
    },
    [validationStatus]
  );

  return (
    <div>
      {rows ? (
        <div>
          <GridItem>
            <Breadcrumb separator=">">
            <Breadcrumb.Item>
            <Link
              to={`/citrine/courseCoordinatorWorkspace/assessment/`}

            >
              {t("Courses List ")}
            </Link>
          </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/courseCoordinatorWorkspace/assessments_list/course/${id}`}
                >
                  {" "}
                  {t("Assessments Methods")}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/courseCoordinatorWorkspace/assessmentsDetails/course/${id}/assessmentMethod/${Assessmentid}`}
                  style={{ color: "orange" }}
                >
                  {t("Assessment Notes")}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card justify="space-between">
                <CardHeader
                  color="info"
                  icon
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <CardIcon color="primary" style={{ height: "65px" }}>
                    <SchoolRounded />
                  </CardIcon>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={2}>
                      <div>
                       
                          <h4
                            style={{
                              color: "#8F6682",
                              height: "13px",
                              fontSize: "14px",
                              marginRight: "20px",
                            }}
                          >
                            <strong>
                              {course &&
                                course.program_detail &&
                                course.program_detail.map((el) => el.code)}
                            </strong>
                          </h4>
                        
                          <h4
                            style={{
                              color: "#6C7B9D",
                              height: "13px",
                              fontSize: "14px",
                              marginRight: "20px",
                            }}
                          >
                            <strong>
                              {course && course.code && course.code}
                            </strong>
                          </h4>
                       
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <div>
                      
                          <h4
                            style={{
                              color: "#8F6682",
                              fontSize: "14px",
                              height: "13px",
                            }}
                          >
                            <strong>
                              {course &&
                                course.program_detail &&
                                course.program_detail.map(
                                  (el) => el.program_degree_name
                                )}
                            </strong>
                          </h4>
                        
                          <h4
                            style={{
                              color: "#6C7B9D",
                              fontSize: "14px",
                              height: "13px",
                            }}
                          >
                            <strong>
                              {course && course.title && course.title}
                            </strong>
                          </h4>
                        
                      </div>

                      <div>

                        <h4
                          style={{
                            color: "grey",
                            fontSize: "14px",
                            height: "13px",
                          }}
                        >
                          <strong>
                            {CourseAssessmentMethod &&
                              CourseAssessmentMethod.assessmentMethod_detail.name &&
                              CourseAssessmentMethod.assessmentMethod_detail.name}
                          </strong>
                        </h4>
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <div style={{ display: "flex" }}>
                        <h4
                          style={{
                            color: "grey",
                            fontSize: "14px",
                            height: "13px",
                            marginRight: "20px"
                          }}
                        >
                          <strong> {t("Assessment Method")}</strong>
                        </h4>

                        <h4
                          style={{
                            color: "grey",
                            fontSize: "14px",
                            height: "13px",
                          }}
                        >
                          <strong> {AssessmentName}</strong>
                        </h4>
                      </div>

                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{
                        padding: "5px",
                        margin: "10px 0px",
                        textAlign: "right",
                      }}
                    >
                      {editable ? (
                        <Tooltip
                          id="tooltip-top"
                          title="save"
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            color="success"
                            simple
                            justIcon
                            onClick={() => setEditable(false)}
                          >
                            <DoneOutlineIcon
                              className={classes.underChartIcons}
                            />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          id="tooltip-top"
                          title="Edit"
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            color="primary"
                            simple
                            justIcon
                            onClick={() => setEditable(true)}
                          >
                            <EditIcon className={classes.underChartIcons} />
                          </Button>
                        </Tooltip>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>

            <Paper>
              {alert}
              <Grid rows={rows} columns={columns}>
                <EditingState
                  editingRowIds={editingRowIds}
                  onEditingRowIdsChange={setEditingRowIds}
                  rowChanges={rowChanges}
                  onRowChangesChange={setRowChanges}
                  onCommitChanges={commitChanges}
                  columnEditingEnabled={editable}
                />
                <Table cellComponent={Cell} />
                <TableHeaderRow />
                <TableEditRow />
                <TableInlineCellEditing />
              </Grid>
            </Paper>
          </GridContainer>

          <br></br>
          <br></br>
          <br></br>
          <div style={{ marginLeft: "1%" }}>
            <GridContainer>
              {/* <AssessmentsDetailsExam onrefresh={onrefresh} Assessmentid={Assessmentid} /> */}
            </GridContainer>
            <br></br>
            <br></br>
          </div>
        </div>
      ) : (
        <p>loading ...</p>
      )}
      <FileUploader id={Assessmentid} />
    </div>
  );
};
export default AssessmentsDetails;
