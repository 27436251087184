// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import React , { useState, useEffect }from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {  useSelector } from "react-redux";
import { fetchResearchAndInnovationActivitiesByProgram} from "../../../../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice"
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import useResearchs from "../../../../Components/useResearchs";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import Comments from "../Comments";


const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const headCells = [
  { id: "Activities", label: "Activities" },
  { id: "Description", label: "Description" },
  // { id: "Reasons", label: "Reasons" },
  // { id: "Compensating Actions", label: "Compensating Actions" },
  // { id: "delete", label: "" },
];
export default function ResearchAndInnovation(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useResearchs(
    records,
    headCells,
    filterFn
  );
  const callback = (key) => {
    console.log(key);
  };
  const id = props.AnnualProgramReport.program;
  // console.log('idddddddddddddd', props.AnnualProgramReport.program)
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchRAndIActivitiesCustomFunction = async (id) => {
    try {
      const resultAction = await dispatch(fetchResearchAndInnovationActivitiesByProgram(id)).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchRAndIActivitiesCustomFunction(id)
    // dispatch(fetchResearchAndInnovationActivities(id))

  }, [])
  const researchAndInnovationActivities = useSelector((state) => state.researchAndInnovationActivities.researchAndInnovationActivitiesByProgram);
  const researchAndInnovationActivitiesStatus = useSelector((state) => state.researchAndInnovationActivities.researchAndInnovationActivitiesByProgramStatus);
  console.log('researchAndInnovationActivities', researchAndInnovationActivities)
  let comment_resource= "researchAndInnovation"
  return (
    <div>
    <GridContainer xs={12} sm={12} md={12}>
      <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
      <GridItem key={Element.id} xs={12} sm={12} md={1}>
        {/* <AddResearchandInnovationModal
          program_id={
            props.AnnualProgramReport &&
            props.AnnualProgramReport.programs &&
            props.AnnualProgramReport.programs[0] &&
            props.AnnualProgramReport.programs[0].id
          }
        /> */}
      </GridItem>
    </GridContainer>

    <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
      <TblHead />
      <TableBody>
        {researchAndInnovationActivities &&
          researchAndInnovationActivities.map((item) => (
            <TableRow key={item.id}>
              <TableCell
                style={{
                  borderLeft: "1px solid lightgray",
                  borderRight: "1px solid lightgray",
                }}
              >
                <p
                  style={{
                    display: "-webkit-box",
                    maxWidth: "200px",
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {item && item.name}
                </p>
              </TableCell>
              <TableCell
                className="multipleLine"
                style={{ borderRight: "1px solid lightgray" }}
              >
                {item && item.abstract}
              </TableCell>

              {/* <TableCell
                style={{
                  borderRight: "1px solid lightgray",
                }}
              >
                <DeleteOutlined
                  onClick={() => DeletePlannedCourse(item && item.id)}
                  style={{ color: "red" }}
                />
              </TableCell> */}
            </TableRow>
          ))}
      </TableBody>
    </TblContainer>
    <GridContainer xs={12} sm={12} md={12}>
       
       <GridItem key={Element.id} xs={12} sm={12} md={12}>
         <Comments
           AnnualProgramReport={props.AnnualProgramReport &&
             props.AnnualProgramReport} type={comment_resource}/>
       </GridItem>
     </GridContainer>
  </div>
  );
}
