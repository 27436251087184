import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  DifficultiesAndChallengesList: [],
  DifficultiesAndChallengesStatus: "idle",
  error: null,
  DifficultiesAndChallengesListByProgram: [],
  DifficultiesAndChallengesByProgramStatus: "idle",
};

export const fetchDifficultiesAndChallengesByCourseId = createAsyncThunk(
  "DifficultiesAndChallenges/fetchDifficultiesAndChallengesByCourseId",
  async (course_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/difficultiesAndChallenges/?course_id=${course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // const response = jsonData
      console.log(response.data, "response.data course");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchDifficultiesAndChallengesByProgram = createAsyncThunk(
  "DifficultiesAndChallenges/fetchDifficultiesAndChallengesByProgram",
  async (program_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/difficultiesAndChallenges/by_program/?program_id=${program_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // const response = jsonData
      console.log(response.data, "response.data course");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewDifficultiesAndChallenges = createAsyncThunk(
  "DifficultiesAndChallenges/addNewDifficultiesAndChallenges",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + "/difficultiesAndChallenges/create/",
        body,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const deleteDifficultiesAndChallenges = createAsyncThunk(
  "DifficultiesAndChallenges/deleteDifficultiesAndChallenges",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        BaseUrl + `/difficultiesAndChallenges/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const DifficultiesAndChallengesSlice = createSlice({
  name: "DifficultiesAndChallenges",
  initialState,
  extraReducers: {},
  reducers: {},
  extraReducers: {
    [fetchDifficultiesAndChallengesByCourseId.pending]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "loading";
    },
    [fetchDifficultiesAndChallengesByCourseId.fulfilled]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "succeeded";
      state.DifficultiesAndChallengesList = action.payload;
    },
    [fetchDifficultiesAndChallengesByCourseId.rejected]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchDifficultiesAndChallengesByProgram.pending]: (state, action) => {
      state.DifficultiesAndChallengesByProgramStatus = "loading";
    },
    [fetchDifficultiesAndChallengesByProgram.fulfilled]: (state, action) => {
      state.DifficultiesAndChallengesByProgramStatus = "succeeded";
      state.DifficultiesAndChallengesListByProgram = action.payload;
    },
    [fetchDifficultiesAndChallengesByProgram.rejected]: (state, action) => {
      state.DifficultiesAndChallengesByProgramStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewDifficultiesAndChallenges.fulfilled]: (state, action) => { },
    [addNewDifficultiesAndChallenges.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [deleteDifficultiesAndChallenges.fulfilled]: (state, action) => { },
    [deleteDifficultiesAndChallenges.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});


export const { } = DifficultiesAndChallengesSlice.actions;

export default DifficultiesAndChallengesSlice.reducer;
