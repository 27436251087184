import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
    ActivitiesAssessmentMethods: [],
    ActivitiesAssessmentMethodStatus: 'idle',
    ActivitiesAssessmentMethodStatus: 'idle',
    error: null,
    ActivitiesAssessmentMethod: null,

    id: null
}


export const fetchActivitiesAssessmentMethods = createAsyncThunk('ActivitiesAssessmentMethod/fetchActivitiesAssessmentMethod', async (course_id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/activitiesAssessmentMethods/?course_id=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    console.log(response.data, 'response.data')
    // const response = jsonData
    return response.data
})


export const addNewActivitiesAssessmentMethod = createAsyncThunk(
    'ActivitiesAssessmentMethod/addNewActivitiesAssessmentMethod',
    async (initialActivitiesAssessmentMethod) => {
        console.log('initialActivitiesAssessmentMethod', initialActivitiesAssessmentMethod)
        const token = localStorage.getItem('token')
        const response = await axios.post(BaseUrl + `/conclusionsDifficulties/create/`, initialActivitiesAssessmentMethod, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data
    }
)

export const deleteActivitiesAssessmentMethod = createAsyncThunk('ActivitiesAssessmentMethod/deleteActivitiesAssessmentMethod', async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/activitiesAssessmentMethods/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')

    return response.data
})

export const EditAssessmentMethod = createAsyncThunk(
    'ActivitiesAssessmentMethod/EditAssessmentMethod',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.put(BaseUrl + `/activitiesAssessmentMethods/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    }
);

const ActivitiesAssessmentMethodsSlice = createSlice({
    name: 'ModeOfInstruction',
    initialState,

    extraReducers: {
        [fetchActivitiesAssessmentMethods.pending]: (state, action) => {
            state.ActivitiesAssessmentMethodsStatus = 'loading'
        },
        [fetchActivitiesAssessmentMethods.fulfilled]: (state, action) => {
            state.ActivitiesAssessmentMethodsStatus = 'succeeded'

            state.ActivitiesAssessmentMethods = action.payload
            //state.TeachingStrategiesSpec =  jsonData

        },
        [fetchActivitiesAssessmentMethods.rejected]: (state, action) => {
            state.ActivitiesAssessmentMethodsStatus = 'failed'
            state.error = action.payload
        },


        [addNewActivitiesAssessmentMethod.fulfilled]: (state, action) => {
            state.ActivitiesAssessmentMethods.push(action.payload)
        },
        [deleteActivitiesAssessmentMethod.fulfilled]: (state, action) => {
            // state.SpecificationAprovalDatas.push(action.payload)
        },

        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },
        }
    }
})

export const { getId } = ActivitiesAssessmentMethodsSlice.actions

export default ActivitiesAssessmentMethodsSlice.reducer



