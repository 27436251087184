import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  exitPoints: [],
  exitPointsStatus: 'idle',
  exitPointStatus: 'idle',
  error: null,
  exitPoint: null,
  id: null
}

export const fetchExitPoints = createAsyncThunk('exitPoint/fetchExitPoints', async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+ `/exitPoints/?programSpec_id=${id}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

   return response.data

  } catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
  }
  })
  

export const fetchExitPointById = createAsyncThunk('exitPoint/fetchExitPointById', 
async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/exitPoints/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data

} catch (err) {
let error = err;
if (!error.response) {
  throw err;
}
console.log("error.response from inside the CAT", error.response);
return rejectWithValue(error.response);
}
})
export const addNewExitPoint = createAsyncThunk(
  'exitPoint/addNewExitPoint',
  async (initialExitPoint, { rejectWithValue }) => {
   const token=localStorage.getItem('token')
   try {
    const response = await axios.post(BaseUrl+`/exitPoints/create/`, initialExitPoint, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data

  } catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
  }
  })
  

export const updateExitPoint = createAsyncThunk(
  'exitPoint/updateExitPoint',
  async (data, { rejectWithValue }) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/exitPoints/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedExitPoint')
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
    }
    })

export const deleteExitPoint = createAsyncThunk('exitPoint/deleteExitPoint', async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl+`/exitPoints/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data

} catch (err) {
let error = err;
if (!error.response) {
  throw err;
}
console.log("error.response from inside the CAT", error.response);
return rejectWithValue(error.response);
}
})
const exitPointsSlice = createSlice({
  name: ' exitPoint',
  initialState,
  extraReducers: {

    [fetchExitPoints.pending]: (state, action) => {
      state.exitPointsStatus = 'loading'
    },
    [fetchExitPoints.fulfilled]: (state, action) => {
      state.exitPointsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.exitPoints = action.payload
    },
    [fetchExitPoints.rejected]: (state, action) => {
      state.exitPointsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchExitPointById.pending]: (state, action) => {
      state.exitPointstatus = 'loading'
    },
    [fetchExitPointById.fulfilled]: (state, action) => {
      state.exitPointstatus = 'succeeded'

      // Add any fetched posts to the array
      state.exitPoint = action.payload
    },
    [fetchExitPointById.rejected]: (state, action) => {
      state.exitPointstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewExitPoint.fulfilled]: (state, action) => {
      state.exitPoints.push(action.payload)
    },
    [deleteExitPoint.fulfilled]: (state, action) => {
      // state.exitPoints.push(action.payload)
    },
    [updateExitPoint.fulfilled]: (state, action) => {
      const result = state.exitPoints.filter(exitPoint => exitPoint.id !== action.payload.id);
      // console.log("exitPoint id from filter from slice",exitPoint.id)
      result.push(action.payload)
      state.exitPoints = result
      console.log("action.payload.id from slice",action.payload)

    },
    [addNewExitPoint.rejected]: (state, action) => {
      state.exitPointstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteExitPoint.rejected]: (state, action) => {
      state.exitPointstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateExitPoint.rejected]: (state, action) => {
      state.exitPointstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

})


export default exitPointsSlice.reducer

 export const selectAllExitPoints = state => state.exitPoint.exitPoints