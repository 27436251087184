import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch, useSelector } from 'react-redux'

import { addNewCourseAssessmentMethod } from 'reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSpecSlice'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Box from '@mui/material/Box'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import 'assets/css/styles.css'
import clsx from 'clsx'
import 'antd/dist/antd.css'
import MenuItem from '@material-ui/core/MenuItem'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchAssessmentQuestionsMethods } from 'reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import {
  DataGrid,
  GridRowModes,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Switch from '@material-ui/core/Switch'
import { purple } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import moment from 'moment'
import { fetchCloById } from 'reduxToolkit/features/CLO/CloSpecSlice'
import { useTranslation } from 'react-i18next'
import { Form, Input, Select, notification, Radio } from 'antd'
import ErrorModal from '../../../Components/ErrorModal'
import { addNewQuestion } from 'reduxToolkit/features/assessmentMethod/QuestionSlice'
import { withStyles } from '@material-ui/core/styles'
import { fetchCourseById } from 'reduxToolkit/features/course/CoursesSlice.js'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import AddIcon from '@material-ui/icons/Add'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'

import {
  fetchGroups,
  UpdateGroup,
  addGroupsLinkedToCourse,
  addNewGroupAssessment,
} from 'reduxToolkit/features/group/GroupSlice'
import {
  fetchCourseInstructorById,
  addNewCourseInstructor,
} from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import {
  getGroupsByAssessmentMethodAndCourse,
  fetchGroupsAssessment,
} from 'reduxToolkit/features/group/GroupSlice'

import {affectNewGroup} from 'reduxToolkit/features/group/GroupSlice'

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const AddCourseInstructorGroup = (props) => {
  //console.log(props)
  const { course_id, Assessmentid, AssessmentName } = useParams()
  //console.log(course_id, Assessmentid, AssessmentName)
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [instructor_ids, setInstructor_id] = React.useState([])
  const [group_id, setGroup_id] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [submitData, setSubmitData] = useState([])
  const [showUploadGroupForm, setShowUploadGroupForm] = useState(true)
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  })
  //   const [data, setdata] = useState(
  //     props.selectedRowData && props.selectedRowData,
  //   )
  const Rows = []
  const { Option } = Select
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [value, setValue] = React.useState(1)
  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
  }

  const personsStatus = useSelector((state) => state.user.users)
  const handleToggle = () => {
    setShowUploadGroupForm(!showUploadGroupForm)
  }

  useEffect(() => {
    dispatch(fetchGroupsAssessment(course_id))
    // let obj = {
    //   course_id: props.course_id,
    // }
    // console.log('course _id props', props.course_id)
    dispatch(fetchStaff())
  }, [dispatch])
  const persons = useSelector((state) => state.user.staffList)
  const groups = useSelector((state) => state.group.groups)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitData([])
  }

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
    console.log(selectedFile, 'selectedFile')
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
      zIndex: 1000,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }
  const groupsAssessments = useSelector(
    (state) => state.group.groupsByAssessment,
  )

  const [name, setName] = useState('')
  const [selectedFile, setSelectedFile] = useState()
  const [isSelected, setIsSelected] = useState(false)
  const onFinishUploadMode = async (values) => {
    console.log('Success:', values.name)
    let resource_id = course_id
    let resource = 'course'
    let model = 'student'
    let document = selectedFile
    let name = values.name
    let assessmentMethod_id = Assessmentid
    let semester_id = localStorage.getItem('semester_id')
    const uploadData = new FormData()
    uploadData.append('resource_id', resource_id)
    uploadData.append('resource', resource)
    uploadData.append('model', model)
    uploadData.append('document', document)
    uploadData.append('name', name)
    uploadData.append('assessmentMethod_id', assessmentMethod_id)
    uploadData.append('semester_id', semester_id)
    console.log('uploadData', uploadData)
    const resultAction = await dispatch(addNewGroupAssessment(uploadData))
    if (addNewGroupAssessment.fulfilled.match(resultAction)) {
      props.setPing(!props.ping)
      openNotificationWithIcon('success', `Add successfully`)

      //   let obj = {
      //     course_id: course_id,
      //     assessmentMethod_id: Assessmentid,
      //     semester_id: localStorage.getItem('semester_id'),
      //   }
      //   dispatch(getGroupsByAssessmentMethodAndCourse(obj))
      form.resetFields()
      handleClose()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      }
    }
    console.log('uploadData', uploadData)
  }
  const onFinishSelectedMode = async (values) => {
    console.log({
      ...values,
      resource_id: props.course_id,
      assessmentMethod_id: props.Assessmentid,
      semester_id: localStorage.getItem('semester_id')
    })
    dispatch(
      affectNewGroup({
        ...values,
        resource_id: props.course_id,
        assessmentMethod_id: props.Assessmentid,
        semester_id: localStorage.getItem('semester_id')
      }))
        .unwrap()
        .then((res) => {
          props.setPing(!props.ping)
          openNotificationWithIcon('success', `Add successfully`)
          form.resetFields()
          handleClose()
        })
        .catch((err) => {
          setHandledError(err)
          handleOpenErrorModal()
        })

  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        {t('New Group')}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Add Group')}</center>
        </DialogTitle>
        <DialogContent>
          <div>
            {/* <Typography component="div">
              <GridContainer component="label" alignItems="center" spacing={1}>
                <GridItem style={{ color: '#000' }}>Select Group</GridItem>
                <GridItem>
                  <AntSwitch
                    checked={state.checkedC}
                    onChange={handleChange}
                    name="checkedC"
                  />
                </GridItem>
                <GridItem style={{ color: '#000' }}>Upload Group</GridItem>
              </GridContainer>
            </Typography> */}
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>{t('Upload Group')}</Radio>
              <Radio value={2} style={{ marginRight: '20px' }}>
                {t('Select Existing Group')}
              </Radio>
            </Radio.Group>
            <br />

            {value === 1 ? (
              <GridContainer justifyContent="center">
                <GridItem>
                  <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinishUploadMode}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError
                  >
                    <Form.Item
                      label={t('Name')}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the group name',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <GridContainer
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                      }}
                    >
                      <GridItem xs={12} sm={12} md={4}>
                        <strong
                          style={{ paddingLeft: '10px', fontSize: '14px' }}
                        >
                          {t('Students List csv')} :
                        </strong>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <input
                          type="file"
                          name="file"
                          onChange={changeHandler}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer style={{ width: 500, textAlign: 'end' }}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ display: 'flex', justifyContent: 'end' }}
                      >
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                          style={{ marginRight: '20px' }}
                        >
                          <Button
                            color="danger"
                            type="submit"
                            onClick={handleClose}
                          >
                            {t('Cancel')}
                          </Button>
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                        >
                          <Button color="info" type="submit">
                            {t('Submit')}
                          </Button>
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </Form>
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer justifyContent="center">
                <GridItem>
                  <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinishSelectedMode}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="group_id"
                      label="Groups"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the State field.',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Groups "
                        dropdownStyle={{ zIndex: 10000 }}
                      >
                        {groupsAssessments &&
                          groupsAssessments.map(({group_details}) => (
                            <Option key={group_details.id} value={group_details.id}>
                              {group_details.group_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <GridContainer style={{ width: 500, textAlign: 'end' }}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ display: 'flex', justifyContent: 'end' }}
                      >
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                          style={{ marginRight: '20px' }}
                        >
                          <Button
                            color="danger"
                            type="submit"
                            onClick={handleClose}
                          >
                            {t('Cancel')}
                          </Button>
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                        >
                          <Button color="info" type="submit">
                            {t('Submit')}
                          </Button>
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </Form>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default AddCourseInstructorGroup
