import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { lazy, useState } from 'react'
import Button from '../../../../components/CustomButtons/Button'

import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchAssessmentById } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import {
  addNewAttachmentAssessmentEvidence,
  deleteAttachmentAssessmentEvidence
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice.js'

import { useTranslation } from 'react-i18next'

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { notification } from 'antd'
import { pdfjs } from 'react-pdf'
import { DocumentBaseUrl } from "../../../../assets/utils/accessToken"
import '../../../../views/Components/Uploaders/upload.css'
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal"
import DeleteAttachmentButton from "./DeleteAttachmentButton"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
const Component = lazy(() => import("../../../Components/filesView/FilesView"));
export default function UploadAttachmentAssessmentEvidence(props) {
  const { Assessmentid, AssessmentName } = useParams()
  const [upload, setUpload] = useState(false)
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState()
  const [isSelected, setIsSelected] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
  }
  const dispatch = useDispatch()
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const handleSubmission = () => {
    try {
      const evidenceAssessment_id = props.item.id
      const resource = props.resource
      const resourceId = props.resourceId
      console.log('resource', props.resource, props.resourceId)
      const document = selectedFile
      const uploadData = new FormData()
      uploadData.append('document', document)
      uploadData.append('resource', resource)
      uploadData.append('resourceId', resourceId)
      uploadData.append('evidenceAssessment_id', evidenceAssessment_id)
      {
        selectedFile &&
          dispatch(addNewAttachmentAssessmentEvidence(uploadData)).then(() => {
            dispatch(fetchAssessmentById(Assessmentid))
            openNotificationWithIcon('success', `File Added successfully`)
            setSelectedFile('')
            setIsSelected(false)
          })
      }
      setUpload(false)
    } catch (error) {
      console.log(error)
      openNotificationWithIcon('error', `somthing went wrong`)
    }
  }

  const error = useSelector((state) => state.program.error)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const handleDelete = (id) => {
    console.log('idddd', item)
    dispatch(deleteAttachmentAssessmentEvidence(item))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchAssessmentById(Assessmentid))
        openNotificationWithIcon('success', `File Deleted successfully`)
      })
  }
  const [ping, setPing] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = useState(null);
  const handleOpenDeleteModal = () => {
    setOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setOpen(false);
    setItem(null);
  };

  return (
    <div>
      <GridContainer>

        <div>
          {upload ? (
            <div>
              <input
                id="fileInput"
                type="file"
                name="file"
                onChange={changeHandler}

              />

              <Button color="primary" onClick={handleSubmission}>
                Submit
              </Button>
            </div>
          ) : (
            <Fab onClick={() => setUpload(!upload)} color="#CBDCCB" size='small'>
              <AddIcon></AddIcon>
            </Fab>
          )}
        </div>
        <GridItem xs={12} sm={12} md={10}></GridItem>
      </GridContainer>
      <GridContainer style={{ marginTop: '50px' }}>
        {props &&
          props.item &&
          props.item.evidence_documents &&
          props.item.evidence_documents.map((attachment) => (
            console.log('attachment', attachment),
            <GridItem
              xs={12}
              sm={12}
              md={11}
              key={attachment.id}
              style={{
                borderRadius: '4px',
                backgroundColor: '#edeff5',
                marginBottom: '8px',
                height: '55px',
              }}
            >
              <GridContainer style={{ height: '50px' }}>
                {/* <GridItem xs={12} sm={12} md={3}>
                  <a href={DocumentBaseUrl + `${attachment?.document}`} target="_blank">
                    <PictureAsPdfIcon style={{ fontSize: '36px' }} />
                  </a>
                </GridItem> */}
                <GridItem xs={12} sm={12} md={7}>
                  <a style={{ fontSize: '12px' }} target="_blank" href={DocumentBaseUrl + `${attachment?.document}`}>
                    {attachment?.document.slice(10)}
                  </a>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <div className="delete" style={{ textAlign: 'center' }}>
                    <DeleteAttachmentButton attachment={attachment} Assessmentid={Assessmentid} />
                  </div>

                  {/* <div className="delete" style={{ textAlign: 'center' }}>
                    {attachment.document && (
                      <PDFDownloadLink
                        document={
                          <Document
                            file={attachment.document}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page pageNumber={pageNumber} />
                          </Document>
                        }
                        fileName={attachment.name}
                      >
                        {({ blob, url, loading, error }) => (
                          <SaveAltIcon
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          />
                        )}
                      </PDFDownloadLink>
                    )}
                  </div> */}
                </GridItem>
              </GridContainer>
            </GridItem>
          ))}
      </GridContainer>
      <SuperGeneralDeleteModal
        delete_Element={handleDelete}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
