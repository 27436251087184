import { CheckOutlined } from "@ant-design/icons";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { deleteActionRecommendation } from "../../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import { fetchProgramImprovementPlan } from "../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import {
  deleteRecommendation,
  fetchRecommandationsByResourceAndResourceId,
} from "../../../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import ActionAPRecommendationModal from "../../../Components/ActionAPRecommendationModal";
import StarIcon from "@material-ui/icons/Star";
import useTable from "../../../Components/useTable";
import useTableRecommendation from "../../../Components/useTableRecommendation";
import "../improvement.css";

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const headCells = [
  { id: "Priorities", label: "Priorities" },
  { id: "Actions", label: "Actions" },
  { id: "Responsibility", label: "Responsibility" },
  { id: "Start Date", label: "Start Date" },
  { id: "End Date", label: "Due Date" },
  { id: "Achievement", label: "Achievement Indicators" },
  { id: "Target Benchmark", label: "Target Benchmark" },
  { id: "delete", label: "" },
];

const headRecommendationsCells = [
  { id: "Recommendations", label: "Recommendations" },
  { id: "Actions", label: "Actions" },
  // { id: "Edit/Delete", label: "" },
];
export default function ProgramImprovementPlan(props) {
  console.log(props.AnnualProgramReport.program)
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();
  const [aPRecommendations, setAPRecommendations] = React.useState(null);
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn);
  let program_id =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].id;
  let annualProgram_id =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.id &&
    props.AnnualProgramReport.id;
  const {
    TblRecommendationContainer,
    TblRecommendationHead,
  } = useTableRecommendation(records, headRecommendationsCells, filterFn);


  useEffect(() => {
    dispatch(
      fetchRecommandationsByResourceAndResourceId({
        resource_id: annualProgram_id,
        resource: "annualProgram",
      })
    )
      .then(unwrapResult)
      .then((Recommendations) => {
        setAPRecommendations(Recommendations);
      });
    dispatch(fetchStaff());
  }, []);

  const users = useSelector((state) => state.user.staffList);

  const fetchRecs = () => {
    dispatch(
      fetchRecommandationsByResourceAndResourceId({
        resource_id: annualProgram_id,
        resource: "annualProgram",
      })
    )
      .then(unwrapResult)
      .then((Recommendations) => {
        setAPRecommendations(Recommendations);
      });
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const delete_ActionRecommendation = (id, objId) => {
    dispatch(deleteActionRecommendation(id)).then(() => {
      dispatch(
        fetchRecommandationsByResourceAndResourceId({
          resource_id: annualProgram_id,
          resource: "annualProgram",
        })
      )
        .then(unwrapResult)
        .then((Recommendations) => {
          setAPRecommendations(Recommendations);
        });
    });
  };
  const fetchActionRecs = () => {
    dispatch(
      fetchRecommandationsByResourceAndResourceId({
        resource_id: annualProgram_id,
        resource: "annualProgram",
      })
    )
      .then(unwrapResult)
      .then((Recommendations) => {
        setAPRecommendations(Recommendations);
      });
  };
  const [pageSize, setPageSize] = React.useState(10);
  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: "flex" }}>

          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />

        </div>
      );
    } else if (value == 2) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />

        </div>
      );
    } else if (value == 3) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />

        </div>
      );
    }
  };
  return (
    <div>
      {/* <div style={{ marginLeft: "20px" }}>
        <AddAPRecommendationModal
          handleDropDownButtonClose={handleClose}
          annualProgram_id={annualProgram_id}
          fetchRecs={fetchRecs}
        />
      </div> */}
      <div
        style={{
          margin: "0px 10px 10px 10px ",
          borderRight: "1px solid lightgray",
          borderLeft: "1px solid lightgray",
        }}
      >
        <TblRecommendationContainer
          style={{ marginTop: "0px", paddingTop: "0px" }}
        >
          <TblRecommendationHead />
          <TableBody>
            {aPRecommendations &&
              aPRecommendations.map((item) => (
                <TableRow key={item.id}>
                  <TableCell
                    style={{
                      borderRight: "1px solid lightgray",
                    }}
                  >
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={11}>
                        <p
                          style={{
                            display: "-webkit-box",
                            maxWidth: "200px",
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item && item.recommendation}
                        </p>
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={1}>
                        <EditAPRecommendationModal recommendation_id={item.id} annualProgram_id={props.annualProgram_id} fetchRecs={fetchRecs} />
                      </GridItem> */}
                    </GridContainer>
                  </TableCell>

                  <TableCell
                    className="multipleLinePlo"
                    style={{
                      width: "490px",
                      borderRight: "1px solid lightgray",
                    }}
                  >
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={11}></GridItem>

                      <GridItem xs={12} sm={12} md={1}>
                        <ActionAPRecommendationModal
                          handleDropDownButtonClose={handleClose}
                          recommendation_id={item.id}
                          annualProgram_id={annualProgram_id}
                          users={users}
                          fetchActionRecs={fetchActionRecs}
                          program_id={props.AnnualProgramReport.program}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer xs={12} sm={12} md={12}>
                      {item &&
                        item.action_recommendations &&
                        item.action_recommendations.map((element) => (
                          <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={11}>
                              <strong>
                                <CheckOutlined />{" "}
                                {element.type_action && element.type_action}{" "}
                                :
                              </strong>
                              {element.text && element.text}
                              <br></br>
                              <strong>
                                {" "}
                                Assigned to :  {" "}
                              </strong>
                              {element.assign_to}
                              <br></br>
                              <strong>
                                {" "}
                                Start date : {" "}
                              </strong>
                              {element.start_date && element.start_date}
                              <br></br>
                              <strong>
                                {" "}
                                Due date : {" "}
                              </strong>
                              {element.endDate && element.endDate}
                              <br></br>
                              <strong>
                                {" "}
                                Priority : {RaitingRender(element && element.priorityId)}
                              </strong>

                              <br></br>

                            </GridItem>

                            <GridItem xs={12} sm={12} md={1}>
                              <DeleteOutlineIcon
                                onClick={() =>
                                  delete_ActionRecommendation(
                                    element.id,
                                    item.annualProgram_id
                                  )
                                }
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        ))}
                    </GridContainer>
                  </TableCell>

                  {/* <TableCell
                    className={classes.tableActions}
                    style={{
                      borderRight: "1px solid lightgray",
                    }}
                  >

                    <Tooltip
                      id="tooltip-top-start"
                      title="Remove"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Close"
                        style={{
                          color: "red",
                          fontSize: "10px",
                        }}
                        className={classes.tableActionButton}
                        onClick={() =>
                          delete_APRecommendations(
                            item.id,
                            item.annualProgram_id
                          )
                        }
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + " "
                            // classes.close
                          }
                          style={{
                            fontSize: "18px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </TblRecommendationContainer>
      </div>
    </div>
  );
}
