import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  AssessmentResponsibilities: [],
  AssessmentResponsibilitiesStatus: 'idle',
  AssessmentResponsibilityStatus: 'idle',
  error: null,
  AssessmentResponsibility: null,
  id: null,
}

export const fetchAssessmentResponsibilities = createAsyncThunk(
  'AssessmentResponsibility/fetchAssessmentResponsibilities',
  async (courseSpec_id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/assessmentResponsibilities/?courseSpec_id=${courseSpec_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchAssessmentResponsibilityById = createAsyncThunk(
  'AssessmentResponsibility/fetchAssessmentResponsibilityById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/assessmentResponsibilities/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      console.log(response.data, 'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const addNewAssessmentResponsibility = createAsyncThunk(
  'AssessmentResponsibility/addNewAssessmentResponsibility',
  async (initialAssessmentResponsibility, { rejectWithValue }) => {
    console.log(
      'initial AssessmentResponsibility',
      initialAssessmentResponsibility
    )
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/assessmentResponsibilities/create/`,
        initialAssessmentResponsibility,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(response.data)
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const AssessmentResponsibilityUpdate = createAsyncThunk(
  'AssessmentResponsibility/updateAssessmentResponsibility',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/assessmentResponsibilities/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(data, 'updated')
      console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const deleteAssessmentResponsibility = createAsyncThunk(
  'AssessmentResponsibility/deleteAssessmentResponsibility',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/assessmentResponsibilities/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(response.data, ' delete response.data')

      return response
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
const AssessmentResponsibilitySlice = createSlice({
  name: 'DatesAndTimesAllocation',
  initialState,

  extraReducers: {
    [fetchAssessmentResponsibilities.pending]: (state, action) => {
      state.AssessmentResponsibilitiesStatus = 'loading'
    },
    [fetchAssessmentResponsibilities.fulfilled]: (state, action) => {
      state.AssessmentResponsibilitiesStatus = 'succeeded'
      state.AssessmentResponsibilities = action.payload
    },
    [fetchAssessmentResponsibilities.rejected]: (state, action) => {
      state.AssessmentResponsibilitiesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAssessmentResponsibilityById.pending]: (state, action) => {
      state.AssessmentResponsibilityStatus = 'loading'
    },
    [fetchAssessmentResponsibilityById.fulfilled]: (state, action) => {
      state.AssessmentResponsibilityStatus = 'succeeded'
      state.AssessmentResponsibility = action.payload
    },
    [fetchAssessmentResponsibilityById.rejected]: (state, action) => {
      state.AssessmentResponsibilityStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewAssessmentResponsibility.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewAssessmentResponsibility.fulfilled]: (state, action) => {
      state.AssessmentResponsibilities.push(action.payload)
    },
    [deleteAssessmentResponsibility.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteAssessmentResponsibility.fulfilled]: (state, action) => {},
    [AssessmentResponsibilityUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [AssessmentResponsibilityUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    reducers: {},
  },
})

export const {} = AssessmentResponsibilitySlice.actions

export default AssessmentResponsibilitySlice.reducer
