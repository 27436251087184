import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  surveys: [],
  surveyTemplatesStatus: 'idle',
  surveyTemplateStatus: 'idle',
  error: null,
  surveyTemplates: [],
  surveyTemplate: null,

  questionStatus: 'idle',
  questions: [],
  programSurveyTemplatesStatus: 'idle',
  programSurveyTemplates: [],

  possibleResponsesType_5Status: 'idle',
  possibleResponsesType_5: [],
  possibleResponsesType_3Status: 'idle',
  possibleResponsesType_3: [],

  campaignAnalysisStatus: 'idle',
  campaignAnalysis: [],

  campaignChartStatus: 'idle',
  campaignChart: [],

  campaignSummaryStatus: 'idle',
  campaignSummary: null,

}

export const fetchSurveyTemplates = createAsyncThunk('survey/fetchSurveyTemplates', async (param, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + '/surveyTemplate/all/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')

    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchSurveyTemplateById = createAsyncThunk('survey/fetchSurveyTemplateById', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/surveyTemplate/${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchQuestionsBySurveyTemplate = createAsyncThunk('survey/fetchQuestionsBySurveyTemplate', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/surveyWeight/?surveyTemplate_id=${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchPossibleType_5ResponsesBySurveyTemplate = createAsyncThunk('survey/fetchPossibletype_5ResponsesBySurveyTemplate', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/surveyDefaultResponse/byTemplate/?surveyTemplate_id=${id}&type=type_5`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchPossibleType_3ResponsesBySurveyTemplate = createAsyncThunk('survey/fetchPossibleType_3ResponsesBySurveyTemplate', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/surveyDefaultResponse/byTemplate/?surveyTemplate_id=${id}&type=type_3`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchCampaignAnalysis = createAsyncThunk('survey/fetchCampaignAnalysis', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/surveyQuestionResponse/surveyAnalysis/?campaign_id=${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchCampaignChart = createAsyncThunk('survey/fetchCampaignChart', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/surveyQuestionResponse/surveyChart/?campaign_id=${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchCampaignSummary = createAsyncThunk('survey/fetchCampaignSummary', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/campaign/summary/?campaign_id=${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})
export const getProgramSurveyTemplates = createAsyncThunk(
  "survey/getProgramSurveyTemplates", async (id, { rejectWithValue }) => {
    try {

      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/surveyTemplate/by_Type/?type=${id}`,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }

      )
      console.log('response.data', response.data)
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
);

export const addNewSurveyTemplate = createAsyncThunk(
  'survey/addNewSurveyTemplate',
  async (initialSurveyTemplate, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/surveyTemplate/create/`, initialSurveyTemplate, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response ", error.response);
      return rejectWithValue(error.response);
    }
  })

export const sendSurveyToStudents = createAsyncThunk(
  'survey/sendSurveyToStudents',
  async (survey, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/surveyResponse/generate/`, survey, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response ", error.response);
      return rejectWithValue(error.response);
    }
  })

export const uploadParticipants = createAsyncThunk(
  'survey/uploadParticipants',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/attachment/assessment_group/update/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response ", error.response);
      return rejectWithValue(error.response);
    }
  })
export const uploadParticipantsProgram = createAsyncThunk(
  'survey/uploadParticipantsProgram',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    console.log('data************************', data)
    try {
      const response = await axios.post(BaseUrl + `/campaign/AddParticipants/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response ", error.response);
      return rejectWithValue(error.response);
    }
  })
export const updateSurveyTemplate = createAsyncThunk(
  'survey/updateSurveyTemplate',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/surveyTemplate/${data.id}/edit/`, data.updatedTemplate, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedTemplate')
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response ", error.response);
      return rejectWithValue(error.response);
    }
  })


export const updateQuestionWeight = createAsyncThunk(
  'survey/updateQuestionWeight',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/surveyWeight/${data.id}/edit/`, data.updatedQuestion, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data



    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response ", error.response);
      return rejectWithValue(error.response);
    }
  })

export const updateResponseWeight = createAsyncThunk(
  'survey/updateResponseWeight',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/surveyDefaultResponse/${data.id}/edit/`, data.updatedResponse, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data



    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response ", error.response);
      return rejectWithValue(error.response);
    }
  })
export const deleteSurveyTemplate = createAsyncThunk('campaign/deleteSurveyTemplate', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/surveyTemplate/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response ", error.response);
    return rejectWithValue(error.response);
  }
})
const surveysSlice = createSlice({
  name: 'survey',
  initialState,
  extraReducers: {

    [fetchSurveyTemplates.pending]: (state, action) => {
      state.surveyTemplatesStatus = 'loading'
    },
    [fetchSurveyTemplates.fulfilled]: (state, action) => {
      state.surveyTemplatesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.surveyTemplates = action.payload
    },
    [fetchSurveyTemplates.rejected]: (state, action) => {
      state.surveyTemplatesStatus = 'failed'

      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [uploadParticipants.pending]: (state, action) => {
      state.surveyTemplateStatus = 'loading'
    },
    [uploadParticipants.fulfilled]: (state, action) => {
      state.surveyTemplateStatus = 'succeeded'
      // Add any fetched posts to the array
      // state.surveyTemplate = action.payload
    },
    [uploadParticipants.rejected]: (state, action) => {
      state.surveyTemplateStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [uploadParticipantsProgram.pending]: (state, action) => {
      state.surveyTemplateStatus = 'loading'
    },
    [uploadParticipantsProgram.fulfilled]: (state, action) => {
      state.surveyTemplateStatus = 'succeeded'
      // Add any fetched posts to the array
      // state.surveyTemplate = action.payload
    },
    [uploadParticipantsProgram.rejected]: (state, action) => {
      state.surveyTemplateStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchSurveyTemplateById.pending]: (state, action) => {
      state.surveyTemplateStatus = 'loading'
    },
    [fetchSurveyTemplateById.fulfilled]: (state, action) => {
      state.surveyTemplateStatus = 'succeeded'
      // Add any fetched posts to the array
      state.surveyTemplate = action.payload
    },
    [fetchSurveyTemplateById.rejected]: (state, action) => {
      state.surveyTemplateStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    ////
    [getProgramSurveyTemplates.pending]: (state, action) => {
      state.programSurveyTemplatesStatus = 'loading'
    },
    [getProgramSurveyTemplates.fulfilled]: (state, action) => {
      state.programSurveyTemplatesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.programSurveyTemplates = action.payload
    },
    [getProgramSurveyTemplates.rejected]: (state, action) => {
      state.programSurveyTemplatesStatus = 'failed'
      state.error = action.payload
    },

    [fetchQuestionsBySurveyTemplate.pending]: (state, action) => {
      state.questionStatus = 'loading'
    },
    [fetchQuestionsBySurveyTemplate.fulfilled]: (state, action) => {
      state.questionStatus = 'succeeded'
      // Add any fetched posts to the array
      state.questions = action.payload
    },
    [fetchQuestionsBySurveyTemplate.rejected]: (state, action) => {
      state.questionStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchPossibleType_5ResponsesBySurveyTemplate.pending]: (state, action) => {
      state.possibleResponsesType_5Status = 'loading'
    },
    [fetchPossibleType_5ResponsesBySurveyTemplate.fulfilled]: (state, action) => {
      state.possibleResponsesType_5Status = 'succeeded'
      // Add any fetched posts to the array
      state.possibleResponsesType_5 = action.payload
    },
    [fetchPossibleType_5ResponsesBySurveyTemplate.rejected]: (state, action) => {
      state.possibleResponsesType_5Status = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchPossibleType_3ResponsesBySurveyTemplate.pending]: (state, action) => {
      state.possibleResponsesType_3Status = 'loading'
    },
    [fetchPossibleType_3ResponsesBySurveyTemplate.fulfilled]: (state, action) => {
      state.possibleResponsesType_3Status = 'succeeded'
      // Add any fetched posts to the array
      state.possibleResponsesType_3 = action.payload
    },
    [fetchPossibleType_3ResponsesBySurveyTemplate.rejected]: (state, action) => {
      state.possibleResponsesType_3Status = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchCampaignAnalysis.pending]: (state, action) => {
      state.campaignAnalysisStatus = 'loading'
    },
    [fetchCampaignAnalysis.fulfilled]: (state, action) => {
      state.campaignAnalysisStatus = 'succeeded'
      // Add any fetched posts to the array
      state.campaignAnalysis = action.payload
    },
    [fetchCampaignAnalysis.rejected]: (state, action) => {
      state.campaignAnalysisStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchCampaignChart.pending]: (state, action) => {
      state.campaignChartStatus = 'loading'
    },
    [fetchCampaignChart.fulfilled]: (state, action) => {
      state.campaignChartStatus = 'succeeded'
      // Add any fetched posts to the array
      state.campaignChart = action.payload
    },
    [fetchCampaignChart.rejected]: (state, action) => {
      state.campaignChartStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchCampaignSummary.pending]: (state, action) => {
      state.campaignSummaryStatus = 'loading'
    },
    [fetchCampaignSummary.fulfilled]: (state, action) => {
      state.campaignSummaryStatus = 'succeeded'
      // Add any fetched posts to the array
      state.campaignSummary = action.payload
    },
    [fetchCampaignSummary.rejected]: (state, action) => {
      state.campaignSummaryStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewSurveyTemplate.fulfilled]: (state, action) => {
      state.surveyTemplates.push(action.payload)
    },
    [addNewSurveyTemplate.rejected]: (state, action) => {
      state.surveyTemplateStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [sendSurveyToStudents.pending]: (state, action) => {
      state.surveyTemplateStatus = 'loading'
    },
    [sendSurveyToStudents.fulfilled]: (state, action) => {
      state.surveys.push(action.payload)
    },
    [sendSurveyToStudents.rejected]: (state, action) => {
      state.surveyTemplateStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteSurveyTemplate.fulfilled]: (state, action) => {
      console.log(action.payload)
    },
    [deleteSurveyTemplate.rejected]: (state, action) => {
      state.surveyTemplateStatus = 'failed'
      if (action.payload) {
        state.error = 'There is an errro'
      } else {
        state.error = action.error
      }
    },
    [updateSurveyTemplate.fulfilled]: (state, action) => {
      // const result = state.surveyTemplates.filter(template => template.id !== action.payload.id);
      // result.push(action.payload)
      // state.surveyTemplates = result
      console.log("action.payload.id from slice", action.payload)

    },
    [updateSurveyTemplate.rejected]: (state, action) => {
      state.surveyTemplateStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }

    },
  },
})


export default surveysSlice.reducer