import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";

// core components
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";

import { Breadcrumb } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { fetchCourseQualityEvaluations } from "reduxToolkit/features/CourseQualityEvaluation/CourseQualityEvaluationSpecSlice";

import DropDownButtonCQE from "../../../../Components/DropDownButtonCQE";

import ErrorModal from "../../../../Components/ErrorModal";

import { useTranslation } from "react-i18next";
import { Tooltip } from 'antd';
import GetAppIcon from "@material-ui/icons/GetApp";


const useNavBarStyles = makeStyles(navBarStyles);

const useStyles = makeStyles(styles);

export default function CourseQualityEvaluationList({ match }) {

  const { t } = useTranslation();
  const navBarClasses = useNavBarStyles();

  const classes = useStyles();

  const { id } = match.params;
  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const CourseQualityEvaluationsStatus = useSelector((state) => state.CourseQualityEvaluationSpec.CourseQualityEvaluationsStatus);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const CourseQualityEvaluations = useSelector((state) => state.CourseQualityEvaluationSpec.CourseQualityEvaluations);

  useEffect(() => {
    fetchCourseQualityEvaluationsByCourseId();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCourseQualityEvaluationsByCourseId = async () => {
    try {
      const resultAction = await dispatch(
        fetchCourseQualityEvaluations(id)
      ).unwrap();
      dispatch(fetchCourseById(id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  let content;

  if (CourseQualityEvaluationsStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (CourseQualityEvaluationsStatus === "succeeded") {
    content = (
      <div>
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseSpecDetails/${course && course.id && course.id
                  }`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/CourseQualityEvaluations`}
                style={{ color: "orange" }}
              >
                {t("Course Quality Evaluation List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <GridContainer>
          <GridItem>
            {disabledStatus ? (
              <p></p>
            ) : (
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/CourseQualityEvaluations/create`}
              >
                <Button color="info" className={classes.updateProfileButton}>
                  {t("Add")}
                </Button>
              </Link>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          {CourseQualityEvaluations &&
            CourseQualityEvaluations.map((Element) => (
              <GridItem key={Element.id} xs={12} sm={12} md={4}>
                <Card style={{ height: 250, textAlign: "start" }}>
                  <CardBody>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={10} sm={10} md={10}>
                        <h4
                          className={classes.cardProductTitle}
                          style={{ textAlign: "start", color: "orange" }}
                        >
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            style={{ color: "orange" }}
                          >
                            {t("Evaluation Areas")} : {Element.evaluation_areas}
                          </a>
                        </h4>
                        <h4
                          className={classes.cardProductTitle}
                          style={{ textAlign: "start" }}
                        >
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {t("Evaluation Method")} :{" "}
                            {Element.evaluation_method}
                          </a>
                        </h4>
                        <h4
                          className={classes.cardProductTitle}
                          style={{ textAlign: "start" }}
                        >
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {t("Evaluators")} :{" "}
                            <Tooltip title={Element?.evaluators}>
                              {Element?.evaluators?.length > 20 ? Element?.evaluators?.slice(0, 50) + '...' : Element?.evaluators}
                            </Tooltip>
                          </a>
                        </h4>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2} style={{ zIndex: 1 }}>
                        {disabledStatus ? (
                          <p></p>
                        ) : (
                          <DropDownButtonCQE
                            CourseQualityEvaluation_ID={Element.id}
                            courseID={course && course.id}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </div>
    );
  }
  return (
    <div>
      {content}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
