import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import React from 'react';
import { MediaBaseUrl } from "../../assets/utils/accessToken";
import { greyColor } from "assets/jss/material-dashboard-pro-react.js";
const AvatarsGroup = (props) => (
  <>
    <Avatar.Group
    // maxCount={2}
    // maxPopoverTrigger="click"
    // maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
    >
      {props.avatars.map((avatar, i) => {
        return (
          <Tooltip key={i} title={avatar.name} placement="top">
            {
              avatar.url ?
                <Avatar
                  src={
                    MediaBaseUrl +
                    `/${avatar.url}`
                  } /> :
                <Avatar
                  style={{
                    backgroundColor: greyColor[0],
                  }}
                  icon={<UserOutlined />}
                />
            }
          </Tooltip>
        )
      })
      }
    </Avatar.Group>
  </>
);

export default AvatarsGroup;
