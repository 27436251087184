import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import token from 'assets/utils/accessToken';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
    SpecificationAprovalDatas: [],
    suSpecificationAprovalDataStatus: 'idle',
    SpecificationAprovalDataStatus: 'idle',
    error: null,
    SpecificationAprovalData: null,

    id: null
}


export const fetchSpecificationAprovalDatas = createAsyncThunk('SpecificationAprovalData/fetchSpecificationAprovalDatas', 
async (course_id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/specificationApprovalData/?courseSpec=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    console.log(response.data, 'response.data')

    return response.data
})

export const fetchSpecificationAprovalDataById = createAsyncThunk('SpecificationAprovalData/fetchSpecificationAprovalDataById', async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/specificationApprovalData/${id}/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, 'response.data')

    return response.data
})
export const addNewSpecificationAprovalData = createAsyncThunk(
    'SpecificationAprovalData/addNewSpecificationAprovalData',
    async (initialSpecificationAprovalData) => {
        console.log('initial SpecificationAprovalData', initialSpecificationAprovalData)
        const token = localStorage.getItem('token')
        const response = await axios.post(BaseUrl + `/specificationApprovalData/create/`, initialSpecificationAprovalData, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data
    }
)
export const SpecificationAprovalDataUpdate = createAsyncThunk(
    'SpecificationAprovalData/SpecificationAprovalDataUpdate',
    async (data, thunkAPI) => {

        try {
            const response = await axios.put(BaseUrl + `/specificationAprovalData/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            console.log(data, 'updatedSpecificationAprovalData')
            console.log(response.data, 'response.data')
            return response.data


        } catch (error) {
            console.error('Failed to update the SpecificationAprovalData: ', error)

        }
    }
)

export const deleteSpecificationAprovalData = createAsyncThunk('SpecificationAprovalData/deleteSpecificationAprovalData', async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/specificationApprovalData/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')

    return response.data
})
const SpecificationAprovalDataSlice = createSlice({
    name: 'SpecificationAprovalData',
    initialState,

    extraReducers: {


        [fetchSpecificationAprovalDatas.pending]: (state, action) => {
            state.subAreasStatus = 'loading'
        },
        [fetchSpecificationAprovalDatas.fulfilled]: (state, action) => {
            state.subAreasStatus = 'succeeded'
            state.SpecificationAprovalDatas = action.payload
        },
        [fetchSpecificationAprovalDatas.rejected]: (state, action) => {
            state.subAreasStatus = 'failed'
            state.error = action.payload
        },


        [fetchSpecificationAprovalDataById.pending]: (state, action) => {
            state.SpecificationAprovalDataStatus = 'loading'
        },
        [fetchSpecificationAprovalDataById.fulfilled]: (state, action) => {
            state.SpecificationAprovalDataStatus = 'succeeded'
            state.SpecificationAprovalData = action.payload
        },
        [fetchSpecificationAprovalDataById.rejected]: (state, action) => {
            state.SpecificationAprovalDataStatus = 'failed'
            state.error = action.payload
        },


        [addNewSpecificationAprovalData.fulfilled]: (state, action) => {
            state.SpecificationAprovalDatas.push(action.payload)
        },
        [deleteSpecificationAprovalData.fulfilled]: (state, action) => {
        },
        [SpecificationAprovalDataUpdate.fulfilled]: (state, action) => {
            const result = state.SpecificationAprovalDatas.filter(SpecificationAprovalData => SpecificationAprovalData.id !== action.payload.id);
            result.push(action.payload)
            state.SpecificationAprovalDatas = result
            console.log("action.payload.id from slice",action.payload)
      
          },


        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },


            SpecificationAprovalDataUpdated(state, action) {
                const { id, council_committee, date, reference, course_id } = action.payload
                const existingSpecificationAprovalData = state.SpecificationAprovalDatas.find(SpecificationAprovalData => SpecificationAprovalData.id === id)
                if (existingSpecificationAprovalData) {
                    existingSpecificationAprovalData.council_committee = council_committee
                    existingSpecificationAprovalData.date = date
                    existingSpecificationAprovalData.reference = reference
                    existingSpecificationAprovalData.course_id = course_id
                }
            }
        }
    }
})

export const { SpecificationAprovalDataAdded, SpecificationAprovalDataUpdated, getId } = SpecificationAprovalDataSlice.actions

export default SpecificationAprovalDataSlice.reducer

//export const selectAllSpecificationAprovalData = state => state.SpecificationAprovalData.SpecificationAprovalDatas

