import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  facultyActivitiesStatus: "idle",
  facultyActivities: [],
  facultyActivityStatus: "idle",
  facultyActivity: null,
  facultyActivityImprovementStatus: "idle",
  facultyActivityImprovement: null,
  error: null,
};

export const fetchfacultyActivitiesByProgram = createAsyncThunk(
  "facultyActivity/fetchfacultyActivities",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/facultyActivities/?program_id=${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const fetchFacultyActivityById = createAsyncThunk(
  "facultyActivity/fetchFacultyActivityById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/facultyActivities/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const addNewFacultyActivity = createAsyncThunk(
  "facultyActivity/addNewFacultyActivity",
  async (toAddFacultyActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/facultyActivities/create/`,
        toAddFacultyActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const updateFacultyActivity = createAsyncThunk(
  "facultyActivity/updateFacultyActivity",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/facultyActivities/${data.id}/edit/`,
        data.updatedFacultyActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      //console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const fetchFacultyActivityImprovementByFacultyActivityId = createAsyncThunk(
  "facultyActivity/fetchFacultyActivityImprovementByFacultyActivityId",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/facultyActivitiesImprovement/?facultyActivity_id=${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const updateFacultyActivityImprovement = createAsyncThunk(
  "facultyActivity/updateFacultyActivityImprovement",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/facultyActivitiesImprovement/${data.id}/edit/`,
        data.updatedFacultyActivityImprovement,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      //console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

const facultyActivitiesSlice = createSlice({
  name: "facultyActivity",
  initialState,
  extraReducers: {
    [fetchfacultyActivitiesByProgram.pending]: (state, action) => {
      state.facultyActivitiesStatus = "loading";
    },
    [fetchfacultyActivitiesByProgram.fulfilled]: (state, action) => {
      state.facultyActivitiesStatus = "succeeded";
      // Add any fetched posts to the array
      state.facultyActivities = action.payload;
    },
    [fetchfacultyActivitiesByProgram.rejected]: (state, action) => {
      state.facultyActivitiesStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    [fetchFacultyActivityById.pending]: (state, action) => {
      state.facultyActivityStatus = "loading";
    },
    [fetchFacultyActivityById.fulfilled]: (state, action) => {
      state.facultyActivityStatus = "succeeded";

      // Add any fetched posts to the array
      state.facultyActivity = action.payload;
    },
    [fetchFacultyActivityById.rejected]: (state, action) => {
      state.facultyActivityStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    [fetchFacultyActivityImprovementByFacultyActivityId.pending]: (state, action) => {
      state.facultyActivityImprovementStatus = "loading";
    },
    [fetchFacultyActivityImprovementByFacultyActivityId.fulfilled]: (state, action) => {
      state.facultyActivityImprovementStatus = "succeeded";

      // Add any fetched posts to the array
      state.facultyActivityImprovement = action.payload;
    },
    [fetchFacultyActivityImprovementByFacultyActivityId.rejected]: (state, action) => {
      state.facultyActivityImprovementStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
  },
});

export default facultyActivitiesSlice.reducer;
