import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';
const initialState = {
    AlignmentOfCLOWithTMAndAMs: [],
    AlignmentOfCLOWithTMAndAMStatus: 'idle',
    AlignmentOfCLOWithTMAndAMStatus: 'idle',
    error: null,
    AlignmentOfCLOWithTMAndAM: null,
    id: null
}


export const fetchAlignmentOfCLOWithTMAndAMs = createAsyncThunk('AlignmentOfCLOWithTMAndAM/fetchAlignmentOfCLOWithTMAndAMs', async (course_id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/alignmentofCLOwithTMandAMSpec/?courseSpec=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    console.log(response.data, 'response.data')

    return response.data
})

  export const fetchAlignmentOfCLOWithTMAndAMById = createAsyncThunk('AlignmentOfCLOWithTMAndAM/fetchAlignmentOfCLOWithTMAndAMById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/alignmentofCLOwithTMandAMSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data,'response.data')

    return response.data
  })
export const addNewAlignmentOfCLOWithTMAndAM = createAsyncThunk(
    'AlignmentOfCLOWithTMAndAM/addNewAlignmentOfCLOWithTMAndAM',
    async (initialAlignmentOfCLOWithTMAndAM) => {
        console.log('initial AlignmentOfCLOWithTMAndAM', initialAlignmentOfCLOWithTMAndAM)
        const token=localStorage.getItem('token')
        const response = await axios.post(BaseUrl+`/alignmentofCLOwithTMandAMSpec/create/`, initialAlignmentOfCLOWithTMAndAM, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data
    }
)
  export const AlignmentOfCLOWithTMAndAMUpdate = createAsyncThunk(
    'AlignmentOfCLOWithTMAndAM/AlignmentOfCLOWithTMAndAMUpdate',
    async (data,thunkAPI) => {
        const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/alignmentofCLOwithTMandAMSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedAlignmentOfCLOWithTMAndAM')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the AlignmentOfCLOWithTMAndAM: ', error)

}
    }
  )

export const deleteAlignmentOfCLOWithTMAndAM = createAsyncThunk('AlignmentOfCLOWithTMAndAM/deleteAlignmentOfCLOWithTMAndAM', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/alignmentofCLOwithTMandAMSpec/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
     console.log(response.data,' delete response.data')

    return response.data
})
const AlignmentOfCLOWithTMAndAMSlice = createSlice({
    name: 'ModeOfInstruction',
    initialState,

    extraReducers: {
        [fetchAlignmentOfCLOWithTMAndAMs.pending]: (state, action) => {
            state.AlignmentOfCLOWithTMAndAMStatus = 'loading'
        },
        [fetchAlignmentOfCLOWithTMAndAMs.fulfilled]: (state, action) => {
            state.AlignmentOfCLOWithTMAndAMStatus = 'succeeded'
            // Add any fetched posts to the array
            state.AlignmentOfCLOWithTMAndAMs = action.payload
        },
        [fetchAlignmentOfCLOWithTMAndAMs.rejected]: (state, action) => {
            state.AlignmentOfCLOWithTMAndAMStatus = 'failed'
            state.error = action.payload
        },

          [fetchAlignmentOfCLOWithTMAndAMById.pending]: (state, action) => {
            state.AlignmentOfCLOWithTMAndAMStatus = 'loading'
          },
          [fetchAlignmentOfCLOWithTMAndAMById.fulfilled]: (state, action) => {
            state.AlignmentOfCLOWithTMAndAMStatus = 'succeeded'

            // Add any fetched posts to the array
            state.AlignmentOfCLOWithTMAndAM = action.payload
          },
          [fetchAlignmentOfCLOWithTMAndAMById.rejected]: (state, action) => {
            state.AlignmentOfCLOWithTMAndAMStatus = 'failed'
            state.error = action.payload
          },
        [addNewAlignmentOfCLOWithTMAndAM.fulfilled]: (state, action) => {
            state.AlignmentOfCLOWithTMAndAMs.push(action.payload)
        },
        [deleteAlignmentOfCLOWithTMAndAM.fulfilled]: (state, action) => {
           // state.SpecificationAprovalDatas.push(action.payload)
        },
          [AlignmentOfCLOWithTMAndAMUpdate.fulfilled]: (state, action) => {
            console.log(action) 
          },



        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },


            // AlignmentOfCLOWithTMAndAMUpdated(state, action) {
            //     const { id, name } = action.payload
            //     const existingAlignmentOfCLOWithTMAndAM = state.AlignmentOfCLOWithTMAndAMs.find(AlignmentOfCLOWithTMAndAM => AlignmentOfCLOWithTMAndAM.id === id)
            //     if (existingAlignmentOfCLOWithTMAndAM) {
            //         existingAlignmentOfCLOWithTMAndAM.name = name


            //     }
            // }
        }
    }
})

export const {
    //AlignmentOfCLOWithTMAndAMAdded, 
    //AlignmentOfCLOWithTMAndAMUpdated, 
    getId } = AlignmentOfCLOWithTMAndAMSlice.actions

export default AlignmentOfCLOWithTMAndAMSlice.reducer

export const selectAllAssessmentTasksForStudent = state => state.AlignmentOfCLOWithTMAndAMs.AlignmentOfCLOWithTMAndAMs

