import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  programSpecificationReportDocuments: [],
  programSpecificationReportDocumentsStatus: 'idle',
  programSpecificationReportDocument: null,
  programSpecificationReportDocumentStatus: 'idle',
  error: null,

}

export const fetchProgramSpecificationReportDocuments =
 createAsyncThunk('programSpecificationReportDocument/fetchProgramSpecificationReportDocuments',
  async ( { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+'/htmlTemplateProgramSpecificationReport/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

   return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

export const fetchProgramSpecificationReportDocumentsByProgramSpecificationId = 
createAsyncThunk('programSpecificationReportDocument/fetchProgramSpecificationReportDocumentsByProgramSpecificationId',
 async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/htmlTemplateProgramSpecificationReport/by_programSpec/?programSpec_id=${id}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

   return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);
export const fetchProgramSpecificationReportDocumentById = 
createAsyncThunk('programSpecificationReportDocument/fetchProgramSpecificationReportDocumentById',
 async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/htmlTemplateProgramSpecificationReport/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);
export const addNewProgramSpecificationReportDocument = createAsyncThunk(
  'programSpecificationReportDocument/addNewProgramSpecificationReportDocument',
  async (initialProgramSpecificationReportDocument,{rejectWithValue}) => {
   const token=localStorage.getItem('token')
  try{
    const response = await axios.post(BaseUrl+`/htmlTemplateProgramSpecificationReport/create/`, initialProgramSpecificationReportDocument, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }

  }
)

export const updateProgramSpecificationReportDocument = createAsyncThunk(
  'programSpecificationReportDocument/updateProgramSpecificationReportDocument',
  async (data,{rejectWithValue}) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/htmlTemplateProgramSpecificationReport/${data.id}/edit/`, data.updatedProgramSpecificationReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  
    }
  )

export const deleteProgramSpecificationReportDocument =
 createAsyncThunk('programSpecificationReportDocument/deleteProgramSpecificationReportDocument', 
 async (id,{rejectWithValue}) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl+`/htmlTemplateProgramSpecificationReport/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);


export const VersionningDocumentReportProgramSpec = createAsyncThunk(
  'courseSpecReportDocument/VersionningDocumentReportProgramSpec',
  async (initialCourse, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/htmlTemplateProgramSpecificationReport/version/`, initialCourse, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)



const programSpecificationReportDocumentsSlice = createSlice({
  name: ' programSpecificationReportDocument',
  initialState,
  extraReducers: {
    

    [fetchProgramSpecificationReportDocumentsByProgramSpecificationId.pending]: (state, action) => {
      state.programSpecificationReportDocumentsStatus = 'loading'
    },
    [fetchProgramSpecificationReportDocumentsByProgramSpecificationId.fulfilled]: (state, action) => {
      state.programSpecificationReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.programSpecificationReportDocuments = action.payload
    },
    [fetchProgramSpecificationReportDocumentsByProgramSpecificationId.rejected]: (state, action) => {
      state.programSpecificationReportDocumentsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchProgramSpecificationReportDocumentById.pending]: (state, action) => {
      state.programSpecificationReportDocumentStatus = 'loading'
    },
    [fetchProgramSpecificationReportDocumentById.fulfilled]: (state, action) => {
      state.programSpecificationReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.programSpecificationReportDocument = action.payload
    },
    [fetchProgramSpecificationReportDocumentById.rejected]: (state, action) => {
      state.programSpecificationReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewProgramSpecificationReportDocument.fulfilled]: (state, action) => {
    //   state.HTMLTemplates.push(action.payload)
    state.programSpecificationReportDocuments.push(action.payload);
    console.log("ProgramSpecificationReportDocument add action.payload",action.payload)

    },
    [deleteProgramSpecificationReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("ProgramSpecificationReportDocument delete action.payload",action.payload)
    },
    [updateProgramSpecificationReportDocument.fulfilled]: (state, action) => {
    //   const result = state.HTMLTemplates.filter(HTMLTemplate => HTMLTemplate.id !== action.payload.id);
    //   // console.log("HTMLTemplate id from filter from slice",HTMLTemplate.id)
    //   result.push(action.payload)
    //   state.HTMLTemplate = result
      console.log("ProgramSpecificationReportDocument update action.payload",action.payload)

    },
    [addNewProgramSpecificationReportDocument.rejected]: (state, action) => {
      //   state.HTMLTemplates.push(action.payload)
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
      [deleteProgramSpecificationReportDocument.rejected]: (state, action) => {
        // state.HTMLTemplates.push(action.payload)
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [updateProgramSpecificationReportDocument.rejected]: (state, action) => {
      //   const result = state.HTMLTemplates.filter(HTMLTemplate => HTMLTemplate.id !== action.payload.id);
      //   // console.log("HTMLTemplate id from filter from slice",HTMLTemplate.id)
      //   result.push(action.payload)
      //   state.HTMLTemplate = result
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },


    [VersionningDocumentReportProgramSpec.fulfilled]: (state, action) => {
    },
    [VersionningDocumentReportProgramSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },

})


export default programSpecificationReportDocumentsSlice.reducer


