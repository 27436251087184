import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import { Breadcrumb, notification } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
// @material-ui/core components
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import CardFooter from "components/Card/CardFooter";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import DropDownButtonCourse from "../../../../views/Components/DropDownButtonCourse";
import AffectCoursesToProgramModal from "./AffectCoursesToProgramModal";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import {
  grayColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const CourseExcerpt = ({
  course,
  programId,
  disabledStatus,
  isEditor,
  staffList,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classes = useStyles();
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const deleteCourseFromProgram = async (id) => {
  //   dispatch(deleteCourse({ id: programId, courseSpec_delete_id: id }))
  //     .unwrap()
  //     .then((originalPromiseResult) => {
  //       // handle result here
  //       console.log('originalPromiseResult', originalPromiseResult)
  //       dispatch(fetchProgramById(programId))
  //       dispatch(fetchNotAffectedCourses(programId))
  //       openNotificationWithIcon('success', `Deleted`)

  //     })
  //     .catch((rejectedValueOrSerializedError) => {
  //       // handle error here
  //       console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
  //       setHandledError(rejectedValueOrSerializedError)
  //       handleOpenErrorModal()
  //     })
  // }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  return (
    <GridItem md={4} xs={12} sm={6} key={course.id}>
      <Card className={classes.cardHover} style={{ height: "320px" }}>
        <CardBody>
          <GridContainer>
            <GridItem xs={10} sm={10} md={10}>
              <h4 className={classes.cardProductTitle}>
                <small>
                  <Link to={`/citrine/courseSpecDetails/${course.id}`}>
                    {course.code}
                  </Link>
                </small>
              </h4>

              <h4 className={classes.cardProductTitle}>
                <small>
                  <Link to={`/citrine/courseSpecDetails/${course.id}`}>
                    {course.title}
                  </Link>
                </small>
              </h4>

              <h4 className={classes.cardProductTitle}>
                <Link to={`/citrine/courseSpecDetails/${course.id}`}>
                  {course.title}
                </Link>
              </h4>

              <h6 style={{ color: "orange" }}> {course.type}</h6>
            </GridItem>

            <GridItem xs={2} sm={2} md={2}>
              {disabledStatus ? (
                <p> </p>
              ) : (
                <DropDownButtonCourse
                  courseID={course && course.id}
                  data={course}
                  id={course && course.id}
                  staffList={staffList}
                  programId={programId}
                />
              )}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              {`${course.description.substring(0, 300)}...`}
            </GridItem>
          </GridContainer>
          <CardFooter>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              style={{
                textAlign: "end",
                color: course.state === "Draft" ? grayColor[1] : "#FFCB3B",
              }}
            >
              {course.state}
            </GridItem>
          </CardFooter>
        </CardBody>
      </Card>
    </GridItem>
    // <GridItem xs={12} sm={6} md={4}>
    //   <Card style={{ height: 300 }} >
    //     <CardHeader>
    //       <GridContainer justify="space-between"
    //         alignItems="baseline">
    //         <GridItem>
    //           <Link to={`/citrine/courseSpecDetails/${course.id}`}>
    //             <h3>
    //               <small style={{ color: `${primaryColor[0]}` }}>
    //                 {course.title}
    //               </small>
    //             </h3>
    //           </Link>
    //         </GridItem>
    //         <GridItem>
    //           {!disabledStatus  && (
    //             <Tooltip
    //               title="Delete"
    //               placement="bottom"
    //             >
    //               <Button
    //                 variant="contained"
    //                 color="primary"
    //                 justIcon
    //                 round
    //                 onClick={handleClickOpen}
    //               >
    //                 <DeleteIcon />
    //               </Button>
    //             </Tooltip>
    //          ) }
    //         </GridItem>
    //       </GridContainer>
    //     </CardHeader>
    //     <CardBody>
    //       <GridContainer justify="center">
    //         <GridItem>
    //           <h4>
    //             <small>
    //               {`${course.description.substring(0, 100)}...`}
    //             </small>
    //           </h4>
    //         </GridItem>
    //       </GridContainer>
    //       <GridContainer justify="center">
    //         <GridItem>
    //           <Link to={`/citrine/courseSpecDetails/${course.id}`}><Button color='primary'>View</Button></Link>
    //         </GridItem>
    //       </GridContainer>
    //     </CardBody>
    //   </Card>

    //   <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
    //   <SuperGeneralDeleteModal
    //     delete_Element={deleteCourseFromProgram}
    //     Element_ID={course.id}
    //     open={open}
    //     handleClose={handleClose}

    //   />
    // </GridItem>
  );
};

export default function CoursesByProgram() {
  const { t } = useTranslation();
  const history = useHistory();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const [isEditor, setIsEditor] = useState(true);
  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;
  let { programId } = useParams();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [statusProgram, setStatusProgram] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const dispatch = useDispatch();

  const programStatus = useSelector((state) => state.programSpec.programStatus);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (programStatus === "idle") {
      dispatch(fetchProgramById(programId));
    }
    setStatusProgram(localStorage.getItem("state"));
    if (localStorage.getItem("state") != "Draft" || !isEditor) {
      setDisabledStatus(true);
    }
  }, [dispatch, programStatus]);

  useEffect(() => {
    dispatch(fetchStaff());
  }, [dispatch]);

  const staffStatus = useSelector((state) => state.user.staffStatus);
  const staffList = useSelector((state) => state.user.staffList);
  const program = useSelector((state) => state.programSpec.program);
  const courses = useSelector((state) => state.programSpec.courses);
  console.log(program);
  console.log(courses);
  console.log(staffList);
  console.log(staffStatus);

  let content;

  if (programStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (programStatus === "succeeded") {
    content =
      program &&
      program.coursesSpec &&
      program.coursesSpec

        .filter((course) => {
          // no search key
          if (search == "") {
            return course;
          }
          // select state and search key
          else if (course.title.toLowerCase().includes(search.toLowerCase())) {
            return course;
          }
        })

        .map((course) => (
          <CourseExcerpt
            key={course.id}
            course={course}
            programId={programId}
            disabledStatus={disabledStatus}
            isEditor={isEditor}
            staffList={staffList}
          />
        ));
  }
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/programspec`}>{t("Programs")}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/programSpec/${program && program.id}`}>
                {`${program && program.program_degree_name}`}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t(`Courses`)}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

        <GridItem>
          <CustomInput
            formControlProps={{
              className: navBarClasses.top + " " + navBarClasses.search,
            }}
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value);
              },
              value: search,
              placeholder: "Search",
            }}
          />

          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
          >
            <Search
              className={
                navBarClasses.headerLinksSvg + " " + navBarClasses.searchIcon
              }
            />
          </Button>
        </GridItem>
      </GridContainer>
      {!disabledStatus && (
        <Button color="primary" onClick={handleClickOpen}>
          {t("Add")}
        </Button>
      )}
      <GridContainer>{content}</GridContainer>
      {isEditor && (
        <AffectCoursesToProgramModal
          programId={programId}
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}
