import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

import { addNewCourseAssessmentMethod } from "../../reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSpecSlice";

// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";

import { fetchAssessmentQuestionsMethods } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";
import { fetchCloById } from "../../reduxToolkit/features/CLO/CloSpecSlice";

import { useTranslation } from "react-i18next";
import { Form, Input, Select, notification } from "antd";
import ErrorModal from "./ErrorModal";
import { addNewQuestion } from "../../reduxToolkit/features/assessmentMethod/QuestionSlice";

import { fetchCourseById } from "../../reduxToolkit/features/course/CoursesSlice.js";

import AddIcon from "@material-ui/icons/Add";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function EditPersonContactInformation(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const courseId = props.courseId;
  const [form] = Form.useForm();

  const [question, setQuestion] = useState("");
  const [actual_weight, setActual_weight] = useState("");
  const [correction_mark, setCorrection_mark] = useState("");
  const [clo_id, setClo_id] = useState();
  const [assessmentMethod_id, setAssessmentMethod_id] = useState(
    props.assessmentMethod_id
  );
  const [course_id, setCourse_id] = useState(props.course_id);

  const { Option } = Select;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    dispatch(fetchCourseById(props.course_id));
  }, [dispatch]);

  const course = useSelector((state) => state.course.course);

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewQuestion({
        ...values,
        assessmentMethod_id,
        course_id,
      })
    );
    if (addNewQuestion.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchAssessmentQuestionsMethods(props.course_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  return (
    <div>
      {/* <Tooltip id="tooltip-top" title="Edit" placement="top">
        <IconButton aria-label="Edit" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      </Tooltip> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Question")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="question"
                  label="question"
                  rules={[
                    {
                      required: true,
                      message: "Please input the question field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="actual_weight"
                  label="Actual weight"
                  rules={[
                    {
                      required: true,
                      message: "Please input the actual weight field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="correction_mark"
                  label="Correction mark"
                  rules={[
                    {
                      required: true,
                      message: "Please input the correction mark field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="clo_id"
                  label="Clo"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Clo field.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Clo "
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {course &&
                      course.clos &&
                      course.clos.map((clo) => (
                        <Option key={clo.id} value={clo.id}>
                          {clo.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: "20px" }}
                    color="primary"
                    onClick={handleClose}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
