import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
//import DropDownGroupButton from 'views/Components/DropDownGroupButton';
import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";
import { deleteOutGoingMailServer, fetchOutGoingMailServerById, fetchOutGoingMailServers } from "../../../reduxToolkit/features/outgoingMailServer/OutGoingMailServerSlice";



const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);



export default function OutGoingMailServerList() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();

    useEffect(() => {

        dispatch(fetchOutGoingMailServers())

    }, [])

    const outGoingMailServers = useSelector(state => state.OutGoingMailServer.outGoingMailServers)
    const error = useSelector(state => state.OutGoingMailServer.error)

    const columns = [

        {
            field: 'name',
            headerName: 'Server Name',
            width: 250,
        },
        {
            field: 'display_name',
            headerName: 'Display Name',
            width: 250,
        },
        {
            field: 'active',
            headerName: 'Active',
            width: 150,
        },
        {
            field: 'from_filter',
            headerName: 'From filter',
            width: 150,
        },
        {
            field: 'sequence',
            headerName: 'Sequence',
            width: 150,
        },
        {
            field: 'smtp_debug',
            headerName: 'SMTP Debug',
            width: 150,
        },
        {
            field: 'smtp_host',
            headerName: 'SMTP Host',
            width: 150,
        },
        {
            field: 'smtp_port',
            headerName: 'SMTP Port',
            width: 150,
        },
        {
            field: 'smtp_user',
            headerName: 'SMTP User',
            width: 150,
        },
        {
            field: 'smtp_authentication',
            headerName: 'SMTP Authentication',
            width: 150,
        },
        {
            field: 'smtp_encryption',
            headerName: 'SMTP Encryption',
            width: 150,
        },
        {
            field: '',
            width: 50,
            renderCell: (cellValues) => {

                return (
                    <Tooltip
                        justIcon
                        //variant="contained"
                        color="primary"
                        onClick={(event) => {
                            handleDeleteClick(cellValues.row.id);
                            console.log(cellValues, 'cellValues from onclick')
                        }}
                    >
                        <DeleteIcon />
                    </Tooltip>
                );
            }
        }
        // {
        //     field: 'outGoingMailServers',
        //     headerName: 'Groups',
        //     width: 350,
        //     renderCell: (cellValues) => {
        //         console.log('name////////', cellValues.row.all_outGoingMailServers)
        //                 cellValues.row.all_outGoingMailServers.map((g) => {
        //                     return (
        //                         <h5>{g.name}</h5>
        //                        );
        //                 })

        //               }

        // }


    ];
    const handleDeleteClick = (id) => {
        dispatch(deleteOutGoingMailServer(id)).then(() => {
            dispatch(fetchOutGoingMailServers())
        }
        )
    }
    const history = useHistory();
    const [checked, setChecked] = React.useState([]);
    const [selectedItemId, setSelectedItemId] = React.useState('');
    const handleToggle = value => {

        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        console.log('newChecked entry', newChecked)
        if (currentIndex === -1) {
            newChecked.push(value);

        } else {
            newChecked.splice(currentIndex, 1);

        }
        setChecked(newChecked);
        console.log('checked programs', checked)
        setSelectedItemId((newChecked[newChecked.length - 1]).toString())
        console.log('selected item id', selectedItemId)
        //  getItem();
        dispatch(fetchOutGoingMailServerById(selectedItemId)).then(unwrapResult)
            .then((p) => {
                console.log('person in then', p);
                history.push(`/citrine/addEditGroup/${selectedItemId}`);

            });


    };

    return (
        <div>
            <GridContainer style={{
                borderBottom: `1px solid  ${grayColor[15]}`,
                borderTop: `1px solid  ${grayColor[15]}`,
            }}
                justify="space-between"
                alignItems="center">
                <GridItem>
                    <Breadcrumb separator=">">

                        <Breadcrumb.Item >
                            <Link to={`/citrine/configurations`} >
                                {t('Configurations')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t('Mail ')}

                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/outgoingMailServers/`}>{t('Out Going Mail Server List')}
                            </Link>
                        </Breadcrumb.Item>


                    </Breadcrumb>
                </GridItem>

            </GridContainer>

            <Link to={`/citrine/addEditOutGoingMailServer/:id`}>
                <Button color="primary">



                    {t('  Add')}
                </Button>
            </Link>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="rose" icon style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {/* <GridItem xs={12} sm={12} md={1} >
                            <Link to={`/citrine/addEditModel/:id`}>
                                <Button
                                    justIcon
                                    round
                                    color="primary"
                                //  className={classes.marginRight}
                                >
                                    <AddCircleRounded className={classes.icons} />
                                </Button>
                            </Link>
                        </GridItem > */}
                            {/* <GridItem xs={12} sm={12} md={9} >

                            <h4 className={classes.cardIconTitle}>{t('Out Going Mail Server List')}</h4>
                        </GridItem > */}


                        </CardHeader>
                        <CardBody>
                            <GridContainer style={{
                                height: 590,
                                width: 1150
                            }}>
                                <DataGrid
                                    rows={outGoingMailServers && outGoingMailServers}
                                    columns={columns}
                                    //  pageSize={9}
                                    // disableSelectionOnClick
                                    //   rowsPerPageOptions={[]}
                                    //    hideFooterPagination
                                    // editMode="row"
                                    // Options={{singleClickEdit: true}}
                                    //suppressClickEdit
                                    // editRowsModel={editRowsModel}
                                    //  onEditRowsModelChange={handleEditRowsModelChange}
                                    onSelectionModelChange={handleToggle}
                                // components={{
                                //     BooleanCellFalseIcon: CheckBoxOutlineBlankIcon,
                                //     BooleanCellTrueIcon:  CheckBoxIcon,
                                //     singleClickEdit: true
                                //   }}
                                />

                            </GridContainer>



                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        </div>
    );
}
