import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewGAReport,
  fetchGAReports,
} from '../../reduxToolkit/features/GAReport/GAResportSlice'
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { unwrapResult } from '@reduxjs/toolkit'
import { notification } from "antd";
import Button from 'components/CustomButtons/Button.js'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ErrorModal from '../Components/ErrorModal'


export default function GAReportModal(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const [type, setType] = React.useState("NCAAA");
  const handleChange = (event) => {
    console.log(event.target.value);
    setType(event.target.value);
  };


  const handleSend = async () => {
    let GAReport = {
      program_id: props.ProgramID,
      program: props.ProgramID,
    }
    dispatch(addNewGAReport(GAReport))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        openNotificationWithIcon("success", ` Successfully Created`);
        props.handleCloseReportModal()
        dispatch(fetchGAReports())
        history.push(`/citrine/reports/${type}/GA`)
      })
      .catch((rejectedValueOrSerializedError) => {
        props.handleCloseReportModal()
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })

  }


  return (
    <div>

      <Dialog
        open={props.open}
        onClose={props.handleCloseReportModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {' '}
          {t('Create GA Report')}
        </DialogTitle>
        <DialogContent>
        <FormControl component="fieldset">
            <h4>Accreditation body</h4>
            <RadioGroup
              aria-label="Accreditation body"
              name="accreditation_body"
              value={type}
              onChange={handleChange}
            >
              <FormControlLabel
                value="NCAAA"
                control={<Radio size="small" color="primary" />}
                label="NCAAA"
              />
              <FormControlLabel
                value="CTI"
                control={<Radio size="small" color="primary" />}
                label="CTI"
              />
              <FormControlLabel
                value="ABET"
                control={<Radio size="small" color="primary" />}
                label="ABET"
              />
            </RadioGroup>
          </FormControl>
          <DialogActions>
            <Button onClick={props.handleCloseReportModal} color="primary">
              {t(' Cancel')}
            </Button>
            <Button
              onClick={() => {
                props.handleCloseReportModal()
                handleSend()
              }}
              color="primary"
            >
              {t(' Confirm')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
