import React, { useCallback, useEffect, useRef, useState, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
/******Import redux tools*****/

import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
/******Import native component*****/

import { GridExporter } from '@devexpress/dx-react-grid-export'
import saveAs from 'file-saver'
import { SchoolRounded } from '@material-ui/icons'
import { DataGrid, GridCellEditStopReasons } from '@mui/x-data-grid';
import { Breadcrumb } from 'antd'
import 'antd/dist/antd.css'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
/******Import custom component*****/

import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import ErrorModal from 'views/Components/ErrorModal'
import DropDownButtonAssessmentGroup from '../../../Components/DropDownButtonAssessmentGroup'

import ListEvidence from './ListEvidence'
/******Import APIs*****/

import { addNewNoteQuestion } from '../../../../reduxToolkit/features/assessment/AssessmentSlice'
import {
  fetchExamByQuestionAndNotes,
  fetchQuestions_ByAssessmentMethodById,
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import { fetchCourseById } from '../../../../reduxToolkit/features/course/CoursesSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice'

const AssessmentsQuestionsDetails = ({
  rows,
  groupeName,
  groupId,
  examId,
  courseId,
  semesterId,
  course,
  program,
  columns
}) => {

  /************************ States ****************************/
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  /**************************************************************** */
  const dispatch = useDispatch()
  let { id, Assessmentid } = useParams()
  const obj_Assessment = {
    id: Assessmentid,
    course_id: id,
  }

  /*****************************************************************
   * Export Handler functions
   * you will find more details in:
   * https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/export/
   ****************************************************************/

  const exporterRef = useRef(null)

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx',
      )
    })
  }

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  /*******************************************************************/
  /***** handle modal err ***/

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  /*****************************************************************
   * 'event' contains all informations about the cell input
   * Api 'addnewNoteQuestion' to submit the newest note in database
   * catch used to handle errors and shows modal contains err
   *****************************************************************/
  const onCellEditStop = async (params, event) => {
    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
      event.defaultMuiPrevented = true;
    }
    const submitedNoteQuestion = {
      groupAssessment_id: groupId,
      assessmentMethod_id: examId,
      course_id: courseId,
      note: event.target.value,
      student_id: params.id,
      question_name: params.field,
      semester_id: semesterId,
    }
    try {
      const resultAction = await dispatch(
        addNewNoteQuestion(submitedNoteQuestion),
      )
      unwrapResult(resultAction)
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    } finally {
      dispatch(fetchExamByQuestionAndNotes(obj_Assessment))
    }
  }

  /**************************************************************/

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <GridContainer>
          <Card>
            <CardBody>
              <div style={{ width: '100%' }}>
                <GridContainer>
                  <GridItem xs={11} sm={11} md={11}>
                    <div style={{ padding: '15px', color: '#8F6682' }}>
                      <strong>Assessment Group : {groupeName}</strong>
                    </div>
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <DropDownButtonAssessmentGroup
                      courseID={courseId}
                      course={course}
                      program={program}
                      semester_id={semesterId}
                      group_name={groupeName}
                      export={startExport}
                      examID={examId}
                      obj_Assessment={obj_Assessment}
                      groupId={groupId}
                    />
                  </GridItem>
                </GridContainer>
                <DataGrid
                  autoHeight
                  rows={rows?.length ? rows : []}
                  columns={columns}
                  pageSize={50}
                  rowsPerPageOptions={[5, 50]}
                  onCellEditStop={onCellEditStop}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
                <GridExporter
                  ref={exporterRef}
                  rows={rows}
                  columns={columns}
                  onSave={onSave}
                />
              </div>
            </CardBody>
          </Card>
        </GridContainer>
      </div>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default function AssessmentsQuestionsDetailsByGroups() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let { id, Assessmentid, ProgramId, AssessmentName } = useParams()
  const [columns, setColumns] = useState([
    {
      name: 'student_identification',
      title: 'Student ID',
      field: 'student_identification',
      headerName: 'Student ID',
      width: 200,
      flex:1
    },
  ])
  let obj_Assessment = {
    id: Assessmentid,
    course_id: id,
  }

  useEffect(() => {
    dispatch(fetchProgramById(ProgramId))
    dispatch(fetchCourseById(id))
    dispatch(fetchExamByQuestionAndNotes(obj_Assessment))
  }, [])

  /**************************************************************
   * generate columns from API
   *************************************************************/
  useEffect(() => {
    let arr = []
    dispatch(fetchQuestions_ByAssessmentMethodById(obj_Assessment))
      .then(unwrapResult)
      .then((AssessmentMethodQuestions) => {
        if (AssessmentMethodQuestions.length > 0) {
          arr = AssessmentMethodQuestions.map((item) => {
            let obj = {
              /***those 2 fields for export data only **/
              name: `${item.question}`,
              title: `${item.question}  (/${item.correction_mark})`,
              /*************************************** */
              field: `${item.question}`,
              headerName: `${item.question}  (/${item.correction_mark})`,
              editable: true,
              flex:1
            }
            return obj
          })
          setColumns([...columns, ...arr])

        }
      })

  }, [])

  /************************************************************** **/
  const program = useSelector((state) => state.program.program)
  const course = useSelector((state) => state.course.course)
  const ExamByQuestionAndNotesMethodstatus = useSelector(
    (state) => state.AssessmentMethods.ExamByQuestionAndNotesStatus,
  )
  const ExamByQuestionAndNotesMethod = useSelector(
    (state) => state.AssessmentMethods.ExamByQuestionAndNotesMethod,
  )
  const QuestionsByAssessmentMethodStatus = useSelector((state) => state.AssessmentMethods.QuestionsByAssessmentMethodStatus)
  const QuestionsByAssessmentMethod = useSelector((state) => state.AssessmentMethods.QuestionsByAssessmentMethod)

  let content
  if (
    QuestionsByAssessmentMethodStatus === 'loading') {
    content = <p>Loading</p>
  } else if (
    QuestionsByAssessmentMethodStatus === 'succeeded') {
    if (ExamByQuestionAndNotesMethod === 'You do not have permission') {
      content = <h5>{t('You do not have permission!')}</h5>
    }
    else if (ExamByQuestionAndNotesMethod?.length === 0) {
      content = <div>There are no groups</div>
    }
    else if (QuestionsByAssessmentMethod?.length === 0) {
      content = <div>There are no questions</div>
    }
    else if (ExamByQuestionAndNotesMethod?.length && QuestionsByAssessmentMethod?.length) {
      content =
        ExamByQuestionAndNotesMethod?.map((group, key) => (
          <AssessmentsQuestionsDetails
            key={key}
            groupeName={group.groupName}
            rows={group.rows}
            groupId={group.groupId}
            examId={group.exam_id}
            courseId={group.course_id}
            semesterId={course?.semester_id}
            program={program}
            course={course}
            columns={columns}
          />
        ))
    }
  } else if (ExamByQuestionAndNotesMethodstatus === 'failed' || QuestionsByAssessmentMethodStatus === 'failed') {
    content = <div>Something went wrong</div>
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/programs_assessment`}>
              {t('Programs')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/program/${ProgramId}`}>
              {program?.program_degree_name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/assessmentsQuestions_list/program/${ProgramId}/course/${id}`}
            >
              {t('Assessment Methods')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/AssessmentsQuestionsDetailsByGroup/program/${ProgramId}/course/${id}/assessmentMethod/${Assessmentid}`}
              style={{ color: 'orange' }}
            >
              {t('Grades By Questions/Groups')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card justify="space-between">
          <CardHeader
            color="info"
            icon
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <CardIcon color="primary" style={{ height: '65px' }}>
              <SchoolRounded />
            </CardIcon>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <div>
                  <Link to={`/citrine/programs_assessment`}>
                    <h4
                      style={{
                        color: '#8F6682',
                        height: '13px',
                        fontSize: '14px',
                        marginRight: '20px',
                      }}
                    >
                      <strong>
                        {course?.program_detail?.map((el) => el.code)}
                      </strong>
                    </h4>
                  </Link>
                  <h4
                    style={{
                      color: '#6C7B9D',
                      height: '13px',
                      fontSize: '14px',
                      marginRight: '20px',
                    }}
                  >
                    <strong>{course?.code}</strong>
                  </h4>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div>
                  <Link to={`/citrine/programs_assessment`}>
                    <h4
                      style={{
                        color: '#8F6682',
                        fontSize: '14px',
                        height: '13px',
                      }}
                    >
                      <strong>
                        {course?.program_detail?.map(
                          (el) => el.program_degree_name,
                        )}
                      </strong>
                    </h4>
                  </Link>
                  <h4
                    style={{
                      color: '#6C7B9D',
                      fontSize: '14px',
                      height: '13px',
                    }}
                  >
                    <strong>{course?.title}</strong>
                  </h4>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div style={{ display: 'flex' }}>
                  <h4
                    style={{
                      color: 'grey',
                      fontSize: '14px',
                      height: '13px',
                      marginRight: '20px',
                    }}
                  >
                    <strong> {t('Assessment Method')}</strong>
                  </h4>
                  <h4
                    style={{
                      color: 'grey',
                      fontSize: '14px',
                      height: '13px',
                    }}
                  >
                    <strong> {AssessmentName}</strong>
                  </h4>
                </div>
              </GridItem>
            </GridContainer>
          </CardHeader>
        </Card>
      </GridItem>
      {content}
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ float: 'right', fontSize: '25px' }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Evidences List
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListEvidence ressource={'course'} ressourceId={id} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}
