import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import token from "assets/utils/accessToken";
const initialState = {
  CourseOutlines: [],
  CourseOutlinesStatus: "idle",
  CourseOutlineStatus: "idle",
  error: null,
  CourseOutline: null,

  id: null,
};

export const fetchCourseOutlines = createAsyncThunk(
  "CourseOutline/fetchCourseOutlines",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/courseSpecOutLine/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseOutlineById = createAsyncThunk(
  "CourseOutline/fetchCourseOutlineById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/courseSpecOutLine/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewCourseOutline = createAsyncThunk(
  "CourseOutline/addNewCourseOutline",
  async (initialCourseOutline, { rejectWithValue }) => {
    console.log("initial CourseOutline", initialCourseOutline);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/courseSpecOutLine/create/`,
        initialCourseOutline,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const CourseOutlineUpdate = createAsyncThunk(
  "CourseOutline/CourseOutlineUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/courseSpecOutLine/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response);
      console.log(data, "updatedCourseOutline");
      console.log(response.data, "response.data");
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseOutline = createAsyncThunk(
  "CourseOutline/deleteCourseOutline",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/courseSpecOutLine/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const CourseOutlineSlice = createSlice({
  name: "CourseOutline",
  initialState,

  extraReducers: {
    [fetchCourseOutlines.pending]: (state, action) => {
      state.CourseOutlinesStatus = "loading";
    },
    [fetchCourseOutlines.fulfilled]: (state, action) => {
      state.CourseOutlinesStatus = "succeeded";
      // Add any fetched posts to the array
      state.CourseOutlines = action.payload;
    },
    [fetchCourseOutlines.rejected]: (state, action) => {
      state.CourseOutlinesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseOutlineById.pending]: (state, action) => {
      state.CourseOutlineStatus = "loading";
    },
    [fetchCourseOutlineById.fulfilled]: (state, action) => {
      state.CourseOutlineStatus = "succeeded";

      // Add any fetched posts to the array
      state.CourseOutline = action.payload;
    },
    [fetchCourseOutlineById.rejected]: (state, action) => {
      state.CourseOutlineStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewCourseOutline.fulfilled]: (state, action) => {
      state.CourseOutlines.push(action.payload);
    },
    [deleteCourseOutline.fulfilled]: (state, action) => {
      // state.ificationAprovalDatas.push(action.payload)
    },
    [addNewCourseOutline.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseOutline.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [CourseOutlineUpdate.fulfilled]: (state, action) => {
      console.log(action);
    },
    [CourseOutlineUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});

export const { getId } = CourseOutlineSlice.actions;

export default CourseOutlineSlice.reducer;
