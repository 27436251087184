import { unwrapResult } from "@reduxjs/toolkit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CheckBox from "devextreme-react/check-box";
import HtmlEditor, {
  Item,
  MediaResizing,
  Toolbar,
} from "devextreme-react/html-editor";
import "devextreme/ui/html_editor/converters/markdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchCourseReportDocumentById,
  fetchCourseReportDocuments,
  updateCourseReportDocument,
} from "../../../../../reduxToolkit/features/CourseReportDocument/CourseReportDocumentSlice";
import "../style.css";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];
const defaultSelectedItemKeys = ["Html"];

export default function CourseReportDocumentEditor() {
  let { id, type, docId } = useParams();

  const [isMultiline, setIsMultiline] = useState(true);
  const [valueContent, setValueContent] = useState("");
  const [editorValueType, setEditorValueType] = useState("html");

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(fetchCourseReportDocumentById(docId))
      .then(unwrapResult)
      .then((courseReportDocument) => {
        // do additional work
        console.log("originalPromiseResult", courseReportDocument);
        setValueContent(courseReportDocument.htmlTemplates_body);
      });
  }, []);

  const courseReportDocument = useSelector(
    (state) => state.courseReportDocument.courseReportDocument
  );
  console.log(
    "courseReportDocument",
    courseReportDocument && courseReportDocument
  );

  const multilineChanged = (e) => {
    setIsMultiline(e.value);
  };

  const valueChanged = (e) => {
    setValueContent(e.value);
  };

  const valueTypeChanged = (e) => {
    setEditorValueType(e.addedItems[0].text.toLowerCase());
  };

  //   const printDocument = () => {

  //     const doc = new jsPDF();

  //     let parser = new DOMParser();
  //     let parsedHtml = parser.parseFromString(HTMLTemplate && HTMLTemplate.html && HTMLTemplate.html, 'text/html');
  //     console.log("parsedHtml", parsedHtml)
  //     var html = htmlToPdfmake(parsedHtml.body.innerHTML);
  //     console.log("html", html)

  //     const documentDefinition = { content: html };
  //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
  //     pdfMake.createPdf(documentDefinition).open();

  //   }

  const save = async () => {
    try {
      const resultAction = await dispatch(
        updateCourseReportDocument({
          id: docId,
          updatedCourseReportDocument: {
            ressource: courseReportDocument.ressource,
            ressourceId: courseReportDocument.ressourceId,
            htmlTemplates_body: valueContent,
          },
        })
      );

      unwrapResult(resultAction);
      history.push(`/citrine/reports/${type}/course/${id}/documents/${docId}`);
    } catch (err) {
      console.log("Failed to save the template: ", err);
    } finally {
      dispatch(fetchCourseReportDocuments());
    }
  };
  return (
    <div>
      <Card>
        <CardBody>
          <div className="widget-container">
            <HtmlEditor
              height="725px"
              value={valueContent && valueContent}
              valueType={editorValueType}
              onValueChanged={valueChanged}
            >
              <MediaResizing enabled={true} />
              <Toolbar multiline={isMultiline}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item name="size" acceptedValues={sizeValues} />
                <Item name="font" acceptedValues={fontValues} />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item name="header" acceptedValues={headerValues} />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="link" />
                <Item name="image" />
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" />
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" />
                <Item name="deleteTable" />
                <Item name="insertRowAbove" />
                <Item name="insertRowBelow" />
                <Item name="deleteRow" />
                <Item name="insertColumnLeft" />
                <Item name="insertColumnRight" />
                <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>

            <div className="options">
              <div className="caption">Options</div>
              <div className="option">
                <CheckBox
                  text="Multiline toolbar"
                  value={isMultiline}
                  onValueChanged={multilineChanged}
                />
              </div>
            </div>

            {/* <div className="options">
        <ButtonGroup
          onSelectionChanged={valueTypeChanged}
          defaultSelectedItemKeys={defaultSelectedItemKeys}
        >
          <ButtonItem text="Html" />
          <ButtonItem text="Markdown" />
        </ButtonGroup>
        <div className="value-content">
          {valueContent && valueContent}
        </div>
      </div> */}
          </div>
        </CardBody>
      </Card>
      <Button color="primary" onClick={save}>
        Save
      </Button>
    </div>
  );
}
