import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import {
  addNewCourseAssessmentMethod,
  fetchCourseAssessmentMethodById,
  updateCourseAssessmentMethod,
} from "../../reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSlice";
import { Link } from "react-router-dom";
// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";
import { SchoolRounded } from "@material-ui/icons";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";
import { Select } from "antd";
import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";
import {
  fetchPloIndicatorById,
  updatePloIndicator,
} from "reduxToolkit/features/PLOIndicator/PLOIndicatorsSlice";
import { fetchPlos } from "reduxToolkit/features/PLO/PLOsSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker, Space } from "antd";
import {
  fetchRecommendationById,
  updateRecommendation,
  fetchPloRecommendations,
} from "reduxToolkit/features/Recommendations/RecommendationsSlice";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { Input } from "antd";

import { useTranslation } from "react-i18next";
import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { updateActionRecommendation } from "../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import {fetchActionPlanById} from 'reduxToolkit/features/ActionPlan/ActionPlanSlice'
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useStyles4 = makeStyles(styles4);

export default function EditAssginTOActionModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();

  console.log("props.users", props.users);
  console.log("props.item", props.item);

  const classes4 = useStyles4();



  const [assign_to_id, setAssign_to_id] = useState(null);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");



  const { Option } = Select;
  const [open, setOpen] = React.useState(false);

  const history = useHistory();

  const onUpdateClicked = async () => {
    let modified = {
        assign_to_id: assign_to_id,
        description: props.item.description,
        type_action: props.item.type_action,
        state: props.item.state,
        priority: props.item.priority,
        due_date:props.item.due_date,
        start_date:props.item.start_date,
        resource:props.item.resource
      }
    console.log("modified" ,modified);
      try {
    
        dispatch(
          updateActionRecommendation({
            id: props.item.id,
            updatedActionRecommendation: {...modified},
          }),
          dispatch(fetchActionPlanById(props.ActionPlanId))
        ).then(unwrapResult);
      } catch (err) {
        console.log("Failed to map plo indicator and clo: ", err);
      }
    
    
    finally {
        dispatch(fetchActionPlanById(props.ActionPlanId))
      setAddRequestStatus("idle");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
    setAssign_to_id(value);
  };
  return (
    <div>
      <Tooltip
        id="tooltip-top"
        title="Edit"
        placement="top"
        classes={{
          tooltip: classes4.tooltip,
        }}
      >
        <IconButton
          aria-label="Edit"
          className={classes4.tableActionButton}
          onClick={(event) => {
            handleClickOpen(event);
          }}
        >
          <Edit
            className={classes4.tableActionButtonIcon + " " + classes4.edit}
          />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Edit Assgin TO Action ")}
        </DialogTitle>
        <DialogContent>
          <form>
            <GridContainer xs={12} sm={12} md={12} style={{ width: "700px" }}>
              <GridItem xs={12} sm={12} md={3}>
                <label>{t("recommendation*")} </label>
              </GridItem>

              <GridItem xs={12} sm={12} md={9}>
                <Select
                 placeholder="select user"
                  dropdownStyle={{ zIndex: 10000 }}
                  style={{ width: 350 }}
                  onChange={handleChange}
                  name="assign_to_id"
                  value={assign_to_id}
                  
                >
                  {props.users &&
                    props.users.map((user) => (
                      <Option
                        key={user.id}
                        value={user.id}
                        style={{ margin: "5px 0px" }}
                      >
                        {user.username}
                      </Option>
                    ))}
                </Select>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              onUpdateClicked();
              handleClose();
            }}
            color="primary"
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
