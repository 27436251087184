import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { Link } from "react-router-dom";
// import { getCourseId } from "../../reduxToolkit/features/course/CoursesSlice"
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";

import {
  primaryColor,
  infoColor,
  successColor,
  dangerColor,
  warningColor,
  roseColor,
  whiteColor,
  blackColor,
  hexToRgb,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";
import useHandelError from "./HandelError";
const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

export default function SuperGeneralDeleteModal(props) {
  //console.log(props)
  const { t } = useTranslation();
  const dispatch = useDispatch();


  return (

    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    //fullWidth="true"
    >
      <DialogTitle id="form-dialog-title"> Confirm Delete</DialogTitle>
      <DialogContent>
        <h4> {t("Are you sure you want to Delete this element ?")}</h4>
        <DialogActions>
          <Button onClick={props.handleClose} color="danger">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              props.delete_Element(props.Element_ID);
              props.handleClose();
            }}
            color="success"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
