import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  levelPlos: [],
  levelPlosStatus: 'idle',
  levelPloStatus: 'idle',
  error: null,
  levelPlo: null,
  id: null
}

export const fetchLevelPlos = createAsyncThunk('levelPlos/fetchLevelPlos',
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + '/levelPlos/',
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }

      )
      // console.log(response.data,'response.data')

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })

export const fetchLevelPlosById = createAsyncThunk('levelPlos/fetchLevelPloById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/levelPlos/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
export const addNewLevelPlos = createAsyncThunk(
  'levelPlos/addNewLevelPlo',
  async (initialLevelPlo, { rejectWithValue }) => {
    console.log('initial levelPlo', initialLevelPlo)
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/levelPlos/create/`, initialLevelPlo, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const updateLevelPlos = createAsyncThunk(
  'levelPlos/updateLevelPlo',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/levelPlos/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedLevelPlo')
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
export const additionalUpdateLevelPlos = createAsyncThunk(
  'levelPlos/additionalUpdateLevelPlo',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/levelPlos/${data.id}/update/`, data.updatedField, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updated')
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
export const deleteLevelPlos = createAsyncThunk('levelPlos/deleteLevelPlo', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/levelPlos/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})
const levelPlosSlice = createSlice({
  name: ' levelPlos',
  initialState,
  extraReducers: {

    [fetchLevelPlos.pending]: (state, action) => {
      state.levelPlosStatus = 'loading'
    },
    [fetchLevelPlos.fulfilled]: (state, action) => {
      state.levelPlosStatus = 'succeeded'
      // Add any fetched posts to the array
      state.levelPlos = action.payload
    },
    [fetchLevelPlos.rejected]: (state, action) => {
      state.levelPlosStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchLevelPlosById.pending]: (state, action) => {
      state.levelPloStatus = 'loading'
    },
    [fetchLevelPlosById.fulfilled]: (state, action) => {
      state.levelPloStatus = 'succeeded'

      // Add any fetched posts to the array
      state.levelPlo = action.payload
    },
    [fetchLevelPlosById.rejected]: (state, action) => {
      state.levelPloStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewLevelPlos.fulfilled]: (state, action) => {
      state.levelPlos.push(action.payload)
    },
    [deleteLevelPlos.fulfilled]: (state, action) => {
      // state.levelPlos.push(action.payload)
    },
    [updateLevelPlos.fulfilled]: (state, action) => {
      const result = state.levelPlos.filter(levelPlo => levelPlo.id !== action.payload.id);
      // console.log("levelPlo id from filter from slice",levelPlo.id)
      result.push(action.payload)
      state.levelPlos = result
      console.log("action.payload.id from slice", action.payload)

    },
    [additionalUpdateLevelPlos.fulfilled]: (state, action) => {

      console.log("result", action.payload)
    },
    [addNewLevelPlos.rejected]: (state, action) => {
      state.levelPloStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteLevelPlos.rejected]: (state, action) => {
      state.levelPloStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateLevelPlos.rejected]: (state, action) => {
      state.levelPloStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [additionalUpdateLevelPlos.rejected]: (state, action) => {
      state.levelPloStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

  reducers: {
    getId:
      (state, action) => {
        state.id = action.payload
      },


    levelPloUpdated(state, action) {
      const { id, name, code, description, period, start_date, end_date } = action.payload
      const existingLevelPlo = state.levelPlos.find(levelPlo => levelPlo.id === id)
      if (existingLevelPlo) {
        existingLevelPlo.name = name
        existingLevelPlo.code = code
        existingLevelPlo.description = description
        existingLevelPlo.period = period
        existingLevelPlo.start_date = start_date
        existingLevelPlo.end_date = end_date

      }
    }
  }
})

export const { levelPloUpdated, getId } = levelPlosSlice.actions

export default levelPlosSlice.reducer

export const selectAllLevelPlos = state => state.levelPlo.levelPlos

// export const selectLevelPloBySlug = (state, slug) =>
//   state.levelPlo.levelPlos.find(levelPlo => levelPlo.slug === slug)