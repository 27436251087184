import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Form, Input, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "antd/dist/antd.css";
import "assets/css/styles.css";
import { addNewAssessmentMethod } from "../../../../../reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec";
import { fetchAssessmentModes } from "../../../../../reduxToolkit/features/assessmentMode/AssessmentModeSpecSlice";
// @material-ui/core components
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// @material-ui/icons
import AddCircleIcon from "@mui/icons-material/AddCircle";

import ErrorModal from "../../../../Components/ErrorModal.jsx";
const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 12,
  },
};

const AddAMModal = (props) => {
  console.log(props.courseSpec_id);
  console.log(props.assessmentMethodsSpec);
  console.log(props.assessmentMethodSpec_ids);

  const [modeSpec_id, setMode_id] = useState("");
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const { Option } = Select;
  const [form] = Form.useForm();
  function onChangeMode_id(value) {
    console.log(`selected ${value}`);
    setMode_id(value);
    // dispatch(fetchAssessmentModeById(value)).then(unwrapResult).then((item) => {
    //   console.log('item.name.charAt(0).toUpperCase()', item.name.charAt(0).toUpperCase())
    //   let res = item.name.charAt(0).toUpperCase()
    //   console.log('res', res)
    //   setDefaultQuestionCode(res)
    //   form.setFieldsValue({
    //     defaultQuestionCode: res,
    //   });
    // });
  }

  // const onFinish = async (values) => {
  //   let courseSpec_id = props.courseSpec_id;
  //   //const resultAction = await dispatch(addNewAssessmentMethod({ ...values, courseSpec_id }));
  //   const resultAction = await dispatch(addNewAssessmentMethod(values));
  //   if (addNewAssessmentMethod.fulfilled.match(resultAction)) {
  //     openNotificationWithIcon("success", `Added successfully`);
  //     console.log("props.assessment method", props.assessmentMethodSpec_ids);
  //     props.setAssessmentMethodSpec_ids([
  //       resultAction.payload.id,
  //       ...props.assessmentMethodSpec_ids,
  //     ]);
  //     form.resetFields();
  //     dispatch(fetchAssessmentModes());
  //     handleClose();
  //   } else if (resultAction.payload) {
  //     setHandledError(resultAction.payload);
  //     handleOpenErrorModal();
  //   } else {
  //     setHandledError(resultAction.error);
  //     handleOpenErrorModal();
  //   }

  //   // const resultAction = await dispatch(
  //   //     addNewTeachingStrategieSpec({ ...values, courseSpec_id })
  //   // );
  //   // if (addNewTeachingStrategieSpec.fulfilled.match(resultAction)) {
  //   //     openNotificationWithIcon("success", `Added successfully`);
  //   //     console.log('props.teaching_strategy_ids', props.teaching_strategy_ids);
  //   //     props.setTeaching_strategy_ids([resultAction.payload.id, ...props.teaching_strategy_ids])
  //   //     form.resetFields();
  //   //     dispatch(fetchTeachingStrategiesSpec(course && course.id && course.id));
  //   //     handleClose();
  //   //    // history.push(`/citrine/specification/course/${id}/TeachingStrategies`);
  //   // } else {
  //   //     if (resultAction.payload) {
  //   //         setHandledError(resultAction.payload);
  //   //         handleOpenErrorModal();
  //   //     } else {
  //   //         setHandledError(resultAction.error);
  //   //         handleOpenErrorModal();
  //   //     }
  //   // }
  // };

  // useEffect(() => {
  //   dispatch(fetchAssessmentModes());
  // }, []);
  // const modes = useSelector(
  //   (state) => state.assessmentModeSpec.assessmentModes
  // );
  return (
    <div>
      <Link
        to={`/citrine/specification/course/${props.courseSpec_id}/ActivitiesAssessmentMethods/create`}
      >
        <Button
          color="primary"
          onClick={handleClickOpen}
          round
          justicon
          size="sm"
        >
          <AddCircleIcon />
        </Button>
      </Link>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Assessment methods")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="modeSpec_id"
                      label="Assessment Mode"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        dropdownStyle={{ zIndex: 10000 }}
                        placeholder="Select mode"
                        allowClear
                        onChange={onChangeMode_id}
                      >
                        {modes &&
                          modes.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="defaultQuestionCode"
                      label="Default question code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="defaultQuestionNumber"
                      label="Default question number"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </GridItem>
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="primary"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t("Cancel")}
                  </Button>

                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      /> */}
    </div>
  );
};

export default AddAMModal;
