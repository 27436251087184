import React, { useEffect, useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
// @material-ui/icons
import { Link } from 'react-router-dom'
import {
  addNewAssessmentTasksForStudent,
  AssessmentTasksForStudentUpdate,
  fetchAssessmentTasksForStudentById,
  fetchAssessmentTasksForStudents,
} from '../../../../../reduxToolkit/features/AssessmentTasksForStudent/AssessmentTasksForStudentSlice'
// core components
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import { Breadcrumb } from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useHistory } from 'react-router-dom'
import { fetchCourseById } from 'reduxToolkit/Course/coursesSpecSlice'
import { fetchAssessmentMethods, fetchAssessmentMethodsByCourseSpec } from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'

import { Form, Input, InputNumber, notification, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import ErrorModal from '../../../../Components/ErrorModal'
const { TextArea } = Input
const useStyles = makeStyles(styles)
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
}
// const classes = useStyles();
export default function CreateAssessmentTasksForStudent({ match }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()


  const { id } = match.params
  const { idATFS } = match.params
  const isAddMode = isNaN(idATFS)

  // const [assessment_task_id, setAssessment_task] = useState('')
  // const [week_due, setWeek_due] = useState('')
  // const [
  //   percentage_of_total_assessment_score,
  //   setPercentage_of_total_assessment_score,
  // ] = useState('')

  // const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const courseSpec_id = id

  const dispatch = useDispatch()
  const history = useHistory()

  const { Option } = Select

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchAssessmentTasksForStudentById(idATFS))
        .then(unwrapResult)
        .then((ATFS) => {
          console.log(ATFS, 'ATFS')
          form.setFieldsValue({
            assessment_task_id: ATFS.assessment_task_id,
            week_due: ATFS.week_due,
            percentage_of_total_assessment_score:
              ATFS.percentage_of_total_assessment_score,
          })
        })
    }
  }, [idATFS])
  const AssessmentTasksForStudentStatus = useSelector(
    (state) => state.AssessmentTasksForStudent.AssessmentTasksForStudentStatus,
  )
  const res = useSelector(
    (state) => state.AssessmentTasksForStudent.AssessmentTasksForStudent,
  )
  console.log('res ', res)

  useEffect(() => {
    dispatch(fetchCourseById(id))
  }, [dispatch])
  const course = useSelector((state) => state.CourseSpec.courseSpecification)

  useEffect(() => {
    dispatch(fetchAssessmentMethodsByCourseSpec(id))
  }, [])
  const assessmentMethodsByCourseSpec = useSelector((state) => state.assessmentMethod.assessmentMethodsByCourse)

  // const canSave =
  //   [
  //     assessment_task_id,
  //     week_due,
  //     percentage_of_total_assessment_score,
  //     courseSpec_id,
  //   ].every(Boolean) && addRequestStatus === 'idle'

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewAssessmentTasksForStudent({ ...values, courseSpec_id }),
    )
    if (addNewAssessmentTasksForStudent.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `Added successfully`)
      form.resetFields()
      dispatch(
        fetchAssessmentTasksForStudents(course && course.id && course.id),
      )
      history.push(
        `/citrine/specification/course/${id}/AssessmentTasksForStudents`,
      )
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      AssessmentTasksForStudentUpdate({
        id: idATFS,
        modified: {
          ...values,
        },
      }),
    )
    if (AssessmentTasksForStudentUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `edit successfully`)
      form.resetFields()
      dispatch(
        fetchAssessmentTasksForStudents(course && course.id && course.id),
      )
      history.push(
        `/citrine/specification/course/${id}/AssessmentTasksForStudents`,
      )
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <Link to={`/citrine/CourseSpecificationList/`}>
                      {t('Courses List')}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/courseSpecDetails/${id}`}>
                      {course && course.title && course.title}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/specification/course/${id}/AssessmentTasksForStudents`}
                    >
                      {t('Assessment Tasks For Students')}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t('Create')}</Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="assessment_task_id"
                        label="Assessment Task"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Assessment Method"
                          allowClear
                        >
                          {assessmentMethodsByCourseSpec &&
                            assessmentMethodsByCourseSpec.map((r) => (
                              <option key={r.id} value={r.id}>
                                {r.name}
                              </option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="week_due"
                        label="Week due"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item
                        name="percentage_of_total_assessment_score"
                        label="Percentage Of Total Assessment Score"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: '20px' }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${id}/AssessmentTasksForStudents`,
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <Link to={`/citrine/CourseSpecificationList/`}>
                      {t('Courses List')}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/courseSpecDetails/${id}`}>
                      {course && course.title && course.title}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/specification/course/${id}/AssessmentTasksForStudents`}
                    >
                      {t('Assessment Tasks For Students')}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t('Edit')}</Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="assessment_task_id"
                        label="Assessment Task"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Assessment Method"
                          allowClear
                        >
                          {assessmentMethodsByCourseSpec &&
                            assessmentMethodsByCourseSpec.map((r) => (
                              <option key={r.id} value={r.id}>
                                {r.name}
                              </option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="week_due"
                        label="Week due"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item
                        name="percentage_of_total_assessment_score"
                        label="Percentage Of Total Assessment Score"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: '20px' }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${id}/AssessmentTasksForStudents`,
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  )
}
