import "@lourenci/react-kanban/dist/styles.css";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchCloResults } from "../../../../../reduxToolkit/features/CLO/CloSlice";
import EditCommentOnAssessmentResults from "../../../../Components/EditCommentOnAssessmentResults";
import useTableCloResults from "../../../../Components/useTableCloResults";
import "../../improvement.css";
const { TabPane } = Tabs;
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function ClosAssessmentResults(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [closResultsMatrix, setClosResultsMatrix] = useState([]);

  const ClosResults = useSelector((state) => state.clo.CloResults);

  useEffect(() => {
    let obj = {
      course_id: props.idCourse,
    };
    dispatch(fetchCloResults(obj))
      .then(unwrapResult)
      .then((ClosResults) => {
        console.log(ClosResults, "ClosResults");
        setClosResultsMatrix([...ClosResults]);
      });
  }, []);

  const fetchCloResultsMethod = () => {
    let obj = {
      course_id: props.idCourse,
    };
    dispatch(fetchCloResults(obj))
      .then(unwrapResult)
      .then((ClosResults) => {
        console.log(ClosResults, "ClosResults");
        setClosResultsMatrix([...ClosResults]);
      });
  };

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headCells = [
    { id: "Level Code", label: "Level Code" },
    {
      id: "Clos",
      label: "Clos",
    },
    { id: "Assessment Methods", label: "Assessment Methods" },
    { id: "Target", label: "Target" },
    { id: "Score", label: "Score" },
    {
      id: "Comment",
      label: "Comment on Assessment Results",
    },
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTableCloResults(records, headCells, filterFn);

  return (
    <div>
      <TblHead />
      <TblContainer>
        <TableBody style={{ borderLeft: "1px solid lightgray" }}>
          {closResultsMatrix &&
            closResultsMatrix.map((item) => (
              <TableRow key={item.id} style={{ height: 'auto' }}>
                <div
                  style={{
                    backgroundColor: "rgb(175,131,183,0.6)",
                    color: "white",
                  }}
                >
                  {item.code_level && item.code_level[0]} . {item.level}
                </div>
                {item &&
                  item.clos &&
                  item.clos.map((el) => (
                    <TableRow key={el.id} style={{ height: 'auto' }}>
                      <TableCell
                        style={{
                          borderRight: "1px solid lightgray",
                          width: "16.66%",
                        }}
                      >
                        {el.code_level}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid lightgray",
                          width: "250px",
                        }}
                      >
                        <p>{el.title}</p>
                      </TableCell>

                      <TableCell
                        style={{
                          borderRight: "1px solid lightgray",
                          width: "16.66%",
                        }}
                      >
                        {el &&
                          el.exams_names &&
                          el.exams_names.map((element) => (
                            <ul>
                              <li> {element}</li>
                            </ul>
                          ))}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid lightgray",
                          width: "16.66%",
                        }}
                      >
                        {el.target}
                      </TableCell>

                      <TableCell
                        style={{
                          borderRight: "1px solid lightgray",
                          width: "16.66%",
                        }}
                      >
                        {el.score}
                      </TableCell>

                      <TableCell
                        style={{
                          borderRight: "1px solid lightgray",
                          width: "16.66%",
                        }}
                      >
                        {/* <div style={{ height: "200px" }}>
                          <p style={{ textAlign: "end" }}> */}

                        {/* </p>
                          <p style={{ height: "80%" }}> */}
                        {el.comment_on_assessment_results}
                        {/* </p>
                        </div> */}
                        <div style={{ textAlign: "end" }}>
                          <EditCommentOnAssessmentResults
                            clo_id={el.id}
                            idCourse={props.idCourse}
                            fetchCloResultsMethod={fetchCloResultsMethod}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableRow>
            ))}
        </TableBody>
        <br></br>
      </TblContainer>
    </div>
  );
}
