import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Input, InputNumber, notification, Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { addNewPLOReportDocument, fetchPLOReportDocumentsByProgramId } from "../../reduxToolkit/features/PLOReportDocument/PLOReportDocumentSlice";
import ErrorModal from "./ErrorModal.jsx";

import {
  addNewKPIReportDocument,
  fetchKPIReportDocumentsByProgramId
} from "../../reduxToolkit/features/KpiReportDocument/KpiReportDocument"




const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16,
  },
};

export default function CreateReportKpiImprovementDocument(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const groupStatus = useSelector((state) => state.group.groupStatus);
  const ProcessInstance = useSelector((state) => state.camunda.ProcessInstance);
  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  console.log('props createeeeeeee ', props)
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };


  const [open, setOpen] = React.useState(false);

  const { Option } = Select;

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  console.log('resource_id', props.resourceId)

  const onFinish = async (values) => {


    const resultAction = await dispatch(
      addNewKPIReportDocument({
        ...values,
        resource: props.type == "AllKpiReport" ?  "AllKpiReport" : "SAQRReport" ,
        resource_id:props.resourceId,
        resource_type: "s"
      }) 
    );

    if (
      addNewKPIReportDocument.fulfilled.match(resultAction)
    ) {
      openNotificationWithIcon("success", `Added successfully`);

      // dispatch(
      //   fetchPLOReportDocumentsByProgramId(
      //     props.programId
      //   )
      // );

      dispatch(
        fetchKPIReportDocumentsByProgramId(
        
          props.resourceId
        
        )
      );

      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <MenuItem
        color="primary"
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        {t("Kpis Report")}
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          Create Kpis Improvement Document Report
        </DialogTitle>
        <DialogContent>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="version"
              label="version"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: "20px" }}
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="primary" color="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
