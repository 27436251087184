// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Breadcrumb } from 'antd'
// core components
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { fetchUniversityById } from '../../../../reduxToolkit/features/university/UniversitiesSlice'

const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
const useStyles = makeStyles(styles)

export default function UniversityDetails({ match }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const classes = useStyles()

  const { id } = match.params

  useEffect(() => {
    dispatch(fetchUniversityById(id))
  }, [id, dispatch])

  const university = useSelector((state) => state.university.university)

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/organizations`}>
                <span> {t('Organizations')} </span>
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/organizations/universities`}>
                <span> {t('Universities')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{university && university.name} </span>
            </Breadcrumb.Item>
          </Breadcrumb>
          {/* <span>
            <Link to={`/citrine/organizations`}>{t('Organizations')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/universities`}> {t('/Universities')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/universities/${university && university.id}`}> {`/${university && university.name}`} </Link>
          </span> */}
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer justify="space-between" alignItems="center">
                <GridItem xs={8} sm={8} md={8}>
                  <h4 className={classes.cardIconTitle}>
                    {university && university.name}
                  </h4>
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Tooltip title="Edit">
                    <Link
                      to={`/citrine/organizations/universities/create/${
                        university && university.id
                      }`}
                    >
                      <Button aria-label="edit" color="primary" justIcon round>
                        <EditIcon />
                      </Button>
                    </Link>
                  </Tooltip>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <Link
                        to={`/citrine/organizations/universities/${id}/colleges`}
                      >
                        <Button color="primary">Colleges</Button>
                      </Link>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Link
                        to={`/citrine/organizations/universities/${id}/KPIs`}
                      >
                        <Button color="primary">KPIs</Button>
                      </Link>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t('Phone number')} </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.phone_number}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t(' Email address')} </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.email}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t('Address')}</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.address}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t('City')}</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.city}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t('Country')}</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.country}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t(' Director')} </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.director}</div>{' '}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justify: 'space-between',
                }}
              >
                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div>{t('Mission')}</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.mission}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t('Vision')}</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.vision}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t('Goals')} </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.goals}</div>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  {' '}
                  <div> {t('Values')}</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {' '}
                  <div> {university && university.values}</div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
