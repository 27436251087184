import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const RelationshipOfStudentOutcomesToProgramEducationalObjectives = ({
  data,
}) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  // const columns = [
  //   {
  //     title: "Title",
  //     dataIndex: "title",
  //     key: "title",
  //   },
  // ];

  const columns = data?.PEOs?.map((peo) => ({
    title: peo,
    dataIndex: peo,
    key: peo,
    render: (content) => <>{content ? <CloseIcon /> : null}</>,
  }));

  return (
    <>
      <h3>
        STUDENT OUTCOMES: Relationship of Student Outcomes to Program
        Educational Objectives
      </h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of how the student outcomes prepare
        graduates to attain the program educational objectives:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The manner in which the student outcomes support the program educational
        objectives is shown in the table below. In this table, each outcome is
        associated with those program educational objectives it supports:
      </p>

      <AntDDataGrid
        columns={[
          {
            title: "Student Outcome",
            dataIndex: "PLO",
            key: "PLO",
          },
          ...columns,
        ]}
        data={data?.PLOs_PEOs_mapping}
      />

      <br />

      <p className={styles.reportParagraphTitle}>
        Each of the aforementioned student outcomes has been defined by a few
        high-level indicators so it can be communicated to students, integrated
        into the curriculum, and measured in a consistent manner. The table
        below shows performance indicators for each outcome for the Engineering
        program:
      </p>

      <AntDDataGrid
        columns={[
          {
            title: "Student Outcome",
            dataIndex: "PLO",
            key: "PLO",
          },
          {
            title: "Performance Indicators",
            dataIndex: "indicators",
            key: "indicators",
            render: (indicators) => (
              <ul>
                {indicators.map((indicator) => {
                  return (
                    <li key={indicator}>
                      {indicator}
                    </li>
                  );
                })}
              </ul>
            ),
          },
        ]}
        data={data?.PLOs_Indicators_mapping}
      />
    </>
  );
};

export default RelationshipOfStudentOutcomesToProgramEducationalObjectives;
