import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Button from "../../components/CustomButtons/Button";
// nodejs library to set properties for components

// @material-ui/core components
import CourseInstructorModal from "./CourseInstructorModal";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";

import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownButton(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // console.log(props.course,"props.course")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onchangeAssessmentByExamNavigation = () => {
    // if (props.course && props.course.assessment_calculation_method === "clo") {
    //   history.push(
    //     `/citrine/assessments_list/program/${props.program.id}/course/${props.courseID}`
    //   );
    // }
    // else if (props.course && props.course.assessment_calculation_method === "question") {
    history.push(
      `/citrine/assessmentsQuestions_list/program/${props.program.id}/course/${props.courseID}`
    );
    // }

  };

  const onchangeAssessmentByCloNavigation = () => {
    // if (props.course && props.course.assessment_calculation_method === "clo") {
    //   history.push(
    //     `/citrine/clos_list/program/${props.program.id}/course/${props.courseID}`
    //   );
    // }
    // else if (props.course && props.course.assessment_calculation_method === "question") {
    history.push(
      `/citrine/closQuestions_list/program/${props.program.id}/course/${props.courseID}`
    );
    // }

  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="info"
        onClick={handleClick}
        justIcon
        round
        simple
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <GroupModal
          // handleMenuItemClick={handleMenuItemClick}
          id={props.CourseAssessmentMethodID}
          AssessmentMethodID={props.AssessmentMethodID}
          courseID={props.courseID}
          handleClose={handleClose}
          course={props.course}
          program={props.program}
          semester_id={props.semester_id}
        /> */}
        <CourseInstructorModal
          course={props.course}
          id={props.courseID}
          program={props.program}
          handleDropDownButtonClose={handleClose}
          staffList={props.staffList}
          fetchProgAss={props.fetchProgAss}

        />
        <MenuItem
          onClick={() => onchangeAssessmentByExamNavigation()}
          style={{ color: "#AC79B0" }}
        >
          {t("Assessment By Exam")}
        </MenuItem>

        <MenuItem
          onClick={() =>
            onchangeAssessmentByCloNavigation()
          }
          style={{ color: "#AC79B0" }}
        >
          {t("CLOs Scores")}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
