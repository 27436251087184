import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import Search from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ErrorModal from "views/Components/ErrorModal";
import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";
import { fetchAllActionsRecommendationsByUser } from "../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import DropDownActionButton from "../../Components/DropDownActionButton.js";

const currentDate = new Date(2021, 4, 11);
const views = ["agenda"];

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const ActionExcerpt = ({ action, fetchAllActions }) => {

  const { t } = useTranslation();

  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch();

  const RaitingRender = (value) => {
    if (value === 1) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
        </div>
      );
    } else if (value === 2) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
        </div>
      );
    } else if (value === 3) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
        </div>
      );
    }
  };

  const StateRender = (value) => {
    if (value === 1) {
      return (
        <div style={{ display: "flex" }}>
          <h6>Created</h6>
        </div>
      );
    } else if (value === 2) {
      return (
        <div style={{ display: "flex" }}>
          <h6>Reassigned </h6>
        </div>
      );
    } else if (value === 3) {
      return (
        <div style={{ display: "flex" }}>
          <h6>Done</h6>
        </div>
      );
    } else if (value === 4) {
      return (
        <div style={{ display: "flex" }}>
          <h6>Suspended</h6>
        </div>
      );
    } else if (value === 5) {
      return (
        <div style={{ display: "flex" }}>
          <h6>Canceled</h6>
        </div>
      );
    }
  };

  return (
    <GridContainer
      md={4}
      xs={12}
      sm={12}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <GridItem key={action.id}>
        <Card product className={classes.cardHover}>
          <CardBody style={{ width: 375 }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={11}>
                    <strong>
                      {action.type_action && action.type_action.name} :
                    </strong>
                    {action.text && action.text}
                    <br></br>
                    <strong> Assigned to : </strong>
                    {action?.assign_to}
                    <br></br>
                    <strong> Start date : </strong>
                    {action.start_date && action.start_date}
                    <br></br>
                    <strong> Due date : </strong>
                    {action.endDate && action.endDate}
                    <br></br>
                    <strong>
                      {" "}
                      Priority : {RaitingRender(action?.priorityId)}
                    </strong>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <strong> {StateRender(action && action.stateId)}</strong>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <DropDownActionButton
                      id={action?.id}
                      fetchAllActions={fetchAllActions}
                      assign_to_id={action?.assign_to_id}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default function ActionsByUser() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const actionsStatus = useSelector(
    (state) => state.ActionRecommendation.ActionsStatus
  );
  const actions = useSelector(
      (state) => state.ActionRecommendation.ActionsByUser
  );
  const error = useSelector((state) => state.ActionRecommendation.error);

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;
  const [search, setSearch] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const [expanded, setExpanded] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchAllActions();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllActions = async () => {
    try {
      const resultAction = await dispatch(
        fetchAllActionsRecommendationsByUser()
      ).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  let content;

  if (actionsStatus === "loading") {
    content = <div className="loader">Loading...</div>;
  } else if (actionsStatus === "succeeded") {
    if (actions && !actions.length) {
      content = <div> No items found ! </div>;
    } else {
      content = actions?.filter((action) => {
            // no search key  & no  selected states
            if (action && action.type_action && action.type_action.name.toLowerCase().includes(search.toLowerCase())) {
              return action;
            }
            // select state and search key
          })
          .map((action) => (
            <ActionExcerpt
              key={action?.id}
              action={action}
              fetchAllActions={fetchAllActions}
            />
          ));
    }
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                to={`/citrine/configuration/course/actions`}
                style={{ color: "orange" }}
              >
                {t("Actions List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer justify="space-between" alignItems="center">
        <GridItem></GridItem>
        <GridItem>
          <CustomInput
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value);
              },
              value: search,
              placeholder: "Search",
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
          >
            <Search
              className={
                navBarClasses.headerLinksSvg + " " + navBarClasses.searchIcon
              }
            />
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
