import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, notification, Select } from "antd";
import { MediaBaseUrl } from "assets/utils/accessToken.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    addCoordinatorAndTheory
} from "../../../../reduxToolkit/features/course/CoursesSlice.js";
import { fetchStaff } from '../../../../reduxToolkit/features/user/UserSlice';
import ErrorModal from "../../../Components/ErrorModal.jsx";

export default function AddCoordinator(props) {

    const persons = useSelector(state => state.user.staffList)

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");
    const [coordinator_id, setCoordinator_id] = useState(null);
    const [theoryInstructor_id, setTheory_id] = useState(null);
    const [courses_ids, setCourses_ids] = useState([]);
    const [open, setOpen] = useState(false);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    useEffect(() => {
        dispatch(fetchStaff())
    }, [])

    const { Option } = Select;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const onFinishFailed = (errorInfo) => { };

    const handleKeyDown = (event, ref) => {
        if (event.key === 'Tab') {
            ref.current.focus();
        }
    };

    const onFinish = async () => {
        let courses_ids = props.selectedRows.join();
        const resultAction = await dispatch(
            addCoordinatorAndTheory({ coordinator_id, theoryInstructor_id, courses_ids })
        );
        if (addCoordinatorAndTheory.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            props.refreshCourses();
            openNotificationWithIcon("success", `added`);
            handleClose();
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        }
    };
    function handleChangeCoordinator(value) {
        setCoordinator_id(value)
    }

    function handleChangeTheory(value) {
        setTheory_id(value)
    }

    return (
        <div>
            <MenuItem
                disabled={props.disabled}
                onClick={() => {
                    handleClickOpen();
                    props.handleDropDownButtonClose();
                }}
            >
                {t("Update Coordinator")}
            </MenuItem>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle id="form-dialog-title">{t("Add Coordinator")}</DialogTitle>
                <DialogContent>
                    <GridContainer justifyContent="center">
                        <GridItem xs={10} sm={10} md={12}>
                            <Form
                                name="basic"
                                layout="vertical"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                scrollToFirstError
                            >
                                <GridContainer justifyContent="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Coordinator")}
                                            name="coordinator_id"
                                            rules={[{ required: false, message: "Please select a coordinator!" }]}
                                            initialValue={props.coordinator}
                                        >
                                            <Select
                                                showSearch={true}
                                                dropdownStyle={{ zIndex: 10000 }}
                                                placeholder={t('Select a Coordinator')}
                                                ref={input1Ref}
                                                onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                                                optionFilterProp="children"
                                                onChange={handleChangeCoordinator}
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toString()
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {persons && persons.map((person, key) =>
                                                    <Option value={person.id} key={key}>
                                                        {!person?.person_details[0]?.avatar ? (<span style={{
                                                            backgroundColor: 'rgb(143, 102, 130)',
                                                            width: '23px',
                                                            height: '23px',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            paddingLeft: '7px',
                                                            marginRight: '15px',
                                                            display: 'inline-block',
                                                            lineHeight: '23px'
                                                        }}>{person?.person_details[0]?.first_name.charAt(0).toUpperCase() + '     '}</span>) : (<img style={{ width: '20px', height: "20px" }} src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`} />)}
                                                        {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                                    </Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="center">
                                    <GridItem>
                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            handleClose();
                                                        }}
                                                    >
                                                        {t("Cancel")}
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t("Save")}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                            </Form>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    );
}
