import React, { useState, useEffect } from 'react'
// @material-ui/core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import { Form, Input, Select, notification, DatePicker } from 'antd'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAssessmentMethodsNotGenerated } from '../../../../reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import { createAssessmentMethod, fetchAssessmentQuestionsMethods } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import ErrorModal from '../../../../../src/views/Components/ErrorModal.jsx'
import {useParams} from "react-router-dom"

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 18,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const Option = Select.Option

const AddAssessmentMethodModal = ({
  props,
  openAddAssessmentMethod,
  handleCloseAddAssessmentMethod,
}) => {
  const {id} = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [open, setOpen] = useState(false);

  const assessmentsSpec = useSelector((state) => state.assessmentMethod.assessmentMethodsNotGenerated,)

  useEffect(() => {
     dispatch(fetchAssessmentMethodsNotGenerated(id))
  },[])

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinish = async (values) => {
    try {
      const resultAction = await dispatch(createAssessmentMethod({...values, name:id}))
      unwrapResult(resultAction)
      form.resetFields()
      handleClose()
      dispatch(fetchAssessmentQuestionsMethods(id))
      dispatch(fetchAssessmentMethodsNotGenerated(id))
      openNotificationWithIcon('success', `Addedd`)
    } catch (err) {
      setHandledError(err)
      form.resetFields()
      handleOpenErrorModal()
    }
  }

  const onFinishFailed = (errorInfo) => {}

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen} >
      {t("Add")}
    </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Add Assessment Method')}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="code"
                  label="Assessment Method"
                  rules={[
                    {
                      required: true,
                      message: 'Please input method spec!!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select assessment method "
                    dropdownStyle={{ zIndex: 10000 }}
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {assessmentsSpec &&
                      assessmentsSpec.map((assessment) => (
                        <Option key={assessment.id} value={assessment.id}>
                          {assessment.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmltype="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default AddAssessmentMethodModal
