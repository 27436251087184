import { Tabs } from 'antd';
import Card from "components/Card/Card"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import { greyColor } from 'assets/jss/material-dashboard-pro-react';
import { Link } from "react-router-dom"
import React from 'react';
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import MissionStatement from './MissionStatement';
import ProgramEducationalObjectives from './ProgramEducationalObjectives';
import ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution from './ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution';
import ProgramConstituencies from './ProgramConstituencies';
import ProcessForReviewOfTheProgramEducationalObjectives from './ProcessForReviewOfTheProgramEducationalObjectives';
import Criterion1Analysis from '../criterion1/Analysis';

const onChange = (key) => {
    console.log(key);
};

const Criterion2 = ({data}) => (
    <>
        {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

        <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            items={[
                {
                    label: `Mission Statement`,
                    key: '1',
                    children: <MissionStatement data={data.MissionStatement}/>
                    ,
                },
                {
                    label: `Program Educational Objectives`,
                    key: '2',
                    children: <ProgramEducationalObjectives data={data.ProgramEducationalObjectives}/>,
                },
                {
                    label: `Consistency of the Program Educational Objectives with the Mission of the Institution`,
                    key: '3',
                    children: <ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution data={data.ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution}/>,
                },
                {
                    label: `Program Constituencies`,
                    key: '4',
                    children: <ProgramConstituencies data={data.ProgramConstituencies}/>,
                },
                {
                    label: `Process for Review of the Program Educational Objectives`,
                    key: '5',
                    children: <ProcessForReviewOfTheProgramEducationalObjectives data={data.ProcessForReviewOfTheProgramEducationalObjectives}/>,
                },
                {
                    label: `Analysis`,
                    key: '6',
                    children: <Criterion1Analysis data={data.Analysis}/>,
                },
            ]}
        />
    </>

);
export default Criterion2;