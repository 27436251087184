import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Button from "../../components/CustomButtons/Button";
// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { notification } from "antd";
import { deleteAssessmentMethodQuestions, fetchAssessmentQuestionsMethods } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import AddQuestionAssessmentModal from "views/Components/AddQuestionAssessmentModal";
import EditQuestionAssessmentModal from "views/Components/EditQuestionAssessmentModal";
import EditAssessmentMethodInstructors from "../Pages/Assessments/courseAssessments/AssessmentMethodInstructors.js";
import ErrorModal from "./ErrorModal";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal";
const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownQuestionAssessmentCoordinator(props) {
  const { t } = useTranslation();
  const buttonClasses = useButtonStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleDeleteItem = async (id) => {

    dispatch(
      deleteAssessmentMethodQuestions({
        questions_delete_ids: props.selectedRows
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Deleted`);


        dispatch(fetchAssessmentQuestionsMethods(props.course_id));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here

        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);


  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <AddQuestionAssessmentModal
          course_id={props.course_id}
          assessmentMethod_id={props.assessmentMethod_id}
          handleCloseAnchor={handleClose}
        />
        <EditQuestionAssessmentModal
          course_id={props.course_id}
          assessmentMethod_id={props.assessmentMethod_id}
          disabled={props.disabled}
          selectedRowData={props.selectedRowData}
          handleCloseAnchor={handleClose}

        />
        <Link to={{
          pathname: `/citrine/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/${props.course_id}/assessmentMethod/${props.assessmentMethod_id}/${props.assessmentQuestionsMethod_name}/Insturctors/`
          }
        }>

          <MenuItem >
            {t("Edit Instructors")}


          </MenuItem>

        </Link>
        <Link to={{
          pathname: `/citrine/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/${props.course_id}/assessmentMethod/${props.assessmentMethod_id}/${props.assessmentQuestionsMethod_name}/Groups/`
          }
        }>

          <MenuItem >
            {t("Edit Groups")}


          </MenuItem>

        </Link>
        {/* <EditAssessmentMethodInstructors
          course_id={props.course_id}
          assessmentMethod_id={props.assessmentMethod_id}
          assessmentQuestionsMethod_name={props.assessmentQuestionsMethod_name}
          selectedRowData={props.selectedRowData}
          handleCloseAnchor={handleClose}

        /> */}
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal();
            handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
}
