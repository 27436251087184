// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { grayColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import { fetchKPIConfigById } from '../../../../../reduxToolkit/features/KPI/KPIConfigSlice';



const useStyles = makeStyles(styles);

export default function KPIConfigDetails({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const classes = useStyles();

  const { id } = match.params

  useEffect(() => {
    dispatch(fetchKPIConfigById(id))
  }, [id, dispatch])

  const KPI = useSelector((state) => state.KPIConfig.KPI)

  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <span>
            <Link to={`/citrine/configuration/KPI`}>{t('KPIs')}</Link>
          </span>
          <span>
            <Link to={`/citrine/configuration/KPI/${KPI && KPI.id}`}> {`/${KPI && KPI.code}`}</Link>
          </span>
        </GridItem>
      </GridContainer>

      <GridContainer xs={10} sm={10} md={10}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer justify='space-between' alignItems='center'>
                <GridItem xs={11} sm={11} md={11}>
                  <h4>
                    {KPI && KPI.code}
                  </h4>
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Tooltip
                    title="Edit"
                  >
                    <Link to={`/citrine/configuration/KPI/${KPI && KPI.id}/update`}>
                      <Button
                        aria-label="edit"
                        color="primary"
                        justIcon
                        round
                      >
                        <EditIcon />
                      </Button>
                    </Link>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}> <div> {t('Name')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {KPI && KPI.name}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('Type')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {KPI && KPI.type}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t(' Categories')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {KPI && KPI.kpiCategoryConfig_detail.map(category => <span>{`${category},`}</span>)}</div></GridItem>
                <GridItem xs={12} sm={12} md={2}> <div> {t('Description')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {KPI && KPI.description}</div></GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );

}




