import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { updateProgram, fetchPrograms ,Special_updateProgram} from '../../reduxToolkit/features/program/ProgramSpecSlice'
import MoreVert from "@material-ui/icons/MoreVert";

import { useTranslation } from 'react-i18next';
import UnarchiveProgramModal from './UnarchiveProgramModal'
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));



export default function ArchivedProgramCardDropdownButton(props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')
  const dispatch = useDispatch()

  const program_degree_name=props.program.program_degree_name
  const accreditation_dates=props.program.accreditation_dates
  const accredited_campus_locations=props.program.accredited_campus_locations
  const criteria=props.program.criteria
  const date_of_next_comprehensive_review=props.program.date_of_next_comprehensive_review
  const accredited_by=props.program.accredited_by
  const mission=props.program.mission
  const goals=props.program.goals
  const university_id=props.program.university_id
  const college_ids=props.program.college_ids
  const department_ids=props.program.department_ids
  const fieldSpec_id=props.program.fieldSpec_id
  const specialization_id=props.program.specialization_id
  const code=props.program.code
  const total_levels=props.program.total_levels
  const category_of_the_program=props.program.category_of_the_program
  const admissionRequirementSpec_ids= props.program.admissionRequirementSpec_ids
  const graduationRequirementSpec_ids=props.program.graduationRequirementSpec_ids
  const keyWordsSpec_ids=props.program.keyWordsSpec_ids
  const coordinator_id=props.program.coordinator_id
  const interims_ids=props.program.interims_ids

  const active=true 

  const handleUnarchive = async () => {
    try {
        setUpdateRequestStatus('pending')
        const resultAction = await dispatch(
          Special_updateProgram({ id: props.program.id, updatedProgram: { state:"Draft",active:true} }),
      )
       dispatch(fetchPrograms())
    } catch (err) {
    
        console.log('Failed to update the program: ',err)
    } finally {
        setUpdateRequestStatus('idle')
        dispatch(fetchPrograms())
    }
   

}
  return (
    <div>


      <Button
        // aria-describedby={id}         
        aria-controls="customized-menu"
        variant="contained" 
        color="primary" 
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"

      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem>
          <ListItemText primary="Unarchive" onClick={(event) => {handleClose(event);handleUnarchive()}} />
        </MenuItem> */}
        <UnarchiveProgramModal 
             handleUnarchive={handleUnarchive}
             handleClose={handleClose}
             Element_ID={props.id}
             />   
      </StyledMenu>
    </div>
  );
}
