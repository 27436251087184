import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";

const ABETReports = () => {
  const reportTypes = [
    {
      type: "ANNUAL PROGRAM",
      title: "Annual Program Reports",
      route: "/citrine/reports/ABET/annualProgram/",
    },
    {
      type: "COURSE",
      title: "Course Reports",
      route: "/citrine/reports/ABET/course/",
    },
    {
      type: "PLO",
      title: "Program Learning Outcomes",
      route: "/citrine/reports/ABET/PLO/",
    },
    {
      type: "PEO",
      title: "Program Educational Objectives",
      route: "/citrine/reports/ABET/PEO/",
    },
    {
      type: "SSR",
      title: "SELF-STUDY REPORT",
      route: "/citrine/reports/ABET/SSR/",
    },
    // {
    //   type: "GA",
    //   title: "Goal Attainment",
    //   route: "/citrine/reports/ABET/GA/",
    // },
  ];

  return (
    <GridContainer>
      {reportTypes.map((reportType) => (
        <GridItem xs={12} sm={6} md={4}>
          <Card key={reportType.type} style={{ marginBottom: 10, height: 150 }}>
            <CardBody>
              <Link to={reportType.route}>
                <Typography variant="h5" component="h2">
                  {reportType.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Click here to view {reportType.type} reports.
                </Typography>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default ABETReports;
