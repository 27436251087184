import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";
// @material-ui/icons
import {
  addNewCourseQualityEvaluation,
  CourseQualityEvaluationUpdate,
  fetchCourseQualityEvaluationById,
  fetchCourseQualityEvaluations
} from "../../../../../reduxToolkit/features/CourseQualityEvaluation/CourseQualityEvaluationSpecSlice";
import { fetchUsers } from "../../../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../../../Components/ErrorModal";






const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

export default function CreateCourseQualityEvaluation({ match }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { idCQE } = match.params;
  const [form] = Form.useForm();
  console.log("idCQE", idCQE);
  const isAddMode = isNaN(idCQE);
  console.log("id from match is add mode", isAddMode);
  useEffect(() => {
    dispatch(fetchCourseById(id));
  }, []);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);

  const { id } = match.params;
  const [evaluation_areas, setEvaluation_areas] = useState("");
  const [evaluation_method, setEvaluation_method] = useState("");
  const [evaluators, setEvaluators] = useState("");
  const [courseSpec_id, setCourseSpec_id] = useState(id);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const { Option } = Select;
  const canSave =
    [evaluation_areas, evaluation_method, evaluators, courseSpec_id].every(
      Boolean
    ) && addRequestStatus === "idle";
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const users = useSelector((state) => state.user.users);

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewCourseQualityEvaluation({ ...values, courseSpec_id })
    );
    if (addNewCourseQualityEvaluation.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchCourseQualityEvaluations(course && course.id && course.id));
      history.push(
        `/citrine/specification/course/${id}/CourseQualityEvaluations`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      CourseQualityEvaluationUpdate({
        id: idCQE,
        modified: {
          ...values,
        },
      })
    );
    if (CourseQualityEvaluationUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchCourseQualityEvaluations(course && course.id && course.id));
      history.push(
        `/citrine/specification/course/${id}/CourseQualityEvaluations`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchCourseQualityEvaluationById(idCQE))
        .then(unwrapResult)
        .then((CQE) => {
          console.log(CQE, "CQE");
          form.setFieldsValue({
            evaluation_areas: CQE.evaluation_areas,
            evaluation_method: CQE.evaluation_method,
            evaluators: CQE.evaluators,
            courseSpec_id: CQE.courseSpec_id,
          });
        });
    }
  }, [idCQE]);

  const res = useSelector(
    (state) => state.CourseQualityEvaluationSpec.CourseQualityEvaluation
  );
  const CourseQualityEvaluationsStatus = useSelector(
    (state) => state.CourseQualityEvaluationSpec.CourseQualityEvaluationsStatus
  );
  console.log("res ", res);

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">


                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/CourseQualityEvaluations`}
                        style={{ color: "orange" }}
                      >
                        {t("Course Quality Evaluation List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="evaluation_areas"
                        label="Evaluation areas"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key="Effectiveness of teaching" value="Effectiveness of teaching">
                            Effectiveness of teaching
                          </Option>
                          <Option key="Effectiveness of students assessment" value="Effectiveness of students assessment">
                            Effectiveness of students assessment
                          </Option>
                          <Option key="Quality of learning resources" value="Quality of learning resources">
                            Quality of learning resources
                          </Option>
                          <Option key="The extent to which CLOs have been achieved" value="The extent to which CLOs have been achieved">
                            The extent to which CLOs have been achieved
                          </Option>
                          <Option key="Others" value="Other">
                            Others
                          </Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="evaluation_method"
                        label="Evaluation method"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key="Direct" value="Direct">
                            Direct
                          </Option>
                          <Option key="Indirect" value="Indirect">
                            Indirect
                          </Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="evaluators"
                        label="Evaluators"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key="Students" value="Students">
                            Students
                          </Option>
                          <Option key="Faculty" value="Faculty">
                            Faculty
                          </Option>
                          <Option key="Program Leaders" value="Program Leaders">
                            Program Leaders
                          </Option>
                          <Option key="Peer Reviewer" value="Peer Reviewer">
                            Peer Reviewer
                          </Option>
                          <Option key="Others" value="Others">
                            Others
                          </Option>
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/CourseQualityEvaluations`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">


                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/CourseQualityEvaluations`}
                        style={{ color: "orange" }}
                      >
                        {t("Course Quality Evaluation List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="evaluation_areas"
                        label="Evaluation areas"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key="Effectiveness of teaching" value="Effectiveness of teaching">
                            Effectiveness of teaching
                          </Option>
                          <Option key="Effectiveness of students assessment" value="Effectiveness of students assessment">
                            Effectiveness of students assessment
                          </Option>
                          <Option key="Quality of learning resources" value="Quality of learning resources">
                            Quality of learning resources
                          </Option>
                          <Option key="The extent to which CLOs have been achieved" value="The extent to which CLOs have been achieved">
                            The extent to which CLOs have been achieved
                          </Option>
                          <Option key="Others" value="Other">
                            Others
                          </Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="evaluation_method"
                        label="Evaluation method"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key="Direct" value="Direct">
                            Direct
                          </Option>
                          <Option key="Indirect" value="Indirect">
                            Indirect
                          </Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="evaluators"
                        label="Evaluators"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key="Students" value="Students">
                            Students
                          </Option>
                          <Option key="Faculty" value="Faculty">
                            Faculty
                          </Option>
                          <Option key="Program Leaders" value="Program Leaders">
                            Program Leaders
                          </Option>
                          <Option key="Peer Reviewer" value="Peer Reviewer">
                            Peer Reviewer
                          </Option>
                          <Option key="Others" value="Others">
                            Others
                          </Option>
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/CourseQualityEvaluations`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
