import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {

  fetchCourseById
} from "../../../../../reduxToolkit/Course/coursesSpecSlice";


// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";

import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { Breadcrumb } from "antd";
import { fetchModeOfInstructions } from "reduxToolkit/features/ModeOfInstruction/ModeOfInstructionSlice";

import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import DropDownButtonModeOfInstruction from "../../../../Components/DropDownButtonModeOfInstruction";

import { useTranslation } from "react-i18next";

import { notification } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const ModeOfInstructionExcerpt = ({ Element, key, course, id, disabledStatus, isEditor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const buttonClasses = useButtonStyles();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [showMore, setShowMore] = useState(false);
  let data = "";
  const [smallModal, setSmallModal] = React.useState(false);

  return (
    <GridContainer xs={12} sm={12} md={4}>
      <GridItem key={Element.id} xs={12} sm={12} md={12}>
        <Card style={{ height: 200 }}>
          <CardBody>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={10}>
                <h4
                  className={classes.cardProductTitle}

                >
                  <a
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}

                  >
                    {t("Mode Of Instruction")}:
                    {Element &&
                      Element.mode_of_instruction_details &&
                      Element.mode_of_instruction_details[0] &&
                      Element.mode_of_instruction_details[0].name}
                  </a>
                </h4>
                <h4
                  className={classes.cardProductTitle}

                >
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {t("Contact Hours")} : {Element.contact_hours}
                  </a>
                </h4>

                <h4
                  className={classes.cardProductTitle}

                >
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {t("Percentage")} : {Element.percentage} %
                  </a>
                </h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }}>
                {isEditor && (
                  <div>
                    {disabledStatus ? <p></p> :
                      <DropDownButtonModeOfInstruction
                        ModeOfInstruction_ID={Element.id}
                        courseID={course && course.id}
                      />
                    }
                  </div>)}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </GridContainer>
  );
};

export default function ModeOfInstructionList({ match }) {
  const { t } = useTranslation();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();
  const [isEditor, setIsEditor] = useState(true)
  const { id } = match.params;
  // console.log("id of course", props.id)
  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllModeOfInstructions = async () => {
    try {
      const resultAction = await dispatch(fetchModeOfInstructions(id)).unwrap();
      dispatch(fetchCourseById(id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchAllModeOfInstructions();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const ModeOfInstructions = useSelector(
    (state) => state.ModeOfInstruction.ModeOfInstructions
  );

  const ModeOfInstructionsStatus = useSelector(
    (state) => state.ModeOfInstruction.ModeOfInstructionsStatus
  );
  console.log(ModeOfInstructions, "ModeOfInstructionsStatus");

  let content;

  if (ModeOfInstructionsStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (ModeOfInstructionsStatus === "succeeded") {
    if (ModeOfInstructions && ModeOfInstructions.length) {
      content = ModeOfInstructions.map((Element) => (
        <ModeOfInstructionExcerpt
          key={Element.id}
          Element={Element}
          course={course}
          disabledStatus={disabledStatus}
          isEditor={isEditor}
        />
      ));
    } else {
      content = <h5>{t("This Course has no modes to display!")}</h5>;
    }
  }
  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
         

          <Breadcrumb.Item>
            <Link to={`/citrine/CourseSpecificationList/`}>
              {t("Courses List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseSpecDetails/${course && course.id && course.id
                }`}
            >
              {course && course.title && course.title}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/ModeOfInstructionList/${course && course.id && course.id
                }`}
              style={{ color: "orange" }}
            >

              {t("Mode Of Instruction List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridContainer>
        {isEditor && (
          <GridItem>
            {disabledStatus ? <p></p> :
              <Link to={`/citrine/CreateModeOfInstruction/course/${course && course.id}/modeOfInstruction/:idMOI`} >
                <Button color="info" className={classes.updateProfileButton}>
                  {t("Add")}
                </Button>
              </Link>
            }
          </GridItem>
        )}
      </GridContainer>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
