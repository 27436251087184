import { createSlice,  createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    actionTypes: [],
    actionTypeStatus: 'idle',
    error: null,
  }

  export const fetchActionTypes = createAsyncThunk('actiontype/fetchActionTypes', async (parameter,{ rejectWithValue }) => {
    try {
  
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + '/actionType/',
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
  
      )
      console.log(response.data, 'response.data')
      return response.data
  
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  })
  export const deleteActionTypes = createAsyncThunk(
    "actiontype/deleteActionTypes",
    async (id, { rejectWithValue }) => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.delete(
          BaseUrl + `/actionType/${id}/delete/`,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        // console.log(response.data,'response.data')
  
        return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );
  export const addNewActionTypes = createAsyncThunk(
    'actiontype/addNewActionTypes',
    async (toAdd, { rejectWithValue }) => {
  
      try {
        const token = localStorage.getItem('token')
        const response = await axios.post(BaseUrl + `/actionType/create/`, toAdd, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        return response.data
      } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
          throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
      }
    }
  )
  export const updateActionType = createAsyncThunk(
    'actiontype/updateActionType',
    async (data, { rejectWithValue }) => {
      
      const token = localStorage.getItem('token')
  
      try {
        const response = await axios.put(BaseUrl + `/actionType/${data.id}/edit/`, data, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        console.log(response.data, 'response.data')
        return response.data
  
      } catch (err) {
        console.log(err.response)
        let error = err 
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
      }
    }
  )
  


  const actionTypeSlice = createSlice({
    name: 'actiontype',
    initialState,
    extraReducers: {
        [fetchActionTypes.pending]: (state, action) => {
            state.actionTypeStatus = 'loading'
          },
          [fetchActionTypes.fulfilled]: (state, action) => {
            state.actionTypeStatus = 'succeeded'
            // Add any fetched posts to the array
            state.actionTypes = action.payload
          },
          [fetchActionTypes.rejected]: (state, action) => {
            state.actionTypeStatus = 'failed'
            if (action.payload) {
              // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
              state.error = action.payload
              console.log( action.payload)
            } else {
              state.error = action.error
              console.log(action.error)
            }
          },
          [addNewActionTypes.fulfilled]: (state, action) => {
            state.actionTypes.push(action.payload)
          },
          [addNewActionTypes.rejected]: (state, action) => {
            state.error = action.payload ? action.payload : action.error
          },
      
          // [updateAuthority.rejected]: (state, action) => {
          //   state.error = action.payload ? action.payload : action.error
          // },
    }
  })


  export default actionTypeSlice.reducer