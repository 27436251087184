import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import {
    Size,
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    CommonAxisSettings,
    Grid,
    Export,
    Legend,
    Margin,
    Tooltip,
    Label,
    Format,
} from 'devextreme-react/chart';
import { architectureSources, sharingStatisticsInfo } from './data.js';

class Linechart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'spline',
        };
        this.types = ['spline', 'stackedspline', 'fullstackedspline'];
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <React.Fragment>
                <Chart
                    palette="Soft Pastel"
                    dataSource={sharingStatisticsInfo}
                // title="Architecture Share Over Time (Count)"
                >
                    <Size height={280} />

                    <CommonSeriesSettings
                        argumentField="year"
                        type={this.state.type}
                    />
                    <CommonAxisSettings>
                        <Grid visible={true} />
                    </CommonAxisSettings>
                    {
                        architectureSources.map((item) => <Series
                            key={item.value}
                            valueField={item.value}
                            name={item.name} />)
                    }
                    <Margin bottom={20} />
                    <ArgumentAxis
                        allowDecimals={false}
                        axisDivisionFactor={60}
                    >
                        <Label>
                            <Format type="decimal" />
                        </Label>
                    </ArgumentAxis>
                    <Legend horizontalAlignment="center" verticalAlignment="bottom" />
                    <Export enabled={true} />
                    <Tooltip enabled={true} />
                </Chart>
            </React.Fragment>
        );
    }

    handleChange(e) {
        this.setState({ type: e.value });
    }
}

export default Linechart;
