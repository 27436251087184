import "@lourenci/react-kanban/dist/styles.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AssessmentMethods from "../CourseDelivery/AssessmentMethods";
import ContactHours from "../CourseDelivery/ContactHours";
import DifferencesInEvaluation from "../CourseDelivery/DifferencesInEvaluation";

import TeachingStrategies from "../CourseDelivery/TeachingStrategies";
import VerificationOfCredibility from "../CourseDelivery/VerificationOfCredibility";
import "../../improvement.css";

import {
  fetchConclusionsDifficultiesByCourseId_And_Type,
  updateDifficultiesAction,
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";

const { TabPane } = Tabs;
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
export default function CourseDelivery(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  let object = {
    course_id: props.idCourse,
    type: "TS",
  };
  useEffect(() => { }, [dispatch]);
  const TabValues = (object) => {
    dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object));
  };
  return (
    <div>
      {props.type && props.type.toLowerCase().includes("conventional course") ? (
        <Tabs
          tabPosition={"top"}
          style={{
            border: "1px solid lightgray",
            flex: "1",
            paddingLeft: "15px",
          }}
        >
          <TabPane tab={t("Course Contact Hours")} key="1">
            <ContactHours idCourse={props.idCourse} />
          </TabPane>
          <TabPane tab={t("Teaching Strategies")} key="2">
            <TeachingStrategies
              idCourse={props.idCourse}
              TabValues={() => TabValues()}
            />
          </TabPane>
          <TabPane tab={t("Assessment Methods")} key="3">
            <AssessmentMethods idCourse={props.idCourse} />
          </TabPane>
          <TabPane tab={t("Verification of Credibility")} key="4">
            <VerificationOfCredibility idCourse={props.idCourse} />
          </TabPane>
        </Tabs>
      ) : (
        <Tabs
          tabPosition={"top"}
          style={{
            border: "1px solid lightgray",
            flex: "1",
            paddingLeft: "15px",
          }}
        >
          <TabPane tab={t("Differences In Evaluation")} key="1">
            <DifferencesInEvaluation
              idCourse={props.idCourse}
              CourseReport={props.CourseReport}
            />
          </TabPane>

          <TabPane tab={t("Teaching Strategies")} key="2">
            <TeachingStrategies
              idCourse={props.idCourse}
              TabValues={() => TabValues()}
            />
          </TabPane>
          <TabPane tab={t("Assessment Methods")} key="3">
            <AssessmentMethods idCourse={props.idCourse} />
          </TabPane>
          <TabPane tab={t("Verification of Credibility")} key="4">
            <VerificationOfCredibility idCourse={props.idCourse} />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
}
