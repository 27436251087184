import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
import {deleteRoleConfig} from "../RolesConfig/RolesConfigSlice";

const initialState = {
  ReportStatesConfigs: [],
  ReportStatesConfigsStatus: 'idle',
  ReportStatesConfig: null,
  ReportStatesConfigStatus: 'idle',
}

export const fetchReportStatesConfigs = createAsyncThunk('ReportStates/fetchReportStatesConfigs', async (id, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/stateNames/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const addReportStatesConfig = createAsyncThunk(
  'ReportStates/addReportStatesConfig',
  async (toAdd, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/stateNames/create/`, toAdd, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
)

export const deleteReportStatesConfig = createAsyncThunk(
    'ReportStates/deleteReportStatesConfig',
    async (id, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.delete(BaseUrl + `/stateNames/${id}/delete/`, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        return response?.data
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
      }
    })

const ReportStatesConfigSlice = createSlice({
  name: 'ReportStatesConfig',
  initialState,
  extraReducers: {
    [fetchReportStatesConfigs.pending]: (state, action) => {
      state.ReportStatesConfigsStatus = 'loading'
    },
    [fetchReportStatesConfigs.fulfilled]: (state, action) => {
      state.ReportStatesConfigsStatus = 'succeeded'
      state.ReportStatesConfigs = action.payload
    },
    [fetchReportStatesConfigs.rejected]: (state, action) => {
      state.ReportStatesConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteReportStatesConfig.fulfilled]: (state, action) => {},
    [deleteReportStatesConfig.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default ReportStatesConfigSlice.reducer

