import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch, useSelector } from 'react-redux'

import { addNewCourseAssessmentMethod } from 'reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSpecSlice'

// core components
/// test
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Box from '@mui/material/Box'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import 'assets/css/styles.css'
import clsx from 'clsx'
import 'antd/dist/antd.css'
import MenuItem from '@material-ui/core/MenuItem'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchAssessmentQuestionsMethods } from 'reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import {
  DataGrid,
  GridRowModes,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import moment from 'moment'
import { fetchCloById } from 'reduxToolkit/features/CLO/CloSpecSlice'

import { useTranslation } from 'react-i18next'
import { Form, Input, Select, notification } from 'antd'
import ErrorModal from '../../../Components/ErrorModal'
import { addNewQuestion } from 'reduxToolkit/features/assessmentMethod/QuestionSlice'
import { withStyles } from '@material-ui/core/styles'
import { fetchCourseById } from 'reduxToolkit/features/course/CoursesSlice.js'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import AddIcon from '@material-ui/icons/Add'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import { fetchGroups, UpdateGroup } from 'reduxToolkit/features/group/GroupSlice'
import { CourseInstructorUpdate } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { fetchCourseInstructorById } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function EditAssessmentMethodGroups(props) {
  console.log(props)

  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [instructor_ids, setInstructor_id] = React.useState([])
  const [group_id, setGroup_id] = React.useState()
  const [open, setOpen] = React.useState(false)
  //console.log('instructor_ids', group_id)
  const { Option } = Select
  const [anchorEl, setAnchorEl] = React.useState(null)

  const personsStatus = useSelector((state) => state.user.users)

  // useEffect(() => {
  //   // if (personsStatus === 'idle') {
  //   let obj = {
  //     course_id: props.courseGroup.resource_id,
  //   }
  //   console.log('course _id props', props.course_id)
  //   dispatch(fetchStaff())
  //   dispatch(fetchGroups(obj))
  //   form.setFieldsValue({
  //     instructor_ids: props.courseGroup.instructor_ids,
  //     group_id: props.courseGroup.group_id,
  //   })
  //   // }
  // }, [dispatch])
  const persons = useSelector((state) => state.user.staffList)
  const groups = useSelector((state) => state.group.groups)
  //console.log(groups, 'groups')
  const handleClickOpen = () => {
    setOpen(true)
    // form.setFieldsValue({
    //   instructor_ids: props.courseGroup.instructor_ids,
    //   group_id: props.courseGroup.group_id,
    // })
  }
  const handleClose = () => {
    setOpen(false)
    form.resetFields()
  }
  const onChangeGroup_id = (value) => {
    setGroup_id(value)
  }
  const onChangeInstructor = (value) => {
    setInstructor_id(value)
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
      zIndex: 1000,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    // message.error('Submit failed!');
  }

  const onFinish = async (values) => {

    const updated = { ...values, assessmentMethod: props.courseGroup.assessmentMethod_id, semester: localStorage.getItem('semester_id'), id: props.courseGroup.group_details.id }
    console.log(updated)

    dispatch(UpdateGroup(updated))
      .unwrap()
      .then((originalPromiseResult) => {
        props.setPing(!props.ping)
        form.resetFields()
        handleClose()
        openNotificationWithIcon('success', `Updated successfully`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })




    // let assessmentMethod_id = props.courseInstructor.assessmentMethod_id
    // let course_id = props.courseInstructor.course_id
    // let obj = {
    //   exam_id: assessmentMethod_id,
    //   course_id,
    // }
    // const updated = { ...values, assessmentMethod_id, course_id }
    // const resultAction = await dispatch(
    //   CourseInstructorUpdate({
    //     id: props.courseInstructor.id,
    //     updated: updated,
    //   }),
    // )
    // if (CourseInstructorUpdate.fulfilled.match(resultAction)) {
    //   openNotificationWithIcon('success', `Edited successfully`)
    //   dispatch(fetchCourseInstructorById(obj))
    //   form.resetFields()
    //   handleClose()
    // } else {
    //   if (resultAction.payload) {
    //     setHandledError(resultAction.payload)
    //     handleOpenErrorModal()
    //     form.resetFields()
    //     handleClose()
    //   } else {
    //     setHandledError(resultAction.error)
    //     handleOpenErrorModal()
    //     form.resetFields()
    //     handleClose()
    //   }
    // }
  }
  useEffect(() => {
    form.setFieldsValue({
      name: props.courseGroup?.group_details?.group_name
    })
  }, [props])
  return (
    <div>
      <MenuItem
        onClick={() => {
          handleClickOpen()
          props.handleClose()
        }}
      >
        <ListItemText primary="Edit" />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Edit Assessment Method Group')}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer justifyContent="center">
            <GridItem>{props.assessmentQuestionsMethod_name}</GridItem>
            <GridItem xs={4} sm={4} md={12}>
              <Form
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
                autoComplete="off"
              >
                <GridContainer justifyContent="center">
                  <GridItem>
                    {/* <Form.Item
                      label={t('Instructors')}
                      name="instructor_ids"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the instructor',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        name="instructor_ids"
                        value={instructor_ids}
                        dropdownStyle={{ zIndex: 10000 }}
                        onChange={(val) => onChangeInstructor(val)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {persons &&
                          persons.map((person) => (
                            <Option key={person.id} value={person.id}>
                              {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item> */}
                    <Form.Item
                      label={t('Groups')}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the group name',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose()
                            }}
                          >
                            {t('Cancel')}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t('Save')}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
