import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownAreaButton from 'views/Components/DropDownAreaButton';
import ErrorModal from "views/Components/ErrorModal";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { deleteArea, fetchAreas, getId } from '../../../../reduxToolkit/features/area/AreaSpecSlice';





const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);


const AreaExcerpt = ({ area }) => {
  const { t } = useTranslation();
  console.log(area)
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getId(id))

  }
  const deleteUniv = (id) => {
    dispatch(deleteArea(id))
  }

  return (

    <GridContainer
      md={4}
      xs={12} sm={12}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <GridItem key={area.id}>

        <Card product className={classes.cardHover} >
          <CardBody style={{ width: 375, height: 200 }}>
            <GridContainer >

              {/* < GridItem  xs={12} sm={12} md={6}>
                <img src={priceImage1} alt="..." width="100%" height="160" />
              </GridItem> */}

              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardProductTitle} style={{ /*width: 108 */}}>
                      
                      {/* {area.name} */}
                      {`${area.name} (${area.code})`}
                      
                    </h4>

                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <DropDownAreaButton id={area.id} />
                  </GridItem>

                </GridContainer>
              </GridItem>

            </GridContainer>
          </CardBody>

        </Card>




      </GridItem>
    </GridContainer>

  )
}

export default function AreasPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const areasStatus = useSelector(state => state.areaSpec.areasStatus)


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllAreas = async () => {
    try {
      const resultAction = await dispatch(fetchAreas()).unwrap();

    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchAllAreas()
  }, [dispatch])

  const areas = useSelector(state => state.areaSpec.areas)
  console.log('areas', areas)
  const error = useSelector(state => state.areaSpec.error)
  let content

  if (areasStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (areasStatus === 'succeeded') {
    content = areas && areas.map(area => (

      <AreaExcerpt key={area.id} area={area} />
    ))
  } else if (areasStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>

          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span > {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <Link to={`/citrine/configuration/course/areas`} style={{ color: "orange" }}>
                {t('Areas List')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>

      <Link to="/citrine/configuration/course/areas/create" >
        <Button color="info">
          {t('  Add')}
        </Button>
      </Link>

      <GridContainer>
        {
          content

        }

      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
