import React, { useState,useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";


// core components
/// test
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import clsx from "clsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { fetchCourseInstructorById } from "reduxToolkit/features/CourseInstructor/CourseInstructorSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import ErrorModal from "../../../Components/ErrorModal";
import AddAssessmentMethodGroup from "./AddAssessmentMethodGroup";
import DropdownEditCourseInstructors from "./DropdownEditCourseInstructors";
import { getGroupsByAssessmentMethodAndCourse } from "reduxToolkit/features/group/GroupSlice";
import DropdownEditCourseGroups from "./DropdownEditCourseGroups";
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));
const EditGroupsInstructor = ({ match }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { ProgramId, course_id, Assessmentid, AssessmentName } = match.params;
    const dispatch = useDispatch();
    const courseId = course_id;
    const [open, setOpen] = React.useState(false);
    const [ping, setPing] = useState(false);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
  
  
  
   
  
    useEffect( () => {
      
      let obj = {
        course_id: course_id,
        assessmentMethod_id: Assessmentid,
        semester_id: localStorage.getItem('semester_id'),
      }
       dispatch(getGroupsByAssessmentMethodAndCourse(obj));
      
    }, [dispatch,ping]);
    const courseGroups = useSelector((state) => state.group.groupAssessments);
    //console.log(courseInstructors)
    const program = useSelector((state) => state.program.program);
    console.log('courseGroups', courseGroups)
    console.log('program', ProgramId)
    const handleOpenErrorModal = () => {
      setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
    };
  
    const openNotificationWithIcon = (type, msg) => {
      notification[type]({
        message: type,
        description: msg,
        top: 50,
        duration: 2,
        zIndex: 1000,
      });
    };
  
    const handleOpenDeleteModal = () => {
      setOpen(true);
    };
  
    const handleCloseDeleteModal = () => {
      setOpen(false);
    };
    const obj = {
      course_id: course_id,
      assessmentMethod_id: Assessmentid,
      semester_id: localStorage.getItem('semester_id'),
    }
  return (
    <div>
    <GridContainer >
      <GridItem xs={12} sm={12} md={12}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/assessmentsQuestions_list/course/${course_id}`}
            >
              {t("Assessment Methods")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ color: "orange" }} >
            {t("Groups ")}
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
    </GridContainer>

    <Card>

      <CardHeader>
        <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <GridItem xs={12} sm={12} md={11}><h4>{AssessmentName}</h4></GridItem>
          {/* <GridItem> <Button color="primary" startIcon={<AddIcon />}>
      {t("Add")}
    </Button></GridItem> */}
          <GridItem xs={12} sm={12} md={1}>
            <AddAssessmentMethodGroup course_id={courseId} Assessmentid={Assessmentid} />
            </GridItem>
        </GridContainer>
      </CardHeader>

    </Card>

    <GridContainer>
     
      {courseGroups && courseGroups.map((courseGroup, key) =>
        <GridItem xs={12} sm={12} md={4}>
          <Card style={{ minWidth: 375, minHeight: 200 }}>
            <CardHeader  >
              <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <GridItem xs={12} sm={12} md={10}>
                <Link
              to={`/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/${courseGroup.id}`}

            >
              <strong><h4 style={{
                    color: "#8F6682"

                  }}>Name : {courseGroup && courseGroup.name}</h4></strong>
            </Link>
                 
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <DropdownEditCourseGroups  courseGroup={courseGroup} ping={ping} setPing={setPing}/>
                </GridItem>
              </GridContainer>
            </CardHeader>
           
          </Card>
        </GridItem>
      )}
    </GridContainer>
    <ErrorModal
      open={openErrorModal}
      handleClose={handleCloseErrorModal}
      handledError={handledError}
    />
  </div>
  )
}

export default EditGroupsInstructor