import React from 'react';
import Chart, {
    ValueAxis,
    Label,
    Legend,
    Series,
    Size,
    Export,
    LoadingIndicator,
  } from 'devextreme-react/chart';
// import { Chart, Series } from 'devextreme-react/chart';
import { dataSource } from './data.js';
// import './style.css';

class Barchart extends React.Component {
  render() {
    return (
      <Chart id="chart" dataSource={dataSource}>
        <Size height={280} />
        <Series
          valueField="oranges"
          argumentField="day"
        //   name="My oranges"
          type="bar"
          color="#60a69f" />
          <Legend visible={false} />
          <Export enabled={true} />
          <LoadingIndicator enabled={true} />
      </Chart>
    );
  }
}

export default Barchart;