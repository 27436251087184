import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  ranges: [],
  rangesStatus: 'idle',
  rangeStatus: 'idle',
  error: null,
  range:null,
 
  id:null
}


export const fetchRanges = createAsyncThunk('range/fetchRanges', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/assessmentRange/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchRangeById = createAsyncThunk('range/fetchRangeById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/assessmentRange/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
  export const addNewRange = createAsyncThunk(
    'range/addNewRange',
    async (initialRange) => {
      console.log('initial range', initialRange)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/assessmentRange/create/`,initialRange ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const rangeUpdate = createAsyncThunk(
    'range/rangeUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/assessmentRange/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedRange')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the range: ', error)

}
    }
  )

  export const deleteRange = createAsyncThunk('range/deleteRange', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/assessmentRange/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data,'response.data')

    return response.data
  })
const  rangesSlice = createSlice({
  name: ' range',
  initialState,
  extraReducers: {
    [fetchRanges.pending]: (state, action) => {
        state.rangesStatus = 'loading'
      },
      [fetchRanges.fulfilled]: (state, action) => {
        state.rangesStatus = 'succeeded'
        // Add any fetched posts to the array
        state.ranges = action.payload
      },
      [fetchRanges.rejected]: (state, action) => {
        state.rangesStatus = 'failed'
        state.error = action.payload
      },

      [fetchRangeById.pending]: (state, action) => {
        state.rangeStatus = 'loading'
      },
      [fetchRangeById.fulfilled]: (state, action) => {
        state.rangeStatus = 'succeeded'

        // Add any fetched posts to the array
        state.range = action.payload
      },
      [fetchRangeById.rejected]: (state, action) => {
        state.rangeStatus = 'failed'
        state.error = action.payload
      },
      [addNewRange.fulfilled]: (state, action) => {
        state.ranges.push(action.payload)
      },
      [rangeUpdate.fulfilled]: (state, action) => {
        console.log("update succeeded!!!")
      },
      [deleteRange.fulfilled]: (state, action) => {
      
         state.rangeStatus = 'succeeded'
      state.ranges = state.ranges.splice(state.ranges.findIndex((arrow) => arrow.id === action.payload), 1);
      //  const index = state.ranges.findIndex((range) => range.id !== action.payload);
      
     
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    rangeUpdated(state, action) {
      const { id,name } = action.payload
      const existingRange = state.ranges.find(range => range.id === id)
      if (existingRange) {
        existingRange.name = name
      }
    },
    
  }
}})

export const { rangeAdded, rangeUpdated, getId} = rangesSlice.actions

export default rangesSlice.reducer

export const selectAllRanges = state => state.range.ranges
