import React, { useEffect, useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCloById,
  updateClo,
} from '../../../../../reduxToolkit/features/CLO/CloSlice'

// @material-ui/icons

import CustomInput from 'components/CustomInput/CustomInput.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import Card from 'components/Card/Card.js'

import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import { Link } from 'react-router-dom'

import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'

import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Select } from 'antd'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)

const useStyles = makeStyles(styles)

export default function UpdateClo({ match }) {
  const { t } = useTranslation()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const classes = useStyles()
  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  const { id } = match.params

  const clo = useSelector((state) => state.clo.clo)

  const { Option } = Select

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCloById(id))
      .then(unwrapResult)
      .then((clo) => {
        console.log('id', id)
        console.log('clo ', clo)
        setTitle(clo.title)
        setCode(clo.code)
        setDescription(clo.description)
      })
  }, [dispatch, clo])

  const [title, setTitle] = useState(title)
  const [code, setCode] = useState(code)
  const [description, setDescription] = useState(description)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const onTitleChanged = (e) => setTitle(e.target.value)
  const onCodeChanged = (e) => setCode(e.target.value)
  const onDescriptionChanged = (e) => setDescription(e.target.value)

  // const courseStatus = useSelector((state) => state.course.courseStatus)
  // const error = useSelector((state) => state.course.error)
  // console.log('course', course)

  const canSave =
    [title, code, description].every(Boolean) && addRequestStatus === 'idle'
  const history = useHistory()

  const onUpdateCloSpecClicked = async () => {
    //  if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onupdate')
      const resultAction = await dispatch(
        updateClo({ id: id, modified: { title, code, description } }),
      )
      unwrapResult(resultAction)
      setTitle('')
      setCode('')
      setDescription('')
      console.log('clo after being modified', clo)
      history.push('/citrine/CourseSpecificationList')
    } catch (err) {
      console.error('Failed to update the course: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
    //   }
  }

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }

  return (
    <GridContainer>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/CourseSpecificationList/`}>
              {t('Courses List')}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link style={{ color: 'orange' }} to={`/citrine/addCourseSpec/`}>
              {' '}
              {t('Edit clo')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <form>
              <GridContainer
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText={t('Title *')}
                    id="title"
                    //  onChange={onNameChanged}
                    type="text"
                    name="title"
                    value={title}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a title!',
                      },
                    ]}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: false,
                      onChange: (event) => {
                        setTitle(event.target.value)
                      },
                      value: title,
                    }}
                  />

                  <CustomInput
                    labelText={t('Code *')}
                    id="code"
                    value={code}
                    //     onChange={onMissionChanged}
                    type="text"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a code!',
                      },
                    ]}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: false,
                      onChange: (event) => {
                        setCode(event.target.value)
                      },
                      value: code,
                    }}
                  />

                  <CustomInput
                    labelText={t('description *')}
                    id="description"
                    value={description}
                    //     onChange={onMissionChanged}
                    type="text"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter an academic year!',
                      },
                    ]}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: false,
                      onChange: (event) => {
                        setDescription(event.target.value)
                      },
                      value: description,
                    }}
                  />

                  <GridItem>
                    <Button
                      color="info"
                      className={classes.updateProfileButton}
                      onClick={onUpdateCloSpecClicked}
                    >
                      {t('Save')}
                    </Button>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
