import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const AuthorityAndResponsibilityOfFaculty = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>FACULTY: Authority and Responsibility of Faculty</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the role played by faculty members
        with respect to course creation, modification, and evaluation, their
        role in the definition and revision of program educational objectives
        and student outcomes, and their role in the attainment of the student
        outcomes including the roles of others on campus, e.g., dean or provost,
        with respect to these areas:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
    </>
  );
};

export default AuthorityAndResponsibilityOfFaculty;
