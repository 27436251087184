import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { unwrapResult } from '@reduxjs/toolkit'
import { Avatar, Breadcrumb, Divider, notification, Tabs } from 'antd'
import 'antd/dist/antd.css'
// core components
import {
  blackColor,
  cardTitle,
  hexToRgb,
  infoColor,
  primaryColor,
  roseColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import 'bpmn-js/dist/assets/diagram-js.css'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FaHistory, FaProjectDiagram, FaTasks } from 'react-icons/fa'
// import AttachFileIcon from '@mui/icons-material/AttachFile';

import AttachFileIcon from '@material-ui/icons/AttachFile'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchActivities } from '../../../../../reduxToolkit/features/Activitiy/ActivitiesSlice'
import {
  GetHistoricTasks,
  GetUserProfile,
} from '../../../../../reduxToolkit/features/camunda/CamundaSlice'
import {
  fetchStaff,
  fetchStaffById,
  updateStateStaff,
} from '../../../../../reduxToolkit/features/user/UserSlice'
import CompleteCourseReportModal from '../../../../Components/CompleteCourseReportModal'
import '../cvTech.css'

import Modal from '../Modal'

import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import 'bpmn-js/dist/assets/diagram-js.css'

//********************* */

import {
  fetchResearchAndDevelopmentOutputsById,
  UpdateResearchAndDevelopmentOutputs,
} from 'reduxToolkit/features/ResearchAndDevelopmentActivity/ResearchAndInnovationOutputsSlice.js'

import { useParams } from 'react-router-dom'
import ErrorModal from '../../../../Components/ErrorModal'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import clsx from 'clsx'

import CreateIcon from '@material-ui/icons/Create'
import DoneIcon from '@material-ui/icons/Done'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

// import VisibilityIcon from '@material-ui/icons/Visibility';

import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import UploadResearchInnovation from 'views/Components/Uploaders/UploadResearchandInnovation'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const theme = createTheme({
  overrides: {
    MuiStepper: {
      root: {
        background: 'none',
        border: 'none',
        padding: 0,
      },
    },
  },
})
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
  },
})

//******************************** */

const styles2 = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
}

const useStyles2 = makeStyles(styles2)

const { TabPane } = Tabs
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}))

const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
// const useStyles = makeStyles(styles);

const ResearchAndInnovationExcerpt = ({ research, personId, type }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const dispatch = useDispatch()

  return (
    <GridItem xs={12} sm={12} md={12} key={research.id}>
      <Card>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={6}>
            <h5
              style={{
                paddingLeft: 20,
                color: `${primaryColor[0]}`,
                fontWeight: 'bold',
              }}
            >
              Title : {research && research.title}
            </h5>
            <h5
              style={{
                paddingLeft: 20,
                color: `${primaryColor[0]}`,
                fontWeight: 'bold',
              }}
            >
              Type : {research && research.type}
            </h5>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <h5
              style={{
                paddingLeft: 20,
                color: `${primaryColor[0]}`,
                fontWeight: 'bold',
              }}
            >
              College :{' '}
              {research &&
                research.colleges_details &&
                research.colleges_details[0] &&
                research.colleges_details[0].name}
            </h5>

            <h5
              style={{
                paddingLeft: 20,
                color: `${primaryColor[0]}`,
                fontWeight: 'bold',
              }}
            >
              Department :{' '}
              {research &&
                research.departments_details &&
                research.departments_details[0] &&
                research.departments_details[0].name}
            </h5>
          </GridItem>
        </GridContainer>
      </Card>
      <Card style={{ maxHeight: '380px', minHeight: '350px' }} cardHover>
        <CardBody smallCardBody>
          {type && type.toLowerCase().includes('book') ? (
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Title Book')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.book_title}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('ISNB')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.isbn}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Citations')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.citations}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('utilized Research Labs')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.type &&
                      research.utilized_research_labs}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Index')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.index}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Scopus')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.scopus}
                  </span>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Peer Reviewed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.peer_reviewed &&
                    research.peer_reviewed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>

                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Students Involved')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.students_involved &&
                    research.students_involved === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Indexed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.indexed && research.indexed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Collaboration With Researchers')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.collaboration_with_researchers &&
                    research.collaboration_with_researchers === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
              </GridItem>
            </GridContainer>
          ) : type && type.toLowerCase().includes('book chapter') ? (
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Title Book')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.book_title}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('ISNB')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.isbn}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Citations')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.citations}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('utilized Research Labs')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.type &&
                      research.utilized_research_labs}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Index')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.index}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Scopus')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.scopus}
                  </span>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Peer Reviewed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.peer_reviewed &&
                    research.peer_reviewed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>

                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Students Involved')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.students_involved &&
                    research.students_involved === true
                      ? 'YES'
                      : 'NO'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Indexed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.indexed && research.indexed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Collaboration With Researchers')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.collaboration_with_researchers &&
                    research.collaboration_with_researchers === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
              </GridItem>
            </GridContainer>
          ) : type && type.toLowerCase().includes('conference proceeding') ? (
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Conference Paper Title')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.conference_paper_title &&
                      research.conference_paper_title}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Conference')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.conference && research.conference}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Location')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.location && research.location}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Utilized Research Labs')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.type &&
                      research.utilized_research_labs}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Alumnae')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.alumnae && research.alumnae}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Scopus')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.scopus}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Link')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.link && research.link}
                  </span>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Peer Reviewed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.peer_reviewed &&
                    research.peer_reviewed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>

                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Students Involved')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.students_involved &&
                    research.students_involved === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Indexed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.indexed && research.indexed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Collaboration With Researchers')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.collaboration_with_researchers &&
                    research.collaboration_with_researchers === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
              </GridItem>
            </GridContainer>
          ) : type && type.toLowerCase().includes('journal articles') ? (
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Journal Paper Title')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.journal_paper_title &&
                      research.journal_paper_title}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Journal Paper Volume')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.journal_paper_volume &&
                      research.journal_paper_volume}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Journal Issue')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.journal_issue &&
                      research.journal_issue}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Utilized Research Labs')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.type &&
                      research.utilized_research_labs}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('PP to pages')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.pp_to_pages && research.pp_to_pages}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Scopus')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.scopus}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Link')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.link && research.link}
                  </span>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Peer Reviewed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.peer_reviewed &&
                    research.peer_reviewed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>

                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Students Involved')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.students_involved &&
                    research.students_involved === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Indexed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.indexed && research.indexed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Collaboration With Researchers')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.collaboration_with_researchers &&
                    research.collaboration_with_researchers === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
              </GridItem>
            </GridContainer>
          ) : type && type.toLowerCase().includes('patents and ip') ? (
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Patent Office')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.patent_office &&
                      research.patent_office}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Field')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.field && research.field}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Registration Number')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.registration_number &&
                      research.registration_number}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Utilized Research Labs')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.type &&
                      research.utilized_research_labs}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Date of Registration')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.date_of_registration &&
                      research.date_of_registration}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Scopus')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.scopus}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Link')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.link && research.link}
                  </span>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Peer Reviewed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.peer_reviewed &&
                    research.peer_reviewed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>

                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Students Involved')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.students_involved &&
                    research.students_involved === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Indexed')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.indexed && research.indexed === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Collaboration With Researchers')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                    research.collaboration_with_researchers &&
                    research.collaboration_with_researchers === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Potential Commercialization')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.potential_commercialization &&
                      research.potential_commercialization}
                  </span>
                </div>

                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Funding Agency')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.funding_agency &&
                      research.funding_agency}
                  </span>
                </div>

                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('funded')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.funded && research.funded}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Funder Name')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.funder_name && research.funder_name}
                  </span>
                </div>
              </GridItem>
            </GridContainer>
          ) : type && type.toLowerCase().includes('citations') ? (
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Effat Google Scholar Page')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.effat_google_scholar_page &&
                      research.effat_google_scholar_page}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Google Citations')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research &&
                      research.google_citations &&
                      research.google_citations}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Web Of Science Citations')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.type && research.scopus}
                  </span>
                </div>
                <br></br>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
                >
                  {t('Checked')} :
                  <span style={{ fontWeight: 'normal', color: 'black' }}>
                    {research && research.checked && research.checked === true
                      ? 'yes'
                      : 'no'}
                  </span>
                </div>
              </GridItem>
            </GridContainer>
          ) : (
            <p></p>
          )}
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function Activity({ match }) {
  const { t } = useTranslation()
  const classes1 = useStyles1()
  const classes2 = useStyles2()
  const classes = useStyles()

  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  const { id } = match.params
  const { type } = match.params

  console.log('type ', type)
  const dispatch = useDispatch()
  const [current, setCurrent] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [stateName, setStateName] = useState('')
  const [show, setShow] = useState(false)

  const [showActivities, setShowActivities] = useState(true)
  const [showTasks, setShowTasks] = useState(false)
  const [showReports, setShowReports] = useState(false)
  const [showDiagram, setShowDiagram] = useState(false)
  const [showAttachment, setShowAttachment] = useState(false)

  const [open, setOpen] = React.useState(false)

  //bpmn
  const [diagram, diagramSet] = useState('')
  var container = document.getElementById('container')
  const [dynamicTask, setDynamicTask] = useState([])

  const ProcessInstanceStatus = useSelector(
    (state) => state.camunda.ProcessInstanceStatus,
  )
  const course = useSelector((state) => state.CourseSpec.courseSpecification)
  const CourseReport = useSelector((state) => state.CourseReport.CourseReport)
  const Activities = useSelector((state) => state.Activity.Activities)
  const HistoricTasks = useSelector((state) => state.camunda.HistoricTasks)
  const diagramBPMN = useSelector((state) => state.camunda.Diagram)
  const ProcessDefinition = useSelector(
    (state) => state.camunda.ProcessDefinition,
  )

  //************************** */

  const [activeStep, setActiveStep] = React.useState(0)
  const [activeState, setActiveState] = React.useState(null)

  const steps =
    activeState === 'Draft' || activeState === 'Waiting for review'
      ? ['Draft', 'Waiting for review']
      : activeState === 'Reviewed'
      ? ['Draft', 'Waiting for review', 'Reviewed']
      : activeState === 'Rejected'
      ? ['Draft', 'Waiting for review', 'Rejected']
      : []

  const icons =
    activeState === 'Draft' || activeState === 'Waiting for review'
      ? {
          1: <CreateIcon />,
          2: <HourglassEmptyIcon />,
        }
      : activeState === 'Reviewed'
      ? {
          1: <CreateIcon />,
          2: <HourglassEmptyIcon />,
          3: <DoneIcon />,
        }
      : activeState === 'Rejected'
      ? {
          1: <CreateIcon />,
          2: <HourglassEmptyIcon />,
          3: <CloseIcon />,
        }
      : []

  let { activityId } = useParams()
  let currentLanguage = localStorage.getItem('i18nextLng')

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchFacultyActivity = () => {
    dispatch(fetchResearchAndDevelopmentOutputsById(activityId))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setActiveState(originalPromiseResult.state)
        setActiveStep(steps.indexOf(originalPromiseResult.state))
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  useEffect(() => {
    fetchFacultyActivity()
  }, [activeState])

  const ResearchAndDevelopmentOutput = useSelector(
    (state) => state.ResearchAndDevelopmentOutputs.ResearchAndDevelopmentOutput,
  )
  const ResearchAndDevelopmentOutputStatus = useSelector(
    (state) =>
      state.ResearchAndDevelopmentOutputs.ResearchAndDevelopmentOutputStatus,
  )

  console.log('ResearchAndDevelopmentOutput', ResearchAndDevelopmentOutput)

  let contentExcerpt

  if (ResearchAndDevelopmentOutputStatus === 'loading') {
    contentExcerpt = <div className="loader">Loading...</div>
  } else if (ResearchAndDevelopmentOutputStatus === 'succeeded') {
    contentExcerpt = ResearchAndDevelopmentOutput && (
      <ResearchAndInnovationExcerpt
        key={ResearchAndDevelopmentOutput.id}
        research={ResearchAndDevelopmentOutput}
        type={type}
        personId={id}
      />
    )
  } else if (ResearchAndDevelopmentOutputStatus === 'failed') {
    contentExcerpt = <div>An error has accured</div>
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const UpdateState = async (value) => {
    let data = {
      modified: { state: value },
      id: activityId,
    }
    const resultAction = await dispatch(
      UpdateResearchAndDevelopmentOutputs(data),
    )
    if (UpdateResearchAndDevelopmentOutputs.fulfilled.match(resultAction)) {
      fetchFacultyActivity()
      openNotificationWithIcon('success', `updated successfully`)
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const handleSendForReview = () => {
    UpdateState('Waiting for review')
  }

  const handleAccept = () => {
    UpdateState('Reviewed')
  }

  const handleReject = () => {
    UpdateState('Rejected')
  }

  const handleReset = () => {
    UpdateState('Draft')
  }

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    )
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  }

  //********************* */

  useEffect(() => {
    dispatch(fetchStaffById(id))
    dispatch(fetchActivities())
  }, [dispatch])

  const user = useSelector((state) => state.camunda.user)
  const person = useSelector((state) => state.user.staff)

  useEffect(() => {
    setStateName(
      person &&
        person.person_details[0].state &&
        person.person_details[0].state,
    )
  }, [person])

  const SendForReview = () => {
    setStateName('Waiting for review')
    try {
      let modifiedPerson = {
        state: 'Waiting for review',
      }
      console.log('modifiedPerson', modifiedPerson)
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff()),
      )
      unwrapResult(resultAction)
      const resultAction1 = dispatch(fetchStaff())
      unwrapResult(resultAction1)
      setStateName('Waiting for review')
    } catch (err) {
      console.log('Failed to save the person: ', err)
    } finally {
    }
  }

  const MakeReviewed = () => {
    setStateName('Reviewed')
    try {
      let modifiedPerson = {
        state: 'Reviewed',
      }
      console.log('modifiedPerson', modifiedPerson)
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff()),
      )
      unwrapResult(resultAction)
      const resultAction1 = dispatch(fetchStaff())
      unwrapResult(resultAction1)
      setStateName('Reviewed')
    } catch (err) {
      console.log('Failed to save the person: ', err)
    } finally {
    }
  }

  const useCardStyles = makeStyles(cardStyles)

  const ResetTODraft = () => {
    setStateName('Created')
    try {
      let modifiedPerson = {
        state: 'Created',
      }
      console.log('modifiedPerson', modifiedPerson)
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff()),
      )
      unwrapResult(resultAction)
      const resultAction1 = dispatch(fetchStaff())
      unwrapResult(resultAction1)
      setStateName('Created')
    } catch (err) {
      console.log('Failed to save the person: ', err)
    } finally {
    }
  }

  const submitComplete = () => {
    if (stateName === 'Created') {
      SendForReview()
    }
    if (stateName.toLowerCase().includes('waiting for review')) {
      MakeReviewed()
    }
  }

  if (!person) {
    return (
      <section>
        <h2> {t('person not found!')}</h2>
      </section>
    )
  }
  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  const callback = (key) => {
    console.log(key)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  //bmpn Modal
  const handleClickOpen = () => {
    setShow(true)

    setOpen(true)
    setShowDiagram(true)
    setShowActivities(false)
    setShowTasks(false)
    setShowReports(false)
    setShowAttachment(false)
  }

  const handleModalClose = () => {
    setOpen(false)
    setShowDiagram(false)
    setShowActivities(true)

    container = document.getElementById('container').innerHTML = ''
  }

  const renderRow = () => {
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: 'gray' }}
              >
                {Moment(item.DueDate).format('YYYY-MM-DD')}
              </Divider>

              <div
                style={{
                  backgroundColor: '#edeff1',
                  borderBottom: '1px solid #cfd5db',
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={3}>
                    <Avatar
                      style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={9}>
                    <p>
                      <strong>{item.AssignedTo}</strong>
                    </p>
                  </GridItem>
                </GridContainer>

                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={12}>
                    <ul>
                      <li>{item.Summary}</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      )
    } else if (showTasks) {
      return (
        <div>
          {HistoricTasks &&
            HistoricTasks.map((item) => (
              //.filter((item, index) => index !== 0)
              <div>
                <Divider
                  type="horizontal"
                  orientation="center"
                  style={{ color: 'gray' }}
                >
                  {Moment(item.startTime).format('YYYY-MM-DD')} ---{' '}
                  {Moment(item.endTime).format('YYYY-MM-DD')}
                </Divider>

                <div
                  style={{
                    backgroundColor: '#edeff1',
                    borderBottom: '1px solid #cfd5db',
                  }}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem key={Element.id} xs={12} sm={12} md={2}>
                      <Avatar
                        style={{
                          color: '#f56a00',
                          backgroundColor: '#fde3cf',
                        }}
                      >
                        {user && user.firstName[0] + user.lastName[0]}
                      </Avatar>
                    </GridItem>
                    <GridItem key={Element.id} xs={12} sm={12} md={10}>
                      <p>
                        <strong>
                          {user && user.firstName + ' ' + user.lastName}
                        </strong>
                      </p>

                      <ul>
                        <li> Task : {item.name}</li>
                      </ul>

                      <ul>
                        <li>Operation : {item.deleteReason}</li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            ))}
        </div>
      )
    } else if (showDiagram) {
      return (
        <div>
          <button onClick={(event) => handleClickOpen(event)}>
            Show Diagram
          </button>
          <Modal title="My Modal" onClose={() => setShow(false)} show={show}>
            rrrrrr
            {/* <div className="App">
              <div
                id="container"
                style={{
                  border: "1px solid #000000",
                  height: "90vh",
                  width: "90vw",
                  margin: "auto",
                }}
              ></div>
            </div> */}
          </Modal>
        </div>
      )
    } else {
      return (
        <div>
          {/* <UploadAttachmentPerson id={person && person.id} /> */}
          <UploadResearchInnovation
            id={ResearchAndDevelopmentOutput && ResearchAndDevelopmentOutput.id}
          />
        </div>
      )
    }
  }
  const renderTasks = () => {
    setShowTasks(true)
    setShowActivities(false)
    setShowDiagram(false)
    setShowReports(false)
    setShowAttachment(false)
    dispatch(GetUserProfile())
    dispatch(GetHistoricTasks(CourseReport.process_identification))
  }
  const renderActivities = () => {
    setShowActivities(true)
    setShowDiagram(false)
    setShowTasks(false)
    setShowAttachment(false)
    setShowReports(false)
  }
  const renderDiagram = () => {
    setShowReports(false)
    setShowActivities(false)
    setShowDiagram(true)
    setShowTasks(false)
    setShowAttachment(false)
  }

  const renderAttachment = () => {
    setShowReports(false)
    setShowActivities(false)
    setShowDiagram(false)
    setShowTasks(false)
    setShowAttachment(true)
  }

  let content
  if (stateName === 'Created') {
    content = (
      <div
        style={{
          height: '100%',
          backgroundColor: 'white',
          width: '100%',
          borderRadius: '3px',
          border: '1px solid lightgray',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            //margin: "0px 25px 0px 20px",
            paddingLeft: '10px',
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <ol class="progress-bar" style={{ width: '60%', height: '40px' }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === 'Created' ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: 'auto',
                      paddingTop: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: 'auto', paddingTop: '10px' }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                )
              })}
          </ol>
        </div>
      </div>
    )
  } else if (
    stateName &&
    stateName.toLowerCase().includes('waiting for review')
  ) {
    content = (
      <div
        style={{
          height: '100%',
          backgroundColor: 'white',
          width: '100%',
          borderRadius: '3px',
          border: '1px solid lightgray',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            //margin: "0px 25px 0px 20px",
            paddingLeft: '10px',
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <div>
            <Button
              style={{ backgroundColor: 'lightgery' }}
              size="sm"
              className={classes.marginRight}
              onClick={() => ResetTODraft()}
            >
              <span style={{ color: 'white', fontSize: '11px' }}>
                {t('Reset tO draft')}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: '60%', height: '40px' }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el && el.toLowerCase().includes('waiting for review') ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: 'auto',
                      paddingTop: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: 'auto', paddingTop: '10px' }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                )
              })}
          </ol>
        </div>
      </div>
    )
  } else if (stateName === 'Reviewed') {
    content = (
      <div
        style={{
          height: '100%',
          backgroundColor: 'white',
          width: '100%',
          borderRadius: '3px',
          border: '1px solid lightgray',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            //margin: "0px 25px 0px 20px",
            paddingLeft: '10px',
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <ol class="progress-bar" style={{ width: '60%', height: '40px' }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === 'Reviewed' ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: 'auto',
                      paddingTop: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: 'auto', paddingTop: '10px' }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                )
              })}
          </ol>
        </div>
      </div>
    )
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t('Faculty')}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t('Teaching Staff')}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${
                person && person.person_details[0].last_name
              }`}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}/researchChoice`}>
              Research and innovation Types
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivitiesOutputs`}
            >
              {t('Research and innovation outputs')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivityOutputs/${id}/${type}`}
            >
              {t('Details')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <div>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem key={Element.id} xs={12} sm={12} md={9}>
            <div className={classes.root}>
              {activeState && (
                <div>
                  <Card>
                    <GridContainer alignItems="center">
                      <GridItem xs={3} sm={3} md={3}>
                        {activeState === 'Draft' && (
                          <div>
                            <Button
                              color="primary"
                              onClick={handleSendForReview}
                            >
                              Send for review
                            </Button>
                          </div>
                        )}
                        {activeState === 'Waiting for review' && (
                          <div>
                            <Button color="info" onClick={handleReset}>
                              Reset
                            </Button>
                            <Button color="primary" onClick={handleAccept}>
                              Accept
                            </Button>
                            <Button color="primary" onClick={handleReject}>
                              Reject
                            </Button>
                          </div>
                        )}
                        {activeState === 'Reviewed' && (
                          <div>
                            <Button color="info" onClick={handleReset}>
                              Reset
                            </Button>
                          </div>
                        )}
                        {activeState === 'Rejected' && (
                          <div>
                            <Button color="info" onClick={handleReset}>
                              Reset
                            </Button>
                          </div>
                        )}
                      </GridItem>
                      <GridItem xs={9} sm={9} md={9}>
                        <ThemeProvider theme={theme}>
                          <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            connector={
                              currentLanguage === 'ar' ? (
                                false
                              ) : (
                                <ColorlibConnector />
                              )
                            }
                          >
                            {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                  {label}
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </ThemeProvider>
                      </GridItem>
                    </GridContainer>
                  </Card>
                </div>
              )}

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem key={Element.id} xs={12} sm={12} md={12}>
                  {contentExcerpt}
                </GridItem>
              </GridContainer>
              <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
              />
            </div>
          </GridItem>

          {/* <GridItem key={Element.id} xs={12} sm={12} md={3}>
            <div
              style={{
                padding: '10px',
                borderRadius: '0px',
                color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
                background: whiteColor,
                width: '100%',
                boxShadow:
                  '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '0',
                wordWrap: 'break-word',
                fontSize: '.875rem',
                height: '82vh',
                border: '1px solid lightgray',
              }}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer justify="space-around">
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderActivities}
                      >
                        <FaHistory
                          color={showActivities ? primaryColor[0] : 'black'}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderTasks}
                      >
                        <FaTasks
                          color={showTasks ? primaryColor[0] : 'black'}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderDiagram}
                      >
                        <FaProjectDiagram
                          color={showDiagram ? primaryColor[0] : 'black'}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderAttachment}
                      >
                        <AttachFileIcon
                          color={showAttachment ? primaryColor[0] : 'black'}
                        />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div class="scrollable">
                    <div
                      style={{
                        padding: '10px',
                        borderRadius: '0px',
                        color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '0',
                        wordWrap: 'break-word',
                        fontSize: '.875rem',
                        height: '68vh',
                      }}
                    >
                      {renderRow()}
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem> */}
        </GridContainer>
      </div>
    </div>
  )
}
