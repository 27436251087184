import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Form, notification } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardBodyStyle from 'assets/jss/material-dashboard-pro-react/components/cardBodyStyle';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileWord } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// import pagesHeaderstyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle";
import { grayColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchCourseById
} from "../../../../../reduxToolkit/Course/coursesSpecSlice";
import { addNewPDF, downloadPDF, fetchCourseSpecReportDocumentById } from "../../../../../reduxToolkit/features/CourseSpecificationReportDocument/CourseSpecificationReportDocumentSlice";
import ErrorModal from "../../../../Components/ErrorModal";



const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useCardBodyStyles = makeStyles(cardBodyStyle);

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function CourseSpecReportDocumentDetails() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("")
  const [form] = Form.useForm();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const cardClasses = useCardStyles();
  const cardBodyClasses = useCardBodyStyles()
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)

  let { progReportDocumentId } = useParams();
  let { id } = useParams();

  const dispatch = useDispatch()
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCourse = async () => {
    try {
      const courses = await dispatch(fetchCourseById(id))
        .then(unwrapResult)
        .then((course) => {

          localStorage.setItem("CourseState", course.state);
        });
      console.log("success", `Fetched`);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
      console.log("error", `Fetch failed: ${err}`);
    }
  };
  useEffect(() => {
    fetchCourse();
    dispatch(fetchCourseSpecReportDocumentById(progReportDocumentId));

  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const doc = useSelector((state) => state.attachment.attachment);

  console.log('doooooc', doc)
  const courseSpecReportDocumentStatus = useSelector(state => state.CourseSpecReportDocument.courseSpecReportDocumentStatus)
  // useEffect(() => {
  //     dispatch(fetchCourseSpecReportDocumentById(progReportDocumentId))
  //     dispatch(fetchCourseSpecReportDocumentById())
  // }, [dispatch])

  const courseSpecReportDocument = useSelector(state => state.CourseSpecReportDocument.courseSpecReportDocument)
  console.log(courseSpecReportDocument, "courseSpecReportDocument")
  // let doc_id = courseSpecReportDocument && courseSpecReportDocument.id;
  let ressource = "courseSpec";
  // const [openErrorModal, setOpenErrorModal] = React.useState(false);
  // const [handledError, setHandledError] = React.useState("");

  // const handleOpenErrorModal = () => {
  //   setOpenErrorModal(true);
  // };
  // const handleCloseErrorModal = () => {
  //   setOpenErrorModal(false);
  // };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewPDF({
        ressource: "courseSpec",
        ressourceId: progReportDocumentId,
        ...values
      })
    );
    if (addNewPDF.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Created successfully`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const download = async (id) => {
    const resultAction = await dispatch(
      downloadPDF(id)
    );
    if (downloadPDF.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Created successfully`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };


  function exportDocumentToWORD(element, filename = '') {
    var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>`;
    var postHtml = `</body></html>`;
    var html = preHtml + element + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    // Specify link url
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + '.doc' : 'document.doc';

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }
  function showpdf(directory) {
    window.open(directory);
  }

  return (
    <div>
      {/* <GridContainer
                alignItems="center"
                style={{
                    borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
                }}>
                <GridItem>
                    <Link to={`/citrine/courseSpec`}>
                        <span className={titleClasses.title}> {t('Courses')} </span>
                    </Link>

                </GridItem>

            </GridContainer> */}
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>

              {t("Specification")}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                style={{ color: "orange" }}
                to={`/citrine/courseSpecDetails/${id}`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>

              {t("Course Specification Report Document")}

            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={10} justify='flex-end'>
        {/* <GridItem>
                    
                    <Button
                        color="white"
                        aria-label="Edit"
                        justIcon
                        round
                        onClick = {download(id)}
                    >
                       
                    </Button>
               
            </GridItem> */}
        <GridItem>
          <Link to={`/citrine/courseSpecDetails/${id}/CourseSpecificationReportDocument/${courseSpecReportDocument && courseSpecReportDocument.id}/editor`}>
            <Button
              color="white"
              aria-label="Edit"
              justIcon
              round
            >
              <Edit />
            </Button>
          </Link>
        </GridItem>
        {/* <GridItem>
                <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                   <Form.Item >
                      
                      <Button 
                        color="white"
                        aria-label="Export to PDF"
                        justIcon
                        round 
                        type="primary" 
                        htmlType="submit">
                        <FaFilePdf />
                      </Button>
                    </Form.Item>
                  </Form>
                    {/* <Button
                        color="white"
                        aria-label="Export to PDF"
                        justIcon
                        round
                        onClick={exportDocumentToPDF}
                    >
                        <FaFilePdf />
                    </Button> */}
        {/* </GridItem>  */}
        <GridItem>
          <Button
            color="white"
            aria-label="Export to Word"
            justIcon
            round
            onClick={() => { exportDocumentToWORD(courseSpecReportDocument && courseSpecReportDocument.htmlTemplates_body && courseSpecReportDocument.htmlTemplates_body, 'word-content') }}
          >
            <FaFileWord />
          </Button>

        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={10}>
        <Card>

          <CardBody className={cardBodyClasses.cardBodyWithMarginTop}>

            <GridContainer justify='center'>
              <GridItem>
                <div dangerouslySetInnerHTML={{ __html: courseSpecReportDocument && courseSpecReportDocument.htmlTemplates_body }} ></div>
              </GridItem>
            </GridContainer>
          </CardBody>

        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}













