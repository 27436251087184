import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  indicatorsStatus: "idle",
  GAIndicators: [],
  indicatorStatus: "idle",
  GAIndicator: null,
  error: null,
};

export const fetchGAIndicators = createAsyncThunk(
  "IndicatorSpec/fetchGAIndicators",
  async ({ rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + "/graduateAttributeIndicatorsSpec/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchGAIndicatorById = createAsyncThunk(
  "IndicatorSpec/fetchGAIndicatorById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/graduateAttributeIndicatorsSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewGAIndicator = createAsyncThunk(
  "IndicatorSpec/addNewIndicator",
  async (initialIndicator, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/graduateAttributeIndicatorsSpec/create/`,
        initialIndicator,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteGAIndicator = createAsyncThunk(
  "IndicatorSpec/deleteGAIndicator",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/graduateAttributeIndicatorsSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const GAIndicatorSpecSlice = createSlice({
  name: "IndicatorSpec",
  initialState,
  extraReducers: {
    [fetchGAIndicators.pending]: (state, action) => {
      state.indicatorsStatus = "loading";
    },
    [fetchGAIndicators.fulfilled]: (state, action) => {
      state.indicatorsStatus = "succeeded";
      state.GAIndicators = action.payload;
    },
    [fetchGAIndicators.rejected]: (state, action) => {
      state.indicatorsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchGAIndicatorById.pending]: (state, action) => {
      state.indicatorStatus = "loading";
    },
    [fetchGAIndicatorById.fulfilled]: (state, action) => {
      state.indicatorStatus = "succeeded";
      state.GAIndicator = action.payload;
    },
    [fetchGAIndicatorById.rejected]: (state, action) => {
      state.indicatorStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewGAIndicator.fulfilled]: (state, action) => {
      state.GAIndicators.push(action.payload);
    },
    [addNewGAIndicator.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },
    [deleteGAIndicator.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    [deleteGAIndicator.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
});

export default GAIndicatorSpecSlice.reducer;
