import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchCodeLevels
} from "../../../../reduxToolkit/features/CodeLevel/CodeLevelConfigSlice";
import DropDownCodeLevelButton from "../../../Components/DropDownCodeLevelButton";



const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function CodeLevelPage() {
  const cardClasses = useCardStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();
  const codeLevelStatus = useSelector(
    (state) => state.CodeLevel.codeLevelStatus
  );
  console.log("status codeLevelStatus", codeLevelStatus);

  useEffect(() => {
    dispatch(fetchCodeLevels());
  }, [dispatch]);

  const CodeLevels = useSelector((state) => state.CodeLevel.codeLevels);
  console.log("CodeLevels", CodeLevels);
  const error = useSelector((state) => state.CodeLevel.error);

  return (
    <div>
      <GridContainer

        md={12}
        xs={12} sm={12}
        direction="row"
        // style={{ width:300 ,height:150}}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>

          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span > {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/codeLevels`}>
                <span > {t('Code Levels')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>


      <Link to="/citrine/createCodeLevel/:id">
        <Button color="info">{t("  Add")}</Button>
      </Link>

      <GridContainer md={12} xs={12} sm={12}>
        {CodeLevels &&
          CodeLevels.map((codeLevel) => (
            <GridItem xs={12} sm={12} md={4} key={codeLevel.id} >
              <Card style={{ maxWidth: "380px", minWidth: "380px", maxHeight: "180px", minHeight: "180px" }} cardHover >
                <CardHeader>
                  <GridContainer direction="row"
                    justifyContent="space-between"
                    alignItems="baseline">
                    <GridItem xs={10} sm={10} md={10}>
                      {/* <Link to={`/citrine/programSpec/${program.id}`}> */}
                      <h4 className={cardClasses.cardProductTitle}>
                        Level: {codeLevel.level}
                      </h4>
                      <h5 className={cardClasses.cardProductTitle}>
                        Code: {codeLevel.code_level}
                      </h5>
                      {/* </Link> */}
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                      <DropDownCodeLevelButton id={codeLevel.id} />
                    </GridItem>
                  </GridContainer>
                </CardHeader>

                <CardFooter>
                  <GridContainer
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                    {/* <GridItem> <span style={{ color: (program.state) === "Draft" ? grayColor[1] : "#FFCB3B" }}>{program.state}</span> </GridItem> */}
                  </GridContainer>
                </CardFooter>
              </Card>
            </GridItem>
          ))
        }
      </GridContainer>
    </div>
  );
}
