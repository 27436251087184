// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Breadcrumb } from 'antd';
// core components
import { unwrapResult } from "@reduxjs/toolkit";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchDepartmentById } from '../../../../reduxToolkit/features/department/DepartmentsSlice';
import { fetchStaffById } from "../../../../reduxToolkit/features/user/UserSlice";
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);


export default function DepartmentDetails({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();

  const { id } = match.params

  useEffect(() => {
    dispatch(fetchDepartmentById(id)).then(unwrapResult).then((item) => {
      console.log('item.chief_id', item.chief_department_id)
      dispatch(fetchStaffById(item.chief_department_id))
    })
  }, [id, dispatch])

  const department = useSelector((state) => state.department.department)
  const director = useSelector((state) => state.user.staff)
  console.log('director', director)
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
        <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/organizations`}>
                <span > {t('Organizations')} </span>
              </Link>
            </Breadcrumb.Item>
            
            <Breadcrumb.Item>
              <Link to={`/citrine/organizations/departments`}>
                <span > {t('Departments')} </span>
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/organizations/departments/${department && department.id}`} >
                <span > {`${department && department.name}`} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          {/* <span>
            <Link to={`/citrine/organizations`}>{t('Organizations')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/departments`}> {t('/Departments')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/departments/${department && department.id}`}> {`/${department && department.name}`} </Link>
          </span> */}
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer justify='space-between' alignItems='center'>
                <GridItem xs={11} sm={11} md={11}>
                  <h4 className={classes.cardIconTitle}>
                    {department && department.name}
                  </h4>
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Tooltip
                    title="Edit"
                  >
                    <Link to={`/citrine/organizations/departments/create/${department && department.id}`}>
                      <Button
                        aria-label="edit"
                        color="primary"
                        justIcon
                        round
                      >
                        <EditIcon />
                      </Button>
                    </Link>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}> <div> {t('College')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {department && department.college_detail.name}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('Phone number')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {department && department.phone_number}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t(' Email address')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {department && department.email}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('Address')}</div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {department && department.address}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('City')}</div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {department && department.city}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('Country')}</div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div> {department && department.country}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t(' Chief department')}   </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div>  {director && director.person_details && director.person_details[0].first_name} {director && director.person_details && director.person_details[0].last_name}</div> </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer style={{ display: "flex", flexDirection: "row", justify: "space-between" }}>
                <GridItem xs={12} sm={12} md={2}> <div>{t('Mission')}</div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div>  {department && department.mission}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('Vision')}</div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div>   {department && department.vision}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('Goals')} </div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div>   {department && department.goals}</div></GridItem>

                <GridItem xs={12} sm={12} md={2}> <div> {t('Values')}</div></GridItem>
                <GridItem xs={12} sm={12} md={10}> <div>   {department && department.values}</div></GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );

}




