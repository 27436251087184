import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  surveyTemplatesTypeConfigsStatus: 'idle',
  surveyTemplatesTypeConfigStatus: 'idle',
  error: null,
  surveyTemplatesTypeConfigs: [],
  surveyTemplatesTypeConfig: null,
}

export const fetchSurveyTemplatesTypeConfigs = createAsyncThunk('surveyTemplateTypeConfig/fetchSurveyTemplatesTypeConfigs', async () => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + '/surveyTemplateTypeConfig/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }
  )
  return response.data
})

export const fetchSurveyTemplatesTypeConfigById = createAsyncThunk('surveyTemplateTypeConfig/fetchSurveyTemplatesTypeConfigById', async (id) => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + `/surveyTemplateTypeConfig/${id}`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  return response.data
})

export const addNewSurveyTemplatesTypeConfig = createAsyncThunk(
  'surveyTemplateTypeConfig/addNewSurveyTemplatesTypeConfig',
  async (initialSurveyTemplatesTypeConfig, { rejectWithValue }) => {
    try {
    const token = localStorage.getItem('token')
    const response = await axios.post(BaseUrl + `/surveyTemplateTypeConfig/create/`, initialSurveyTemplatesTypeConfig, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})

export const updateSurveyTemplatesTypeConfig = createAsyncThunk(
  'surveyTemplateTypeConfig/updateSurveyTemplatesTypeConfig',
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/surveyTemplateTypeConfig/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (error) {}
  }
)

export const deleteSurveyTemplatesTypeConfig = createAsyncThunk('surveyTemplatesTypeConfig/deleteSurveyTemplatesTypeConfig', async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl+`/surveyTemplateTypeConfig/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
  return response.data
  } catch (err) {
  // Use `err.response.data` as `action.payload` for a `rejected` action,
  // by explicitly returning it using the `rejectWithValue()` utility
  return rejectWithValue(err.response.data)
  }
})

const SurveyTemplatesTypeConfigSlice = createSlice({
  name: 'surveyTemplatesTypeConfig',
  initialState,
  extraReducers: {
    [fetchSurveyTemplatesTypeConfigs.pending]: (state, action) => {
      state.surveyTemplatesTypeConfigsStatus = 'loading'
    },
    [fetchSurveyTemplatesTypeConfigs.fulfilled]: (state, action) => {
      state.surveyTemplatesTypeConfigsStatus = 'succeeded'
      state.surveyTemplatesTypeConfigs = action.payload
    },
    [fetchSurveyTemplatesTypeConfigs.rejected]: (state, action) => {
      state.surveyTemplatesTypeConfigsStatus = 'failed'
      state.error = action.payload
    },

    [fetchSurveyTemplatesTypeConfigById.pending]: (state, action) => {
      state.surveyTemplatesTypeConfigStatus = 'loading'
    },
    [fetchSurveyTemplatesTypeConfigById.fulfilled]: (state, action) => {
      state.surveyTemplatesTypeConfigStatus = 'succeeded'
      // Add any fetched posts to the array
      state.surveyTemplatesTypeConfig = action.payload
    },
    [fetchSurveyTemplatesTypeConfigById.rejected]: (state, action) => {
      state.surveyTemplatesTypeConfigStatus = 'failed'
    },

    [addNewSurveyTemplatesTypeConfig.fulfilled]: (state, action) => {
      state.surveyTemplatesTypeConfigs.push(action.payload)
    },
    [addNewSurveyTemplatesTypeConfig.rejected]: (state, action) => {
      state.surveyTemplatesTypeConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteSurveyTemplatesTypeConfig.fulfilled]: (state, action) => {},

    [updateSurveyTemplatesTypeConfig.fulfilled]: (state, action) => {},
  },
})


export default SurveyTemplatesTypeConfigSlice.reducer
