import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { unwrapResult } from "@reduxjs/toolkit";
import { Select } from "antd";
import StarIcon from "@material-ui/icons/Star";
import "antd/dist/antd.css";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { deleteActionRecommendation } from "../../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import { fetchCourses } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchPlos } from "../../../../reduxToolkit/features/PLO/PLOsSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import {
  deleteRecommendation,
  fetchPloRecommendations,
  fetchKPIsRecommendations,
} from "../../../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import KPIActionRecommendationModal from "../../../Components/KPIActionRecommendationModal";
import AddKpiRecommendationModal from "../../../Components/AddKpiRecommendationModal";
import EditPloIndictorsModal from "../../../Components/EditPloIndictorsModal";
import EditRecommendationModal from "../../../Components/EditRecommendationModal";
import useTable from "../../../Components/useTable";
import useTablePlo from "../../../Components/useTablePlo";
import useTableRecommendation from "../../../Components/useTableRecommendation";

import { Avatar } from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";

import {
  warningColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";

import EditStandardsOverAnalysisModal from "./EditStandardsOverAnalysisModal";
import EditkPIAnalysisModal from "./EditkPIAnalysisModal";

const { Option, OptGroup } = Select;

const useStyles3 = makeStyles(styles1);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useStyles4 = makeStyles(styles4);

const useCardStyles = makeStyles(cardStyles);
const useButtonStyles = makeStyles(buttonStyles);
const headCells = [
  { id: "PloIndicators", label: "Plo Indicators" },
  { id: "Satisfactory", label: "Satisfactory" },
  { id: "Developing", label: "Developing" },
  { id: "Unsatisfactory", label: "Unsatisfactory" },
  { id: "edit", label: "Edit" },
];
const headPloCells = [
  { id: "PloIndicators", label: "Plo Indicators " },
  { id: "Average", label: "Average" },
  { id: "Target", label: "Target" },
  { id: "Strength", label: "Strength" },
  { id: "Weakness", label: "Weakness" },
];
const headRecommendationsCells = [
  { id: "Recommendations", label: "Recommendations" },
  { id: "Actions", label: "Actions" },
  { id: "Edit", label: "" },
];

export default function AnalysisKpis(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const classes4 = useStyles4();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();

  // const plos = useSelector((state) => state.plo.plos);
  let obj = {};
  useEffect(() => {
    //dispatchKPIsRecommendations()
    // dispatch(fetchPlos(props.idProgram));
    dispatch(fetchStaff());
  }, []);

  // const coursesStatus = useSelector((state) => state.course.coursesStatus);
  // useEffect(() => {
  //   if (coursesStatus === "idle") {
  //     dispatch(fetchCourses());
  //   }
  // }, [coursesStatus, dispatch]);
  // const courses = useSelector((state) => state.course.courses);
  // const error = useSelector((state) => state.course.error);
  const score = useSelector((state) => state.ploIndicator.ScorePloIndicator);

  const users = useSelector((state) => state.user.staffList);

  const [shownPloIndicators, setShownPloIndicators] = useState({});

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [kpiRecommendations, setKpiRecommendations] = React.useState(null);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log("res");
  };
  const [shownComments, setShownComments] = useState({});

  const toggleComment = (id, type) => {
    let obj = {
      type: type,
      Kpi_id: id,
    };

    setShownComments((prevShownComments) => ({
      ...prevShownComments,
      [id]: !prevShownComments[id],
    }));
    dispatch(fetchKPIsRecommendations(obj))
      .then(unwrapResult)
      .then((Recommendations) => {
        setKpiRecommendations(Recommendations);
      });
  };
  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: "flex" }}>

          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />

        </div>
      );
    } else if (value == 2) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />

        </div>
      );
    } else if (value == 3) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />

        </div>
      );
    }
  };

  const dispatchKPIsRecommendations = (id, type) => {
    let obj = {
      type: type,
      Kpi_id: id,
    };
    dispatch(fetchKPIsRecommendations(obj))
      .then(unwrapResult)
      .then((Recommendations) => {
        setKpiRecommendations(Recommendations);
      });
  };

  const KpiRecommendations = useSelector(
    (state) => state.Recommendations.KPIsRecommendations
  );

  console.log("KpiRecommendations", KpiRecommendations);

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn);
  const { TblPloContainer, TblPloHead } = useTablePlo(
    records,
    headPloCells,
    filterFn
  );

  const {
    TblRecommendationContainer,
    TblRecommendationHead,
  } = useTableRecommendation(records, headRecommendationsCells, filterFn);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const delete_KpiRecommendations = (id, objId, type) => {
    let obj = {
      type: type,
      Kpi_id: objId,
    };
    dispatch(deleteRecommendation(id)).then(() => {
      dispatch(fetchKPIsRecommendations(obj))
        .then(unwrapResult)
        .then((Recommendations) => {
          setKpiRecommendations(Recommendations);
        });
    });
  };
  const delete_ActionRecommendation = (id, objId, type) => {
    let obj = {
      type: type,
      Kpi_id: objId,
    };
    dispatch(deleteActionRecommendation(id)).then(() => {
      dispatch(fetchKPIsRecommendations(obj))
        .then(unwrapResult)
        .then((Recommendations) => {
          setKpiRecommendations(Recommendations);
        });
    });
  };

  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        {props.kpiReport &&
          props.kpiReport.standards > 0 &&
          props.kpiReport.standards.map((element, j) => (
            <fieldset
              key={j}
              style={{
                backgroundColor: "#eeeeee",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <legend
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  padding: "5px 10px",
                }}
              >
                Standard {j} : {element.standard}
              </legend>
              {element &&
                element.kpis.map((obj, i) => (
                  <Card
                    key={obj.id}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      width: "97%",
                      marginLeft: "10px",
                    }}
                  >
                    <CardBody>
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={6}>
                          <strong>
                            S{j} - KPI {i}
                          </strong>
                          <strong>
                            <p
                              style={{
                                color: "gray",
                                textAlign: "start",
                                fontSize: "14px",
                              }}
                            >
                              <Tooltip
                                id="tooltip-top"
                                title={obj.name}
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                                style={{ fontSize: "14px" }}
                              >
                                <div simple color="info" justIcon>
                                  {obj.name}
                                </div>
                              </Tooltip>
                            </p>
                          </strong>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginEnd: "10px",
                            }}
                          >
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: red[600],
                                  height: 56,
                                  width: 56,
                                }}
                                style={{ backgroundColor: "#6C7B9D" }}
                              >
                                {obj.internal_benchmark}%
                              </Avatar>
                              <p
                                style={{ marginTop: "10px", fontSize: "14px" }}
                              >
                                {t("In Benchmark")}
                              </p>
                            </Grid>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: orange[600],
                                  height: 56,
                                  width: 56,
                                }}
                                style={{ backgroundColor: "#6C7B9D" }}
                              >
                                {obj.external_benchmark}%
                              </Avatar>
                              <p
                                style={{ marginTop: "10px", fontSize: "14px" }}
                              >
                                {t("Ex Benchmark")}
                              </p>
                            </Grid>

                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: orange[600],
                                  height: 56,
                                  width: 56,
                                }}
                                style={{ backgroundColor: "#6C7B9D" }}
                              >
                                {obj.target}%
                              </Avatar>
                              <p
                                style={{ marginTop: "10px", fontSize: "14px" }}
                              >
                                {t("Target")}
                              </p>
                            </Grid>

                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: orange[600],
                                  height: 56,
                                  width: 56,
                                }}
                                style={{ backgroundColor: "#6C7B9D" }}
                              >
                                {obj.actual_benchmark}%
                              </Avatar>
                              <p
                                style={{ marginTop: "10px", fontSize: "14px" }}
                              >
                                {t("Actual")}
                              </p>
                            </Grid>
                          </div>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={1}
                          style={{ textAlign: "end" }}
                        >
                          {obj.id ? (
                            <Button
                              color="transparent"
                              round
                              simple
                              aria-label="Dashboard"
                              justIcon
                              onClick={() => toggleComment(obj.id, obj.type)}
                            >
                              <DownOutlined style={{ fontSize: "14px" }} />
                            </Button>
                          ) : null}
                        </GridItem>
                      </GridContainer>

                      <GridContainer xs={12} sm={12} md={12}>
                        {shownComments[obj.id] && KpiRecommendations ? (
                          <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={12}>
                              <AddKpiRecommendationModal
                                handleDropDownButtonClose={handleClose}
                                Kpi_id={obj.id}
                                type={obj.type}
                                setKpiRecommendations={setKpiRecommendations}
                                resource={obj.type}
                                resource_id={obj.id}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                              <div
                                style={{
                                  margin: "0px 10px 10px 10px ",
                                  borderRight: "1px solid lightgrey",
                                  borderLeft: "1px solid lightgrey",
                                }}
                              >
                                <TblRecommendationContainer
                                  style={{
                                    marginTop: "0px",
                                    paddingTop: "0px",
                                  }}
                                >
                                  <TblRecommendationHead />
                                  <TableBody>
                                    {KpiRecommendations &&
                                      KpiRecommendations.map((item) => (
                                        <TableRow key={item.id}>
                                          <TableCell
                                            style={{
                                              borderRight:
                                                "1px solid lightgrey",
                                            }}
                                          >
                                            <EditRecommendationModal
                                              recommendation_id={item.id}
                                              plo_Id={obj.id}
                                              setKpiRecommendations={
                                                setKpiRecommendations
                                              }
                                            />
                                            <p
                                              style={{
                                                display: "-webkit-box",
                                                maxWidth: "200px",
                                                WebkitLineClamp: 4,
                                                WebkitBoxOrient: "vertical",
                                                overflow: "hidden",
                                              }}
                                            >
                                              {item && item.recommendation}
                                            </p>
                                          </TableCell>

                                          <TableCell
                                            className="multipleLinePlo"
                                            style={{
                                              width: "490px",
                                              borderRight:
                                                "1px solid lightgrey",
                                            }}
                                          >
                                            <GridContainer
                                              xs={12}
                                              sm={12}
                                              md={12}
                                            >
                                              <GridItem
                                                xs={12}
                                                sm={12}
                                                md={11}
                                              ></GridItem>

                                              <GridItem xs={12} sm={12} md={1}>
                                                <KPIActionRecommendationModal
                                                  handleDropDownButtonClose={
                                                    handleClose
                                                  }
                                                  recommendation_id={item.id}
                                                  kpi_id={obj.id}
                                                  type={obj.type}
                                                  users={users}
                                                  dispatchKPIsRecommendations={
                                                    dispatchKPIsRecommendations
                                                  }
                                                  kpiReport={props.kpiReport}
                                                />
                                              </GridItem>
                                            </GridContainer>

                                            <GridContainer
                                              xs={12}
                                              sm={12}
                                              md={12}
                                            >
                                              {item &&
                                                item.action_recommendations &&
                                                item.action_recommendations.map(
                                                  (element) => (
                                                    <GridContainer
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                    >
                                                      <GridItem
                                                        xs={12}
                                                        sm={12}
                                                        md={11}
                                                      >
                                                        <strong>
                                                          <CheckOutlined />{" "}
                                                          {element.type_action && element.type_action}{" "}
                                                          :
                                                        </strong>
                                                        {element.text && element.text}
                                                        <br></br>
                                                        <strong>
                                                          {" "}
                                                          Assigned to :  {" "}
                                                        </strong>
                                                        {element.assign_to}
                                                        <br></br>
                                                        <strong>
                                                          {" "}
                                                          Start date : {" "}
                                                        </strong>
                                                        {element.start_date && element.start_date}
                                                        <br></br>
                                                        <strong>
                                                          {" "}
                                                          Due date : {" "}
                                                        </strong>
                                                        {element.endDate && element.endDate}
                                                        <br></br>
                                                        <strong>
                                                          {" "}
                                                          Priority : {RaitingRender(element && element.priorityId)}
                                                        </strong>

                                                        <br></br>

                                                        {/* <strong>
                                                          <CheckOutlined />{" "}
                                                          State :
                                                        </strong>
                                                        {StateRender(element && element.stateId)} */}
                                                      </GridItem>

                                                      <GridItem
                                                        xs={12}
                                                        sm={12}
                                                        md={1}
                                                      >
                                                        <DeleteOutlineIcon
                                                          onClick={() =>
                                                            delete_ActionRecommendation(
                                                              element.id,
                                                              obj.id,
                                                              obj.type
                                                            )
                                                          }
                                                          style={{
                                                            color: "red",
                                                            fontSize: "16px",
                                                          }}
                                                        />
                                                      </GridItem>
                                                    </GridContainer>
                                                  )
                                                )}
                                            </GridContainer>
                                          </TableCell>

                                          <TableCell
                                            className={classes.tableActions}
                                            style={{
                                              borderRight:
                                                "1px solid lightgrey",
                                            }}
                                          >
                                            <Tooltip
                                              id="tooltip-top-start"
                                              title="Remove"
                                              placement="top"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                            >
                                              <IconButton
                                                aria-label="Close"
                                                style={{
                                                  color: "red",
                                                  fontSize: "10px",
                                                }}
                                                className={
                                                  classes.tableActionButton
                                                }
                                                onClick={() =>
                                                  delete_KpiRecommendations(
                                                    item.id,
                                                    obj.id,
                                                    obj.type
                                                  )
                                                }
                                              >
                                                <Close
                                                  className={
                                                    classes.tableActionButtonIcon +
                                                    " "
                                                    // classes.close
                                                  }
                                                  style={{
                                                    fontSize: "18px",
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </TblRecommendationContainer>
                              </div>
                            </GridItem>

                            <Card
                              style={{
                                marginRight: "10px",
                                marginBottom: "10px",
                                width: "97%",
                                marginLeft: "10px",
                              }}
                            >
                              <CardBody>
                                <GridContainer xs={12} sm={12} md={12}>
                                  <GridItem xs={12} sm={12} md={10}>
                                    <p style={{ minHeight: "100px" }}>
                                      <strong
                                        style={{ color: primaryColor[0] }}
                                      >
                                        kPI Analysis
                                      </strong>
                                      :<p>{obj.analysis_of_kpi}</p>
                                    </p>
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={12}
                                    md={2}
                                    style={{ textAlign: "end" }}
                                  >
                                    <EditkPIAnalysisModal
                                      kpiReport_id={props.kpiReport.id}
                                      kpiReport={props.kpiReport}
                                      kpi={obj}
                                      analysis_of_kpi={obj.analysis_of_kpi}
                                    />
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridContainer>
                        ) : null}
                      </GridContainer>
                    </CardBody>
                  </Card>
                ))}

              <Card
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                  width: "97%",
                  marginLeft: "10px",
                }}
              >
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={10}>
                      <p style={{ minHeight: "100px" }}>
                        <strong style={{ color: primaryColor[0] }}>
                          Overall Analysis
                        </strong>
                        :<p>{element.overall_analysis_of_standard}</p>
                      </p>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ textAlign: "end" }}
                    >
                      <EditStandardsOverAnalysisModal
                        kpiReport_id={props.kpiReport.id}
                        kpiReport={props.kpiReport}
                        standard={element}
                        overall_analysis_of_standard={
                          element.overall_analysis_of_standard
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </fieldset>
          ))}
      </GridContainer>
    </div>
  );
}
