import React, { useState } from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import { unwrapResult } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Button from '../../../../components/CustomButtons/Button'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import { deleteAuthority } from '../../../../reduxToolkit/features/AccreditationAuthority/AuthoritySlice'
import EditAuthority from './EditAuthority'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})

((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const AuthorityDropDown = ({ authority, ping, setPing }) => {

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [openEdit, setOpenEdit] = useState(false)
  const [openModalEvidence, setOpenModalEvidence] = useState(false)

  const handleOpenEvidence = () => setOpenModalEvidence(true)
  const { t } = useTranslation()

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleDeleteItem = async (id) => {
    try {
      const resultAction = await dispatch(deleteAuthority(id))
      unwrapResult(resultAction)
      setPing(!ping)
      handleClose()
      openNotificationWithIcon('success', `Deleted successfully`)
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleOpenEditModal = () => {
    setOpenEdit(true)
  }

  const handleCloseEditModal = () => {
    setOpenEdit(false)
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenEditModal()
            handleClose()
          }}
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <EditAuthority
        authority={authority}
        open={openEdit}
        handleClose={handleCloseEditModal}
        handleOpenEditModal={handleOpenEditModal}
        ping={ping}
        setPing={setPing}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={authority.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}

export default AuthorityDropDown
