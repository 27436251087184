import React from "react";
import Button from "../../components/CustomButtons/Button";
// nodejs library to set properties for components

// @material-ui/core components

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import AnnualProgramReportModal from "../Components/AnnualProgramReportModal";
import CreateProgramSurveyModal from "../Components/CreateProgramSurveyModal";
import AccessAndRoleModal from "./AccessAndRoleModal";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownButtonAnnualProgram_PES_SES(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
        simple
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <AnnualProgramReportModal ProgramID={props.ProgramID} />
        <CreateProgramSurveyModal ProgramID={props.ProgramID} />
        <AccessAndRoleModal
          id={props.ProgramID}
          handleDropDownButtonClose={handleClose}
          NameOfComponentUsed="ProgramAss"
          interface='progAss'
          data={props.data}
          staffList={props?.staffList}
          semester_id={props.semester_id}
          page={props.page}
          records={props.rowsPerPage}
          fetchProgAss={props.fetchProgAss}

        />

        {/* <StudentEvaluationSurveyModal ProgramID={props.ProgramID} /> */}
      </StyledMenu>
    </div>
  );
}
