import { unwrapResult } from '@reduxjs/toolkit';
import { Select } from 'antd';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CheckBox from 'devextreme-react/check-box';
import HtmlEditor, { Item, MediaResizing, Toolbar } from 'devextreme-react/html-editor';
import "devextreme/ui/html_editor/converters/markdown";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { addNewHTMLTemplate, fetchHTMLTemplates } from '../../../../reduxToolkit/features/HTMLTemplate/HTMLTemplateSlice';
import './style.css';



const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];
const defaultSelectedItemKeys = ['Html'];

export default function CreateHTMLTemplate({ match }) {


  const { id } = match.params
  const [isMultiline, setIsMultiline] = useState(true)
  const [valueContent, setValueContent] = useState('')
  const [editorValueType, setEditorValueType] = useState('html')
  const [type, setType] = useState("")
  const [name, setName] = useState("")
  const dispatch = useDispatch()

  let history = useHistory();

  const { t } = useTranslation();

  const { Option } = Select;

  //   const HTMLTemplateStatus = useSelector((state) => state.HTMLTemplate.HTMLTemplateStatus)


  //   useEffect(() => {
  //     dispatch(fetchHTMLTemplateById(id)).then(unwrapResult)
  //       .then((htmltemplate) => {
  //         // do additional work
  //         console.log("originalPromiseResult", htmltemplate)
  //         setValueContent(htmltemplate.html)
  //       })
  //   }, [dispatch])

  //   const HTMLTemplate = useSelector((state) => state.HTMLTemplate.HTMLTemplate)
  //   console.log('HTMLTemplate', HTMLTemplate && HTMLTemplate)




  const multilineChanged = (e) => {
    setIsMultiline(e.value)
  }

  const valueChanged = (e) => {
    setValueContent(e.value)
  }

  const valueTypeChanged = (e) => {
    setEditorValueType(e.addedItems[0].text.toLowerCase())
  }

  //   const printDocument = () => {

  //     const doc = new jsPDF();

  //     let parser = new DOMParser();
  //     let parsedHtml = parser.parseFromString(HTMLTemplate && HTMLTemplate.html && HTMLTemplate.html, 'text/html');
  //     console.log("parsedHtml", parsedHtml)
  //     var html = htmlToPdfmake(parsedHtml.body.innerHTML);
  //     console.log("html", html)

  //     const documentDefinition = { content: html };
  //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
  //     pdfMake.createPdf(documentDefinition).open();

  //   }

  const createHTMLTemplate = async () => {
    try {
      const resultAction = await dispatch(
        addNewHTMLTemplate({ type, name, html: valueContent })
      )
      unwrapResult(resultAction)
      history.push(`/citrine/HTMLTemplates`);

    } catch (err) {
      console.error('Failed to save the HTML template: ', err)
    } finally {
      dispatch(fetchHTMLTemplates())
    }
  }
  return (
    <div>
      <Card>
        <CardBody>
          {/* <label>{t('Name *')}</label> */}
          <CustomInput
            labelText={t("Name *")}
            id="name"
            type="text"
            name="name"
            value={name}
            rules={[
              {
                required: true,
                message: 'Please enter a name!',
              },
            ]}
            // formControlProps={{
            //   fullWidth: true
            // }}
            inputProps={{
              onChange: event => {
                setName(event.target.value);
              }
            }}
          />
          <br />
          <br />
          <label>{t('Type *')}</label>
          <br />
          <Select
            showSearch
            // style={{ width: '100%' }}
            placeholder={t("Select a type")}
            optionFilterProp="children"
            value={type}
            onChange={value => {
              setType(value)
              console.log("typevalue", value)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: 200 }}>
            <Option value="course">Course</Option>
            <Option value="plo">PLO</Option>
            <Option value="peo">PEO</Option>
            <Option value="program">Program</Option>

          </Select>

          <br />
          <br />

          <label>{t('Template *')}</label>
          <br />
          <div className="widget-container">
            <HtmlEditor
              height="725px"
              value={valueContent && valueContent}
              valueType={editorValueType}
              onValueChanged={valueChanged}

            >

              <MediaResizing enabled={true} />
              <Toolbar multiline={isMultiline}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item
                  name="size"
                  acceptedValues={sizeValues}
                />
                <Item
                  name="font"
                  acceptedValues={fontValues}
                />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item
                  name="header"
                  acceptedValues={headerValues}
                />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="link" />
                <Item name="image" />
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" />
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" />
                <Item name="deleteTable" />
                <Item name="insertRowAbove" />
                <Item name="insertRowBelow" />
                <Item name="deleteRow" />
                <Item name="insertColumnLeft" />
                <Item name="insertColumnRight" />
                <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>

            <div className="options">
              <div className="caption">Options</div>
              <div className="option">
                <CheckBox
                  text="Multiline toolbar"
                  value={isMultiline}
                  onValueChanged={multilineChanged}
                />
              </div>
            </div>

            {/* <div className="options">
              <ButtonGroup
                onSelectionChanged={valueTypeChanged}
                defaultSelectedItemKeys={defaultSelectedItemKeys}
              >
                <ButtonItem text="Html" />
                <ButtonItem text="Markdown" />
              </ButtonGroup>
              <div className="value-content">
                {valueContent && valueContent}
              </div>
            </div> */}
          </div>
        </CardBody>

      </Card>
      <Button color="primary" onClick={createHTMLTemplate}>Save</Button>

    </div>
  );

}