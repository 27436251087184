import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { Form, Input, notification, Select } from 'antd'
import Button from 'components/CustomButtons/Button.js'
import { Link, useHistory } from 'react-router-dom'
import { addNewAuthorityLevelConfig } from 'reduxToolkit/features/AuthorityLevelConfig/AuthorityLevelConfigSlice'
import {fetchApprovalLevels} from "reduxToolkit/features/Configuration/ApprovalLevel/ApprovalLevelSlice.jsx"
import { fetchRoleConfigs } from 'reduxToolkit/features/RolesConfig/RolesConfigSlice'
import ErrorModal from 'views/Components/ErrorModal'

const { TextArea } = Input

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 14,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const AddAuthorityLevelConfig = () => {

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { Option } = Select

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const roles = useSelector((state) => state.rolesConfig.RoleConfigs)
  const approvalLevels = useSelector((state) => state.approvalLevel.approvalLevels)

  useEffect(() => {
    dispatch(fetchRoleConfigs())
    dispatch(fetchApprovalLevels())
  }, [])

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const onFinish = async (values) => {
    dispatch(addNewAuthorityLevelConfig(values))
      .unwrap()
      .then((res) => {
        handleClose()
        form.resetFields()
        openNotificationWithIcon('success', `Added successfully`)
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }
  const handleClose = () => {
    history.push(`/citrine/configuration/others/authorityLevelPage`)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/authorityLevelPage`}>
                <span>{t('Authority Level Page')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span> {t('Add')} </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <br />
                <Form.Item
                  label={t('Level')}
                  name="level_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please select level.',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Level"

                  >
                    {approvalLevels?.map((approvalLevel) => (
                      <Option key={approvalLevel.id} value={approvalLevel.id}>
                        {approvalLevel.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('Role')}
                  name="role_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please select role.',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Role"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {roles?.map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.role}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <br />

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default AddAuthorityLevelConfig
