import { makeStyles } from '@material-ui/core';
import { Breadcrumb } from 'antd';
import 'antd/dist/antd.css';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader';
import Button from "components/CustomButtons/Button.js";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


import {
    grayColor,
    primaryColor
} from "../../../../assets/jss/material-dashboard-pro-react.js";
import { fetchHTMLTemplates, fetchHTMLTemplatesReports } from '../../../../reduxToolkit/features/HTMLTemplate/HTMLTemplateSlice.jsx';
import MailTempDropDownButton from './MailTempDropDownButton.jsx';
import MailTemplates from './MailTemplates.jsx';
const useStyles = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);



const ListTemplate = (props) => {
    const { t } = useTranslation();
    const cardClasses = useCardStyles();

    return (
        <GridItem xs={12} sm={6} md={4} key="">
            <Card style={{ maxHeight: "200px", minHeight: "200px" }} cardHover>
                <CardHeader>
                    <GridContainer
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <GridItem xs={10} sm={10} md={10}>
                            {/* <Link to={`/citrine/programSpec/${program.id}`}>
                  <h4 className={cardClasses.cardProductTitle}>
                    {program && program.program_degree_name}
                  </h4>
                </Link> */}
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>

                            {/* <ProgramCardDropdownButton
                            // id={program.id}
                            // data={program}
                            // state={program.state}
                            // staffList={staffList}
                            // ping={ping}
                            // setPing={setPing}
                            /> */}
                            <MailTempDropDownButton id={props.item.id} data={props.item} />

                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody smallCardBody>
                    <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                        {t("Name")}:
                        <span style={{ fontWeight: "normal" }}>
                            {" "}
                            {props?.item?.name}{" "}
                        </span>
                    </div>
                    <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                        {t("Type")}:
                        <span style={{ fontWeight: "normal" }}>
                            {" "}
                            {props?.item?.type}{" "}
                        </span>
                    </div>

                </CardBody>
            </Card>
        </GridItem>
    )
}

export default function MaileTempList() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [temp, setTemp] = useState([]);
    const [open, setOpen] = React.useState(false);
    const HTMLTemplates = useSelector((state) => state.HTMLTemplate.HTMLTemplates)
    const {HTMLTemplatesReports} = useSelector((state) => state.HTMLTemplate)
    const HTMLTemplatesStatus = useSelector((state) => state.HTMLTemplate.HTMLTemplatesStatus)
    console.log(HTMLTemplatesReports)
    // useEffect(() => {
    //     dispatch(fetchHTMLTemplates()).then(res => {
    //         console.log(res)
    //         setTemp(res?.payload);

    //     })

    // }, [dispatch])
    useEffect(() => {
        dispatch(fetchHTMLTemplatesReports()).then(res => {
            console.log(res)
            setTemp(res?.payload);

        })

    }, [dispatch])

    if (HTMLTemplatesStatus === 'loading') {
        return <div>Loading...</div>;
    }



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
           
            <GridContainer style={{
                borderBottom: `1px solid  ${grayColor[15]}`,
                borderTop: `1px solid  ${grayColor[15]}`,
            }}
                justify="space-between"
                alignItems="center">
                <GridItem>

                    <Breadcrumb separator=">">
                        <Breadcrumb.Item >
                        <Link to={`/citrine/HTMLTemplates/`}>
                                <span > {t('Configurations')} </span>
                            </Link>
                            

                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/mailetemplist/`}>
                                <span > {t('HtmlTemplateReports')} </span>
                            </Link>
                            
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>
                
                    <Button onClick={handleClickOpen} color="primary">{t("Add")}</Button>
                
                <GridContainer>
                    {/* /citrine/mailtemplates */}
                    {HTMLTemplatesReports?.map((item) => {
                        return (
                            <>
                                {/* <Card
                                        style={{ maxWidth: "380px", minWidth: "380px", height: 150 }}
                                        cardHover
                                        key={item?.id}
                                    >
                                        <CardHeader>
                                            <GridContainer
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="baseline"
                                            >
                                                <GridItem xs={10} sm={10} md={10}>
                                                    <Link to={`/citrine/mailetemplist`}>
                                                        <h4 >
                                                            {item?.name}

                                                        </h4>
                                                        <h4 >
                                                            {item?.type}

                                                        </h4>

                                                    </Link>
                                                </GridItem>


                                            </GridContainer>
                                        </CardHeader>
                                    </Card> */}
                                <ListTemplate item={item} />
                            </>
                        )
                    })}

                    <MailTemplates open={open} onClose={handleClose}/>

                </GridContainer>
            </GridContainer>
        </div>
    )
}
