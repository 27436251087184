import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { UserOutlined } from "@ant-design/icons";
import IconButton from "@material-ui/core/IconButton";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from '@mui/icons-material/Edit';
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, notification, Popconfirm } from "antd";

// core components
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  deleteUser,
  fetchUsers,
  selectedUserById
} from "reduxToolkit/features/user/UserSlice";
import { MediaBaseUrl } from "../../../../assets/utils/accessToken";
import ErrorModal from "../../../Components/ErrorModal";

export default function UserList() {
  const [color, setColor] = React.useState("");
  const [borderColor, setBorderColor] = React.useState("");
  const [userToDelete, setUserToDelete] = React.useState("");
  const [users, setUsers] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchUserList = async () => {
    try {
      const users = await dispatch(fetchUsers()).unwrap();
      setUsers(users);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  console.log("users list", users);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  // const users = useSelector((state) => state.user.users)
  const columns = [
    {
      field: "Avatar",
      headerName: "Avatar",
      width: 70,
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues &&
              cellValues.row &&
              cellValues.row.person_details &&
              cellValues.row.person_details[0] &&
              cellValues.row.person_details[0].avatar && (
                <div>
                  <Avatar
                    src={
                      MediaBaseUrl +
                      `/${cellValues.row.person_details[0].avatar}`
                    }
                  ></Avatar>
                </div>
              )}
            {cellValues &&
              cellValues.row &&
              cellValues.row.person_details &&
              cellValues.row.person_details[0] &&
              !cellValues.row.person_details[0].avatar && (
                <div>
                  <Avatar icon={<UserOutlined />}></Avatar>
                </div>
              )}
          </div>
        );
      },
    },
    {
      field: "first_name",
      headerName: "Fist Name",
      width: 150,
      valueGetter: (cellValues) => {
        return (
          cellValues &&
          cellValues.row &&
          cellValues.row.person_details &&
          cellValues.row.person_details[0] &&
          cellValues.row.person_details[0].first_name
        );
      },
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      valueGetter: (cellValues) => {
        return (
          cellValues &&
          cellValues.row &&
          cellValues.row.person_details &&
          cellValues.row.person_details[0] &&
          cellValues.row.person_details[0].last_name
        );
      },
    },
    {
      field: "username",
      headerName: "UserName",
      width: 150,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      valueGetter: (cellValues) => {
        return (
          cellValues &&
          cellValues.row &&
          cellValues.row.person_details &&
          cellValues.row.person_details[0] &&
          cellValues.row.person_details[0].category
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.8,
      minWidth: 350,
      valueGetter: (cellValues) => {
        return (
          cellValues &&
          cellValues.row &&
          cellValues.row.person_details &&
          cellValues.row.person_details[0] &&
          cellValues.row.person_details[0].email
        );
      },
    },
    {
      field: "",
      width: 120,
      renderCell: (cellValues) => {
        return (
            <>
              <Link to={`/citrine/EditUser/${cellValues.id}`}>
                <IconButton
                    aria-label="edit"
                >
                  <EditIcon size="medium" />
                </IconButton>
              </Link>
              <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => handleDeleteClick(cellValues.id)}
                  okButtonProps={{
                    style: { backgroundColor: "#8F6682", borderColor: "#8F6682" },
                  }}
                  cancelButtonProps={{
                    style: { color: color, borderColor: borderColor },
                    onMouseEnter: () => {
                      setColor("#8f6682");
                      setBorderColor("#8f6682");
                    },
                    onMouseLeave: () => {
                      setColor("");
                      setBorderColor("");
                    },
                  }}
              >
                <IconButton
                    aria-label="delete"
                    onClick={() => setUserToDelete(cellValues.row.username)}
                >
                  <DeleteIcon size="medium" />
                </IconButton>
              </Popconfirm>
            </>
        );
      },
    },
  ];
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 5,
      zIndex: 1000,
    });
  };
  const handleDeleteClick = (id) => {
    dispatch(deleteUser(id)).then(() => {
      openNotificationWithIcon(
        "success",
        `${userToDelete} was deleted successfully`
      );
      fetchUserList();
    });
  };

  const handleToggle = (value) => {
    dispatch(selectedUserById(value.id))
      .then(unwrapResult)
      .then((p) => {
        // history.push(`/citrine/EditUser/${value.id}`);
      });
  };
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/configurations`}>{t("Configurations")}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("Access Control")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/users/`}>{t("Users List")}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Link to={`/citrine/addUser/`}>
        <Button color="primary">{t("  Add")}</Button>
      </Link>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer
                style={{
                  height: 590,
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={users && users}
                  columns={columns}
                  id="username"
                  onRowDoubleClick={handleToggle}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
