import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";

const ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution =
  ({ data }) => {
    const [valueContent, setValueContent] = useState(data.description ?? "");

    return (
      <>
        <h3>
          PROGRAM EDUCATIONAL OBJECTIVES: Consistency of the Program Educational
          Objectives with the Mission of the Institution
        </h3>

        <p className={styles.reportParagraphTitle}>
          The following is a description of how the program educational
          objectives are consistent with the mission of the institution:
        </p>
        <HTMLEditor
          valueContent={valueContent}
          setValueContent={setValueContent}
        />
      </>
    );
  };

export default ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution;
