import {createSlice, nanoid, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    levels: [],
    levelsStatus: 'idle',
    levelStatus: 'idle',
    error: null,
    level: null,
    id: null
}

export const fetchLevels = createAsyncThunk('level/fetchLevels', async (param, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + '/courseAddLevelConfig/',
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
})

export const fetchLevelById = createAsyncThunk('level/fetchLevelById',
    async (id, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(BaseUrl + `/courseAddLevelConfig/${id}/`, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const addNewLevel = createAsyncThunk(
    'level/addNewLevel',
    async (initialLevel, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.post(BaseUrl + `/courseAddLevelConfig/create/`, initialLevel, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })


export const updateLevel = createAsyncThunk(
    'level/updateLevel',
    async (data, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.put(BaseUrl + `/courseAddLevelConfig/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const deleteLevel = createAsyncThunk('level/deleteLevel', async (id, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.delete(BaseUrl + `/courseAddLevelConfig/${id}/delete/`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
})

const levelsSlice = createSlice({
    name: ' level',
    initialState,
    extraReducers: {
        [fetchLevels.pending]: (state, action) => {
            state.levelsStatus = 'loading'
        },
        [fetchLevels.fulfilled]: (state, action) => {
            state.levelsStatus = 'succeeded'
            state.levels = action.payload
        },
        [fetchLevels.rejected]: (state, action) => {
            state.levelsStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [fetchLevelById.pending]: (state, action) => {
            state.levelstatus = 'loading'
        },
        [fetchLevelById.fulfilled]: (state, action) => {
            state.levelstatus = 'succeeded'
            state.level = action.payload
        },
        [fetchLevelById.rejected]: (state, action) => {
            state.levelstatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [addNewLevel.fulfilled]: (state, action) => {
            state.levels.push(action.payload)
        },
        [addNewLevel.rejected]: (state, action) => {
          state.levelstatus = 'failed'
          if (action.payload) {
            state.error = action.payload
          } else {
            state.error = action.error
          }
        },

        [deleteLevel.fulfilled]: (state, action) => {},
        [deleteLevel.rejected]: (state, action) => {
          state.levelstatus = 'failed'
          if (action.payload) {
            state.error = action.payload
          } else {
            state.error = action.error
          }
        },

        [updateLevel.fulfilled]: (state, action) => {
            const result = state.levels.filter(level => level.id !== action.payload.id);
            result.push(action.payload)
            state.levels = result
        },
        [updateLevel.rejected]: (state, action) => {
            state.levelstatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },
    },
})


export default levelsSlice.reducer

export const selectAllLevels = state => state.level.levels
