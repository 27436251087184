import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const Guidance = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>FACILITIES: Guidance</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of how students in the program are
        provided appropriate guidance regarding the use of the tools, equipment,
        computing resources, and laboratories:
      </p>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
    </>
  );
};

export default Guidance;
