import {useDispatch} from "react-redux";
import {clearState, getCurrentUserByToken} from "../reduxToolkit/features/user/UserSlice";

const useAuth = () => {

    const dispatch = useDispatch();

    const checkStorage = () => {
        const token = localStorage.getItem('token');
        const id = localStorage.getItem('userId');
        return !!(token && id)
    };

    const getCurrentUser = async () => {
        if (checkStorage()) {
           await dispatch(getCurrentUserByToken()).unwrap()
        }
    };

   const logout = () => {
       localStorage.removeItem('token');
       dispatch(clearState());
       window.location.replace('/');
   };

   return { getCurrentUser, logout, checkStorage };
};

export default useAuth;
