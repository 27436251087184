import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

export const synchSpecCoursesWithLMS = createAsyncThunk(
  "LMSIntegration/synchSpecCoursesWithLMS",
  async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().LMSIntegration;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/importSpecLms/airflow_getCourseSpec/`,
        null,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const synchSpecAssessmentMethodsWithLMS = createAsyncThunk(
  "LMSIntegration/synchSpecAssessmentMethodsWithLMS",
  async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().LMSIntegration;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/importSpecLms/airflow_getAssessmentMethodSpec/`,
        null,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const synchSpecCLOsWithLMS = createAsyncThunk(
  "LMSIntegration/synchSpecCLOsWithLMS",
  async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().LMSIntegration;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/importSpecLms/airflow_getCloSpec/`,
        null,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const synchDataWithLMS = createAsyncThunk(
  "LMSIntegration/synchDataWithLMS",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const synchSpecCoursesDagState = await dispatch(
        synchSpecCoursesWithLMS()
      ).unwrap();
      const synchSpecAssessmentMethodsDagState = await dispatch(
        synchSpecAssessmentMethodsWithLMS()
      ).unwrap();
      const synchSpecCLOsDagState = await dispatch(
        synchSpecCLOsWithLMS()
      ).unwrap();
      if (
        synchSpecCoursesDagState.dag_state === "success" &&
        synchSpecAssessmentMethodsDagState.dag_state === "success" &&
        synchSpecCLOsDagState.dag_state === "success"
      ) {
        return "succeeded";
      } else {
        return "failed";
      }
    } catch (err) {
      let error = err; // cast the error for access
      // if (!error.response) {
      //   throw err;
      // }
      return rejectWithValue(error);
    }
  }
);

export const importDataFromLMS = createAsyncThunk(
  "LMSIntegration/importDataFromLMS",
  async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().LMSIntegration;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/importSpecLms/last-created/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const loadLMSImportedData = createAsyncThunk(
  "LMSIntegration/loadLMSImportedData",
  async (data, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().LMSIntegration;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/importSpecLms/airflow_loadCourseSpecData/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("response.data", response.data?.dag_state === "success");

      if (response.data?.dag_state === "success") {
        return "succeeded";
      } else {
        return "failed";
      }
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const synchMarksWithLMS = createAsyncThunk(
  "LMSIntegration/synchMarksWithLMS",
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().LMSIntegration;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/importAssessmentLms/airflow_getNote/`,
        null,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.data?.dag_state === "success") {
        return "succeeded";
      } else {
        return "failed";
      }
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const loadLMSImportedMarks = createAsyncThunk(
  "LMSIntegration/loadLMSImportedMarks",
  async (data, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().LMSIntegration;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/importAssessmentLms/airflow_loadNote/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.data?.dag_state === "success") {
        return "succeeded";
      } else {
        return "failed";
      }
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const LMSIntegrationSlice = createSlice({
  name: "LMSIntegration",
  initialState: {
    synchSpecCoursesState: null,
    sychSpecAssessmentMethodsState: null,
    synchSpecCLOsState: null,
    synchSpecDataState: null,
    importedLMSData: null,
    loading: "idle",
    pullingCourses: "idle",
    pullingAssessments: "idle",
    pullingCLOs: "idle",
    pullDataLoading: "idle",
    currentRequestId: undefined,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(synchSpecCoursesWithLMS.pending, (state, action) => {
        if (state.loading === "idle") {
          state.pullingCourses = "pending";
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(synchSpecCoursesWithLMS.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.pullingCourses = "idle";
          state.loading = "idle";
          state.synchSpecCoursesState = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(synchSpecCoursesWithLMS.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.pullingCourses = "idle";
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      .addCase(synchSpecAssessmentMethodsWithLMS.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.pullingAssessments = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(synchSpecAssessmentMethodsWithLMS.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.pullingAssessments = "idle";
          state.sychSpecAssessmentMethodsState = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(synchSpecAssessmentMethodsWithLMS.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.pullingAssessments = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      .addCase(synchSpecCLOsWithLMS.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.pullingCLOs = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(synchSpecCLOsWithLMS.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.pullingCLOs = "idle";
          state.synchSpecCLOsState = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(synchSpecCLOsWithLMS.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.pullingCLOs = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      .addCase(synchDataWithLMS.pending, (state, action) => {
        if (state.pullDataLoading === "idle") {
          state.pullDataLoading = "pending";
        }
      })
      .addCase(synchDataWithLMS.fulfilled, (state, action) => {
        if (state.pullDataLoading === "pending") {
          state.pullDataLoading = "idle";
          state.synchSpecDataState = action.payload;
        }
      })
      .addCase(synchDataWithLMS.rejected, (state, action) => {
        if (state.pullDataLoading === "pending") {
          state.pullDataLoading = "idle";
        }
      })

      .addCase(importDataFromLMS.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(importDataFromLMS.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.importedLMSData = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(importDataFromLMS.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      .addCase(loadLMSImportedData.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(loadLMSImportedData.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(loadLMSImportedData.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
        }
      })
      .addCase(synchMarksWithLMS.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(synchMarksWithLMS.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(synchMarksWithLMS.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
        }
      })

      .addCase(loadLMSImportedMarks.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(loadLMSImportedMarks.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
        }
      })
      .addCase(loadLMSImportedMarks.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
        }
      });
  },
});

export default LMSIntegrationSlice.reducer;
