import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import pagesHeaderstyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle";
import { grayColor, primaryColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchPrograms } from '../../../../reduxToolkit/features/program/ProgramSpecSlice';
import ArchivedProgramCardDropdownButton from "../../../Components/archivedProgramCardDropdownButton";




const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);


const ProgramExcerpt = ({ program }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();

  return (

    <GridItem xs={12} sm={12} md={4} key={program.id} >
      <Card style={{ maxWidth: "380px", minWidth: "380px", maxHeight: "380px", minHeight: "380px" }} cardHover >
        <CardHeader>
          <GridContainer direction="row"
            justifyContent="space-between"
            alignItems="baseline">
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/programSpec/${program.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {program.program_degree_name}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <ArchivedProgramCardDropdownButton id={program.id} program={program} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>{t('Code:')} <span style={{ fontWeight: "normal" }}> {program.code} </span></div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>{t('Category:')} <span style={{ fontWeight: "normal" }}>{program.category_of_the_program}</span></div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>{t('Total levels:')} <span style={{ fontWeight: "normal" }}>{program.total_levels}</span></div>


          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>{t('Accredited by:')} <span style={{ fontWeight: "normal" }}>{program.accredited_by}</span></div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>{t('Accredited campus locations:')} <span style={{ fontWeight: "normal" }}>{program.accredited_campus_locations}</span></div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>{t('University:')} <span style={{ fontWeight: "normal" }}>{program.university_detail && program.university_detail[0].name}</span></div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}> {t('Colleges:')}
            {program.college_detail && program.college_detail.map((college) => {
              return <div style={{ fontWeight: "normal" }}> {college.name} </div>

            }
            )}
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}> {t('Departments:')}
            {program.department_detail && program.department_detail.map((department) => {
              return <div style={{ fontWeight: "normal" }}> {department.name} </div>

            }
            )}
          </div>


        </CardBody>
        <CardFooter>
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="center">
            <GridItem> <span style={{ color: (program.state) === "Draft" ? grayColor[1] : "#FFCB3B" }}>{program.state}</span> </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

export default function ProgramsArchive() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)


  const dispatch = useDispatch()

  const programsStatus = useSelector(state => state.programSpec.programsStatus)
  console.log('programsStatus', programsStatus)
  useEffect(() => {
    if (programsStatus === 'idle') {
      dispatch(fetchPrograms())
    }
  }, [
    programsStatus,
    dispatch])

  const programs = useSelector(state => state.programSpec.programs)
  const error = useSelector(state => state.programSpec.error)
  console.log(programs, "programs")
  let content

  const searchButton =
    navBarClasses.top +
    " " +
    navBarClasses.searchButton

  if (programsStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (programsStatus === 'succeeded') {
    content = programs && programs.map(program => (
      !program.active &&
      <ProgramExcerpt key={program.id} program={program} />
    ))
  } else if (programsStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer
        alignItems="center"
        style={{
          borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
        }}>
        <GridItem>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t('Programs')} </span>
          </Link>

        </GridItem>

      </GridContainer>

      <GridContainer
        justify="flex-end"
        alignItems="center">

        <GridItem>
          <CustomInput
            formControlProps={{
              className: navBarClasses.top + " " + navBarClasses.search
            }}
            inputProps={{
              placeholder: "Search",
              "aria-label": "Search",
              className: navBarClasses.searchInput
            }}
          />
          <Button
            color="white"
            aria-label="Search"
            justIcon
            round
            className={searchButton}
          >
            <Search className={navBarClasses.headerLinksSvg + " " + navBarClasses.searchIcon} />
          </Button>
        </GridItem>
      </GridContainer>

      <GridContainer >
        {content}
      </GridContainer>
      {/* <Link to={"/citrine/programs/test"} >
    <p>here</p>
    </Link> */}
    </div>
  );
}













