import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  KPIs: [],
  KPIsStatus: "idle",
  KPI: null,
  KPIStatus: "idle",
  error: null,
};

export const fetchKPIs = createAsyncThunk(
  "KPIConfig/fetchKPIs",
  async (param, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/kpiConfig/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response);
    }
  }
);

export const fetchKPIConfigById = createAsyncThunk(
  "KPIConfig/fetchKPIConfigById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/kpiConfig/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response);
    }
  }
);

export const addNewKPIConfig = createAsyncThunk(
  "KPIConfig/addNewKPIConfig",
  async (initialKPIConfig, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/kpiConfig/create/`,
        initialKPIConfig,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response);
    }
  }
);

export const updateAnalysis_of_kpi = createAsyncThunk(
  "KPIConfig/updateAnalysis_of_kpi",
  async (initialKPIConfig, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/kpiReport/analysis_kpi/`,
        initialKPIConfig,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response);
    }
  }
);

export const updateKPIConfig = createAsyncThunk(
  "KPIConfig/updateKPIConfig",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/kpiConfig/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const deleteKPIConfig = createAsyncThunk(
  "KPIConfig/deleteKPIConfig",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/kpiConfig/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

const KPIConfigSlice = createSlice({
  name: "KPIConfig",
  initialState,
  extraReducers: {
    [fetchKPIs.pending]: (state, action) => {
      state.KPIsStatus = "loading";
    },
    [fetchKPIs.fulfilled]: (state, action) => {
      state.KPIsStatus = "succeeded";
      state.KPIs = action.payload;
    },
    [fetchKPIs.rejected]: (state, action) => {
      state.KPIsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchKPIConfigById.pending]: (state, action) => {
      state.KPIStatus = "loading";
    },
    [fetchKPIConfigById.fulfilled]: (state, action) => {
      state.KPIStatus = "succeeded";
      state.KPI = action.payload;
    },
    [fetchKPIConfigById.rejected]: (state, action) => {
      state.KPIStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewKPIConfig.fulfilled]: (state, action) => {
      state.KPIs.push(action.payload);
    },
    [addNewKPIConfig.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [updateAnalysis_of_kpi.fulfilled]: (state, action) => {},
    [updateAnalysis_of_kpi.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});

export default KPIConfigSlice.reducer;
