import React, { useEffect, useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCourseById } from '../../../../../reduxToolkit/Course/coursesSpecSlice'
//import { selectAllColleges, fetchColleges, getId, deleteCollege } from '../../reduxToolkit/features/college/CollegesSlice'
// @material-ui/icons
import CustomInput from 'components/CustomInput/CustomInput.js'
// core components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Link } from 'react-router-dom'
//import DropDownButtonAdd from 'views/Components/DropDownButtonAdd';
import Search from '@material-ui/icons/Search'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import DropDownButtonClo from '../../../../Components/DropDownButtonClo'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

import { EyeOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import ErrorModal from '../../../../Components/ErrorModal'
import { Badge, Breadcrumb } from 'antd'

const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
const useStyles = makeStyles(styles)

const useButtonStyles = makeStyles(buttonStyles)

const ClosListExcerpt = ({
  clo,
  key,
  course,
  id,
  disabledStatus,
  isEditor,
  ping,
  setPing,
}) => {
  console.log(clo)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const buttonClasses = useButtonStyles()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const [showMore, setShowMore] = useState(false)
  let data = ''
  const [smallModal, setSmallModal] = React.useState(false)

  return (
    <GridContainer xs={12} sm={12} md={4}>
      <GridItem key={clo.id} xs={12} sm={12} md={12}>
        <Card style={{ height: 200, textAlign: 'start' }}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={10}>
                <Link to={`/citrine/courseCloDetails/${clo.id}`}>
                  <h4>
                    <a
                      style={{ color: 'orange', textAlign: 'start' }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Link to={`/citrine/courseCloDetails/${clo.id}`}>
                        {clo.code}
                      </Link>
                    </a>
                  </h4>
                </Link>
                Aligned PLO : {clo && clo.ploSpec_details && clo.ploSpec_details.length ? (

                  clo.ploSpec_details.map((el) => (
                    <Badge
                      className="site-badge-count-109"
                      count={el.name}
                      style={{ backgroundColor: '#52c41a' }}
                    />
                  ))
                ) : (
                  <span></span>
                )}
                {/* {clo &&
                  clo.ploSpec_details &&
                  clo?.ploSpec_details.length &&
                  'Aligned PLo: ' +
                    clo.ploSpec_details.map(({ name }) => (
                      <Badge
                        className="site-badge-count-109"
                        count={name}
                        style={{ backgroundColor: '#52c41a' }}
                      />
                    ))} */}
              </GridItem>
              {isEditor && (
                <GridItem xs={12} sm={12} md={2}>
                  {disabledStatus ? (
                    <p></p>
                  ) : (
                    <DropDownButtonClo
                      clo={clo}
                      clo_id={clo.id}
                      courseID={course && course.id}
                      ping={ping}
                      setPing={setPing}
                    />
                  )}
                </GridItem>
              )}
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {showMore ? clo.title : `${clo.description.substring(0, 60)}`}
                {clo.description.length > 60 && (
                  <div>
                    <Button
                      className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
                      aria-label="showMore"
                      color="white"
                      justIcon
                      onClick={() => setSmallModal(true)}
                    >
                      <EyeOutlined />
                    </Button>
                    <Dialog
                      classes={{
                        root: classes.center + ' ' + classes.modalRoot,
                        paper: classes.modal + ' ' + classes.modalSmall,
                      }}
                      open={smallModal}
                      //TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setSmallModal(false)}
                      aria-labelledby="small-modal-slide-title"
                      aria-describedby="small-modal-slide-description"
                    >
                      <DialogTitle
                        id="small-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                      ></DialogTitle>
                      <DialogContent
                        id="small-modal-slide-description"
                        className={
                          classes.modalBody + ' ' + classes.modalSmallBody
                        }
                      >
                        <h5>{t('Clo Description')}</h5>

                        <p className={cardClasses.cardProductDesciprion}>
                          {clo.description}
                        </p>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setSmallModal(false)
                          }}
                          color="info"
                        >
                          {t('Close')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </GridContainer>
  )
}

export default function CourseSpecClosList(props) {
  const buttonClasses = useButtonStyles()
  const { t } = useTranslation()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const classes = useStyles()
  const [isEditor, setEditor] = useState(true)
  const cardClasses = useCardStyles()
  const id = props.id
  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [disabledStatus, setDisabledStatus] = useState(false)
  const [ping, setPing] = useState(false)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchCourseByIdMethod = async () => {
    try {
      const resultAction = await dispatch(fetchCourseById(id)).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    fetchCourseByIdMethod()
    if (localStorage.getItem('CourseState') != 'Draft') {
      setDisabledStatus(true)
    }
  }, [dispatch, ping])

  const course = useSelector((state) => state.CourseSpec.courseSpecification)
  console.log('course', course)
  const courseSpecificationStatus = useSelector(
    (state) => state.CourseSpec.courseSpecificationStatus,
  )
  const [search, setSearch] = useState('')

  let content

  if (courseSpecificationStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (courseSpecificationStatus === 'succeeded') {
    if (course.closSpec && course.closSpec.length) {
      content = course.closSpec
        .filter((e) => e.title.toLowerCase().includes(search.toLowerCase()))
        .map((clo) => (
          <ClosListExcerpt
            key={clo.id}
            clo={clo}
            id={id}
            course={course}
            disabledStatus={disabledStatus}
            isEditor={isEditor}
            ping={ping}
            setPing={setPing}
          />
        ))
    } else {
      content = <h5>{t('This Course has no CLOs to display!')}</h5>
    }
  }

  return (
    <div>
      <GridContainer alignItems="baseline">
        {isEditor && (
          <GridItem xs={7} sm={7} md={7}>
            <Link to={`/citrine/CreateCourseClo/course/${props.id}/clo/:idClo`}>
              {!disabledStatus && <Button color="primary">{t('Add')}</Button>}
            </Link>
          </GridItem>
        )}
        <GridItem xs={4} sm={4} md={4}>
          <CustomInput
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value)
              },
              value: search,
              placeholder: 'Search',
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>

        <GridItem xs={1} sm={1} md={1}>
          <Button color="white" aria-label="edit" justIcon round>
            <Search />
          </Button>
        </GridItem>
      </GridContainer>

      <GridContainer xs={12} sm={12} md={12}>
        <GridContainer>{content}</GridContainer>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </GridContainer>
    </div>
  )
}
