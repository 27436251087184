import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

export const fetchSectors = createAsyncThunk(
    "sectors/fetchSectors",
    async (_, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().sectors
        if (loading !== 'pending' || requestId !== currentRequestId) {
            return
        }
        const token = localStorage.getItem("token");
        // try {
        const response = await axios.get(
            BaseUrl + `/sectorSpec/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;
        // } catch (err) {
        //     console.log("err",err)
        //     let error = err; // cast the error for access
        //     console.log("error",error)

        //     if (!error.response) {
        //         throw err;
        //     }
        //     return rejectWithValue(error?.response?.data);
        // }
    }
);

export const fetchSector = createAsyncThunk(
    "sectors/fetchSector",
    async (id, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().sectors
        if (loading !== 'pending' || requestId !== currentRequestId) {
            return
        }
        const token = localStorage.getItem("token");
        // try {
        const response = await axios.get(
            BaseUrl + `/sectorSpec/${id}`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;
        // } catch (err) {
        //     let error = err; // cast the error for access
        //     if (!error.response) {
        //         throw err;
        //     }
        //     return rejectWithValue(error?.response?.data);
        // }
    }
);

export const createSector = createAsyncThunk(
    "sectors/createSector",
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                BaseUrl + `/sectorSpec/create/`,
                data,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
);

export const updateSector = createAsyncThunk(
    "sectors/updateSector",
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        try {
            const { id, ...fields } = data

            const response = await axios.put(
                BaseUrl + `/sectorSpec/${id}/edit/`,
                fields,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
);

export const deleteSector = createAsyncThunk(
    "sectors/deleteSector",
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        const response = await axios.delete(
            BaseUrl + `/sectorSpec/${id}/delete/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;

    }
);
const SectorsSlice = createSlice({
    name: "sectors",
    initialState: {
        sectors: [],
        sector: null,
        loading: "idle",
        currentRequestId: undefined,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSectors.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchSectors.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.sectors = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchSectors.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        builder
            .addCase(fetchSector.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchSector.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.sector = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchSector.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        builder.addCase(createSector.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        builder.addCase(updateSector.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        builder.addCase(deleteSector.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
    },
});

export default SectorsSlice.reducer;
