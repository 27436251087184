import {createSlice, nanoid, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    areas: [], areasStatus: 'idle', areaStatus: 'idle', error: null, area: null, id: null
}

export const fetchAreas = createAsyncThunk('areaSpec/fetchAreas', async (param, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + '/areaSpec/', {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
        return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
})

export const fetchAreaById = createAsyncThunk('areaSpec/fetchAreaById', async (id, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/areaSpec/${id}/`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
})

export const addNewArea = createAsyncThunk('areaSpec/addNewArea', async (initialArea, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.post(BaseUrl + `/areaSpec/create/`, initialArea, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        return response.data;
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
});
export const updateArea = createAsyncThunk('areaSpec/updateArea', async (data, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.put(BaseUrl + `/areaSpec/${data.id}/edit/`, data.modified, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
})

export const additionalUpdateArea = createAsyncThunk('areaSpec/additionalUpdateArea', async (data, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.put(BaseUrl + `/areaSpec/${data.id}/update/`, data.updatedField, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
})

export const deleteArea = createAsyncThunk('areaSpec/deleteArea', async (id, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios.delete(BaseUrl + `/areaSpec/${id}/delete/`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response);
    }
})

const areasSlice = createSlice({
    name: ' areaSpec', initialState, extraReducers: {
        [fetchAreas.pending]: (state, action) => {
            state.areasStatus = 'loading'
        },
        [fetchAreas.fulfilled]: (state, action) => {
            state.areasStatus = 'succeeded'
            state.areas = action.payload
        },
        [fetchAreas.rejected]: (state, action) => {
            state.areasStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [fetchAreaById.pending]: (state, action) => {
            state.areaStatus = 'loading'
        },
        [fetchAreaById.fulfilled]: (state, action) => {
            state.areaStatus = 'succeeded'
            state.area = action.payload
        },
        [fetchAreaById.rejected]: (state, action) => {
            state.areaStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [addNewArea.fulfilled]: (state, action) => {
          state.areas.push(action.payload)
        },
        [addNewArea.rejected]: (state, action) => {
          state.areaStatus = 'failed'
          if (action.payload) {
            state.error = action.payload
          } else {
            state.error = action.error
          }
        },

        [deleteArea.fulfilled]: (state, action) => {},
        [deleteArea.rejected]: (state, action) => {
          state.areaStatus = 'failed'
          if (action.payload) {
            state.error = action.payload
          } else {
            state.error = action.error
          }
        },

        [updateArea.fulfilled]: (state, action) => {
            const result = state.areas.filter(area => area.id !== action.payload.id);
            result.push(action.payload)
            state.areas = result
        },
        [updateArea.rejected]: (state, action) => {
            state.areaStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [additionalUpdateArea.fulfilled]: (state, action) => {},
        [additionalUpdateArea.rejected]: (state, action) => {
            state.areaStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },
    },

    reducers: {
        getId: (state, action) => {
            state.id = action.payload
        },

        areaUpdated(state, action) {
            const {id, name, code, description, period, start_date, end_date} = action.payload
            const existingArea = state.areas.find(area => area.id === id)
            if (existingArea) {
                existingArea.name = name
                existingArea.code = code
                existingArea.description = description
                existingArea.period = period
                existingArea.start_date = start_date
                existingArea.end_date = end_date

            }
        }
    }
})

export const {areaUpdated, getId} = areasSlice.actions

export default areasSlice.reducer

export const selectAllAreas = state => state.area.areas
