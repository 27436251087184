import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const FacultyWorkload = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const columns = [
    {
      title: "Faculty Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "FT or PT",
      dataIndex: "employmentStatus",
      key: "employmentStatus",
      width: 150,
    },
    {
      title: "Classes Taught (Course No./Credit Hrs.) Term and Year",
      dataIndex: "classes",
      key: "classes",
      width: 200,
    },
    {
      title: "Program Activity Distribution",
      children: [
        {
          title: "Teaching",
          dataIndex: "teachingActivitiesPercentage",
          key: "teachingActivitiesPercentage",
          width: 100,
        },
        {
          title: "Research or Scholarship",
          dataIndex: "researchOrScholarshipActivitiesPercentage",
          key: "researchOrScholarshipActivitiesPercentage",
          width: 100,
        },
        {
          title: "Other",
          dataIndex: "otherActivitiesPercentage",
          key: "otherActivitiesPercentage",
          width: 100,
        },
      ],
    },
    {
      title: "% of Time Devoted to the Program",
      dataIndex: "timePercentageDevotedToTheProgram",
      key: "timePercentageDevotedToTheProgram",
      width: 150,
    },
  ];

  return (
    <>
      <h3>FACULTY: Faculty Workload</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of Faculty Workload in terms of workload
        expectations or requirements:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is a Faculty Workload Summary in the form of a table
        including Classes Taught and Program Activity Distribution:
      </p>
      <div className={styles.reportCart}>
        <AntDDataGrid
          columns={columns}
          data={data?.FacultyWorkloadTable}
          scroll
          bordered
          size="middle"
        />
      </div>
    </>
  );
};

export default FacultyWorkload;
