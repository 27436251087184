import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  GraduateAttributes: [],
  AllGraduateAttributes: [],
  GraduateAttributeStatus: "idle",
  GraduateAttributesStatus: "idle",
  error: null,
  GraduateAttribute: null,
  GraphGa: null,
};

export const fetchGraphGa = createAsyncThunk(
  "GraduateAttribute/fetchGraphGa",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/program/${id}/graphic/ga/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    console.log(response.data, "response.data fetchGraphGa");

    return response.data.data_ga;
  }
);
export const fetchGraduateAttributes = createAsyncThunk(
  "GraduateAttribute/fetchGraduateAttributes",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/graduateAttribute/?program_id=${id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    // console.log(response.data,'response.data')
    return response.data;
  }
);

export const fetchAllGAs = createAsyncThunk(
  "GraduateAttribute/fetchAllGAs",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/graduateAttribute/all/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    // console.log(response.data,'response.data')
    return response.data;
  }
);

export const fetchGraduateAttributeById = createAsyncThunk(
  "GraduateAttribute/fetchGraduateAttributeById",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/graduateAttribute/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    // console.log(response.data,'response.data')

    return response.data;
  }
);
export const addNewGraduateAttribute = createAsyncThunk(
  "GraduateAttribute/addNewGraduateAttribute",
  async (initialPlo) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/graduateAttribute/create/`,
      initialPlo,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const updateGraduateAttribute = createAsyncThunk(
  "GraduateAttribute/updateGraduateAttribute",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/graduateAttribute/${data.id}/edit/`,
        data.updatedGraduateAttributeSpec,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedGraduateAttribute");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the GraduateAttribute: ", error);
    }
  }
);

export const updateGraduateAttributeFieldByField = createAsyncThunk('GraduateAttribute/updateGraduateAttributeFieldByField', async (data, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.put(BaseUrl + `/graduateAttribute/${data.id}/update/`,
      data.updatedGraduateAttributeSpec,

      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})

export const updateGraduateAttributePlanning = createAsyncThunk(
  'GraduateAttribute/updateGraduateAttributePlanning',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/graduateAttribute/${data.id}/update/planning/`, data.updated, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const deleteGraduateAttribute = createAsyncThunk(
  "GraduateAttribute/deleteGraduateAttribute",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/graduateAttribute/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchScoreGraduateAttribute = createAsyncThunk(
  "ploIndicator/fetchScoreGraduateAttribute",
  async (initialGraduateAttribute) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/graduateAttribute/score`,
      initialGraduateAttribute,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);




export const Score_Question_GA = createAsyncThunk(
  "GraduateAttribute/Score_Question_GA",
  async (Object) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/graduateAttribute/score_plo/`,
      Object,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

const GraduateAttributeSlice = createSlice({
  name: " GraduateAttribute",
  initialState,
  extraReducers: {
    [fetchGraphGa.pending]: (state, action) => {
      state.GraduateAttributeStatus = "loading";
    },
    [fetchGraphGa.fulfilled]: (state, action) => {
      state.GraduateAttributeStatus = "succeeded";
      state.GraphGa = action.payload;
    },
    [fetchGraphGa.rejected]: (state, action) => {
      state.GraduateAttributeStatus = "failed";
      state.error = action.payload;
    },
    [fetchAllGAs.pending]: (state, action) => {
      state.GraduateAttributesStatus = "loading";
    },
    [fetchAllGAs.fulfilled]: (state, action) => {
      state.GraduateAttributesStatus = "succeeded";
      state.AllGraduateAttributes = action.payload;
    },
    [fetchAllGAs.rejected]: (state, action) => {
      state.GraduateAttributesStatus = "failed";
      state.error = action.payload;
    },



    [fetchGraduateAttributes.pending]: (state, action) => {
      state.GraduateAttributesStatus = "loading";
    },
    [fetchGraduateAttributes.fulfilled]: (state, action) => {
      state.GraduateAttributesStatus = "succeeded";
      state.GraduateAttributes = action.payload;
    },
    [fetchGraduateAttributes.rejected]: (state, action) => {
      state.GraduateAttributesStatus = "failed";
      state.error = action.payload;
    },



    //  fetched GraduateAttributeSpec by ID
    [fetchGraduateAttributeById.pending]: (state, action) => {
      state.GraduateAttributeStatus = "loading";
    },
    [fetchGraduateAttributeById.fulfilled]: (state, action) => {
      state.GraduateAttributeStatus = "succeeded";
      state.GraduateAttribute = action.payload;
    },
    [fetchGraduateAttributeById.rejected]: (state, action) => {
      state.GraduateAttributeStatus = "failed";
      state.error = action.payload;
    },

    //  ADD  GraduateAttributeSpec
    [addNewGraduateAttribute.fulfilled]: (state, action) => {
      state.GraduateAttributes.push(action.payload);
    },
    //  DELETE  GraduateAttributeSpec
    [deleteGraduateAttribute.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    [updateGraduateAttribute.fulfilled]: (state, action) => {
      console.log(
        "action.payload after update fulfilled from Graduate Attribute slice",
        action.payload
      );
    },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { programAdded } = GraduateAttributeSlice.actions;

export default GraduateAttributeSlice.reducer;
