import React, {useRef, useState} from "react";

import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Menu from "@mui/material/Menu";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import { useTranslation } from "react-i18next";
import AccessAndRoleModal from "./AccessAndRoleModal";
import { deleteAnnualProgramReport, fetchAnnualProgramReports } from '../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice'

const useButtonStyles = makeStyles(buttonStyles);

export default function DropDownButtonAnnualProgramReport(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const buttonClasses = useButtonStyles();
  const anchorRef = useRef(null);

  const delete_AnnualProgramReport = (id) => {
    try {
      dispatch(deleteAnnualProgramReport(id))
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchAnnualProgramReports())
            .then(unwrapResult)
            .then((AnnualProgramReports) => {
              props.orderReportsAccordingToStates()
            });
        });
    } catch (err) {
      console.log("Failed to delete  the delete Annual Program : ", err);
    } finally {
    }
  };

  /******************************************************************
    *                          Handle Menu
    *****************************************************************/
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosebtn = () => {
    setAnchorEl(null);
  };

  const op_en = Boolean(anchorEl);

  return (
    <span>
      <Button
        info
        round
        ref={anchorRef}
        aria-controls={op_en ? "basic-menu" : undefined}
        aria-expanded={op_en ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="true"
        onClick={handleClick}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
        id="basic-button"
      >
        <MoreVert />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={op_en}
        onClose={handleClosebtn}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <AccessAndRoleModal
          id={props.id}
          handleDropDownButtonClose={handleClosebtn}
          NameOfComponentUsed="AP-Report"
          interface='reports'
          disabled={false}
          data={props.data}
          staffList={props.staffList}
        />
        <MenuList id="basic-menu">
          <MenuItem>
            <p onClick={() => delete_AnnualProgramReport(props.id)}>
              {t("Delete")}
            </p>
          </MenuItem>
        </MenuList>
      </Menu>
    </span>
  );
}
