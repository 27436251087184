import React from 'react'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import ScoreIcon from '@material-ui/icons/Score';
import 'react-circular-progressbar/dist/styles.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import "./styleCircularCard.css"
import {
  primaryColor,
  infoColor,
  warningColor,
  whiteColor,
  greyColor
} from "assets/jss/material-dashboard-pro-react.js";

export default function CircularProgressbarCard(props) {

  return (
    <GridContainer>
      <GridItem xs={3} sm={3} md={3} lg={3} xl={3}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <div style={{ width: '60px', height: "60px" }}>
            <CircularProgressbar
              value={props.item?.score_clo_question === null ? 0 : props.item?.score_clo_question.toFixed(0)}
              text={`${props.item?.score_clo_question === null ? 0 : props.item?.score_clo_question.toFixed(0)}%`}
              strokeWidth={8}
              styles={buildStyles({
                trailColor: "#EEEEEE",
                pathColor: infoColor[0]
              })}
            />
          </div>
          <div>
            <span className="sizing">Score</span>
          </div>
        </div>
      </GridItem>


      <GridItem xs={3} sm={3} md={3} lg={3} xl={3}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <div style={{ width: '60px', height: "60px" }}>
            <CircularProgressbar
              value={props.item.clo_coverage === null ? 0 : props.item?.clo_coverage.toFixed(0)}
              text={`${props.item.clo_coverage === null ? 0 : props.item?.clo_coverage.toFixed(0)}`}
              strokeWidth={10}
              styles={buildStyles({
                trailColor: "#EEEEEE",
                pathColor: infoColor[0]
              })}
            />
          </div>
          <div>
            <span className="sizing">Coverage</span>
          </div>
        </div>
      </GridItem>


      <GridItem xs={3} sm={3} md={3} lg={3} xl={3}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <div style={{ width: '60px', height: "60px" }}>
            <CircularProgressbar
              value={props.item.percentage_75 === null ? 0 : props.item?.percentage_75.toFixed(0)}
              text={`${props.item.percentage_75 === null ? 0 : props.item?.percentage_75.toFixed(0)}`}
              strokeWidth={10}
              styles={buildStyles({
                trailColor: "#EEEEEE",
                pathColor: infoColor[0]
              })}
            />
          </div>
          <div>
            <span className="sizing">{`Attainment ${">75%"}`}</span>
          </div>
        </div>
      </GridItem>


      <GridItem xs={3} sm={3} md={3} lg={3} xl={3}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <div style={{ width: '60px', height: "60px" }}>
            <CircularProgressbar
              value={props.item.percentage_80 === null ? 0 : props.item?.percentage_80.toFixed(0)}
              text={`${props.item.percentage_80 === null ? 0 : props.item?.percentage_80.toFixed(0)}`}
              strokeWidth={10}
              styles={buildStyles({
                trailColor: "#EEEEEE",
                pathColor: infoColor[0]
              })}
            />
          </div>
          <div>
            <span className="sizing">{`Attainment ${">80%"}`}</span>
          </div>
        </div>
      </GridItem>

    </GridContainer>


  )
}
