import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';

const initialState = {
  standardConfigs: [],
  standardConfigsStatus: 'idle',
  standardConfigsByType: [],
  standardConfigsByTypeStatus: 'idle',
  standardConfig: null,
  standardConfigStatus: 'idle',
}

export const fetchStandardConfigs = createAsyncThunk('standardConfig/fetchStandardConfigs', async (id, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/standardConfig/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response?.data
  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const addStandardConfig = createAsyncThunk(
  'standardConfig/addStandardConfig',
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/standardConfig/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
)

export const updateStandard = createAsyncThunk(
  'standards/updateStandard',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(BaseUrl + `/standardConfig/${data.id}/edit/`,data, {
        headers: {
          'Authorization': `token ${token}`
        }
      });
      // Return the updated standard object as the payload
      return response.data;
    } catch (error) {
      // Handle any errors and reject the thunk with an error payload
      return rejectWithValue(error.response.data);
    }
  }
);


export const deleteStandard = createAsyncThunk(
  'standardConfig/deleteStandard',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(BaseUrl + `/standardConfig/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response?.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

const standardConfigSlice = createSlice({
  name: 'standardConfig',
  initialState,
  extraReducers: {
    [fetchStandardConfigs.pending]: (state, action) => {
      state.standardConfigsStatus = 'loading'
    },
    [fetchStandardConfigs.fulfilled]: (state, action) => {
      state.standardConfigsStatus = 'succeeded'
      state.standardConfigs = action.payload
    },
    [fetchStandardConfigs.rejected]: (state, action) => {
      state.standardConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateStandard.fulfilled]: (state, action) => {
      state.standardConfigsStatus = 'succeeded'
    },
    [updateStandard.rejected]: (state, action) => {
      state.standardConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteStandard.fulfilled]: (state, action) => {
      // console.log(action.payload)
    },
    [deleteStandard.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default standardConfigSlice.reducer

