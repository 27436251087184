import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, Form, Input, notification, Select } from "antd";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  addNewKPIConfig,
  fetchKPIs
} from "../../../../../reduxToolkit/features/KPI/KPIConfigSlice";
import { fetchKPICategories } from "../../../../../reduxToolkit/features/KPICategory/KPICategorySlice";
import ErrorModal from "../../../../Components/ErrorModal";


import { fetchStandardConfigs } from "../../../../../reduxToolkit/features/standardConfig/StandardConfigSlice";

const useNavBarStyles = makeStyles(navBarStyles);

export default function CreateKPIConfig() {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const navBarClasses = useNavBarStyles();

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  const KPICategoriesStatus = useSelector(
    (state) => state.KPICategory.KPICategoriesStatus
  );

  //   useEffect(() => {
  //     dispatch(fetchStandardConfigs());
  //   }, [dispatch])


  useEffect(() => {
    dispatch(fetchStandardConfigs());


    if (KPICategoriesStatus === "idle") {
      dispatch(fetchKPICategories());
    }

  }, []);
  const KPICategories = useSelector((state) => state.KPICategory.KPICategories);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [name, setName] = useState("");
  const [number, setNumber] = useState(null);

  const { error } = useSelector((state) => state.KPIConfig);
  const dispatch = useDispatch();


  const standardConfigs = useSelector((state) => state.StandardConfig.standardConfigs);
  console.log('standardConfigs', standardConfigs);

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // message.error('Submit failed!');
  };

  const onFinish = async (values) => {
    // const target = parseInt(target)
    console.log("values", values);
    const resultAction = await dispatch(addNewKPIConfig(values));

    if (addNewKPIConfig.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const KPI = resultAction.payload;
      console.log("success", `Added ${KPI.name}`);
      openNotificationWithIcon("success", `Added ${KPI.name}`);
      dispatch(fetchKPIs());
      history.push("/citrine/configuration/KPI");
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const { Option } = Select;

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/KPI`}>{t("KPI")}</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              {/* <Link to={`/citrine/academicCalender/${id}/KPIProgram/${program && program.id}/KPIs`}> */}
              {t("Create")}
              {/* </Link> */}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={10} sm={10} md={12}>
          <Card>
            <CardBody>
              <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <Form.Item
                      label={t("Code")}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the KPI's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={9}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the KPI's name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Category")}
                      name="kpiCategoryConfig_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the KPI's category",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      // onChange={onChangeCo_requisites_ids}
                      >
                        {KPICategories.map((KPICategory) => (
                          <Option key={KPICategory.id} value={KPICategory.id}>
                            {KPICategory.category}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Type")}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please input the KPI's type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      // onChange={onChangeCo_requisites_ids}
                      >
                        <Option value="strategic">Strategic</Option>
                        <Option value="general">General</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Standard")}
                      name="standardConfig_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select the standard Config",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >

                        {standardConfigs &&
                          standardConfigs.map((person) => (
                            <Option key={person.id} value={person.id}>
                              {person?.KPIstandardConfigType[0]?.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Description")}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input the KPI's description",
                        },
                      ]}
                    >
                      <Input.TextArea autoSize={true} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
