import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../../assets/utils/accessToken'

const initialState = {
    approvalLevels: [],
    approvalLevelsStatus: 'idle',
    error: null,
}

export const fetchApprovalLevels = createAsyncThunk('approvalLevel/fetchApprovalLevels', async (parameter, { rejectWithValue }) => {
    try {

        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + '/approvalLevelConfig/',
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }

        )
        console.log(response.data, 'response.data')
        return response.data

    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})
export const deleteApprovalLevel = createAsyncThunk(
    "approvalLevel/deleteApprovalLevel",
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.delete(
                BaseUrl + `/approvalLevelConfig/${id}/delete/`,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                }
            );
            // console.log(response.data,'response.data')

            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            console.log("error.response from inside the CAT", error.response);
            return rejectWithValue(error.response);
        }
    }
);
export const createApprovalLevel = createAsyncThunk(
    'approvalLevel/createApprovalLevel',
    async (toAdd, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/approvalLevelConfig/create/`, toAdd, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)
export const updateApprovalLevel = createAsyncThunk(
    'approvalLevel/updateApprovalLevel',
    async (data, { rejectWithValue }) => {

        const token = localStorage.getItem('token')

        try {
            const response = await axios.put(BaseUrl + `/approvalLevelConfig/${data.id}/edit/`, data, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            console.log(response.data, 'response.data')
            return response.data

        } catch (err) {
            console.log(err.response)
            let error = err
            if (!error.response) {
                throw err
            }
            return rejectWithValue(error.response)
        }
    }
)



const approvalLevelSlice = createSlice({
    name: 'approvalLevel',
    initialState,
    extraReducers: {
        [fetchApprovalLevels.pending]: (state, action) => {
            state.approvalLevelsStatus = 'loading'
        },
        [fetchApprovalLevels.fulfilled]: (state, action) => {
            state.approvalLevelsStatus = 'succeeded'
            // Add any fetched posts to the array
            state.approvalLevels = action.payload
        },
        [fetchApprovalLevels.rejected]: (state, action) => {
            state.approvalLevelsStatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log(action.payload)
            } else {
                state.error = action.error
                console.log(action.error)
            }
        },
        [createApprovalLevel.fulfilled]: (state, action) => {
            state.approvalLevels.push(action.payload)
        },
        [createApprovalLevel.rejected]: (state, action) => {
            state.error = action.payload ? action.payload : action.error
        },

        // [updateAuthority.rejected]: (state, action) => {
        //   state.error = action.payload ? action.payload : action.error
        // },
    }
})


export default approvalLevelSlice.reducer