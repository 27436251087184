import React, {useRef, useState} from "react";
import Button from "../../components/CustomButtons/Button";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal";
import SuperGeneralUpdateModalStandard from "../Components/SuperGeneralUpdateModalStandard.js"
import ErrorModal from "./ErrorModal";
import { useTranslation } from "react-i18next";
import ListItemText from "@material-ui/core/ListItemText";

import { notification } from "antd";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import {deleteTypesConfig, fetchTypesConfigs} from "../../reduxToolkit/features/TypesConfig/TypesConfigSlice";

const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownTypeConfigButton(props) {
  const { t } = useTranslation();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [errortext, setErrortext] = useState("");
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleOpenUpdateModal = () => {
    setOpenUpdate(true)
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdate(false)
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleDeleteItem = async () => {
    dispatch(deleteTypesConfig(props.id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Deleted`);
        dispatch(fetchTypesConfigs());
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError);
        setErrortext(rejectedValueOrSerializedError?.data?.detail)
        handleOpenErrorModal();
      });
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal();
            handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
        <SuperGeneralUpdateModalStandard
        id={props.id}
        open={openUpdate}
        handleClose={handleCloseUpdateModal}
      />
    </div>
  );
}
