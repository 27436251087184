import { makeStyles } from "@material-ui/core/styles"
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteRange, fetchRanges } from '../../../../../reduxToolkit/features/range/RangeSpecSlice'

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle"


import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js"

// core components
import Card from "components/Card/Card.js"
import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import CardBody from "components/Card/CardBody.js"



import Slide from "@material-ui/core/Slide"

import { Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import DropDownRangeButton from '../../../../../views/Components/DropDownRangeButton'

import {
  fetchCourseById
} from "../../../../../reduxToolkit/Course/coursesSpecSlice"

import { notification } from "antd"
import ErrorModal from "views/Components/ErrorModal"

const useStyles = makeStyles(styles);

const useTitleStyles = makeStyles(titleStyles);



const RangeExcerpt = ({ range, match, courseId, disabledStatus }) => {

  const [smallModal, setSmallModal] = React.useState(false);
  const { t } = useTranslation();
  console.log(range)
  const classes = useStyles();

  const dispatch = useDispatch()

  const handleDeleteRange = (id) => {
    setSmallModal(false)
    dispatch(deleteRange(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log('originalPromiseResult', originalPromiseResult)
      }).then(() => {
        // do additional work
        dispatch(fetchRanges())

      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)

      })
  }

  return (

    <GridItem key={range.id} xs={12} sm={12} md={4}>
      <Card product className={classes.cardHover} >
        <CardBody style={{ height: 150 }}>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={10}>
              <h4 className={classes.cardProductTitle}>
                <Link > {range.type}</Link>

              </h4>
              [{range.start_of_interval}-{range.end_of_interval} %]
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              {!disabledStatus && <DropDownRangeButton id={range.id} courseId={courseId} />}

            </GridItem>

          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>

  )
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function RangesPage({ match }) {
  const [disabledStatus, setDisabledStatus] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const titleClasses = useTitleStyles();
  const { id } = match.params;
  const rangesStatus = useSelector(state => state.rangeSpec.rangesStatus)

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const fetchAllRanges = async () => {
    try {
      const resultAction = await dispatch(fetchRanges(id)).unwrap();

    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchAllRanges()
  }, [dispatch])







  const fetchAllModeOfInstructions = async () => {
    try {
      // const resultAction = await dispatch(fetchModeOfInstructions(id)).unwrap();
      dispatch(fetchCourseById(id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    // fetchAllModeOfInstructions();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);



  const ranges = useSelector(state => state.rangeSpec.ranges)
  const error = useSelector(state => state.rangeSpec.error)

  let content

  if (rangesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (rangesStatus === 'succeeded') {
    content = ranges && ranges.map(range => (

      <RangeExcerpt key={range.id} range={range} courseId={course && course.id} disabledStatus={disabledStatus} />
    ))
  } else if (rangesStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id}/ranges`}
                style={{ color: "orange" }} >
                {t("ranges List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>


      {!disabledStatus &&
        <Link to={`/citrine/specification/course/${course && course.id && course.id}/ranges/create`} >
          <Button color="info">
            {t('Add')}
          </Button>
        </Link>}

      <GridContainer>
        {
          content
        }
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
