// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import { unwrapResult } from "@reduxjs/toolkit";
//import Select from "@material-ui/core/Select";
import { Breadcrumb, Select } from "antd";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchAreas } from "../../../../../reduxToolkit/features/area/AreaSpecSlice";
import {
  addNewType, fetchTypeById,
  fetchTypes, updateType
} from "../../../../../reduxToolkit/features/type/TypeSlice";





const useTitleStyles = makeStyles(titleStyles);

export default function CreateType({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;

  console.log("id", id);

  const isAddMode = isNaN(id);
  console.log("id from match is add mode", isAddMode);
  const [name, setName] = useState("");
  const [areaSpec_ids, setAreaSpec_ids] = useState([]);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();

  const history = useHistory();
  const areasStatus = useSelector((state) => state.areaSpec.areasStatus);

  useEffect(() => {
    if (areasStatus === "idle") {
      dispatch(fetchAreas());
    }
  }, [areasStatus, dispatch]);

  const areas = useSelector((state) => state.areaSpec.areas);

  const { Option } = Select;

  function handleArea_ids(value) {
    //  console.log(`selected ${value}`);
    setAreaSpec_ids(value);
  }

  const onSaveTypeClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(addNewType({ name, areaSpec_ids }));
      unwrapResult(resultAction);
    } catch (err) {
      console.error("Failed to save the type: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };
  const onUpdateClicked = async () => {
    //  if (canSave) {
    try {
      setAddRequestStatus("pending");
      console.log("onupdate", name);
      const resultAction = await dispatch(
        updateType({ id: id, modified: { name, areaSpec_ids } })
      );
      unwrapResult(resultAction);

      dispatch(fetchTypes());
      history.push("/citrine/configuration/course/type");
    } catch (err) {
      console.error("Failed to update the type: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
    //   }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchTypeById(id));
    }
  }, [id]);

  const type = useSelector((state) => state.type.type);
  console.log("type ", type);

  useEffect(() => {
    if (!isAddMode) {
      setName(type && type.name);
      setAreaSpec_ids(type && type.areaSpec_ids);
    }
  }, [type, dispatch]);

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer
              md={12}
              xs={12}
              sm={12}
              direction="row"
              // style={{ width:300 ,height:150}}
              justify="space-between"
              alignItems="center"
            >
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course`}>
                      <span> {t("Course Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course/type`}>
                      <span> {t("Types")} </span>
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Create")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="name"
                            labelText={t("Name")}
                            inputProps={{
                              value: name,
                              onChange: (event) => {
                                setName(event.target.value);
                              },
                              type: "text",
                            }}
                          />

                          <GridItem>
                            <label>{t("Area ")}</label>

                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a area"
                              optionFilterProp="children"
                              value={areaSpec_ids}
                              onChange={handleArea_ids}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {areas &&
                                areas.map((area) => (
                                  <Option key={area.id} value={area.id}>
                                    {area.name}
                                  </Option>
                                ))}
                            </Select>
                          </GridItem>

                          <br />
                          <br />
                          <Link to={`/citrine/configuration/course/type`}>
                            <Button color="primary" onClick={onSaveTypeClicked}>
                              {t("Save")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      {!isAddMode && type && (
        <div>
          <div>
            <GridContainer
              md={12}
              xs={12}
              sm={12}
              direction="row"
              // style={{ width:300 ,height:150}}
              justify="space-between"
              alignItems="center"
            >
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course`}>
                      <span> {t("Course Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course/type`}>
                      <span> {t("Types")} </span>
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Edit")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="name"
                            labelText={t("Name ")}
                            type="text"
                            value={name}
                            inputProps={{
                              value: name,
                              onChange: (event) => {
                                setName(event.target.value);
                              },
                              value: name,
                            }}
                          />

                          <GridItem>
                            <label>{t("Area ")}</label>

                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select an area"
                              optionFilterProp="children"
                              value={areaSpec_ids}
                              onChange={handleArea_ids}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {areas &&
                                areas.map((area) => (
                                  <Option key={area.id} value={area.id}>
                                    {area.name}
                                  </Option>
                                ))}
                            </Select>
                          </GridItem>

                          <br />
                          <br />
                          <Link to={`/citrine/configuration/course/type`}>
                            <Button color="primary" onClick={onUpdateClicked}>
                              {t("Update")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
    </div>
  );
}
