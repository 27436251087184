import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Breadcrumb, notification } from "antd";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Search from "@material-ui/icons/Search";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchfacultyActivitiesByProgram,
  updateFacultyActivity,
} from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import ErrorModal from "../../../Components/ErrorModal";
import { useParams } from "react-router-dom";
import { fetchFacultyActivityById } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import {
  primaryColor,
  roseColor,
  infoColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import EventIcon from "@material-ui/icons/Event";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import Planned from "./Planned.jsx";
import WFA from "./WFA.jsx";
import Analysed from "./Analysed.jsx";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <EventIcon />,
    2: <HourglassEmptyIcon />,
    3: <EventAvailableIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const theme = createTheme({
  overrides: {
    MuiStepper: {
      root: {
        background: 'none',
        border: 'none',
        padding:0
      },
    },
  },
});

export default function Activity() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeState, setActiveState] = React.useState(null);

  const steps = ["Planned", "Waiting for analysis", "Analysed"];
  const dispatch = useDispatch();

  let { activityId } = useParams();
  let currentLanguage = localStorage.getItem("i18nextLng");

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchfacultyActivity = async () => {
    try {
      const resultAction = await dispatch(
        fetchFacultyActivityById(activityId)
      ).unwrap();
      // handle result here
      setActiveState(resultAction.state);
      setActiveStep(steps.indexOf(resultAction.state));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchfacultyActivity();
  }, []);

  const facultyActivity = useSelector(
    (state) => state.facultyActivity.facultyActivity
  );

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleSendForAnalysis = () => {
    UpdateState("Waiting for analysis");
  };

  const handleAnalyse = () => {
    UpdateState("Analysed");
  };
  const handleReset = () => {
    UpdateState("Planned");
  };
  const handleBackFromAnalysed = () => {
    UpdateState("Waiting for analysis");
  };
  const handleBackFromWFA = () => {
    UpdateState("Planned");
  };

  const UpdateState = async (value) => {
    let data = {
      updatedFacultyActivity: { state: value },
      id: activityId,
    };
    const resultAction = await dispatch(updateFacultyActivity(data));

    if (updateFacultyActivity.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      fetchfacultyActivity();
      openNotificationWithIcon("success", `updated successfully`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  return (
    <div className={classes.root}>
      <Card>
        <GridContainer alignItems="center">
          <GridItem xs={3} sm={3} md={3}>
            {activeState === "Planned" && (
              <div>
                <Button color="primary" onClick={handleSendForAnalysis}>
                  Analyse
                </Button>
              </div>
            )}
            {activeState === "Waiting for analysis" && (
              <div>
                <Button color="info" onClick={handleBackFromWFA}>
                  Back
                </Button>
                <Button color="primary" onClick={handleAnalyse}>
                  Approve
                </Button>
              </div>
            )}
            {activeState === "Analysed" && (
              <div>
                <Button color="info" onClick={handleBackFromAnalysed}>
                  Back
                </Button>
                <Button color="info" onClick={handleReset}>
                  Reset
                </Button>
              </div>
            )}
          </GridItem>
          <GridItem xs={9} sm={9} md={9}>
          <ThemeProvider theme={theme}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={
                currentLanguage === "ar" ? false : <ColorlibConnector />
              }
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            </ThemeProvider>
          </GridItem>
        </GridContainer>
      </Card>
      {facultyActivity && (
        <Planned facultyActivity={facultyActivity} />
      )}
      <div>
        {/* {activeState === "Planned" && (
          <div>
            <Planned facultyActivity={facultyActivity && facultyActivity} />
          </div>
        )} */}
        {activeState === "Waiting for analysis" && (
          <WFA facultyActivity={facultyActivity && facultyActivity} />
        )}
        {activeState === "Analysed" && (
          <Analysed facultyActivity={facultyActivity && facultyActivity} />
        )}
      </div>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
