import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  groupStateActions: [],
  groupStateActionsStatus: 'idle',
  groupStateActionStatus: 'idle',
  error: null,
  groupStateAction: null,
}

export const fetchGroupStateActions = createAsyncThunk(
  'groupStateActions/fetchGroupStateActions',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/groupActions/?state_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data of groups')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewGroupStateActions = createAsyncThunk(
  'groupStateActionActions/addNewGroupStateActions',
  async (bodyGroup, { rejectWithValue }) => {
    //console.log(bodyGroup)
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + '/groupActions/create/',
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      //console.log(response.data, 'response.data of groups state actions')

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const deleteGroupStateActions = createAsyncThunk(
  'groupStateActionActions/deleteGroupStateActions',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(
        BaseUrl + `/groupActions/${id}/delete/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response)
    }
  },
)

// export const UpdateGroup = createAsyncThunk(
//   "group/UpdateGroup",
//   async (bodyGroup,{ rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.post(
//         // BaseUrl + "/attachment/assessment_group/update/",
//         BaseUrl + "/attachment/assessment_group/update/",
//         bodyGroup,
//         {
//           headers: {
//             Authorization: `Token ${token}`,
//           },
//         }
//       );
//       console.log(response.data, "response.data of groups");

//       return response.data;
//     } catch (err) {
//       let error = err; // cast the error for access
//       if (!error.response) {
//         throw err;
//       }
//       // We got validation errors, let's return those so we can reference in our component and set form errors
//       console.log("error.response from inside the CAT", error.response);
//       return rejectWithValue(error.response);
//     }
//   }
// );

// export const getGroupsByProgram = createAsyncThunk(
//   "group/getGroupsByProgram", async (id,{ rejectWithValue }) => {
//     try {

//       const token = localStorage.getItem('token')
//       const response = await axios.get(BaseUrl +`/groupAssessment/byProgram/?program_id=${id}`,
//         {
//           headers: {
//             'Authorization': `Token ${token}`
//           }
//         }

//       )
//       console.log('response.data', response.data)
//       return response.data

//     } catch (err) {
//       let error = err // cast the error for access
//       if (!error.response) {
//         throw err
//       }
//       // We got validation errors, let's return those so we can reference in our component and set form errors
//       console.log('error.response from inside the CAT', error.response)
//       return rejectWithValue(error.response)
//     }
//   }
// );

const groupStateActionsSlice = createSlice({
  name: ' groupStateActionsSlice',
  initialState,
  extraReducers: {
    [fetchGroupStateActions.pending]: (state, action) => {
      state.groupStateActionsStatus = 'loading'
    },
    [fetchGroupStateActions.fulfilled]: (state, action) => {
      state.groupStateActionsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.groupStateActions = action.payload
    },
    [fetchGroupStateActions.rejected]: (state, action) => {
      state.groupStateActionsStatus = 'failed'
      state.error = action.payload ? action.payload : action.error

    },

    //     [getGroupsByProgram.pending]: (state, action) => {
    //       state.groupsByProgramStatus = "loading";
    //     },
    //     [getGroupsByProgram.fulfilled]: (state, action) => {
    //       state.groupsByProgramStatus = "succeeded";
    //       // Add any fetched posts to the array
    //       state.groupsByProgram = action.payload;
    //     },
    //     [getGroupsByProgram.rejected]: (state, action) => {
    //       state.groupsByProgramStatus = "failed";
    //     },

    //     // [addNewStudent.fulfilled]: (state, action) => {},

    [addNewGroupStateActions.fulfilled]: (state, action) => {
      state.groupStateActionsStatus = 'succeeded'
      //state.groupStateActions.push(action.payload);
    },

    [addNewGroupStateActions.loading]: (state, action) => {
      state.groupStateActionsStatus = 'loading'
    },
    [addNewGroupStateActions.rejected]: (state, action) => {
      state.groupStateActionsStatus = 'failed'
      state.error = action.payload ? action.payload : action.error

    },

    [deleteGroupStateActions.fulfilled]: (state, action) => {
      state.groupStateActionsStatus = 'succeeded'
    },

    [deleteGroupStateActions.loading]: (state, action) => {
      state.groupStateActionsStatus = 'loading'
    },
    [deleteGroupStateActions.rejected]: (state, action) => {
      state.groupStateActionsStatus = 'failed'

      state.error = action.payload ? action.payload : action.error
    },
  },
})

export default groupStateActionsSlice.reducer
// export const  groups = state => state.group.groups
