import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch, useSelector } from 'react-redux'

// core components
/// test
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Breadcrumb, notification, Popconfirm } from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import clsx from 'clsx'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { fetchCourseInstructorById } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice'
import ErrorModal from '../../../Components/ErrorModal'
import AddAssessmentMethodGroup from './AddAssessmentMethodGroup'
import DropdownEditCourseInstructors from './DropdownEditCourseInstructors'
import { getGroupsByAssessmentMethodAndCourse } from 'reduxToolkit/features/group/GroupSlice'
import DropdownEditCourseGroups from './DropdownEditCourseGroups'
import { fetchGroupAssessmentById } from 'reduxToolkit/features/group/GroupSlice'
import { DataGrid } from '@material-ui/data-grid'
import { UserOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import DropDwonAssessmentMethodGroup from './DropDwonAssessmentMethodGroup'
import Button from "components/CustomButtons/Button.js";
import AddStudent from './AddStudent'
import { fetchStudents } from "../../../../reduxToolkit/features/Student/StudentSlice"
import { MediaBaseUrl } from "../../../../assets/utils/accessToken";
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function AssessmentMethodGroupsDetails({ match }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    ProgramId,
    course_id,
    Assessmentid,
    AssessmentName,
    group_id,
  } = match.params

  const dispatch = useDispatch()
  const courseId = course_id
  const [open, setOpen] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [selectedItemId, setSelectedItemId] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [selected, setSelected] = useState(true)
  const [ping, setPing] = useState(false)
  const [group, setGroup] = useState()

  //const students = useSelector((state)=>state.student.students)

  useEffect(() => {
    dispatch(fetchProgramById(ProgramId))
    dispatch(fetchStudents())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchGroupAssessmentById(group_id))
      .then(unwrapResult)
      .then((group) => {
        setGroup(group.name)
        console.log(group.students_details)
        setRows(group.students_details)
      })
  }, [dispatch, ping])
  const groupAssessment = useSelector((state) => state.group.groupAssessment)
  //console.log(courseInstructors)
  const program = useSelector((state) => state.program.program)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
      zIndex: 1000,
    })
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  const selectionModelChange = (newSelectionModel, details) => {
    console.log(newSelectionModel)
    console.log(details)
    if (newSelectionModel.length > 0) {
      setSelected(false)
    } else {
      setSelected(true)
    }
    setSelectionModel((prevModel) =>
      newSelectionModel.filter((newId) => !prevModel.includes(newId)),
    )
  }
  const columns = [

    {
      field: 'avatar',
      headerName: 'Avatar',
      width: 70,
      renderCell: ({ row }) => {
        console.log(row)
        if (row.avatar === null || row.avatar === '') {
          return <Avatar icon={<UserOutlined />}></Avatar>
        } else {
          return <Avatar src={MediaBaseUrl + `${row.avatar}`}></Avatar>
        }
      },
    },
    {
      field: 'student_identification',
      headerName: 'Identification',
      width: 100,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 150,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 100,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
    },
    {
      field: 'phone_number',
      headerName: 'Phone number',
      width: 100,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 100,
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 150,
    //   type: "actions",
    //   cellClassName: "actions",
    //   renderCell: (cellValues) => {
    //     return (
    //       <Popconfirm
    //         title="Sure to delete?"
    //         onConfirm={() => handleDeleteClick(cellValues.id)}
    //         okButtonProps={{
    //           style: { backgroundColor: "#8F6682", borderColor: "#8F6682" },
    //         }}
    //         cancelButtonProps={{
    //           style: { color: color, borderColor: borderColor },
    //           onMouseEnter: () => {
    //             setColor("#8f6682");
    //             setBorderColor("#8f6682");
    //           },
    //           onMouseLeave: () => {
    //             setColor("");
    //             setBorderColor("");
    //           },
    //         }}
    //       >
    //         <IconButton
    //           aria-label="delete"
    //           onClick={() => setPersonToDelete(`${cellValues.row.first_name} ${cellValues.row.last_name}`)}
    //         >
    //           <DeleteIcon size="medium" />
    //         </IconButton>
    //       </Popconfirm>
    //     );
    //   },
    //},
  ]
  const handleToggle = (value) => {
    setSelectedItemId(value)
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/programs_assessment`}>
                {t('Programs List')}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/program/${ProgramId}`}>
                {program &&
                  program.program_degree_name &&
                  program.program_degree_name}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link
                to={`/citrine/assessmentsQuestions_list/program/${ProgramId}/course/${course_id}`}
              >
                {t('Assessment Methods')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: 'orange' }}>
              <Link
                to={`/citrine/assessmentsQuestionsDetails/program/${ProgramId}/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/`}
              >
                {t('Groups')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: 'orange' }}>
              {group && group}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Card>
        <CardHeader>
          <GridContainer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={11}>
              <h4>
                {AssessmentName} : {group && group}
              </h4>
            </GridItem>
            {/* <GridItem> <Button color="primary" startIcon={<AddIcon />}>
        {t("Add")}
      </Button></GridItem> */}
            {/* <GridItem xs={12} sm={12} md={1}><AddAssessmentMethodGroup course_id={courseId} Assessmentid={Assessmentid} /></GridItem> */}
          </GridContainer>
        </CardHeader>
      </Card>

      <GridContainer>
        <Card>
          <CardBody>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={10} sm={11} md={11}>
                <Link to={{ pathname: '/citrine/addStudent/', state: { group_id, AssessmentName, Assessmentid, ProgramId, course_id, programName: program?.program_degree_name } }}>
                  <Button color="primary">{t('Add')}</Button>
                </Link>
              </GridItem>
              <GridItem xs={2} sm={1} md={1}>
                <DropDwonAssessmentMethodGroup
                  selected={selected}
                  student={selectionModel[0]}
                  ping={ping}
                  setPing={setPing}
                  stateLink={{ group_id, AssessmentName, Assessmentid, ProgramId, course_id, programName: program?.program_degree_name }}
                />
                {/* <DropDownPersonList
                selectedItemId={selectedItemId}
                id={selectedItemId.toString()}
              /> */}
              </GridItem>
            </GridContainer>

            <GridContainer
              style={{
                height: 500,
                width: '100%',
              }}
            >
              <DataGrid
                columns={columns}
                rows={rows && rows}
                //pageSize={5}
                //pagination
                checkboxSelection
                rowsPerPageOptions={[5, 10, 50, 100]}
                onSelectionModelChange={selectionModelChange}
                selectionModel={selectionModel}
              />
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
