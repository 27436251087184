import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const FacultyQualifications = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const columns = [
    {
      title: "Faculty Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Highest Degree Earned: Field and Year",
      dataIndex: "degree",
      key: "degree",
      width: 200,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      width: 150,
    },
    {
      title: "Type of Academic Appointment T, TT, or NTT",
      dataIndex: "AcademicAppointmentType",
      key: "AcademicAppointmentType",
      width: 150,
    },
    {
      title: "FT or PT",
      dataIndex: "employmentStatus",
      key: "employmentStatus",
      width: 150,
    },
    {
      title: "Years of Experience",
      children: [
        {
          title: "Govt./Ind. Practice",
          dataIndex: "nonAcademiaExperienceYears",
          key: "nonAcademiaExperienceYears",
          width: 100,
        },
        {
          title: "Teaching",
          dataIndex: "academiaExperienceYears",
          key: "academiaExperienceYears",
          width: 100,
        },
        {
          title: "This Institution",
          dataIndex: "insideInstitutionExperienceYears",
          key: "insideInstitutionExperienceYears",
          width: 100,
        },
      ],
    },
    {
      title: "Professional Registration / Certification",
      dataIndex: "ProfessionalRegistrationCertification",
      key: "ProfessionalRegistrationCertification",
      width: 150,
    },
    {
      title: "Level of Activity H, M, or L",
      children: [
        {
          title: "Professional Organizations",
          dataIndex: "ProfessionalOrganizationsActivityLevel",
          key: "ProfessionalOrganizationsActivityLevel",
          width: 100,
        },
        {
          title: "Professional Development",
          dataIndex: "ProfessionalDevelopmentActivityLevel",
          key: "ProfessionalDevelopmentActivityLevel",
          width: 100,
        },
        {
          title: "Consulting/Summer Work in Industry",
          dataIndex: "industryConsultingORSummerWorkActivityLevel",
          key: "industryConsultingORSummerWorkActivityLevel",
          width: 100,
        },
      ],
    },
  ];

  return (
    <>
      <h3>FACULTY: Faculty Qualifications</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the qualifications of the faculty and
        how they are adequate to cover all the curricular areas of the program
        and also meet any applicable program criteria:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is information about the faculty members in the form of a
        table including the composition, size, credentials, and experience for
        each member of the faculty in the program:
      </p>
      <div className={styles.reportCart}>
        <AntDDataGrid
          columns={columns}
          data={data?.FacultyQualificationsTable}
          scroll
          bordered
          size="middle"
        />
      </div>
    </>
  );
};

export default FacultyQualifications;
