import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { Link } from "react-router-dom";
// import { getCourseId } from "../../reduxToolkit/features/course/CoursesSlice"
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";

import {
    primaryColor,
    infoColor,
    successColor,
    dangerColor,
    warningColor,
    roseColor,
    whiteColor,
    blackColor,
    hexToRgb,
    grayColor
  } from "assets/jss/material-dashboard-pro-react.js";
  import useHandelError from "./HandelError";
const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

export default function GeneralDeleteModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

  const buttonClasses = useButtonStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [deleteWord, setDeleteWord] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  console.log(props.Element_ID,'Element_ID')


 
  return (
    <div>

    <span
      style={{ color: primaryColor[0] }}
      onClick={(event) => {
        handleClickOpen(event);
      }}
    >
      {t("Delete")}
    </span>

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth="true"
    >
      <DialogTitle id="form-dialog-title"> Confirm Delete</DialogTitle>
      <DialogContent>
        <h4> {t("Are you sure you want to Delete this element ?")}</h4>
        <DialogActions>
          <Button onClick={handleClose} color="danger" >
            {t("Cancel")}
          </Button>
          <Button onClick={() => {handleClose();props.delete_Element(props.Element_ID);
  
        }}
            color="success"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  </div>
  );
}
