import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchKpiReports,
  UpdateKpiReport,
} from '../../../../reduxToolkit/features/KpiReport/KpiReportSlice'
import { fetchGroupStatesByReport } from "reduxToolkit/features/groupState/GroupStateSlice";
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Link,useParams } from 'react-router-dom'
import Board, { moveCard } from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Card } from 'antd'
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next'
import DropDownButtonKpiReport from '../../../Components/DropDownButtonKpiReport'
import KPIReportModal from '../../../Components/KPIReportModal'
import ErrorModal from "../../../Components/ErrorModal";
import Info from "components/Typography/Info.js";
import Moment from 'moment'


export default function KPIsImprovementList(props) {
  const initialBoard = {
    columns: [],
  }
  let content
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { type  } = useParams()

  const [board, setBoard] = useState(initialBoard)
  const [refrech, setrefrech] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchData = () => {
    setrefrech(true)
  }

  const KpiWorkFlow = (kpiReports) => {
    console.log(board, 'board')
    var NewBoard = { ...initialBoard }
    let tab = []
    for (let i = 0; i < kpiReports.length; i++) {
      tab = Object.values(NewBoard)[0]
      let array = []
      for (let j = 0; j < tab.length; j++) {
        if (kpiReports[i].state_object === tab[j].title) {
          tab[j].cards.push(kpiReports[i])
        }
        tab[j].cards = [...tab[j].cards]
        tab[j].cards = [...new Set(tab[j].cards)]
      }
    }
    setBoard(NewBoard)
  }

  function onCardMove(card, source, destination) {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
    let NewState
    let tab = Object.values(board)[0]
    for (let j = 1; j < tab.length; j++) {
      NewState = tab[destination.toColumnId].title
    }
    try {
      let modifiedKpiReport = {
        due_date: card.due_date,
        state_object: NewState,
      }
      const resultAction = dispatch(
        UpdateKpiReport({
          id: card.id,
          updatedKpiReport: { ...modifiedKpiReport },
        }),
        dispatch(fetchKpiReports()),
      )
      unwrapResult(resultAction)
      const resultAction1 = dispatch(fetchKpiReports())
      unwrapResult(resultAction1)
    } catch (err) {
      console.log('Failed to save the CourseReport: ', err)
    } finally {
    }
  }

  function renderCard(card, t, r) {
    return (
      <div>
        {card ? (
          <Card
            style={{
              border: '1px solid lightgrey',
              width: '250px',
              height: '170px',
            }}
          >
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem key={Element.id} xs={12} sm={12} md={10}>
                <Link
                  to={`/citrine/reports/${type}/KPI/${card.id}`}
                >
                  <strong>
                    <p>{card && card.details}</p>
                  </strong>
                </Link>
                <strong>
                  <p>{card && card.type}</p>
                </strong>

                <p>
                  {card && card.academic_year_name}/{card && card.semester_name}
                </p>
                {Moment(card.date).format('YYYY-MM-DD')}
              </GridItem>
              <GridItem key={Element.id} xs={12} sm={12} md={2}>
                <DropDownButtonKpiReport
                  KpiReport_ID={card.id}
                  KpiWorkFlow={KpiWorkFlow}
                  data={card}
                  id={card.id}
                  fetchKPIReports={fetchData}
                />
              </GridItem>
            </GridContainer>
          </Card>
        ) : (
          <p></p>
        )}
      </div>
    )
  }

  useEffect(() => {
    dispatch(fetchGroupStatesByReport('KPIReport')).then(unwrapResult).then((KPIReport_StateBoard) => {
      console.log("KPIReport_StateBoard", KPIReport_StateBoard)
      if (KPIReport_StateBoard?.colums?.length > 0) {
        KPIReport_StateBoard.colums.map((item) => {
          initialBoard.columns.push({
            id: item.id,
            title: item.title,
            cards: [],
          })
        })
      }

    }).catch((error) => {
      setHandledError(error);
      handleOpenErrorModal();
    })
  }, []);

  useEffect(() => {
    dispatch(fetchKpiReports())
      .then(unwrapResult)
      .then((KPIReports) => {
        console.log("KPIReports", KPIReports)
        KpiWorkFlow(KPIReports)
      }).catch((error) => {
        setHandledError(error);
        handleOpenErrorModal();
      })

    return () => setrefrech(false)
  }, [refrech])



  const KpiReportsStatus = useSelector(
    (state) => state.KpiReport.KpiReportsStatus,
  )
  const KPIReport_StateBoard = useSelector(
    (state) => state.groupState.groupStatesByReport,
  )
  const groupStatesByReportStatus = useSelector(
    (state) => state.groupState.groupStatesByReportStatus,
  )


  if (KpiReportsStatus === "loading" || groupStatesByReportStatus === "loading") {

    content = <GridContainer style={{ height: "100%" }} justifyContent="center" alignItems="center"><GridItem><CircularProgress color="inherit" /></GridItem></GridContainer>
  }

  if (KpiReportsStatus === "failed" || groupStatesByReportStatus === "failed") {
    return (
      <GridContainer justifyContent="center" alignItems="center"><GridItem style={{ width: "60%" }}><Info>Oops! Something went wrong.
        We're having trouble retrieving the KPI reports. Please try again later. If the issue persists, contact support for assistance.
      </Info></GridItem></GridContainer>
    )
  }

  if (KpiReportsStatus === "succeeded" && groupStatesByReportStatus === "succeeded") {
    if (KPIReport_StateBoard.colums.length == 0) {


      content = <>
        <KPIReportModal KpiWorkFlow={KpiWorkFlow} />
        <GridContainer justifyContent="center" alignItems="center"><GridItem style={{ width: "60%" }}><Info>Oops! Something went wrong.
          We could not find any KPI reports states. Please add the needed states in the configuration menu or contact support for assistance.
        </Info></GridItem></GridContainer></>
    } else {
      content = <Card style={{ minHeight: '600px' }}>
        <KPIReportModal KpiWorkFlow={KpiWorkFlow} />

        <Board
          onCardDragEnd={onCardMove}
          border="1px solid red"
          renderCard={renderCard}
          disableColumnDrag
          className="react-kanban-board react-kanban-column"
        >
          {board}
        </Board>
      </Card>
    }
  }


  return (
    <>

      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/reports/${type}/KPI`}>
            {t('KPI Report')}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      {content}

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  )
}
