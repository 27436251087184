import { makeStyles } from '@material-ui/core/styles'
import MailOutline from '@material-ui/icons/MailOutline'
import { unwrapResult } from '@reduxjs/toolkit'

import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewBranche,
  fetchBrancheById,
  updateBranche,
} from '../../../../reduxToolkit/features/branche/BranchesSlice'

import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js'
import { useTranslation } from 'react-i18next'
import { fetchColleges } from 'reduxToolkit/features/college/CollegesSlice'
import ErrorModal from '../../../Components/ErrorModal'
import {
  Breadcrumb,
  Form,
  Input,
  notification,
  Select,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
const useStyles = makeStyles(styles)

export default function CreateBranche({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const [form] = Form.useForm()
  const history = useHistory()

  const isAddMode = isNaN(id)
  const [name, setName] = useState('')
  const [college_id, setCollege_id] = useState([])
  const colleges = useSelector((state) => state.college.colleges)

  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const dispatch = useDispatch()

  const classes = useStyles()
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    //message.error('Submit failed!')
  }
  const onFinish = async (values) => {
    try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(addNewBranche(values))
        unwrapResult(resultAction)
        openNotificationWithIcon('success', `Added with success`)
        history.push('/citrine/organizations/branches')
      form.resetFields()
      } catch (err) {
        setHandledError(err)
        handleOpenErrorModal()
      } finally {
        setAddRequestStatus('idle')
      }
  }

  const onUpdateClicked = async () => {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(
        updateBranche({ id: id, modified: { name, college_id } }),
      )
      unwrapResult(resultAction)
    } catch (err) {
      console.error('Failed to save the branche: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }

  useEffect(() => {
    dispatch(fetchColleges())
  }, [])

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchBrancheById(id))
    }
  }, [id])

  const branche = useSelector((state) => state.branches.branche)

  useEffect(() => {
    if (!isAddMode) {
      setName(branche && branche.name)
      setCollege_id(branche && branche.college_id)
    }
  }, [branche, dispatch])

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer
              md={12}
              xs={12}
              sm={12}
              direction="row"
              // style={{ width:300 ,height:150}}
              justify="space-between"
              alignItems="center"
            >
              <GridItem>
                <Breadcrumb separator=">">

                <Breadcrumb.Item><Link to={`/citrine/organizations`}><span > {t('Organizations')} </span></Link></Breadcrumb.Item>

                <Breadcrumb.Item><Link to={`/citrine/organizations/branches`}><span > {t('Branches')} </span></Link></Breadcrumb.Item>

                <Breadcrumb.Item><span> {t('Create')} </span></Breadcrumb.Item>
              </Breadcrumb>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{t('Create')}</h4>
                  </CardHeader>
                  <CardBody>
                    <Form
                      name="basic"
                      form={form}
                      layout="vertical"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      scrollToFirstError
                    >
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Form.Item
                            label={t('Name')}
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a name!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('College')}
                              name="college_id"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select College',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: 400 }}
                                optionFilterProp="children"
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {colleges.map((college) => (
                                  <option key={college.id} value={college.id}>
                                    {college.name}
                                  </option>
                                ))}
                              </Select>
                            </Form.Item>
                          </GridItem>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="flex-end">
                        <GridItem>
                          <Form.Item>
                            <Button
                              onClick={() =>
                                history.push('/citrine/organizations/branches')
                              }
                              style={{ marginRight: '20px' }}
                              color="danger"
                            >
                              {t('Cancel')}
                            </Button>
                            <Button
                              type="submit"
                              color="info"
                              className={classes.updateProfileButton}
                            >
                              {t('Save')}
                            </Button>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      {!isAddMode && branche && (
        <div>
          <div>
            <GridContainer
              md={12}
              xs={12}
              sm={12}
              direction="row"
              // style={{ width:300 ,height:150}}
              justify="space-between"
              alignItems="center"
            >
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/organizations`}>
                      <span> {t('Organizations')} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/organizations/branches`}>
                      <span> {t('Branches')} </span>
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{t('Edit')}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="name"
                            labelText={t('Name ')}
                            inputProps={{
                              value: name,
                              onChange: (event) => {
                                setName(event.target.value)
                              },
                              branche: 'text',
                            }}
                          />
                          <GridItem xs={12} sm={12} md={6}>
                            <label>{t('College  ')}</label>
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              optionFilterProp="children"
                              name="college_id"
                              value={college_id}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {colleges.map((college) => (
                                <option key={college.id} value={college.id}>
                                  {college.name}
                                </option>
                              ))}
                            </Select>
                          </GridItem>
                          <br />
                          <br />
                          <Link to={`/citrine/organizations/branches`}>
                            <Button
                              color="primary"
                              onClick={onUpdateClicked}
                              className={classes.registerButton}
                            >
                              Update
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
