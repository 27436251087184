import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  LanguagePersons: [],
  LanguagePersonsStatus: "idle",
  LanguagePersonStatus: "idle",
  error: null,
  LanguagePerson: null,
};

export const fetchLanguagePersonsByPersonId = createAsyncThunk(
  "LanguagePerson/fetchLanguagePersonsByPersonId",
  async (person_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/language/byStaff/?staff_id=${person_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);

export const addNewLanguagePerson = createAsyncThunk(
  "LanguagePerson/addNewLanguagePerson",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/language/create/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateLanguagePerson = createAsyncThunk(
  "LanguagePerson/UpdateLanguagePerson",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/language/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "education");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the education: ", error);
    }
  }
);

export const deleteLanguagePerson = createAsyncThunk(
  "LanguagePerson/deleteLanguagePerson",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(BaseUrl + `/language/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  }
);

const LanguagePersonSlice = createSlice({
  name: "education",
  initialState,
  extraReducers: {
    [fetchLanguagePersonsByPersonId.pending]: (state, action) => {
      state.LanguagePersonsStatus = "loading";
    },
    [fetchLanguagePersonsByPersonId.fulfilled]: (state, action) => {
      state.LanguagePersonsStatus = "succeeded";
      // Add any fetched posts to the array
      state.LanguagePersons  = action.payload;
    },
    [fetchLanguagePersonsByPersonId.rejected]: (state, action) => {
      state.LanguagePersonsStatus = "failed";
      state.error = action.payload;
    },

    [addNewLanguagePerson.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },

    [UpdateLanguagePerson.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    [deleteLanguagePerson.fulfilled]: (state, action) => {},
  },
});

export default LanguagePersonSlice.reducer;
// export const  groups = state => state.group.groups
