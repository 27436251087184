// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { SchoolRounded } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import { Breadcrumb, Select } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ErrorModal from "views/Components/ErrorModal";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchAreaById } from '../../../../reduxToolkit/features/area/AreaSpecSlice';




export default function AreaDetails({ match }) {
    const { t } = useTranslation();
    const useStyles = makeStyles(styles);
    const useNavBarStyles = makeStyles(navBarStyles);
    const useTitleStyles = makeStyles(titleStyles);
    const classes = useStyles();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton
    const { id } = match.params
    const dispatch = useDispatch()

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };
    const fetchArea = async () => {
        try {
            const resultAction = await dispatch(fetchAreaById(id)).unwrap();

        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    useEffect(() => {
        fetchArea(id)

    }, [id, dispatch])

    const area = useSelector((state) => state.areaSpec.area)

    const { Option } = Select;

    const [addRequestStatus, setAddRequestStatus] = useState('idle')



    const areaStatus = useSelector((state) => state.areaSpec.areaStatus)
    const error = useSelector((state) => state.areaSpec.error)






    // useEffect(() => {
    //     dispatch(fetchAreaById(id))

    // }, [dispatch, area])



    if (!area) {
        return (
            <section>
                <h2>{t('Area not found!')}</h2>
            </section>
        )
    }

    return (
        <div>
            <GridContainer style={{
                borderBottom: `1px solid  ${grayColor[15]}`,
                borderTop: `1px solid  ${grayColor[15]}`,
            }}
                justify="space-between"
                alignItems="center">
                <GridItem>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item >

                            {t('Configurations')}

                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/configuration/course`}>
                                <span > {t('Course Configurations')} </span>
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to={`/citrine/configuration/course/areas`} style={{ color: "orange" }}>
                                {t('Areas List')}
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>

            </GridContainer>





            <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} xs={12} sm={12} md={12}>

                <GridItem xs={12} sm={12} md={12}>
                    <Card justify="space-between">
                        <CardHeader color="info" icon style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CardIcon color="info">
                                        <SchoolRounded />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>{t('Range ')} ---<small>{area.Type}</small> </h4>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Edit"
                                        placement="bottom"
                                        classes={{ tooltip: classes.tooltip }}

                                    >
                                        <Link to={`/citrine/configuration/course/areas/${area.id}/edit`}>
                                            <Button color="success" simple justIcon>
                                                <EditIcon className={classes.underChartIcons} />
                                            </Button>
                                        </Link>
                                    </Tooltip></GridItem>
                            </GridContainer>
                        </CardHeader>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>


                    <div >
                        <Card >
                            <CardBody>
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={12} sm={12} md={10}> <div>{t('Name')} </div></GridItem>
                                    <GridItem xs={12} sm={12} md={2}>  {area.name}</GridItem>
                                    <GridItem xs={12} sm={12} md={10}> <div>{t('Code')} </div></GridItem>
                                    <GridItem xs={12} sm={12} md={2}>  <div>  {area.code} </div></GridItem>



                                </GridContainer>
                            </CardBody>
                        </Card>
                    </div>



                </GridItem>

            </GridContainer>

            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />






        </div>
    );


}

