import React, { useState, useEffect, Fragment } from "react";

import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { Tabs } from "antd";
import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";

import useTable from "../../../../Components/useTable";
import useTableRecommendation from "../../../../Components/useTableRecommendation";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";

import { TableBody, TableRow, TableCell } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import AddLanguageModal from "./AddLanguageModal";

import ErrorModal from "../../../../Components/ErrorModal";
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  addNewLanguagePerson,
  fetchLanguagePersonsByPersonId,
  deleteLanguagePerson,
} from "../../../../../reduxToolkit/features/LanguagePerson/LanguagePersonSlice.js";
const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const headCells = [
  { id: "Language", label: "Language" },
  { id: "proficiency", label: "Proficiency" },
  { id: "delete", label: "" },
];

export default function LanguageList(props) {

  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn);
  let person_id = props.person && props.person.id;
  useEffect(() => {
    dispatch(fetchLanguagePersonsByPersonId(person_id));
  }, [dispatch]);

  const LanguagePersons = useSelector((state) => state.LanguagePerson.LanguagePersons);
  
  const DeleteLanguagePerson = (id) => {
    dispatch(deleteLanguagePerson(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchLanguagePersonsByPersonId(person_id));
      });
  };
  const [pageSize, setPageSize] = React.useState(10);
  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          <AddLanguageModal person_id={person_id} />
        </GridItem>
      </GridContainer>

      <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
        {LanguagePersons && LanguagePersons.length != 0 && <TblHead />}
        <TableBody>
          {LanguagePersons &&
            LanguagePersons.map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    borderLeft: "1px solid lightgrey",
                    borderRight: "1px solid lightgrey",
                  }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      maxWidth: "100px",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item && item.language}
                  </p>
                </TableCell>
               
          
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgrey" }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      maxWidth: "100px",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item && item.proficiency}
                  </p>
                </TableCell>

                <TableCell>
                  <DeleteOutlined
                    onClick={() => DeleteLanguagePerson(item && item.id)}
                    style={{ color: "red" }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TblContainer>
    </div>
  );
}
