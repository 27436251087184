// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchImprovementByResourceAndResourceIdAndType,
} from "../../../../../reduxToolkit/features/annualProgramAnalysis/annualProgramAnalysisSlice"; import CoursesWithVariations from "./CoursesWithVariations";
import ResultAnalysisOfCourseReports from "./ResultAnalysisOfCourseReports";
import TeachingOfPlannedCourses from "./TeachingOfPlannedCourses";
import AssessmentAnalysis from "./AssessmentAnalysis"

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

export default function SummaryOfCourseReports(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const [activeChildTabKey, setActiveChildTabKey] = useState(1)

  let type = "summaryOfCourseReports";
  let resource = "annualProgram";
  let resource_id = props.AnnualProgramReport?.id;

  let data = {
    resource,
    resource_id,
    type,
  };
  const fetchAnalysis = () => {
    dispatch(fetchImprovementByResourceAndResourceIdAndType(data))
      .then(unwrapResult)
      .catch((error) => {
        console.error(error);
      })
  }
  useEffect(() => {
    if (props.activeParentTabKey === "5") {
      fetchAnalysis()
    }
  }, [props.activeParentTabKey]);



  return (
    <div>
      
      <Tabs onChange={(activeKey) => { setActiveChildTabKey(activeKey) }} type="card" defaultActiveKey="1" destroyInactiveTabPane>
        <TabPane tab={t("Teaching Of Planned Courses / Units")} key="1" forceRender>
          <TeachingOfPlannedCourses
            AnnualProgramReport={props.AnnualProgramReport}
          />
        </TabPane>

        <TabPane tab={t("Courses With Variations")} key="2" forceRender>
          <CoursesWithVariations
            AnnualProgramReport={props.AnnualProgramReport}
          />
        </TabPane>
        <TabPane tab={t("Analysis")} key="3" forceRender>
          <AssessmentAnalysis AnnualProgramReport={props.AnnualProgramReport} fetchAnalysis={fetchAnalysis} activeChildTabKey={activeChildTabKey} />
        </TabPane>
      </Tabs>
    </div>
  );
}
