import React, { useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer.js'
import { fetchApprovalLevels, deleteApprovalLevel } from '../../../../reduxToolkit/features/Configuration/ApprovalLevel/ApprovalLevelSlice'
import { Breadcrumb, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GridItem from 'components/Grid/GridItem.js'
import Button from '../../../../components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import ErrorModal from "../../../Components/ErrorModal";
import UpdateApprovalLevel from "./UpdateApprovalLevel"
// import ActionTypeDropDown from './ActionTypeDropDown.jsx'
const useCardStyles = makeStyles(cardStyles)

function ApprovalLevelCard({ el, handleOpenDeleteModal, setToBeDeleted, setToBeUpdated, setUpdateModalIsOpen }) {
    const cardClasses = useCardStyles()

    return (
        <GridItem xs={12} sm={12} md={4} key={el.id}>
            <Card
                style={{ height: '200px' }}
            >
                <CardBody>
                    <GridContainer
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <GridItem xs={12} sm={12} md={10}>
                            <h4 className={cardClasses.cardProductTitle}>
                                {`${el?.name}`}
                            </h4>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={2}>
                            <CustomDropdown
                                isButtonIcon
                                dropdownList={[
                                    {
                                        text: "Edit", onClickHandler() {
                                            setToBeUpdated(el)
                                            setUpdateModalIsOpen(true)
                                        }
                                    },
                                    {
                                        text: "Delete", onClickHandler() {
                                            setToBeDeleted(el.id)
                                            handleOpenDeleteModal()
                                        }
                                    },
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </GridItem>
    )
}

export default function ApprovalLevelsPage() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    //error modal
    const [errorModalIsOpen, setErrorModalIsOpen] = React.useState(false);
    const handleOpenErrorModal = () => {
        setErrorModalIsOpen(true);
    };
    const handleCloseErrorModal = () => {
        setErrorModalIsOpen(false);
    };

    const [handledError, setHandledError] = React.useState("");

    //delete modal
    const [open, setOpen] = React.useState(false);

    const handleOpenDeleteModal = () => {
        setOpen(true);
    };
    const handleCloseDeleteModal = () => {
        setOpen(false);
    };

    const [toBeDeleted, setToBeDeleted] = React.useState(null);
    const [toBeUpdated, setToBeUpdated] = React.useState(null);


    const [updateModalIsOpen, setUpdateModalIsOpen] = React.useState(false)
    console.log("toBeUpdated", toBeUpdated)

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        })
    }
    const handleDelete = async (id) => {
        dispatch(deleteApprovalLevel(id))
            .then(unwrapResult)
            .then(() => {
                dispatch(fetchApprovalLevels())
                openNotificationWithIcon('success', `File Deleted successfully`)
            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                console.log("rejectedValueOrSerializedError", rejectedValueOrSerializedError)
                setHandledError(rejectedValueOrSerializedError);
                handleOpenErrorModal();
            });
    }


    useEffect(() => {
        dispatch(fetchApprovalLevels())
    }, [dispatch])

    const { approvalLevels } = useSelector((state) => state.ApprovalLevel)
    return (
        <>
            <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <GridItem>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/configuration/others`}>
                                <span> {t('other Configurations')} </span>
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/configuration/others/approvalLevels`}>
                                <span> {t('Approval Levels')} </span>
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>
            </GridContainer>

            <Link to={`/citrine/configuration/others/approvalLevels/create`}>
                <Button color="info">{t('Add')}</Button>
            </Link>

            <GridContainer>
                {approvalLevels?.map((el) => (
                    <ApprovalLevelCard key={el.id} el={el} setToBeDeleted={setToBeDeleted} handleOpenDeleteModal={handleOpenDeleteModal} setToBeUpdated={setToBeUpdated} setUpdateModalIsOpen={setUpdateModalIsOpen} />
                ))}
            </GridContainer>

            <SuperGeneralDeleteModal
                delete_Element={handleDelete}
                Element_ID={toBeDeleted}
                open={open}
                handleClose={handleCloseDeleteModal}
            />
            <ErrorModal
                open={errorModalIsOpen}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
            <UpdateApprovalLevel setUpdateModalIsOpen={setUpdateModalIsOpen} updateModalIsOpen={updateModalIsOpen} toBeUpdated={toBeUpdated} handleOpenErrorModal={handleOpenErrorModal} setHandledError={setHandledError} />
        </>
    )
}
