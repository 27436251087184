import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { DatePicker, Form, notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateSelectedCoursesPlanning } from "reduxToolkit/features/course/CoursesSlice.js";
import ErrorModal from "../../../Components/ErrorModal.jsx";
import isDate2GreaterThanDate1 from "assets/utils/helperFunction"

export default function UpdateDates(props) {

  const [assessment_start_date, setAssessmentStartDate] = useState("");
  const [assessment_due_date, setAssessmentDueDate] = useState("");
  const [assessment_due_date_validation_status, setAssessment_due_date_validation_status] = useState("");
  const [survey_start_date, setSurveyStartDate] = useState("");
  const [survey_due_date, setSurveyDueDate] = useState("");
  const [survey_due_date_validation_status, setSurvey_due_date_validation_status] = useState("");
  const [improvement_due_date, setImprovementDueDate] = useState("");

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  // const assessmentStartDatePickerRef = useRef(null);
  // const assessmentDueDatePickerRef = useRef(null);
  // const surveyStartDatePickerRef = useRef(null);
  // const surveyDueDatePickerRef = useRef(null);
  // const improvementDueDatePickerRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  function onAssessmentStartDateChange(value, dateString) {
    setAssessmentStartDate(dateString);
  }

  function onAssessmentDueDateChange(value, dateString) {
    setAssessmentDueDate(dateString);
  }

  function onSurveyStartDateChange(value, dateString) {
    setSurveyStartDate(dateString);
  }

  function onSurveyDueDateChange(value, dateString) {
    setSurveyDueDate(dateString);
  }

  function onImprovementDueDateChange(value, dateString) {
    setImprovementDueDate(dateString);
  }

  const onFinishFailed = (errorInfo) => { };

  const onFinish = async () => {
    let id = props?.selectedRows?.join();
    let data = {
      assessment_start_date,
      assessment_due_date,
      survey_start_date,
      survey_due_date,
      improvement_due_date,
    };

    if (assessment_due_date_validation_status === "" && survey_due_date_validation_status === "") {
      const dateArr = Object.entries(data);
      const filteredArr = dateArr.filter(function ([key, value]) {
        return !!value;
      });
      const filteredObj = Object.fromEntries(filteredArr);
      const resultAction = await dispatch(
        updateSelectedCoursesPlanning({ ...filteredObj, id })
      );
      if (updateSelectedCoursesPlanning.fulfilled.match(resultAction)) {
        // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
        props.refreshCourses();
        openNotificationWithIcon("success", `Updated successfully`);
        handleClose();
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
          handleClose();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
          handleClose();
        }
      }
      setAssessment_due_date_validation_status("")
      setSurvey_due_date_validation_status("")
      setAssessmentStartDate('')
      setAssessmentDueDate('')
      setSurveyStartDate('')
      setSurveyDueDate('')
      setImprovementDueDate('')
    }


  };

  useEffect(() => {
    if (assessment_start_date && assessment_due_date) {
      isDate2GreaterThanDate1(assessment_start_date, assessment_due_date) ? setAssessment_due_date_validation_status("") : setAssessment_due_date_validation_status('error')
    }

  }, [assessment_start_date, assessment_due_date])

  useEffect(() => {
    if (survey_start_date && survey_due_date) {
      isDate2GreaterThanDate1(survey_start_date, survey_due_date) ? setSurvey_due_date_validation_status("") : setSurvey_due_date_validation_status('error')
    }

  }, [survey_start_date, survey_due_date])

  return (
    <div>
      <MenuItem
        disabled={props.disabled}
        onClick={() => {
          handleClickOpen();
          props.handleDropDownButtonClose();
        }}
      >
        {t("Update dates")}
      </MenuItem>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{t("Update dates")}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("assessment start date")}>
                      <DatePicker
                        // ref={assessmentStartDatePickerRef}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Tab") { // "Tab" key
                        //     event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        //     assessmentDueDatePickerRef.current.focus(); // Focus the next DatePicker component
                        //   }
                        // }}
                        onChange={onAssessmentStartDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("assessment due date")}
                      validateStatus={assessment_due_date_validation_status}
                      help="The assessment due date should be greater than the assessment start date"
                    >
                      <DatePicker
                        // ref={assessmentDueDatePickerRef}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Tab") { // "Tab" key
                        //     event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        //     surveyStartDatePickerRef.current.focus(); // Focus the next DatePicker component
                        //   }
                        // }}
                        onChange={onAssessmentDueDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Survey start date")}>
                      <DatePicker
                        // ref={surveyStartDatePickerRef}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Tab") { // "Tab" key
                        //     event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        //     surveyDueDatePickerRef.current.focus(); // Focus the next DatePicker component
                        //   }
                        // }}
                        onChange={onSurveyStartDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("survey due date")}
                      validateStatus={survey_due_date_validation_status}
                      help="The survey due date should be greater than the survey start date"
                    >
                      <DatePicker
                        // ref={surveyDueDatePickerRef}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Tab") { // "Tab" key
                        //     event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        //     improvementDueDatePickerRef.current.focus(); // Focus the next DatePicker component
                        //   }
                        // }}
                        onChange={onSurveyDueDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Improvement due date")}>
                      <DatePicker
                        // ref={improvementDueDatePickerRef}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Tab") { // "Tab" key
                        //     event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        //     assessmentStartDatePickerRef.current.focus(); // Focus the next DatePicker component
                        //   }
                        // }}
                        onChange={onImprovementDueDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
