import { Tabs } from 'antd';
import Card from "components/Card/Card"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import { greyColor } from 'assets/jss/material-dashboard-pro-react';
import { Link } from "react-router-dom"
import React from 'react';
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import StudentAdmissions from './StudentAdmissions';
import GraduationRequirements from './GraduationRequirements';
import RecordsOfStudentWorkOrTranscripts from './RecordsOfStudentWorkOrTranscripts';
import EvaluatingStudentPerformance from './EvaluatingStudentPerformance';
import TransferStudentsAndTransferCourses from './TransferStudentsAndTransferCourses';
import AdvisingAndCareerGuidance from './AdvisingAndCareerGuidance';
import WorkInLieuOfCourses from './WorkInLieuOfCourses';
import Criterion1Analysis from './Analysis';

const onChange = (key) => {
    console.log(key);
};

const Criterion1 = ({data}) => (
    console.log("data",data),
    <>
        {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

        <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            items={[
                {
                    label: `Student Admissions`,
                    key: '1',
                    children: <StudentAdmissions data={data.StudentAdmissions}/>
                    ,
                },
                {
                    label: `Evaluating Student Performance`,
                    key: '2',
                    children: <EvaluatingStudentPerformance data={data.EvaluatingStudentPerformance}/>,
                },
                {
                    label: `Transfer Students and Transfer Courses`,
                    key: '3',
                    children: <TransferStudentsAndTransferCourses data={data.TransferStudentsAndTransferCourses}/>,
                },
                {
                    label: `Advising and Career Guidance`,
                    key: '4',
                    children: <AdvisingAndCareerGuidance data={data.AdvisingAndCareerGuidance}/>,
                },
                {
                    label: `Work in Lieu of Courses`,
                    key: '5',
                    children: <WorkInLieuOfCourses data={data.WorkInLieuOfCourses}/>,
                },
                {
                    label: `Graduation Requirements`,
                    key: '6',
                    children: <GraduationRequirements data={data.GraduationRequirements}/>,
                },
                {
                    label: `Records of Student Work/Transcripts`,
                    key: '7',
                    children: <RecordsOfStudentWorkOrTranscripts data={data.RecordsOfStudentWorkOrTranscripts}/>,
                },
                {
                    label: `Analysis`,
                    key: '8',
                    children: <Criterion1Analysis data={data.Analysis}/>,
                },
            ]}
        />
    </>

);
export default Criterion1;