import {
  FormOutlined
} from "@ant-design/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import { addNewAnnualProgramAnalysis, fetchAnnualProgramAnalysissByProgram } from "../../reduxToolkit/features/annualProgramAnalysis/annualProgramAnalysisSlice";
import ErrorModal from "./ErrorModal";
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useStyles4 = makeStyles(styles4);
const useButtonStyles = makeStyles(buttonStyles);
export default function EditAnalysisOfProgram(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);
  const [type, setType] = useState(props.type);
  const [program_id, setProgram_id] = useState(props.program_id);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const classes4 = useStyles4();
  useEffect(() => {
    let object = {
      program_id: props.program_id,
      type: props.type,
    }
    dispatch(
      fetchAnnualProgramAnalysissByProgram(object)
    )
  }, []);

  const res = useSelector(
    (state) => state.annualProgramAnalysis.annualProgramAnalysissByProgram
  );
  // useEffect(() => {
  //   form.setFieldsValue({
  //     strength:
  //       res &&
  //       res[0] &&
  //       res[0].strength,
  //     priorities_for_improvement:
  //       res &&
  //       res[0] &&
  //       res[0].priorities_for_improvement,
  //     areas_for_improvement:
  //       res &&
  //       res[0] &&
  //       res[0].areas_for_improvement,
  //   });

  // }, [res]);


  const { Option } = Select;
  const [open, setOpen] = React.useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    let object = {
      program_id: props.program_id,
      type: props.type,
    }
    const updated = { ...values };
    console.log(updated, "updated");
    console.log(props.courseReport, "props.courseReport");
    const resultAction = await dispatch(
      addNewAnnualProgramAnalysis({
        type,
        program_id,
        ...values,
      })
    );
    if (
      addNewAnnualProgramAnalysis.fulfilled.match(resultAction)
    ) {
      openNotificationWithIcon("success", `Edit successfully`);
      console.log('ooooooobject', object)
      dispatch(fetchAnnualProgramAnalysissByProgram(object));
      props.fetchAnalysis();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FormOutlined
        onClick={(event) => {
          handleClickOpen(event);
        }}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />

      <Dialog
        forceRender
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
      //maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Annual Program Analysis")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                {/* <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Course field.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Course "
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {program && program.courses &&
                      program.courses.map((course) => (
                        <Option value={course.id}>{course.title}</Option>
                      ))}
                  </Select>
                </Form.Item> */}

                <Form.Item
                  name="strength"
                  label="Strength"
                  rules={[
                    {
                      required: false,
                      message: "Please input the strength field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="priorities_for_improvement"
                  label="Priorities for improvement"
                  rules={[
                    {
                      required: false,
                      message: "Please input the Priorities for improvement field.",
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>
                <Form.Item
                  name="areas_for_improvement"
                  label="Areas for improvement"
                  rules={[
                    {
                      required: false,
                      message: "Please input the Areas for improvement field.",
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
