import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import { DatePicker, Form, notification, Select } from 'antd'
// import Button from "components/CustomButtons/Button.js";
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { fetchSurveyTemplatesTypeConfigs } from 'reduxToolkit/features/surveyTemplateTypeConfig/SurveyTemplateTypeConfigSlice.js'
import {
  fetchAllPrograms,
  fetchProgramById,
  updateProgramSurveyDates,
} from '../../../../reduxToolkit/features/program/ProgramsSlice.js'
import ErrorModal from '../../../Components/ErrorModal.jsx'

export default function UpdateDates(props) {
  console.log(props)
  const [start_date, setStart_date] = React.useState('')
  const [due_date, setDue_date] = React.useState('')
  const [program_id, setProgram_id] = React.useState('')
  const [survey_type, setSurveyType] = React.useState('')
  const [templatesTypes, setTemplatesTypes] = React.useState([])

  const { t } = useTranslation()
  const { Option } = Select
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState([])
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const dispatch = useDispatch()

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  function onStart_dateChange(value, dateString) {
    setStart_date(dateString)
  }
  function onDue_dateChange(value, dateString) {
    setDue_date(dateString)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    // message.error('Submit failed!');
  }

  const onFinish = async () => {
    let program_id = props.program_id
    let survey_type = props.selectedRows.join()
    console.log("survey_type = props.selectedRows.join()", survey_type)
    let data = {
      start_date,
      due_date,
    }
    console.log(data)



    const resultAction = await dispatch(
      updateProgramSurveyDates({
        ...data,
        program_id,
        survey_type,
      }),
    )

    if (updateProgramSurveyDates.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchProgramById(program_id))
      openNotificationWithIcon('success', `Updated successfully`)
      handleClose()
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        handleClose()
      } else {
        console.log('resultAction.error  from component', resultAction.error)
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        handleClose()
      }
    }
    setStart_date('')
    setDue_date('')
  }
  // const fetchTypes = async () => {
  //   dispatch(fetchSurveyTemplatesTypeConfigs()).unwrap().then((res) => {

  //     res.filter(type => {
  //       if (type.category == "program") {
  //         templatesTypes.push(type)
  //         console.log('theeen result', res)
  //       }
  //     })
  //   });

  // };
  // useEffect(() => {
  //   fetchTypes();
  // }, []);
  return (
    <div>
      <MenuItem
        disabled={props.disabled}
        onClick={() => {
          handleClickOpen()
          props.handleDropDownButtonClose()
        }}
      >
        {t('Update dates')}
      </MenuItem>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{t('Update dates')}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                //   labelCol={{ span: 8 }}
                //   wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Type")}
                      name="survey_type"
                      rules={[
                        {
                          required: true,
                          message: "Please input the type's field",
                        },
                      ]}
                    >
                      <Select
                        // mode="multiple"
                        value={value}
                        onChange={(newValue) => {
                          setSurveyType(newValue);
                        }}
                        showSearch
                        dropdownStyle={{ zIndex: 10000 }}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {templatesTypes && templatesTypes.map((type) => (
                          <Option key={type.id} value={type.name}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem> */}

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      name="start_date"
                      label={t('Start date')}
                      rules={[
                        {
                          required: true,
                          message: `Please select start date`,
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onStart_dateChange}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      name="due_date"
                      label={t('Due date')}
                      rules={[
                        {
                          required: true,
                          message: `Please select end date`,
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onDue_dateChange}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </GridItem>
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose()
                            }}
                          >
                            {t('Cancel')}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t('Save')}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
