import { FormOutlined } from "@ant-design/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/core components
import { Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { warningColor } from "../../../../assets/jss/material-dashboard-pro-react";

import ErrorModal from "../../../Components/ErrorModal";
import {
  fetchGraduateAttributeById,
  fetchGraduateAttributes,
  updateGraduateAttributeFieldByField,
} from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";

export default function EditComments({ id, program }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [form] = Form.useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchGraduateAttributeById(id))
      .unwrap()
      .then((GA) => {
        // handle result here
        form.setFieldsValue({
          comments: GA.comments,
        });
        console.log("comments from edit modal", GA.comments);
      });
  }, [open]);

  const onFinish = async (values) => {
    let data = {
      updatedGraduateAttributeSpec: values,
      id: id,
    };
    const resultAction = await dispatch(
      updateGraduateAttributeFieldByField(data)
    );

    if (updateGraduateAttributeFieldByField.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchGraduateAttributes(program && program.id));
      openNotificationWithIcon("success", `updated successfully`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  return (
    <div>
      <FormOutlined
        onClick={handleClickOpen}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">
          {t("Edit the comments")}
        </DialogTitle>
        <DialogContent>
          <Form name="basic" layout="vertical" onFinish={onFinish} form={form}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item label={t("Comments")} name="comments">
                  <Input.TextArea />
                </Form.Item>
              </GridItem>
            </GridContainer>

            {/* </FormControl> */}
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button color="primary" onClick={handleClose}>
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={handleClose}
                      >
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
