import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  
  status:null,
 
  id:null
}




const  StatusSlice = createSlice({
  name: 'status',
  initialState,
  


  reducers: {

    statusChanged: (state, action) => {
        state.status = action.payload
      },
    
  }
})

export const {statusChanged} = StatusSlice.actions

export default StatusSlice.reducer


