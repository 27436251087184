
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, notification } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CheckBox from 'devextreme-react/check-box';
import HtmlEditor, { Item, MediaResizing, Toolbar } from 'devextreme-react/html-editor';
import "devextreme/ui/html_editor/converters/markdown";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from "react-router-dom";
import { grayColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchCourseById
} from "../../../../../reduxToolkit/Course/coursesSpecSlice";
import { fetchCourseSyllabusReportDocumentById, fetchCourseSyllabusReportDocumentsByCourseSpecId, updateCourseSyllabusReportDocument } from "../../../../../reduxToolkit/features/CourseSyllabusReportDocument/CourseSyllabusReportDocumentSlice";
import ErrorModal from "../../../../Components/ErrorModal";
import '../style.css';
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];
const defaultSelectedItemKeys = ['Html'];

export default function CourseSyllabusReportDocumentEditor() {
  const { t } = useTranslation();

  let { courseSyllabusReportDocumentId } = useParams();

  let { id } = useParams();
  const [isMultiline, setIsMultiline] = useState(true)
  const [valueContent, setValueContent] = useState('')
  const [editorValueType, setEditorValueType] = useState('html')

  const dispatch = useDispatch()
  let history = useHistory();
  console.log('use effect course spec document editor', courseSyllabusReportDocumentId)
  const fetchCourse = async () => {
    try {
      const courses = await dispatch(fetchCourseById(id))
        .then(unwrapResult)
        .then((course) => {

          localStorage.setItem("CourseState", course.state);
        });
      console.log("success", `Fetched`);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
      console.log("error", `Fetch failed: ${err}`);
    }
  };
  useEffect(() => {
    fetchCourse();
    dispatch(fetchCourseSyllabusReportDocumentById(courseSyllabusReportDocumentId)).then(unwrapResult)
      .then((courseSyllabusReportDocument) => {
        // do additional work
        console.log("originalPromiseResult", courseSyllabusReportDocument)
        setValueContent(courseSyllabusReportDocument.htmlTemplates_body)
      })
  }, [courseSyllabusReportDocumentId, dispatch])
  const courseSyllabusReportDocument = useSelector(state => state.CourseSyllabusReportDocument.courseSyllabusReportDocument)
  console.log(courseSyllabusReportDocument, "courseSyllabusReportDocument")

  const course = useSelector((state) => state.CourseSpec.courseSpecification);


  const multilineChanged = (e) => {
    setIsMultiline(e.value)
  }

  const valueChanged = (e) => {
    setValueContent(e.value)
  }

  const valueTypeChanged = (e) => {
    setEditorValueType(e.addedItems[0].text.toLowerCase())
  }

  //   const printDocument = () => {

  //     const doc = new jsPDF();

  //     let parser = new DOMParser();
  //     let parsedHtml = parser.parseFromString(HTMLTemplate && HTMLTemplate.html && HTMLTemplate.html, 'text/html');
  //     console.log("parsedHtml", parsedHtml)
  //     var html = htmlToPdfmake(parsedHtml.body.innerHTML);
  //     console.log("html", html)

  //     const documentDefinition = { content: html };
  //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
  //     pdfMake.createPdf(documentDefinition).open();

  //   }
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      updateCourseSyllabusReportDocument({
        id: courseSyllabusReportDocumentId,
        updatedCourseSyllabusReportDocument: {
          ressource: courseSyllabusReportDocument.ressource,
          ressourceId: courseSyllabusReportDocument.ressourceId,
          htmlTemplates_body: valueContent,
          name: courseSyllabusReportDocument.name,
          version: courseSyllabusReportDocument.version
        }
      })
    );
    if (updateCourseSyllabusReportDocument.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      //   form.resetFields();
      dispatch(fetchCourseSyllabusReportDocumentsByCourseSpecId(id));
      history.push(`/citrine/courseSpecDetails/${id}`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>

              {t("Specification")}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                style={{ color: "orange" }}
                to={`/citrine/courseSpecDetails/${id}`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>

              {t("Course Syllabus Report Document")}

            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Card>
        <CardBody>
          <div className="widget-container">
            <HtmlEditor
              height="725px"
              value={valueContent && valueContent}
              valueType={editorValueType}
              onValueChanged={valueChanged}

            >

              <MediaResizing enabled={true} />
              <Toolbar multiline={isMultiline}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item
                  name="size"
                  acceptedValues={sizeValues}
                />
                <Item
                  name="font"
                  acceptedValues={fontValues}
                />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item
                  name="header"
                  acceptedValues={headerValues}
                />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="link" />
                <Item name="image" />
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" />
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" />
                <Item name="deleteTable" />
                <Item name="insertRowAbove" />
                <Item name="insertRowBelow" />
                <Item name="deleteRow" />
                <Item name="insertColumnLeft" />
                <Item name="insertColumnRight" />
                <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>

            <div className="options">
              <div className="caption">Options</div>
              <div className="option">
                <CheckBox
                  text="Multiline toolbar"
                  value={isMultiline}
                  onValueChanged={multilineChanged}
                />
              </div>
            </div>


            {/* <div className="options">
        <ButtonGroup
          onSelectionChanged={valueTypeChanged}
          defaultSelectedItemKeys={defaultSelectedItemKeys}
        >
          <ButtonItem text="Html" />
          <ButtonItem text="Markdown" />
        </ButtonGroup>
        <div className="value-content">
          {valueContent && valueContent}
        </div>
      </div> */}
          </div>
        </CardBody>
      </Card>
      <Button color="primary" onClick={onFinishUpdate}>Save</Button>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />

    </div>
  );

}










