// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/assessmentMethodSlice";
import {
  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";


const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
// const classes = useStyles();
export default function CreateCourseImprovement({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);

  useEffect(() => { }, []);

  const titleClasses = useTitleStyles();
  const { id } = match.params;
  console.log("idCourse", id);
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [course_id, setCourse_id] = useState(id);
  const [stateObject, setStateObject] = useState("");
  const [comments, setComments] = useState("");
  const [due_Date, setDue_Date] = useState("");
  const [readers_ids, setReaders_ids] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("");

  const onTitleChanged = (e) => setTitle(e.target.value);
  const onCodeChanged = (e) => setCode(e.target.value);
  const onStateObjectChanged = (e) => setStateObject(e.target.value);
  const onCommentsChanged = (e) => setComments(e.target.value);
  const onDue_DateChanged = (e) => setDue_Date(e.target.value);
  const onReaders_idsChanged = (e) => setReaders_ids(e.target.value);
  const onCourse_idChanged = (e) => setCourse_id(e.target.value);

  const dispatch = useDispatch();
  const history = useHistory();

  const { Option } = Select;
  const canSave =
    [
      title,
      code,
      stateObject,
      stateObject,
      comments,
      due_Date,
      course_id,
      readers_ids,
    ].every(Boolean) && addRequestStatus === "idle";

  const onSaveActualLearningHourClicked = async () => {
    try {
      setAddRequestStatus("pending");
      console.log("onsave");
      const resultAction = await dispatch();
      //addNewActualLearningHour({ title, code, stateObject, stateObject, comments, due_Date, course_id, readers_ids })
      unwrapResult(resultAction);

      history.push(`/citrine/ActualLearningHourList/${id}`);

      const NewResultAction = await dispatch();
      // dispatch(fetchActualLearningHours(course && course.id && course.id))
      unwrapResult(NewResultAction);

      setTitle("");
      setCode("");
      setCourse_id("");
      setStateObject("");
      setDue_Date("");
      setReaders_ids("");
      setComments("");
    } catch (err) {
      console.error("Failed to save ModeOfInstruction: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };

  function onChangeStateObject(value) {
    //  console.log(`selected ${value}`);
    setStateObject(value);
  }

  function onBlur() {
    //   console.log('blur');
  }

  function onFocus() {
    //  console.log('focus');
  }

  function onSearch(val) {
    //  console.log('search:', val);
  }
  const courseStatus = useSelector((state) => state.course.courseStatus);

  const handleCourse_id = (id) => {
    setCourse_id(id);
    //   console.log('course_id', id)
    dispatch(fetchCourseById(id));
  };

  const course = useSelector((state) => state.CourseSpec.courseSpecification);

  function handleChangeClos(value) {
    //  console.log(`Selected: ${value}`);
    // setClo_ids(value);
  }
  function onChange(date, dateString) {
    //console.log(date, dateString);
    setDue_Date(dateString);
  }
  function onOk(value) {
    console.log("onOk: ", value);
  }
  return (
    <GridContainer>

    </GridContainer>
  )

}
