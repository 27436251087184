import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import { addNewCourseAssessmentMethod, fetchCourseAssessmentMethodById, updateCourseAssessmentMethod } from '../../reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSlice';
import { Link } from 'react-router-dom';
// core components
/// test 
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from 'react-router-dom';
import { SchoolRounded } from "@material-ui/icons";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";
import { Select } from 'antd';
import 'antd/dist/antd.css';
import MenuItem from "@material-ui/core/MenuItem";
import { fetchPloIndicatorById ,updatePloIndicator} from "reduxToolkit/features/PLOIndicator/PLOIndicatorsSlice";
import { fetchPlos } from "reduxToolkit/features/PLO/PLOsSlice";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker, Space } from 'antd';
import { fetchCourseAssessmentMethods } from "reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSlice";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import { Input } from 'antd';

import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));

export default function EditPloIndictorsModal(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const titleClasses = useTitleStyles();
    const dispatch = useDispatch()
    // console.log('props.PloIndictorID', props.PloIndictorID)
    // console.log('props.ProgramID', props.ProgramID)
    // console.log('props.PloID', props.PloID)


    useEffect(() => {
        dispatch(fetchPloIndicatorById(props.PloIndictorID)).then(unwrapResult)
            .then((PloIndictor) => {
                // console.log("PloIndictor", PloIndictor)
                setSatisfactory(PloIndictor.satisfactory)
                setDeveloping(PloIndictor.developing)
                setUnSatisfactory(PloIndictor.unSatisfactory)
            })
    }, [dispatch, PloIndictor])
    const PloIndictor = useSelector((state) => state.ploIndicator.ploIndicator)

    const [satisfactory, setSatisfactory] = useState("")
    const [developing, setDeveloping] = useState("")
    const [unSatisfactory, setUnSatisfactory] = useState("")
    const [plo_id, setPlo_id] = useState(props.PloID)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')


    const onSatisfactoryChanged = (e) => setSatisfactory(e.target.value)
    const onDevelopingChanged = (e) => setDeveloping(e.target.value)
    const onUnSatisfactoryChanged = (e) => setUnSatisfactory(e.target.value)


    const { Option } = Select;
    const [open, setOpen] = React.useState(false);

    const canSave =
        [satisfactory, developing, unSatisfactory, plo_id].every(Boolean) && addRequestStatus === 'idle'
    const history = useHistory();



    const onUpdatePloIndictorClicked = async () => {
        try {

            setAddRequestStatus('pending')
            const resultAction = await dispatch(
                updatePloIndicator({ id: props.PloIndictorID, updatedPloIndicator: { satisfactory, developing, unSatisfactory, plo_id } }),

            )
            unwrapResult(resultAction)
            setSatisfactory("")
            setDeveloping("")
            setUnSatisfactory("")
        } catch (err) {
        } finally {
            dispatch(fetchPlos(props.ProgramID))
            setAddRequestStatus('idle')
        }

    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <MenuItem
                onClick={(event) => {
                    handleClickOpen(event);
                }}>
                <EditIcon color="primary" fontSize="small"></EditIcon>
            </MenuItem>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">{t('Edit PLO Indicator')} </DialogTitle>
                <DialogContent>
                    <GridContainer >
                        <form>
                            <GridContainer xs={12} sm={12} md={12} >


                                <GridContainer xs={12} sm={12} md={12}   >

                                    <GridItem xs={12} sm={12} md={3}  >
                                        <label>{t('Satisfactory*')} </label>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={9}    >
                                        <TextArea rows={4}
                                            id="satisfactory"
                                            value={satisfactory}
                                            onChange={(event) => setSatisfactory(event.target.value)}
                                    
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer xs={12} sm={12} md={12}   >

                                    <GridItem xs={12} sm={12} md={3}  >
                                        <label>{t('Developing*')} </label>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={9}    >
                                        <TextArea rows={4}
                                            id="developing"
                                            value={developing}
                                            onChange={(event) => setDeveloping(event.target.value)}

                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer xs={12} sm={12} md={12}   >

                                    <GridItem xs={12} sm={12} md={3}  >
                                        <label>{t('UnSatisfactory*')} </label>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={9}    >
                                        <TextArea rows={4}
                                            id="unSatisfactory"
                                            value={unSatisfactory}
                                            onChange={(event) => setUnSatisfactory(event.target.value)}

                                        />
                                    </GridItem>
                                </GridContainer>

                            </GridContainer> 
                        </form>


                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="info">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={() => { onUpdatePloIndictorClicked(); handleClose() }} color="primary">
                        {t('Save')}
                    </Button>
                </DialogActions>

            </Dialog>

        </div>
    );


}



