import React, { useEffect, useState } from "react";
import "./FilesView.css";
import { useDispatch, useSelector } from "react-redux";
import FileCard from "../filecard/FileCard";
import Header from "../header/index";
import { getallDocs } from "reduxToolkit/features/docslice/DocSlice";
import ErrorModal from "../../Components/ErrorModal";
const FilesView = ({ ping, setPing, model, resource, resource_id, course_id }) => {
  const dispatch = useDispatch();
  // const model = "assessmentMethod";
  // const resource = "Quizz";
  // const resource_id = 6;
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const fetchDocsCustomFunction = async () => {
    try {
      const resultAction = await dispatch(getallDocs({ model, resource, resource_id, course_id })).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchDocsCustomFunction({ model, resource, resource_id, course_id })
  }, [dispatch, model, resource, resource_id, course_id]);
  const Documents = useSelector((state) => state.Doc.allDocuments);

  const [filter, setFilter] = useState("");
  return (
    <div className="fileView">
      <Header
        model={model}
        resource_id={resource_id}
        resource={resource}
        course_id={course_id}
        fetchDocsCustomFunction={fetchDocsCustomFunction}
        filter={filter}
        setFilter={setFilter}
        ping={ping}
        setPing={setPing}
      />
      <div className="fileView1">
        {Documents?.filter((el) =>
          el.name.toLowerCase().includes(filter.toLowerCase())
        ).map((el) => (
          <FileCard key={el._id} el={el} ping={ping} setPing={setPing} fetchDocsCustomFunction={fetchDocsCustomFunction}/>
        ))}
      </div>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
};

export default FilesView;
