// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Select, notification, Form } from "antd";

import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAssessmentMethodsByCourseId } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import useTable from "../../../../Components/useTable";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import EditActivitiesAssessmentMethodsDifficultiesAction from "../../../../Components/EditActivitiesAssessmentMethodsDifficultiesAction"
import {
  fetchConclusionsDifficultiesByCourseId_And_Type,
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";
import {
  primaryColor,
} from "../../../../../assets/jss/material-dashboard-pro-react"

const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 14,
    span: 0,
  },
};

const headCells = [
  { id: "Planned Assessment Methods", label: "Planned Assessment Methods" },
  { id: "Were They Implemented?", label: "Were They Implemented?" },
];

export default function AssessmentMethods(props) {

  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();

  const [records, setRecords] = [];

  const [open, setOpen] = useState(false);
  const [array_difficulties_SuggestedAction, setArray_difficulties_SuggestedAction] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [value, setValue] = useState(1);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [course_id, setCourse_id] = useState(props.idCourse);
  const [difficulties, setDifficulties] = useState("");
  const [suggested, setSuggested] = useState("");
  const [
    ativitiesAssessmentMethods_ids,
    setActivitiesAssessmentMethods_ids,
  ] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const ActivitiesAssessmentMethods = useSelector(
      (state) => state.AssessmentMethods.assessmentMethodsByCourseId
  );

  useEffect(() => {
    dispatch(fetchAssessmentMethodsByCourseId(props.idCourse))
        .then(unwrapResult)
        .then((ActivitiesAssessmentMethods) => {
          setActivitiesAssessmentMethods_ids(
              ActivitiesAssessmentMethods.map((el) => el.id)
          );
        });
  }, []);

  useEffect(() => {
    dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
        .then(unwrapResult)
        .then((difficulties_SuggestedAction) => {
          setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
        });
  }, []);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDifficultiesChanged = (e) => setDifficulties(e.target.value);
  const onSuggestedChanged = (e) => setSuggested(e.target.value);
  const { Option } = Select;
  const canSave = [difficulties, suggested].every(Boolean) && addRequestStatus === "idle";

  let object={
      course_id: props.idCourse,
        type: "AM",
  }

    const fetchConclusionsDifficulties =()=>{
      dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
      .then(unwrapResult)
        .then((difficulties_SuggestedAction) => {
          setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
        });
    }

    const difficulties_SuggestedAction = useSelector(
      (state) => state.ConclusionsDifficulties.ConclusionsDifficultiesList
    );

  return (
    <GridContainer xs={12} sm={12} md={12}>
      <div
        //  class="card" class="scrollable"
        style={{ width: "100%" }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
            <TblHead />
            <TableBody>
              {ActivitiesAssessmentMethods &&
                ActivitiesAssessmentMethods.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell style={{ borderLeft: "1px solid lightgrey" }}>
                      {item &&
                        item.exam &&
                        item.exam}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid lightgrey",
                        borderLeft: "1px solid lightgrey",
                      }}
                    >
                      {item && item.implemented && item.implemented}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </TblContainer>
        </GridItem>
        <Card style={{ marginRight:"10px"}}>
        <CardBody>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={10}>
            <p style={{ height: "150px" }}>
              <strong style={{color:primaryColor[0]}}>Difficulties Experienced</strong> :
              <p>{array_difficulties_SuggestedAction && array_difficulties_SuggestedAction[0] && array_difficulties_SuggestedAction[0].difficulties}</p>
              </p>
              <p style={{ height: "150px" }}>  <strong style={{color:primaryColor[0]}}>Suggested Action</strong> :
              <p>{array_difficulties_SuggestedAction && array_difficulties_SuggestedAction[0] && array_difficulties_SuggestedAction[0].suggested}</p>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} style={{textAlign:"end"}}>
              <EditActivitiesAssessmentMethodsDifficultiesAction idCourse= {props.idCourse} fetchConclusionsDifficulties={fetchConclusionsDifficulties} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      </div>
    </GridContainer>
  );
}
