import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
// @material-ui/icons
import { addNewOutGoingMailServer, fetchOutGoingMailServerById, outGoingMailServerUpdate } from "../../../reduxToolkit/features/outgoingMailServer/OutGoingMailServerSlice";


const useStyles = makeStyles(styles);

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
// const classes = useStyles();
export default function AddEditOutGoingMailServer({ match }) {
    const { t } = useTranslation();
    const { id } = match.params

    console.log('id', id)

    const isAddMode = isNaN(id);
    console.log('id from match is add mode', isAddMode)
    const classes = useStyles();
    const useTitleStyles = makeStyles(titleStyles);

    const titleClasses = useTitleStyles();
    const [name, setName] = useState('')
    const [active, setActive] = useState(true)
    const [display_name, setDisplay_name] = useState('')
    const [from_filter, setFrom_filter] = useState('')
    const [sequence, setSequence] = useState('')
    const [smtp_debug, setSmtp_debug] = useState(true)
    const [smtp_host, setSmtp_host] = useState('')
    const [smtp_pass, setSmtp_pass] = useState('')
    const [smtp_port, setSmtp_port] = useState('')
    const [smtp_user, setSmtp_user] = useState('')
    const [smtp_authentication, setSmtp_authentication] = useState('')
    const [smtp_encryption, setSmtp_encryption] = useState('')
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const onNameChanged = (e) => setName(e.target.value)
    const onSequenceChanged = (e) => setSequence(e.target.value)
    const onDisplay_nameChanged = (e) => setDisplay_name(e.target.value)
    const onActiveChanged = (e) => setActive(e.target.value)
    const onFrom_filterChanged = (e) => setFrom_filter(e.target.value)
    const onSmtp_debugChanged = (e) => setSmtp_debug(e.target.value)
    const onSmtp_hostChanged = (e) => setSmtp_host(e.target.value)
    const onSmtp_passChanged = (e) => setSmtp_pass(e.target.value)
    const onSmtp_portChanged = (e) => setSmtp_port(e.target.value)
    const onSmtp_userChanged = (e) => setSmtp_user(e.target.value)
    const onSmtp_authenticationChanged = (e) => setSmtp_authentication(e.target.value)
    const onSmtp_encryptionChanged = (e) => setSmtp_encryption(e.target.value)


    const dispatch = useDispatch()

    const canSave =
        [name, display_name, active, sequence, from_filter, smtp_host, smtp_pass, smtp_debug, smtp_user, smtp_encryption, smtp_authentication, smtp_port].every(Boolean) && addRequestStatus === 'idle'
    const history = useHistory();

    const onSaveOutGoingMailServerClicked = async () => {

        // if (canSave) {
        try {
            setAddRequestStatus('pending')
            console.log('onsave')
            const resultAction = await dispatch(
                addNewOutGoingMailServer({ name, display_name, active, sequence, from_filter, smtp_host, smtp_pass, smtp_debug, smtp_user, smtp_encryption, smtp_authentication, smtp_port })
            )
            unwrapResult(resultAction)
            setName('')
            setDisplay_name('')
            setActive('')
            setSequence('')
            setFrom_filter('')
            setSmtp_authentication('')
            setSmtp_debug('')
            setSmtp_encryption('')
            setSmtp_host('')
            setSmtp_pass('')
            setSmtp_user('')
            setSmtp_port('')
            history.push('/citrine/outGoingMailServers');
        } catch (err) {
            console.error('Failed to save the outGoingMailServer: ', err)
        } finally {
            setAddRequestStatus('idle')
        }


    }
    const onUpdateClicked = async () => {

        //  if (canSave) {
        try {
            setAddRequestStatus('pending')

            const resultAction = await dispatch(
                outGoingMailServerUpdate({ id: id, modified: { name, display_name, active, sequence, from_filter, smtp_host, smtp_pass, smtp_debug, smtp_user, smtp_encryption, smtp_authentication, smtp_port } })
            )
            unwrapResult(resultAction)
            setName('')
            setDisplay_name('')
            setActive('')
            setSequence('')
            setFrom_filter('')
            setSmtp_authentication('')
            setSmtp_debug('')
            setSmtp_encryption('')
            setSmtp_host('')
            setSmtp_pass('')
            setSmtp_user('')
            setSmtp_port('')
            history.push('/citrine/outGoingMailServers');
        } catch (err) {
            console.error('Failed to update the outGoingMailServers: ', err)
        } finally {
            setAddRequestStatus('idle')
        }
        //   }

    }

    const [checkedActive, setCheckedActive] = React.useState();
    const handleChangeActive = (event) => {
        setCheckedActive(event.target.checked);
        setActive(event.target.checked)
    };
    const [checkedDebugActive, setCheckedDebugActive] = React.useState();
    const handleChangeDebugActive = (event) => {
        setCheckedDebugActive(event.target.checked);
        setSmtp_debug(event.target.checked);
    };



    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    useEffect(() => {
        if (!isAddMode) {
            dispatch(fetchOutGoingMailServerById(id))
        }
    }, [id]);

    const outGoingMailServer = useSelector((state) => state.OutGoingMailServer.outGoingMailServer)
    console.log('outGoingMailServers ', outGoingMailServer)

    useEffect(() => {
        if (!isAddMode) {
            setName(outGoingMailServer && outGoingMailServer.name)
            setDisplay_name(outGoingMailServer && outGoingMailServer.display_name)
            setActive(outGoingMailServer && outGoingMailServer.active)
            setSequence(outGoingMailServer && outGoingMailServer.sequence)
            setFrom_filter(outGoingMailServer && outGoingMailServer.from_filter)
            setSmtp_authentication(outGoingMailServer && outGoingMailServer.smtp_authentication)
            setSmtp_debug(outGoingMailServer && outGoingMailServer.smtp_debug)
            setSmtp_encryption(outGoingMailServer && outGoingMailServer.smtp_encryption)
            setSmtp_host(outGoingMailServer && outGoingMailServer.smtp_host)
            setSmtp_pass(outGoingMailServer && outGoingMailServer.smtp_pass)
            setSmtp_user(outGoingMailServer && outGoingMailServer.smtp_user)
            setSmtp_port(outGoingMailServer && outGoingMailServer.smtp__port)
        }
    }, [outGoingMailServer, dispatch]);

    return (


        <div>
            {isAddMode &&
                <div>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <SchoolRounded />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        <span className={titleClasses.title}>      <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/outgoingMailServers`}>{t(' /Out Going Mail Servers')}</Link>{t(' /Create')} </span>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <GridContainer xs={12} sm={12} md={6} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <h5 style={{ fontSize: "15px" }}>{t('General Information')}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Description ")}
                                                            id="name"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="name"
                                                            value={name}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setName(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Display Name *")}

                                                            id="display_name"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="display_name"
                                                            value={display_name}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setDisplay_name(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Priority *")}
                                                            id="sequence"
                                                            //  onChange={onNameChanged}
                                                            type="integer"
                                                            name="sequence"
                                                            value={sequence}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSequence(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("From filter ")}
                                                            id="from_filter"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="from_filter"
                                                            value={from_filter}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setFrom_filter(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={8} >

                                                        <FormControlLabel control={<Checkbox defaultChecked checked={checkedActive} onChange={handleChangeActive} />} label="Active" />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer xs={12} sm={12} md={6} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <h5 style={{ fontSize: "15px" }}>{t('Connexion Information')}</h5>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <CustomInput
                                                            labelText={t("SMTP Server ")}
                                                            id="smtp_host"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="smtp_host"
                                                            value={smtp_host}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSmtp_host(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("SMTP Pass ")}
                                                            id="smtp_pass"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="smtp_pass"
                                                            value={smtp_pass}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSmtp_pass(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("SMTP Port ")}
                                                            id="smtp_port"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="smtp_port"
                                                            value={smtp_port}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSmtp_port(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("SMTP User")}
                                                            id="smtp_user"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="smtp_user"
                                                            value={smtp_user}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSmtp_user(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("SMTP Authentication ")}
                                                            id="smtp_authentication"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="smtp_authentication"
                                                            value={smtp_authentication}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSmtp_authentication(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("SMTP Encryption ")}
                                                            id="smtp_encryption"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="smtp_encryption"
                                                            value={smtp_encryption}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSmtp_encryption(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <FormControlLabel control={<Checkbox defaultChecked checked={checkedDebugActive} onChange={handleChangeDebugActive} />} label="Debugging" />
                                                    </GridItem>
                                                </GridContainer>
                                            </GridContainer>



                                            <GridItem>
                                                <Button color="primary"
                                                    className={classes.updateProfileButton}
                                                    onClick={onSaveOutGoingMailServerClicked}
                                                >
                                                    {t(' Save')}
                                                </Button></GridItem>


                                        </GridContainer>
                                    </form>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            }
            {!isAddMode && outGoingMailServer &&
                <div>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <SchoolRounded />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        <span className={titleClasses.title}>      <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/resourceTypeConfigs`}>{t(' /Assessment Method')}</Link> {t('/Edit')} </span>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        {/* <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}> */}
                                        {/* <GridItem xs={6} sm={6} md={6}>
        
         
            <CustomInput
              labelText="{t('Name *')}"
              id="name"
              //  onChange={onNameChanged}
              type="text"
              name="name"
              value={name}
              rules={[
                {
                  required: true,
                  message: 'Please enter a name!',
                },
              ]}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: false,
                onChange: event => {
                  setName(event.target.value);
                },
                value: name
              }}
            />
      
                                  <label>{t('Assessment Mode *')} </label>
                                  <Select
                                          
                                          showSearch
                                          style={{ width: '100%' }}
                                          size={200}
                                          optionFilterProp="children"
                                          name="mode_id"
                                          value={modeSpec_id}
                                          onChange={onChangeMode_id}
                                          onFocus={onFocus}
                                          onBlur={onBlur}
                                          onSearch={onSearch}
                                          filterOption={(input, option) =>
                                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                          }
                                      >

                                          {modes.map(r => (
                                                  <option key={r.id} value={r.id}>
                                                      {r.name}
                                                  </option>
                                              ))

                                      
                                          }

                                      </Select>
                                  </GridItem>  */}
                                        <GridItem>
                                            <Button color="primary"
                                                className={classes.updateProfileButton}
                                                onClick={onUpdateClicked}
                                            >
                                                {t(' Update')}
                                            </Button></GridItem>


                                        {/* </GridContainer>  */}
                                    </form>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>


                </div>
            }
        </div>

    );



}


