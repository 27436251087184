// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import styles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";





















const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'fit-content',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
}));
const useStyles1 = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));
const useStyles2 = makeStyles(styles)
const useCardStyles = makeStyles(cardStyles);


export default function AccreditationPrograms(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const classes1 = useStyles1();
    const classes2 = useStyles2();
    const classes3 = useStyles3();
    const dispatch = useDispatch();



    useEffect(() => {

    }, [dispatch]);

    const callback = (key) => {
        console.log(key);
    }


    return (
        <div>

            <GridContainer xs={12} sm={12} md={12}>
                <GridItem key={Element.id} xs={12} sm={12} md={6} >
                    <Link to={`/citrine/ProgramAccreditationDetails/`} >
                        <Card product className={classes.cardHover} style={{ width: 450, height: 200 }} >

                            <CardBody>
                                <Link to={`/citrine/ProgramAccreditationDetails/`} >
                                    <h4 style={{ marginTop: "10%", textAlign: "center", fontSize: "22px", color: "#D1CF4B" }}>
                                        <strong>{t('Computer Networks and Communications Engineering')}</strong>
                                    </h4>
                                </Link>

                            </CardBody>
                        </Card>
                    </Link>
                </GridItem>
                <GridItem key={Element.id} xs={12} sm={12} md={6} >

                    <Card product className={classes.cardHover} style={{ width: 450, height: 200 }} >

                        <CardBody>
                            <Link to={`/citrine/organizations/universities`} >
                                <h4 style={{ marginTop: "10%", textAlign: "center", fontSize: "22px", color: "#D1CF4B" }}>
                                    <strong> {t('Mechanical Engineering')}</strong>
                                </h4>
                            </Link>

                        </CardBody>
                    </Card>

                </GridItem>
            </GridContainer>
        </div>
    )
}
