import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  fetchCourseNextPage,
  fetchCourses,
  fetchCoursesByPage,
} from "../../../../reduxToolkit/Course/coursesSpecSlice";
import { fetchCourseByTitle } from "../../../../reduxToolkit/Course/coursesSpecSlice.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import DropDownButtonCourse from "../../../../views/Components/DropDownButtonCourse";

import {
  grayColor,
  primaryColor,
  successColor,
} from "../../../../assets/jss/material-dashboard-pro-react";

import ErrorModal from "../../../Components/ErrorModal";

import CardFooter from "components/Card/CardFooter";
import { useTranslation } from "react-i18next";

import Avatar from "@mui/material/Avatar";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import CardHeader from "../../../../components/Card/CardHeader";
import cardStyles from "../../../../assets/jss/material-dashboard-pro-react/components/cardStyle";
import Pagination from "@mui/material/Pagination";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { notification } from "antd";
import {
  synchSpecCoursesWithLMS,
  synchSpecAssessmentMethodsWithLMS,
  synchSpecCLOsWithLMS,
  synchDataWithLMS,
} from "../../../../reduxToolkit/features/LMSIntegration/LMSIntegrationSlice";

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "rgb(0,0,0,0.7)",
    display: "flex",
    flexDirection: "column",
  },
}));

const openNotificationWithIcon = (type, msg) => {
  notification[type]({
    message: type,
    description: msg,
    top: 50,
    duration: 2,
  });
};

const CourseExcerpt = ({
  course,
  isEditor,
  staffList,
  ping,
  setPing,
  pingUseEffect,
  setPingUseEffect,
}) => {
  const { t } = useTranslation();
  const cardClasses = useCardStyles();

  return (
    <GridItem xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card style={{ minHeight: "380px", maxHeight: "380px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/courseSpecDetails/${course.id}`}>
                <h4 className={cardClasses.cardProductTitle}>{course.title}</h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              {isEditor && (
                <DropDownButtonCourse
                  courseID={course && course.id}
                  data={course}
                  id={course && course.id}
                  staffList={staffList}
                  ping={ping}
                  setPing={setPing}
                  pingUseEffect={pingUseEffect}
                  setPingUseEffect={setPingUseEffect}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Code")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                <Link to={`/citrine/courseSpecDetails/${course.id}`}>
                  {course.code}
                </Link>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Description")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div
                style={{
                  color: `${grayColor[1]}`,
                  display: "flex",
                  gap: "3px",
                }}
              >
                {`${course.description.substring(0, 300)}...`}
              </div>
            </GridItem>
          </GridContainer>
          {course && course.coordinator_id != null && (
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}
                >
                  {t(" Coordinator")}:
                </div>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <div style={{ color: `${grayColor[1]}` }}>
                  {course && course.coordinator_id != null ? (
                    ` ${course && course.coordinator_detail[0].first_name} ${
                      course && course.coordinator_detail[0].last_name
                    }`
                  ) : (
                    <Avatar
                      src="/broken-image.jpg"
                      sx={{ bgcolor: "red", width: 24, height: 24 }}
                    />
                  )}
                </div>
              </GridItem>
            </GridContainer>
          )}
        </CardBody>
        <CardFooter>
          <GridContainer justifyContent="space-between">
            <GridItem>
              <span
                style={{
                  color: grayColor[1],
                  fontWeight: "bold",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                V {course.version}
              </span>
            </GridItem>
            <GridItem>
              <span
                style={{
                  backgroundColor:
                    course.state === "Approved"
                      ? successColor[7]
                      : grayColor[4],
                  padding: 5,
                  marginRight: 5,
                  marginBottom: 5,
                  borderRadius: "10px",
                  color: grayColor[1],
                }}
              >
                {course.state}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function CourseSpecificationList() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const backdropClasses = useBackdropStyles();

  const coursesSpecificationStatus = useSelector(
    (state) => state.CourseSpec.coursesSpecificationStatus
  );

  const [isEditor, setEditor] = useState(true);
  const [search, setSearch] = useState("");
  const [ping, setPing] = useState(false);
  const [pingUseEffect, setPingUseEffect] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [searchFiltred, setSearchFiltred] = useState("");
  const [searchByDrop, setSearchByDrop] = useState("All Courses");
  const [currentState, setCurrentState] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const staffList = useSelector((state) => state.user.staffList);
  const courses = useSelector((state) => state.CourseSpec.coursesSpecification);
  const coursesByPage = useSelector(
    (state) => state.CourseSpec.coursesSpecificationByPage
  );

  useEffect(() => {
    dispatch(fetchStaff());
  }, []);

  useEffect(() => {
    dispatch(fetchCourseByTitle(search));
  }, [search]);

  useEffect(() => {
    let data = {
      page: page,
      records: rowsPerPage,
    };
    fetchCourseByPageList(data);
  }, []);

  const states = [
    { label: "All Courses" },
    { label: "Draft" },
    { label: "Waiting for approval" },
    { label: "Approved" },
    { label: "Archived" },
  ];

  const substring = "/api";

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCourseByPageList = async (data) => {
    try {
      const courses = await dispatch(fetchCoursesByPage(data)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const onhandleChangedrop = (text) => {
    setSearchByDrop(text);
  };

  const dropdownfilter = coursesByPage?.results?.filter((program) => {
    if (searchByDrop === "Approved") {
      return program.state === "Approved";
    } else if (searchByDrop === "New") {
      return (
        program.state === "Draft" || program.state === "Waiting for approval"
      );
    } else if (searchByDrop === "Archived") {
      return program.state === "Archived";
    } else {
      return program;
    }
  });

  let content;
  if (coursesSpecificationStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (coursesSpecificationStatus === "succeeded") {
    if (coursesByPage && coursesByPage === "You do not have permission") {
      let coursesByPage = [];
      content = <div>You do not have permission to access courses list</div>;
    } else if (search == "") {
      content =
        coursesByPage &&
        coursesByPage &&
        coursesByPage.results
          ?.filter((course) => {
            // no search key  & no  selected states
            if (search === "" && !currentState) {
              return course;
            } else if (
              currentState &&
              course.state.toLowerCase().includes(currentState.toLowerCase()) &&
              course.title.toLowerCase().includes(search.toLowerCase())
            ) {
              return course;
            } else if (currentState && !search) {
              return course;
            } else if (
              currentState &&
              search &&
              course.title.toLowerCase().includes(search.toLowerCase())
            ) {
              return course;
            } else if (
              !currentState &&
              course.title.toLowerCase().includes(search.toLowerCase())
            ) {
              return course;
            }
          })
          .map((course) => (
            <CourseExcerpt
              key={course.id}
              course={course}
              isEditor={isEditor}
              staffList={staffList}
              pingUseEffect={pingUseEffect}
              setPingUseEffect={setPingUseEffect}
            />
          ));
    } else {
      content =
        courses &&
        courses &&
        courses
          .filter((course) => {
            if (search === "" && !currentState) {
              return course;
            } else if (
              currentState &&
              course.state.toLowerCase().includes(currentState.toLowerCase()) &&
              course.title.toLowerCase().includes(search.toLowerCase())
            ) {
              return course;
            } else if (
              currentState &&
              !search &&
              currentState.includes("All Courses")
            ) {
              return course;
            } else if (
              currentState &&
              search &&
              course.title.toLowerCase().includes(search.toLowerCase()) &&
              currentState.includes("All Courses")
            ) {
              return course;
            } else if (
              !currentState &&
              course.title.toLowerCase().includes(search.toLowerCase())
            ) {
              return course;
            }
          })
          .map((course) => (
            <CourseExcerpt
              key={course.id}
              course={course}
              isEditor={isEditor}
              staffList={staffList}
              pingUseEffect={pingUseEffect}
              setPingUseEffect={setPingUseEffect}
            />
          ));
    }
  }

  const titleClasses = useTitleStyles();

  const filteredContent = dropdownfilter?.filter((item) => {
    return searchFiltred.toLowerCase() === ""
      ? item
      : item?.title?.toLowerCase()?.includes(searchFiltred);
  });

  const filteredContentt = (
    <GridContainer>
      {filteredContent?.map((course) => (
        <CourseExcerpt
          key={course.id}
          course={course}
          isEditor={isEditor}
          staffList={staffList}
          ping={ping}
          setPing={setPing}
          pingUseEffect={pingUseEffect}
          setPingUseEffect={setPingUseEffect}
        />
      ))}
    </GridContainer>
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const previousPage = () => {
    if (coursesByPage.previous !== null) {
      const index =
        coursesByPage &&
        coursesByPage.previous &&
        coursesByPage.previous.indexOf(substring);
      const previous =
        coursesByPage &&
        coursesByPage.previous &&
        coursesByPage.previous.slice(0, index) + coursesByPage.previous &&
        coursesByPage.previous.slice(index + 4);
      dispatch(fetchCourseNextPage(previous));
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (coursesByPage.next !== null) {
      const index = coursesByPage && coursesByPage.next.indexOf(substring);
      const previous =
        coursesByPage &&
        coursesByPage.next.slice(0, index) + coursesByPage.next &&
        coursesByPage.next.slice(index + 4);
      dispatch(fetchCourseNextPage(previous));
      setPage(page + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    let data = {
      page: 1,
      records: event.target.value,
    };
    fetchCourseByPageList(data);
  };

  const { pullDataLoading, pullingCourses, pullingAssessments, pullingCLOs } =
    useSelector((state) => state.LMSIntegration);

  const sychWithLMS = async () => {
    console.log("LMS");
    try {
      const synchSpecDataDagState = await dispatch(synchDataWithLMS()).unwrap();
      // handle result here
      if (synchSpecDataDagState === "succeeded") {
        openNotificationWithIcon("success", `Synchronized data successfully`);
      } else {
        openNotificationWithIcon("error", `Data synchronization failed`);
      }
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.log("error in component", rejectedValueOrSerializedError);
      setHandledError(rejectedValueOrSerializedError);
      handleOpenErrorModal();
    }
  };
  return (
    <>
      <Backdrop
        className={backdropClasses.backdrop}
        open={pullDataLoading === "pending"}
      >
        <CircularProgress color="inherit" />
        <br />
        <br />

        <div>
          {pullingCourses === "pending" && (
            <p>Pulling courses from LMS, this may take a few seconds...</p>
          )}
          {pullingAssessments === "pending" && (
            <p>Pulling assessments from LMS, this may take a few seconds...</p>
          )}
          {pullingCLOs === "pending" && (
            <p>Pulling CLOs from LMS, this may take a few seconds...</p>
          )}
        </div>
      </Backdrop>
      <GridContainer
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
          minHeight: "50px",
          maxHeight: "50px",
        }}
        alignItems="center"
      >
        <GridItem>
          <Link to={`/citrine/CourseSpecificationList/`}>
            <span className={titleClasses.title}> {t("Courses")} </span>
          </Link>
        </GridItem>
      </GridContainer>

      <GridContainer justifyContent="space-between" alignItems="center">
        <GridItem>
          <GridContainer justifyContent="flex-start" alignItems="center">
            {isEditor && (
              <GridItem>
                <Link to="/citrine/addCourseSpec">
                  <Button color="primary">{t("Add")}</Button>
                </Link>
              </GridItem>
            )}
            <GridItem>
              <CustomDropdown
                buttonProps={{ color: "primary" }}
                buttonText="Sychronize with"
                dropdownList={[
                  {
                    text: "LMS",
                    onClickHandler() {
                      sychWithLMS();
                    },
                  },
                  {
                    text: "SIS",
                    onClickHandler() {
                      console.log("SIS");
                    },
                  },
                  {
                    text: "CSV",
                    onClickHandler() {
                      console.log("CSV");
                    },
                  },
                ]}
              ></CustomDropdown>
            </GridItem>
            <GridItem>
              <CustomDropdown
                buttonProps={{ color: "primary" }}
                buttonText="Import from"
                dropdownList={[
                  {
                    text: "LMS",
                    onClickHandler() {
                      history.push(`/citrine/courses/importFromLMS`);
                    },
                  },
                  {
                    text: "SIS",
                    onClickHandler() {
                      console.log("SIS");
                    },
                  },
                  {
                    text: "CSV",
                    onClickHandler() {
                      console.log("CSV");
                    },
                  },
                ]}
              ></CustomDropdown>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem>
          <GridContainer alignItems="center" justifyContent="flex-end">
            <GridItem>
              <input
                placeholder="Search"
                style={{
                  backgroundColor: "#eeeeee",
                  border: "none",
                  borderBottom: "1px solid #BDBDBD",
                  marginRight: "50px",
                  width: "250px",
                  height: "30px",
                }}
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </GridItem>
            <GridItem>
              <CustomDropdown
                buttonProps={{ color: "primary" }}
                buttonText={searchByDrop}
                dropPlacement="bottom-end"
                dropdownList={[
                  {
                    text: "New",
                    onClickHandler() {
                      onhandleChangedrop("New");
                    },
                  },
                  {
                    text: "Approved",
                    onClickHandler() {
                      onhandleChangedrop("Approved");
                    },
                  },
                  {
                    text: "Archived",
                    onClickHandler() {
                      onhandleChangedrop("Archived");
                    },
                  },
                  {
                    text: "All Programs",
                    onClickHandler() {
                      onhandleChangedrop("All Programs");
                    },
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {filteredContentt}
      <GridContainer justifyContent="flex-end">
        {coursesByPage?.count > 0 && (
          <Pagination
            color={"secondary"}
            count={Math.ceil(coursesByPage?.count / rowsPerPage)}
            variant="outlined"
            page={page}
            onChange={handleChangePage}
          />
        )}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
