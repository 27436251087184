import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {addCourseSection, fetchCourses} from "../../../../reduxToolkit/features/course/CoursesSlice.js";

const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

export default function AddSectionConfirmation(props) {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [handledError, setHandledError] = useState("");
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const refreshCourses = () => {
        dispatch(fetchCourses())
            .then(unwrapResult)
            .then((courses) => {
                setRows(courses);
            });
        setSelectionModel([])
    };

    const onFinishFailed = (errorInfo) => {};

    const onFinish = async () => {
        let courses_ids = props.selectedRows.join();
        const resultAction = await dispatch(addCourseSection({ courses_ids }));
        if (addCourseSection.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            props.refreshCourses();
            openNotificationWithIcon("success", `Added`);
            handleClose();
            refreshCourses();
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        }
    };

    return (
        <div>
            <MenuItem
                disabled={!props.selectedRows.length}
                onClick={() => {
                    handleClickOpen();
                    props.handleDropDownButtonClose();
                }}
            >
                {t("Add Section")}
            </MenuItem>
            <Dialog
                open={open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth="true"
            >
                <DialogTitle id="form-dialog-title"> <center>Add section</center></DialogTitle>
                <DialogContent>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError
                    >
                        <GridContainer justifyContent="center">
                            <GridItem>
                                <center>
                                    <h4> {t("Are you sure you want to add section of these courses ?")}</h4>
                                </center>
                            </GridItem>
                            <Form.Item>
                                <GridContainer>
                                    <GridItem >
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                handleClose();
                                            }}
                                        >
                                            {t("Cancel")}
                                        </Button>
                                    </GridItem>
                                    <GridItem >
                                        <Button type="submit" color="primary">
                                            {t("Yes")}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </Form.Item>
                        </GridContainer>
                    </Form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
