import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  groups: [],
  examGroups: [],
  groupsByAssessment: [],
  groupsLinkedToCourse: [],
  groupStatus: 'idle',
  groupsStatus: 'idle',
  error: null,
  group: null,
  groupsByProgramStatus: 'idle',
  groupsByProgram: [],
  groupAssessmentStatus: 'idle',
  groupAssessments: [],
  groupAssessment: null,
}

export const fetchGroups = createAsyncThunk(
  'group/fetchGroups',
  async (obj) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl + `/groupAssessment/?course_id=${obj.course_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    )
    //  console.log(response.data, "response.data of groups");

    return response.data
  },
)

export const fetchExamGroupsAssessment = createAsyncThunk(
  'group/fetchExamGroupsAssessment',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(
        BaseUrl + `/courseInstructors/ExamGroups/?course_id=${obj.course_id}&assessmentMethod_id=${obj.assessmentMethod_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchGroupsAssessment = createAsyncThunk(
  'group/fetchGroupsAssessment',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(
        BaseUrl + `/courseInstructors/allGroups/?course_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addGroupsLinkedToCourse = createAsyncThunk(
  'group/addGroupsLinkedToCourse',
  async (obj) => {
    const token = localStorage.getItem('token')
    const { data } = await axios.post(
      BaseUrl + `/groupAssessment/byCourse/?resource_id=${obj.course_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    )
    return data
  },
)

export const addNewGroup = createAsyncThunk(
  'group/addNewGroup',
  async (bodyGroup, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + '/attachment/planning_group/upload/',
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data of groups')

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const affectNewGroup = createAsyncThunk(
  'group/affectNewGroup',
  async (bodyGroup, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + '/groupAssessment/affectExisting/',
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchGroupAssessmentById = createAsyncThunk(
  'group/fetchGroupAssessmentById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/groupAssessment/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      console.log('response.data', response.data)
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const getGroupsByAssessmentMethodAndCourse = createAsyncThunk(
  'group/getGroupsByAssessmentMethodAndCourse',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl +
        `/groupAssessment/ByAssessmentMethodAndCourse/?course_id=${data.course_id}&assessmentMethod_id=${data.assessmentMethod_id}&semester_id=${data.semester_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const addNewGroupAssessment = createAsyncThunk(
  'group/addNewGroupAssessment',
  async (bodyGroup, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + '/groupAssessment/create/',
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response ', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const deleteGroupAssessment = createAsyncThunk(
  'group/deleteGroupAssessment',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(
        BaseUrl + `/groupAssessment/${id}/delete/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response ', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const UpdateGroup = createAsyncThunk(
  'group/UpdateGroup',
  async (bodyGroup, { rejectWithValue }) => {
    //const objGroup = {};
    //bodyGroup.forEach((value, key) => (objGroup[key] = value));
    //console.log(objGroup);
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(
        BaseUrl + `/groupAssessment/${bodyGroup.id}/edit/`,
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data of groups')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const getGroupsByProgram = createAsyncThunk(
  'group/getGroupsByProgram',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/groupAssessment/byProgram/?program_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log('response.data', response.data)
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

const groupsSlice = createSlice({
  name: 'group',
  initialState,
  extraReducers: {
    [fetchExamGroupsAssessment.fulfilled]: (state, action) => {
      state.examGroups = action.payload
    },
    [fetchGroups.pending]: (state, action) => {
      state.groupsStatus = 'loading'
    },
    [fetchGroups.fulfilled]: (state, action) => {
      state.groupsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.groups = action.payload
    },
    [fetchGroups.rejected]: (state, action) => {
      state.groupsStatus = 'failed'
      state.error = action.payload
    },
    [fetchGroupsAssessment.fulfilled]: (state, action) => {
      state.groupsByAssessment = action.payload
    },
    [addGroupsLinkedToCourse.fulfilled]: (state, action) => {
      //state.groupsLinkedToCourse = action.payload;
    },

    [getGroupsByProgram.pending]: (state, action) => {
      state.groupsByProgramStatus = 'loading'
    },
    [getGroupsByProgram.fulfilled]: (state, action) => {
      state.groupsByProgramStatus = 'succeeded'
      // Add any fetched posts to the array
      state.groupsByProgram = action.payload
    },
    [getGroupsByProgram.rejected]: (state, action) => {
      state.groupsByProgramStatus = 'failed'
    },

    [fetchGroupAssessmentById.pending]: (state, action) => {
      state.groupAssessmentStatus = 'loading'
    },
    [fetchGroupAssessmentById.fulfilled]: (state, action) => {
      state.groupAssessmentStatus = 'succeeded'
      // Add any fetched posts to the array
      state.groupAssessment = action.payload
    },
    [fetchGroupAssessmentById.rejected]: (state, action) => {
      state.groupAssessmentStatus = 'failed'
    },
    // [addNewStudent.fulfilled]: (state, action) => {},

    [addNewGroup.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    [addNewGroupAssessment.pending]: (state, action) => {
      state.groupAssessmentStatus = 'loading'
    },
    [addNewGroupAssessment.fulfilled]: (state, action) => {
      state.groupAssessmentStatus = 'succeeded'
      //state.groupAssessments.push(action.payload)
    },
    [addNewGroupAssessment.rejected]: (state, action) => {
      state.groupAssessmentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [getGroupsByAssessmentMethodAndCourse.pending]: (state, action) => {
      state.groupAssessmentStatus = 'loading'
    },
    [getGroupsByAssessmentMethodAndCourse.fulfilled]: (state, action) => {
      state.groupAssessmentStatus = 'succeeded'
      //state.groupAssessments.push(action.payload);
      state.groupAssessments = action.payload
    },
    [getGroupsByAssessmentMethodAndCourse.rejected]: (state, action) => {
      state.groupAssessmentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [UpdateGroup.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    // [addNewCourseStudent.fulfilled]: (state, action) => {},
  },
})

export default groupsSlice.reducer
// export const  groups = state => state.group.groups
