import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'


const initialState = {
    plannedAccreditationDates: [],
    plannedAccreditationDatesStatus: 'idle',
    error: null,
}

export const fetchPlannedAccreditationDates = createAsyncThunk('plannedAccreditationDate/fetchPlannedAccreditationDates', async (parameter, { rejectWithValue }) => {
    try {

        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + '/plannedAccreditation/',
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }

        )
        console.log(response.data, 'response.data')
        return response.data

    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})


export const fetchPlannedAccreditationDatesByProgramId = createAsyncThunk('plannedAccreditationDate/fetchPlannedAccreditationDatesByProgramId', async (id, { rejectWithValue }) => {
    try {

        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + `/plannedAccreditation/?programSpec_id=${id}`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        // console.log(response.data,'response.data')

        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})




export const fetchPlannedAccreditationDateById = createAsyncThunk('plannedAccreditationDate/fetchPlannedAccreditationDateById', async (id, { rejectWithValue }) => {
    try {

        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + `/plannedAccreditation/${id}/`, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        // console.log(response.data,'response.data')

        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})


export const addNewPlannedAccreditationDate = createAsyncThunk(
    'plannedAccreditationDate/addNewPlannedAccreditationDate',
    async (toAdd, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/plannedAccreditation/create/`, toAdd, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)

export const updatePlannedAccreditationDate = createAsyncThunk(
    'plannedAccreditationDate/updatePlannedAccreditationDate',
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem('token')

        try {
            const response = await axios.put(BaseUrl + `/plannedAccreditation/${data.id}/edit/`, data.updated, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            console.log(response.data, 'response.data')
            return response.data

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)

export const deletePlannedAccreditationDate = createAsyncThunk(
    "plannedAccreditationDate/deletePlannedAccreditationDate",
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.delete(
                BaseUrl + `/plannedAccreditation/${id}/delete/`,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                }
            );
            // console.log(response.data,'response.data')

            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            console.log("error.response from inside the CAT", error.response);
            return rejectWithValue(error.response);
        }
    }
);


const plannedAccreditationDatesSlice = createSlice({
    name: ' plannedAccreditationDate',
    initialState,
    extraReducers: {

        [fetchPlannedAccreditationDatesByProgramId.pending]: (state, action) => {
            state.plannedAccreditationDatesStatus = 'loading'
        },
        [fetchPlannedAccreditationDatesByProgramId.fulfilled]: (state, action) => {
            state.plannedAccreditationDatesStatus = 'succeeded'
            // Add any fetched posts to the array
            state.plannedAccreditationDates = action.payload
        },
        [fetchPlannedAccreditationDatesByProgramId.rejected]: (state, action) => {
            state.plannedAccreditationDatesStatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error
                console.log("action.error from inside extra add plo reducer", action.error)
            }
        },

        [addNewPlannedAccreditationDate.fulfilled]: (state, action) => {
            state.plannedAccreditationDates.push(action.payload)
        },
        [addNewPlannedAccreditationDate.rejected]: (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
            } else {
                state.error = action.error
                console.log("action.error from inside extra add plo reducer", action.error)
            }
        },
    },

})

export default plannedAccreditationDatesSlice.reducer