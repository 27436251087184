import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import { unwrapResult } from '@reduxjs/toolkit'
import CustomInput from "components/CustomInput/CustomInput.js";
import { editCLOWeight } from '../../reduxToolkit/features/CLO/CLOWeightSlice'
import { fetchPloById } from '../../reduxToolkit/features/PLO/PLOsSlice'
import { useTranslation } from 'react-i18next';


export default function WeightCloModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const [weight, setWeight] = useState()

    const handleSend = async () => {

        try {
            dispatch(editCLOWeight({ clo: props.cloID, weight, ploIndicators: props.ploIndicatorID }))
                .then(unwrapResult)
        } catch (err) {
            console.log('Failed to save the clo : ', err)
        } finally {
            dispatch(fetchPloById(props.ploID))
            setWeight(null)
        }
    }


    return (
        <div>

            <Dialog open={props.openEditCLOWeightModal} onClose={props.handleCloseEditCLOWeightModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">  {t('Edit Weight Clo ')}</DialogTitle>
                <DialogContent>
                    <form>

                        <CustomInput
                            labelText={t('weight*')}
                            id="weight"
                            type="text"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a weight ',
                                },
                            ]}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: event => {
                                    setWeight(event.target.value);
                                },
                                value: weight
                            }}
                        />

                    </form>

                    <DialogActions>
                        <Button onClick={props.handleCloseEditCLOWeightModal} color="info">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={() => { props.handleCloseEditCLOWeightModal(); handleSend() }} color="info">
                            {t('Save')}
                        </Button>
                    </DialogActions>

                </DialogContent>

            </Dialog>
        </div>
    );
}
