import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";

const ProgramDeliveryModes = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>Background Information: Program Delivery Modes</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the program delivery modes:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />
      <p className={styles.reportParagraphTitle}>
        The following is the list of the delivery modes initially defined for
        the program:
      </p>
      {/* <GridItem xs={1} sm={1} md={1}>
          <Button
            justIcon
            round
            color="transparent"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <EditIcon />
          </Button>
        </GridItem> */}
      <ul>
        {data?.modes?.map((mode, index) => (
          <li key={index}>{mode}</li>
        ))}
      </ul>
    </>
  );
};

export default ProgramDeliveryModes;
