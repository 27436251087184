import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  academicYears: [],
  academicYearsStatus: 'idle',
  academicYearStatus: 'idle',
  error: null,
  academicYear: null,
}

export const fetchAcademicYears = createAsyncThunk('academicYear/fetchAcademicYears', async (parameter, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + '/academicYear/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchAcademicYearById = createAsyncThunk('academicYear/fetchAcademicYearById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/academicYear/${id}/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data, 'response.data AY')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const addNewAcademicYear = createAsyncThunk(
  'academicYear/addNewAcademicYear',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/academicYear/create/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


export const deleteAcademicYear = createAsyncThunk(
  'academicYear/deleteAcademicYear', async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {

      const response = await axios.delete(BaseUrl + `/academicYear/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  })

export const updateAcademicYear = createAsyncThunk(
  'academicYear/updateAcademicYear', async (
    data, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {

    const response = await axios.put(BaseUrl + `/academicYear/${data.id}/edit/`, data, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})


const academicYearsSlice = createSlice({
  name: 'academicYear',
  initialState,

  extraReducers: {
    [fetchAcademicYears.pending]: (state, action) => {
      state.academicYearsStatus = 'loading'
    },
    [fetchAcademicYears.fulfilled]: (state, action) => {
      state.academicYearsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.academicYears = action.payload
    },
    [fetchAcademicYears.rejected]: (state, action) => {
      state.academicYearsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra reducer", action.error)
      }
    },
    [fetchAcademicYearById.pending]: (state, action) => {
      state.academicYearStatus = 'loading'
    },
    [fetchAcademicYearById.fulfilled]: (state, action) => {
      state.academicYearStatus = 'succeeded'

      // Add any fetched posts to the array
      state.academicYear = action.payload
    },
    [fetchAcademicYearById.rejected]: (state, action) => {
      state.academicYearStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra reducer", action.error)
      }
    },
    [addNewAcademicYear.fulfilled]: (state, action) => {
      state.academicYears.push(action.payload)
    },

  },
})


export default academicYearsSlice.reducer
