import React from 'react'
import {
    primaryColor,
    warningColor,
    whiteColor
  } from "assets/jss/material-dashboard-pro-react.js";
export default function IdentificationComp({item}) {
  return (
    <span style={{ color: primaryColor[0] }}>
    {item?.title}
  </span>
  )
}
