import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'


const initialState = {
  authorities: [],
  authoritiesStatus: 'idle',
  error: null,
}

export const fetchAuthorities = createAsyncThunk('authority/fetchAuthorities', async (parameter,{ rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/authority/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})


export const fetchAuthorityById = createAsyncThunk('authority/fetchAuthorityById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/authority/${id}/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const addNewAuthority = createAsyncThunk(
  'authority/addNewAuthority',
  async (toAdd, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/authority/create/`, toAdd, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const updateAuthority = createAsyncThunk(
  'authority/updateAuthority',
  async (data, { rejectWithValue }) => {
    
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/authority/${data.id}/edit/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      console.log(err.response)
      let error = err 
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
)

export const deleteAuthority = createAsyncThunk(
  "authority/deleteAuthority",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/authority/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


const authoritiesSlice = createSlice({
  name: ' authority',
  initialState,
  extraReducers: {

    [fetchAuthorities.pending]: (state, action) => {
      state.authoritiesStatus = 'loading'
    },
    [fetchAuthorities.fulfilled]: (state, action) => {
      state.authoritiesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.authorities = action.payload
    },
    [fetchAuthorities.rejected]: (state, action) => {
      state.authoritiesStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    
    [addNewAuthority.fulfilled]: (state, action) => {
      state.authorities.push(action.payload)
    },
    [addNewAuthority.rejected]: (state, action) => {
      state.error = action.payload ? action.payload : action.error
    },

    [updateAuthority.rejected]: (state, action) => {
      state.error = action.payload ? action.payload : action.error
    },
  },

})

export default authoritiesSlice.reducer