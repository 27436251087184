import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  semesters: [],
  semestersStatus: 'idle',
  semesterStatus: 'idle',
  error: null,
  semester: null,
  currentSemesterStatus: 'idle',
  currentSemester: null,
  generate: null,
  generateStatus: 'idle',
}

export const fetchSemesters = createAsyncThunk(
  'semester/fetchSemesters',
  async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/semester/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    // console.log(response.data,'response.data of semesters')

    return response.data
  },
)
//const userId = localStorage.getItem('userId')

export const fetchCurrentSemester = createAsyncThunk(
  'semester/fetchCurrentSemester',
  async (id, { rejectWithValue }) => {
    const userID = localStorage.getItem('userId')
    const data = { user_id: id }
    console.log('data from semester slice', data)
    console.log('id   from semester slice', id)
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/semesterActive/current_semester/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchSemesterById = createAsyncThunk(
  'semester/fetchSemesterById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/semester/get/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      // console.log(response.data,'response.data')

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const addNewSemester = createAsyncThunk(
  'semester/addNewSemester',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/semester/add/create/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const switchSemester = createAsyncThunk(
  'semester/switchSemester',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/semesterActive/switch/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const updateSemester = createAsyncThunk(
  'semester/updateSemester',
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(
        BaseUrl + `/semester/${data.id}/edit/`,
        data.updatedSemester,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //console.log(response.data, 'response.data')
      return response.data
    } catch (error) {
      console.error('Failed to update the semester: ', error)
    }
  },
)

export const generateSemester = createAsyncThunk(
  'semester/generateSemester',
  async (data, { rejectWithValue }) => {
    try {
      //    console.log(' generate semster id', id )
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/semester/generate/create/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //  console.log('token in generate', token)
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const deleteSemester = createAsyncThunk(
  'semester/deleteSemester',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      
      const response  = await axios.delete(
        BaseUrl + `/semester/${id}/delete/`,
        
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const semestersSlice = createSlice({
  name: ' semester',
  initialState,
  extraReducers: {
    [fetchSemesters.pending]: (state, action) => {
      state.semestersStatus = 'loading'
    },
    [fetchSemesters.fulfilled]: (state, action) => {
      state.semestersStatus = 'succeeded'
      // Add any fetched posts to the array
      state.semesters = action.payload
    },
    [fetchSemesters.rejected]: (state, action) => {
      state.semestersStatus = 'failed'
      state.error = action.payload
    },

    [fetchSemesterById.pending]: (state, action) => {
      state.semesterStatus = 'loading'
    },
    [fetchSemesterById.fulfilled]: (state, action) => {
      state.semesterStatus = 'succeeded'

      // Add any fetched posts to the array
      state.semester = action.payload
    },
    [fetchSemesterById.rejected]: (state, action) => {
      state.semesterStatus = 'failed'
      state.error = action.payload
    },
    [addNewSemester.fulfilled]: (state, action) => {
      state.semesters.push(action.payload)
    },
    [fetchCurrentSemester.pending]: (state, action) => {
      state.currentSemesterStatus = 'loading'
    },
    [fetchCurrentSemester.fulfilled]: (state, action) => {
      state.currentSemesterStatus = 'succeeded'
      state.currentSemester = action.payload
      localStorage.setItem(
        'semester_id',
        JSON.stringify(action.payload.semester_id),
      )
    },
    [fetchCurrentSemester.rejected]: (state, action) => {
      state.currentSemesterStatus = 'failed'
      state.error = action.payload
    },
    [switchSemester.fulfilled]: (state, action) => {
      console.log(action.payload)
    },
    [generateSemester.pending]: (state, action) => {
      state.generateStatus = 'loading'
    },
    [generateSemester.fulfilled]: (state, action) => {
      state.generateStatus = 'succeeded'
    },
    [generateSemester.rejected]: (state, action) => {
      state.generateStatus = 'failed'
    },
  },
})

export default semestersSlice.reducer
