// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/icons
// core components
/// test 
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { addNewMenu, fetchMenuById, menuUpdate } from "reduxToolkit/features/menu/MenuSlice";
import routes from "routes.js";


//const useStyles = makeStyles(styles);
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};


const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));

export default function AddEditMenuAccessControl({ match }) {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const useTitleStyles = makeStyles(titleStyles);


    const { t } = useTranslation();
    const { id } = match.params;
    const isAddMode = isNaN(id);
    console.log('id from match is add mode', isAddMode)
    console.log('routes', routes)

    const titleClasses = useTitleStyles();
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const onNameChanged = (e) => setName(e.target.value)
    const onDescriptionChanged = (e) => setDescription(e.target.value)
    const dispatch = useDispatch()
    const { Option } = Select;

    const history = useHistory();
    const onSaveClicked = async () => {


        try {
            setAddRequestStatus('pending')

            const resultAction = await dispatch(
                addNewMenu({ name, description })
            )
            unwrapResult(resultAction)
            setName('')
            setDescription('')

            history.push('/citrine/menus');
        } catch (err) {
            console.log('menus', addNewMenu)
            console.error('Failed to save the menu: ', err)
        } finally {
            setAddRequestStatus('idle')
        }


    }
    const onUpdateClicked = async () => {


        try {
            setAddRequestStatus('pending')
            console.log('onupdate')
            const resultAction = await dispatch(

                menuUpdate({ id: id, modified: { name, description } })
            )
            unwrapResult(resultAction)
            setName('')
            setDescription('')


            history.push('/citrine/menus');
        } catch (err) {
            console.log('menuUpdated', menuUpdate)
            console.error('Failed to save the menu: ', err)
        } finally {
            setAddRequestStatus('idle')
        }


    }

    function onChange(value) {
        console.log(`selected ${value}`);
        setName(value);
    }

    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }






    useEffect(() => {
        if (!isAddMode) {
            dispatch(fetchMenuById(id))
        }
    }, [id]);

    const menu = useSelector((state) => state.menu.menu)
    console.log('menu name', menu && menu.name)

    useEffect(() => {
        if (!isAddMode) {
            setName(menu && menu.name);
            setDescription(menu && menu.description);

        }
    }, [menu, dispatch]);
    return (

        <div>
            {isAddMode &&
                <div>

                    <GridContainer >
                        <Card>
                            <CardHeader color="info" icon>
                                <CardIcon color="info">
                                    <SchoolRounded />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    <span className={titleClasses.title}>      <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/menus`}> {t('/  Menus List')}</Link> {t('/  Create')}</span>
                                </h4>
                            </CardHeader>
                            <form>
                                <GridContainer xs={12} sm={12} md={6} style={{ display: "d-flex align-items-center", justifyContent: "space-between" }}>

                                    <GridItem xs={12} sm={12} md={6} >
                                        <label>{t('Name *')} </label>
                                        <Select
                                            showSearch
                                            style={{ width: 200 }}


                                            optionFilterProp="children"
                                            name="name"
                                            value={name}
                                            onChange={onChange}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}

                                        >
                                            {routes.map((r) => (
                                                <option value={r.name}>
                                                    {r.name}
                                                </option>
                                            ))}

                                        </Select>


                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>



                                        <CustomInput
                                            labelText="{t('Description *')}"
                                            id="description"
                                            value={description}
                                            type="text"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter a description!',
                                                },
                                            ]}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: false,
                                                onChange: event => {
                                                    setDescription(event.target.value);
                                                }
                                            }}

                                        />


                                    </GridItem>

                                </GridContainer>
                            </form>
                        </Card>

                    </GridContainer>



                    <Button onClick={() => { onSaveClicked() }} color="primary">
                        {t('Save')}
                    </Button>



                </div>
            }
            {!isAddMode && menu &&
                <div>

                    <GridContainer >
                        <Card>
                            <CardHeader color="info" icon>
                                <CardIcon color="info">
                                    <SchoolRounded />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    <span className={titleClasses.title}>      <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/menus`}> {t('/Menus access control')}</Link> {t('Edit')}</span>
                                </h4>
                            </CardHeader>
                            <form>
                                <GridContainer xs={12} sm={12} md={6} style={{ display: "d-flex align-items-center", justifyContent: "space-between" }}>

                                    <GridItem xs={12} sm={12} md={6} >
                                        <label>{t('Name *')} </label>
                                        <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            optionFilterProp="children"
                                            name="name"
                                            value={name}
                                            onChange={onChange}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}

                                        >
                                            {routes.map((r) => (
                                                <option value={r.name}>
                                                    {r.name}
                                                </option>
                                            ))}

                                        </Select>


                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>


                                        <CustomInput
                                            labelText="{t('Description *')}"
                                            id="description"
                                            //  value={description}
                                            type="text"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter a description!',
                                                },
                                            ]}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: false,
                                                onChange: event => {
                                                    setDescription(event.target.value);
                                                },
                                                value: description

                                            }}

                                        />


                                    </GridItem>

                                </GridContainer>
                            </form>
                        </Card>

                    </GridContainer>



                    <Button onClick={() => { onUpdateClicked() }} color="primary">
                        {t(' Update')}
                    </Button>



                </div>
            }
        </div>


    );


}

