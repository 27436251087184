import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
  } from "@mui/x-data-grid";
  import { Breadcrumb, notification } from "antd";
  import { Link } from "react-router-dom";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import Button from "components/CustomButtons/Button.js";
  import CustomInput from "components/CustomInput/CustomInput.js";
  import Search from "@material-ui/icons/Search";
  
  import GridContainer from "components/Grid/GridContainer";
  import GridItem from "components/Grid/GridItem";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import {
    fetchResearchAndInnovationActivityById,
    updateResearchAndInnovationActivity,
  } from "reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice";
  import ErrorModal from "../../../../Components/ErrorModal";
  import { useParams } from "react-router-dom";
  import { fetchFacultyActivityById } from "reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
  import { useDispatch, useSelector } from "react-redux";
  
  import PropTypes from "prop-types";
  import { makeStyles, withStyles } from "@material-ui/core/styles";
  import clsx from "clsx";
  import Stepper from "@material-ui/core/Stepper";
  import Step from "@material-ui/core/Step";
  import StepLabel from "@material-ui/core/StepLabel";
  import Check from "@material-ui/icons/Check";
  import SettingsIcon from "@material-ui/icons/Settings";
  import GroupAddIcon from "@material-ui/icons/GroupAdd";
  import VideoLabelIcon from "@material-ui/icons/VideoLabel";
  import StepConnector from "@material-ui/core/StepConnector";
  import Typography from "@material-ui/core/Typography";
  import {
    primaryColor,
    roseColor,
    infoColor,
  } from "assets/jss/material-dashboard-pro-react";
  import EventIcon from "@material-ui/icons/Event";
  import EventAvailableIcon from "@material-ui/icons/EventAvailable";
  
  import CreateIcon from '@material-ui/icons/Create';
  import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
  import DoneIcon from '@material-ui/icons/Done';
  import CloseIcon from '@material-ui/icons/Close';
  // import VisibilityIcon from '@material-ui/icons/Visibility';
  
  import { createTheme, ThemeProvider } from '@material-ui/core/styles';
  import { Visibility } from "@material-ui/icons";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
  
  const theme = createTheme({
    overrides: {
      MuiStepper: {
        root: {
          background: 'none',
          border: 'none',
          padding: 0
        },
      },
    },
  });
  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
      },
    },
    completed: {
      "& $line": {
        backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);
  
  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  });
  export default function Activity() {
  
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeState, setActiveState] = React.useState(null);
  
    // const steps = ["Draft", "Waiting for review", "Reviewed"];
  
    // const icons = {
    //   1: <CreateIcon />,
    //   2: <HourglassEmptyIcon />,
    //   3: <DoneIcon />,
    // };
  
    // const [steps, setSteps] = React.useState(["Draft", "Waiting for review"] );
    // const [icons, setIcons] = React.useState({1: <CreateIcon />, 2: <HourglassEmptyIcon />});
  
  
    const steps = (activeState === "Draft" || activeState === "Waiting for review") ? ["Draft", "Waiting for review"] : (activeState === "Reviewed") ? ["Draft", "Waiting for review", "Reviewed"] : (activeState === "Rejected") ? ["Draft", "Waiting for review", "Rejected"] : []
  
    const icons = (activeState === "Draft" || activeState === "Waiting for review") ? {
      1: <CreateIcon />,
      2: <HourglassEmptyIcon />,
    } : (activeState === "Reviewed") ? {
      1: <CreateIcon />,
      2: <HourglassEmptyIcon />,
      3: <DoneIcon />,
    } : (activeState === "Rejected") ? {
      1: <CreateIcon />,
      2: <HourglassEmptyIcon />,
      3: <CloseIcon />,
    } : []
  
    const dispatch = useDispatch();
  
    let { activityId } = useParams();
    let currentLanguage = localStorage.getItem("i18nextLng");
  
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
  
    const handleOpenErrorModal = () => {
      setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
    };
  
    const fetchfacultyActivity = () => {
      dispatch(
        fetchResearchAndInnovationActivityById(activityId)
      ).unwrap()
        .then((originalPromiseResult) => {
          // handle result here
          setActiveState(originalPromiseResult.state);
          setActiveStep(steps.indexOf(originalPromiseResult.state));
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
          setHandledError(rejectedValueOrSerializedError);
          handleOpenErrorModal();
        })
    };
  
    useEffect(() => {
      fetchfacultyActivity();
    },[activeState]);
  
    console.log("steps",steps)
    console.log("activeState",activeState)
    const facultyActivity = useSelector(
      (state) => state.facultyActivity.facultyActivity
    );
  
    const openNotificationWithIcon = (type, msg) => {
      notification[type]({
        message: type,
        description: msg,
        top: 50,
        duration: 2,
      });
    };
  
  
    const UpdateState = async (value) => {
      let data = {
        updatedResearchAndInnovationActivity: { state: value },
        id: activityId,
      };
      const resultAction = await dispatch(updateResearchAndInnovationActivity(data));
  
      if (updateResearchAndInnovationActivity.fulfilled.match(resultAction)) {
        // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
        fetchfacultyActivity();
        openNotificationWithIcon("success", `updated successfully`);
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
        }
      }
    };
  
    const handleSendForReview = () => {
      UpdateState("Waiting for review");
    };
  
    const handleAccept = () => {
      UpdateState("Reviewed");
    };
  
    const handleReject = () => {
      UpdateState("Rejected");
    };
  
    const handleReset = () => {
      UpdateState("Draft");
    };
  
    function ColorlibStepIcon(props) {
      const classes = useColorlibStepIconStyles();
      const { active, completed } = props;
  
  
      return (
        <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
          })}
        >
          {icons[String(props.icon)]}
        </div>
      );
    }
  
    ColorlibStepIcon.propTypes = {
      /**
       * Whether this step is active.
       */
      active: PropTypes.bool,
      /**
       * Mark the step as completed. Is passed to child components.
       */
      completed: PropTypes.bool,
      /**
       * The label displayed in the step icon.
       */
      icon: PropTypes.node,
    };
  
    return (
      <div className={classes.root}>
        {activeState && <div>
          <Card>
            <GridContainer alignItems="center">
              <GridItem xs={3} sm={3} md={3}>
                {activeState === "Draft" && (
                  <div>
                    <Button color="primary" onClick={handleSendForReview}>
                      Send for review
                    </Button>
                  </div>
                )}
                {activeState === "Waiting for review" && (
                  <div>
                    <Button color="info" onClick={handleReset}>
                      Reset
                    </Button>
                    <Button color="primary" onClick={handleAccept}>
                      Accept
                    </Button>
                    <Button color="primary" onClick={handleReject}>
                      Reject
                    </Button>
                  </div>
                )}
                {activeState === "Reviewed" && (
                  <div>
                    <Button color="info" onClick={handleReset}>
                      Reset
                    </Button>
                  </div>
                )}
                {activeState === "Rejected" && (
                  <div>
                    <Button color="info" onClick={handleReset}>
                      Reset
                    </Button>
                  </div>
                )}
              </GridItem>
              <GridItem xs={9} sm={9} md={9}>
                <ThemeProvider theme={theme}>
                  <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={
                      currentLanguage === "ar" ? false : <ColorlibConnector />
                    }
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </ThemeProvider>
              </GridItem>
            </GridContainer>
          </Card>
          <div>
            {activeState === "Draft" && (
              <div>Draft</div>
            )}
            {activeState === "Waiting for review" && (
              <div>Waiting for review</div>
            )}
            {activeState === "Reviewed" && (
              <div>Reviewed </div>
            )}
            {activeState === "Rejected" && (
              <div>Rejected </div>
            )}
          </div>
        </div>}
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </div>
    );
  }
  