import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";

// core components
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";

import { Breadcrumb } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { fetchDatesAndTimesAllocations } from "../../../../../reduxToolkit/features/DatesAndTimesAllocation/DatesAndTimesAllocationSlice";


import ErrorModal from "../../../../Components/ErrorModal";

import { useTranslation } from "react-i18next";
import DropDownButtonDatesAndTimesAllocation from '../../../../Components/DropDownButtonDatesAndTimesAllocation';

const useNavBarStyles = makeStyles(navBarStyles);

const useStyles = makeStyles(styles);

export default function DatesAndTimesAllocationList({ match }) {
  const { t } = useTranslation();
  const navBarClasses = useNavBarStyles();

  const classes = useStyles();


  const { id } = match.params;
  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const DatesAndTimesAllocationsStatus = useSelector(
    (state) => state.DatesAndTimesAllocation.DatesAndTimesAllocationsStatus
  );

  const fetchDatesAndTimesAllocationsByCourseId = async () => {
    try {
      const resultAction = await dispatch(
        fetchDatesAndTimesAllocations(id)
      ).unwrap();
      dispatch(fetchCourseById(id));
      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
      console.log("err from DatesAndTimesAllocations fetch by course id", err);
    }
  };

  useEffect(() => {
    fetchDatesAndTimesAllocationsByCourseId();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const DatesAndTimesAllocations = useSelector(
    (state) => state.DatesAndTimesAllocation.DatesAndTimesAllocations
  );
  console.log("course to be updated", course);

  console.log("course", course);
  console.log("DatesAndTimesAllocations", DatesAndTimesAllocations);

  let content;

  if (DatesAndTimesAllocationsStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (DatesAndTimesAllocationsStatus === "succeeded") {
    content = (
      <div>
        <GridItem>
          <Breadcrumb separator=">">
           

            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseSpecDetails/${course && course.id && course.id
                  }`}
              >
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id}/DatesAndTimesAllocations`}
                style={{ color: "orange" }}
              >
                {t("Date sAnd Times Allocations List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <GridContainer>
          <GridItem>
            {disabledStatus ? (
              <p></p>
            ) : (
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id}/DatesAndTimesAllocations/create`}
              >
                <Button color="info" className={classes.updateProfileButton}>
                  {t("Add")}
                </Button>
              </Link>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          {DatesAndTimesAllocations &&
            DatesAndTimesAllocations.map((Element) => (
              <GridItem key={Element.id} xs={12} sm={12} md={4}>
                <Card style={{ height: 200, textAlign: "start" }}>
                  <CardBody>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={10} sm={10} md={10}>
                        <h4
                          className={classes.cardProductTitle}
                          style={{ textAlign: "start", color: "orange" }}
                        >
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            style={{ color: "orange" }}
                          >
                            {t("Number of weeks")} : {Element.number_of_weeks}
                          </a>
                        </h4>


                        <h4
                          className={classes.cardProductTitle}
                          style={{ textAlign: "start" }}
                        >
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {t("Number of days")} :
                            {Element.number_of_days}
                          </a>
                        </h4>

                        <h4
                          className={classes.cardProductTitle}
                          style={{ textAlign: "start" }}
                        >
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {t("Number of hours")} :
                            {Element.number_of_hours}
                          </a>
                        </h4>



                      </GridItem>
                      <GridItem xs={2} sm={2} md={2} style={{ zIndex: 1 }}>
                        {disabledStatus ? (
                          <p></p>
                        ) : (
                          <DropDownButtonDatesAndTimesAllocation
                            DatesAndTimesAllocation_ID={Element.id}
                            courseID={course && course.id}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </div>
    );
  }
  return (
    <div>
      {content}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
