import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';
const initialState = {
    CourseQualityEvaluations: [],
    CourseQualityEvaluationStatus: 'idle',
    CourseQualityEvaluationStatus: 'idle',
    error: null,
    CourseQualityEvaluation: null,

    id: null
}


export const fetchCourseQualityEvaluations = createAsyncThunk('CourseQualityEvaluation/fetchCourseQualityEvaluations', async (course_id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/courseQualityEvaluation/?courseSpec=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    console.log(response.data, 'response.data')

    return response.data
})

//   export const fetchSubAreaById = createAsyncThunk('subArea/fetchSubAreaById', async (id) => {
//     const response = await axios.get(`http://127.0.0.1:8000/api/subArea/${id}/`, {
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })
//       // console.log(response.data,'response.data')

//     return response.data
//   })
export const addNewCourseQualityEvaluation = createAsyncThunk(
    'CourseQualityEvaluation/addNewCourseQualityEvaluation',
    async (initialCourseQualityEvaluation) => {
        console.log('initial CourseQualityEvaluation', initialCourseQualityEvaluation)
        const token=localStorage.getItem('token')
        const response = await axios.post(BaseUrl+`/courseQualityEvaluation/create/`, initialCourseQualityEvaluation, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data
    }
)
//   export const subAreaUpdate = createAsyncThunk(
//     'subArea/subAreaUpdate',
//     async (data,thunkAPI) => {

//       try{
//         const response = await axios.put(`http://127.0.0.1:8000/api/subArea/${data.id}/edit/`,data.modified ,{
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })   
//       console.log(data,'updatedSubArea')
//       console.log(response.data,'response.data')   
//       return response.data


//     } catch (error) {
// console.error('Failed to update the subArea: ', error)

// }
//     }
//   )

export const deleteCourseQualityEvaluation = createAsyncThunk('CourseQualityEvaluation/deleteCourseQualityEvaluation', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/courseQualityEvaluation/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
     console.log(response.data,' delete response.data')

    return response.data
})
const CourseQualityEvaluationSlice = createSlice({
    name: 'CourseQualityEvaluation',
    initialState,

    extraReducers: {
        [fetchCourseQualityEvaluations.pending]: (state, action) => {
            state.CourseQualityEvaluationStatus = 'loading'
        },
        [fetchCourseQualityEvaluations.fulfilled]: (state, action) => {
            state.CourseQualityEvaluationStatus = 'succeeded'
            // Add any fetched posts to the array
            state.CourseQualityEvaluations = action.payload
        },
        [fetchCourseQualityEvaluations.rejected]: (state, action) => {
            state.CourseQualityEvaluationStatus = 'failed'
            state.error = action.payload
        },

        //   [fetchSubAreaById.pending]: (state, action) => {
        //     state.subAreaStatus = 'loading'
        //   },
        //   [fetchSubAreaById.fulfilled]: (state, action) => {
        //     state.subAreaStatus = 'succeeded'

        //     // Add any fetched posts to the array
        //     state.subArea = action.payload
        //   },
        //   [fetchSubAreaById.rejected]: (state, action) => {
        //     state.subAreaStatus = 'failed'
        //     state.error = action.payload
        //   },
        [addNewCourseQualityEvaluation.fulfilled]: (state, action) => {
            state.CourseQualityEvaluations.push(action.payload)
        },
        [deleteCourseQualityEvaluation.fulfilled]: (state, action) => {
           // state.SpecificationAprovalDatas.push(action.payload)
        },
        //   [subAreaUpdate.fulfilled]: (state, action) => {
        //     console.log(action) 
        //   },



        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },


            // subAreaUpdated(state, action) {
            //     const { id, name } = action.payload
            //     const existingSubArea = state.subAreas.find(subArea => subArea.id === id)
            //     if (existingSubArea) {
            //         existingSubArea.name = name


            //     }
            // }
        }
    }
})

export const {
    //subAreaAdded, 
    //subAreaUpdated, 
    getId } = CourseQualityEvaluationSlice.actions

export default CourseQualityEvaluationSlice.reducer

export const selectAllCourseContent = state => state.CourseQualityEvaluation.CourseQualityEvaluations

