import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CreateReportCourseDocument from "../Components/CreateReportCourseDocument";
import ErrorModal from "./ErrorModal";
import CreateReportCourseSyllabusDocument from "../Components/CreateReportCourseSyllabusDocument"



const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

export default function DropDownDocumentCourseSpecReport(props) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <AddIcon />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <CreateReportCourseDocument
          CourseType={props.CourseType}
          CourseId={props.CourseId}
          handleDropDownButtonClose={handleClose}
        />
        <CreateReportCourseSyllabusDocument
          CourseId={props.CourseId}
          handleDropDownButtonClose={handleClose}
        />
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
