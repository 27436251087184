import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePlo,
  fetchPlos,
} from "../../reduxToolkit/features/PLO/PLOSpecSlice";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select, DatePicker, Input,Form } from "antd";
import ListItemText from "@material-ui/core/ListItemText";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { fetchPeoById } from "../../reduxToolkit/features/PEO/PEOSpecSlice";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { fetchPloRecommendations } from "../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { addNewGenaralRecommendation ,fetchCourseRecommendations} from "../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import AddIcon from "@material-ui/icons/Add";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

export default function AddCourseRecommendationModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { Option, OptGroup } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [course_id, setCourse_id] = useState(props.course_id);
  const [resource, setResource] = useState(props.resource);
  const [resource_id, setResource_id] = useState(props.resource_id);
  const [recommendation, setRecommendation] = useState();
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");

  const handleSend = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        addNewGenaralRecommendation({ course_id, recommendation, resource, resource_id })
      );
      unwrapResult(resultAction);
      setRecommendation("");
    } catch (err) {
      console.error("Failed to save the action: ", err);
    } finally {
      setAddRequestStatus("idle");
      dispatch(fetchCourseRecommendations(props.course_id));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        color="primary"
        round
        style={{ border: "1px solid #D1CF4B", marginTop: "10px" ,backgroundColor:"#D1CF4B"}}
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <AddIcon style={{ color: "white" }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Add  Recommendation")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Recommendation*")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <TextArea
                rows={4}
                id="recommendation"
                value={recommendation}
                onChange={(event) => setRecommendation(event.target.value)}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleSend();
            }}
            color="primary"
          >
            {t(" Send")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
