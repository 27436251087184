import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
    CourseSpecPerformancePlo: [],
    CourseSpecPerformancePloStatus: 'idle',

    error: null,
}

export const fetchCourseSpecPerformancePlo = createAsyncThunk('CourseSpecPerformancePlo/fetchCourseSpecPerformancePlo', async (ploId, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + `/courseSpecPerformancePlo/?ploSpec_id=${ploId}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }

        )
        console.log(response.data, 'response.data')
        return response.data

    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})



export const addNewCourseSpecPerformancePlo = createAsyncThunk(
    'CourseSpecPerformancePlo/addNewCourseSpecPerformancePlo',
    async (toAdd, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/courseSpecPerformancePlo/create/`, toAdd, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response', error.response)
            return rejectWithValue(error.response)
        }
    }
)
export const DeleteCourseSpecPerformancePlo = createAsyncThunk(
    'CourseSpecPerformancePlo/DeleteCourseSpecPerformancePlo',
    async (id, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(BaseUrl + `/courseSpecPerformancePlo/${id}/delete/`,  {
                headers: {
                  Authorization: `token ${token}`,
                },
              });
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response', error.response)
            return rejectWithValue(error.response)
        }
    }
)




const CourseSpecPerformancePloSlice = createSlice({
    name: 'CourseSpecPerformancePlo',
    initialState,
    extraReducers: {

        [fetchCourseSpecPerformancePlo.pending]: (state, action) => {
            state.CourseSpecPerformancePloStatus = 'loading'
        },
        [fetchCourseSpecPerformancePlo.fulfilled]: (state, action) => {
            state.CourseSpecPerformancePlotatus = 'succeeded'
            // Add any fetched posts to the array
            state.CourseSpecPerformancePlo = action.payload
        },
        [fetchCourseSpecPerformancePlo.rejected]: (state, action) => {
            state.CourseSpecPerformancePlotatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside reducer", action.payload)
            } else {
                state.error = action.error
                console.log("action.error from inside  reducer", action.error)
            }
        },
    },

})


export default CourseSpecPerformancePloSlice.reducer


