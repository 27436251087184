import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";

import { Form, Input, message, Select, DatePicker, notification } from "antd";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { addNewPloIndicator } from "../../reduxToolkit/features/PLOIndicator/PLOIndicatorSpecSlice";

import { fetchPloById } from "../../reduxToolkit/features/PLO/PLOSpecSlice";
import ErrorModal from "./ErrorModal";

import { useTranslation } from "react-i18next";

export default function PLOIndicatorsModal({
  plo,
  programId,
  PLOId,
  disabledStatus,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [weight, setWeight] = useState(null);
  const [target, setTarget] = useState(null);

  const dispatch = useDispatch();

  const ploSpec_id = plo && plo.id;

  const onFinish = async () => {
    // const target = parseInt(target)
    const resultAction = await dispatch(
      addNewPloIndicator({ name, code, weight, target, ploSpec_id })
    );

    if (addNewPloIndicator.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const ploIndicator = resultAction.payload;
      console.log("success", `Added ${ploIndicator.name}`);
      openNotificationWithIcon("success", `Added ${ploIndicator.name}`);
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
    dispatch(fetchPloById(PLOId));
  };
  return (
    <div>
      {disabledStatus ? (
        <p></p>
      ) : (
        <Button color="primary" onClick={handleClickOpen}>
          Add
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Add a PLO Indicator")}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={8} sm={8} md={12}>
              <DialogContentText>
                {t(
                  " Program Learning Objectives describe the career and professional accomplishments that programs are preparing graduates to attain within few years of graduation"
                )}
              </DialogContentText>
            </GridItem>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                //   labelCol={{ span: 8 }}
                //   wrapperCol={{ span: 16 }}
                //   initialValues={{ remember: true }}
                onFinish={onFinish}
                //   autoComplete="off"
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Code")}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's code",
                        },
                      ]}
                    >
                      <Input
                        id="code"
                        value={code}
                        onChange={(event) => {
                          setCode(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's name",
                        },
                      ]}
                    >
                      <Input
                        id="name"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Target")}
                      name="target"
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's target",
                        },
                      ]}
                    >
                      <Input
                        id="target"
                        value={target}
                        onChange={(event) => {
                          setTarget(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Weight")}
                      name="weight"
                      rules={[
                        {
                          required: true,
                          message: "Please input the PLO's weight",
                        },
                      ]}
                    >
                      <Input
                        id="weight"
                        value={weight}
                        onChange={(event) => {
                          setWeight(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
