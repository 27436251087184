import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import React, { useEffect } from "react";
// core components
import { Breadcrumb } from "antd";
import {
  grayColor,
  primaryColor
} from "assets/jss/material-dashboard-pro-react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchStaffById } from "../../../../../reduxToolkit/features/user/UserSlice";

import CardFooter from "components/Card/CardFooter";
import CommunityServicesDropdownButton from "views/Components/CommunityServicesDropdown";
import { fetchCommunityServices } from "../../../../../reduxToolkit/features/CommunityServices/CommunityServicesSlice";
import ErrorModal from "../../../../Components/ErrorModal";



// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const CommunityService = ({ community, staffId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch();

  return (
    // <GridItem xs={12} sm={6} md={4}>
    //   <Card style={{ height: 280 }}>
    //     <CardHeader>
    //       <GridContainer justify="center">
    //         <GridItem>
    //           <h3 style={{ color: `${primaryColor[0]}`,fontWeight: "bold" }}>{community.title}</h3>
    //         </GridItem>
    //       </GridContainer>
    //     </CardHeader>
    //     <CardBody>


    //       <GridContainer>
    //         <GridItem>
    //           <h10 style={{ color: `${primaryColor[0]}` }}>Type:</h10>
    //         </GridItem>
    //         <GridItem>
    //           <h10 style={{ color: `${primaryColor[0]}` }}>
    //             {community.type}
    //           </h10>
    //         </GridItem>
    //       </GridContainer>
    //       <GridContainer>
    //         <GridItem>
    //           <h10 style={{ color: `${primaryColor[0]}` }}>Community:</h10>
    //         </GridItem>
    //         <GridItem>
    //           <h10 style={{ color: `${primaryColor[0]}` }}>
    //             {community.community}
    //           </h10>
    //         </GridItem>
    //       </GridContainer>

    //       <GridContainer>


    //         <GridContainer>
    //           <GridItem>
    //             <h10 style={{ color: `${primaryColor[0]}` }}>State:</h10>
    //           </GridItem>
    //           <GridItem>
    //             <h10 style={{ color: `${primaryColor[0]}` }}>
    //               {community.state}
    //             </h10>
    //           </GridItem>
    //         </GridContainer>
    //       </GridContainer>
    //       <br />


    //       <GridContainer justify="center">
    //         <GridItem>
    //           <Link
    //             to={`/citrine/faculty/teachingStaff/${personId}/communityServicesActivity/${community.id}`}
    //           >
    //             <Button color="primary">View</Button>
    //           </Link>
    //         </GridItem>
    //       </GridContainer>
    //     </CardBody>
    //   </Card>
    // </GridItem>


    <GridItem xs={12} sm={4} md={4} key={community.id}>
      <Card style={{ maxHeight: "280px", minHeight: "280px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/faculty/teachingStaff/${staffId}/communityServicesActivity/${community.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {community && community.title}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <CommunityServicesDropdownButton id={community.id} community={community} staffId={staffId} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Type")}:
            <span style={{ fontWeight: "normal" }}> {community && community.type} </span>
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Community")}:
            <span style={{ fontWeight: "normal" }}>
              {community && community.community}
            </span>
          </div>

        </CardBody>
        <CardFooter>
          <GridContainer xs={2} sm={2} md={12} >
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "start" }}>
              <span
                style={{
                  color: "#FFCB3B",
                }}
              >
                {/* V {program && program.version} */}
              </span>
            </GridItem>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "end" }}>
              <span
                style={{
                  color: community.state === "Draft" ? grayColor[1] : "#FFCB3B",
                }}
              >
                {community && community.state}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function CommunityServices({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const { id } = match.params;

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCommunityServicesCustomFunction = async (id) => {
    try {
      const resultAction = await dispatch(fetchCommunityServices(id)).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    dispatch(fetchStaffById(id));
    fetchCommunityServicesCustomFunction(id);
  }, []);

  const person = useSelector((state) => state.user.staff);
  const communityServices = useSelector(
    (state) => state.communityServices.communityServices
  );
  const communityServicesStatus = useSelector(
    (state) => state.communityServices.communityServicesStatus
  );

  console.log("communityServices", communityServices);

  let content;

  if (communityServicesStatus === "loading") {
    content = <div className="loader">Loading...</div>;
  } else if (communityServicesStatus === "succeeded") {
    content =
      communityServices &&
      communityServices.map((community) => (
        // <GridItem xs={4} sm={4} md={4}>
        <CommunityService
          key={community.id}
          community={community}
          staffId={id}
        />
        // </GridItem>
      ));
  } else if (communityServicesStatus === "failed") {
    content = <div>An error has accured</div>;
  }

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff`}>{t("Teaching Staff")}</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff/${id}`}>
            {`${person && person.person_details && person.person_details[0].first_name} ${person && person.person_details && person.person_details[0].last_name}`}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link
            to={`/citrine/faculty/teachingStaff/${id}/communityServicesActivities`}
          >
            {t("Community services")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Link
        to={`/citrine/faculty/teachingStaff/${id}/communityServicesActivities/create`}
      >
        <Button color="primary">{t(" Add")}</Button>
      </Link>
      {/* <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          <ImportScopusModal person_id={person.id} />
        </GridItem>
      </GridContainer> */}
      <GridContainer

        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {
          content
        }
      </GridContainer>



      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
