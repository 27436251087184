import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";


// core components
/// test
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getGroupsByAssessmentMethodAndCourse, fetchExamGroupsAssessment, } from "reduxToolkit/features/group/GroupSlice";
import ErrorModal from "../../../Components/ErrorModal";
import AddAssessmentMethodGroup from "./AddAssessmentMethodGroup";
import DropdownEditCourseGroups from "./DropdownEditCourseGroups";
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function AssessmentMethodGroupsCoordinator({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { course_id, Assessmentid, AssessmentName } = match.params;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");



  useEffect(() => {
    let obj = {
      course_id: course_id,
      assessmentMethod_id: Assessmentid,
      semester_id: localStorage.getItem('semester_id'),
    }
    dispatch(fetchExamGroupsAssessment(obj));
    // }
  }, [dispatch]);
  const courseGroups = useSelector((state) => state.group.groupAssessments);
  const examGroups = useSelector((state) => state.group.examGroups)
  console.log("courseGroups", courseGroups)
  //console.log(courseInstructors)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
      zIndex: 1000,
    });
  };

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseCoordinatorWorkspace/assessment/`}
              >
                {t("Courses List ")}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseCoordinatorWorkspace/assessmentsQuestions_list/course/${course_id}`}
              >
                {t("Assessment Methods")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: "orange" }} >
              {t("Groups ")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Card>

        <CardHeader>
          <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <GridItem xs={12} sm={12} md={11}><h4>{AssessmentName}</h4></GridItem>
            {/* <GridItem> <Button color="primary" startIcon={<AddIcon />}>
        {t("Add")}
      </Button></GridItem> */}
            <GridItem xs={12} sm={12} md={1}><AddAssessmentMethodGroup course_id={course_id} Assessmentid={Assessmentid} /></GridItem>
          </GridContainer>
        </CardHeader>

      </Card>

      <GridContainer>
        {(examGroups.length > 0) ? (examGroups && examGroups.map((examGroup, key) =>
          <GridItem xs={12} sm={12} md={4}>
            <Card style={{ minWidth: 375, minHeight: 200 }}>
              <CardHeader  >
                <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <GridItem xs={12} sm={12} md={10}>
                    {/**/}
                    <Link
                      to={`/citrine/courseCoordinatorWorkspace/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/${examGroup.id}`}

                    >
                      <strong>
                          <h4
                            style={{
                              color: '#8F6682',
                            }}
                          >
                            Name :{' '}
                            {examGroup &&
                              examGroup.group_details &&
                              examGroup.group_details.group_name}
                          </h4>
                        </strong>
                    </Link>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <DropdownEditCourseGroups courseGroup={examGroup} />
                  </GridItem>
                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
        )) : null}

      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
