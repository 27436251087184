import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { primaryColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { fetchAcademicYearById } from '../../../../reduxToolkit/features/academicYear/AcademicYearsSlice'
import { fetchAcademicYearEvents } from '../../../../reduxToolkit/features/academicYearEvents/academicYearEventsSlice'
import ErrorModal from '../../../Components/ErrorModal'
import CreateAcademicYearEventModal from './CreateAcademicYearEventModal'
import CreateSemesterModal from './CreateSemesterModal.jsx'
import DropDownSemester from './DropDownSemester'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const SemesterExcerpt = ({ academicYear, semester,ping,setPing }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const dispatch = useDispatch()
  //console.log("semesterin SemesterExcerpt", semester);
  return (
    <GridItem xs={12} sm={6} md={4}>
      <Card style={{ height: 250 }}>
        <CardHeader>
          <GridContainer>
            <GridItem xs={10} sm={10} md={10}>
            <Link
                to={`/citrine/planning/academicCalender/academicYears/${academicYear.id}/semesters/${semester.id}`}
              >
                <h3 style={{ color: `${primaryColor[0]}` }}>{semester.name}</h3>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}><DropDownSemester id={semester.id} ping={ping} setPing={setPing} /></GridItem>
          
          
          </GridContainer>
        </CardHeader>
        <CardBody>
          {/* <GridContainer justify="center">
            <GridItem>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${academicYear.id}/semesters/${semester.id}`}
              >
                <h3 style={{ color: `${primaryColor[0]}` }}>{semester.name}</h3>
              </Link>
            </GridItem>
          </GridContainer> */}
          <GridContainer justify="center">
            <GridItem>
              <h4>{`${semester.start_date}   /   ${semester.end_date}`}</h4>
            </GridItem>
          </GridContainer>
          {semester.active ? (
            <GridContainer justify="center">
              <GridItem>
                <div>Active</div>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer justify="center">
              <GridItem>
                <div>Archived</div>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer justify="center">
            <GridItem>
              <Link
                to={`/citrine/planning/academicCalender/academicYears/${academicYear.id}/semesters/${semester.id}`}
              >
                <Button color="primary">View</Button>
              </Link>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function AcademicYear() {
  let { id } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [ping, setPing] = useState(false)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const academicYearStatus = useSelector(
    (state) => state.academicYear.academicYearStatus,
  )

  const fetchSemesters = async (id) => {
    try {
      const resultAction = await dispatch(fetchAcademicYearById(id)).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  useEffect(() => {
    fetchSemesters(id)
  }, [dispatch,ping])

  const academicYear = useSelector((state) => state.academicYear.academicYear)

  console.log('academicYear', academicYear && academicYear)

  let content

  if (academicYearStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (academicYearStatus === 'succeeded') {
    if (
      academicYear &&
      academicYear.semesters &&
      academicYear.semesters.length
    ) {
      content = academicYear.semesters.map((semester) => (
        <SemesterExcerpt
          key={semester.id}
          academicYear={academicYear}
          semester={semester}
          ping={ping}
          setPing={setPing}
        />
      ))
    } else {
      content = <div>This academic year contains no terms</div>
    }
  } else if (academicYearStatus === 'failed') {
    content = <div>An error has accured</div>
  }
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = React.useState(5)

  const columns = [
    {
      field: 'program_id',
      headerName: 'Program',
      width: 250,
      sortable: false,
      valueGetter: (params) => {
        //console.log('params', params)
        return `${params.row.program_detail.program_degree_name || ''}`
      },
    },
    {
      field: 'name',
      headerName: 'Academic year event',
      width: 250,
      sortable: false,
    },
    {
      field: 'start_date',
      headerName: 'Start date',
      type: 'date',
      width: 250,
      editable: false,
      sortable: false,
    },
    {
      field: 'end_date',
      headerName: 'End date',
      type: 'date',
      width: 250,
      editable: false,
      sortable: false,
    },
  ]

  useEffect(() => {
    dispatch(fetchAcademicYearEvents(id))
      .then(unwrapResult)
      .then((AcademicYearEvents) => {
        setRows(AcademicYearEvents)
      })
  }, [dispatch])

  const AcademicYearEvents = useSelector(
    (state) => state.academicYearEvent.academicYearEvents,
  )

  const AllAcademicYearEvents = () => {
    dispatch(fetchAcademicYearEvents(id))
      .then(unwrapResult)
      .then((AcademicYearEvents) => {
        setRows(AcademicYearEvents)
      })
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>{t('Planning')}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('Academic Calender')}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/planning/academicCalender/academicYears`}>
            {t('Academic years')}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/planning/academicCalender/academicYears/${
              academicYear && academicYear.id
            }`}
          >
            {t(`${academicYear && academicYear.name}`)}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('terms')}</Breadcrumb.Item>
      </Breadcrumb>

      <CreateSemesterModal academicYear_id={academicYear && academicYear.id} />
      <br />
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        direction="row"
        spacing={3}
        alignItems="center"
      >
        {content}
      </GridContainer>
      <hr
        style={{
          color: 'lightgray',
          backgroundColor: 'lightgray',
          height: 1,
        }}
      />
      <CreateAcademicYearEventModal
        academicYear_id={id}
        AllAcademicYearEvents={AllAcademicYearEvents}
      />
      <Card>
        <div style={{ width: '100%', height: 460, padding: '20px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            //autoPageSize={true}

            disableColumnMenu={true}
            showCellRightBorder
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 80]}
            pagination
          />
        </div>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
