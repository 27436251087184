import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import token from "assets/utils/accessToken";
const initialState = {
  CourseInstructors: [],
  CourseInstructorsStatus: "idle",
  CourseInstructorStatus: "idle",
  error: null,
  CourseInstructor: null,
  id: null,
  instructorCourseAssessmentGroups: [],
  instructorCourseAssessmentGroupsStatus: "idle",
  instructorCourseAssessmentGroup: null,
  instructorCourseAssessmentGroupStatus: "idle",
  instructorCourseClos: [],
  instructorCourseClosStatus: "idle",

};

export const fetchCourseInstructors = createAsyncThunk(
  "CourseInstructor/fetchCourseInstructors",
  async (obj, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/courseInstructors/?exam_id=${obj.id}&course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseInstructorById = createAsyncThunk(
  "CourseInstructor/fetchCourseInstructorById",
  async (obj, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/courseInstructors/?exam_id=${obj.exam_id}&course_id=${obj.course_id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewCourseInstructor = createAsyncThunk(
  "CourseInstructor/addNewCourseInstructor",
  async (initialCourseInstructor, { rejectWithValue }) => {
    console.log("initial CourseInstructor", initialCourseInstructor);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/courseInstructors/create/`,
        initialCourseInstructor,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const CourseInstructorUpdate = createAsyncThunk(
  "CourseInstructor/CourseInstructorUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/courseInstructors/${data.id}/edit/`,
        data.updated,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response);
      console.log(data, "updatedCourseInstructor");
      console.log(response.data, "response.data");
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseInstructor = createAsyncThunk(
  "CourseInstructor/deleteCourseInstructor",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/courseInstructors/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchInstructorCourseAssessmentGroups = createAsyncThunk('CourseInstructor/fetchInstructorCourseAssessmentGroups', async (parameter, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/courseInstructors/byGroup/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    //console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})

export const fetchInstructorCourseAssessmentGroup = createAsyncThunk('CourseInstructor/fetchInstructorCourseAssessmentGroup', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/courseInstructors/${id}/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    //console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})

export const fetchInstructorCourseClos = createAsyncThunk('CourseInstructor/fetchInstructorCourseClos', async (parameter, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/clo/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    //console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})
const CourseInstructorSlice = createSlice({
  name: "CourseInstructor",
  initialState,

  extraReducers: {
    [fetchCourseInstructors.pending]: (state, action) => {
      state.CourseInstructorsStatus = "loading";
    },
    [fetchCourseInstructors.fulfilled]: (state, action) => {
      state.CourseInstructorsStatus = "succeeded";
      // Add any fetched posts to the array
      state.CourseInstructors = action.payload;
    },
    [fetchCourseInstructors.rejected]: (state, action) => {
      state.CourseInstructorsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseInstructorById.pending]: (state, action) => {
      state.CourseInstructorStatus = "loading";
    },
    [fetchCourseInstructorById.fulfilled]: (state, action) => {
      state.CourseInstructorStatus = "succeeded";

      // Add any fetched posts to the array
      state.CourseInstructors = action.payload;
    },
    [fetchCourseInstructorById.rejected]: (state, action) => {
      state.CourseInstructorStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewCourseInstructor.fulfilled]: (state, action) => {
      state.CourseInstructors.push(action.payload);
    },
    [deleteCourseInstructor.fulfilled]: (state, action) => {
      // state.ificationAprovalDatas.push(action.payload)
    },
    [addNewCourseInstructor.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseInstructor.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [CourseInstructorUpdate.fulfilled]: (state, action) => {
      console.log(action);
    },
    [CourseInstructorUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchInstructorCourseAssessmentGroups.pending]: (state, action) => {
      state.instructorCourseAssessmentGroupsStatus = 'loading'
    },
    [fetchInstructorCourseAssessmentGroups.fulfilled]: (state, action) => {
      state.instructorCourseAssessmentGroupsStatus = 'succeeded'
      state.instructorCourseAssessmentGroups = action.payload
    },
    [fetchInstructorCourseAssessmentGroups.rejected]: (state, action) => {
      state.instructorCourseAssessmentGroupsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchInstructorCourseAssessmentGroup.pending]: (state, action) => {
      state.instructorCourseAssessmentGroupStatus = 'loading'
    },
    [fetchInstructorCourseAssessmentGroup.fulfilled]: (state, action) => {
      state.instructorCourseAssessmentGroupStatus = 'succeeded'
      state.instructorCourseAssessmentGroup = action.payload
    },
    [fetchInstructorCourseAssessmentGroup.rejected]: (state, action) => {
      state.instructorCourseAssessmentGroupStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchInstructorCourseClos.pending]: (state, action) => {
      state.instructorCourseClosStatus = 'loading'
    },
    [fetchInstructorCourseClos.fulfilled]: (state, action) => {
      state.instructorCourseClosStatus = 'succeeded'
      state.instructorCourseClos = action.payload
    },
    [fetchInstructorCourseClos.rejected]: (state, action) => {
      state.instructorCourseClosStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
});


export default CourseInstructorSlice.reducer;
