import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  EvidenceTypeStatesConfigs: [],
  EvidenceTypeStatesConfig: null,
  EvidenceTypeStatesConfigsStatus: 'idle',
  EvidenceTypeStatesConfigStatus: 'idle',
}

export const fetchEvidenceTypeStatesConfigs = createAsyncThunk(
  'EvidenceTypeStates/fetchEvidenceTypeStatesConfigs',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + '/evidenceType/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
    //   console.log(response)
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response)
    }
  },
)

export const fetchEvidenceTypeById = createAsyncThunk(
  'EvidenceTypeStates/fetchEvidenceTypeById',
  async (id)=>{
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/evidenceType/${id}/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    //console.log(response.data)
    return response.data;
  }
)

export const addEvidenceTypeStatesConfig = createAsyncThunk(
  'EvidenceTypeStates/addEvidenceTypeStatesConfig',
  async (toAdd, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/evidenceType/create/`,toAdd,{
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
      //console.log(response)
    } catch (err) {
    //   let error = err
    //   if (!error.response) {
    //     throw err
    //   }

    //   return rejectWithValue(error.response)
      console.log(err)
    }
  },
)

export const DeleteEvidenceType = createAsyncThunk(
  'evidenceTypeConfig/deleteEvidenceType',
  async (id, { rejectWithValue }) => {
    console.log(id)
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(BaseUrl + `/evidenceType/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`
        }
      })
      console.log(response.data, 'response.data')

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
})

export const updateEvidenceTypeConfig = createAsyncThunk(
  'evidenceTypeConfig/updateEvidenceType',
  async (type, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
                                               // api/evidenceType/id/edit/
      const response = await axios.put(BaseUrl + `/evidenceType/${type.id}/edit/`,  type.modified, {
        headers: {
          Authorization: `token ${token}`
        }
      })
      console.log(response.data, 'response.data')

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
})

const EvidenceTypeStatesConfigSlice = createSlice({
  name: 'EvidenceTypeStatesConfig',
  initialState,
  extraReducers: {
    [fetchEvidenceTypeStatesConfigs.pending]: (state, action) => {
      state.EvidenceTypeStatesConfigsStatus = 'loading'
    },

    [fetchEvidenceTypeStatesConfigs.fulfilled]: (state, action) => {
      state.EvidenceTypeStatesConfigsStatus = 'succeeded'
      state.EvidenceTypeStatesConfigs = action.payload
    },

    [fetchEvidenceTypeStatesConfigs.rejected]: (state, action) => {
      state.EvidenceTypeStatesConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    /* Delete */
    [DeleteEvidenceType.pending]: (state, action) => {
      state.TypesConfigsStatus = 'loading'
    },

     [DeleteEvidenceType.fulfilled]: (state, action) => {
       state.TypesConfigsStatus = 'succeeded'
     },
     [DeleteEvidenceType.rejected]: (state, action) => {
       state.TypesConfigsStatus = 'failed'
       if (action.payload) {
         state.error = action.payload
       } else {
         state.error = action.error
       }
     },
     /*Edit */
     [updateEvidenceTypeConfig.pending]: (state, action) => {
      state.TypesConfigsStatus = 'loading'
    },

     [updateEvidenceTypeConfig.fulfilled]: (state, action) => {
       state.TypesConfigsStatus = 'succeeded'
     },
     [updateEvidenceTypeConfig.rejected]: (state, action) => {
       state.TypesConfigsStatus = 'failed'
       if (action.payload) {
         state.error = action.payload
       } else {
         state.error = action.error
       }
     },
     /*Get by Id */
     [fetchEvidenceTypeById.pending]: (state, action) => {
      state.TypesConfigsStatus = 'loading'
    },

     [fetchEvidenceTypeById.fulfilled]: (state, action) => {
       state.TypesConfigsStatus = 'succeeded'
       state.EvidenceTypeStatesConfig = action.payload;
     },
     [fetchEvidenceTypeById.rejected]: (state, action) => {
       state.TypesConfigsStatus = 'failed'
       if (action.payload) {
         state.error = action.payload
       } else {
         state.error = action.error
       }
     },
  },
})

export default EvidenceTypeStatesConfigSlice.reducer
