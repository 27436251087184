// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Badge, Breadcrumb } from "antd";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { Avatar, Tooltip } from "antd";
//import Card from '@material-ui/core/Card';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
// react plugin for creating charts
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CourseLogo from "../../../../assets/img/CourseLogo.png";
import NoDataFound from "../../../../assets/img/NoDataFound.png";
import { Assessment, SchoolRounded } from "@material-ui/icons";
//chartist
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { fetchInstructorCourseAssessmentGroups } from "../../../../reduxToolkit/features/CourseInstructor/CourseInstructorSlice";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardIcon from "components/Card/CardIcon.js";
import { unwrapResult } from "@reduxjs/toolkit";
import DropDownButton from "../../../Components/DropDownButtonGoToAssessment";
import DropDownButtonUserView from "views/Components/DropDownButtonUserView";
import { Tabs } from "antd";
import Code from "components/Typography/Code.js";
import Info from "components/Typography/Info.js";

import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
// import MoreVert from '@material-ui/icons/MoreVert'
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import {
  primaryColor,
  infoColor,
  warningColor,
  whiteColor,
  greyColor,
} from "assets/jss/material-dashboard-pro-react.js";
import Muted from "components/Typography/Muted.js";
import AvatarsGroup from "components/Avatar/AvatarsGroup";
import { SlCalender } from "react-icons/sl";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import SuperGeneralDeleteModal from "views/Components/SuperGeneralDeleteModal";
import ErrorModal from "views/Components/ErrorModal";
import ClosQuestionListFaculty from "views/Pages/Assessments/CalculAssessmentsNotes/ClosQuestionListFaculty";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CreateIcon from "@material-ui/icons/Create";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SchoolIcon from "@mui/icons-material/School";
const InstructorCourseAssessmentGroup = ({ assessmentMethod }) => {
  const { t } = useTranslation();
  let history = useHistory();
  function goToConfig(id) {
    history.push(`/citrine/faculty/coursesAssessmentsGroups/${id}/config`);
  }
  // function goToGrades(course_id, assessment_method_id, assessment_method_name) {
  //   history.push(`/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${assessment_method_id}/${assessment_method_name}`);
  // }
  function goToGrades(id) {
    history.push(`/citrine/faculty/coursesAssessmentsGroups/${id}/grades`);
  }

  const avatarList = assessmentMethod?.group_details?.instructors?.map(
    (instructor) => {
      return { name: instructor.first_name, url: instructor.avatar };
    }
  );

  return (
    <GridItem xs={12} sm={6} md={4} lg={4} xl={3} key={assessmentMethod.id}>
      <Card
        withSidePadding
        withHiddenOverflow
        style={{
          height: 270,
          // boxShadow: '0 3px 10px 3px rgb(213 227 246 / 0.5)'
        }}
      >
        <GridContainer alignItems="flex-start">
          <GridItem xs={10} sm={10} md={10}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ height: "40px" }}>
                  <h5>{assessmentMethod?.assessmentMethod_details?.name}</h5>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Code>
                  {`${assessmentMethod?.group_details?.group_name} Group`}
                </Code>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ height: "40px" }}>
                  <Code>{`${assessmentMethod?.course_details?.code}  `}</Code>
                  <Info>{assessmentMethod?.course_details?.title}</Info>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={2} sm={2} md={2}>
            {/* <DropDown id={assessmentMethod.id} /> */}
            <CustomDropdown
              dropPlacement="bottom-end"
              isButtonIcon
              dropdownList={[
                {
                  text: "Grade",
                  onClickHandler() {
                    goToGrades(assessmentMethod.id);
                  },
                },
                {
                  text: "Config",
                  onClickHandler() {
                    goToConfig(assessmentMethod.id);
                  },
                },
              ]}
            />
          </GridItem>
        </GridContainer>

        {/* <br /> */}

        <div style={{ paddingLeft: 5, paddingRight: 5 }}>
          <GridContainer justifyContent="space-between" alignItems="center">
            <GridItem>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Students" placement="top">
                  <span style={{ color: infoColor[0] }}>
                    <SchoolIcon />
                  </span>
                </Tooltip>
                <Info style={{ textAlign: "center" }}>
                  {assessmentMethod?.notes_indicators?.students_count}
                </Info>
              </div>
            </GridItem>
            <GridItem>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Questions" placement="top">
                  <span style={{ color: infoColor[0] }}>
                    <LiveHelpIcon />
                  </span>
                </Tooltip>
                <Info style={{ textAlign: "center" }}>
                  {assessmentMethod?.notes_indicators?.questions_count}
                </Info>
              </div>
            </GridItem>
            <GridItem>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Evidence Upload" placement="top">
                  <span style={{ color: infoColor[0] }}>
                    <DriveFolderUploadRoundedIcon />
                  </span>
                </Tooltip>
                <Info style={{ textAlign: "center" }}>
                  {assessmentMethod?.notes_indicators?.evidences_upload_rating.toFixed(
                    0
                  )}{" "}
                  <small>%</small>
                </Info>
              </div>
            </GridItem>
            <GridItem>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Note Entry" placement="top">
                  <span style={{ color: infoColor[0] }}>
                    <CreateIcon />
                  </span>
                </Tooltip>
                <Info style={{ textAlign: "center" }}>
                  {assessmentMethod?.notes_indicators?.notes_entry_indicators.toFixed(
                    0
                  )}{" "}
                  <small>%</small>
                </Info>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <CardFooter stats>
          <GridContainer alignItems="center">
            <GridItem xs={7} sm={7} md={7}>
              <AvatarsGroup avatars={avatarList} />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <GridContainer alignItems="center" justifyContent="flex-end">
                <GridItem>
                  <SlCalender fontSize="small" />
                </GridItem>
                <GridItem>
                  <Info>
                    {assessmentMethod?.assessmentMethod_details?.date ||
                      "--/--/----"}
                  </Info>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function InstructorCourseAssessmentGroupListPage() {
  // ==========================================================================================
  // states
  const [open, setOpen] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  //
  // ==========================================================================================

  // ==========================================================================================
  // hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //
  // ==========================================================================================

  // ==========================================================================================
  // refs
  //
  // ==========================================================================================

  // ==========================================================================================
  // constants

  //
  // ==========================================================================================

  // ==========================================================================================
  // useEffects
  useEffect(() => {
    dispatch(fetchInstructorCourseAssessmentGroups());
  }, []);
  //
  // ==========================================================================================

  // ==========================================================================================
  // redux
  const data = useSelector(
    (state) => state.CourseInstructor.instructorCourseAssessmentGroups
  );
  const instructorCourseAssessmentGroupsStatus = useSelector(
    (state) => state.CourseInstructor.instructorCourseAssessmentGroupsStatus
  );

  // ==========================================================================================

  // ==========================================================================================
  // event handlers

  const fn0 = (value) => {};

  const fn1 = async () => {};

  const onChange = (key) => {
    console.log(key);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  //
  // ==========================================================================================

  // ==========================================================================================
  // functions
  const fn2 = (value) => {};

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  //
  // ==========================================================================================

  // ==========================================================================================
  // conditional rendering

  let fully_entered;
  let not_fully_entered;
  let all;
  let content;

  if (instructorCourseAssessmentGroupsStatus === "loading") {
    content = <div>Loading...</div>;
  } else if (instructorCourseAssessmentGroupsStatus === "succeeded") {
    if (data === "You do not have permission") {
      content = <div>You do not have permission to access courses list</div>;
    } else {
      if (data?.length) {
        let searched = data?.filter((assessment) => {
          if (searchTerm == "") {
            return Assessment;
          } else if (
            assessment?.assessmentMethod_details?.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            assessment?.group_details?.group_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            return Assessment;
          }
        });

        all = searched?.map((assessmentMethod) => (
          <InstructorCourseAssessmentGroup
            key={assessmentMethod.id}
            assessmentMethod={assessmentMethod}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        ));

        fully_entered = searched
          ?.filter(
            (assessment) =>
              assessment?.notes_indicators?.notes_entry_indicators == 100
          )
          .map((assessmentMethod) => (
            <InstructorCourseAssessmentGroup
              key={assessmentMethod.id}
              assessmentMethod={assessmentMethod}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          ));

        not_fully_entered = searched
          ?.filter(
            (assessment) =>
              assessment?.notes_indicators?.notes_entry_indicators !== 100
          )
          .map((assessmentMethod) => (
            <InstructorCourseAssessmentGroup
              key={assessmentMethod.id}
              assessmentMethod={assessmentMethod}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          ));

        const assessmentGroupItems = [
          {
            key: "1",
            label: `Not Fully Entered (${not_fully_entered.length})`,
            children: <GridContainer>{not_fully_entered}</GridContainer>,
          },
          {
            key: "2",
            label: `Fully Entered (${fully_entered.length})`,
            children: <GridContainer>{fully_entered}</GridContainer>,
          },
          {
            key: "3",
            label: `All (${all.length})`,
            children: <GridContainer>{all}</GridContainer>,
          },
        ];

        content = <Tabs defaultActiveKey="1" items={assessmentGroupItems} />;
      } else {
        content = <div>No assessment Methods found.</div>;
      }
    }
  }
  const items = [
    {
      key: "1",
      label: `By Exam`,
      children: content,
    },
    {
      key: "2",
      label: `By CLO`,
      children: <ClosQuestionListFaculty />,
    },
  ];
  //
  // ==========================================================================================

  // ==========================================================================================
  // console logs
  console.log("data", data);
  //
  // ==========================================================================================
  return (
    <>
      {/* <Card withPadding withScroll style={{height:"500px"}}> */}
      <GridContainer justifyContent="space-between">
        <GridItem>
          <Breadcrumb separator=">">
            <Link to="/citrine/faculty/CoursesAssessmentsGroups/">
              <Breadcrumb.Item>Courses’ Assessments’ Groups</Breadcrumb.Item>
            </Link>
          </Breadcrumb>
        </GridItem>
        <GridItem>
          <input
            placeholder="Search"
            style={{
              backgroundColor: "#eeeeee",
              border: "none",
              borderBottom: "1px solid #BDBDBD",
              marginRight: "50px",
              width: "250px",
              height: "30px",
            }}
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </GridItem>
      </GridContainer>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

      {/* </Card> */}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        //  delete_Element={handleDeleteItem}
        //  Element_ID={id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </>
  );
}

// const DropDown = ({ id }) => {

// const handleDeleteItem = async (id) => {
//   console.log('id : ', id)
//   dispatch(deleteUniversity(id))
//     .unwrap()
//     .then((originalPromiseResult) => {
//       dispatch(fetchUniversities())
//       openNotificationWithIcon('success', `Deleted`)
//     })
//     .catch((rejectedValueOrSerializedError) => {
//       setHandledError(rejectedValueOrSerializedError)
//       handleOpenErrorModal()
//     })
// }

//   return (

//   )
// }
