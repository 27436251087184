import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/icons
import { addNewCourseAssessmentMethod } from '../../../../reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSpecSlice';
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { SchoolRounded } from "@material-ui/icons";
import { Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { useHistory } from 'react-router-dom';
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";
import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec";

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
// const classes = useStyles();
export default function CreateCourseAssessmentMethod(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const useTitleStyles = makeStyles(titleStyles);
    // const { label, value, closable, onClose } = props;
    // const onPreventMouseDown = event => {
    //     event.preventDefault();
    //     event.stopPropagation();
    // };
    useEffect(() => {
        dispatch(fetchAssessmentMethods())
        console.log(assessmentMethods)
    }, []
    )

    const titleClasses = useTitleStyles();
    const [weight, setWeight] = useState('')
    const [total_marks, setTotalMarks] = useState('')
    const [courseSpec_id, setCourseSpec_id] = useState('')
    const [assessmentMethod_id, setAssessmentMethod_id] = useState('')
    const [clo_ids, setClo_ids] = useState([])
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const onWeightChanged = (e) => setWeight(e.target.value)
    const onTotalMarksChanged = (e) => setTotalMarks(e.target.value)
    const onCourse_idChanged = (e) => setCourseSpec_id(e.target.value)
    const onAssessmentMethod_idChanged = (e) => setAssessmentMethod_id(e.target.value)
    const onClo_idsChanged = (e) => setClo_ids(e.target.value)

    const dispatch = useDispatch()
    const courses = useSelector((state) => state.course.courses)

    const assessmentMethods = useSelector((state) => state.assessmentMethod.assessmentMethods)

    const { Option } = Select;
    const canSave =
        [weight, total_marks,
            courseSpec_id, assessmentMethod_id, clo_ids].every(Boolean) && addRequestStatus === 'idle'
    const history = useHistory();

    const onSaveCourseAssessmentMethodClicked = async () => {

        // if (canSave) {
        try {
            setAddRequestStatus('pending')
            console.log('onsave')
            const resultAction = await dispatch(
                addNewCourseAssessmentMethod({ weight, total_marks, courseSpec_id, assessmentMethod_id, clo_ids })
            )
            unwrapResult(resultAction)
            setWeight('')
            setTotalMarks('')
            setCourseSpec_id('')
            setAssessmentMethod_id('')
            setClo_ids([])

            history.push('/citrine/CourseSpecificationList');
        } catch (err) {
            console.error('Failed to save the course assessment method: ', err)
        } finally {
            setAddRequestStatus('idle')
        }


    }

    function onChangeCourse_id(value) {
        //  console.log(`selected ${value}`);
        setCourseSpec_id(value);
    }
    function onChangeAssessmentMethod_id(value) {
        //console.log(`selected ${value}`);
        setAssessmentMethod_id(value);
    }
    function onBlur() {
        //   console.log('blur');
    }

    function onFocus() {
        //  console.log('focus');
    }

    function onSearch(val) {
        //  console.log('search:', val);
    }
    const courseStatus = useSelector(state => state.course.courseStatus)




    const handleCourse_id = id => {
        setCourseSpec_id(id);
        //   console.log('course_id', id)
        dispatch(fetchCourseById(id))

    };

    const course = useSelector((state) => state.course.course)
    //  console.log('course', course)


    function handleChangeClos(value) {
        //  console.log(`Selected: ${value}`);
        setClo_ids(value);
    }

    return (

        <GridContainer >

            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <SchoolRounded />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", color: "gray" }}>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText={t("Weight *")}
                                        id="weight"
                                        type="text"
                                        name="weight"
                                        value={weight}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a weight!',
                                            },
                                        ]}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: false,
                                            onChange: event => {
                                                setWeight(event.target.value);
                                            }
                                        }}
                                    />
                                    <CustomInput
                                        labelText={t("Total Marks *")}
                                        id="total_marks"
                                        value={total_marks}
                                        type="text"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a total_marks!',
                                            },
                                        ]}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: false,
                                            onChange: event => {
                                                setTotalMarks(event.target.value);
                                            }
                                        }}
                                    />


                                    <GridItem xs={12} sm={12} md={6} >
                                        <label>{t('Assessment Method *')} </label>
                                        <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            optionFilterProp="children"
                                            name="assessmentMethod_id"
                                            value={assessmentMethod_id}
                                            onChange={onChangeAssessmentMethod_id}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }

                                        >
                                            {assessmentMethods.map((assessmentMethod) => (
                                                <option key={assessmentMethod.id} value={assessmentMethod.id}>
                                                    {assessmentMethod.name}
                                                </option>
                                            ))}

                                        </Select>
                                    </GridItem>

                                    <GridItem>
                                        <Button color="info"
                                            className={classes.updateProfileButton}
                                            onClick={onSaveCourseAssessmentMethodClicked}
                                        >
                                            {t('Save')}
                                        </Button></GridItem>
                                </GridItem>

                            </GridContainer>
                        </form>

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>



    );



}


