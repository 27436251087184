import {createSlice, nanoid, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    types: [],
    typesStatus: 'idle',
    typeStatus: 'idle',
    error: null,
    type: null,
    id: null
}

export const fetchTypes = createAsyncThunk('type/fetchTypes',
    async (param, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(BaseUrl + '/courseAddTypeConfig/',
                {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                }
            )
            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const fetchTypeById =
    createAsyncThunk('type/fetchTypeById', async (id, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.get(BaseUrl + `/courseAddTypeConfig/${id}/`, {
                    headers: {
                        'Authorization': `token ${token}`
                    }
                })
                return response.data;
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        }
    );

export const addNewType = createAsyncThunk(
    'type/addNewType',
    async (initialType, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.post(BaseUrl + `/courseAddTypeConfig/create/`, initialType, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const updateType = createAsyncThunk(
    'type/updateType',
    async (data, {rejectWithValue}) => {
        const token = localStorage.getItem('token')

        try {
            const response = await axios.put(BaseUrl + `/courseAddTypeConfig/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const deleteType = createAsyncThunk('type/deleteType',
    async (id, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.delete(BaseUrl + `/courseAddTypeConfig/${id}/delete/`, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    }
);

const typesSlice = createSlice({
    name: ' type',
    initialState,
    extraReducers: {
        [fetchTypes.pending]: (state, action) => {
            state.typesStatus = 'loading'
        },
        [fetchTypes.fulfilled]: (state, action) => {
            state.typesStatus = 'succeeded'
            state.types = action.payload
        },
        [fetchTypes.rejected]: (state, action) => {
            state.typesStatus = 'failed'
            if (action.payload) {
                state.error = action.payload;
            } else {
                state.error = action.error;
            }
        },

        [fetchTypeById.pending]: (state, action) => {
            state.typestatus = 'loading'
        },
        [fetchTypeById.fulfilled]: (state, action) => {
            state.typestatus = 'succeeded'
            state.type = action.payload
        },
        [fetchTypeById.rejected]: (state, action) => {
            state.typestatus = 'failed'
            if (action.payload) {
                state.error = action.payload;
            } else {
                state.error = action.error;
            }
        },

        [addNewType.fulfilled]: (state, action) => {
            state.types.push(action.payload)
        },
        [addNewType.rejected]: (state, action) => {
          state.typetatus = 'failed'
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
        },

        [deleteType.fulfilled]: (state, action) => {},
        [deleteType.rejected]: (state, action) => {
            state.typetatus = 'failed'
            if (action.payload) {
                state.error = action.payload;
            } else {
                state.error = action.error;
            }
        },

        [updateType.fulfilled]: (state, action) => {
          const result = state.types.filter(type => type.id !== action.payload.id);
          result.push(action.payload)
          state.types = result
        },
        [updateType.rejected]: (state, action) => {
            state.typetatus = 'failed'
            if (action.payload) {
                state.error = action.payload;
            } else {
                state.error = action.error;
            }
        },
    },
})


export default typesSlice.reducer

export const selectAllTypes = state => state.type.types
