import { makeStyles } from "@material-ui/core/styles";
import { Badge, Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DropDownMOIButton from "views/Components/DropDownMOIButton";
import {
  fetchModeOfInstructionConfigs
} from "../../../../reduxToolkit/features/modeOfInstructionConfig/ModeOfInstructionConfigSlice";

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);

export default function ModeOfInstructionConfigPage() {
  const { t } = useTranslation();

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();

  const dispatch = useDispatch();
  //const modeOfInstructionConfigStatus = useSelector((state) => state.FacilitiesRequiredConfig.modeOfInstructionConfigStatus)

  useEffect(() => {
    dispatch(fetchModeOfInstructionConfigs());
  }, []);

  const modeOfInstructionConfigs = useSelector(
    (state) => state.ModeOfInstructionConfig.ModeOfInstructionConfigs
  );

  if (!modeOfInstructionConfigs) {
    return (
      <section>
        <h2>Mode Of Instruction Config not found!</h2>
      </section>
    );
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/configuration/course`}>
              {" "}
              {t("Course Configurations")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/ModeOfInstructionConfigs`}
              style={{ color: "orange" }}
            >
              {t("Mode Of Instruction Config List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridContainer>
        <GridItem>
          <Link to={`/citrine/createModeOfInstructionConfig/:id`}>
            <Button color="info" className={classes.updateProfileButton}>
              {t("Add")}
            </Button>
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {modeOfInstructionConfigs &&
          modeOfInstructionConfigs.map((modeOfInstructionConfig) => (
            <GridItem key={Element.id} xs={12} sm={12} md={4}>
              <Card style={{ height: 200, textAlign: "start" }}>
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={9}>
                      <h4
                        className={classes.cardProductTitle}
                        style={{ textAlign: "start" }}
                      >
                        Name :  {modeOfInstructionConfig.name}
                      </h4>
                      <h5>
                        {modeOfInstructionConfig && modeOfInstructionConfig.areaSpec_details &&
                          modeOfInstructionConfig.areaSpec_details.length ? (
                          modeOfInstructionConfig.areaSpec_details.map((el) => (
                            <Badge
                              className="site-badge-count-109"
                              count={el.name}
                              style={{ backgroundColor: "#52c41a" }}
                            />
                          ))
                        ) : (
                          <span></span>
                        )}
                      </h5>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <DropDownMOIButton id={modeOfInstructionConfig.id} />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}

// const ModeOfInstructionConfigExcerpt = ({ modeOfInstructionConfig }) => {
//   const { t } = useTranslation();
//   console.log(modeOfInstructionConfig)
//   const classes = useStyles();
//   const dispatch = useDispatch()
//   const sendId = (id) => {
//     dispatch(getId(id))

//   }
//   const deleteUniv = (id) => {
//     dispatch(deleteModeOfInstructionConfig(id))
//   }

//   return (

//     <GridContainer
//       md={12}
//       xs={12} sm={12}
//        justify="space-between"
//        alignItems="center"
//     >
//       <GridItem key={modeOfInstructionConfig.id}>

//         <Card product className={classes.cardHover} >
//           <CardBody style={{ width: 200, height: 150 }}>
//             <GridContainer >

//               <GridItem xs={12} sm={12} md={12}>
//                 <GridContainer justify="space-between">
//                   <GridItem xs={12} sm={12} md={10}>
//                   <h4 className={classes.cardProductTitle} >
//                     <a href="#pablo" onClick={e => e.preventDefault()} >
//                       <Link to={`/citrine/modeOfInstructionConfigDetails/${modeOfInstructionConfig.id}`} > {modeOfInstructionConfig.name}</Link>
//                     </a>
//                   </h4>

//                 </GridItem>

//                   <GridItem xs={12} sm={12} md={2}>
//                   <DropDownMOIButton id={modeOfInstructionConfig.id}/>
//                   </GridItem>

//                 </GridContainer>
//               </GridItem>

//             </GridContainer>
//           </CardBody>

//         </Card>

//       </GridItem>
//     </GridContainer>

//   )
// }

// export default function ModeOfInstructionConfigPage() {
//   const { t } = useTranslation();
//   const dispatch = useDispatch()
//   const navBarClasses = useNavBarStyles();
//   const titleClasses = useTitleStyles();

//   const modeOfInstructionConfigsStatus = useSelector(state => state.ModeOfInstructionConfig.ModeOfInstructionConfigsStatus)
//   console.log('status subareas', modeOfInstructionConfigsStatus)

//   useEffect(() => {
//     if (modeOfInstructionConfigsStatus === 'idle') {
//       dispatch(fetchModeOfInstructionConfigs())

//     }
//   }, [modeOfInstructionConfigsStatus, dispatch])

//   const modeOfInstructionConfigs = useSelector(selectAllModeOfInstructionConfig)
//   console.log('*******subareas', modeOfInstructionConfigs)
//   const error = useSelector(state => state.ModeOfInstructionConfig.error)

//   let content

//   if (modeOfInstructionConfigsStatus === 'loading') {
//     content = <div className="loader">Loading...</div>
//   } else if (modeOfInstructionConfigsStatus === 'succeeded') {
//     content = modeOfInstructionConfigs && modeOfInstructionConfigs.map(modeOfInstructionConfig => (

//       <ModeOfInstructionConfigExcerpt key={modeOfInstructionConfig.id} modeOfInstructionConfig={modeOfInstructionConfig} />
//     ))
//   } else if (modeOfInstructionConfigsStatus === 'failed') {
//     content = <div>{error}</div>
//   }
//   return (
// <div>
// <GridContainer style={{
//       borderBottom: `1px solid  ${grayColor[15]}`,
//       borderTop: `1px solid  ${grayColor[15]}`,
//     }}
//       justify="space-between"
//       alignItems="center">
//       <GridItem>
//       <span className={titleClasses.title}>   <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/CourseConfigurations`}>/{t('Course Configuration')}</Link> {t('/Mode Of Instruction Configs')}</span>
//       </GridItem>

//     </GridContainer>

//       <Link to="/citrine/createModeOfInstructionConfig/:id" >
//         <Button color="info">

//          {t(' Add')}
//         </Button>
//       </Link>

//       <GridContainer>
//         {
//           content

//         }

//       </GridContainer>
//  </div>
//   );
// }
