import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch } from "react-redux";

// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import "antd/dist/antd.css";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../../../Components/ErrorModal";


import {
  FormOutlined
} from "@ant-design/icons";
import {
  warningColor
} from "../../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchStaffById, updateStaff
} from "../../../../../reduxToolkit/features/user/UserSlice.js";
import { unwrapResult } from "@reduxjs/toolkit";
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function EditGeneralInformationModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const courseId = props.courseId;
  const [form] = Form.useForm();

  const { Option } = Select;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
   
    form.setFieldsValue({
      first_name:
        props.person && props.person.person_details[0].first_name && props.person.person_details[0].first_name,

      last_name:
        props.person && props.person.person_details[0].last_name && props.person.person_details[0].last_name,

      category: props.person && props.person.person_details[0].category && props.person.person_details[0].category,

      scopus_id: props.person && props.person.scopus_id && props.person.scopus_id,
      full_time: props.person && props.person.full_time && props.person.full_time,

      google_scholar_id: props.person && props.person.google_scholar_id && props.person.google_scholar_id

      , web_of_science_id: props.person && props.person.web_of_science_id && props.person.web_of_science_id

      , rank: props.person && props.person.rank && props.person.rank

      , type_of_academic_appointment: props.person
        && props.person.type_of_academic_appointment && props.person.type_of_academic_appointment

    });
  }, [props.person, dispatch]);

  const onFinish = async (values) => {
    const updated = { ...values };
    console.log('updated', updated)
    const resultAction = await dispatch(
      updateStaff({ id: props.person_id, updated :{ ...values } })
    );
    if (updateStaff.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Edit successfully`);
      dispatch(fetchStaffById(props.person_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  return (
    <div>
      <FormOutlined
        onClick={handleClickOpen}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Edit General Information")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="first_name"
                  label="First name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the First name field.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>

                <Form.Item
                  name="last_name"
                  label="Last name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Last name field.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>

                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please input the role field.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>

                <Form.Item
                  name="scopus_id"
                  label="Scopus"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Scopus ID.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>

                <Form.Item
                  name="google_scholar_id"
                  label="Google Scolar"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Google Scolar ID.",
                    },
                  ]}
                >
                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>




                <Form.Item
                  label={t("Rank")}
                  name="rank"
                  rules={[
                    {
                      required: true,
                      message: "Please select the type of the rank",
                    },
                  ]}
                >
                  <Select
                    dropdownStyle={{ zIndex: 10000 }}
                    // style = {{  width : "100%" }}
                    style={{ marginLeft: "10px" }}
                    size={200}
                    showArrow>
                    <Option value="professor">Professor</Option>
                    <Option value="associate">Associate</Option>
                    <Option value="professor-assistant">Professor-Assistant</Option>
                    <Option value="professor-lecturer">Professor-Lecturer</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t("FT/PT")}
                  name="full_time"
                  rules={[
                    {
                      required: true,
                      message: "Please select the type of time",
                    },
                  ]}
                >
                  <Select
                    dropdownStyle={{ zIndex: 10000 }}
                    // style = {{  width : "100%" }}
                    style={{ marginLeft: "10px" }}
                    size={200}
                    showArrow>
                    <Option value="Full time">Full time</Option>
                    <Option value="Part time">Part time</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t("Academic Appointment")}
                  name="type_of_academic_appointment"
                  rules={[
                    {
                      required: true,
                      message: "Please select the type of the Academic Appointment",
                    },
                  ]}
                >
                  <Select
                    dropdownStyle={{ zIndex: 10000 }}
                    // style = {{  width : "100%" }}
                    style={{ marginLeft: "10px" }}
                    size={200}
                    showArrow>
                    <Option value="non-tenure">Non-tenure</Option>
                    <Option value="track-ntt-tenure">Track-ntt-tenure</Option>
                    <Option value="track-tt-tenured-t">Track-tt-tenured-t</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="web_of_science_id"
                  label="Web Of Science"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Web Of Science.",
                    },
                  ]}
                >


                  <Input style={{ marginLeft: "10px" }} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button

                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}

                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
