import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
/*************MUI ************************/
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { pink } from '@mui/material/colors';
import { addDeleteFromStaff } from "reduxToolkit/features/person/PersonsSlice";
import ErrorModal from "views/Components/ErrorModal.jsx";

export default function AddStaff(props) {
  // useEffect(() => {
  //     dispatch(fetchAllPrograms());
  // }, [dispatch]);
  //const personsStatus = useSelector(state => state.user.users)

  const [coordinator_id, setCoordinator_id] = React.useState(null);
  const [theoryInstructor_id, setTheory_id] = React.useState(null);
  const [courses_ids, setCourses_ids] = React.useState([]);
  const [value, setValue] = React.useState(false);

  const { t } = useTranslation();
  const { Option } = Select;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const dispatch = useDispatch();

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // message.error('Submit failed!');
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const onFinish = async () => {
     
        console.log("person_id onFinish", props.id);
        const resultAction = await dispatch(
          addDeleteFromStaff({ id_person:props.id, activate_staff:value })
        );

        if (addDeleteFromStaff.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            //props.refreshCourses();
            openNotificationWithIcon("success", `added`);
            handleClose();
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                console.log("resultAction.error  from component", resultAction.error);
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        } 
  };
  /*  function handleChangeCoordinator(value) {
       // console.log("value", value);
        setCoordinator_id(value)

    }
    function handleChangeTheory(value) {
        //console.log("value", value);
        setTheory_id(value)

    } */

  
   // console.log(value,props.id)
  return (
    <div>
      <MenuItem
        disabled={props.disabled}
        onClick={() => {
          handleClickOpen();
          props.handleDropDownButtonClose();
        }}
        style={{color:"#8f6682"}}
      >
        {t("Add Staff")}
      </MenuItem>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{t("Add staff")}</DialogTitle>
        <DialogContent>
          <GridContainer justifyContent="center">
            <GridItem xs={10} sm={10} md={12}>
              <Form
                layout="vertical"
                //   labelCol={{ span: 8 }}
                //   wrapperCol={{ span: 16 }}
               onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                
                <FormControl>

                    <RadioGroup
                      defaultValue={false}
                      value={value}
                      onChange={handleChange}
                      name="controlled-radio-buttons-group"

                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio  sx={{
                            color: '#64475b',
                            '&.Mui-checked': {
                              color: '#8f6682',
                            },
                          }}/>}
                        label="Activate"
                       sx={{'.MuiFormControlLabel-label':{color:'#64475b'}}}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio  sx={{
                            color: '#64475b',
                            '&.Mui-checked': {
                              color: '#8f6682',
                            },
                          }}/>}
                        label="Desactivate"
                        sx={{'.MuiFormControlLabel-label':{color:'#64475b'}}}

                      />
                    </RadioGroup>
                    </FormControl>


                <GridContainer justifyContent="center">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
