import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import React from 'react';
import { MediaBaseUrl } from "../../assets/utils/accessToken";
import { greyColor } from "assets/jss/material-dashboard-pro-react.js";
const CustomAvatar = (props) => (

    <>
        {props.name ?
            <Tooltip title={props.name} placement="top">
                {
                    props.url ?
                        <Avatar
                            src={
                                MediaBaseUrl +
                                `/${props.url}`
                            } /> :
                        <Avatar
                            style={{
                                backgroundColor: greyColor[0],
                            }}
                            icon={<UserOutlined />}
                        />
                }
            </Tooltip>
            :
            props.url ?
                <Avatar
                    src={
                        MediaBaseUrl +
                        `/${props.url}`
                    } /> :
                <Avatar
                    style={{
                        backgroundColor: greyColor[0],
                    }}
                    icon={<UserOutlined />}
                />
        }
    </>
);

export default CustomAvatar;
