// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import { unwrapResult } from '@reduxjs/toolkit';
//import Select from "@material-ui/core/Select";
import { Breadcrumb, Select } from 'antd';
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAreas } from '../../../../../reduxToolkit/features/area/AreaSpecSlice';
import { addNewLevel, fetchLevelById, updateLevel } from '../../../../../reduxToolkit/features/level/LevelSlice';







const useTitleStyles = makeStyles(titleStyles);


export default function CreateLevel({ match }) {
    const { t } = useTranslation();
    const { id } = match.params

    console.log('id', id)

    const isAddMode = isNaN(id);
    console.log('id from match is add mode', isAddMode)
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')
    const [areaSpec_ids, setAreaSpec_ids] = useState([]);


    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()


    const areasStatus = useSelector(state => state.areaSpec.areasStatus)

    useEffect(() => {

        if (areasStatus === 'idle') {
            dispatch(fetchAreas())
        }

    }, [areasStatus,
        dispatch])


    const areas = useSelector(state => state.areaSpec.areas)


    const { Option } = Select;

    const titleClasses = useTitleStyles();

    function handleArea_ids(value) {
        //  console.log(`selected ${value}`);
        setAreaSpec_ids(value);
    }


    const onSaveLevelClicked = async () => {
        try {
            setAddRequestStatus('pending')
            const resultAction = await dispatch(
                addNewLevel({ name, tag, areaSpec_ids })
            )
            unwrapResult(resultAction)

        } catch (err) {
            console.error('Failed to save the level: ', err)
        } finally {
            setAddRequestStatus('idle')
        }
    }
    const onUpdateClicked = async () => {
        try {
            setAddRequestStatus('pending')
            const resultAction = await dispatch(
                updateLevel({ id: id, modified: { name, tag, areaSpec_ids } })
            )
            unwrapResult(resultAction)

        } catch (err) {
            console.error('Failed to save the level: ', err)
        } finally {
            setAddRequestStatus('idle')
        }
    }

    useEffect(() => {
        if (!isAddMode) {
            dispatch(fetchLevelById(id))
        }
    }, [id]);

    const level = useSelector((state) => state.level.level)
    console.log('level ', level)

    useEffect(() => {
        if (!isAddMode) {
            setName(level && level.name)
            setTag(level && level.tag)
            setAreaSpec_ids(level && level.areaSpec_ids)

        }
    }, [level, dispatch]);

    return (
        <div>
            {isAddMode &&
                <div>
                    <div>
                        <GridContainer

                            md={12}
                            xs={12} sm={12}
                            direction="row"
                            // style={{ width:300 ,height:150}}
                            justify="space-between"
                            alignItems="center"
                        >
                            <GridItem>

                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item >

                                        {t('Configurations')}

                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={`/citrine/configuration/course`}>
                                            <span > {t('Course Configurations')} </span>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={`/citrine/configuration/course/level`}>
                                            <span > {t('Levels')} </span>
                                        </Link>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </GridItem>

                        </GridContainer>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12} style={{
                                display: "flex", flexDirection: "row", justifyContent: "space-between"
                            }} >
                                <Card>
                                    <CardHeader color="primary" icon>
                                        <CardIcon color="primary">
                                            <MailOutline />
                                        </CardIcon>
                                        <h4>{t('Create')}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        id="name"
                                                        labelText={t('Name ')}
                                                        inputProps={{
                                                            value: name,
                                                            onChange: event => {
                                                                setName(event.target.value);
                                                            },
                                                            level: "text"
                                                        }}

                                                    />
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        id="tag"
                                                        labelText={t('Tag ')}
                                                        inputProps={{
                                                            value: tag,
                                                            onChange: event => {
                                                                setTag(event.target.value);
                                                            },
                                                            level: "text"
                                                        }}

                                                    />
                                                    <GridItem>
                                                        <label>{t('Area ')}</label>

                                                        <Select
                                                            mode="multiple"
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a area"
                                                            optionFilterProp="children"
                                                            value={areaSpec_ids}
                                                            onChange={handleArea_ids}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >

                                                            {areas && areas.map(area => (

                                                                <Option key={area.id} value={area.id}>
                                                                    {area.name}
                                                                </Option>

                                                            ))}

                                                        </Select>
                                                    </GridItem>

                                                    <br />
                                                    <br />
                                                    <Link to={`/citrine/configuration/course/level`}>
                                                        <Button
                                                            color="primary"
                                                            onClick={onSaveLevelClicked}
                                                        >
                                                            {t('Save')}
                                                        </Button>
                                                    </Link>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>

                    </div>
                </div>
            }
            {!isAddMode && level &&
                <div>

                    <div>
                        <GridContainer

                            md={12}
                            xs={12} sm={12}
                            direction="row"
                            // style={{ width:300 ,height:150}}
                            justify="space-between"
                            alignItems="center"
                        >
                            <GridItem>

                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item >

                                        {t('Configurations')}

                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={`/citrine/configuration/course`}>
                                            <span > {t('Course Configurations')} </span>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={`/citrine/configuration/course/level`}>
                                            <span > {t('Levels')} </span>
                                        </Link>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </GridItem>

                        </GridContainer>
                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12} style={{
                                display: "flex", flexDirection: "row", justifyContent: "space-between"
                            }} >
                                <Card>
                                    <CardHeader color="primary" icon>
                                        <CardIcon color="primary">
                                            <MailOutline />
                                        </CardIcon>
                                        <h4>{t('Edit')}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        id="name"
                                                        labelText={t('Name ')}
                                                        inputProps={{
                                                            value: name,
                                                            onChange: event => {
                                                                setName(event.target.value);
                                                            },
                                                            level: "text"
                                                        }}

                                                    />
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        id="tag"
                                                        labelText={t('Tag ')}
                                                        inputProps={{
                                                            value: tag,
                                                            onChange: event => {
                                                                setTag(event.target.value);
                                                            },
                                                            level: "text"
                                                        }}

                                                    />
                                                    <GridItem>
                                                        <label>{t('Area')} </label>

                                                        <Select
                                                            mode="multiple"
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a area"
                                                            optionFilterProp="children"
                                                            value={areaSpec_ids}
                                                            onChange={handleArea_ids}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >

                                                            {areas && areas.map(area => (

                                                                <Option key={area.id} value={area.id}>
                                                                    {area.name}
                                                                </Option>

                                                            ))}

                                                        </Select>
                                                    </GridItem>

                                                    <br />
                                                    <br />
                                                    <Link to={`/citrine/configuration/course/level`}>
                                                        <Button
                                                            color="primary"
                                                            onClick={onUpdateClicked}
                                                        >
                                                            Update
                                                        </Button>
                                                    </Link>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>

                    </div>

                </div>
            }
        </div>
    )
}



