import { Table, Tag } from 'antd';
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";

const expandedRowKeys = [-1];

export default function ActualLearningHourTreeList({ id }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [disabledStatus, setDisabledStatus] = useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchCourseByIdMethod = async () => {
    try {
      const resultAction = await dispatch(fetchCourseById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchCourseByIdMethod();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const ActualLearningHours = useSelector(
    (state) => state.ActualLearningHourSpec.ActualLearningHours
  );


  const [view, setView] = useState("list");

  const columns = [

    {
      title: 'type',
      key: 'type',
      dataIndex: 'type',
      render: (_, { type_details }) => (
        <>
          {type_details?.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            console.log(tag);
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag?.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'learningHours',
      dataIndex: 'learning_hours',
      key: 'learningHours',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'activity',
      key: 'activity',
      dataIndex: 'activity',
      render: (_, { activity_details }) => (
        <>
          {activity_details?.map((tag) => {
            return (
              <Tag key={tag}>
                {tag?.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },

  ];
  // const data = [
  //   {
  //     key: '1',
  //     name: 'John Brown',
  //     age: 32,
  //     address: 'New York No. 1 Lake Park',
  //     tags: ['nice', 'developer'],
  //   },
  //   {
  //     key: '2',
  //     name: 'Jim Green',
  //     age: 42,
  //     address: 'London No. 1 Lake Park',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '3',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sydney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  // ];

  return (
    <div>
      <GridContainer justify="space-between" alignItems="baseline">
        <GridContainer>
          <GridItem>
            {disabledStatus ? (
              <p></p>
            ) : (
              <Link
                to={`/citrine/specification/course/${course && course.id && course.id
                  }/ActualLearningHours/create`}
              >
                <Button color="info" >
                  {t("Add")}
                </Button>
              </Link>
            )}
          </GridItem>
        </GridContainer>
        {/* <TreeList
          style={{ maxHeight: "440px" }}
          dataSource={ActualLearningHour}
          rootValue={-1}
          defaultExpandedRowKeys={expandedRowKeys}
          showRowLines={true}
          showBorders={true}
          columnAutoWidth={true}
          keyExpr="ID"
          parentIdExpr="Head_ID"
        >
          <Column dataField="type" caption="Type" />
          <Column dataField="activity" />
          <Column dataField="learning_hours" caption="Learning Hours" />
        </TreeList> */}
        <Table columns={columns} dataSource={ActualLearningHours} style={{ width: '1020px' }} />
      </GridContainer>
    </div>
  );
}
const ActualLearningHour =
  [
    {
      ID: 100,
      Head_ID: -1,
      activity: "",
      type: "Type 1",
      learning_hours: "100",
    },

    {
      ID: 101,
      Head_ID: -1,
      activity: "",
      type: "Type 2",
      learning_hours: "80",
    },
    {
      ID: 102,
      Head_ID: -1,
      activity: "",
      type: "Tutorial",
      learning_hours: "70",
    },

    {
      ID: 1,
      Head_ID: 100,
      activity: "hTML",
      type: "",
      learning_hours: "80",
    },
    {
      ID: 2,
      Head_ID: 100,
      activity: "CSS",
      type: "",
      learning_hours: "20",
    },

    {
      ID: 3,
      Head_ID: 101,
      activity: "Lab 1",
      type: "",
      learning_hours: "60",
    },
    {
      ID: 4,
      Head_ID: 101,
      activity: "Lab 2",
      type: "",
      learning_hours: "20",
    },

    {
      ID: 5,
      Head_ID: 102,
      activity: "Tutorial 1",
      type: "",
      learning_hours: "30",
    },
    {
      ID: 5,
      Head_ID: 102,
      activity: "Tutorial 2",
      type: "",
      learning_hours: "30",
    },
  ];
