import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  GradesAndStatus: [],
  gardeStatus: "idle",
  error: null,
};

export const fetchGradesAndStatus = createAsyncThunk(
  "DistributionOfGrades/fetchDistributionOfGrades",
  async (obj) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/gradesAndStatus/?course_id=${obj.course_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);

export const addNewdistributionOfGrades = createAsyncThunk(
  "DistributionOfGrades/addNewdistributionOfGrades",
  async (obj) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/distributionOfGrades/create/`,
      obj,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of DistributionOfGrades");

    return response.data;
  }
);

const DistributionOfGradesSlice = createSlice({
  name: " DistributionOfGrades",
  initialState,
  extraReducers: {
    [fetchGradesAndStatus.pending]: (state, action) => {
      state.gardeStatus = "loading";
    },
    [fetchGradesAndStatus.fulfilled]: (state, action) => {
      state.gardeStatus = "succeeded";
      state.GradesAndStatus = action.payload;
    },
    [fetchGradesAndStatus.rejected]: (state, action) => {
      state.gardeStatus = "failed";
      state.error = action.payload;
    },

    [addNewdistributionOfGrades.fulfilled]: (state, action) => { },

  },

  reducers: {},
});

export const { getId } = DistributionOfGradesSlice.actions;

export default DistributionOfGradesSlice.reducer;

