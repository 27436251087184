// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
//import DropDownButtonActualLearningHour from "../../../../Components/DropDownButtonActualLearningHour"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSemesters } from "reduxToolkit/features/semester/SemestersSlice";


const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);


export default function SemesterList(props) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(fetchSemesters())

    }, [dispatch])


    const semesters = useSelector((state) => state.semester.semesters)
    console.log('semesters', semesters)


    if (!semesters) {
        return (
            <section>
                <h2>{t('semesters not found!')}</h2>
            </section>
        )
    }

    return (
        <div>

            <GridItem>
                <Breadcrumb separator=">">

                    {/* <Breadcrumb.Item >
              <Link to={`/citrine/OthersConfigurations`} >
              Others Configurations
              </Link>
            </Breadcrumb.Item> */}

                    <Breadcrumb.Item>
                        <Link to={`/citrine/AcademicYearList/`}>{t('Academic Year List')}
                        </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <Link style={{ color: "orange" }} to={`/citrine/semesterList/`}>{t('semester List')}
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </GridItem>

            <GridContainer>
                {semesters && semesters.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 150, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={12} sm={12} md={11}>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                {t('Name :')} {Element.name}
                                            </a>

                                        </h4>


                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={1} style={{ zIndex: 1 }} >
                                        {/* <DropDownButtonActualLearningHour ActualLearningHour_ID={Element.id} /> */}
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>


        </div >

    );

}




