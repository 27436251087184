import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  persons: [],
  personsAccordingToCommunityType : [],
  personsStatus: "idle",
  personStatus: "idle",
  personsAccordingToCommunityTypeStatus : "idle",
  error: null,
  person: null,
};

export const fetchPersons = createAsyncThunk(
  "person/fetchPersons",
  async (param,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + "/persson/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    //console.log(response.data,'response.data of persons')

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);

export const fetchUserAccordingToCommunityType = createAsyncThunk(
  "person/fetchUserAccordingToCommunityType",
  async (type,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/user/by_type/?type=${type}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    console.log(response.data,'response.data of persons')

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);



export const fetchPersonById = createAsyncThunk(
  "person/fetchPersonById",
  async (id,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/persson/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    // console.log(response.data,'response.data')

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);
export const addNewPerson = createAsyncThunk(
  "person/addNewPerson",
  async (uploadData,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/persson/create/`,
      uploadData,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);

export const updatePerson = createAsyncThunk(
  "person/updatePerson",
  async (data, {rejectWithValue}) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/persson/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "data");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateStatePerson = createAsyncThunk(
  "person/updateStatePerson",
  async (data, {rejectWithValue}) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/persson/${data.id}/teachingStaff/update/`,
        data.updatedPerson,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedPerson");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const avatarPerson = createAsyncThunk(
  "person/avatarPerson",
  async (data,{rejectWithValue}) => {
    console.log('data from peson slice avatar', data.uploadData)
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/persson/${data.id}/edit/avatar`,
        data.uploadData,
        { 
          headers: {
            Authorization: `token ${token}`,
            //'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
          },
        }
      );

      console.log(response.data, "response.data");
      console.log("upload data from slice", response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const deletePerson = createAsyncThunk(
  "person/deletePerson",
  async (id,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.delete(BaseUrl + `/persson/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    // console.log(response.data,'response.data')

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);
export const addDeleteFromStaff = createAsyncThunk(
  "person/addDeleteFromStaff",
  async (uploadData,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/persson/addDeleteFromStaff/`,
      uploadData,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);
const personsSlice = createSlice({
  name: " person",
  initialState,
  extraReducers: {
    [fetchPersons.pending]: (state, action) => {
      state.personsStatus = "loading";
    },
    [fetchPersons.fulfilled]: (state, action) => {
      state.personsStatus = "succeeded";
      // Add any fetched posts to the array
      state.persons = action.payload;
    },
    [fetchPersons.rejected]: (state, action) => {
      state.personsStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },


    [fetchUserAccordingToCommunityType.pending]: (state, action) => {
      state.personsAccordingToCommunityTypeStatus = "loading";
    },
    [fetchUserAccordingToCommunityType.fulfilled]: (state, action) => {
      state.personsAccordingToCommunityTypeStatus = "succeeded";
      // Add any fetched posts to the array
      state.personsAccordingToCommunityType = action.payload;
    },
    [fetchUserAccordingToCommunityType.rejected]: (state, action) => {
      state.personsAccordingToCommunityTypeStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },




















    




    [fetchPersonById.pending]: (state, action) => {
      state.personStatus = "loading";
    },
    [fetchPersonById.fulfilled]: (state, action) => {
      state.personStatus = "succeeded";

      // Add any fetched posts to the array
      state.person = action.payload;
    },
    [fetchPersonById.rejected]: (state, action) => {
      state.personStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewPerson.fulfilled]: (state, action) => {
      state.persons.push(action.payload);
    },
    [addNewPerson.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deletePerson.fulfilled]: (state, action) => {
      // state.persons.push(action.payload)
    },
    [deletePerson.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updatePerson.fulfilled]: (state, action) => {
      console.log(action);
    },
    [updatePerson.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateStatePerson.fulfilled]: (state, action) => {
      console.log(action);
    },
    [updateStatePerson.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload;
    },

    personUpdated(state, action) {
      const {
        id,
        name,
        code,
        description,
        period,
        start_date,
        end_date,
      } = action.payload;
      const existingPerson = state.persons.find((person) => person.id === id);
      if (existingPerson) {
        existingPerson.name = name;
        existingPerson.code = code;
        existingPerson.description = description;
        existingPerson.period = period;
        existingPerson.start_date = start_date;
        existingPerson.end_date = end_date;
      }
    },
  },
});

export const { getId } = personsSlice.actions;

export default personsSlice.reducer;
export const persons = (state) => state.person.persons;


