import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";

const WorkInLieuOfCourses = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>STUDENTS: Work in Lieu of Courses</h3>
      <p className={styles.reportParagraphTitle}>
        The following is a summary of the requirements, process, and
        documentation for awarding credit for work in lieu of courses. This
        could include such things as life experience, Advanced Placement, dual
        enrollment, test out, military experience, etc:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is a description of the work in lieu of courses initially
        defined for the program:
      </p>
      <p>{data.WorkInLieuOfCourses}</p>
    </>
  );
};

export default WorkInLieuOfCourses;
