import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { Tabs, notification } from "antd";
import { DocumentBaseUrl } from "../../../../../assets/utils/accessToken";
import FileUploader from "./FileUploader";
import SuperGeneralDeleteModal from "../../../../Components/SuperGeneralDeleteModal";
import ErrorModal from "../../../../Components/ErrorModal";

export default function TranscriptsFiles(props) {
  const dispatch = useDispatch();

  //error modal
  const [errorModalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setErrorModalIsOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const columns = [
    {
      field: "title",
      headerName: "Document",
      width: 500,
      renderCell: (params) => (
        <a
          style={{}}
          href={DocumentBaseUrl + `${params.value}`}
          target="_blank"
        >
          {params.value.slice(10)}
        </a>
      ),
    },
    {
      field: "created_at",
      headerName: "Uploaded at",
      width: 200,
    },
    {
      field: "id",
      headerName: "Delete",
      renderCell: (params) => (
        <GridContainer justifyContent="center">
          <GridItem>
            <Button
              justIcon
              round
              color="primary"
              onClick={() => {
                // handleOpenDeleteModal();
                // setToBeDeletedDocument(params.value);
              }}
            >
              <DeleteIcon />
            </Button>
          </GridItem>
        </GridContainer>
      ),
    },
  ];

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  let evidence_types_list = [];

  const rows = evidence_types_list?.map((document) => {
    return {
      id: document.id,
      title: document.document,
    };
  });

  return (
    <>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <FileUploader />
        </GridItem>
      </GridContainer>
      <div style={{ height: 400 }}>
        <DataGrid
          // rows={evidenceDocuments.length > 0 ? evidenceDocuments : []}
          rows={rows || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableRowSelectionOnClick
          disableSelectionOnClick
        />
      </div>
      <ErrorModal
        open={errorModalIsOpen}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
