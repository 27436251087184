import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    ModeOfInstructionConfigs: [],
    ModeOfInstructionConfigStatus: 'idle',
    ModeOfInstructionConfigsStatus: 'idle',
    error: null,
    ModeOfInstructionConfig: null,
    modesByCourse: [],
    id: null
}

export const fetchModeOfInstructionConfigs =
    createAsyncThunk('ModeOfInstructionConfig/fetchModeOfInstructionConfigs',
        async (param, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.get(BaseUrl + `/modeOfInstructionConfig/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

export const fetchModeOfInstructionConfigsByCourseId =
    createAsyncThunk('ModeOfInstructionConfig/fetchModeOfInstructionConfigsByCourseId',
        async (courseSpec_id, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.get(BaseUrl + `/modeOfInstructionSpec/areaSpec_filtered/?courseSpec_id=${courseSpec_id}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

export const fetchModeOfInstructionConfigById =
    createAsyncThunk('ModeOfInstructionConfig/fetchModeOfInstructionConfigById',
        async (id, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.get(BaseUrl + `/modeOfInstructionConfig/${id}/`, {
                    headers: {
                        'Authorization': `token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

export const addNewModeOfInstructionConfig = createAsyncThunk(
    'ModeOfInstructionConfig/addNewModeOfInstructionConfig',
    async (initialModeOfInstructionConfig, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.post(BaseUrl + `/modeOfInstructionConfig/create/`, initialModeOfInstructionConfig, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const ModeOfInstructionConfigUpdate = createAsyncThunk(
    'ModeOfInstructionConfig/ModeOfInstructionConfigUpdate',
    async (data, {rejectWithValue}) => {
        try {
            const token = localStorage.getItem('token')
            const response = await axios.put(BaseUrl + `/modeOfInstructionConfig/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const deleteModeOfInstructionConfig =
    createAsyncThunk('ModeOfInstructionConfig/deleteModeOfInstructionConfig', async (id, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.delete(BaseUrl + `/modeOfInstructionConfig/${id}/delete/`, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

const ModeOfInstructionConfigSlice = createSlice({
    name: 'ModeOfInstructionConfig',
    initialState,
    // commit
    extraReducers: {
        [fetchModeOfInstructionConfigs.pending]: (state, action) => {
            state.ModeOfInstructionConfigsStatus = 'loading'
        },
        [fetchModeOfInstructionConfigs.fulfilled]: (state, action) => {
            state.ModeOfInstructionConfigsStatus = 'succeeded'
            state.ModeOfInstructionConfigs = action.payload
        },
        [fetchModeOfInstructionConfigs.rejected]: (state, action) => {
            state.ModeOfInstructionConfigsStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [fetchModeOfInstructionConfigsByCourseId.pending]: (state, action) => {
            state.ModeOfInstructionConfigsStatus = 'loading'
        },
        [fetchModeOfInstructionConfigsByCourseId.fulfilled]: (state, action) => {
            state.ModeOfInstructionConfigsStatus = 'succeeded'
            state.modesByCourse = action.payload
        },
        [fetchModeOfInstructionConfigsByCourseId.rejected]: (state, action) => {
            state.ModeOfInstructionConfigsStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },


        [fetchModeOfInstructionConfigById.pending]: (state, action) => {
            state.ModeOfInstructionConfigStatus = 'loading'
        },
        [fetchModeOfInstructionConfigById.fulfilled]: (state, action) => {
            state.ModeOfInstructionConfigStatus = 'succeeded'
            state.ModeOfInstructionConfig = action.payload
        },
        [fetchModeOfInstructionConfigById.rejected]: (state, action) => {
            state.ModeOfInstructionConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [addNewModeOfInstructionConfig.fulfilled]: (state, action) => {
            state.ModeOfInstructionConfigs.push(action.payload)
        },
        [addNewModeOfInstructionConfig.rejected]: (state, action) => {
            state.ModeOfInstructionConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [deleteModeOfInstructionConfig.fulfilled]: (state, action) => {},
        [deleteModeOfInstructionConfig.rejected]: (state, action) => {
            state.ModeOfInstructionConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [ModeOfInstructionConfigUpdate.fulfilled]: (state, action) => {
            console.log(action)
        },
        [ModeOfInstructionConfigUpdate.rejected]: (state, action) => {
            state.ModeOfInstructionConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },


        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },

            ModeOfInstructionConfigUpdated(state, action) {
                const {id, mode_of_instruction, contact_hours, percentage} = action.payload
                const existingModeOfInstructionConfig = state.ModeOfInstructionConfigs.find(ModeOfInstructionConfig => ModeOfInstructionConfig.id === id)
                if (existingModeOfInstructionConfig) {
                    existingModeOfInstructionConfig.mode_of_instruction = mode_of_instruction
                    existingModeOfInstructionConfig.contact_hours = contact_hours
                    existingModeOfInstructionConfig.percentage = percentage
                }
            }
        }
    }
})

export const {
    ModeOfInstructionConfigAdded,
    ModeOfInstructionConfigUpdated,
    getId
} = ModeOfInstructionConfigSlice.actions

export default ModeOfInstructionConfigSlice.reducer

export const selectAllModeOfInstructionConfig = state => state.ModeOfInstructionConfig.ModeOfInstructionConfigs

