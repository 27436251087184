import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const FileDownload = require("js-file-download");
const initialState = {
  courseSyllabusReportDocuments: [],
  courseSyllabusReportDocumentsStatus: 'idle',
  courseSyllabusReportDocument: null,
  courseSyllabusReportDocumentStatus: 'idle',
  courseSyllabusReportDocumentPDFs: [],
  courseSyllabusReportDocumentPDFsStatus: 'idle',
  error: null,

}

export const fetchCourseSyllabusReportDocuments = createAsyncThunk('courseSyllabusReportDocument/fetchCourseSyllabusReportDocuments',
  async ({ rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + '/htmlTemplateCourseSyllabusReport/',
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }

      )
      console.log(response.data, 'response.data')


      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseSyllabusReportDocumentsByCourseSpecId =
  createAsyncThunk('courseSyllabusReportDocument/fetchCourseSyllabusReportDocumentsByCourseSpecId',
    async (id, { rejectWithValue }) => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(BaseUrl + `/htmlTemplateCourseSyllabusReport/by_courseSpec/?courseSpec_id=${id}`,
          {
            headers: {
              'Authorization': `Token ${token}`
            }
          }

        )
        console.log(response.data, 'response.data')


        return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );

export const fetchCourseSyllabusReportDocumentById =
  createAsyncThunk('courseSyllabusReportDocument/fetchCourseSyllabusReportDocumentById',
    async (id, { rejectWithValue }) => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(BaseUrl + `/htmlTemplateCourseSyllabusReport/${id}/`, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        // console.log(response.data,'response.data')

        return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );
export const addNewCourseSyllabusReportDocument = createAsyncThunk(
  'courseSyllabusReportDocument/addNewCourseSyllabusReportDocument',
  async (initialCourseSyllabusReportDocument, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/htmlTemplateCourseSyllabusReport/create/`, initialCourseSyllabusReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }

  }
)
export const addNewFieldExperienceReportDocument = createAsyncThunk(
  'courseSyllabusReportDocument/addNewFieldExperienceReportDocument',
  async (initialCourseSyllabusReportDocument, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/htmlTemplateCourseSyllabusReport/create/fieldExperience/`, initialCourseSyllabusReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }

  }
)
export const addNewPDF = createAsyncThunk(
  'courseSyllabusReportDocument/addNewPDF',
  async (obj, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/convert_html_pdf/?ressource=${obj.ressource}&ressource_id=${obj.ressourceId}`, obj, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }

  }
)
export const downloadPDF = createAsyncThunk(
  'courseSyllabusReportDocument/downloadPDF',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/attachment/${id}`, id, {
        headers: {
          'Authorization': `token ${token}`,
          'ContentType': 'application/json'
        }
      }).then(response => response.blob()).then(response => {
        FileDownload(response, "doc.pdf");
        console.log("success!", response);
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }

  }
)
export const updateCourseSyllabusReportDocument = createAsyncThunk(
  'courseSyllabusReportDocument/updateCourseSyllabusReportDocument',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/htmlTemplateCourseSyllabusReport/${data.id}/edit/`, data.updatedCourseSyllabusReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseSyllabusReportDocument = createAsyncThunk('courseSyllabusReportDocument/deleteCourseSyllabusReportDocument',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(BaseUrl + `/htmlTemplateCourseSyllabusReport/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const VersionningDocumentReportCourseSpec = createAsyncThunk(
  'courseSyllabusReportDocument/VersionningDocumentReportCourseSpec',
  async (initialCourse, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/htmlTemplateCourseSyllabusReport/version/`, initialCourse, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


const courseSyllabusReportDocumentsSlice = createSlice({
  name: ' courseSyllabusReportDocument',
  initialState,
  extraReducers: {

    [fetchCourseSyllabusReportDocuments.pending]: (state, action) => {
      state.courseSyllabusReportDocumentsStatus = 'loading'
    },
    [fetchCourseSyllabusReportDocuments.fulfilled]: (state, action) => {
      state.courseSyllabusReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.courseSyllabusReportDocuments = action.payload
    },
    [fetchCourseSyllabusReportDocumentsByCourseSpecId.rejected]: (state, action) => {
      state.courseSyllabusReportDocumentsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseSyllabusReportDocumentsByCourseSpecId.pending]: (state, action) => {
      state.courseSyllabusReportDocumentsStatus = 'loading'
    },
    [fetchCourseSyllabusReportDocumentsByCourseSpecId.fulfilled]: (state, action) => {
      state.courseSyllabusReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.courseSyllabusReportDocuments = action.payload
    },
    [fetchCourseSyllabusReportDocumentsByCourseSpecId.rejected]: (state, action) => {
      state.courseSyllabusReportDocumentsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },


    [fetchCourseSyllabusReportDocumentById.pending]: (state, action) => {
      state.courseSyllabusReportDocumentStatus = 'loading'
    },
    [fetchCourseSyllabusReportDocumentById.fulfilled]: (state, action) => {
      state.courseSyllabusReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.courseSyllabusReportDocument = action.payload
    },
    [fetchCourseSyllabusReportDocumentById.rejected]: (state, action) => {
      state.courseSyllabusReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewCourseSyllabusReportDocument.fulfilled]: (state, action) => {
      state.courseSyllabusReportDocuments.push(action.payload);

    },
    [addNewFieldExperienceReportDocument.fulfilled]: (state, action) => {
      state.courseSyllabusReportDocuments.push(action.payload);

    },
    [addNewPDF.fulfilled]: (state, action) => {
      state.courseSyllabusReportDocumentPDFs.push(action.payload);

    },
    [deleteCourseSyllabusReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("CourseSyllabusReportDocument delete action.payload", action.payload)
    },
    [updateCourseSyllabusReportDocument.fulfilled]: (state, action) => {

      console.log("CourseSyllabusReportDocument update action.payload", action.payload)

    },
    [addNewCourseSyllabusReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewFieldExperienceReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewPDF.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseSyllabusReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [updateCourseSyllabusReportDocument.rejected]: (state, action) => {

      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [VersionningDocumentReportCourseSpec.fulfilled]: (state, action) => {
    },
    [VersionningDocumentReportCourseSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

  }

})


export default courseSyllabusReportDocumentsSlice.reducer


