import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Input, message, notification, InputNumber  } from "antd";
import Button from 'components/CustomButtons/Button';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AffectCoursesToProgram, fetchNotAffectedCourses, fetchProgramById, fetchProgramLevels } from '../../../../reduxToolkit/features/program/ProgramSpecSlice';
import ErrorModal from "../../../Components/ErrorModal";
import { addNewGAReportDocument, deleteGAReportDocument, fetchGAReportDocumentsByProgramId } from "../../../../reduxToolkit/features/GAReportDocument/GAReportDocumentSlice";




export default function AddDocument(props) {
    console.log(props)
    const { t } = useTranslation();
    const dispatch = useDispatch()


    useEffect(() => {
        // if (programsStatus === 'idle') {
        //}
    }, [
        //programsStatus,
        dispatch])


    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };


    const onFinish = async (values) => {
        const version= parseFloat(values.version)
        let toAddDocument={...values, version, ressource: "ga", ressourceId: props.programId}
        const resultAction = await dispatch(addNewGAReportDocument(toAddDocument))

        console.log("values", values)

        if (addNewGAReportDocument.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk

            openNotificationWithIcon('success', `Added`)
            props.handleClose()
            dispatch(fetchGAReportDocumentsByProgramId(props.programId))
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                props.handleClose()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                props.handleClose()
            }
        }

    }



    return (
        <div>

            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth
                maxWidth="sm">
                <DialogTitle id="form-dialog-title">{t('Add Report Document')}</DialogTitle>
                <DialogContent>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        scrollToFirstError
                    >

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            <Form.Item
                      label={t("Name")}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                            <Form.Item
                      label={t("Version")}
                      name="version"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                            </GridItem>

                        </GridContainer>

                        {/* </FormControl> */}
                        <GridContainer justify="flex-end">
                            <GridItem>
                                <Form.Item>
                                    <GridContainer>
                                        <GridItem>
                                            <Button color="primary" onClick={() => { props.handleClose() }}>
                                                {t('Cancel')}
                                            </Button>
                                        </GridItem>
                                        <GridItem>
                                            <Button type="submit" color="primary">
                                                {t('Save')}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Form.Item>
                            </GridItem>
                        </GridContainer>
                    </Form>
                </DialogContent>
            </Dialog>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
        </div>
    );
}
