import CardHeader from "components/Card/CardHeader.js";

import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider, notification, Tabs } from "antd";
import "antd/dist/antd.css";
// core components
import {
  blackColor,
  cardTitle,
  grayColor,
  hexToRgb, infoColor, primaryColor, roseColor, whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/diagram-js.css";
import Modeler from "bpmn-js/lib/Modeler";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import { FaHistory, FaProjectDiagram, FaTasks } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchActivities } from "../../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import {
  GetDiagram,
  GetHistoricTasks,
  GetProcessDefinitionByKey,
  GetUserProfile
} from "../../../../../reduxToolkit/features/camunda/CamundaSlice";
import {
  CourseReportUpdate, fetchCourseReports
} from "../../../../../reduxToolkit/features/CourseImprovement/courseImprovementSlice";
import {
  fetchStaff, fetchStaffById,
  updateStateStaff
} from "../../../../../reduxToolkit/features/user/UserSlice";
import CompleteCourseReportModal from "../../../../Components/CompleteCourseReportModal";
import "../cvTech.css";

import Modal from "../Modal";

import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/diagram-js.css";

import CardFooter from "components/Card/CardFooter";




//********************* */

import { useParams } from "react-router-dom";
import ErrorModal from "../../../../Components/ErrorModal";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import clsx from "clsx";


import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

import { fetchProfessionalDevelopmentById, updateProfessionalDevelopmentServiceActivity } from "../../../../../reduxToolkit/features/ProfessionalDevelopment/ProfessionalDevelopmentSlice";


import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import UploadAttachmentProfessionalDevelopment from "views/Components/Uploaders/UploadProfessionalDevelopment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const theme = createTheme({
  overrides: {
    MuiStepper: {
      root: {
        background: 'none',
        border: 'none',
        padding: 0
      },
    },
  },
});
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
  },
});















//******************************** */

const styles2 = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles2 = makeStyles(styles2);

const { TabPane } = Tabs;
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);


const ProfessionalDevelopmentExcerpt = ({ activity, personId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch();

  return (


    <GridItem xs={12} sm={12} md={12} key={activity.id}>
      <Card>
        <GridContainer
          justify="space-between"
          alignItems="baseline"
        >
          <GridItem>
            <h5
              style={{

                paddingLeft: 20,
              }}
            > {activity && activity.title} </h5>
          </GridItem>
        </GridContainer>
      </Card>
      <Card style={{ maxHeight: "380px", minHeight: "380px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/faculty/teachingStaff/${personId}/professionalDevelopmentActivity/${activity.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {/* {activity && activity.title} */}
                </h4>
              </Link>
            </GridItem>
            {/* <GridItem xs={2} sm={2} md={2}>
            <ProgramCardDropdownButton id={program.id} program={program} />
          </GridItem> */}
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Type")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.type}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Activity level")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.colleges_details && activity.colleges_details[0] && `${activity.colleges_details[0].name}`}
              {activity && activity.departments_details && activity.departments_details[0] && `${activity.departments_details[0].name}`}
              {activity && activity.program_details && activity.program_details[0] && `program ${activity.program_details[0].program_degree_name}`}
              {activity && activity.courses_details && activity.courses_details[0] && `course ${activity.courses_details[0].title}`}

            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Organization")}:
            <span style={{ fontWeight: "normal", color: "black" }}> {activity && activity.organization} </span>
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Theme")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.theme}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Start Date")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.start_date}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("End Date")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.end_date}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Location")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.location && activity.location}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Trainer")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.trainer}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Team members")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.team_members_details[0] && `${activity.team_members_details[0].first_name} ${activity.team_members_details[0].last_name}`}
            </span>
          </div>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Description")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.description}
            </span>
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Authority")}:
            <span style={{ fontWeight: "normal", color: "black" }}>
              {activity && activity.authorityLevelConfig_details && activity.authorityLevelConfig_details.username}
            </span>
          </div>


        </CardBody>
        <CardFooter>
          <GridContainer xs={2} sm={2} md={12}>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "start" }}>
              <span
                style={{
                  color: "#FFCB3B",
                }}
              >
                {/* V {program && program.version} */}
              </span>
            </GridItem>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "end" }}>
              <span
                style={{
                  color: activity.state === "Draft" ? grayColor[1] : "#FFCB3B",
                }}
              >
                {/* {activity && activity.state} */}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function Activity({ match }) {
  const { t } = useTranslation();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes = useStyles();

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  const { id } = match.params;
  // console.log("id of course report ", id)
  const dispatch = useDispatch();
  const [current, setCurrent] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);

  const [showActivities, setShowActivities] = useState(true);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);

  const [open, setOpen] = React.useState(false);

  //bpmn
  const [diagram, diagramSet] = useState("");
  var container = document.getElementById("container");
  const [dynamicTask, setDynamicTask] = useState([]);

  const ProcessInstanceStatus = useSelector(
    (state) => state.camunda.ProcessInstanceStatus
  );
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const CourseReport = useSelector((state) => state.CourseReport.CourseReport);
  const Activities = useSelector((state) => state.Activity.Activities);
  const HistoricTasks = useSelector((state) => state.camunda.HistoricTasks);
  const diagramBPMN = useSelector((state) => state.camunda.Diagram);
  const ProcessDefinition = useSelector(
    (state) => state.camunda.ProcessDefinition
  );


  //************************** */


  const [activeStep, setActiveStep] = React.useState(0);
  const [activeState, setActiveState] = React.useState(null);




  const steps = (activeState === "Draft" || activeState === "Waiting for review") ? ["Draft", "Waiting for review"] : (activeState === "Reviewed") ? ["Draft", "Waiting for review", "Reviewed"] : (activeState === "Rejected") ? ["Draft", "Waiting for review", "Rejected"] : []

  const icons = (activeState === "Draft" || activeState === "Waiting for review") ? {
    1: <CreateIcon />,
    2: <HourglassEmptyIcon />,
  } : (activeState === "Reviewed") ? {
    1: <CreateIcon />,
    2: <HourglassEmptyIcon />,
    3: <DoneIcon />,
  } : (activeState === "Rejected") ? {
    1: <CreateIcon />,
    2: <HourglassEmptyIcon />,
    3: <CloseIcon />,
  } : []



  let { activityId } = useParams();
  let currentLanguage = localStorage.getItem("i18nextLng");

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchfacultyActivity = () => {
    dispatch(fetchProfessionalDevelopmentById(activityId))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setActiveState(originalPromiseResult.state);
        setActiveStep(steps.indexOf(originalPromiseResult.state));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };

  useEffect(() => {
    fetchfacultyActivity();
  }, [activeState]);

  const professionalDevelopmentActivity = useSelector(
    (state) =>
      state.professionalDevelopmentActivities.professionalDevelopmentActivity
  );
  const professionalDevelopmentActivityStatus = useSelector(
    (state) =>
      state.professionalDevelopmentActivities
        .professionalDevelopmentActivityStatus
  );

  console.log("professionalDevelopmentActivity", professionalDevelopmentActivity);

  let contentt;

  if (professionalDevelopmentActivityStatus === "loading") {
    contentt = <div className="loader">Loading...</div>;
  } else if (professionalDevelopmentActivityStatus === "succeeded") {
    contentt = professionalDevelopmentActivity && (
      <ProfessionalDevelopmentExcerpt
        key={professionalDevelopmentActivity.id}
        activity={professionalDevelopmentActivity}
        personId={id}
      />
    );
  } else if (professionalDevelopmentActivityStatus === "failed") {
    contentt = <div>An error has accured</div>;
  }
















  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  const UpdateState = async (value) => {
    let data = {
      updatedDevelopmentServiceActivity: { state: value },
      id: activityId,
    };
    const resultAction = await dispatch(
      // updateResearchAndInnovationActivity(data)
      updateProfessionalDevelopmentServiceActivity(data)
    );

    if (updateProfessionalDevelopmentServiceActivity.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      fetchfacultyActivity();
      openNotificationWithIcon("success", `updated successfully`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const handleSendForReview = () => {
    UpdateState("Waiting for review");
  };

  const handleAccept = () => {
    UpdateState("Reviewed");
  };

  const handleReject = () => {
    UpdateState("Rejected");
  };

  const handleReset = () => {
    UpdateState("Draft");
  };

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;


    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };










  //********************* */











  useEffect(() => {
    let key = "teachingStaffProcess";
    dispatch(GetProcessDefinitionByKey("teachingStaffProcess"))
      .then(unwrapResult)
      .then((ProcessDefinition) => {
        dispatch(GetDiagram(ProcessDefinition[0].id))
          .then(unwrapResult)
          .then((diagramBPMN) => {
            var str = diagramBPMN && diagramBPMN.bpmn20Xml;
            // console.log(str, "xml");
            // to remove space
            str = typeof str === "string" ? str.replace(/>\s*/g, ">") : ""; // Remove space after >
            str = typeof str === "string" ? str.replace(/\s*</g, "<", ">") : ""; // Remove space before <

            //console.log(str, "str");

            // to remove \n
            str =
              typeof str === "string"
                ? str.replace(new RegExp("\\n", "g"), "")
                : "";
            //console.log(str, "str1");

            // to parse xml
            var parser = new DOMParser();
            const xmlDoc = parser.parseFromString(str, "text/xml");
            // console.log(xmlDoc, "xmlDoc");

            var BpmnProcess = xmlDoc.getElementsByTagName("bpmn:process");
            console.log(BpmnProcess, "BpmnProcess");
            let Tasks = [];
            for (var i = 0; i < BpmnProcess.length; i++) {
              for (var j = 0; j < BpmnProcess[i].childNodes.length; j++) {
                var el = BpmnProcess[i].childNodes[j];
                if (el.tagName === "bpmn:userTask") {
                  console.log(el.attributes[1].nodeValue, "task");
                  Tasks.push(el.attributes[1].nodeValue);
                  console.log(Tasks, "Tasks");
                  setDynamicTask([...Tasks]);
                  console.log(dynamicTask, "dynamicTask");
                }
              }
            }
          });
      });



  }, []);

  useEffect(() => {
    // container = document.getElementById("container").innerHTML = "";
    let key = "teachingStaffProcess";
    dispatch(GetProcessDefinitionByKey("teachingStaffProcess"))
      .then(unwrapResult)
      .then((ProcessDefinition) => {
        dispatch(GetDiagram(ProcessDefinition[0].id))
          .then(unwrapResult)
          .then((diagramBPMN) => {
            diagramSet(diagramBPMN && diagramBPMN.bpmn20Xml);
          });
      });
  }, [diagram]);



  useEffect(() => {
    dispatch(fetchStaffById(id));
    dispatch(fetchActivities());
  }, [dispatch]);

  const user = useSelector((state) => state.camunda.user);
  const person = useSelector((state) => state.user.staff);



  useEffect(() => {
    setStateName(person && person.person_details[0].state && person.person_details[0].state);
  }, [person]);

  const SendForReview = () => {
    setStateName("Waiting for review");
    try {
      let modifiedPerson = {
        state: "Waiting for review",
      };
      console.log("modifiedPerson", modifiedPerson);
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedStaff: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Waiting for review");
    } catch (err) {
      console.log("Failed to save the person: ", err);
    } finally {
    }
  };

  const MakeReviewed = () => {
    setStateName("Reviewed");
    try {
      let modifiedPerson = {
        state: "Reviewed",
      };
      console.log("modifiedPerson", modifiedPerson);
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Reviewed");
    } catch (err) {
      console.log("Failed to save the person: ", err);
    } finally {
    }
  };


  const useCardStyles = makeStyles(cardStyles);

  const Archive = () => {
    setStateName("Archived");
    try {
      let modifiedCourseReport = {
        due_date: CourseReport.due_date,
        state_object: "Archived",
        process_identification: CourseReport.process_identification,
      };
      console.log("modifiedCourseReport", modifiedCourseReport);
      const resultAction = dispatch(
        CourseReportUpdate({
          id: id,
          updatedCourseReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchCourseReports())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(dispatch(fetchCourseReports()));
      unwrapResult(resultAction1);
      setStateName("Archived");
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
    }
  };

  const ResetTODraft = () => {
    setStateName("Created");
    try {
      let modifiedPerson = {
        state: "Created",
      };
      console.log("modifiedPerson", modifiedPerson);
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Created");
    } catch (err) {
      console.log("Failed to save the person: ", err);
    } finally {
    }
  };

  const submitComplete = () => {
    if (stateName === "Created") {
      SendForReview();
    }
    if (stateName.toLowerCase().includes("waiting for review")) {
      MakeReviewed();
    }
  };

  if (!person) {
    return (
      <section>
        <h2> {t("person not found!")}</h2>
      </section>
    );
  }
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const callback = (key) => {
    console.log(key);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //bmpn Modal
  const handleClickOpen = () => {
    setShow(true);

    setOpen(true);
    setShowDiagram(true);
    setShowActivities(false);
    setShowTasks(false);
    setShowReports(false);
    setShowAttachment(false);



    if (diagram.length > 0) {
      const modeler = new Modeler({
        container,
        keyboard: {
          bindTo: document,
        },
      });
      modeler
        .importXML(diagram)
        .then(({ warnings }) => {
          if (warnings.length) {
            console.log("Warnings", warnings);
          }

          const canvas = modeler.get("modeling");
          canvas.setColor("CalmCustomerTask", {
            stroke: "green",
            fill: "yellow",
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDiagram(false);
    setShowActivities(true);

    container = document.getElementById("container").innerHTML = "";
  };

  const renderRow = () => {
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: "gray" }}
              >
                {Moment(item.DueDate).format("YYYY-MM-DD")}
              </Divider>

              <div
                style={{
                  backgroundColor: "#edeff1",
                  borderBottom: "1px solid #cfd5db",
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={3}>
                    <Avatar
                      style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={9}>
                    <p>
                      <strong>{item.AssignedTo}</strong>
                    </p>
                  </GridItem>
                </GridContainer>

                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={12}>
                    <ul>
                      <li>{item.Summary}</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (showTasks) {
      return (
        <div>
          {HistoricTasks &&
            HistoricTasks.map((item) => (
              //.filter((item, index) => index !== 0)
              <div>
                <Divider
                  type="horizontal"
                  orientation="center"
                  style={{ color: "gray" }}
                >
                  {Moment(item.startTime).format("YYYY-MM-DD")} ---{" "}
                  {Moment(item.endTime).format("YYYY-MM-DD")}
                </Divider>

                <div
                  style={{
                    backgroundColor: "#edeff1",
                    borderBottom: "1px solid #cfd5db",
                  }}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem key={Element.id} xs={12} sm={12} md={2}>
                      <Avatar
                        style={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                        }}
                      >
                        {user && user.firstName[0] + user.lastName[0]}
                      </Avatar>
                    </GridItem>
                    <GridItem key={Element.id} xs={12} sm={12} md={10}>
                      <p>
                        <strong>
                          {user && user.firstName + " " + user.lastName}
                        </strong>
                      </p>

                      <ul>
                        <li> Task : {item.name}</li>
                      </ul>

                      <ul>
                        <li>Operation : {item.deleteReason}</li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            ))}
        </div>
      );
    } else if (showDiagram) {
      return (
        <div>
          <button onClick={(event) => handleClickOpen(event)}>
            Show Diagram
          </button>
          <Modal title="My Modal" onClose={() => setShow(false)} show={show}>
            rrrrrr
            {/* <div className="App">
              <div
                id="container"
                style={{
                  border: "1px solid #000000",
                  height: "90vh",
                  width: "90vw",
                  margin: "auto",
                }}
              ></div>
            </div> */}
          </Modal>
        </div>
      );
    } else {
      return (
        <div >
          <UploadAttachmentProfessionalDevelopment id={professionalDevelopmentActivity && professionalDevelopmentActivity.id} />
        </div>
      );
    }
  };
  const renderTasks = () => {
    setShowTasks(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowReports(false);
    setShowAttachment(false);
    dispatch(GetUserProfile());
    dispatch(GetHistoricTasks(CourseReport.process_identification));
  };
  const renderActivities = () => {
    setShowActivities(true);
    setShowDiagram(false);
    setShowTasks(false);
    setShowAttachment(false);
    setShowReports(false);
  };
  const renderDiagram = () => {
    setShowReports(false);
    setShowActivities(false);
    setShowDiagram(true);
    setShowTasks(false);
    setShowAttachment(false);
  };

  const renderAttachment = () => {
    setShowReports(false);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
    setShowAttachment(true);
  };

  let content;
  if (stateName === "Created") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === "Created" ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  } else if (
    stateName &&
    stateName.toLowerCase().includes("waiting for review")
  ) {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <div>
            <Button
              style={{ backgroundColor: "lightgery" }}
              size="sm"
              className={classes.marginRight}
              onClick={() => ResetTODraft()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Reset tO draft")}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el && el.toLowerCase().includes("waiting for review") ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Reviewed") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === "Reviewed" ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div>
      <GridItem>
        {/* <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.first_name} ${person && person.last_name}`}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb> */}
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Teaching Staff")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/professionalDevelopmentActivities`}
            >
              {t("Professional development")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <div>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem key={Element.id} xs={12} sm={12} md={9}>
            <div className={classes.root}>
              {activeState && <div>
                <Card>
                  <GridContainer alignItems="center">
                    <GridItem xs={3} sm={3} md={3}>
                      {activeState === "Draft" && (
                        <div>
                          <Button color="primary" onClick={handleSendForReview}>
                            Send for review
                          </Button>
                        </div>
                      )}
                      {activeState === "Waiting for review" && (
                        <div>
                          <Button color="info" onClick={handleReset}>
                            Reset
                          </Button>
                          <Button color="primary" onClick={handleAccept}>
                            Accept
                          </Button>
                          <Button color="primary" onClick={handleReject}>
                            Reject
                          </Button>
                        </div>
                      )}
                      {activeState === "Reviewed" && (
                        <div>
                          <Button color="info" onClick={handleReset}>
                            Reset
                          </Button>
                        </div>
                      )}
                      {activeState === "Rejected" && (
                        <div>
                          <Button color="info" onClick={handleReset}>
                            Reset
                          </Button>
                        </div>
                      )}
                    </GridItem>
                    <GridItem xs={9} sm={9} md={9}>
                      <ThemeProvider theme={theme}>
                        <Stepper
                          alternativeLabel
                          activeStep={activeStep}
                          connector={
                            currentLanguage === "ar" ? false : <ColorlibConnector />
                          }
                        >
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel StepIconComponent={ColorlibStepIcon}>
                                {label}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </ThemeProvider>
                    </GridItem>
                  </GridContainer>
                </Card>

              </div>}

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem key={Element.id} xs={12} sm={12} md={12}>
                  {contentt}
                </GridItem>

              </GridContainer>
              <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
              />
            </div>
          </GridItem>




















































          <GridItem key={Element.id} xs={12} sm={12} md={3}>
            <div
              style={{
                padding: "10px",
                borderRadius: "0px",
                color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                background: whiteColor,
                width: "100%",
                boxShadow:
                  "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                minWidth: "0",
                wordWrap: "break-word",
                fontSize: ".875rem",
                height: "82vh",
                border: "1px solid lightgray",
              }}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer justify="space-around">
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderActivities}
                      >
                        <FaHistory
                          color={showActivities ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderTasks}
                      >
                        <FaTasks
                          color={showTasks ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderDiagram}
                      >
                        <FaProjectDiagram
                          color={showDiagram ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderAttachment}
                      >
                        <AttachFileIcon
                          color={showAttachment ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div class="card scrollable">
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "0px",
                        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "0",
                        wordWrap: "break-word",
                        fontSize: ".875rem",
                        height: "68vh",

                      }}
                    >
                      {renderRow()}
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}


