import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import pagesHeaderstyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle";
import { grayColor, primaryColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchUniversityById } from '../../../../reduxToolkit/features/university/UniversitiesSlice';




const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);


const CollegeExcerpt = ({ college }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();


  return (
    <GridItem xs={12} sm={6} md={4}>
      <Card style={{ height: 300 }} >
        <CardHeader>
          <GridContainer justify="center">
            <GridItem>
              <h3 style={{ color: `${primaryColor[0]}` }}>
                {college.name}
              </h3>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem>
              <h4>
                {`${college.mission.substring(0, 100)}...`}
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem>
              <Link to={`/citrine/organizations/colleges/${college.id}`} ><Button color='primary'>View</Button></Link>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function CollegesByUniversity({ match }) {
  const { t } = useTranslation();

  const dispatch = useDispatch()

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();

  const { id } = match.params

  const universityStatus = useSelector((state) => state.university.universityStatus)

  useEffect(() => {
    dispatch(fetchUniversityById(id))
  }, [id, dispatch])

  const university = useSelector((state) => state.university.university)

  let content

  if (universityStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (universityStatus === 'succeeded') {
    content = university?.colleges?.length > 0 && university?.colleges.map(college => (
      <CollegeExcerpt key={college.id} college={college} />
    ))
  }

  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <span>
            <Link to={`/citrine/organizations`}>{t('Organizations')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/universities`}> {t('/Universities')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/universities/${university && university.id}`}> {`/${university && university.name}`} </Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/universities/${id}/colleges`}> {t('/Colleges')} </Link>
          </span>
        </GridItem>
      </GridContainer>

      <GridContainer >
        {content}
      </GridContainer>
    </div>
  );
}













