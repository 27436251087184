import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchCourseNextPage, fetchCourses, fetchCoursesByPage
} from "../../../../reduxToolkit/Course/coursesSpecSlice";
import { fetchCourseByTitle } from '../../../../reduxToolkit/Course/coursesSpecSlice.js';

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import DropDownButtonCourse from "../../../Components/DropDownButtonCourse";

import { Breadcrumb } from "antd";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";

import ErrorModal from "../../../Components/ErrorModal";

import CardFooter from "components/Card/CardFooter";
import { useTranslation } from "react-i18next";

import Avatar from "@mui/material/Avatar";
import TablePagination from "@mui/material/TablePagination";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const CourseExcerpt = ({ course, isEditor, staffList }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <GridItem md={4} xs={12} sm={6} key={course.id}>
      <Card className={classes.cardHover} style={{ height: "400px" }}>
        <CardBody>
          <GridContainer>
            <GridItem xs={10} sm={10} md={10}>
              <h4 className={classes.cardProductTitle}>
                <small>
                  <Link to={`/citrine/courseSpecDetails/${course.id}`}>
                    {course.code}
                  </Link>
                </small>
              </h4>

              <h4 className={classes.cardProductTitle}>
                <Link to={`/citrine/courseSpecDetails/${course.id}`}>
                  {course.title}
                </Link>
              </h4>

              <h6 style={{ color: "orange" }}> {course.type}</h6>
            </GridItem>

            <GridItem xs={2} sm={2} md={2}>
              {isEditor && (
                <DropDownButtonCourse
                  courseID={course && course.id}
                  data={course}
                  id={course && course.id}
                  staffList={staffList}
                />
              )}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              {`${course.description.substring(0, 300)}...`}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              {course && course.coordinator_id != null ? (
                ` ${course && course.coordinator_detail[0].first_name} ${course && course.coordinator_detail[0].last_name
                }`
              ) : (
                <Avatar
                  src="/broken-image.jpg"
                  sx={{ bgcolor: "red", width: 24, height: 24 }}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            style={{ textAlign: "start", color: "#FFCB3B" }}
          >
            V {course.version}
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            style={{
              textAlign: "end",
              color: course.state === "Draft" ? grayColor[1] : "#FFCB3B",
            }}
          >
            {course.state}
          </GridItem>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function CourseSpecificationList() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const [isEditor, setEditor] = useState(true);
  const [search, setSearch] = useState("");
  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;
  const coursesSpecificationStatus = useSelector(
    (state) => state.CourseSpec.coursesSpecificationStatus
  );

  // const filterdCourseByTitle = useSelector(state => state.CourseSpec.coursesSpecificationByPage);



  // const searche = title => dispatch(fetchCourseByTitle(title));

  // useEffect(() => {
  //   dispatch(fetchCourseByTitle(search));


  // }, [search])

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const [searchFiltred, setSearchFiltred] = useState("");
  const [searchByDrop, setSearchByDrop] = useState("All Courses");

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [currentState, setCurrentState] = React.useState(null);
  const states = [
    { label: "All Courses" },
    { label: "Draft" },
    { label: "Waiting for approval" },
    { label: "Approved" },
    { label: "Archived" },
  ];
  const substring = '/api';
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const fetchCourseByPageList = async (data) => {
    // let data = {
    //   page: page,
    //   records: rowsPerPage,
    // }
    try {
      const courses = await dispatch(fetchCoursesByPage(data)).unwrap();

    } catch (err) {

      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  const fetchCourseList = async () => {
    try {
      const courses = await dispatch(fetchCourses()).unwrap();

    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  /*******************************************************************/
  useEffect(() => {
    dispatch(fetchStaff())
  }, [dispatch]);

  const staffStatus = useSelector((state) => state.user.staffStatus);
  const staffList = useSelector((state) => state.user.staffList);


  /******************************************************************/
  useEffect(() => {
    fetchCourseList();
    // let data = {
    //   page: page,
    //   records: rowsPerPage,
    // };
    // fetchCourseByPageList(data);
  }, [dispatch]);


  const courses = useSelector((state) => state.CourseSpec.coursesSpecification);



  // const coursesByPage = useSelector(
  //   (state) => state.CourseSpec.coursesSpecificationByPage
  // );

  // const onhandleChangedrop = (e) => {
  //   setSearchByDrop(e.target.value)
  // }



  // const dropdownfilter = coursesByPage?.results?.filter((program) => {
  //   if (searchByDrop === 'Approved') {
  //     return program.state === 'Approved'
  //   } else if (searchByDrop === 'Draft') {
  //     return program.state === 'Draft'
  //   } else if (searchByDrop === 'Waiting for approval') {
  //     return program.state === 'Waiting for approval'
  //   } else if (searchByDrop === 'Archived') {
  //     return program.state === 'Archived'
  //   } else {
  //     return program
  //   }
  // })



  let content;

  if (coursesSpecificationStatus === "loading") {
    content = <div className="loader">Loading...</div>;
  }
  else if (coursesSpecificationStatus === "succeeded") {
    if (courses && courses ==="You do not have permission")
    {
      let courses = [];
      content = <div>You do not have permission to access   </div>;
    }
    else
    if (search == "") {
      content =
        courses &&
        courses
          .filter((course) => {
            // no search key  & no  selected states
            if (search == "" && !currentState) {
              return course;
            }
            // select state and search key
            else if (
              currentState &&
              course.state.toLowerCase().includes(currentState.toLowerCase()) &&
              course.title.toLowerCase().includes(search.toLowerCase())
            ) {
              return course;
            }
            //select  just all programs
            else if (
              currentState &&
              !search
              // &&
              // currentState.includes("All Courses")
            ) {
              return course;
            }
            //select all programs and search key
            else if (
              currentState &&
              search &&
              course.title.toLowerCase().includes(search.toLowerCase())
              // &&
              //currentState.includes("All Courses")
            ) {
              return course;
            }
            //just search key
            else if (
              !currentState &&
              course.title.toLowerCase().includes(search.toLowerCase())
            ) {
              return course;
            }
          })
          .map((course) => (
            <CourseExcerpt
              key={course.id}
              course={course}
              isEditor={isEditor}
              staffList={staffList}
            />
          ));
    }

  }
  // }

  // function statesChanged(value) {
  //   setCurrentState(value);
  // }
  // const [page, setPage] = React.useState(1);
  // const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const titleClasses = useTitleStyles();


  // const startIndex = (page - 1) * rowsPerPage;

  // const endIndex = startIndex + rowsPerPage;

  // const itemsToDisplay = courses?.slice(startIndex, endIndex);


  // const filteredContent = dropdownfilter?.filter((item) => {
  //   return searchFiltred.toLowerCase() === '' ? item : item?.title?.toLowerCase()?.includes(searchFiltred)
  // })

  // const filteredContentt = filteredContent?.map((course) => (
  //   <CourseExcerpt
  //     key={course.id}
  //     course={course}
  //     isEditor={isEditor}
  //     staffList={staffList}
  //   />
  // ));

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const previousPage = () => {
  //   if (coursesByPage.previous !== null) {
  //     const index = coursesByPage && coursesByPage.previous && coursesByPage.previous.indexOf(substring);
  //     const previous = coursesByPage && coursesByPage.previous && coursesByPage.previous.slice(0, index) + coursesByPage.previous && coursesByPage.previous.slice(index + 4);
  //     dispatch(fetchCourseNextPage(previous));
  //     setPage(page - 1);
  //   }
  //   // setPage(newPage);
  // };
  // const nextPage = () => {
  //   if (coursesByPage.next !== null) {
  //     const index = coursesByPage && coursesByPage.next.indexOf(substring);
  //     const previous = coursesByPage && coursesByPage.next.slice(0, index) + coursesByPage.next && coursesByPage.next.slice(index + 4);
  //     dispatch(fetchCourseNextPage(previous));
  //     setPage(page + 1);
  //   }
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(1);
  //   let data = {
  //     page: 1,
  //     records: event.target.value,
  //   };
  //   fetchCourseByPageList(data);
  // };

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
        <Link to={`/citrine/courseCoordinatorWorkspace/CourseSpecificationList/`}>
          <span className={titleClasses.title}> {t("Courses")} </span>
        </Link>
            {/*<Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link

                to={`/citrine/CourseSpecificationList/`}
              >
                <span className={titleClasses.title}> {t("Courses")} </span>

              </Link>
            </Breadcrumb.Item>
          </Breadcrumb> */}
        </GridItem>
      </GridContainer>

      <GridContainer justify="space-between" alignItems="center">
        {/* {isEditor && (
          <GridItem>
            <Link to="/citrine/addCourseSpec">
              <Button color="info">{t("Add")}</Button>
            </Link>
          </GridItem>
        )} */}
        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>
        {/* <GridItem> */}
          {/* <div
            style={{
              border: "1px solid gray",
              width: "180px",
              borderRadius: "4px",
            }}
          >
            <span onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
              {currentState ? (
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={10}
                    style={{ textAlign: "start", paddingTop: "7px" }}
                  >
                    {currentState}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{ textAlign: "end" }}>
                    {" "}
                    <ArrowDropDown fontSize="small" />
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={10}
                    style={{ textAlign: "start", paddingTop: "7px" }}
                  >
                    All Courses
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{ textAlign: "end" }}>
                    {" "}
                    <ArrowDropDown fontSize="small" />
                  </GridItem>
                </GridContainer>
              )}
            </span>
            <Popover
              open={!!menuAnchor}
              anchorEl={menuAnchor}
              onClose={() => setMenuAnchor(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div>
                <List>
                  {states &&
                    states.map((item) => (
                      <ListItem
                        button
                        key={item}
                        onClick={() => {
                          statesChanged(item.label);
                          setMenuAnchor(null);
                        }}
                      >
                        {item.label}
                      </ListItem>
                    ))}
                </List>
              </div>
            </Popover>
          </div> */}
          {/* <select onChange={onhandleChangedrop} style={{ backgroundColor: '#EEEEEE', color: 'gray', border: '1px solid black', borderRadius: '8px', padding: '10px', width: '200px' }}>
            <option value="All Courses">All Courses</option>
            <option value="Draft">Draft</option>
            <option value="Waiting for approval">Waiting for approval</option>
            <option value="Approved">Approved</option>
            <option value="Archived">Archived</option>
          </select>
        </GridItem> */}
        <GridItem>
          {/* <CustomInput
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value);
              },
              value: search,
              placeholder: "Search",
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
          >
            <Search
              className={
                navBarClasses.headerLinksSvg + " " + navBarClasses.searchIcon
              }
            />
          </Button> */}
          {/* <input placeholder='Search' style={{ backgroundColor: '#eeeeee', border: 'none', borderBottom: '1px solid #BDBDBD', marginRight: '50px', width: '250px', height: "30px" }} type='text' onChange={(e) => setSearch(e.target.value)} value={search} /> */}
        </GridItem>
      </GridContainer>
      <GridContainer>{content}</GridContainer>
      {/* <GridContainer>
        <Card>
          <CardBody>
            <TablePagination
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} OF ${count !== -1 ? count : `MORE THAN ${to}`}`
              }
              component="div"
              count={coursesByPage && coursesByPage.count}
              rowsPerPageOptions={[
                10,
                25,
                50,
                100,
                `${coursesByPage && coursesByPage.count}`,
              ]}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "previous page",
                onClick: previousPage,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                onClick: nextPage,
              }}
            />
          </CardBody>
        </Card>
      </GridContainer> */}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
