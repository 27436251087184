import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {

}

export const editCLOWeight = createAsyncThunk(
    'CLOWeight/editCLOWeight',
    async (CLOWeight, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.put(BaseUrl + `/weightClo/update_clo/`, CLOWeight, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)

const CLOWeightSlice = createSlice({
    name: 'CLOWeight',
    initialState,
    extraReducers: {

    },

})

export default CLOWeightSlice.reducer

