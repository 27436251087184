import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import token from "assets/utils/accessToken";

const initialState = {
  ActivitiesAssessmentMethodsSpec: [],
  ActivitiesAssessmentMethodSpecStatus: "idle",
  ActivitiesAssessmentMethodSpecStatus: "idle",
  error: null,
  ActivitiesAssessmentMethodSpec: null,

  id: null,
};

export const fetchActivitiesAssessmentMethodsSpec = createAsyncThunk(
  "ActivitiesAssessmentMethodSpec/fetchActivitiesAssessmentMethodSpec",
  async (courseSpec_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
          `/activitiesAssessmentMethodsSpec/?courseSpec_id=${courseSpec_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewActivitiesAssessmentMethodSpec = createAsyncThunk(
  "ActivitiesAssessmentMethodSpec/addNewActivitiesAssessmentMethodSpec",
  async (initialActivitiesAssessmentMethodSpec, { rejectWithValue }) => {
    console.log(
      "initialActivitiesAssessmentMethodSpec",
      initialActivitiesAssessmentMethodSpec
    );
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/activitiesAssessmentMethodsSpec/create/`,
        initialActivitiesAssessmentMethodSpec,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const deleteActivitiesAssessmentMethodSpec = createAsyncThunk(
  "ActivitiesAssessmentMethodSpec/deleteActivitiesAssessmentMethodSpec",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/activitiesAssessmentMethodsSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchActivitiesAssessmentMethodSpecById = createAsyncThunk(
  "ActivitiesAssessmentMethodSpec/activitiesAssessmentMethodSpecById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/activitiesAssessmentMethodsSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const ActivitiesAssessmentMethodSpecUpdate = createAsyncThunk(
  "ActivitiesAssessmentMethodSpec/activitiesAssessmentMethodSpecUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/activitiesAssessmentMethodsSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updated");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const ActivitiesAssessmentMethodsSpecSlice = createSlice({
  name: "ActivitiesAssessmentMethodSpec",
  initialState,

  extraReducers: {
    [fetchActivitiesAssessmentMethodsSpec.pending]: (state, action) => {
      state.ActivitiesAssessmentMethodsSpecStatus = "loading";
    },
    [fetchActivitiesAssessmentMethodsSpec.fulfilled]: (state, action) => {
      state.ActivitiesAssessmentMethodsSpecStatus = "succeeded";

      state.ActivitiesAssessmentMethodsSpec = action.payload;
      //state.TeachingStrategiesSpec =  jsonData
    },
    [fetchActivitiesAssessmentMethodSpecById.pending]: (state, action) => {
      state.ActivitiesAssessmentMethodSpecStatus = "loading";
    },
    [fetchActivitiesAssessmentMethodSpecById.fulfilled]: (state, action) => {
      state.ActivitiesAssessmentMethodSpecStatus = "succeeded";

      // Add any fetched posts to the array
      state.ActivitiesAssessmentMethodSpec = action.payload;
    },
    [fetchActivitiesAssessmentMethodSpecById.rejected]: (state, action) => {
      state.ActivitiesAssessmentMethodSpecStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [ActivitiesAssessmentMethodSpecUpdate.fulfilled]: (state, action) => {
      console.log(action);
    },

    [fetchActivitiesAssessmentMethodsSpec.rejected]: (state, action) => {
      state.ActivitiesAssessmentMethodsSpecStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewActivitiesAssessmentMethodSpec.fulfilled]: (state, action) => {
      state.ActivitiesAssessmentMethodsSpec.push(action.payload);
    },
    [deleteActivitiesAssessmentMethodSpec.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [addNewActivitiesAssessmentMethodSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteActivitiesAssessmentMethodSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [ActivitiesAssessmentMethodSpecUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [ActivitiesAssessmentMethodSpecUpdate.fulfilled]: (state, action) => {},

    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },
    },
  },
});

export const { getId } = ActivitiesAssessmentMethodsSpecSlice.actions;

export default ActivitiesAssessmentMethodsSpecSlice.reducer;
