import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  researchAndInnovationActivities: [],
  AllResearchAndInnovationActivities: [],
  researchAndInnovationActivitiesStatus: "idle",
  researchAndInnovationActivity: null,
  researchAndInnovationActivityStatus: "idle",
  AllResearchAndInnovationActivitiesStatus: "idle",
  researchAndInnovationActivitiesByProgram: [],
  researchAndInnovationActivitiesByProgramStatus: "idle"
};

export const fetchResearchAndInnovationActivities = createAsyncThunk(
  "researchAndInnovationActivities/fetchResearchAndInnovationActivities",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/researchAndInnovationFaculty/byStaff/?staff_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);
export const fetchResearchAndInnovationActivitiesByProgram = createAsyncThunk(
  "researchAndInnovationActivities/fetchResearchAndInnovationActivitiesByProgram",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/researchAndInnovationFaculty/by_program/?program_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const fetchAllResearchAndInnovationActivities = createAsyncThunk(
  "researchAndInnovationActivities/fetchAllResearchAndInnovationActivities",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/researchAndInnovationFaculty/all/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const fetchResearchAndInnovationActivityById = createAsyncThunk(
  "researchAndInnovationActivities/fetchResearchAndInnovationActivityById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/researchAndInnovationFaculty/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const addNewResearchAndInnovationActivity = createAsyncThunk(
  "researchAndInnovationActivities/addNewResearchAndInnovationActivity",
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/researchAndInnovationFaculty/create/`,
        toAddActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const addResearchAndInnovationActivityFromScopus = createAsyncThunk(
  "researchAndInnovationActivities/addNewResearchAndInnovationActivity",
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/researchOutPut/add_scopus/`,
        toAddActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const updateResearchAndInnovationActivity = createAsyncThunk(
  "researchAndInnovationActivities/updateResearchAndInnovationActivity",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/researchAndInnovationFaculty/${data.id}/edit/`,
        data.toAddActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);


export const deleteResearchAndInnovationActivity = createAsyncThunk(
  "researchAndInnovationActivities/deleteResearchAndInnovationActivity",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/researchAndInnovationFaculty/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);


const researchAndInnovationActivitiesSlice = createSlice({
  name: "researchAndInnovationActivities",
  initialState,
  extraReducers: {
    [fetchResearchAndInnovationActivities.pending]: (state, action) => {
      state.researchAndInnovationActivitiesStatus = "loading";
    },
    [fetchResearchAndInnovationActivities.fulfilled]: (state, action) => {
      state.researchAndInnovationActivitiesStatus = "succeeded";
      state.researchAndInnovationActivities = action.payload;
    },
    [fetchResearchAndInnovationActivities.rejected]: (state, action) => {
      state.researchAndInnovationActivitiesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchResearchAndInnovationActivitiesByProgram.pending]: (state, action) => {
      state.researchAndInnovationActivitiesByProgramStatus = "loading";
    },
    [fetchResearchAndInnovationActivitiesByProgram.fulfilled]: (state, action) => {
      state.researchAndInnovationActivitiesByProgramStatus = "succeeded";
      state.researchAndInnovationActivitiesByProgram = action.payload;
    },
    [fetchResearchAndInnovationActivitiesByProgram.rejected]: (state, action) => {
      state.researchAndInnovationActivitiesByProgramStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },



    [fetchAllResearchAndInnovationActivities.pending]: (state, action) => {
      state.AllResearchAndInnovationActivitiesStatus = "loading";
    },
    [fetchAllResearchAndInnovationActivities.fulfilled]: (state, action) => {
      state.AllResearchAndInnovationActivitiesStatus = "succeeded";
      state.AllResearchAndInnovationActivities = action.payload;
    },
    [fetchAllResearchAndInnovationActivities.rejected]: (state, action) => {
      state.AllResearchAndInnovationActivitiesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },








    [fetchResearchAndInnovationActivityById.pending]: (state, action) => {
      state.researchAndInnovationActivityStatus = "loading";
    },
    [fetchResearchAndInnovationActivityById.fulfilled]: (state, action) => {
      state.researchAndInnovationActivityStatus = "succeeded";

      // Add any fetched posts to the array
      state.researchAndInnovationActivity = action.payload;
    },
    [fetchResearchAndInnovationActivityById.rejected]: (state, action) => {
      state.researchAndInnovationActivityStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    [addNewResearchAndInnovationActivity.pending] : (state)=>{
      state.researchAndInnovationActivityStatus = "loading"
    },
    [addNewResearchAndInnovationActivity.fulfilled]:(state,action) => {
      console.log(action)
      state.researchAndInnovationActivityStatus = "succeeded"
    },
    [addNewResearchAndInnovationActivity.rejected] : (state,action) => {
      state.researchAndInnovationActivityStatus = "failed"
      state.error = action.payload ? action.payload : action.error
    },


   
  },
});

export default researchAndInnovationActivitiesSlice.reducer;
