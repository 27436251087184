import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Breadcrumb } from 'antd'

const useStyles = makeStyles(styles)

export default function OthersConfigurations() {
  const { t } = useTranslation()
  const classes = useStyles()


  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configuration')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configuration')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <GridContainer>
      
        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/SkillsPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/SkillsPage`}>
                  <h4 className={classes.cardProductTitle}>
                        {t('key Words Skills')}
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddConfigSkills">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/AuthorityPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/AuthorityPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Person Authority
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddAuthority">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/StandardPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/StandardPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Standards Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddStandard">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/PublisherPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/PublisherPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Publishers Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddPublisher">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/ResearchDirectionPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link
                  to={`/citrine/configuration/others/ResearchDirectionPage`}
                >
                  <h4 className={classes.cardProductTitle}>
                        Research Direction Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddResearchDirection">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/RolesConfigPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/RolesConfigPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Roles Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddRolesConfigPage">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/TypesConfigPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/TypesConfigPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Types Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddTypesConfigPage">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/IndexResearchPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/IndexResearchPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Index Research Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddIndexResearchPage">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link
            to={`/citrine/configuration/others/InternationalResearchConfigPage`}
          >
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link
                  to={`/citrine/configuration/others/InternationalResearchConfigPage`}
                >
                  <h4 className={classes.cardProductTitle}>
                        International Research Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddInternationalResearchConfig">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/UtilizedResearchLabsPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link
                  to={`/citrine/configuration/others/UtilizedResearchLabsPage`}
                >
                  <h4 className={classes.cardProductTitle}>
                        Utilized Research Labs Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddUtilizedResearchLabsPage">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/ReportStatesConfigPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link
                  to={`/citrine/configuration/others/ReportStatesConfigPage`}
                >
                  <h4 className={classes.cardProductTitle}>
                        Report States Config
                  </h4>
                </Link>
                <Link to="/citrine/configuration/others/AddReportStatesConfigPage">
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        {/* Evidence Type Config */}
        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/EvidenceTypeConfigPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link
                  to={`/citrine/configuration/others/EvidenceTypeConfigPage`}
                >
                  <h4 className={classes.cardProductTitle}>
                        Evidence Type Config
                  </h4>
                </Link>
                <Link
                  to={`/citrine/configuration/others/AddEvidenceTypeConfigPage`}
                >
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        {/* Authority Config */}
        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/Authority`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/Authority`}>
                  <h4 className={classes.cardProductTitle}>
                        Authority Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/AddAuthorityConfig`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/KpiStandardType`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/KpiStandardType`}>
                  <h4 className={classes.cardProductTitle}>
                        KPI Standards Type Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/AddKpiStandardConfig`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/actiontypepage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/actiontypepage`}>
                  <h4 className={classes.cardProductTitle}>
                        Action Type Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/addActionTypeConfig`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/authorityLevelPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/authorityLevelPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Authority Level Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/addAuthorityLevelPage`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/approvalLevels`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/approvalLevels`}>
                  <h4 className={classes.cardProductTitle}>
                        Approval Level Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/approvalLevels/create`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/ActionApprovalProcessPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/ActionApprovalProcessPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Action Approval Process Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/addActionApprovalProcessPage`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/StateNamePage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/StateNamePage`}>
                  <h4 className={classes.cardProductTitle}>
                        State Name Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/addStateNamePage`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/StepConfigPage`}>
            <Card product className={classes.cardHover}>
              <CardBody>
                <Link to={`/citrine/configuration/others/StepConfigPage`}>
                  <h4 className={classes.cardProductTitle}>
                        Step Config
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/addStepConfigPage`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={4} lg={3}>
          <Link to={`/citrine/configuration/others/locations`}>
            <Card product className={classes.cardHover}>
              <CardBody>
              <Link to={`/citrine/configuration/others/locations`}>
                  <h4 className={classes.cardProductTitle}>
                        Locations
                  </h4>
                </Link>
                <Link to={`/citrine/configuration/others/locations/create`}>
                  <Button color="info">{t('Add')}</Button>
                </Link>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
    </div>
  )
}
