import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";
import { notification } from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";

import { Form, Input, InputNumber } from "antd";

import ErrorModal from "./ErrorModal.jsx";

import {
  addNewStudyPlanReportDocument,
} from "../../reduxToolkit/features/StudyPlan/StudyPlanReportDocumentSlice";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16,
  },
};

export default function CreateReportStudyPlanDocument(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewStudyPlanReportDocument({
        ...values,
        ressource: "studyPlan",
        programSpec_id: props.programId,
      })
    );
    if (
      addNewStudyPlanReportDocument.fulfilled.match(resultAction)
    ) {
      openNotificationWithIcon("success", `Added successfully`);
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <MenuItem
        color="primary"
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <span style={{marginRight: 5}}>Add</span>
        <PlusCircleOutlined />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          Create Study Plan Document Report
        </DialogTitle>
        <DialogContent>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="version"
              label="version"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0} style={{width:"100%"}}/>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: "20px" }}
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="primary" color="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
