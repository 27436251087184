//I load my translation files
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import ar from "./locales/ar/translation.json";
// import fr from "./locales/fr/translation.json";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["en"];
const availableLanguages = ["en", "ar"];

//Configure i18next
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
//Useful for debuging, displays which key is missing

//  debug: true,

//In which lang to translate (will be set dynamically later)
detection: {
  checkWhitelist: true,
},

//If translation key is missing, which lang use instead
fallbackLng,

  resources: {
    en: {
      translation: en
    },
    // fr: {
    //   translation: fr
    // },
    ar: {
      translation: ar
    }
  }
,
//Namespace to use by default, when not indicated
  defaultNs: 'translation',
  interpolation:{escapeValue:false},
  whitelist: availableLanguages,

});
export default i18n
//I load my translation files
