import { EditingState } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableEditRow,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui'
// editable array
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import ScoreIcon from '@material-ui/icons/Score'
import { unwrapResult } from '@reduxjs/toolkit'
//import Button from "components/CustomButtons/Button.js";
import { Breadcrumb, Button, Tabs } from 'antd'
import 'antd/dist/antd.css'
import stylesAlert from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import randomSeed from '../../../../assets/demo-data/random'
import {
  fetchCloById,
  fetchScoreCLOById,
  fetchTuplesNumberStudentsByRange,
} from '../../../../reduxToolkit/features/CalculNotes/CalculNotesSlice'
import { fetchCourseById } from '../../../../reduxToolkit/features/course/CoursesSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice'
import {
  blackColor,
  cardTitle,
  grayColor,
  hexToRgb,
  primaryColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js'
import { DataGrid } from '@mui/x-data-grid'
import SurveyQuestionByCloList from './SurveyQuestionByCloList'
import CardBody from 'components/Card/CardBody.js'

const useStyles = makeStyles(styles)

const useStylesAlert = makeStyles(stylesAlert)

const CloSurveyQuestionConfig = ({ weights_Marks }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const classesAlert = useStylesAlert()
  const { TabPane } = Tabs

  return (
    <div className="scrollable">
      <div
        style={{
          padding: '10px',
          borderRadius: '0px',
          color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
          background: whiteColor,
          width: '100%',
          boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minWidth: '0',
          wordWrap: 'break-word',
          fontSize: '.875rem',
          // borderRight: "1px solid lightgray"
        }}
      >
        <Tabs type="card">
          <TabPane tab={t('Survey Questions')} key="1">
            <SurveyQuestionByCloList />
          </TabPane>

          <TabPane tab={t('Weights')} key="2">
            <Card>
              <CardBody>{weights_Marks}</CardBody>
             
            </Card>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
export default CloSurveyQuestionConfig
