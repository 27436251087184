import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  SubCodeLevels: [],
  SubCodeLevelsStatus: 'idle',
  SubCodeLevelStatus: 'idle',
  SubCodeLevel: null,
  error: null,
  id: null,
}

export const fetchSubCodeLevels = createAsyncThunk(
  'subLevelClo/fetchSubCodeLevels',
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + '/subLevelClos/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchSubCodeLevelById = createAsyncThunk(
  'subLevelClo/fetchSubCodeLevelById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/subLevelClos/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const addNewSubCodeLevel = createAsyncThunk(
  'subLevelClo/addNewSubCodeLevel',
  async (initialAssessmentMode, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/subLevelClos/create/`,
        initialAssessmentMode,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateSubCodeLevel = createAsyncThunk(
  'subLevelClo/updateSubCodeLevel',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/subLevelClos/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteSubCodeLevel = createAsyncThunk(
  'subLevelClo/deleteSubCodeLevel',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/subLevelClos/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
const SubCodeLevelSlice = createSlice({
  name: ' subLevelClo',
  initialState,
  extraReducers: {
    [fetchSubCodeLevels.pending]: (state, action) => {
      state.SubCodeLevelsStatus = 'loading'
    },
    [fetchSubCodeLevels.fulfilled]: (state, action) => {
      state.SubCodeLevelsStatus = 'succeeded'
      state.SubCodeLevels = action.payload
    },
    [fetchSubCodeLevels.rejected]: (state, action) => {
      state.SubCodeLevelsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchSubCodeLevelById.pending]: (state, action) => {
      state.SubCodeLevelStatus = 'loading'
    },
    [fetchSubCodeLevelById.fulfilled]: (state, action) => {
      state.SubCodeLevelStatus = 'succeeded'
      state.SubCodeLevel = action.payload
    },
    [fetchSubCodeLevelById.rejected]: (state, action) => {
      state.SubCodeLevelStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default SubCodeLevelSlice.reducer
