import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";


// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import "antd/dist/antd.css";


import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "./ErrorModal";

import AddIcon from "@material-ui/icons/Add";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import {
  addNewVariationsCourse, fetchVariationsCourses
} from "../../reduxToolkit/features/VariationsCourses/VariationsCoursesSlice.js";

import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramsSlice";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

export default function AddVariationsCoursesModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);

  const { Option } = Select;
  const { TextArea } = Input;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [program_id, setProgram_id] = React.useState(props.program_id);
  const [completion_date, setCompletion_date] = React.useState("");

  const [iscompleted, setIscompleted] = React.useState(true);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  console.log(props.program_id, "program_id");

  useEffect(() => {
    dispatch(fetchProgramById(props.program_id));

  }, [dispatch]);
  const program = useSelector((state) => state.program.program);
  console.log(program, "program");

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewVariationsCourse({
        ...values,
        program_id,
      })
    );
    if (addNewVariationsCourse.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchVariationsCourses(props.program_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  function onChange(date, dateString) {
    console.log(date, dateString);
    setCompletion_date(dateString);
  }

  function onChangeCheckbox(e) {
    console.log(`checked = ${e.target.checked}`);
    setIscompleted(e.target.checked);
  }
  return (
    <div>
      <Button
        color="primary"
        round
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={(event) => {
          handleClickOpen(event);
        }}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
      >
        <AddIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Course Variations")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="course_id"
                  label="Course"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Course field.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Course "
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {program &&
                      program.courses &&
                      program.courses.map((course) => (
                        <Option value={course.id}>{course.title}</Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="variation"
                  label="Variation "
                  rules={[
                    {
                      required: true,
                      message: "Please input the variation field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="reasons_for_variation"
                  label="Reasons"
                  rules={[
                    {
                      required: true,
                      message: "Please input the reasons_for_variation field.",
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>
                <Form.Item
                  name="actions_taken"
                  label="Actions Taken"
                  rules={[
                    {
                      required: true,
                      message: "Please input the actions_taken field.",
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
