import React, { useEffect, useState, useRef, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
// core components
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ErrorModal from '../../../Components/ErrorModal'
import Tooltip from '@material-ui/core/Tooltip'

import TableViewIcon from '@mui/icons-material/TableView'
import ViewListIcon from '@mui/icons-material/ViewList'
import GetAppIcon from '@material-ui/icons/GetApp'
import { DataGrid } from '@mui/x-data-grid'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchAllResearchAndInnovationActivities } from '../../../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Select } from 'antd'
import { fetchStaff } from '../../../../reduxToolkit/features/user/UserSlice'
import { MediaBaseUrl } from 'assets/utils/accessToken'

import saveAs from 'file-saver'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import ActivityDown from './ActivityDown'

const { Option } = Select

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

export default function AllResearchAndInnovationActivities() {

  const { t } = useTranslation()
  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [viewList, setViewList] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [selected, setSelected] = useState(true)
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5)
  const [search, setSearch] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const AllResearchAndInnovationActivities = useSelector((state) => state.researchAndInnovationActivities.AllResearchAndInnovationActivities,)
  const academicYears = useSelector((state) => state.academicYear.academicYears)
  const persons = useSelector((state) => state.user.staffList)

  useEffect(() => {
    dispatch(fetchAllResearchAndInnovationActivities())
      .then(unwrapResult)
      .then((AllResearchAndInnovationActivities) => {
        setRows(AllResearchAndInnovationActivities)
      })
  }, [])

  useEffect(() => {
    dispatch(fetchStaff())
  }, [])

  const listResearchAndInnovationActivitiesYear = AllResearchAndInnovationActivities.filter(
    ({ year }) => year != null,
  )

  const listResearchAndInnovationActivitiesType = AllResearchAndInnovationActivities.filter(
    (obj, index) =>
      AllResearchAndInnovationActivities.findIndex(
        (item) => item.type === obj.type,
      ) === index,
  )

  function onChangeActivitiesByYear(value) {
    let AllResearchAndInnovation =AllResearchAndInnovationActivities &&
      AllResearchAndInnovationActivities.filter((ResearchAndInnovation) => {
        if (!value && search === '') {
          return ResearchAndInnovation
        } else if (
          ResearchAndInnovation?.year?.toLowerCase()?.includes(value?.toLowerCase())
        ) {
          return ResearchAndInnovation
        }
      })
    setRows(AllResearchAndInnovation)
  }

  function onChangeActivitiesByType(value) {
    let AllResearchAndInnovation =
      AllResearchAndInnovationActivities &&
      AllResearchAndInnovationActivities.filter((ResearchAndInnovation) => {
        if (!value) {
          return ResearchAndInnovation
        } else if (
          value &&
          ResearchAndInnovation?.type?.toLowerCase()?.includes(value?.toLowerCase())
        ) {
          return ResearchAndInnovation
        }
      })
    setRows(AllResearchAndInnovation)
  }

  function onChangeActivitiesByAuthors(value) {
    let AllResearchAndInnovation =
      AllResearchAndInnovationActivities &&
      AllResearchAndInnovationActivities.filter((ResearchAndInnovation) => {
        if (!value) {
          return ResearchAndInnovation
        } else if (value && ResearchAndInnovation.authors_ids.includes(value)) {
          return ResearchAndInnovation
        }
      })
    setRows(AllResearchAndInnovation)
  }

  function onChangeActivitiesByName(value) {
    let AllResearchAndInnovation =
      AllResearchAndInnovationActivities &&
      AllResearchAndInnovationActivities.filter((ResearchAndInnovation) => {
        if (!value) {
          return ResearchAndInnovation
        } else if (
          value &&
          ResearchAndInnovation.name.toLowerCase().includes(value.toLowerCase())
        ) {
          return ResearchAndInnovation
        }
      })
    setRows(AllResearchAndInnovation)
  }

  const exporterRef = useRef(null)

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx',
      )
    })
  }

  const selectionModelChange = (newSelectionModel, details) => {
    if (newSelectionModel.length > 0) {
      setSelected(false)
    } else {
      setSelected(true)
    }
    setSelectionModel((prevModel) =>
      newSelectionModel.filter((newId) => !prevModel.includes(newId)),
    )
  }

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/Activities`}>{t('Activities')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/faculty/Activities/AllResearchAndInnovationActivities`}
          >
            {t('Research And Innovation Activities')}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br></br>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <div>
          <Select
            allowClear
            placeholder="Type"
            optionFilterProp="children"
            onChange={onChangeActivitiesByType}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              marginRight: '20px',
              width: '200px',
              backgroundColor: 'none',
              zIndex: '10000',
            }}
          >
            {listResearchAndInnovationActivitiesType &&
              listResearchAndInnovationActivitiesType.map(
                (ResearchAndInnovation) => (
                  <Option
                    value={ResearchAndInnovation && ResearchAndInnovation.type}
                  >
                    {ResearchAndInnovation && ResearchAndInnovation.type}
                  </Option>
                ),
              )}
          </Select>
          <Select
            allowClear
            placeholder="Year"
            optionFilterProp="children"
            onChange={onChangeActivitiesByYear}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ marginRight: '20px', width: '200px' }}
          >
            {academicYears &&
              academicYears.map((year) => (
                <Option value={year.name}>{year.name}</Option>
              ))}
          </Select>
          <Select
            showSearch
            allowClear
            placeholder="Author"
            optionFilterProp="children"
            onChange={onChangeActivitiesByAuthors}
            style={{ marginRight: '20px', width: '200px' }}
          >
            {persons &&
              persons.map((person) => (
                <Option value={person && person.id}>
                  {!person?.person_details[0]?.avatar ? (
                    <span
                      style={{
                        backgroundColor: 'rgb(143, 102, 130)',
                        width: '23px',
                        height: '23px',
                        color: 'white',
                        borderRadius: '50%',
                        paddingLeft: '7px',
                        marginRight: '15px',
                        display: 'inline-block',
                        lineHeight: '23px',
                      }}
                    >
                      {person?.person_details[0]?.first_name
                        .charAt(0)
                        .toUpperCase() + '     '}
                    </span>
                  ) : (
                    <img
                      style={{ width: '20px', height: '20px' }}
                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                    />
                  )}
                  {`${
                    person &&
                    person.person_details &&
                    person.person_details[0].first_name
                  }`}{' '}
                  {`${''}`}{' '}
                  {`${
                    person &&
                    person.person_details &&
                    person.person_details[0].last_name
                  }`}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin: '10px'}}>
        <Tooltip title="Export Data">
          <GetAppIcon style={{ cursor: 'pointer' }} onClick={startExport} />
        </Tooltip>
        &nbsp;&nbsp;
        <span style={{ color: 'gray'}}>Export data</span>
      </div>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={9}></GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <ActivityDown
                selected={selected}
                modelSelected={selectionModel}
              />
            </GridItem>
          </CardHeader>
          <div style={{ width: '100%', height: 460, padding: '10px' }}>
            <DataGrid
              checkboxSelection
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              autoPageSize={true}
              columnBuffer={0}
              disableColumnMenu={true}
              showCellRightBorder
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 80]}
              pagination
              onSelectionModelChange={selectionModelChange}
              selectionModel={selectionModel}
            />
          </div>
        </Card>
      </GridItem>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

const columns = [
  {
    field: 'title',
    headerName: 'Title',
    name: 'title',
    width: 180,
    sortable: true,
  },
  {
    field: 'type',
    headerName: 'Type',
    name: 'type',
    width: 150,
    sortable: false,
  },
  {
    field: 'authors',
    headerName: 'Authors',
    name: 'authors',
    width: 200,
    sortable: false,
    renderCell: (params) => (
      <div>
        {params.row.authors_details.map((item) => (
          <Typography fullWidth display="block">
            <GridItem>
              {item.first_name} {} {item.last_name}
            </GridItem>
          </Typography>
        ))}
      </div>
    ),
  },
  {
    field: 'category_research_activities',
    headerName: 'Category',
    name: 'category_research_activities',
    width: 110,
    sortable: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    name: 'date',
    width: 120,
    sortable: false,
  },
  {
    field: 'state',
    headerName: 'State',
    name: 'state',
    width: 100,
    sortable: false,
  },
  {
    field: 'department',
    headerName: 'department',
    name: 'department',
    width: 130,
    sortable: false,
    renderCell: (params) => (
      <div>
        {params.row.departments_details.map((item) => (
          <Typography fullWidth align="justify">
            <GridItem>{item.name}</GridItem>
          </Typography>
        ))}
      </div>
    ),
  },
  {
    field: 'college',
    headerName: 'College',
    name: 'college',
    width: 250,
    sortable: false,
    renderCell: (params) => (
      <div>
        {params.row.colleges_details.map((item) => (
          <Typography fullWidth align="justify">
            <GridItem>{item.name}</GridItem>
          </Typography>
        ))}
      </div>
    ),
  },
]
