import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Card } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchActionPlans,
  UpdateActionPlan
} from "../../../reduxToolkit/features/ActionPlan/ActionPlanSlice";
import DropDownButtonActionPlan from "../../Components/DropDownButtonActionPlan";

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);

export default function ActionPlan(props) {
  const { t } = useTranslation();
  const initialBoard = {
    columns: [
      {
        id: 0,
        title: "Draft",
        cards: [],
      },
      {
        id: 1,
        title: "Inprogress",
        cards: [],
      },
      {
        id: 2,
        title: "WaintingForApproval",
        cards: [],
      },
      {
        id: 4,
        title: "Approved",
        cards: [],
      },
    ],
  };

  const [board, setBoard] = useState(initialBoard);
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();
  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  const dispatch = useDispatch();
  const ActionPlans = useSelector((state) => state.ActionPlan.ActionPlans);

  useEffect(() => {
    dispatch(fetchActionPlans())
      .then(unwrapResult)
      .then((ActionPlans) => {
        ActionWorkFlow(ActionPlans);
      });
  }, []);


  const ActionWorkFlow = (ActionPlans) => {
    let NewBoard = { ...initialBoard };
    let tab = [];
    for (let i = 0; i < ActionPlans.length; i++) {
      tab = Object.values(NewBoard)[0];
      let array = [];
      for (let j = 0; j < tab.length; j++) {
        if (ActionPlans[i].state === tab[j].title) {
          tab[j].cards.push(ActionPlans[i]);
        }
        tab[j].cards = [...tab[j].cards];
        tab[j].cards = [...new Set(tab[j].cards)];
      }
    }
    setBoard(NewBoard);
  };

  if (!ActionPlans) {
    return (
      <section>
        <h2>ActionPlans not found!</h2>
      </section>
    );
  }

  function onCardMove(card, source, destination) {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
    let NewState;
    let tab = Object.values(board)[0];
    for (let j = 1; j < tab.length; j++) {
      NewState = tab[destination.toColumnId].title;
    }
    try {
      let modifiedActionPlan = {
        due_date: card.due_date,
        state: NewState,
      };
      const resultAction = dispatch(
        UpdateActionPlan({
          id: card.id,
          updatedActionPlan: { ...modifiedActionPlan },
        }),
        dispatch(fetchActionPlans())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(dispatch(fetchActionPlans()));
      unwrapResult(resultAction1);
    } catch (err) {} finally {}
  }

  const renderCard = (card, t, r) => {
    return (
      <div>
        {card ? (
          <Card
            style={{
              border: "1px solid lightgray",
              width: "220px",
              height: "220px",
            }}
          >
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem key={Element.id} xs={12} sm={12} md={11}>
                <strong>
                  <p>
                    Type : {card && card.resource && card.resource} Action Plan
                  </p>
                </strong>
                <Link to={`/citrine/ActionPlanDetails/${card.id}`}>
                  <strong>
                    <p> {card && card.name && card.name}</p>
                  </strong>
                </Link>
                {Moment(card.date).format("YYYY-MM-DD")}
              </GridItem>
              <GridItem key={Element.id} xs={12} sm={12} md={1}>
                <DropDownButtonActionPlan
                  ActionPlan_ID={card.id}
                  ActionWorkFlow={ActionWorkFlow}
                />
              </GridItem>
            </GridContainer>
          </Card>
        ) : (
          <p></p>
        )}
      </div>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/programs_assessment`}>
                {t(" Programs List ")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/actionPlan/Workflow`}>
                {t("Action Workflow")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <GridContainer xs={12} sm={12} md={12}>
        <Card>
          <Board
            onCardDragEnd={onCardMove}
            renderCard={renderCard}
            className="react-kanban-board"
          >
            {board}
          </Board>
        </Card>
      </GridContainer>
    </div>
  );
}
