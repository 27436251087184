// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider, Steps, Tabs } from "antd";
import "antd/dist/antd.css";
// core components
import {
  blackColor,
  cardTitle,
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoReport } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchActivities } from "../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import { fetchPLOReportDocumentsByProgramId } from "../../../../reduxToolkit/features/PLOReportDocument/PLOReportDocumentSlice";
import {
  fetchPloReportById,
  fetchPloReports,
  UpdatePloReport,
} from "../../../../reduxToolkit/features/PloReporte/PloReporteSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import DropDownPloImprovementReportButton from "../../../Components/DropDownPloImprovementReportButton";
import GenerateActionsModal from "../../../Components/GenerateActionsPloModal";
import PreviousActionPlan from "../../../Components/PreviousActionPlan";
import Analysis from "./Analysis";
import AssessmentAnalysis from "./AssessmentAnalysis";
import "../improvement.css";
import CreateReportPloImprovementDocument from "../../../Components/CreateReportPloImprovementDocument";
import CircularProgress from "@mui/material/CircularProgress";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";

const styles2 = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const { TabPane } = Tabs;
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const useStyles = makeStyles(styles);

export default function PloImprovementDetails() {
  const [valueContent, setValueContent] = useState('');
  const { t } = useTranslation();
  const classes1 = useStyles1();
  const classes = useStyles();

  const { id, type } = useParams();

  const dispatch = useDispatch();
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);

  const renderReports = () => {
    setShowReports(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
  };

  const ploReportDocuments = useSelector(
    (state) => state.ploReportDocument.ploReportDocuments
  );

  const renderRow = () => {
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: "gray" }}
              >
                {Moment(item.DueDate).format("YYYY-MM-DD")}
              </Divider>
              <div
                style={{
                  backgroundColor: "#edeff1",
                  borderBottom: "1px solid #cfd5db",
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={2}>
                    <Avatar
                      style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={10}>
                    <p>
                      <strong>{item.AssignedTo}</strong>
                    </p>
                    <ul>
                      <li>{item.Summary}</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (showTasks) {
      return <div>Tasks</div>;
    } else {
      return (
        <div>
          <div style={{ zIndex: 10000 }}>
            <CreateReportPloImprovementDocument
              title={"Create Plos Improvement Document Report"}
              programId={PloReport && PloReport?.program}
            />
          </div>
          {Array.isArray(ploReportDocuments)
            ? ploReportDocuments.map((ploReportDocument) => {
                return (
                  <Card style={{ backgroundColor: grayColor[8] }}>
                    <GridContainer justify="space-between" alignItems="center">
                      <Link
                        to={`/citrine/reports/${type}/PLO/${id}/documents/${ploReportDocument?.id}`}
                      >
                        <GridItem>
                          <h6> {ploReportDocument?.name}</h6>
                          <br />
                          <h7>State : {ploReportDocument?.state}</h7>
                          <br />
                          <h7>
                            Created By:{" "}
                            {ploReportDocument &&
                              ploReportDocument?.creator_details &&
                              ploReportDocument?.creator_details
                                ?.first_name}{" "}
                            {""}{" "}
                            {ploReportDocument &&
                              ploReportDocument?.creator_details &&
                              ploReportDocument?.creator_details?.last_name}
                          </h7>
                          <br />
                          <h7>Created On: {ploReportDocument.creation_date}</h7>
                          <br />
                          <h7>
                            Assigned To:{" "}
                            {ploReportDocument &&
                              ploReportDocument?.report_details &&
                              ploReportDocument?.report_details
                                ?.assign_to_details &&
                              ploReportDocument?.report_details
                                ?.assign_to_details?.first_name}{" "}
                            {""}{" "}
                            {ploReportDocument &&
                              ploReportDocument?.report_details &&
                              ploReportDocument?.report_details
                                ?.assign_to_details &&
                              ploReportDocument?.report_details
                                ?.assign_to_details?.last_name}
                          </h7>
                          <br />
                          <h7>Version: {ploReportDocument?.version} </h7>
                          <br />
                        </GridItem>
                      </Link>
                      <GridItem>
                        <DropDownPloImprovementReportButton
                          id={ploReportDocument && ploReportDocument?.id}
                          progId={PloReport && PloReport?.program}
                        />
                      </GridItem>
                    </GridContainer>
                  </Card>
                );
              })
            : ""}
        </div>
      );
    }
  };

  const PloReport = useSelector((state) => state.PloReport.PloReport);
  const Activities = useSelector((state) => state.Activity.Activities);
  const program = useSelector((state) => state.program.program);
  const programStatus = useSelector((state) => state.program.programStatus);
  const [disabledStatus, setDisabledStatus] = useState(false);

  useEffect(() => {
    dispatch(fetchPloReportById(id))
      .unwrap()
      .then((PloReport) => {
        console.log(PloReport);

        setStateName(PloReport.state_object);
        dispatch(fetchPLOReportDocumentsByProgramId(PloReport.program));
        dispatch(fetchProgramById(PloReport.program));
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
    dispatch(fetchActivities());
  }, []);

  let content;

  if (programStatus === "loading") {
    content = (
      <GridContainer
        style={{ height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <GridItem>
          <CircularProgress color="inherit" />
        </GridItem>
      </GridContainer>
    );
  } else if (programStatus === "succeeded") {
    content = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <div style={{ border: "1px solid lightgray" }}>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={4}>
                <h6> {t("Program Name")} </h6>
                <p style={{ fontSize: "12px" }}>
                  {program &&
                    program.program_degree_name &&
                    program.program_degree_name}
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <h6> {t("Program Code")}</h6>
                <p style={{ fontSize: "12px" }}>
                  {program && program.code && program.code}
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <h6> {t("Program Category")}</h6>
                <p style={{ fontSize: "12px" }}>
                  {program && program.category_of_the_program_details[0].name}
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <h6> {t("Department Name")}</h6>
                <p style={{ fontSize: "12px" }}>
                  {program &&
                    program.department_detail[0] &&
                    program.department_detail[0].name}
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <h6> {t("College Name")}</h6>
                <p style={{ fontSize: "12px" }}>
                  {program &&
                    program.college_detail[0] &&
                    program.college_detail[0].name}
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <h6> {t("University Name")}</h6>
                <p style={{ fontSize: "12px" }}>
                  {program &&
                    program.university_detail &&
                    program.university_detail[0].name}
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  if (!PloReport) {
    return (
      <section>
        <GridContainer
          style={{ height: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem>
            <CircularProgress color="inherit" />
          </GridItem>
        </GridContainer>
      </section>
    );
  }

  const callback = (key) => {
    console.log(key);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let content2;
  {
    PloReport &&
      PloReport.states_actions &&
      PloReport.states_actions.map((item) => {
        if (stateName === "Created" && item.state === "Created") {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          PloReport_id={PloReport.id}
                          PloReport={PloReport}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset tO draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {PloReport &&
                    PloReport.states_actions &&
                    PloReport.states_actions.map((item) => {
                      return item.state === "Created" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (
          stateName &&
          stateName.toLowerCase().includes("waiting for approval") &&
          item.state &&
          item.state.toLowerCase().includes("waiting for approval")
        ) {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          PloReport_id={PloReport.id}
                          PloReport={PloReport}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {PloReport &&
                    PloReport.states_actions &&
                    PloReport.states_actions.map((item) => {
                      return item.state &&
                        item.state
                          .toLowerCase()
                          .includes("waiting for approval") ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Approved" && item.state === "Approved") {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          PloReport_id={PloReport.id}
                          PloReport={PloReport}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {PloReport &&
                    PloReport.states_actions &&
                    PloReport.states_actions.map((item) => {
                      return item.state === "Approved" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Archived" && item.state === "Archived") {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsModal
                          handleDropDownButtonClose={handleClose}
                          PloReport_id={PloReport.id}
                          PloReport={PloReport}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {PloReport &&
                    PloReport.states_actions &&
                    PloReport.states_actions.map((item) => {
                      return item.state === "Archived" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        }
      });
  }

  const Approve = () => {
    setShow(true);
    setStateName("Approved");
    localStorage.setItem("PloReport_State", "Approved");
    try {
      let modifiedCourseReport = {
        due_date: PloReport && PloReport.due_date && PloReport.due_date,

        state_object: "Approved",
      };
      const resultAction = dispatch(
        UpdatePloReport({
          id: id,
          updatedPloReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchPloReports())
      );
      unwrapResult(resultAction);
      setStateName("Approved");
      dispatch(fetchPloReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPloReports());
    }
  };
  const SendforApproval = () => {
    setShow(true);
    setStateName("Waiting for approval");
    localStorage.setItem("PloReport_State", "Waiting for approval");
    try {
      let modifiedCourseReport = {
        due_date: PloReport && PloReport.due_date && PloReport.due_date,
        state_object: "Waiting for approval",
      };
      const resultAction = dispatch(
        UpdatePloReport({
          id: id,
          updatedPloReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchPloReports())
      );
      unwrapResult(resultAction);
      setStateName("Waiting for approval");
      dispatch(fetchPloReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPloReports());
    }
  };
  const ResetTODraft = () => {
    setStateName("Created");
    localStorage.setItem("PloReport_State", "Created");
    try {
      let modifiedCourseReport = {
        due_date: PloReport && PloReport.due_date && PloReport.due_date,
        state_object: "Created",
      };
      const resultAction = dispatch(
        UpdatePloReport({
          id: id,
          updatedPloReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchPloReports())
      );
      unwrapResult(resultAction);
      setStateName("Created");
      dispatch(fetchPloReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPloReports());
    }
  };

  const Archive = () => {
    setShow(true);
    setStateName("Archived");
    localStorage.setItem("PloReport_State", "Archived");
    try {
      let modifiedCourseReport = {
        due_date: PloReport && PloReport.due_date && PloReport.due_date,
        state_object: "Archived",
      };
      const resultAction = dispatch(
        UpdatePloReport({
          id: id,
          updatedPloReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchPloReports())
      );
      unwrapResult(resultAction);
      setStateName("Archived");
      dispatch(fetchPloReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPloReports());
    }
  };

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/reports/${type}/PLO/`}>
              {t("PLO Report List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              style={{ color: "orange" }}
              to={`/citrine/reports/${type}/PLO/${id}`}
            >
              {t("PLO Report Details")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={9}>
          <GridContainer xs={12} sm={12} md={12}>
            {content2}
          </GridContainer>
          <GridContainer>
            <div class="card" class="scrollable">
              <div
                style={{
                  padding: "10px",
                  borderRadius: "0px",
                  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                  background: whiteColor,
                  width: "100%",
                  boxShadow:
                    "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "0",
                  wordWrap: "break-word",
                  fontSize: ".875rem",
                }}
              >
                {content}
                <Tabs onChange={callback} type="card">
                  <TabPane tab={t("PLOs & Recommendations ")} key="1">
                    <Analysis
                      idProgram={
                        PloReport && PloReport.program && PloReport.program
                      }
                      idPloReport={id}
                    />
                  </TabPane>
                  <TabPane tab={t("Previous Action Plan")} key="3">
                    <PreviousActionPlan
                      resource_id={PloReport && PloReport.id}
                      resource="Plo"
                    />
                  </TabPane>
                  <TabPane tab={t("Assessment Analysis")} key="4">
                    <AssessmentAnalysis PloReport={PloReport && PloReport} />
                  </TabPane>
                  <TabPane tab={t("Dynamic section")} key="5">
                    <HTMLEditor valueContent={valueContent} setValueContent={setValueContent} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </GridContainer>
        </GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={3}>
          <div
            style={{
              borderRadius: "0px",
              color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
              background: whiteColor,
              width: "100%",
              boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              minWidth: "0",
              wordWrap: "break-word",
              fontSize: ".875rem",
              height: 380,
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-around">
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderReports}
                    >
                      <GoReport
                        color={showReports ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes1.root} class="card" class="scrollable">
                  <div>{renderRow()}</div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
