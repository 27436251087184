import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React,{ useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { grayColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import { fetchGraduateAttributeConfigs, getId } from '../../../../../reduxToolkit/features/graduateAttributeConfig/GraduateAttributeConfigSlice';
import DropDownGraduateAttributeConfigButton from '../../../../Components/DropDownGraduateAttributeConfigButton';


const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);


const GraduateAttributeConfigExcerpt = ({ graduateAttributeConfig }) => {
  const { t } = useTranslation();
  console.log(graduateAttributeConfig)
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getId(id))

  }
  //   const deleteCol = (id) => {
  //     dispatch(deleteGraduateAttributeConfig(id))
  //   }

  return (


    <GridItem key={graduateAttributeConfig.id}>


      <Card product className={classes.cardHover}  >
          <GridContainer justify="space-between" alignItems="baseline">
        <CardBody style={{ width: '300px', height: "290px" , display:'flex',justifyContent:'space-between'}}>
            <GridItem >

              <div>
                <h4 className={classes.cardProductTitle} >

                  {graduateAttributeConfig && `${graduateAttributeConfig.code}: ${graduateAttributeConfig.name}`}

                </h4>
                <p className={classes.cardProductDesciprion}>
                  {graduateAttributeConfig.description}
                </p>
              </div>
            </GridItem>
            <DropDownGraduateAttributeConfigButton id={graduateAttributeConfig.id} />

        </CardBody>
            </GridContainer>
      </Card>



    </GridItem>


  )
}

export default function GraduateAttributeConfigsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const graduateAttributeConfigsStatus = useSelector(state => state.graduateAttributeConfig.graduateAttributeConfigsStatus)

  useEffect(() => {
    if (graduateAttributeConfigsStatus === 'idle') {
      dispatch(fetchGraduateAttributeConfigs())
    }
  }, [graduateAttributeConfigsStatus, dispatch])

  const graduateAttributeConfigs = useSelector(state => state.graduateAttributeConfig.graduateAttributeConfigs)
  const error = useSelector(state => state.graduateAttributeConfig.error)

  let content

  if (graduateAttributeConfigsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (graduateAttributeConfigsStatus === 'succeeded') {
    content = graduateAttributeConfigs && graduateAttributeConfigs.map(graduateAttributeConfig => (

      <GraduateAttributeConfigExcerpt key={graduateAttributeConfig.id} graduateAttributeConfig={graduateAttributeConfig} />
    ))
  } else if (graduateAttributeConfigsStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                <span > {t('Program Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <Link to={`/citrine/graduateAttributeConfigs`} style={{ color: "orange" }}>
                {t('GraduateAttributeConfigs')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>
      <Link to="/citrine/configuration/program/graduateAttributeConfigs/create" >
        <Button color="info">

          {t('Add')}
        </Button>
      </Link>

      <GridContainer>
        {
          content

        }
      </GridContainer>
    </div>
  );
}










