import React, { useState } from "react";
import { useDispatch } from "react-redux";
//import Button from '@material-ui/core/Button';
import {
  EditOutlined
} from "@ant-design/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useHistory } from "react-router-dom";
import { updateClo } from "../../reduxToolkit/features/CLO/CloSlice";
import { fetchScoreGAIndicator } from "../../reduxToolkit/features/GAIndicators/GAIndicatorSlice";

import { useTranslation } from "react-i18next";
import { fetchGraduateAttributeById } from "reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
export default function WeightCloGAIndictoorsModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [open, setOpen] = React.useState(false);

  const { Option } = Select;

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");
  const [weight_ga, setWeight_ga] = useState(props.clo.weight);
  const [
    graduateAttributeIndicators_id,
    setGraduateAttributeIndicators_id,
  ] = useState(props.GAIndicatorID);

  const handleSend = async () => {
    let ScoreObject = {
      id: props.GAIndicatorID,
    };
    try {
      dispatch(
        updateClo({
          id: props.cloID,
          updatedClo: { weight_ga, graduateAttributeIndicators_id },
        })
      ).then(() => {
        dispatch(fetchScoreGAIndicator(ScoreObject)).then(() => {
          history.push(
            `/citrine/GADetails/PrgramId/${props.PrgramId}/GAId/${props.GAId}`
          );
          dispatch(fetchGraduateAttributeById(props.GAId));
        });
      });
    } catch (err) {
      console.log("Failed to save the clo : ", err);
    } finally {
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <EditOutlined
        style={{ color: "royalblue", fontSize: "17px" }}
        onClick={(event) => {
          handleClickOpen(event);
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          {t("Edit Weight Clo")}{" "}
        </DialogTitle>
        <DialogContent>
          <form>
            <CustomInput
              labelText={t('weight*')}
              id="weight_ga"
              value={weight_ga}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please enter a weight ",
                },
              ]}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: false,
                onChange: (event) => {
                  setWeight_ga(event.target.value);
                },
                value: weight_ga,
              }}
            />
          </form>

          <DialogActions>
            <Button onClick={handleClose} color="info">
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleSend();
              }}
              color="info"
            >
              {" Save"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
