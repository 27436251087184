import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  plannedActivities: [],
  plannedActivitiesStatus: 'idle',
  plannedActivityStatus: 'idle',
  error: null,
  plannedActivity: null,
  id: null
}

export const fetchPlannedActivities = createAsyncThunk('plannedActivity/fetchPlannedActivities', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/plannedActivity/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data fetchPlannedActivities')

  return response.data
})

export const fetchPlannedActivityById = createAsyncThunk('plannedActivity/fetchPlannedActivityById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/plannedActivity/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewPlannedActivity = createAsyncThunk(
  'plannedActivity/addNewPlannedActivity',
  async (initialPAType) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/plannedActivity/create/`, initialPAType, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updatePlannedActivity = createAsyncThunk(
  'plannedActivity/updatePlannedActivity',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/plannedActivity/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedPlannedActivity')
      console.log(response.data, ' plannedActivity ************ response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the plannedActivity:  +++++++ ', error)

    }
  }
)


export const deletePlannedActivity = createAsyncThunk('patypeSpec/deletePAType', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/plannedActivity/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
const plannedActivitiesSlice = createSlice({
  name: 'plannedActivity',
  initialState,
  extraReducers: {

    [fetchPlannedActivities.pending]: (state, action) => {
      state.plannedActivitiesStatus = 'loading'
    },
    [fetchPlannedActivities.fulfilled]: (state, action) => {
      state.plannedActivitiesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.plannedActivities = action.payload
    },
    [fetchPlannedActivities.rejected]: (state, action) => {
      state.plannedActivitiesStatus = 'failed'
      state.error = action.payload
    },

    [fetchPlannedActivityById.pending]: (state, action) => {
      state.plannedActivityStatus = 'loading'
    },
    [fetchPlannedActivityById.fulfilled]: (state, action) => {
      state.plannedActivityStatus = 'succeeded'

      // Add any fetched posts to the array
      state.plannedActivity = action.payload
    },
    [fetchPlannedActivityById.rejected]: (state, action) => {
      state.plannedActivityStatus = 'failed'
      state.error = action.payload
    },
    [addNewPlannedActivity.fulfilled]: (state, action) => {
      state.plannedActivities.push(action.payload)
    },
    [deletePlannedActivity.fulfilled]: (state, action) => {
      // state.plannedActivies.push(action.payload)
    },
    [updatePlannedActivity.fulfilled]: (state, action) => {
      const result = state.plannedActivities.filter(plannedActivity => plannedActivity.id !== action.payload.id);
      // console.log("plannedActivity id from filter from slice",plannedActivity.id)
      result.push(action.payload)
      state.plannedActivities = result
      console.log("action.payload.id from slice",action.payload)

    },
  
  },

  reducers: {
    getId:
      (state, action) => {
        state.id = action.payload
      },


      plannedActivityUpdated(state, action) {
      const { id, name } = action.payload
      const existingPAType = state.plannedActivities.find(plannedActivity => plannedActivity.id === id)
      if (existingPAType) {
        existingPAType.name = name
      

      }
    }
  }
})

export const { plannedActivityUpdated, getId } = plannedActivitiesSlice.actions

export default plannedActivitiesSlice.reducer

 export const selectAllPlannedActivities = state => state.plannedActivities.plannedActivities
