import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import token from 'assets/utils/accessToken';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
    FacilitiesRequireds: [],
    FacilitiesRequiredStatus: 'idle',
    FacilitiesRequiredStatus: 'idle',
    error: null,
    FacilitiesRequired: null,
    FacilitiesRequiredsByCourse:[],
    id: null
}


export const fetchFacilitiesRequireds = createAsyncThunk(
  'FacilitiesRequired/fetchFacilitiesRequireds', async (course_id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl + `/facilitiesRequiredSpec/?courseSpec=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    console.log(response.data, 'response.data')
    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);



export const fetchFacilitiesRequiredsByCourse = createAsyncThunk(
  "FacilitiesRequired/fetchFacilitiesRequiredsByCourse",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
          `/facilitiesRequiredSpec/areaSpec_filtered/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

  export const fetchFacilitiesRequiredById = createAsyncThunk(
    'facilitiesRequired/fetchFacilitiesRequiredById', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/facilitiesRequiredSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewFacilitiesRequired = createAsyncThunk(
    'FacilitiesRequired/addNewFacilitiesRequired',
    async (initialFacilitiesRequired, { rejectWithValue }) => {
        const token=localStorage.getItem('token')
        console.log('initial FacilitiesRequired', initialFacilitiesRequired)
        try {
        const response = await axios.post(BaseUrl+`/facilitiesRequiredSpec/create/`, initialFacilitiesRequired, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
  export const facilitiesRequiredUpdate = createAsyncThunk(
    'facilitiesRequired/facilitiesRequiredUpdate',
    async (data, { rejectWithValue }) => {
        const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/facilitiesRequiredSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedFacilitiesRequired')
      console.log(response.data,'response.data')   
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const deleteFacilitiesRequired = createAsyncThunk(
  'FacilitiesRequired/deleteFacilitiesRequired', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(BaseUrl+`/facilitiesRequiredSpec/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')
    return response;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);
const FacilitiesRequiredSlice = createSlice({
    name: 'FacilitiesRequired',
    initialState,

    extraReducers: {
        [fetchFacilitiesRequireds.pending]: (state, action) => {
            state.FacilitiesRequiredStatus = 'loading'
        },
        [fetchFacilitiesRequireds.fulfilled]: (state, action) => {
            state.FacilitiesRequiredStatus = 'succeeded'
            // Add any fetched posts to the array
            state.FacilitiesRequireds = action.payload
        },
        [fetchFacilitiesRequireds.rejected]: (state, action) => {
            state.FacilitiesRequiredStatus = 'failed'
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },

          [fetchFacilitiesRequiredById.pending]: (state, action) => {
            state.FacilitiesRequiredStatus = 'loading'
          },
          [fetchFacilitiesRequiredById.fulfilled]: (state, action) => {
            state.FacilitiesRequiredStatus = 'succeeded'

            // Add any fetched posts to the array
            state.FacilitiesRequired = action.payload
          },
          [fetchFacilitiesRequiredById.rejected]: (state, action) => {
            state.FacilitiesRequiredStatus = 'failed'
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },



          [fetchFacilitiesRequiredsByCourse.pending]: (state, action) => {
            state.FacilitiesRequiredStatus = "loading";
          },
          [fetchFacilitiesRequiredsByCourse.fulfilled]: (state, action) => {
            state.FacilitiesRequiredStatus = "succeeded";
            // Add any fetched posts to the array
            state.FacilitiesRequiredsByCourse = action.payload;
          },
          [fetchFacilitiesRequiredsByCourse.rejected]: (state, action) => {
            state.FacilitiesRequiredStatus = "failed";
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
          },



        [addNewFacilitiesRequired.fulfilled]: (state, action) => {
            state.FacilitiesRequireds.push(action.payload)
        },
        [addNewFacilitiesRequired.rejected]: (state, action) => {
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
        },
    
      
      [deleteFacilitiesRequired.fulfilled]: (state, action) => {
            // state.SpecificationAprovalDatas.push(action.payload)
        },
      [deleteFacilitiesRequired.rejected]: (state, action) => {
          if (action.payload) {
            state.error = action.payload;
          } else {
            state.error = action.error;
          }
      },
    
     
      [facilitiesRequiredUpdate.fulfilled]: (state, action) => {
            console.log(action) 
      },
      [facilitiesRequiredUpdate.rejected]: (state, action) => {
            if (action.payload) {
              state.error = action.payload;
            } else {
              state.error = action.error;
            }
      },
      
        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },


            facilitiesRequiredUpdated(state, action) {
                const { id, name } = action.payload
                const existingFacilitiesRequired = state.FacilitiesRequireds.find(facilitiesRequired => facilitiesRequired.id === id)
                if (existingFacilitiesRequired) {
                    existingFacilitiesRequired.name = name


                }
            },
        },
    },
});

export const {
    FacilitiesRequiredAdded, 
    FacilitiesRequiredUpdated, 
    getId } = FacilitiesRequiredSlice.actions

export default FacilitiesRequiredSlice.reducer

export const selectAllFacilitiesRequired = state => state.FacilitiesRequired.FacilitiesRequireds

