import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchAnnualProgramReportById } from "reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import DevelopmentalRecommendationsModal from "../../../../Components/DevelopmentalRecommendationsModal";
import useTableCourseEvaluation from "../../../../Components/useTableCourseEvaluation";
import { unwrapResult } from "@reduxjs/toolkit";
const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);
const headCells = [
  {
    id: "Course_Code",
    label: "Course Code",
  },
  {
    id: "Course_Title",
    label: "Course Title",
  },
  {
    id: "Student_Evaluation",
    label: "Student Evaluation",
  },

  {
    id: "number_of_participants",
    label: "Number Of Participants",
  },

  {
    id: "number_of_response ",
    label: "Number Of Response ",
  },

  {
    id: "rate",
    label: "Rate",
  },

  {
    id: "Developmental_Recommendations ",
    label: "Developmental Recommendations ",
  },
  ,
];
export default function CoursesEvaluation(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();
  const [courses, setCourses] = useState(props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].program_courses &&
    props.AnnualProgramReport.programs[0].program_courses)
 
  console.log("courses", courses);
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  console.log("props", props.AnnualProgramReport);

  const callback = (key) => {
    console.log(key);
  };
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTableCourseEvaluation(records, headCells, filterFn);

  const fetchDevRecommendations =()=>{
    dispatch(fetchAnnualProgramReportById(props.AnnualProgramReport &&
      props.AnnualProgramReport.id))
    .then(unwrapResult)
      .then((AnnualProgramReport) => {
        setCourses([...AnnualProgramReport.programs[0] &&
          AnnualProgramReport.programs[0] &&
          AnnualProgramReport.programs[0].program_courses &&
          AnnualProgramReport.programs[0].program_courses]);
      });
  }
  return (
    <div>
      <p style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold" }}>
        Courses Evaluation
      </p>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div
              style={{
                margin: "0px 10px 10px 10px ",
                borderRight: "1px solid lightgrey",
                borderLeft: "1px solid lightgrey",
              }}
            >
              <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
                <TblHead />
                <TableBody>
                  {courses &&
                    courses.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell
                          style={{
                            borderRight: "1px solid lightgrey",
                          }}
                        >
                          {item && item.code}
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid lightgrey",
                          }}
                        >
                          {item && item.title}
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid lightgrey",
                          }}
                        >
                          {item && item.evaluation}
                        </TableCell>

                        <TableCell
                          style={{
                            borderRight: "1px solid lightgrey",
                          }}
                        >
                          {item && item.number_of_response}
                        </TableCell>

                        <TableCell
                          style={{
                            borderRight: "1px solid lightgrey",
                          }}
                        >
                          {item && item.number_of_participants}
                        </TableCell>

                        <TableCell
                          style={{
                            borderRight: "1px solid lightgrey",
                          }}
                        >
                          {item && item.rate}
                        </TableCell>

                         <TableCell>
                          <DevelopmentalRecommendationsModal
                            item={item}
                            Report_id={
                              props.AnnualProgramReport &&
                              props.AnnualProgramReport.id
                            }
                            fetchDevRecommendations={fetchDevRecommendations}
                          />
                          {item && item.developmental_recommendations}
                        </TableCell> 
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
