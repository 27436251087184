import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import token from 'assets/utils/accessToken';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  graduationRequirements: [],
  graduationRequirementsStatus: 'idle',
  graduationRequirementStatus: 'idle',
  error: null,
  graduationRequirement:null,
 
  id:null
}


export const fetchGraduationRequirements = createAsyncThunk('graduationRequirement/fetchGraduationRequirements', async () => {
  const token=localStorage.getItem('token')
  const response = await axios.get(BaseUrl+'/graduationRequirement/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchGraduationRequirementById = createAsyncThunk('graduationRequirement/fetchGraduationRequirementById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/graduationRequirement/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewGraduationRequirement = createAsyncThunk(
    'graduationRequirement/addNewGraduationRequirement',
    async (initialGraduationRequirement) => {
      console.log('initial graduationRequirement', initialGraduationRequirement)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/graduationRequirement/create/`,initialGraduationRequirement ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const graduationRequirementUpdate = createAsyncThunk(
    'graduationRequirement/graduationRequirementUpdate',
    async (data,thunkAPI) => {
      
      try{
        const token=localStorage.getItem('token')
        const response = await axios.put(BaseUrl+`/api/graduationRequirement/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedGraduationRequirement')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the graduationRequirement: ', error)

}
    }
  )

  export const deleteGraduationRequirement = createAsyncThunk('graduationRequirement/deleteGraduationRequirement', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/graduationRequirement/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
const  graduationRequirementsSlice = createSlice({
  name: 'graduationRequirement',
  initialState,
  extraReducers: {
    [fetchGraduationRequirements.pending]: (state, action) => {
        state.graduationRequirementsStatus = 'loading'
      },
      [fetchGraduationRequirements.fulfilled]: (state, action) => {
        state.graduationRequirementsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.graduationRequirements = action.payload
      },
      [fetchGraduationRequirements.rejected]: (state, action) => {
        state.graduationRequirementsStatus = 'failed'
        state.error = action.payload
      },

      [fetchGraduationRequirementById.pending]: (state, action) => {
        state.graduationRequirementStatus = 'loading'
      },
      [fetchGraduationRequirementById.fulfilled]: (state, action) => {
        state.graduationRequirementStatus = 'succeeded'

        // Add any fetched posts to the array
        state.graduationRequirement = action.payload
      },
      [fetchGraduationRequirementById.rejected]: (state, action) => {
        state.graduationRequirementStatus = 'failed'
        state.error = action.payload
      },
      [addNewGraduationRequirement.fulfilled]: (state, action) => {
        state.graduationRequirements.push(action.payload)
      },
      [graduationRequirementUpdate.fulfilled]: (state, action) => {
        console.log(action) 
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    graduationRequirementUpdated(state, action) {
      const { id,name } = action.payload
      const existingGraduationRequirement = state.graduationRequirements.find(graduationRequirement => graduationRequirement.id === id)
      if (existingGraduationRequirement) {
        existingGraduationRequirement.name = name
      }
    }
  }
}})

export const { graduationRequirementAdded, graduationRequirementUpdated, getId} = graduationRequirementsSlice.actions

export default graduationRequirementsSlice.reducer

export const selectAllGraduationRequirements = state => state.graduationRequirement.graduationRequirements
