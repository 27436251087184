import React from 'react'
import Button from '../../../../components/CustomButtons/Button'
import MenuItem from '@material-ui/core/MenuItem'
// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// material-ui icons
import MoreVert from '@material-ui/icons/MoreVert'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import ErrorModal from '../../../Components/ErrorModal'
import ListItemText from '@material-ui/core/ListItemText'
import { notification } from 'antd'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import { fetchSemesterById } from '../../../../reduxToolkit/features/semester/SemestersSlice.js'
import {
  fetchPrograms,
  deleteProgram,
} from '../../../../reduxToolkit/features/program/ProgramsSlice'
const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function DropDownSemesterButton(props) {
  const { t } = useTranslation()
  const buttonClasses = useButtonStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const dispatch = useDispatch()

  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleDeleteProgram = async (id) => {
    dispatch(deleteProgram(id))
      .unwrap()
      .then(async (originalPromiseResult) => {
        await dispatch(fetchSemesterById(props.semesterId))
        await fetchPrograms(props.semesterId)
        props.setPing(!props.ping)
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteProgram}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
