import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';

export const fetchAreasByTemplateId = createAsyncThunk('surveyArea/fetchAreasByTemplateId', async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().surveyArea
    if (loading !== 'pending' || requestId !== currentRequestId) {
        return
    }
    const token = localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/surveyArea/${id}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }
        )
        return response.data
    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

const surveyAreaSlice = createSlice({
    name: 'surveyArea',
    initialState: {
        areasByTemplate: [],
        loading: 'idle',
        currentRequestId: undefined,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAreasByTemplateId.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchAreasByTemplateId.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.areasByTemplate = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchAreasByTemplateId.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
    }
})
export default surveyAreaSlice.reducer
