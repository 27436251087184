import React, { useState, useEffect, Fragment } from "react";

import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { Tabs } from "antd";
import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";
// import "./improvement.css"

import {
  fetchAnnualProgramReportById,
  UpdateAnnualProgramReport,
} from "../../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import Moment from "moment";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import teacher from "../../../../../assets/img/teacher.png";
import {
  warningColor,
  whiteColor,
} from "../../../../../assets/jss/material-dashboard-pro-react";
import {
  UserOutlined,
  PhoneOutlined,
  FileDoneOutlined,
  FormOutlined,
} from "@ant-design/icons";
import EditPersonContactInformation from "../../../../Components/EditPersonContactInformation";
import EditDifferencesInEvaluationModal from "../../../../Components/EditDifferencesInEvaluationModal";

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

export default function DifferencesInEvaluation({ CourseReport }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const callback = (key) => {
    console.log(key);
  };
  console.log(CourseReport,"CourseReport");
  return (
    <div>
      <p
        style={{
          paddingLeft: "10px",
          color: warningColor[0],
          fontSize: "14px",
        }}
      >
        <strong style={{ color: warningColor[0] }}>
          <FileDoneOutlined style={{ color: warningColor[0] }} />
          Differences In Evaluation
        </strong>
      </p>
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        style={{
          borderRadius: "6px",
          backgroundColor: whiteColor,
          border: "1px solid lightgrey",
          marginRight: "20px",
          marginBottom: "10px",
        }}
      >
        <GridItem xs={12} sm={12} md={11}>
          {CourseReport && CourseReport.differences_in_evaluation && CourseReport.differences_in_evaluation} 
        </GridItem>

        <GridItem xs={12} sm={12} md={1} style={{ textAlign: "end" }}>
          <EditDifferencesInEvaluationModal
            course_report_id={CourseReport && CourseReport.id}
            CourseReport={CourseReport}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
