import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, InputNumber, notification, Select } from 'antd'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import {
  addNewGroupStates,
  fetchGroupStates,
  updateGroupState,
} from 'reduxToolkit/features/groupState/GroupStateSlice'
import { fetchUserGroups } from 'reduxToolkit/features/userGroups/UserGroupSlice'
import { fetchReportStatesConfigs } from 'reduxToolkit/features/ReportStatesConfig/ReportStatesConfigSlice'
import ErrorModal from 'views/Components/ErrorModal'

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const useButtonStyles = makeStyles(buttonStyles)

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const EditStateActionGroupModal = ({
  open,
  handleOpenEditModal,
  handleClose,
  groupState,
  ping,
  setPing,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttonClasses = useButtonStyles()
  const [form] = Form.useForm()
  const anchorRef = React.useRef(null)

  const { Option, OptGroup } = Select
  const { TextArea } = Input
  const [group_ids, setGroups_ids] = useState('')
  const [name, setName] = useState('')
  const [model, setModel] = useState(groupState)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  const onFinish = async (values) => {
    console.log({ ...values, id: groupState.id, model: groupState.model })
    try {
      const resultAction = await dispatch(
        updateGroupState({
          ...values,
          id: groupState.id,
          model: groupState.model,
        }),
      )
      unwrapResult(resultAction)
      handleClose()
      form.resetFields();
      openNotificationWithIcon('success', `update successfully`)
      setPing(!ping)
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
    
  }



  const classes = useStyles()
  function onChange(value) {
    console.log(`selected ${value}`)
    setName(value)
  }
  const history = useHistory()

  const stateNames = useSelector(
    (state) => state.ReportStates.ReportStatesConfigs,
  )
  const groupsList = useSelector((state) => state.userGroup.userGroups)

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  function onChangeGroups_ids(value) {
    console.log(`selected ${value}`)
    setGroups_ids(value)
  }
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  useEffect(() => {
    form.setFieldsValue({
      name_id: groupState.name_id,
      index: groupState.index,
      group_ids: groupState.group_ids,
    })
  }, [])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <center> {t('Update State')}</center>
      </DialogTitle>
      <DialogContent style={{ width: '600px' }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                name="name_id"
                label="State"
                rules={[
                  {
                    required: true,
                    message: 'Please input the State field.',
                  },
                ]}
              >
                <Select
                  placeholder="Select State "
                  dropdownStyle={{ zIndex: 10000 }}
                >
                  {stateNames &&
                    stateNames.map((r) => (
                      <Option key={r.id} value={r.id}>
                        {r.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="index"
                label="Index"
                rules={[
                  {
                    required: true,
                    message: 'Please input the Index field.',
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="group_ids"
                label="Groups"
                rules={[
                  {
                    required: true,
                    message: 'Please input the State field.',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Groups "
                  dropdownStyle={{ zIndex: 10000 }}
                >
                  {groupsList &&
                    groupsList.map((r) => (
                      <Option key={r.id} value={r.id}>
                        {r.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  style={{ marginRight: '20px' }}
                  color="danger"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="primary" color="info">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </Dialog>
  )
}

export default EditStateActionGroupModal
