import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

export const fetchTransferRequirements = createAsyncThunk(
    "transferCreditsAcceptanceRequirement/fetchTransferRequirements",
    async (_, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().transferCreditsAcceptanceRequirement
        if (loading !== 'pending' || requestId !== currentRequestId) {
            return
        }
        const token = localStorage.getItem("token");
        const response = await axios.get(
            BaseUrl + `/transferCredits/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;
    }
);

export const fetchTransferRequirement = createAsyncThunk(
    "transferCreditsAcceptanceRequirement/fetchTransferRequirement",
    async (id, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().transferCreditsAcceptanceRequirement
        if (loading !== 'pending' || requestId !== currentRequestId) {
            return
        }
        const token = localStorage.getItem("token");
        const response = await axios.get(
            BaseUrl + `/transferCredits/${id}`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;
    }
);

export const createTransferRequirement = createAsyncThunk(
    "transferCreditsAcceptanceRequirement/createTransferRequirement",
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                BaseUrl + `/transferCredits/create/`,
                data,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
);

export const updateTransferRequirement = createAsyncThunk(
    "transferCreditsAcceptanceRequirement/updateTransferRequirement",
    async (data, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        try {
            const { id, ...fields } = data

            const response = await axios.put(
                BaseUrl + `/transferCredits/${id}/edit/`,
                fields,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
);

export const deleteTransferRequirement = createAsyncThunk(
    "transferCreditsAcceptanceRequirement/deleteAcceptanceRequirement",
    async (id, { rejectWithValue }) => {
        const token = localStorage.getItem("token");

        const response = await axios.delete(
            BaseUrl + `/transferCredits/${id}/delete/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return response.data;

    }
);
const TransferCreditsAcceptanceRequirementsSlice = createSlice({
    name: "transferCreditsAcceptanceRequirement",
    initialState: {
        acceptanceRequirements: [],
        acceptanceRequirement: null,
        loading: "idle",
        currentRequestId: undefined,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransferRequirements.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchTransferRequirements.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.acceptanceRequirements = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchTransferRequirements.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        builder
            .addCase(fetchTransferRequirement.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            .addCase(fetchTransferRequirement.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.acceptanceRequirement = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(fetchTransferRequirement.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        builder.addCase(createTransferRequirement.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        builder.addCase(updateTransferRequirement.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        builder.addCase(deleteTransferRequirement.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
    },
});

export default TransferCreditsAcceptanceRequirementsSlice.reducer;
