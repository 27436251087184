import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  CourseContacts: [],
  CourseContacttatus: 'idle',
  CourseContactStatus: 'idle',
  error: null,
  CourseContact: null,

  id: null
}


export const fetchCourseContacts = createAsyncThunk(
  'CourseContact/fetchCourseContacts', async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/courseSpecContact/?courseSpec_id=${course_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      console.log(response.data, 'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseContactById = createAsyncThunk(
  'CourseContact/fetchCourseContactById', async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/courseSpecContact/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewCourseContact = createAsyncThunk(
  'CourseContact/addNewCourseContact',
  async (initialCourseContact, { rejectWithValue }) => {
    console.log('initial CourseContact', initialCourseContact)
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/courseSpecContact/create/`, initialCourseContact, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const CourseContactUpdate = createAsyncThunk(
  'CourseContact/CourseContactUpdate',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/courseSpecContact/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedCourseContact')
      console.log(response.data, 'response.data')
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseContact = createAsyncThunk(
  'CourseContact/deleteCourseContact', async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(BaseUrl + `/courseSpecContact/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, ' delete response.data')

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const CourseContactSlice = createSlice({
  name: 'CourseContact',
  initialState,

  extraReducers: {
    [fetchCourseContacts.pending]: (state, action) => {
      state.CourseContactStatus = 'loading'
    },
    [fetchCourseContacts.fulfilled]: (state, action) => {
      state.CourseContactStatus = 'succeeded'
      // Add any fetched posts to the array
      state.CourseContacts = action.payload
    },
    [fetchCourseContacts.rejected]: (state, action) => {
      state.CourseContactStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCourseContactById.pending]: (state, action) => {
      state.CourseContactStatus = 'loading'
    },
    [fetchCourseContactById.fulfilled]: (state, action) => {
      state.CourseContactStatus = 'succeeded'

      // Add any fetched posts to the array
      state.CourseContact = action.payload
    },
    [fetchCourseContactById.rejected]: (state, action) => {
      state.CourseContactStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewCourseContact.fulfilled]: (state, action) => {
      state.CourseContacts.push(action.payload)
    },
    [deleteCourseContact.fulfilled]: (state, action) => {
      // state.ificationAprovalDatas.push(action.payload)
    },
    [addNewCourseContact.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseContact.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [CourseContactUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [CourseContactUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },



    reducers: {
      getId:
        (state, action) => {
          state.id = action.payload
        },


      // CourseContactUpdated(state, action) {
      //     const { id, name } = action.payload
      //     const existingCourseContact = state.CourseContacts.find(CourseContact => CourseContact.id === id)
      //     if (existingCourseContact) {
      //         existingCourseContact.name = name


      //     }
      // }
    }
  }
})

export const {
  //CourseContactAdded, 
  //CourseContactUpdated, 
  getId } = CourseContactSlice.actions

export default CourseContactSlice.reducer


