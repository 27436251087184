import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardBodyStyle from "assets/jss/material-dashboard-pro-react/components/cardBodyStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileWord } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchPLOReportDocumentById } from "../../../../../reduxToolkit/features/PLOReportDocument/PLOReportDocumentSlice";
import { Avatar, Breadcrumb, Divider, Steps, Tabs } from "antd";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useCardBodyStyles = makeStyles(cardBodyStyle);

export default function PLOReportDocumentDetails() {
  const { id, type, docId } = useParams();
  const { t } = useTranslation();

  const cardBodyClasses = useCardBodyStyles();

  const dispatch = useDispatch();

  const ploReportDocument = useSelector(
    (state) => state.ploReportDocument.ploReportDocuments
  );
  console.log(ploReportDocument, "ploReportDocument");

  const exportDocumentToPDF = () => {
    // const doc = new jsPDF();

    // let parser = new DOMParser();
    // let parsedHtml = parser.parseFromString(ploReportDocument && ploReportDocument.htmlTemplates_body && ploReportDocument.htmlTemplates_body, 'text/html');
    // console.log("parsedHtml", parsedHtml)
    var html = htmlToPdfmake(
      ploReportDocument && ploReportDocument.htmlTemplates_body
    );
    console.log("html", html);

    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  };

  function exportDocumentToWORD(element, filename = "") {
    var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>`;
    var postHtml = `</body></html>`;
    var html = preHtml + element + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }
  useEffect(() => {
    dispatch(fetchPLOReportDocumentById(docId)).then((res) => console.log(res));
  }, []);
  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/reports/${type}/PLO`}>
              {t("PLO Report List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/reports/${type}/PLO/${id}`}>
              {t("PLO Report Details")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ color: "orange" }}>
            {ploReportDocument?.name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      {/* <GridContainer
                alignItems="center"
                style={{
                    borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
                }}>
                <GridItem>
                    <Link to={`/citrine/programSpec`}>
                        <span className={titleClasses.title}> {t('Programs')} </span>
                    </Link>

                </GridItem>

            </GridContainer> */}
      <GridContainer xs={12} sm={12} md={10} justify="flex-end">
        <GridItem>
          <Link
            to={`/citrine/reports/${type}/PLO/${id}/documents/${docId}/edit`}
          >
            <Button color="white" aria-label="Edit" justIcon round>
              <Edit />
            </Button>
          </Link>
        </GridItem>
        {/* <GridItem>

                    <Button
                        color="white"
                        aria-label="Export to PDF"
                        justIcon
                        round
                        onClick={exportDocumentToPDF}
                    >
                        <FaFilePdf />
                    </Button>
                </GridItem> */}
        <GridItem>
          <Button
            color="white"
            aria-label="Export to Word"
            justIcon
            round
            onClick={() => {
              exportDocumentToWORD(
                ploReportDocument &&
                  ploReportDocument.htmlTemplates_body &&
                  ploReportDocument.htmlTemplates_body,
                "word-content"
              );
            }}
          >
            <FaFileWord />
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={10}>
        <Card>
          <CardBody className={cardBodyClasses.cardBodyWithMarginTop}>
            <GridContainer justify="center">
              <GridItem>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      ploReportDocument && ploReportDocument.htmlTemplates_body,
                  }}
                ></div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
}
