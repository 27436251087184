import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker, Form, notification, Select, Input, Alert, Space } from "antd";

// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../../Components/ErrorModal.jsx";
import { addNewAcademicYearEvent, fetchAcademicYearEvents } from "../../../../reduxToolkit/features/academicYearEvents/academicYearEventsSlice"
import { fetchAcademicYearById } from '../../../../reduxToolkit/features/academicYear/AcademicYearsSlice';
import { fetchProgramsByAcademicYear } from "../../../../reduxToolkit/features/program/ProgramsSlice.js";
export default function CreateAcademicYearEventModal({ academicYear_id, AllAcademicYearEvents }) {
    const { t } = useTranslation();
    const { Option } = Select;
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProgramsByAcademicYear(academicYear_id));
    }, [dispatch]);
    const programs = useSelector((state) => state.program.programsByAcademicYear);
    const [program_id, setProgram_id] = React.useState(null);
    const onChangeProgram_id = (value) => {
        setProgram_id(value);

    };
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const [handleErrorText, setHandleErrorText] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const [start_date, setStart_date] = useState("");
    const [end_date, setEnd_date] = useState("");

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    function onStart_dateChange(value, dateString) {
        setStart_date(dateString);
    }
    function onEnd_dateChange(value, dateString) {
        setEnd_date(dateString);
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        // message.error('Submit failed!');

    };


    const onFinish = async (values) => {

        if (!start_date && !end_date) {
            // Show an error message to the user
            handleOpenErrorModal()
            setHandleErrorText("Start and end dates should be not empty")
            return;
        }

        let data = { ...values, start_date, end_date, academicYear_id }
        console.log("data", data);

        const resultAction = await dispatch(
            addNewAcademicYearEvent(data)
        );

        if (addNewAcademicYearEvent.fulfilled.match(resultAction)) {
            AllAcademicYearEvents()
            dispatch(fetchAcademicYearEvents(academicYear_id))
            openNotificationWithIcon("success", ` success Added`);
            handleClose();
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                console.log("resultAction.error  from component", resultAction.error);
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        }
    };

    return (
        <div>
            <Button color="primary" onClick={handleClickOpen}>
                {t("Add")}
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {t("Add Academic Year Event")}
                </DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={10} sm={10} md={12}>
                            <Form
                                name="basic"
                                layout="vertical"
                                //   labelCol={{ span: 8 }}
                                //   wrapperCol={{ span: 16 }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                scrollToFirstError
                            >
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Program")}
                                            name="program_id"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Select a program"
                                                showSearch
                                                style={{ width: "100%" }}
                                                optionFilterProp="children"
                                                value={program_id}
                                                onChange={onChangeProgram_id}
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                dropdownStyle={{ zIndex: 10000 }}
                                            >
                                                {programs?.map((program) => (
                                                    <Option key={program.id} value={program.id}>
                                                        {program.program_degree_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </GridItem>


                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Name")}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>
                                        <Form.Item
                                            label={t("Start date")}
                                            name="start"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={onStart_dateChange} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>
                                        <Form.Item
                                            label={t("End date")}
                                            name="end"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={onEnd_dateChange} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>

                                {/* </FormControl> */}
                                <GridContainer justify="flex-end">
                                    <GridItem>
                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            handleClose();
                                                        }}
                                                    >
                                                        {t("Cancel")}
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t("Save")}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                            </Form>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>

            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
                handleErrorText={handleErrorText}
            />
        </div>
    );
}
