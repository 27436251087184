import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'antd';
import Card from "components/Card/Card"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import { greyColor } from 'assets/jss/material-dashboard-pro-react';
import { Link } from "react-router-dom"

export default function AdminPage() {
    return (
        <>
            <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>Administrator Tools</p>
            {/* <Breadcrumb>
                <Breadcrumb.Item><p style={{ fontSize: "12px" }}>Administrator Tools</p></Breadcrumb.Item>
            </Breadcrumb> */}
            <br />
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}><div style={{ backgroundColor: "white", padding: 10 }}><p style={{ fontSize: "16px", fontWeight: 'bold', padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>Security</p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin"}>Privileges </Link> </p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin"}>Session Fingerprint Settings</Link> </p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin"}>Password Settings</Link> </p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin"}>Account Lock Settings</Link> </p>
                </div>
                </GridItem >
                <GridItem xs={12} sm={6} md={6}><div style={{ backgroundColor: "white", padding: 10 }}><p style={{ fontSize: "16px", fontWeight: 'bold', padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>Data Integration</p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin"}>Pull course data</Link> </p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin/LMSData/pull"}>Pull LMS data</Link> </p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin/LMSData/push"}>Push data</Link> </p>
                    <p style={{ fontSize: "12px", fontWeight: 'bold', padding: 5, paddingLeft: 10 }}> <Link to={"/citrine/admin/LMSData/map"}>Goals mapping</Link> </p>
                </div>
                </GridItem>

            </GridContainer >

        </>
    );
}