// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchAreas } from "reduxToolkit/features/area/AreaSpecSlice";
import { addNewModeOfInstructionConfig, fetchModeOfInstructionConfigById, ModeOfInstructionConfigUpdate } from '../../../../reduxToolkit/features/modeOfInstructionConfig/ModeOfInstructionConfigSlice';

const useStyles = makeStyles(styles);

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
// const classes = useStyles();
export default function CreateModeOfInstructionConfigPage({ match }) {
  const { t } = useTranslation();
  const { id } = match.params

  console.log('id', id)

  const isAddMode = isNaN(id);
  console.log('id from match is add mode', isAddMode)
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);

  const titleClasses = useTitleStyles();
  const [name, setName] = useState('')

  const [areaSpec_ids, setArea_ids] = useState([])
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const onNameChanged = (e) => setName(e.target.value)

  const onArea_idsChanged = (e) => setArea_ids(e.target.value)

  const areasStatus = useSelector((state) => state.areaSpec.areasStatus)
  const dispatch = useDispatch()
  useEffect(() => {

    if (areasStatus === 'idle') {
      dispatch(fetchAreas())
    }
  }, [areasStatus, dispatch]
  )
  const areas = useSelector((state) => state.areaSpec.areas)
  console.log('areas', areas)
  const canSave =
    [name, areaSpec_ids].every(Boolean) && addRequestStatus === 'idle'
  const history = useHistory();

  const onSaveClicked = async () => {

    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onsave')
      const resultAction = await dispatch(
        addNewModeOfInstructionConfig({ name, areaSpec_ids })
      )
      unwrapResult(resultAction)
      setName('')


      setArea_ids('')
      history.push('/citrine/modeOfInstructionConfigs');
    } catch (err) {
      console.error('Failed to save the modeOfInstructionConfig: ', err)
    } finally {
      setAddRequestStatus('idle')
    }


  }

  const onUpdateClicked = async () => {

    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onsave')
      const resultAction = await dispatch(
        ModeOfInstructionConfigUpdate({ id: id, modified: { name, areaSpec_ids } })
      )
      unwrapResult(resultAction)
      setName('')


      setArea_ids('')
      history.push('/citrine/modeOfInstructionConfigs');
    } catch (err) {
      console.error('Failed to save the modeOfInstructionConfig: ', err)
    } finally {
      setAddRequestStatus('idle')
    }


  }
  function onChangeArea_ids(value) {
    console.log(`selected ${value}`);
    setArea_ids(value);
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchModeOfInstructionConfigById(id))
    }
  }, [id]);

  const mode = useSelector((state) => state.ModeOfInstructionConfig.ModeOfInstructionConfig)
  console.log('mode ', mode)

  useEffect(() => {
    if (!isAddMode) {
      setName(mode && mode.name)
      setArea_ids(mode && mode.areaSpec_ids)

    }
  }, [mode, dispatch]);


  return (

    <div>
      {isAddMode &&
        <div>
          <div>
            <GridItem>
              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}  > {t('Course Configurations')}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/ModeOfInstructionConfigs`} style={{ color: "orange" }} >{t('Mode Of Instruction Config List')}
                  </Link>
                </Breadcrumb.Item>

              </Breadcrumb>
            </GridItem>
            <GridContainer>

              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {t('Create')}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <GridItem xs={6} sm={6} md={6}>


                          <CustomInput
                            labelText={t('Name ')}
                            id="name"
                            //  onChange={onNameChanged}
                            type="text"
                            name="name"
                            value={name}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a name!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: event => {
                                setName(event.target.value);
                              }
                            }}
                          />

                          <label>{t('Area ')} </label>
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            size={200}

                            optionFilterProp="children"
                            name="area_ids"
                            //  value={areaSpec_ids}
                            onChange={onChangeArea_ids}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >

                            {areas.map(r => (
                              <option key={r.id} value={r.id}>
                                {r.name}
                              </option>
                            ))

                            }


                          </Select>
                        </GridItem>
                        <GridItem>
                          <Button color="info"
                            className={classes.updateProfileButton}
                            onClick={onSaveClicked}
                          >
                            {t(' Save')}
                          </Button></GridItem>


                      </GridContainer>
                    </form>

                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      }
      {!isAddMode && mode &&
        <div>

          <div>
            <GridItem>
              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}  > {t('Course Configurations')}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/ModeOfInstructionConfigs`} style={{ color: "orange" }} >{t('Mode Of Instruction Config List')}
                  </Link>
                </Breadcrumb.Item>

              </Breadcrumb>
            </GridItem>
            <GridContainer>

              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {t('Edit')}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <GridItem xs={6} sm={6} md={6}>


                          <CustomInput
                            labelText={t('Name ')}
                            id="name"
                            //  onChange={onNameChanged}
                            type="text"
                            name="name"
                            value={name}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a name!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: event => {
                                setName(event.target.value);
                              },
                              value: name
                            }}
                          />

                          <label>{t('Area  ')}</label>
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            size={200}

                            optionFilterProp="children"
                            name="area_ids"
                            value={areaSpec_ids}
                            onChange={onChangeArea_ids}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >

                            {areas.map(r => (
                              <option key={r.id} value={r.id}>
                                {r.name}
                              </option>
                            ))

                            }


                          </Select>
                        </GridItem>
                        <GridItem>
                          <Button color="info"
                            className={classes.updateProfileButton}
                            onClick={onUpdateClicked}
                          >
                            {t('Update')}
                          </Button></GridItem>


                      </GridContainer>
                    </form>

                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

          </div>

        </div>
      }
    </div>
  );



}


