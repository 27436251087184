import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  peosStatus: "idle",
  peos: [],
  AllPEOs: [],
  ProgramPeos: [],
  peoStatus: "idle",
  peo: null,
  error: null,
  GraphPeo: null,
};



export const rerunPEOsScores = createAsyncThunk('peo/rerunPEOsScores', async (params, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(BaseUrl + `/peo/run_peo/`, params,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchGraphPeo = createAsyncThunk(
  "peo/fetchGraphPeo",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/program/${id}/graphic/peo/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    console.log(response.data, "response.data fetchGraphPeo");

    return response.data.data_peo;
  }
);
export const fetchPeos = createAsyncThunk("peo/fetchPeos", async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + "/peo/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
});

export const fetchAllPEOs = createAsyncThunk("peo/fetchAllPEOs", async (id) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + `/peo/all`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  return response.data;
});
export const fetchPeosByProgramId = createAsyncThunk(
  "peo/fetchPeosByProgramId",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/peo/?program_id=${id}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  }
);
export const fetchPeoById = createAsyncThunk("peo/fetchPeoById", async (id) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + `/peo/${id}/`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  // console.log(response.data,'response.data')

  return response.data;
});

export const addNewPeo = createAsyncThunk(
  "peo/addNewPeo",
  async (initialPeo) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(BaseUrl + `/peo/create/`, initialPeo, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const fetchScorePeo = createAsyncThunk(
  "peo/fetchScorePeo",
  async (initialPeo) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(BaseUrl + `/peo/score/`, initialPeo, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const updatePeo = createAsyncThunk(
  "program/updatePeo",
  async (data, thunkAPI) => {
    console.log(data, "UpdatedPeo");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/peo/${data.id}/edit/`,
        data.updatedPeo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedpeo");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the courseReport: ", error);
    }
  }
);

export const updatePeoPlanning = createAsyncThunk(
  "peo/updatePeoPlanning",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/peo/${data.id}/update/planning/`,
        data.updated,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deletePeo = createAsyncThunk(
  "peo/deletePeo",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(BaseUrl + `/peo/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

const peosSlice = createSlice({
  name: "peo",
  initialState,
  extraReducers: {
    [fetchGraphPeo.pending]: (state, action) => {
      state.peoStatus = "loading";
    },
    [fetchGraphPeo.fulfilled]: (state, action) => {
      state.peoStatus = "succeeded";
      state.GraphPeo = action.payload;
    },
    [fetchGraphPeo.rejected]: (state, action) => {
      state.peoStatus = "failed";
      state.error = action.payload;
    },
    [fetchPeos.pending]: (state, action) => {
      state.peosStatus = "loading";
    },
    [fetchPeos.fulfilled]: (state, action) => {
      state.peosStatus = "succeeded";
      // Add any fetched posts to the array
      state.peos = action.payload;
    },
    [fetchPeos.rejected]: (state, action) => {
      state.peosStatus = "failed";
      state.error = action.payload;
    },

    [fetchAllPEOs.pending]: (state, action) => {
      state.peosStatus = "loading";
    },
    [fetchAllPEOs.fulfilled]: (state, action) => {
      state.peosStatus = "succeeded";
      // Add any fetched posts to the array
      state.AllPEOs = action.payload;
    },
    [fetchAllPEOs.rejected]: (state, action) => {
      state.peosStatus = "failed";
      state.error = action.payload;
    },

    [fetchPeosByProgramId.pending]: (state, action) => {
      state.peosStatus = "loading";
    },
    [fetchPeosByProgramId.fulfilled]: (state, action) => {
      state.peosStatus = "succeeded";
      // Add any fetched posts to the array
      state.ProgramPeos = action.payload;
    },
    [fetchPeosByProgramId.rejected]: (state, action) => {
      state.peosStatus = "failed";
      state.error = action.payload;
    },

    [fetchPeoById.pending]: (state, action) => {
      state.peoStatus = "loading";
    },
    [fetchPeoById.fulfilled]: (state, action) => {
      state.peoStatus = "succeeded";

      // Add any fetched posts to the array
      state.peo = action.payload;
    },
    [fetchPeoById.rejected]: (state, action) => {
      state.peoStatus = "failed";
      state.error = action.payload;
    },

    [addNewPeo.fulfilled]: (state, action) => {
      state.peos.push(action.payload);
    },

    // [deletePeo.fulfilled]: (state, action) => {
    //   // console.log(action.payload)
    // },

    //       [updateProgram.fulfilled]: (state, action) => {
    //         const result = state.programs.filter(program => program.id !== action.payload.id);
    //         // console.log("program id from filter from slice",program.id)
    //         result.push(action.payload)
    //         state.programs = result
    //         // console.log("action.payload.id from slice",action.payload.id)

    //       },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload;
    },
  },
});

// export const { programAdded, programUpdated, getId } = programsSlice.actions

export default peosSlice.reducer;

export const selectAllPeos = (state) => state.peo.peos;
