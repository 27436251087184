// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
import { Alert, Breadcrumb, Select, Space, notification } from "antd";

import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link
} from "react-router-dom";

import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";
import { fetchCampaigns, fetchCampaignsInstactor } from '../../../reduxToolkit/features/campaign/CampaignsSlice';
import ErrorModal from "../../Components/ErrorModal";









const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const { Option } = Select;
const CampaignExcerpt = ({ campaign }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const percentage = campaign && campaign.closed_survey && campaign.closed_survey.response_rate;

 
  return (
    <GridItem xs={12} sm={12} md={3}>
      <Link to={`/citrine/surveys/studentsSurvey/${campaign.id}`}>
        <Card chart className={classes.cardHover}>
          <CardHeader color="grey" >
            <div style={{ width: 150, margin: '0 auto' }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                strokeWidth={5}
              />
            </div>
          </CardHeader>
          <CardBody>

            {/* <h4 className={classes.cardTitle}>   {campaign.resource}</h4>
            <p className={classes.cardCategory}> */}
            <h4><strong>{campaign && campaign.type_details} - {campaign && campaign.resource_name} </strong></h4>
            <h5><strong>{campaign && campaign.academic_year} -{campaign && campaign.semester} </strong></h5>
            {/* </p> */}

            <p className={classes.cardCategory}>
              {t("Created at")}: {campaign.creation_date}
            </p>
          </CardBody>
          <CardFooter chart>
            <div className={classes.stats}>
              <AccessTime /> {campaign && campaign.closed_survey && campaign.closed_survey.state}
            </div>
          </CardFooter>
        </Card>
      </Link>
    </GridItem>
  )
}

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 30,
    span: 26,
  },
};
export default function StaffSurveyCampaignList() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("")
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const dispatch = useDispatch()
  const semester_id = localStorage.getItem('semester_id');
  const campaignsStatus = useSelector(state => state.campaign.campaignsStatus)

  useEffect(() => {

    dispatch(fetchCampaigns());

  }, [ dispatch])

  useEffect(()=>{
    dispatch(fetchCampaignsInstactor())
   
  },[])


  const campaigns = useSelector(state => state.campaign.campaigns)
  const campaignsInstactor = useSelector(state => state.campaign.campaignsInstactor)
  console.log(campaignsInstactor)
  const campaignsByUserAndSemesterProgram = useSelector(state => state.campaign.campaignsByUserAndSemesterProgram)
  //const [campaigns, setCampaigns] = useState([])
  const error = useSelector(state => state.campaign.error)
  // useEffect(() => {
  //   if (campaignsByUserAndSemesterCourse || campaignsByUserAndSemesterProgram === "You do not have permission") { setCampaigns("You do not have permission"); }
  //   else {
  //     setCampaigns([...campaignsByUserAndSemesterCourse, ...campaignsByUserAndSemesterProgram]);
  //   }

  // }, [
  //   campaignsByUserAndSemesterCourse, campaignsByUserAndSemesterProgram, dispatch])



  let content

  if (campaignsStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (campaignsStatus === 'succeeded') {

    if (campaigns === "You do not have permission") {


      content = <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        <Alert
          message=''
          description={`${campaigns} to access surveys list`}
          type="error"
          closable

        />
      </Space>


    }
    else {
      if (campaignsInstactor && campaignsInstactor.length) {
        content = campaignsInstactor && campaignsInstactor.filter((campaign) => {
          if (search === '') {
            return campaign
          }
          // else if(campaign.name.toLowerCase().includes(search.toLowerCase())){
          // return campaign
          // }
        }).map(campaign => (
          <CampaignExcerpt key={campaign.id} campaign={campaign} />
        ))
      }
      else if ((campaignsInstactor && !campaignsInstactor.length)) {
        content = <div> No items found ! </div>
      }
    }
  }
  //  else if (campaignsStatus === 'failed') {
  //     content = <div>no items founded</div>
  //   }
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  return (
    <div>
      <GridContainer
        alignItems="center"
        style={{
          borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
        }}>

        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/surveys/studentsSurvey`}>
                {t("Internal Survey")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>



      </GridContainer>

      <GridContainer>
        {content}

      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
