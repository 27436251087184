// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Breadcrumb, Form, Input, message, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import { grayColor, infoColor } from "assets/jss/material-dashboard-pro-react";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// @material-ui/icons
import {
  addCourseSyllabus, fetchCourseById
} from "../../../../../reduxToolkit/Course/coursesSpecSlice";
import ErrorModal from "../../../../Components/ErrorModal";
import {fetchYearSemesters} from "../../../../../reduxToolkit/features/yearSemester/YearSemesterSlice";

const useStyles = makeStyles(styles);

export default function CourseSyllabus({ match }) {

  const { id } = match.params;

  const yearSemestersStatus = useSelector((state) => state.yearSemesters.yearSemestersStatus);
  const yearSemesters = useSelector((state) => state.yearSemesters.yearSemesters);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [yearSemesterConfig_id, setYearSemesterConfig_id] = useState("");

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (yearSemestersStatus === "idle") {
      dispatch(fetchYearSemesters());
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCourseById(id))
        .unwrap()
        .then((course) => {
          form.setFieldsValue({
            office_hours: course.office_hours,
            office: course.office,
            student_academic_counseling_and_support:
            course.student_academic_counseling_and_support,
            students_development_and_participation:
            course.students_development_and_participation,
            decision_making: course.decision_making,
            course_schedule: course.course_schedule,
            welcoming_message: course.welcoming_message,
            faculty_availability: course.faculty_availability,
            course_resources_and_interactions:
            course.course_resources_and_interactions,
            class_participation: course.class_participation,
            additional_recommended_readings:
            course.additional_recommended_readings,
            assessment_strategy: course.assessment_strategy,
            delivery_of_assessment: course.delivery_of_assessment,
            evaluation_of_assessment: course.evaluation_of_assessment,
            academic_integrity: course.academic_integrity,
            submission_of_assessment: course.submission_of_assessment,
            assessment_details_and_rubics: course.assessment_details_and_rubics,
            follow_up: course.follow_up,
            students_attendance: course.students_attendance,
            number_of_days: course.number_of_days,
            number_of_hours: course.number_of_hours,
            yearSemesterConfig_id: course.yearSemesterConfig_id,
          });
        });
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Submit failed!");
  };

  const onFinish = async (values) => {
    let data;
    // if (values.prerequisites_ids && values.prerequisites_ids.length && values.co_requisites_ids && values.co_requisites_ids.length) {
    data = { syllabus: values, id };
    const resultAction = await dispatch(addCourseSyllabus(data));
    if (addCourseSyllabus.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Added successfully`);
      history.push("/citrine/CourseSpecificationList");
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                style={{ color: "orange" }}
                to={`/citrine/CourseSpecificationList/`}
              >
                {t("Courses List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ prerequisites_ids: [], co_requisites_ids: [] }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <LibraryBooksIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Add course syllabus</h4>
              </CardHeader>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Office Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <Form.Item
                      label={t("Office ")}
                      name="office"
                      rules={[
                        {
                          required: false,
                          message: "Please input the course's office",
                        },
                      ]}
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t("Office hours")}
                      name="office_hours"
                      rules={[
                        {
                          required: false,
                          message: "Please input the course's office hours",
                        },
                      ]}
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Syllabus Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Year / Semester")}
                      name="yearSemesterConfig_id"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the course's student academic counseling and support",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {yearSemesters &&
                          yearSemesters.map((c) => (
                            <Option key={c.id} value={c.id}>
                              {c.libelle}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Student academic counseling and support")}
                      name="student_academic_counseling_and_support"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the course's student academic counseling and support",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Student development and participation")}
                      name="students_development_and_participation"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the course's student development and participation",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Student Attendance")}
                      name="students_attendance"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the course's student attendance",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Course schedule ")}
                      name="course_schedule"
                      rules={[
                        {
                          required: false,
                          message: "Please input the course's Course schedule",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Welcoming message ")}
                      name="welcoming_message"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the course's Welcoming message",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Faculty availability")}
                      name="faculty_availability"
                      rules={[
                        {
                          required: false,
                          message: "Please input the  Faculty availability",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Course resources and interactions")}
                      name="course_resources_and_interactions"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the Course resources and interactions",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Class participation")}
                      name="class_participation"
                      rules={[
                        {
                          required: false,
                          message: "Please input the Class participation",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Additional recommended readings")}
                      name="additional_recommended_readings"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the Additional recommended readings",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Assessment strategy")}
                      name="assessment_strategy"
                      rules={[
                        {
                          required: false,
                          message: "Please input the Assessment strategy",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Assessment details and rubrics")}
                      name="assessment_details_and_rubics"
                      rules={[
                        {
                          required: false,
                          message: "Please input the Assessment strategy",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Delivery of assessment")}
                      name="delivery_of_assessment"
                      rules={[
                        {
                          required: false,
                          message: "Please input the Assessment strategy",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Evaluation of assessment")}
                      name="evaluation_of_assessment"
                      rules={[
                        {
                          required: false,
                          message: "Please input the Evaluation of assessment",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Academic integrity ")}
                      name="academic_integrity"
                      rules={[
                        {
                          required: false,
                          message: "Please input the Academic integrity ",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Submission of assessment")}
                      name="submission_of_assessment"
                      rules={[
                        {
                          required: false,
                          message: "Please input the Grading ",
                        },
                      ]}
                    >
                      <TextArea autoSize />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
