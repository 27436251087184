import React, { lazy, Suspense, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
// material ui icons
import MailOutline from '@material-ui/icons/MailOutline'
import { unwrapResult } from '@reduxjs/toolkit'
//import { Input } from 'antd'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  fetchSurveyTemplates,
  fetchSurveyTemplateById,
  updateSurveyTemplate,
} from '../../../reduxToolkit/features/Survey/SurveySlice'
//import { Breadcrumb, notification } from 'antd'
import LazyLoad from 'react-lazyload'
import ErrorModal from '../../Components/ErrorModal'
//import ReactJson from 'react-json-view'

import './SurveuEditor.css'

import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

import {
  Breadcrumb,
  Form,
  Input,
  notification,
  Select,
  Space,
  InputNumber,
} from 'antd'

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
}

const ReactJson = lazy(() => import('react-json-view'))
const useStyles = makeStyles(styles)
const useTitleStyles = makeStyles(titleStyles)
const { TextArea } = Input
const { Option } = Select
export default function SurveyTemplateEditor({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const history = useHistory()
  const [stringifiedTemplate, setStringifiedTemplate] = useState('')
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [test, setTest] = useState({ text: 'testing' })
  const [disabledTest, setDisabledTest] = useState(true)

  const dispatch = useDispatch()
  useEffect(() => {
    setDisabledTest(true)
    dispatch(fetchSurveyTemplateById(id))
      .then(unwrapResult)
      .then((template) => {
        setStringifiedTemplate(JSON.parse(template.template))
        setType(template.type)
        setName(template.name)
      })
  }, [dispatch])

  const surveyTemplate = useSelector((state) => state.survey.surveyTemplate)
  const [form] = Form.useForm()
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const classes = useStyles()
  const titleClasses = useTitleStyles()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [disableEditButton, setDisableEditButton] = useState(false)
  const [theme, setTheme] = useState('monokai')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const canSave =
    [stringifiedTemplate, type, name].every(Boolean) &&
    addRequestStatus === 'idle'

  const onSaveTemplateClicked = async () => {
    const template = JSON.parse(stringifiedTemplate)
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(
          updateSurveyTemplate({
            id: id,
            updatedTemplate: { template, type, name },
          }),
        )

        unwrapResult(resultAction)
      } catch (err) {
        console.log('Failed to save the template: ', err)
      } finally {
        setAddRequestStatus('idle')
      }
      // dispatch(fetchSurveyTemplates())
      // console.log("stringifiedTemplate", stringifiedTemplate)
      // console.log("parsedTemplate", template)
    }
  }
  const updateSurvey = () => {
    console.log({
      id,
      updatedTemplate: { template: stringifiedTemplate, type, name },
    })
    dispatch(
      updateSurveyTemplate({
        id,
        updatedTemplate: { template: stringifiedTemplate, type, name },
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(fetchSurveyTemplates())
        openNotificationWithIcon('success', `Updated successfully`)
        //history.push('/citrine/surveyTemplates')
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }
  const editSurvey = ({ updated_src }) => {
    setStringifiedTemplate(updated_src)
  }
  const addSurvey = ({ updated_src }) => {
    setStringifiedTemplate(updated_src)
  }
  const deleteSurvey = ({ updated_src }) => {
    setStringifiedTemplate(updated_src)
  }
  const onHandleChangeTheme = (e) => {
    setTheme(e.target.value)
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/surveyTemplates`}>{t('Survey templates')}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>{surveyTemplate?.name}</Breadcrumb.Item>

          <Breadcrumb.Item>{t('edit')}</Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CardIcon color="primary">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t('Update Survey Template')}
                </h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <label style={{ color: 'RGB(60, 72, 88)' }}>Theme : </label>

                <select
                  onChange={onHandleChangeTheme}
                  style={{
                    backgroundColor: '#EEEEEE',
                    color: 'gray',
                    border: '1px solid black',
                    borderRadius: '8px',
                    padding: '10px',
                    width: '200px',
                  }}
                >
                  <option value="monokai">Default</option>
                  <option value="rjv-default">rjv-default</option>
                  {/* <option value="paraiso">Paraiso</option>
                  <option value="pop">Pop</option>
                  <option value="railscasts">Railscasts</option> */}
                </select>
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>
                {/* <Button onClick={updateSurvey} type="submit" color="primary">
                  {t('Edit')}
                </Button> */}
                <DialogEditSurvey
                  addSurvey={addSurvey}
                  editSurvey={editSurvey}
                  deleteSurvey={deleteSurvey}
                  stringifiedTemplate={stringifiedTemplate}
                  updateSurvey={updateSurvey}
                  disableEditButton={disableEditButton}
                  setDisableEditButton={setDisableEditButton}
                />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
              <div className="suspense-container">
                <Suspense fallback={<div>Loading Template ...</div>}>
                  <LazyLoad>
                    {stringifiedTemplate && (
                      <ReactJson
                        src={stringifiedTemplate}
                        theme={theme}
                        displayDataTypes={false}
                        name="Template"
                      />
                    )}
                  </LazyLoad>
                </Suspense>
              </div>
            </div>
          </CardBody>
          {/* <CardBody>
              <Suspense fallback={<div>Loading Template ...</div>}>
                {stringifiedTemplate && (
                  <LazyLoad>
                    <ReactJson
                      src={stringifiedTemplate}
                      theme="monokai"
                      displayDataTypes={false}
                      onDelete={deleteSurvey}
                      onAdd={addSurvey}
                      onEdit={editSurvey}
                      name="Template"
                    />
                  </LazyLoad>
                )}
              </Suspense>
              <GridContainer
                style={{
                  position: 'sticky',
                  bottom: 0,
                  padding: '10px',
                  backgroundColor: '#f1f1f1',
                }}
                justify="flex-end"
              >
                <Button onClick={updateSurvey} type="submit" color="primary">
                  {t('Edit')}
                </Button>
              </GridContainer>
            </CardBody> */}
        </Card>
      </GridItem>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </GridContainer>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const DialogEditSurvey = ({
  addSurvey,
  editSurvey,
  deleteSurvey,
  stringifiedTemplate,
  updateSurvey,
  disableEditButton,
  setDisableEditButton,
}) => {
  //console.log(stringifiedTemplate)
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [jsonTemplate, setJsonTemplate] = useState()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: 'relative' }} className={classes.appBar}>
          <Toolbar>
            <GridContainer>
              <GridItem xs={12} sm={12} md={1}>
                <Button
                  disabled={disableEditButton}
                  autoFocus
                  color="primary"
                  onClick={() => {
                    //handleClose()
                    setDisableEditButton(!disableEditButton)
                    updateSurvey()
                  }}
                >
                  Save
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>
                <IconButton
                  color="inherit"
                  onClick={() => history.push('/citrine/surveyTemplates')}
                  aria-label="close"
                >
                  close
                </IconButton>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}></GridItem>
              <GridItem xs={12} sm={12} md={1}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </GridItem>
            </GridContainer>
          </Toolbar>
        </AppBar>
        <List>
          <Divider />

          <div
            style={{ marginTop: '-5px', maxHeight: '670px', overflowY: 'auto' }}
          >
            <div className="suspense-container">
              <Suspense fallback={<div>Loading Template ...</div>}>
                <LazyLoad>
                  {stringifiedTemplate && (
                    <ReactJson
                      src={stringifiedTemplate}
                      theme="monokai"
                      displayDataTypes={false}
                      onDelete={deleteSurvey}
                      onAdd={addSurvey}
                      onEdit={editSurvey}
                      name="Template"
                    />
                  )}
                </LazyLoad>
              </Suspense>
            </div>
          </div>
        </List>
      </Dialog>
    </div>
  )
}
