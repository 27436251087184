import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import ListItemText from "@material-ui/core/ListItemText";

import {
  fetchNotAffectedPLOsToGA,
  addNewGA_PLOMapping,
} from "../../../../reduxToolkit/features/GraduateAttribute/GA_PLOMappingSlice.jsx";

import {
  deleteGraduateAttributeSpec,
  fetchGraduateAttributesSpec,
} from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";

import { useTranslation } from "react-i18next";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Form, Input, message, Select, notification } from "antd";

export default function GA_PLOMapModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [PLOs, setPLOs] = React.useState([]); 
  const [ploSpec_ids, setPloSpec_ids] = React.useState([]); 
  const ids = { GAId: props.GA.id, programId: props.programId };

  useEffect(() => {
    dispatch(fetchNotAffectedPLOsToGA(ids))
      .unwrap()
      .then((PLOs) => {
        // handle result here
        setPLOs(PLOs);
      });
  }, [dispatch]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const { Option } = Select;

  const graduateAttributeSpec_id = props.GA.id;

  const onFinish = async (values) => {
    console.log("values", values);
    let toAdd = { ...values, graduateAttributeSpec_id };

    const resultAction = await dispatch(addNewGA_PLOMapping(toAdd));

    if (addNewGA_PLOMapping.fulfilled.match(resultAction)) {
      console.log("success", `Affected successfully`);
      dispatch(fetchNotAffectedPLOsToGA(ids));
      dispatch(fetchGraduateAttributesSpec(props.programId));
      openNotificationWithIcon("success", `successfully Added `);
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <MenuItem
        onClick={() => {
          handleClickOpen();
          props.handleDropDownButtonClose();
        }}
      >
        <ListItemText primary="Add PLO" />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">{t("Add PLO")}</DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <GridContainer>
              <GridItem xs={6} sm={6} md={12}>
                <Form.Item
                  label={t("Select  PLOs *")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name="ploSpec_ids"
                >
                  <Select
                    mode="multiple"
                    showSearch
                    style={{ width: "100%", zIndex: 5 }}
                    dropdownStyle={{ zIndex: 2000 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {PLOs &&
                      PLOs.map((plo) => (
                        <Option value={plo.id}>{`${plo.name}`}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
            </GridContainer>

            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="primary">
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
