import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
    TeachingLoadList: [],
    TeachingLoadsStatus: "idle",
    TeachingLoadStatus: "idle",
  error: null,
 
  id: null,
};

export const fetchTeachingLoadList = createAsyncThunk(
  "TeachingLoad/fetchTeachingLoadList",
  async (person_id, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/teachingLoadFaculty/get/?staff_id=${person_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateTeachingLoad = createAsyncThunk(
    "TeachingLoad/updateTeachingLoad",
    async (data, { rejectWithValue }) => {
      console.log(data, "data");
      try {
        const token = localStorage.getItem("token");
        const response = await axios.put(
          BaseUrl + `/teachingLoadFaculty/${data.id}/edit/`,
          data.updated,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
  
        return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );




const TeachingLoadSlice = createSlice({
  name: "TeachingLoad",
  initialState,
  extraReducers: {
    [fetchTeachingLoadList.pending]: (state, action) => {
      state.TeachingLoadsStatus = "loading";
    },
    [fetchTeachingLoadList.fulfilled]: (state, action) => {
      state.TeachingLoadsStatus = "succeeded";
      state.TeachingLoadList = action.payload;
    },
    [fetchTeachingLoadList.rejected]: (state, action) => {
      state.TeachingLoadsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [updateTeachingLoad.fulfilled]: (state, action) => {
      
    },
    [updateTeachingLoad.rejected]: (state, action) => {
          if (action.payload) {
              state.error = action.payload
            } else {
              state.error = action.error
            }
      },

  },
});
export default TeachingLoadSlice.reducer;
