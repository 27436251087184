// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
//import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/assessmentMethodSlice";
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";
import {
  fetchStaff,
  fetchStaffById,

} from "reduxToolkit/features/user/UserSlice";
import {
  addNewCourseContact,
  CourseContactUpdate,
  fetchCourseContactById,
  fetchCourseContacts
} from "../../../../../reduxToolkit/features/CourseContact/CourseContactSlice";
import ErrorModal from "../../../../Components/ErrorModal";

const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

// const classes = useStyles();
export default function CreateCourseContact({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(fetchStaff());
  }, [dispatch]);

  const staffStatus = useSelector((state) => state.user.staffStatus);

  const staffList = useSelector((state) => state.user.staffList);

  const [coordinator_id, setCoordinator_id] = React.useState("");

  const titleClasses = useTitleStyles();
  const { id } = match.params;
  const { idCC } = match.params;
  const isAddMode = isNaN(idCC);
  const [lms_contact, setLms_contact] = useState("");
  const [tech_contact, settech_contact] = useState("");
  const [courseSpec_id, setCourseSpec_id] = useState(id);
  const [coordinator_email, setCoordinator_email] = useState("");
  const [coordinator_phone, setCoordinator_phone] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const history = useHistory();

  const { Option } = Select;

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewCourseContact({ ...values, courseSpec_id })
    );
    if (addNewCourseContact.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchCourseContacts(course?.id));
      history.push(`/citrine/specification/course/${id}/CourseContacts`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      CourseContactUpdate({
        id: idCC,
        modified: {
          ...values,
          courseSpec_id
        },
      })
    );
    if (CourseContactUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchCourseContacts(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/CourseContacts`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchCourseContactById(idCC))
        .then(unwrapResult)
        .then((CC) => {
          console.log(CC, "CC");
          dispatch(fetchStaffById(CC.coordinator_id))
            .then(unwrapResult)
            .then((user) => {
              form.setFieldsValue({
                coordinator_id: CC.coordinator_id,
                courseSpec_id: CC.courseSpec_id,
                coordinator_email: user.person_details[0].email,
                coordinator_phone: user.person_details[0].phone_number,
              });
            });
          // form.setFieldsValue({
          //   coordinator_id: CC.coordinator_id,

          //   lms_contact: CC.lms_contact,
          //   tech_contact: CC.tech_contact,
          //   courseSpec_id: CC.courseSpec_id,
          //});
        });
    }
  }, [idCC]);

  const res = useSelector((state) => state.CourseContact.CourseContact);
  console.log("res ", res);

  const onChangeCoordinator = async (value) => {
    setCoordinator_id(value);
    console.log("value", value);
    setCoordinator_email(true);
    dispatch(fetchStaffById(value))
      .then(unwrapResult)
      .then((staff) => {
        form.setFieldsValue({
          coordinator_email:
            staff &&
            staff.person_details &&
            staff.person_details[0] &&
            staff.person_details[0].email,
          coordinator_phone:
            staff &&
            staff.person_details &&
            staff.person_details[0] &&
            staff.person_details[0].phone_number,
        });
      });
  };

  function onBlur() { }

  function onFocus() { }

  function onSearch(val) { }
  const courseStatus = useSelector((state) => state.course.courseStatus);

  const handleCourse_id = (id) => {
    setCourseSpec_id(id);
    dispatch(fetchCourseById(id));
  };

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">


                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/CourseContacts`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Course Contact List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="coordinator_id"
                        label="Coordinator"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Coordinator"
                          allowClear
                          onChange={onChangeCoordinator}
                          optionFilterProp="children"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {staffList &&
                            staffList.map((staff) => (
                              <Option value={staff && staff.id}>
                                {` ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].first_name
                                  } 
                          ${" "}
                          ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].last_name
                                  }`}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="coordinator_email"
                        label="Coordinator Email"
                        rules={[
                          {
                            disable: true,
                            required: false,
                          },
                        ]}
                      >
                        <Input disabled={true} style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="coordinator_phone"
                        label="Coordinator Phone"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input disabled={true} style={{ width: "100%" }} />
                      </Form.Item>
                      {/*
                      <Form.Item
                        name="lms_contact"
                        label="LMS support contact"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="tech_contact"
                        label="General technical support contact"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item> */}

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/CourseContacts`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">


                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/CourseContacts`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Course Contact List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="coordinator_id"
                        label="Coordinator"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Coordinator"
                          allowClear
                          onChange={onChangeCoordinator}
                        >
                          {staffList &&
                            staffList.map((staff) => (
                              <Option value={staff && staff.id}>
                                {` ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].first_name
                                  } 
                          ${" "}
                          ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].last_name
                                  }`}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="coordinator_email"
                        label="Coordinator Email"
                        rules={[
                          {
                            disable: true,
                            required: false,
                          },
                        ]}
                      >
                        <Input disabled={true} style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="coordinator_phone"
                        label="Coordinator Phone"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input disabled={true} style={{ width: "100%" }} />
                      </Form.Item>

                      {/* <Form.Item
                        name="lms_contact"
                        label="LMS support contact"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="tech_contact"
                        label="General technical support contact"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item> */}

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/CourseContacts`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
