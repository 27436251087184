import React, { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import { UserOutlined } from "@ant-design/icons";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Avatar, Breadcrumb, notification, Popconfirm } from "antd";
// core components
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  deletePerson,
  fetchPersons
} from "reduxToolkit/features/person/PersonsSlice";
import DropDownPersonList from "views/Components/Access control/Person/DropDownPersonList";

export default function GroupDetails({ match }) {

  const { t } = useTranslation();
  const useStyles = makeStyles(styles);
  const dispatch = useDispatch();
  const [ping,setPing]=useState(false)

  useEffect(() => {
    dispatch(fetchPersons());
  }, [ping]);

  const persons = useSelector((state) => state.person.persons);

  const classes = useStyles();

  const [selectedItemId, setSelectedItemId] = useState([]);
  const [color, setColor] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [personToDelete, setPersonToDelete] = useState("");

  const columns = [
    {
      field: "avatar",
      headerName: "Avatar",
      width: 70,
      renderCell: (cellValues) => {
        if (cellValues.value === null) {
          return <Avatar icon={<UserOutlined />}></Avatar>;
        } else {
          return <Avatar  src={cellValues.value}></Avatar>;
        }
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "responsible",
      headerName: "Responsible",
      width: 400,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },

    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "address",
      headerName: "Adress",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      type: "actions",
      cellClassName: "actions",
      renderCell: (cellValues) => {
        return (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteClick(cellValues.id)}
            okButtonProps={{
              style: { backgroundColor: "#8F6682", borderColor: "#8F6682" },
            }}
            cancelButtonProps={{
              style: { color: color, borderColor: borderColor },
              onMouseEnter: () => {
                setColor("#8f6682");
                setBorderColor("#8f6682");
              },
              onMouseLeave: () => {
                setColor("");
                setBorderColor("");
              },
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={() => setPersonToDelete(`${cellValues.row.first_name} ${cellValues.row.last_name}`)}
            >
              <DeleteIcon size="medium" />
            </IconButton>
          </Popconfirm>
        );
      },
    },
  ];

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 5,
      zIndex: 1000,
    });
  };

  const handleDeleteClick = (id) => {
    dispatch(deletePerson(id)).then(() => {
      openNotificationWithIcon("success", `${personToDelete} was deleted successfully`);
      dispatch(fetchPersons());
    });
  };

  const handleToggle = (value) => {
    setSelectedItemId(value);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={11}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/configurations`}>{t("Configurations")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("Access Control")}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/persons/`}>{t("Persons List")}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridItem xs={12} sm={12} md={1}>
        <Link to={`/citrine/addEditPerson/:id`}>
          <Button width="2px" color="primary" className={classes.marginLeft}>
            {t("Add")}
          </Button>
        </Link>
      </GridItem>
      <Card>
        <CardBody>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={10} sm={11} md={11}></GridItem>
            <GridItem xs={2} sm={1} md={1}>
              <DropDownPersonList
              ping={ping}
              setPing={setPing}
                selectedItemId={selectedItemId}
                id={selectedItemId.toString()}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              height: 650,
              width: "100%",
            }}
          >
            <DataGrid
              rows={persons && persons}
              columns={columns}
              pageSize={9}
              pagination
              checkboxSelection
              rowsPerPageOptions={[]}
              onSelectionModelChange={handleToggle}
              selectionModel={selectedItemId}
            />
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
