import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  clos: [],
  CloResults: [],
  closStatus: "idle",
  cloStatus: "idle",
  error: null,
  clo: null,
  id: null,
  allClos: [],
  allClosStatus: "idle",
  ArticulationMatrix: null,
  QuestionArticulationMatrix:null,
};

export const fetchCloById = createAsyncThunk(
  "clo/fetchCloById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/clo/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data clo");
      ///console.log(response.data)
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchcourseAssessmentMethodClo = createAsyncThunk(
  "clo/fetchcourseAssessmentMethodClo",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/courseAssessmentMethodClo/${id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      // console.log(response.data)
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchArticulationMatrix = createAsyncThunk(
  "clo/fetchArticulationMatrix",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/clo/data_matrix/?course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


export const fetchQuestionArticulationMatrix = createAsyncThunk(
  "clo/fetchQuestionArticulationMatrix",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/clo/question_matrix/?course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


export const fetchCloResults = createAsyncThunk(
  "clo/fetchArticulationMatrix",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/clo/all/?course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchClos = createAsyncThunk(
  "clo/fetchClos",
  async (param, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + "/clo/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewClo = createAsyncThunk(
  "clo/addNewClo",
  async (initialClo, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + `/clo/create/`, initialClo, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateClo = createAsyncThunk(
  "clo/updateClo",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/clo/${data.id}/edit/`,
        data.updatedClo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedClo");
      // console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateCommentOnAssessmentResults = createAsyncThunk(
  "clo/UpdateCommentOnAssessmentResults",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/clo/comment_on_assessment_results/${data.id}/update/`,
        data.updatedClo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedClo");
      // console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchAllClos = createAsyncThunk(
  "clo/fetchAllClos",
  async (param, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + "/clo/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const deleteCLO = createAsyncThunk(
  "clo/deleteClo",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/clo/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const CloSlice = createSlice({
  name: " clo",
  initialState,
  extraReducers: {
    [fetchClos.pending]: (state, action) => {
      state.closStatus = "loading";
    },
    [fetchClos.fulfilled]: (state, action) => {
      state.closStatus = "succeeded";
      // Add any fetched posts to the array
      state.clos = action.payload;
    },
    [fetchClos.rejected]: (state, action) => {
      state.closStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchArticulationMatrix.pending]: (state, action) => {
      state.closStatus = "loading";
    },
    [fetchArticulationMatrix.fulfilled]: (state, action) => {
      state.closStatus = "succeeded";
      state.ArticulationMatrix = action.payload;
    },
    [fetchArticulationMatrix.rejected]: (state, action) => {
      state.closStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },


    [fetchQuestionArticulationMatrix.pending]: (state, action) => {
      state.closStatus = "loading";
    },
    [fetchQuestionArticulationMatrix.fulfilled]: (state, action) => {
      state.closStatus = "succeeded";
      state.QuestionArticulationMatrix = action.payload;
    },
    [fetchQuestionArticulationMatrix.rejected]: (state, action) => {
      state.closStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    

    [fetchCloResults.pending]: (state, action) => {
      state.closStatus = "loading";
    },
    [fetchCloResults.fulfilled]: (state, action) => {
      state.closStatus = "succeeded";
      state.CloResults = action.payload;
    },
    [fetchCloResults.rejected]: (state, action) => {
      state.closStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchCloById.pending]: (state, action) => {
      state.cloStatus = "loading";
    },
    [fetchCloById.fulfilled]: (state, action) => {
      state.cloStatus = "succeeded";

      // Add any fetched posts to the array
      state.clo = action.payload;
    },
    [fetchCloById.rejected]: (state, action) => {
      state.cloStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewClo.fulfilled]: (state, action) => {
      state.clos.push(action.payload);
    },
    [updateClo.fulfilled]: (state, action) => {},
    [UpdateCommentOnAssessmentResults.fulfilled]: (state, action) => {},
    [UpdateCommentOnAssessmentResults.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    
    [fetchAllClos.pending]: (state, action) => {
      state.allClosStatus = "loading";
    },
    [fetchAllClos.fulfilled]: (state, action) => {
      state.allClosStatus = "succeeded";
      // Add any fetched posts to the array
      state.allClos = state.allClos.concat(action.payload);
    },
    [fetchAllClos.rejected]: (state, action) => {
      state.allClosStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },

  reducers: {},
});

export const {} = CloSlice.actions;

export default CloSlice.reducer;

export const selectAllClos = (state) => state.clo.clos;
