import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";


// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import MenuItem from "@material-ui/core/MenuItem";
import "antd/dist/antd.css";

import { fetchAssessmentQuestionsMethods } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import { addNewQuestion } from "../../reduxToolkit/features/assessmentMethod/QuestionSlice";
import ErrorModal from "./ErrorModal";

import { fetchCourseById } from "../../reduxToolkit/features/course/CoursesSlice.js";
import {
    fetchInstructorCourseAssessmentGroup
} from "../../reduxToolkit/features/CourseInstructor/CourseInstructorSlice";


const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 12,
        span: 0,
    },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));

export default function AddQuestion(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [form] = Form.useForm();



    const [question, setQuestion] = useState("");
    const [actual_weight, setActual_weight] = useState("");
    const [correction_mark, setCorrection_mark] = useState("");
    const [clo_id, setClo_id] = useState();
    const [assessmentMethod_id, setAssessmentMethod_id] = useState(
        props.assessmentMethod_id
    );
    const [course_id, setCourse_id] = useState(props.course_id);

    const { Option } = Select;

    const [open, setOpen] = React.useState(false);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);


    const handleKeyDown = (event, ref) => {
        if (event.key === 'Tab') {
            ref.current.focus();
        }
    };


    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    useEffect(() => {
        if (props.course_id) {
          dispatch(fetchCourseById(props.course_id));
        }
      }, [props.addModalIsOpen]);

    const course = useSelector((state) => state.course.course);

    const onFinish = async (values) => {
        const resultAction = await dispatch(
            addNewQuestion({
                ...values, assessmentMethod_id:props.assessmentMethod_id, course_id:props.course_id
            })
        );
        if (addNewQuestion.fulfilled.match(resultAction)) {
            openNotificationWithIcon("success", `Add successfully`);
            //   dispatch(fetchAssessmentQuestionsMethods(props.course_id));
            dispatch(fetchInstructorCourseAssessmentGroup(props.id));
            form.resetFields();
            props.handleCloseAddModal();
        } else {
            if (resultAction.payload) {
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                form.resetFields();
                props.handleCloseAddModal();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                form.resetFields();
                props.handleCloseAddModal();
            }
        }
    };

    //   function onChangeAssessmentMethod_id(value) {
    //     setAssessmentMethod_id(value);
    //     setClo_ids(props.cloId);
    //     setPercentage_note(0);
    //     setDate_of_input(moment().format("yyyy-MM-DD HH:mm:ss"));
    //   }

    return (
        <div>
            {/* <MenuItem onClick={handleClickOpen}>Add question</MenuItem> */}
            <Dialog
                open={props.addModalIsOpen}
                onClose={props.handleCloseAddModal}
                aria-labelledby="form-dialog-title"
                fullWidth="true"
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    <center>{t("Add Question")}</center>
                </DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>

                            <Form
                                {...layout}
                                form={form}
                                name="control-hooks"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="question"
                                    label="question"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input the question field.",
                                        },
                                    ]}
                                >
                                    <Input ref={input1Ref}
                                        onKeyDown={(event) => handleKeyDown(event, input2Ref)} />
                                </Form.Item>

                                <Form.Item
                                    name="actual_weight"
                                    label="Actual weight"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input the actual weight field.",
                                        },
                                    ]}
                                >
                                    <Input ref={input2Ref}
                                        onKeyDown={(event) => handleKeyDown(event, input3Ref)} />
                                </Form.Item>
                                <Form.Item
                                    name="correction_mark"
                                    label="Correction mark"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input the correction mark field.",
                                        },
                                    ]}
                                >
                                    <Input ref={input3Ref}
                                        onKeyDown={(event) => handleKeyDown(event, input4Ref)} />
                                </Form.Item>

                                <Form.Item
                                    name="clo_id"
                                    label="Clo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input the Clo field.",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Clo "
                                        dropdownStyle={{ zIndex: 10000 }}
                                        optionFilterProp="children"
                                        showSearch
                                        ref={input4Ref}
                                        onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {course &&
                                            course.clos &&
                                            course.clos.map((clo) => (
                                                <Option key={clo.id} value={clo.id}>
                                                    {`${clo.code}--${clo.title}`}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item {...tailLayout}>
                                    <Button
                                        type="primary"
                                        style={{ marginRight: "20px" }}
                                        color="primary"
                                        onClick={props.handleCloseAddModal}
                                        htmlType="submit"
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" color="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>

                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    );
}
