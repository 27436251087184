import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const ContactInformation = ({ data }) => {
  return (
    <>
      <h3>Background Information: Contact Information</h3>
      <br />
      <p>
        <strong>Name:</strong> {data.name}
      </p>
      <p>
        <strong>Department:</strong> {data.department}
      </p>
      <p>
        <strong>College:</strong> {data.college}
      </p>
      <p>
        <strong>University:</strong> {data.university}
      </p>
      <p>
        <strong>Address:</strong> {data.address}
      </p>
      <p>
        <strong>Phone:</strong> {data.phone}
      </p>
      <p>
        <strong>Email:</strong> {data.email}
      </p>
      <p>
        <strong>Fax:</strong> {data.fax}
      </p>
    </>
  );
};

export default ContactInformation;
