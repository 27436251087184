import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  studyPlanReportDocuments: [],
  studyPlanReportDocumentsStatus: 'idle',
  studyPlanReportDocument: null,
  studyPlanReportDocumentStatus: 'idle',
  error: null,

}

export const fetchStudyPlanReportDocuments = createAsyncThunk('studyPlanReportDocument/fetchStudyPlanReportDocuments', 
async ({ rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+'/studyPlan/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  
   return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

export const fetchStudyPlanReportDocumentsByProgramSpecId = 
createAsyncThunk('studyPlanReportDocument/fetchStudyPlanReportDocumentsByStudyPlanId',
 async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/studyPlan/by_programSpec/?programSpec_id=${id}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

 
   return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

export const fetchStudyPlanReportDocumentById = 
createAsyncThunk('studyPlanReportDocument/fetchStudyPlanReportDocumentById', 
async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/studyPlan/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);
export const addNewStudyPlanReportDocument = createAsyncThunk(
  'studyPlanReportDocument/addNewStudyPlanReportDocument',
  async (initialStudyPlanReportDocument, { rejectWithValue }) => {
   const token=localStorage.getItem('token')
   try {
    const response = await axios.post(BaseUrl+`/studyPlan/create/`, initialStudyPlanReportDocument, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);
export const updateStudyPlanReportDocument = createAsyncThunk(
  'studyPlanReportDocument/updateStudyPlanReportDocument',
  async (data, { rejectWithValue }) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/studyPlan/${data.id}/edit/`, data.updatedStudyPlanReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

export const deleteStudyPlanReportDocument = createAsyncThunk('studyPlanReportDocument/deleteStudyPlanReportDocument', 
async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl+`/studyPlan/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);

const studyPlanReportDocumentsSlice = createSlice({
  name: ' studyPlanReportDocument',
  initialState,
  extraReducers: {
    
    [fetchStudyPlanReportDocuments.pending]: (state, action) => {
      state.studyPlanReportDocumentsStatus = 'loading'
    },
    [fetchStudyPlanReportDocuments.fulfilled]: (state, action) => {
      state.studyPlanReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.studyPlanReportDocuments = action.payload
    },
   
    [fetchStudyPlanReportDocumentsByProgramSpecId.pending]: (state, action) => {
      state.studyPlanReportDocumentsStatus = 'loading'
    },
    [fetchStudyPlanReportDocumentsByProgramSpecId.fulfilled]: (state, action) => {
      state.studyPlanReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.studyPlanReportDocuments = action.payload
    },
    [fetchStudyPlanReportDocumentsByProgramSpecId.rejected]: (state, action) => {
      state.studyPlanReportDocumentsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
   

    [fetchStudyPlanReportDocumentById.pending]: (state, action) => {
      state.studyPlanReportDocumentStatus = 'loading'
    },
    [fetchStudyPlanReportDocumentById.fulfilled]: (state, action) => {
      state.studyPlanReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.studyPlanReportDocument = action.payload
    },
    [fetchStudyPlanReportDocumentById.rejected]: (state, action) => {
      state.studyPlanReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewStudyPlanReportDocument.fulfilled]: (state, action) => {
      state.studyPlanReportDocuments.push(action.payload);

    },
    [deleteStudyPlanReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("StudyPlanReportDocument delete action.payload",action.payload)
    },
    [updateStudyPlanReportDocument.fulfilled]: (state, action) => {
    
      console.log("StudyPlanReportDocument update action.payload",action.payload)

    },
    [addNewStudyPlanReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteStudyPlanReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [updateStudyPlanReportDocument.rejected]: (state, action) => {
    
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },


  }

})


export default studyPlanReportDocumentsSlice.reducer


