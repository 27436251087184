import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  KPIs: [],
  KPIsList: [],
  standards_KPIsCollege:[],
  KPIsStatus: 'idle',
  KPI: null,
  KPIStatus: 'idle',
  error: null,
  filteredKPIconfig: [],
  filteredKPIconfigStatus: 'idle',
  standards_KPIsCollegeStatus: 'idle',
  
}

export const fetchKPIsCollegeList = createAsyncThunk('KPICollege/fetchKPIsCollegeList', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiCollege/list/?college_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})
export const fetchKPIsCollege = createAsyncThunk('KPICollege/fetchKPIsCollege', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiCollege/?college_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchNotAffectedKPIConfigToCollege = createAsyncThunk('KPICollege/fetchNotAffectedKPIConfigToCollege', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiCollege/kpiConfig_filtered/?college_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})



export const fetchAll_standards_KPIsCollege = createAsyncThunk('KPICollege/fetchAll_standards_KPIsCollege', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiCollege/standards/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})




// export const fetchKPIProgramById = createAsyncThunk('KPICollege/fetchKPIProgramById', async (id, { rejectWithValue }) => {
//   try {

//     const token = localStorage.getItem('token')
//     const response = await axios.get(BaseUrl + `/kpiProgram/${id}/`, {
//       headers: {
//         'Authorization': `token ${token}`
//       }
//     })
//     // console.log(response.data,'response.data')

//     return response.data
//   } catch (err) {
//     let error = err // cast the error for access
//     if (!error.response) {
//       throw err
//     }
//     // We got validation errors, let's return those so we can reference in our component and set form errors
//     console.log('error.response from inside the CAT', error.response)
//     return rejectWithValue(error.response)
//   }
// })

export const addNewKPICollege = createAsyncThunk(
  'KPICollege/addNewKPICollege',
  async (initialKPICollege, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/kpiCollege/create/`, initialKPICollege, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const deleteKPICollege = createAsyncThunk(
  "KPICollege/deleteKPICollege",
  async (ids, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/kpiCollege/delete/`,ids,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



const KPICollegeSlice = createSlice({
  name: 'KPICollege',
  initialState,
  extraReducers: {

    [fetchKPIsCollege.pending]: (state, action) => {
      state.KPIsStatus = 'loading'
    },
    [fetchKPIsCollege.fulfilled]: (state, action) => {
      state.KPIsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.KPIs = action.payload
    },
    [fetchKPIsCollege.rejected]: (state, action) => {
      state.KPIsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    
    [fetchKPIsCollegeList.pending]: (state, action) => {
      state.KPIsStatus = 'loading'
    },
    [fetchKPIsCollegeList.fulfilled]: (state, action) => {
      state.KPIsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.KPIsList = action.payload
    },
    [fetchKPIsCollegeList.rejected]: (state, action) => {
      state.KPIsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    [fetchNotAffectedKPIConfigToCollege.pending]: (state, action) => {
      state.filteredKPIconfigStatus = 'loading'
    },
    [fetchNotAffectedKPIConfigToCollege.fulfilled]: (state, action) => {
      state.filteredKPIconfigStatus = 'succeeded'
      // Add any fetched posts to the array
      state.filteredKPIconfig = action.payload
    },
    [fetchNotAffectedKPIConfigToCollege.rejected]: (state, action) => {
      state.filteredKPIconfigStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    



    [fetchAll_standards_KPIsCollege.pending]: (state, action) => {
      state.standards_KPIsCollegeStatus = 'loading'
    },
    [fetchAll_standards_KPIsCollege.fulfilled]: (state, action) => {
      state.standards_KPIsCollegeStatus = 'succeeded'
      // Add any fetched posts to the array
      state.standards_KPIsCollege = action.payload
    },
    [fetchAll_standards_KPIsCollege.rejected]: (state, action) => {
      state.standards_KPIsCollegeStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    [addNewKPICollege.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
  },

})


export default KPICollegeSlice.reducer


