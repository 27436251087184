import MenuItem from '@material-ui/core/MenuItem'
import React, {useState} from 'react'
import Button from 'components/CustomButtons/Button.js'
import MoreVert from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import { notification } from 'antd'
import { deleteAssessmentMethodQuestion } from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import ErrorModal from '../../../../Components/ErrorModal'
import SuperGeneralDeleteModal from '../../../../Components/SuperGeneralDeleteModal'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const DropDownClosQuestions = ({ selectionModel, addRow, fetchClo }) => {

  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const handleDeleQuestion = (id) => {
    dispatch(deleteAssessmentMethodQuestion(id))
      .unwrap()
      .then(() => {
        openNotificationWithIcon('success', `Deleted successfully!`)
        fetchClo()
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={selectionModel.length !== 1}
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            addRow()
            handleClose()
          }}
        >
          {t('Add question')}
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleQuestion}
        Element_ID={selectionModel[0]}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}

export default DropDownClosQuestions
