import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  addNewGraduateAttributeSpec,
  fetchGraduateAttributeSpecById,
} from "../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";
import { addNewGAIndicator } from "../../reduxToolkit/features/GAIndicators/GAIndicatorSpecSlice";
import { fetchPloById } from "../../reduxToolkit/features/PLO/PLOSpecSlice";
import { Form, Input, message, Select, notification } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "./ErrorModal.jsx";


export default function GAIndictorsModal({ GA, programId, GAId,disabledStatus }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [weight_ga, setWeight_ga] = useState("");
  const [target, setTarget] = useState("");
  const [type, setType] = useState("GA");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();

  const graduateAttributeSpec_id = GAId;
  const canSave = [name, code].every(Boolean) && addRequestStatus === "idle";

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const onFinish = async () => {
    const resultAction = await dispatch(
      addNewGAIndicator({
        name,
        code,
        weight_ga,
        graduateAttributeSpec_id,
        target,
      })
    );
    setName("");
    setCode("");
    setWeight_ga("");
    setTarget("");
    if (addNewGAIndicator.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const GAIndicator = resultAction.payload;
      console.log("success", `Added ${GAIndicator.name}`);
      openNotificationWithIcon("success", `Added ${GAIndicator.name}`);
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
    handleClose();
    dispatch(fetchGraduateAttributeSpecById(GAId));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      {disabledStatus ? <p></p> :
      <Button color="primary" onClick={ handleClickOpen}>
        {t("Add")}
      </Button>
    }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Add a Graduate Attribute Indicator")}
        </DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            style={{ padding: "0px 10px" }}
          >
            <GridContainer direction="row" justify="center" alignItems="center">
              <GridItem xs={8} sm={8} md={12}>
                <DialogContentText>
                  {t(
                    "Graduate attributes are the high level qualities, skills and understandings that a student should gain as a result"
                  )}
                </DialogContentText>
              </GridItem>

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <Form.Item
                    label={t("Code *")}
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Please input the  GA's code!",
                        warningOnly: true,
                      },
                    ]}
                  >
                    <Input
                      id="code"
                      value={code}
                      onChange={(event) => {
                        setCode(event.target.value);
                      }}
                    />
                  </Form.Item>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <Form.Item
                    label={t("Name *")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input the  GA's name!",
                        warningOnly: true,
                      },
                    ]}
                  >
                    <Input
                      id="name"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </Form.Item>
                </GridItem>
              </GridContainer>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={6}>
                  <Form.Item
                    label={t("target *")}
                    name="target"
                    rules={[
                      {
                        required: true,
                        message: "Please input the GA's target !",
                      },
                    ]}
                  >
                    <Input
                      id="target"
                      value={target}
                      onChange={(event) => {
                        setTarget(event.target.value);
                      }}
                    />
                  </Form.Item>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <Form.Item
                    label={t("weight *")}
                    name="weight_ga"
                    rules={[
                      {
                        required: true,
                        message: "Please input the GA's weight !",
                      },
                    ]}
                  >
                    <Input
                      id="weight_ga"
                      value={weight_ga}
                      onChange={(event) => {
                        setWeight_ga(event.target.value);
                      }}
                      rows={4}
                    />
                  </Form.Item>
                </GridItem>
              </GridContainer>
            </GridContainer>

            <GridContainer direction="row" justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={10}>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button color="primary" onClick={handleClose}>
                        {t("Cancel")}
                      </Button>
                    </Form.Item>
                  </GridItem>
                  <GridItem>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button type="submit" color="primary">
                        {t("Save")}
                      </Button>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
