import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Input, notification, Select, InputNumber, Tabs } from 'antd'

import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import ErrorModal from '../../../Components/ErrorModal.jsx'
import MenuItem from '@material-ui/core/MenuItem'
import {
  fetchProgramById,
  programSpecVersioning,
} from '../../../../reduxToolkit/features/program/ProgramSpecSlice'
import { DiffEditor } from '@monaco-editor/react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import Typography from '@mui/material/Typography'
import Avatar from '@material-ui/core/Avatar'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Chip from '@material-ui/core/Chip'
import { MediaBaseUrl, MediaBaseUrlAvatar } from 'assets/utils/accessToken'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter'
import CardHeader from 'components/Card/CardHeader.js'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'

import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import VersionControl from 'views/Pages/Specifications/course/VersionControl'
import { Tag } from 'antd'
import FiberNewIcon from '@material-ui/icons/FiberNew'

const VersionControlModal2 = ({
  id,
  data,
  disabledStatus,
  handleDropDownButtonClose,
  setRefresh,
  refresh,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { Option } = Select
  const [open, setOpen] = React.useState(false)
  const [pingUseEffect, setPingUseEffect] = React.useState(false)
  const [scroll, setScroll] = React.useState('paper')
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onFinish = async (values) => {
    console.log('values', values)
  }

  const programVersioning = useSelector(
    (state) => state.programSpec.programSpecVersioning,
  )

  useEffect(() => {
    if (refresh) {
      dispatch(programSpecVersioning(id))
        .unwrap()
        .then((res) => console.log(res))
        .catch((err) => console.log(err))
    }
  }, [pingUseEffect])

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          setRefresh(true)
          setPingUseEffect(!pingUseEffect)
          handleClickOpen()
          setOpen(true)
          handleDropDownButtonClose()
        }}
      >
        {t('Version Control')}
      </MenuItem>
      <Dialog
        fullScreen
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {programVersioning[0] ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}></GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody
                  style={{
                    margin: '0px',
                    padding: '2px',
                    fontWeight: 'bold',
                    color: '',
                    fontFamily: 'Arial, sans-serif',
                  }}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {`${programVersioning[0]?.parentDetails?.program_degree_name} (${programVersioning[0]?.parentDetails?.code})`}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Chip
                        size="small"
                        label={programVersioning[0]?.parentDetails?.state}
                        clickable
                        color="primary"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      {`V${programVersioning[0]?.parentDetails?.version}`}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem style={{ margin: '20px' }} xs={12} sm={12} md={1}>
              <TrendingFlatIcon style={{ fontSize: '50px' }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody
                  style={{
                    margin: '2px',
                    padding: '2px',
                    fontWeight: 'bold',
                    color: '',
                    fontFamily: 'Arial, sans-serif',
                  }}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {`${programVersioning[0]?.childDetails?.program_degree_name} (${programVersioning[0]?.childDetails?.code})`}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Chip
                        size="small"
                        label={programVersioning[0]?.childDetails?.state}
                        clickable
                        color="primary"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      {`V${programVersioning[0]?.childDetails?.version}`}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <center>No Data</center>
        )}

        <DialogContent dividers={scroll === 'paper'}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Program Specification Versioning`,
                children: (
                  <ProgramSpecVersioning
                    actionRecommendations_details={
                      programVersioning[0]?.actionRecommendations_details
                    }
                  />
                ),
              },
              {
                key: '2',
                label: `Courses Specification`,
                children: (
                  <CourseSpecVersioningTabs
                    courses={programVersioning[0]?.courses}
                  />
                ),
              },

              {
                key: '3',
                label: `PLOs`,
                children: (
                  <PLOVersioning
                    childPLOs={programVersioning[0]?.childDetails.plo}
                    parentPLOs={programVersioning[0]?.parentDetails.plo}
                    list={programVersioning[0]?.plo}
                  />
                ),
              },
              {
                key: '4',
                label: `KPIs`,
                children: (
                  <VersioningComponent list={programVersioning[0]?.kpi} />
                ),
              },
              {
                key: '5',
                label: `GA`,
                children: (
                  <VersioningComponent list={programVersioning[0]?.ga} />
                ),
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default VersionControlModal2

/* Program spec Versioning */
const ProgramSpecVersioning = ({ actionRecommendations_details }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Timeline>
          {actionRecommendations_details?.map((action, index) => (
            <CustomizedTimeline key={index} action={action} />
          ))}
        </Timeline>
      </GridItem>
    </GridContainer>
  )
}

const CustomizedTimeline = ({ action }) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: '0' }}>
        {action?.recommendation_due_date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot>
          <CheckCircleIcon
            style={
              new Date(new Date().getTime()).setHours(0, 0, 0, 0) >
              new Date(action?.recommendation_due_date).getTime()
                ? { color: 'red' }
                : { color: 'green' }
            }
          />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <VersionsControl action={action} />
      </TimelineContent>
    </TimelineItem>
  )
}

const VersionsControl = ({ action }) => {
  const [openCollapse, setOpenCollapse] = useState(true)
  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }
  return (
    <List>
      <ListItem
        style={{
          borderRadius: '5px',
          height: '30px',
          backgroundColor: '#F5F5F5',
        }}
        button
        onClick={handleClick}
      >
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
        <MenuItem>
          <strong>{`${action?.actionType_name} : ${action?.recommendation_description}`}</strong>
        </MenuItem>
      </ListItem>
      <Collapse
        style={{
          backgroundColor: 'RGBA(245, 245, 245,0.3)',
          textAlign: 'left',
        }}
        in={openCollapse}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {action?.section?.map((section) => (
            <React.Fragment>
              <GridItem xs={12} sm={12} md={1}></GridItem>
              <GridItem xs={12} sm={12} md={11}>
                <VersionsControl4 section={section} />
              </GridItem>
            </React.Fragment>
          ))}
        </GridContainer>
      </Collapse>
    </List>
  )
}

const VersionsControl4 = ({ section }) => {
  const [openCollapse, setOpenCollapse] = useState(false)

  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }

  return (
    <List>
      <ListItem
        style={{
          borderRadius: '5px',
          height: '30px',
          textDecoration: 'underline',
        }}
        button
        onClick={handleClick}
      >
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
        <div style={{ padding: '0px', marginLeft: '10px' }}>
          <MenuItem>
            <strong>{section?.name}</strong>
          </MenuItem>
        </div>
        <div style={{}}>
          <MenuItem>
            <Button>See Details</Button>
          </MenuItem>
        </div>
      </ListItem>
      <Collapse
        style={{
          backgroundColor: 'RGBA(245, 245, 245,0.3)',
          textAlign: 'left',
        }}
        in={openCollapse}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {section?.versions?.map((version) => {
            const childKeys = Object.keys(version.childField)
            const parentKeys = Object.keys(version.parentField)
            const keys =
              childKeys.length > parentKeys.length ? childKeys : parentKeys

            return keys.map((key, index) => (
              <GridItem xs={12} sm={12} md={12} key={index}>
                <span>{key}</span>
                <span>
                  <ReactDiffViewer
                    newValue={JSON.stringify(version.childField[key])}
                    oldValue={JSON.stringify(version.parentField[key])}
                    showDiffOnly={false}
                  />
                </span>
              </GridItem>
            ))
          })}
        </GridContainer>
      </Collapse>
    </List>
  )
}

/* End Program spec Versioning */

/******************************************************************/

/* course spec versioning */

const CourseSpecVersioningTabs = ({ courses }) => {
  const [openCollapse, setOpenCollapse] = useState(true)
  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={2}></GridItem>
      <GridItem xs={12} sm={12} md={10}>
        <>
          {courses?.map((course, index) => (
            <CourseSpecVers
              key={index}
              parentCourses={course.parent}
              childCourses={course.child}
            />
          ))}
        </>
      </GridItem>
    </GridContainer>
  )
}

const CourseSpecVers = ({ childCourses, parentCourses }) => {
  const _ = require('lodash')
  const id =
    Object.keys(childCourses).length > 0 ? childCourses.id : parentCourses.id
  const childKeys = Object.keys(childCourses || [])
  const parentKeys = Object.keys(parentCourses || [])
  console.log(childCourses)
  console.log(parentCourses)
  const keys = childKeys.length > parentKeys.length ? childKeys : parentKeys
  const [openCollapse, setOpenCollapse] = useState(false)
  const [refresh, setRefresh] = React.useState(false)

  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }
  const handleClose = () => {
    //setAnchorEl(null);
  }

  return (
    <List>
      <ListItem
        style={{
          borderRadius: '5px',
          height: '30px',
          textDecoration: 'underline',
        }}
        button
        onClick={handleClick}
      >
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
        <div style={{ padding: '0px', marginLeft: '10px' }}>
          <MenuItem>
            <strong>
              {Object.keys(childCourses).length > 0
                ? childCourses.title
                : parentCourses.title}
            </strong>
          </MenuItem>
        </div>
        <MenuItem>
          <VersionControl
            //id={199}
            id={id}
            refresh={refresh}
            setRefresh={setRefresh}
            handleDropDownButtonClose={handleClose}
            icon={true}
          />
        </MenuItem>
        <MenuItem>
          {_.isEqual(childCourses, parentCourses) ? (
            ''
          ) : (
            <FiberNewIcon style={{ fontSize: 40, color: 'red' }} />
          )}
        </MenuItem>
      </ListItem>
      <Collapse
        style={{
          backgroundColor: 'RGBA(245, 245, 245,0.3)',
          textAlign: 'left',
        }}
        in={openCollapse}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {keys.map(
            (key, index) =>
              key !== 'id' && (
                <GridItem xs={12} sm={12} md={12} key={index}>
                  <span>{key}</span>
                  <span>
                    <ReactDiffViewer
                      newValue={JSON.stringify(childCourses[key])}
                      oldValue={JSON.stringify(parentCourses[key])}
                      showDiffOnly={false}
                    />
                  </span>
                </GridItem>
              ),
          )}
        </GridContainer>
      </Collapse>
    </List>
  )
}

/* End course spec versioning */

/* PLO Versioning */
const PLOVersioning = ({ childPLOs, parentPLOs, list }) => {
  // Fonction pour rechercher un élément par ID dans un tableau donné
  function findItemById(id, array) {
    return array.find((item) => item.id === id)
  }

  // Compléter les plo_indicator pour chaque item (child et parent) dans le tableau list
  const listPLOs = list?.map((item) => {
    const newItem = { ...item }

    if (item.child.id) {
      const childPLO = findItemById(item.child.id, childPLOs)
      if (childPLO) {
        newItem.child = {
          ...newItem.child,
          plo_indicators: childPLO.plo_indicators,
        }
      }
    }

    if (item.parent.id) {
      const parentPLO = findItemById(item.parent.id, parentPLOs)
      if (parentPLO) {
        newItem.parent = {
          ...newItem.parent,
          plo_indicators: parentPLO.plo_indicators,
        }
      }
    }

    return newItem
  })

  // Le tableau list mis à jour avec les plo_indicator pour chaque item (child et parent)
  //console.log(listPLOs)
  const [openCollapse, setOpenCollapse] = useState(true)

  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }
  return (
    <GridContainer>
      {listPLOs && (
        <>
          <GridItem xs={12} sm={12} md={2}></GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Tag color="grey">plo</Tag>
            <Tag color="#8F6682">clo</Tag>
            <Tag color="default">plo indicator</Tag>
          </GridItem>
        </>
      )}
      <GridItem xs={12} sm={12} md={2}></GridItem>
      <GridItem xs={12} sm={12} md={10}>
        {listPLOs?.map((el, index) => (
          <ReactDiffComponentPLO key={index} el={el} />
        ))}
      </GridItem>
    </GridContainer>
  )
}
const ReactDiffComponentPLO = ({ el }) => {
  const _ = require('lodash')
  

  const childName = el.child?.name
  const parentName = el.parent?.name
  const truncateName = (name) => {
    return name && name.length > 100 ? name.substring(0, 100) + '...' : name
  }
  const childKeys = Object.keys(el.child || [])
  const parentKeys = Object.keys(el.parent || [])
  const keys = childKeys.length > parentKeys.length ? childKeys : parentKeys
  const childPloIndicators = el.child.plo_indicators || []
  const parentPloIndicators = el.parent.plo_indicators || []
  const listPLOsIndicators = []

  function findMatchingParent(childObj) {
    return parentPloIndicators.find((parentObj) => parentObj.id === childObj.id)
  }

  childPloIndicators.forEach((childObj) => {
    const parentObj = findMatchingParent(childObj)
    listPLOsIndicators.push({ child: childObj, parent: parentObj || {} })
  })

  parentPloIndicators.forEach((parentObj) => {
    if (!childPloIndicators.some((childObj) => childObj.id === parentObj.id)) {
      listPLOsIndicators.push({ child: {}, parent: parentObj })
    }
  })

  const [openCollapse, setOpenCollapse] = useState(false)
  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }

  return (
    <List>
      <ListItem
        style={{
          borderRadius: '5px',
          height: '30px',
          backgroundColor: '#F5F5F5',
        }}
        button
        onClick={handleClick}
      >
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
        <div style={{ padding: '0px', marginLeft: '10px' }}>
          <MenuItem>
            <strong>
              {Object.keys(el.child).length > 0
                ? truncateName(childName)
                : truncateName(parentName)}
            </strong>
          </MenuItem>
        </div>
        <MenuItem>
          {_.isEqual(el.child, el.parent) ? (
            ''
          ) : (
            <FiberNewIcon style={{ fontSize: 40, color: 'red' }} />
          )}
        </MenuItem>
      </ListItem>
      <Collapse
        style={{
          backgroundColor: 'RGBA(245, 245, 245,0.3)',
          textAlign: 'left',
        }}
        in={openCollapse}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {keys?.map(
            (key, index) =>
              key !== 'id' &&
              key !== 'plo_indicators' && (
                <GridItem xs={12} sm={12} md={12} key={index}>
                  <span>{key}</span>

                  <span>
                    <ReactDiffViewer
                      newValue={JSON.stringify(el.child[key])}
                      oldValue={JSON.stringify(el.parent[key])}
                      showDiffOnly={false}
                    />
                  </span>
                </GridItem>
              ),
          )}
          <GridItem xs={12} sm={12} md={12}>
            {listPLOsIndicators?.map((ploIndicator, index) => (
              <GridItem xs={12} sm={12} md={12} key={index}>
                <PloIndicator key={index} ploIndicator={ploIndicator} />
              </GridItem>
            ))}
          </GridItem>
        </GridContainer>
      </Collapse>
    </List>
  )
}
const PloIndicator = ({ ploIndicator }) => {
  const childName = ploIndicator.child?.name
  const parentName = ploIndicator.parent?.name
  const childKeys = Object.keys(ploIndicator.child || [])
  const parentKeys = Object.keys(ploIndicator.parent || [])
  const keys = childKeys.length > parentKeys.length ? childKeys : parentKeys
  const listCLOs = []
  const childCLOs = ploIndicator.child.clo || []
  const parentCLOs = ploIndicator.parent.clo || []

  function findMatchingParent(childObj) {
    return parentCLOs.find((parentObj) => parentObj.id === childObj.id)
  }

  childCLOs.forEach((childObj) => {
    const parentObj = findMatchingParent(childObj)
    listCLOs.push({ child: childObj, parent: parentObj || {} })
  })

  parentCLOs.forEach((parentObj) => {
    if (!childCLOs.some((childObj) => childObj.id === parentObj.id)) {
      listCLOs.push({ child: {}, parent: parentObj })
    }
  })

  const truncateName = (name) => {
    return name && name.length > 100 ? name.substring(0, 100) + '...' : name
  }
  const [openCollapse, setOpenCollapse] = useState(false)

  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }

  return (
    <List>
      <ListItem
        style={{
          borderRadius: '5px',
          height: '30px',
          textDecoration: 'underline',
        }}
        button
        onClick={handleClick}
      >
        {openCollapse ? <ExpandLess /> : <ExpandMore />}

        <MenuItem>
          <strong>
            {Object.keys(ploIndicator.child).length > 0
              ? truncateName(childName)
              : truncateName(parentName)}
          </strong>
        </MenuItem>
      </ListItem>
      <Collapse
        style={{
          backgroundColor: 'RGBA(245, 245, 245,0.3)',
          textAlign: 'left',
        }}
        in={openCollapse}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {keys?.map(
            (key, index) =>
              key !== 'id' &&
              key !== 'clo' && (
                <GridItem xs={12} sm={12} md={12} key={index}>
                  <span>{key}</span>

                  <span>
                    <ReactDiffViewer
                      newValue={JSON.stringify(ploIndicator.child[key])}
                      oldValue={JSON.stringify(ploIndicator.parent[key])}
                      showDiffOnly={false}
                    />
                  </span>
                </GridItem>
              ),
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={1}></GridItem>
            <GridItem xs={12} sm={12} md={10}>
              {listCLOs?.map((clo, index) => (
                <CLO key={index} clo={clo} />
              ))}
            </GridItem>
            <GridItem xs={12} sm={12} md={1}></GridItem>
          </GridContainer>
        </GridContainer>
      </Collapse>
    </List>
  )
}
const CLO = ({ clo }) => {
  //console.log(clo)
  const childName = clo.child?.title
  const parentName = clo.parent?.title
  const truncateName = (name) => {
    return name && name.length > 100 ? name.substring(0, 100) + '...' : name
  }
  const childKeys = Object.keys(clo.child || [])
  const parentKeys = Object.keys(clo.parent || [])
  const keys = childKeys.length > parentKeys.length ? childKeys : parentKeys
  const [openCollapse, setOpenCollapse] = useState(false)

  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }
  return (
    <List>
      <ListItem
        style={{
          //padding:'-10px',
          borderRadius: '5px',
          height: '30px',
          backgroundColor: 'RGBA(143, 102, 130,0.1)',
        }}
        button
        onClick={handleClick}
      >
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
        <div
          style={
            {
              /*padding: '0px', marginLeft: '10px' */
            }
          }
        >
          <MenuItem>
            <strong>
              {Object.keys(clo.child).length > 0
                ? truncateName(childName)
                : truncateName(parentName)}
            </strong>
          </MenuItem>
        </div>
      </ListItem>
      <Collapse
        style={{
          backgroundColor: 'RGBA(245, 245, 245,0.3)',
          textAlign: 'left',
        }}
        in={openCollapse}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {keys?.map(
            (key, index) =>
              key !== 'id' && (
                <GridItem xs={12} sm={12} md={12} key={index}>
                  <span>{key}</span>

                  <span>
                    <ReactDiffViewer
                      newValue={JSON.stringify(clo.child[key])}
                      oldValue={JSON.stringify(clo.parent[key])}
                      showDiffOnly={false}
                    />
                  </span>
                </GridItem>
              ),
          )}
        </GridContainer>
      </Collapse>
    </List>
  )
}

/* End PLO Versioning */

/* (KPI+GA) Versioning */

const VersioningComponent = ({ list }) => {
  const [openCollapse, setOpenCollapse] = useState(true)

  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={2}></GridItem>
      <GridItem xs={12} sm={12} md={10}>
        {list?.map((el, index) => (
          <ReactDiffComponent
            key={index}
            childFields={el?.child}
            parentFields={el?.parent}
          />
        ))}
      </GridItem>
    </GridContainer>
  )
}

const ReactDiffComponent = ({ childFields, parentFields }) => {
  const _ = require('lodash')

  const childName = childFields?.name
  const parentName = parentFields?.name
  const truncateName = (name) => {
    return name && name.length > 100 ? name.substring(0, 100) + '...' : name
  }
  const childKeys = Object.keys(childFields || [])
  const parentKeys = Object.keys(parentFields || [])
  const keys = childKeys.length > parentKeys.length ? childKeys : parentKeys
  const [openCollapse, setOpenCollapse] = useState(false)
  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }

  return (
    <List>
      <ListItem
        style={{
          borderRadius: '5px',
          height: '30px',
          textDecoration: 'underline',
        }}
        button
        onClick={handleClick}
      >
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
        <div style={{ padding: '0px', marginLeft: '10px' }}>
          <MenuItem>
            <strong>
              {Object.keys(childFields).length > 0
                ? truncateName(childName)
                : truncateName(parentName)}
            </strong>
          </MenuItem>
        </div>
        <MenuItem>
          {_.isEqual(childFields, parentFields) ? (
            ''
          ) : (
            <FiberNewIcon style={{ fontSize: 40, color: 'red' }} />
          )}
        </MenuItem>
      </ListItem>
      <Collapse
        style={{
          backgroundColor: 'RGBA(245, 245, 245,0.3)',
          textAlign: 'left',
        }}
        in={openCollapse}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {keys?.map(
            (key, index) =>
              key !== 'id' && (
                <GridItem xs={12} sm={12} md={12} key={index}>
                  <span>{key}</span>

                  <span>
                    <ReactDiffViewer
                      newValue={JSON.stringify(childFields[key])}
                      oldValue={JSON.stringify(parentFields[key])}
                      showDiffOnly={false}
                    />
                  </span>
                </GridItem>
              ),
          )}
        </GridContainer>
      </Collapse>
    </List>
  )
}

/* End (KPI+GA) */
