import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Select, Radio, notification } from 'antd';
import { Option } from 'antd/lib/mentions';
import { useTranslation } from "react-i18next";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignById } from "../../../../reduxToolkit/features/campaign/CampaignsSlice";
import { AddParticipantsFromGroups } from 'reduxToolkit/features/campaign/CampaignsSlice';

export default function UploadParticipantsExistingGroup(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    let id = props.Campaign_id
    console.log(id)

    const resultAction = await dispatch(AddParticipantsFromGroups({ id: id, ...values }));
    if (AddParticipantsFromGroups.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", ` success Added `);
      dispatch(fetchCampaignById(props.Campaign_id));
      props.handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        props.handleClose()
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        props.handleClose()
      }
    }

  };

  return (
    <>
      <DialogTitle>Manage participants</DialogTitle>
      <DialogContent>
        <Radio.Group onChange={props.onChange} value={props.value}>
          <Radio value={1}>{t("Upload Participants")}</Radio>
          <Radio value={2} style={{ marginRight: "20px" }}>
            {t("Select Existing Group")}
          </Radio>
        </Radio.Group>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Form form={form} onFinish={onFinish}>
              <Form.Item
                //label={t(changeCoordToAssignTo)}
                name="group_ids"
                rules={[
                  {
                    required: false,
                    // message: `Please select ${changeCoordToAssignTo}`,
                  },
                ]}
              // initialValue={coordinator_id}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  showSearch
                  dropdownStyle={{ zIndex: 10000 }}
                  value={props.selectedValue}
                  onChange={(value) => props.setSelectedValue(value)}

                >
                  {props.groupAssessment?.map((staff, key) => {
                    return (
                      <Select.Option value={staff?.id} key={key}>
                        {staff?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>



              <GridContainer justify="flex-end">
                <GridItem>
                  <GridContainer justify="space-between">
                    <GridItem>
                      <Form.Item>
                        <Button color="danger" onClick={props.handleClose}>
                          {t("Cancel")}
                        </Button>
                      </Form.Item>
                    </GridItem>
                    <GridItem>
                      <Form.Item>
                        <Button color="info" type="submit">
                          {t("Submit")}
                        </Button>
                      </Form.Item>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

            </Form>
          </GridItem>
        </GridContainer>



      </DialogContent>
    </>
  );

}
