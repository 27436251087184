import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb } from "antd";
import { Link, useParams } from "react-router-dom";
// antdesign components
import { DatePicker, Form, Input, message, notification, Select } from "antd";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import TextArea from "antd/lib/input/TextArea";
import { infoColor } from "assets/jss/material-dashboard-pro-react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchProfessionalDevelopmentById,
  updateProfessionalDevelopmentServiceActivity
} from "reduxToolkit/features/ProfessionalDevelopment/ProfessionalDevelopmentSlice.js";
import {
  fetchPrograms
} from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchCurrentSemester } from "reduxToolkit/features/semester/SemestersSlice";
import { fetchAuthorityAccordingToType } from "../../../../../reduxToolkit/features/AuthorityConfig/AuthorityConfigSlice";
import { fetchColleges } from "../../../../../reduxToolkit/features/college/CollegesSlice";
import { fetchCourses } from "../../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchDepartments } from "../../../../../reduxToolkit/features/department/DepartmentsSlice";
import {
  fetchStaff, fetchStaffById
} from "../../../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../../../Components/ErrorModal";




const useStyles = makeStyles(styles);
const useTitleStyles = makeStyles(titleStyles);

export default function EditProfessionalDevelopmentActivity({ match }) {

  const { t } = useTranslation();
  let history = useHistory();
  let { id, personId } = useParams();
  const titleClasses = useTitleStyles();
  const [form] = Form.useForm();

  const [selectedProgram, setSelectedProgram] = React.useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [levelType, setLevelType] = React.useState("");


  const [students, setStudents] = React.useState([]);
  const [staff, setStaff] = useState([]);
  const [faculty, setFaculty] = useState([]);

  const [university_id, setUniversity_id] = React.useState(null);

  const dispatch = useDispatch();

  const fieldsStatus = useSelector((state) => state.fieldSpec.fieldsStatus);
  const admissionRequirementsStatus = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirementsStatus
  );
  const graduationRequirementsStatus = useSelector(
    (state) => state.graduationRequirementSpec.graduationRequirementsStatus
  );

  const configAcoordingtoType = useSelector((state) => state.authorityConfig.AuthorityConfigsByType);
  const personsx = useSelector((state) => state.user.staffList)

  useEffect(() => {

    dispatch(fetchStaff());

    dispatch(fetchProfessionalDevelopmentById(id)).then(unwrapResult)
      .then((prof) => {

        console.log('prof', prof)
        console.log('authority', prof.authorityLevelConfig_details);
        const dateFormat = 'YYYY/MM/DD';
        moment(prof.start_date, dateFormat);
        form.setFieldsValue({
          type: prof.type,
          college_ids: prof.college_ids.length != 0 ? prof.colleges_details[0].name : "",
          department_ids: prof.department_ids != 0 ? prof.departments_details[0].title : "",
          course_ids: prof.course_ids.length != 0 ? prof.courses_details[0].title : "",
          program_ids: prof.program_ids.length != 0 ? prof.program_details[0] : "",
          title: prof.title,
          start_date: moment(prof.start_date, dateFormat),
          end_date: moment(prof.end_date, dateFormat),
          description: prof.description,
          theme: prof.theme,
          location: prof.location,
          organization: prof.organization,
          trainer: prof.trainer,
          authorityLevelConfig_id: prof.authorityLevelConfig_details.username,
          team_members_ids: prof.team_members_ids,






        });
      });






    dispatch(fetchStaffById(personId));
    const userId = localStorage.getItem('userId');
    dispatch(fetchCurrentSemester(userId))
      .unwrap()
      .then((semester) => {
        console.log("semesterId", semester.semester_id);
        dispatch(fetchPrograms(semester.semester_id));
      });




  }, [dispatch]);


  const authorsList = useSelector((state) => state.authorityConfig.AuthorityConfigs)


  const person = useSelector((state) => state.user.staff);
  const currentSemester = useSelector(
    (state) => state.semester.currentSemester
  );
  console.log("currentSemester", currentSemester);
  const [coursesByProgram, setCoursesByProgram] = useState([]);

  const colleges = useSelector((state) => state.college.colleges);
  const departments = useSelector((state) => state.department.departments);
  const courses = useSelector((state) => state.course.courses);

  function onHandleActivityLevelType(communityString) {
    setLevelType(communityString);


    dispatch(fetchAuthorityAccordingToType(communityString.toString()))

    if (communityString == "department") {
      dispatch(fetchDepartments())

    } else if (communityString == "college") {
      dispatch(fetchColleges())
    }
    else if (communityString == "program") {
      const userId = localStorage.getItem('userId');
      dispatch(fetchCurrentSemester(userId))
        .unwrap()
        .then((semester) => {
          dispatch(fetchPrograms(semester.semester_id));
        });
    }


    else if (communityString == "course") {
      dispatch(fetchCourses())
    }
  }


  const programs = useSelector((state) => state.program.programs);

  const persons = useSelector(
    (state) => state.user.staffList
  );



  const [start_date, setStartDate] = React.useState("");
  const [end_date, setEndDate] = React.useState("");

  function onStartDateChange(value, dateString) {
    setStartDate(dateString);
  }
  function onEndDateChange(value, dateString) {
    setEndDate(dateString);
  }
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Submit failed!");
  };

  let semester_id = currentSemester && currentSemester.semester_id;
  let academicYear_id = 1;

  const onFinish = async (values) => {
    // console.log("Received values of form: ", values);

    const toAddFacultyActivity = {
      ...values,
      start_date,
      end_date,
      semester_id,
      academicYear_id,

    };
    // console.log("toAddFacultyActivity: ", toAddFacultyActivity);
    const d = {
      id: id,
      updateProfessionalDevelopmentServiceActivity: toAddFacultyActivity
    }
    const resultAction = await dispatch(
      updateProfessionalDevelopmentServiceActivity(d)
    );

    if (updateProfessionalDevelopmentServiceActivity.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      history.push(
        `/citrine/faculty/teachingStaff/${personId}/professionalDevelopmentActivities`
      );
    } else {
      if (resultAction.payload) {
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const { Option } = Select;

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person?.person_details[0].first_name} ${person && person?.person_details[0].last_name}`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/professionalDevelopmentActivities`}
            >
              {t("Professional development")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Edit activity
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Type")}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size={200} showArrow>
                        <Option value="training">
                          training
                        </Option>
                        <Option value="course">course</Option>
                        <Option value="workshop">
                          workshop
                        </Option>
                        <Option value="other">other</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Activity Level")}
                      name="authorityLevelSelect"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size={200} showArrow
                        onChange={onHandleActivityLevelType}


                      >
                        <Option value="university">
                          university
                        </Option>
                        <Option value="college">
                          college
                        </Option>
                        <Option value="program">
                          program
                        </Option>
                        <Option value="course">
                          course
                        </Option>
                        <Option value="department">
                          department
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>



                  {/* { levelType == "college" ? ( */}
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Colleges")} name="college_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {colleges &&
                          colleges.map((program) => (
                            <Option key={program.id} value={program.id}>
                              {program.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  {/* ) : (<p></p>) } */}

                  {/* { levelType == "department" ? ( */}
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Departments")} name="department_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {departments &&
                          departments.map((program) => (
                            <Option key={program.id} value={program.id}>
                              {program.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  {/*  ) : (<p></p>) }  */}
                  {/* { levelType == "course" ? ( */}
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Courses")} name="course_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {courses &&
                          courses.map((program) => (
                            <Option key={program.id} value={program.id}>
                              {program.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  {/*  ) : (<p></p>) } */}
                  {/* { levelType == "program" ? ( */}
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Programs")} name="program_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {programs &&
                          programs.map((program) => (
                            <Option key={program.id} value={program.id}>
                              {program.program_degree_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  {/*  ) : (<p></p>) }  */}



















                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Title")}
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>



                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t("Start date")}
                      name="start_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the start date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onStartDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t("End date")}
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the end date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onEndDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Description")}
                      name="description"
                      rules={[
                        {
                          // required: true,
                          message: "Please enter the description",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </GridItem>



                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Theme")}
                      name="theme"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the theme",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Location")}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the location",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Organization")}
                      name="organization"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the theme",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Trainer")}
                      name="trainer"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the trainer",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Authority Person")}
                      name="authorityLevelConfig_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select the authors",
                        },
                      ]}
                    >
                      <Select
                        // mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {personsx &&
                            personsx.map((person) => (
                            <Option key={person.id} value={person.id}>
                              {/* {`${person.first_name} ${person.last_name}`} */}
                              {`${person.person_details[0].first_name}`}
                              {/*{person.user_details[0].username}*/}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Team members")}
                      name="team_members_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {persons.map((student) => (
                          <Option key={student.id} value={student.id}>
                            {`${person?.person_details[0].first_name} ${person?.person_details[0].last_name}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* </FormControl> */}

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Update")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
