// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import styles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";





















const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'fit-content',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
}));
const useStyles1 = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));
const useStyles2 = makeStyles(styles)
const useCardStyles = makeStyles(cardStyles);


export default function ProgramCriteria(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const classes1 = useStyles1();
    const classes2 = useStyles2();
    const classes3 = useStyles3();
    const dispatch = useDispatch();



    useEffect(() => {

    }, [dispatch]);

    const callback = (key) => {
        console.log(key);
    }


    return (
        <div>
            <GridItem>
                <Link to={`/citrine/AccreditationPrograms/`} >
                    <Card product className={classes.cardHover} style={{ height: 300 }} >

                        <CardBody>
                            <Link to={`/citrine/AccreditationPrograms/`} >
                                <p>{t('PLO 1 : Analyze a complex computing problem and to apply principles of computing and other relevant disciplines to identify solutions')}</p>
                                <p>{t('PLO 2 : Design, implement, and evaluate a computing-based solution to meet a given set of computing requirements in the context of the program’s discipline')}</p>
                                <p>{t('Plo 3 : Communicate effectively in a variety of professional contexts')}</p>
                                <p>{t('Plo 4 : Recognize professional responsibilities and make informed judgments in computing practice based on legal and ethical principles')}</p>
                                <p>{t('Plo 5 : Function effectively as a member or leader of a team engaged in activities appropriate to the program’s discipline')}</p>
                                <p>{t('Plo 6 : Apply computer science theory and software development fundamentals to produce computing-based solutions')}</p>


                            </Link>

                        </CardBody>
                    </Card>
                </Link>
            </GridItem>
        </div>
    )
}
