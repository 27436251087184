import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons
// core components
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import {
  fetchConclusionsDifficultiesByCourseId_And_Type,
  updateDifficultiesAction
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";

import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { useTranslation } from "react-i18next";

import {
  FormOutlined
} from "@ant-design/icons";
import {
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const useStyles4 = makeStyles(styles4);

export default function EditActivitiesAssessmentMethodsDifficultiesAction(props) {

  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();

  const [type, setType] = useState("AM");
  const [course_id, setCourse_id] = useState(props.idCourse);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const difficulties_SuggestedAction = useSelector((state) => state.ConclusionsDifficulties.ConclusionsDifficultiesList);

  const classes4 = useStyles4();

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    let object = {
      course_id: props.idCourse,
      type: "AM",
    }
    dispatch(
      fetchConclusionsDifficultiesByCourseId_And_Type(object)
    )
      .then(unwrapResult)
      .then((Difficulties_SuggestedAction) => {
        form.setFieldsValue({
          difficulties:
            Difficulties_SuggestedAction &&
            Difficulties_SuggestedAction[0] &&
            Difficulties_SuggestedAction[0].difficulties,
          suggested:
            Difficulties_SuggestedAction &&
            Difficulties_SuggestedAction[0] &&
            Difficulties_SuggestedAction[0].suggested,
        });
      });
  }, []);

  const { Option } = Select;

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    let object = {
      course_id: props.idCourse,
      type: "AM",
    }
    const updated = { ...values };
    const resultAction = await dispatch(
      updateDifficultiesAction({
        type,
        course_id,
        ...values,
      })
    );
    if (
      updateDifficultiesAction.fulfilled.match(resultAction)
    ) {
      openNotificationWithIcon("success", `Edit successfully`);
      dispatch(
        fetchConclusionsDifficultiesByCourseId_And_Type(object)
      );
      props.fetchConclusionsDifficulties();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FormOutlined
        onClick={(event) => {
          handleClickOpen(event);
        }}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {t("Edit Difficulties Experienced & Suggested Action")}
        </DialogTitle>
        <DialogContent>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="difficulties"
              label={t("Difficulties Experienced")}
              rules={[
                {
                  required: true,
                  message:
                    "Please input the difficulties_and_Challenges field.",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item
              name="suggested"
              label={t("Suggested Action*")}
              rules={[
                {
                  required: true,
                  message: "Please input the suggested field.",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: "20px" }}
                color="danger"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="primary" color="info" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
