import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  professionalDevelopmentActivities: [],
  AllProfessionalDevelopmentActivities: [],
  professionalDevelopmentActivitiesStatus: "idle",
  professionalDevelopmentActivity: null,
  professionalDevelopmentActivityStatus: "idle",
  AllProfessionalDevelopmentActivityStatus: "idle",

}

export const fetchProfessionalDevelopment = createAsyncThunk('professionalDevelopment/fetchProfessionalDevelopment', async (id,{ rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/professionalDevelopmentFaculty/byStaff/?staff_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    //console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
   // console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})


export const fetchAllProfessionalDevelopmentActivities = createAsyncThunk(
  "professionalDevelopment/fetchAllProfessionalDevelopmentActivities",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/professionalDevelopmentFaculty/all/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const addProfessionalDevelopment = createAsyncThunk(
  'professionalDevelopment/addProfessionalDevelopment',
  async (toAddActivity, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/professionalDevelopmentFaculty/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
     // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const fetchProfessionalDevelopmentById = createAsyncThunk('professionalDevelopment/fetchProfessionalDevelopmentById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/professionalDevelopmentFaculty/${id}/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
   // console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})


export const updateProfessionalDevelopmentServiceActivity = createAsyncThunk(
  "professionalDevelopmentFaculty/updateProfessionalDevelopmentFaculty",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/professionalDevelopmentFaculty/${data.id}/edit/`,
        data.updatedDevelopmentServiceActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      //console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);

export const deleteProfessionalDevelopmentFaculty = createAsyncThunk(
  "professionalDevelopmentFaculty/deleteProfessionalDevelopmentFaculty",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/professionalDevelopmentFaculty/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


const professionalDevelopmentSlice = createSlice({
  name: 'professionalDevelopment',
  initialState,
  extraReducers: {

    [fetchProfessionalDevelopment.pending]: (state, action) => {
      state.professionalDevelopmentActivitiesStatus = 'loading'
    },
    [fetchProfessionalDevelopment.fulfilled]: (state, action) => {
      state.professionalDevelopmentActivitiesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.professionalDevelopmentActivities = action.payload
    },
    [fetchProfessionalDevelopment.rejected]: (state, action) => {
      state.professionalDevelopmentActivitiesStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
       // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },



    [fetchProfessionalDevelopmentById.pending]: (state, action) => {
      state.professionalDevelopmentActivityStatus = 'loading'
    },
    [fetchProfessionalDevelopmentById.fulfilled]: (state, action) => {
      state.professionalDevelopmentActivityStatus = 'succeeded'
      // Add any fetched posts to the array
      state.professionalDevelopmentActivity = action.payload
    },
    [fetchProfessionalDevelopmentById.rejected]: (state, action) => {
      state.professionalDevelopmentActivityStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
       // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

   

    [fetchAllProfessionalDevelopmentActivities.pending]: (state, action) => {
      state.AllProfessionalDevelopmentActivityStatus = "loading";
    },
    [fetchAllProfessionalDevelopmentActivities.fulfilled]: (state, action) => {
      state.AllProfessionalDevelopmentActivityStatus = "succeeded";

      // Add any fetched posts to the array
      state.AllProfessionalDevelopmentActivities = action.payload;
    },
    [fetchAllProfessionalDevelopmentActivities.rejected]: (state, action) => {
      state.AllProfessionalDevelopmentActivityStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    

  },

})

export default professionalDevelopmentSlice.reducer

