import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";

const ProgramHistory = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>Background Information: Program History</h3>
      <p className={styles.reportParagraphTitle}>
        The last general review was held <span>{data.lastReviewDate}</span>
      </p>
      <p className={styles.reportParagraphTitle}>
        The following is a summary of the major program changes with an emphasis
        on changes occurring since the last general review:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />
      <p className={styles.reportParagraphTitle}>
        The following is a summary of the program's history initially defined for
        the program:
      </p>
      <p>{data.history}</p>
    </>
  );
};

export default ProgramHistory;
