import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";

const GraduationRequirements = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>STUDENTS: Graduation Requirements</h3>
      <p className={styles.reportParagraphTitle}>
        The degree awarded for the <strong>{data.programName}</strong> program
        is <strong>{data.awardedDegreeName}</strong>.
      </p>
      <p className={styles.reportParagraphTitle}>
        The following is a summary of the graduation requirements for the
        program and the process for ensuring and documenting that each graduate
        completes all graduation requirements for the program:
      </p>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is the list of the graduation requirements for the
        program:
      </p>
      <ul>
        {data?.graduationRequirements?.map((requirement, index) => (
          <li key={index}>{requirement}</li>
        ))}
      </ul>
    </>
  );
};

export default GraduationRequirements;
