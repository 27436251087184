import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPersons } from "../../reduxToolkit/features/person/PersonsSlice";
import { courseUpdateToGroupAssessment } from "../../reduxToolkit/features/course/CoursesSlice";
//import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Radio } from "antd";
import { Form, Input, Checkbox } from "antd";
import { Upload, message, Button, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// import Button from "components/CustomButtons/Button.js";
import {
  fetchGroups,
  UpdateGroup,
  addNewGroup,
  // addNewCourseStudent,
} from "../../reduxToolkit/features/group/GroupSlice";
import { fetchCourseById } from "../../reduxToolkit/features/course/CoursesSlice";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramsSlice";
import { useTranslation } from "react-i18next";
import { addNewAttachment } from "../../reduxToolkit/features/attachment/AttachmentsSlice";

import ErrorModal from "./ErrorModal.jsx";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
export default function GroupModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const groupStatus = useSelector((state) => state.group.groupStatus);

  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    dispatch(fetchGroups());
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    console.log("Success:", values.name);
    let resource_id = props.courseID;
    let resource = "course";
    let model = "student";
    let document = selectedFile;
    let name = values.name;

    const uploadData = new FormData();
    uploadData.append("resource_id", resource_id);
    uploadData.append("resource", resource);
    uploadData.append("model", model);
    uploadData.append("document", document);
    uploadData.append("name", name);

    const resultAction = await dispatch(addNewGroup(uploadData));

    if (addNewGroup.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", ` success Added `);
      dispatch(fetchProgramById(props.program.id));
      handleClose();
    } else {
      if (resultAction.payload) {
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        //handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        //handleClose();
      }
    }

    
  };

  const onUpdateFinish = async (values) => {
    console.log("Success:", values.name);
    let resource_id = props.semester_id;
    let resource = "student";
    let model = "course";
    let document = selectedFile;
    let name = props.group_name;

    const uploadData = new FormData();
    uploadData.append("resource_id", resource_id);
    uploadData.append("resource", resource);
    uploadData.append("model", model);
    uploadData.append("document", document);
    uploadData.append("name", name);

    const resultAction = await dispatch(UpdateGroup(uploadData));

    if (UpdateGroup.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", ` success Added `);
      dispatch(fetchProgramById(props.program.id));
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        //handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        //handleClose();
      }
    }
    //handleClose();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    let obj = {
      course_id: props.course.id,
    };
    dispatch(fetchGroups(obj));
  }, [dispatch]);

  const groups = useSelector((state) => state.group.groups);

  const error = useSelector((state) => state.person.error);

  //console.log("groups", groups);

  const { Option } = Select;
  const [groupAssessment_ids, setGroupAssessment_ids] = React.useState([]);

  function onGroupChange(value) {
    console.log("value", value);
    setGroupAssessment_ids(value);
    console.log("groupAssessment_ids", groupAssessment_ids);
  }

  function onGroupBlur() {
    console.log("blur");
  }

  function onGroupFocus() {
    console.log("focus");
  }

  function onGroupSearch(val) {
    console.log("search:", val);
  }

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

 // console.log("props.AssessmentMethodID", props.AssessmentMethodID);

 // console.log("props.course", props.course);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    console.log(selectedFile, "selectedFile");
  };

  const handleSend = async () => {
    try {
      dispatch(
        courseUpdateToGroupAssessment({
          id: props.courseID,
          modifiedCourse: { groupAssessment_ids },
        })
      ).then(() => {
        dispatch(fetchProgramById(props.program.id));
        props.handleClose();
      });
    } catch (err) {
      console.log("Failed to save the CourseAssessmentMethod: ", err);
    } finally {
      setUpdateRequestStatus("idle");
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
          props.handleClose();
        }}
        style={{ color: "#AC79B0" }}
      >
        {t("Add Group")}
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Add Group")}</DialogTitle>
        <DialogContent>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1} style={{ marginRight: "20px" }}>
              {t("Upload Group")}
            </Radio>
            <Radio value={2}>{t("Update Group")}</Radio>
          </Radio.Group>
          <br></br>
          <br></br>
          <div>
            {value === 1 ? (
              <GridContainer
                xs={12}
                sm={12}
                md={12}
                style={{ width: 600, height: "200px" }}
              >
                <Form
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item
                        label={t("Planning Group Name")}
                        name="name"
                        value={name}
                        rules={[
                          {
                            required: true,
                            message: "Please input your name group!",
                          },
                        ]}
                        onChange={(event) => {
                          setName(event.target.value);
                          console.log("name", name);
                        }}
                      >
                        <Select
                          showSearch
                          // mode="multiple"
                          style={{ width: 300 }}
                          dropdownStyle={{ zIndex: 10000 }}
                          placeholder={t("Select a group")}
                          optionFilterProp="children"
                          onChange={onGroupChange}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {props.course &&
                            props.course &&
                            props.course.planning_groups &&
                            props.course.planning_groups.map((group) => (
                              <Option key={group.id}
                                value={group.name}
                              >{`${group.name}`}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <GridItem xs={12} sm={12} md={4}>
                      <strong style={{ paddingLeft: "10px", fontSize: "14px" }}>
                        {t("Students List csv")} :
                      </strong>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                      <input type="file" name="file" onChange={changeHandler} />
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ width: 500, textAlign: "end" }}
                  >
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                        style={{ marginRight: "20px" }}
                      >
                        <Button
                          color="danger"
                          htmltype="submit"
                           onClick={handleClose}
                        >
                          {t("Cancel")}
                        </Button>
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                        <Button color="info" htmltype="submit">
                          {t("Submit")}
                        </Button>
                      </Form.Item>
                    </GridItem>
                  </GridContainer>
                </Form>
                <ErrorModal
                  open={openErrorModal}
                  handleClose={handleCloseErrorModal}
                  handledError={handledError}
                />
              </GridContainer>
            ) : (
              <GridContainer
                xs={12}
                sm={12}
                md={12}
                style={{ width: 600, height: "200px" }}
              >
                <Form
                  name="basic"
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onUpdateFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item
                        label={t("Assessment Group Name")}
                        name="name"
                        value={name}
                        rules={[
                          {
                            required: true,
                            message: "Please input your name group!",
                          },
                        ]}
                        onChange={(event) => {
                          setName(event.target.value);
                          console.log("name", name);
                        }}
                      >
                        <Select
                          showSearch
                          // mode="multiple"
                          style={{ width: 300 }}
                          dropdownStyle={{ zIndex: 10000 }}
                          placeholder={t("Select a group")}
                          optionFilterProp="children"
                          onChange={onGroupChange}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {props.course &&
                            props.course &&
                            props.course.groups_assessment &&
                            props.course.groups_assessment.map((group) => (
                              <Option
                                value={group.name}
                              >{`${group.name}`}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <GridItem xs={12} sm={12} md={4}>
                      <strong style={{ paddingLeft: "10px", fontSize: "14px" }}>
                        {t("Students List csv")} :
                      </strong>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                      <input type="file" name="file" onChange={changeHandler} />
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ width: 500, textAlign: "end" }}
                  >
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                        style={{ marginRight: "20px" }}
                      >
                        <Button
                          color="danger"
                          htmlType="submit"
                          onClick={handleClose}
                        >
                          {t("Cancel")}
                        </Button>
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                        <Button color="info" htmlType="submit">
                          {t("Submit")}
                        </Button>
                      </Form.Item>
                    </GridItem>
                  </GridContainer>
                </Form>
                <ErrorModal
                  open={openErrorModal}
                  handleClose={handleCloseErrorModal}
                  handledError={handledError}
                />
              </GridContainer>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
