import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseById } from '../../../../../reduxToolkit/Course/coursesSpecSlice';


// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';





import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { Breadcrumb } from 'antd';
import { fetchCourseContacts } from "reduxToolkit/features/CourseContact/CourseContactSlice";

import DropDownButtonCourseContact from "../../../../Components/DropDownButtonCourseContact";

import { useTranslation } from 'react-i18next';

import { notification } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

const useStyles = makeStyles(styles);


export default function CourseContactList({ match }) {
    const { t } = useTranslation();

    const classes = useStyles();
    const dispatch = useDispatch()


    const { id } = match.params

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };


    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const fetchAllCourseContacts = async () => {
        try {
            const resultAction = await dispatch(fetchCourseContacts(id)).unwrap();
            dispatch(fetchCourseById(id));
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };
    useEffect(() => {
        fetchAllCourseContacts()
        if (localStorage.getItem("CourseState") != "Draft") {
            setDisabledStatus(true);
        }
    }, [dispatch])
    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const CourseContacts = useSelector((state) => state.CourseContact.CourseContacts)

    console.log('CourseContacts', CourseContacts)
    // if (!course) {
    //     return (
    //         <section>
    //             <h2>Course not found!</h2>
    //         </section>
    //     )
    // }

    return (
        <div>
            <GridItem>
                <Breadcrumb separator=">">



                    <Breadcrumb.Item>
                        <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}>{course && course.title && course.title}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/specification/course/${course && course.id && course.id}/CourseContacts`} style={{ color: "orange" }} > {t('Course Contact List')}
                        </Link>
                    </Breadcrumb.Item>

                </Breadcrumb>
            </GridItem>
            <GridContainer>
                <GridItem >

                    {disabledStatus ? <p></p> :
                        <Link to={`/citrine/specification/course/${course && course.id && course.id}/CourseContacts/create`}>
                            <Button color="info"
                                className={classes.updateProfileButton}
                            >
                                {t('Add')}
                            </Button>
                        </Link>
                    }
                </GridItem>
            </GridContainer>
            <GridContainer>
                { CourseContacts?.map(Element => (
                    <GridItem key={Element?.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 200, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={10} sm={10} md={10}>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            {/* <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                {t('Coordinator')} : {Element?.coordinator_details[0]?.first_name} {Element?.coordinator_details[0]?.last_name}
                                            </a> */}
                                            <a style={{ color: "orange" }}>
                                                {t('Coordinator')} : 
                                                {Element?.coordinator_details?.map((item)=>{
                                                    return (
                                                        <>
                                                        <span> {item?.first_name}</span> <span>{item?.last_name}</span>
                                                        </>
                                                    )
                                                })}   
                                               
                                            </a>
                                        </h4>





                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{ zIndex: 1 }} >
                                        {disabledStatus ? <p></p> :
                                            <DropDownButtonCourseContact CourseContact_ID={Element.id} courseID={course && course.id} />
                                        }
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
            {/* <GridContainer>
                <GridItem>
                    <Link to={`/citrine/courses/`}>
                        <Button color="info"
                            className={classes.updateProfileButton}
                        >
                            Exit
                        </Button>
                    </Link>
                </GridItem>
            </GridContainer> */}
        </div >

    );

}




