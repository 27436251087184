// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import CoursesEvaluation from './CoursesEvaluation';
import EmployerEvaluation from './EmployerEvaluation';
import ProgramEvaluationSurvey from './ProgramEvaluationSurvey';
import StudentsEvaluationSurvey from './StudentsEvaluationSurvey';
import AssessmentAnalysis from "./AssessmentAnalysis"
import {
    fetchImprovementByResourceAndResourceIdAndType,
} from "../../../../../reduxToolkit/features/annualProgramAnalysis/annualProgramAnalysisSlice";
import { fetchEvaluationList } from "../../../../../reduxToolkit/features/CourseEvaluation/CourseEvaluationSlice.js";


const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'fit-content',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
}));
const useStyles1 = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));
const useStyles2 = makeStyles(styles)
const useCardStyles = makeStyles(cardStyles);


export default function ProgramEvaluationPage(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const classes1 = useStyles1();
    const classes2 = useStyles2();
    const classes3 = useStyles3();
    const dispatch = useDispatch();

    const [activeChildTabKey, setActiveChildTabKey] = useState(1)

    let type = "programEvaluation";
    let resource = "annualProgram";
    let resource_id = props.AnnualProgramReport?.id;

    let data = {
        resource,
        resource_id,
        type,
    };

    let obj = {
        resource: "program",
        resource_id: props.AnnualProgramReport?.program,
        type: "pes"
    }
    useEffect(() => {
        dispatch(fetchEvaluationList(obj))
    }, []);

    const CourseEvaluationList = useSelector(
        (state) => state.CourseEvaluation.CourseEvaluationList
    );

    const fetchAnalysis = () => {
        dispatch(fetchImprovementByResourceAndResourceIdAndType(data))
            .then(unwrapResult)
            .catch((error) => {
                console.error(error);
            })
    }
    useEffect(() => {
        if (props.activeParentTabKey === "7") {
            fetchAnalysis()
        }
    }, [props.activeParentTabKey]);


    return (
        <div >
            <Tabs onChange={(activeKey) => { setActiveChildTabKey(activeKey); console.log(activeKey) }} type="card" defaultActiveKey="1" destroyInactiveTabPane>
                <TabPane tab={t("Courses Evaluation Survey (CES)")} key="1" forceRender>
                    <CoursesEvaluation AnnualProgramReport={props.AnnualProgramReport} />
                </TabPane>

                <TabPane tab={t("Students Evaluation Survey (SES)")} key="2" forceRender>
                    <StudentsEvaluationSurvey AnnualProgramReport={props.AnnualProgramReport} resource={"program"} type={"ses"} resource_id={props.AnnualProgramReport?.program} />
                </TabPane>
                <TabPane tab={t("Program Evaluation Survey (PES)")} key="3" forceRender>
                    <ProgramEvaluationSurvey CourseEvaluationList={CourseEvaluationList && CourseEvaluationList} AnnualProgramReport={props.AnnualProgramReport} resource={"program"} type={"pes"} resource_id={props.AnnualProgramReport?.program} />
                </TabPane>

                <TabPane tab={t("Employer Survey (ES)")} key="4" forceRender>
                    <EmployerEvaluation AnnualProgramReport={props.AnnualProgramReport} resource={"program"} type={"es"} resource_id={props.AnnualProgramReport?.program} />
                </TabPane>

                <TabPane tab={t("Analysis")} key="5" forceRender>
                    <AssessmentAnalysis AnnualProgramReport={props.AnnualProgramReport} fetchAnalysis={fetchAnalysis} activeChildTabKey={activeChildTabKey} />
                </TabPane>
            </Tabs>
        </div>
    )
}
