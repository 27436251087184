import { makeStyles } from "@material-ui/core/styles";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
//In your react App.js or yourComponent.js file add these lines to import
import { notification } from "antd";
import Card from "components/Card/Card.js";
import * as Survey from "survey-react";
import "survey-react/modern.css";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { addNewSurveyResponse } from '../../../../reduxToolkit/features/Survey/SurveyResponseSlice';
import { fetchSurveyTemplateById } from '../../../../reduxToolkit/features/Survey/SurveySlice';
import "./Survey.scss";




const useTitleStyles = makeStyles(titleStyles);

Survey
    .StylesManager
    .applyTheme("modern");


export default function SurveyForm() {

    const { t } = useTranslation();
    const titleClasses = useTitleStyles();

    //Define Survey JSON
    let { id } = useParams();

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSurveyTemplateById(id))
    }, [dispatch])

    const surveyTemplate = useSelector((state) => state.survey.surveyTemplate)


    console.log("surveyTemplate", surveyTemplate && surveyTemplate.template && surveyTemplate.template)

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        // message.error('Submit failed!');
    };

    const onFinish = async () => {

        const resultAction = await dispatch(

            addNewSurveyResponse({ model })
        );

        if (addNewSurveyResponse.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk

            openNotificationWithIcon("success", `added`);
            handleClose();
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                console.log("resultAction.error  from component", resultAction.error);
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        }
    };


    //Define a callback methods on survey complete
    const onComplete = (survey, options) => {
        //Write survey results into database
        console.log(survey.data);
        // localStorage.setItem('surveyResults', JSON.stringify(survey.data));
        // console.log("localStorage.getItem('surveyResults')",localStorage.getItem('surveyResults'))
        // console.log("unexisting",localStorage.getItem('unexisting'))
        // console.log("localStorage.getItem('surveyResults') PARSED",JSON.parse(localStorage.getItem('surveyResults')))


        let existingResults = JSON.parse(localStorage.getItem("surveyResults"));
        if (existingResults == null) existingResults = [];

        // Save allEntries back to local storage
        existingResults.push(survey.data);
        localStorage.setItem("surveyResults", JSON.stringify(existingResults));





        const stringified = JSON.stringify(survey.data)
        console.log("stringified", stringified)

        const parsed = JSON.parse(stringified)
        console.log("parsed", parsed)

        console.log(Object.values(survey.data));
        const resArray = Object.values(survey.data)
        console.log(Object.prototype.toString.call(survey.data) === '[object Object]')
        // console.log(Object.prototype.toString.call("data") === '[object Object]')
        // console.log(Object.prototype.toString.call(["survey","data"]) === '[object Object]')
        const filter = resArray.filter(x => Object.prototype.toString.call(x) === '[object Object]');
        const map1 = filter.map(x => {
            // if(Object.prototype.toString.call(x) === '[object Object]') 
            return Object.values(x)
        });
        console.log(filter)
        console.log(map1)
    }


    //  let tableauFormaté = tableauOrig.map(obj => {
    //   let rObj = {};
    //   rObj[obj.clé] = obj.valeur;
    //   return rObj;
    // });

    const model = new Survey.Model(surveyTemplate && surveyTemplate.template && surveyTemplate.template);
    console.log('model', model)
    return (

        //Create the model and pass it into react Survey component
        //You may create survey model outside the render function and use it in your App or component
        //The most model properties are reactive, on their change the component will change UI when needed.
        <div>
            <GridContainer
                alignItems="center"
                style={{
                    borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
                }}>
                <GridItem>

                    <Link to={`/citrine/surveyTemplates`}>
                        <span className={titleClasses.title}> {t('Survey templates')} </span>
                    </Link>
                    <Link to={`/citrine/surveyTemplates/${surveyTemplate && surveyTemplate.id}`}>
                        <span className={titleClasses.title}> {`> ${surveyTemplate && surveyTemplate.name}`} </span>
                    </Link>

                </GridItem>
            </GridContainer>
            <Card>
                <Survey.Survey model={model}
                //onComplete={onComplete} 
                />
                {/* <Form
                                name="basic"
                                layout="vertical"
                                //   labelCol={{ span: 8 }}
                                //   wrapperCol={{ span: 16 }}
                                //   initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                //   autoComplete="off"
                                scrollToFirstError
                            >
                                {/* </FormControl> */}
                {/* <GridContainer justifyContent="center">
                                    <GridItem>
                                        <Form.Item>
                                            <GridContainer>
                                                {/* <GridItem>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            handleClose();
                                                        }}
                                                    >
                                                        {t("Cancel")}
                                                    </Button>
                                                </GridItem> */}
                {/* <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t("Save")}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                            </Form>   */}

                {/* The alternative way. react Survey component will create survey model internally
            return (<Survey.Survey json={this.json} onComplete={this.onComplete} />); */}

                {/* You may pass model properties directly into component or set it into model
            <Survey.Survey model={model} mode="display" />
            or
            model.mode="display"
            <Survey.Survey model={model} />
            You may change model properties outside render function.
            If needed react Survey Component will change its behavior and change UI. */}
            </Card>
        </div>
    )
}