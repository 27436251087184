import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import {
  addNewCourseAssessmentMethod,
  fetchCourseAssessmentMethodById,
  updateCourseAssessmentMethod,
} from "../../reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSlice";
import { Link } from "react-router-dom";
// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";
import { SchoolRounded } from "@material-ui/icons";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";
import { Select, Form, notification } from "antd";
import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";
import {
  fetchPloIndicatorById,
  updatePloIndicator,
} from "reduxToolkit/features/PLOIndicator/PLOIndicatorsSlice";
import { fetchPlos } from "reduxToolkit/features/PLO/PLOsSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker, Space } from "antd";
import {
  fetchConclusionsDifficultiesByCourseId_And_Type,
  updateDifficultiesAction,
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { Input } from "antd";

import { useTranslation } from "react-i18next";
import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";

import {
  warningColor,
  whiteColor,
} from "../../assets/jss/material-dashboard-pro-react";
import {
  UserOutlined,
  PhoneOutlined,
  FileDoneOutlined,
  FormOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useStyles4 = makeStyles(styles4);

export default function EditCommentsStudentResults(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();


  const [type, setType] = useState("SR");
  const [course_id, setCourse_id] = useState(props.idCourse);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const classes4 = useStyles4();
  useEffect(() => {
    let object = {
      course_id: props.idCourse,
      type: "SR",
    }
    dispatch(
      fetchConclusionsDifficultiesByCourseId_And_Type(object)
    )
      .then(unwrapResult)
      .then((Difficulties_SuggestedAction) => {
        form.setFieldsValue({
          comment_students_results:
            Difficulties_SuggestedAction &&
            Difficulties_SuggestedAction[0] &&
            Difficulties_SuggestedAction[0].comment_students_results,
        });
      });
  }, []);

  const difficulties_SuggestedAction = useSelector(
    (state) => state.ConclusionsDifficulties.ConclusionsDifficultiesList
  );



  const { Option } = Select;
  const [open, setOpen] = React.useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    let object = {
      course_id: props.idCourse,
      type: "SR",
    }
    const updated = { ...values };
    console.log(updated, "updated");
    console.log(props.courseReport, "props.courseReport");
    const resultAction = await dispatch(
      updateDifficultiesAction({
        type,
        course_id,
        ...values,
      })
    );
    if (
      updateDifficultiesAction.fulfilled.match(resultAction)
    ) {
      openNotificationWithIcon("success", `Edit successfully`);
      dispatch(
        fetchConclusionsDifficultiesByCourseId_And_Type(object)
      );
      props.fetchConclusionsDifficulties();
      // form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        // form.resetFields();
        handleClose();
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };




  return (
    <div>
      <FormOutlined
        onClick={(event) => {
          handleClickOpen(event);
        }}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {t("Edit Comments Students Results")}
        </DialogTitle>
        <DialogContent>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="comment_students_results"
              label={t("Comments")}
              rules={[
                {
                  required: true,
                  message:
                    "Please input the Comments field.",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: "20px" }}
                color="danger"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="primary" color="info" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
