import { createSlice } from '@reduxjs/toolkit'
import i18next from "i18next";

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    languages: {
      en: { label: "English", rtl: false },
      // ar: { label: "العربية", rtl: true },
      // fr: { label: "Français", rtl: false }
    },
    currentLanguage: localStorage.getItem("i18nextLng")
  },
  reducers: {
    languageChanged: (state, action) => {
      i18next.changeLanguage(action.payload);
      state.currentLanguage = localStorage.getItem("i18nextLng")
    },
    getCurrentLanguage: (state, action) => {
      state.currentLanguage = localStorage.getItem("i18nextLng")
    },
  }
})

// Action creators are generated for each case reducer function
export const { languageChanged, getCurrentLanguage } = languageSlice.actions

export default languageSlice.reducer
