import React from "react";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    fetchAssessmentById, deleteAttachmentAssessmentEvidence
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice';
import { DataGrid } from '@mui/x-data-grid';
import { Tabs, notification } from 'antd';

import { DocumentBaseUrl } from "../../../../assets/utils/accessToken"
import EvidenceFileUploader from "./EvidenceFileUploader"
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import ErrorModal from "../../../Components/ErrorModal";




export default function EvidenceList(props) {
    const { Assessmentid } = props
    const dispatch = useDispatch()

    //error modal
    const [errorModalIsOpen, setErrorModalIsOpen] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setErrorModalIsOpen(true);
    };
    const handleCloseErrorModal = () => {
        setErrorModalIsOpen(false);
    };
    //delete modal
    const [open, setOpen] = React.useState(false);
    const [toBeDeletedDocument, setToBeDeletedDocument] = React.useState(null);

    const handleOpenDeleteModal = () => {
        setOpen(true);
    };
    const handleCloseDeleteModal = () => {
        setOpen(false);
    };

    const columns = [
        {
            field: 'title',
            headerName: 'Document',
            width: 500,
            renderCell: (params) => (
                <a style={{}} href={DocumentBaseUrl + `${params.value}`} target="_blank">
                    {params.value.slice(10)}
                </a>

            )
        },
        {
            field: 'created_at',
            headerName: 'Uploaded at',
            width: 200
        },
        {
            field: 'id',
            headerName: 'Delete',
            renderCell: (params) => (
                <GridContainer justifyContent='center'>
                    <GridItem>
                        {/* {params.value.getFullYear()}
                    <Button
                        variant="contained"
                        size="small"
                        style={{ marginLeft: 16 }}
                        tabIndex={params.hasFocus ? 0 : -1}
                    >
                        Delete
                    </Button> */}
                        <Button
                            justIcon
                            round
                            color="primary"
                            onClick={() => {
                                handleOpenDeleteModal();
                                setToBeDeletedDocument(params.value)
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </GridItem>
                </GridContainer>
            ),
        },


    ];

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };
    const handleDelete = async (id) => {
        dispatch(deleteAttachmentAssessmentEvidence(id))
            .then(unwrapResult)
            .then(() => {
                dispatch(fetchAssessmentById(Assessmentid))
                openNotificationWithIcon('success', `File Deleted successfully`)
            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                setHandledError(rejectedValueOrSerializedError);
                handleOpenErrorModal();
            });
    }

    useEffect(() => {
        if (Assessmentid) {
            dispatch(fetchAssessmentById(Assessmentid))
        }
    }, [Assessmentid])


    const evidence_types_list = useSelector(
        (state) => state.AssessmentMethods.assessmentById,
    )
    let tabItems = []

    // if (evidence_types_list) {
    //     for (const type of evidence_types_list) {
    //         type.evidence_documents.map((document) => {
    //             const constructedDocument = {
    //                 id: document.id,
    //                 evidence_type: type.evidence_type,
    //                 isRequired: type.required,
    //                 title: document.document.slice(10),
    //                 url: document.document,

    //             }
    //             evidenceDocuments.push(constructedDocument)
    //             return
    //         })
    //     }
    //     console.log("evidence types for this assessment from evidence list V2 component", evidence_types_list)
    //     console.log("evidence documents for this assessment from evidence list V2 component", evidenceDocuments)
    // }
    // if (evidence_types_list.length > 0) {
    // for (const type in evidence_types_list) {
    tabItems = evidence_types_list?.map((type) => {
        const rows = type.evidence_documents?.map((document) => {
            return ({
                id: document.id,
                evidence_type: type.evidence_type,
                isRequired: type.required,
                title: document.document,
            })
        })
        const label = type.required ? type.evidence_type + " (Required)" : type.evidence_type
        return ({
            key: type.id,
            label,
            children: <>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <EvidenceFileUploader type_id={type.id} resource={props.resource} resource_id={props.resourceId} Assessmentid={Assessmentid} />
                    </GridItem>
                </GridContainer>
                <div style={{ height: 400 }}>
                    <DataGrid
                        // rows={evidenceDocuments.length > 0 ? evidenceDocuments : []}
                        rows={rows || []}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                    />
                </div>
            </>

        })
    })
    // }
    // const items = [
    //     {
    //         key: '1',
    //         label: `By Exam`,
    //         //   children: <GridContainer>{content}</GridContainer>,
    //         children: <Card withoutBoxShadow style={{ height: 400 }}>
    //             <CardBody>
    //                 <DataGrid
    //                     rows={evidenceDocuments.length > 0 ? evidenceDocuments : []}
    //                     columns={columns}
    //                     pageSize={5}
    //                     rowsPerPageOptions={[5]}
    //                     checkboxSelection
    //                     disableSelectionOnClick
    //                     experimentalFeatures={{ newEditingApi: true }}
    //                 />
    //             </CardBody>
    //         </Card >
    //     },
    //     {
    //         key: '2',
    //         label: `By CLO`,
    //         //   children:<ClosQuestionListFaculty courseId={data?.[0]?.course_id} />
    //         children: <Card withoutBoxShadow style={{ height: 400 }}>
    //             <CardBody>
    //                 <DataGrid
    //                     rows={evidenceDocuments.length > 0 ? evidenceDocuments : []}
    //                     columns={columns}
    //                     pageSize={5}
    //                     rowsPerPageOptions={[5]}
    //                     checkboxSelection
    //                     disableSelectionOnClick
    //                     experimentalFeatures={{ newEditingApi: true }}
    //                 />
    //             </CardBody>
    //         </Card >

    //     },

    // ];
    const onChange = (key) => {
        console.log(key);
    };

    return (
        // <div>
        //     <GridContainer>
        //         {assessment?.map((item) => (
        //             <GridItem xs={12} sm={12} md={4} >
        //                 <div style={{ marginLeft: "20px", width: '300px', height: '300px' }}>
        //                     <Card sx={{ minWidth: 500, minHeight: 500 }} style={{ boxShadow: "-5px -5px 8px 5px rgba(0,0,0,0.3)" }}>
        //                         <CardHeader >
        //                             <Typography variant="body2" color="text.secondary">
        //                                 {item.required ? <GradingIcon style={{ color: 'red' }} /> : <SubjectIcon />} {item.evidence_type}
        //                             </Typography>
        //                             {/* <Typography variant="body2" color="text.secondary">
        //             <GradingIcon /> <SubjectIcon />
        //               {`Required: ${item.required} `}
        //             </Typography> */}
        //                         </CardHeader>
        //                         <CardContent>

        //                             <Typography variant="body2" color="text.secondary">
        //                                 <UploadAttachmentAssessmentEvidence item={item} resource={props.resource} resourceId={props.resourceId} />
        //                             </Typography>

        //                         </CardContent>
        //                     </Card>
        //                 </div>
        //             </GridItem>
        //         ))}
        //     </GridContainer>
        // </div>
        <>
            <Card withPadding>
                {/* <CardHeader>
                    <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                            <Typography>
                                <strong style={{
                                    color: '#6C7B9D',
                                    textAlign: "center"
                                }}>Evidence List</strong>
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </CardHeader> */}
                <CardBody>
                    <Tabs items={tabItems} onChange={onChange} />
                </CardBody>
            </Card >
            <SuperGeneralDeleteModal
                delete_Element={handleDelete}
                Element_ID={toBeDeletedDocument}
                open={open}
                handleClose={handleCloseDeleteModal}
            />
            <ErrorModal
                open={errorModalIsOpen}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </>
    )
}
