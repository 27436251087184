import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  mailTemplates: [],
  mailTemplatesStatus: 'idle',
  mailTemplateStatus: 'idle',
  error: null,
  mailTemplate:null,
 
  id:null
}


export const fetchMailTemplates = createAsyncThunk('mailTemplate/fetchMailTemplates', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/mailTemplate/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchMailTemplateById = createAsyncThunk('mailTemplate/fetchMailTemplateById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/mailTemplate/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
  export const addNewMailTemplate = createAsyncThunk(
    'mailTemplate/addNewMailTemplate',
    async (initialMailTemplate) => {
      console.log('initial mailTemplate', initialMailTemplate)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/mailTemplate/create/`,initialMailTemplate ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const mailTemplateUpdate = createAsyncThunk(
    'mailTemplate/mailTemplateUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/mailTemplate/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedMailTemplate')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the mailTemplate: ', error)

}
    }
  )

  export const deleteMailTemplate = createAsyncThunk('mailTemplate/deleteMailTemplate', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/mailTemplate/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data,'response.data')

    return response.data
  })
const  mailTemplatesSlice = createSlice({
  name: ' mailTemplate',
  initialState,
  extraReducers: {
    [fetchMailTemplates.pending]: (state, action) => {
        state.mailTemplatesStatus = 'loading'
      },
      [fetchMailTemplates.fulfilled]: (state, action) => {
        state.mailTemplatesStatus = 'succeeded'
        // Add any fetched posts to the array
        state.mailTemplates = action.payload
      },
      [fetchMailTemplates.rejected]: (state, action) => {
        state.mailTemplatesStatus = 'failed'
        state.error = action.payload
      },

      [fetchMailTemplateById.pending]: (state, action) => {
        state.mailTemplateStatus = 'loading'
      },
      [fetchMailTemplateById.fulfilled]: (state, action) => {
        state.mailTemplateStatus = 'succeeded'

        // Add any fetched posts to the array
        state.mailTemplate = action.payload
      },
      [fetchMailTemplateById.rejected]: (state, action) => {
        state.mailTemplateStatus = 'failed'
        state.error = action.payload
      },
      [addNewMailTemplate.fulfilled]: (state, action) => {
        state.mailTemplates.push(action.payload)
      },
      [mailTemplateUpdate.fulfilled]: (state, action) => {
        console.log("update succeeded!!!")
      },
      [deleteMailTemplate.fulfilled]: (state, action) => {
      
         state.mailTemplateStatus = 'succeeded'
      state.mailTemplates = state.mailTemplates.splice(state.mailTemplates.findIndex((arrow) => arrow.id === action.payload), 1);
      //  const index = state.mailTemplates.findIndex((mailTemplate) => mailTemplate.id !== action.payload);
      
     
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    mailTemplateUpdated(state, action) {
      const { id,name } = action.payload
      const existingMailTemplate = state.mailTemplates.find(mailTemplate => mailTemplate.id === id)
      if (existingMailTemplate) {
        existingMailTemplate.name = name
      }
    },
    
  }
}})

export const { mailTemplateAdded, mailTemplateUpdated, getId} = mailTemplatesSlice.actions

export default mailTemplatesSlice.reducer

export const selectAllMailTemplates = state => state.mailTemplate.mailTemplates
