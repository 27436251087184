// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Form, Input, notification } from 'antd'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
// @material-ui/icons
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  addNewAdmissionRequirement,
  admissionRequirementUpdate,
  fetchAdmissionRequirementById,
  fetchAdmissionRequirements,
} from 'reduxToolkit/features/admissionRequirement/AdmissionRequirementSpecSlice'
import ErrorModal from 'views/Components/ErrorModal'

const useStyles = makeStyles(styles)

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
}

// const classes = useStyles();
export default function CreateProgramAdmissionRequirement({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const [form] = Form.useForm()
  console.log('id', id)

  const isAddMode = isNaN(id)
  console.log('id from match is add mode', isAddMode)
  const classes = useStyles()
  const useTitleStyles = makeStyles(titleStyles)

  const titleClasses = useTitleStyles()
  const [title, setTitle] = useState('')
  const [code, setCode] = useState('')

  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const onTitleChanged = (e) => setTitle(e.target.value)
  const onCodeChanged = (e) => setCode(e.target.value)

  const dispatch = useDispatch()
  const canSave = [title, code].every(Boolean) && addRequestStatus === 'idle'
  const history = useHistory()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewAdmissionRequirement({ ...values }),
    )
    if (addNewAdmissionRequirement.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `Added successfully`)
      form.resetFields()
      dispatch(fetchAdmissionRequirements())
      history.push(`/citrine/configuration/program/admissionRequirements`)
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      admissionRequirementUpdate({
        id: id,
        modified: {
          ...values,
        },
      }),
    )
    if (admissionRequirementUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `edit successfully`)
      form.resetFields()
      dispatch(fetchAdmissionRequirements())
      history.push(`/citrine/configuration/program/admissionRequirements`)
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchAdmissionRequirementById(id))
        .then(unwrapResult)
        .then((AD) => {
          console.log(AD, 'AD')
          form.setFieldsValue({
            id: AD.id,
            title: AD.title,
            code: AD.code,
          })
        })
    }
  }, [dispatch, id])

  const req = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirement,
  )

  return (
    <div>
      
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span> {t('Program Configurations')} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/configuration/program/admissionRequirements`}
                      style={{ color: 'orange' }}
                    >
                      {t('Admission Requirements')}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: 'orange' }}>
                    {t('Create')}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item
                        name="code"
                        label="Code"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="submit"
                          style={{ marginRight: '20px' }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/configuration/program/admissionRequirements`,
                            )
                          }
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && req && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span> {t('Program Configurations')} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/configuration/program/admissionRequirements`}
                      style={{ color: 'orange' }}
                    >
                      {t('Admission Requirements')}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: 'orange' }}>
                    {t('Edit')}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item
                        name="code"
                        label="Code"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: '20px' }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/configuration/program/admissionRequirements`,
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          color="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  )
}
