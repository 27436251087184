import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  AnnualProgramReports: [],
  ProgramImprovementPlanList: [],
  DifficultiesAndChallengesList: [],
  AnnualProgramsStatus: "idle",
  AnnualProgramStatus: "idle",
  ProgramImprovementPlanStatus: "idle",
  DifficultiesAndChallengesStatus: "idle",
  error: null,
  AnnualProgramReport: null,
  id: null,
};

export const fetchAnnualProgramReports = createAsyncThunk(
  "AnnualProgramReport/fetchAnnualProgramReports",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/annualProgramReport/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    // const response = jsonData
    //console.log(response.data, 'response.data course')

    return response.data;
  }
);
export const fetchAnnualProgramReportById = createAsyncThunk(
  "AnnualProgramReport/fetchAnnualProgramReportById",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/annualProgramReport/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    console.log(response.data, "response.data fetch annualProgramReport by id");

    return response.data;
  }
);
export const addNewAnnualProgramReport = createAsyncThunk(
  "AnnualProgramReport/addNewAnnualProgramReport",
  async (initialAnnualProgramReport, { rejectWithValue }) => {
    //console.log('initialAnnualProgramReport', initialAnnualProgramReport)
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/annualProgramReport/create/`,
        initialAnnualProgramReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data)
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const deleteAnnualProgramReport = createAsyncThunk(
  "AnnualProgramReport/deleteAnnualProgramReport",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/annualProgramReport/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    //console.log(response.data, ' delete response.data')

    return response.data;
  }
);

export const UpdateAnnualProgramReport = createAsyncThunk(
  "AnnualProgramReport/UpdateAnnualProgramReport",
  async (data, thunkAPI) => {
    //console.log(data, 'UpdatedAnnualProgramReport')
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/annualProgramReport/${data.id}/edit/`,
        data.updatedAnnualProgramReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(data, 'updatedAnnualProgramReport')
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (error) {
      //console.error('Failed to update the courseReport: ', error)
    }
  }
);

export const addNewDifficultiesAndChallenges = createAsyncThunk(
  "AnnualProgramReport/addNewDifficultiesAndChallenges",
  async (DifficultiesAndChallenges) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/difficultiesAndChallenges/create/`,
      DifficultiesAndChallenges,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const addNewProgramImprovementPlan = createAsyncThunk(
  "AnnualProgramReport/addNewProgramImprovementPlan",
  async (ProgramImprovementPlan) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/programImprovementPlan/create/`,
      ProgramImprovementPlan,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);
export const fetchProgramImprovementPlan = createAsyncThunk(
  "AnnualProgramReport/fetchProgramImprovementPlan",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/programImprovementPlan/?program_id=${id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data, 'response.data')
    return response.data;
  }
);


export const deleteProgramImprovementPlan = createAsyncThunk(
  "AnnualProgramReport/deleteAnnualProgramReport",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/programImprovementPlan/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    //console.log(response.data, ' delete response.data')

    return response.data;
  }
);



export const fetchDifficultiesAndChallenges = createAsyncThunk(
  "AnnualProgramReport/fetchDifficultiesAndChallenges",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/difficultiesAndChallenges/?program_id=${id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);


export const deleteDifficultiesAndChallenge = createAsyncThunk(
  "AnnualProgramReport/deleteDifficultiesAndChallenge",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/difficultiesAndChallenges/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    //console.log(response.data, ' delete response.data')

    return response.data;
  }
)

export const AffectCoordinatorToAnnualProgramReport = createAsyncThunk(
  'annualProgramReport/AffectCoordinatorToAnnualProgramReport',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/annualProgramReport/update/${data.id}/`, data.data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      //  console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)
const AnnualProgramReportSlice = createSlice({
  name: "AnnualProgramReport",
  initialState,
  extraReducers: {},
  reducers: {
    AnnualProgramReportUpdate(state, action) {
      const { id, program, state_object, due_date, sent_to } = action.payload;
      const existingAnnualProgramReport = state.AnnualProgramReports.find(
        (AnnualProgramReport) => AnnualProgramReport.id === id
      );
      if (existingAnnualProgramReport) {
        existingAnnualProgramReport.program = program;
        existingAnnualProgramReport.state_object = state_object;
        existingAnnualProgramReport.due_date = due_date;
        existingAnnualProgramReport.sent_to = sent_to;
      }
    },
  },
  extraReducers: {
    // fetchPloReports
    [fetchAnnualProgramReports.pending]: (state, action) => {
      state.AnnualProgramsStatus = "loading";
    },
    [fetchAnnualProgramReports.fulfilled]: (state, action) => {
      state.AnnualProgramsStatus = "succeeded";
      state.AnnualProgramReports = action.payload;
    },
    [fetchAnnualProgramReports.rejected]: (state, action) => {
      state.AnnualProgramsStatus = "failed";
      state.error = action.payload;
    },

    [fetchProgramImprovementPlan.pending]: (state, action) => {
      state.ProgramImprovementPlanStatus = "loading";
    },
    [fetchProgramImprovementPlan.fulfilled]: (state, action) => {
      state.ProgramImprovementPlanStatus = "succeeded";
      state.ProgramImprovementPlanList = action.payload;
    },
    [fetchProgramImprovementPlan.rejected]: (state, action) => {
      state.ProgramImprovementPlanStatus = "failed";
      state.error = action.payload;
    },

    [fetchDifficultiesAndChallenges.pending]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "loading";
    },
    [fetchDifficultiesAndChallenges.fulfilled]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "succeeded";
      state.DifficultiesAndChallengesList = action.payload;
    },
    [fetchDifficultiesAndChallenges.rejected]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "failed";
      state.error = action.payload;
    },

    // fetchPloReportById
    [fetchAnnualProgramReportById.pending]: (state, action) => {
      state.AnnualProgramStatus = "loading";
    },
    [fetchAnnualProgramReportById.fulfilled]: (state, action) => {
      state.AnnualProgramStatus = "succeeded";
      state.AnnualProgramReport = action.payload;
    },
    [fetchAnnualProgramReportById.rejected]: (state, action) => {
      state.AnnualProgramStatus = "failed";
      state.error = action.payload;
    },
    // addNewPloReport
    [addNewAnnualProgramReport.pending]: (state, action) => {
      state.AnnualProgramStatus = "loading";
    },
    [addNewAnnualProgramReport.fulfilled]: (state, action) => {
      state.AnnualProgramStatus = "succeeded";
      state.AnnualProgramReports.push(action.payload);
    },
    [addNewAnnualProgramReport.rejected]: (state, action) => {
      state.AnnualProgramStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewDifficultiesAndChallenges.fulfilled]: (state, action) => { },

    [addNewProgramImprovementPlan.fulfilled]: (state, action) => { },
    // deletePloReport
    [deleteAnnualProgramReport.fulfilled]: (state, action) => {
      // console.log(action)
    },
    //PloReportUpdate
    // [UpdateAnnualProgramReport.fulfilled]: (state, action) => {
    //   // console.log(action)
    // },
  },
});

export const {
  AnnualProgramReportAdded,
  AnnualProgramReportUpdate,
} = AnnualProgramReportSlice.actions;

export default AnnualProgramReportSlice.reducer;
