import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom'
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Select,
  InputNumber,
} from 'antd'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
// style for this view
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import { infoColor } from 'assets/jss/material-dashboard-pro-react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  fetchPrograms,
} from 'reduxToolkit/features/program/ProgramsSlice.js'
import {
  fetchCurrentSemester,
  fetchSemesters,
} from 'reduxToolkit/features/semester/SemestersSlice'
import {
  fetchStaff,
  fetchStaffById,
} from '../../../../../reduxToolkit/features/user/UserSlice'

import TextArea from 'antd/lib/input/TextArea'
import { fetchCategories } from '../../../../../reduxToolkit/features/category/CategorySlice'
import { fetchColleges } from '../../../../../reduxToolkit/features/college/CollegesSlice'
import { fetchDepartments } from '../../../../../reduxToolkit/features/department/DepartmentsSlice'
import { fetchPublisherConfigs } from '../../../../../reduxToolkit/features/Publisher/publisherSlice'
import { fetchResearchDirectionConfigs } from '../../../../../reduxToolkit/features/ResearchDirection/researchDirectionSlice'
import {
  addRoleConfig,
  fetchRoleConfigs,
} from '../../../../../reduxToolkit/features/RolesConfig/RolesConfigSlice'
import { fetchTypesConfigs } from '../../../../../reduxToolkit/features/TypesConfig/TypesConfigSlice'
import { fetchStudents } from '../../../../../reduxToolkit/features/user/UserSlice'
import { addNewResearchAndInnovationActivity } from '../../../../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice'

import moment from 'moment'

import ErrorModal from '../../../../Components/ErrorModal'

import { MediaBaseUrl } from 'assets/utils/accessToken'

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

export default function CreateResearchAndInnovationActivity(props) {

  const userId = localStorage.getItem('userId')
  const { t } = useTranslation()
  let history = useHistory()
  let { id } = useParams()
  const [form] = Form.useForm()

  const [collegesReviewEditing, setCollegesReviewEditing] = useState([])
  const [departmentReviewEditing, setDepartmentReviewEditing] = useState([])
  const [researchType, setResearchType] = useState('')
  const [levelType, setLevelType] = useState('')
  const [roleConf_ids, setKeyWordsSpec_ids] = useState([])
  const [link, setLink] = useState('')
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [to_date, setToDate] = useState(moment().format('YYYY-MM-DD'))
  const [from_date, setFromDate] = useState(moment().format('YYYY-MM-DD'))
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [indexed, setIndexedValue] = useState(false)
  const [peer_reviewed, setReviewedValue] = useState(false)
  const [budget, setBudget] = useState('')

  const dispatch = useDispatch()

  const person = useSelector((state) => state.user.staff)
  const persons = useSelector((state) => state.user.staffList)
  const students = useSelector((state) => state.user.students)
  const currentSemester = useSelector((state) => state.semester.currentSemester)
  const semesters = useSelector((state) => state.semester.semesters)
  const programs = useSelector((state) => state.program.programs)
  const authorsList = useSelector((state) => state.authorityConfig.AuthorityConfigs,)
  const configAcoordingtoType = useSelector((state) => state.authorityConfig.AuthorityConfigsByType,)
  const colleges = useSelector((state) => state.college.colleges)
  const courses = useSelector((state) => state.course.courses)
  const researchDirectionItems = useSelector((state) => state.ResearchDirection.researchDirectionConfigs,)
  const publisherConfigsItems = useSelector((state) => state.PublisherConfig.PublisherConfigs,)
  const typesConfigsItems = useSelector((state) => state.typesConfig.TypesConfigs,)
  const rolesConfigsItems = useSelector((state) => state.rolesConfig.RoleConfigs,)
  const categoryConfItems = useSelector((state) => state.category.categories)
  const academicYears = useSelector((state) => state.academicYear.academicYears)
  const departments = useSelector((state) => state.department.departments)

  colleges.map((el) => collegesReviewEditing.push(el.id))
  const listCollegesReviewEditing = [...new Set(collegesReviewEditing)]

  departments.map((el) => departmentReviewEditing.push(el.id))
  const listDepartmentEditingView = [...new Set(departmentReviewEditing)]

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment(from_date);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Submit failed!')
  }

  function onStartDateChange(value, dateString) {
    setFromDate(moment(value).format('YYYY-MM-DD'))
    setToDate('');
  }

  function onEndDateChange(value, dateString) {
    setToDate(dateString)
  }

  function onDateChange(value, dateString) {
    setDate(moment(value).format('YYYY-MM-DD'))
  }

  let semester_id = currentSemester && currentSemester.semester_id
  let academicYear_id = 1

  const onFinish = async (values) => {
    const resultAction = await dispatch(addNewResearchAndInnovationActivity({...values, to_date,from_date,faculty_id : id,date, ug_pg_ids : []}))
    if(addNewResearchAndInnovationActivity.fulfilled.match(resultAction)){
      openNotificationWithIcon("success", `Added successfully`);
      history.push(`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`);
    }else if (resultAction.payload){
      setHandledError(resultAction.error)
      handleOpenErrorModal()
    } else {
      setHandledError(resultAction.error)
      handleOpenErrorModal()
    }
  }

  function onHandleCommunityType(communityString) {
    setResearchType(communityString)
  }

  function onChangeCheckboxIndex(e) {
    setIndexedValue(e.target.checked)
  }

  function onChangeCheckboxReviewed(e) {
    setReviewedValue(e.target.checked)
  }

  const handleChange = (value) => {
    if (value.length > 0) {
      // handle result here
      let chosenKWObjects =
        rolesConfigsItems &&
        rolesConfigsItems.filter((kw) => value.includes(kw.role))
      let ids = chosenKWObjects.map((el) => {
        return el.id
      })
      let mapped =
        rolesConfigsItems &&
        rolesConfigsItems.map((el) => {
          return el.role
        })
      const role = [...value].pop()
      if (mapped.includes(role)) {
        setKeyWordsSpec_ids(ids)
      } else {
        dispatch(addRoleConfig({ role }))
          .then(unwrapResult)
          .then((newKeyWord) => {
            setKeyWordsSpec_ids(ids.concat(newKeyWord.id))
          })
      }
    } else {
      setKeyWordsSpec_ids([])
    }
  }

  const { Option } = Select

  useEffect(() => {
    dispatch(fetchCurrentSemester(userId))
      .unwrap()
      .then((semester) => {
        dispatch(fetchPrograms(semester.semester_id))
          .unwrap()
          .then((res) => {})
          .catch((err) => {})
      })
      .catch((err) => console.log(err))
    dispatch(fetchStaffById(id))
    dispatch(fetchSemesters())
    dispatch(fetchDepartments())
    dispatch(fetchColleges())
    dispatch(fetchResearchDirectionConfigs())
    dispatch(fetchPublisherConfigs())
    dispatch(fetchStaff())
    dispatch(fetchStudents())
    dispatch(fetchRoleConfigs())
    dispatch(fetchTypesConfigs())
    dispatch(fetchCategories())
  }, [researchType])

  useEffect(() => {
    form.setFieldsValue({
      academicYear_id: currentSemester?.academicYear_id,
    })
    dispatch(fetchRoleConfigs())
  }, [])

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t('Faculty')}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t('Teaching Staff')}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${
                person && person.person_details[0].last_name
              }`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`}
            >
              {t('Research and innovation Activities')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>

      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Add Research Activity
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Academic Year')}
                      name="academicYear"
                    >
                      <Select
                        onChange={(e) => console.log(e)}
                        //disabled={researchType == "Review-Editing"}
                        //mode="multiple"

                        showSearch
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {academicYears &&
                          academicYears.map((academicYear) => (
                            <Option
                              key={academicYear.id}
                              value={academicYear.id}
                            >
                              {academicYear.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t('Colleges')} name="college_id">
                      <Select
                        onChange={(e) => console.log(e)}
                        //disabled={researchType == "Review-Editing"}
                        //mode="multiple"
                        showSearch
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {colleges &&
                          colleges.map((program) => (
                            <Option
                              /*disabled={researchType == "Review-Editing"}*/ key={
                                program.id
                              }
                              value={program.id}
                            >
                              {program.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t('Departments')} name="department_id">
                      <Select
                        //disabled={researchType == "Review-Editing"}
                        //mode="multiple"
                        showSearch
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {departments &&
                          departments.map((program) => (
                            <Option
                              /*disabled={researchType == "Review-Editing"}*/ key={
                                program.id
                              }
                              value={program.id}
                            >
                              {program.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Type')}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the type',
                        },
                      ]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        size={200}
                        showArrow
                        onChange={onHandleCommunityType}
                      >
                        <Option value="Review-Editing">Review Editing</Option>
                        <Option value="Research Proposals">
                          Research Proposals
                        </Option>
                        <Option value="Conference Presentation">
                          Conference Presentation
                        </Option>
                        <Option value="Memberships">Memberships</Option>
                        <Option value="Research Grants">Research Grants</Option>
                        <Option value="Workshop-Seminar">
                          Workshop-Seminar
                        </Option>
                        <Option value="Review Letter">Review Letter</Option>
                        <Option value="Professional Magazines">
                          Professional Magazines
                        </Option>
                        <Option value="Journal Editorials">
                          Journal Editorials
                        </Option>
                        <Option value="Acad.-Professional meetings">
                          Acad.-Professional meetings
                        </Option>

                        <Option value="Thesis Supervesion">
                          Thesis Supervesion
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  {researchType == 'Review-Editing' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title')}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Category')}
                          name="category_research_activities"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the category',
                            },
                          ]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            size={200}
                            showArrow
                          >
                            <Option value="Journal">Journal</Option>
                            <Option value="Conference">Conference</Option>
                            <Option value="Book">Book</Option>
                            <Option value="Book Chapter">Book Chapter</Option>
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Name of the Journal/Conf/Book')}
                          name="category_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the name',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Publisher')}
                          name="publisherConfig"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the publisher',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {publisherConfigsItems &&
                              publisherConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Local Research Direction')}
                          name="researchDirection"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('From date')}
                          name="from_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={from_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('To date')}
                          name="to_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={to_date}
                            onChange={onEndDateChange}
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional Information')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Link')}
                          name="link"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required.',
                            },
                            {
                              type: 'url',
                              message: 'This field must be a valid url.',
                            },
                          ]}
                        >
                          <Input placeholder="https://example.com" />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Research Proposals' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title')}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Name of the member')}
                          name="authors_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the name',
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Role')}
                          name="roleConf_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the role',
                            },
                          ]}
                        >
                          <Select
                            //mode="multiple"
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {rolesConfigsItems &&
                              rolesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.role}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Proposed Project Leader')}
                          name="proposed_project_leader_id"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the project leader',
                            },
                          ]}
                        >
                          <Select
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Investigator Name (Internal)')}
                          name="investigator_name_internal_id"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the investigator name',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Investigator Name (External)')}
                          name="investigator_name_external"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the investigator name',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Proposed Projet Organization')}
                          name="proposed_project_organization"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the project',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title of proposal')}
                          name="title_of_proposal"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Budget')}
                          name="budget"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the budget',
                            },
                          ]}
                        >
                          <InputNumber style={{ width: '250px' }} />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Local Research Direction')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('From date')}
                          name="from_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={from_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('To date')}
                          name="to_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={to_date}
                            onChange={onEndDateChange}
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Conference Presentation' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title')}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Authors')}
                          name="authors_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the author',
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Conference Presenter')}
                          name="conference_presenter"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the conference presenter',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Conference Title')}
                          name="conference_title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the conference title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Local Research Direction')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location')}
                          name="location"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the location',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item name="indexed">
                          <Checkbox
                            onChange={onChangeCheckboxIndex}
                            checked={indexed}
                          >
                            {`Indexed`}
                          </Checkbox>
                        </Form.Item>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item name="peer_reviewed">
                          <Checkbox
                            onChange={onChangeCheckboxReviewed}
                            checked={peer_reviewed}
                          >
                            {`Peer Reviewed`}
                          </Checkbox>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional Information')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Memberships' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Role')}
                          name="roleConf_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the role',
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {rolesConfigsItems &&
                              rolesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.role}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Organization')}
                          name="organization"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the organization',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('From date')}
                          name="from_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={from_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('To date')}
                          name="to_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={to_date}
                            onChange={onEndDateChange}
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Link')}
                          name="link"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter the Link',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Thesis Supervesion' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title')}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Name')}
                          name="authors_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the name',
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Thesis Title')}
                          name="thesis_title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the thesis title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Role')}
                          name="roleConf_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the role',
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {rolesConfigsItems &&
                              rolesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.role}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Local Research Direction')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('UG or PG')}
                          name="ug_pg_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select UG/PG',
                            },
                          ]}
                        >
                          <Select
                            //mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {/* <Option value={"Undergraduate"}>Undergraduate</Option>
                            <Option value={"Postgraduate"}>Postgraduate</Option> */}
                            {categoryConfItems &&
                              categoryConfItems.map((program) => (
                                <Option key={program.id} value={program.id}>
                                  {program.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Students')}
                          name="student_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the students',
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {/* {students &&
                              students.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.student_details.first_name} ${person.student_details.last_name}`}
                                </Option>
                              ))} */}
                            {students &&
                              students.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.student_details?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person.student_details.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.student_details &&
                                    person.student_details.first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.student_details &&
                                    person.student_details.last_name
                                  }`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Starting Semester Year')}
                          name="starting_semester_year"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Starting Semester Year',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {semesters &&
                              semesters.map((semester) => (
                                <Option key={semester.id} value={semester.id}>
                                  {`${semester.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Ending Semester Year')}
                          name="ending_semester_year"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Ending Semester Year',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {semesters &&
                              semesters.map((semester) => (
                                <Option key={semester.id} value={semester.id}>
                                  {`${semester.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Research Grants' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title')}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Role')}
                          name="roleConf_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the role',
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {rolesConfigsItems &&
                              rolesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.role}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Project Leader (internal)')}
                          name="project_leader_internal_id"
                          rules={[
                            {
                              required: false,
                              message: 'Please select the project leader',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Project Leader (external)')}
                          name="project_leader_external"
                          rules={[
                            {
                              required: false,
                              message: 'Please select the project leader',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Budget')}
                          name="budget"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the budget',
                            },
                          ]}
                        >
                          <InputNumber style={{ width: '250px' }} />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location  Of Research Directory')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: false,
                              message:
                                'Please select the Local research Directory',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Principal Investigator')}
                          name="principal_investigator"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select the Principal Investigator',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Co Investigator')}
                          name="co_investigators"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the Co Investigator',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Internal or External')}
                          name="internal_external"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Internal or External',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value={'internal'}>Internal</Option>
                            <Option value={'external'}>External</Option>
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Funding Agency')}
                          name="funding_agency"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the Funding Agency',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Expected Output')}
                          name="expected_output"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the Expected Output',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('From date')}
                          name="from_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={from_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('To date')}
                          name="to_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={to_date}
                            onChange={onEndDateChange}
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Workshop-Seminar' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title')}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Seminar Presenter (internal)')}
                          name="seminar_presenter_internal_id"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the seminar presenter',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Seminar Presenter (external)')}
                          name="seminar_presenter_external"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the seminar presenter',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Seminar')}
                          name="seminar_paper_title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the seminar paper title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Workshop Location')}
                          name="workshop_location"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the workshop location',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location  Of Research Directory')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select the Local research directory',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('From date')}
                          name="from_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={from_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('To_date')}
                          name="to date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onEndDateChange}
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Acad.-Professional meetings' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Role')}
                          name="roleConf_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the role',
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {rolesConfigsItems &&
                              rolesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.role}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Meeting head (internal)')}
                          name="meeting_head_internal_id"
                          rules={[
                            {
                              required: false,
                              message: 'Meeting head (internal)',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Meeting head (external)')}
                          name="meeting_head_external"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter the Meeting head',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title of meeting')}
                          name="title_of_meeting"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title of meeting',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Organization')}
                          name="organization"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the organization',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location')}
                          name="location"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the location',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location Research Directory')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: false,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('Date')}
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Review Letter' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Role')}
                          name="roleConf_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the role',
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {rolesConfigsItems &&
                              rolesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.role}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Research review leader (internal)')}
                          name="research_review_leader_internal_id"
                          rules={[
                            {
                              required: false,
                              message:
                                'Please select the research review leader',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch={false}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {persons &&
                              persons.map((person) => (
                                <Option value={person && person.id}>
                                  {!person?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {person?.person_details[0]?.first_name
                                        .charAt(0)
                                        .toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].first_name
                                  }`}{' '}
                                  {`${''}`}{' '}
                                  {`${
                                    person &&
                                    person.person_details &&
                                    person.person_details[0].last_name
                                  }`}
                                </Option>
                              ))}
                            {/* {persons &&
                              persons.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.person_details[0].first_name} ${person.person_details[0].last_name}`}
                                </Option>
                              ))} */}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Research review leader (external)')}
                          name="research_review_leader_external"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please enter the research review leader',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Research review project organization')}
                          name="research_review_project_organization"
                          rules={[
                            {
                              required: false,
                              message:
                                'Please enter the research review project organization',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title of review letter')}
                          name="title_of_review_letter"
                          rules={[
                            {
                              required: true,
                              message:
                                'Please enter the title of review letter',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location Of Research Directory')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: false,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('From date')}
                          name="from_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={from_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('To date')}
                          name="to_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={to_date}
                            onChange={onEndDateChange}
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Professional Magazines' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title of article')}
                          name="title_of_article"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title of article',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Magazine name')}
                          name="magazine_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the magazine name',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Issue No.')}
                          name="issue_no"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the issue No.',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location Of Research Directory')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: false,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Semester')}
                          name="semester"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the Semester',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {semesters &&
                              semesters.map((semester) => (
                                <Option key={semester.id} value={semester.id}>
                                  {`${semester.academicYear_detail}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('Date')}
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {researchType == 'Journal Editorials' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Title')}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Role')}
                          name="roleConf_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the role',
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {rolesConfigsItems &&
                              rolesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.role}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Type')}
                          name="typesConfig_ids"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the type',
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {typesConfigsItems &&
                              typesConfigsItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.type}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Location Of Research Directory')}
                          name="researchDirection_id"
                          rules={[
                            {
                              required: false,
                              message:
                                'Please select the Local research direction',
                            },
                          ]}
                        >
                          <Select
                            // mode="multiple"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {researchDirectionItems &&
                              researchDirectionItems.map((person) => (
                                <Option key={person.id} value={person.id}>
                                  {`${person.name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Volume')}
                          name="volume"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter the Volume',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Issue No.')}
                          name="issue_no"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter the issue No.',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Program')}
                          name="program"
                          rules={[
                            {
                              required: true,
                              message: 'Please select the Program',
                            },
                          ]}
                        >
                          <Select
                            //mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          >
                            {programs &&
                              programs.map((program) => (
                                <Option key={program.id} value={program.id}>
                                  {`${program.program_degree_name}`}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Index')}
                          name="index"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter the Index',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('From date')}
                          name="from_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={from_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <Form.Item
                          label={t('To date')}
                          name="to_date"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the date',
                            },
                          ]}
                        >
                          <DatePicker
                            value={to_date}
                            onChange={onStartDateChange}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Link')}
                          name="link"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter the Link',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Additional informations')}
                          name="additional_information"
                        >
                          <TextArea />
                        </Form.Item>
                      </GridItem>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
