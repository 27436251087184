import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseById } from '../../../../../../reduxToolkit/Course/coursesSpecSlice';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';


import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";


import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { Breadcrumb } from 'antd';
import { fetchActivitiesAssessmentMethodsSpec } from "reduxToolkit/features/ActivitiesAssessmentMethod/ActivitiesAssessmentMethodSpecSlice";

import DropDownActivitiesAssessmentMethods from "../../../../../Components/DropDownActivitiesAssessmentMethods";

import { useTranslation } from 'react-i18next';

import { notification } from "antd";
import ErrorModal from "views/Components/ErrorModal";
const useNavBarStyles = makeStyles(navBarStyles);

const useStyles = makeStyles(styles);

export default function ActivitiesAssessmentMethodsList({ match }) {

    const { t } = useTranslation();

    const navBarClasses = useNavBarStyles();

    const classes = useStyles();
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton
    const { id } = match.params

    const dispatch = useDispatch()

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);

    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const ActivitiesAssessmentMethodsSpec = useSelector((state) => state.ActivitiesAssessmentMethodSpec.ActivitiesAssessmentMethodsSpec)
    const courseStatus = useSelector((state) => state.CourseSpec.courseSpecificationStatus)
    const error = useSelector((state) => state.course.error)

    useEffect(() => {
        fetchAllActivitiesAssessmentMethodsSpec()
        if (localStorage.getItem("CourseState") !== "Draft") {
            setDisabledStatus(true);
        }
    }, [])

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const fetchAllActivitiesAssessmentMethodsSpec = async () => {
        try {
            const resultAction = await dispatch(fetchActivitiesAssessmentMethodsSpec(id)).unwrap();
            dispatch(fetchCourseById(id));
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    return (
        <div>
            <GridItem>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to={`/citrine/CourseSpecificationList/`}>
                            {t('Courses List')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}>{course && course.title && course.title}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods`} style={{ color: "orange" }} > {t("Activities Assessment Methods List")}
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </GridItem>
            <GridContainer >
                <GridItem >
                    {disabledStatus ? <p></p> :
                        <Link to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods/create`}>
                            <Button color="info"
                                className={classes.updateProfileButton}
                            >
                                {t("Add")}
                            </Button>
                        </Link>
                    }
                </GridItem>
            </GridContainer>
            <GridContainer>
                {ActivitiesAssessmentMethodsSpec && ActivitiesAssessmentMethodsSpec.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 150, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={12} sm={12} md={10}>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                            Planned Assessment Methods :{Element && Element.planned_assessment_method_spec_details && Element.planned_assessment_method_spec_details[0].name}
                                            </a>
                                        </h4>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                            Grading :{Element?.grading}
                                            </a>
                                        </h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                        {disabledStatus ? <p></p> :
                                            <DropDownActivitiesAssessmentMethods ActivitiesAssessmentMethods_ID={Element.id} courseID={course && course.id} />
                                        }
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div >
    );
}
