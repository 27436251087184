import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  assessmentModes: [],
  assessmentModesStatus: 'idle',
  assessmentModeStatus: 'idle',
  error: null,
  assessmentMode: null,
  id: null
}

export const fetchAssessmentModes = createAsyncThunk('assessmentMode/fetchAssessmentModes', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/assessmentMode/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchAssessmentModeById = createAsyncThunk('assessmentMode/fetchAssessmentModeById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/assessmentMode/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewAssessmentMode = createAsyncThunk(
  'assessmentMode/addNewAssessmentMode',
  async (initialAssessmentMode) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/assessmentMode/create/`, initialAssessmentMode, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateAssessmentMode = createAsyncThunk(
  'assessmentMode/updateAssessmentMode',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/assessmentMode/${data.id}/edit/`, data.updatedAssessmentMode, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedAssessmentMode')
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the assessmentMode: ', error)

    }
  }
)

export const deleteAssessmentMode = createAsyncThunk('assessmentMode/deleteAssessmentMode', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/assessmentMode/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
const assessmentModesSlice = createSlice({
  name: ' assessmentMode',
  initialState,
  extraReducers: {

    [fetchAssessmentModes.pending]: (state, action) => {
      state.assessmentModesStatus = 'loading'
    },
    [fetchAssessmentModes.fulfilled]: (state, action) => {
      state.assessmentModesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.assessmentModes = action.payload
    },
    [fetchAssessmentModes.rejected]: (state, action) => {
      state.assessmentModesStatus = 'failed'
      state.error = action.payload
    },

    [fetchAssessmentModeById.pending]: (state, action) => {
      state.assessmentModestatus = 'loading'
    },
    [fetchAssessmentModeById.fulfilled]: (state, action) => {
      state.assessmentModestatus = 'succeeded'

      // Add any fetched posts to the array
      state.assessmentMode = action.payload
    },
    [fetchAssessmentModeById.rejected]: (state, action) => {
      state.assessmentModestatus = 'failed'
      state.error = action.payload
    },
    [addNewAssessmentMode.fulfilled]: (state, action) => {
      state.assessmentModes.push(action.payload)
    },
    [deleteAssessmentMode.fulfilled]: (state, action) => {
      // state.assessmentModes.push(action.payload)
    },
    [updateAssessmentMode.fulfilled]: (state, action) => {
      const result = state.assessmentModes.filter(assessmentMode => assessmentMode.id !== action.payload.id);
      // console.log("assessmentMode id from filter from slice",assessmentMode.id)
      result.push(action.payload)
      state.assessmentModes = result
      console.log("action.payload.id from slice",action.payload)

    },
  },

})


export default assessmentModesSlice.reducer

 export const selectAllAssessmentModes = state => state.assessmentMode.assessmentModes