import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  HTMLTemplates: [],
  HTMLTemplatesReports: [],
  HTMLTemplatesSurveyMails: [],
  HTMLTemplatesStatus: 'idle',
  HTMLTemplate: null,
  HTMLTemplateStatus: 'idle',
  error: null,
}

export const fetchHTMLTemplates = createAsyncThunk(
  'HTMLTemplate/fetchHTMLTemplates',
  async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/htmlTemplates/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    return response.data
  },
)
export const fetchHTMLTemplatesReports = createAsyncThunk(
  'HTMLTemplate/fetchHTMLTemplatesReports',
  async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/htmlTemplates/Reports/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    return response.data
  },
)
export const fetchHTMLTemplatesSurveyMails = createAsyncThunk(
  'HTMLTemplate/fetchHTMLTemplatesSurveyMails',
  async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/htmlTemplates/SurveyMails/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    return response.data
  },
)

export const fetchHTMLTemplateById = createAsyncThunk(
  'HTMLTemplate/fetchHTMLTemplateById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/htmlTemplates/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    return response.data
  },
)
export const addNewHTMLTemplate = createAsyncThunk(
  'HTMLTemplate/addNewHTMLTemplate',
  async (initialHTMLTemplate) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(
      BaseUrl + `/htmlTemplates/create/`,
      initialHTMLTemplate,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    return response.data
  },
)

export const updateHTMLTemplate = createAsyncThunk(
  'HTMLTemplate/updateHTMLTemplate',
  async (data, {rejectWithValue}) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/htmlTemplates/${data.id}/edit/`,
        data.updatedHTMLTemplate,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteHTMLTemplate = createAsyncThunk(
  'HTMLTemplate/deleteHTMLTemplate',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(
      BaseUrl + `/htmlTemplates/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    return response.data
  },
)

const HTMLTemplatesSlice = createSlice({
  name: ' HTMLTemplate',
  initialState,
  extraReducers: {
    [fetchHTMLTemplates.pending]: (state, action) => {
      state.HTMLTemplatesStatus = 'loading'
    },
    [fetchHTMLTemplates.fulfilled]: (state, action) => {
      state.HTMLTemplatesStatus = 'succeeded'
      state.HTMLTemplates = action.payload
    },
    [fetchHTMLTemplates.rejected]: (state, action) => {
      state.HTMLTemplatesStatus = 'failed'
      state.error = action.payload
    },

    [fetchHTMLTemplatesReports.pending]: (state, action) => {
      state.HTMLTemplatesStatus = 'loading'
    },
    [fetchHTMLTemplatesReports.fulfilled]: (state, action) => {
      state.HTMLTemplatesStatus = 'succeeded'
      state.HTMLTemplatesReports = action.payload
    },
    [fetchHTMLTemplatesReports.rejected]: (state, action) => {
      state.HTMLTemplatesStatus = 'failed'
      state.error = action.payload
    },

    [fetchHTMLTemplatesSurveyMails.pending]: (state, action) => {
      state.HTMLTemplatesStatus = 'loading'
    },
    [fetchHTMLTemplatesSurveyMails.fulfilled]: (state, action) => {
      state.HTMLTemplatesStatus = 'succeeded'
      state.HTMLTemplatesSurveyMails = action.payload
    },
    [fetchHTMLTemplatesSurveyMails.rejected]: (state, action) => {
      state.HTMLTemplatesStatus = 'failed'
      state.error = action.payload
    },

    [fetchHTMLTemplateById.pending]: (state, action) => {
      state.HTMLTemplateStatus = 'loading'
    },
    [fetchHTMLTemplateById.fulfilled]: (state, action) => {
      state.HTMLTemplateStatus = 'succeeded'
        state.HTMLTemplate = action.payload
    },
    [fetchHTMLTemplateById.rejected]: (state, action) => {
      state.HTMLTemplateStatus = 'failed'
      state.error = action.payload
    },

    [addNewHTMLTemplate.fulfilled]: (state, action) => {},

    [deleteHTMLTemplate.fulfilled]: (state, action) => {},

    [updateHTMLTemplate.fulfilled]: (state, action) => {},
  },
})

export default HTMLTemplatesSlice.reducer
