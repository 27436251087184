import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { unwrapResult } from "@reduxjs/toolkit";
import { DatePicker, Input, Select } from "antd";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addNewActionRecommendation } from "../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import { fetchAPRecommendations } from "../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import { fetchStaff } from "../../reduxToolkit/features/user/UserSlice";
import { fetchActionTypes } from "reduxToolkit/features/actionType/ActionTypeSlice";
import Avatar from "components/Avatar/Avatar"


const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);
export default function ActionAPRecommendationModal(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { Option, OptGroup } = Select;
  const { TextArea } = Input;

  const [start_date, setStart_date] = useState([]);
  const [due_date, setDue_date] = useState([]);
  const [description, setDescription] = useState(null);
  const [priority, setPriority] = useState(null);
  const [type_action, setType_action] = useState(null);
  const [assign_to_id, setAssign_to_id] = useState(null);
  const [recommendation_id, setRecommendation_id] = useState(
    props.recommendation_id
  );
  const [resource, setResource] = useState("annualProgram");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  function handleChange(value) {
    console.log("value", value);
    setType_action(value);
  }
  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");


  useEffect(() => {
    dispatch(fetchActionTypes())
  }, [])
  const { actionTypes } = useSelector((state) => state.ActionType)
  const users = useSelector((state) => state.user.staffList);

  const handleSend = async () => {
    //let resource_id = props.annualProgram_id;
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        addNewActionRecommendation({
          type_action,
          priority,
          due_date,
          start_date,
          description,
          assign_to_id,
          recommendation_id,
          resource: "program",
          resource_id: props.program_id,
        })
      )

      props.fetchActionRecs();
      setDue_date("");
      setStart_date("");
      setDescription("");
      setType_action("");
      setAssign_to_id("");
      setPriority("");
      setResource("");
    } catch (err) {
      console.error("Failed to save the action: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  function onChangeDue_date(date, dateString) {
    setDue_date(dateString);
  }
  function onChangeStart_date(date, dateString) {
    setStart_date(dateString);
  }

  function onOk(value) {
    console.log("onOk: ", value);
  }

  function onChange(value) {
    console.log(`selected Assign_to_id ${value}`);
    setAssign_to_id(value);
  }
  function onChangePriority(value) {
    console.log(`selected Priority ${value}`);
    setPriority(value);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  return (
    <div>
      <Button
        color="primary"
        style={{
          border: "1px solid #D1CF4B",
          backgroundColor: "#D1CF4B",
          borderRadius: "30px",
          fontSize: "10px",
          height: "30px",
          minWidth: "30px",
          width: "30px",
          marginTop: "-10px",
          marginBottom: "5px",
        }}
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <AddIcon style={{ color: "white", fontSize: "15px" }} />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Add Action Recommendation")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Select Type Action* ")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <Select
                showArrow="true"
                style={{ width: 400 }}
                dropdownStyle={{ zIndex: 10000 }}
                placeholder={t("Select Type")}
                defaultValue="Select Type"
                onChange={handleChange}
                name="type_action"
                value={type_action}
              >
                {actionTypes?.filter(actionType => actionType.category === "program").map((item) => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Assign to * ")}</label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <Select
                showArrow="true"
                style={{ width: 400 }}
                dropdownStyle={{ zIndex: 10000 }}
                placeholder={t("Select user")}
                defaultValue="Select user"
                onChange={onChange}
                name="assign_to_id"
                value={assign_to_id}
              >
                {props.users?.map((p) => (
                  <Option key={p.id} value={p.id}>
                    <GridContainer justifyContent='flex-start' alignItems="baseline"><GridItem><Avatar url={p.person_details?.[0]?.avatar} /></GridItem><GridItem> <p>{p.person_details?.[0]?.first_name}  {p.person_details?.[0]?.last_name}</p></GridItem></GridContainer>
                  </Option>
                ))}
              </Select>
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Priority* ")}</label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <Select
                showArrow="true"
                style={{ width: 400 }}
                dropdownStyle={{ zIndex: 10000 }}
                placeholder={t("Select Priority")}
                defaultValue="Select Priority"
                onChange={onChangePriority}
                name="priority"
                value={priority}
              >
                <Option value={`${1}`}>{t("High")}</Option>

                <Option value={`${2}`}>{t("Medium")}</Option>

                <Option value={`${3}`}>{t("Low")}</Option>
              </Select>
            </GridItem>
          </GridContainer>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Description*")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <TextArea
                rows={4}
                id="description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Start date* ")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <DatePicker
                // showTime={{
                //   format: 'HH:mm',
                // }}
                format="YYYY-MM-DD"
                onChange={onChangeStart_date}
                onOk={onOk}
                style={{ zIndex: "10000  !important" }}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={3}>
              <label>{t("Due Date* ")} </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={9}>
              <DatePicker
                // showTime
                // showTime={{
                //   format: 'HH:mm',
                // }}
                format="YYYY-MM-DD"
                onChange={onChangeDue_date}
                onOk={onOk}
                style={{ zIndex: "10000  !important" }}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </GridItem>
          </GridContainer>


        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleSend();
            }}
            color="primary"
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
