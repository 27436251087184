// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, Select } from 'antd';
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { addNewField, fetchFieldById, fetchFields, updateField } from "reduxToolkit/features/field/FieldSpecSlice";


const useStyles = makeStyles(styles);

export default function CreateFieldPage({ match }) {
  const { t } = useTranslation();
  const { id } = match.params

  console.log('id', id)

  const isAddMode = isNaN(id);
  console.log('id from match is add mode', isAddMode)
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);

  const titleClasses = useTitleStyles();
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const onNameChanged = (e) => setName(e.target.value)
  const onCodeChanged = (e) => setCode(e.target.value)




  const dispatch = useDispatch()
  const { Option } = Select;
  const canSave =
    [name, code].every(Boolean) && addRequestStatus === 'idle'
  const history = useHistory();
  const onSaveFieldClicked = async () => {

    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onsave')
      const resultAction = await dispatch(
        addNewField({ name, code })
      )
      unwrapResult(resultAction)
      setName('')
      setCode('')
      dispatch(fetchFields())
      history.push('/citrine/fields');
    } catch (err) {
      console.log('field', addNewField)
      console.error('Failed to save the field: ', err)
    } finally {
      setAddRequestStatus('idle')
    }


  }
  const onUpdateFieldClicked = async () => {

    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onsave')
      const resultAction = await dispatch(
        updateField({ id: id, modified: { name, code } })
      )
      unwrapResult(resultAction)
      setName('')
      setCode('')
      dispatch(fetchFields())
      history.push('/citrine/fields');
    } catch (err) {
      console.log('field', addNewField)
      console.error('Failed to save the field: ', err)
    } finally {
      setAddRequestStatus('idle')
    }


  }

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchFieldById(id))
    }
  }, [id]);

  const field = useSelector((state) => state.fieldSpec.field)
  console.log('field ', field)

  useEffect(() => {
    if (!isAddMode) {
      setName(field && field.name)
      setCode(field && field.code)

    }
  }, [field, dispatch]);

  return (
    <div>
      {isAddMode &&
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item >

                    {t('Configurations')}

                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span > {t('Program Configurations')} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item >
                    <Link to={`/citrine/fields`} style={{ color: "orange" }}>
                      {t('Fields')}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <span className={titleClasses.title}> {t(' Create')} </span>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <GridItem xs={6} sm={6} md={6}>
                          <GridItem>
                            <CustomInput
                              labelText={t('Name ')}
                              id="name"
                              //  onChange={onNameChanged}
                              type="text"
                              name="name"
                              value={name}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a name!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setName(event.target.value);
                                }
                              }}
                            />
                            <CustomInput
                              labelText={t('Code ')}
                              id="code"
                              value={code}
                              formControlProps={{
                                fullWidth: true
                              }}
                              //       onChange={onPostalCodeChanged}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a code!',
                                },
                              ]}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setCode(event.target.value);
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem>
                            <Button color="info"
                              className={classes.updateProfileButton}
                              onClick={onSaveFieldClicked}
                            >
                              {t('Save')}
                            </Button></GridItem>
                        </GridItem>

                      </GridContainer>
                    </form>

                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

          </div>
        </div>
      }
      {!isAddMode && field &&
        <div>

          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item >

                    {t('Configurations')}

                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span > {t('Program Configurations')} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item >
                    <Link to={`/citrine/fields`} style={{ color: "orange" }}>
                      {t('Fields')}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <span className={titleClasses.title}> {t('Edit ')}</span>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <GridItem xs={6} sm={6} md={6}>
                          <GridItem>
                            <CustomInput
                              labelText={t('Name ')}
                              id="name"
                              //  onChange={onNameChanged}
                              type="text"
                              name="name"
                              value={name}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a name!',
                                },
                              ]}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setName(event.target.value);
                                },
                                value: name
                              }}
                            />





                            <CustomInput
                              labelText={t('Code ')}
                              id="code"
                              value={code}
                              formControlProps={{
                                fullWidth: true
                              }}
                              //       onChange={onPostalCodeChanged}
                              type="text"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a code!',
                                },
                              ]}
                              inputProps={{
                                disabled: false,
                                onChange: event => {
                                  setCode(event.target.value);
                                },
                                value: code
                              }}
                            />
                          </GridItem>
                          <GridItem>
                            <Button color="info"
                              className={classes.updateProfileButton}
                              onClick={onUpdateFieldClicked}
                            >
                              {t('Update')}
                            </Button></GridItem>
                        </GridItem>

                      </GridContainer>
                    </form>

                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

          </div>

        </div>
      }
    </div>
  );
}
