import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const CreditUnit = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>Institutional Summary: Credit Unit</h3>

      <p className={styles.reportParagraphTitle}>
        It is assumed that one semester or quarter credit normally represents
        one class hour or three laboratory hours per week. One academic year
        normally represents at least 28 weeks of classes, exclusive of final
        examinations.
        The following is a description of any other standards if used for this program:
      </p>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
    </>
  );
};

export default CreditUnit;
