import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";
import { fetchProgramsByPage } from "reduxToolkit/features/program/ProgramsSlice";
import {
  fetchCurrentSemester,
  fetchSemesters, switchSemester
} from "../../reduxToolkit/features/semester/SemestersSlice";
import {
  fetchCoursesByUserAndSemester
} from "../../reduxToolkit/features/course/CoursesSlice";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

const Semesters = (props) => {
  // Object.keys(j).forEach(function(prop) {
  //     // `prop` is the property name
  //     // `data[prop]` is the property value

  //     j[prop]=prop
  //     // console.log("prop",prop);
  //     // console.log("j[prop]",j[prop]);
  //  });
  //  console.log("j",j);
  // const sj = JSON.stringify(j)
  // console.log("sj",sj);

  const dispatch = useDispatch();
  let history = useHistory();
  const { rtlActive } = props;

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [selectedSemester, setSelectedSemester] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const userId = localStorage.getItem('userId')


  React.useEffect(() => {
    dispatch(fetchSemesters())
      .then(unwrapResult)
    if (userId) {
      dispatch(fetchCurrentSemester(userId))
        .then(unwrapResult)
        .then((currentSemester) => localStorage.setItem('semester_id', JSON.stringify(currentSemester.semester_id)))
    }
  }, [menuAnchor]);
  const semesters = useSelector((state) => state.semester.semesters);
  const currentSemester = useSelector(
    (state) => state.semester.currentSemester
  );
  // console.log('Semesters', semesters)
  // console.log('currentSemester', currentSemester)
  const handleSemesterSwitch = () => {
    try {
      dispatch(switchSemester({ semester_id: selectedSemester.id, user_id: userId }))
        .then(unwrapResult)
        .then((switched) => {
          localStorage.removeItem('semester_id');
          localStorage.setItem('semester_id', JSON.stringify(switched.semester_id))
          dispatch(fetchCurrentSemester(userId))
            .then(unwrapResult)
            .then((current) => {
              dispatch(fetchProgramsByPage({
                page: 1,
                records: 25,
                semester_id: current.semester_id,
              }));
              dispatch(fetchCoursesByUserAndSemester());
            });
        });

    } catch (err) {
      console.log(err)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  return (
    <div className={managerClasses}>
      <Button
        color="transparent"
        aria-label="Semesters"
        aria-haspopup="true"
        style={{
          paddingLeft: "12px",
          paddingRight: "12px",
        }}
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : ""
        }}
      >
        <DateRangeIcon
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive
              ? classes.links + " " + classes.linksRTL
              : classes.links)
          }
        />
        <span
          onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
          className={classes.linkText}
        >
          {currentSemester && currentSemester.semester_details}
        </span>
      </Button>

      {/* <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {semesters?.map((item) => (
            <ListItem

              button
              key={item.id}
              onClick={() => {
                setMenuAnchor(null);
                setSelectedSemester(item);
                handleClickOpen();

              }}
            >
              {item.academicYear_detail}
            </ListItem>
          ))}
        </List>
      </Popover> */}

      <Popper
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !menuAnchor,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="notification-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={() => setMenuAnchor(null)}>
                <MenuList role="menu">

                  {semesters?.map((item) => (
                    <MenuItem
                      key={item.id}
                      onClick={() => {
                        setMenuAnchor(null);
                        setSelectedSemester(item);
                        handleClickOpen();

                      }} className={dropdownItem}
                    >
                      {item.academicYear_detail}
                    </MenuItem>

                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {`Are you sure you want to switch to ${selectedSemester && selectedSemester.academicYear_detail
            }?`}
        </DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            No
          </Button>
          <Button
            color="primary"
            onClick={() => {
              // dispatch(languageChanged(item))
              handleClose();
              handleSemesterSwitch();

            }}
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Semesters;
