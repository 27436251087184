import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  mails: [],
  mailsStatus: 'idle',
  mailStatus: 'idle',
  error: null,
  mail:null,
 
  id:null
}


export const fetchMails = createAsyncThunk('mail/fetchMails', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/mail/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchMailById = createAsyncThunk('mail/fetchMailById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/mail/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
  export const addNewMail = createAsyncThunk(
    'mail/addNewMail',
    async (initialMail) => {
      console.log('initial mail', initialMail)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/mail/create/`,initialMail ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const mailUpdate = createAsyncThunk(
    'mail/mailUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/mail/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedMail')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the mail: ', error)

}
    }
  )

  export const deleteMail = createAsyncThunk('mail/deleteMail', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/mail/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data,'response.data')

    return response.data
  })
const  mailsSlice = createSlice({
  name: ' mail',
  initialState,
  extraReducers: {
    [fetchMails.pending]: (state, action) => {
        state.mailsStatus = 'loading'
      },
      [fetchMails.fulfilled]: (state, action) => {
        state.mailsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.mails = action.payload
      },
      [fetchMails.rejected]: (state, action) => {
        state.mailsStatus = 'failed'
        state.error = action.payload
      },

      [fetchMailById.pending]: (state, action) => {
        state.mailStatus = 'loading'
      },
      [fetchMailById.fulfilled]: (state, action) => {
        state.mailStatus = 'succeeded'

        // Add any fetched posts to the array
        state.mail = action.payload
      },
      [fetchMailById.rejected]: (state, action) => {
        state.mailStatus = 'failed'
        state.error = action.payload
      },
      [addNewMail.fulfilled]: (state, action) => {
        state.mails.push(action.payload)
      },
      [mailUpdate.fulfilled]: (state, action) => {
        console.log("update succeeded!!!")
      },
      [deleteMail.fulfilled]: (state, action) => {
      
         state.mailStatus = 'succeeded'
      state.mails = state.mails.splice(state.mails.findIndex((arrow) => arrow.id === action.payload), 1);
      //  const index = state.mails.findIndex((mail) => mail.id !== action.payload);
      
     
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    mailUpdated(state, action) {
      const { id,name } = action.payload
      const existingMail = state.mails.find(mail => mail.id === id)
      if (existingMail) {
        existingMail.name = name
      }
    },
    
  }
}})

export const { mailAdded, mailUpdated, getId} = mailsSlice.actions

export default mailsSlice.reducer

export const selectAllMails = state => state.mail.mails
