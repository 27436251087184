import React, { useState } from "react";
import { useDispatch } from "react-redux";
//import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import {
  fetchPeosByProgramId,
} from "../../reduxToolkit/features/PEO/PEOsSlice";

import { useTranslation } from "react-i18next";
import ErrorModal from 'views/Components/ErrorModal'
import { EditPEOPLOWeight } from "../../reduxToolkit/features/PLO/PLOWeightSlice";

export default function EditWeightPlo_PeoModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const [weight, setWeight] = useState();
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleSend = async () => {


    dispatch(EditPEOPLOWeight({ weight, peo_id: props.peo_id, plo_id: props.plo?.id }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchPeosByProgramId(props.ProgramID));
        setWeight(null)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })

  };


  return (
    <div>
      <Dialog
        open={props.openEditPLOWeightModal}
        onClose={props.handleCloseEditPLOWeightModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          {t("Edit Weight Plo/Peo ")}
        </DialogTitle>
        <DialogContent>
          <form>
            <CustomInput
              labelText={t("weight*")}
              id="weight"
              value={weight}
              type="integer"
              rules={[
                {
                  required: true,
                  message: "Please enter a weight ",
                },
              ]}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: false,
                onChange: (event) => {
                  setWeight(event.target.value);
                },
                value: weight,
              }}
            />
          </form>

          <DialogActions>
            <Button onClick={props.handleCloseEditPLOWeightModal} color="info">
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                props.handleCloseEditPLOWeightModal();
                handleSend();
              }}
              color="info"
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
