import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Input, InputNumber, notification, Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewCourseSpecReportDocument, addNewFieldExperienceReportDocument, fetchCourseSpecReportDocumentsByCourseSpecId
} from "../../reduxToolkit/features/CourseSpecificationReportDocument/CourseSpecificationReportDocumentSlice";
import ErrorModal from "./ErrorModal.jsx";
import {PlusCircleOutlined} from "@ant-design/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16,
  },
};

export default function CreateReportCourseDocument(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);

  const { Option } = Select;

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const [course_type, setCourse_type] = useState(props.CourseType)


  const programs = useSelector((state) => state.programSpec.programsByCourseSpec)

  const onFinish = async (values) => {
    if (course_type && course_type == "Field Experience") {
      const resultAction = await dispatch(
        addNewFieldExperienceReportDocument({
          ressource: "fieldExperience",
          ressourceId: props.CourseId,
          ...values
        })
      );
      if (
        addNewFieldExperienceReportDocument.fulfilled.match(resultAction)
      ) {
        openNotificationWithIcon("success", `Added successfully`);
        dispatch(
          fetchCourseSpecReportDocumentsByCourseSpecId(props.CourseId)
        )
        handleClose();
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
          handleClose();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
          handleClose();
        }
      }
    }
    else {
      const resultAction = await dispatch(
        addNewCourseSpecReportDocument({
          ressource: "courseSpec",
          ressourceId: props.CourseId,
          ...values
        })
      );
      if (
        addNewCourseSpecReportDocument.fulfilled.match(resultAction)
      ) {
        openNotificationWithIcon("success", `Added successfully`);
        dispatch(
          fetchCourseSpecReportDocumentsByCourseSpecId(props.CourseId)
        )
        handleClose();
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
          handleClose();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
          handleClose();
        }
      }
    }
  };
  return (
    <div>
      <MenuItem
        color="primary"
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <PlusCircleOutlined />
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          Create Course Specifications Document Report
        </DialogTitle>
        <DialogContent>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="version"
              label="version"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="programSpec_id"
              label="Program"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                dropdownStyle={{ zIndex: 10000 }}

              >
                {programs && programs.map((p) => (
                  <Option key={p.id} value={p.id}>
                    {p.program_degree_name}
                  </Option>
                ))}

              </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: "20px" }}
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="primary" color="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
