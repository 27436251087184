import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { addNewPlo } from "../../reduxToolkit/features/PLO/PLOSpecSlice";
import { Form, Input, message, Select, DatePicker } from "antd";

// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { VersionningCourseSpec } from "../../reduxToolkit/Course/coursesSpecSlice";

import ErrorModal from "./ErrorModal";

import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";

export default function VersionningCourseSpecModal({ CourseSpec_id,course}) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [major, setMajor] = useState(false);
  const [minor, setMinor] = useState(false);

  const { error } = useSelector((state) => state.ploSpec);
  const dispatch = useDispatch();

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

 
  
  function onChangeMajor(e) {
    console.log(`checked Major = ${e.target.checked}`);
    setMajor(e.target.checked)
  }
  function onChangeMinor(e) {
    console.log(`checked Minor = ${e.target.checked}`);
    setMinor(e.target.checked)
  }
  
  const onFinishFailed = (errorInfo) => {
  };

  const onFinish = async () => {
    
    
    let BodyVersion={
      id:CourseSpec_id,
      major:major,
      minor:minor,
     
    }
    console.log(BodyVersion,"BodyVersion")
    const resultAction = await dispatch(
        VersionningCourseSpec(BodyVersion)
    );

    if (VersionningCourseSpec.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Version Added successfully`);
      // dispatch(fetchPrograms(programSpec_id));
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
    
  };
  return (
    <div>
      <Button
        size="sm"
        color="info"
        onClick={handleClickOpen}
      >
        <span style={{ color: "white", fontSize: "11px" }}>
          {t("Versionning")}
        </span>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">
          {t("Create New Version")}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
              
                 

                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={3}>
                    <span> Versionning</span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <Form.Item name="major">
                      <Checkbox onChange={onChangeMajor}>Major</Checkbox>
                    </Form.Item>
                    <Form.Item name="minor">
                      <Checkbox onChange={onChangeMinor}>Minor</Checkbox>
                    </Form.Item>
                  </GridItem>
                </GridContainer>

                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
