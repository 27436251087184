import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  outGoingMailServers: [],
  outGoingMailServersStatus: 'idle',
  outGoingMailServerStatus: 'idle',
  error: null,
  outGoingMailServer:null,
 
  id:null
}


export const fetchOutGoingMailServers = createAsyncThunk('outGoingMailServer/fetchOutGoingMailServers', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/outGoingMailServer/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchOutGoingMailServerById = createAsyncThunk('outGoingMailServer/fetchOutGoingMailServerById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/outGoingMailServer/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
  export const addNewOutGoingMailServer = createAsyncThunk(
    'outGoingMailServer/addNewOutGoingMailServer',
    async (initialOutGoingMailServer) => {
      console.log('initial outGoingMailServer', initialOutGoingMailServer)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/outGoingMailServer/create/`,initialOutGoingMailServer ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const outGoingMailServerUpdate = createAsyncThunk(
    'outGoingMailServer/outGoingMailServerUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/outGoingMailServer/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedOutGoingMailServer')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the outGoingMailServer: ', error)

}
    }
  )

  export const deleteOutGoingMailServer = createAsyncThunk('outGoingMailServer/deleteOutGoingMailServer', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/outGoingMailServer/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data,'response.data')

    return response.data
  })
const  outGoingMailServersSlice = createSlice({
  name: ' outGoingMailServer',
  initialState,
  extraReducers: {
    [fetchOutGoingMailServers.pending]: (state, action) => {
        state.outGoingMailServersStatus = 'loading'
      },
      [fetchOutGoingMailServers.fulfilled]: (state, action) => {
        state.outGoingMailServersStatus = 'succeeded'
        // Add any fetched posts to the array
        state.outGoingMailServers = action.payload
      },
      [fetchOutGoingMailServers.rejected]: (state, action) => {
        state.outGoingMailServersStatus = 'failed'
        state.error = action.payload
      },

      [fetchOutGoingMailServerById.pending]: (state, action) => {
        state.outGoingMailServerStatus = 'loading'
      },
      [fetchOutGoingMailServerById.fulfilled]: (state, action) => {
        state.outGoingMailServerStatus = 'succeeded'

        // Add any fetched posts to the array
        state.outGoingMailServer = action.payload
      },
      [fetchOutGoingMailServerById.rejected]: (state, action) => {
        state.outGoingMailServerStatus = 'failed'
        state.error = action.payload
      },
      [addNewOutGoingMailServer.fulfilled]: (state, action) => {
        state.outGoingMailServers.push(action.payload)
      },
      [outGoingMailServerUpdate.fulfilled]: (state, action) => {
        console.log("update succeeded!!!")
      },
      [deleteOutGoingMailServer.fulfilled]: (state, action) => {
      
         state.outGoingMailServerStatus = 'succeeded'
      state.outGoingMailServers = state.outGoingMailServers.splice(state.outGoingMailServers.findIndex((arrow) => arrow.id === action.payload), 1);
      //  const index = state.outGoingMailServers.findIndex((outGoingMailServer) => outGoingMailServer.id !== action.payload);
      
     
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    outGoingMailServerUpdated(state, action) {
      const { id,name } = action.payload
      const existingOutGoingMailServer = state.outGoingMailServers.find(outGoingMailServer => outGoingMailServer.id === id)
      if (existingOutGoingMailServer) {
        existingOutGoingMailServer.name = name
      }
    },
    
  }
}})

export const { outGoingMailServerAdded, outGoingMailServerUpdated, getId} = outGoingMailServersSlice.actions

export default outGoingMailServersSlice.reducer

export const selectAllOutGoingMailServers = state => state.outGoingMailServer.outGoingMailServers
