import React, { useEffect, useState } from 'react'
import { fetchAuthorities } from '../../../../reduxToolkit/features/AccreditationAuthority/AuthoritySlice'
import { useDispatch, useSelector } from 'react-redux'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/CustomButtons/Button'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import AuthorityDropDown from './AuthorityDropDown'
import { makeStyles } from '@material-ui/core/styles'
const useCardStyles = makeStyles(cardStyles)
const useStyles = makeStyles(styles)

const AuthorityPage = () => {
  const [ping, setPing] = useState(false)
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const authorities = useSelector((state) => state.authority.authorities)

  useEffect(() => {
    dispatch(fetchAuthorities())
  }, [])

  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Authority Page')}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to={`/citrine/configuration/others/AddAuthorityConfig`}>
        <Button color="info">{t('Add')}</Button>
      </Link>
      <GridContainer>
        {authorities &&
          authorities.map((el) => (
            <GridItem xs={12} sm={12} md={4} key={el.id}>
              <Card
                style={{ maxHeight: '200px', minHeight: '200px' }}
                cardHover
              >
                <CardBody>
                  <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <GridItem xs={12} sm={12} md={10}>
                      <h4 className={cardClasses.cardProductTitle}>
                        {`${el.name}`}
                      </h4>
                    </GridItem>

                    <GridItem xs={2} sm={2} md={2}>
                      <AuthorityDropDown
                        ping={ping}
                        setPing={setPing}
                        authority={el}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  )
}

export default AuthorityPage
