import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";
import { languageChanged } from "../reduxToolkit/features/language/LanguageSlice"


import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

const LangSelect = (props) => {

  const { rtlActive } = props;

  const selected = localStorage.getItem("i18nextLng") || "en";

  const languages = useSelector((state) => state.language.languages)

  const dispatch = useDispatch();

  const [menuAnchor, setMenuAnchor] = React.useState(null);


  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  return (

    <div className={managerClasses}>
      <Button
        color="transparent"
        aria-label="Semesters"
        aria-haspopup="true"
        style={{
          paddingLeft: "12px",
          paddingRight: "12px",
        }}
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : ""
        }}
      >
        <LanguageIcon
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive
              ? classes.links + " " + classes.linksRTL
              : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span
            onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
            className={classes.linkText}
          >
            {rtlActive ? "اللغات" : "Languages"}
          </span>
        </Hidden>
      </Button>

      <Popper
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !menuAnchor,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="notification-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={() => setMenuAnchor(null)}>
                <MenuList role="menu">

                  {Object.keys(languages)?.map(item => (
                    <MenuItem
                      key={item.id}
                      onClick={() => {
                        dispatch(languageChanged(item))
                        setMenuAnchor(null);
                      }}
                      className={dropdownItem}
                    >
                      {languages[item].label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

    </div>
  );
};
export default LangSelect



