import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { unwrapResult } from '@reduxjs/toolkit'
import { Form, Input, Select, InputNumber, notification } from 'antd'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchGroupStateActions,
  addNewGroupStateActions,
} from 'reduxToolkit/features/groupStateActions/GroupStateActionsSlice'
import { fetchUserGroups } from 'reduxToolkit/features/userGroups/UserGroupSlice'
import { Link, useHistory } from 'react-router-dom'
import ErrorModal from 'views/Components/ErrorModal'
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const useButtonStyles = makeStyles(buttonStyles)

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
export default function AddStateActionGroupControlModal(props) {
  //console.log(props.state, typeof props.state)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttonClasses = useButtonStyles()
  const [form] = Form.useForm()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const { Option, OptGroup } = Select
  const { TextArea } = Input
  const [name, setName] = useState('')
  const [groupStates_ids, setGroupStates_ids] = useState()
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  const onFinish = async (values) => {
    dispatch(addNewGroupStateActions({ ...values, groupStates_ids }))
      .unwrap()
      .then((result) => {
        openNotificationWithIcon('success', `Add successfully`)
        dispatch(fetchGroupStateActions(props.state))
        form.resetFields()
        handleClose()
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      })
    // const resultAction = await dispatch(
    //   addNewGroupStateActions({
    //     ...values,

    //     groupStates_ids,
    //   }),
    // )
    // if (addNewGroupStateActions.fulfilled.match(resultAction)) {
    //   openNotificationWithIcon('success', `Add successfully`)
    //   dispatch(fetchGroupStateActions(props.state))
    //   form.resetFields()
    //   handleClose()
    // } else {
    //   if (resultAction.payload) {
    //     setHandledError(resultAction.payload)
    //     handleOpenErrorModal()
    //     form.resetFields()
    //     handleClose()
    //   } else {
    //     setHandledError(resultAction.error)
    //     handleOpenErrorModal()
    //     form.resetFields()
    //     handleClose()
    //   }
    //}
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()
  function onChange(value) {
    console.log(`selected ${value}`)
    setName(value)
  }
  const history = useHistory()
  useEffect(() => {
    dispatch(fetchGroupStateActions(props.state))
    setGroupStates_ids([props.state])
  }, [])

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  return (
    <div>
      {/* <Button color="primary" onClick={(event) => {
                    handleClickOpen(event);
                    props.handleDropDownButtonClose();
                }}>
{t('  Add')}
</Button> */}
      <Button
        color="primary"
        onClick={(event) => {
          handleClickOpen(event)
          //props.handleDropDownButtonClose();
        }}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <center> {t('Add State')}</center>
        </DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="name"
                  label="Action"
                  rules={[
                    {
                      required: true,
                      message: 'Please select an action.',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Actions "
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    <Option value={'Send for approval'}>
                      {'Send for approval'}
                    </Option>
                    <Option value={'Approve'}>{'Approve'}</Option>
                    <Option value={'Reset tO draft'}>{'Reset tO draft'}</Option>
                    <Option value={'Archived'}>{'Archived'}</Option>
                    <Option value={'generate actions'}>{'Generate actions'}</Option>
                    <Option value={'versionning'}>{'versionning'}</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="index"
                  label="Index"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the Index field.',
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
