// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Badge, Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchActualLearningHoursConfig } from "../../../../reduxToolkit/features/ActualLearningHours/ActualLearningConfigSlice";
import DropDownALAButton from "../../../Components/DropDownALAButton";

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);

export default function ActualLeaningConfigList(props) {
  const { t } = useTranslation();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();
  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  // const { id } = match.params
  console.log("id of course", props.id);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const ActualLearningHoursConfig = useSelector(
    (state) => state.ActualLearningHourConfig.ActualLearningHours
  );
  console.log("course to be updated", course);

  const courseStatus = useSelector(
    (state) => state.CourseSpec.courseSpecificationStatus
  );
  const error = useSelector((state) => state.course.error);

  console.log("ActualLearningHoursConfig", ActualLearningHoursConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActualLearningHoursConfig());
  }, [dispatch]);

  if (!ActualLearningHoursConfig) {
    return (
      <section>
        <h2>{t("Teaching Strategies not found!")}</h2>
      </section>
    );
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/configuration/course/`}>
              {t(" Course Configurations")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/ActualLeaningConfigList`}
              style={{ color: "orange" }}
            >
              {t("Actual Learning Hours Activities List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridContainer>
        <GridItem>
          <Link to={`/citrine/CreateActualLeaningConfig/:id`}>
            <Button color="info" className={classes.updateProfileButton}>
              {t("Add")}
            </Button>
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {ActualLearningHoursConfig &&
          ActualLearningHoursConfig.map((Element) => (
            <GridItem key={Element.id} xs={12} sm={12} md={4}>
              <Card style={{ height: 200, textAlign: "start" }}>
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={10}>
                      {/* <h4
                        className={classes.cardProductTitle}
                        style={{ textAlign: "start", color: "orange" }}
                      >
                        <a href="#pablo">
                          {t("Actual Learning Hours Activity")}
                        </a>
                      </h4> */}
                      <h4
                        className={classes.cardProductTitle}
                        //style={{ textAlign: "start", color: "orange" }}
                      >
                        
                          {Element.name}
                     
                      </h4>



                      {Element &&
                        Element.areaSpec_details && Element.areaSpec_details.length &&
                        Element.areaSpec_details.length ? (
                        Element.areaSpec_details.map((el) => (
                          <Badge
                            className="site-badge-count-109"
                            count={el.name}
                            style={{ backgroundColor: "#52c41a" }}
                          />
                        ))
                      ) : (
                        <span></span>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }}>
                      <DropDownALAButton id={Element.id} />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
