import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import Button from 'components/CustomButtons/Button.js'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { notification } from 'antd'

import MoreVert from '@material-ui/icons/MoreVert'
import ErrorModal from './ErrorModal'
import SuperGeneralDeleteModal from './SuperGeneralDeleteModal'

import {
  deleteCourse,
  fetchCourses,
} from '../../reduxToolkit/Course/coursesSpecSlice'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import VersionControl from 'views/Pages/Specifications/course/VersionControl'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function DropDownButtonInCourseDetails(props) {

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [refresh, setRefresh] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()

  const dispatch = useDispatch()

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleDeleteCourse = async (id) => {
    dispatch(deleteCourse(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon('success', `Deleted`)
        dispatch(fetchCourses())
        history.push('/citrine/CourseSpecificationList')
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={`/citrine/courseSpecUpdate/${props.courseID}`}>
            {t('Edit Specifications')}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={`/citrine/specification/course/${props.courseID}/courseSyllabus`}
          >
            {t('Course syllabus')}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={`/citrine/specification/course/${props.courseID}/CourseOutlines`}
          >
            {t('Course outline')}
          </Link>
        </MenuItem>
        {/* 
        <VersionControl
          id={props.courseID}
          pingUseEffect={props.pingUseEffect}
          setPingUseEffect={props.setPingUseEffect}
          handleDropDownButtonClose={handleClose}
          refresh={refresh}
          setRefresh={setRefresh}
        /> */}
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteCourse}
        Element_ID={props.courseID}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
