
import { unwrapResult } from '@reduxjs/toolkit';
import { notification } from 'antd';
import Button from "components/CustomButtons/Button.js";
import React,{ useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { addNewHTMLTemplate, fetchHTMLTemplateById } from '../../../../reduxToolkit/features/HTMLTemplate/HTMLTemplateSlice.jsx';
import EditorToolbar, { formats, modules } from "./EditorToolbar";
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MailTemplates(props) {

  const [value, setValue] = useState('');
  const [ispost, setpost] = useState([]);
  let history = useHistory();
  const dispatch = useDispatch()

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleChange = (ontent, delta, source, editor) => {
    setValue(editor.getHTML());
  };

  useEffect(() => {
    /*dispatch(fetchHTMLTemplateById(21)).then(res => {
      setpost(res?.payload);
    })*/
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    const initialHTMLTemplate = {
      name: "firsting",
      type: "surveyMailTemplate",
      html: value
    }
    dispatch(addNewHTMLTemplate(initialHTMLTemplate)).then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Added`);
        history.push("/citrine/mailetemplist/");
      })
      .catch((rejectedValueOrSerializedError) => {});
  }

  return (
    <div>
      <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
            </IconButton>
            <Typography style={{cursor:"pointer"}} onClick={props.onClose} sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Close
            </Typography>
            <Button autoFocus color="primary" onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
          <EditorToolbar />
          <ReactQuill style={{height:"500px"}} theme="snow" value={value} onChange={handleChange} modules={modules} formats={formats} />
      </Dialog>
    </div>
    </div>
  );
};
