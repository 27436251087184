import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DataGrid } from "@material-ui/data-grid";
import { notification } from "antd";
import "antd/dist/antd.css";
import Button from "components/CustomButtons/Button.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";
import { fetchApprovedNotGeneratedPrograms } from "reduxToolkit/features/program/ProgramSpecSlice";
import { fetchSemesterById } from "reduxToolkit/features/semester/SemestersSlice";
import ErrorModal from "../../../Components/ErrorModal";
import { generateSemester } from "../../../../reduxToolkit/features/semester/SemestersSlice.js";

export default function GenerateModal({ semesterId, programs, fetchPrograms }) {

  const columns = [
    {
      field: "code",
      headerName: "Code",
      width: 100,
      sortable: false,
    },
    {
      field: "version",
      headerName: "Version",
      //flex: 1,
      width: 200,
      sortable: false,
    },
    {
      field: "program_degree_name",
      headerName: "program",
      flex: 1,
      width: 200,
      sortable: false,
    },
  ];
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // const [programs, setPrograms] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchApprovedNotGeneratedPrograms(semesterId))
  //     .unwrap()
  //     .then((originalPromiseResult) => {
  //       setPrograms(
  //         originalPromiseResult
  //         .filter(
  //           (program) => program.state === "Approved"
  //         )
  //       );
  //     });
  // }, [dispatch]);
  // console.log("programs", programs);

  const [programSpec_ids, setProgramSpec_ids] = React.useState([]);

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const onFinish = async () => {
    let id = semesterId;
    let program_spec_generate = programSpec_ids.join();
    // console.log("program_spec_generate", `${program_spec_generate} | ${id}`);

    const resultAction = await dispatch(
      generateSemester({ id, program_spec_generate })
    );

    if (generateSemester.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Generated`);
      handleClose();
      dispatch(fetchSemesterById(semesterId));
      fetchPrograms(semesterId)
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={(event) => {
          handleClickOpen(event);
        }}
      >
        {t("Generate")}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">
          {t("Choose the programs to be generated")}
        </DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: "100%"}}>
            <DataGrid
              rows={programs && programs}
              columns={columns}
              autoPageSize={true}
              columnBuffer={0}
              disableColumnMenu
              disableSelectionOnClick
              showCellRightBorder
              showColumnRightBorder
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setProgramSpec_ids(newSelectionModel);
              }}
              selectionModel={programSpec_ids}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              onFinish();
              handleClose();
            }}
            color="primary"
          >
            {t("Generate")}
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
