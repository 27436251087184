import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  majorTracks: [],
  majorTracksStatus: 'idle',
  majorTrackStatus: 'idle',
  error: null,
  majorTrack: null,
  id: null
}

export const fetchMajorTracks = createAsyncThunk('majorTrack/fetchMajorTracks', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/majorTracks/?programSpec_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})


export const fetchMajorTrackById = createAsyncThunk('majorTrack/fetchMajorTrackById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/majorTracks/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
export const addNewMajorTrack = createAsyncThunk(
  'majorTrack/addNewMajorTrack',
  async (initialMajorTrack, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/majorTracks/create/`, initialMajorTrack, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })


export const updateMajorTrack = createAsyncThunk(
  'majorTrack/updateMajorTrack',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/majorTracks/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedMajorTrack')
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })

export const deleteMajorTrack = createAsyncThunk('majorTrack/deleteMajorTrack', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/majorTracks/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})
const majorTracksSlice = createSlice({
  name: ' majorTrack',
  initialState,
  extraReducers: {

    [fetchMajorTracks.pending]: (state, action) => {
      state.majorTracksStatus = 'loading'
    },
    [fetchMajorTracks.fulfilled]: (state, action) => {
      state.majorTracksStatus = 'succeeded'
      // Add any fetched posts to the array
      state.majorTracks = action.payload
    },
    [fetchMajorTracks.rejected]: (state, action) => {
      state.majorTracksStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchMajorTrackById.pending]: (state, action) => {
      state.majorTrackstatus = 'loading'
    },
    [fetchMajorTrackById.fulfilled]: (state, action) => {
      state.majorTrackstatus = 'succeeded'

      // Add any fetched posts to the array
      state.majorTrack = action.payload
    },
    [fetchMajorTrackById.rejected]: (state, action) => {
      state.majorTrackstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewMajorTrack.fulfilled]: (state, action) => {
      state.majorTracks.push(action.payload)
    },
    [deleteMajorTrack.fulfilled]: (state, action) => {
      // state.majorTracks.push(action.payload)
    },
    [updateMajorTrack.fulfilled]: (state, action) => {
      const result = state.majorTracks.filter(majorTrack => majorTrack.id !== action.payload.id);
      // console.log("majorTrack id from filter from slice",majorTrack.id)
      result.push(action.payload)
      state.majorTracks = result
      console.log("action.payload.id from slice", action.payload)

    },
    [addNewMajorTrack.rejected]: (state, action) => {
      state.majorTrackstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteMajorTrack.rejected]: (state, action) => {
      state.majorTrackstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateMajorTrack.rejected]: (state, action) => {
      state.majorTrackstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

})


export default majorTracksSlice.reducer

export const selectAllMajorTracks = state => state.majorTracks.majorTracks