import React from 'react'
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Link } from "react-router-dom";
import { CircularProgressbar } from 'react-circular-progressbar';
import { Avatar, Tooltip } from 'antd';
import Code from "components/Typography/Code.js";
import Info from "components/Typography/Info.js";
import 'react-circular-progressbar/dist/styles.css';
import Button from "components/CustomButtons/Button.js";
import CircularProgressbarCard from './CircularProgressbarCard';
export default function CardBlue(props) {
  const containerStyle = {
    clipPath: 'polygon(0 0, 90% 0, 100% 100%, 0% 100%)',
    backgroundColor: "#6C7B9D",
    padding: "4px 35px 4px 7px",
    boxShadow: '0 3px 10px 3px rgb(192 192 192 / 0.5)'
  };

  return (
    <GridItem xs={12} sm={6} md={4} lg={4} xl={3}>
      <Link to={`/citrine/faculty/result_calcul_Clos_Questions/course/${props.item?.course_id}/clo/${props.item?.id}`}>
        <Card withPadding withHiddenOverflow style={{ height: 240 }}>
          <GridContainer>
            <GridItem>
              <Tooltip title={props.item.title} placement="top">
                <h5>
                  {props.item.code}
                </h5>
              </Tooltip>

              <Info>
                {props.item.title.slice(0, 50)}
              </Info>

            </GridItem>
          </GridContainer>
          <br />
          <CircularProgressbarCard item={props.item} />

        </Card>
      </Link>
    </GridItem>
  )
}
