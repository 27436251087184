import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
//import { selectAllColleges, fetchColleges, getId, deleteCollege } from '../../reduxToolkit/features/college/CollegesSlice'
// @material-ui/icons
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from 'react-router-dom';
//import DropDownButtonAdd from 'views/Components/DropDownButtonAdd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Moment from 'moment';
import { fetchSpecificationAprovalDatas } from "reduxToolkit/features/SpecificationAprovalData/SpecificationAprovalDataSlice";
import DropDownButtonSepcificationApproval from "../../../../Components/DropDownButtonSepcificationApproval";

import { useTranslation } from 'react-i18next';

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);


export default function SpecificationAprovalDataList(props) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton

    //const { id } = match.params
    // console.log("id of course", props.id)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchSpecificationAprovalDatas(course && course.id && course.id))
    }, [dispatch])

    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const SpecificationAprovalDatas = useSelector((state) => state.SpecificationAprovalData.SpecificationAprovalDatas)
    // console.log('course to be updated', course)
    // console.log('SpecificationAprovalDatas', SpecificationAprovalDatas)
    const courseStatus = useSelector((state) => state.CourseSpec.courseSpecificationStatus)
    const error = useSelector((state) => state.course.error)
    // console.log('course', course)

    // if (!course) {
    //     return (
    //         <section>
    //             <h2>Course not found!</h2>
    //         </section>
    //     )
    // }

    return (
        <div>
            <GridContainer >
                <GridItem >
                    <Link to={`/citrine/CreateSpecificationAprovalData/${course && course.id}/:idSAD`}>
                        <Button color="info"
                            className={classes.updateProfileButton}
                        >
                            {t('Add')}
                        </Button>
                    </Link>

                </GridItem>
            </GridContainer>
            <GridContainer>
                {SpecificationAprovalDatas && SpecificationAprovalDatas.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 150, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={12} sm={12} md={10}>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                {Element.reference}

                                            </a>

                                        </h4>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start' }} >

                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {Element.council_committee}
                                            </a>

                                        </h4>
                                        {Element.id}
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start' }} >

                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {t('Date :')}  {Moment(Element.date).format('d MMM YYYY')}
                                            </a>
                                        </h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                        <DropDownButtonSepcificationApproval idCourse={props.idCourse} SepcificationApproval_ID={Element.id} />
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>
            {/* 
            <GridContainer>
                <GridItem>
                    <Link to={`/citrine/courses/`}>
                        <Button color="info"
                            className={classes.updateProfileButton}
                        >
                            Exit
                        </Button>
                    </Link>
                </GridItem>
            </GridContainer> */}
        </div >

    );

}




