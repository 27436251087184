import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
    CLOs_PLOI: [],
    CLOs_PLOIStatus: 'idle',
    CLOs_GAI: [],
    CLOs_GAIStatus: 'idle',
    error: null,

}

export const fetchNotAffectedCLOsToPLOI = createAsyncThunk('CLOSpecWeight/fetchNotAffectedCLOsToPLOI', async (ids, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + `/weightCloSpec/closSpec_filtered_PLOI/?ploIndicatorsSpec_id=${ids.PLOIndicatorId}&programSpec_id=${ids.programId}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }

        )
        console.log(response.data, 'response.data')
        return response.data

    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})



export const fetchNotAffectedCLOsToGAI = createAsyncThunk('CLOSpecWeight/fetchNotAffectedCLOsToGAI', async (ids, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + `/weightCloSpec/closSpec_filtered_GAI/?GAISpec_id=${ids.GAIndicatorId}&programSpec_id=${ids.programId}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }

        )
        console.log(response.data, 'response.data')
        return response.data

    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})
// export const fetchKPIProgramById = createAsyncThunk('KPISpec/fetchKPIProgramById', async (id, { rejectWithValue }) => {
//   try {

//     const token = localStorage.getItem('token')
//     const response = await axios.get(BaseUrl + `/kpiProgram/${id}/`, {
//       headers: {
//         'Authorization': `token ${token}`
//       }
//     })
//     // console.log(response.data,'response.data')

//     return response.data
//   } catch (err) {
//     let error = err // cast the error for access
//     if (!error.response) {
//       throw err
//     }
//     // We got validation errors, let's return those so we can reference in our component and set form errors
//     console.log('error.response from inside the CAT', error.response)
//     return rejectWithValue(error.response)
//   }
// })

export const addNewCLOSpecWeight = createAsyncThunk(
    'CLOSpecWeight/addNewCLOSpecWeight',
    async (initialCLOSpecWeight, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/weightCloSpec/create/`, initialCLOSpecWeight, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)

export const DeleteCLOSpecWeight = createAsyncThunk(
    'CLOSpecWeight/DeleteCLOSpecWeight',
    async (ids, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/weightCloSpec/delete/`, ids, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)




const CLOSpecWeightSlice = createSlice({
    name: 'CLOSpecWeight',
    initialState,
    extraReducers: {

        [fetchNotAffectedCLOsToPLOI.pending]: (state, action) => {
            state.CLOs_PLOIStatus = 'loading'
        },
        [fetchNotAffectedCLOsToPLOI.fulfilled]: (state, action) => {
            state.CLOs_PLOIStatus = 'succeeded'
            // Add any fetched posts to the array
            state.CLOs_PLOI = action.payload
        },
        [fetchNotAffectedCLOsToPLOI.rejected]: (state, action) => {
            state.CLOs_PLOIStatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error
                console.log("action.error from inside extra add plo reducer", action.error)
            }
        },

        // [fetchKPIProgramById.pending]: (state, action) => {
        //   state.KPIStatus = 'loading'
        // },
        // [fetchKPIProgramById.fulfilled]: (state, action) => {
        //   state.KPIStatus = 'succeeded'

        //   // Add any fetched posts to the array
        //   state.KPI = action.payload
        // },
        // [fetchKPIProgramById.rejected]: (state, action) => {
        //   state.KPIStatus = 'failed'
        //   if (action.payload) {
        //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        //     state.error = action.payload
        //     console.log("action.payload from inside extra add plo reducer", action.payload)
        //   } else {
        //     state.error = action.error
        //     console.log("action.error from inside extra add plo reducer", action.error)
        //   }
        // },

        [fetchNotAffectedCLOsToGAI.pending]: (state, action) => {
            state.CLOs_GAIStatus = 'loading'
        },
        [fetchNotAffectedCLOsToGAI.fulfilled]: (state, action) => {
            state.CLOs_GAIStatus = 'succeeded'
            // Add any fetched posts to the array
            state.CLOs_GAI = action.payload
        },
        [fetchNotAffectedCLOsToGAI.rejected]: (state, action) => {
            state.CLOs_GAIStatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error
                console.log("action.error from inside extra add plo reducer", action.error)
            }
        },

        [addNewCLOSpecWeight.rejected]: (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
            } else {
                state.error = action.error
                console.log("action.error from inside extra add plo reducer", action.error)
            }
        },
    },

})


export default CLOSpecWeightSlice.reducer


