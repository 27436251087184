import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";


import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  deletePeoReport,
  fetchPeoReports,
} from "../../reduxToolkit/features/PeoReport/PeoReportSlice";

import { useTranslation } from "react-i18next";
import AccessAndRoleModal from "./AccessAndRoleModal";
import Menu from "@mui/material/Menu";

const useButtonStyles = makeStyles(buttonStyles);

export default function DropDownButtonPeoReport(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const buttonClasses = useButtonStyles();
  const anchorRef = useRef(null);

  const delete_PeoReport = (id) => {
    try {
      dispatch(deletePeoReport(id))
          .then(unwrapResult)
          .then(() => {
            dispatch(fetchPeoReports())
                .then(unwrapResult)
                .then((PeoReports) => {
                  props.orderReportsAccordingToStates()
                });
          });
    } catch (err) {
      console.log("Failed to delete  the delete courseReports : ", err);
    } finally {}
  };

  /******************************************************************
   *                          Handle Menu
   *****************************************************************/
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosebtn = () => {
    setAnchorEl(null);
  };

  const op_en = Boolean(anchorEl);

  return (
    <span>
      <Button
        info
        round
        ref={anchorRef}
        aria-controls={op_en ? "split-button-menu" : undefined}
        aria-expanded={op_en ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleClick}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
        id="basic-button"
      >
        <MoreVert />
      </Button>
      <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={op_en}
          onClose={handleClosebtn}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
      >
        <AccessAndRoleModal
            id={props.id}
            handleDropDownButtonClose={handleClosebtn}
            NameOfComponentUsed="AP-Report"
            interface='reports'
            disabled={false}
            data={props.data}
            staffList={props.staffList}
        />
        <MenuList id="basic-menu">
          <MenuItem>
            <p onClick={() => delete_PeoReport(props.id)}>
              {t("Delete")}
            </p>
          </MenuItem>
        </MenuList>
      </Menu>
    </span>
  );
}
