import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ErrorModal from "../../../Components/ErrorModal";


import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TableViewIcon from '@mui/icons-material/TableView';
import ViewListIcon from '@mui/icons-material/ViewList';
import { DataGrid } from "@mui/x-data-grid";
import { unwrapResult } from "@reduxjs/toolkit";
import { Select } from "antd";
import { fetchAllCommunityServicesActivities } from "../../../../reduxToolkit/features/CommunityServices/CommunityServicesSlice";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
const { Option } = Select;

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function AllCommunityServicesActivities() {

  const { t } = useTranslation();

  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");

  const persons = useSelector((state) => state.user.staffList);
  const AllCommunityServicesActivities = useSelector((state) => state.communityServices.AllCommunityServices);

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  useEffect(() => {
    dispatch(fetchAllCommunityServicesActivities())
        .then(unwrapResult)
        .then((AllCommunityServicesActivities) => {
          console.log(AllCommunityServicesActivities)
          setRows(AllCommunityServicesActivities);
        });
  }, []);

  useEffect(() => {
    dispatch(fetchStaff());
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  function onChangeActivitiesByOrganization(value) {
    let CommunityServicesActivities =
      AllCommunityServicesActivities &&
      AllCommunityServicesActivities.filter((CommunityServices) => {
        if (!value && search == "") {
          return CommunityServices;
        } else if (
          CommunityServices.community
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return CommunityServices;
        }
      });
    setRows(CommunityServicesActivities);
  }

  function onChangeActivitiesByType(value) {
    let CommunityServicesActivities =
      AllCommunityServicesActivities &&
      AllCommunityServicesActivities.filter((CommunityServices) => {
        if (!value && search == "") {
          return CommunityServices;
        } else if (
          CommunityServices.type.toLowerCase().includes(value.toLowerCase())
        ) {
          return CommunityServices;
        }
      });
    setRows(CommunityServicesActivities);
  }

  function onChangeActivitiesByAuthors(value) {
    let AllCommunityServices =
      AllCommunityServicesActivities &&
      AllCommunityServicesActivities &&
      AllCommunityServicesActivities.organization_body_details &&
      AllCommunityServicesActivities.organization_body_details[0].filter(
        (CommunityServices) => {
          if (!value) {
            return CommunityServices;
          } else if (
            value &&
            CommunityServices.type.toLowerCase().includes(value.toLowerCase())
          ) {
            return CommunityServices;
          } else if (
            CommunityServices.name.toLowerCase().includes(search.toLowerCase())
          ) {
            return CommunityServices;
          }
        }
      );
      console.log(AllCommunityServices)
    setRows(AllCommunityServices);
  }

  function onChangeActivitiesByName(value) {
    let AllCommunityServices =
      AllCommunityServicesActivities &&
      AllCommunityServicesActivities.filter((CommunityServices) => {
        if (!value) {
          return CommunityServices;
        } else if (
          value &&
          CommunityServices.title.toLowerCase().includes(value.toLowerCase())
        ) {
          return CommunityServices;
        }
      });
    setRows(AllCommunityServices);
  }

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/Activities`}>{t("Activities")}</Link>{" "}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/Activities/AllCommunityServicesActivities`}>
            {t("Community Services Activities")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br></br>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <div>
          <Select
            allowClear
            placeholder="Type"
            optionFilterProp="children"
            onChange={onChangeActivitiesByType}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              marginRight: "20px",
              width: "200px",
              backgroundColor: "none",
              zIndex: "10000",
            }}
          >
            {AllCommunityServicesActivities &&
              AllCommunityServicesActivities.map((CommunityServices) => (
                <Option value={CommunityServices && CommunityServices.type}>
                  {CommunityServices && CommunityServices.type}
                </Option>
              ))}
          </Select>
          <Select
            allowClear
            placeholder="Community"
            optionFilterProp="children"
            onChange={onChangeActivitiesByOrganization}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ marginRight: "20px", width: "200px" }}
          >
            {AllCommunityServicesActivities &&
              AllCommunityServicesActivities.map((CommunityServices) => (
                <Option
                  value={CommunityServices && CommunityServices.community}
                >
                  {CommunityServices && CommunityServices.community}
                </Option>
              ))}
          </Select>
          <Select
            showSearch
            allowClear
            placeholder="Author"
            optionFilterProp="children"
            onChange={onChangeActivitiesByAuthors}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ marginRight: "20px", width: "200px" }}
          >
            {persons &&
              persons.map(
                (person) => (
                  <Option value={person && person.id} >
                    {`${person.person_details[0].first_name + " " + person.person_details[0].last_name}`}
                  </Option>
                )
              )}
          </Select>
        </div>
      </div>
      <div>
        <Box sx={{ mt: '10px', ml: '1200px', p: '20px', width: '300px' }}>
          <ViewListIcon style={{ cursor: 'pointer' }} />
          <TableViewIcon style={{ cursor: 'pointer' }} />
        </Box>
      </div>
      <Card>
        <div style={{ width: "100%", height: 460, padding: "10px" }}>
          <DataGrid
            rows={rows || []}
            columns={columns}
            disableSelectionOnClick
            autoPageSize={true}
            columnBuffer={0}
            disableColumnMenu={true}
            showCellRightBorder
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 80]}
            pagination
          />
        </div>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}

const columns = [
  {
    field: "title",
    headerName: "Title",
    width: 200,
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    sortable: false,
  },
  {
    field: "author",
    headerName: "Faculties",
    width: 150,
    sortable: false,
    renderCell: (params) => (
      <div>
        {params.row.faculty_details.map((item) =>

          <Typography fullWidth display='block'>
            <GridItem>
              {item.first_name} { } {item.last_name}
            </GridItem>
          </Typography>
        )}
      </div>
    ),
  },
  {
    field: "start_date",
    headerName: "Start Date",
    width: 130,
    sortable: false,
  },
  {
    field: "end_date",
    headerName: "End Date",
    width: 130,
    sortable: false,
  },
  {
    field: "location",
    headerName: "Location",
    width: 110,
    sortable: false,
  },
  {
    field: "community",
    headerName: "Community",
    width: 120,
    sortable: false,
  },

  {
    field: "theme",
    headerName: "Theme",
    width: 150,
    sortable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
    sortable: false,
  },
];
