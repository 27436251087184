import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  kpiReportDocuments: [],
  kpiReportDocumentsStatus: 'idle',
  kpiReportDocument: null,
  kpiReportDocumentStatus: 'idle',
  error: null,

}

export const fetchKPIReportDocuments = createAsyncThunk(
    'kpiReportDocument/fetchKPIReportDocuments', 
    async (param, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+'/htmlKpiReport/all/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
})



export const fetchKPIReportDocumentsByProgramId = createAsyncThunk('kpiReportDocument/fetchKPIReportDocumentsByProgramId', async (id,  { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/htmlKpiReport/?kpiReport_id=${id}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )

  return response.data
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
})


export const fetchKPIReportDocumentById = createAsyncThunk('kpiReportDocument/fetchKPIReportDocumentById', async (id,  { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+`/htmlKpiReport/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  
  console.log('response data kpi by id', response.data)
  return response.data
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
})


export const addNewKPIReportDocument = createAsyncThunk(
  'kpiReportDocument/addNewKPIReportDocument',
  async (initialPLOReportDocument,  { rejectWithValue }) => {
   const token=localStorage.getItem('token')
   try {
    const response = await axios.post(BaseUrl+`/htmlKpiReport/create/`, initialPLOReportDocument, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})

export const updateKPIReportDocument = createAsyncThunk(
  'kpiReportDocument/updateKPIReportDocument',
  async (data,  { rejectWithValue }) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/htmlKPIReport/${data.id}/edit/`, data.updatedKPIReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data


    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
  
export const deleteKPIReportDocument = createAsyncThunk('kpiReportDocument/deleteKPIReportDocument', async (id,  { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.delete(BaseUrl+`/htmlKpiReport/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
})



const kpiReportDocumentsSlice = createSlice({
  name: ' kpiReportDocument',
  initialState,
  extraReducers: {
    

    [fetchKPIReportDocumentsByProgramId.pending]: (state, action) => {
      state.kpiReportDocumentsStatus = 'loading'
    },
    [fetchKPIReportDocumentsByProgramId.fulfilled]: (state, action) => {
      state.kpiReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.kpiReportDocuments = action.payload
    },
    [fetchKPIReportDocumentsByProgramId.rejected]: (state, action) => {
      state.kpiReportDocumentsStatus = 'failed'
      state.error = action.payload
    },

    [fetchKPIReportDocumentById.pending]: (state, action) => {
      state.kpiReportDocumentStatus = 'loading'
    },
    [fetchKPIReportDocumentById.fulfilled]: (state, action) => {
      state.kpiReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.kpiReportDocument = action.payload
    },
    [fetchKPIReportDocumentById.rejected]: (state, action) => {
      state.kpiReportDocumentStatus = 'failed'
      state.error = action.payload
    },
    [addNewKPIReportDocument.fulfilled]: (state, action) => {
    //   state.HTMLTemplates.push(action.payload)
    console.log("PLOReportDocument add action.payload",action.payload)

    },
    [deleteKPIReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("PLOReportDocument delete action.payload",action.payload)
    },
    [updateKPIReportDocument.fulfilled]: (state, action) => {
    //   const result = state.HTMLTemplates.filter(HTMLTemplate => HTMLTemplate.id !== action.payload.id);
    //   // console.log("HTMLTemplate id from filter from slice",HTMLTemplate.id)
    //   result.push(action.payload)
    //   state.HTMLTemplate = result
      console.log("PLOReportDocument update action.payload",action.payload)

    },
    [addNewKPIReportDocument.rejected]: (state, action) => {
      state.kpiReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
      [deleteKPIReportDocument.rejected]: (state, action) => {
        state.kpiReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
      [updateKPIReportDocument.rejected]: (state, action) => {
        state.kpiReportDocumentStatus = 'failed'
        if (action.payload) {
          state.error = action.payload
        } else {
          state.error = action.error
        }
      },
  },

})


export default kpiReportDocumentsSlice.reducer


