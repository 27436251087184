import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid'
import { notification } from 'antd'
import { primaryColor } from 'assets/jss/material-dashboard-pro-react'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchClosByCourse } from '../../../../../reduxToolkit/features/CLO/CloSpecSlice.jsx'
import DropDownButtonClo from '../../../../Components/DropDownButtonClo'
import ErrorModal from '../../../../Components/ErrorModal'

function CustomToolbar(props) {
  return (
    <GridToolbarContainer justifyContent="space-between">
      <GridItem xs={12} sm={12} md={11}>
        <Button color="primary" simple>
          <GridToolbarExport
            style={{ color: `${primaryColor[0]}`, fontWeight: 'bold' }}
          />
        </Button>
      </GridItem>
    </GridToolbarContainer>
  )
}

export default function CLOsListView(props) {

  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [search, setSearch] = useState('')
  const [disabledStatus, setDisabledStatus] = useState(false)
  const [dropDownDisabled, setDropDownDisabled] = useState(true)
  const [ping, setPing] = useState(false)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchClosByCourseMethod = async () => {
    try {
      const resultAction = await dispatch(fetchClosByCourse(props.id))
        .unwrap()
        .then((clos) => {
          setRows(
            clos.filter((e) =>
              e.title.toLowerCase().includes(search.toLowerCase()),
            ),
          )
        })
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    fetchClosByCourseMethod()
    if (localStorage.getItem('CourseState') != 'Draft') {
      setDisabledStatus(true)
    }
  }, [ping, search, dispatch])

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: 'target',
      headerName: 'Target',
      flex: 1,
      type: 'number',
      editable: true,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      editable: true,
      sortable: false,
    },
    {
      field: 'levelClo_name',
      headerName: 'Level',
      flex: 2,
      editable: true,
      sortable: false,
    },
    {
      field: 'subLevelClo_name',
      headerName: 'Sub-level',
      flex: 2,
      editable: true,
      sortable: false,
    },
    {
      field: 'teaching_strategy_names',
      headerName: 'Teaching strategies',
      flex: 2,
      editable: true,
      sortable: false,
    },
    {
      field: 'assessmentMethodSpec_names',
      headerName: 'Assessment methods',
      flex: 2,
      editable: true,
      sortable: false,
    },
  ]

  const { t } = useTranslation()

  const [rows, setRows] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [isEditor, setEditor] = useState(true)

  const clos = useSelector((state) => state.cloSpec.closByCourse)
  return (
    <div>
      <GridContainer justifyContent="space-between" alignItems="baseline">
        {isEditor && (
          <GridItem xs={12} sm={12} md={11}>
            <Link to={`/citrine/CreateCourseClo/course/${props.id}/clo/:idClo`}>
              {!disabledStatus && <Button color="primary">{t('Add')}</Button>}
            </Link>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={1}>
          <DropDownButtonClo
            viewList={true}
            selectedRows={selectionModel}
            clo_id={selectionModel && selectionModel[0]}
            clo={props.clos}
            courseID={parseInt(props.id)}
            ping={ping}
            setPing={setPing}
          />
        </GridItem>
      </GridContainer>

      <Card simple>
        <CardBody simple>
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              rowHeight={50}
              rows={rows || []}
              columns={columns.map((column) => ({
                ...column,
                editable: false,
              }))}
              autoPageSize={true}
              columnBuffer={0}
              disableSelectionOnClick
              showCellRightBorder
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel)
                setDropDownDisabled(false)
              }}
              selectionModel={selectionModel}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </div>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
