import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom'
// antdesign components
import { DatePicker, Form, Input, message, notification, Select } from 'antd'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import { infoColor } from 'assets/jss/material-dashboard-pro-react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addProfessionalDevelopment } from 'reduxToolkit/features/ProfessionalDevelopment/ProfessionalDevelopmentSlice.js'
import { fetchPrograms } from 'reduxToolkit/features/program/ProgramsSlice.js'
import {
  fetchStaff,
  fetchStaffById,
} from '../../../../../reduxToolkit/features/user/UserSlice'
import ErrorModal from '../../../../Components/ErrorModal'
// import TextArea from "antd/lib/input/TextArea";
import TextArea from 'antd/lib/input/TextArea'
import { fetchAuthorityAccordingToType } from '../../../../../reduxToolkit/features/AuthorityConfig/AuthorityConfigSlice'
import { fetchColleges } from '../../../../../reduxToolkit/features/college/CollegesSlice'
import { fetchCourses } from '../../../../../reduxToolkit/features/course/CoursesSlice'
import { fetchDepartments } from '../../../../../reduxToolkit/features/department/DepartmentsSlice'
import { MediaBaseUrl } from 'assets/utils/accessToken'

const useStyles = makeStyles(styles)
const useTitleStyles = makeStyles(titleStyles)

export default function CreateProfessionalDevelopmentActivity({ match }) {
  const { t } = useTranslation()
  let history = useHistory()
  let { id } = useParams()
  const titleClasses = useTitleStyles()
  const [form] = Form.useForm()

  const [selectedProgram, setSelectedProgram] = React.useState(null)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [levelType, setLevelType] = React.useState('')

  const [students, setStudents] = React.useState([])
  const [staff, setStaff] = useState([])
  const [faculty, setFaculty] = useState([])

  const [university_id, setUniversity_id] = React.useState(null)

  const dispatch = useDispatch()

  const fieldsStatus = useSelector((state) => state.fieldSpec.fieldsStatus)
  const admissionRequirementsStatus = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirementsStatus,
  )
  const graduationRequirementsStatus = useSelector(
    (state) => state.graduationRequirementSpec.graduationRequirementsStatus,
  )

  const configAcoordingtoType = useSelector(
    (state) => state.authorityConfig.AuthorityConfigsByType,
  )
  console.log(configAcoordingtoType)

  useEffect(() => {
    dispatch(fetchStaffById(id))
    const semester_id = localStorage.getItem('semester_id')
    dispatch(fetchPrograms(semester_id))

    // dispatch(fetchProgramById(id))
    //   .then(unwrapResult)
    //   .then((program) => {
    //     // do additional work;
    //     const courses = program.courses;
    //     setCoursesByProgram(courses);
    //   });

    dispatch(fetchStaff())
      .unwrap()
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
  }, [dispatch])

  //const authorsList = useSelector((state) => state.authorityConfig.AuthorityConfigsByType)

  const authorsList = useSelector(
    (state) => state.authorityConfig.AuthorityConfigs,
  )

  console.log('authorsList', authorsList)

  // const programs = useSelector((state) => state.program.programs);
  const person = useSelector((state) => state.user.staff)

  const [coursesByProgram, setCoursesByProgram] = useState([])

  const colleges = useSelector((state) => state.college.colleges)
  const departments = useSelector((state) => state.department.departments)
  const courses = useSelector((state) => state.course.courses)

  function onHandleActivityLevelType(communityString) {
    //console.log(communityString);
    setLevelType(communityString)
    // dispatch(fetchAuthorityConfigs());
    dispatch(fetchAuthorityAccordingToType(communityString.toString()))
    // dispatch(fetchUserAccordingToCommunityType(communityString.toString()));
    // console.log('authorsList',authorsList);

    dispatch(fetchAuthorityAccordingToType(communityString.toString()))

    if (communityString == 'department') {
      dispatch(fetchDepartments())
    } else if (communityString == 'college') {
      dispatch(fetchColleges())
    } else if (communityString == 'program') {
      const semester_id = localStorage.getItem('semester_id')
      dispatch(fetchPrograms(semester_id))
    } else if (communityString == 'course') {
      dispatch(fetchCourses())
    }
  }

  // const handleProgram = (value) => {
  //   console.log(`selected program in onchange ${value}`);
  //   setSelectedProgram(value);
  //   dispatch(fetchProgramById(value))
  //     .then(unwrapResult)
  //     .then((program) => {
  //       // do additional work;
  //       const courses = program.courses;
  //       setCoursesByProgram(courses);
  //     });
  // };
  const programs = useSelector((state) => state.program.programs)

  const persons = useSelector((state) => state.user.staffList)

  console.log('persons', persons)

  const [start_date, setStartDate] = React.useState('')
  const [end_date, setEndDate] = React.useState('')

  function onStartDateChange(value, dateString) {
    setStartDate(dateString)
  }
  function onEndDateChange(value, dateString) {
    setEndDate(dateString)
  }
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    message.error('Submit failed!')
  }

  const semester_id = localStorage.getItem('semester_id')
  let academicYear_id = 1

  const onFinish = async (values) => {
    console.log('Received values of form: ', values)
    // let staff_participants_ids = values.staff_participants_ids || [];
    // let faculty_participants_ids = values.faculty_participants_ids || [];
    // let student_participants_ids = values.student_participants_ids || [];
    let faculty_id = id
    // let program_id = id;
    const toAddFacultyActivity = {
      ...values,
      start_date,
      end_date,
      semester_id,
      academicYear_id,
      faculty_id,
      // staff_participants_ids,
      // faculty_participants_ids,
      // student_participants_ids,
      // program_id,
    }
    console.log('toAddFacultyActivity: ', toAddFacultyActivity)

    const resultAction = await dispatch(
      // addNewFacultyActivity(toAddFacultyActivity)
      addProfessionalDevelopment(toAddFacultyActivity),
    )

    if (addProfessionalDevelopment.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon('success', `Added successfully`)
      history.push(
        `/citrine/faculty/teachingStaff/${id}/professionalDevelopmentActivities`,
      )
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log('resultAction.payload from component', resultAction.payload)
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        console.log('resultAction.error  from component', resultAction.error)
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const { Option } = Select

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t('Faculty')}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t('Teaching Staff')}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${
                person && person.person_details[0].last_name
              }`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/professionalDevelopmentActivities`}
            >
              {t('Professional development')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Add a new activity
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Type')}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the type',
                        },
                      ]}
                    >
                      <Select style={{ width: '100%' }} size={200} showArrow>
                        <Option
                          //value="counselling_and_support"
                          value="training"
                        >
                          training
                        </Option>
                        <Option
                          value="course"
                          // value="professional_development"
                        >
                          course
                        </Option>
                        <Option
                          value="workshop"
                          //value="research_and_innovation"
                        >
                          workshop
                        </Option>
                        <Option
                          value="other"
                          //value="community_partnership"
                        >
                          other
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Activity Level')}
                      name="authorityLevelSelect"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the type',
                        },
                      ]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        size={200}
                        showArrow
                        onChange={onHandleActivityLevelType}
                      >
                        <Option value="university">university</Option>
                        <Option value="college">college</Option>
                        <Option value="program">program</Option>
                        <Option value="course">course</Option>
                        <Option value="department">department</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  {levelType == 'college' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t('Colleges')} name="college_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {colleges &&
                            colleges.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  {levelType == 'department' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t('Departments')} name="department_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {departments &&
                            departments.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}
                  {levelType == 'course' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t('Courses')} name="course_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {courses &&
                            courses.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.title}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}
                  {levelType == 'program' ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t('Programs')} name="program_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {programs &&
                            programs.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.program_degree_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (
                    <p></p>
                  )}

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Title')}
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the title',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t('Start date')}
                      name="start_date"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the start date',
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onStartDateChange}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t('End date')}
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the end date',
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onEndDateChange}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Description')}
                      name="description"
                      rules={[
                        {
                          // required: true,
                          message: 'Please enter the description',
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Programs")} name="program_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {programs &&
                          programs.map((program) => (
                            <Option key={program.id} value={program.id}>
                              {program.program_degree_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem> */}

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t('Theme')}
                      name="theme"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the theme',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t('Location')}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the location',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t('Organization')}
                      name="organization"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the theme',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t('Trainer')}
                      name="trainer"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the trainer',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Authority Person')}
                      name="authorityLevelConfig_id"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the authors',
                        },
                      ]}
                    >
                      <Select
                        // mode="multiple"
                        showSearch
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {persons &&
                          persons.map((person) => (
                            <Option key={person.id} value={person.id}>
                              {!person?.person_details[0]?.avatar ? (
                                <span
                                  style={{
                                    backgroundColor: 'rgb(143, 102, 130)',
                                    width: '23px',
                                    height: '23px',
                                    color: 'white',
                                    borderRadius: '50%',
                                    paddingLeft: '7px',
                                    marginRight: '15px',
                                    display: 'inline-block',
                                    lineHeight: '23px',
                                  }}
                                >
                                  {person?.person_details[0]?.first_name
                                    .charAt(0)
                                    .toUpperCase() + '     '}
                                </span>
                              ) : (
                                <img
                                  style={{ width: '20px', height: '20px' }}
                                  src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                />
                              )}
                              {`${
                                person &&
                                person.person_details &&
                                person.person_details[0].first_name
                              } ${
                                person &&
                                person.person_details &&
                                person.person_details[0].last_name
                              }`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t('Team members')}
                      name="team_members_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        size={200}
                        showArrow
                        optionFilterProp="children"

                        // onChange={handleGraduationRequirements_ids}
                      >
                        {persons &&
                          persons.map((person) => (
                            <Option key={person.id} value={person.id}>
                              {!person?.person_details[0]?.avatar ? (
                                <span
                                  style={{
                                    backgroundColor: 'rgb(143, 102, 130)',
                                    width: '23px',
                                    height: '23px',
                                    color: 'white',
                                    borderRadius: '50%',
                                    paddingLeft: '7px',
                                    marginRight: '15px',
                                    display: 'inline-block',
                                    lineHeight: '23px',
                                  }}
                                >
                                  {person?.person_details[0]?.first_name
                                    .charAt(0)
                                    .toUpperCase() + '     '}
                                </span>
                              ) : (
                                <img
                                  style={{ width: '20px', height: '20px' }}
                                  src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                />
                              )}
                              {`${
                                person &&
                                person.person_details &&
                                person.person_details[0].first_name
                              } ${
                                person &&
                                person.person_details &&
                                person.person_details[0].last_name
                              }`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* </FormControl> */}

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
