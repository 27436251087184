// @material-ui/core components
import { Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ScoreCourseByQuestion } from "../../../../reduxToolkit/features/assessment/AssessmentSlice";
import {
  CalculScoreCourse,
  fetchCourseById
} from "../../../../reduxToolkit/features/course/CoursesSlice";

import DropDownButtonCourseReport_Survey from "../../../Components/DropDownButtonCourseReport_Survey";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);

export default function ClosQuestionListCoordinator({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const dispatch = useDispatch();

  const { id } = match.params;

  const [assessmentMethodsArry, setAssessmentMethodsArry] = useState([]);

  const [score_course_by_question, setScore_course_by_question] = useState([]);

  let arr = [];
  useEffect(() => {

    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        let ScoreCourse = {
          course_id: id,
          semester_id:
            course &&
            course.program_detail &&
            course.program_detail[0] &&
            course.program_detail[0].semester_id,
        };
        console.log(ScoreCourse, "ScoreCourse");

        dispatch(CalculScoreCourse(ScoreCourse));
        dispatch(ScoreCourseByQuestion(ScoreCourse))
          .then(unwrapResult)
          .then((score) => {
            console.log(score, "score");
            setScore_course_by_question(score.score_by_course);
          });
      });
  }, [dispatch]);
  const course = useSelector((state) => state.course.course);
  const AssessmentMethod = useSelector(
    (state) => state.course.AssessmentMethod
  );
  let content;
  if (score_course_by_question >= 75) {
    content = (
      <div>
        <Button
          color="success"
          shape="round"
          style={{
            width: "250px",
            height: "45px",
            fontSize: "16px",
            borderRadius: "28px",
            paddingTop: "20px",
          }}
        >
          <strong>
            <p>Score Course : {score_course_by_question}%</p>
          </strong>
        </Button>
      </div>
    );
  }
  if (score_course_by_question >= 50 && score_course_by_question < 75) {
    content = (
      <div>
        <Button
          color="info"
          shape="round"
          style={{
            width: "250px",
            height: "45px",
            fontSize: "16px",
            paddingTop: "20px",
            borderRadius: "28px",
          }}
        >
          <strong>
            <p>Score Course : {score_course_by_question}%</p>
          </strong>
        </Button>
      </div>
    );
  }
  if (score_course_by_question < 50 && score_course_by_question >= 0) {
    content = (
      <div>
        <Button
          color="danger"
          shape="round"
          style={{
            width: "250px",
            height: "45px",
            fontSize: "16px",
            borderRadius: "28px",
            paddingTop: "20px",
          }}
        >
          <strong>
            <p>Score Course : {score_course_by_question}%</p>
          </strong>
        </Button>
      </div>
    );
  }
  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
        <Breadcrumb.Item>
            <Link
              to={`/citrine/courseCoordinatorWorkspace/assessment/`}

            >
              {t("Courses List ")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseCoordinatorWorkspace/closQuestions_list/course/${id}`}
              style={{ color: "orange" }}
            >
              {t("Clos")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <Card>
        <CardHeader
          color="info"
          icon
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CardIcon color="primary" style={{ height: "60px" }}>
            <SchoolRounded />
          </CardIcon>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={2}>
              
                <h4
                  style={{
                    color: "#8F6682",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong>
                    {course &&
                      course.program_detail &&
                      course.program_detail.map((el) => el.code)}
                  </strong>
                </h4>
           

              
                <h4
                  style={{
                    color: "#6C7B9D",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong>{course && course.code && course.code}</strong>
                </h4>
             
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              
                <h4
                  style={{
                    color: "#8F6682",
                    fontSize: "14px",
                    height: "13px",
                  }}
                >
                  <strong>
                    {course &&
                      course.program_detail &&
                      course.program_detail.map((el) => el.program_degree_name)}
                  </strong>
                </h4>
             

            
                <h4
                  style={{
                    color: "#6C7B9D",
                    fontSize: "14px",
                    height: "13px",
                  }}
                >
                  <strong>{course && course.title && course.title}</strong>
                </h4>
             
            </GridItem>

            <GridItem xs={12} sm={12} md={7}>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={5}>
                  {content}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Button
                    color="info"
                    shape="round"
                    style={{
                      width: "260px",
                      height: "45px",
                      fontSize: "16px",
                      borderRadius: "28px",
                      paddingTop: "20px",
                    }}
                  >
                    <strong>
                      <p>
                        {t("Total actual weight")} :
                        {course &&
                          course.total_actual_weight &&
                          course.total_actual_weight}
                      </p>
                    </strong>
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <DropDownButtonCourseReport_Survey course={course} />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
      <GridContainer>
        <GridItem>
          <br></br>{" "}
        </GridItem>
        <GridItem>
          <br></br>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {course &&
          course.clos &&
          course.clos.map((item, id) => (
            <GridItem
              key={id}
              xs={12}
              sm={6}
              md={4}
              component={Link}
              to={`/citrine/courseCoordinatorWorkspace/result_calcul_Clos_Questions/course/${course.id}/clo/${item.id}`}
            >
              <Card>
                <CardHeader
                  style={{
                    textAlign: "center",
                    border: "1px solid lightgray",
                    height: "300px",
                  }}
                >
                  <h5 style={{ color: "orange" }}>{item.code}</h5>
                  <h4>
                    <p key={id} className={classes2.cardCategory}>
                      {item.title.slice(0, 50)}...
                    </p>
                  </h4>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "40px",
                      marginEnd: "10px",
                    }}
                  >
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        style={{
                          height: "50px",
                          width: "50px",
                          backgroundColor: "red",
                        }}
                      >
                        {item &&
                          item.score_clo_question &&
                          item.score_clo_question.toFixed(0)}
                        %
                      </Avatar>
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "orange",
                        }}
                      >
                        score
                      </p>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: "orange",
                          height: "50px",
                          width: "50px",
                        }}
                      >
                        {item &&
                          item.clo_coverage &&
                          item.clo_coverage.toFixed(0)}
                        %
                      </Avatar>
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "orange",
                        }}
                      >
                        Coverage
                      </p>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: "deepskyblue",
                          height: "50px",
                          width: "50px",
                        }}
                      >
                        {item &&
                          item.percentage_75 &&
                          item.percentage_75.toFixed(0)}
                        %
                      </Avatar>
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "orange",
                        }}
                      >
                        Attainment
                      </p>
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "orange",
                        }}
                      >
                        {">"} 75%
                      </p>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: "mediumseagreen",
                          height: "50px",
                          width: "50px",
                        }}
                      >
                        {item &&
                          item.percentage_80 &&
                          item.percentage_80.toFixed(0)}
                        %
                      </Avatar>
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "orange",
                        }}
                      >
                        Attainment
                      </p>
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "orange",
                        }}
                      >
                        {">"} 80%
                      </p>
                    </Grid>
                  </div>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
