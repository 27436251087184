import red from "@material-ui/core/colors/red";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import React, { useRef, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import { useDispatch } from "react-redux";
import { ImageConfig } from "../../config/ImageConfig";
import uploadImg from "../../../media/cloud-upload-regular-240.png"
import {DocumentBaseUrl} from "../../../assets/utils/accessToken";
import { PostDoc } from "../../../reduxToolkit/features/docslice/DocSlice";
import "./NewFile.css";
import {Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const NewFile = ({ ping, setPing , model, resource, resource_id, course_id, fetchDocsCustomFunction}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [document, setDocument] = useState("");
  // const [model, setModel] = useState(model);
  // const [resource, setResource] = useState(resource);
  // const [resource_id, setResource_id] = useState(resource_id);
  const [pinned, setPinned] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  // const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  // const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  // const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const handleUpload = async (e) => {
    e.preventDefault();
    setFileList(e.target.files[0]);
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      const { data } = await axios.post(
        DocumentBaseUrl +`/api/upload0`,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      setDocument(data.file.filePath);
      setName(data.file.fileName);
      setLoading(false);
    } catch (err) {
      // alert(err.response.data.msg);
    }
  };
  const fileRemove = () => {
    setFileList(0);
    setDocument("");
  };
  const handelAdd = (e) => {
    e.preventDefault();
    dispatch(
      PostDoc(
        { document, model, resource, resource_id, pinned, name, code, course_id },
        dispatch
      )
    );
    fetchDocsCustomFunction();
    fileRemove();
    setPing(!ping);
  };
  const showFile = (e) => {
    // e.preventDefault();
    // const reader = new FileReader();
    // reader.onload = (e) => {
    //   const text = e.target.result;
    //   setText(text);
    // };
    // reader.readAsBinaryString(e.target.files[0]);
    // console.log(text);
  };
  return (
    <div className="newFile">
      
        <Button color="primary" onClick={handleShow}>
        <AddCircleIcon />
        Upload Evidences
        </Button>
     
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
        
          <>
            {fileList ? (
              <>
                {loading ? (
                  <div className="loading1">
                    <div></div>
                  </div>
                ) : (
                  <div className="drop-file-preview__item">
                    <img
                      src={
                        ImageConfig[fileList.type.split("/")[1]] ||
                        ImageConfig["default"]
                      }
                      alt=""
                    />
                    <div className="drop-file-preview__item__info">
                      <p>{fileList.name}</p>
                      <p>{fileList.size}B</p>
                    </div>
                    <span className="dropdel" onClick={fileRemove}>
                      <DeleteIcon style={{ color: red[600] }} />
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div
                //ref={wrapperRef}
                className="drop-file-input"
                //onDragEnter={onDragEnter}
                //onDragLeave={onDragLeave}
                //onDrop={onDrop}
              >
                <div className="drop-file-input__label">
                  <img src={uploadImg} alt="" />
                </div>
                <input
                  type="file"
                  onChange={(e) => {
                    handleUpload(e);
                    showFile(e);
                  }}
                />
              </div>
            )}
          </>
          </DialogContent>
        <DialogActions> 
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!loading && document ? false : true}
            variant="outlined"
            onClick={(e) => {
              handleClose();
              handelAdd(e);
            }}
          >
            Upload
          </Button>
        </DialogActions>
        </Dialog>{" "}
    </div>
  );
};

export default NewFile;
