import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchReportStatesConfigs } from "../../../../reduxToolkit/features/ReportStatesConfig/ReportStatesConfigSlice";
import DropDownReportStatesConfigButton from "../../../Components/DropDownReportStatesConfigButton";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function PublisherPage() {
  const cardClasses = useCardStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();

  const ReportStatesConfigsItems = useSelector((state) =>
      state.ReportStates.ReportStatesConfigs
  );

  useEffect(() => {
    dispatch(fetchReportStatesConfigs());
  }, []);

  return (
    <div>
      <GridContainer
        md={12}
        xs={12}
        sm={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t("other Configurations")} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/ReportStatesConfigPage`}>
                <span> {t("Report States Page")} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/configuration/others/AddReportStatesConfigPage">
        <Button color="info">{t("  Add")}</Button>
      </Link>
      <GridContainer md={12} xs={12} sm={12}>
        {ReportStatesConfigsItems &&
          ReportStatesConfigsItems.map((ReportState) => (
            <GridItem xs={12} sm={12} md={4} key={ReportState.id}>
              <Card
                style={{
                  maxWidth: "380px",
                  minWidth: "380px",
                  maxHeight: "10px",
                  minHeight: "100px",
                }}
                cardHover
              >
                <CardHeader>
                  <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <GridItem xs={10} sm={10} md={10}>
                      <h4 className={cardClasses.cardProductTitle}>
                        {`Name : ${ReportState.name}`}
                      </h4>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                      <DropDownReportStatesConfigButton id={ReportState.id} />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
