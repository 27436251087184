import { makeStyles } from "@material-ui/core/styles";
import { Badge, Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DropDownResourceTypeButton from "views/Components/DropDownResourceTypeButton";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchResourceTypeConfigs, selectAllResourceTypeConfigs
} from "../../../../reduxToolkit/features/resourceTypeConfig/ResourceTypeConfigSlice";


const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function ResourceTypesPage() {
  const dispatch = useDispatch();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();

  const { t } = useTranslation();
  const resourceTypeConfigsStatus = useSelector(
    (state) => state.resourceTypeConfig.resourceTypeConfigsStatus
  );
  console.log("status subareas", resourceTypeConfigsStatus);

  useEffect(() => {

    dispatch(fetchResourceTypeConfigs());

  }, [dispatch]);

  const resourceTypeConfigs = useSelector(selectAllResourceTypeConfigs);
  console.log("*******subareas", resourceTypeConfigs);
  const error = useSelector((state) => state.resourceTypeConfig.error);

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                {" "}
                {t("Course Configurations")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/resourceTypeConfigs`}
                style={{ color: "orange" }}
              >
                {t("Ressource Type Config List")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Link to="/citrine/createResourceTypeConfig/:id">
        <Button color="info">{t("Add")}</Button>
      </Link>

      <GridContainer>
        {resourceTypeConfigs &&
          resourceTypeConfigs.map((resourceTypeConfig) => (
            <GridItem>
              <Card product className={classes.cardHover}>
                <CardBody
                  style={{
                    maxWidth: "380px",
                    minWidth: "380px",
                    maxHeight: "180px",
                    minHeight: "180px",
                  }}
                  cardHover
                >
                  <GridContainer>

                    <GridItem xs={10} sm={10} md={10}>
                      <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12} md={11}>
                          <h4 className={classes.cardProductTitle}>
                            {resourceTypeConfig?.name}
                          </h4>
                          <h5 className={classes.cardProductTitle}>
                            {`Is it a book: ${resourceTypeConfig?.isBook}`}
                          </h5>

                          {resourceTypeConfig &&
                            resourceTypeConfig.areaSpec_details &&
                            resourceTypeConfig.areaSpec_details.length ? (
                            resourceTypeConfig.areaSpec_details.map((el) => (
                              <Badge
                                className="site-badge-count-109"
                                count={el.name}
                                style={{ backgroundColor: "#52c41a" }}
                              />
                            ))
                          ) : (
                            <span></span>
                          )}
                        </GridItem>

                        <GridItem xs={2} sm={2} md={1}>
                          <DropDownResourceTypeButton
                            id={resourceTypeConfig.id}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
