import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const FacultyHiringAndRetention = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  return (
    <>
      <h3>INSTITUTIONAL SUPPORT: Faculty Hiring and Retention</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of:
      </p>
      <ul>
        <li> The process for hiring of new faculty.</li>
        <li>Strategies used to retain current qualified faculty.</li>
      </ul>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
    </>
  );
};

export default FacultyHiringAndRetention;
