import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import cardBodyStyle from 'assets/jss/material-dashboard-pro-react/components/cardBodyStyle';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { fetchHTMLTemplateById } from '../../../../reduxToolkit/features/HTMLTemplate/HTMLTemplateSlice';
const useCardBodyStyles = makeStyles(cardBodyStyle);

export default function HTMLTemplateViewer({ match }) {

    const cardBodyClasses = useCardBodyStyles()

    const { id } = match.params

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(fetchHTMLTemplateById(id))
    }, [dispatch])

    const HTMLTemplate = useSelector((state) => state.HTMLTemplate.HTMLTemplate)
    console.log('HTMLTemplate', HTMLTemplate && HTMLTemplate)

    const values = [
        ["Books", "Clothes", "Medicals"],
        ["$22", "$190", "$516"],
    ];
    const headers = [["<b> Item </b>"], ["<b> Expenditure </b>"]];
    const data = [
        {
            type: "table",
            header: {
                values: headers,
                align: "center",
            },
            cells: {
                values: values,
                align: "center",
            },
        },
    ];
    return (
        <div>
            <GridContainer xs={12} sm={12} md={10} justify='flex-end'>

                <GridItem>
                    <Link to={`/citrine/HTMLTemplates/${id}/editor`}>
                        <Button
                            color="white"
                            aria-label="Edit"
                            justIcon
                            round
                        >
                            <Edit />
                        </Button>
                    </Link>
                </GridItem>

            </GridContainer>
            <GridContainer xs={12} sm={12} md={10}>
                <Card>

                    <CardBody className={cardBodyClasses.cardBodyWithMarginTop}>

                        <GridContainer justify='center'>
                            <GridItem>
                                <div
                                    dangerouslySetInnerHTML={{ __html: HTMLTemplate && HTMLTemplate.html }} ></div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>

                </Card>
            </GridContainer>
        </div>

    );

}

