import "@lourenci/react-kanban/dist/styles.css";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs, notification } from "antd";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchCloResults } from "../../../../../reduxToolkit/features/CLO/CloSlice";
import useDifficultiesAndChallengesTable from "../../../../Components/useDifficultiesAndChallengesTable";
import "../../improvement.css";
import AddDifficultiesAndChallengesModal from "./AddDifficultiesAndChallengesModal";

import GeneralDeleteModal from "../../../../Components/GeneralDeleteModal";
import Button from "components/CustomButtons/Button.js";
import {
  addNewDifficultiesAndChallenges,
  fetchDifficultiesAndChallengesByCourseId,
  deleteDifficultiesAndChallenges,
} from "../../../../../reduxToolkit/features/DifficultiesAndChallenges/DifficultiesAndChallengesSlice";
const { TabPane } = Tabs;
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function DifficultiesAndChallenges(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [closResultsMatrix, setClosResultsMatrix] = useState([]);
  useEffect(() => {
    dispatch(fetchDifficultiesAndChallengesByCourseId(props.idCourse));
  }, [dispatch]);

  const DifficultiesAndChallenges = useSelector(
    (state) => state.DifficultiesAndChallenges.DifficultiesAndChallengesList
  );

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headCells = [
    { id: "Difficulties and Challenges", label: "Difficulties and Challenges" },
    ,
    { id: "Consequences", label: "Consequences" },
    { id: "Actions Taken", label: "Actions Taken" },
    { id: "delete", label: "Delete" },
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useDifficultiesAndChallengesTable(records, headCells, filterFn);

  //Modal
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [
    difficultiesAndChallengesId,
    setDifficultiesAndChallengesId,
  ] = React.useState(null);

  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleDelete_DifficultiesAndChallenges = (id) => {
    // console.log(difficultiesAndChallengesId,"difficultiesAndChallengesId")
    dispatch(deleteDifficultiesAndChallenges(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        dispatch(fetchDifficultiesAndChallengesByCourseId(props.idCourse));
        openNotificationWithIcon("success", `Deleted`);
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };
  return (
    <div>
      <AddDifficultiesAndChallengesModal
        type={props.type}
        idCourse={props.idCourse}
      />
      <TblHead />
      <TblContainer>
        <TableBody style={{ borderLeft: "1px solid lightgray" }}>
          {DifficultiesAndChallenges &&
            DifficultiesAndChallenges.map((item) => (
              <TableRow key={item.id}>
                <div
                  style={{
                    backgroundColor: "rgb(175,131,183,0.6)",
                    color: "white",
                  }}
                >
                  {item.type}
                </div>
                {item &&
                  item.difficulties_and_challenges &&
                  item.difficulties_and_challenges.map((el) => (
                    <div style={{ width: "100%" }}>
                      <TableCell
                        style={{
                          borderRight: "1px solid lightgrey",
                          width: "25%",
                        }}
                      >
                        <p>{el.name}</p>
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid lightgrey",
                          width: "25%",
                        }}
                      >
                        <p>{el.consequences}</p>
                      </TableCell>

                      <TableCell
                        style={{
                          borderRight: "1px solid lightgrey",
                          width: "25%",
                        }}
                      >
                        <p>{el.actions_taken}</p>
                      </TableCell>

                      <TableCell
                        style={{
                          borderRight: "1px solid lightgrey",
                          width: "300px",
                        }}
                      >
                        <GeneralDeleteModal
                          delete_Element={() => handleDelete_DifficultiesAndChallenges(el.id)}
                          Element_ID={el.id}
                          open={open}
                          handleClose={handleClose}
                        />
                      </TableCell>
                    </div>
                  ))}
              </TableRow>
            ))}
        </TableBody>
        <br></br>
        {/* <GeneralDeleteModal
          delete_Element={() =>
            handleDelete_DifficultiesAndChallenges(
              handleDelete_DifficultiesAndChallenges
            )
          }
          Element_ID={difficultiesAndChallengesId}
          open={open}
          handleClose={handleClose}
        /> */}
      </TblContainer>
    </div>
  );
}
