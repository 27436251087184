import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import { makeStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from '../../../../components/CustomButtons/Button'
import CreateSurveyModal from '../../../Components/CreateSurveyModal'

const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)

const DropDownButtonCourseReportSurveyInstructor = ({ course }) => {
  const { t } = useTranslation()
  const buttonClasses = useButtonStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }
  const dispatch = useDispatch()
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }
  const classes = useStyles()
  return (
    <span>
      <Button
        color="primary"
        round
        ref={anchorRef}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
      >
        <MoreVert />
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper dropdownStyle={{ zIndex: 10000 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  dropdownStyle={{ zIndex: 10000 }}
                >
                  <CreateSurveyModal
                    handleMenuItemClick={handleMenuItemClick}
                    courseID={course && course.id && course.id}
                    course={course && course}
                    handleToggle={handleToggle}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  )
}

export default DropDownButtonCourseReportSurveyInstructor
