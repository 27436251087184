import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb } from 'antd'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import React from 'react'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

export default function GroupActionsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const classes = useStyles()
  const cardClasses = useCardStyles()
  let CourseReport = 'CourseReport'
  let ProgramSpec = 'ProgramSpec'
  let CourseSpec = 'CourseSpec'
  let APReport = 'APReport'
  let GAReport = 'GAReport'
  let PLOReport = 'PLoReport'
  let PEOReport = 'PEOReport'
  let KPIReport = 'KPIReport'

  const groupModelsReport = [CourseReport,ProgramSpec,CourseSpec,APReport,GAReport,PLOReport,PEOReport,KPIReport]
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
            <Breadcrumb.Item>{t('Report Model')}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <GridContainer
        md={12}
        xs={12}
        sm={12}
        direction="row"
        // justify="space-between"
        alignItems="flex-start"
      >
        
        {groupModelsReport.map((el)=>
             <GridItem md={4} xs={12} sm={12}>
            <Card product className={classes.cardHover}>
              <CardBody style={{ width: 375, height: 200 }}>
                <h4 className={classes.cardProductTitle}>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    <Link to={`/citrine/groupActions/states/${el}/`}>
                      {el}
                    </Link>
                  {/* </a> */}
                </h4>
              </CardBody>
            </Card>
          </GridItem>)
        }
        {/* <GridItem md={4} xs={12} sm={12}>
          <Card product className={classes.cardHover}>
            <CardBody style={{ width: 375, height: 200 }}>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <Link to={`/citrine/groupActions/states/${CourseReport}/`}>
                    Course Report
                  </Link>
                </a>
              </h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} xs={12} sm={12}>
          <Card product className={classes.cardHover}>
            <CardBody style={{ width: 375, height: 200 }}>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <Link to={`/citrine/groupActions/states/${KPIReport}/`}>
                    Key Performance Indicator Report
                  </Link>
                </a>
              </h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} xs={12} sm={12}>
          <Card product className={classes.cardHover}>
            <CardBody style={{ width: 375, height: 200 }}>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <Link to={`/citrine/groupActions/states/${GAReport}/`}>
                    Graduate Attribute Report
                  </Link>
                </a>
              </h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} xs={12} sm={12}>
          <Card product className={classes.cardHover}>
            <CardBody style={{ width: 375, height: 200 }}>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <Link to={`/citrine/groupActions/states/${PLOReport}/`}>
                    Program Learning Outcomes Report
                  </Link>
                </a>
              </h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} xs={12} sm={12}>
          <Card product className={classes.cardHover}>
            <CardBody style={{ width: 375, height: 200 }}>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <Link to={`/citrine/groupActions/states/${APReport}/`}>
                    Annual Program Report
                  </Link>
                </a>
              </h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} xs={12} sm={12}>
          <Card product className={classes.cardHover}>
            <CardBody style={{ width: 375, height: 200 }}>
              <h4 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <Link to={`/citrine/groupActions/states/${ProgramSpec}/`}>
                    Program Specification
                  </Link>
                </a>
              </h4>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  )
}
