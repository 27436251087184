import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
    GA_PLO: [],
    GA_PLOStatus: 'idle',
    error: null,
}

export const fetchNotAffectedPLOsToGA = createAsyncThunk('GA_PLO/fetchNotAffectedPLOsToGA', async (ids, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem('token')
        const response = await axios.get(BaseUrl + `/mappingGaPloSpec/ploSpec_gaSpec_filtered/?programSpec_id=${ids.programId}&GASpec_id=${ids.GAId}`,
            {
                headers: {
                    'Authorization': `Token ${token}`
                }
            }

        )
        console.log(response.data, 'response.data')
        return response.data

    } catch (err) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        console.log('error.response from inside the CAT', error.response)
        return rejectWithValue(error.response)
    }
})



export const addNewGA_PLOMapping = createAsyncThunk(
    'GA_PLO/addNewGA_PLOMapping',
    async (toAdd, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/mappingGaPloSpec/create/`, toAdd, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)

export const DeleteGA_PLOMapping = createAsyncThunk(
    'GA_PLO/DeleteGA_PLOMapping',
    async (ids, { rejectWithValue }) => {

        try {
            const token = localStorage.getItem('token')
            const response = await axios.post(BaseUrl + `/mappingGaPloSpec/delete/`, ids, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            console.log('error.response from inside the CAT', error.response)
            return rejectWithValue(error.response)
        }
    }
)




const GA_PLOMappingSlice = createSlice({
    name: 'GA_PLO',
    initialState,
    extraReducers: {

        [fetchNotAffectedPLOsToGA.pending]: (state, action) => {
            state.GA_PLOStatus = 'loading'
        },
        [fetchNotAffectedPLOsToGA.fulfilled]: (state, action) => {
            state.GA_PLOStatus = 'succeeded'
            // Add any fetched posts to the array
            state.GA_PLO = action.payload
        },
        [fetchNotAffectedPLOsToGA.rejected]: (state, action) => {
            state.GA_PLOStatus = 'failed'
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload
                console.log("action.payload from inside extra add plo reducer", action.payload)
            } else {
                state.error = action.error
                console.log("action.error from inside extra add plo reducer", action.error)
            }
        },
    },

})


export default GA_PLOMappingSlice.reducer


