import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  ProcessInstance: [],
  ProcessInstanceStatus: "idle",
  GetTaskStatus:'idle',
  task:null,
  HistoricTasks:[],
  error: null,
  user:null,
  id: null,
  Diagram:null,
  ProcessDefinition:null
};

export const StartProcessInstance = createAsyncThunk(
  "camunda/StartProcessInstance",
  async (key) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl +
          `/bpmnProxy/post/?uri=engine-rest/process-definition/key/${key}/start`,{},
        {
          withCredentials: true,
          headers: {
                 Authorization: `token ${token}`,

          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);


export const GetTask = createAsyncThunk(
  "camunda/GetTask",
  async (processInstanceId) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/bpmnProxy/?uri=engine-rest/task/?processInstanceId=${processInstanceId}`,{params:{}},
      {
        headers: {
         // Authorization: `token ${token}`,
         'Content-Type':"application/json"
        },
      }
    );
    console.log(response.data,'task redux');
    return response.data;
  }
);




export const GetUserProfile = createAsyncThunk(
  "camunda/GetUserProfile",
  async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/bpmnProxy/?uri=engine-rest/user/demo/profile`,{},
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  }
);

export const ClaimTask = createAsyncThunk(
  "camunda/ClaimTask",
  async (task_Object) => {
    const token = localStorage.getItem("token");
    console.log(task_Object,"task_Object")
    const response = await axios.post(
      BaseUrl + `/bpmnProxy/create/?uri=engine-rest/task/${task_Object.task_id}/claim`,{params:{"userId":`${task_Object.userId}`}},
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data);
    console.log("claim task")
    return response.data;
  }
);

export const SubmitClaimedTask = createAsyncThunk(
  "camunda/SubmitClaimedTask",
  async (task_Object) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/bpmnProxy/create/?uri=engine-rest/task/${task_Object.task_id}/claim`,{params:{"userId":task_Object.userId}},
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data);
    console.log("submit claimed task ")
    return response.data;
  }
);

export const CompleteTask = createAsyncThunk(
  "camunda/CompleteProcessInstance",
  async (task_Object) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/bpmnProxy/create/?uri=engine-rest/task/${task_Object.task_id}/complete`,{},
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data);
    console.log(" task completed ")
    return response.data;
  }
);


export const GetHistoricTasks = createAsyncThunk(
  "camunda/GetHistoricTasks",
  async (processInstanceId) => {
    console.log(processInstanceId,'processInstanceId');
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl+`/bpmnProxy/?uri=engine-rest/history/task/?processInstanceId=${processInstanceId}`);
    console.log(response.data,'HistoricTasksredux');
    return response.data;
  }
);

export const GetDiagram = createAsyncThunk(
  "camunda/GetDiagram",
  async (ProcessDefinitionId) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/bpmnProxy/?uri=engine-rest/process-definition/${ProcessDefinitionId}/xml`,{params:{}},
      {
        headers: {
         // Authorization: `token ${token}`,
         'Content-Type':"application/json"
        },
      }
    );
    console.log(response.data,'diagram redux');
    return response.data;
  }
);


export const GetProcessDefinitionByKey= createAsyncThunk(
  "camunda/GetProcessDefinitionByKey",
  async (key) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/bpmnProxy/?uri=engine-rest/process-definition/?key=${key}`,{params:{}},
      {
        headers: {
         // Authorization: `token ${token}`,
         'Content-Type':"application/json"
        },
      }
    );
    console.log(response.data,'ProcessDefinitionByKey redux');
    return response.data;
  }
);


const CamundaSlice = createSlice({
  name: "camunda",
  initialState,
  extraReducers: {
    [StartProcessInstance.pending]: (state, action) => {
      state.ProcessInstanceStatus = "loading";
    },
    [StartProcessInstance.fulfilled]: (state, action) => {
      state.ProcessInstanceStatus = "succeeded";
      state.ProcessInstance = action.payload;
    },
    [StartProcessInstance.rejected]: (state, action) => {
      state.ProcessInstanceStatus = "failed";
      state.error = action.payload;
    },



    [GetHistoricTasks.pending]: (state, action) => {
      state.ProcessInstanceStatus = "loading";
    },
    [GetHistoricTasks.fulfilled]: (state, action) => {
      state.ProcessInstanceStatus = "succeeded";
      state.HistoricTasks = action.payload;
      console.log(action.payload,'action.payload')
    },
    [GetHistoricTasks.rejected]: (state, action) => {
      state.ProcessInstanceStatus = "failed";
      state.error = action.payload;
    },


    [GetTask.pending]: (state, action) => {
      state.GetTaskStatus = "loading";
    },
    [GetTask.fulfilled]: (state, action) => {
      state.GetTaskStatus = "succeeded";
      state.task = action.payload;
    },
    [GetTask.rejected]: (state, action) => {
      state.GetTaskStatus = "failed";
      state.error = action.payload;
    },


    
    [GetDiagram.pending]: (state, action) => {
      state.ProcessInstanceStatus = "loading";
    },
    [GetDiagram.fulfilled]: (state, action) => {
      state.ProcessInstanceStatus = "succeeded";
      state.Diagram = action.payload;
    },
    [GetDiagram.rejected]: (state, action) => {
      state.ProcessInstanceStatus = "failed";
      state.error = action.payload;
    },



    [GetUserProfile.pending]: (state, action) => {
      state.ProcessInstanceStatus = "loading";
    },
    [GetUserProfile.fulfilled]: (state, action) => {
      state.ProcessInstanceStatus = "succeeded";
      state.user = action.payload;
    },
    [GetUserProfile.rejected]: (state, action) => {
      state.ProcessInstanceStatus = "failed";
      state.error = action.payload;
    },

    
    [GetProcessDefinitionByKey.pending]: (state, action) => {
      state.ProcessInstanceStatus = "loading";
    },
    [GetProcessDefinitionByKey.fulfilled]: (state, action) => {
      state.ProcessInstanceStatus = "succeeded";
      state.ProcessDefinition = action.payload;
    },
    [GetProcessDefinitionByKey.rejected]: (state, action) => {
      state.ProcessInstanceStatus = "failed";
      state.error = action.payload;
    },
    
    [CompleteTask.fulfilled]: (state, action) => {
    
    },
    [ClaimTask.fulfilled]: (state, action) => {
    },

    [SubmitClaimedTask.fulfilled]: (state, action) => {
    },

  },

  reducers: {},
});

export const {} = CamundaSlice.actions;

export default CamundaSlice.reducer;
