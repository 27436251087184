import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { grayColor } from '../../../../../assets/jss/material-dashboard-pro-react'
import {
  fetchSpecializations,
  getId,
} from '../../../../../reduxToolkit/features/specialization/SpecializationSpecSlice'
import DropDownSpecializationButton from '../../../../Components/DropDownSpecializationButton'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const SpecializationExcerpt = ({ specialization }) => {
  const { t } = useTranslation()
  console.log(specialization)
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getId(id))
  }
  //   const deleteCol = (id) => {
  //     dispatch(deleteSpecialization(id))
  //   }

  return (
    
      <GridItem xs={12} sm={6} md={4} key={specialization.id}>
        <Card product className={classes.cardHover}>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={10}>
                <h4 className={classes.cardProductTitle}>
                  {/* {specialization.name} */}
                  {specialization && specialization.name && `${specialization.name} (${specialization.code})`  }
                </h4>
                
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>
                <DropDownSpecializationButton id={specialization.id} />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody style={{ width: 300, height: 100 }}>
          <p className={classes.cardProductDesciprion}>
                  {specialization.description}
                </p>
            {/* <GridContainer justify="space-between">
              <GridItem>
                <GridContainer justify="space-between">
                  <GridItem>
                    <h4 className={classes.cardProductTitle}>
                      {specialization.name}
                    </h4>
                    <p className={classes.cardProductDesciprion}>
                      {specialization.description}
                    </p>
                  </GridItem>
                  <GridItem>
                    <DropDownSpecializationButton id={specialization.id} />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer> */}
          </CardBody>
        </Card>
      </GridItem>
    
  )
}

export default function SpecializationsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const specializationsStatus = useSelector(
    (state) => state.specializationSpec.specializationsStatus,
  )

  useEffect(() => {
    if (specializationsStatus === 'idle') {
      dispatch(fetchSpecializations())
    }
  }, [specializationsStatus, dispatch])

  const specializations = useSelector(
    (state) => state.specializationSpec.specializations,
  )
  const error = useSelector((state) => state.specializationSpec.error)

  let content

  if (specializationsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (specializationsStatus === 'succeeded') {
    content =
      specializations &&
      specializations.map((specialization) => (
        <SpecializationExcerpt
          key={specialization.id}
          specialization={specialization}
        />
      ))
  } else if (specializationsStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                <span> {t('Program Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/specializations`} style={{ color: 'orange' }}>
                {t('Specializations')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/createSpecialization/:id">
        <Button color="info">{t('Add')}</Button>
      </Link>

      <GridContainer>{content}</GridContainer>
    </div>
  )
}
