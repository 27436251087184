import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from 'react-router-dom';

import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import { Breadcrumb } from 'antd';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";
import { deleteFacilitiesRequired, fetchFacilitiesRequireds } from "reduxToolkit/features/FacilitiesRequired/FacilitiesRequiredSlice";
import DropDownButtonFR from "../../../../Components/DropDownButtonFR";

import { notification } from "antd";
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../../Components/ErrorModal";
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles(styles);

const tailLayout = {
    wrapperCol: {
        offset: 16,
        span: 16,
    },
};
export default function FacilitiesRequiredList({ match }) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton

    const { id } = match.params

    const dispatch = useDispatch()


    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const fetchAllFacilitiesRequireds = async () => {
        try {
            const resultAction = await dispatch(fetchFacilitiesRequireds(id)).unwrap();
            dispatch(fetchCourseById(id));
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    useEffect(() => {
        fetchAllFacilitiesRequireds()

        if (localStorage.getItem("CourseState") != "Draft") {
            setDisabledStatus(true);
        }
    }, [dispatch])

    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const FacilitiesRequireds = useSelector((state) => state.FacilitiesRequired.FacilitiesRequireds)
    console.log('course to be updated', course)
    console.log(FacilitiesRequireds)


    const delete_FacilitiesRequired = (id) => {
        dispatch(deleteFacilitiesRequired(id))
            .then(() => {
                dispatch(fetchFacilitiesRequireds());
            })
            .catch(() => {

            });
    };
    return (
        <div>
            <GridItem>
                <Breadcrumb separator=">">



                    <Breadcrumb.Item>
                        <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}>{course && course.title && course.title}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/specification/course/${id}/FacilitiesRequireds`} style={{ color: "orange" }} > {t('Facilities Required List')}
                        </Link>
                    </Breadcrumb.Item>

                </Breadcrumb>
            </GridItem>
            <GridContainer>
                <GridItem >
                    {disabledStatus ? <p></p> :
                        <Link to={`/citrine/specification/course/${id}/FacilitiesRequireds/create`}>
                            <Button color="info"
                                className={classes.updateProfileButton}
                            >
                                {t('Add')}
                            </Button>
                        </Link>
                    }
                </GridItem>
            </GridContainer>

            <GridContainer>
                {FacilitiesRequireds && FacilitiesRequireds.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 300 }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={12} sm={12} md={10}>
                                        <h4 className={classes.cardProductTitle} style={{ color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                {t('Item')} : {Element && Element.item_details && Element.item_details[0].name}
                                            </a>

                                        </h4>
                                        <h4 className={classes.cardProductTitle} >

                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {t('Resources')} :   {`${Element.resources.substring(0, 100)}...`}
                                            </a>

                                        </h4>
                                        {Element?.computing_resource && <h4 className={classes.cardProductTitle} >

                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {t('Computing Resource')} :   {`${Element?.computing_resource.substring(0, 100)}...`}
                                            </a>

                                        </h4>}

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                        {disabledStatus ? <p></p> :
                                            <DropDownButtonFR FacilitiesRequired_ID={Element.id} courseID={course && course.id} />
                                        }
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
            {/* <GridContainer>
                <GridItem>
                    <Link to={`/citrine/courses/`}>
                        <Button color="info"
                            className={classes.updateProfileButton}
                        >
                            Exit
                        </Button>
                    </Link>
                </GridItem>
            </GridContainer> */}
        </div >

    );

}




