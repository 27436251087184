import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  KPIs: [],
  AllKPIs: [],
  standards_KPIsProgram:[],
  KPIsStatus: 'idle',
  KPI: null,
  KPIStatus: 'idle',
  standards_KPIsProgramStatus:'idle',
  error: null,

}

export const fetchKPIsByProgram = createAsyncThunk('KPIProgram/fetchKPIsByProgram', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiProgram/?program_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})


export const fetchAllKPIs = createAsyncThunk('KPIProgram/fetchAllKPIs', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiProgram/all/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})



export const fetchAll_standards_KPIsProgram = createAsyncThunk('KPIProgram/fetchAll_standards_KPIsProgram', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiProgram/standards/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})




export const fetchKPIProgramById = createAsyncThunk('KPIProgram/fetchKPIProgramById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/kpiProgram/${id}/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const addNewKPIProgram = createAsyncThunk(
  'KPIProgram/addNewKPIProgram',
  async (initialKPIProgram, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/kpiProgram/create/`, initialKPIProgram, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


export const updateKPIPlanning = createAsyncThunk(
  'KPIProgram/updateKPIPlanning',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/kpiProgram/${data.id}/update/planning/`, data.updated, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

const KPIProgramSlice = createSlice({
  name: ' KPIProgram',
  initialState,
  extraReducers: {

    [fetchKPIsByProgram.pending]: (state, action) => {
      state.KPIsStatus = 'loading'
    },
    [fetchKPIsByProgram.fulfilled]: (state, action) => {
      state.KPIsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.KPIs = action.payload
    },
    [fetchKPIsByProgram.rejected]: (state, action) => {
      state.KPIsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },



    [fetchAllKPIs.pending]: (state, action) => {
      state.KPIsStatus = 'loading'
    },
    [fetchAllKPIs.fulfilled]: (state, action) => {
      state.KPIsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.AllKPIs = action.payload
    },
    [fetchAllKPIs.rejected]: (state, action) => {
      state.KPIsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },








    [fetchAll_standards_KPIsProgram.pending]: (state, action) => {
      state.standards_KPIsProgramStatus = 'loading'
    },
    [fetchAll_standards_KPIsProgram.fulfilled]: (state, action) => {
      state.standards_KPIsProgramStatus = 'succeeded'
      // Add any fetched posts to the array
      state.standards_KPIsProgram = action.payload
    },
    [fetchAll_standards_KPIsProgram.rejected]: (state, action) => {
      state.standards_KPIsProgramStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },





    [fetchKPIProgramById.pending]: (state, action) => {
      state.KPIStatus = 'loading'
    },
    [fetchKPIProgramById.fulfilled]: (state, action) => {
      state.KPIStatus = 'succeeded'

      // Add any fetched posts to the array
      state.KPI = action.payload
    },
    [fetchKPIProgramById.rejected]: (state, action) => {
      state.KPIStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    [addNewKPIProgram.fulfilled]: (state, action) => {
      state.KPIs.push(action.payload)
    },
    [addNewKPIProgram.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
  },

})


export default KPIProgramSlice.reducer


