import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";


// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import MenuItem from "@material-ui/core/MenuItem";
import "antd/dist/antd.css";

import { fetchAssessmentQuestionsMethods } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import { updateQuestion } from "../../reduxToolkit/features/assessmentMethod/QuestionSlice";
import ErrorModal from "./ErrorModal";

import { fetchCourseById } from "../../reduxToolkit/features/course/CoursesSlice.js";
import {
  fetchInstructorCourseAssessmentGroup
} from "../../reduxToolkit/features/CourseInstructor/CourseInstructorSlice";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function EditQuestion(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const handleKeyDown = (event, ref) => {
    if (event.key === 'Tab') {
      ref.current.focus();
    }
  };



  const [question_id, setQuestion_id] = useState()
  const [question, setQuestion] = useState("");
  const [actual_weight, setActual_weight] = useState("");
  const [correction_mark, setCorrection_mark] = useState("");
  const [selectedClo, setSelectedClo] = useState(null);
  const [clo_id, setClo_id] = useState();
  const [assessmentMethod_id, setAssessmentMethod_id] = useState(
    props.assessmentMethod_id
  );
  //console.log(course_id);
  const { Option } = Select;

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    if (props.course_id) {
      dispatch(fetchCourseById(props.course_id));
    }
  }, [props.editModalIsOpen]);

  useEffect(() => {
    console.log('data', props.selectedRowData);
    setQuestion_id(props.selectedRowData?.id);
    form.setFieldsValue({
      question: props.selectedRowData?.question,
      actual_weight: props.selectedRowData?.actual_weight,
      correction_mark: props.selectedRowData?.correction_mark,
      clo_id: props.selectedRowData?.clo_id

    });
  }, [props.selectedRowData]);

  const course = useSelector((state) => state.course.course);


  const onFinish = async (values) => {
    console.log(values);

    /*  const resultAction = await dispatch(
      addNewQuestion({
        ...values,assessmentMethod_id,course_id
      })
    );

    if (addNewQuestion.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchAssessmentQuestionsMethods(props.course_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    } */
    setAddRequestStatus("pending");
    let updatedQuestion = { ...values, assessmentMethod: assessmentMethod_id };
    const resultAction = await dispatch(
      updateQuestion({ id: question_id, updatedQuestion })
    );
    console.log(resultAction);

    if (updateQuestion.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const Question = resultAction.payload;
      console.log(Question);
      // dispatch(fetchAssessmentQuestionsMethods(props.course_id));
      dispatch(fetchInstructorCourseAssessmentGroup(props.id));
      console.log("success", `Edited ${Question?.question}`);
      openNotificationWithIcon("success", `Edited ${Question?.question}`);
      props.handleCloseEditModal();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        props.handleCloseEditModal();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        props.handleCloseEditModal();
      }
    }
    // dispatch(fetchProgramById(programSpec_id));
    props.handleCloseEditModal();


  };



  return (
    <div>
      <Dialog
        open={props.editModalIsOpen}
        onClose={props.handleCloseEditModal}
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Edit Question")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="question"
                  label="Question"
                  rules={[
                    {
                      required: true,
                      message: "Please input the question field.",
                    },
                  ]}
                //initialValue={data.question}

                >
                  <Input
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    //initialValues ={data.question}
                    ref={input1Ref}
                    onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                  />
                </Form.Item>

                <Form.Item
                  name="actual_weight"
                  label="Actual weight"
                  rules={[
                    {
                      required: true,
                      message: "Please input the actual weight field.",
                    },
                  ]}
                >
                  <Input
                    value={actual_weight}
                    onChange={(e) => setActual_weight(e.target.value)}
                    //initialValues={data.actual_weight}
                    ref={input2Ref}
                    onKeyDown={(event) => handleKeyDown(event, input3Ref)}
                  />
                </Form.Item>
                <Form.Item
                  name="correction_mark"
                  label="Correction mark"
                  rules={[
                    {
                      required: true,
                      message: "Please input the correction mark field.",
                    },
                  ]}
                >
                  <Input
                    value={correction_mark}
                    onChange={(e) => setCorrection_mark(e.target.value)}
                    //initialValues={data.correction_mark}
                    ref={input3Ref}
                    onKeyDown={(event) => handleKeyDown(event, input4Ref)}
                  />
                </Form.Item>

                <Form.Item
                  name="clo_id"
                  label="Clo"
                  //initialValues={data.clo_id}
                  rules={[
                    {
                      required: true,
                      message: "Please input the Clo field.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Clo "
                    dropdownStyle={{ zIndex: 10000 }}
                    // onChange={(e) => setSelectedClo(e.target.value)}
                    optionFilterProp="children"
                    showSearch
                    //ref={input4Ref}
                    //onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  // initialValues={`${props.selectedRowData && props.selectedRowData?.clo_id
                  //   }
                  //   // --
                  //   // ${props.selectedRowData && props.selectedRowData?.title
                  //   }`}
                  >
                    {course &&
                      course.clos &&
                      course.clos.map((clo) => (
                        <Option key={clo.id} value={clo.id}  >
                          {`${clo.code}--${clo.title}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="primary"
                    onClick={props.handleCloseEditModal}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="primary" htmltype="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
