import React, { useEffect, useState } from "react";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button";
import {
  addNewAttachment,
  fetchAttachments,
  deleteAttachment,
} from "../../../reduxToolkit/features/attachment/AttachmentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { useTranslation, Trans } from "react-i18next";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ClearIcon from "@material-ui/icons/Clear";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
} from "@react-pdf/renderer";

import { Link } from "react-router-dom";

import "./upload.css";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function UploadAttachmentProfessionalDevelopment(props) {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };
  const dispatch = useDispatch();

  const handleSubmission = () => {
    // let resource=id
    let resource_id = props.id;
    let model = "professional_development";
    let resource = "attachment_prof_dev";
    let document = selectedFile;
    let name = selectedFile.name.slice(0, 25);
    const uploadData = new FormData();
    uploadData.append("resource_id", resource_id);
    uploadData.append("resource", resource);
    uploadData.append("model", model);
    uploadData.append("document", document);
    uploadData.append("name", name);

    // console.log("{model,resource,document,name }", { model, resource, document, name })
    // console.log("uploadData", uploadData)
    {
      selectedFile &&
        dispatch(addNewAttachment(uploadData)).then(() => {
          setSelectedFile("");
          setIsSelected(false);
        });
    }
  };

  useEffect(() => {
    dispatch(fetchAttachments());
  }, [dispatch]);
  //   let filteredPeople = people.filter(function (currentElement) {
  // 	// the current value is an object, so you can check on its properties
  // 	return currentElement.country === "America" && currentElement.age < 25;
  //   });

  const attachments = useSelector((state) => state.attachment.attachments);

  const filteredAttachments =
    attachments &&
    attachments.filter(
      (attachment) =>
        attachment.model === "professional_development" && attachment.resource_id == props.id
    );

  // console.log("attachments", attachments)
  const error = useSelector((state) => state.program.error);
  // console.log("filteredAttachments", filteredAttachments)
  // console.log("props.id", props.id)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleOpenDeleteAttachment = (id) => {
    dispatch(deleteAttachment(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchAttachments());
      });
  };

  return (
    <div>
      <GridContainer>
        <input
          id="fileInput"
          type="file"
          name="file"
          onChange={changeHandler}
        />

        <div>
          <Button color="primary" onClick={handleSubmission}>
            Submit
          </Button>
        </div>

        {filteredAttachments &&
          filteredAttachments.map((attachment) => {
            return (
              <GridItem
                xs={12}
                sm={12}
                md={11}
                key={attachment.id}
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#edeff5",
                  marginBottom: "8px",
                  height: "55px",
                }}
              >
                <GridContainer
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ height: "50px" }}
                >
                  <GridItem xs={12} sm={12} md={3}>
                    <a href={attachment.document} target="_blank">
                      {/* <Document
                        file={attachment.document}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page
                          height="200"
                          width="200"
                          pageNumber={pageNumber}
                        />
                   
                      </Document> */}
                      <PictureAsPdfIcon style={{ fontSize: "36px" }} />
                    </a>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={7}>
                    <span style={{ fontSize: "12px" }}>
                      <a href={attachment.document} target="_blank">
                        {attachment.name}
                      </a>
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <div className="delete" style={{ textAlign: "center" }}>
                      <ClearIcon
                        onClick={() => {
                          handleOpenDeleteAttachment(
                            attachment && attachment.id
                          );
                        }}
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      />
                    </div>

                    <div className="delete" style={{ textAlign: "center" }}>
                      {attachment.document && (
                        <PDFDownloadLink
                          document={
                            <Document
                              file={attachment.document}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page pageNumber={pageNumber} />
                            </Document>
                          }
                          fileName={attachment.name}
                        >
                          {({ blob, url, loading, error }) => (
                            <SaveAltIcon
                              style={{ fontSize: "14px", fontWeight: "bold" }}
                            />
                          )}
                        </PDFDownloadLink>
                      )
                      
                      }
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            );
          })}
      </GridContainer>
    </div>
  );
}
