import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {
  DeleteOutlined
} from "@ant-design/icons";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { deleteCounselingAndSupportActivity, fetchCounselingAndSupportActivitiesByProgramId } from "../../../../../reduxToolkit/features/CounselingAndSupportActivities/CounselingAndSupportActivitiesSlice.js";
import Comments from "../Comments";
import AddCounselingAndSupportOnAnnualProgram from "./AddCounselingAndSupportOnAnnualProgram";
import useTable2Columns from "views/Components/useTable2Columns.js";
const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);
const headCells = [
  { id: "Activities", label: "Activities" },
  { id: "Description", label: "Description" },
  { id: "delete", label: "" },]
export default function CounselingAndSupport(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const callback = (key) => {
    console.log(key);
  };
  let comment_resource = "CounselingAndSupport"
  const [handledError, setHandledError] = React.useState("");
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useTable2Columns(
    records,
    headCells,
    filterFn
  );
  const idAP = props.AnnualProgramReport.id;
  let idProgram =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].id

  const fetchSupportCustomFunction = async (id) => {
    try {
      const resultAction = await dispatch(fetchCounselingAndSupportActivitiesByProgramId(idAP)).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchSupportCustomFunction(idAP)
    // dispatch(fetchResearchAndInnovationActivities(id))

  }, [])
  const counselingAndSupportByProgram = useSelector((state) => state.CounselingAndSupportActivities.CounselingAndSupportActivitiesByProgram);
  const counselingAndSupportByProgramStatus = useSelector((state) => state.CounselingAndSupportActivities.CounselingAndSupportActivitiesByProgramStatus);
  const [array_counseling, setArray_counseling] = useState([]);
  useEffect(() => {
    dispatch(fetchCounselingAndSupportActivitiesByProgramId(idAP))
      .then(unwrapResult)
      .then((counseling) => {
        setArray_counseling([...counseling]);
      });
  }, []);


  const fetchCounseling = () => {
    dispatch(fetchCounselingAndSupportActivitiesByProgramId(idAP))
      .then(unwrapResult)
      .then((counseling) => {
        setArray_counseling([...counseling]);
      });
  }

  const DeleteCounseling = (id) => {
    dispatch(deleteCounselingAndSupportActivity(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchCounselingAndSupportActivitiesByProgramId(idAP)).then(unwrapResult)
          .then((counseling) => {
            setArray_counseling([...counseling]);
          });
      });
  };

  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          <AddCounselingAndSupportOnAnnualProgram
          id={idAP} fetchCounseling={fetchCounseling}
          />
        </GridItem>
      </GridContainer>
      <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
        <TblHead />
        <TableBody>
          {array_counseling &&
            array_counseling.map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    borderLeft: "1px solid lightgray",
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      maxWidth: "200px",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item && item.activity}
                  </p>
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.description}

                </TableCell>
                <TableCell
                                    style={{
                                        borderRight: "1px solid lightgray"
                                    }}
                                >
                                    <DeleteOutlined

                                        onClick={() => DeleteCounseling(item && item.id)}
                                        style={{ color: "red" }}
                                    />
                                </TableCell>
                {/* <TableCell
                style={{
                  borderRight: "1px solid lightgray",
                }}
              >
                <DeleteOutlined
                  onClick={() => DeletePlannedCourse(item && item.id)}
                  style={{ color: "red" }}
                />
              </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </TblContainer>
      <GridContainer xs={12} sm={12} md={12}>

        <GridItem key={Element.id} xs={12} sm={12} md={12}>
          <Comments
            AnnualProgramReport={props.AnnualProgramReport &&
              props.AnnualProgramReport} type={comment_resource} />
        </GridItem>
      </GridContainer>
    </div>
  );
}