import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch } from "react-redux";

// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import "antd/dist/antd.css";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "../Components/ErrorModal";


import {
  FormOutlined
} from "@ant-design/icons";
import {
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";


import {
  fetchAnnualProgramReportById
} from "../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice.js";

import { updateCourseDevelopmentalRecommendations } from "../../reduxToolkit/features/course/CoursesSlice";
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function DevelopmentalRecommendationsModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();


  const { Option } = Select;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      developmental_recommendations:
        props.item &&
        props.item.developmental_recommendations &&
        props.item.developmental_recommendations,
    });
  }, [dispatch]);

  const onFinish = async (values) => {
    const updatedObj = { ...values };
    const resultAction = await dispatch(
      updateCourseDevelopmentalRecommendations({ id: props.item.id, updated: updatedObj })
    );
    if (updateCourseDevelopmentalRecommendations.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Edit successfully`);
      dispatch(fetchAnnualProgramReportById(props.Report_id));
      props.fetchDevRecommendations();
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();

        handleClose();
      }
    }
  };

  return (
    <div>
      <FormOutlined
        onClick={(event) => {
          handleClickOpen(event);
        }}
        style={{ color: warningColor[0], fontSize: "18px" }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Edit Developmental Recommendations")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
               <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="developmental_recommendations"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input the developmental_recommendations   field.",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter developmental recommendations  "
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form> 
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      /> 
    </div>
  );
}
