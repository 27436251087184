import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  academicYearEvents: [],
  academicYearEventsStatus: 'idle',
  academicYearEventStatus: 'idle',
  error: null,
  academicYearEvent: null,

}

export const fetchAcademicYearEvents = createAsyncThunk('academicYearEvent/fetchAcademicYearEvents', 
async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/academicYearEvents/?academic_year_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})



export const addNewAcademicYearEvent = createAsyncThunk(
  'academicYearEvent/addNewAcademicYearEvent',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/academicYearEvents/create/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)






const academicYearEventSlice = createSlice({
  name: 'academicYearEvent',
  initialState,

  extraReducers: {

    [fetchAcademicYearEvents.pending]: (state, action) => {
      state.academicYearsStatus = 'loading'
    },
    [fetchAcademicYearEvents.fulfilled]: (state, action) => {
      state.academicYearsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.academicYearEvents = action.payload
    },
    [fetchAcademicYearEvents.rejected]: (state, action) => {
      state.academicYearsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra reducer", action.error)
      }
    },

 
    [addNewAcademicYearEvent.fulfilled]: (state, action) => {
      state.academicYearEvents.push(action.payload)
    },

  },
})


export const { } = academicYearEventSlice.actions;

export default academicYearEventSlice.reducer;

