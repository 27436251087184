import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';

const initialState = {
  yearSemesters: [],
  yearSemestersStatus: 'idle',
  yearSemesterStatus: 'idle',
  error: null,
  yearSemester: null,
  id: null
}

export const fetchYearSemesters = createAsyncThunk('yearSemester/fetchYearSemesters', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/yearSemesterConfig/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})


export const fetchYearSemesterById = createAsyncThunk('yearSemester/fetchYearSemesterById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/yearSemesterConfig/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const addNewYearSemester = createAsyncThunk(
  'yearSemester/addNewYearSemester',
  async (initialYearSemester, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/yearSemesterConfig/create/`, initialYearSemester, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const updateYearSemester = createAsyncThunk(
  'yearSemester/updateYearSemester',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/yearSemesterConfig/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const deleteYearSemester = createAsyncThunk('yearSemester/deleteYearSemester', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/yearSemesterConfig/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

const yearSemestersSlice = createSlice({
  name: ' yearSemester',
  initialState,
  extraReducers: {
    [fetchYearSemesters.pending]: (state, action) => {
      state.yearSemestersStatus = 'loading'
    },
    [fetchYearSemesters.fulfilled]: (state, action) => {
      state.yearSemestersStatus = 'succeeded'
      // Add any fetched posts to the array
      state.yearSemesters = action.payload
    },
    [fetchYearSemesters.rejected]: (state, action) => {
      state.yearSemestersStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchYearSemesterById.pending]: (state, action) => {
      state.yearSemesterStatus = 'loading'
    },
    [fetchYearSemesterById.fulfilled]: (state, action) => {
      state.yearSemesterStatus = 'succeeded'
      state.yearSemester = action.payload
    },
    [fetchYearSemesterById.rejected]: (state, action) => {
      state.yearSemesterStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewYearSemester.fulfilled]: (state, action) => {
      state.yearSemesters.push(action.payload)
    },
    [addNewYearSemester.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteYearSemester.fulfilled]: (state, action) => {},
    [deleteYearSemester.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateYearSemester.fulfilled]: (state, action) => {
      const result = state.yearSemesters.filter(yearSemester => yearSemester.id !== action.payload.id);
      result.push(action.payload)
      state.yearSemesters = result
    },
    [updateYearSemester.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default yearSemestersSlice.reducer

export const selectAllYearSemesters = state => state.yearSemesters.yearSemesters
