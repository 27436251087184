import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchAttachments } from "reduxToolkit/features/attachment/AttachmentsSlice";
// @material-ui/icons
import { addNewMailTemplate, fetchMailTemplateById, mailTemplateUpdate } from "../../../reduxToolkit/features/MailTemplate/MailTemplateSlice";


const useStyles = makeStyles(styles);

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
// const classes = useStyles();
export default function AddEditMailTemplate({ match }) {
    const { t } = useTranslation();
    const { id } = match.params

    console.log('id', id)

    const isAddMode = isNaN(id);
    console.log('id from match is add mode', isAddMode)
    const classes = useStyles();
    const useTitleStyles = makeStyles(titleStyles);

    const titleClasses = useTitleStyles();
    const [name, setName] = useState('')
    const [copy_value, setCopy_value] = useState('')
    const [display_name, setDisplay_name] = useState('')
    const [lang, setLang] = useState('')
    const [partner_to, setPartner_to] = useState('')
    const [null_value, setNullValue] = useState('')
    const [model, setModel] = useState('')
    const [render_model, setRender_model] = useState('')
    const [reply_to, setReply_to] = useState('')
    const [report_name, setReport_name] = useState('')
    const [scheduled_date, setScheduled_date] = useState('')
    const [use_default_to, setUse_default_to] = useState(true)
    const [email_to, setEmail_to] = useState('')
    const [email_cc, setEmail_cc] = useState('')
    const [email_from, setEmail_from] = useState('')
    const [subject, setSubject] = useState('')
    const [auto_delete, setAuto_delete] = useState(true)
    const [body_html, setBody_html] = useState('')
    const [attachement_ids, setAttachement_ids] = useState([])
    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const dispatch = useDispatch()

    const history = useHistory();
    useEffect(() => {

        dispatch(fetchAttachments())

    }, []);
    const attachements = useSelector((state) => state.attachment.attachments)
    const onSaveMailTemplateClicked = async () => {


        try {
            setAddRequestStatus('pending')
            console.log('onsave')
            const resultAction = await dispatch(
                addNewMailTemplate({
                    name, display_name, copy_value, lang, partner_to, null_value, model, render_model, reply_to, report_name, scheduled_date, use_default_to,
                    email_cc, email_from, email_to, subject, auto_delete, body_html, attachement_ids
                })
            )
            unwrapResult(resultAction)
            setName('')
            setDisplay_name('')
            setCopy_value('')
            setLang('')
            setPartner_to('')
            setNullValue('')
            setModel('')
            setRender_model('')
            setReply_to('')
            setReport_name('')
            setScheduled_date('')
            setUse_default_to('')
            setEmail_to('')
            setEmail_cc('')
            setEmail_from('')
            setSubject('')
            setAuto_delete('')
            setBody_html('')
            setAttachement_ids('')
            history.push('/citrine/mailTemplates');
        } catch (err) {
            console.error('Failed to save the mailTemplate: ', err)
        } finally {
            setAddRequestStatus('idle')
        }


    }
    const onUpdateClicked = async () => {

        //  if (canSave) {
        try {
            setAddRequestStatus('pending')

            const resultAction = await dispatch(
                mailTemplateUpdate({
                    id: id, modified: {
                        name, display_name, copy_value, lang, partner_to, null_value, model, render_model, reply_to, report_name, scheduled_date, use_default_to,
                        email_to, email_cc, email_from, subject, auto_delete, body_html, attachement_ids
                    }
                })
            )
            unwrapResult(resultAction)
            setName('')
            setDisplay_name('')
            setCopy_value('')
            setLang('')
            setPartner_to('')
            setNullValue('')
            setModel('')
            setRender_model('')
            setReply_to('')
            setReport_name('')
            setScheduled_date('')
            setUse_default_to('')
            setEmail_to('')
            setEmail_cc('')
            setEmail_from('')
            setSubject('')
            setAuto_delete('')
            setBody_html('')
            setAttachement_ids('')
            history.push('/citrine/mailTemplates');
        } catch (err) {
            console.error('Failed to update the mailTemplates: ', err)
        } finally {
            setAddRequestStatus('idle')
        }
        //   }

    }

    const [checkedUseDefault, setCheckedUseDefault] = React.useState();
    const handleChangeUseDefault = (event) => {
        setCheckedUseDefault(event.target.checked);
        setUse_default_to(event.target.checked);

    };
    const [checkedDelete, setCheckedDelete] = React.useState();
    const handleChangeDelete = (event) => {
        setCheckedDelete(event.target.checked);
        setAuto_delete(event.target.checked);
    };



    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    useEffect(() => {
        if (!isAddMode) {
            dispatch(fetchMailTemplateById(id))
        }
    }, [id]);

    const mailTemplate = useSelector((state) => state.MailTemplate.mailTemplate)
    console.log('mailTemplates ', mailTemplate)

    useEffect(() => {
        if (!isAddMode) {
            setName(mailTemplate && mailTemplate.name)
            setDisplay_name(mailTemplate && mailTemplate.display_name)
            setCopy_value(mailTemplate && mailTemplate.copy_value)
            setLang(mailTemplate && mailTemplate.lang)
            setPartner_to(mailTemplate && mailTemplate.partner_to)
            setNullValue(mailTemplate && mailTemplate.null_value)
            setRender_model(mailTemplate && mailTemplate.render_model)
            setReply_to(mailTemplate && mailTemplate.reply_to)
            setReport_name(mailTemplate && mailTemplate.report_name)
            setScheduled_date(mailTemplate && mailTemplate.scheduled_date)
            setUse_default_to(mailTemplate && mailTemplate.use_default_to)
            setEmail_cc(mailTemplate && mailTemplate.email_cc)
            setEmail_from(mailTemplate && mailTemplate.email_from)
            setSubject(mailTemplate && mailTemplate.subject)
            setAuto_delete(mailTemplate && mailTemplate.auto_delete)
            setBody_html(mailTemplate && mailTemplate.body_html)
            setAttachement_ids(mailTemplate && mailTemplate.attachement_ids)

        }
    }, [mailTemplate, dispatch]);
    function onChangeAttachements_ids(value) {
        console.log(`selected ${value}`);
        setAttachement_ids(value);
    }
    return (


        <div>
            {isAddMode &&
                <div>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <SchoolRounded />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        <span className={titleClasses.title}>      <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/outgoingMailServers`}>{t(' /Mail Templates')}</Link>{t(' /Create')} </span>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <GridContainer xs={12} sm={12} md={6} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Name ")}
                                                            id="name"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="name"
                                                            value={name}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setName(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Display Name ")}

                                                            id="display_name"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="display_name"
                                                            value={display_name}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setDisplay_name(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Copy value ")}

                                                            id="copy_value"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="copy_value"
                                                            value={copy_value}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setCopy_value(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Lang ")}
                                                            id="lang"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="lang"
                                                            value={lang}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setLang(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Partner to")}
                                                            id="partner_to"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="partner_to"
                                                            value={partner_to}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setPartner_to(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Default value")}
                                                            id="null_value"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="null_value"
                                                            value={null_value}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setNullValue(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <CustomInput
                                                            labelText={t(" Model")}
                                                            id="model"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="model"
                                                            value={model}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setModel(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <CustomInput
                                                            labelText={t("Render Model")}
                                                            id="render_model"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="render_model"
                                                            value={render_model}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setRender_model(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8} >

                                                        <FormControlLabel control={<Checkbox defaultChecked checked={checkedUseDefault} onChange={handleChangeUseDefault} />} label="Use Default" />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer xs={12} sm={12} md={6} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Reply to")}
                                                            id="reply_to"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="reply_to"
                                                            value={reply_to}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setReply_to(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Report Name")}
                                                            id="report_name"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="report_name"
                                                            value={report_name}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setReport_name(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Scheduled date")}
                                                            id="scheduled_date"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="scheduled_date"
                                                            value={scheduled_date}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setScheduled_date(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Email To *")}
                                                            id="email_to"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="email_to"
                                                            value={email_to}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setEmail_to(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Email CC *")}
                                                            id="email_cc"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="email_cc"
                                                            value={email_cc}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setEmail_cc(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Email From")}
                                                            id="email_from"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="email_from"
                                                            value={email_from}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setEmail_from(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Subject")}
                                                            id="subject"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="subject"
                                                            value={subject}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setSubject(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <CustomInput
                                                            labelText={t("Body")}
                                                            id="body_html"
                                                            //  onChange={onNameChanged}
                                                            type="text"
                                                            name="body_html"
                                                            value={body_html}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter a name!',
                                                                },
                                                            ]}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: false,
                                                                onChange: event => {
                                                                    setBody_html(event.target.value);
                                                                }
                                                            }}
                                                        />

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8} >
                                                        <label>{t('Attachments *')} </label>
                                                        {/* <CustomInput labelText="Prerequisites *"
                                           ></CustomInput> */}
                                                        <Select
                                                            mode="multiple"
                                                            onChange={onChangeAttachements_ids}
                                                            size={100}
                                                            placeholder={t("Please select")}
                                                            style={{ width: '100%' }}>
                                                            {attachements.map((a) => (
                                                                <option key={a.id} value={a.id}>
                                                                    {a.name}
                                                                </option>
                                                            ))}
                                                        </Select>

                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={8}>


                                                        <FormControlLabel control={<Checkbox defaultChecked checked={checkedDelete} onChange={handleChangeDelete} />} label="Auto Delete" />
                                                    </GridItem>
                                                </GridContainer>
                                            </GridContainer>



                                            <GridItem>
                                                <Button color="primary"
                                                    className={classes.updateProfileButton}
                                                    onClick={onSaveMailTemplateClicked}
                                                >
                                                    {t(' Save')}
                                                </Button></GridItem>


                                        </GridContainer>
                                    </form>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            }
            {!isAddMode && mailTemplate &&
                <div>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <SchoolRounded />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        <span className={titleClasses.title}>      <Link to={`/citrine/configurations`}>{t('Configuration')}</Link> <Link to={`/citrine/resourceTypeConfigs`}>{t(' /Assessment Method')}</Link> {t('/Edit')} </span>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        {/* <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}> */}
                                        {/* <GridItem xs={6} sm={6} md={6}>
        
         
            <CustomInput
              labelText="{t('Name *')}"
              id="name"
              //  onChange={onNameChanged}
              type="text"
              name="name"
              value={name}
              rules={[
                {
                  required: true,
                  message: 'Please enter a name!',
                },
              ]}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: false,
                onChange: event => {
                  setName(event.target.value);
                },
                value: name
              }}
            />
      
                                  <label>{t('Assessment Mode *')} </label>
                                  <Select
                                          
                                          showSearch
                                          style={{ width: '100%' }}
                                          size={200}
                                          optionFilterProp="children"
                                          name="mode_id"
                                          value={modeSpec_id}
                                          onChange={onChangeMode_id}
                                          onFocus={onFocus}
                                          onBlur={onBlur}
                                          onSearch={onSearch}
                                          filterOption={(input, option) =>
                                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                          }
                                      >

                                          {modes.map(r => (
                                                  <option key={r.id} value={r.id}>
                                                      {r.name}
                                                  </option>
                                              ))

                                      
                                          }

                                      </Select>
                                  </GridItem>  */}
                                        <GridItem>
                                            <Button color="primary"
                                                className={classes.updateProfileButton}
                                                onClick={onUpdateClicked}
                                            >
                                                {t(' Update')}
                                            </Button></GridItem>


                                        {/* </GridContainer>  */}
                                    </form>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>


                </div>
            }
        </div>

    );



}


