import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  Comments: [],
  CommentsStatus: "idle",
  error: null,
  CommentsOnAnnualProgramByProgramAndType: [],
  CommentsOnAnnualProgramByProgramAndTypeStatus: "idle"
};

export const fetchCommentsByCourseId_And_Type = createAsyncThunk(
  "Comments/fetchCommentsByCourseId_And_Type",
  async (object, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl +
        `/comments/?type=${object.type}&course_id=${object.course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // const response = jsonData
      console.log(response.data, "response.data course");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCommentsOnAnnualProgramByProgramAndType = createAsyncThunk(
  "Comments/fetchCommentsOnAnnualProgramByProgramAndType",
  async (object, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl +
        `/comments/by_program/?type=${object.type}&resource_id=${object.resource_id}&resource=${object.resource}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // const response = jsonData
      console.log(response.data, "response.data ");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewComment = createAsyncThunk(
  "Comments/addNewComment",
  async (initialComment, { rejectWithValue }) => {
    try {
      console.log("initialComment", initialComment);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/comments/create/`,
        initialComment,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    }
    catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
export const deleteComments = createAsyncThunk(
  "Comments/deleteComments",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/comments/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const CommentsSlice = createSlice({
  name: "Comments",
  initialState,
  extraReducers: {},
  reducers: {},
  extraReducers: {
    [fetchCommentsByCourseId_And_Type.pending]: (
      state,
      action
    ) => {
      state.CommentsStatus = "loading";
    },
    [fetchCommentsByCourseId_And_Type.fulfilled]: (
      state,
      action
    ) => {
      state.CommentsStatus = "succeeded";
      state.Comments = action.payload;
    },
    [fetchCommentsByCourseId_And_Type.rejected]: (
      state,
      action
    ) => {
      state.CommentsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchCommentsByCourseId_And_Type.pending]: (
      state,
      action
    ) => {
      state.CommentsStatus = "loading";
    },
    [fetchCommentsOnAnnualProgramByProgramAndType.fulfilled]: (
      state,
      action
    ) => {
      state.CommentsOnAnnualProgramByProgramAndTypeStatus = "succeeded";
      state.CommentsOnAnnualProgramByProgramAndType = action.payload;
    },
    [fetchCommentsOnAnnualProgramByProgramAndType.rejected]: (
      state,
      action
    ) => {
      state.CommentsOnAnnualProgramByProgramAndTypeStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewComment.fulfilled]: (
      state,
      action
    ) => { },
    [addNewComment.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});

export const { } = CommentsSlice.actions;

export default CommentsSlice.reducer;
