import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";

import { useTranslation } from "react-i18next";
import { Form, Input, Select, notification, DatePicker, Checkbox } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

import AddIcon from "@material-ui/icons/Add";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import {
  addNewProject,
  fetchProjectsByPersonId,
} from "../../../../../reduxToolkit/features/Project/projectSlice.js";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

export default function AddProjectModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);

  const { Option } = Select;
  const { TextArea } = Input;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [staff_id, setPerson_id] = React.useState(props.person_id);
  const [start_date, setStart_date] = React.useState("");
  const [end_date, setEnd_date] = React.useState("");

  const [until_now, setUntil_now] = React.useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  console.log(props.program_id, "program_id");

  useEffect(() => {}, [dispatch]);

  const onFinish = async (values) => {
    let resultAction;
    if (until_now === true) {
      resultAction = await dispatch(
        addNewProject({
          ...values,
          start_date,
          staff_id,
          until_now,
        })
      );
    } else {
      resultAction = await dispatch(
        addNewProject({
          ...values,
          start_date,
          end_date,
          staff_id,
          until_now,
        })
      );
    }

    if (addNewProject.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchProjectsByPersonId(props.person_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  function onChangeStartDate(date, dateString) {
    console.log(date, dateString);
    setStart_date(dateString);
  }

  function onChangeEndDate(date, dateString) {
    console.log(date, dateString);
    setEnd_date(dateString);
  }
  function onChangeCheckbox(e) {
    console.log(`checked = ${e.target.checked}`);
    setUntil_now(e.target.checked);
  }
  return (
    <div>
      <Button
        color="primary"
        round
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={(event) => {
          handleClickOpen(event);
        }}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
      >
        <AddIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Project")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input the title field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="type_of_project"
                  label="Type Of Project"
                  rules={[
                    {
                      required: true,
                      message: "Please input the type of project field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="team_members"
                  label="Team Members"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Team Members field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="start_date"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                      message: "Please input the start date date  field.",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={onChangeStartDate}
                    style={{ zIndex: "10000  !important" }}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    style={{ width: "400px" }}
                  />
                </Form.Item>

                {!until_now && (
                  <Form.Item name="end_date" label="End Date">
                    <DatePicker
                      onChange={onChangeEndDate}
                      style={{ zIndex: "10000  !important" }}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      style={{ width: "400px" }}
                    />
                  </Form.Item>
                )}

                <Form.Item name="until_now" label="Until Now">
                  <Checkbox
                    onChange={onChangeCheckbox}
                    checked={until_now}
                  ></Checkbox>
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Description field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
