// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { SchoolRounded } from '@material-ui/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Form } from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
// @material-ui/icons

import {
  fetchEvidenceTypeById, fetchEvidenceTypeStatesConfigs, updateEvidenceTypeConfig
} from '../../../../reduxToolkit/features/EvidenceType/EvidenceTypeSlice'

const useStyles = makeStyles(styles)

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}
export default function EditEvidenceTypesConfig({ match }) {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = match.params

  const classes = useStyles()
  const useTitleStyles = makeStyles(titleStyles)

  const titleClasses = useTitleStyles()
  const [type, setType] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [form] = Form.useForm()

  const onTypeChanged = (e) => setType(e.target.value)

  const onUpdateClicked = async () => {

    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(updateEvidenceTypeConfig({ id: id, modified: { type } }));

      unwrapResult(resultAction)
      dispatch(fetchEvidenceTypeStatesConfigs())
      history.push('/citrine/configuration/others/EvidenceTypeConfigPage')
    } catch (err) {
      console.error('Failed to update the Evidence Type: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }

  useEffect(() => {
    dispatch(fetchEvidenceTypeById(id))
      .then(unwrapResult)
      .then((obj) => {
        setType(obj.type)
      })
  }, [id])

  return (
    
      <div>
        <GridContainer>
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/configuration/course`}>
                 
                  {t('Evidence Type Configurations')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/assessmentMethods`}
                  style={{ color: 'orange' }}
                >
                  {t('Evidence Type')}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <SchoolRounded />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Edit')}</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      <CustomInput
                        labelText={t('Type ')}
                        id="type"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Evidence Type!',
                          },
                        ]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: type,
                          onChange: (event) => {
                            setType(event.target.value);
                          },
                          type: "text",
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <Button
                        color="info"
                        className={classes.updateProfileButton}
                        onClick={onUpdateClicked}
                      >
                        {t(' Update')}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    
  )
}
