import React, { useState, useEffect, Fragment } from "react";

import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { Tabs } from "antd";
import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";
import "../../improvement.css";
import usePlannedCourses from "../../../../Components/usePlannedCourses";
import {
  fetchAnnualProgramReportById,
  UpdateAnnualProgramReport,
} from "../../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";

import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";

import { TableBody, TableRow, TableCell } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import AddTeachingOfPlannedCoursesModal from "../../../../Components/AddTeachingOfPlannedCoursesModal";

import {
  addNewPreviousActionPlan,
  fetchPreviousActionPlans,
  deletePreviousAction,
} from "../../../../../reduxToolkit/features/ActionPlan/ActionPlanSlice";
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  addNewPlannedCourse,
  fetchPlannedCourses,
  deletePlannedCourse
} from "../../../../../reduxToolkit/features/TeachingOfPlannedCourses/TeachingOfPlannedCoursesSlice";

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const headCells = [
  { id: "Course", label: "Course" },
  { id: "Units/Topics", label: "Units/Topics" },
  { id: "Reasons", label: "Reasons" },
  { id: "Compensating Actions", label: "Compensating Actions" },
  { id: "delete", label: "" },
];

export default function TeachingOfPlannedCourses(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = usePlannedCourses(
    records,
    headCells,
    filterFn
  );
  let program_id =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].id;
  useEffect(() => {
    dispatch(fetchPlannedCourses(program_id));
  }, []);

  const PlannedCourses = useSelector(
    (state) => state.PlannedCourses.PlannedCourses
  );
  const DeletePlannedCourse = (id) => {
    dispatch(deletePlannedCourse(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchPlannedCourses(program_id));
      });
  };
  const [pageSize, setPageSize] = React.useState(10);
  return (
    <div>
      ....
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          <AddTeachingOfPlannedCoursesModal
            program_id={
              props.AnnualProgramReport &&
              props.AnnualProgramReport.programs &&
              props.AnnualProgramReport.programs[0] &&
              props.AnnualProgramReport.programs[0].id
            }
          />
        </GridItem>
      </GridContainer>

      <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
        <TblHead />
        <TableBody>
          {PlannedCourses &&
            PlannedCourses.map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    borderLeft: "1px solid lightgray",
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      maxWidth: "200px",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item && item.course_name}
                  </p>
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.topics}
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.reasons}
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.compensating_actions}
                </TableCell>

                <TableCell
                  style={{
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <DeleteOutlined
                    onClick={() => DeletePlannedCourse(item && item.id)}
                    style={{ color: "red" }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TblContainer>
    </div>
  );
}
