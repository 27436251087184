import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownFieldButton from 'views/Components/DropDownFieldButton';
//import CreateFieldPage from './CreateFieldPage';
import { grayColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import { fetchFields, getId } from '../../../../../reduxToolkit/features/field/FieldSpecSlice';


const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);


const FieldExcerpt = ({ field }) => {

  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getId(id))

  }
  //   const deleteCol = (id) => {
  //     dispatch(deleteField(id))
  //   }

  return (
    <GridItem xs={12} sm={6} md={4} key={field.id}>
      <Card product className={classes.cardHover} style={{ maxHeight: "200px", minHeight: "200px" }}>
        <CardBody style={{ height: 200 }}>
          <GridContainer justify="space-between" alignItems="center">
            <GridItem>
              <h4 className={classes.cardProductTitle}>
                {field.code}
              </h4>
            </GridItem>
            <GridItem>
              <DropDownFieldButton id={field.id} />
            </GridItem>
          </GridContainer>
          <h5 className={classes.cardProductTitle}>
            {field.name}
          </h5>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function FieldsPage() {
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const { t } = useTranslation();
  const fieldsStatus = useSelector(state => state.fieldSpec.fieldsStatus)

  useEffect(() => {
    if (fieldsStatus === 'idle') {
      dispatch(fetchFields())
    }
  }, [fieldsStatus, dispatch])

  const fields = useSelector(state => state.fieldSpec.fields)
  const error = useSelector(state => state.fieldSpec.error)
  console.log("fields", fields)
  let content

  if (fieldsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (fieldsStatus === 'succeeded') {
    content = fields && fields.map(field => (

      <FieldExcerpt key={field.id} field={field} />
    ))
  } else if (fieldsStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                <span > {t('Program Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <Link to={`/citrine/fields`} style={{ color: "orange" }}>
                {t('Fields')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/createField/:id" >
        <Button color="info">

          {t(' Add')}
        </Button>
      </Link>

      <GridContainer
        justify="space-between"
        alignItems="center"
      >
        {
          content
        }
      </GridContainer>
    </div>
  );
}










