import ReactDOM from 'react-dom'

import App from "./App";
import store from "./reduxToolkit/Store";
import {Provider} from "react-redux";
import React from "react";

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root'),
)
