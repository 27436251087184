import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  PlannedCourses: [],
  PlannedCoursesStatus: "idle",
  PlannedCourseStatus: "idle",
  error: null,
  PlannedCourse: null,
  id: null,
};

export const fetchPlannedCourses = createAsyncThunk(
  "PlannedCoursesSlice/fetchPlannedCourses",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/teachingOfPlannedCourses/?program_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewPlannedCourse = createAsyncThunk(
  "PlannedCoursesSlice/addNewTeachingOfPlannedCourse",
  async (initialObject, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/teachingOfPlannedCourses/create/`,
        initialObject,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const deletePlannedCourse = createAsyncThunk(
  "PlannedCoursesSlice/deletePlannedCourse",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/teachingOfPlannedCourses/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const PlannedCoursesSlice = createSlice({
  name: " level",
  initialState,
  extraReducers: {
    [fetchPlannedCourses.pending]: (state, action) => {
      state.PlannedCoursesStatus = "loading";
    },
    [fetchPlannedCourses.fulfilled]: (state, action) => {
      state.PlannedCoursesStatus = "succeeded";
      state.PlannedCourses = action.payload;
    },
    [fetchPlannedCourses.rejected]: (state, action) => {
      state.PlannedCoursesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewPlannedCourse.fulfilled]: (state, action) => {
      state.PlannedCourses.push(action.payload);
    },
    [deletePlannedCourse.fulfilled]: (state, action) => {
      // state.levels.push(action.payload)
    },

    [addNewPlannedCourse.rejected]: (state, action) => {
      state.PlannedCoursesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deletePlannedCourse.rejected]: (state, action) => {
      state.PlannedCoursesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});
export default PlannedCoursesSlice.reducer;
