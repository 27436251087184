import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import React from 'react';
import SuperGeneralUpdateModal from '../../Components/SuperGeneralUpdateModal';
export default function PEOsUpdates(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpenDeleteModal = () => {
        setOpen(true);
    };
    const handleCloseDeleteModal = () => {
        setOpen(false);
    };

    return (
        <div>
            {/* <MenuItem
                onClick={props.disabledStatus ? {} :
                    handleOpenDeleteModal
                }
            >
                <ListItemText primary="Update" />
            </MenuItem> */}

            <SuperGeneralUpdateModal open={open} onClose={handleCloseDeleteModal} programId={props.programId} disabledStatus={props.disabledStatus} peo={props.peo}/>
        </div>
    )
}
