import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import token from 'assets/utils/accessToken';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  graduationRequirements: [],
  graduationRequirementsStatus: 'idle',
  graduationRequirementStatus: 'idle',
  error: null,
  graduationRequirement:null,
 }


export const fetchGraduationRequirements = 
createAsyncThunk(
  'graduationRequirementSpec/fetchGraduationRequirements', 
  async (params,{ rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+'/graduationRequirementSpec/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

       return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
      });
      

  export const fetchGraduationRequirementById =
   createAsyncThunk(
     'graduationRequirementSpec/fetchGraduationRequirementById', 
     async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/graduationRequirementSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

   
  return response.data;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);
  export const addNewGraduationRequirement = createAsyncThunk(
    'graduationRequirementSpec/addNewGraduationRequirement',
    async (initialGraduationRequirement, { rejectWithValue }) => {
      console.log('initial graduationRequirement', initialGraduationRequirement)
      const token=localStorage.getItem('token')
      try {
      const response = await axios.post(BaseUrl+`/graduationRequirementSpec/create/`,initialGraduationRequirement ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
  );
  export const graduationRequirementUpdate = createAsyncThunk(
    'graduationRequirementSpec/graduationRequirementUpdate',
    async (data, { rejectWithValue }) => {
      
      try{
        const token=localStorage.getItem('token')
        const response = await axios.put(BaseUrl+`/graduationRequirementSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedGraduationRequirement')
      console.log(response.data,'response.data')   
     
  return response;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);

  export const deleteGraduationRequirement = createAsyncThunk(
    'graduationRequirementSpec/deleteGraduationRequirement', 
    async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(BaseUrl+`/graduationRequirementSpec/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     
     return response;
    } catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);
  
const  graduationRequirementsSlice = createSlice({
  name: 'graduationRequirementSpec',
  initialState,
  extraReducers: {
    [fetchGraduationRequirements.pending]: (state, action) => {
        state.graduationRequirementsStatus = 'loading'
      },
      [fetchGraduationRequirements.fulfilled]: (state, action) => {
        state.graduationRequirementsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.graduationRequirements = action.payload
      },
      [fetchGraduationRequirements.rejected]: (state, action) => {
        state.graduationRequirementsStatus = 'failed'
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },

      [fetchGraduationRequirementById.pending]: (state, action) => {
        state.graduationRequirementStatus = 'loading'
      },
      [fetchGraduationRequirementById.fulfilled]: (state, action) => {
        state.graduationRequirementStatus = 'succeeded'

        // Add any fetched posts to the array
        state.graduationRequirement = action.payload
      },
      [fetchGraduationRequirementById.rejected]: (state, action) => {
        state.graduationRequirementStatus = 'failed'
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [addNewGraduationRequirement.fulfilled]: (state, action) => {
        state.graduationRequirements.push(action.payload)
      },
      [addNewGraduationRequirement.rejected]: (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [graduationRequirementUpdate.fulfilled]: (state, action) => {
        console.log(action) 
      },
      [graduationRequirementUpdate.rejected]: (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [deleteGraduationRequirement.fulfilled]: (state, action) => {
        // state.SpecificationAprovalDatas.push(action.payload)
      },
      [deleteGraduationRequirement.rejected]: (state, action) => {
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      
}})

export const { graduationRequirementAdded, graduationRequirementUpdated, getId} = graduationRequirementsSlice.actions

export default graduationRequirementsSlice.reducer

export const selectAllGraduationRequirements = state => state.graduationRequirementSpec.graduationRequirements
