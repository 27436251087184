import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  RoleConfigs: [],
  RoleConfigsStatus: 'idle',
  RoleConfig: null,
  RoleConfigStatus: 'idle',
}

export const fetchRoleConfigs = createAsyncThunk('roleConfig/fetchRoleConfigs', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/roleConfig/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const addRoleConfig = createAsyncThunk(
  'roleConfig/addRoleConfig',
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/roleConfig/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
)

export const deleteRoleConfig = createAsyncThunk(
    'roleConfig/deleteRoleConfig',
    async (id, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.delete(BaseUrl + `/roleConfig/${id}/delete/`, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        return response?.data
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
      }
    })

const roleConfigSlice = createSlice({
  name: 'roleConfig',
  initialState,
  extraReducers: {
    [fetchRoleConfigs.pending]: (state, action) => {
      state.RoleConfigsStatus = 'loading'
    },
    [fetchRoleConfigs.fulfilled]: (state, action) => {
      state.RoleConfigsStatus = 'succeeded'
      state.RoleConfigs = action.payload
    },
    [fetchRoleConfigs.rejected]: (state, action) => {
      state.RoleConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteRoleConfig.fulfilled]: (state, action) => {},
    [deleteRoleConfig.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default roleConfigSlice.reducer

