import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import token from 'assets/utils/accessToken';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  admissionRequirements: [],
  admissionRequirementsStatus: 'idle',
  admissionRequirementStatus: 'idle',
  error: null,
  admissionRequirement: null,
  id: null
}


export const fetchAdmissionRequirements = createAsyncThunk(
  'admissionRequirementSpec/fetchAdmissionRequirements',
   async (params,{ rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
  const response = await axios.get(
    BaseUrl + '/admissionRequirementSpec/', {
    headers: {
      'Authorization': `Token ${token}`
    }
  });
  console.log(response.data, 'response.data')

  return response.data;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
});

export const fetchAdmissionRequirementById = createAsyncThunk(
  'admissionRequirementSpec/fetchAdmissionRequirementById', 
  async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
  const response = await axios.get(
    BaseUrl + `/admissionRequirementSpec/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data;
} catch (err) {
  let error = err;
  if (!error.response) {
    throw err;
  }
  console.log("error.response from inside the CAT", error.response);
  return rejectWithValue(error.response);
}
}
);
export const addNewAdmissionRequirement = createAsyncThunk(
  'admissionRequirementSpec/addNewAdmissionRequirement',
  async (initialAdmissionRequirement, { rejectWithValue }) => {
    console.log('initial admissionRequirement', initialAdmissionRequirement)
    const token = localStorage.getItem('token')
    try {
    const response = await axios.post(BaseUrl + `/admissionRequirementSpec/create/`, initialAdmissionRequirement, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data);
    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
}
);
export const admissionRequirementUpdate = createAsyncThunk(
  'admissionRequirementSpec/admissionRequirementUpdate',
  async (data, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(BaseUrl + `/admissionRequirementSpec/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedAdmissionRequirement')
      console.log(response.data, 'response.data')
      return response.data


    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteAdmissionRequirement = createAsyncThunk(
  'admissionRequirementSpec/deleteAdmissionRequirement', 
  async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
  const response = await axios.delete(
    BaseUrl + `/admissionRequirementSpec/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

const admissionRequirementsSlice = createSlice({
  name: ' admissionRequirementSpec',
  initialState,
  extraReducers: {
    [fetchAdmissionRequirements.pending]: (state, action) => {
      state.admissionRequirementsStatus = 'loading'
    },
    [fetchAdmissionRequirements.fulfilled]: (state, action) => {
      state.admissionRequirementsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.admissionRequirements = action.payload
    },
    [fetchAdmissionRequirements.rejected]: (state, action) => {
      state.admissionRequirementsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchAdmissionRequirementById.pending]: (state, action) => {
      state.admissionRequirementStatus = 'loading'
    },
    [fetchAdmissionRequirementById.fulfilled]: (state, action) => {
      state.admissionRequirementStatus = 'succeeded'

      // Add any fetched posts to the array
      state.admissionRequirement = action.payload
    },
    [fetchAdmissionRequirementById.rejected]: (state, action) => {
      state.admissionRequirementStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewAdmissionRequirement.fulfilled]: (state, action) => {
      state.admissionRequirements.push(action.payload)
    },
    [addNewAdmissionRequirement.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [admissionRequirementUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [admissionRequirementUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteAdmissionRequirement.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [deleteAdmissionRequirement.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

  }
})

export const { admissionRequirementAdded, admissionRequirementUpdated, getId } = admissionRequirementsSlice.actions

export default admissionRequirementsSlice.reducer

export const selectAllAdmissionRequirements = state => state.admissionRequirementSpec.admissionRequirements
