import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import Button from 'components/CustomButtons/Button';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { addNewCLOSpecWeight } from '../../reduxToolkit/features/CLO/WeightCloSpecSlice'
import { getCLOsByProgram } from '../../reduxToolkit/features/CLO/CloSpecSlice'

import { fetchPloById } from '../../reduxToolkit/features/PLO/PLOSpecSlice'
import { fetchPloIndicatorById } from '../../reduxToolkit/features/PLOIndicator/PLOIndicatorSpecSlice'
import { fetchProgramById } from '../../reduxToolkit/features/program/ProgramSpecSlice'

import { Form, Input, InputNumber, message, Select, notification } from 'antd';
import ErrorModal from "./ErrorModal"

import { useTranslation } from 'react-i18next';

export default function PLOIndicatorsAndCLOsMapModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const [CLOs, setCLOs] = React.useState([]);

    const fetchCLOs = () => {
        dispatch(getCLOsByProgram(props.programId))
            .unwrap()
            .then((clos) => {
                setCLOs(clos)
            })
            .catch((err) => {
                setHandledError(err);
                handleOpenErrorModal();
            })
    };


    useEffect(() => {

        fetchCLOs()

    }, [])

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };
    const { Option } = Select;


    const onFinish = async (values) => {
        console.log("values", values)
        const resultAction =
            await dispatch(addNewCLOSpecWeight({ ...values, ploIndicatorsSpec_id: props.ploInd.id }))

        if (addNewCLOSpecWeight.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            console.log('resultAction', resultAction)
            console.log('resultAction.payload', resultAction.payload)

            openNotificationWithIcon('success', `Affected`)
            handleClose()
            dispatch(fetchPloById(props.PLOId))
            dispatch(fetchPloIndicatorById(props.ploInd.id))
            dispatch(getCLOsByProgram(props.programId))
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                handleClose()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                handleClose()
            }
        }

    }

    return (
        <div>

            <MenuItem
                onClick={(event) => {
                    handleClickOpen(event); props.handleDropDownButtonClose();
                }}>

                <ListItemText primary="Add CLO" />
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('Add CLO')}</DialogTitle>
                <DialogContent>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        scrollToFirstError
                    >

                        <GridContainer>

                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("CLO")}
                                    name="cloSpec_id"
                                    rules={[{ required: true, message: "Please select a CLO!" }]}
                                >
                                    <Select
                                        showArrow="true"
                                        dropdownStyle={{ zIndex: 10000 }}
                                        placeholder={t('select a CLO')}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {CLOs && CLOs.map(clo => <Option value={clo.id}>{clo.title}</Option>)}
                                    </Select>
                                </Form.Item>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={6}>
                                <Form.Item
                                    label={t('Weight')}
                                    name="weight"
                                    rules={[{ required: true, message: "Please input the weight!" }]}
                                >
                                    <InputNumber min={0} style={{ width: '100%' }} />
                                </Form.Item>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                                <Form.Item
                                    label={t('Target')}
                                    name="target"
                                    rules={[{ required: true, message: "Please input the target!" }]}
                                >
                                    <InputNumber min={0} max={100} style={{ width: '100%' }} />
                                </Form.Item>
                            </GridItem>
                        </GridContainer>


                        {/* </FormControl> */}
                        <GridContainer justify="flex-end">
                            <GridItem>

                                <Form.Item>
                                    <GridContainer>
                                        <GridItem>
                                            <Button color="primary" onClick={() => { handleClose() }}>
                                                {t('Cancel')}
                                            </Button>
                                        </GridItem>
                                        <GridItem>
                                            <Button type="submit" color="primary">
                                                {t('Save')}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Form.Item>

                            </GridItem>
                        </GridContainer>

                    </Form>
                </DialogContent>
            </Dialog>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
        </div>
    );
}
