import React from "react";
import { Link, useHistory } from "react-router-dom";

import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddStaff from "./AddStaff";
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  fetchPersonById, deletePerson
} from "reduxToolkit/features/person/PersonsSlice";
import ErrorModal from "views/Components/ErrorModal";
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'

const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

export default function DropDownPersonList(props) {
  console.log(props)
  const { t } = useTranslation();
  const buttonClasses = useButtonStyles();
  const [open, setOpen] = React.useState(false);
  const [openSection, setOpenSection] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const history = useHistory();

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDeleteModal = () => {
    setOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setOpen(false);
  };
  const handleOpenSectionModal = () => {
    setOpenSection(true);
  };
  const handleCloseSectionModal = () => {
    setOpenSection(false);
  };
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDeleteItem = () => {
    dispatch(deletePerson(props.id))
      .unwrap()
      .then((originalPromiseResult) => {
        props.setPing(!props.ping)
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
        style={{ marginRight: "50px" }}
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <AddStaff
          handleDropDownButtonClose={handleClose}
          disabled={
            props.selectedItemId.length == 0 || props.selectedItemId.length > 1
          }
          id={props.id}
        />
        {/* <MenuItem disabled={!props.selectedRows.length}>Update Dates</MenuItem> */}

        {/*<MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Link to={`/citrine/addEditPerson/:id`}>
            <ListItemText primary="Add Person" />
          </Link>
        </MenuItem>*/}
        <MenuItem
          disabled={
            props.selectedItemId.length == 0 || props.selectedItemId.length > 1
          }
          onClick={() => {
            dispatch(fetchPersonById(props.id))
              .then(unwrapResult)
              .then((p) => {
                console.log("person in then", p);
                history.push(`/citrine/addEditPerson/${props.id}`);
              });
            //  history.push(`/citrine/addEditPerson/${props.id}`)
            handleClose();
          }}
        >
          <ListItemText primary="Edit Person" />
        </MenuItem>
        <MenuItem
           onClick={() => {
             handleClose();
          handleOpenDeleteModal()
           }}
        >

            <ListItemText primary="Delete Person" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
}
