import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  ranges: [],
  rangesStatus: 'idle',
  rangeStatus: 'idle',
  error: null,
  range:null,
 
  id:null
}


export const fetchRanges = 
createAsyncThunk('rangeSpec/fetchRanges', async (id, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try{
    const response = await axios.get(BaseUrl+`/assessmentRangeSpec/?courseSpec_id=${id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

       return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );

  export const fetchRangeById = 
  createAsyncThunk('rangeSpec/fetchRangeById', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/assessmentRangeSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
  export const addNewRange = createAsyncThunk(
    'rangeSpec/addNewRange',
    async (initialRange, { rejectWithValue }) => {
      console.log('initial range', initialRange)
      const token=localStorage.getItem('token')
      try{
      const response = await axios.post(BaseUrl+`/assessmentRangeSpec/create/`,initialRange ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
  export const rangeUpdate = createAsyncThunk(
    'rangeSpec/rangeUpdate',
    async (data,{ rejectWithValue }) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/assessmentRangeSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedRange')
      console.log(response.data,'response.data')   
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

  export const deleteRange = createAsyncThunk('rangeSpec/deleteRange', 
  async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(BaseUrl+`/assessmentRangeSpec/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const  rangesSlice = createSlice({
  name: ' rangeSpec',
  initialState,
  extraReducers: {
    [fetchRanges.pending]: (state, action) => {
        state.rangesStatus = 'loading'
      },
      [fetchRanges.fulfilled]: (state, action) => {
        state.rangesStatus = 'succeeded'
        // Add any fetched posts to the array
        state.ranges = action.payload
      },
      [fetchRanges.rejected]: (state, action) => {
        state.rangesStatus = 'failed'
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [fetchRangeById.pending]: (state, action) => {
        state.rangeStatus = 'loading'
      },
      [fetchRangeById.fulfilled]: (state, action) => {
        state.rangeStatus = 'succeeded'

        // Add any fetched posts to the array
        state.range = action.payload
      },
      [fetchRangeById.rejected]: (state, action) => {
        state.rangeStatus = 'failed'
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [addNewRange.fulfilled]: (state, action) => {
        state.ranges.push(action.payload)
      },
      [rangeUpdate.fulfilled]: (state, action) => {
        console.log("update succeeded!!!")
      },
      [deleteRange.fulfilled]: (state, action) => {
      
         state.rangeStatus = 'succeeded'
      state.ranges = state.ranges.splice(state.ranges.findIndex((arrow) => arrow.id === action.payload), 1);
      //  const index = state.ranges.findIndex((range) => range.id !== action.payload);
      
     
      },
      [addNewRange.rejected]: (state, action) => {
        state.rangeStatus = 'failed'
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [rangeUpdate.rejected]: (state, action) => {
        state.rangeStatus = 'failed'
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      [deleteRange.rejected]: (state, action) => {
        state.rangeStatus = 'failed'
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    rangeUpdated(state, action) {
      const { id,name } = action.payload
      const existingRange = state.ranges.find(range => range.id === id)
      if (existingRange) {
        existingRange.name = name
      }
    },
    
  }
}})

export const { rangeAdded, rangeUpdated, getId} = rangesSlice.actions

export default rangesSlice.reducer

export const selectAllRanges = state => state.rangeSpec.ranges
