import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  specializations: [],
  specializationsStatus: 'idle',
  specializationStatus: 'idle',
  error: null,
  specialization:null,
}

export const fetchSpecializations = createAsyncThunk('specializationSpec/fetchSpecializations', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/specializationSpec/', 
    {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
      
      )
      // console.log(response.data,'response.data')

    return response.data
  })

  export const fetchSpecializationById = createAsyncThunk('specializationSpec/fetchSpecializationById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/specializationSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewSpecialization = createAsyncThunk(
    'specializationSpec/addNewSpecialization',
    async (initialSpecialization) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/specializationSpec/create/`,initialSpecialization ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )

  export const updateSpecialization = createAsyncThunk(
    'specializationSpec/updateSpecialization',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/specializationSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedSpecialization')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the Specialization: ', error)

}
    }
  )
  export const deleteSpecialization = createAsyncThunk('specializationSpec/deleteSpecialization', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/specializationSpec/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')

    return response.data
})

const  specializationsSlice = createSlice({
  name: ' specializationSpec',
  initialState,
  extraReducers: {

    [fetchSpecializations.pending]: (state, action) => {
        state.specializationsStatus = 'loading'
      },
      [fetchSpecializations.fulfilled]: (state, action) => {
        state.specializationsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.specializations = action.payload
      },
      [fetchSpecializations.rejected]: (state, action) => {
        state.specializationsStatus = 'failed'
        state.error = action.payload
      },

      [fetchSpecializationById.pending]: (state, action) => {
        state.specializationStatus = 'loading'
      },
      [fetchSpecializationById.fulfilled]: (state, action) => {
        state.specializationStatus = 'succeeded'

        // Add any fetched posts to the array
        state.specialization = action.payload
      },
      [fetchSpecializationById.rejected]: (state, action) => {
        state.specializationStatus = 'failed'
        state.error = action.payload
      },
      [addNewSpecialization.fulfilled]: (state, action) => {
        state.specializations.push(action.payload)
      },
      [deleteSpecialization.fulfilled]: (state, action) => {
      
      },
      [updateSpecialization.fulfilled]: (state, action) => {
        

      },

  },

})

 export const { specializationAdded, specializationUpdated, getId } = specializationsSlice.actions

export default specializationsSlice.reducer

export const selectAllSpecializations = state => state.specializationSpec.specializations
