import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseById } from '../../../../../reduxToolkit/Course/coursesSpecSlice';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';

import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { Breadcrumb } from 'antd';
import { fetchVerificationOfCredibilitiesSpec } from "reduxToolkit/features/VerificationOfCredibility/VerificationOfCredibilitySpecSlice";

import DropDownButtonVerificationOfCredibility from "../../../../Components/DropDownButtonVerificationOfCredibility";

import { notification } from "antd";
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../../Components/ErrorModal";

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);


export default function VerificationCredibilityList({ match }) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();


    const { id } = match.params

    const dispatch = useDispatch()

    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const VerificationOfCredibilitiesSpec = useSelector((state) => state.VerificationOfCredibility.VerificationOfCredibilitiesSpec)
    console.log('course to be updated', course)

    const courseStatus = useSelector((state) => state.CourseSpec.courseSpecificationStatus)
    const error = useSelector((state) => state.course.error)
    console.log('course', course)

    // if (!course) {
    //     return (
    //         <section>
    //             <h2>Course not found!</h2>
    //         </section>
    //     )
    // }
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };


    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const fetchAllVerificationOfCredibilitySpec = async () => {
        try {
            const resultAction = await dispatch(fetchVerificationOfCredibilitiesSpec(id)).unwrap();
            dispatch(fetchCourseById(id));
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };
    useEffect(() => {
        fetchAllVerificationOfCredibilitySpec();
        if (localStorage.getItem("CourseState") != "Draft") {
            setDisabledStatus(true);
        }
    }, [dispatch]);

    console.log('VerificationOfCredibilitiesSpec', VerificationOfCredibilitiesSpec)

    return (
        <div>
            <GridItem>
                <Breadcrumb separator=">">

                   

                    <Breadcrumb.Item>
                        <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/courseSpecDetails/${course && course.id && course.id}`}>{course && course.title && course.title}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/specification/course/${id}/VerificationCredibilities`} style={{ color: "orange" }} > {t('Verification Of Credibilities Spec')}
                        </Link>
                    </Breadcrumb.Item>

                </Breadcrumb>
            </GridItem>
            <GridContainer >
                <GridItem >
                    {disabledStatus ? <p></p> :
                        <Link to={`/citrine/specification/course/${id}/VerificationCredibilities/create`}>
                            <Button color="info"
                                className={classes.updateProfileButton}
                            >
                                {t('Add')}
                            </Button>
                        </Link>
                    }
                </GridItem>
            </GridContainer>

            <GridContainer>
                {VerificationOfCredibilitiesSpec && VerificationOfCredibilitiesSpec.map(Element =>

                (

                    <GridItem key={Element.id} xs={12} sm={12} md={6} >
                        <Card style={{ height: 150, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={12} sm={12} md={10}>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                {t('Verification Method')} : {Element && Element.method_verificationSpec_detail && Element.method_verificationSpec_detail.name && Element.method_verificationSpec_detail.name}
                                            </a>

                                        </h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                        {disabledStatus ? <p></p> :
                                            <DropDownButtonVerificationOfCredibility VerificationOfCredibility_ID={Element.id} courseID={course && course.id} />
                                        }
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>

            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div >

    );

}




