import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  student: null,
  assessmentStatus: 'idle',
  assessmentStatus: 'idle',
  CourseAssessmentMethodNotesEXAMStatus: 'idle',
  error: null,
  assessmentId: null,
  cloNotes: null,
  examNotes: null,
  questionNotes: null,
  AssessmentMethod: null,
  courseAssessmentMethod: null,
  CourseAssessmentMethodNotesCLO: null,
  CourseAssessmentMethodNotesEXAM: null,
  score: null,
  TuplesNoteQuestions: [],
  TuplesNoteQuestionsByGroup: [],
  TuplesNoteByQuestionsStatus: 'idle',
  NoteByQuestions: [],
  tuplesNoteQuestionsStatus: 'idle',
  tuplesNoteQuestionsByGroupStatus: 'idle',
  ClosQuestionsStatus: 'idle',
  ClosQuestions: [],
  WeightMarksByClos: []
  //  assessmentMethods:[],
}

export const addNewNoteClo = createAsyncThunk(
  'assessment/addNewNoteClo',
  async (NewNote) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(
      BaseUrl + '/courseAssessmentNoteClo/create/',
      NewNote,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    )
    return response.post
  },
)
export const addNewNoteQuestion = createAsyncThunk(
  'assessment/addNewNoteQuestion',
  async (NewNote, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + '/examByQuestion/note/create/',
        NewNote,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.post
    } catch (error) {
      // cast the error for access
      if (!error.response) {
        throw error
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const addNewNoteExam = createAsyncThunk(
  'assessment/addNewNoteExam',
  async (NewNote) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(
      BaseUrl + '/courseAssessmentNoteExam/create/',
      NewNote,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    return response.post
  },
)
export const fetchNotesByCloIdAndAssessmentId = createAsyncThunk(
  'assessment/fetchNotesByCloIdAndAssessmentId',
  async (obj) => {
    //    console.log(obj.clo_id, 'clo_id +++')
    //    console.log(obj.assessment_id, 'assessment_id++++')
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/courseAssessmentNoteClo/?clo=${obj.clo_id}&assessment=${obj.assessment_id}&semester=${obj.semester_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data, 'response.data')

    return response.data
  },
)
export const fetchNoteExamBySemsterAndAssessmentId = createAsyncThunk(
  'assessment/fetchNoteExamBySemsterAndAssessmentId',
  async (obj) => {
    console.log(obj.semester_id, 'semester_id +++')
    console.log(obj.assessment_id, 'assessment_id++++')
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/courseAssessmentNoteExam/?assessment=${obj.assessment_id}&semester=${obj.semester_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data, 'response.data fetchNoteExamBySemsterAndAssessmentId ')

    return response.data
  },
)
export const fetchTuplesNoteQuestions = createAsyncThunk(
  'assessment/fetchTuplesNoteQuestions',
  async (obj) => {
    console.log(obj.semester_id, 'semester_id +++')
    console.log(obj.assessment_id, 'assessment_id++++')
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/examByQuestion/tuple/?course_id=${obj.course_id}&exam_id=${obj.id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data, 'response.data fetchNoteExamBySemsterAndAssessmentId ')

    return response.data
  },
)
export const fetchTuplesNoteQuestionsByClos = createAsyncThunk(
  'assessment/fetchTuplesNoteQuestionsByClos',
  async (object) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/examByQuestion/tuple/note_by_clo/?course_id=${object.id}&clo_id=${object.clo_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data, 'response.data fetchNoteExamBySemsterAndAssessmentId ')

    return response.data
  },
)
export const uploadQuestionNotes = createAsyncThunk(
  'assessment/uploadQuestionNotes',
  async (bodyGroup, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + '/attachment/question_notes/upload/',
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data of groups')

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
/*************************************************************************************
 *
 * Import / Export Api
 *
 ***************************************************************************************/

export const uploadNotes = createAsyncThunk(
  'assessment/uploadNotes',
  async (bodyGroup, { rejectWithValue }) => {
    // console.log(bodyGroup.uploadData)
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + '/attachment/import_export/',
        bodyGroup,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data of import/export')

      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
/************************************************************************************ */
export const fetchTuplesNoteQuestionsByGroups = createAsyncThunk(
  'assessment/fetchTuplesNoteQuestionsByGroups',
  async (object) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/examByQuestion/tuple/note_by_groups/?exam_id=${object.id}&course_id=${object.course_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    console.log(
      response.data,
      'response.data fetchTuplesNoteQuestionsByGroups ',
    )

    return response.data
  },
)
/*************************************************************************** */
export const fetchTuplesNoteByQuestions = createAsyncThunk(
  'assessment/fetchTuplesNoteByQuestions',
  async (object, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl +
        `/examByQuestion/note_by_question/?course_id=${object.id}&clo_id=${object.clo_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data fetchTuplesNoteByQuestions ')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
/************************************************************************** */
export const fetchTuplesWeight_MarksByClos = createAsyncThunk(
  'assessment/fetchTuplesWeight_MarksByClos',
  async (object, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl +
        `/examQuestion/weight_marks_by_clo/?course_id=${object.id}&clo_id=${object.clo_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      // console.log(response.data, 'response.data fetchNoteExamBySemsterAndAssessmentId ')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const fetchTuplesScoreStudentByCloQuestions = createAsyncThunk(
  'assessment/fetchTuplesScoreStudentByCloQuestions',
  async (object) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/scoreCloByStudent/tuple/score_clo/?course_id=${object.id}&clo_id=${object.clo_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    return response.data
  },
)
export const ScoreCourseByQuestion = createAsyncThunk(
  'assessment/ScoreCourseByQuestion',
  async (object) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(
      BaseUrl + `/scoreCourseByQuestion/create/ `,
      object,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    return response.data
  },
)
export const fetchQuestions_ByCloId = createAsyncThunk(
  'assessment/fetchQuestions_ByCloId',
  async (object, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl +
        `/examQuestion/questions_by_clo/?course_id=${object.id}&clo_id=${object.clo_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data fetchQuestions_ByCloId ')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const fetchAssessmentMethodById = createAsyncThunk(
  'assessment/fetchAssessmentMethodById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/assessmentMethod/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    //console.log(response.data, 'response.data')

    return response.data
  },
)
export const fetchCourseAssessmentMethodById = createAsyncThunk(
  'assessment/fetchCourseAssessmentMethodById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl + `/courseAssessmentMethod/${id}/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    // console.log(response.data, 'response.data  CourseAssessmentMethod')

    return response.data
  },
)
export const fetchStudentNameById = createAsyncThunk(
  'assessment/fetchStudentNameById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/student/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    // console.log(response.data, 'response.data')

    return response.data
  },
)
export const fetchScoreCloByCourseId = createAsyncThunk(
  'assessment/fetchScoreCloByCourseId',
  async (obj) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/calculationScoreByCourse/?course_id=${obj.course_id}&semester_id=${obj.semester_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    console.log(response.data, 'response.data calcul score')

    return response.data
  },
)
export const ExportNoteQuestion = createAsyncThunk(
  'assessment/ExportNoteQuestion',
  async (obj) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(
      BaseUrl +
      `/examByQuestion/?course_id=${obj.course_id}&semester_id=${obj.semester_id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    console.log(response.data, 'response.data calcul score')

    return response.data
  },
)

const assessemntsSlice = createSlice({
  name: 'assessemnt',
  initialState,
  reducers: {
    getAssessmentId: (state, action) => {
      state.assessmentId = action.payload
    },
  },
  extraReducers: {
    [addNewNoteClo.fulfilled]: (state, action) => {
      state.cloNotes.push(action.payload)
    },
    [addNewNoteExam.fulfilled]: (state, action) => {
      state.examNotes.push(action.payload)
    },
    /*  [addNewNoteQuestion.fulfilled]: (state, action) => {
      state.questionNotes.push(action.payload);
    },  */

    [fetchNotesByCloIdAndAssessmentId.pending]: (state, action) => {
      state.courseStatus = 'loading'
    },
    [fetchNotesByCloIdAndAssessmentId.fulfilled]: (state, action) => {
      state.courseStatus = 'succeeded'
      // Add any fetched posts to the array
      state.CourseAssessmentMethodNotesCLO = action.payload
    },
    [fetchNotesByCloIdAndAssessmentId.rejected]: (state, action) => {
      state.courseStatus = 'failed'
      state.error = action.payload
    },

    [fetchNoteExamBySemsterAndAssessmentId.pending]: (state, action) => {
      state.CourseAssessmentMethodNotesEXAMStatus = 'loading'
    },
    [fetchNoteExamBySemsterAndAssessmentId.fulfilled]: (state, action) => {
      state.CourseAssessmentMethodNotesEXAMStatus = 'succeeded'
      // Add any fetched posts to the array
      state.CourseAssessmentMethodNotesEXAM = action.payload
    },
    [fetchNoteExamBySemsterAndAssessmentId.rejected]: (state, action) => {
      state.CourseAssessmentMethodNotesEXAMStatus = 'failed'
      state.error = action.payload
    },

    [fetchTuplesNoteQuestions.pending]: (state, action) => {
      state.tuplesNoteQuestionsStatus = 'loading'
    },
    [fetchTuplesNoteQuestions.fulfilled]: (state, action) => {
      state.tuplesNoteQuestionsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.TuplesNoteQuestions = action.payload
    },
    [fetchTuplesNoteQuestions.rejected]: (state, action) => {
      state.tuplesNoteQuestionsStatus = 'failed'
    },

    [fetchTuplesNoteQuestionsByGroups.pending]: (state, action) => {
      state.tuplesNoteQuestionsByGroupStatus = 'loading'
    },
    [fetchTuplesNoteQuestionsByGroups.fulfilled]: (state, action) => {
      state.tuplesNoteQuestionsByGroupStatus = 'succeeded'
      // Add any fetched posts to the array
      state.TuplesNoteQuestionsByGroup = action.payload
    },
    [fetchTuplesNoteQuestionsByGroups.rejected]: (state, action) => {
      state.tuplesNoteQuestionsByGroupStatus = 'failed'
    },

    [fetchQuestions_ByCloId.pending]: (state, action) => {
      state.ClosQuestionsStatus = 'loading'
    },
    [fetchQuestions_ByCloId.fulfilled]: (state, action) => {
      state.ClosQuestionsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ClosQuestions = action.payload
    },
    [fetchQuestions_ByCloId.rejected]: (state, action) => {
      state.ClosQuestionsStatus = 'failed'
    },

    [fetchStudentNameById.pending]: (state, action) => {
      state.studentStatus = 'loading'
    },
    [fetchStudentNameById.fulfilled]: (state, action) => {
      state.studentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.student = action.payload
    },
    [fetchStudentNameById.rejected]: (state, action) => {
      state.studentStatus = 'failed'
      state.error = action.payload
    },

    [fetchAssessmentMethodById.pending]: (state, action) => {
      state.courseStatus = 'loading'
    },
    [fetchAssessmentMethodById.fulfilled]: (state, action) => {
      state.courseStatus = 'succeeded'

      // Add any fetched posts to the array
      state.AssessmentMethod = action.payload
    },
    [fetchAssessmentMethodById.rejected]: (state, action) => {
      state.courseStatus = 'failed'
      state.error = action.payload
    },

    [fetchCourseAssessmentMethodById.pending]: (state, action) => {
      state.courseAssessmentMethodStatus = 'loading'
    },
    [fetchCourseAssessmentMethodById.fulfilled]: (state, action) => {
      state.courseAssessmentMethodStatus = 'succeeded'

      // Add any fetched posts to the array
      state.courseAssessmentMethod = action.payload
    },
    [fetchCourseAssessmentMethodById.rejected]: (state, action) => {
      state.courseAssessmentMethodStatus = 'failed'
      state.error = action.payload
    },

    [fetchScoreCloByCourseId.pending]: (state, action) => {
      state.courseStatus = 'loading'
    },
    [fetchScoreCloByCourseId.fulfilled]: (state, action) => {
      state.courseStatus = 'succeeded'
      // Add any fetched posts to the array
      state.score = action.payload
    },
    [fetchScoreCloByCourseId.rejected]: (state, action) => {
      state.courseStatus = 'failed'
      state.error = action.payload
    },

    /********************************************************** */
    [fetchTuplesNoteByQuestions.pending]: (state, action) => {
      state.TuplesNoteByQuestionsStatus = 'loading'
    },
    [fetchTuplesNoteByQuestions.fulfilled]: (state, action) => {
      state.TuplesNoteByQuestionsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.NoteByQuestions = action.payload
    },
    [fetchTuplesNoteByQuestions.rejected]: (state, action) => {
      state.TuplesNoteByQuestionsStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },
    /************************************************************ */
    [fetchTuplesWeight_MarksByClos.pending]: (state, action) => {
      state.TuplesWeight_MarksByClosStatus = 'loading'
    },
    [fetchTuplesWeight_MarksByClos.fulfilled]: (state, action) => {
      state.TuplesWeight_MarksByClosStatus = 'succeeded'
      // Add any fetched posts to the array
      state.WeightMarksByClos = action.payload
    },
    [fetchTuplesWeight_MarksByClos.rejected]: (state, action) => {
      state.TuplesWeight_MarksByClosStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },
    /********************************************************** */
    [fetchTuplesScoreStudentByCloQuestions.pending]: (state, action) => {
      state.TuplesScoreStudentByCloQuestionsStatus = 'loading'
    },
    [fetchTuplesScoreStudentByCloQuestions.fulfilled]: (state, action) => {
      state.TuplesScoreStudentByCloQuestionsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ScoreStudentByCloQuestions = action.payload
    },
    [fetchTuplesScoreStudentByCloQuestions.rejected]: (state, action) => {
      state.TuplesScoreStudentByCloQuestionsStatus = 'failed'
      state.error = action.payload
    },
    /********************************************************** */
  },
})

export const { getAssessmentId } = assessemntsSlice.actions

export default assessemntsSlice.reducer
