import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import {
    Link
} from "react-router-dom";
import { addNewCampaign } from '../../reduxToolkit/features/campaign/CampaignsSlice'

import { useTranslation } from 'react-i18next';
import { fetchUserById } from '../../reduxToolkit/features/user/UserSlice'
export default function ErrorsModal(props) {
    
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [show, setShow] = useState(false)
    //check if user is connected and have a valid token, in case of problem logout
  /*   useEffect(() => {
        const id = localStorage.getItem('userId')
        dispatch(fetchUserById(id)).unwrap().then((originalPromiseResult) => {
         // console.log('user identified')
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log('rejectedValueOrSerializedError')  
          handleLogout();
          })
       
        
      }, []);
      const handleLogout = async () => {
        localStorage.clear();
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        window.location.href= '/auth/login-page';
      };
     */
    let content
    if (props?.handledError?.status) {
        content =
            <div>
                <div>{props?.handledError?.data?.detail}</div>
                <hr />
                {!show && <Button link onClick={() => { setShow(true) }}>See Details</Button>}
                {show && <div>
                    <div style={{ backgroundColor: "rgba(176, 48, 48, 0.3)", padding: "10px" }}>{props?.handledError?.data?.error}</div>
                    <br />
                    <Button link onClick={() => { setShow(false) }}>Hide</Button>
                    <Button link onClick={() => navigator.clipboard.writeText(props?.handledError?.data?.error)}>Copy</Button>


                </div>}
            </div>
    } else {
        if (props?.handledError?.message === 'Network Error') {
            content = <div><div>{props?.handledError?.message}</div><div>Please check your internet connection!</div></div>
        } else {
            content = <div>{props?.handledError?.message}</div>
        }
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}

            >
                <DialogTitle id="alert-dialog-title">
                    <GridContainer justify="space-between" alignItems="center">
                        <GridItem><p>Error</p></GridItem>
                        <GridItem>
                            <Button
                                // aria-describedby={id}
                                aria-controls="customized-menu"
                                variant="contained"
                                color="danger"
                                onClick={props.handleClose}
                                justIcon
                                round
                                aria-haspopup="true"
                            >
                                <CloseIcon />
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="danger" autoFocus>
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}