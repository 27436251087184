import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  jobs: [],
  jobsStatus: 'idle',
  jobStatus: 'idle',
  error: null,
  job: null,
  id: null
}

export const fetchJobs = createAsyncThunk('job/fetchJobs', async (id, { rejectWithValue }) => {
  
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/jobs/?programSpec_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    console.log(response.data, 'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})


export const fetchJobById = createAsyncThunk('job/fetchJobById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/jobs/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
export const addNewJob = createAsyncThunk(
  'job/addNewJob',
  async (initialJob, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/jobs/create/`, initialJob, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })


export const updateJob = createAsyncThunk(
  'job/updateJob',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/jobs/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedJob')
      console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })

export const deleteJob = createAsyncThunk('job/deleteJob', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/jobs/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})
const jobsSlice = createSlice({
  name: ' job',
  initialState,
  extraReducers: {

    [fetchJobs.pending]: (state, action) => {
      state.jobsStatus = 'loading'
    },
    [fetchJobs.fulfilled]: (state, action) => {
      state.jobsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.jobs = action.payload
    },
    [fetchJobs.rejected]: (state, action) => {
      state.jobsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchJobById.pending]: (state, action) => {
      state.jobstatus = 'loading'
    },
    [fetchJobById.fulfilled]: (state, action) => {
      state.jobstatus = 'succeeded'

      // Add any fetched posts to the array
      state.job = action.payload
    },
    [fetchJobById.rejected]: (state, action) => {
      state.jobstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewJob.fulfilled]: (state, action) => {
      state.jobs.push(action.payload)
    },
    [deleteJob.fulfilled]: (state, action) => {
      // state.jobs.push(action.payload)
    },
    [updateJob.fulfilled]: (state, action) => {
      const result = state.jobs.filter(job => job.id !== action.payload.id);
      // console.log("job id from filter from slice",job.id)
      result.push(action.payload)
      state.jobs = result
      console.log("action.payload.id from slice", action.payload)

    },
    [addNewJob.rejected]: (state, action) => {
      state.jobstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteJob.rejected]: (state, action) => {
      state.jobstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateJob.rejected]: (state, action) => {
      state.jobstatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

})


export default jobsSlice.reducer

export const selectAllJobs = state => state.jobs.jobs