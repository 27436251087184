import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  KPIs: [],
  KPIsStatus: 'idle',
  KPI: null,
  KPIStatus: 'idle',
  error: null,
  filteredKPIconfig: [],
  filteredKPIconfigStatus: 'idle',
}

export const fetchKPIsSpec = createAsyncThunk('KPISpec/fetchKPIsSpec', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiSpec/?programSpec_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'fetchKPIsSpec')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchNotAffectedKPIConfigToProgram = createAsyncThunk('KPISpec/fetchNotAffectedKPIConfigToProgram', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl +`/kpiSpec/kpiConfig_filtered/?programSpec_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})
// export const fetchKPIProgramById = createAsyncThunk('KPISpec/fetchKPIProgramById', async (id, { rejectWithValue }) => {
//   try {

//     const token = localStorage.getItem('token')
//     const response = await axios.get(BaseUrl + `/kpiProgram/${id}/`, {
//       headers: {
//         'Authorization': `token ${token}`
//       }
//     })
//     // console.log(response.data,'response.data')

//     return response.data
//   } catch (err) {
//     let error = err // cast the error for access
//     if (!error.response) {
//       throw err
//     }
//     // We got validation errors, let's return those so we can reference in our component and set form errors
//     console.log('error.response from inside the CAT', error.response)
//     return rejectWithValue(error.response)
//   }
// })

export const addNewKPISpec = createAsyncThunk(
  'KPISpec/addNewKPISpec',
  async (initialKPISpec, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/kpiSpec/create/`, initialKPISpec, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

export const deleteKPISpec = createAsyncThunk(
  "KPISpec/deleteKPISpec",
  async (ids, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    
    try {
      console.log(ids)
      const response = await axios.post(
        BaseUrl + `/kpiSpec/delete/`,ids,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


const KPISpecSlice = createSlice({
  name: 'KPISpec',
  initialState,
  extraReducers: {

    [fetchKPIsSpec.pending]: (state, action) => {
      state.KPIsStatus = 'loading'
    },
    [fetchKPIsSpec.fulfilled]: (state, action) => {
      state.KPIsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.KPIs = action.payload
    },
    [fetchKPIsSpec.rejected]: (state, action) => {
      state.KPIsStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },


    [fetchNotAffectedKPIConfigToProgram.pending]: (state, action) => {
      state.filteredKPIconfigStatus = 'loading'
    },
    [fetchNotAffectedKPIConfigToProgram.fulfilled]: (state, action) => {
      state.filteredKPIconfigStatus = 'succeeded'
      // Add any fetched posts to the array
      state.filteredKPIconfig = action.payload
    },
    [fetchNotAffectedKPIConfigToProgram.rejected]: (state, action) => {
      state.filteredKPIconfigStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    // [fetchKPIProgramById.pending]: (state, action) => {
    //   state.KPIStatus = 'loading'
    // },
    // [fetchKPIProgramById.fulfilled]: (state, action) => {
    //   state.KPIStatus = 'succeeded'

    //   // Add any fetched posts to the array
    //   state.KPI = action.payload
    // },
    // [fetchKPIProgramById.rejected]: (state, action) => {
    //   state.KPIStatus = 'failed'
    //   if (action.payload) {
    //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
    //     state.error = action.payload
    //     console.log("action.payload from inside extra add plo reducer", action.payload)
    //   } else {
    //     state.error = action.error
    //     console.log("action.error from inside extra add plo reducer", action.error)
    //   }
    // },
    [addNewKPISpec.fulfilled]: (state, action) => {
      // state.KPIs.push(action.payload)
      console.log(action.payload)

    },
    [addNewKPISpec.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
  },

})


export default KPISpecSlice.reducer


