// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, Form, Input, notification } from 'antd';
import "assets/css/styles.css";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ErrorModal from "views/Components/ErrorModal";
import { addNewYearSemester, fetchYearSemesterById, fetchYearSemesters, updateYearSemester } from '../../../../reduxToolkit/features/yearSemester/YearSemesterSlice';

const useStyles = makeStyles(styles);

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};
// const classes = useStyles();
export default function CreateYearSemesterPage({ match }) {
  const { t } = useTranslation();
  const { id } = match.params

  console.log('id', id)

  const isAddMode = isNaN(id);
  console.log('id from match is add mode', isAddMode)
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const [form] = Form.useForm();
  const titleClasses = useTitleStyles();
  const [name, setName] = useState('')

  const [addRequestStatus, setAddRequestStatus] = useState('idle')




  const dispatch = useDispatch()

  const history = useHistory();
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewYearSemester({ ...values })
    );
    if (addNewYearSemester.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchYearSemesters());
      history.push(`/citrine/configuration/course/yearSemesters`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      updateYearSemester({
        id: id,
        modified: {
          ...values,
        },
      })
    );
    if (updateYearSemester.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchYearSemesters());
      history.push(`/citrine/configuration/course/yearSemesters`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };


  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchYearSemesterById(id))
        .then(unwrapResult)
        .then((x) => {
          console.log(x, "x");
          form.setFieldsValue({
            libelle: x.libelle,
          });
        });
    }
  }, [id]);

  const yearSemester = useSelector((state) => state.yearSemesters.yearSemester)
  console.log('yearSemester ', yearSemester)

  return (
    <div>
      {isAddMode &&
        <div>
          <GridContainer style={{
            borderBottom: `1px solid  ${grayColor[15]}`,
            borderTop: `1px solid  ${grayColor[15]}`,
          }}
            justify="space-between"
            alignItems="center">
            <GridItem>

              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}>
                    <span > {t('Course Configurations')} </span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item >
                  <Link to={`/citrine/configuration/course/yearSemesters`} style={{ color: "orange" }}>
                    {t('YearSemesters List')}
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>

          </GridContainer>
          <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <span className={titleClasses.title}>{t('Create ')}</span>
                  </h4>
                </CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="libelle"
                      label="Libelle"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>



                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/configuration/course/yearSemesters`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      }
      {!isAddMode && yearSemester &&
        <div>
          <GridContainer style={{
            borderBottom: `1px solid  ${grayColor[15]}`,
            borderTop: `1px solid  ${grayColor[15]}`,
          }}
            justify="space-between"
            alignItems="center">
            <GridItem>

              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}>
                    <span > {t('Course Configurations')} </span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item >
                  <Link to={`/citrine/configuration/course/yearSemesters`} style={{ color: "orange" }}>
                    {t('YearSemesters List')}
                  </Link>
                </Breadcrumb.Item>

              </Breadcrumb>
            </GridItem>

          </GridContainer>
          <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <span className={titleClasses.title}> {t('Edit')} </span>
                  </h4>
                </CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinishUpdate}
                  >
                    <Form.Item
                      name="libelle"
                      label="Libelle"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>



                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/configuration/course/yearSemesters`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      }
    </div>


  );



}


