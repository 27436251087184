import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { addNewPeoIndicator } from '../../reduxToolkit/features/PEOIndicator/PEOIndicatorSpecSlice';

import { fetchPeoById } from '../../reduxToolkit/features/PEO/PEOSpecSlice';

import { useTranslation } from 'react-i18next';

export default function PEOIndicatorsModal({ peo, programId, PEOId }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [weight, setWeight] = useState(null)

  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const dispatch = useDispatch()

  const peoSpec_id = peo && peo.id
  const canSave =
    [name, code, weight].every(Boolean) && addRequestStatus === 'idle'

  const handleSend = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(
          addNewPeoIndicator({ name, code, weight, peoSpec_id })
        )
      } catch (err) {
        console.error('Failed to save the peoIndicator: ', err)
      } finally {
        setAddRequestStatus('idle')
        dispatch(fetchPeoById(PEOId))
      }
    }

  }
  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Add
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('Add a PEO Indicator')}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={8} sm={8} md={8}>
              <DialogContentText>
                {t(' Program educational objectives describe the career and professional accomplishments that programs are preparing graduates to attain within few years of graduation')}
              </DialogContentText>
            </GridItem>
            <GridItem xs={10} sm={10} md={10}>
              <TextField
                margin="dense"
                label="{t('Code')}"
                fullWidth
                value={code}
                onChange={event => {
                  setCode(event.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={10} sm={10} md={10}>
              <TextField
                margin="dense"
                label="{t('Name')}"
                fullWidth
                value={name}
                onChange={event => {
                  setName(event.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={10} sm={10} md={10}>
              <TextField
                margin="dense"
                label="{t('Weight')}"
                fullWidth
                value={weight}
                onChange={event => {
                  setWeight(event.target.value);
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Link to={`/citrine/programSpec/${programId}/PEOs/${PEOId}/PEOIndicators`} >
            <Button onClick={() => { handleClose(); handleSend() }} color="primary">
              {t('Save')}
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
