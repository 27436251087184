import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, Form, Input, notification, Select } from 'antd';
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchKPIConfigById, updateKPIConfig } from '../../../../../reduxToolkit/features/KPI/KPIConfigSlice';
import { fetchKPICategories } from "../../../../../reduxToolkit/features/KPICategory/KPICategorySlice";
import ErrorModal from "../../../../Components/ErrorModal";






const useNavBarStyles = makeStyles(navBarStyles);

export default function CreateKPIConfig({ match }) {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = match.params
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const navBarClasses = useNavBarStyles();


    const KPIStatus = useSelector((state) => state.KPIConfig.KPIStatus)

    useEffect(() => {
        dispatch(fetchKPIConfigById(id)).then(unwrapResult)
            .then((KPI) => {
                // do additional work
                console.log('here it is', KPI)
                form.setFieldsValue({
                    name: KPI.name,
                    code: KPI.code,
                    type: KPI.type,
                    description: KPI.description,
                    kpiCategoryConfig_ids: KPI.kpiCategoryConfig_ids
                });
            })
    }, [dispatch])


    const KPICategoriesStatus = useSelector((state) => state.KPICategory.KPICategoriesStatus)
    useEffect(() => {
        if (KPICategoriesStatus === 'idle') {
            dispatch(fetchKPICategories())
        }
    }, [dispatch])
    const KPICategories = useSelector((state) => state.KPICategory.KPICategories)


    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };



    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // message.error('Submit failed!');
    };

    const onFinish = async (values) => {
        // const target = parseInt(target)
        console.log('values', values)
        const resultAction = await dispatch(
            updateKPIConfig({ id: id, modified: values })
        )

        if (updateKPIConfig.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            const KPI = resultAction.payload
            console.log('success', `Updated ${KPI.code}`)
            openNotificationWithIcon('success', `Updated ${KPI.code}`)
            history.push(`/citrine/configuration/KPI/${id}`);
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
            }
        }
    }

    const { Option } = Select;

    return (
        <div>

            <GridContainer
                style={{
                    borderBottom: `1px solid  ${grayColor[15]}`,
                    borderTop: `1px solid  ${grayColor[15]}`,
                }}
                justify="space-between"
                alignItems="center"
            >
                <GridItem>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to={`/citrine/configuration/KPI`}>
                                {t("KPI")}
                            </Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item>
                            {/* <Link to={`/citrine/academicCalender/${id}/KPIProgram/${program && program.id}/KPIs`}> */}
                            {t("Update")}
                            {/* </Link> */}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={10} sm={10} md={12}>
                    <Card>
                        <CardBody>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                scrollToFirstError
                            >

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <Form.Item
                                            label={t("Code")}
                                            name="code"
                                            rules={[{ required: true, message: "Please input the KPI's code" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={9}>
                                        <Form.Item
                                            label={t('Name')}
                                            name="name"
                                            // hasFeedback
                                            rules={[{ required: true, message: "Please input the KPI's name" }]}

                                        >
                                            <Input />
                                        </Form.Item>

                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <Form.Item
                                            label={t('Category')}
                                            name="kpiCategoryConfig_ids"
                                            rules={[{ required: true, message: "Please input the KPI's categories" }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                showArrow
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            // onChange={onChangeCo_requisites_ids}
                                            >
                                                {KPICategories.map((KPICategory) => (
                                                    <Option key={KPICategory.id} value={KPICategory.id}>
                                                        {KPICategory.category}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <Form.Item
                                            label={t('Type')}
                                            name="type"
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            // onChange={onChangeCo_requisites_ids}
                                            >
                                                <Option value="strategic">
                                                    Strategic
                                                </Option>
                                                <Option value="general">
                                                    General
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t('Description')}
                                            name="description"
                                            // hasFeedback
                                            rules={[{ required: true, message: "Please input the KPI's description" }]}

                                        >
                                            <Input />
                                        </Form.Item>

                                    </GridItem>

                                </GridContainer>


                                {/* </FormControl> */}
                                <GridContainer justify="flex-end">
                                    <GridItem>

                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t('Save')}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>

                                    </GridItem>
                                </GridContainer>

                            </Form>
                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>



            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />

        </div>
    );
}
