import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import { BaseUrl2 } from "../../../assets/utils/accessToken";

const initialState = {
  plos: [],
  allplos: [],
  plosStatus: "idle",
  ploStatus: "idle",
  error: null,
  plo: null,
  notAffectedPLOsToPEOStatus: "idle",
  notAffectedPLOsToPEO: [],
};

export const fetchPlos = createAsyncThunk("ploSpec/fetchPlos", async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + "/ploSpec/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  // console.log(response.data,'response.data')

  return response.data;
});

export const fetchPlosByProgramId = createAsyncThunk(
  "ploSpec/fetchPlosByProgramId",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/ploSpec/?programSpec_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,

          /*   Authorization: `Token 77e8c96b2b9bcfcb83db28c0cdaa2f21c50ea3ee`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS", */
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchAllPlos = createAsyncThunk(
  "ploSpec/fetchAllPlos",
  async (params, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/ploSpec/all/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from fetch plos", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchNotAffectedPLOsToPEO = createAsyncThunk(
  "ploSpec/fetchNotAffectedPLOsToPEO",
  async (ids, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl +
          `/ploSpec/ploSpec_filtered/?peoSpec_id=${ids.PEOId}&programSpec_id=${ids.programId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchPloById = createAsyncThunk(
  "ploSpec/fetchPloById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/ploSpec/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewPlo = createAsyncThunk(
  "ploSpec/addNewPlo",
  async (initialPlo, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/ploSpec/create/`,
        initialPlo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const mapCoursesToPlo = createAsyncThunk(
  "ploSpec/mapCoursesToPlo",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/ploSpec/addCourses/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updatePlo = createAsyncThunk(
  "ploSpec/updatePlo",
  async (data, { rejectWithValue }) => {
    console.log("data", data);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/ploSpec/${data.id}/edit/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedPlo");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deletePlo = createAsyncThunk(
  "ploSpec/deletePlo",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/ploSpec/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const deleteCoursePlo = createAsyncThunk(
  "ploSpec/deleteCoursePlo",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/ploSpec/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
/************************************************************
 * POST and GET Course PLO
 * 
 * New API by Dhaker
 ***********************************************************/
/*******GET*******/
 export const fetchCourseSpecPerfPLO = createAsyncThunk(
  "CoursePLOSpec/fetchCourseSpecPerfPLO",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/courseSpecPerformancePlo/?ploSpec_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
/*****POST********/
export const addNewCourseSpecPLO = createAsyncThunk(
  "ploSpec/addNewCourseSpecPLO",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/courseSpecPerformancePlo/create/`,
        body,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
/***********************************************************/

const ploSpecSlice = createSlice({
  name: " ploSpec",
  initialState,
  extraReducers: {
    [fetchPlosByProgramId.pending]: (state, action) => {
      state.plosStatus = "loading";
    },
    [fetchPlosByProgramId.fulfilled]: (state, action) => {
      state.plosStatus = "succeeded";
      // Add any fetched posts to the array
      state.plos = action.payload;
      // state.plos.concat(action.payload)
      // [...new Set([...state.plos,...action.payload])]
      console.log("state.plos", state.plos);
    },
    [fetchPlosByProgramId.rejected]: (state, action) => {
      state.plosStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },
    [fetchAllPlos.pending]: (state, action) => {
      state.plosStatus = "loading";
    },
    [fetchAllPlos.fulfilled]: (state, action) => {
      state.plosStatus = "succeeded";

      state.allplos = action.payload;
    },
    [fetchAllPlos.rejected]: (state, action) => {
      state.plosStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [fetchNotAffectedPLOsToPEO.pending]: (state, action) => {
      state.notAffectedPLOsToPEOStatus = "loading";
    },
    [fetchNotAffectedPLOsToPEO.fulfilled]: (state, action) => {
      state.notAffectedPLOsToPEOStatus = "succeeded";
      // Add any fetched posts to the array
      state.notAffectedPLOsToPEO = action.payload;
      // state.plos.concat(action.payload)
      // [...new Set([...state.plos,...action.payload])]
      console.log("state.plos", state.plos);
    },
    [fetchNotAffectedPLOsToPEO.rejected]: (state, action) => {
      state.notAffectedPLOsToPEOStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [fetchPloById.pending]: (state, action) => {
      state.ploStatus = "loading";
    },
    [fetchPloById.fulfilled]: (state, action) => {
      state.ploStatus = "succeeded";
      // Add any fetched posts to the array
      state.plo = action.payload;
    },
    [fetchPloById.rejected]: (state, action) => {
      state.ploStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    // [addNewPlo.fulfilled]: (state, action) => {
    //   state.plos.push(action.payload);
    // },
    // [addNewPlo.rejected]: (state, action) => {
    //   if (action.payload) {
    //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
    //     state.error = action.payload;
    //     console.log(
    //       "action.payload from inside extra add plo reducer",
    //       action.payload.statusText
    //     );
    //   } else {
    //     state.error = action.error;
    //     console.log(
    //       "action.error from inside extra add plo reducer",
    //       action.error
    //     );
    //   }
    // },
    [deletePlo.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    [deletePlo.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },
    [deleteCoursePlo.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    [deleteCoursePlo.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [updatePlo.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [updatePlo.fulfilled]: (state, action) => {},
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { programAdded } = ploSpecSlice.actions;

export default ploSpecSlice.reducer;

export const selectAllPlos = (state) => state.ploSpec.plos;
