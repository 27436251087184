import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useTranslation } from "react-i18next";
import GenerateActionsCourseModal from "../../Components/GenerateActionsCourseModal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";

const useStyles = makeStyles(styles);

function Stepper({ states, stateName, setStateName }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // Find the current state object from the array
  //   let currentStateObj;
  //   useEffect(() => {
  //     currentStateObj = states.find((state) => state.state === stateName);
  //   }, [stateName]);
  const currentStateObj = states.find((state) => state.state === stateName);

  // Handle action click
  const handleActionClick = (actionName) => {
    if (actionName.toLowerCase() === "approve") {
      setStateName("Approved");
    }

    if (actionName.toLowerCase() === "send for approval") {
      setStateName("Waiting for approval");
    }

    if (actionName.toLowerCase() === "reset to draft") {
      setStateName("Created");
    }

    if (actionName.toLowerCase() === "archive") {
      setStateName("Archived");
    }
  };



  return (
    <>
      <Card
        style={{
          width: "100%",
        }}
      >
      <GridContainer alignItems="center">
        <GridItem xs={12} sm={3} md={3}>
          {currentStateObj?.actions.map((action) => (
            <Button
            key={action.index}
              color="primary"
              size="sm"
              className={classes.marginRight}
              onClick={() => handleActionClick(action.name)}
            >
              {action.name}
            </Button>
          ))}
        </GridItem>
        <GridItem xs={12} sm={9} md={9}>
        <GridContainer justifyContent="flex-end">
        <GridItem>
        <ol className="progress-bar" style={{ height: "40px" }}>
          {states?.map((stateObj, index) => {
            return (
              <li
                key={stateObj.id}
                className={
                  stateObj.state === stateName
                    ? "progress-bar__steps current"
                    : "progress-bar__steps"
                }
                style={{
                  width: "auto",
                  paddingTop: "10px",
                  textAlign: "center",
                }}
              >
                <span className="progress-bar__steps--text">
                  {stateObj.state}
                </span>
              </li>
            );
          })}
        </ol>
        </GridItem>
        </GridContainer>
        </GridItem>
        </GridContainer>
      </Card>
    </>
  );
}

export default Stepper;
