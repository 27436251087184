import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  TeachingAcademicExperiences: [],
  TeachingAcademicExperiencesStatus: "idle",
  TeachingAcademicExperienceStatus: "idle",
  error: null,
  TeachingAcademicExperience: null,
};

export const fetchTeachingAcademicExperiencesByPersonId = createAsyncThunk(
  "TeachingAcademicExperience/fetchTeachingAcademicExperiencesByPersonId",
  async (person_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/teachingAndAcademicExperience/byStaff/?staff_id=${person_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);

export const addNewTeachingAcademicExperience = createAsyncThunk(
  "TeachingAcademicExperience/addNewTeachingAcademicExperience",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/teachingAndAcademicExperience/create/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateTeachingAcademicExperience = createAsyncThunk(
  "TeachingAcademicExperience/UpdateTeachingAcademicExperience",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/teachingAndAcademicExperience/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "education");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the education: ", error);
    }
  }
);

export const deleteTeachingAcademicExperience = createAsyncThunk(
  "TeachingAcademicExperience/deleteTeachingAcademicExperience",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(BaseUrl + `/teachingAndAcademicExperience/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  }
);

const TeachingAcademicExperienceSlice = createSlice({
  name: "education",
  initialState,
  extraReducers: {
    [fetchTeachingAcademicExperiencesByPersonId.pending]: (state, action) => {
      state.TeachingAcademicExperiencesStatus = "loading";
    },
    [fetchTeachingAcademicExperiencesByPersonId.fulfilled]: (state, action) => {
      state.TeachingAcademicExperiencesStatus = "succeeded";
      // Add any fetched posts to the array
      state.TeachingAcademicExperiences = action.payload;
    },
    [fetchTeachingAcademicExperiencesByPersonId.rejected]: (state, action) => {
      state.TeachingAcademicExperiencesStatus = "failed";
      state.error = action.payload;
    },

    [addNewTeachingAcademicExperience.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },

    [UpdateTeachingAcademicExperience.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    [deleteTeachingAcademicExperience.fulfilled]: (state, action) => {},
  },
});

export default TeachingAcademicExperienceSlice.reducer;
// export const  groups = state => state.group.groups
