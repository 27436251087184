import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import React from "react";

import { useDispatch } from "react-redux";
import {
  deletePeo
} from "../../reduxToolkit/features/PEO/PEOSpecSlice";

import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramSpecSlice";

import MoreVert from "@material-ui/icons/MoreVert";
import ErrorModal from "./ErrorModal";
import PEOsAndPLOsMapModal from "./PEOsAndPLOsMapModal";

import { useTranslation } from "react-i18next";
import SuperGeneralDeleteModal from "../Components/SuperGeneralDeleteModal";
import PEOsUpdates from "./Uploaders/PEOsUpdates";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function PEODropDownButton(props) {
  console.log(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [toBeDeleted, setToBeDeleted] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeletePeo = (id) => {
    dispatch(deletePeo(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log("originalPromiseResult", originalPromiseResult);
      })
      .then(() => {
        // do additional work
        dispatch(fetchProgramById(props.programId));
        openNotificationWithIcon("success", `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  return (

    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <PEOsAndPLOsMapModal
          handleDropDownButtonClose={handleClose}
          peo={props.peo}
          programId={props.programId}
          disabledStatus={props.disabledStatus}
        />



        <MenuItem
          onClick={props.disabledStatus ? {} :
            () => {
              handleOpenDeleteModal()
              setToBeDeleted("peo")
            }
          }
        >
          <ListItemText primary="Delete PEO" />
        </MenuItem>

        <MenuItem
          disabled={!props.ids.ploSpec_id}
          onClick={props.disabledStatus ? undefined :
            () => {
              handleOpenDeleteModal()
              setToBeDeleted("plo")
            }}
        >
          <ListItemText primary="Delete PLO" />
        </MenuItem>

        <PEOsUpdates disabledStatus={props.disabledStatus} programId={props.programId} peo={props.peo} />

        <SuperGeneralDeleteModal
          delete_Element={
            toBeDeleted === "peo" ? handleDeletePeo : props.handleDeletePLO
          }
          Element_ID={toBeDeleted === "peo" ? props.peo.id : props.ids}
          open={open}
          handleClose={handleCloseDeleteModal}
        />



        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </StyledMenu>
    </div>
  );
}
