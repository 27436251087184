import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { fetchRangeById } from '../../../../../reduxToolkit/features/range/RangeSpecSlice';


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';

import { SchoolRounded } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";


import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import { Breadcrumb } from 'antd';

import { useTranslation } from 'react-i18next';

import { unwrapResult } from '@reduxjs/toolkit';
import { notification } from "antd";
import ErrorModal from "views/Components/ErrorModal";
export default function RangeDetails({ match }) {
  const { t } = useTranslation();
  const useStyles = makeStyles(styles);
  const useNavBarStyles = makeStyles(navBarStyles);
  const useTitleStyles = makeStyles(titleStyles);
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const { id } = match.params
  const dispatch = useDispatch()
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  useEffect(() => {
    dispatch(fetchRangeById(id))
      .then(unwrapResult)


  }, [id, dispatch])

  const range = useSelector((state) => state.rangeSpec.range)

  console.log('range details', range)


  const rangeStatus = useSelector((state) => state.rangeSpec.rangeStatus)
  const error = useSelector((state) => state.rangeSpec.error)

  return (
    <div>
      <GridContainer

        md={12}
        xs={12} sm={12}
        direction="row"
        // style={{ width:300 ,height:150}}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>

          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span > {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/ranges`}>
                <span > {t('Ranges')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>

              <span > {t('Details of')} </span>

            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>







      <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} xs={12} sm={12} md={12}>

        <GridItem xs={12} sm={12} md={12}>
          <Card justify="space-between">
            <CardHeader color="info" icon style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <GridContainer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <GridItem xs={12} sm={12} md={10}>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>{t('Range')}---<small>{range && range.type}</small> </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}

                  >
                    <Link to={`/citrine/createRange/${range && range.id}`}>
                      <Button color="success" simple justIcon>
                        <EditIcon className={classes.underChartIcons} />
                      </Button>
                    </Link>
                  </Tooltip></GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>


          <div >
            <Card >
              <CardBody>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={10}> <div>{t('Start of interval')}  </div></GridItem>
                  <GridItem xs={12} sm={12} md={2}>  {range && range.start_of_interval}</GridItem>
                  <GridItem xs={12} sm={12} md={10}> <div>{t('End of interval')}</div></GridItem>
                  <GridItem xs={12} sm={12} md={2}>  <div>  {range && range.end_of_interval}</div></GridItem>
                  <GridItem xs={12} sm={12} md={10}> <div>{t('Course ')}</div></GridItem>
                  <GridItem xs={12} sm={12} md={2}>  <div>  {range && range.courseSpec_name.name}</div></GridItem>


                </GridContainer>
              </CardBody>
            </Card>
          </div>



        </GridItem>

      </GridContainer>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />






    </div>
  );


}

