import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import jsonData from "../../../assets/utils/historicPlo";
const initialState = {
  plosStatus: "idle",
  plos: [],
  PlosAssessmentResultStatus: "idle",
  PlosAssessmentResult: [],
  ploStatus: "idle",
  plo: null,
  GraphPloStatus: "idle",
  GraphPlo: null,
  AllPlos: [],
  ScorePlo: null,
  HistoricPlos: [],
  error: null,

};

export const rerunPLOsScores = createAsyncThunk('plo/rerunPLOsScores', async (params, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(BaseUrl + `/plo/run_plo/`, params,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchPlos = createAsyncThunk("plo/fetchPlos", async (id) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + `/plo/?program_id=${id}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  console.log(response.data, 'response.data fetchPlos')

  return response.data;
});

export const fetchAllPlos = createAsyncThunk("plo/fetchAllPlos", async (id) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + `/plo/all`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  return response.data;
});

export const fetchPlosAssessmentResult = createAsyncThunk(
  "plo/fetchPlosAssessmentResult",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/plo/plos_assessment_result/?program_id=${id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );

    return response.data;
  }
);

export const fetchPloById = createAsyncThunk("plo/fetchPloById", async (id) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + `/plo/${id}/`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  console.log(response.data, "response.data");

  return response.data;
});
export const addNewPlo = createAsyncThunk(
  "plo/addNewPlo",
  async (initialPlo) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(BaseUrl + `/plo/create/`, initialPlo, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const updatePlo = createAsyncThunk(
  "plo/updatePlo",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/plo/${data.id}/edit/`,
        data.updatedPlo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedPlo");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the plo: ", error);
    }
  }
);


export const updatePLOFieldByField = createAsyncThunk('plo/updatePLOFieldByField', async (data, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.put(BaseUrl + `/plo/${data.id}/update/`,
      data.updatedPLO,

      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})

export const updatePloPlanning = createAsyncThunk(
  "plo/updatePloPlanning",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/plo/${data.id}/update/planning/`,
        data.updated,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deletePlo = createAsyncThunk(
  "plo/deletePlo",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(BaseUrl + `/plo/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchScorePlo = createAsyncThunk(
  "ploIndicator/ftechScorePloIndicator",
  async (initialPloIndicator) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/plo/score`,
      initialPloIndicator,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const Score_Question_Plo = createAsyncThunk(
  "ploIndicator/Score_Question_Plo",
  async (object) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/plo/score_by_question/`,
      object,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchGraphPloByCode = createAsyncThunk(
  "plo/fetchGraphPloByCode",
  async (code) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/plo/historicPlo/?code=${code}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    console.log(response.data, "response.data");

    return response.data;
  }
);
export const fetchGraphPlo = createAsyncThunk(
  "plo/fetchGraphPlo",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/program/${id}/graphic/plo/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data.data_plo;
  }
);

export const UploadHistoricPlo = createAsyncThunk(
  "plo/addHistoricPlo",
  async (initialPlo) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/attachment/create/`,
      initialPlo,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data");

    return response.data;
  }
);

export const fetchHistoricPlos = createAsyncThunk(
  "plo/fetchHistoricPlos",
  async () => {
    const token = localStorage.getItem("token");
    // const response = await axios.post(BaseUrl + `/attachment/create/`, {
    //   headers: {
    //     'Authorization': `token ${token}`
    //   }
    // })
    const response = jsonData;
    console.log(response.data, "response.data");

    return response.data;
  }
);
const plosSlice = createSlice({
  name: " plo",
  initialState,
  extraReducers: {
    // plos fetched
    [fetchPlos.pending]: (state, action) => {
      state.plosStatus = "loading";
    },
    [fetchPlos.fulfilled]: (state, action) => {
      state.plosStatus = "succeeded";
      state.plos = action.payload;
    },
    [fetchPlos.rejected]: (state, action) => {
      state.plosStatus = "failed";
      state.error = action.payload;
    },

    [fetchAllPlos.pending]: (state, action) => {
      state.plosStatus = "loading";
    },
    [fetchAllPlos.fulfilled]: (state, action) => {
      state.plosStatus = "succeeded";
      state.AllPlos = action.payload;
    },
    [fetchAllPlos.rejected]: (state, action) => {
      state.plosStatus = "failed";
      state.error = action.payload;
    },


    //fetchPlosAssessmentResult
    [fetchPlosAssessmentResult.pending]: (state, action) => {
      state.PlosAssessmentResultStatus = "loading";
    },
    [fetchPlosAssessmentResult.fulfilled]: (state, action) => {
      state.PlosAssessmentResultStatus = "succeeded";
      state.PlosAssessmentResult = action.payload;
    },
    [fetchPlosAssessmentResult.rejected]: (state, action) => {
      state.PlosAssessmentResultStatus = "failed";
      state.error = action.payload;
    },

    // fetch  historic plos
    [fetchHistoricPlos.pending]: (state, action) => {
      state.plosStatus = "loading";
    },
    [fetchHistoricPlos.fulfilled]: (state, action) => {
      state.plosStatus = "succeeded";
      state.HistoricPlos = jsonData;
    },
    [fetchHistoricPlos.rejected]: (state, action) => {
      state.plosStatus = "failed";
      state.error = action.payload;
    },
    // fetch by Id
    [fetchPloById.pending]: (state, action) => {
      state.ploStatus = "loading";
    },
    [fetchPloById.fulfilled]: (state, action) => {
      state.ploStatus = "succeeded";
      state.plo = action.payload;
    },
    [fetchPloById.rejected]: (state, action) => {
      state.ploStatus = "failed";
      state.error = action.payload;
    },
    [fetchGraphPloByCode.pending]: (state, action) => {
      state.ploStatus = "loading";
    },
    [fetchGraphPloByCode.fulfilled]: (state, action) => {
      state.ploStatus = "succeeded";
      state.GraphPlo = action.payload;
    },
    [fetchGraphPloByCode.rejected]: (state, action) => {
      state.ploStatus = "failed";
      state.error = action.payload;
    },
    // fetch graph
    [fetchGraphPlo.pending]: (state, action) => {
      state.GraphPloStatus = "loading";
    },
    [fetchGraphPlo.fulfilled]: (state, action) => {
      state.GraphPloStatus = "succeeded";
      state.GraphPlo = action.payload;
    },
    [fetchGraphPlo.rejected]: (state, action) => {
      state.GraphPloStatus = "failed";
      state.error = action.payload;
    },
    // fetch Score plo
    [fetchScorePlo.pending]: (state, action) => {
      state.ploStatus = "loading";
    },
    [fetchScorePlo.fulfilled]: (state, action) => {
      state.ploStatus = "succeeded";

      state.ScorePlo = action.payload;
    },
    [fetchScorePlo.rejected]: (state, action) => {
      state.ploStatus = "failed";
      state.error = action.payload;
    },
    //add plo
    [addNewPlo.fulfilled]: (state, action) => {
      state.plos.push(action.payload);
    },
    //upload historic plo
    [UploadHistoricPlo.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    // delete plo
    [deletePlo.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    //update plo
    [updatePlo.fulfilled]: (state, action) => {
      console.log(
        "action.payload after update fulfilled from plo slice",
        action.payload
      );
    },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { programAdded } = plosSlice.actions;

export default plosSlice.reducer;
