import Typography from '@material-ui/core/Typography'
// core components
import DeleteIcon from '@material-ui/icons/Delete'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, notification, Table } from 'antd'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, {Fragment, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { DeleteGA_PLOMapping } from '../../../../reduxToolkit/features/GraduateAttribute/GA_PLOMappingSlice.jsx'
import {
  deleteGraduateAttributeSpec,
  fetchGraduateAttributesSpec,
} from '../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramSpecSlice'
import CreateGAModal from '../../../Components/CreateGAModal'
import ErrorModal from '../../../Components/ErrorModal'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import GADropDownButton from './GADropDownButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

const GAExcerpt = ({ GA, disabledStatus, isEditor }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let { programId } = useParams()

  //Modal
  const [open, setOpen] = useState(false)
  const [stateTest, SetStateTest] = useState(localStorage.getItem("state"))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //error modal
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleDeleteGA = (id) => {
    dispatch(deleteGraduateAttributeSpec(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        dispatch(fetchGraduateAttributesSpec(programId))
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const handleDeleteGA_PLOMapping = () => {
    dispatch(
      DeleteGA_PLOMapping({ ploSpec_id: toDeletePLOId, gaSpec_id: GA.id }),
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        dispatch(fetchGraduateAttributesSpec(programId))
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const [toDeletePLOId, setToDeletePLOId] = useState(null)

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
    },
    {
      dataIndex: 'delete',
      title: 'Delete',
      render: (_, record) => (
        <Button
            disabled={stateTest === "Approved" || stateTest === "Archived"}
          simple
          size="xs"
          color="primary"
          onClick={() => {
            setToDeletePLOId(record.id)
            handleClickOpen()
          }}
          //style = {{width:"5x"}}
          // style={{pointerEvents: isEditor ? '' : 'none'}}
          justIcon
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ]

  const data = GA.plosSpec
  console.log(GA)
  return (
    <GridItem xs={12} sm={12} md={11}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <GADetailsModal
                code={GA?.graduateAttributeConfig_details?.code}
                name={GA?.graduateAttributeConfig_details?.name}
                GA={GA}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              {!disabledStatus && (
                <GADropDownButton
                  GA={GA}
                  programId={programId}
                  handleDeleteGA={handleDeleteGA}
                  disabledStatus={disabledStatus}
                />
              )}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Table columns={columns} dataSource={data} pagination={false} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />

      <SuperGeneralDeleteModal
        delete_Element={handleDeleteGA_PLOMapping}
        // Element_ID={}
        open={open}
        handleClose={handleClose}
      />
    </GridItem>
  )
}

export default function GraduateAttributes({ match }) {
  const { t } = useTranslation()
  const { programId } = match.params

  const dispatch = useDispatch()
  const [isEditor, setIsEditor] = useState(true)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const [statusProgram, setStatusProgram] = useState('')
  const [disabledStatus, setDisabledStatus] = useState(false)
  const [ping, setPing] = useState(false)

  const fetchGraduateAttributesByProgramId = async () => {
    try {
      const resultAction = await dispatch(
        fetchGraduateAttributesSpec(programId),
      ).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    fetchGraduateAttributesByProgramId()
    dispatch(fetchProgramById(programId))
    setStatusProgram(localStorage.getItem('state'))
    if (localStorage.getItem('state') != 'Draft') {
      setDisabledStatus(true)
    }
  }, [ping, dispatch])

  const GraduateAttributesStatus = useSelector(
    (state) => state.GraduateAttributeSpec.GraduateAttributesStatus,
  )
  const GraduateAttributes = useSelector(
    (state) => state.GraduateAttributeSpec.GraduateAttributes,
  )

  const program = useSelector((state) => state.programSpec.program)

  let content

  if (GraduateAttributesStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (GraduateAttributesStatus === 'succeeded') {
    if (GraduateAttributes && GraduateAttributes.length) {
      content = GraduateAttributes.map((GA) => (
        <GAExcerpt key={GA.id} GA={GA} disabledStatus={disabledStatus} />
      ))
    } else {
      content = <h5>{t('This Program has no GAs to display!')}</h5>
    }
  }

  return (
    <div>
      <GridContainer>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/programSpec`}>{t('Programs')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/programSpec/${programId}`}>
              <span>{program && program.program_degree_name}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/programSpec/${programId}/GraduateAttributes`}>
              {t('Graduate Attributes')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>

      <GridContainer>
        <GridItem>
          {isEditor && (
            <CreateGAModal
              programSpec_id={programId}
              disabledStatus={disabledStatus}
              ping={ping}
              setPing={setPing}
            />
          )}
        </GridItem>
      </GridContainer>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

const GADetailsModal = ({ GA, name, code }) => {
  const graduateAttributeConfigs = useSelector(
    (state) => state.graduateAttributeConfig.graduateAttributeConfigs,
  )
  const GAName = graduateAttributeConfigs?.find(({ id }) => GA.name_id == id)
    ?.name

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Fragment>
      <Typography
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleClickOpen()
        }}
      >
        {code && name && `${code} : ${name}`}
      </Typography>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={1}>
              name:
            </GridItem>
            <GridItem xs={12} sm={12} md={11}>
              <Typography>
                {
                  graduateAttributeConfigs?.find(({ id }) => GA.name_id == id)
                    ?.name
                }
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              code:
            </GridItem>
            <GridItem xs={12} sm={12} md={11}>
              <Typography>{GA?.code}</Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              target:
            </GridItem>
            <GridItem xs={12} sm={12} md={11}>
              <Typography>{GA?.target}</Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              description:
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <Typography> {GA?.description}</Typography>
            </GridItem>

          </GridContainer>
          <GridContainer justify="flex-end">
            <GridItem>
              <Button
                color="primary"
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
