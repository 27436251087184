import React from "react";
import styled from "styled-components";
import Card from "./Card";
import "./scroll.css";
import { Droppable } from "react-beautiful-dnd";
import { greyColor } from "assets/jss/material-dashboard-pro-react.js";
const Container = styled.div`
    background-color: #F7F8F9;
    // border-radius: 2.5px;
    width: 400px;
    height: 96%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    // border: 1px solid gray;
    margin: 4px
`;

const Title = styled.p`
    padding: 20px;
    background-color: #F7F8F9;
    text-align: center;
    margin:0px
    font-weight: 900;
    font-size: 16px;
    z-index: 100;
    `;

const TaskList = styled.div`
    padding: 3px;
    transistion: background-color 0.2s ease;
    // background-color: #f4f5f7;
    flex-grow: 1;
    min-height: 100px;
`;

export default function Column({ title, tasks, id, renderCardContent }) {
    return (
        <Container className="column">
            <Title
                style={{
                    // backgroundColor: "lightblue",
                    position: "sticky",
                    top: "0",
                    fontWeight: 900

                }}
            >
                {title}
            </Title>
            <Droppable droppableId={id}>
                {(provided, snapshot) => (
                    <TaskList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                    >
                        {tasks.map((task, index) => (
                            <Card key={index} index={index} task={task} renderCardContent={renderCardContent} />
                        ))}
                        {provided.placeholder}
                    </TaskList>
                )}
            </Droppable>
        </Container >
    );
}