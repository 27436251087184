import React from "react";
import { Table } from "antd";

// rowSelection objects indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const AntDDataGrid = ({ data, columns, scroll, ...props }) => {
  return (
    <>
      <Table
        {...props}
        columns={columns}
        // rowSelection={{
        //   ...rowSelection,
        // }}
        dataSource={data}
        scroll={
          scroll
            ? {
                x: "max-content",
              }
            : null
        }
      />
    </>
  );
};
export default AntDDataGrid;
