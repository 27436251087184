import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Input, notification, Select } from 'antd';
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNewExitPoint, fetchExitPoints
} from '../../reduxToolkit/features/exitPoints/ExitPointsSlice';
import { fetchProgramById } from '../../reduxToolkit/features/program/ProgramSpecSlice';
import ErrorModal from "./ErrorModal";



export default function ExitPointsModal(props) {
    const { t } = useTranslation();
    const { Option } = Select;
    //console.log('program id in add exitPoint modal', programSpec_id)
    const programSpec_id = props.programSpec_id;
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const [exitPoint, setExitPoint] = useState('')
    const [credit_hours, setCredit_hours] = useState('')
    const [target, setTarget] = useState(null)

    const dispatch = useDispatch()


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };
    const exitPointsStatus = useSelector((state) => state.exitpoints.exitPointsStatus)
    useEffect(() => {
        if (exitPointsStatus === "idle") {
            dispatch(fetchExitPoints(programSpec_id));
        }

    }, [exitPointsStatus, dispatch]);

    const exitPoints = useSelector((state) => state.exitpoints.exitPoints)

    console.log('levelExitPoints', exitPoints)
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // message.error('Submit failed!');
    };

    const onFinish = async () => {
        // const target = parseInt(target)
        const resultAction = await dispatch(
            addNewExitPoint({ exitPoint, credit_hours, programSpec_id })
        )

        if (addNewExitPoint.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            const exitPoint = resultAction.payload
            console.log('success', `Added ${exitPoint.exitPoint}`)
            openNotificationWithIcon('success', `Added ${exitPoint.exitPoint}`)
            handleClose()
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                handleClose()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                handleClose()
            }
        }
        dispatch(fetchProgramById(programSpec_id))
    }



    return (
        <div>


            <Button color="primary" onClick={handleClickOpen}>
                {t('Add')}
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('Add an Exit Point')}</DialogTitle>
                <DialogContent>
                    <GridContainer>

                        <GridItem xs={10} sm={10} md={12}>

                            <Form
                                name="basic"
                                layout="vertical"
                                //   labelCol={{ span: 8 }}
                                //   wrapperCol={{ span: 16 }}
                                //   initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                //   autoComplete="off"
                                scrollToFirstError
                            >

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Form.Item
                                            label={t('ExitPoint')}
                                            name="exitPoint"
                                            // hasFeedback
                                            rules={[{ required: true, message: "Please input the exitPoint" }]}

                                        >
                                            <Input
                                                id="exitPoint"
                                                value={exitPoint}
                                                onChange={event => {
                                                    setExitPoint(event.target.value);
                                                }}
                                            />
                                        </Form.Item>

                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>
                                        <Form.Item
                                            label={t("Credit hours")}
                                            name="credit_hours"
                                            rules={[{ required: true, message: "Please input the exitPoint's credit_hours" }]}
                                        >
                                            <Input
                                                id="credit_hours"
                                                type="number"
                                                value={credit_hours}
                                                onChange={event => {
                                                    setCredit_hours(event.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </GridItem>

                                </GridContainer>


                                {/* </FormControl> */}
                                <GridContainer justify="flex-end">
                                    <GridItem>

                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button color="primary" onClick={() => { handleClose() }}>
                                                        {t('Cancel')}
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t('Save')}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>

                                    </GridItem>
                                </GridContainer>

                            </Form>
                        </GridItem>

                    </GridContainer>

                </DialogContent>
            </Dialog>

            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />

        </div>
    );
}
