import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {

  PublisherConfigs: [],
  PublisherConfigsStatus: 'idle',
  PublisherConfigsByType: [],
  PublisherConfigsByTypeStatus: 'idle',
  PublisherConfig: null,
  PublisherConfigStatus: 'idle',

}

export const fetchPublisherConfigs = createAsyncThunk('publisherConfig/fetchPublisherConfigs', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/publisherConfig/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    //console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response)
  }
})

export const addPublisherConfig = createAsyncThunk(
  'publisherConfig/addPublisherConfig',
  async (toAddActivity, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/publisherConfig/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response)
    }
  }
)

export const deletePublisherConfig = createAsyncThunk(
  'publisherConfig/deletePublisherConfig',
  async (id, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(BaseUrl + `/publisherConfig/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response)
    }
  }
)

const publisherConfigSlice = createSlice({
  name: 'publisherConfig',
  initialState,
  extraReducers: {

    [fetchPublisherConfigs.pending]: (state, action) => {
      state.PublisherConfigsStatus = 'loading'
    },
    [fetchPublisherConfigs.fulfilled]: (state, action) => {
      state.PublisherConfigsStatus = 'succeeded'

      state.PublisherConfigs = action.payload
    },
    [fetchPublisherConfigs.rejected]: (state, action) => {
      state.PublisherConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

  },

})

export default publisherConfigSlice.reducer

