import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  graduateAttributeConfigs: [],
  graduateAttributeConfigsStatus: 'idle',
  graduateAttributeConfigStatus: 'idle',
  error: null,
  graduateAttributeConfig:null,
}

export const fetchGraduateAttributeConfigs = createAsyncThunk('graduateAttributeConfig/fetchGraduateAttributeConfigs', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/graduateAttributeConfig/', 
    {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
      
      )
      // console.log(response.data,'response.data')

    return response.data
  })

  export const fetchGraduateAttributeConfigById = createAsyncThunk('graduateAttributeConfig/fetchGraduateAttributeConfigById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/graduateAttributeConfig/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewGraduateAttributeConfig = createAsyncThunk(
    'graduateAttributeConfig/addNewGraduateAttributeConfig',
    async (initialGraduateAttributeConfig) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/graduateAttributeConfig/create/`,initialGraduateAttributeConfig ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )

  export const updateGraduateAttributeConfig = createAsyncThunk(
    'graduateAttributeConfig/updateGraduateAttributeConfig',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/graduateAttributeConfig/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedGraduateAttributeConfig')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the GraduateAttributeConfig: ', error)

}
    }
  )
  export const deleteGraduateAttributeConfig = createAsyncThunk('graduateAttributeConfig/deleteGraduateAttributeConfig', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/graduateAttributeConfig/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')

    return response.data
})

const  graduateAttributeConfigsSlice = createSlice({
  name: ' graduateAttributeConfig',
  initialState,
  extraReducers: {

    [fetchGraduateAttributeConfigs.pending]: (state, action) => {
        state.graduateAttributeConfigsStatus = 'loading'
      },
      [fetchGraduateAttributeConfigs.fulfilled]: (state, action) => {
        state.graduateAttributeConfigsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.graduateAttributeConfigs = action.payload
      },
      [fetchGraduateAttributeConfigs.rejected]: (state, action) => {
        state.graduateAttributeConfigsStatus = 'failed'
        state.error = action.payload
      },

      [fetchGraduateAttributeConfigById.pending]: (state, action) => {
        state.graduateAttributeConfigStatus = 'loading'
      },
      [fetchGraduateAttributeConfigById.fulfilled]: (state, action) => {
        state.graduateAttributeConfigStatus = 'succeeded'

        // Add any fetched posts to the array
        state.graduateAttributeConfig = action.payload
      },
      [fetchGraduateAttributeConfigById.rejected]: (state, action) => {
        state.graduateAttributeConfigStatus = 'failed'
        state.error = action.payload
      },
      [addNewGraduateAttributeConfig.fulfilled]: (state, action) => {
        state.graduateAttributeConfigs.push(action.payload)
      },
      [deleteGraduateAttributeConfig.fulfilled]: (state, action) => {
      
      },
      [updateGraduateAttributeConfig.fulfilled]: (state, action) => {
        

      },

  },

})

 export const { graduateAttributeConfigAdded, graduateAttributeConfigUpdated, getId } = graduateAttributeConfigsSlice.actions

export default graduateAttributeConfigsSlice.reducer

export const selectAllGraduateAttributeConfigs = state => state.graduateAttributeConfig.graduateAttributeConfigs
