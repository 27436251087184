import React, { useEffect,useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch, useSelector } from 'react-redux'

// core components
/// test
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import { Breadcrumb, notification } from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import clsx from 'clsx'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { fetchCourseInstructorById } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice'
import ErrorModal from '../../../Components/ErrorModal'
import AddAssessmentMethodInstructor from './AddAssessmentMethodInstuctor'
import DropdownEditCourseInstructors from './DropdownEditCourseInstructors'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import AddCourseInstructorGroup from './AddCourseInstructorGroup'

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function AssessmentMethodInstructors({ match }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { ProgramId, course_id, Assessmentid, AssessmentName } = match.params
  const dispatch = useDispatch()
  const courseId = course_id
  const [open, setOpen] = React.useState(false)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [ping, setPing] = useState(false)

  useEffect(() => {
    dispatch(fetchProgramById(ProgramId))
    let obj = {
      course_id: course_id,
      exam_id: Assessmentid,
    }
    dispatch(fetchCourseInstructorById(obj))
    // }
  }, [dispatch,ping])
  const courseInstructors = useSelector(
    (state) => state.CourseInstructor.CourseInstructors,
  )
  //console.log(courseInstructors)
  const program = useSelector((state) => state.program.program)
  console.log('courseInstructors', courseInstructors)
  console.log('program', ProgramId)
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
      zIndex: 1000,
    })
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/programs_assessment`}>
                {t('Programs List')}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/program/${ProgramId}`}>
                {program &&
                  program.program_degree_name &&
                  program.program_degree_name}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link
                to={`/citrine/assessmentsQuestions_list/program/${ProgramId}/course/${course_id}`}
              >
                {t('Assessment Methods')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: 'orange' }}>
              {t('Instructors By Group ')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Card>
        <CardHeader>
          <GridContainer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={9}>
              <h4>{AssessmentName}</h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <AddCourseInstructorGroup
                course_id={courseId}
                Assessmentid={Assessmentid}
                ping={ping}
                setPing={setPing}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}></GridItem>

            <GridItem xs={12} sm={12} md={1}>
              <AddAssessmentMethodInstructor
                course_id={courseId}
                Assessmentid={Assessmentid}
              
              />
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>

      <GridContainer>
        {courseInstructors &&
          courseInstructors.map((courseInstructor, key) => (
            <GridItem xs={12} sm={12} md={4}>
              <Card style={{ minWidth: 375, minHeight: 300 }}>
                <CardHeader>
                  <GridContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <GridItem xs={12} sm={12} md={10}>
                      <strong>
                        <h4
                          style={{
                            color: '#8F6682',
                          }}
                        >
                          Group :{' '}
                          {courseInstructor &&
                            courseInstructor.group_details &&
                            courseInstructor.group_details.group_name}
                        </h4>
                      </strong>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <DropdownEditCourseInstructors
                        courseInstructor={courseInstructor}
                      />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <strong>
                    <h4
                      style={{
                        color: '#8F6682',
                      }}
                    >
                      Instructors :
                    </h4>{' '}
                  </strong>
                  {courseInstructor &&
                    courseInstructor.group_details &&
                    courseInstructor.group_details.instructors &&
                    courseInstructor.group_details.instructors.map(
                      (instructor) => (
                        <h4>
                          {!instructor?.avatar ? (
                            <span
                              style={{
                                backgroundColor: 'rgb(143, 102, 130)',
                                width: '23px',
                                height: '23px',
                                color: 'white',
                                borderRadius: '50%',
                                paddingLeft: '7px',
                                marginRight: '15px',
                                display: 'inline-block',
                                lineHeight: '23px',
                              }}
                            >
                              {instructor?.first_name.charAt(0).toUpperCase() +
                                '     '}
                            </span>
                          ) : (
                            <img
                              style={{ width: '20px', height: '20px' }}
                              src={`${MediaBaseUrl}${instructor?.avatar}`}
                            />
                          )}
                          {instructor.first_name} {} {instructor.last_name}
                        </h4>
                      ),
                    )}
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
