import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  patypes: [],
  patypesStatus: 'idle',
  patypeStatus: 'idle',
  error: null,
  patype: null,
  id: null
}

export const fetchPATypes = createAsyncThunk('patype/fetchPATypes', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/activityType/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchPATypeById = createAsyncThunk('patype/fetchPATypeById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/activityType/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewPAType = createAsyncThunk(
  'patype/addNewPAType',
  async (initialPAType) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/activityType/create/`, initialPAType, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updatePAType = createAsyncThunk(
  'patype/updatePAType',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/activityType/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedPAType')
      console.log(response.data, ' patype ************ response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the patype:  +++++++ ', error)

    }
  }
)


export const deletePAType = createAsyncThunk('patypeSpec/deletePAType', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/activityType/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
const patypesSlice = createSlice({
  name: ' patypeSpec',
  initialState,
  extraReducers: {

    [fetchPATypes.pending]: (state, action) => {
      state.patypesStatus = 'loading'
    },
    [fetchPATypes.fulfilled]: (state, action) => {
      state.patypesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.patypes = action.payload
    },
    [fetchPATypes.rejected]: (state, action) => {
      state.patypesStatus = 'failed'
      state.error = action.payload
    },

    [fetchPATypeById.pending]: (state, action) => {
      state.patypeStatus = 'loading'
    },
    [fetchPATypeById.fulfilled]: (state, action) => {
      state.patypeStatus = 'succeeded'

      // Add any fetched posts to the array
      state.patype = action.payload
    },
    [fetchPATypeById.rejected]: (state, action) => {
      state.patypeStatus = 'failed'
      state.error = action.payload
    },
    [addNewPAType.fulfilled]: (state, action) => {
      state.patypes.push(action.payload)
    },
    [deletePAType.fulfilled]: (state, action) => {
      // state.patypes.push(action.payload)
    },
    [updatePAType.fulfilled]: (state, action) => {
      const result = state.patypes.filter(patype => patype.id !== action.payload.id);
      // console.log("patype id from filter from slice",patype.id)
      result.push(action.payload)
      state.patypes = result
      console.log("action.payload.id from slice",action.payload)

    },
  
  },

  reducers: {
    getId:
      (state, action) => {
        state.id = action.payload
      },


    patypeUpdated(state, action) {
      const { id, name } = action.payload
      const existingPAType = state.patypes.find(patype => patype.id === id)
      if (existingPAType) {
        existingPAType.name = name
      

      }
    }
  }
})

export const { patypeUpdated, getId } = patypesSlice.actions

export default patypesSlice.reducer

 export const selectAllPATypes = state => state.patype.patypes
