import React, { useEffect, useState } from "react";
// @material-ui/core components

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons

import { Link } from "react-router-dom";
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";
import {
  addNewAssessmentResponsibility,
  AssessmentResponsibilityUpdate, fetchAssessmentResponsibilities, fetchAssessmentResponsibilityById
} from "../../../../../reduxToolkit/features/AssessmentResponsibilities/AssessmentResponsibilitiesSlice.js";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { useHistory } from "react-router-dom";

import { Breadcrumb, Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import { useTranslation } from "react-i18next";
import {
  fetchStaff, fetchUsers
} from "../../../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../../../Components/ErrorModal";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

export default function CreateAssessmentResponsibilities({ match }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { idAR } = match.params;
  const { id } = match.params;
  const [form] = Form.useForm();
  console.log("idAR", idAR);
  const isAddMode = isNaN(idAR);
  console.log("id from match is add mode", isAddMode);
  useEffect(() => {
    dispatch(fetchCourseById(id));
    dispatch(fetchStaff());
  }, []);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);

  const staffStatus = useSelector((state) => state.user.staffStatus);

  const staffList = useSelector((state) => state.user.staffList);

  const [teaching_staff_ids, setTeaching_staff_ids] = useState("");
  const [field_supervisor, setField_supervisor] = useState("");
  const [others, setOthers] = useState("");
  const [courseSpec_id, setCourseSpec_id] = useState(id);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const { Option } = Select;
  const canSave =
    [teaching_staff_ids, field_supervisor, others, courseSpec_id].every(
      Boolean
    ) && addRequestStatus === "idle";

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const users = useSelector((state) => state.user.users);

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewAssessmentResponsibility({ ...values, courseSpec_id })
    );
    if (addNewAssessmentResponsibility.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(
        fetchAssessmentResponsibilities(course && course.id && course.id)
      );
      history.push(
        `/citrine/specification/course/${id}/AssessmentResponsibilities`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      AssessmentResponsibilityUpdate({
        id: idAR,
        modified: {
          ...values,
        },
      })
    );
    if (AssessmentResponsibilityUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(
        fetchAssessmentResponsibilities(course && course.id && course.id)
      );
      history.push(
        `/citrine/specification/course/${id}/FieldExperienceLocations`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchAssessmentResponsibilityById(idAR))
        .then(unwrapResult)
        .then((idAR) => {
          console.log(idAR, "idAR");
          form.setFieldsValue({
            teaching_staff_ids: idAR.teaching_staff_ids,
            field_supervisor: idAR.field_supervisor,
            others: idAR.others,
          });
        });
    }
  }, [idAR]);

  const res = useSelector(
    (state) => state.FieldExperienceLocationFieldExperienceLocation
  );
  const FieldExperienceLocationsStatus = useSelector(
    (state) => state.FieldExperienceLocation.FieldExperienceLocationsStatus
  );
  console.log("res ", res);


  const onChangeTeaching_staff = async (value) => {
    setTeaching_staff_ids(value);
    console.log('value', value)
  }
  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                   

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/FieldExperienceLocations`}
                        style={{ color: "orange" }}
                      >
                        {t("Field Experience Location List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="teaching_staff_ids"
                        label="teaching_staff_ids"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Item"
                          mode="multiple"
                          allowClear
                          onChange={onChangeTeaching_staff}
                        >
                          {staffList &&
                            staffList.map((staff) => (
                              <Option value={staff && staff.id}>
                                {` ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].first_name
                                  } 
                          ${" "}
                          ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].last_name
                                  }`}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="field_supervisor"
                        label="field supervisor "
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        name="others"
                        label="others"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/DatesAndTimesAllocations`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                   
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/FieldExperienceLocations`}
                        style={{ color: "orange" }}
                      >
                        {t("Field Experience Location List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="teaching_staff_ids"
                        label="teaching_staff_ids"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Item"
                          mode="multiple"
                          allowClear
                          onChange={onChangeTeaching_staff}
                        >
                          {staffList &&
                            staffList.map((staff) => (
                              <Option value={staff && staff.id}>
                                {` ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].first_name
                                  } 
                          ${" "}
                          ${staff &&
                                  staff.person_details &&
                                  staff.person_details[0] &&
                                  staff.person_details[0].last_name
                                  }`}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="field_supervisor"
                        label="field supervisor "
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        name="others"
                        label="others"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/CourseQualityEvaluations`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
