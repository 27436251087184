import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { Form, Input, notification, Select } from 'antd'
import Button from 'components/CustomButtons/Button.js'
import { Link, useHistory, useParams } from 'react-router-dom'
import {fetchstepConfig} from "reduxToolkit/features/stepConfig/StepsConfig"
import {
  fetchActionApprovalProcessConfigById,
  updateActionApprovalProcessConfig,
} from 'reduxToolkit/features/ActionApprovalProcess/ActionApprovalProcessSlice'
import {fetchStatesNames} from 'reduxToolkit/features/StateNames/StateNamesSlice'
import {addNewActionApprovalProcessConfig} from 'reduxToolkit/features/ActionApprovalProcess/ActionApprovalProcessSlice'
import { fetchAuthorityLevelsConfig , addNewAuthorityLevelConfig} from 'reduxToolkit/features/AuthorityLevelConfig/AuthorityLevelConfigSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import ErrorModal from 'views/Components/ErrorModal'

const { TextArea } = Input
const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 14,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const EditActionApprovalProcessConfig = () => {
  const { id } = useParams()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { Option } = Select

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const onFinish = async (values) => {
    console.log(values)
    dispatch(updateActionApprovalProcessConfig({ ...values, id }))
      .unwrap()
      .then((res) => {
        handleClose()
        form.resetFields()
        openNotificationWithIcon('success', `updated successfully`)
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }
  const handleClose = () => {
    history.push(`/citrine/configuration/others/actionApprovalProcessPage`)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const authLevelsConfig = useSelector((state) => state.authorityLevelConfig.authorityLevelsConfig)
  const stateNames = useSelector((state) => state.stateNames.statesNames)
  const {stepConfig} = useSelector((state) => state.stepConfig)
  useEffect(()=>{
    dispatch(fetchstepConfig())
  },[])
  console.log(stepConfig)

  useEffect(() => {
    dispatch(fetchStatesNames())
    dispatch(fetchAuthorityLevelsConfig())
    dispatch(fetchActionApprovalProcessConfigById(id))
      .unwrap()
      .then((res) => {
        form.setFieldsValue({
          name: res.name,
          approvalAuthority : res.approvalAuthority.id,
          steps: res?.steps?.map(({ id }) => id)
        })
      })
  }, [])

  // console.log(stepConfig)
  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/configuration/others/ActionApprovalProcessPage`}
              >
                <span>{t('Action Approval Process Page')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span> {t('Update')} </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <br />
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name.',
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>
                
                <Form.Item
                  label={t('Approval Authority')}
                  name="approvalAuthority"
                >
                  <Select
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Approval Authority"
                  >
                    {authLevelsConfig?.map((authLevelConfig) => (
                      <Option key={authLevelConfig.id} value={authLevelConfig.id}>
                        {authLevelConfig.level_details.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('Steps')}
                  name="steps"
               
                >
                  <Select
                  mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Step"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {stepConfig?.map((step) => (
                      <Option key={step.id} value={step.id}>
                        {step?.stateNames?.state_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <br />

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default EditActionApprovalProcessConfig
