import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPersons } from "../../reduxToolkit/features/person/PersonsSlice";
import {
  addNewCourseReport,
  fetchCourseReports,
} from "../../reduxToolkit/features/CourseImprovement/courseImprovementSlice";
//import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Radio } from "antd";
import { Form, Input, Checkbox } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { fetchGroups } from "reduxToolkit/features/group/GroupSlice";
import { fetchCourseById } from "../../reduxToolkit/features/course/CoursesSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput.js";
import { updateGAIndicator } from "../../reduxToolkit/features/GAIndicators/GAIndicatorSlice";
import {
  fetchGraduateAttributeById,
  fetchScoreGraduateAttribute,
} from "../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";
import { fetchScorePlo } from "../../reduxToolkit/features/PLO/PLOsSlice";
import {
  BulbOutlined,
  TagOutlined,
  DownOutlined,
  UpOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
export default function WeightGAIndicatorModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [open, setOpen] = React.useState(false);

  const { Option } = Select;

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");
  const [weight_ga, setWeight_ga] = useState(props.GAIndicator.weight_ga);
  const [graduateAttribute_id, setPlo_id] = useState(props.GAId);
  const [ploIndicators_id, setPloIndicators_id] = useState(props.GAIndicatorID);

  const handleSend = async () => {
    let ScoreObject = {
      id: props.GAId,
    };
    try {
      dispatch(
        updateGAIndicator({
          id: props.GAIndicatorID,
          updatedGAIndicator: { weight_ga, graduateAttribute_id },
        })
      ).then(() => {
        dispatch(fetchScoreGraduateAttribute(ScoreObject)).then(() => {
          dispatch(fetchGraduateAttributeById(props.GAId));
        });
      });
    } catch (err) {
      console.log("Failed to save the clo : ", err);
    } finally {
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <EditOutlined
        style={{ color: "royalblue", fontSize: "17px" }}
        onClick={(event) => {
          handleClickOpen(event);
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          {t("Edit Weight GA Indicators ")}
        </DialogTitle>
        <DialogContent>
          <form>
            <CustomInput
              labelText="{t('weight*')}"
              id="weight_ga"
              value={weight_ga}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please enter a weight ",
                },
              ]}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: false,
                onChange: (event) => {
                  setWeight_ga(event.target.value);
                },
                value: weight_ga,
              }}
            />
          </form>

          <DialogActions>
            <Button onClick={handleClose} color="info">
              {t(" Cancel")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleSend();
              }}
              color="info"
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
