import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  GAReports: [],
  GAReportsStatus: 'idle',
  error: null,
  GAReport: null,
  GAReportStatus: 'idle',
}

export const fetchGAReports = createAsyncThunk(
  'GAReport/fetchGAReports',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/graduateAttributeReport/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      console.log(response.data, 'GAReports')
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchGAReportById = createAsyncThunk(
  'GAReport/fetchGAReportById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/graduateAttributeReport/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const addNewGAReport = createAsyncThunk(
  'GAReport/addNewGAReport',
  async (initialGaReport,{rejectWithValue}) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/graduateAttributeReport/create/`,
        initialGaReport,
        {
          headers: { Authorization: `token ${token}` },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const deleteGAReport = createAsyncThunk(
  'GAReport/deleteGAReport',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(
      BaseUrl + `/graduateAttributeReport/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    console.log(response.data, ' delete response.data')

    return response.data
  },
)

export const UpdateGAReport = createAsyncThunk(
  'GAReport/UpdateGAReport',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(
        BaseUrl + `/graduateAttributeReport/${data.id}/edit/`,
        data.updatedGAReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const AffectCoordinatorToGAReport = createAsyncThunk(
  'gaReport/AffectCoordinatorToGAReport',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/graduateAttributeReport/update/${data.id}/`,
        data.data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //  console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)
const GAReportSlice = createSlice({
  name: ' GAReport',
  initialState,
  extraReducers: {
    // fetchPloReports
    [fetchGAReports.pending]: (state, action) => {
      state.GAReportsStatus = 'loading'
    },
    [fetchGAReports.fulfilled]: (state, action) => {
      state.GAReportsStatus = 'succeeded'
      state.GAReports = action.payload
    },
    [fetchGAReports.rejected]: (state, action) => {
      state.GAReportsStatus = 'failed'
    },
    // fetchPloReportById
    [fetchGAReportById.pending]: (state, action) => {
      state.GAReportStatus = 'loading'
    },
    [fetchGAReportById.fulfilled]: (state, action) => {
      state.GAReportStatus = 'succeeded'
      state.GAReport = action.payload
    },
    [fetchGAReportById.rejected]: (state, action) => {
      state.GAReportStatus = 'failed'
    },
  },
})

export default GAReportSlice.reducer
