import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  dashboardData: [],
  dashboardDataStatus: 'idle',
}

export const fetchDashboardData = createAsyncThunk('dashboardData/fetchDashboardData', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/bpmnProxy/get/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})


const dashboardDataSlice = createSlice({
  name: ' dashboardData',
  initialState,
  extraReducers: {

    [fetchDashboardData.pending]: (state, action) => {
      state.dashboardDataStatus = 'loading'
    },
    [fetchDashboardData.fulfilled]: (state, action) => {
      state.dashboardDataStatus = 'succeeded'
      // Add any fetched posts to the array
      state.dashboardData = action.payload
    },
    [fetchDashboardData.rejected]: (state, action) => {
      state.dashboardDataStatus = 'failed'
    },

  },

})


export default dashboardDataSlice.reducer