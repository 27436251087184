// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { Breadcrumb } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCloId } from "../../../../reduxToolkit/features/CalculNotes/CalculNotesSlice";
import { CalculScoreCourse, fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";








const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);

export default function ClosAssessmentList({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const dispatch = useDispatch();
  // let courseID = props.courseID
  // console.log('courseID', courseID)
  // const id = useSelector((state) => state.course.id)
  const { id, programId } = match.params;
  const course = useSelector((state) => state.course.course);
  const program = useSelector((state) => state.program.program);
  const AssessmentMethod = useSelector(
    (state) => state.course.AssessmentMethod
  );
  const [assessmentMethodsArry, setAssessmentMethodsArry] = useState([]);
  // console.log(id, "id course");
  // console.log(course, "course");
  let arr = [];
  useEffect(() => {
    let ScoreCourse = {
      course_id: id,
      semester_id: course && course.semester_id
    }
    dispatch(fetchCourseById(id));
    dispatch(fetchProgramById(programId));
    dispatch(CalculScoreCourse(ScoreCourse))
  }, [dispatch]);

  const sendID = (id) => {
    dispatch(getCloId(id));
    console.log(id, "id in send clo");
  };
  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/program/${programId}`}>
              {program &&
                program.program_degree_name &&
                program.program_degree_name}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/clos_list/program/${programId}/course/${id}`}>
              {t("Clos List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <Card>
        <CardHeader
          color="info"
          icon
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CardIcon color="primary" style={{ height: "60px" }}>
            <SchoolRounded />
          </CardIcon>

          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={2}>
              <Link to={`/citrine/programs_assessment`}>
                <h4
                  style={{
                    color: "#8F6682",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong>
                    {course &&
                      course.program_detail &&
                      course.program_detail.map((el) => el.code)}
                  </strong>
                </h4>
              </Link>

              <Link to={`/citrine/program/${id}`}>
                <h4
                  style={{
                    color: "#6C7B9D",
                    height: "13px",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  <strong>{course && course.code && course.code}</strong>
                </h4>
              </Link>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Link to={`/citrine/programs_assessment`}>
                <h4
                  style={{ color: "#8F6682", fontSize: "14px", height: "13px" }}
                >
                  <strong>
                    {course &&
                      course.program_detail &&
                      course.program_detail.map((el) => el.program_degree_name)}
                  </strong>
                </h4>
              </Link>

              <Link to={`/citrine/program/${id}`}>
                <h4
                  style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                >
                  <strong>{course && course.title && course.title}</strong>
                </h4>
              </Link>
            </GridItem>

            <GridItem
              xs={12}
              sm={12}
              md={4}
              style={{ padding: "5px", margin: "10px 0px", textAlign: "right" }}
            ></GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
      <GridContainer>
        {course &&
          course.clos &&
          course.clos.map((item, id) => (
            <GridItem
              xs={12}
              sm={6}
              md={4}
              onClick={() => sendID(item.id)}
              component={Link}
              to={`/citrine/result_calcul_Clos/program/${programId}/course/${course.id}/clo/${item.id}`}

            >
              <Card>
                <CardHeader
                  style={{
                    textAlign: "center",
                    border: "1px solid lightgray",
                    height: "200px",
                  }}
                >
                  <h5 style={{ color: "orange" }}>{item.code}</h5>
                  <h4>
                    <p key={id} className={classes2.cardCategory}>

                      {item.title}
                    </p>
                  </h4>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
