import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPersons } from "../../reduxToolkit/features/person/PersonsSlice";
import {
  addNewCourseReport,
  fetchCourseReports,
  StartProcessInstance,
  CourseReportUpdate,
  fetchCourseReportById,
} from "../../reduxToolkit/features/CourseImprovement/courseImprovementSlice";
import {
  GetTask,
  GetUserProfile,
  ClaimTask,
  SubmitClaimedTask,
  CompleteTask,
  GetHistoricTasks,
} from "../../reduxToolkit/features/camunda/CamundaSlice";
//import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Radio } from "antd";
import { Form, Input, Checkbox } from "antd";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { fetchGroups } from "reduxToolkit/features/group/GroupSlice";
import { fetchCourseById } from "../../reduxToolkit/features/course/CoursesSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
export default function CompleteCourseReportModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const ProcessInstance = useSelector(
    (state) => state.CourseReport.ProcessInstance
  );
  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    //console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const [open, setOpen] = React.useState(false);

  const { Option } = Select;

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");

  const handleSend = async () => {
    try {
      console.log(
        props.CourseReport.process_identification,
        "process INSTANCE id"
      );
      console.log(
        props.CourseReport,
        "props.CourseReport"
      );
      dispatch(GetTask(props.CourseReport.process_identification))
        .then(unwrapResult)
        .then((task) => {
          console.log(task, "task");
          dispatch(GetUserProfile())
            .then(unwrapResult)
            .then((user) => {
              let task_Object = { userId: user.id, task_id: task && task[0] && task[0].id };
              console.log(task_Object, "task_Object");
              dispatch(ClaimTask(task_Object))
                .then(unwrapResult)
                .then(() => {
                  dispatch(SubmitClaimedTask(task_Object))
                    .then(unwrapResult)
                    .then(() => {
                      dispatch(CompleteTask(task_Object))
                        .then(unwrapResult)
                        .then(() => {
                          props.submitComplete();
                        })
                        .then(() => {
                          dispatch(
                            GetHistoricTasks(
                              props.CourseReport.process_identification
                            )
                          );
                        });
                    });
                });
            });
        });
    } catch (err) {
      console.log("Failed to delete  the delete courseReports : ", err);
    } finally {
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        color="primary"
        size="sm"
        style={{
          border: "1px solid #AC79B0",
          backgroundColor: "#AC79B0",
          fontSize: "10px",
          height: "30px",
          minWidth: "30px",
          marginTop: "3px",
          marginBottom: "5px",
        }}
        onClick={(event) => (
          handleClickOpen(event),
          // props.InformCoodinator(),
          props.handleDropDownButtonClose()
        )}
      >
        <strong style={{ color: "white", fontSize: "11px" }}>
          {t("Complete")}
        </strong>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"> Complete Task</DialogTitle>
        <DialogContent>
          <h4> {t("Are you sure you want to Complete this Task ?")}</h4>
          <DialogActions>
            <Button onClick={handleClose} color="info">
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleSend();
              }}
              color="primary"
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}







