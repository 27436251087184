import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  keyWords: [],
  keyWordsStatus: 'idle',
  keyWordStatus: 'idle',
  error: null,
  keyWord:null,
  id:null
}


export const fetchKeyWords = createAsyncThunk('keyWordSpec/fetchKeyWords', async () => {
  const token=localStorage.getItem('token')
  const response = await axios.get(BaseUrl+`/keyWordSpec/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchKeyWordById = createAsyncThunk('keyWordSpec/fetchKeyWordById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/keyWordSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     console.log(response.data,'response.data')

    return response.data
  })

  export const addNewKeyWords = createAsyncThunk(
    'keyWordSpec/addNewKeyWord',
    async (initialKeyWord) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/keyWordSpec/create/`,initialKeyWord ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
    //  console.log(response.data)
      let keywords = response.data
    //  console.log('keywords', keywords)
      return keywords
    }
  )
  export const keyWordUpdate = createAsyncThunk(
    'keyWordSpec/keyWordUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/keyWordSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedKeyWord')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the keyWord: ', error)

}
    }
  )
  export const deleteKeyWord = createAsyncThunk('keyWordSpec/deleteKeyWord', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/keyWordSpec/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     

    return response.data
  })
const  keywordSlice = createSlice({
  name: 'keyWordSpec',
  initialState,
  extraReducers: {
  
    [fetchKeyWords.pending]: (state, action) => {
        state.keyWordsStatus = 'loading'
      },
      [fetchKeyWords.fulfilled]: (state, action) => {
        state.keyWordsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.keyWords = action.payload
      },
      [fetchKeyWords.rejected]: (state, action) => {
        state.keyWordsStatus = 'failed'
        state.error = action.payload
      },

      [fetchKeyWordById.pending]: (state, action) => {
        state.keyWordStatus = 'loading'
      },
      [fetchKeyWordById.fulfilled]: (state, action) => {
        state.keyWordStatus = 'succeeded'

        // Add any fetched posts to the array
        state.keyWord = action.payload
      },
      [fetchKeyWordById.rejected]: (state, action) => {
        state.keyWordStatus = 'failed'
        state.error = action.payload
      },
    [addNewKeyWords.fulfilled]: (state, action) => {
      state.keyWords.push(action.payload)
    },
    [keyWordUpdate.fulfilled]: (state, action) => {
      console.log(action) 
    },
    [deleteKeyWord.fulfilled]: (state, action) => {
     console.log(action)
    },
  },

  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
    keyWordAdded: {
      reducer(state, action) {
        state. keyWords.push(action.payload)
      },
      prepare(keyWords) {
        // omit prepare logic
      }
    },
    keyWordUpdated(state, action) {
      const { id, keyWords} = action.payload
      const existingKeyWord = state.keyWords.find(keyWord => keyWord.id === id)
      if (existingKeyWord) {
        existingKeyWord.keyWords = keyWords
     
      }
    }
  }
})

export const { keyWordAdded, keyWordUpdated, getId, keywords } = keywordSlice.actions

export default keywordSlice.reducer

export const selectAllKeyWords = state => state.keyWordSpec.keyWords