import React from 'react';

import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { Tabs } from "antd";
import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";
// import "./improvement.css"


import Card from "components/Card/Card.js";

import {
  PicCenterOutlined
} from "@ant-design/icons";
import {
  primaryColor,
  warningColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import IdentificationComp from "./IdentificationComp.jsx";

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

export default function Identification({ CourseReport }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  console.log(CourseReport)
  const callback = (key) => {
    console.log(key);
  };

  return (
    <div>
      <Card>
        <GridContainer xs={12} sm={12} md={12}>
          <p
            style={{
              paddingLeft: "10px",
              color: warningColor[0],
              fontSize: "14px",
            }}
          >
            <strong style={{ color: warningColor[0] }}>
              <PicCenterOutlined style={{ color: warningColor[0] }} />
              Identification
            </strong>
          </p>
          <GridContainer
            xs={12}
            sm={12}
            md={12}
            style={{ borderRadius: "6px", backgroundColor: whiteColor }}
          >
            <GridItem xs={12} sm={12} md={5}>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}> Course : </span>
                </strong>
                {CourseReport?.course?.map((item) => {

                  return (
                    <React.Fragment key={item.id}>
                      <IdentificationComp item={item} />
                    </React.Fragment>
                  );
                })}
              </div>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>Program : </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport &&
                    CourseReport.course &&
                    CourseReport.course[0] &&
                    CourseReport.course[0].program &&
                    CourseReport.course[0].program.program_degree_name}
                </span>
              </div>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>Department : </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport?.course[0]?.department?.map((item,index) => {
                    return (
                      <React.Fragment key={item.id}>
                        {index > 0 && ', '}
                        {item?.name}
                      </React.Fragment>
                    );
                  })}
                </span>
              </div>

              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>College : </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>

                     {CourseReport?.course[0]?.college_details?.map((item,index) => {
                    return (
                      <React.Fragment key={item.id}>
                        {index > 0 && ', '}
                        {item?.name}
                      </React.Fragment>
                    );
                  })}
                </span>
              </div>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>Institution : </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport &&
                    CourseReport.course &&
                    CourseReport.course[0] &&
                    CourseReport.course[0].institution &&
                    CourseReport.course[0].institution.name}
                </span>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>Type : </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport &&
                    CourseReport.course &&
                    CourseReport.course[0] &&
                    CourseReport.course[0].type &&
                    CourseReport.course[0].type}
                </span>
              </div>

              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>
                    Academic Year & Semester :{" "}
                  </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport &&
                    CourseReport.course &&
                    CourseReport.course[0] &&
                    CourseReport.course[0].academic_year &&
                    CourseReport.course[0].academic_year.name}
                  /
                  {CourseReport &&
                    CourseReport.course &&
                    CourseReport.course[0] &&
                    CourseReport.course[0].semester &&
                    CourseReport.course[0].semester.name}
                </span>
              </div>

              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>
                    Number of Students Starting the course :{" "}
                  </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport?.course[0]?.number_of_students}
                </span>
              </div>

              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>
                    Number of Students Completing the course :
                  </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport &&
                    CourseReport.course &&
                    CourseReport.course[0] &&
                    CourseReport.course[0]
                      .number_of_students_starting_the_course}
                </span>
              </div>
              <div>
                <strong>
                  <span style={{ color: primaryColor[0] }}>
                    Supervisor/Instructor :{" "}
                  </span>
                </strong>
                <span style={{ color: primaryColor[0] }}>
                  {CourseReport &&
                    CourseReport.course &&
                    CourseReport.course[0] &&
                    CourseReport.course[0].instructor_details &&
                    CourseReport.course[0].instructor_details[0] &&
                    CourseReport.course[0].instructor_details[0].username}
                </span>
              </div>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </Card>
    </div>
  );
}
