import React from "react";

import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";
// import "./improvement.css"

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import teacher from "../../../../assets/img/teacher.png";
import { warningColor, whiteColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { UserOutlined} from "@ant-design/icons";
import EditGeneralInformationModal from "./ProfessionalSummary/EditGeneralInformationModal"
import EditAvatarModal from "./EditAvatarModal.js";
import { MediaBaseUrl } from "assets/utils/accessToken";
export default function Profile({ person }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <GridContainer xs={12} sm={12} md={12}>
      <p style={{ paddingLeft: "10px", color: warningColor[0], fontSize: "14px" }}>
        <strong style={{ color: warningColor[0] }}>
          <UserOutlined style={{ color: warningColor[0] }} />
          General Information
        </strong>
      </p>
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        style={{ borderRadius: "6px", backgroundColor: whiteColor }}
      >
        <GridItem xs={12} sm={12} md={2} >
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={10} >
              <Button class="btn btn-light" onClick={handleClickOpen}>
              {person && person.person_details && person.person_details[0].avatar === "" ? (
                <img src={teacher} width="100%" height="100%" />
              ) : (
                <img src={MediaBaseUrl+`${person && person.person_details && person.person_details[0].avatar}`} width="100%" height="100%" />
              )}
              </Button>
            {/* <GridItem xs={12} sm={12} md={2} style={{ textAlign: "end" }}> */}
              <EditAvatarModal person_id={person && person.person_details && person.person_details[0].id} person={person} open={open} handleClose={handleClose}/>
            {/* </GridItem> */}
            </GridItem>
            
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={3}>
          <div>
            <strong>
              {person && person.person_details && person.person_details[0].first_name} {person && person.person_details && person.person_details[0].last_name}
            </strong>
          </div>
          <div>
            <strong> Category : {person && person.person_details && person.person_details[0].category} </strong>
          </div>
          <div>
            <strong> Program : {person && person.program}</strong>
          </div>
          <div>
            <strong> Rank : {person && person.rank}</strong>
          </div>

        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <div>
            <strong> Type Of Academic Appointment : {person && person.type_of_academic_appointment}</strong>
          </div>
          <div>
            <strong> FT/PT : {person && person.full_time}</strong>
          </div>
          <div>
            <strong> University : {person && person.university} </strong>
          </div>
          <div>
            <strong> Department :  {person && person.department} </strong>
          </div>
          <div>
            <strong> College :  {person && person.college} </strong>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <div>
            <strong> Google Scolar ID : {person && person.google_scholar_id} </strong>
          </div>
          <div>
            <strong> Scopus ID :  {person && person.scopus_id} </strong>
          </div>
          <div>
            <strong> Web of Science ID :  {person && person.web_of_science_id} </strong>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={1} style={{ textAlign: "end" }}>
          <EditGeneralInformationModal person_id={person && person.id} person={person} />
        </GridItem>
      </GridContainer>

    </GridContainer>
  );
}
