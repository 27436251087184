import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import {fetchDashboardData} from'../../../reduxToolkit/features/Dashboard/DashboardsSlice'
import PieChart, {
  Legend,
  Series,
  Tooltip,
  Format,
  Label,
  Size,
  Connector,
  Export,
  Font
} from 'devextreme-react/pie-chart';
import { infoColor, primaryColor, successColor, warningColor, roseColor } from "assets/jss/material-dashboard-pro-react";
import { getCurrentLanguage } from '../../../reduxToolkit/features/language/LanguageSlice'



const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
}).format;

const calculateTotal = (pieChart) => {
  return formatNumber(pieChart
    .getAllSeries()[0]
    .getVisiblePoints()
    .reduce((s, p) => s + p.originalValue, 0));
}

const CenterTemplate = (pieChart) => {
  const { model } = pieChart&&pieChart.getAllSeries().length&&pieChart.getAllSeries()[0].getVisiblePoints().length&&pieChart.getAllSeries()[0].getVisiblePoints()[0].data;
  return (
    <svg>
      {/* <image href={getImagePath(model)} x="70" y="58" width="60" height="40" /> */}
      <text textAnchor="middle" x="70" y="100" style={{ fontSize: 18, fill: '#494949' }}>
        <tspan x="100" style={{ fontWeight: 600 }}>{
          calculateTotal(pieChart)
        }</tspan>
        <tspan x="100" dy="20px">{model}</tspan>
      </text>
    </svg>
  );
}


export default function CourseReportsDoughnutchart() {
  const dispatch = useDispatch();


  const customizeLabel = (e) => {
    return `${e.valueText}`;
  }
  const customizeTooltip = (arg) => {
    return {
      text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
    };
  }
  const [dashboardData, setDashboardData] = useState([]);
  const [row, setRow] = useState('');
  const [column, setColumn] = useState('');

  useEffect(() => {
    dispatch(fetchDashboardData()).then(unwrapResult)
    .then((originalPromiseResult) => {
      const filteredData = originalPromiseResult.find(chart => chart.column === "course" && chart.row === "improvement");
      console.log("filteredData", filteredData&&filteredData.data)
      setDashboardData(filteredData)    
    setRow(originalPromiseResult)
    setColumn(originalPromiseResult)
    })

  }, [dispatch]);

  useEffect(() => {
    // const item = localStorage.getItem("i18nextLng");
    dispatch(getCurrentLanguage());
  }, localStorage.getItem("i18nextLng"));
  const currentLanguage = useSelector((state) => state.language.currentLanguage);
  const languages = useSelector((state) => state.language.languages);
  const position =
  currentLanguage && languages && languages[currentLanguage] && languages[currentLanguage].rtl
      ? "left"
      : "right"

  return (
    <div className="pies-container">
      <PieChart
        id="pie-chart"
        dataSource={dashboardData&&dashboardData.data}
        innerRadius={0.7}
        centerRender={CenterTemplate}
        type="doughnut"
        palette={[`${primaryColor[0]}`, `${successColor[0]}`, `${roseColor[0]}`,  `${infoColor[0]}`]}
        title="Courses' Improvement Process"
        diameter={0.7}>

        {/* <AdaptiveLayout
          height={300}
          width={400}/> */}
          <Series argumentField="partition" valueField="total">
          <Label
            visible={true}
            customizeText={customizeLabel}>
            <Font size={10} />
            <Connector visible={true} width={0.5} />
          </Label>
        </Series>
        <Legend
          margin={0}
          horizontalAlignment={position}
          verticalAlignment="bottom" />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
          {/* <Format type="millions" /> */}
        </Tooltip>
        <Size width={550} height={300} />
      </PieChart>
    </div>
  );
}


