import React, { useEffect, useState } from 'react'

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
} from '@ant-design/icons'
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Breadcrumb,
  Form,
  Input,
  notification,
  Select,
  Space,
  InputNumber,
} from 'antd'

import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import {
  fetchAccessControlModels,
  fetchModels,
  fetchModelById,
  addAccessControlModel,
} from 'reduxToolkit/features/model/ModelSlice'
import ErrorModal from '../../Components/ErrorModal'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import { grayColor, infoColor } from 'assets/jss/material-dashboard-pro-react'

const useStyles = makeStyles(styles)

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 20,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
}
const useCardStyles = makeStyles(cardStyles)

const AddAccessControl = ({ disabled, modelSelected }) => {
  const cardClasses = useCardStyles()
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [securityGroup, setSecurityGroup] = useState([])
  const [ManagerGroup, setManagerGroup] = useState([])
  const [ReaderGroup, setReaderGroup] = useState([])
  const [open, setOpen] = React.useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)

  const [handledError, setHandledError] = useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onFinish = async (values) => {
    const readF =
      values?.read == undefined || values?.read.length == 0
        ? {}
        : { fields: values?.read }
    const updateF =
      values?.update == undefined || values?.update.length == 0
        ? {}
        : { fields: values?.update }
    const { read, update, ...rest } = values
    console.log({ ...rest, model_id: id, read: readF, update: updateF })
    console.log(readF)

    dispatch(
      addAccessControlModel({
        ...rest,
        model_id: id,
        read: JSON.stringify(readF || {}),
        update: JSON.stringify(updateF || {}),
      }),
    )
      .unwrap()
      .then((originalPromiseResult) => {
        history.push('/citrine/models')
        openNotificationWithIcon('success', `Added`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const { Option } = Select
  const handleClose = () => {
    setOpen(false)
  }
  const handleClickOpen = () => {
    setOpen(true)
    //props.handleCloseAnchor();
  }
  const groups = useSelector((state) => state.userGroup.userGroups)
  const models = useSelector((state) => state.model.models)
  const model = useSelector((state) => state.model.model)
  const listMap = models
    ?.find((model) => model.id == id)
    ?.fields_models?.map((el) => (
      <Option key={el} value={el}>
        {el}
      </Option>
    ))
  useEffect(() => {
    dispatch(fetchModelById(id))
      .then(unwrapResult)
      .then((model) => {
        console.log(model)
        form.setFieldsValue({
          read:
            model?.modelsPermissions?.read != undefined
              ? model?.modelsPermissions?.read?.fields
              : [],
          update:
            model?.modelsPermissions?.update != undefined
              ? model?.modelsPermissions?.update?.fields
              : [],
          security_group_ids: model?.security_group_ids,
          reader_group_ids: model?.reader_group_ids,
          manager_group_ids: model?.manager_group_ids,
        })
        setSecurityGroup(model.security_group_ids)
        setReaderGroup(model.reader_group_ids)
        setManagerGroup(model.manager_group_ids)
      })
  }, [])
  const handleSecurityGroup = (e) => {
    setSecurityGroup(e)
  }

  return (
    <div>
      <GridContainer>
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/models/`}>{t('Models')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: 'orange' }}>
              {"Model : "}{model?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardHeader>
            {' '}
            <center>
              <h3 className={cardClasses.cardProductTitle}>
                <strong style={{ color: infoColor[0] }}>
                  {t('Add Access Control')}
                </strong>
              </h3>
            </center>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={10}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  label="Object"
                  name="model_id"
                  rules={[
                    {
                      message: 'Please select the Model',
                    },
                  ]}
                >
                  <Select
                    disabled
                    style={{
                      width: '80%',
                    }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={model?.name}
                    //showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  ></Select>
                </Form.Item>

                <Form.Item
                  label="Security group"
                  name="security_group_ids"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the Security group',
                    },
                  ]}
                  initialValue={securityGroup}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '80%' }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Add a security group"
                    showSearch
                    value={securityGroup}
                    onChange={handleSecurityGroup}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {groups &&
                      groups.map((group, key) => (
                        <Option key={key} value={group.id}>
                          {group.name}
                        </Option>
                      ))}{' '}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Manager group"
                  name="manager_group_ids"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the Manager group',
                    },
                  ]}
                  initialValue={ManagerGroup}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '80%' }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Add a manager group"
                    showSearch
                    // value={ManagerGroup}

                    onChange={(e) => setManagerGroup(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {securityGroup.map(
                      (m, key) =>
                        groups &&
                        groups.map((item) =>
                          item.id == m ? (
                            <Select.Option key={key} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ) : (
                            <></>
                          ),
                        ),
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Reader group"
                  name="reader_group_ids"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the Reader group',
                    },
                  ]}
                  initialValue={ReaderGroup}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '80%' }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Add a reader group"
                    showSearch
                    //value={ReaderGroup}

                    onChange={(e) => setReaderGroup(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {securityGroup.map(
                      (m, key) =>
                        groups &&
                        groups.map((item) =>
                          item.id == m ? (
                            <Select.Option key={key} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ) : (
                            <></>
                          ),
                        ),
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Read Fields"
                  name="read"
                  rules={[
                    {
                      required: false,
                      message: 'Please select the Read Fields',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '80%' }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Read Fields"
                    showSearch
                    //onChange={(e) => setReadField(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {listMap}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Update Fields"
                  name="update"
                  rules={[
                    {
                      required: false,
                      message: 'Please select the Update Fields',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '80%' }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder="Update Fields"
                    showSearch
                    //onChange={(e) => setUpdateField(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {listMap}
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: '20px' }}
                    color="primary"
                    onClick={() => history.push('/citrine/models')}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default AddAccessControl
