import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Input, message, notification, InputNumber } from "antd";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Components/ErrorModal";
import {
    fetchActions,
    updateActionRecommendation,
  } from "../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import { Select } from 'antd';
import MenuItem from '@material-ui/core/MenuItem';
import {
  primaryColor
 
} from "assets/jss/material-dashboard-pro-react.js";
const { Option } = Select;

export default function Cancel(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onFinish = async (values) => {

const updatedActionRecommendation={...values,state_id:5}
    console.log("updatedActionRecommendation: ", updatedActionRecommendation);

    const resultAction = await dispatch(
      updateActionRecommendation({
        id: props.id,
        updatedActionRecommendation: updatedActionRecommendation,
      })
    );

    if (updateActionRecommendation.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Updated`);
      props.fetchAllActions();
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };



  return (
    <div>
        <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
          props.handleClose();
        }}
        style={{ color: "#AC79B0" }}
      >
        {t("Cancel")}
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
        {t("Cancel the action")}
        </DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            form={form}

          >
            <GridContainer>
              {/* <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Assignee")}
                  name="assign_to_id"
                  rules={[
                    {
                      required: true,
                      message: "Please input the program's code",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please select a user"
                    style={{ width: 180 }}
                    dropdownStyle={{ zIndex: 10000 }}

                  >
                    {props.allusers.map((user) => {
                        return (
                          <Option key={user.id} value={user.id}>
                            {user.username}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </GridItem> */}
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Reasons")}
                  name="cancel_reasons"
                >
                  <Input.TextArea />
                </Form.Item>
              </GridItem>
            </GridContainer>

            {/* </FormControl> */}
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="primary">
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
