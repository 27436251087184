import "@lourenci/react-kanban/dist/styles.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs, Table } from "antd";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticulationMatrix, fetchQuestionArticulationMatrix } from "../../../../../reduxToolkit/features/CLO/CloSlice";
import useMatrixTable from "../../../../Components/useMatrixTable";
import "../../improvement.css";

const { TabPane } = Tabs;
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function CourseArticulationMatrix(props) {

  const [records, setRecords] = [];

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const matrix = useSelector((state) => state.clo.ArticulationMatrix);
  const QuestionArticulationMatrix = useSelector((state) => state.clo.QuestionArticulationMatrix);
  const AssessmentMethodsList = useSelector((state) => state.AssessmentMethods.assessmentMethods);

  const [TeoryAssessmentMethods, setTeoryAssessmentMethods] = useState([]);
  const [LabAssessmentMethods, setLabAssessmentMethods] = useState([]);
  const [articulationMatrix, setArticulationMatrix] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tab, setTab] = useState([]);
  const [filterFn, setFilterFn] = useState({fn: (items) => {return items;}});
  const [columns1, setColumns1] = useState([
    {
      title: "code Level",
      dataIndex: "code_level",
      key: "code_level",
      width: 100,
    },
    {
      title: "CLO",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
  ]);

  useEffect(() => {
    let obj = {
      course_id: props.idCourse,
    };
    dispatch(fetchArticulationMatrix(obj))
      .then(unwrapResult)
      .then((matrix) => {
        setArticulationMatrix([...matrix]);
      });
  }, []);

  useEffect(() => {
    let arr = []
    let obj = {
      course_id: props.idCourse,
    };
    dispatch(fetchQuestionArticulationMatrix(obj))
      .then(unwrapResult)
      .then((QuestionArticulationMatrix) => {
        let array = [...columns1, ...QuestionArticulationMatrix]
        setColumns(array)
      });
  }, []);

  const headCells = [
    { id: "code", label: "code" },
    { id: "Clo", label: "CLO" },
  ];
  const TheoryheadCells = TeoryAssessmentMethods;
  const LabheadCells = LabAssessmentMethods;
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useMatrixTable(
    records,
    headCells,
    TheoryheadCells,
    LabheadCells,
    filterFn
  );
  return (
    <div>
      <Table
        columns={columns}
        dataSource={articulationMatrix}
        bordered={true}
        size="middle"
      />
    </div>
  );
}
