import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { DatePicker, Form, Input, notification, Select, Table } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { fetchAuthorities } from "../../../../reduxToolkit/features/AccreditationAuthority/AuthoritySlice";
import { addNewAccreditationDate, deleteAccreditationDate, fetchAccreditationDatesByProgramId } from "../../../../reduxToolkit/features/AccreditationDates/AccreditationDatesSlice";
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import "./style.css";
import isDate2GreaterThanDate1 from "assets/utils/helperFunction"






// import ProgramCardDropdownButton from "../Components/ProgramCardDropdownButton"
const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function OldAccreditationDates(props) {

    const { t } = useTranslation();
    let { programId } = useParams();

    const dispatch = useDispatch()

    const classes = useStyles();
    const cardClasses = useCardStyles();


    const fetchAllAccreditationDates = async () => {

        try {
            const resultAction = await dispatch(fetchAccreditationDatesByProgramId(programId)).unwrap()

            console.log('success')
        } catch (err) {
            setHandledError(err)
            handleOpenErrorModal()
        }
    }


    useEffect(() => {
        fetchAllAccreditationDates()
    }, [dispatch])

    const data = useSelector((state) => state.accreditationDate.accreditationDates)

    const columns = [
        {
            title: 'Entered at',
            dataIndex: 'date_of_input',
            key: 'date_of_input',
        },
        {
            title: 'Left at',
            dataIndex: 'date_of_output',
            key: 'date_of_output',
        },
        {
            title: 'Accreditation date',
            dataIndex: 'accreditation_dates',
            key: 'accreditation_dates',
        },
        {
            title: 'Authority',
            dataIndex: 'authority',
            key: 'authority',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => <Button onClick={() => { handleClickOpen(record.id) }} color="primary" simple>Delete</Button>,
        },

    ];


    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const [open, setOpen] = React.useState(false);
    const [toDeleteAccreditationDateId, setToDeleteAccreditationDateId] = React.useState(null);

    const handleClickOpen = (id) => {
        setToDeleteAccreditationDateId(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleDeleteAccreditationDate = async (id) => {
        dispatch(deleteAccreditationDate(id))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                // handle result here
                console.log('originalPromiseResult', originalPromiseResult)
                dispatch(fetchAccreditationDatesByProgramId(programId))
                openNotificationWithIcon('success', `Deleted`)

            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
                setHandledError(rejectedValueOrSerializedError)
                handleOpenErrorModal()
            })
    }



    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };

    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <AddAccreditationDateModal />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Table columns={columns} dataSource={data && data} />
                <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
                <SuperGeneralDeleteModal
                    delete_Element={handleDeleteAccreditationDate}
                    Element_ID={toDeleteAccreditationDateId}
                    open={open}
                    handleClose={handleClose}

                />

            </GridItem>
        </GridContainer>



    );
}


function AddAccreditationDateModal() {
    let { programId } = useParams();

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        dispatch(fetchAuthorities())

    }, [])

    const authorities = useSelector((state) => state.authority.authorities)

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };
    const { Option } = Select;


    // const [kpiConfig_id, setkpiConfig_id] = React.useState([]);

    // function handleChange(value) {
    //     console.log("value", value);
    //     setkpiConfig_id(value)
    // }
    const [status, setStatus] = useState('')
    const [comment, setComment] = useState('')
    const [authority_id, setAuthority_id] = useState(null)

    const [accreditation_dates, setAccreditation_dates] = useState('')
    const [accreditationDateValidationStatus, setAccreditationDateValidationStatus] = useState('')
    function onAccreditationDateChange(value, dateString) {
        setAccreditation_dates(dateString)
    }

    const [date_of_input, setDate_of_input] = useState('')
    function onInputDateChange(value, dateString) {
        setDate_of_input(dateString)
    }

    const [date_of_output, setDate_of_output] = useState('')
    const [dateOfOutputValidationStatus, setDateOfOutputValidationStatus] = useState('')

    function onOutputDateChange(value, dateString) {
        setDate_of_output(dateString)
    }

    useEffect(() => {
        if (date_of_input && date_of_output) {
            isDate2GreaterThanDate1(date_of_input, date_of_output) ? setDateOfOutputValidationStatus("") : setDateOfOutputValidationStatus('error')
        }

        if (date_of_input && date_of_output && accreditation_dates) {
            if (isDate2GreaterThanDate1(date_of_input, accreditation_dates) && isDate2GreaterThanDate1(accreditation_dates, date_of_output)) { setAccreditationDateValidationStatus("") }
            else {
                setAccreditationDateValidationStatus("error")
            }
        }

    }, [date_of_input, date_of_output, accreditation_dates])


    const programSpec_id = programId
    const onFinish = async () => {
        if (isDate2GreaterThanDate1(date_of_input, date_of_output) && isDate2GreaterThanDate1(date_of_input, accreditation_dates) && isDate2GreaterThanDate1(accreditation_dates, date_of_output)) {
            const resultAction = await dispatch(addNewAccreditationDate({ programSpec_id, date_of_input, date_of_output, accreditation_dates, authority_id, status, comment }))

            if (addNewAccreditationDate.fulfilled.match(resultAction)) {
                // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk

                dispatch(fetchAccreditationDatesByProgramId(programId))

                // history.push(`/citrine/programSpec/${programSpec_id}/KPIs`);
                openNotificationWithIcon('success', `Affected successfully`)
                handleClose()


            } else {
                if (resultAction.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                    console.log('resultAction.payload from component', resultAction.payload)
                    setHandledError(resultAction.payload)
                    handleOpenErrorModal()
                    handleClose()
                } else {
                    console.log('resultAction.error  from component', resultAction.error)
                    setHandledError(resultAction.error)
                    handleOpenErrorModal()
                    handleClose()
                }
            }
        }
    }


    return (
        <div>

            <Button color='primary'
                onClick={(event) => {
                    handleClickOpen(event)
                }}>

                Add
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}>
                <DialogTitle id="form-dialog-title">{t('Add Accreditation Date')}</DialogTitle>
                <DialogContent>

                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        scrollToFirstError
                    >

                        <GridContainer>

                            <GridItem xs={4} sm={4} md={4}>
                                <Form.Item
                                    label={t('Entered at')}
                                    name="date_of_input"
                                >
                                    <DatePicker onChange={onInputDateChange} style={{ zIndex: 100000 }} />
                                </Form.Item>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Form.Item
                                    label={t('Left at')}
                                    name="date_of_output"
                                    validateStatus={dateOfOutputValidationStatus}
                                    help="This date should be greater than the previous date"
                                >
                                    <DatePicker onChange={onOutputDateChange} />
                                </Form.Item>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Form.Item
                                    label={t('Accreditation date')}
                                    name="accreditation_dates"

                                    validateStatus={accreditationDateValidationStatus}
                                    help="This date should be between the two previous dates"
                                >
                                    <DatePicker onChange={onAccreditationDateChange} />
                                </Form.Item>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("Authority")}
                                    name="authority_id"
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ zIndex: 5 }}
                                        dropdownStyle={{ zIndex: 2000 }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={value => {
                                            setAuthority_id(value);
                                        }}
                                    >
                                        {authorities && authorities.length && authorities.map((authority) => (
                                            <Option key={authority.id} value={authority.id}>
                                                {authority.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("Status")}
                                    name="status"
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        id="status"
                                        value={status}
                                        onChange={event => {
                                            setStatus(event.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("Comments")}
                                    name="comment"
                                    rules={[{ required: true }]}
                                >
                                    <Input.TextArea
                                        id="comment"
                                        value={comment}
                                        onChange={event => {
                                            setComment(event.target.value);
                                        }}
                                        autoSize={true}
                                    />
                                </Form.Item>
                            </GridItem>
                        </GridContainer>


                        {/* </FormControl> */}
                        <GridContainer justify="flex-end">
                            <GridItem>

                                <Form.Item>
                                    <GridContainer>
                                        <GridItem>
                                            <Button color="primary" onClick={() => { handleClose() }}>
                                                {t('Cancel')}
                                            </Button>
                                        </GridItem>
                                        <GridItem>
                                            <Button type="submit" color="primary">
                                                {t('Save')}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Form.Item>

                            </GridItem>
                        </GridContainer>

                    </Form>
                </DialogContent>
            </Dialog>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
        </div>
    );
}