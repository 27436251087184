import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  statesNames: [],
  statesNamesStatus: 'idle',
  stateNames: null,
  statesNameStatus: 'idle',
  error:null
}

export const fetchStatesNames = createAsyncThunk(
  'state/fetchStatesNames',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + '/stateNames/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchStateNamesById = createAsyncThunk(
  'state/fetchStateNamesById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/stateNames/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewStateNames = createAsyncThunk(
  'state/addNewStateNames',
  async (authority, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.post(
        BaseUrl + `/stateNames/create/`,
        authority,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteStateNames = createAsyncThunk(
  'state/deleteNewStateNames',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.delete(
        BaseUrl + `/stateNames/${id}/delete/`,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateStateNames = createAsyncThunk(
  'state/updateNewStateNames',
  async (state, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.put(
        BaseUrl + `/stateNames/${state.id}/edit/`,
        state,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const statesNamesSlice = createSlice({
  name: 'stateNames',
  initialState,
  extraReducers: {
    [fetchStatesNames.pending]: (state, action) => {
      state.statesNamesStatus = 'loading'
    },
    [fetchStatesNames.fulfilled]: (state, action) => {
      state.statesNamesStatus = 'succeeded'
      state.statesNames = action.payload
    },
    [fetchStatesNames.rejected]: (state, action) => {
      state.statesNamesStatus = 'rejected'
      state.statesNames = action.payload
    },

    [fetchStateNamesById.pending]: (state, action) => {
      state.statesNameStatus = 'loading'
    },
    [fetchStateNamesById.fulfilled]: (state, action) => {
      state.statesNameStatus = 'succeeded'
      state.stateNames = action.payload
    },
    [fetchStateNamesById.rejected]: (state, action) => {
      state.statesNameStatus = 'rejected'
      state.statesNames = action.payload
    },
  },
})

export default statesNamesSlice.reducer
