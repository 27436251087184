import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchBranches } from '../../../../reduxToolkit/features/branche/BranchesSlice';
import DropDownBrancheButton from "../../../Components/DropDownBrancheButton";


const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);


const BrancheExcerpt = ({ branche }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();


  return (

    <GridItem xs={12} sm={12} md={4} key={branche.id} >
      <Card style={{ /*maxWidth: "380px", minWidth: "380px",*/ maxHeight: "180px", minHeight: "180px" }} cardHover >
        <CardHeader>
          <GridContainer direction="row"
            justifyContent="space-between"
            alignItems="baseline">
            <GridItem xs={10} sm={10} md={10}>
              {/* <Link to={`/citrine/programSpec/${program.id}`}> */}
              <h4 className={cardClasses.cardProductTitle}>
                {branche.name}
              </h4>
              {/* </Link> */}
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <DropDownBrancheButton id={branche.id} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        {/* <CardBody smallCardBody>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}> Departments:
            {program.department_detail && program.department_detail.map((department) => {
              return <div style={{ fontWeight: "normal" }}> {department.name} </div>

            }
            )}
          </div>


        </CardBody> */}
        <CardFooter>
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="center">
            {/* <GridItem> <span style={{ color: (program.state) === "Draft" ? grayColor[1] : "#FFCB3B" }}>{program.state}</span> </GridItem> */}
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>

  )
}

export default function Branches() {
  const [search, setSearch] = useState("")
  const { t } = useTranslation();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)


  const dispatch = useDispatch()

  const branchesStatus = useSelector(state => state.branches.branchesStatus)
  useEffect(() => {
    if (branchesStatus === 'idle') {
      dispatch(fetchBranches())
    }
  }, [
    branchesStatus,
    dispatch])

  const branches = useSelector(state => state.branches.branches)
  const error = useSelector(state => state.branches.error)
  console.log(branches, "branches")
  let content

  const searchButton =
    navBarClasses.top +
    " " +
    navBarClasses.searchButton

  if (branchesStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (branchesStatus === 'succeeded') {
    content = branches && branches.filter((branche) => {
      if (search == '') {
        return branche
      } else if (branche.name.toLowerCase().includes(search.toLowerCase())) {
        return branche
      }
    }).map(branche => (
      <BrancheExcerpt key={branche.id} branche={branche} />
    ))
  } else if (branchesStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer

        // md={12}
        // xs={12} sm={12}
        direction="row"
        // style={{ width:300 ,height:150}}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>

          <Breadcrumb separator=">">
            
            <Breadcrumb.Item>
              <Link to={`/citrine/organizations`}>
                <span > {t('Organizations')} </span>
              </Link>
            </Breadcrumb.Item>
            
            <Breadcrumb.Item>
              <Link to={`/citrine/organizations/branches`}>
                <span > {t('Branches')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>


      <GridContainer
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Link to="/citrine/organizations/branches/create/">
            <Button color="primary">
              {t('Add')}
            </Button>
          </Link>
        </GridItem>

      </GridContainer>

      <GridContainer >

        {content}
      </GridContainer>
      {/* <Link to={"/citrine/programs/test"} >
    <p>here</p>
    </Link> */}
    </div>
  );
}













