import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import {
  createTheme,
  makeStyles,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles'
import EventIcon from '@material-ui/icons/Event'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { DataGrid } from '@mui/x-data-grid'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Form, notification, Tabs } from 'antd'
import clsx from 'clsx'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { fetchPersons } from 'reduxToolkit/features/person/PersonsSlice'
import {
  infoColor,
  primaryColor,
  roseColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
import {
  fetchCampaignById,
  updateCampaign,
  updateCampaignToDone,
  updateCampaignToDraft,
  updateCampaignToInProgress,
} from '../../../../reduxToolkit/features/campaign/CampaignsSlice'
import { sendSurveyToStudents } from '../../../../reduxToolkit/features/Survey/SurveySlice'
import ErrorModal from '../../../Components/ErrorModal.jsx'
import './Survey.scss'
import SurveyAnalysis from './SurveyAnalysis'
import UploadParticipants from './UploadParticipants'
const spinner_css = {
  margin: '20px 0',
  marginBottom: '20px',
  padding: '30px 50px',
  textAlign: 'center',
  background: '#000000',
  borderRadius: '4px',
}

const columns = [
  //{ field: 'id', headerName: 'ID', },

  {
    field: 'student_identification',
    headerName: 'Identification',
    editable: true,
    width: 200,
  },
  {
    field: 'first_name',
    headerName: 'First name',
    width: 220,
    editable: true,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    editable: true,
    width: 220,
  },
  {
    field: 'email',
    headerName: 'Email',
    // type: 'number',
    editable: true,
    width: 350,
  },
  {
    field: 'state',
    headerName: 'State',
    type: 'boolean',
    width: 150,
  },

  // {
  //     field: 'level',
  //     headerName: 'Level',
  //     width: 150,
  // },
]
const columnsProgram = [
  //{ field: 'id', headerName: 'ID', },


  {
    field: 'first_name',
    headerName: 'First name',
    width: 220,
    editable: true,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    editable: true,
    width: 220,
  },
  {
    field: 'email',
    headerName: 'Email',
    // type: 'number',
    editable: true,
    width: 350,
  },
  {
    field: 'state',
    headerName: 'State',
    type: 'boolean',
    width: 150,
  },

  // {
  //     field: 'level',
  //     headerName: 'Level',
  //     width: 150,
  // },
]

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
  },
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <EventIcon />,
    2: <HourglassEmptyIcon />,
    3: <EventAvailableIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const theme = createTheme({
  overrides: {
    MuiStepper: {
      root: {
        background: 'none',
        border: 'none',
        padding: 0,
      },
    },
  },
})

export default function StudentSurveyCampaignDetails() {
  const { t } = useTranslation()
  const [pageSize, setPageSize] = React.useState(9)
  let currentLanguage = localStorage.getItem('i18nextLng')
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [activeState, setActiveState] = React.useState('In Progress')
  const [draft, setDraft] = useState(false)
  const [inprogress, setInProgress] = useState(false)
  const [done, setDone] = useState(false)
  const steps = ['Draft', 'In Progress', 'Done']
  const [rows, setRows] = useState([])
  const [openSpinner, setOpenSpinner] = React.useState(false)



  const handleCloseSpinner = () => {
    setOpenSpinner(false)
  }
  const [selectionModel, setSelectionModel] = React.useState([])
  let { id } = useParams()
  const dispatch = useDispatch()
  // const campaignStatus = useSelector((state) => state.campaign.campaignStatus)

  const fetchCampaignCustomFunction = async () => {
    try {
      const compaign = await dispatch(fetchCampaignById(id)).unwrap()

      // setStudentsList(compaign.students_by_survey);
      // setPersonsList(compaign.students_by_program_survey);
      if (compaign.state == 'Draft') {
        setDraft(true)
        setActiveState(compaign.state)
        setActiveStep(steps.indexOf(compaign.state))
      } else if (compaign.state == 'In Progress') {
        setInProgress(true)
        setActiveState(compaign.state)
        setActiveStep(steps.indexOf(compaign.state))
      } else if (compaign.state == 'Done') {
        setDone(true)
        setActiveState(compaign.state)
        setActiveStep(steps.indexOf(compaign.state))
      }

      // setActiveState(compaign.state)
      dispatch(fetchPersons())

      console.log('success')
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  useEffect(() => {
    // if (programsStatus === 'idle') {
    fetchCampaignCustomFunction()
    //}
  }, [])

  const campaign = useSelector((state) => state.campaign.campaign)

  console.log('campaign', campaign && campaign)

  console.log('Draft', draft)
  console.log('In Progress', inprogress)
  console.log('Done', done)
  const p = useSelector((state) => state.person.persons)
  const { campaigns } = useSelector((state) => state.campaign)

  console.log(campaigns)

  const [students_list, setStudentsList] = useState(null)
  const [persons_list, setPersonsList] = useState(null)

  const [participants_ids, setParticipants_ids] = useState('')
  const [spinner, setSpinner] = useState(false)
  // const getStudents = async () => {
  //     console.log("cocampaignm", campaign && campaign)

  //     setStudentsList(campaign.students_by_survey)
  // }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 6,
    })
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    // message.error('Submit failed!');
  }
  const onUpdateToInProgress = async () => {
    let state = 'In Progress'
    let data = {
      state: state,
      id: id,
    }
    const resultAction = await dispatch(updateCampaignToInProgress(data))

    if (updateCampaignToInProgress.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchCampaignById(id))
        .then(unwrapResult)
        .then((compaign) => {
          if (compaign.state == 'Draft') {
            setDraft(true)
            setInProgress(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'In Progress') {
            setInProgress(true)
            setDraft(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'Done') {
            setDone(true)
            setDraft(false)
            setInProgress(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          }
          setActiveState(compaign.state)
          setActiveStep(steps.indexOf(compaign.state))
        })
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }
  const onResendRemainder = async () => {
    console.log(campaign.active)
    const state = 'In Progress'
    const active = campaign.active
    let data = {
      id,
      state,
      active
    }

    const resultAction = await dispatch(updateCampaignToInProgress(data))

    if (updateCampaignToInProgress.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchCampaignById(id))
        .then(unwrapResult)
        .then((compaign) => {
          console.log(compaign)
          if (compaign.state == 'Draft') {
            setDraft(true)
            setInProgress(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'In Progress') {
            setInProgress(true)
            setDraft(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'Done') {
            setDone(true)
            setDraft(false)
            setInProgress(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          }
          setActiveState(compaign.state)
          setActiveStep(steps.indexOf(compaign.state))
        })
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const onUpdateToClosed = async () => {
    let state = 'Done'
    let data = {
      updatedCampaign: { state },
      id: id,
    }
    console.log('data', data)
    const resultAction = await dispatch(updateCampaignToDone(data))

    if (updateCampaignToDone.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchCampaignById(id))
        .then(unwrapResult)
        .then((compaign) => {
          if (compaign.state == 'Draft') {
            setDraft(true)
            setInProgress(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'In Progress') {
            setInProgress(true)
            setDraft(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'Done') {
            setDone(true)
            setDraft(false)
            setInProgress(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          }
          setActiveState(compaign.state)
          setActiveStep(steps.indexOf(compaign.state))
        })
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }
  const onResetToDraft = async () => {
    let state = 'Draft'
    let data = {
      updatedCampaign: { state },
      id: id,
    }
    const resultAction = await dispatch(updateCampaignToDraft(data))

    if (updateCampaignToDraft.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchCampaignById(id))
        .then(unwrapResult)
        .then((compaign) => {
          if (compaign.state == 'Draft') {
            setDraft(true)
            setInProgress(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'In Progress') {
            setInProgress(true)
            setDraft(false)
            setDone(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          } else if (compaign.state == 'Done') {
            setDone(true)
            setDraft(false)
            setInProgress(false)
            setActiveState(compaign.state)
            setActiveStep(steps.indexOf(compaign.state))
          }
          setActiveState(compaign.state)
          setActiveStep(steps.indexOf(compaign.state))
        })
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const onFinish = async () => {
    let participants_ids = selectionModel.toString()
    console.log('participants_ids', participants_ids)
    setSpinner(true)
    //openNotificationWithIcon("info", `Sending Mails, Please Wait a while`);
    // handleClose();
    const resultAction = await dispatch(
      sendSurveyToStudents({
        survey_response: {},
        campaign_id: id,
        participants_ids,
      }),
    )
    // if (sendSurveyToStudents.pending.match(resultAction)) {
    //     // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk

    //     openNotificationWithIcon("pending", `Sending Mails, Please Wait a while`);
    //     handleClose();
    // }
    if (sendSurveyToStudents.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      handleCloseSpinner()
      openNotificationWithIcon('success', `Sended`)
      handleClose()
      onUpdateToInProgress()
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        handleClose()
        handleCloseSpinner()
      } else {
        console.log('resultAction.error  from component', resultAction.error)
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        handleClose()
        handleCloseSpinner()
      }
    }
  }
  //   console.log('campaignStatus', campaignStatus)
  //   const error = useSelector((state) => state.campaign.error)

  // console.log('campaign students_by_survey', campaign.students_by_survey)

  // if (!campaign) {
  //   return (
  //     <section>
  //       <h3>{t("Loading...")}</h3>
  //     </section>
  //   );
  // }
  // if (!campaign.students_by_survey || !campaign.students_by_survey.length) {
  //     return (
  //         <section>
  //             <h3>{t('Make sure the participants participated in the course exams and had their grades !')}</h3>
  //         </section>
  //     )
  // }

  const { TabPane } = Tabs
  function callback(key) {
    console.log(key)
  }
  console.log('selectionModel', selectionModel)
  const handleToggleSpinner = () => {
    setOpenSpinner(!openSpinner)
  }

  //   const handleSendForAnalysis = () => {
  //     UpdateState("Waiting for analysis");
  //   };

  const handleCloseCampaign = () => {
    UpdateState('Analysed')
  }
  //   const handleReset = () => {
  //     UpdateState("Planned");
  //   };
  //   const handleBackFromAnalysed = () => {
  //     UpdateState("Waiting for analysis");
  //   };
  //   const handleBackFromWFA = () => {
  //     UpdateState("Planned");
  //   };
  const UpdateState = async (value) => {
    let data = {
      updatedCampaignState: { state: value },
      id: id,
    }
    const resultAction = await dispatch(updateCampaign(data))

    if (updateCampaign.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchCampaignById(id))
      openNotificationWithIcon('success', `updated successfully`)
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }
  return (
    <div>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openSpinner}
          onClick={handleCloseSpinner}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* {spinner && <div className="spinner_css">
          <Spin />
          </div>} */}
      </div>
      <div>
        <GridContainer alignItems="stretch">
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/citrine/surveys/studentsSurvey`}>
                  {t('Internal Survey')}
                </Link>
                / {campaign && campaign.type} Campaign Details
              </Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ padding: 0 }}>
              <GridContainer justify="space-between" alignItems="stretch">
                <GridItem xs={12} sm={12} md={3}>
                  <Form
                    name="basic"
                    layout="vertical"
                    //   labelCol={{ span: 8 }}
                    //   wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError
                  >
                    <Form.Item>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer xs={12} sm={12} md={12}>
                            {draft && (
                              <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                style={{ marginTop: '5px' }}
                              >
                                <Button
                                  onClick={handleToggleSpinner}
                                  type="submit"
                                  color="primary"
                                  disabled={!selectionModel.length}
                                >
                                  {t('Send')}
                                </Button>
                              </GridItem>
                            )}
                            <GridItem xs={12} sm={12} md={6}>
                              {inprogress && (
                                <div style={{ marginTop: '5px' }}>
                                  <Button
                                    color="primary"
                                    onClick={onResendRemainder}
                                  >
                                    Resend
                                  </Button>
                                </div>
                              )}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              {inprogress && (
                                <div style={{ marginTop: '5px' }}>
                                  <Button
                                    color="primary"
                                    onClick={onUpdateToClosed}
                                  >
                                    Close &ensp;
                                  </Button>
                                </div>
                              )}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              {done && (
                                <div style={{ marginTop: '0px' }}>
                                  <Button
                                    color="primary"
                                    onClick={onResetToDraft}
                                  >
                                    Re-open
                                  </Button>
                                </div>
                              )}
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </Form>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={9}
                  style={{ marginTop: '15px', marginRight: '0px' }}
                >
                  <ThemeProvider theme={theme}>
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={
                        currentLanguage === 'ar' ? false : <ColorlibConnector />
                      }
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </ThemeProvider>
                </GridItem>
              </GridContainer>
            </Card>
            <Card>
              <GridContainer justify="space-between" alignItems="stretch">
                <GridItem xs={12} sm={12} md={9} style={{ marginTop: '5px' }}>
                  <h4>
                    <strong>
                      {campaign && campaign.type_details} -{' '}
                      {campaign && campaign.resource_name}{' '}
                    </strong>
                  </h4>
                  <h5>
                    <strong>
                      {campaign && campaign.academic_year} -
                      {campaign && campaign.semester}{' '}
                    </strong>
                  </h5>
                  <div>
                    {t('Created at')}: {campaign && campaign.creation_date}
                  </div>
                  <div>
                    {t('Sent at')}: {campaign && campaign.sent_at}
                  </div>
                  <div>
                    {t('Due date')}: {campaign && campaign.due_date}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <div>
                    {t('Participants')}{' '}
                    <Button
                      // aria-describedby={id}
                      aria-controls="customized-menu"
                      variant="contained"
                      color="white"
                      //onClick={handleClick}
                      justIcon
                      label="Participants"
                      round
                      aria-haspopup="true"
                      style={{ marginRight: '0px', marginTop: '10px' }}
                    >
                      {campaign &&
                        campaign.closed_survey &&
                        campaign.closed_survey.participants_numbers}

                      {/* <MoreVert /> */}
                    </Button>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <div>
                    {t('Responses')}
                    <Button
                      // aria-describedby={id}
                      aria-controls="customized-menu"
                      variant="contained"
                      color="white"
                      //onClick={handleClick}
                      justIcon
                      round
                      aria-haspopup="true"
                      style={{ marginRight: '0px', marginTop: '10px' }}
                    >
                      {campaign &&
                        campaign.closed_survey &&
                        campaign.closed_survey.total_participants_number}

                      {/* <MoreVert /> */}
                    </Button>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <div>
                    {t('Rate')} %<br></br>
                    <Button
                      // aria-describedby={id}
                      aria-controls="customized-menu"
                      variant="contained"
                      color="white"
                      //onClick={handleClick}
                      justIcon
                      title="Rate"
                      round
                      aria-haspopup="true"
                      style={{ marginRight: '0px', marginTop: '10px' }}
                    >
                      {campaign &&
                        campaign.closed_survey &&
                        campaign.closed_survey.response_rate}

                      {/* <MoreVert /> */}
                    </Button>
                  </div>
                </GridItem>

                {/* <GridItem>
                                <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "10px",
                        marginEnd: "10px",
                      }}
                    ><Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#e6b3ff",
                            height: "50px",
                            width: "70px",
                          }}
                        >
                              {campaign &&
                            campaign.closed_survey &&
                            campaign.closed_survey.participants_numbers}
                         
                        </Avatar>
                        <p style={{ marginTop: "10px", marginLeft: "20px", fontSize: "14px" }}>
                          {t("Participants")}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#d580ff",
                            height: "50px",
                            width: "70px",
                          }}
                        >
                          {campaign &&
                            campaign.closed_survey &&
                            campaign.closed_survey.total_participants_number}
                        </Avatar>
                        <p style={{ marginTop: "10px", marginLeft: "20px", fontSize: "14px" }}>
                          {t("Responses")}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#aa00ff",
                            height: "50px",
                            width: "70px",
                          }}
                        >
                          {campaign &&
                            campaign.closed_survey &&
                            campaign.closed_survey.response_rate} %
                        </Avatar>
                        <p style={{ marginTop: "10px", marginLeft: "30px", fontSize: "14px" }}>
                          {t("Rate")} 
                        </p>
                      </Grid>
                      </div></GridItem> */}
              </GridContainer>
            </Card>
            <Card withPadding>
              <Tabs onChange={callback} type="card">
                <TabPane tab="Participants" key="1">
                  {campaign && campaign.resource === 'course' ? (
                    // <GridContainer
                    //   style={{
                    //     height: 500,
                    //     width: 1500,
                    //   }}
                    // >
                    <Card>
                      <CardBody>
                        <div style={{ height: 600, width: '100%' }}>
                          <DataGrid
                            rows={
                              (campaign && campaign.students_by_survey) || []
                            }
                            columns={columns.map((column) => ({ ...column, editable: false }))}
                            // pageSize={7}
                            // rowsPerPageOptions={[7]}
                            // onSelectionModelChange={(ids) => { setParticipants_ids(ids.toString()) }}
                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            //rowsPerPageOptions={[5, 10, 20, 80]}

                            checkboxSelection
                            disableSelectionOnClick
                            onSelectionModelChange={(newSelectionModel) => {
                              setSelectionModel(newSelectionModel)

                              console.log(
                                'newSelectionModel',
                                newSelectionModel.toString(),
                              )
                            }}
                            selectionModel={selectionModel}
                            autoPageSize={true}
                            pageSize={pageSize}
                            pagination
                          />
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    // </GridContainer>
                    <div>
                      <UploadParticipants
                        campaign={campaign && campaign}
                        id={id}
                      />
                      <br />
                      {/* <GridContainer
                        style={{
                          height: 500,
                          width: 1500,
                        }}
                      > */}
                      <Card>
                        <CardBody>
                          <div style={{ height: 600, width: '100%' }}>
                            <DataGrid
                              rows={
                                (campaign &&
                                  campaign.students_by_program_survey) ||
                                []
                              }
                              columns={columnsProgram}
                              // pageSize={7}
                              // rowsPerPageOptions={[7]}
                              checkboxSelection
                              disableSelectionOnClick
                              // onSelectionModelChange={(ids) => { setParticipants_ids(ids.toString()) }}
                              onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel)

                                console.log(
                                  'newSelectionModel',
                                  newSelectionModel.toString(),
                                )
                              }}
                              autoPageSize={true}
                              selectionModel={selectionModel}
                              pageSize={pageSize}
                              onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                              }
                              rowsPerPageOptions={[5, 10, 20, 80]}
                              pagination
                            />
                          </div>
                        </CardBody>
                      </Card>
                      {/* </GridContainer> */}
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Analysis" key="2">
                  <SurveyAnalysis
                    campaign={campaign && campaign}
                    campaignId={id}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </GridItem>
        </GridContainer>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </div>
    </div>
  )
}
