import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";

const ProgramCurriculum = ({ data }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const columns = [
    {
      title: "Course",
      dataIndex: "title",
      key: "Course",
      width: 200,
    },
    {
      title: "Required,  Elective or a Selected Elective ( R, an E or an SE )",
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: "Subject Area (Credit Hours)",
      children: [
        {
          title: "Math & Basic Sciences",
          dataIndex: "mathBasicSciencesCredits",
          key: "MathBasicSciences",
          width: 100,
        },
        {
          title: "Engineering Topics; Check if Contains Significant Design (√)",
          dataIndex: "engineeringTopicsCredits",
          key: "EngineeringTopics",
          width: 100,
        },
        {
          title: "Other",
          dataIndex: "otherCredits",
          key: "Other",
          width: 100,
        },
      ],
    },
    {
      title: "Year & Term",
      dataIndex: "yearTerm",
      key: "yearTerm",
      width: 150,
    },
    {
      title:
        "Last Two Terms the Course was Offered: Year and Semester (or Quarter)",
      dataIndex: "courseLastTwoTerms",
      key: "courseLastTwoTerms",
      width: 150,
      render: (terms) => (
        <ul>
          {terms.map((term) => {
            return <li key={term}>{term}</li>;
          })}
        </ul>
      ),
    },
    {
      title:
        "Maximum Section Enrollment for the Last Two Terms the Course was Offered",
      dataIndex: "maximumSectionEnrollment",
      key: "maximumSectionEnrollment",
      width: 100,
    },
  ];

  return (
    <>
      <h3>CURRICULUM: Program Curriculum</h3>

      <p className={styles.reportParagraphTitle}>
        The following should describe or include :
      </p>
      <ul>
        <li>The plan of study for students in this program.</li>
        <li>
          How the curriculum aligns with the program educational objectives.
        </li>
        <li>
          How the curriculum and its associated prerequisite structure support
          the attainment of the student outcomes.
        </li>
        <li>
          A flowchart or worksheet that illustrates the prerequisite structure
          of the program’s required courses.
        </li>
        <li>
          How the program meets the requirements in terms of hours and depth of
          study for each subject area (Math and Basic Sciences, Engineering
          Topics).
        </li>
        <li>
          The broad education component and how it complements the technical
          content of the curriculum and how it is consistent with the program
          educational objectives.
        </li>
        <li>
          The major design experience that prepares students for engineering
          practice. How this experience is based upon the knowledge and skills
          acquired in earlier coursework and incorporates appropriate
          engineering standards and multiple design constraints.
        </li>
        <li>
          A description of the cooperative education experience and how it is
          evaluated by the faculty, if the program allows this academic
          component to satisfy curricular requirements specifically addressed by
          either the general or program criteria
        </li>
        <li>
          The materials that will be available for review during and/or prior to
          the visit to demonstrate achievement related to this criterion.
        </li>
      </ul>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={setValueContent}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is the program curriculum in the form of a schedule:
      </p>
      <div className={styles.reportCart}>
        <AntDDataGrid
          columns={columns}
          data={data?.programCurriculumTable}
          scroll
          bordered
          size="middle"
        />
      </div>
    </>
  );
};

export default ProgramCurriculum;
