import { makeStyles } from '@material-ui/core/styles'
import { SchoolRounded } from '@material-ui/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, Form, Input, notification, Select } from 'antd'
//import Select from '@mui/material/Select'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  addNewAssessmentMethod,
  assessmentMethodUpdate,
  fetchAssessmentMethodById,
  fetchAssessmentMethods
} from '../../../../reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import { fetchAssessmentModeById, fetchAssessmentModes } from '../../../../reduxToolkit/features/assessmentMode/AssessmentModeSpecSlice'
import ErrorModal from "../../../Components/ErrorModal"
//import AddEvidence from './AddEvidence'
const Option = Select.Option

const useStyles = makeStyles(styles)
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};
// const classes = useStyles();
export default function CreateAssessmentMethodPage({ match }) {
  const [form] = Form.useForm();
  const [type, setType] = useState()
  const [checked, setChecked] = useState(true)
  const { t } = useTranslation()
  const { id } = match.params
  const isAddMode = isNaN(id)
  console.log('id from match is add mode', isAddMode)
  const classes = useStyles()
  const useTitleStyles = makeStyles(titleStyles)

  const titleClasses = useTitleStyles()
  const [name, setName] = useState('')
  const [evidence, setEvidence] = useState('')
  const [modeSpec_id, setMode_id] = useState('')
  const [defaultQuestionCode, setDefaultQuestionCode] = useState('')
  const [defaultQuestionNumber, setDefaultQuestionNumber] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')


  const modesStatus = useSelector(
    (state) => state.assessmentModeSpec.assessmentModesStatus,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (modesStatus === 'idle') {
      dispatch(fetchAssessmentModes())

    }
  }, [modesStatus, dispatch])

  const modes = useSelector((state) => state.assessmentModeSpec.assessmentModes)


  const history = useHistory()


  function onChangeMode_id(value) {
    console.log(`selected ${value}`)
    setMode_id(value)
    dispatch(fetchAssessmentModeById(value)).then(unwrapResult).then((item) => {
      console.log('item.name.charAt(0).toUpperCase()', item.name.charAt(0).toUpperCase())
      let res = item.name.charAt(0).toUpperCase()
      console.log('res', res)
      setDefaultQuestionCode(res)
      form.setFieldsValue({
        defaultQuestionCode: res,
      });
    });

  }


  const assessmentMethod = useSelector(
    (state) => state.assessmentMethod.assessmentMethod,
  )
  console.log('assessmentMethods ', assessmentMethod)

  useEffect(() => {
    if (!isAddMode) {
      setName(assessmentMethod && assessmentMethod.name)
      setMode_id(assessmentMethod && assessmentMethod.modeSpec_id)
      setDefaultQuestionCode(assessmentMethod && assessmentMethod.defaultQuestionCode)
      setDefaultQuestionNumber(assessmentMethod && assessmentMethod.defaultQuestionNumber)
    }
  }, [assessmentMethod, dispatch])
  //console.log(type)

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewAssessmentMethod({ ...values })
    );
    if (addNewAssessmentMethod.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchAssessmentMethods());
      history.push('/citrine/assessmentMethods');
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      assessmentMethodUpdate({
        id: id,
        modified: {
          ...values,
        },
      })
    );
    if (assessmentMethodUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();

      dispatch(fetchAssessmentMethods())
      history.push('/citrine/assessmentMethods')
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchAssessmentMethodById(id))
        .then(unwrapResult)
        .then((obj) => {
          form.setFieldsValue({
            modeSpec_id: obj.modeSpec_id,
            name: obj.name,
            defaultQuestionCode: obj.defaultQuestionCode,
            defaultQuestionNumber: obj.defaultQuestionNumber
          });
        });
    }
  }, [dispatch, id]);



  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course`}>
                      {' '}
                      {t('Course Configurations')}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/assessmentMethods`}
                      style={{ color: 'orange' }}
                    >
                      {' '}
                      {t('Assessment Method List')}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{t('Create')}</h4>
                  </CardHeader>

                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="modeSpec_id"
                        label="Assessment Mode"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear onChange={onChangeMode_id}>
                          {modes &&
                            modes.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="defaultQuestionCode"
                        label="Default question code"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="defaultQuestionNumber"
                        label="Default question number"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>



                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(`/citrine/ModeOfInstructionList/${id}`)
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          color="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                  {/* <form>
                    <GridContainer
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText={t('Name ')}
                          id="name"
                          //  onChange={onNameChanged}
                          type="text"
                          name="name"
                          value={name}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setName(event.target.value)
                            },
                          }}
                        />

                        <label>{t('Assessment Mode  ')}</label>
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          size={200}
                          optionFilterProp="children"
                          name="mode_id"
                          value={modeSpec_id}
                          onChange={onChangeMode_id}
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {modes.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText={t('Default Question Code ')}
                          id="defaultQuestionCode"
                          //  onChange={onNameChanged}
                          type="text"
                          name="defaultQuestionCode"
                          value={defaultQuestionCode}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a default Question Code!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setDefaultQuestionCode(event.target.value)
                            },
                          }}
                        />

                        <CustomInput
                          labelText={t('Default Question Number ')}
                          id="defaultQuestionNumber"
                          //  onChange={onNameChanged}
                          type="text"
                          name="defaultQuestionNumber"
                          value={defaultQuestionNumber}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a default Question Number!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setDefaultQuestionNumber(event.target.value)
                            },
                          }}
                        />
                      </GridItem>
                      {/* <GridItem
                        xs={6}
                        sm={6}
                        md={6}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <label>{t('Evidence Type ')}</label>

                        <Select
                          defaultValue=""
                          showSearch
                          style={{ width: '100%' }}
                          size={200}
                          optionFilterProp="children"
                          name="mode_id"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          {types.map((r) => (
                            <MenuItem key={r.id} value={r.type}>
                              {r.type}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormGroup>
                          <FormControlLabel
                            onChange={(event) => {
                              setChecked(event.currentTarget.checked)
                            }}
                            control={<Checkbox defaultChecked />}
                            label="Required"
                          />
                        </FormGroup>
                        <hr />
                      </GridItem> */}
                  {/* <GridItem>
                        <Button
                          color="info"
                          className={classes.updateProfileButton}
                          onClick={onSaveAssessmentMethodClicked}
                        >
                          {t(' Save')}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form> */}
                  {/* </CardBody> */}
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && assessmentMethod && (
        <div>
          <GridContainer>
            <GridItem>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}>
                    {' '}
                    {t('Course Configurations')}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={`/citrine/assessmentMethods`}
                    style={{ color: 'orange' }}
                  >
                    {' '}
                    {t('Assessment Method List')}
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>{t('Edit')}</h4>
                </CardHeader>

                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinishUpdate}
                  >
                    <Form.Item
                      name="modeSpec_id"
                      label="Assessment Mode"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="Select mode" allowClear onChange={onChangeMode_id}>
                        {modes &&
                          modes.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="defaultQuestionCode"
                      label="Default question code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="defaultQuestionNumber"
                      label="Default question number"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>



                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/ModeOfInstructionList/${id}`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        color="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
                {/* <CardBody>
                  <form>
                    <GridContainer
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText={t('Name ')}
                          id="name"
                          //  onChange={onNameChanged}
                          type="text"
                          name="name"
                          value={name}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setName(event.target.value)
                            },
                            value: name,
                          }}
                        />
                        <label>{t('Assessment Mode ')} </label>
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          size={200}
                          optionFilterProp="children"
                          name="mode_id"
                          value={modeSpec_id}
                          onChange={onChangeMode_id}
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {modes.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                        </Select>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText={t('Default Question Code ')}
                          id="defaultQuestionCode"
                          //  onChange={onNameChanged}
                          type="text"
                          name="defaultQuestionCode"
                          value={defaultQuestionCode}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a default Question Code!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setDefaultQuestionCode(event.target.value)
                            },
                          }}
                        />

                        <CustomInput
                          labelText={t('Default Question Number ')}
                          id="defaultQuestionNumber"
                          //  onChange={onNameChanged}
                          type="text"
                          name="defaultQuestionNumber"
                          value={defaultQuestionNumber}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a default Question Number!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setDefaultQuestionNumber(event.target.value)
                            },
                          }}
                        />
                      </GridItem>
                      <GridItem>
                        <Button
                          color="info"
                          className={classes.updateProfileButton}
                          onClick={onUpdateClicked}
                        >
                          {t(' Update')}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody> */}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  )
}
