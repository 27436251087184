import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseById } from '../../../../../reduxToolkit/Course/coursesSpecSlice';


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';


import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { Breadcrumb } from 'antd';
import { fetchAssessmentTasksForStudents } from "reduxToolkit/features/AssessmentTasksForStudent/AssessmentTasksForStudentSlice";

import DropDownATFS from "../../../../Components/DropDownATFS";

import { useTranslation } from 'react-i18next';

import { notification } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

const useStyles = makeStyles(styles);


export default function AssessmentTasksForStudentsList({ match }) {
    const { t } = useTranslation();

    const classes = useStyles();

    const { id } = match.params
    console.log("id+++++++++++++++++++++++++", id)
    const dispatch = useDispatch()

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };
    const fetchAllAssessmentTasksForStudents = async () => {
        try {
            const resultAction = await dispatch(fetchAssessmentTasksForStudents(id)).unwrap();
            dispatch(fetchCourseById(id));
            console.log('inside try catch+++++++++')
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };
    useEffect(() => {
        fetchAllAssessmentTasksForStudents()
        if (localStorage.getItem("CourseState") != "Draft") {
            setDisabledStatus(true);
        }

    }, [dispatch])

    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const AssessmentTasksForStudents = useSelector((state) => state.AssessmentTasksForStudent.AssessmentTasksForStudents)
    console.log('//////////////list/////////', AssessmentTasksForStudents)
    const error = useSelector((state) => state.course.error)

    // if (!course) {
    //     return (
    //         <section>
    //             <h2>Course not found!</h2>
    //         </section>
    //     )
    // }

    return (
        <div>
            <GridItem>
                <Breadcrumb separator=">">

                    

                    <Breadcrumb.Item>
                        <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/courseSpecDetails/${id}`}>{course && course.title && course.title}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/specification/course/${id}/AssessmentTasksForStudents`} style={{ color: "orange" }} > {t('Assessment Tasks For Student List')}
                        </Link>
                    </Breadcrumb.Item>

                </Breadcrumb>
            </GridItem>
            <GridContainer>
                <GridItem >
                    {disabledStatus ? <p></p> :
                        <Link to={`/citrine/specification/course/${id}/AssessmentTasksForStudents/create`}>
                            <Button color="info"
                                className={classes.updateProfileButton}
                            >
                                {t('Add')}
                            </Button>
                        </Link>
                    }
                </GridItem>
            </GridContainer>

            <GridContainer>
                {AssessmentTasksForStudents && AssessmentTasksForStudents.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 250, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={12} sm={12} md={10}>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start', color: "orange" }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} style={{ color: "orange" }} >
                                                {t('Assessment Task')} :  {Element && Element.assessment_task_details && Element.assessment_task_details[0].name}
                                            </a>

                                        </h4>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start' }} >

                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {t('Week Due')} :  {Element && Element.week_due}
                                            </a>

                                        </h4>
                                        <h4 className={classes.cardProductTitle} style={{ textAlign: 'start' }} >

                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {t('Percentage Of Total Assessment Score')} :  {Element && Element.percentage_of_total_assessment_score}
                                            </a>

                                        </h4>

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }} >
                                        {disabledStatus ? <p></p> :
                                            <DropDownATFS AssessmentTasksForStudent_ID={Element && Element.id} courseID={course && course.id} />
                                        }
                                    </GridItem>
                                </GridContainer>



                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div >

    );

}




