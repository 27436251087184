import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";

// antdesign components
import { DatePicker, Form, Input, message, notification, Select } from "antd";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { infoColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { addNewFacultyActivity } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import {
  fetchProgramById
} from "../../../../reduxToolkit/features/program/ProgramsSlice.js";
import { fetchUsersByTypeAndCourseIds } from "../../../../reduxToolkit/features/user/UserSlice.js";
import ErrorModal from "../../../Components/ErrorModal";
import { fetchCurrentSemester } from "reduxToolkit/features/semester/SemestersSlice";

const useStyles = makeStyles(styles);
const useTitleStyles = makeStyles(titleStyles);

export default function CreateActivity({ match }) {
  const { t } = useTranslation();
  let history = useHistory();
  let { id } = useParams();
  const titleClasses = useTitleStyles();
  const [form] = Form.useForm();

  const [selectedProgram, setSelectedProgram] = React.useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [students, setStudents] = React.useState([]);
  const [staff, setStaff] = useState([]);
  const [faculty, setFaculty] = useState([]);

  const [university_id, setUniversity_id] = React.useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    dispatch(fetchCurrentSemester(userId))
    dispatch(fetchProgramById(id))
      .then(unwrapResult)
      .then((program) => {
        // do additional work;
        const courses = program.courses;
        setCoursesByProgram(courses);
      });
  }, [dispatch]);
  // const programs = useSelector((state) => state.program.programs);

  const currentSemester = useSelector(
    (state) => state.semester.currentSemester
  );
  console.log("currentSemester", currentSemester);
  const [coursesByProgram, setCoursesByProgram] = useState([]);

  // const handleProgram = (value) => {
  //   console.log(`selected program in onchange ${value}`);
  //   setSelectedProgram(value);
  //   dispatch(fetchProgramById(value))
  //     .then(unwrapResult)
  //     .then((program) => {
  //       // do additional work;
  //       const courses = program.courses;
  //       setCoursesByProgram(courses);
  //     });
  // };
  const program = useSelector((state) => state.program.program);

  const handleCourses = (value) => {
    console.log(`selected courses in onchange ${value}`);
    console.log("type", typeof value);
    console.log("joined", value.join());

    setSelectedCourses(value);
    dispatch(
      fetchUsersByTypeAndCourseIds({ type: "student", ids: value.join() })
    )
      .then(unwrapResult)
      .then((students) => {
        // do additional work;
        setStudents(students);
      });

    dispatch(fetchUsersByTypeAndCourseIds({ type: "staff", ids: value.join() }))
      .then(unwrapResult)
      .then((staff) => {
        // do additional work;
        setStaff(staff);
      });

    dispatch(
      fetchUsersByTypeAndCourseIds({ type: "faculty", ids: value.join() })
    )
      .then(unwrapResult)
      .then((faculty) => {
        // do additional work;
        setFaculty(faculty);
      });
  };

  const [start_date, setStartDate] = React.useState("");
  const [end_date, setEndDate] = React.useState("");

  function onStartDateChange(value, dateString) {
    setStartDate(dateString);
  }
  function onEndDateChange(value, dateString) {
    setEndDate(dateString);
  }
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Submit failed!");
  };

  let semester_id = currentSemester.id;
  let academicYear_id = currentSemester.academicYear_id;
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    let staff_participants_ids = values.staff_participants_ids || [];
    let faculty_participants_ids = values.faculty_participants_ids || [];
    let student_participants_ids = values.student_participants_ids || [];
   
    let program_id = id;
    const toAddFacultyActivity = {
      ...values,
      start_date,
      end_date,
      semester_id,
      academicYear_id,
      staff_participants_ids,
      faculty_participants_ids,
      student_participants_ids,
      program_id,
    };
    console.log("toAddFacultyActivity: ", toAddFacultyActivity);

    const resultAction = await dispatch(
      addNewFacultyActivity(toAddFacultyActivity)
    );

    if (addNewFacultyActivity.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Added successfully`);
      history.push(`/citrine/faculty/programs/${id}/activities`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const { Option } = Select;

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      ></GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Add a new activity
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  {/* <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Program")}
                      name="program_id"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please select a program",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="program_id"
                        value={selectedProgram}
                        onChange={handleProgram}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {programs &&
                          programs.map((program) => (
                            <Option key={program.id} value={program.id}>
                              {program.program_degree_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem> */}

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Courses")} name="courses_ids">
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                        name="courses_ids"
                        value={selectedCourses}
                        onChange={handleCourses}
                      >
                        {coursesByProgram.map((course) => (
                          <Option key={course.id} value={course.id}>
                            {course.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t("Type")}
                      name="activity_type"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} size={200} showArrow>
                        <Option value="counselling_and_support">
                          Counselling & support
                        </Option>
                        <Option value="professional_development">
                          Professional development
                        </Option>
                        <Option value="research_and_innovation">
                          Research and innovation
                        </Option>
                        <Option value="community_partnership">
                          Community partnership
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t("Start date")}
                      name="start_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the start date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onStartDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t("End date")}
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the end date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onEndDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Description")}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the description",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Students")}
                      name="student_participants_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {students.map((student) => (
                          <Option key={student.id} value={student.id}>
                            {student.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item label={t("Staff")} name="staff_participants_ids">
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {staff.map((staff) => (
                          <Option key={staff.id} value={staff.id}>
                            {staff.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Faculty")}
                      name="faculty_participants_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {faculty.map((faculty) => (
                          <Option key={faculty.id} value={faculty.id}>
                            {faculty.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("External participants")}
                      name="external_participants"
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Assigned to")}
                      name="assigned_to_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select the assignee",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {faculty.map((faculty) => (
                          <Option key={faculty.id} value={faculty.id}>
                            {faculty.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* </FormControl> */}

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
