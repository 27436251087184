import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  ploIndicatorsStatus: 'idle',
  ploIndicators: [],
  ploIndicatorStatus: 'idle',
  ploIndicator: null,
  error: null,
}

export const fetchPloIndicators = createAsyncThunk('ploIndicatorSpec/fetchPloIndicators', async () => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + '/ploIndicatorsSpec/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
  // console.log(response.data,'response.data')

  return response.data
})

export const fetchPloIndicatorById = createAsyncThunk('ploIndicatorSpec/fetchPloIndicatorById', async (id) => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + `/ploIndicatorsSpec/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})

export const addNewPloIndicator = createAsyncThunk(
  'ploIndicatorSpec/addNewPloIndicator',
  async (initialPloIndicator, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/ploIndicatorsSpec/create/`, initialPloIndicator, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }


  }
)


export const deletePloIndicator = createAsyncThunk('ploIndicatorSpec/deletePloIndicator', async (id, { rejectWithValue }) => {

  try {

    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/ploIndicatorsSpec/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

const ploIndicatorsSlice = createSlice({
  name: 'ploIndicatorSpec',
  initialState,
  extraReducers: {

    [fetchPloIndicators.pending]: (state, action) => {
      state.ploIndicatorsStatus = 'loading'
    },
    [fetchPloIndicators.fulfilled]: (state, action) => {
      state.ploIndicatorsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ploIndicators = state.ploIndicators.concat(action.payload)
    },
    [fetchPloIndicators.rejected]: (state, action) => {
      state.ploIndicatorsStatus = 'failed'
      state.error = action.payload
    },

    [fetchPloIndicatorById.pending]: (state, action) => {
      state.ploIndicatorStatus = 'loading'
    },
    [fetchPloIndicatorById.fulfilled]: (state, action) => {
      state.ploIndicatorStatus = 'succeeded'

      // Add any fetched posts to the array
      state.ploIndicator = action.payload
    },
    [fetchPloIndicatorById.rejected]: (state, action) => {
      state.ploIndicatorStatus = 'failed'
      state.error = action.payload
    },

    [addNewPloIndicator.fulfilled]: (state, action) => {
      state.ploIndicators.push(action.payload)
    },

    [deletePloIndicator.fulfilled]: (state, action) => {
      console.log(action.payload)
    },
    //       [updateProgram.fulfilled]: (state, action) => {
    //         const result = state.programs.filter(program => program.id !== action.payload.id);
    //         // console.log("program id from filter from slice",program.id)
    //         result.push(action.payload)
    //         state.programs = result       
    //         // console.log("action.payload.id from slice",action.payload.id)

    //       },

  },

})

// export const { programAdded, programUpdated, getId } = programsSlice.actions

export default ploIndicatorsSlice.reducer

export const selectAllPloIndicators = state => state.ploIndicatorSpec.ploIndicators
