import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons
import { Link } from "react-router-dom";
import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec";
import {
  addNewAlignmentOfCLOWithTMAndAM, AlignmentOfCLOWithTMAndAMUpdate,
  fetchAlignmentOfCLOWithTMAndAMById, fetchAlignmentOfCLOWithTMAndAMs
} from "../../../../../reduxToolkit/features/AlignmentOfCLOWithTMAndAM/AlignmentOfCLOWithTMAndAMSlice";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
//import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/assessmentMethodSlice";
import { Breadcrumb, Input } from "antd";
import {
  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";

import { useTranslation } from "react-i18next";

import { Form, notification } from "antd";
import { fetchTeachingStrategiesSpec } from "reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import ErrorModal from "../../../../Components/ErrorModal";
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

// const classes = useStyles();
export default function CreateAlignmentOfCLOWithTMAndAM({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();
  const { id } = match.params;
  console.log("idCourse", id);
  const { idACWTMAM } = match.params;
  console.log("idACWTMAM", idACWTMAM);
  const isAddMode = isNaN(idACWTMAM);
  console.log("id from match is add mode", isAddMode);
  const [filteredTeachingStrategies, setFilteredTeachingStrategies] = useState(
    []
  );

  useEffect(() => {
    dispatch(fetchAssessmentMethods());
  }, [dispatch]);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const assessmentMethods = useSelector(
    (state) => state.assessmentMethod.assessmentMethods
  );
  console.log(course, "course");
  console.log(TeachingStrategiesConfig, "TeachingStrategiesConfig");
  let tab = [];
  useEffect(() => {
    dispatch(fetchCourseById(id));
    dispatch(fetchTeachingStrategiesSpec(id));
    dispatch(fetchAssessmentMethods(id));
  }, []);
  const TeachingStrategiesConfig = useSelector(
    (state) => state.TeachingStrategieConfig.TeachingStrategiesApprovementConfig
  );
  const TS = useSelector(
    (state) => state.TeachingStrategieSpec.TeachingStrategiesSpec
  );

  const [code_level, setCode_level] = useState("");
  const [cloSpec_id, setClo_id] = useState("");
  const [assessmentMethodSpec_ids, setAssessmentMethod] = useState([]);
  const [teaching_strategy_ids, setTeachingStrategies] = useState([]);
  const [courseSpec_id, setCourseSpec_id] = useState(id);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();
  const history = useHistory();

  const { Option } = Select;
  const canSave =
    [code_level, cloSpec_id, courseSpec_id, teaching_strategy_ids].every(
      Boolean
    ) && addRequestStatus === "idle";
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewAlignmentOfCLOWithTMAndAM({ ...values, courseSpec_id })
    );
    if (addNewAlignmentOfCLOWithTMAndAM.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(
        fetchAlignmentOfCLOWithTMAndAMs(course && course.id && course.id)
      );
      history.push(
        `/citrine/specification/course/${id}/AlignmentOfCLOWithTMAndAMs`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      AlignmentOfCLOWithTMAndAMUpdate({
        id: idACWTMAM,
        modified: {
          ...values,
        },
      })
    );
    if (AlignmentOfCLOWithTMAndAMUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(
        fetchAlignmentOfCLOWithTMAndAMs(course && course.id && course.id)
      );
      history.push(
        `/citrine/specification/course/${id}/AlignmentOfCLOWithTMAndAMs`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchAlignmentOfCLOWithTMAndAMById(idACWTMAM))
        .then(unwrapResult)
        .then((ACWTMAM) => {
          console.log(ACWTMAM, "ACWTMAM");
          form.setFieldsValue({
            code_level: ACWTMAM.code_level,
            cloSpec_id: ACWTMAM.cloSpec_id,
            assessmentMethodSpec_ids: ACWTMAM.assessmentMethodSpec_ids,
            teaching_strategy_ids: ACWTMAM.teaching_strategy_ids,
            courseSpec_id: ACWTMAM.courseSpec_id,
          });
        });
    }
  }, [idACWTMAM]);

  const res = useSelector(
    (state) => state.AlignmentOfCLOWithTMAndAM.AlignmentOfCLOWithTMAndAM
  );
  console.log("res ", res);

  function onChangeTeachingStrategies(value) {
    //  console.log(`selected ${value}`);
    setTeachingStrategies(value);
  }

  function onChangeAssessmentMethod(value) {
    //  console.log(`selected ${value}`);
    setAssessmentMethod(value);
  }
  function onChangeClo_id(value) {
    //  console.log(`selected ${value}`);
    setClo_id(value);
  }
  function onBlur() {
    //   console.log('blur');
  }

  function onFocus() {
    //  console.log('focus');
  }

  function onSearch(val) {
    //  console.log('search:', val);
  }
  const courseStatus = useSelector((state) => state.course.courseStatus);

  function handleChangeClos(value) {
    //  console.log(`Selected: ${value}`);
    // setClo_ids(value);
  }

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/AlignmentOfCLOWithTMAndAMs`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Alignment Of CLO With TS And AM List ")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="teaching_strategy_ids"
                        label="Teaching strategies"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select strategies"
                          allowClear
                          mode="multiple"
                        >
                          {TS && TS.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item && item.planned_teaching_strategy_detail && item.planned_teaching_strategy_detail.name}
                            </option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="cloSpec_id"
                        label="CLO"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select CLO" allowClear>
                          {course &&
                            course.closSpec &&
                            course.closSpec.map((clo) => (
                              <option value={clo.id}>{`${clo.title}`}</option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="code_level"
                        label="code_level"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/TeachingStrategies`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to={`/citrine/programs`}>{t("Programs List")}</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        style={{ color: "orange" }}
                        to={`/citrine/CreateAlignmentOfCLOWithTMAndAM/${id}`}
                      >
                        {t("update")}
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="teaching_strategy_ids"
                        label="Teaching strategies"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select strategies"
                          allowClear
                          mode="multiple"
                        >
                          {TS && TS.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item && item.planned_teaching_strategy_detail && item.planned_teaching_strategy_detail.name}
                            </option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="cloSpec_id"
                        label="CLO"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select CLO" allowClear>
                          {course &&
                            course.closSpec &&
                            course.closSpec.map((clo) => (
                              <option value={clo.id}>{`${clo.title}`}</option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="code_level"
                        label="code_level"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/TeachingStrategies`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
