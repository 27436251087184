import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/CustomButtons/Button'
import {fetchHTMLTemplates, updateHTMLTemplate} from '../../../reduxToolkit/features/HTMLTemplate/HTMLTemplateSlice.jsx'
import { Form, Select, notification } from 'antd'
import ErrorModal from '../../Components/ErrorModal'

export default function EditTemplateModal(props) {

  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [anchorEl, setAnchorEl] = useState(null)
  const [htmlTemplates_id, SethtmlTemplates_id] = useState('')
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const { Option } = Select
  const dispatch = useDispatch()

  const HTMLTemplates = useSelector((state) => state.HTMLTemplate.HTMLTemplates)
  const HTMLTemplatesStatus = useSelector((state) => state.HTMLTemplate.HTMLTemplatesStatus,)

  useEffect(() => {
    dispatch(fetchHTMLTemplates()).then((res) => {
    })
  }, [])

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinish = async (values) => {
    const data ={
      id : props.id,
      updatedHTMLTemplate : values.htmlTemplates_id
    }
    /*dispatch(updateHTMLTemplate(data))
    .unwrap()
    .then((res) => {
      openNotificationWithIcon('success', `Updated successfully`)
      //form.resetFields()
    })
    .catch((err) => {
      setHandledError(err)
      handleOpenErrorModal()
    })*/
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        style={{ zIndex: 9 }}
      >
        <DialogTitle id="form-dialog-title">
          {t('Edit mail template')}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              scrollToFirstError
              style={{ zIndex: 9999 }}
              onFinish={onFinish}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <Form.Item
                    label={t('Template')}
                    name="htmlTemplates_id"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a htmlTemplates_id',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '500px' }}
                      placeholder={t('Select htmlTemplates_id')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {HTMLTemplatesStatus === 'succeeded' &&
                        HTMLTemplates?.map((type) => (
                          <Option
                            key={type.id}
                            value={type.id}
                            style={{ zIndex: 9999 }}
                          >
                            {` ${type.name} / ${type.type}`}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <br />
                  <br />
                </GridItem>
              </GridContainer>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Form.Item>
                    <GridContainer>
                      <GridItem>
                        <Button
                          color="primary"
                          onClick={() => {
                            props.handleClose()
                          }}
                        >
                          {t('Cancel')}
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button type="submit" color="primary">
                          {t('Save')}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </Form.Item>
                </GridItem>
              </GridContainer>
            </Form>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
