import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '../../../../components/CustomButtons/Button'
//../../components/CustomButtons/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
// nodejs library to set properties for components
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'

import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
//import { Checkbox } from 'antd'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// material-ui icons
import MoreVert from '@material-ui/icons/MoreVert'

import Tooltip from '@material-ui/core/Tooltip'

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { Link } from 'react-router-dom'
import { getId } from '../../../../reduxToolkit/features/course/CoursesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  deleteCLO,
  fetchClos,
} from '../../../../reduxToolkit/features/CLO/CloSlice'
import { fetchCourseById } from '../../../../reduxToolkit/Course/coursesSpecSlice'

import { useTranslation } from 'react-i18next'
import SuperGeneralDeleteModal from '../../../../views/Components/SuperGeneralDeleteModal'
import ErrorModal from '../../../../views/Components/ErrorModal'
import ListItemText from '@material-ui/core/ListItemText'
import { notification } from 'antd'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import { deleteAssessmentMethod } from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import { fetchAssessmentMethods } from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import { fetchEvidenceTypeStatesConfigs } from 'reduxToolkit/features/EvidenceType/EvidenceTypeSlice'

const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)
const { Option } = Select

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))
export default function DropDownAssessmentMethodButton(props) {
  const handleSend = () => {}
  function onChangeInstructor(value) {}
  const { t } = useTranslation()
  const buttonClasses = useButtonStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const dispatch = useDispatch()

  const classes = useStyles()
  const modesStatus = useSelector(
    (state) => state.assessmentModeSpec.assessmentModesStatus,
  )

  useEffect(() => {
    if (modesStatus === 'idle') {
      dispatch(fetchEvidenceTypeStatesConfigs())
    }
  }, [modesStatus, dispatch])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleDeleteItem = async (id) => {
    console.log(id)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    Height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const onChangeRequiredEvidence = (e) => {
    setRequiredEvidence(e.target.checked)
    //console.log(e.target.checked)
  }
  const onChangeEvidenceTypeValue = (e) => {
    //console.log(e.target.value)
    setEvidenceTypeValue(e.target.value)
  }
  const [openModalEvidence, setOpenModalEvidence] = useState(false)
  const handleOpenEvidence = () => setOpenModalEvidence(true)
  const handleCloseEvidence = () => setOpenModalEvidence(false)
  const [evidenceTypeValue, setEvidenceTypeValue] = useState('')
  const [requiredEvidence, setRequiredEvidence] = useState(false)
  const [openModalEditEvidence, setOpenModalEditEvidence] = useState(false)
  const handleOpenEditEvidence = () => setOpenModalEditEvidence(true)
  const handleCloseEditEvidence = () => setOpenModalEditEvidence(false)

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  const types = useSelector(
    (state) => state.EvidenceTypeStatesConfig.EvidenceTypeStatesConfigs,
  )

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
       
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />{' '}
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
