import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";
import { unwrapResult } from "@reduxjs/toolkit";

import { useTranslation } from "react-i18next";
import { Form, Input, Select, notification, DatePicker, Checkbox } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

import AddIcon from "@material-ui/icons/Add";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import {
  addNewCvTechSkills,
  fetchCvTechSkillsByPersonId,
} from "../../../../../reduxToolkit/features/Skills/CvTechSkillsSlice.js";

import {
  fetchConfigSkills,
  addNewConfigSkills,
} from "../../../../../reduxToolkit/features/Skills/ConfigSkillsSlice.js";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

export default function AddSkillsModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);

  const { Option } = Select;
  const { TextArea } = Input;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [staff_id, setPerson_id] = React.useState(props.person_id);

  const [skills_ids, setSkills_ids] = useState([]);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  useEffect(() => {
    dispatch(fetchConfigSkills());
  }, [dispatch]);

  const keyWords = useSelector((state) => state.ConfigSkills.ConfigSkills);
console.log(keyWords,"keyWords")
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewCvTechSkills({
        skills_ids,
        staff_id,
      })
    );
    if (addNewCvTechSkills.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchCvTechSkillsByPersonId(props.person_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  // const handleChange = (value) => {
  //   console.log("value", value);
  //   if (value.length > 0) {
  //     // handle result here
  //     let chosenKWObjects =
  //     ConfigSkills && ConfigSkills.filter((kw) => value.includes(kw.keyword));
  //     console.log("chosenKWObjects", chosenKWObjects);
  //     let ids = chosenKWObjects.map((el) => {
  //       return el.id;
  //     });
  //     let mapped =
  //     ConfigSkills &&
  //     ConfigSkills.map((el) => {
  //         return el.keyword;
  //       });
  //     const keyword = [...value].pop();
  //     console.log("keyword", keyword);
  //     console.log("mapped", mapped);

  //     if (mapped.includes(keyword)) {
  //       setSkills_ids(ids);
  //     } else {
  //       dispatch(addNewConfigSkills({keyword}))
  //         .then(unwrapResult)
  //         .then((newKeyWord) => {
  //           console.log("added key word", newKeyWord);
  //           setSkills_ids(ids.push(newKeyWord.id));
  //         });
  //     }
  //   } else {
  //     setSkills_ids([]);
  //   }
  // };

  const handleChange = (value) => {
    if (value.length > 0) {
      // handle result here
      let chosenKWObjects =
        keyWords && keyWords.filter((kw) => value.includes(kw.keyword));
      console.log("chosenKWObjects", chosenKWObjects);
      let ids = chosenKWObjects.map((el) => {
        return el.id;
      });
      console.log("ids", ids);
      let mapped =
        keyWords &&
        keyWords.map((el) => {
          return el.keyword;
        });

      const keyword = [...value].pop();
      if (mapped.includes(keyword)) {
        setSkills_ids(ids);
      } else {
        dispatch(addNewConfigSkills({ keyword }))
          .then(unwrapResult)
          .then((newKeyWord) => {
            console.log("added key word", newKeyWord);
            ids.push(newKeyWord.id)
            setSkills_ids(ids);
      
          });
          
          dispatch(fetchConfigSkills());
      }
    } else {
      setSkills_ids([]);
    }
  };
  console.log("Skills_ids", skills_ids);

  return (
    <div>
      <Button
        color="primary"
        round
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={(event) => {
          handleClickOpen(event);
        }}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
      >
        <AddIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Skills ")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="skills_ids"
                  label="Skills"
                  rules={[
                    {
                      required: true,
                      message: "Please input the type of skills field.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Skills "
                    dropdownStyle={{ zIndex: 10000 }}
                    mode="tags"
                    onChange={handleChange}
                  >
                    {keyWords &&
                      keyWords.map((keyWord) => (
                        <Option key={keyWord.id} value={keyWord.keyword}>
                          {keyWord.keyword}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
