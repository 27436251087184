// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Select, notification, Form } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/assessmentMethodSlice";
import {
  fetchTeachingStrategies,
  updateTeachingStrategies,
} from "../../../../../reduxToolkit/features/TeachingStrategies/TeachingStrategiesSlice";

import { DataGrid } from "@mui/x-data-grid";
import ErrorModal from "../../../../Components/ErrorModal";
import EditTeachingStrategiesDifficultiesAction from ".././../../../Components/EditTeachingStrategiesDifficultiesAction";

import {
  fetchConclusionsDifficultiesByCourseId_And_Type,
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";

import {
  primaryColor,
} from "../../../../../assets/jss/material-dashboard-pro-react"

const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 14,
    span: 0,
  },
};

export default function TeachingStrategies(props) {

  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [value, setValue] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rows, setRows] = useState([]);
  const [array_difficulties_SuggestedAction, setArray_difficulties_SuggestedAction] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [open, setOpen] = useState(false);
  const [course_id, setCourse_id] = useState(props.idCourse);
  const [difficulties, setDifficulties] = useState("");
  const [suggested, setSuggested] = useState("");
  const [teachingStrategies_ids, setTeachingStrategies_ids] = useState([]);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const course = useSelector((state) => state.course.course);
  const TeachingStrategies = useSelector((state) => state.TeachingStrategie.TeachingStrategiesApprovement);
  const difficulties_SuggestedAction = useSelector((state) => state.ConclusionsDifficulties.ConclusionsDifficultiesList);

  useEffect(() => {
    dispatch(fetchTeachingStrategies(props.idCourse))
        .then(unwrapResult)
        .then((TeachingStrategies) => {
          setTeachingStrategies_ids(TeachingStrategies.map((el) => el.id));
          setRows([...TeachingStrategies]);
        });
  }, []);

  useEffect(() => {
    dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
        .then(unwrapResult)
        .then((difficulties_SuggestedAction) => {
          setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
        });
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  let object={
    course_id: props.idCourse,
    type: "TS",
}

const fetchConclusionsDifficulties =()=>{
  dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
  .then(unwrapResult)
    .then((difficulties_SuggestedAction) => {
      setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
    });
}

  const onDifficultiesChanged = (e) => setDifficulties(e.target.value);
  const onSuggestedChanged = (e) => setSuggested(e.target.value);
  const onCourse_idChanged = (e) => setCourse_id(e.target.value);
  const { Option } = Select;
  const canSave = [difficulties, suggested, course_id].every(Boolean) && addRequestStatus === "idle";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCellEditCommit = async (event) => {
    const value = event.field === "implemented" ? event.value : event.value;
    const field = event.field;
    const updated = {
      [field]: value,
    };
    const id = event.id;
    const resultAction = await dispatch(
      updateTeachingStrategies({ id: id, updated: updated })
    );
    if (updateTeachingStrategies.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Updated successfully`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  return (
    <GridContainer xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <div style={{ height: 400 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                columnBuffer={0}
                autoPageSize={true}
                disableColumnMenu={true}
                onCellEditCommit={onCellEditCommit}
                showCellRightBorder
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                showColumnRightBorder
                pagination={false}
              />
            </div>
          </CardBody>
        </Card>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </GridItem>

      <Card style={{ marginRight:"10px"}}>
        <CardBody>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={10}>
              <p style={{ height: "150px" }}>
              <strong style={{color:primaryColor[0]}}>Difficulties Experienced</strong> :
              <p>{array_difficulties_SuggestedAction && array_difficulties_SuggestedAction[0] && array_difficulties_SuggestedAction[0].difficulties}</p>
              </p>
              <p style={{ height: "150px" }}>  <strong style={{color:primaryColor[0]}}>Suggested Action</strong> :
              <p>{array_difficulties_SuggestedAction && array_difficulties_SuggestedAction[0] && array_difficulties_SuggestedAction[0].suggested}</p>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} style={{textAlign:"end"}}>
              <EditTeachingStrategiesDifficultiesAction    idCourse= {props.idCourse} fetchConclusionsDifficulties={fetchConclusionsDifficulties}/>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
const columns = [
  {
    field: "planned_teaching_strategy_name",
    headerName: "Planned Teaching Strategies",
    width: 450,
    sortable: false,
  },
  {
    field: "implemented",
    headerName: "Were They Implemented?",
    width: 391,
    editable: true,
    sortable: false,
    type: "boolean",
  },
];
