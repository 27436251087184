import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import { Breadcrumb } from 'antd';
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  grayColor,
  primaryColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
import {
  fetchDepartments,
  selectAllDepartments,deleteDepartment
} from '../../../../reduxToolkit/features/department/DepartmentsSlice'

import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import { notification } from 'antd'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import ErrorModal from 'views/Components/ErrorModal'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const DepartmentExcerpt = ({ department }) => {

  return (
    <GridItem xs={12} sm={6} md={4}>
      <Card style={{ height: 300 }}>
        <CardHeader>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Link to={`/citrine/organizations/departments/${department.id}`}>
                <h3 style={{ color: `${primaryColor[0]}` }}>
                  {department.name}
                </h3>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <DropDownDepartment id={department.id}/>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem>
              <h4>
                {`${
                  department?.mission?.length > 100 ? `${department?.mission?.substring(0, 100)}...` : department?.mission ? department?.mission : ''
                }`}
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem>
              <Link to={`/citrine/organizations/departments/${department.id}`}>
                <Button color="primary">View</Button>
              </Link>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function DepartmentsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const departmentsStatus = useSelector(
    (state) => state.department.departmentsStatus,
  )

  useEffect(() => {
    if (departmentsStatus === 'idle') {
      dispatch(fetchDepartments())
    }
  }, [departmentsStatus, dispatch])

  const departments = useSelector(selectAllDepartments)
  let content

  if (departmentsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (departmentsStatus === 'succeeded') {
    content =
      departments &&
      departments.map((department) => (
        <DepartmentExcerpt key={department.id} department={department} />
      ))
  } else if (departmentsStatus === 'failed') {
    content = <div>An error has accured</div>
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
        <Breadcrumb separator=">">

            <Breadcrumb.Item>
              <Link to={`/citrine/organizations`}>
                <span > {t('Organizations')} </span>
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/organizations/departments`}>
                <span > {t('Departments')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/organizations/departments/create/:id">
        <Button color="primary">{t(' Add')}</Button>
      </Link>
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {content}
      </GridContainer>
    </div>
  )
}

const DropDownDepartment = ({ id }) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleDeleteItem = async (id) => {
    dispatch(deleteDepartment(id))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchDepartments())
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />{' '}
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
