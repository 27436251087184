import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  fields: [],
  fieldsStatus: 'idle',
  fieldStatus: 'idle',
  error: null,
  field:null,
}

export const fetchFields = createAsyncThunk('field/fetchFields', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/field/', 
    {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
      
      )
      // console.log(response.data,'response.data')

    return response.data
  })

  export const fetchFieldById = createAsyncThunk('field/fetchFieldById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/field/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
  export const addNewField = createAsyncThunk(
    'field/addNewField',
    async (initialField) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/field/create/`,initialField ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )

  export const updateField = createAsyncThunk(
    'field/updateField',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/field/${data.id}/edit/`,data.updatedField ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedField')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the Field: ', error)

}
    }
  )


//   export const additionalUpdateProgram = createAsyncThunk(
//     'program/additionalUpdateProgram',
//     async (data,thunkAPI) => {
      
//       try{
//         const response = await axios.put(`http://127.0.0.1:8000/api/program/${data.id}/update/`,data.updatedField ,{
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })   
//       console.log(data,'updated')
//       console.log(response.data,'response.data')   
//       return response.data


//     } catch (error) {
// console.error('Failed to update the program: ', error)

// }
//     }
//   )

//   export const deleteProgram = createAsyncThunk('program/deleteProgram', async (id) => {
//     const response = await axios.delete(`http://127.0.0.1:8000/api/program/${id}/delete/`, {
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })
//       // console.log(response.data,'response.data')

//     return response.data
//   })
const  fieldsSlice = createSlice({
  name: ' field',
  initialState,
  extraReducers: {

    [fetchFields.pending]: (state, action) => {
        state.fieldsStatus = 'loading'
      },
      [fetchFields.fulfilled]: (state, action) => {
        state.fieldsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.fields = state.fields.concat(action.payload)
      },
      [fetchFields.rejected]: (state, action) => {
        state.fieldsStatus = 'failed'
        state.error = action.payload
      },

      [fetchFieldById.pending]: (state, action) => {
        state.fieldStatus = 'loading'
      },
      [fetchFieldById.fulfilled]: (state, action) => {
        state.fieldStatus = 'succeeded'

        // Add any fetched posts to the array
        state.field = action.payload
      },
      [fetchFieldById.rejected]: (state, action) => {
        state.fieldStatus = 'failed'
        state.error = action.payload
      },
      [addNewField.fulfilled]: (state, action) => {
        state.fields.push(action.payload)
      },
    //   [deleteProgram.fulfilled]: (state, action) => {
    //     // state.programs.push(action.payload)
    //   },
      [updateField.fulfilled]: (state, action) => {
        const result = state.fields.filter(field => field.id !== action.payload.id);
        // console.log("program id from filter from slice",program.id)
        result.push(action.payload)
        state.fields = result       
        // console.log("action.payload.id from slice",action.payload.id)

      },
    //   [additionalUpdateProgram.fulfilled]: (state, action) => {

    //   console.log("result",action.payload)
    //   },
  },

  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    

//     programUpdated(state, action) {
//       const { id,name, code, description,period,start_date,end_date } = action.payload
//       const existingProgram = state.programs.find(program => program.id === id)
//       if (existingProgram) {
//         existingProgram.name = name
//         existingProgram.code = code
//         existingProgram.description = description
//         existingProgram.period = period
//         existingProgram.start_date = start_date
//         existingProgram.end_date = end_date

//       }
//     }
   }
})

export const { fieldAdded, fieldUpdated, getId } = fieldsSlice.actions

export default fieldsSlice.reducer

export const selectAllFields = state => state.field.fields

// export const selectProgramBySlug = (state, slug) =>
//   state.program.programs.find(program => program.slug === slug)