import { Breadcrumb } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useState } from 'react'

import Menu from '@material-ui/core/Menu'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '../../../../components/CustomButtons/Button'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchEvidenceTypeStatesConfigs } from '../../../../reduxToolkit/features/EvidenceType/EvidenceTypeSlice'
import EvidenceTypeDown from './EvidenceTypeDown'


const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default function EvidenceTypeConfigPage() {

  const [openModalEvidence, setOpenModalEvidence] = useState(false)
  const handleCloseEvidence = () => setOpenModalEvidence(false)
  const handleOpenEvidence = () => setOpenModalEvidence(true)

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [evidenceTypeList, setEvidenceTypeList] = useState([])
  const cardClasses = useCardStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const classes = useStyles()
  useEffect(() => {
    dispatch(fetchEvidenceTypeStatesConfigs())
  }, [dispatch])

  const evidenceTypeById = EvidenceTypeStatesConfigsItem
  const EvidenceTypeStatesConfigsItem = useSelector(
    (state) => state.EvidenceTypeStatesConfig.EvidenceTypeStatesConfigs,
  )
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const onFinish = async () => {
    handleClose()
  }

  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/EvidenceTypeConfigPage`}>
                <span> {t('Evidence Type Page')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to={`/citrine/configuration/others/AddEvidenceTypeConfigPage`}>
        <Button color="info">{t('  Add')}</Button>
      </Link>
      <GridContainer>
        {EvidenceTypeStatesConfigsItem &&
          EvidenceTypeStatesConfigsItem.map((i) => (
            <GridItem xs={12} sm={12} md={4} key={i.id}>
              <Card
                style={{
                  maxWidth: '380px',
                  minWidth: '380px',
                  maxHeight: '10px',
                  minHeight: '100px',
                }}
              //cardHover
              >
                <CardHeader>
                  <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    
                    <GridItem xs={10} sm={10} md={10}>
                      <h4 className={cardClasses.cardProductTitle}>
                        {`${i.type}`}
                      </h4>
                    </GridItem>

                    <GridItem xs={2} sm={2} md={2}>
                      <EvidenceTypeDown el={i} />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  )
}
