import React, { useEffect, useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  ActivitiesAssessmentMethodSpecUpdate,
  addNewActivitiesAssessmentMethodSpec,
  fetchActivitiesAssessmentMethodSpecById,
  fetchActivitiesAssessmentMethodsSpec,
} from "../../../../../../reduxToolkit/features/ActivitiesAssessmentMethod/ActivitiesAssessmentMethodSpecSlice";
import { fetchAssessmentMethods } from "reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec";
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";

import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import "antd/dist/antd.css";
import "assets/css/styles.css";
import {
  Form,
  Input,
  notification,
  Breadcrumb,
  InputNumber,
  Select,
  DatePicker,
} from "antd";

import moment from "moment";

import { useTranslation } from "react-i18next";
import ErrorModal from "views/Components/ErrorModal";

import isDate2GreaterThanDate1 from "assets/utils/helperFunction";

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 7,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 13,
    span: 13,
  },
};

export default function CreateActivitiesAssessmentMethods({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = match.params;
  const { idAAM } = match.params;
  const [form] = Form.useForm();

  const [start_date, setStart_date] = useState(null);
  const [startDateDPProps, setStartDateDPProps] = useState();

  const [due_date, setDue_date] = useState(null);
  const [dueDateDPProps, setDueDateDPProps] = useState();
  const [due_date_validation_status, setDue_date_validation_status] =
    useState("");

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const handleStartDateChange = (date, dateString) => {
    setStart_date(dateString);
  };

  const handleDueDateChange = (date, dateString) => {
    setDue_date(dateString);
  };

  useEffect(() => {
    if (start_date && due_date) {
      isDate2GreaterThanDate1(start_date, due_date)
        ? setDue_date_validation_status("")
        : setDue_date_validation_status("error");
    }
  }, [start_date, due_date]);

  useEffect(() => {
    dispatch(fetchAssessmentMethods());
  }, []);

  useEffect(() => {
    if (idAAM) {
      dispatch(fetchActivitiesAssessmentMethodSpecById(idAAM))
        .then(unwrapResult)
        .then((AAM) => {
          form.setFieldsValue({
            planned_assessment_method_spec_id:
              AAM.planned_assessment_method_spec_id,
            courseSpec_id: AAM.courseSpec_id,
            grading: AAM.grading,
          });

          if (AAM.start_date) {
            setStartDateDPProps({
              defaultValue: moment(AAM.start_date, "YYYY-MM-DD"),
            });
          } else {
            setStartDateDPProps({});
          }

          if (AAM.due_date) {
            setDueDateDPProps({
              defaultValue: moment(AAM.due_date, "YYYY-MM-DD"),
            });
          } else {
            setDueDateDPProps({});
          }

          setStart_date(AAM.start_date);
          setDue_date(AAM.due_date);
        });
    }
  }, [idAAM]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const assessmentMethods = useSelector(
    (state) => state.assessmentMethod.assessmentMethods
  );

  const onFinish = async (values) => {
    console.log("values", values);

    const resultAction = await dispatch(
      addNewActivitiesAssessmentMethodSpec({
        ...values,
        start_date,
        due_date,
        courseSpec_id: id,
      })
    );
    if (addNewActivitiesAssessmentMethodSpec.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchActivitiesAssessmentMethodsSpec(id));
      history.push(
        `/citrine/specification/course/${id}/ActivitiesAssessmentMethods`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
    setDue_date_validation_status("");
  };

  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      ActivitiesAssessmentMethodSpecUpdate({
        id: idAAM,
        modified: {
          ...values,
          start_date,
          due_date,
          courseSpec_id: id,
        },
      })
    );
    if (ActivitiesAssessmentMethodSpecUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchActivitiesAssessmentMethodsSpec(id));
      history.push(
        `/citrine/specification/course/${id}/ActivitiesAssessmentMethods`
      );
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
    setDue_date_validation_status("");
    setStartDateDPProps(null);
    setDueDateDPProps(null);
  };

  console.log("startDateDPProps", startDateDPProps);
  console.log("dueDateDPProps", dueDateDPProps);

  return (
    <>
      {!idAAM && (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to={`/citrine/CourseSpecificationList/`}>
                    {t("Courses List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/courseSpecDetails/${id}`}>
                    {course?.title}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods`}
                    style={{ color: "orange" }}
                  >
                    {" "}
                    {t("Activities Assessment Methods List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon></CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="planned_assessment_method_spec_id"
                      label="Planned Assessment Methods"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select allowClear style={{ width: "100%" }}>
                        {assessmentMethods &&
                          assessmentMethods.map((r) => (
                            <option key={r.id} value={r.id}>
                              {r.name}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="grading"
                      label="Grading"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min="0"
                        max="100"
                      />
                    </Form.Item>

                    <Form.Item name="start_date" label="Strat date">
                      <DatePicker
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        onChange={handleStartDateChange}
                      />
                    </Form.Item>

                    <Form.Item
                      name="due_date"
                      label="Due date"
                      validateStatus={due_date_validation_status}
                      help="The assessment due date should be greater than the assessment start date"
                    >
                      <DatePicker
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        onChange={handleDueDateChange}
                      />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(
                            `/citrine/specification/course/${id}/ActivitiesAssessmentMethods`
                          )
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
      {idAAM && startDateDPProps && dueDateDPProps && (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link to={`/citrine/programs`}>{t("Programs List")}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/CourseSpecificationList/`}>
                    {t("Courses List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/courseSpecDetails/${id}`}>
                    {course?.title}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={`/citrine/specification/course/${id}/ActivitiesAssessmentMethods`}
                    style={{ color: "orange" }}
                  >
                    {" "}
                    {t("Activities Assessment Methods List")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon></CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinishUpdate}
                  >
                    <Form.Item
                      name="planned_assessment_method_spec_id"
                      label="Planned Assessment Methods"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="Select Assessment Method" allowClear>
                        {assessmentMethods &&
                          assessmentMethods.map((r) => (
                            <option key={r.id} value={r.id}>
                              {r.name}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="grading"
                      label="Grading"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min="0"
                        max="100"
                      />
                    </Form.Item>
                    <Form.Item name="start_date" label="Strat date">
                      <DatePicker
                        {...startDateDPProps}
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        onChange={handleStartDateChange}
                      />
                    </Form.Item>

                    <Form.Item
                      name="due_date"
                      label="Due date"
                      validateStatus={due_date_validation_status}
                      help="The assessment due date should be greater than the assessment start date"
                    >
                      <DatePicker
                        {...dueDateDPProps}
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        onChange={handleDueDateChange}
                      />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(
                            `/citrine/specification/course/${id}/ActivitiesAssessmentMethods`
                          )
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
