import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchPersons } from '../../reduxToolkit/features/person/PersonsSlice'
import { updateCourseAssessmentMethod } from '../../reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSlice'
//import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Select } from 'antd';
import { Radio } from 'antd';
import { Form, Input, Checkbox } from 'antd';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import { fetchCourseById } from '../../reduxToolkit/features/course/CoursesSlice'
import { fetchCourseAssessmentMethodById } from '../../reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSlice'

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));

export default function UpdateResponsileInputsModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const personsStatus = useSelector(state => state.person.personsStatus)
    const [value, setValue] = React.useState(1);


    useEffect(() => {
        if (personsStatus === 'idle') {
            dispatch(fetchPersons())
        }
        // dispatch(fetchCourseAssessmentMethodById(props.id))
    }, [personsStatus, dispatch])

    const persons = useSelector(state => state.person.persons)
    const courseAssessmentMethod = useSelector(state => state.courseAssessmentMethod.courseAssessmentMethod)

    console.log('persons', persons)
    const [open, setOpen] = React.useState(false);

    const { Option } = Select;
    const [responsible_of_inputs_id, setResponsible_of_inputs_id] = React.useState([]);

    function onInstructorChange(value) {
        console.log("value", value);
        setResponsible_of_inputs_id(value)
        console.log("responsible_of_inputs_id", responsible_of_inputs_id);
    }

    function onInstructorBlur() {
        console.log('blur');
    }

    function onInstructorFocus() {
        console.log('focus');
    }

    function onInstructorSearch(val) {
        console.log('search:', val);
    }

    const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')


    console.log('props.CourseAssessmentMethod', props.CourseAssessmentMethod)
    console.log('props.id', props.id)
    const handleSend = async () => {
        try {
            let modifiedCourseAssessmentMethod = {
                weight: courseAssessmentMethod.weight,
                total_marks: courseAssessmentMethod.total_marks,
                assessmentMethod_id: courseAssessmentMethod.assessmentMethod_id,
                code: courseAssessmentMethod.code,
                date_of_input: courseAssessmentMethod.date_of_input,
                percentage_note: courseAssessmentMethod.percentage_note,
                instructor_id: courseAssessmentMethod.instructor_id,
                clo_ids: courseAssessmentMethod.clo_ids,
                course_id: props.courseID
            }

            setUpdateRequestStatus('pending')
            const resultAction = await dispatch(
                updateCourseAssessmentMethod({ id: props.id, updatedcourseAssessmentMethod: { ...modifiedCourseAssessmentMethod, responsible_of_inputs_id } })
            )
        } catch (err) {
            console.log('Failed to save the CourseAssessmentMethod: ', err)
        } finally {
            setUpdateRequestStatus('idle')
            dispatch(fetchCourseById(props.courseID))
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const classes = useStyles();


    return (
        <div>
            <MenuItem
                onClick={(event) => {
                    handleClickOpen(event); props.handleClose();
                }}>
                {t('Affect Responsible Of Inputs')}
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('Select New Responsile Inputs')} </DialogTitle>
                <DialogContent>
                    <div>
                        <Select
                            showSearch
                            // mode="multiple"
                            style={{ width: 200, color: "grey" }}
                            dropdownStyle={{ zIndex: 10000 }}
                            placeholder={t('Select a instructor')}
                            optionFilterProp="children"
                            onChange={onInstructorChange}
                            onFocus={onInstructorFocus}
                            onBlur={onInstructorBlur}
                            onSearch={onInstructorSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {persons && persons.map((person) =>
                                <Option value={person.id}>{`${person.first_name}` + " " + `${person.last_name}`}</Option>
                            )}

                        </Select>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                {t('Cancel')}
                            </Button>
                            <Button onClick={() => { handleClose(); handleSend() }} color="primary">
                                {t('Send')}
                            </Button>
                        </DialogActions>
                    </div>


                </DialogContent>

            </Dialog>
        </div>
    );
}
