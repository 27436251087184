import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchStaffById
} from "../../../../../../src/reduxToolkit/features/user/UserSlice";
import {
  grayColor
} from "../../../../../assets/jss/material-dashboard-pro-react";
import ErrorModal from "../../../../Components/ErrorModal";

import { IconContext } from "react-icons";
import { GiArchiveResearch } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function ResearchActivityTypeChoice({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { id } = match.params;

  useEffect(() => {
    dispatch(fetchStaffById(id));



  }, [])

  const person = useSelector((state) => state.user.staff);



  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/teachingStaff`}>
                {t("Teaching Staff")}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/teachingStaff/${id}`}>
                {`${person && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/teachingStaff/${id}/researchChoice`}>
                {t("Research and innovation Types")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <GridItem>
          <CustomInput
            formControlProps={{
              className: navBarClasses.top + " " + navBarClasses.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: navBarClasses.searchInput,
              },
            }}
          />
        </GridItem>
      </GridContainer>


      <GridContainer>
        <GridItem className="HoverCard" xs={12} sm={12} md={3}>
          <Link
            //to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities/create`}
            to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`}

          >
            <Card
              style={{
                height: "150px",
                textAlign: "center",
                border: "1px solid lightgray",
                borderRaduis: "10px",
                boxShadow: "2px 2px 2px lightgray",
              }}
            >
              <CardBody>
                <IconContext.Provider value={{ size: "3em" }}>
                  <GiArchiveResearch />
                </IconContext.Provider>
                <strong>
                  <h4 style={{ marginTop: "10%" }}>
                    {t("Research Activities")}
                  </h4>
                </strong>
              </CardBody>
            </Card>
          </Link>
        </GridItem>
        <GridItem className="HoverCard" xs={12} sm={12} md={3}>
          <Link
            //to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities/create`}
            to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivitiesOutputs`}

          >
            <Card
              style={{
                height: "150px",
                textAlign: "center",
                border: "1px solid lightgray",
                borderRaduis: "10px",
                boxShadow: "2px 2px 2px lightgray",
              }}
            >
              <CardBody>
                <IconContext.Provider value={{ size: "3em" }}>
                  <IoIosPeople />
                </IconContext.Provider>
                <strong>
                  <h4 style={{ marginTop: "10%" }}>
                    {t("Research Outputs")}
                  </h4>
                </strong>
              </CardBody>
            </Card>
          </Link>
        </GridItem>

      </GridContainer>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
