import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  accessControls: [],
  accessControl: null,
  accessControlStatus: 'idle',
  accessControlsStatus: 'idle',
  modelGroupPermissions: [],
  modelGroupPermissionsStatus: 'idle',
  modelGroupPermissionStatus: 'idle',
  error: null,
  modelGroupPermission: null,
  id: null,
}

export const fetchModelGroupPermissions = createAsyncThunk('modelGroupPermission/fetchModelGroupPermissions', async () => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + '/modelGroupPermission/', {
    headers: {
      Authorization: `Token ${token}`,
    },
  })
  //console.log(response.data, 'response.data')

  return response.data
})

export const fetchModelGroupPermissionById = createAsyncThunk(
  'modelGroupPermission/fetchModelGroupPermissionById',
  async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/modelGroupPermission/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    })
    //console.log(response.data, 'response.data fetch by id')

    return response.data
  },
)
export const addNewModelGroupPermission = createAsyncThunk(
  'modelGroupPermission/addNewModelGroupPermission',
  async (initialModel) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(
      BaseUrl + `/modelGroupPermission/create/`,
      initialModel,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      },
    )
    console.log(response.data)
    return response.data
  },
)
export const modelGroupPermissionUpdate = createAsyncThunk(
  'modelGroupPermission/modelGroupPermissionUpdate',
  async (obj, thunkAPI) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/modelGroupPermission/${obj.id}/edit/`,
        obj.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      //    console.log(data,'updatedModel')
      //  console.log(response.data,'response.data')
      return response.data
    } catch (error) {
      console.error('Failed to update the modelGroupPermission: ', error)
    }
  },
)
export const deleteModelGroupPermission = createAsyncThunk('modelGroupPermission/deleteModelGroupPermission', async (id) => {
  const token = localStorage.getItem('token')
  const response = await axios.delete(BaseUrl + `/modelGroupPermission/${id}/delete/`, {
    headers: {
      Authorization: `token ${token}`,
    },
  })
  return response.data
})



const modelGroupPermissionSlice = createSlice({
  name: ' modelGroupPermission',
  initialState,
  extraReducers: {
    [fetchModelGroupPermissions.pending]: (state, action) => {
      state.modelGroupPermissionsStatus = 'loading'
    },
    [fetchModelGroupPermissions.fulfilled]: (state, action) => {
      state.modelGroupPermissionsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.modelGroupPermissions = action.payload
    },
    [fetchModelGroupPermissions.rejected]: (state, action) => {
      state.modelGroupPermissionsStatus = 'failed'
      state.error = action.payload
    },

    [fetchModelGroupPermissionById.pending]: (state, action) => {
      state.modelGroupPermissionStatus = 'loading'
    },
    [fetchModelGroupPermissionById.fulfilled]: (state, action) => {
      state.modelGroupPermissionStatus = 'succeeded'

      // Add any fetched posts to the array
      state.modelGroupPermission = action.payload
    },
    [fetchModelGroupPermissionById.rejected]: (state, action) => {
      state.modelGroupPermissionStatus = 'failed'
      state.error = action.payload
    },
    [addNewModelGroupPermission.fulfilled]: (state, action) => {
      state.modelGroupPermissions.push(action.payload)
    },
    [modelGroupPermissionUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [deleteModelGroupPermission.fulfilled]: (state, action) => {
      console.log(action)
    },
  },
 
})


export default modelGroupPermissionSlice.reducer

