import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  GraduateAttributes: [],
  GraduateAttributeStatus: "idle",
  GraduateAttributesStatus: "idle",
  error: null,
  GraduateAttribute: null,
};

export const fetchGraduateAttributesSpec = createAsyncThunk(
  "GraduateAttributeSpec/fetchGraduateAttributesSpec",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/graduateAttributeSpec/?programSpec_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchGraduateAttributeSpecById = createAsyncThunk(
  "GraduateAttributeSpec/fetchGraduateAttributeSpecById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/graduateAttributeSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const addNewGraduateAttributeSpec = createAsyncThunk(
  "GraduateAttributeSpec/addNewGraduateAttributeSpec",
  async (initialPlo, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/graduateAttributeSpec/create/`,
        initialPlo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateGraduateAttributeSpec = createAsyncThunk(
  "GraduateAttributeSpec/updateGraduateAttributeSpec",
  async (data,  { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/graduateAttributeSpec/${data.id}/edit/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedGraduateAttributeSpec");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the GraduateAttributeSpec: ", error);
    }
  }
);

export const deleteGraduateAttributeSpec = createAsyncThunk(
  "GraduateAttributeSpec/deleteGraduateAttributeSpec",
  async (id,{rejectWithValue}) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/graduateAttributeSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

const GraduateAttributeSpecSlice = createSlice({
  name: " GraduateAttributeSpec",
  initialState,
  extraReducers: {
    //  fetched GraduateAttributeSpec
    [fetchGraduateAttributesSpec.pending]: (state, action) => {
      state.GraduateAttributesStatus = "loading";
    },
    [fetchGraduateAttributesSpec.fulfilled]: (state, action) => {
      state.GraduateAttributesStatus = "succeeded";
      state.GraduateAttributes = action.payload;
    },
    [fetchGraduateAttributesSpec.rejected]: (state, action) => {
      state.GraduateAttributesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    //  fetched GraduateAttributeSpec by ID
    [fetchGraduateAttributeSpecById.pending]: (state, action) => {
      state.GraduateAttributeStatus = "loading";
    },
    [fetchGraduateAttributeSpecById.fulfilled]: (state, action) => {
      state.GraduateAttributeStatus = "succeeded";
      state.GraduateAttribute = action.payload;
    },
    [fetchGraduateAttributeSpecById.rejected]: (state, action) => {
      state.GraduateAttributeStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)

      }
    },

    //  ADD  GraduateAttributeSpec
    [addNewGraduateAttributeSpec.fulfilled]: (state, action) => {
      state.GraduateAttributes.push(action.payload);
    },
    [addNewGraduateAttributeSpec.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },
    //  DELETE  GraduateAttributeSpec
    [deleteGraduateAttributeSpec.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    [deleteGraduateAttributeSpec.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        console.log("action.payload from inside extra add plo reducer", action.payload.statusText)
      } else {
        state.error = action.error
        console.log("action.error from inside extra add plo reducer", action.error)

      }
    },
    [updateGraduateAttributeSpec.fulfilled]: (state, action) => {
      console.log(
        "action.payload after update fulfilled from Graduate Attribute slice",
        action.payload
      );
    },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { programAdded } = GraduateAttributeSpecSlice.actions;

export default GraduateAttributeSpecSlice.reducer;
