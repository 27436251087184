import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb, notification } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import Search from '@material-ui/icons/Search'
import StarIcon from '@material-ui/icons/Star'
import CustomInput from 'components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ErrorModal from 'views/Components/ErrorModal'
import { grayColor } from '../../../assets/jss/material-dashboard-pro-react'
import { fetchAllActionsRecommendations } from '../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice'
import DropDownActionButton from '../../Components/DropDownActionButton.js'
import { Box } from '@material-ui/core'
import TableViewIcon from '@mui/icons-material/TableView'
import ViewListIcon from '@mui/icons-material/ViewList'
import { DataGrid } from '@material-ui/data-grid'
import Rating from '@material-ui/lab/Rating'
import Chip from '@material-ui/core/Chip'
import Stack from "@mui/material/Stack";

const currentDate = new Date(2021, 4, 11)
const views = ['agenda']

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const ActionExcerpt = ({ action, fetchAllActions }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const dispatch = useDispatch()

  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
        </div>
      )
    } else if (value == 2) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
        </div>
      )
    } else if (value == 3) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
        </div>
      )
    }
  }
  const StateRender = (state) => {
    const {stateId, suspend_reasons, cancel_reasons, feedback} = state;
    if (stateId == 1) {
      return (
        <div style={{ display: 'flex' }}>
          <h6>Created</h6>
        </div>
      )
    } else if (stateId == 2) {
      return (
        <div style={{ display: 'flex' }}>
          <h6>Reassigned </h6>
        </div>
      )
    } else if (stateId == 3) {
      return (
        <div style={{ display: 'flex' }}>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <h6>Done</h6>
            {
                feedback && <Chip label={feedback ?? ''}/>
            }
          </Stack>
        </div>
      )
    } else if (stateId == 4) {
      return (
        <div style={{ display: 'flex' }}>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <h6>Suspended</h6>
            {
                suspend_reasons && <Chip label={suspend_reasons ?? ''}/>
            }
          </Stack>
        </div>
      )
    } else if (stateId == 5) {
      return (
        <div style={{ display: 'flex' }}>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <h6>Canceled</h6>
            {cancel_reasons && <Chip label={cancel_reasons ?? ''}/>}
          </Stack>
        </div>
      )
    }
  }
  return (

    <GridItem xs={12} sm={12} md={4} key={action.id}>
      <Card style={{ width: 375, height: "400px" }} product className={classes.cardHover}>
        <CardBody >
          <GridContainer>
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={11}>
                <strong>
                  Action Type : {action.resource_details && action.resource_details.resource_type}
                </strong>
                <br></br>
                <strong>
                  <Link to={`/citrine/courseSpecDetails/${action.resource_details && action.resource_details.resource_id}`}>
                    {action.resource_details && action.resource_details.resource_code} : {action.resource_details && action.resource_details.resource_name}
                  </Link>
                </strong>
                <br></br>
                <br></br>
                <br></br>
                <strong>
                  {action.typeAction_details?.actiontype_name && action.typeAction_details?.actiontype_name} :
                </strong>
                {action.text && `${action.text.substring(0, 200)}...`}
                <br></br>
                <strong> Assigned to : </strong>
                {action.assign_to}
                <br></br>
                <strong> Start date : </strong>
                {action.start_date && action.start_date}
                <br></br>
                <strong> Due date : </strong>
                {action.endDate && action.endDate}
                <br></br>
                <strong>
                  {' '}
                  Priority : {RaitingRender(action && action.priorityId)}
                </strong>
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>
                <DropDownActionButton
                  id={action.id}
                  fetchAllActions={fetchAllActions}
                  assign_to_id={action.assign_to_id}
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </CardBody>
        <CardFooter><strong> {StateRender(action)}</strong></CardFooter>
      </Card>
    </GridItem>
  )
}

export default function ActionsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const actionsStatus = useSelector((state) => state.ActionRecommendation.ActionsStatus,)
  const actions = useSelector((state) => state.ActionRecommendation.Actions)
  const error = useSelector((state) => state.ActionRecommendation.error)

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  const [search, setSearch] = useState('')
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [showListView, setShowListView] = useState(false)
  const [showKanbanView, setShowKanbanView] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const [open, setOpen] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchAllActions = async () => {
    try {
      const resultAction = await dispatch(
        fetchAllActionsRecommendations(),
      ).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    fetchAllActions()
  }, [])

  const columns = [
    {
      field: 'type_action',
      headerName: 'Action Type',
      width: 150,
    },
    {
      field: 'assign_to',
      headerName: 'Assigned to',
      width: 250,
    },
    {
      field: 'start_date',
      headerName: 'Start date',
      width: 200,
    },
    {
      field: 'endDate',
      headerName: 'Due date',
      width: 200,
    },
    {
      field: 'stateId',
      headerName: 'Status',
      width: 120,
      renderCell: ({ row }) => {
        return row.stateId == 1 ? (
          <Chip color="primary" size="small" label="Created" />
        ) : row.stateId == 2 ? (
          <Chip color="primary" size="small" label="Reassigned" />
        ) : row.stateId == 3 ? (
          <Chip style={{ backgroundColor: 'green' }} color="primary" size="small" label="Done" />
        ) : row.stateId == 4 ? (
          <Chip color="primary" size="small" label="Suspended" />
        ) : (
          <Chip color="secondary" size="small" label="Canceled" />
        )
      },
    },
    {
      field: 'priorityId',
      headerName: 'Priority',
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Rating name="read-only" value={row.priorityId} max={3} readOnly />
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      type: 'actions',
      cellClassName: 'actions',
      renderCell: ({ row }) => {
        return (
          <DropDownActionButton
            id={row.id}
            fetchAllActions={fetchAllActions}
            assign_to_id={row.assign_to_id}
          />
        )
      },
    },
  ]
  const actionsListViewFiltered = actions?.filter((action) => {
    if (
      action?.typeAction_details?.actiontype_name.toLowerCase().includes(search.toLowerCase()) ||
      action?.assign_to.toLowerCase().includes(search.toLowerCase())
    ) {
      return action
    }
  })

  let content

  if (actionsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (actionsStatus === 'succeeded') {
    content =
      actions &&
      actions
        .filter((action) => {
          // no search key  & no  selected states
          if (
            action?.typeAction_details?.actiontype_name.toLowerCase().includes(search.toLowerCase()) ||
            action?.assign_to.toLowerCase().includes(search.toLowerCase())
          ) {
            return action
          }
          // select state and search key
        })
        .map((action) => (
          <ActionExcerpt
            key={action.id}
            action={action}
            fetchAllActions={fetchAllActions}
          />
        ))
  } else if (actionsStatus === 'failed') {
    content = <div>{error}</div>
  }

  let contentListView

  if (actionsStatus === 'loading') {
    contentListView = <div className="loader">Loading...</div>
  } else if (actionsStatus === 'succeeded') {
    contentListView = (
      <Card>
        <CardBody>
          <GridContainer
            style={{
              height: 450,
              width: '100%',
            }}
          >
            <DataGrid
              disableSelectionOnClick
              //checkboxSelection
              rows={actionsListViewFiltered}
              columns={columns}
              pageSize={5}
              pagination
              rowsPerPageOptions={[]}
            //onSelectionModelChange={selectionModelChange}
            //selectionModel={selectionModel}
            />
          </GridContainer>
        </CardBody>
      </Card>
    )
  } else if (actionsStatus === 'failed') {
    contentListView = <div>{error}</div>
  }
  const renderListView = () => {
    setShowListView(true)
    setShowKanbanView(false)
  }
  const renderKanbanView = () => {
    setShowListView(false)
    setShowKanbanView(true)
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                to={`/citrine/configuration/course/actions`}
                style={{ color: 'orange' }}
              >
                {t('Actions')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer justify="space-between" alignItems="center">
        <GridItem></GridItem>

        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'end' }}>
          <CustomInput
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value)
              },
              value: search,
              placeholder: 'Search',
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
          >
            <Search
              className={
                navBarClasses.headerLinksSvg + ' ' + navBarClasses.searchIcon
              }
            />
          </Button>
        </GridItem>

        <br />
        <br />
        <GridItem></GridItem>
        <GridItem>
          <Box>
            <ViewListIcon
              onClick={renderListView}
              style={{ cursor: 'pointer' }}
            />
            <TableViewIcon
              onClick={renderKanbanView}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {showKanbanView ? content : contentListView}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
