import fileDefault from './img/file.jpg';
import fileCSS from './img/css.jpg';
import filePdf from './img/pdf.jpg';
import filePng from './img/png.jpg';
import fileXLSX from './img/xlsx.png'
export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    css: fileCSS,
    xlsx: fileXLSX
}