import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider, Tabs } from "antd";
import "antd/dist/antd.css";
// core components
import { UserOutlined } from '@ant-design/icons';
import {
  blackColor,
  cardTitle,
  grayColor,
  hexToRgb,
  primaryColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/diagram-js.css";
import Modeler from "bpmn-js/lib/Modeler";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { GiArchiveResearch, GiStairsGoal, GiTeacher } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";

import AttachFileIcon from "@material-ui/icons/AttachFile";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchActivities } from "../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import {
  GetUserProfile
} from "../../../../reduxToolkit/features/camunda/CamundaSlice";
import {
  fetchStaff, fetchStaffById, updateStateStaff
} from "../../../../reduxToolkit/features/user/UserSlice";
import CompleteCourseReportModal from "../../../Components/CompleteCourseReportModal";
import "./cvTech.css";
import EducationList from "./Education/EducationList.js";
import LanguageList from "./Language/LanguageList";
import ProfessionalExperienceList from "./ProfessionalExperience/ProfessionalExperienceList.js";
import ProfessionalSummary from "./ProfessionalSummary/ProfessionalSummary";
import Profile from "./Profile.js";
import ProjectList from "./Project/ProjectList";
import ResearchAndDevelopmentActivitiesList from "./R&DActivity/ResearchAndDevelopmentActivitiesList";
import SkillsList from "./skills/SkillsList";
import TeachingAcademicExperienceList from "./Teaching&Academic Experience/TeachingAcademicExperienceList";

import Modal from "./Modal";
import ErrorModal from "../../../Components/ErrorModal";

import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/diagram-js.css";

import UploadAttachmentPerson from "../../../Components/Uploaders/UploadAttachmentPerson";

const styles2 = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles2 = makeStyles(styles2);

const { TabPane } = Tabs;
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);

export default function TeachingStaffDetails({ match }) {

  const { id } = match.params;

  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);
  const [showAttachment, setShowAttachment] = useState(true);
  const [open, setOpen] = useState(false);
  const [diagram, diagramSet] = useState("");
  const [dynamicTask, setDynamicTask] = useState([]);

  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes = useStyles();

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  const dispatch = useDispatch();

  let container = document.getElementById("container");

  const ProcessInstanceStatus = useSelector((state) => state.camunda.ProcessInstanceStatus);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const CourseReport = useSelector((state) => state.CourseReport.CourseReport);
  const Activities = useSelector((state) => state.Activity.Activities);
  const HistoricTasks = useSelector((state) => state.camunda.HistoricTasks);
  const diagramBPMN = useSelector((state) => state.camunda.Diagram);
  const ProcessDefinition = useSelector((state) => state.camunda.ProcessDefinition);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchStaffCustomFunction = async (data) => {
    try {
      await dispatch(fetchStaffById(data)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchStaffCustomFunction(id);
    dispatch(fetchActivities());
  }, []);

  useEffect(() => {
    setStateName(person && person.state && person.state);
  }, []);
  const person = useSelector((state) => state.user.staff);

  const SendForReview = () => {
    setStateName("Waiting for review");
    try {
      let modifiedPerson = {
        state: "Waiting for review",
      };
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Waiting for review");
    } catch (err) {
    } finally { }
  };

  const MakeReviewed = () => {
    setStateName("Reviewed");
    try {
      let modifiedPerson = {
        state: "Reviewed",
      };
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Reviewed");
    } catch (err) {
    } finally { }
  };



  const ResetTODraft = () => {
    setStateName("Created");
    try {
      let modifiedPerson = {
        state: "Created",
      };
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Created");
    } catch (err) { } finally {
    }
  };

  const submitComplete = () => {
    if (stateName === "Created") {
      SendForReview();
    }
    if (stateName.toLowerCase().includes("waiting for review")) {
      MakeReviewed();
    }
  };

  // if (!person) {
  //   return (
  //     <section>
  //       <h2> {t("person not found!")}</h2>
  //     </section>
  //   );
  // }

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const callback = (key) => { };

  const handleClose = () => { setAnchorEl(null); };

  const handleClickOpen = () => {
    setShow(true);
    setOpen(true);
    setShowDiagram(true);
    setShowActivities(false);
    setShowTasks(false);
    setShowReports(false);
    setShowAttachment(false);
    if (diagram.length > 0) {
      const modeler = new Modeler({
        container,
        keyboard: {
          bindTo: document,
        },
      });
      modeler
        .importXML(diagram)
        .then(({ warnings }) => {
          if (warnings.length) { }
          const canvas = modeler.get("modeling");
          canvas.setColor("CalmCustomerTask", {
            stroke: "green",
            fill: "yellow",
          });
        })
        .catch((err) => { });
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDiagram(false);
    setShowActivities(true);
    container = document.getElementById("container").innerHTML = "";
  };

  const renderRow = () => {
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: "gray" }}
              >
                {Moment(item.DueDate).format("YYYY-MM-DD")}
              </Divider>
              <div
                style={{
                  backgroundColor: "#edeff1",
                  borderBottom: "1px solid #cfd5db",
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={3}>
                    <Avatar shape="square" size={64} icon={<UserOutlined />}
                      style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    >
                      {item && item.person_details && item.person_details[0].avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={9}>
                    <p>
                      <strong>{item.AssignedTo}</strong>
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={12}>
                    <ul>
                      <li>{item.Summary}</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (showTasks) {
      return (
        <div>
          {HistoricTasks &&
            HistoricTasks.map((item) => (
              <div>
                <Divider
                  type="horizontal"
                  orientation="center"
                  style={{ color: "gray" }}
                >
                  {Moment(item.startTime).format("YYYY-MM-DD")} ---{" "}
                  {Moment(item.endTime).format("YYYY-MM-DD")}
                </Divider>
                <div
                  style={{
                    backgroundColor: "#edeff1",
                    borderBottom: "1px solid #cfd5db",
                  }}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem key={Element.id} xs={12} sm={12} md={2}>
                      <Avatar
                        style={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                        }}
                      >
                        "user " "user.firstName[0] + user.lastName[0]"
                      </Avatar>
                    </GridItem>
                    <GridItem key={Element.id} xs={12} sm={12} md={10}>
                      <p>
                        <strong>
                          "user " "user.firstName + " " + user.lastName"
                        </strong>
                      </p>
                      <ul>
                        <li> Task : {item.name}</li>
                      </ul>
                      <ul>
                        <li>Operation : {item.deleteReason}</li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            ))}
        </div>
      );
    } else if (showDiagram) {
      return (
        <div>
          <button onClick={(event) => handleClickOpen(event)}>
            Show Diagram
          </button>
          <Modal title="My Modal" onClose={() => setShow(false)} show={show}></Modal>
        </div>
      );
    } else {
      return (
        <div >
          <UploadAttachmentPerson id={person && person.id} />
        </div>
      );
    }
  };
  const renderTasks = () => {
    setShowTasks(false);
    setShowActivities(false);
    setShowDiagram(false);
    setShowReports(false);
    setShowAttachment(true);
    dispatch(GetUserProfile());
  };

  const renderActivities = () => {
    setShowActivities(true);
    setShowDiagram(false);
    setShowTasks(false);
    setShowAttachment(false);
    setShowReports(false);
  };

  const renderDiagram = () => {
    setShowReports(false);
    setShowActivities(false);
    setShowDiagram(true);
    setShowTasks(false);
    setShowAttachment(false);
  };

  const renderAttachment = () => {
    setShowReports(false);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
    setShowAttachment(true);
  };

  let content;
  if (stateName === "Created") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === "Created" ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  } else if (
    stateName &&
    stateName.toLowerCase().includes("waiting for review")
  ) {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>
          <div>
            <Button
              style={{ backgroundColor: "lightgery" }}
              size="sm"
              className={classes.marginRight}
              onClick={() => ResetTODraft()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Reset tO draft")}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el && el.toLowerCase().includes("waiting for review") ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Reviewed") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>
          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === "Reviewed" ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details && person.person_details[0].first_name} ${person && person.person_details && person.person_details[0].last_name}`}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <div>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem key={Element.id} xs={12} sm={12} md={9}>
            <GridContainer xs={12} sm={12} md={12}>
              {content}
            </GridContainer>
            <GridContainer style={{ border: "none", marginTop: "10px" }}>
              <div class="scrollable">
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "0px",
                    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                    background: "#F8F8F8",
                    width: "100%",
                    boxShadow:
                      "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "0",
                    wordWrap: "break-word",
                    fontSize: ".875rem",
                  }}
                >
                  <GridContainer style={{ border: `2px solid ${grayColor[15]}`, borderRadius: "6px", backgroundColor: whiteColor }}>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer alignItems="center">
                        <GridItem xs={3} sm={3} md={3} style={{ borderLeft: `2px solid ${grayColor[15]}`, borderRight: `2px solid ${grayColor[15]}` }}>
                          <GridContainer>
                            <GridItem xs={4} sm={4} md={4}>
                              <IconContext.Provider value={{ size: "2em" }}>
                                <GiArchiveResearch />
                              </IconContext.Provider>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8}>
                              <Link
                                to={`/citrine/faculty/teachingStaff/${id}/researchChoice`}
                              >
                                <span style={{ color: blackColor }}>Research && innovation</span>
                              </Link>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3} style={{ borderRight: `2px solid ${grayColor[15]}` }}>
                          <GridContainer>
                            <GridItem xs={4} sm={4} md={4}>
                              <IconContext.Provider value={{ size: "2em" }}>
                                <IoIosPeople />
                              </IconContext.Provider>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8}>
                              <Link to={`/citrine/faculty/teachingStaff/${id}/communityServicesActivities`}>
                                <span style={{ color: blackColor }}>Community services</span>
                              </Link>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3} style={{ borderRight: `2px solid ${grayColor[15]}` }}>
                          <GridContainer>
                            <GridItem xs={4} sm={4} md={4}>
                              <IconContext.Provider value={{ size: "2em" }}>
                                <GiStairsGoal />
                              </IconContext.Provider>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8}>
                              <Link to={`/citrine/faculty/teachingStaff/${id}/professionalDevelopmentActivities`}>
                                <span style={{ color: blackColor }}>Professional development</span>
                              </Link>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3} >
                          <GridContainer>
                            <GridItem xs={4} sm={4} md={4}>
                              <IconContext.Provider value={{ size: "2em" }}>
                                <GiTeacher />
                              </IconContext.Provider>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8}>
                              <Link to={`/citrine/faculty/teachingStaff/${id}/teachingLoadActivities`}>
                                <span style={{ color: blackColor }}>Teaching load</span>
                              </Link>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <br />
                  <GridContainer xs={12} sm={12} md={12}>
                    <Profile person={person} />
                  </GridContainer>
                  <Tabs
                    onChange={callback}
                    type="card"
                    style={{ marginTop: "5px", backgroundColor: whiteColor }}
                  >
                    <TabPane tab="Professional Summary " key="1">
                      <ProfessionalSummary person={person} />
                    </TabPane>
                    <TabPane tab="Education" key="2">
                      <EducationList
                        person={person}
                        person_id={person && person.id}
                      />
                    </TabPane>
                    <TabPane tab="Teaching & Academic Experience" key="3">
                      <TeachingAcademicExperienceList
                        person={person}
                        person_id={person && person.id}
                      />
                    </TabPane>
                    <TabPane tab="Professional Experience" key="4">
                      <ProfessionalExperienceList
                        person={person}
                        person_id={person && person.id}
                      />
                    </TabPane>
                    <TabPane tab="R&D Activities" key="5">
                      <ResearchAndDevelopmentActivitiesList
                        person={person}
                        person_id={person && person.id}
                      />
                    </TabPane>
                    <TabPane tab="Projects" key="6">
                      <ProjectList
                        person={person}
                        person_id={person && person.id}
                      />
                    </TabPane>
                    <TabPane tab="Skills" key="7">
                      <SkillsList
                        person={person}
                        person_id={person && person.id}
                      />
                    </TabPane>
                    <TabPane tab="Languages" key="8">
                      <LanguageList
                        person={person}
                        person_id={person && person.id}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </GridContainer>
          </GridItem>
          <GridItem key={Element.id} xs={12} sm={12} md={3}>
            <div
              style={{
                padding: "10px",
                borderRadius: "0px",
                color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                background: whiteColor,
                width: "100%",
                boxShadow:
                  "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                minWidth: "0",
                wordWrap: "break-word",
                fontSize: ".875rem",
                height: "82vh",
                border: "1px solid lightgray",
              }}
            >
              <GridContainer>
                <GridItem>
                  <Button
                    color="transparent"
                    simple
                    aria-label="Dashboard"
                    justIcon
                    onClick={renderAttachment}
                  >
                    <AttachFileIcon
                      color={showAttachment ? primaryColor[0] : "black"}
                    />
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div class="card scrollable">
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "0px",
                        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "0",
                        wordWrap: "break-word",
                        fontSize: ".875rem",
                        height: "68vh",

                      }}
                    >
                      {renderRow()}
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
