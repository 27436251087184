import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector, useDispatch } from "react-redux";
import MoreVert from "@material-ui/icons/MoreVert";
import GAIndicatorsAndCLOsMapModal from "./GAIndicatorsAndCLOsMapModal";
import ErrorModal from "../Components/ErrorModal.jsx";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import SuperGeneralDeleteModal from "../Components/SuperGeneralDeleteModal";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteGAIndicator } from "../../reduxToolkit/features/GAIndicators/GAIndicatorSpecSlice";
import {
  fetchGraduateAttributeSpecById,
  deleteGraduateAttributeSpec,
} from "../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";
import { Breadcrumb, notification } from "antd";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function GAIndicatorDropDownButton(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteGAIndicator = (id) => {
    dispatch(deleteGAIndicator(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log("originalPromiseResult", originalPromiseResult);
        dispatch(fetchGraduateAttributeSpecById(props.GAInd.id));
        openNotificationWithIcon("success", `Deleted`)
      })
      .catch(
          (rejectedValueOrSerializedError) => {
            // handle error here
            console.log(
              "rejectedValueOrSerializedError",
              rejectedValueOrSerializedError
            );
            setHandledError(rejectedValueOrSerializedError);
            handleOpenErrorModal();
          }
        );
     
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <GAIndicatorsAndCLOsMapModal
          handleDropDownButtonClose={handleClose}
          disabledStatus={props.disabledStatus}
          GAInd={props.GAInd}
          GAId={props.GAId}
          programId={props.programId}
        />
        <MenuItem
          onClick={ props.disabledStatus ? {}: () =>{
            handleOpenDeleteModal();
            handleClose();
          }
          }
        >
          <ListItemText primary="Delete" />
        </MenuItem>

        <SuperGeneralDeleteModal
          delete_Element={handleDeleteGAIndicator}
          Element_ID={props.GAInd.id}
          open={open}
          handleClose={handleCloseDeleteModal}
        />
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </StyledMenu>
    </div>
  );
}
