import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  universities: [],
  universitiesStatus: 'idle',
  universityStatus: 'idle',
  error: null,
  university:null,
  colleges: [],
  id:null
}


export const fetchUniversities = createAsyncThunk('university/fetchUniversities', async (_,{rejectWithValue}) => {
  try {
    const token=localStorage.getItem('token')
    const {data} = await axios.get(BaseUrl+'/university/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })

    return data
  } catch (err) {
    let error = err; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
 
  })

  export const fetchUniversityById = createAsyncThunk('university/fetchUniversityById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/university/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewUniversity = createAsyncThunk(
    'university/addNewUniversity',
    async (initialUniversity) => {
      console.log('initial university', initialUniversity)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/university/create/`,initialUniversity ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const universityUpdate = createAsyncThunk(
    'university/universityUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/university/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedUniversity')
      console.log(response.data,'response.data')   
      console.log(response,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the university: ', error)

}
    }
  )

  export const deleteUniversity = createAsyncThunk('program/deleteUniversity', async (id,{rejectWithValue}) => {
    try {
      const token=localStorage.getItem('token')
    const {data} = await axios.delete(BaseUrl+`/university/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })

    return data
    } catch (err) {
      let error = err; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
    }
    
  })
const  universitiesSlice = createSlice({
  name: ' university',
  initialState,
  extraReducers: {
    [fetchUniversities.pending]: (state, action) => {
        state.universitiesStatus = 'loading'
      },
      [fetchUniversities.fulfilled]: (state, action) => {
        state.universitiesStatus = 'succeeded'
        // Add any fetched posts to the array
        state.universities = action.payload
      },
      [fetchUniversities.rejected]: (state, action) => {
        state.universitiesStatus = 'failed'
        state.error = action.payload
      },

      [fetchUniversityById.pending]: (state, action) => {
        state.universityStatus = 'loading'
      },
      [fetchUniversityById.fulfilled]: (state, action) => {
        state.universityStatus = 'succeeded'

        // Add any fetched posts to the array
        state.university = action.payload
      },
      [fetchUniversityById.rejected]: (state, action) => {
        state.universityStatus = 'failed'
        state.error = action.payload
      },
      [addNewUniversity.fulfilled]: (state, action) => {
        state.universities.push(action.payload)
      },
      [universityUpdate.fulfilled]: (state, action) => {
        console.log(action) 
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    universityUpdated(state, action) {
      const { id,name, mission, vision, goals, values, phoneNumber, email, director, image, adress, city, country, postalCode } = action.payload
      const existingUniversity = state.universities.find(university => university.id === id)
      if (existingUniversity) {
        existingUniversity.name = name
        existingUniversity.mission = mission
        existingUniversity.vision = vision
        existingUniversity.goals = goals
        existingUniversity.values = values
        existingUniversity.phoneNumber = phoneNumber
        existingUniversity.email = email
        existingUniversity.director = director
        existingUniversity.image = image
        existingUniversity.adress = adress
        existingUniversity.city = city
        existingUniversity.country = country
        existingUniversity.postalCode = postalCode

      }
    }
  }
}})

export const { universityAdded, universityUpdated, getId} = universitiesSlice.actions

export default universitiesSlice.reducer

export const selectAllUniversities = state => state.university.universities
