import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// core components
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, notification, Table } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchAssessmentQuestionsMethods } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import { fetchCourseInstructorById } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { MediaBaseUrlAvatar } from 'assets/utils/accessToken'
import DropDownQuestionAssessmentCoordinator from '../../../Components/DropDownQuestionAssessmentCoordinator'
import ErrorModal from '../../../Components/ErrorModal'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  CalculScoreCourse,
  fetchCourseById,
} from '../../../../reduxToolkit/features/course/CoursesSlice'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'
import { MediaBaseUrl } from 'assets/utils/accessToken'

import { ScoreCourseByQuestion } from '../../../../reduxToolkit/features/assessment/AssessmentSlice'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import GroupIcon from '@material-ui/icons/Group'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
const useStylesCollapse = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const useStylesAvatar = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))
const AssessmentQuestionMethodExcerpt = ({
  assessmentQuestionsMethod,
  course_id,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const cardClasses = useCardStyles()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
    },
    {
      title: 'Clo',
      dataIndex: 'code',
    },
    {
      title: 'Actual weight',
      dataIndex: 'actual_weight',
    },
    {
      title: 'Correction mark',
      dataIndex: 'correction_mark',
    },
  ]

  const [selectedRows, setSelectedRows] = React.useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [disabled, setDisabled] = React.useState(true)
  const [listInstructors, setListInstructors] = React.useState([])
  const [listGroups, setListGroups] = React.useState([])

  const handleDisable = (selectedRows) => {
    selectedRows.length === 1 ? setDisabled(false) : setDisabled(true)
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRowData(Object.assign({}, selectedRow[0]))
      setSelectedRows(selectedRowKeys.join(','))
      handleDisable(selectedRowKeys)
    },
  }
  const [selectionType, setSelectionType] = useState('checkbox')
  const data = assessmentQuestionsMethod.rows

  const courseInstructors = useSelector(
    (state) => state.CourseInstructor.CourseInstructors,
  )
  //console.log(courseInstructors)
  const CourseInstructorStatus = useSelector(
    (state) => state.CourseInstructor.CourseInstructorStatus,
  )
  const persons = useSelector((state) => state.user.staffList)
  const listCourseInstructorIDs = courseInstructors?.map((el) => {
    return el?.group_details?.instructors?.[0]?.id
  })
  //console.log(listCourseInstructorIDs)
  console.log(listInstructors)

  useEffect(() => {
    dispatch(
      fetchCourseInstructorById({
        course_id,
        exam_id: assessmentQuestionsMethod?.id,
      }),
    ).then((res) => {
      if (res?.payload?.length > 0) {
        const constructors_IDS = res?.payload?.map((el) => {
          return el?.group_details?.instructors[0]?.id
        })
        const listInst = persons
          ?.filter((el) => constructors_IDS?.includes(el.person_id))
          .map((el) => el.person_details[0])
          .map(({ first_name, last_name, avatar }) => (
            <>
              <MenuItem>
                <Avatar title={'assignee'} src={MediaBaseUrl + `${avatar}`}>
                  {first_name
                    .split(' ')
                    .map((word) => word[0])
                    .join('')}
                </Avatar>
                &nbsp; &nbsp;
                {first_name} {last_name}
              </MenuItem>

            </>
          ))
        const listGroup = res?.payload?.map((el) => (
          <>
            <MenuItem>{el?.group_details?.group_name}</MenuItem>
          </>
        ))
        setListInstructors(listInst)
        setListGroups(listGroup)
      }
    })
    //}
  }, [])

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardBody>
          <GridContainer>
           
            <GridItem xs={12} sm={12} md={11}>
             
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Link
                    to={`/citrine/courseCoordinatorWorkspace/assessmentsQuestionsDetailsByGroup/course/${course_id}/assessmentMethod/${assessmentQuestionsMethod &&
                      assessmentQuestionsMethod.id
                      }/${assessmentQuestionsMethod &&
                      assessmentQuestionsMethod.name
                      }`}
                    style={{ display: 'flex' }}
                  >
                    <Typography>
                      {assessmentQuestionsMethod.name} (
                      {assessmentQuestionsMethod.mode})
                    </Typography>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}></GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  {assessmentQuestionsMethod &&
                    assessmentQuestionsMethod.instructors &&
                    assessmentQuestionsMethod?.instructors.map((el) => (
                      <TableAssessmentHeaderInstructor instructor={el} />
                    ))}
                </GridItem>
              </GridContainer>
              {/* )} */}
            </GridItem>

            <GridItem xs={12} sm={12} md={1}>
              <DropDownQuestionAssessmentCoordinator
                course_id={course_id}
                assessmentMethod_id={assessmentQuestionsMethod.id}
                assessmentQuestionsMethod_name={assessmentQuestionsMethod.name}
                selectedRows={selectedRows}
                disabled={disabled}
                selectedRowData={selectedRowData}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      {/* <SuperGeneralDeleteModal
        delete_Element={handleDeletePlo}
        Element_ID={assessmentQuestionsMethod.id}
        open={open}
        handleClose={handleClose}
      /> */}
    </GridItem>
  )
}

export default function AssessmentQuestionListCoordinator({ match }) {
  const { t } = useTranslation()

  const { id } = match.params

  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const AssessmentQuestionsMethods = async () => {
    try {
      const resultAction = await dispatch(
        fetchAssessmentQuestionsMethods(id),
      )
      unwrapResult(resultAction)

    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    dispatch(fetchStaff())
    AssessmentQuestionsMethods()
    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        let ScoreCourse = {
          course_id: id,
          semester_id: course?.semester_id,
        }
        dispatch(CalculScoreCourse(ScoreCourse))
        dispatch(ScoreCourseByQuestion(ScoreCourse))
      })
  }, [dispatch])

  const assessmentQuestionsMethods = useSelector(
    (state) => state.AssessmentMethods.assessmentQuestionsMethods,
  )
  console.log('assessmentQuestionsMethods', assessmentQuestionsMethods)
  const assessmentQuestionsMethodsStatus = useSelector(
    (state) => state.AssessmentMethods.assessmentQuestionsMethodsStatus,
  )


  let content

  if (assessmentQuestionsMethodsStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (assessmentQuestionsMethodsStatus === 'succeeded') {
    if (
      assessmentQuestionsMethods &&
      assessmentQuestionsMethods === 'You do not have permission'
    ) {
      content = <h5>{t('You do not have permission!')}</h5>
    } else if (
      assessmentQuestionsMethods?.length
    ) {
      content = assessmentQuestionsMethods.map((assessmentQuestionsMethod) => (
        <AssessmentQuestionMethodExcerpt
          course_id={id}
          key={assessmentQuestionsMethod.id}
          assessmentQuestionsMethod={assessmentQuestionsMethod}
        />
      ))
    } else {
      content = (
        <h5>
          {t('This course has no assessment Methods to display!')}
        </h5>
      )
    }
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/courseCoordinatorWorkspace/assessment/`}>
              {t('Courses List ')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseCoordinatorWorkspace/assessmentsQuestions_list/course/${id}`}
              style={{ color: 'orange' }}
            >
              {t('Assessments Questions ')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
const TableAssessmentHeaderInstructor = ({ instructor }) => {
  //console.log(instructor)
  const classes = useStylesCollapse()
  const classes3 = useStylesAvatar()
  const [openCollapse, setOpenCollapse] = React.useState(false)

  const handleClick = () => {
    setOpenCollapse(!openCollapse)
  }
  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem button onClick={handleClick}>
          <MenuItem>
            <Avatar
              title={'assignee'}
              src={MediaBaseUrlAvatar + `${instructor.avatar}`}
              className={classes3.small}
            >
              {instructor.first_name
                .split(' ')
                .map((word) => word[0])
                .join('')}
            </Avatar>
            &nbsp; &nbsp;
            {instructor.first_name} {instructor.last_name}
          </MenuItem>

          {openCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          style={{ textAlign: 'center' }}
          in={openCollapse}
          timeout="auto"
          unmountOnExit
        >
          {instructor &&
            instructor.groups &&
            instructor.groups.map((group, index) => (
              <Chip
                style={{ marginTop: '2px', marginLeft: '2px' }}
                label={group?.group_name}
                color="#8F6682"
              />
            ))}
        </Collapse>
      </List>
    </>
  )
}
