import React, { lazy, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
/******Import redux tools*****/

import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

/******Import native component*****/

import saveAs from 'file-saver'

import { GridExporter } from '@devexpress/dx-react-grid-export'

import { SchoolRounded } from '@material-ui/icons'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { DataGrid } from '@mui/x-data-grid'
import { Alert, Breadcrumb, Space } from 'antd'
import 'antd/dist/antd.css'
/******Import components*****/
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import ErrorModal from 'views/Components/ErrorModal'
import DropDownButtonExportImportQuestionNotes from '.././../../Components/DropDownButtonExportImportQuestionNotes'
import ListEvidence from './ListEvidence'
import Button from '../../../../components/CustomButtons/Button'
import { Form, Input, Select, notification, DatePicker } from 'antd'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'

/******Import APIs*****/

import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import { addNewNoteQuestion } from '../../../../reduxToolkit/features/assessment/AssessmentSlice'
import {
  fetchExamByQuestionAndNotes,
  fetchQuestions_ByAssessmentMethodById,
  GetAssessmentMethodById,
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import { fetchCourseById } from '../../../../reduxToolkit/features/course/CoursesSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramsSlice'
import { fetchEvidenceTypeStatesConfigs } from '../../../../reduxToolkit/features/EvidenceType/EvidenceTypeSlice'

import {
  fetchAffectEvidenceTypeToAssessmentMethod,
  affectEvidenceTypeToAssessmentMethod,
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import { fetchAssessmentById } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'

const Component = lazy(() => import('../../../Components/filesView/FilesView'))
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
const Option = Select.Option
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AssessmentQuestion = ({
  rows,
  groupId,
  examId,
  courseId,
  semesterId,
  columns,
  course,
  program,
  CourseAssessmentMethod
}) => {
  console.log(columns)
  const dispatch = useDispatch()
  let { id, Assessmentid, ProgramId, AssessmentName } = useParams()
  const obj_Assessment = {
    id: Assessmentid,
    course_id: id,
  }
  console.log(program)
  /*****************************************************************
   * Export Handler functions
   * you will find more details in:
   * https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/export/
   ****************************************************************/
  const exporterRef = useRef(null)

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx',
      )
    })
  }

  /************************ States ****************************/
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  /***** handle modal err ***/
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    console.log(open)
  };

  const handleClose = () => {
    setOpen(false);
    console.log(open)
  };
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  /*****************************************************************
   * 'event' contains all informations about the cell input
   * Api 'addnewNoteQuestion' to submit the newest note in database
   * catch used to handle errors and shows modal contains err
   *****************************************************************/

  const onCellEditCommit = async (event) => {
    const submitedNoteQuestion = {
      groupAssessment_id: groupId,
      assessmentMethod_id: examId,
      course_id: courseId,
      note: event.value,
      student_id: event.id,
      question_name: event.field,
      semester_id: semesterId,
    }

    try {
      const resultAction = await dispatch(
        addNewNoteQuestion(submitedNoteQuestion),
      )
      unwrapResult(resultAction)
      dispatch(fetchExamByQuestionAndNotes(obj_Assessment))
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
      dispatch(fetchExamByQuestionAndNotes(obj_Assessment))
    }
  }
  /**************************************************************/




  return (
    <>
      <GridContainer>
        <Card>
          <GridContainer>

            <GridItem xs={11} sm={11} md={11}>
              <FullscreenIcon onClick={handleClickOpen} fontSize="medium" style={{ marginLeft: '40px', cursor: 'pointer', marginTop: '20px' }} />
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >


              <DropDownButtonExportImportQuestionNotes
                courseID={courseId}
                course={course}
                program={program}
                semester_id={semesterId}
                export={startExport}
                group_name={''}
                examID={examId}
                obj_Assessment={obj_Assessment}
              />
            </GridItem>
          </GridContainer>

          <CardBody style={{ height: '500px' }}>


            <DataGrid
              //autoHeight
              rows={rows || []}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[5, 50]}
              onCellEditCommit={onCellEditCommit}
              disableSelectionOnClick


            />

          </CardBody>
        </Card>
      </GridContainer>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#8F6682' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <strong>{course && course.code && course.code}</strong>-
              <strong>
                {course &&
                  course.program_detail &&
                  course.program_detail.map(
                    (el) => el.program_degree_name,
                  )}
              </strong>
              <strong>-
                {course && course.title && course.title}
              </strong>
              <strong>
                {CourseAssessmentMethod &&
                  CourseAssessmentMethod.assessmentMethod_detail
                    .name &&
                  CourseAssessmentMethod.assessmentMethod_detail.name}
              </strong>

            </Typography>
          </Toolbar>
        </AppBar>

        <CardBody style={{ height: '500px' }}>
          <DataGrid
            //autoHeight
            rows={rows || []}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5, 50]}
            onCellEditCommit={onCellEditCommit}
            disableSelectionOnClick
          />
        </CardBody>
      </Dialog>
    </>
  )
}

const AssessmentsQuestionsDetails = ({ match }) => {
  /************************ States ****************************/
  const [columns, setColumns] = useState([
    {
      name: 'student_identification',
      title: 'Student ID',
      field: 'student_identification',
      headerName: 'Student ID',
      width: 200,
    },
  ])

  /************************************************************* */

  const { t } = useTranslation()
  const dispatch = useDispatch()

  let { id, Assessmentid, ProgramId, AssessmentName } = useParams()
  const obj_Assessment = {
    id: Assessmentid,
    course_id: id,
  }
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  /***** handle modal err ***/
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  /*******************************************************************/
  /**************************************************************
   * generate columns from API
   *************************************************************/
  const [ids, setIds] = useState()
  const [evidnt, setEvidnt] = useState([])
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const AssessmentMethod = useSelector(
    (state) => state.AssessmentMethods.assessmentMethod,
  )
  useEffect(() => {
    setIds(Assessmentid)
    setEvidnt(AssessmentMethod?.evidenceAssessment_details)
    console.log('Assessmentiddddddddd', Assessmentid)
    dispatch(GetAssessmentMethodById(Assessmentid))
      .then(unwrapResult)
      .then((res) => {
        console.log('reeeeeeees555', res)
      })
    let arr = []
    dispatch(fetchQuestions_ByAssessmentMethodById(obj_Assessment))
      .then(unwrapResult)
      .then((AssessmentMethodQuestions) => {
        AssessmentMethodQuestions &&
          AssessmentMethodQuestions.map((item, id) => {
            let obj = {
              /***those 2 fields for export data only **/

              name: `${item && item.question && item.question}`,
              title: `${item && item.question}  (/${item.correction_mark})`,
              /*************************************** */

              field: `${item && item.question && item.question}`,
              headerName: `${item && item.question}  (/${item.correction_mark
                })`,
              //flex: 1,
              editable: true,
            }
            arr = [...arr, obj]
            setColumns([...columns, ...arr])
            console.log('columns', columns)
          })
      })
  }, [dispatch])
  /******************************************************************************/

  useEffect(() => {
    dispatch(fetchCourseById(id))
    dispatch(fetchProgramById(ProgramId))
    dispatch(fetchExamByQuestionAndNotes(obj_Assessment))
  }, [dispatch])
  /******************Get data of API's from redux*******************************************/
  const CourseAssessmentMethod = useSelector(
    (state) => state.assessment.courseAssessmentMethod,
  )
  const ExamByQuestionAndNotesMethod = useSelector(
    (state) => state.AssessmentMethods.ExamByQuestionAndNotesMethod,
  )
  console.log('ExamByQuestionAndNotesMethod', ExamByQuestionAndNotesMethod)
  const ExamByQuestionAndNotesMethodstatus = useSelector(
    (state) => state.AssessmentMethods.ExamByQuestionAndNotesStatus,
  )
  const program = useSelector((state) => state.program.program)
  const course = useSelector((state) => state.course.course)
  /******************************************************************************/
  /******************************************************************************/
  let content
  if (
    ExamByQuestionAndNotesMethodstatus === 'loading' ||
    ExamByQuestionAndNotesMethodstatus === 'succeeded'
  ) {
    if (ExamByQuestionAndNotesMethod === 'You do not have permission') {
      //let ExamByQuestionAndNotesMethod = [];
      content = (
        <Space
          direction="vertical"
          style={{
            width: '100%',
          }}
        >
          <Alert
            message=""
            description={`${ExamByQuestionAndNotesMethod}`}
            type="error"
            closable
          />
        </Space>
      )
    } else {
      content =
        ExamByQuestionAndNotesMethod &&
        ExamByQuestionAndNotesMethod.map((items, key) => (
          <React.Fragment key={key}>
            <AssessmentQuestion
              groupeName={items.groupName}
              rows={items.rows}
              groupId={items.groupId}
              examId={items.exam_id}
              courseId={items.course_id}
              semesterId={course && course.semester_id && course.semester_id}
              program={program}
              course={course}
              columns={columns}
              CourseAssessmentMethod={CourseAssessmentMethod}
            />
          </React.Fragment>
        ))
    }
  } else if (ExamByQuestionAndNotesMethodstatus === 'failed') {
    content = <div>{'failed'}</div>
  }
  const [ping, setPing] = useState(false)
  return (
    <div>
      <div>
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/programs_assessment`}>
                {t('Programs List')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/program/${ProgramId}`}>
                {program &&
                  program.program_degree_name &&
                  program.program_degree_name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/assessmentsQuestions_list/program/${ProgramId}/course/${id}`}
              >
                {' '}
                {t('Assessments Methods')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/assessmentsQuestionsDetails/program/${ProgramId}/course/${id}/assessmentMethod/${Assessmentid}`}
                style={{ color: 'orange' }}
              >
                {t('Assessment Questions Notes')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card justify="space-between">
              <CardHeader
                color="info"
                icon
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardIcon color="primary" style={{ height: '65px' }}>
                  <SchoolRounded />
                </CardIcon>
                <GridContainer>
                  <GridItem xs={12} sm={11} md={2}>
                    <div>
                      <Link to={`/citrine/programs_assessment`}>
                        <h4
                          style={{
                            color: '#8F6682',
                            height: '13px',
                            fontSize: '14px',
                            marginRight: '20px',
                          }}
                        >
                          <strong>
                            {course &&
                              course.program_detail &&
                              course.program_detail.map((el) => el.code)}
                          </strong>
                        </h4>
                      </Link>
                      {/* <Link to={`/citrine/courses_assessment/${id}`}> */}
                      <h4
                        style={{
                          color: '#6C7B9D',
                          height: '13px',
                          fontSize: '14px',
                          marginRight: '20px',
                        }}
                      >
                        <strong>{course && course.code && course.code}</strong>
                      </h4>
                      {/* </Link> */}
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={11} md={4}>
                    <div>
                      <Link to={`/citrine/programs_assessment`}>
                        <h4
                          style={{
                            color: '#8F6682',
                            fontSize: '14px',
                            height: '13px',
                          }}
                        >
                          <strong>
                            {course &&
                              course.program_detail &&
                              course.program_detail.map(
                                (el) => el.program_degree_name,
                              )}
                          </strong>
                        </h4>
                      </Link>
                      {/* <Link to={`/citrine/courses_assessment/${id}`}> */}
                      <h4
                        style={{
                          color: '#6C7B9D',
                          fontSize: '14px',
                          height: '13px',
                        }}
                      >
                        <strong>
                          {course && course.title && course.title}
                        </strong>
                      </h4>
                      {/* </Link> */}
                    </div>

                    <div>
                      <h4
                        style={{
                          color: 'grey',
                          fontSize: '14px',
                          height: '13px',
                        }}
                      >
                        <strong>
                          {CourseAssessmentMethod &&
                            CourseAssessmentMethod.assessmentMethod_detail
                              .name &&
                            CourseAssessmentMethod.assessmentMethod_detail.name}
                        </strong>
                      </h4>
                    </div>
                  </GridItem>

                  <GridItem xs={10} sm={11} md={5}>
                    <div style={{ display: 'flex' }}>
                      <h4
                        style={{
                          color: 'grey',
                          fontSize: '14px',
                          height: '13px',
                          marginRight: '20px',
                        }}
                      >
                        <strong> {t('Assessment Method')}</strong>
                      </h4>

                      <h4
                        style={{
                          color: 'grey',
                          fontSize: '14px',
                          height: '13px',
                        }}
                      >
                        <strong> {AssessmentName}</strong>
                      </h4>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <hr />
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ float: 'right', fontSize: '25px' }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                color: '#6C7B9D',
              }}
            >
              <strong>Evidences List</strong>
            </Typography>
          </AccordionSummary>
          <Typography
            style={{
              color: '#6C7B9D',
              marginLeft: '15px',
            }}
          >
            <AddEvidenceModal />
            {/* <Button color="primary">Add</Button> */}
          </Typography>

          <ListEvidence resource={'course'} resourceId={id} />
        </Accordion>
        <hr />
        {content}
        <div>
          {/* <Component
                 
                 model={"assessmentMethod"}
                 resource_id={Assessmentid}
                 resource={AssessmentName}
                 course_id={id}
                 ping={ping}
                 setPing={setPing}
               /> */}

          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      </div>
      {/* <ListEvidence /> */}
      {/* <FileUploader id={Assessmentid} /> */}
    </div>
  )
}
export default AssessmentsQuestionsDetails

const AddEvidenceModal = () => {
  const { t } = useTranslation()
  const { id, Assessmentid } = useParams()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [evidenceType, setEvidenceType] = useState('')
  const [requiredEvidence, setRequiredEvidence] = useState(true)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const onFinish = (values) => {
    console.log(values)
    handleClose()
  }

  const types = useSelector(
    (state) => state.EvidenceTypeStatesConfig.EvidenceTypeStatesConfigs,
  )
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const addEvidenceToAssessment = () => {
    dispatch(
      affectEvidenceTypeToAssessmentMethod({
        required: requiredEvidence,
        evidenceType_id: evidenceType,
        assessmentMethod_id: Assessmentid,
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(fetchAssessmentById(Assessmentid))
        openNotificationWithIcon('success', `Add successfully`)
        handleClose()
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }
  useEffect(() => {
    dispatch(fetchEvidenceTypeStatesConfigs())
  }, [])
  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        {t('Add')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Add Evidence')}</center>
        </DialogTitle>
        <DialogContent>
          <InputLabel>{t('Select Evidence Type')}</InputLabel>
          <br />
          <Select
            style={{ width: 400 }}
            dropdownStyle={{ zIndex: 10000 }}
            placeholder={t('Select Evidence Type')}
            optionFilterProp="children"
            onChange={(e) => {
              setEvidenceType(e)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={evidenceType}
          >
            {types &&
              types.map((item) => <Option value={item.id}>{item.type}</Option>)}
          </Select>
          <FormGroup>
            <FormControlLabel
              onChange={(e) => {
                setRequiredEvidence(e.target.checked)
              }}
              control={<Checkbox defaultChecked />}
              label="Required"
            />
          </FormGroup>

          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">
            {t('Cancel')}
          </Button>
          <Button onClick={addEvidenceToAssessment} color="info">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
