import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  departments: [],
  departmentsStatus: 'idle',
  departmentStatus: 'idle',
  error: null,
  department:null,
  id:null
}


export const fetchDepartments = createAsyncThunk('department/fetchDepartments', async (_,{rejectWithValue}) => {
  try {
    const token=localStorage.getItem('token')
    const {data} = await axios.get(BaseUrl+'/department/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })

    return data
  } catch (err) {
    let error = err; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
  
  })

  export const fetchDepartmentById = createAsyncThunk('department/fetchDepartmentById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/department/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })
  export const addNewDepartment = createAsyncThunk(
    'department/addNewDepartment',
    async (initialDepartment) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/department/create/`,initialDepartment ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const departmentUpdate = createAsyncThunk(
    'department/departmentUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/department/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedDepartment')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the department: ', error)

}
    }
  )
  export const deleteDepartment = createAsyncThunk('department/deleteDepartment', async (id,{rejectWithValue}) => {
    try {
      const token=localStorage.getItem('token')
      const {data} = await axios.delete(BaseUrl+`/department/${id}/delete/`, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
       
  
      return data
    } catch (err) {
      let error = err; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
    }
   
  })
  
const  departmentsSlice = createSlice({
  name: ' department',
  initialState,
  extraReducers: {
   
    [fetchDepartments.pending]: (state, action) => {
        state.departmentsStatus = 'loading'
      },
      [fetchDepartments.fulfilled]: (state, action) => {
        state.departmentsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.departments = action.payload
      },
      [fetchDepartments.rejected]: (state, action) => {
        state.departmentsStatus = 'failed'
        state.error = action.payload
      },

      [fetchDepartmentById.pending]: (state, action) => {
        state.departmentStatus = 'loading'
      },
      [fetchDepartmentById.fulfilled]: (state, action) => {
        state.departmentStatus = 'succeeded'

        // Add any fetched posts to the array
        state.department = action.payload
      },
      [fetchDepartmentById.rejected]: (state, action) => {
        state.departmentStatus = 'failed'
        state.error = action.payload
      },
      [addNewDepartment.fulfilled]: (state, action) => {
        state.departments.push(action.payload)
      },
      [departmentUpdate.fulfilled]: (state, action) => {
        console.log(action) 
      },
      [deleteDepartment.fulfilled]: (state, action) => {
       console.log(action)
      },
  },
// extraReducers: {
//     [fetchUniversities.fulfilled]: (state, action) => {
//       return action.payload
//     },
//   },
  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
    departmentAdded: {
      reducer(state, action) {
        state. departments.push(action.payload)
      },
      prepare( name, college_id, mission, phoneNumber, email, chief_department, image) {
        // omit prepare logic
      }
    },
    departmentUpdated(state, action) {
      const {  id,name, college, mission, phoneNumber, email, chief_department, image } = action.payload
      const existingDepartment = state.departments.find(department => department.id === id)
      if (existingDepartment) {
        existingDepartment.name = name
   //     existingDepartment.college = college
        existingDepartment.mission = mission
        existingDepartment.phoneNumber = phoneNumber
        existingDepartment.email = email
        existingDepartment.chief_department = chief_department
        existingDepartment.image = image

      }
    }
  }
})

export const { departmentAdded, departmentUpdated, getId } = departmentsSlice.actions

export default departmentsSlice.reducer

export const selectAllDepartments = state => state.department.departments

