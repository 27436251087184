import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    stepConfig: [],
    stepConfigStatus: 'idle',
    StepConfig: null,
    error:null
  }

  export const fetchstepConfig = createAsyncThunk(
    'stepConfig/fetchstepConfig',
    async (_, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(BaseUrl + '/stepConfig/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        return data
      } catch (err) {
        let error = err
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
      }
    },
  )

  export const fetchStepConfigById = createAsyncThunk(
    'stepConfig/fetchStepConfigById',
    async (id, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(BaseUrl + `/stepConfig/${id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        return data
      } catch (err) {
        let error = err
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
      }
    },
  )

  export const addNewStepConfig = createAsyncThunk(
    'stepConfig/addNewStepConfig',
    async (stepConfig, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post(
          BaseUrl + `/stepConfig/create/`,
          stepConfig,
          { headers: { Authorization: `token ${token}` } },
        )
        return data
      } catch (err) {
        let error = err
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
      }
    },
  )
  
  export const deleteStepConfig = createAsyncThunk(
    'stepConfig/deleteStepConfig',
    async (id, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const { data } = await axios.delete(
          BaseUrl + `/stepConfig/${id}/delete/`,
          { headers: { Authorization: `token ${token}` } },
        )
        return data
      } catch (err) {
        let error = err
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
      }
    },
  )
  
  export const updateStepConfig = createAsyncThunk(
    'stepConfig/updateStepConfig',
    async (state, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const { data } = await axios.put(
          BaseUrl + `/stepConfig/${state.id}/edit/`,
          state,
          { headers: { Authorization: `token ${token}` } },
        )
        return data
      } catch (err) {
        let error = err
        if (!error.response) {
          throw err
        }
        return rejectWithValue(error.response)
      }
    },
  )

  const stepConfigSlice = createSlice({
    name: 'stepConfig',
    initialState,
    extraReducers:{
        [fetchstepConfig.pending]: (state, action) => {
            state.stepConfigStatus = 'loading'
          },
          [fetchstepConfig.fulfilled]: (state, action) => {
            state.stepConfigStatus = 'succeeded'
            state.stepConfig = action.payload
          },
          [fetchstepConfig.rejected]: (state, action) => {
            state.stepConfigStatus = 'rejected'
            state.stepConfig = action.payload
          },
          [fetchStepConfigById.fulfilled]: (state, action) => {
            state.StepConfig = action.payload
          },
    }
  })
  
  export default stepConfigSlice.reducer