import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  verificationCredibilityStudentsResultsConfigs: [],
  verificationCredibilityStudentsResultsConfigsStatus: 'idle',
  verificationCredibilityStudentsResultsConfigStatus: 'idle',
  error: null,
  verificationCredibilityStudentsResultsConfig:null,
  id:null
}

export const fetchVerificationCredibilityStudentsResultsConfigs =
    createAsyncThunk('verificationCredibilityStudentsResultsConfig/fetchVerificationCredibilityStudentsResultsConfigs',
        async (param, {rejectWithValue}) => {
          const token = localStorage.getItem('token')
          try {
            const response = await axios.get(BaseUrl + '/verificationCredibilityStudentsResultsConfig/', {
              headers: {
                'Authorization': `Token ${token}`
              }
            })
            return response.data
          } catch (err) {
            let error = err;
            if (!error.response) {
              throw err;
            }
            return rejectWithValue(error.response);
          }
        })

export const fetchVerificationCredibilityStudentsResultsConfigById =
    createAsyncThunk('verificationCredibilityStudentsResultsConfig/fetchVerificationCredibilityStudentsResultsConfigById',
        async (id, {rejectWithValue}) => {
          const token = localStorage.getItem('token')
          try {
            const response = await axios.get(BaseUrl + `/verificationCredibilityStudentsResultsConfig/${id}/`, {
              headers: {
                'Authorization': `token ${token}`
              }
            })
            return response.data
          } catch (err) {
            let error = err;
            if (!error.response) {
              throw err;
            }
            return rejectWithValue(error.response);
          }
        })

export const addNewVerificationCredibilityStudentsResultsConfig = createAsyncThunk(
    'verificationCredibilityStudentsResultsConfig/addNewVerificationCredibilityStudentsResultsConfig',
    async (initialVerificationCredibilityStudentsResultsConfig, {rejectWithValue}) => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.post(BaseUrl + `/verificationCredibilityStudentsResultsConfig/create/`, initialVerificationCredibilityStudentsResultsConfig, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        return response.data
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
      }
    })

export const verificationCredibilityStudentsResultsConfigUpdate = createAsyncThunk(
    'verificationCredibilityStudentsResultsConfig/verificationCredibilityStudentsResultsConfigUpdate',
    async (data, {rejectWithValue}) => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.put(BaseUrl + `/verificationCredibilityStudentsResultsConfig/${data.id}/edit/`, data.modified, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        return response.data
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
      }
    })

export const deleteVerificationCredibilityStudentsResultsConfig =
    createAsyncThunk('verificationCredibilityStudentsResultsConfig/deleteVerificationCredibilityStudentsResultsConfig',
        async (id, {rejectWithValue}) => {
          const token = localStorage.getItem('token')
          try {
            const response = await axios.delete(BaseUrl + `/verificationCredibilityStudentsResultsConfig/${id}/delete/`, {
              headers: {
                'Authorization': `token ${token}`
              }
            })
            return response.data
          } catch (err) {
            let error = err;
            if (!error.response) {
              throw err;
            }
            return rejectWithValue(error.response);
          }
        })

const verificationCredibilityStudentsResultsConfigsSlice = createSlice({
  name: ' verificationCredibilityStudentsResultsConfig',
  initialState,
  extraReducers: {
    [fetchVerificationCredibilityStudentsResultsConfigs.pending]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigsStatus = 'loading'
    },
    [fetchVerificationCredibilityStudentsResultsConfigs.fulfilled]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigsStatus = 'succeeded'
      state.verificationCredibilityStudentsResultsConfigs = action.payload
    },
    [fetchVerificationCredibilityStudentsResultsConfigs.rejected]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchVerificationCredibilityStudentsResultsConfigById.pending]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigStatus = 'loading'
    },
    [fetchVerificationCredibilityStudentsResultsConfigById.fulfilled]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigStatus = 'succeeded'
      state.verificationCredibilityStudentsResultsConfig = action.payload
    },
    [fetchVerificationCredibilityStudentsResultsConfigById.rejected]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewVerificationCredibilityStudentsResultsConfig.fulfilled]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigs.push(action.payload)
    },
    [addNewVerificationCredibilityStudentsResultsConfig.rejected]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [verificationCredibilityStudentsResultsConfigUpdate.fulfilled]: (state, action) => {},
    [verificationCredibilityStudentsResultsConfigUpdate.rejected]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteVerificationCredibilityStudentsResultsConfig.fulfilled]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigStatus = 'succeeded'
      state.verificationCredibilityStudentsResultsConfigs = state.verificationCredibilityStudentsResultsConfigs.splice(state.verificationCredibilityStudentsResultsConfigs.findIndex((arrow) => arrow.id === action.payload), 1);
    },
    [deleteVerificationCredibilityStudentsResultsConfig.rejected]: (state, action) => {
      state.verificationCredibilityStudentsResultsConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    reducers: {
      getId: (state, action) => {
        state.id = action.payload
      },
      verificationCredibilityStudentsResultsConfigUpdated(state, action) {
        const {id, name} = action.payload
        const existingVerificationCredibilityStudentsResultsConfig = state.verificationCredibilityStudentsResultsConfigs.find(verificationCredibilityStudentsResultsConfig => verificationCredibilityStudentsResultsConfig.id === id)
        if (existingVerificationCredibilityStudentsResultsConfig) {
          existingVerificationCredibilityStudentsResultsConfig.name = name
        }
      },
    }
  }
})

export const {
  verificationCredibilityStudentsResultsConfigAdded,
  verificationCredibilityStudentsResultsConfigUpdated,
  getId
} = verificationCredibilityStudentsResultsConfigsSlice.actions

export default verificationCredibilityStudentsResultsConfigsSlice.reducer

export const selectAllVerificationCredibilityStudentsResultsConfigs = state => state.verificationCredibilityStudentsResultsConfig.verificationCredibilityStudentsResultsConfigs
