import {
  DeleteOutlined
} from "@ant-design/icons";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifficultiesAndChallengesByProgram } from "reduxToolkit/features/DifficultiesAndChallenges/DifficultiesAndChallengesSlice.js";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {
  deleteDifficultiesAndChallenge
} from "../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import AddDifficultiesAndChallenges from "../../../Components/AddDifficultiesAndChallenges";
import useDifficultiesAndChallengesTable from "../../../Components/useDifficultiesAndChallengesTable";
import "../improvement.css";












const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const headCells = [
  { id: "Difficulties and Challenges", label: "Difficulties and Challenges" },
  { id: "Implications on the Program", label: "Implications on the Program" },
  { id: "Actions Taken", label: "Actions Taken" },
  { id: "delete", label: "" },
];

export default function DifficultiesAndChallenges(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useDifficultiesAndChallengesTable(records, headCells, filterFn);
  let program_id =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].id;
  useEffect(() => {
    dispatch(fetchDifficultiesAndChallengesByProgram(program_id));
  }, []);

  const DifficultiesAndChallenges = useSelector(
    (state) => state.DifficultiesAndChallenges.DifficultiesAndChallengesListByProgram
  );
  console.log(DifficultiesAndChallenges, 'DifficultiesAndChallenges')

  const DeleteDifficultiesAndChallenges = (id) => {
    dispatch(deleteDifficultiesAndChallenge(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchDifficultiesAndChallengesByProgram(program_id));
      });
  };
  const [pageSize, setPageSize] = React.useState(10);
  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          <AddDifficultiesAndChallenges
            program_id={
              props.AnnualProgramReport &&
              props.AnnualProgramReport.programs &&
              props.AnnualProgramReport.programs[0] &&
              props.AnnualProgramReport.programs[0].id
            }
          />
        </GridItem>
      </GridContainer>

      <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
        <TblHead />
        <TableBody>
          {DifficultiesAndChallenges &&
            DifficultiesAndChallenges.map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    borderLeft: "1px solid lightgray",
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      maxWidth: "300px",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item && item.difficulties_and_Challenges}
                  </p>
                </TableCell>

                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.implication_of_program}
                </TableCell>


                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.actions_taken}
                </TableCell>

                <TableCell
                  style={{
                    borderRight: "1px solid lightgray"
                  }}
                >
                  <DeleteOutlined

                    onClick={() => DeleteDifficultiesAndChallenges(item && item.id)}
                    style={{ color: "red" }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TblContainer>
    </div>
  );
}
