// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";

// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import { makeStyles } from "@material-ui/core/styles";
// import MenuItem from "@material-ui/core/MenuItem";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";

// import ListItemText from "@material-ui/core/ListItemText";
// import { updateClo } from "../../reduxToolkit/features/CLO/CloSpecSlice";
// import { fetchPloById } from "../../reduxToolkit/features/PLO/PLOSpecSlice";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramSpecSlice";
// import { unwrapResult } from "@reduxjs/toolkit";
// import {
//   fetchGraduateAttributeSpecById,
//   deleteGraduateAttributeSpec,
// } from "../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";

// import { useTranslation } from "react-i18next";
// import ErrorModal from "./ErrorModal";
// import Button from "components/CustomButtons/Button.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import { Form, Input, message, Select, notification } from "antd";
// const { Option } = Select;

// const useStyles = makeStyles((theme) => ({
//   button: {
//     display: "block",
//     marginTop: theme.spacing(2),
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 220,
//   },
// }));
// export default function GraduateAttributesAndClosMapModal(props) {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const [open, setOpen] = React.useState(false);
//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const [openErrorModal, setOpenErrorModal] = React.useState(false);
//   const [handledError, setHandledError] = React.useState("");
//   const handleOpenErrorModal = () => {
//     setOpenErrorModal(true);
//   };
//   const handleCloseErrorModal = () => {
//     setOpenErrorModal(false);
//   };

//   const [clo_id, setClo_id] = React.useState([]);
//   const [weight_ga, setWeight_ga] = React.useState("");
//   const [ploIndicatorsSpec_id, setPloIndicatorsSpec_id] = React.useState("");
//   const [clos, setClos] = React.useState([]);

//   useEffect(() => {
//     dispatch(fetchProgramById(props.programId))
//       .then(unwrapResult)
//       .then((program) => {
//         setClos(program && program.coursesSpec.map((el) => el && el.closSpec));
//       });
//   }, [program]);
//   const program = useSelector((state) => state.programSpec.program);

//   function handleChange(value) {
//     setClo_id(value);
//   }
//   const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");
//   const graduateAttributeIndicatorsSpec_id = props.GAInd_id;

//   const openNotificationWithIcon = (type, msg) => {
//     notification[type]({
//       message: type,
//       description: msg,
//       top: 50,
//       duration: 2,
//     });
//   };
//   const [addRequestStatus, setAddRequestStatus] = useState("idle");

//   const onFinish = async () => {
//     const resultAction = await dispatch(
//       updateClo({
//         id: clo_id,
//         updatedClo: {
//           weight_ga,
//           graduateAttributeIndicatorsSpec_id,
//           ploIndicatorsSpec_id,
//         },
//       })
//     );

//     if (updateClo.fulfilled.match(resultAction)) {
//       // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
//       const plo = resultAction.payload;
//       console.log("success", `Added ${plo.name}`);
//       openNotificationWithIcon("success", `Added ${plo.name}`);
//       dispatch(fetchGraduateAttributeSpecById(props.GraduateAttributeID));
//       handleClose();
//     } else {
//       if (resultAction.payload) {
//         // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
//         console.log(
//           "resultAction.payload from component",
//           resultAction.payload
//         );
//         setHandledError(resultAction.payload);

//         handleOpenErrorModal();
//         handleClose();
//       } else {
//         console.log("resultAction.error  from component", resultAction.error);
//         setHandledError(resultAction.error);
//         handleOpenErrorModal();
//         handleClose();
//       }
//     }
//   };

//   const classes = useStyles();
//   const onFinishFailed = (errorInfo) => {};

//   return (
//     <div>
//       <MenuItem onClick={props.disabledStatus ? {} :handleClickOpen}>
//         <ListItemText primary="Add CLOs" />
//       </MenuItem>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="form-dialog-title"
//         maxWidth="sm"
//         fullWidth="true"
//       >
//         <DialogTitle id="form-dialog-title">{t("Add CLOs")}</DialogTitle>
//         <DialogContent>
//           <Form
//             name="basic"
//             layout="vertical"
//             onFinish={onFinish}
//             onFinishFailed={onFinishFailed}
//             scrollToFirstError
//             style={{ padding: "0px 10px" }}
//           >
//             <GridContainer direction="row" justify="center" alignItems="center">
//               <GridItem xs={8} sm={8} md={12}>
//                 <DialogContentText></DialogContentText>
//               </GridItem>
//               <GridItem xs={6} sm={6} md={12}>
//                 <Form.Item
//                   label={t("CLO *")}
//                   name="clo_id"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Please input the CLO!",
//                     },
//                   ]}
//                 >
//                   <Select
//                     showSearch
//                     style={{ width: "100%", zIndex: 5 }}
//                     dropdownStyle={{ zIndex: 2000 }}
//                     optionFilterProp="children"
//                     onChange={handleChange}
//                     defaultValue={"select clo"}
//                     filterOption={(input, option) =>
//                       option.children
//                         .toLowerCase()
//                         .indexOf(input.toLowerCase()) >= 0
//                     }
//                   >
//                     {clos &&
//                       clos.map((tab) =>
//                         tab.map((el) => (
//                           <Option value={el.id}>{el.title}</Option>
//                         ))
//                       )}
//                   </Select>
//                 </Form.Item>
//               </GridItem>
//               <GridItem xs={4} sm={4} md={12}>
//                 <Form.Item
//                   label={t("weight_ga *")}
//                   name="weight_ga"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Please input the  GA's weight_ga!",
//                       warningOnly: true,
//                     },
//                   ]}
//                 >
//                   <Input
//                     id="weight"
//                     value={weight_ga}
//                     onChange={(event) => {
//                       setWeight_ga(event.target.value);
//                     }}
//                   />
//                 </Form.Item>
//               </GridItem>
//             </GridContainer>

//             <GridContainer direction="row" justify="center" alignItems="center">
//               <GridItem xs={12} sm={12} md={10}>
//                 <GridContainer justify="flex-end">
//                   <GridItem>
//                     <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
//                       <Button color="primary" onClick={handleClose}>
//                         {t("Cancel")}
//                       </Button>
//                     </Form.Item>
//                   </GridItem>
//                   <GridItem>
//                     <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
//                       <Button type="submit" color="primary">
//                         {t("Save")}
//                       </Button>
//                     </Form.Item>
//                   </GridItem>
//                 </GridContainer>
//               </GridItem>
//             </GridContainer>
//           </Form>
//         </DialogContent>
//       </Dialog>
//       <ErrorModal
//         open={openErrorModal}
//         handleClose={handleCloseErrorModal}
//         handledError={handledError}
//       />
//     </div>
//   );
// }



import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import Button from 'components/CustomButtons/Button';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { fetchNotAffectedCLOsToGAI, addNewCLOSpecWeight } from '../../reduxToolkit/features/CLO/WeightCloSpecSlice'
import { fetchGraduateAttributeSpecById } from '../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice'
import { fetchProgramById } from '../../reduxToolkit/features/program/ProgramSpecSlice'

import { Form, Input, message, Select, notification } from 'antd';
import ErrorModal from "./ErrorModal"

import { useTranslation } from 'react-i18next';

export default function GraduateAttributesAndClosMapModal(props) {


  const { t } = useTranslation();
  const dispatch = useDispatch()

  const [CLOs, setCLOs] = React.useState([]);

  const fetchCLOs = async () => {
    try {
      const resultAction = await dispatch(fetchNotAffectedCLOsToGAI({ GAIndicatorId: props.GAInd.id, programId: props.programId }))
        .unwrap()
        .then((clos) => {
          // handle result here
          setCLOs(clos)
        })

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };


  useEffect(() => {
    console.log("props.GAInd id", props.GAInd.id)
    console.log("props.GAId", props.GAId)
    console.log("props.programId", props.programId)
    fetchCLOs()

  }, [])

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };


  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2
    });
  };
  const { Option } = Select;


  const onFinish = async (values) => {
    console.log("values", values)
    const resultAction =
      await dispatch(addNewCLOSpecWeight({ ...values, graduateAttributeIndicatorsSpec_id: props.GAInd.id }))

    if (addNewCLOSpecWeight.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      console.log('resultAction', resultAction)
      console.log('resultAction.payload', resultAction.payload)

      openNotificationWithIcon('success', `Affected`)
      handleClose()
      dispatch(fetchGraduateAttributeSpecById(props.GAId))
      dispatch(fetchNotAffectedCLOsToGAI({ GAIndicatorId: props.GAInd.id, programId: props.programId }))
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log('resultAction.payload from component', resultAction.payload)
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        handleClose()
      } else {
        console.log('resultAction.error  from component', resultAction.error)
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        handleClose()
      }
    }

  }

  return (
    <div>

      <MenuItem
        onClick={(event) => {
          handleClickOpen(event); props.handleDropDownButtonClose();
        }}>

        <ListItemText primary="Add CLO" />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('Add CLO')}</DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("CLO")}
                  name="cloSpec_id"
                  rules={[{ required: true, message: "Please select a CLO!" }]}
                >
                  <Select
                    showArrow="true"
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t('select a CLO')}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {CLOs && CLOs.map(clo => <Option value={clo.id}>{clo.title}</Option>)}
                  </Select>
                </Form.Item>
              </GridItem>


              <GridItem xs={12} sm={12} md={6}>
                <Form.Item
                  label={t('Weight')}
                  name="weight"
                  rules={[{ required: true, message: "Please input the weight!" }]}
                >
                  <Input />
                </Form.Item>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <Form.Item
                  label={t('Target')}
                  name="target"
                  rules={[{ required: true, message: "Please input the target!" }]}
                >
                  <Input />
                </Form.Item>
              </GridItem>
            </GridContainer>


            {/* </FormControl> */}
            <GridContainer justify="flex-end">
              <GridItem>

                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button color="primary" onClick={() => { handleClose() }}>
                        {t('Cancel')}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="primary">
                        {t('Save')}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>

              </GridItem>
            </GridContainer>

          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
    </div>
  );
}
