import React from 'react'
import MoreVert from "@material-ui/icons/MoreVert";
import Button from '../../../components/CustomButtons/Button'
import { unwrapResult } from '@reduxjs/toolkit';
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useDispatch } from "react-redux";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { deleteArea, fetchAreas } from "reduxToolkit/features/area/AreaSpecSlice";

import { makeStyles } from "@material-ui/core/styles";
import EditActionplandDetails from './EditActionplandDetails';
import ErrorModal from 'views/Components/ErrorModal';
import SuperGeneralDeleteModal from 'views/Components/SuperGeneralDeleteModal';
const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));
export default function DropDownActionButtonDetails(props) {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorRef = React.useRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    console.log(props.item)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleOpenDeleteModal = () => {
        setOpen(true);
      };
    
      const handleCloseDeleteModal = () => {
        setOpen(false);
      };
    

      const [openErrorModal, setOpenErrorModal] = React.useState(false);
      const [handledError, setHandledError] = React.useState("");
    
      const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
      };
      const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
      };
  return (
    <div>
        <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        
         {/* <Check id={props.id} fetchAllActions={props.fetchAllActions}  handleClose={handleClose}/> */}
       
      
      <EditActionplandDetails state_id={props.state_id} item={props.item}  handleClose={handleClose}/>
      
      </StyledMenu>
      <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError}/>
      <SuperGeneralDeleteModal
        //delete_Element={handleDeleteItem}
        //Element_ID={props.id}
        open={open}
        handleClose={handleCloseDeleteModal}

      />
    </div>
  )
}
