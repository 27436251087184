import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  ConclusionsDifficultiesList: [],
  ConclusionsDifficultiesStatus: "idle",
  error: null,
};

export const fetchConclusionsDifficultiesByCourseId_And_Type = createAsyncThunk(
  "ConclusionsDifficulties/fetchConclusionsDifficultiesByCourseId_And_Type",
  async (object, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl +
          `/conclusionsDifficulties/?type=${object.type}&course_id=${object.course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // const response = jsonData
      console.log(response.data, "response.data course");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);




export const updateDifficultiesAction = createAsyncThunk(
    "ConclusionsDifficulties/updateDifficultiesAction",
    async (initialObject, { rejectWithValue }) => {
      try {
        console.log("initialObject", initialObject);
        const token = localStorage.getItem("token");
        const response = await axios.post(
          BaseUrl + `/conclusionsDifficulties/create/`,
          initialObject,
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        console.log(response.data);
        return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        console.log("error.response from inside the CAT", error.response);
        return rejectWithValue(error.response);
      }
    }
  );


const ConclusionsDifficultiesSlice = createSlice({
  name: "ConclusionsDifficulties",
  initialState,
  extraReducers: {},
  reducers: {
   
  },
  extraReducers: {
    [fetchConclusionsDifficultiesByCourseId_And_Type.pending]: (state, action) => {
      state.ConclusionsDifficultiesStatus = "loading";
    },
    [fetchConclusionsDifficultiesByCourseId_And_Type.fulfilled]: (state, action) => {
      state.ConclusionsDifficultiesStatus = "succeeded";
      state.ConclusionsDifficultiesList = action.payload;
    },
    [fetchConclusionsDifficultiesByCourseId_And_Type.rejected]: (state, action) => {
      state.ConclusionsDifficultiesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },



    [updateDifficultiesAction.fulfilled]: (state, action) => {},
    [updateDifficultiesAction.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});

export const {} = ConclusionsDifficultiesSlice.actions;

export default ConclusionsDifficultiesSlice.reducer;
