import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  courseAssessmentMethods: [],
  courseAssessmentMethodsStatus: 'idle',
  courseAssessmentMethodStatus: 'idle',
  error: null,
  courseAssessmentMethod: null,
  id: null
}


export const fetchCourseAssessmentMethods = createAsyncThunk('courseAssessmentMethod/fetchCourseAssessmentMethods', async () => {
  const token=localStorage.getItem('token')
  const response = await axios.get(BaseUrl+'/courseAssessmentMethod/', {
    headers: {
      'Authorization': `Token ${token}`
    }
  })
//  console.log(response.data, 'response.data')

  return response.data
})

export const fetchCourseAssessmentMethodById = createAsyncThunk('courseAssessmentMethod/fetchCourseAssessmentMethodById', async (id) => {
  const token=localStorage.getItem('token')
  const response = await axios.get(BaseUrl+`/courseAssessmentMethod/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
   console.log(response.data,'fetchCourseAssessmentMethodById  response')

  return response.data
})

//NEW SALAH FUNCTION

export const fetchCourseAssessmentMethodById_NEW = createAsyncThunk('courseAssessmentMethod/fetchCourseAssessmentMethodById', async (id_var,CallBack) => {
  const token=localStorage.getItem('token')
  const response = await axios.get(BaseUrl+`/courseAssessmentMethod/${id_var}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
   console.log(response.data,'fetchCourseAssessmentMethodById  response')
   CallBack() ; 

   console.log('myy id'+id_var)

  return response.data
})


//NEW SALAH FUNCTION


export const addNewCourseAssessmentMethod = createAsyncThunk(
  'CourseAssessmentMethod/addNewCourseAssessmentMethod',
  async (initialCourseAssessmentMethod) => {
    console.log('initial course', initialCourseAssessmentMethod)
    const token=localStorage.getItem('token')
    const response = await axios.post(BaseUrl+`/courseAssessmentMethod/create/`, initialCourseAssessmentMethod, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
   // console.log(response.data)
    return response.data
  }
)



export const updateCourseAssessmentMethod = createAsyncThunk(
  'CourseAssessmentMethod/updateCourseAssessmentMethod',
  async (data, thunkAPI) => {
    console.log(data.updatedcourseAssessmentMethod, 'updated courseAssessmentMethod')
    const token=localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl+`/courseAssessmentMethod/${data.id}/edit/`, data.updatedcourseAssessmentMethod, {
        headers: {
          'Authorization': `token ${token}`
        }
      })

      console.log(response.data, 'response.data updatin CAM from slice')
      return response.data


    } catch (error) {
      console.error('Failed to update the courseAssessmentMethod: ', error)

    }
  }
)
//   export const courseUpdate = createAsyncThunk(
//     'Course/courseUpdate',
//     async (data,thunkAPI) => {

//       try{
//         const response = await axios.put(`http://127.0.0.1:8000/api/course/${data.id}/edit/`,data.modified ,{
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })   
//       console.log(data,'updatedCourse')
//       console.log(response.data,'response.data')   
//       return response.data


//     } catch (error) {
// console.error('Failed to update the course: ', error)

// }
//     }
//   )

//   export const deleteCourse = createAsyncThunk('program/deleteCourse', async (id) => {
//     const response = await axios.delete(`http://127.0.0.1:8000/api/course/${id}/delete/`, {
//         headers: {
//           'Authorization': `token ${token}`
//         }
//       })
//       // console.log(response.data,'response.data')

//     return response.data
//   })
export const deleteCourseAssessmentMethod = createAsyncThunk('program/deleteCourseAssessmentMethod', async (id) => {
  const token=localStorage.getItem('token')
  const response = await axios.delete(BaseUrl+`/courseAssessmentMethod/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data,'response.data')

  return response.data
})
const courseAssessmentMethodsSlice = createSlice({
  name: ' courseAssessmentMethod',
  initialState,
  extraReducers: {
    [fetchCourseAssessmentMethods.pending]: (state, action) => {
      state.courseAssessmentMethodsStatus = 'loading'
    },
    [fetchCourseAssessmentMethods.fulfilled]: (state, action) => {
      state.courseAssessmentMethodsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.courseAssessmentMethods = state.courseAssessmentMethods.concat(action.payload)
    },
    [fetchCourseAssessmentMethods.rejected]: (state, action) => {
      state.courseAssessmentMethodsStatus = 'failed'
      state.error = action.payload
    },

    [fetchCourseAssessmentMethodById.pending]: (state, action) => {
      state.courseAssessmentMethodStatus = 'loading'
    },
    [fetchCourseAssessmentMethodById.fulfilled]: (state, action) => {
      state.courseAssessmentMethodStatus = 'succeeded'

      // Add any fetched posts to the array
      state.courseAssessmentMethod = action.payload
    },
    [fetchCourseAssessmentMethodById.rejected]: (state, action) => {
      state.courseAssessmentMethodStatus = 'failed'
      state.error = action.payload
    },
    [addNewCourseAssessmentMethod.fulfilled]: (state, action) => {
      state.courseAssessmentMethods.push(action.payload)
    },
    //   [courseUpdate.fulfilled]: (state, action) => {
    //     console.log(action) 
    //   },

    [updateCourseAssessmentMethod.fulfilled]: (state, action) => {
      console.log('update fulffilled')
      // const result = state.courseAssessmentMethods.filter(courseAssessmentMethod => courseAssessmentMethod.id !== action.payload.id);
      // result.push(action.payload)
      // state.courseAssessmentMethods = result
    },

    reducers: {
      getId:
        (state, action) => {
          state.id = action.payload
        },


      courseAssessmentMethodUpdated(state, action) {
        const { id, weight, total_marks, assessmentMethod_id} = action.payload
        const existingCourseAssessmentMethod = state.courseAssessmentMethod.find(courseAssessmentMethod => courseAssessmentMethod.id === id)
        if (existingCourseAssessmentMethod) {
          existingCourseAssessmentMethod.weight = weight
          existingCourseAssessmentMethod.total_marks = total_marks
          existingCourseAssessmentMethod.assessmentMethod_id = assessmentMethod_id
         
        }
      }
    }
  }
})

export const { courseAssessmentMethodAdded, courseAssessmentMethodUpdated, getId } = courseAssessmentMethodsSlice.actions

export default courseAssessmentMethodsSlice.reducer

export const selectAllCourseAssessmentMethods = state => state.courseAssessmentMethod.courseAssessmentMethods
