import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '../../../../components/CustomButtons/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
// nodejs library to set properties for components
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'

import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
//import { Checkbox } from 'antd'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import Tooltip from '@material-ui/core/Tooltip'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import ListItemText from '@material-ui/core/ListItemText'
import { notification } from 'antd'
import Menu from '@material-ui/core/Menu'
import { Form } from 'antd'

import { withStyles } from '@material-ui/core/styles'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'

import ErrorModal from 'views/Components/ErrorModal'
import { deleteStudent } from 'reduxToolkit/features/Student/StudentSlice'
import AddStudent from './AddStudent'
import EditStudent from './EditStudent'
import { fetchStudentById } from 'reduxToolkit/features/Student/StudentSlice'
const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)
const { Option } = Select

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const DropDownAssessmentMethodGroupInstructor = ({
  selected,
  student,
  ping,
  setPing,
  stateLink,
  
}) => {
    console.log(student)
  const { course_id, Assessmentid, AssessmentName, group_id } = useParams()
  const { t } = useTranslation()
  const history = useHistory()
  const buttonClasses = useButtonStyles()
  const [open, setOpen] = React.useState(false)
  const [openEditStudent, setOpenEditStudent] = useState(false)
  const anchorRef = React.useRef(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleClickOpenEditStudent = () => {
    setOpenEditStudent(true)
  }
  const handleCloseEditStudent = () => {
    setOpenEditStudent(false)
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDeleteItem = async (id) => {
    console.log('id : ', id)
    dispatch(deleteStudent(id))
      .unwrap()
      .then((originalPromiseResult) => {
        setPing(!ping)
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    Height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={selected}
          // onClick={() => {
          //   handleClickOpenEditStudent()
          //   handleClose()
          // }}
          onClick={() => {
            history.push({
              pathname: `/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${Assessmentid}/${AssessmentName}/Groups/${group_id}/editStudent`,
              state: {
                mode: 'edit',
                values: { ...stateLink, id: student },
              },
            })
          }}
        >
          {t('Edit')}
        </MenuItem>

        <MenuItem
          disabled={selected}
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />{' '}
        </MenuItem>
      </StyledMenu>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={student}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}

export default DropDownAssessmentMethodGroupInstructor
