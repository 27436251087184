import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { notification, Space } from "antd";

export default function useHandelError(statusCode) {
  // const { t } = useTranslation();
  if (statusCode === 204) {
    notification["success"]({
      message: "Deleted",
      duration: 1.5,
      style: { marginTop: "20px" },
      description: "This Item was successfully deleted  !",
    });
    // console.log("This Item was successfully deleted  !")
  } else if (statusCode === 400) {
  } else if (statusCode === 404) {
    notification["info"]({
      message: "erorr",
      duration: 1.5,
      style: { marginTop: "20px" },
      description: "network error !",
    });
  } else if (statusCode >= 500) {
    notification["warning"]({
      message: "erorr",
      duration: 1.5,
      style: { marginTop: "20px" },
      description: "server error,try again !",
    });
  }
  
}
