import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken.js";

const initialState = {
    groupAssessment: [],
    groupAssessmentStatus: "idle",
    error: null,
    // group: null,
    // groupsByProgramStatus: "idle",
    // groupsByProgram: [],
    // groupAssessmentStatus : "idle",
    // groupAssessments : [],
    // groupAssessment : null,
};

export const fetchGroupAssessmentAll = createAsyncThunk(
    "groupAssessment/fetchGroupAssessmentAll",
    async () => {
        const token = localStorage.getItem("token");
        const response = await axios.get(BaseUrl+`/groupAssessment/all/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        console.log(response.data);

        return response.data;
    }
);

const groupSlice = createSlice({
    name: 'groupAssessment',
    initialState: initialState,
    extraReducers: {
        [fetchGroupAssessmentAll.pending]: (state, action) => {
            state.groupAssessmentStatus = "loading";
        },
        [fetchGroupAssessmentAll.fulfilled]: (state, action) => {
            state.groupAssessmentStatus = "succeeded";
            // Add any fetched posts to the array
            state.groupAssessment = action.payload;
        },
        [fetchGroupAssessmentAll.rejected]: (state, action) => {
            state.groupAssessmentStatus = "failed";
            state.error = action.payload;
        },

    }
})

export default groupSlice.reducer;