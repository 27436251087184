import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  campaigns: [],
  campaignsInstactor:[],
  campaignsByUserAndSemesterCourse: [],
  campaignsByUserAndSemesterProgram: [],
  campaignsStatus: 'idle',
  campaignsFiltred: [],
  campaignsFiltredStatus: 'idle',
  campaignStatus: 'idle',
  error: null,
  campaign: null,
  campaignTemplate: null,
};

export const fetchCampaigns = createAsyncThunk('campaign/fetchCampaigns', async () => {
  const token = localStorage.getItem('token')

  const response = await axios.get(BaseUrl + '/campaign/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
  console.log(response.data, 'response.data')

  return response.data;
}
);

export const fetchCampaignsInstactor = createAsyncThunk('campaign/fetchCampaignsInstactor', async () => {
  const token = localStorage.getItem('token')

  const response = await axios.get(BaseUrl + '/campaign/instructor/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
  console.log(response.data, 'response.data')

  return response.data;
}
);

// export const fetchCampaignById = createAsyncThunk('campaign/fetchCampaignById', async (id) => {
//   const token=localStorage.getItem('token')

//   const response = await axios.get(BaseUrl+`/campaign/${id}/course/`, {
//     headers: {
//       'Authorization': `token ${token}`
//     }
//   })
//    console.log(response.data,'response.data')

//   return response.data
// })

export const fetchCampaignsByUserAndSemesterCourse = createAsyncThunk('campaign/fetchCampaignsByUserAndSemesterCourse', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/campaign/ByUserAndSemesterCourse/?semester_id=${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data, 'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})

export const fetchCampaignsByUserAndSemesterProgram = createAsyncThunk('campaign/fetchCampaignsByUserAndSemesterProgram', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/campaign/ByUserAndSemesterProgram/?semester_id=${id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data, 'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})
export const fetchCampaignById = createAsyncThunk('campaign/fetchCampaignById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/campaign/${id}/course/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data, 'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})
export const fetchCampaignByProgramId = createAsyncThunk('campaign/fetchCampaignById', async (id, { rejectWithValue }) => {
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/campaign/by_program/?resource=program&program_id=/${id}/course/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data, 'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})
export const fetchCampaignsFiltred = createAsyncThunk('campaign/fetchCampaignsFiltred', async (data, { rejectWithValue }) => {
  console.log('data', data)
  try {

    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/campaign/filtered/?academic_year_id=${data.academic_year_id}&type=${data.type}&program_id=${data.program_id}&semester_id=${data.semester_id}`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    console.log(response.data, 'response.data')

    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})
export const addNewCampaign = createAsyncThunk(
  "campaign/addNewCampaign",
  async (initialCampaign, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        BaseUrl + `/campaign/create/`,
        initialCampaign,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
)
export const AddParticipantsFromGroups = createAsyncThunk(
  "campaign/AddParticipantsFromGroups",
  async (initialCampaign, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        BaseUrl + `/campaign/AddParticipantsFromGroups/`,
        initialCampaign,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
)

export const updateCampaignToInProgress = createAsyncThunk(
  "campaign/updateCampaignToInProgress",
  async (data, { rejectWithValue }) => {
    console.log(data)
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/campaign/${data.id}/update/`, data,
        {
          headers: {
            'Authorization': `token ${token}`
          }
        }
      );

      console.log(data, "updatedCampaign");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
);
export const updateCampaignToDraft = createAsyncThunk(
  "campaign/updateCampaignToDraft",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/campaign/${data.id}/update/`,
        data.updatedCampaign,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedCampaign");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the Campaign: ", error);
    }
  }
);
export const updateCampaignToDone = createAsyncThunk(
  "campaign/updateCampaignToDone",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        BaseUrl + `/campaign/${data.id}/update/`,
        data.updatedCampaign,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedCampaign");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the Campaign: ", error);
    }
  }
);
export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/campaign/${data.id}/edit/`, data.updatedCampaign, {
        headers: {
          'Authorization': `token ${token}`
        }
      }
      );
      console.log(data, "updatedCampaign");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the Campaign: ", error);
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(BaseUrl + `/campaign/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

const campaignsSlice = createSlice({
  name: "campaign",
  initialState,
  extraReducers: {

    [fetchCampaignsByUserAndSemesterCourse.pending]: (state, action) => {
      state.campaignsStatus = "loading";
    },
    [fetchCampaignsByUserAndSemesterCourse.fulfilled]: (state, action) => {
      state.campaignsStatus = "succeeded";
      // Add any fetched posts to the array
      state.campaignsByUserAndSemesterCourse = action.payload;
    },
    [fetchCampaignsByUserAndSemesterCourse.rejected]: (state, action) => {
      state.campaignsStatus = "failed";
      state.campaignStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    [fetchCampaignsByUserAndSemesterProgram.pending]: (state, action) => {
      state.campaignsStatus = "loading";
    },
    [fetchCampaignsByUserAndSemesterProgram.fulfilled]: (state, action) => {
      state.campaignsStatus = "succeeded";
      // Add any fetched posts to the array
      state.campaignsByUserAndSemesterProgram = action.payload;
    },
    [fetchCampaignsByUserAndSemesterProgram.rejected]: (state, action) => {
      state.campaignsStatus = "failed";
      state.campaignStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
    [fetchCampaigns.pending]: (state, action) => {
      state.campaignsStatus = "loading";
    },
    [fetchCampaigns.fulfilled]: (state, action) => {
      state.campaignsStatus = "succeeded";
      // Add any fetched posts to the array
      state.campaigns = action.payload;
    },
    [fetchCampaigns.rejected]: (state, action) => {
      state.campaignsStatus = "failed";
      state.error = action.payload;
    },
    [fetchCampaignsInstactor.pending]: (state, action) => {
      state.campaignsStatus = "loading";
    },
    [fetchCampaignsInstactor.fulfilled]: (state, action) => {
      state.campaignsStatus = "succeeded";
      // Add any fetched posts to the array
      state.campaignsInstactor = action.payload;
    },
    [fetchCampaignsInstactor.rejected]: (state, action) => {
      state.campaignsStatus = "failed";
      state.error = action.payload;
    },
    [fetchCampaignsFiltred.pending]: (state, action) => {
      state.campaignsFiltredStatus = 'loading'
    },
    [fetchCampaignsFiltred.fulfilled]: (state, action) => {
      state.campaignsFiltredStatus = 'succeeded'
      // Add any fetched posts to the array
      state.campaignsFiltred = action.payload
    },
    [fetchCampaignsFiltred.rejected]: (state, action) => {
      state.campaignsFiltredStatus = 'failed'
      state.error = action.payload
    },
    [fetchCampaignById.pending]: (state, action) => {
      state.campaignStatus = "loading";
    },
    [fetchCampaignById.fulfilled]: (state, action) => {
      state.campaignStatus = "succeeded";

      // Add any fetched posts to the array
      state.campaign = action.payload;
    },
    [fetchCampaignById.rejected]: (state, action) => {
      state.campaignStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        //console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error;
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },
     [AddParticipantsFromGroups.fulfilled]: (state, action) => {
      state.campaigns.push(action.payload);
    },

    [addNewCampaign.fulfilled]: (state, action) => {
      state.campaigns.push(action.payload);
    },
    [addNewCampaign.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCampaign.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
    [updateCampaign.fulfilled]: (state, action) => {
      const result = state.campaigns.filter(
        (campaign) => campaign.id !== action.payload.id
      );
      result.push(action.payload);
      state.campaigns = result;
      console.log("action.payload.id from slice", action.payload);
    },
    [updateCampaignToInProgress.fulfilled]: (state, action) => {
      const result = state.campaigns.filter(
        (campaign) => campaign.id !== action.payload.id
      );
      result.push(action.payload);
      state.campaigns = result;
      console.log("action.payload.id from slice", action.payload);
    },
    [updateCampaignToDraft.fulfilled]: (state, action) => {
      const result = state.campaigns.filter(
        (campaign) => campaign.id !== action.payload.id
      );
      result.push(action.payload);
      state.campaigns = result;
      console.log("action.payload.id from slice", action.payload);
    },
    [updateCampaignToDone.fulfilled]: (state, action) => {
      const result = state.campaigns.filter(
        (campaign) => campaign.id !== action.payload.id
      );
      result.push(action.payload);
      state.campaigns = result;
      console.log("action.payload.id from slice", action.payload);
    },
    [deleteCampaign.pending]: (state, action) => {
      state.campaignStatus = "loading";
    },
    [deleteCampaign.fulfilled]: (state, action) => {
      console.log(action.payload);
    },
  },
});

export default campaignsSlice.reducer;
