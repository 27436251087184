import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    ActualLearningHoursConfigTypes: [],
    ActualLearningHourStatus: 'idle',
    error: null,
    ActualLearningHour: null,
    id: null
}

export const fetchActualLearningHoursConfigType =
createAsyncThunk(' ActualLearningHourConfigType/fetch ActualLearningHours',
async (param, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
        const response = await axios.get(BaseUrl + `/actualLearningTypeConfig/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
    return response.data
    } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
    }
})

export const fetchActualLearningHoursConfigTypeById =
  createAsyncThunk('ActualLearningHourConfigType/fetchActualLearningHourConfigTypeById',
   async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/actualLearningTypeConfig/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
       return response.data
      } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
)

export const addNewActualLearningHoursConfigType = createAsyncThunk(
    'ActualLearningHourConfigType/addNewActualLearningHoursConfigType',
    async (initialActualLearningHour, { rejectWithValue }) => {
        const token=localStorage.getItem('token')
        try {
            const response = await axios.post(BaseUrl+`/actualLearningTypeConfig/create/`, initialActualLearningHour, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
           return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
              throw err;
            }
            return rejectWithValue(error.response);
        }
    }
)

export const actualLearningHoursConfigTypeUpdate = createAsyncThunk(
    'ActualLearningHourConfigType/actualLearningHoursConfigTypeUpdate',
    async (data, {rejectWithValue}) => {
        try {
            const token = localStorage.getItem('token')
            const response = await axios.put(BaseUrl + `/actualLearningTypeConfig/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const deleteActualLearningHour =
    createAsyncThunk('ActualLearningHourConfigType/deleteActualLearningHour',
        async (id, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.delete(BaseUrl + `/actualLearningTypeConfig/${id}/delete/`, {
                    headers: {
                        'Authorization': `token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

const ActualLearningHourConfigTypeSlice = createSlice({
    name: 'ActualLearningHourConfigType',
    initialState,
    extraReducers: {
        [fetchActualLearningHoursConfigType.pending]: (state, action) => {
            state.ActualLearningHourStatus = 'loading'
        },
        [fetchActualLearningHoursConfigType.fulfilled]: (state, action) => {
            state.ActualLearningHourStatus = 'succeeded'
            state.ActualLearningHoursConfigTypes = action.payload
        },
        [fetchActualLearningHoursConfigType.rejected]: (state, action) => {
            state.ActualLearningHourStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [fetchActualLearningHoursConfigTypeById.pending]: (state, action) => {
            state.ActualLearningHourStatus = 'loading'
        },
        [fetchActualLearningHoursConfigTypeById.fulfilled]: (state, action) => {
            state.ActualLearningHourStatus = 'succeeded'
            state.ActualLearningHour = action.payload
        },
        [fetchActualLearningHoursConfigTypeById.rejected]: (state, action) => {
            state.ActualLearningHourStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [addNewActualLearningHoursConfigType.fulfilled]: (state, action) => {
            state.ActualLearningHoursConfigTypes.push(action.payload)
        },
        [addNewActualLearningHoursConfigType.rejected]: (state, action) => {
            state.ActualLearningHourStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [deleteActualLearningHour.fulfilled]: (state, action) => {},
        [deleteActualLearningHour.rejected]: (state, action) => {
            state.ActualLearningHourStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [actualLearningHoursConfigTypeUpdate.fulfilled]: (state, action) => {},
        [actualLearningHoursConfigTypeUpdate.rejected]: (state, action) => {
            state.ActualLearningHourStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },
        }
    }
})

export const {
    getId
} = ActualLearningHourConfigTypeSlice.actions

export default ActualLearningHourConfigTypeSlice.reducer

export const selectAllFacilitiesRequired = state => state.ActualLearningHourConfigType.ActualLearningHoursConfigTypes
