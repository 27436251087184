import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Form, Modal, Input } from "antd";
import StarIcon from "@material-ui/icons/Star";
import {
  fetchActions,
  updateActionRecommendation,
} from "../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import Badge from "@mui/material/Badge";
import { Option } from "antd/lib/mentions";
import useTable from "../../Components/useTable";
import TableHead from "@mui/material/TableHead";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  Avatar,
  Breadcrumb,
  Divider,
  Select,
  Steps,
  Tabs,
  Tooltip,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import EditAssginTOActionModal from "views/Components/EditAssginTOActionModal";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  blackColor,
  cardTitle,
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import Moment from "moment";
import { useState } from "react";

import DropDownActionButton from "views/Components/DropDownActionButton";
import DropDownActionButtonDetails from "./DropDownActionButtonDetails";
import UpdateStateActionPlanModal from "./UpdateStateActionPlanModal";
import { fetchActionPlans } from "reduxToolkit/features/ActionPlan/ActionPlanSlice";
const useStyles = makeStyles(styles);
export default function ActionPlanDetailsDraft({ item, id, ActionPlanId }) {
  const [openLog, setOpenLog] = useState(true);
  const [ids, setIds] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [records, setRecords] = [];
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  console.log(item);
  const onFinish = async (values) => {
    console.log(values);

    const data = { ...values, id: ids };
    console.log("updatedActionRecommendation: ", data);

    const resultAction = await dispatch(updateActionRecommendation(data));

    if (updateActionRecommendation.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Updated`);
      dispatch(fetchActionPlans());
      handleClose(); // Close the dialog after successful form submission
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        // setHandledError(resultAction.payload);
        // handleOpenErrorModal();
      } else {
        // setHandledError(resultAction.error);
        // handleOpenErrorModal();
      }
    }
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (ids) => {
    setOpen(true);
    setIds(ids);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const headCells = [
    { id: "Actions", label: "Actions " },
    { id: "Type", label: "Type" },
    // { id: "Due Date", label: "Due Date" },
    { id: "Priority", label: "Priority" },
    // { id: "Status", label: "Status" },
    { id: "Assignto", label: "Assign To" },
  ];

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const { TblContainer, TblHead } = useTable(records, headCells, filterFn);
  const users = useSelector((state) => state.user.users);
  const { t } = useTranslation();
  const RaitingRender = (value) => {
    if (value == 1) {
      return <Badge color="error" badgeContent="High"></Badge>;
    } else if (value == 2) {
      return <Badge color="warning" badgeContent="Medium"></Badge>;
    } else if (value == 3) {
      return <Badge color="info" badgeContent="Low"></Badge>;
    }
  };

  const StatusRender = (value) => {
    if (value == "Archived") {
      return <Badge color="info" badgeContent="Archived"></Badge>;
    } else if (value == "Created") {
      return <Badge color="success" badgeContent="Created"></Badge>;
    } else if (value == "Waiting for approval") {
      return <Badge color="primary" badgeContent="Waiting"></Badge>;
    } else if (value == "Approved") {
      return <Badge color="primary" badgeContent="Approved"></Badge>;
    }
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <MenuItem
          onClick={(event) => {
            handleClickOpen(event);
            handleClose();
          }}
          style={{ color: "#AC79B0" }}
        >
          {t("Edit")}
        </MenuItem>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title">{t("Edit")}</DialogTitle>
          <DialogContent>
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}></GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Form.Item label={t("Status")} name="status">
                    <Select
                      placeholder="Please select a user"
                      style={{ width: 400 }}
                      dropdownStyle={{ zIndex: 10000 }}
                    >
                      <Option value="Archived">Archived</Option>
                      <Option value="Created">Created</Option>
                      <Option value="Waiting">Waiting</Option>
                      <Option value="Waiting for approval">
                        Waiting for approval
                      </Option>
                      <Option value="Approved">Approved</Option>
                    </Select>
                  </Form.Item>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item label={t("Feedback")} name="feedback">
                      <Input.TextArea />
                    </Form.Item>
                  </GridItem>
                </GridItem>
              </GridContainer>

              {/* </FormControl> */}
              <GridContainer justify="flex-end">
                <GridItem>
                  <Form.Item>
                    <GridContainer>
                      <GridItem>
                        <Button
                          color="primary"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          {t("Cancel")}
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button type="submit" color="primary">
                          {t("Save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </Form.Item>
                </GridItem>
              </GridContainer>
            </Form>
          </DialogContent>
        </Dialog>

        <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
          {/* <TblHead /> */}
          {/* <TableBody>
                                  {item?.recommendations?.map((item) => (
                                    <TableRow key={item.id}>
                                      <TableCell
                                        style={{
                                          borderRight: "1px solid lightgray",
                                        }}
                                      >
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            maxWidth: "180px",
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {item && item.description}
                                        </p>
                                      </TableCell>
                                      <TableCell
                                        className="multipleLine"
                                        style={{
                                          borderRight: "1px solid lightgray",
                                        }}
                                      >
                                        
                                        <div>
                                          <Tooltip title="Code" placement="top">
                                            <small style={{backgroundColor:"#6C7B9D",padding:"2px 5px",width:"15px",borderRadius:"5px" ,color:"white",marginRight:"5px"}}>
                                              {item && item.actionTypeDetails.code}
                                            </small>
                                           </Tooltip> 
                                              {item && item.actionTypeDetails.name}
                                        </div>
                                        <div style={{marginTop:"10px"}}>{item && item.actionTypeDetails.category}</div>
                                        
                                      
                                      </TableCell>
                                  
                                      <TableCell
                                        className="multipleLine"
                                        style={{
                                          borderRight: "1px solid lightgray",
                                        }}
                                      >
                                        {RaitingRender(item && item.priority)}
                                      </TableCell>

                                    
                                      <TableCell>
                                        <GridContainer xs={12} sm={12} md={12}>
                                          <GridItem xs={12} sm={12} md={10}>
                                            <p>
                                              {item && item.assign_to}
                                            </p>
                                          </GridItem>
                                          <GridItem xs={12} sm={12} md={2}> */}
          {/* <EditAssginTOActionModal
                                              item={item}
                                              users={users}
                                              ActionPlanId={ActionPlanId}
                                            /> */}
          {/* </GridItem> */}
          {/* </GridContainer> */}
          {/* </TableCell> */}
          {/* </TableRow> */}
          {/* ))}
           */}
          {/* </TableBody> */}

          <TableHead>
            <TableRow>
              <TableCell>Actions</TableCell>
              <TableCell align="center">Assign To</TableCell>
              <TableCell align="center">ٍٍStatus</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Priority</TableCell>
              <TableCell align="center">Dates</TableCell>
              {/* <TableCell align="center">Approvel Stage</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {item?.recommendations?.map((row) => (
              <TableRow
                key={row?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ width: "270px" }}
                >
                  {row?.description}
                </TableCell>

                <TableCell align="center">
                  {" "}
                  <Tooltip title={row?.assign_to} placement="top">
                    {" "}
                    <Avatar
                      alt="avatar"
                      src={`http://141.94.175.167:8003${row?.avatar}`}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell
                  onClick={() => handleClickOpen(row.id)}
                  align="center"
                >
                  {StatusRender(row?.state)}
                </TableCell>
                <TableCell align="center">
                  {row?.actionTypeDetails?.code} {row?.actionTypeDetails?.name}
                </TableCell>
                <TableCell align="center">
                  {row?.actionTypeDetails?.category}
                </TableCell>
                <TableCell align="center">
                  {RaitingRender(row?.priority)}
                </TableCell>
                <TableCell style={{ width: "150px" }} align="center">
                  {row?.due_date}
                </TableCell>
                {/* <TableCell align="center"><DropDownActionButtonDetails state_id={row?.id} item={item}/></TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </GridItem>
    </GridContainer>
  );
}
