import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
// @material-ui/icons
// core components
/// test
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useHistory } from "react-router-dom";

import AddIcon from '@material-ui/icons/Add';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditIcon from '@material-ui/icons/Edit';
import { default as defaultAvatar, default as defaultImage } from "assets/img/default-avatar.png";
import styles4 from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import FormData from 'form-data';
import { useTranslation } from "react-i18next";
import { avatarPerson } from "reduxToolkit/features/person/PersonsSlice";
const { TextArea } = Input;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
}));
const useStyles4 = makeStyles(styles4);
export default function EditAvatarModal(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const classes4 = useStyles4();
    const { t } = useTranslation();


    const titleClasses = useTitleStyles();
    const classes = useStyles();


    const [avatar, setAvatar] = useState('')

    const [addRequestStatus, setAddRequestStatus] = useState('idle')


    const dispatch = useDispatch()
    const { Option } = Select;

    const history = useHistory();
    const onSaveClicked = async () => {
        let id = props.person_id;
        const uploadData = new FormData();
        uploadData.append('avatar', avatar);
        uploadData.append('id', props.person_id)

        console.log("{avatar", { avatar })
        console.log("uploadData", uploadData)

        try {
            setAddRequestStatus('pending')

            const resultAction = await dispatch(
                avatarPerson({ id, uploadData })
            )
            unwrapResult(resultAction)
            setAvatar('')
        } catch (err) {
            console.error('Failed to save the person: ', err)
        } finally {
            setAddRequestStatus('idle')
        }


    }





    const [file, setFile] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
        avatar ? defaultAvatar : defaultImage
    );
    let fileInput = React.createRef();
    const uploadData = new FormData();
    const handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result);
            setAvatar(file)
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        handleSubmit(e);
    };
    // eslint-disable-next-line
    const handleSubmit = e => {
        //  e.preventDefault();


        let avatar = avatar;


        console.log("{avatar, id }", { avatar })

        uploadData.append('avatar', avatar);
        console.log("uploadData", uploadData)

        // file is the file/image uploaded
        // in this function you can save the image (file) on form submit
        // you have to call it yourself
    };
    const handleClick = () => {
        fileInput.current.click();
        setAvatar(avatar);

    };
    console.log('avatar', avatar)
    const handleRemove = () => {
        setFile(null);
        setImagePreviewUrl(avatar ? defaultAvatar : defaultImage);
        fileInput.current.value = null;
    };
    return (
        <div>
            {/* <Tooltip
                id="tooltip-top"
                title="Edit Avatar"
                placement="top"
                classes={{
                    tooltip: classes4.tooltip,
                }}
            >
                <IconButton
                    aria-label="Edit Avatar"
                    className={classes4.tableActionButton}
                    onClick={(event) => {
                        handleClickOpen(event);
                    }}
                >
                    <Edit
                        className={classes4.tableActionButtonIcon + " " + classes4.edit}
                    />
                </IconButton>
            </Tooltip> */}

            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {t("Edit Avatar")}
                </DialogTitle>
                <DialogContent>
                    <form>
                        <GridContainer xs={12} sm={12} md={12} style={{ width: "700px" }}>


                            <GridItem xs={12} sm={12} md={10}>
                                <div className="fileinput text-center">
                                    <input type="file" onChange={handleImageChange} ref={fileInput} />
                                    <div className={"thumbnail"}>
                                        <img src={imagePreviewUrl} alt="..." />
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <div>
                                    <div>
                                        {file === null ? (
                                            <Button
                                                justIcon
                                                color="primary"
                                                round="true"
                                                padding="6px 6px"
                                                fontSize="6px"
                                                onClick={() => { handleClick(); handleSubmit() }}>
                                                {avatar ? <AddIcon className={classes.icons} /> : <AddIcon className={classes.icons} />}
                                            </Button>
                                        ) : (
                                            <span>
                                                <Button justIcon color="primary"
                                                    round="true"
                                                    //  padding= "6px 6px"
                                                    //   fontSize= "6px" 
                                                    onClick={() => handleClick()}>
                                                    <EditIcon className={classes.icons} />
                                                </Button>


                                                {avatar ? <br /> : null}
                                                <Button justIcon color="primary"
                                                    round="true"
                                                    //   padding= "6px 6px"
                                                    //    fontSize= "6px" 
                                                    onClick={() => handleRemove()}>
                                                    <DeleteRoundedIcon className={classes.icons} />
                                                </Button>
                                            </span>
                                        )}
                                    </div>
                                </div>

                            </GridItem>
                        </GridContainer>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        props.handleClose();
                    }} color="info">
                        {t("Cancel")}
                    </Button>
                    <Button
                        onClick={() => {
                            onSaveClicked()
                            // onUpdateClicked();
                            props.handleClose();
                        }}
                        color="primary"
                    >
                        {t("Save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
