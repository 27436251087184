import React, {  useEffect ,useState } from "react";
import { useDispatch , useSelector} from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { addNewGraduateAttributeSpec } from "../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";
import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramSpecSlice";
import { useTranslation } from "react-i18next";
import { Form, Input, message, Select, notification } from "antd";
import { fetchGraduateAttributeConfigs } from "../../reduxToolkit/features/graduateAttributeConfig/GraduateAttributeConfigSlice";
import ErrorModal from "./ErrorModal.jsx";
const { Option } = Select;

export default function CreateGAModal({ programSpec_id, disabledStatus,ping,setPing }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  console.log("program id in add plo modal", programSpec_id);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [name_id, setName] = useState("");
  const [code, setCode] = useState("");
  const [target, setTarget] = useState("");

  const [description, setDescription] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();

  const graduateAttributeConfigsStatus = useSelector(state => state.graduateAttributeConfig.graduateAttributeConfigsStatus)

  useEffect(() => {
    if (graduateAttributeConfigsStatus === 'idle') {
      dispatch(fetchGraduateAttributeConfigs())
    }
  }, [graduateAttributeConfigsStatus, dispatch])

  const graduateAttributeConfigs = useSelector(state => state.graduateAttributeConfig.graduateAttributeConfigs)
  console.log(graduateAttributeConfigs)

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async () => {
    setAddRequestStatus("pending");
    const resultAction = await dispatch(
      addNewGraduateAttributeSpec({
        name_id,
        code,
        target,
        description,
        programSpec_id,
      })
    );
    setName("");
    setCode("");
    setTarget("");
    setDescription("");
    if (addNewGraduateAttributeSpec.fulfilled.match(resultAction)) {
      setPing(!ping)
      openNotificationWithIcon("success", `Added`);
      handleClose();
      //dispatch(fetchProgramById(programSpec_id));
  
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
       
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
       
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
    //handleClose();
    
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChangeName(value) {
    setName(value);
    
  }

  function onBlur() {}

  function onFocus() {}

  function onSearch(val) {}
  return (
    <div>
      {disabledStatus ? (
        <p></p>
      ) : (
        <Button color="primary" onClick={ handleClickOpen}>
          {t("Add")}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Add a Graduate Attribute")}
        </DialogTitle>

        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError
          style={{ padding: "0px 10px" }}
        >
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={8} sm={8} md={12}>
              <DialogContentText>
                {t(
                  "Graduate attributes are the high level qualities, skills and understandings that a student should gain as a result"
                )}
              </DialogContentText>
            </GridItem>
            <GridItem xs={6} sm={6} md={12}>
              <Form.Item
                label={t("name")}
                name="name_id"
                rules={[
                  {
                    required: true,
                    message: "Please input the GA's name!",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%", zIndex: 5 }}
                  dropdownStyle={{ zIndex: 2000 }}
                  optionFilterProp="children"
                  name="name_id"
                  value={name_id}
                  onChange={onChangeName}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >

                  {graduateAttributeConfigs && graduateAttributeConfigs.map((item) => 
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                  )}
                 

                </Select>
              </Form.Item>
            </GridItem>

            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={4} sm={4} md={6}>
                <Form.Item
                  label={t("Code")}
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Please input the  GA's code!",
                      warningOnly: true,
                    },
                  ]}
                > 
                  <Input
                    id="code"
                    value={code}
                    onChange={(event) => {
                      setCode(event.target.value);
                    }}
                  />
                </Form.Item>
              </GridItem>

              <GridItem xs={4} sm={4} md={6}>
                <Form.Item
                  label={t("Target")}
                  name="target"
                  rules={[
                    {
                      required: true,
                      message: "Please input the  GA's target!",
                      warningOnly: true,
                    },
                  ]}
                >
                  <Input
                    id="target"
                    value={target}
                    onChange={(event) => {
                      setTarget(event.target.value);
                    }}
                  />
                </Form.Item>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("description")}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input the GA's description!",
                    },
                  ]}
                >
                  <Input.TextArea
                    id="description"
                    value={description}
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                    rows={4}
                  />
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridContainer>

          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={10}>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button color="primary" onClick={handleClose}>
                      {t("Cancel")}
                    </Button>
                  </Form.Item>
                </GridItem>
                <GridItem>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="submit" color="primary">
                      {t("Save")}
                    </Button>
                  </Form.Item>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Form>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
