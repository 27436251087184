import { Tabs } from "antd";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { greyColor } from "assets/jss/material-dashboard-pro-react";
import { Link } from "react-router-dom";
import React from "react";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import OverallCommentsOnFacilities from "./OverallCommentsOnFacilities";
import OfficesClassroomsLaboratories from "./OfficesClassroomsLaboratories";
import ComputingResources from "./ComputingResources";
import Guidance from "./Guidance";
import MaintenanceAndUpgradingOfFacilities from "./MaintenanceAndUpgradingOfFacilities";
import LibraryServices from "./LibraryServices";
import Criterion1Analysis from "../criterion1/Analysis";

const onChange = (key) => {
  console.log(key);
};

const Criterion7 = ({ data }) => (
  <>
    {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      items={[
        {
          label: `Offices, Classrooms and Laboratories`,
          key: "1",
          children: (
            <OfficesClassroomsLaboratories
              data={data.OfficesClassroomsLaboratories}
            />
          ),
        },
        {
          label: `Computing Resources`,
          key: "2",
          children: <ComputingResources data={data.ComputingResources} />,
        },
        {
          label: `Guidance`,
          key: "3",
          children: <Guidance data={data.Guidance} />,
        },
        {
          label: `Maintenance and Upgrading of Facilities`,
          key: "4",
          children: (
            <MaintenanceAndUpgradingOfFacilities
              data={data.MaintenanceAndUpgradingOfFacilities}
            />
          ),
        },
        {
          label: `Library Services`,
          key: "5",
          children: <LibraryServices data={data.LibraryServices} />,
        },
        {
          label: `Overall Comments on Facilities`,
          key: "6",
          children: (
            <OverallCommentsOnFacilities
              data={data.OverallCommentsOnFacilities}
            />
          ),
        },
        {
          label: `Analysis`,
          key: "7",
          children: <Criterion1Analysis data={data.Analysis} />,
        },
      ]}
    />
  </>
);
export default Criterion7;
