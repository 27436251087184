import { unwrapResult } from '@reduxjs/toolkit'

import { Link, useParams, useLocation } from 'react-router-dom'

// antdesign components
import {Breadcrumb, InputNumber, Checkbox, DatePicker, Form, Input, message, notification, Select,} from 'antd'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
// style for this view
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import { infoColor } from 'assets/jss/material-dashboard-pro-react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {fetchResearchAndInnovationActivityById, updateResearchAndInnovationActivity,} from 'reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice'
import { fetchStaff } from '../../../../../reduxToolkit/features/user/UserSlice'
import TextArea from 'antd/lib/input/TextArea'
import { fetchColleges } from '../../../../../reduxToolkit/features/college/CollegesSlice'
import { fetchDepartments } from '../../../../../reduxToolkit/features/department/DepartmentsSlice'
import { fetchPublisherConfigs } from '../../../../../reduxToolkit/features/Publisher/publisherSlice'
import { fetchResearchDirectionConfigs } from '../../../../../reduxToolkit/features/ResearchDirection/researchDirectionSlice'
import ErrorModal from '../../../../Components/ErrorModal'
import { fetchCategories } from '../../../../../reduxToolkit/features/category/CategorySlice'
import {addRoleConfig, fetchRoleConfigs,} from '../../../../../reduxToolkit/features/RolesConfig/RolesConfigSlice'
import { fetchTypesConfigs } from '../../../../../reduxToolkit/features/TypesConfig/TypesConfigSlice'
import { fetchStudents } from '../../../../../reduxToolkit/features/user/UserSlice'
import {fetchCurrentSemester,} from 'reduxToolkit/features/semester/SemestersSlice'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import moment from 'moment';

export default function EditResearchAndInnovationActivity({ match }) {

  const { t } = useTranslation()
  let history = useHistory()
  let { id } = useParams()
  const locationLink = useLocation();

  const [form] = Form.useForm()
  const userId = localStorage.getItem('userId')
  const dateFormat = 'YYYY/MM/DD'

  const [researchType, setResearchType] = React.useState('')
  const [levelType, setLevelType] = useState('')
  const [conference, setConference] = useState('')
  const [editor, setEditor] = useState('')
  const [institution, setInstitution] = useState('')
  const [authors_ids, setAuthorsIds] = useState([])
  let [type, setType] = useState('')
  const [journal, setJournal] = useState('')
  const [name, setName] = useState('')
  const [external_authors, setExternalAuthors] = useState('')
  const [authorityLevelConfig_id, SetAuthorityLevelConfigId] = useState('',)
  const [college_id, setCollegeId] = useState()
  const [types_research_activities, setTypesResearchActivities,] = useState('')
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const [to_date, setToDate] = useState('2022-05-12')
  const [from_date, setFromDate] = useState('2022-05-27')
  const [date, setDatee] = useState('2022-03-04')
  const [indexed, setIndexedValue] = useState(false)
  const [peer_reviewed, setReviewedValue] = useState(false)
  const [roleConf_ids, setKeyWordsSpec_ids] = useState([])
  const [category_research_activities, setCategoryResearchActivities,] = useState('')
  const [publisherConfig_id, setPublisherConfigId] = useState('')
  const [researchDirection_id, setResearchDirectionId] = useState('')
  const [additional_information, setAdditionalInformation] = useState('')
  const [budget, setBudget] = useState('')
  const [title_of_proposal, setTitleOfProposal] = useState('')
  const [proposed_project_organization, setProposedProjectOrganization,] = useState('')
  const [investigator_name_external, setInvestigatorNameExternal,] = useState('')
  const [investigator_name_internal_id, setInvestigatorNameInternalId,] = useState('')
  const [proposed_project_leader_id, setProposedProjectLeaderId,] = useState('')
  const [location, setLocation] = useState('')
  const [conference_title, setConferenceTitle] = useState('')
  const [conference_paper_title, setConferencePaperTitle] = useState('')
  const [conference_presenter, setConferencePresenter] = useState('')
  const [thesis_title, setThesisTitle] = useState('')
  const [organization, setOrganization] = useState('')
  const [student_ids, setStudentIds] = useState([])
  const [project_leader_internal_id, setProjectLeaderInternalId,] = useState('')
  const [project_title, setProjectTitle] = useState('')
  const [workshop_location, setWorkshopLocation] = useState('')
  const [seminar_paper_title, setSeminarPaperTitle] = useState('')
  const [workshop_seminar_title, setWorkshopSeminarTitle] = useState('')
  const [seminar_presenter_external, setSeminarPresenterExternal,] = useState('')
  const [meeting_head_internal_id, setMeetingHeadInternalId] = useState('')
  const [meeting_head_external, setMeetingHeadExternal] = useState('')
  const [title_of_meeting, setTitleOfMeeting] = useState('')
  const [seminar_presenter_internal_id, setSeminarPresenterInternalId,] = useState('')
  const [research_review_leader_internal_id, setResearchReviewLeaderInternalId,] = useState('')
  const [research_review_leader_external, setResearchReviewLeaderExternal,] = useState('')
  const [research_review_project_organization, setResearchReviewProjectOrganization,] = useState('')
  const [title_of_review_letter, setTitleOfReviewLetter] = useState('')
  const [title_of_article, setTitleOfArticle] = useState('')
  const [magazine_name, setMagazineName] = useState('')
  const [issue_no, setIssueNo] = useState('')
  const [category_name, setCategoryName] = useState('')
  const [link, setLink] = useState('')
  const [typesConfig_ids, setTypesConfigIds] = useState([])
  const [ug_pg_ids, setUpPgIds] = useState([])
  const [name_members_ids, setNameMembersIds] = useState([])
  const [title, setTitle] = useState('')
  const [project_leader_external, setProjectLeaderExternal] = useState('')

  const dispatch = useDispatch()

  const activity = useSelector((state) => state.researchAndInnovationActivities.researchAndInnovationActivity,)
  const activityStatus = useSelector((state) => state.researchAndInnovationActivities.researchAndInnovationActivityStatus,)
  const colleges = useSelector((state) => state.college.colleges)
  const departments = useSelector((state) => state.department.departments)
  const courses = useSelector((state) => state.course.courses)
  const researchDirectionItems = useSelector((state) => state.ResearchDirection.researchDirectionConfigs,)
  const publisherConfigsItems = useSelector((state) => state.PublisherConfig.PublisherConfigs,)
  const typesConfigsItems = useSelector((state) => state.typesConfig.TypesConfigs,)
  const rolesConfigsItems = useSelector((state) => state.rolesConfig.RoleConfigs,)
  const categoryConfItems = useSelector((state) => state.category.categories)
  const students = useSelector((state) => state.user.students)
  const person = useSelector((state) => state.user.staff)
  const currentSemester = useSelector((state) => state.semester.currentSemester)
  const programs = useSelector((state) => state.program.programs)
  const authorsList = useSelector((state) => state.authorityConfig.AuthorityConfigs,)
  const configAcoordingtoType = useSelector((state) => state.authorityConfig.AuthorityConfigsByType)
  const persons = useSelector((state) => state.user.staffList)
  const academicYears = useSelector((state) => state.academicYear.academicYears)

  useEffect(() => {
    dispatch(fetchResearchAndInnovationActivityById(id))
        .unwrap()
        .then((activity) => {
          setType(activity.type && activity.type)
          setResearchType(activity.type && activity.type)
          if (activity?.type === 'Review-Editing') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              authors_ids: activity?.authors_ids,
              researchDirection_id: activity?.researchDirection,
              title: activity?.title,
              category_research_activities: activity?.category_research_activities,
              category_name: activity?.category_name,
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Research Proposals') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              roleConf_ids: activity?.roleConf_ids,
              proposed_project_leader_id: activity?.proposed_project_leader,
              investigator_name_internal_id: activity?.investigator_name_internal,
              investigator_name_external: activity?.investigator_name_external,
              proposed_project_organization: activity?.proposed_project_organization,
              title_of_proposal: activity?.title_of_proposal,
              budget: activity?.budget,
              researchDirection_id: activity?.researchDirection,
              from_date: moment(activity?.from_date, dateFormat),
              to_date: moment(activity?.to_date, dateFormat),
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Conference Presentation') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              authors_ids: activity?.authors_ids,
              conference_paper_title: activity?.conference_paper_title,
              conference_presenter: activity?.conference_presenter,
              conference_title: activity?.conference_title,
              indexed: activity?.indexed,
              location: activity?.location,
              peer_reviewed: activity?.peer_reviewed,
              researchDirection_id: activity?.researchDirection,
              title: activity?.title,
            })
          } else if (activity?.type === 'Memberships') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              roleConf_ids: activity?.roleConf_ids,
              organization: activity?.organization,
              researchDirection_id: activity?.researchDirection,
              from_date: moment(activity?.from_date, dateFormat),
              to_date: moment(activity?.to_date, dateFormat),
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Thesis Supervesion') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              thesis_title: activity?.thesis_title,
              ug_pg_ids: activity?.ug_pg_ids,
              student_ids: activity?.student_ids,
              researchDirection_id: activity?.researchDirection,
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Research Grants') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              roleConf_ids: activity?.roleConf_ids,
              project_leader_internal_id: activity?.project_leader_internal,
              project_leader_external: activity?.project_leader_external,
              project_title: activity?.project_title,
              budget: activity?.budget,
              researchDirection_id: activity?.researchDirection,
              from_date: moment(activity?.from_date, dateFormat),
              to_date: moment(activity?.to_date, dateFormat),
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Workshop-Seminar') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              seminar_presenter_internal_id: activity?.seminar_presenter_internal,
              seminar_presenter_external: activity?.seminar_presenter_external,
              workshop_seminar_title: activity?.workshop_seminar_title,
              seminar_paper_title: activity?.seminar_paper_title,
              workshop_location: activity?.workshop_location,
              researchDirection_id: activity?.researchDirection,
              from_date: moment(activity?.from_date, dateFormat),
              to_date: moment(activity?.to_date, dateFormat),
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Acad.-Professional meetings') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              roleConf_ids: activity?.roleConf_ids,
              meeting_head_internal_id: activity?.meeting_head_internal,
              meeting_head_external: activity?.meeting_head_external,
              title_of_meeting: activity?.title_of_meeting,
              organization: activity?.organization,
              location: activity?.location,
              researchDirection_id: activity?.researchDirection,
              date: moment(activity?.date, dateFormat),
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Review Letter') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              roleConf_ids: activity?.roleConf_ids,
              research_review_leader_internal_id: activity?.research_review_leader_internal,
              research_review_leader_external: activity?.research_review_leader_external,
              research_review_project_organization: activity?.research_review_project_organization,
              title_of_review_letter: activity?.title_of_review_letter,
              researchDirection_id: activity?.researchDirection,
              from_date: moment(activity?.from_date, dateFormat),
              to_date: moment(activity?.to_date, dateFormat),
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Professional Magazines') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              title_of_article: activity?.title_of_article,
              magazine_name: activity?.magazine_name,
              issue_no: activity?.issue_no,
              researchDirection_id: activity?.researchDirection,
              date: moment(activity?.date, dateFormat),
              additional_information: activity?.additional_information
            })
          } else if (activity?.type === 'Journal Editorials') {
            form.setFieldsValue({
              academicYear_id: currentSemester?.academicYear_id,
              college_id: activity?.college,
              department_id: activity?.department,
              title: activity?.title,
              authors_ids: activity?.authors_ids,
              roleConf_ids: activity?.roleConf_ids,
              journal: activity?.journal,
              typesConfig_ids: activity?.typesConfig_ids,
              researchDirection_id: activity?.researchDirection,
              from_date: moment(activity?.from_date, dateFormat),
              to_date: moment(activity?.to_date, dateFormat),
              link: activity?.link
            })
          }
        })
    dispatch(fetchCurrentSemester(userId))
    dispatch(fetchDepartments())
    dispatch(fetchColleges())
    dispatch(fetchResearchDirectionConfigs())
    dispatch(fetchPublisherConfigs())
    dispatch(fetchStaff())
    dispatch(fetchStudents())
    dispatch(fetchRoleConfigs())
    dispatch(fetchTypesConfigs())
    dispatch(fetchCategories())
  }, [])

  useEffect(() => {
    dispatch(fetchRoleConfigs())
  }, [])

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Submit failed!')
  }

  const handleChange = (value) => {
    if (value.length > 0) {
      let chosenKWObjects =
        rolesConfigsItems &&
        rolesConfigsItems.filter((kw) => value.includes(kw.role))
      let ids = chosenKWObjects.map((el) => {
        return el.id
      })
      let mapped =
        rolesConfigsItems &&
        rolesConfigsItems.map((el) => {
          return el.role
        })
      const role = [...value].pop()
      if (mapped.includes(role)) {
        setKeyWordsSpec_ids(ids)
      } else {
        dispatch(addRoleConfig({ role }))
          .then(unwrapResult)
          .then((newKeyWord) => {
            setKeyWordsSpec_ids(ids.concat(newKeyWord.id))
          })
      }
    } else {
      setKeyWordsSpec_ids([])
    }
  }

  function onChangeCheckboxIndex(e) {
    setIndexedValue(e.target.checked)
  }

  function onChangeCheckboxReviewed(e) {
    setReviewedValue(e.target.checked)
  }

  function onStartDateChange(value, dateString) {
    setFromDate(dateString)
  }

  function onEndDateChange(value, dateString) {
    setToDate(dateString)
  }

  function onDateChange(value, dateString) {
    setDatee(dateString)
  }

  let semester_id = currentSemester && currentSemester.semester_id

  const onFinish = async (values) => {
    let type = activity.type
    const toAddActivity = {
      ...values,
      researchDirection: values.researchDirection_id ? [values.researchDirection_id] : '',
      department: [values.department_id],
      college: [values.college_id],
      semester: semester_id,
      academicYear: [currentSemester?.academicYear_id],
      type,
    }
    const data = {
      id: id,
      toAddActivity: toAddActivity,
    }
    const resultAction = await dispatch(updateResearchAndInnovationActivity(data))
    if (updateResearchAndInnovationActivity.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `Added successfully`)
      history.push(`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`)
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  function onHandleCommunityType(communityString) {
    setResearchType(communityString)
  }

  const { Option } = Select

  const { RangePicker } = DatePicker;

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment(from_date);
  };


  let content

  if (activityStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (activityStatus === 'succeeded') {
    content = (
      <div>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError
        >
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={10}>
              <h3>
                <small style={{ color: `${infoColor[0]}` }}>
                  Edit the research and innovation activity
                </small>
              </h3>
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item
                        label={t('Academic Year')}
                        name="academicYear_id"
                      >
                        <Select
                          onChange={(e) => console.log(e)}
                          showSearch
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {academicYears &&
                            academicYears.map((academicYear) => (
                              <Option
                                key={academicYear.id}
                                value={academicYear.id}
                              >
                                {academicYear.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t('Colleges')} name="college_id">
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {colleges &&
                            colleges.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t('Departments')} name="department_id">
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {departments &&
                            departments.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                    {researchType == 'Review-Editing' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Name of the member')}
                            name="authors_ids"
                          >
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Category')}
                            name="category_research_activities"
                          >
                            <Select
                              style={{ width: '100%' }}
                              size={200}
                              showArrow
                            >
                              <Option value="Journal">Journal</Option>
                              <Option value="Conference">Conference</Option>
                              <Option value="Book">Book</Option>
                              <Option value="Book Chapter">Book Chapter</Option>
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Name of the Journal/Conf/Book')}
                            name="category_name"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Publisher')}
                            name="publisherConfig"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              defaultValue={
                                activity.publisherConfig_details &&
                                activity.publisherConfig_details[0] &&
                                activity.publisherConfig_details[0].name &&
                                activity.publisherConfig_details[0].name
                              }
                            >
                              {publisherConfigsItems &&
                                publisherConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              defaultValue={
                                activity.researchDirection_details &&
                                activity.researchDirection_details[0] &&
                                activity.researchDirection_details[0].name &&
                                activity.researchDirection_details[0].name
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('From date')} name="from_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                              defaultValue={moment(
                                activity.from_date,
                                dateFormat,
                              )}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('To date')} name="to_date">
                            <DatePicker
                              onChange={onEndDateChange}
                              disabledDate={disabledDate}
                              style={{ width: '100%' }}
                              defaultValue={moment(
                                activity.to_date,
                                dateFormat,
                              )}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType == 'Research Proposals' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Name of the member')}
                            name="authors_ids"
                          >
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Role')} name="roleConf_ids">
                            <Select
                              mode="tags"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {rolesConfigsItems &&
                                rolesConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.role}>
                                    {`${person.role}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Proposed Project Leader')}
                            name="proposed_project_leader_id"
                          >
                            <Select
                                showSearch={false}
                                style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Investigator Name (Internal)')}
                            name="investigator_name_internal_id"
                          >
                            <Select
                                showSearch={false}
                                style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Investigator Name (External)')}
                            name="investigator_name_external"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Proposed Projet Organization')}
                            name="proposed_project_organization"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Title of proposal')}
                            name="title_of_proposal"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Budget')} name="budget">
                            <InputNumber style={{ width: '250px' }}/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('From date')} name="from_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('To date')} name="to_date">
                            <DatePicker
                              onChange={onEndDateChange}
                              disabledDate={disabledDate}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Conference Presentation' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Authors')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Conference Presenter')}
                            name="conference_presenter"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Conference Paper Title')}
                            name="conference_paper_title"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Conference Title')}
                            name="conference_title"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Location')} name="location">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item name="indexed">
                            <Checkbox
                              onChange={onChangeCheckboxIndex}
                              checked={indexed}
                            >
                              {`Indexed`}
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item name="peer_reviewed">
                            <Checkbox
                              onChange={onChangeCheckboxReviewed}
                              checked={peer_reviewed}
                            >
                              {`Peer Reviewed`}
                            </Checkbox>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Memberships' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Name')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Role')} name="roleConf_ids">
                            <Select
                              mode="tags"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {rolesConfigsItems &&
                                rolesConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.role}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Organization')}
                            name="organization"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('From date')} name="from_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('To date')} name="to_date">
                            <DatePicker
                              onChange={onEndDateChange}
                              disabledDate={disabledDate}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Thesis Supervesion' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Name')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Thesis Title')}
                            name="thesis_title"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('UG/PG')} name="ug_pg_ids">
                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {categoryConfItems &&
                                categoryConfItems.map((program) => (
                                  <Option key={program.id} value={program.id}>
                                    {program.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Students')} name="student_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {students &&
                                students.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.student_details?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person.student_details.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.student_details &&
                                      person.student_details.first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.student_details &&
                                      person.student_details.last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Research Grants' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Name')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Role')} name="roleConf_ids">
                            <Select
                              mode="tags"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {rolesConfigsItems &&
                                rolesConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.role}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Project Leader (internal)')}
                            name="project_leader_internal_id"
                          >
                            <Select
                                showSearch={false}
                                style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Project Leader (external)')}
                            name="project_leader_external"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Project title')}
                            name="project_title"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('budget')} name="budget">
                            <InputNumber
                              style={{ width: '250px' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('From date')} name="from_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('To date')} name="to_date">
                            <DatePicker
                              onChange={onEndDateChange}
                              disabledDate={disabledDate}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Workshop-Seminar' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Name')}
                            name="authors_ids"
                          >
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Seminar Presenter (internal)')}
                            name="seminar_presenter_internal_id"
                          >
                            <Select
                                showSearch={false}
                                style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Seminar Presenter (external)')}
                            name="seminar_presenter_external"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Workshop/Seminar title')}
                            name="workshop_seminar_title"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Seminar Paper Title')}
                            name="seminar_paper_title"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Workshop Location')}
                            name="workshop_location"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('From date')} name="from_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>

                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('To date')} name="to_date">
                            <DatePicker
                              onChange={onEndDateChange}
                              disabledDate={disabledDate}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Acad.-Professional meetings' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Name')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Role')} name="roleConf_ids">
                            <Select
                              mode="tags"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {rolesConfigsItems &&
                                rolesConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.role}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Meeting head (internal)')}
                            name="meeting_head_internal_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Meeting head (external)')}
                            name="meeting_head_external"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Title of meeting')}
                            name="title_of_meeting"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Organization')}
                            name="organization"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Location')} name="location">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('Date')} name="date">
                            <DatePicker
                              onChange={onDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Review Letter' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Name')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Role')} name="roleConf_ids">
                            <Select
                              mode="tags"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {rolesConfigsItems &&
                                rolesConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.role}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Research review leader (internal)')}
                            name="research_review_leader_internal_id"
                          >
                            <Select
                                showSearch={false}
                                style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Research review leader (external)')}
                            name="research_review_leader_external"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Research review project organization')}
                            name="research_review_project_organization"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Title of review letter')}
                            name="title_of_review_letter"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('From date')} name="from_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('To date')} name="to_date">
                            <DatePicker
                              onChange={onEndDateChange}
                              disabledDate={disabledDate}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Professional Magazines' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Name')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Title of article')}
                            name="title_of_article"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Magazine name')}
                            name="magazine_name"
                          >
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('issue No.')} name="issue_no">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('Date')} name="date">
                            <DatePicker
                              onChange={onDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Additional informations')}
                            name="additional_information"
                          >
                            <TextArea/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                    {researchType === 'Journal Editorials' ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Title')} name="title">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Name')} name="authors_ids">
                            <Select
                              mode="multiple"
                              showSearch={false}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {persons &&
                                persons.map((person) => (
                                  <Option value={person && person.id}>
                                    {!person?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {person?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].first_name
                                      }`}{' '}
                                    {`${''}`}{' '}
                                    {`${person &&
                                      person.person_details &&
                                      person.person_details[0].last_name
                                      }`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Role')} name="roleConf_ids">
                            <Select
                              mode="tags"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleChange}
                            >
                              {rolesConfigsItems &&
                                rolesConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.role}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Journal name')} name="journal">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('Type')} name="typesConfig_ids">
                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {typesConfigsItems &&
                                typesConfigsItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.type}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item
                            label={t('Local Research Directions')}
                            name="researchDirection_id"
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {researchDirectionItems &&
                                researchDirectionItems.map((person) => (
                                  <Option key={person.id} value={person.id}>
                                    {`${person.name}`}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('From date')} name="from_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item label={t('To date')} name="to_date">
                            <DatePicker
                              onChange={onStartDateChange}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Form.Item label={t('ORCID Link')} name="link">
                            <Input/>
                          </Form.Item>
                        </GridItem>
                      </GridItem>
                    ) : (
                      <p></p>
                    )}
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={10}>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="submit" color="primary">
                      {t('Save')}
                    </Button>
                  </Form.Item>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Form>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </div>
    )
  } else if (activityStatus === 'failed') {
    content = <div>An error has accured</div>
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t('Faculty')}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t('Teaching Staff')}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`}
            >
              {t('Research and innovation')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>
      {content}
    </div>
  )
}
