import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  authorityLevelsConfig: [],
  authorityLevelsConfigStatus: 'idle',
  authorityLevelConfig: null,
  authorityLevelConfigStatus: 'idle',
  error: null,
}

export const fetchAuthorityLevelsConfig = createAsyncThunk(
  'authorityLevelConfig/fetchAuthorityLevelConfig',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/authorityLevelConfig/`, {
        headers: { Authorization: `token ${token}` },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchAuthorityLevelConfigById = createAsyncThunk(
  'authorityLevel/fetchAuthorityLevelConfigById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(
        BaseUrl + `/authorityLevelConfig/${id}/`,
        {
          headers: { Authorization: `token ${token}` },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewAuthorityLevelConfig = createAsyncThunk(
  'authorityLevelConfig/addNewAuthorityLevelConfig',
  async (authority, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.post(
        BaseUrl + `/authorityLevelConfig/create/`,
        authority,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteAuthorityLevelConfig = createAsyncThunk(
  'authorityLevelConfig/deleteAuthorityLevelConfig',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.delete(
        BaseUrl + `/authorityLevelConfig/${id}/delete/`,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateAuthorityLevelConfig = createAsyncThunk('authorityLevelConfig/updateAuthorityLevelConfig',async (authority, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.put(BaseUrl + `/authorityLevelConfig/${authority.id}/edit/`,authority,{headers: {Authorization: `token ${token}`}})
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const AuthorityLevelsConfigSlice = createSlice({
  name: 'authorityLevelConfig',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAuthorityLevelsConfig.pending]: (state, action) => {
      state.authorityLevelsConfigStatus = 'loading'
    },
    [fetchAuthorityLevelsConfig.fulfilled]: (state, action) => {
      state.authorityLevelsConfigStatus = 'succeeded'
      state.authorityLevelsConfig = action.payload
    },
    [fetchAuthorityLevelsConfig.rejected]: (state, action) => {
      state.authorityLevelsConfigStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },

    [fetchAuthorityLevelConfigById.pending]: (state, action) => {
      state.authorityLevelConfigStatus = 'loading'
    },
    [fetchAuthorityLevelConfigById.fulfilled]: (state, action) => {
      state.authorityLevelConfigStatus = 'succeeded'
      state.authorityLevelConfig = action.payload
    },
    [fetchAuthorityLevelConfigById.rejected]: (state, action) => {
      state.authorityLevelConfigStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },
  },
})

export default AuthorityLevelsConfigSlice.reducer
