import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import ListItemText from "@material-ui/core/ListItemText";

import {
  updateGraduateAttributeSpec,
  fetchGraduateAttributeSpecById,
} from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice.js";
import {
  deleteGraduateAttributeSpec,
  fetchGraduateAttributesSpec,
} from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";

import { useTranslation } from "react-i18next";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { fetchGraduateAttributeConfigs} from '../../../../reduxToolkit/features/graduateAttributeConfig/GraduateAttributeConfigSlice';
import { Form, Input, message, Select, notification } from "antd";

export default function GAEditModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [target, setTarget] = useState("");

  const [description, setDescription] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const graduateAttributeConfigsStatus = useSelector(state => state.graduateAttributeConfig.graduateAttributeConfigsStatus)

  useEffect(() => {
    if (graduateAttributeConfigsStatus === 'idle') {
      dispatch(fetchGraduateAttributeConfigs())
    }
  }, [graduateAttributeConfigsStatus, dispatch])

  const graduateAttributeConfigs = useSelector(state => state.graduateAttributeConfig.graduateAttributeConfigs)
 
  console.log(graduateAttributeConfigs)

  useEffect(() => {
    dispatch(fetchGraduateAttributeSpecById(props.GA.id))
      .unwrap()
      .then((GA) => {
        form.setFieldsValue({
          programSpec_id: GA.programSpec_id,
          name: GA.name_id,
          code: GA.code,
          target: GA.target,
          description: GA.description,
        });
      });
  }, [dispatch]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const { Option } = Select;

  const graduateAttributeSpec_id = props.GA.id;
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
    // message.error('Submit failed!');
  };
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      updateGraduateAttributeSpec({
        ...values,
        id: props.GA.id,
        programSpec: props.programId,
      })
    );

    if (updateGraduateAttributeSpec.fulfilled.match(resultAction)) {
      console.log("success", `Edited successfully`);

      openNotificationWithIcon("success", `updated successfully`);
      dispatch(fetchGraduateAttributesSpec(props.programId));
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  function onChangeName (value){
    setName(value);
    const selectedGraduateAttribute = graduateAttributeConfigs?.find(
      (attribute) => attribute?.id === value
    );
    if (selectedGraduateAttribute) {
      setCode(selectedGraduateAttribute?.code);
    } else {
      setCode("");
    }
  }
  return (
    <div>
      <MenuItem
        onClick={() => {
          handleClickOpen();
          props.handleDropDownButtonClose();
        }}
      >
        <ListItemText primary="Edit" />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="true"
      >
        <DialogTitle id="form-dialog-title">
          {t("Edit Graduate Attribute")}
        </DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            style={{ padding: "0px 10px" }}
          >
            <GridContainer direction="row" justify="center" alignItems="center">
              <GridItem xs={6} sm={6} md={12}>
                <Form.Item
                  label={t("name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the GA's name!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%", zIndex: 5 }}
                    dropdownStyle={{ zIndex: 2000 }}
                    optionFilterProp="children"
                    name="name"
                    onChange={onChangeName}
                    value={name}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    
                  {graduateAttributeConfigs && graduateAttributeConfigs.map((item) => 
                  <Option key={item.id} value={item.id}>
                    {item.code }: { item.name}
                  </Option>
                  )}
                 
                  </Select>
                </Form.Item>
              </GridItem>

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={4} sm={4} md={6}>
                  <Form.Item
                    label={t("Code")}
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Please input the  GA's code!",
                        warningOnly: true,
                      },
                    ]}
                  >
                    <Input
                      id="code"
                      value={code}
                      onChange={(event) => {
                        setCode(event.target.value);
                      }}
                    />
                  </Form.Item>
                </GridItem>

                <GridItem xs={4} sm={4} md={6}>
                  <Form.Item
                    label={t("Target")}
                    name="target"
                    rules={[
                      {
                        required: true,
                        message: "Please input the  GA's target!",
                        warningOnly: true,
                      },
                    ]}
                  >
                    <Input
                      id="target"
                      value={target}
                      onChange={(event) => {
                        setTarget(event.target.value);
                      }}
                    />
                  </Form.Item>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <Form.Item
                    label={t("description")}
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please input the GA's description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      id="description"
                      value={description}
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                      rows={4}
                    />
                  </Form.Item>
                </GridItem>
              </GridContainer>
            </GridContainer>

            <GridContainer direction="row" justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={10}>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button color="primary" onClick={handleClose}>
                        {t("Cancel")}
                      </Button>
                    </Form.Item>
                  </GridItem>
                  <GridItem>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button type="submit" color="primary">
                        {t("Save")}
                      </Button>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
