import { Tabs } from "antd";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { greyColor } from "assets/jss/material-dashboard-pro-react";
import { Link } from "react-router-dom";
import React from "react";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import Institution from "./Institution";
import TypeOfControl from "./TypeOfControl";
import EducationalUnit from "./EducationalUnit";
import AcademicSupportUnits from "./AcademicSupportUnits";
import NonAcademicSupportUnits from "./NonAcademicSupportUnits";
import CreditUnit from "./CreditUnit";
import Tables from "./Tables";

const onChange = (key) => {
  console.log(key);
};

const Appendices = ({data}) => (
  <>
    {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      items={[
        {
          label: `The Institution`,
          key: "1",
          children: <Institution data={data.Institution}/>,
        },
        {
          label: `Type of Control`,
          key: "2",
          children: <TypeOfControl data={data.TypeOfControl}/>,
        },
        {
          label: `Educational Unit`,
          key: "3",
          children: <EducationalUnit data={data.EducationalUnit}/>,
        },
        {
          label: `Academic Support Units`,
          key: "4",
          children: <AcademicSupportUnits data={data.AcademicSupportUnits}/>,
        },
        {
          label: `Non-academic Support Units`,
          key: "5",
          children: <NonAcademicSupportUnits data={data.NonAcademicSupportUnits}/>,
        },
        {
          label: `Credit Unit`,
          key: "6",
          children: <CreditUnit data={data.CreditUnit}/>,
        },
        {
          label: `Tables`,
          key: "7",
          children: <Tables data={data.Tables}/>,
        },
      ]}
    />
  </>
);
export default Appendices;
