import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
  } from "@mui/x-data-grid";
  import { Breadcrumb, notification, Table } from "antd";
  import { Link } from "react-router-dom";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import Button from "components/CustomButtons/Button.js";
  import CustomInput from "components/CustomInput/CustomInput.js";
  import Search from "@material-ui/icons/Search";
  
  import GridContainer from "components/Grid/GridContainer";
  import GridItem from "components/Grid/GridItem";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import {
    fetchFacultyActivityImprovementByFacultyActivityId,
    updateFacultyActivityImprovement,
  } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
  import ErrorModal from "../../../Components/ErrorModal";
  import { useParams } from "react-router-dom";
  import { fetchFacultyActivityById } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
  import { useDispatch, useSelector } from "react-redux";
  
  import PropTypes from "prop-types";
  import { makeStyles, withStyles } from "@material-ui/core/styles";
  import clsx from "clsx";
  import Stepper from "@material-ui/core/Stepper";
  import Step from "@material-ui/core/Step";
  import StepLabel from "@material-ui/core/StepLabel";
  import Check from "@material-ui/icons/Check";
  import SettingsIcon from "@material-ui/icons/Settings";
  import GroupAddIcon from "@material-ui/icons/GroupAdd";
  import VideoLabelIcon from "@material-ui/icons/VideoLabel";
  import StepConnector from "@material-ui/core/StepConnector";
  import Typography from "@material-ui/core/Typography";
  import {
    primaryColor,
    roseColor,
    infoColor,
    grayColor,
  } from "../../../../assets/jss/material-dashboard-pro-react";
  import EventIcon from "@material-ui/icons/Event";
  import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
  import EventAvailableIcon from "@material-ui/icons/EventAvailable";
  
  export default function Analysed(props) {
    const dispatch = useDispatch();
    let { activityId } = useParams();
    const { t } = useTranslation();
  
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
  
    const handleOpenErrorModal = () => {
      setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
    };
  
    const fetchfacultyActivityImprovement = async () => {
      try {
        const resultAction = await dispatch(
          fetchFacultyActivityImprovementByFacultyActivityId(activityId)
        ).unwrap();
        // handle result here
      } catch (err) {
        setHandledError(err);
        handleOpenErrorModal();
      }
    };
  
    useEffect(() => {
      fetchfacultyActivityImprovement();
    }, []);
  
    const facultyActivityImprovement = useSelector(
      (state) => state.facultyActivity.facultyActivityImprovement
    );
  
    console.log("facultyActivityImprovement", facultyActivityImprovement);
  
    const columns = [
      // {
      //   field: "id",
      //   headerName: "id",
      //   width: 50,
      //   sortable: false,
      // },
  
      {
        dataIndex: "result",
        title: "Result",
        editable: true,
        sortable: false,
      },
      {
        dataIndex: "weaknesses",
        title: "Weaknesses",
        editable: true,
        sortable: false,
      },
      {
        dataIndex: "strengths",
        title: "Strengths",
        editable: true,
        sortable: false,
      },
      {
        dataIndex: "areas_for_improvement",
        title: "Areas for improvement",
        editable: true,
        sortable: false,
      },
      {
        dataIndex: "priorities_for_improvement",
        title: "Priorities for improvement",
        editable: true,
        sortable: false,
      },
    ];
  
    const [rows, setRows] = useState([]);
  
    const openNotificationWithIcon = (type, msg) => {
      notification[type]({
        message: type,
        description: msg,
        top: 50,
        duration: 2,
      });
    };
  
    const onCellEditCommit = async (event) => {
      console.log("onCellEditCommit", event);
      const value = event.value;
  
      const field = event.field;
  
      const id = event.id;
  
      // console.log("updated", { programs_update_ids: id, [field]: value });
  
      // if (value && value !== "Invalid date") {
      //   console.log("not empty", value);
      // } else {
      //   console.log("empty", value);
      // }
      // if (event.row[field] == value) {
      //   console.log("----------kifkif--------------");
      //   console.log("event.row[field]", event.row[field]);
      //   console.log("value", value);
      // } else {
      //   console.log("----------NOT kifkif-------------");
      //   console.log("event.row[field]", event.row[field]);
      //   console.log("value", value);
      // }
  
      if (value && value !== event.row[field]) {
        const resultAction = await dispatch(
          updateFacultyActivityImprovement({
            id: id,
            updatedFacultyActivityImprovement: {
              facultyActivity: activityId,
              [field]: value,
            },
          })
        );
        if (updateFacultyActivityImprovement.fulfilled.match(resultAction)) {
          // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
          fetchfacultyActivityImprovement();
          openNotificationWithIcon("success", `Updated successfully`);
        } else {
          if (resultAction.payload) {
            // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
            setHandledError(resultAction.payload);
            handleOpenErrorModal();
          } else {
            setHandledError(resultAction.error);
            handleOpenErrorModal();
          }
        }
      }
    };
  
    return (
      <div>
        <GridContainer>
          <GridItem>
            <Button color="primary">{t("Save")}</Button>
          </GridItem>
        </GridContainer>
        <Card>
          <CardBody>
            <div style={{ height: 300, width: "100%", margin: 20 }}>
              <Table
                rowHeight={242}
                dataSource={facultyActivityImprovement || []}
                columns={columns}
                pagination={false}
                bordered
              />
            </div>
          </CardBody>
        </Card>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </div>
    );
  }
  