import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  sections: [],
  section: null,
  sectionsStatus: 'idle',
  sectionStatus: 'idle',
  error: null,
}

export const fetchSections = createAsyncThunk(
  'section/fetchSections',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/section/`, {
        headers: { Authorization: `token ${token}` },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const fetchSectionById = createAsyncThunk(
  'section/fetchSectionById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/section/${id}`, {
        headers: { Authorization: `token ${token}` },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const addNewSection = createAsyncThunk(
  'section/addSection',
  async (section, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.post(
        BaseUrl + `/section/create/`,
        section,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const deleteSection = createAsyncThunk(
  'section/deleteSection',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.delete(BaseUrl + `/section/${id}/delete/`, {
        headers: { Authorization: `token ${token}` },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const updateSection = createAsyncThunk(
  'section/updateSection',
  async (section, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.put(
        BaseUrl + `/section/${section.id}/edit/`,
        section,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const SectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSections.fulfilled]: (state, action) => {
      state.sections = action.payload
    },
    [fetchSectionById.fulfilled]: (state, action) => {
      state.sections = action.payload
    },
  },
})
export default SectionSlice.reducer
