import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { notification, Table } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { DeleteCLOSpecWeight } from '../../../../reduxToolkit/features/CLO/WeightCloSpecSlice'
import { fetchPloById } from '../../../../reduxToolkit/features/PLO/PLOSpecSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramSpecSlice'
import {
  fetchSubLevelPLosById,
  fetchSubLevelPLos,
} from 'reduxToolkit/features/SubLevelPlo/SubLevelPloSlice'
import {
  fetchLevelPlosById,
  fetchLevelPlos,
} from 'reduxToolkit/features/levelPlo/LevelPloSlice'
import { fetchPlosByProgramId } from 'reduxToolkit/features/PLO/PLOSpecSlice.jsx'
import ErrorModal from '../../../Components/ErrorModal'
import PLOIndicatorDropDownButton from '../../../Components/PLOIndicatorDropDownButton'
import PLOIndicatorsModal from '../../../Components/PLOIndicatorsModal'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'

// import ProgramCardDropdownButton from "../Components/ProgramCardDropdownButton"
const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const PLOIndicatorsByPLOExcerpt = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()
  const cardClasses = useCardStyles()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Level',
      dataIndex: 'code_level',
      key: 'code_level',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record) => (
        <Button
          onClick={() => {
            handleClickOpen(record.id)
          }}
          color="primary"
          simple
        >
          Delete
        </Button>
      ),
    },
  ]

  const data = props.ploInd.closSpec

  const [toDeleteCLOId, setToDeleteCLOId] = React.useState(null)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (id) => {
    setToDeleteCLOId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const ids = {
    cloSpec_id: toDeleteCLOId,
    PLOISpec_id: props.ploInd.id,
  }

  const handleDeleteCLO = async (ids) => {
    dispatch(DeleteCLOSpecWeight(ids))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log('originalPromiseResult', originalPromiseResult)
        dispatch(fetchPloById(props.PLOId))
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          'rejectedValueOrSerializedError',
          rejectedValueOrSerializedError,
        )
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  return (
    <GridItem
      xs={12}
      sm={12}
      md={11}
    // key={program.id}
    >
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <Link
                to={`/citrine/programSpec/${props.programId}/PLOs/${props.PLOId}/PLOIndicators/${props.ploInd.id}`}
              >
                <h4 className={cardClasses.cardProductTitle}>
                  <div> {props.ploInd.name} </div>
                </h4>
                <div>
                  {/* <Table columns={columns} dataSource={data} /> */}
                </div>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              {!props.disabledStatus && (
                <PLOIndicatorDropDownButton
                  ploInd={props.ploInd}
                  PLOId={props.PLOId}
                  programId={props.programId}
                  ploPage={true}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteCLO}
        Element_ID={ids}
        open={open}
        handleClose={handleClose}
      />
    </GridItem>
  )
}

export default function PLOIndicatorsByPLO() {
  const { t } = useTranslation()
  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const cardClasses = useCardStyles()

  let { programId } = useParams()
  let { PLOId } = useParams()

  const dispatch = useDispatch()
  const ploStatus = useSelector((state) => state.ploSpec.ploStatus)
  const error = useSelector((state) => state.ploSpec.error)

  const [clos, setClos] = useState()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const [disabledStatus, setDisabledStatus] = useState(false)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchPLOIndicators = async () => {
    try {
      const resultAction = await dispatch(fetchPloById(PLOId))
        .unwrap()
        .then((plo) => {
          console.log(plo)
          dispatch(fetchSubLevelPLos())
          dispatch(fetchLevelPlos())
        })
        .catch((err) => console.log(err))

      console.log('success')
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    fetchPLOIndicators()
    dispatch(fetchProgramById(programId))

    if (localStorage.getItem('state') != 'Draft') {
      setDisabledStatus(true)
    }
  }, [dispatch])
  const levelPlos = useSelector((state) => state.levelPlos.levelPlos)
  const subLevelPlos = useSelector((state) => state.subLevelPlo.subLevelPLos)
  const plo = useSelector((state) => state.ploSpec.plo)
  const program = useSelector((state) => state.programSpec.program)

  console.log('plo', plo)
  console.log('levelPlos', levelPlos)
  console.log('subLevelPlos', subLevelPlos)
  // const levelPlo = levelPlos?.find(({id})=>plo.levelPlo_id==id)
  // const subLevelPlo = subLevelPlos?.find(({id})=>plo.subLevelPlo_id==id)
  // console.log("level: ",levelPlo)
  // console.log("subLevel: ",`${subLevelPlo.levelPlo_id} /  ${subLevelPlo.level}`)

  let content

  if (ploStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (ploStatus === 'succeeded') {
    if (plo && plo.ploIndicatorsSpec && plo.ploIndicatorsSpec.length) {
      content = plo.ploIndicatorsSpec.map((ploInd) => (
        <PLOIndicatorsByPLOExcerpt
          ploInd={ploInd}
          PLOId={PLOId}
          programId={programId}
          disabledStatus={disabledStatus}
        />
      ))
    } else {
      content = <h5>{t('This PLO has no PLO Indicators to display!')}</h5>
    }
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t('Programs')} </span>
          </Link>
          <Link to={`/citrine/programSpec/${program && program.id}`}>
            <span className={titleClasses.title}>
              {' '}
              {`> ${program && program.program_degree_name}`}{' '}
            </span>
          </Link>
          <Link to={`/citrine/programSpec/${program && program.id}/PLOs`}>
            <span className={titleClasses.title}>{t(`> PLOs`)} </span>
          </Link>
          <Link
            to={`/citrine/programSpec/${program && program.id}/PLOs/${plo && plo.id}/PLOIndicators`}>
            <span className={titleClasses.title}>
              {`> ${plo && plo.code} `}{' '}
            </span>
          </Link>
          <span className={titleClasses.title}> {t(`> PLO Indicators`)} </span>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {!disabledStatus && (
            <PLOIndicatorsModal
              plo={plo && plo}
              programId={programId}
              PLOId={PLOId}
            />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={11}>
          <Card>
            {/* <CardHeader>
            PLO Details
          </CardHeader> */}
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 className={cardClasses.cardProductTitle}>
                    {plo &&
                      plo.name &&
                      plo.code && <strong>Name: </strong> &&
                      `${plo.name} (${plo.code})`}
                    <br />
                    {plo &&
                      plo.code_level &&
                      ` Code Level: ${plo.code_level}`}{' '}
                    <br />
                    {plo && plo.target && `Target: ${plo.target}`} <br />

                    {/* Level : {levelPlos?.find(({ id }) => plo?.levelPlo_id == id)?.level}<br /> */}

                    Level/Sub Level: {plo?.subLevelPlos.levelPlo_level + ' / ' + plo?.subLevelPlos.sublevelPlo_level}
                  </h4>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
