import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { fetchProgramById } from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchAcademicYearById } from "../../../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import { fetchSemesterById } from "../../../../reduxToolkit/features/semester/SemestersSlice.js";

import { Link } from "react-router-dom";

// import { fetchAllPlos } from "../../../../reduxToolkit/features/PLO/PLOsSlice";
// import { fetchAllGAs } from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";
// import { fetchAllPEOs } from "../../../../reduxToolkit/features/PEO/PEOsSlice";
// import { fetchAllKPIs } from "../../../../reduxToolkit/features/KPI/KPIProgramSlice.jsx";
import {
  fetchAllPrograms
} from "../../../../reduxToolkit/features/program/ProgramsSlice.js";

import { Breadcrumb } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  infoColor
} from "../../../../assets/jss/material-dashboard-pro-react";

export default function ProgramAssessment({ match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { program_id } = match.params;
  const { semesterId } = match.params;
  const { id } = match.params;

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const fetchSemester = async (id) => {
    try {
      const resultAction = await dispatch(fetchSemesterById(id)).unwrap();
      //   setSemester(resultAction);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  const fetchProgram = async (id) => {
    try {
      const resultAction = await dispatch(fetchProgramById(id)).unwrap();
      //   setSemester(resultAction);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  const fetchAcademicYear = async (id) => {
    try {
      const resultAction = await dispatch(fetchAcademicYearById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    fetchSemester(semesterId);
    fetchAcademicYear(id);
    fetchProgram(program_id);
  }, [dispatch]);

  const semester = useSelector((state) => state.semester.semester);
  const academicYear = useSelector((state) => state.academicYear.academicYear);
  const program = useSelector((state) => state.program.program)

  console.log(program)

  // const AllPlos = useSelector((state) => state.plo.AllPlos);
  // const AllGAs = useSelector(
  //   (state) => state.GraduateAttribute.AllGraduateAttributes
  // );
  // const AllPEOs = useSelector((state) => state.peo.AllPEOs);
  // const AllKPIs = useSelector((state) => state.KPIProgram.AllKPIs);
  // const programs = useSelector((state) => state.program.allPrograms);

  return (

    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>{t("Planning")}</Breadcrumb.Item>
          <Breadcrumb.Item>{t("Academic Calender")}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/planning/academicCalender/academicYears`}>
              {t("Academic years")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/planning/academicCalender/academicYears/${id}`}
            >
              {t(`${academicYear && academicYear.name}`)}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("semesters")}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}`}
            >
              {t(`${semester && semester.name}`)}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}`}
            >
              {t(`${program && program.program_degree_name}`)}
            </Link>
          </Breadcrumb.Item>

        </Breadcrumb>
      </GridItem>
      <GridContainer >

        <GridItem xs={12} sm={12} md={3}>
          <Card style={{ height: "150px" }} className="card">
            <CardBody>
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <GridItem xs={12} sm={12} md={10}>
                  <div>
                    <Link to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}/courses`}>
                      <p style={{ fontSize: "14px" }}>

                        {t("Courses")}
                      </p>
                    </Link>
                    <br></br>
                    <strong style={{ fontSize: "16px", color: infoColor[0] }}>
                      {program && program.courses_count} Courses
                    </strong>
                  </div>
                </GridItem>

                {/* <GridItem xs={12} sm={12} md={2}>
                  <DropDownPlanning />
                </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card style={{ height: "150px" }} className="card">
            <CardBody>
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >

                <GridItem xs={12} sm={12} md={10}>
                  <div>
                    <Link to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}/PLO`}>
                      <p style={{ fontSize: "14px" }}>

                        {t("Program Learning Outcomes")}
                      </p>
                    </Link>
                    <br></br>
                    <strong style={{ fontSize: "16px", color: infoColor[0] }}>
                      {program && program.plos_count} PLOs
                    </strong>
                  </div>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={2}>
                  <DropDownPlanning />
                </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card style={{ height: "150px" }} className="card">
            <CardBody>
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <GridItem xs={12} sm={12} md={10}>
                  <div>
                    <Link to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}/GA`}>
                      <p style={{ fontSize: "16px" }}>

                        {t("Graduate attributes")}
                      </p>
                    </Link>
                    <br></br>
                    <strong style={{ fontSize: "16px", color: infoColor[0] }}>
                      {program && program.gas_count} GAs
                    </strong>
                  </div>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={2}>
                  <DropDownPlanning />
                </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card style={{ height: "150px" }} className="card">
            <CardBody>
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <GridItem xs={12} sm={12} md={10}>
                  <div>
                    <Link to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}/PEO`}>
                      <p style={{ fontSize: "14px" }}>

                        {t("Program Educational Objectives")}
                      </p>
                    </Link>
                    <br></br>
                    <strong style={{ fontSize: "16px", color: infoColor[0] }}>
                      {program && program.peos_count} PEOs
                    </strong>
                  </div>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={2}>
                  <DropDownPlanning />
                </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card style={{ height: "150px" }} className="card">
            <CardBody>
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <GridItem xs={12} sm={12} md={10}>
                  <div>
                    <Link to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}/KPI`}>
                      <p style={{ fontSize: "16px" }}>

                        {t("Key Performance Indicator")}
                      </p>
                    </Link>
                    <br></br>
                    <strong style={{ fontSize: "16px", color: infoColor[0] }}>
                      {program && program.kpis_count} KPIs
                    </strong>
                  </div>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={2}>
                  <DropDownPlanning />
                </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={3}>
          <Card style={{ height: "150px" }} className="card">
            <CardBody>
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <GridItem xs={12} sm={12} md={10}>
                  <div>
                    <Link to={`/citrine/planning/academicCalender/academicYears/${id}/semesters/${semesterId}/program/${program_id}/survey`}>
                      <p style={{ fontSize: "16px" }}>

                        {t("Survey")}
                      </p>
                    </Link>
                    <br></br>
                    <strong style={{ fontSize: "16px", color: infoColor[0] }}>
                      {program && program.SurveyProgram_count} Surveys
                    </strong>
                  </div>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={2}>
                  <DropDownPlanning />
                </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
