import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  ResearchAndDevelopmentOutputs: [],
  ResearchAndDevelopmentOutputsStatus: "idle",
  ResearchAndDevelopmentOutputStatus: "idle",
  ResearchAndDevelopmentOutput: null,
  error: null,
};

export const fetchResearchAndDevelopmentOutputs = createAsyncThunk(
  "ResearchAndDevelopmentOutputs/fetchResearchAndDevelopmentOutputs",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/researchOutPut/byStaff/?staff_id=${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const fetchResearchAndDevelopmentOutputsById = createAsyncThunk(
  "ResearchAndDevelopmentOutputs/fetchResearchAndDevelopmentOutputsById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/researchOutPut/${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);
export const addNewResearchOutPut = createAsyncThunk(
  "ResearchAndDevelopmentOutputs/addNewResearchOutPut",
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/researchOutPut/create/`,
        toAddActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);
export const UpdateResearchAndDevelopmentOutputs = createAsyncThunk(
  "ResearchAndDevelopmentOutputs/UpdateResearchAndDevelopmentOutputs",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/researchOutPut/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "education");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the education: ", error);
    }
  }
);
export const deleteResearchAndInnovationOutputs = createAsyncThunk(
  "ResearchAndDevelopmentOutputs/deleteResearchAndInnovationOutputs",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(BaseUrl + `/researchOutPut/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  }
);


const ResearchAndDevelopmentOutputsSlice = createSlice({
  name: "ResearchAndDevelopmentOutputs",
  initialState,
  extraReducers: {
    [fetchResearchAndDevelopmentOutputs.pending]: (state, action) => {
      state.ResearchAndDevelopmentOutputsStatus = "loading";
    },
    [fetchResearchAndDevelopmentOutputs.fulfilled]: (state, action) => {
      state.ResearchAndDevelopmentOutputsStatus = "succeeded";
      // Add any fetched posts to the array
      state.ResearchAndDevelopmentOutputs = action.payload;
    },
    [fetchResearchAndDevelopmentOutputs.rejected]: (state, action) => {
      state.ResearchAndDevelopmentOutputsStatus = "failed";
      state.error = action.payload;
    },



    [fetchResearchAndDevelopmentOutputsById.pending]: (state, action) => {
      state.ResearchAndDevelopmentOutputStatus = "loading";
    },
    [fetchResearchAndDevelopmentOutputsById.fulfilled]: (state, action) => {
      state.ResearchAndDevelopmentOutputStatus = "succeeded";
      // Add any fetched posts to the array
      state.ResearchAndDevelopmentOutput = action.payload;
    },
    [fetchResearchAndDevelopmentOutputsById.rejected]: (state, action) => {
      state.ResearchAndDevelopmentOutputStatus = "failed";
      state.error = action.payload;
    },



    [addNewResearchOutPut.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },

    [UpdateResearchAndDevelopmentOutputs.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },

    [deleteResearchAndInnovationOutputs.fulfilled]: (state, action) => {},
  },
});

export default ResearchAndDevelopmentOutputsSlice.reducer;
// export const  groups = state => state.group.groups
