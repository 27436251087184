import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  subLevelPLos: [],
  subLevelPLosStatus: 'idle',
  subLevelPLoStatus: 'idle',
  error: null,
  subLevelPLo: null,
  id: null,
}

export const fetchSubLevelPLos = createAsyncThunk(
  'subLevelPLos/fetchSubLevelPLos',
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + '/subLevelPlos/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      console.log(response.data, 'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchSubLevelPLosById = createAsyncThunk(
  'subLevelPLos/fetchSubLevelPLoById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/subLevelPlos/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      // console.log(response.data,'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const addNewSubLevelPLos = createAsyncThunk(
  'subLevelPLos/addNewSubLevelPLo',
  async (initialSubLevelPLo, { rejectWithValue }) => {
    console.log('initial subLevelPLo', initialSubLevelPLo)
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/subLevelPlos/create/`,
        initialSubLevelPLo,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(response.data)
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response ', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const updateSubLevelPLos = createAsyncThunk(
  'subLevelPLos/updateSubLevelPLo',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(
        BaseUrl + `/subLevelPlos/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(data, 'updatedSubLevelPLo')
      console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response ', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const additionalUpdateSubLevelPLos = createAsyncThunk(
  'subLevelPLos/additionalUpdateSubLevelPLo',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(
        BaseUrl + `/subLevelPlos/${data.id}/update/`,
        data.updatedField,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(data, 'updated')
      console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response', error.response)
      return rejectWithValue(error.response)
    }
  },
)
export const deleteSubLevelPLos = createAsyncThunk(
  'subLevelPLos/deleteSubLevelPLo',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/subLevelPlos/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      // console.log(response.data,'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response ', error.response)
      return rejectWithValue(error.response)
    }
  },
)
const subLevelPLosSlice = createSlice({
  name: ' subLevelPLos',
  initialState,
  extraReducers: {
    [fetchSubLevelPLos.pending]: (state, action) => {
      state.subLevelPLosStatus = 'loading'
    },
    [fetchSubLevelPLos.fulfilled]: (state, action) => {
      state.subLevelPLosStatus = 'succeeded'
      // Add any fetched posts to the array
      state.subLevelPLos = action.payload
    },
    [fetchSubLevelPLos.rejected]: (state, action) => {
      state.subLevelPLosStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchSubLevelPLosById.pending]: (state, action) => {
      state.subLevelPLoStatus = 'loading'
    },
    [fetchSubLevelPLosById.fulfilled]: (state, action) => {
      state.subLevelPLoStatus = 'succeeded'

      // Add any fetched posts to the array
      state.subLevelPLo = action.payload
    },
    [fetchSubLevelPLosById.rejected]: (state, action) => {
      state.subLevelPLoStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewSubLevelPLos.fulfilled]: (state, action) => {
      state.subLevelPLos.push(action.payload)
    },
    [deleteSubLevelPLos.fulfilled]: (state, action) => {
      // state.subLevelPLos.push(action.payload)
    },
    [updateSubLevelPLos.fulfilled]: (state, action) => {
      const result = state.subLevelPLos.filter(
        (subLevelPLo) => subLevelPLo.id !== action.payload.id,
      )
      // console.log("subLevelPLo id from filter from slice",subLevelPLo.id)
      result.push(action.payload)
      state.subLevelPLos = result
      console.log('action.payload.id from slice', action.payload)
    },
    [additionalUpdateSubLevelPLos.fulfilled]: (state, action) => {
      console.log('result', action.payload)
    },
    [addNewSubLevelPLos.rejected]: (state, action) => {
      state.subLevelPLoStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteSubLevelPLos.rejected]: (state, action) => {
      state.subLevelPLoStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateSubLevelPLos.rejected]: (state, action) => {
      state.subLevelPLoStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [additionalUpdateSubLevelPLos.rejected]: (state, action) => {
      state.subLevelPLoStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload
    },
  },
})

export const { getId } = subLevelPLosSlice.actions

export default subLevelPLosSlice.reducer

export const selectAllSubLevelPLos = (state) => state.subLevelPLo.subLevelPLos

// export const selectSubLevelPLoBySlug = (state, slug) =>
//   state.subLevelPLo.subLevelPLos.find(subLevelPLo => subLevelPLo.slug === slug)
