import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Form, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import { unwrapResult } from "@reduxjs/toolkit";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "antd/dist/antd.css";
import "assets/css/styles.css";

// @material-ui/core components
import { useHistory } from "react-router-dom";
import {

    fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";
// @material-ui/icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    addNewTeachingStrategieSpec, fetchTeachingStrategiesSpec
} from "../../../../../reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import ErrorModal from "../../../../Components/ErrorModal.jsx";
const layout = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 12,
        span: 12,
    },
};
export default function AddTSModal(props) {
    const { t } = useTranslation();


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };




    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };




    const dispatch = useDispatch();
    const history = useHistory();

    const { Option } = Select;


    const id = props.courseSpec_id;

    const [form] = Form.useForm();


    const [field, setField] = useState(false)
    const [course_type, setCourse_type] = useState('')
    const [value, setValue] = React.useState(1);
    const [
        planned_teaching_strategy_id,
        setplanned_teaching_strategies,
    ] = useState("");
    const [courseSpec_id, setCourseSpec_id] = useState(props.id);
    const [addRequestStatus, setAddRequestStatus] = useState("idle");


    useEffect(() => {
        dispatch(fetchCourseById(id)).then(unwrapResult).then((course) => {
            setCourse_type(course.type)
            if (course.type == 'Field Experience') {
                setField(true)

            }
            else {
                setField(false);
            }
        });
        //  dispatch(fetchTeachingStrategiesByCourse(id));
    }, [dispatch]);
    // const TeachingStrategiesList = useSelector(
    //     (state) => state.TeachingStrategieSpec.TeachingStrategieByCourse
    // );
    const course = useSelector((state) => state.CourseSpec.courseSpecification);





    const onFinish = async (values) => {

        let courseSpec_id = props.courseSpec_id;

        const resultAction = await dispatch(
            addNewTeachingStrategieSpec({ ...values, courseSpec_id })
        );
        if (addNewTeachingStrategieSpec.fulfilled.match(resultAction)) {
            openNotificationWithIcon("success", `Added successfully`);
            console.log('props.teaching_strategy_ids', props.teaching_strategy_ids);
            props.setTeaching_strategy_ids([resultAction.payload.id, ...props.teaching_strategy_ids])
            form.resetFields();
            dispatch(fetchTeachingStrategiesSpec(course && course.id && course.id));
            handleClose();
            // history.push(`/citrine/specification/course/${id}/TeachingStrategies`);
        } else {
            if (resultAction.payload) {
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
            }
        }
    };
    return (
        <div>
            <Button color="primary" onClick={handleClickOpen} round justicon size="sm">
                <AddCircleIcon />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title"><center>{t("Add Teaching Strategy")}</center></DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Form
                                {...layout}
                                form={form}
                                //name="basic"
                                layout="vertical"
                                name="control-hooks"
                                onFinish={onFinish}
                            >

                                <Form.Item
                                    name="planned_teaching_strategy_id"
                                    label="Planned Training Activity"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select dropdownStyle={{ zIndex: 10000 }} placeholder="Select mode" allowClear style={{ width: "200%" }}>
                                        {props.TeachingStrategiesConfig &&
                                            props.TeachingStrategiesConfig.map((r) => (
                                                <Option key={r.id} value={r.id}>
                                                    {r.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item  {...tailLayout}>
                                    <Button
                                        style={{ marginRight: "20px" }}
                                        color="primary"
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        {t("Cancel")}
                                    </Button>



                                    <Button type="submit" color="primary">
                                        {t("Save")}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </GridItem>
                    </GridContainer>

                </DialogContent>
            </Dialog >

            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div >
    );
}


