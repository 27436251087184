import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchTypes } from '../../../../../reduxToolkit/features/type/TypeSlice';
import { fetchAreas} from 'reduxToolkit/features/area/AreaSpecSlice';

import DropDownTypeButton from "../../../../Components/DropDownTypeButton";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);


const TypeExcerpt = ({ type }) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const areas = useSelector(state => state.areaSpec.areas)
  const listAreaSpec  = areas?.filter(({id})=>type.areaSpec_ids.includes(id)).map(({name})=> name).join(", ")
  //console.log(listAreaSpec)

  return (

    <GridItem xs={12} sm={12} md={4} key={type.id} >
      <Card style={{ maxWidth: "380px", minWidth: "380px", maxHeight: "180px", minHeight: "180px" }} cardHover >
        <CardHeader>
          <GridContainer direction="row"
            justifyContent="space-between"
            alignItems="baseline">
            <GridItem xs={10} sm={10} md={10}>
              <h4 className={cardClasses.cardProductTitle}>
                {type.name}                
              </h4>
              <h4 className={cardClasses.cardProductTitle}>
              {listAreaSpec && `Area: ${listAreaSpec}`}
              </h4>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <DropDownTypeButton id={type.id} />

            </GridItem>
          </GridContainer>
        </CardHeader>
        {/* <CardBody smallCardBody>

          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}> Departments:
            {program.department_detail && program.department_detail.map((department) => {
              return <div style={{ fontWeight: "normal" }}> {department.name} </div>

            }
            )}
          </div>


        </CardBody> */}
        <CardFooter>
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="center">
            {/* <GridItem> <span style={{ color: (program.state) === "Draft" ? grayColor[1] : "#FFCB3B" }}>{program.state}</span> </GridItem> */}
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

export default function Types() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("")

  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)


  const dispatch = useDispatch()

  const typesStatus = useSelector(state => state.type.typesStatus)
  useEffect(() => {
    if (typesStatus === 'idle') {
      dispatch(fetchTypes())
    }
    dispatch((fetchAreas()))
  }, [
    typesStatus,
    dispatch])

  const types = useSelector(state => state.type.types)
  const error = useSelector(state => state.type.error)
  console.log(types, "types")
  let content

  const searchButton =
    navBarClasses.top +
    " " +
    navBarClasses.searchButton

  if (typesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (typesStatus === 'succeeded') {
    content = types && types.filter((type) => {
      if (search == '') {
        return type
      } else if (type.name.toLowerCase().includes(search.toLowerCase())) {
        return type
      }
    }).map(type => (
      <TypeExcerpt key={type.id} type={type} />
    ))
  } else if (typesStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer

        md={12}
        xs={12} sm={12}
        direction="row"
        // style={{ width:300 ,height:150}}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>

          <Breadcrumb separator=">">
            <Breadcrumb.Item >

              {t('Configurations')}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span > {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course/type`}>
                <span > {t('Types')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

      </GridContainer>



      <GridContainer
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Link to="/citrine/configuration/course/type/createType/:id">
            <Button color="primary">
              {t(' Add')}
            </Button>
          </Link>
        </GridItem>

      </GridContainer>

      <GridContainer >
        {content}
      </GridContainer>
      {/* <Link to={"/citrine/programs/test"} >
    <p>here</p>
    </Link> */}
    </div>
  );
}













