import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  Projects: [],
  ProjectsStatus: "idle",
  ProjectStatus: "idle",
  error: null,
  Project: null,
};

export const fetchProjectsByPersonId = createAsyncThunk(
  "Project/fetchProjectsByPersonId",
  async (person_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/project/byStaff/?staff_id=${person_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);

export const addNewProject = createAsyncThunk(
  "Project/addNewProject",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/project/create/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateProject = createAsyncThunk(
  "Project/UpdateProject",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/project/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "education");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the education: ", error);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "Project/deleteProject",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(BaseUrl + `/project/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  }
);

const ProjectSlice = createSlice({
  name: "education",
  initialState,
  extraReducers: {
    [fetchProjectsByPersonId.pending]: (state, action) => {
      state.ProjectsStatus = "loading";
    },
    [fetchProjectsByPersonId.fulfilled]: (state, action) => {
      state.ProjectsStatus = "succeeded";
      // Add any fetched posts to the array
      state.Projects = action.payload;
    },
    [fetchProjectsByPersonId.rejected]: (state, action) => {
      state.ProjectsStatus = "failed";
      state.error = action.payload;
    },

    [addNewProject.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },

    [UpdateProject.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    [deleteProject.fulfilled]: (state, action) => {},
  },
});

export default ProjectSlice.reducer;
// export const  groups = state => state.group.groups
