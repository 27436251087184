import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons

import { Link } from "react-router-dom";
import {
  addNewModeOfInstruction,
  fetchModeOfInstructionById,
  fetchModeOfInstructions
} from "../../../../../reduxToolkit/features/ModeOfInstruction/ModeOfInstructionSlice";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useHistory } from "react-router-dom";

import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import "antd/dist/antd.css";

import { Breadcrumb } from "antd";

import { Form, InputNumber, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import { fetchCourseById } from "reduxToolkit/Course/coursesSpecSlice";
import { ModeOfInstructionUpdate } from "reduxToolkit/features/ModeOfInstruction/ModeOfInstructionSlice";
import { fetchModeOfInstructionConfigsByCourseId } from "../../../../../reduxToolkit/features/modeOfInstructionConfig/ModeOfInstructionConfigSlice";
import ErrorModal from "../../../../Components/ErrorModal";
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

export default function CreateModeOfInstruction({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const dispatch = useDispatch();
  const history = useHistory();
  const { Option } = Select;
  const [form] = Form.useForm();

  const { id } = match.params;
  const { idMOI } = match.params;
  const isAddMode = isNaN(idMOI);

  const [filteredmodes, setFilteredmodes] = useState([]);
  let tab = [];

  const [mode_of_instruction_id, setMode_of_instruction] = useState("");
  const [contact_hours, setContact_hours] = useState("");
  const [courseSpec_id, setCourseSpec_id] = useState(id);
  const [percentage, setPercentage] = useState("");

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const onMode_of_instructionChanged = (e) =>
    setMode_of_instruction(e.target.value);


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  useEffect(() => {
    dispatch(fetchModeOfInstructionConfigsByCourseId(id))
      .then(unwrapResult)
      .then(() => dispatch(fetchCourseById(id)));

  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const modes = useSelector(
    (state) => state.ModeOfInstructionConfig.modesByCourse
  );
  const ModeOfInstructionConfigsStatus = useSelector(
    (state) => state.ModeOfInstructionConfig.ModeOfInstructionConfigsStatus
  );



  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewModeOfInstruction({ ...values, courseSpec_id })
    );
    if (addNewModeOfInstruction.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchModeOfInstructions(course && course.id && course.id));
      history.push(`/citrine/ModeOfInstructionList/${id}`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      ModeOfInstructionUpdate({
        id: idMOI,
        modified: {
          ...values,
        },
      })
    );
    if (ModeOfInstructionUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchModeOfInstructions(course && course.id && course.id));
      history.push(`/citrine/ModeOfInstructionList/${id}`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchModeOfInstructionById(idMOI))
        .then(unwrapResult)
        .then((MOI) => {
          console.log(MOI, "MOI");
          form.setFieldsValue({
            mode_of_instruction: MOI.mode_of_instruction_id,
            contact_hours: MOI.contact_hours,
            percentage: MOI.percentage,
          });
        });
    }
  }, [dispatch, idMOI]);

  const ModeOfInstruction = useSelector(
    (state) => state.ModeOfInstruction.ModeOfInstruction
  );

  //   useEffect(() => {
  //     if (!isAddMode) {
  //       setMode_of_instruction(
  //         ModeOfInstruction && ModeOfInstruction.mode_of_instruction_id
  //       );
  //       setContact_hours(ModeOfInstruction && ModeOfInstruction.contact_hours);
  //       setCourseSpec_id(ModeOfInstruction && ModeOfInstruction.courseSpec_id);
  //       setPercentage(ModeOfInstruction && ModeOfInstruction.percentage);
  //     }
  //   }, [ModeOfInstruction, dispatch]);

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">


                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/ModeOfInstructionList/${course && course.id && course.id
                          }`}

                      >
                        {t("Mode Of Instruction List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        style={{ color: "orange" }}
                        to={`/citrine/CreateModeOfInstruction/${id}`}
                      >
                        {t("Create")}
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="mode_of_instruction_id"
                        label="Mode Of Instruction"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {modes &&
                            modes.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="contact_hours"
                        label="Contact Hours"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        name="percentage"
                        label="Percentage"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(`/citrine/ModeOfInstructionList/${id}`)
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          color="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && ModeOfInstruction && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/ModeOfInstructionList/${course && course.id && course.id
                          }`}

                      >
                        {t("Mode Of Instruction List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        style={{ color: "orange" }}
                        to={`/citrine/CreateModeOfInstruction/${id}`}
                      >
                        {t("Edit")}
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="mode_of_instruction"
                        label="Mode Of Instruction"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {modes &&
                            modes.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="contact_hours"
                        label="Contact Hours"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        name="percentage"
                        label="Percentage"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(`/citrine/ModeOfInstructionList/${id}`)
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          color="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
