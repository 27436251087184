// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { SchoolRounded } from '@material-ui/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'

import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
} from 'antd'

// core components

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchStaff, fetchUsers } from 'reduxToolkit/features/user/UserSlice'
import Countries from '../../../../assets/utils/countries'
import {
  addNewUniversity,
  fetchUniversities,
  fetchUniversityById,
  universityUpdate,
} from '../../../../reduxToolkit/features/university/UniversitiesSlice'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import ErrorModal from '../../../Components/ErrorModal'
import Stack from "@mui/material/Stack";
const useStyles = makeStyles(styles)

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}

export default function CreateUniversityPage({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const [form] = Form.useForm()

  const isAddMode = isNaN(id)
  const classes = useStyles()
  const useTitleStyles = makeStyles(titleStyles)
  const { Option } = Select
  const personsStatus = useSelector((state) => state.user.usersStatus)

  useEffect(() => {
    if (personsStatus === 'idle') {
      dispatch(fetchUsers())
    }
  }, [])

  const titleClasses = useTitleStyles()
  const [name, setName] = useState('')
  const [mission, setMission] = useState('')
  const [vision, setVision] = useState('')
  const [goals, setGoals] = useState('')
  const [values, setValues] = useState('')
  const [phone_number, setPhone_number] = useState('')
  const [email, setEmail] = useState('')
  const [director_id, setDirector] = useState('')

  const [address, setaddress] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [postal_code, setPostal_code] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const onNameChanged = (e) => setName(e.target.value)
  const onMissionChanged = (e) => setMission(e.target.value)
  const onVisionChanged = (e) => setVision(e.target.value)
  const onGoalsChanged = (e) => setGoals(e.target.value)
  const onValuesChanged = (e) => setValues(e.target.value)
  const onPhone_numberChanged = (e) => setPhone_number(e.target.value)
  const onEmailChanged = (e) => setEmail(e.target.value)
  const onDirectorChanged = (e) => setDirector(e.target.value)

  const onaddressChanged = (e) => setaddress(e.target.value)
  const onCityChanged = (e) => setCity(e.target.value)
  const onCountryChanged = (e) => setCountry(e.target.value)
  const onPostal_codeChanged = (e) => setPostal_code(e.target.value)

  const dispatch = useDispatch()
  const canSave =
    [
      name,
      mission,
      vision,
      goals,
      values,
      phone_number,
      director_id,
      email,
      address,
      city,
      country,
      postal_code,
    ].every(Boolean) && addRequestStatus === 'idle'
  const history = useHistory()
  function onChangeDirector(value) {
    setDirector(value)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    //message.error('Submit failed!')
  }

  const onFinish = async (values) => {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(addNewUniversity(values))
      unwrapResult(resultAction)
      openNotificationWithIcon('success', `Added with success`)
      dispatch(fetchUniversities())
      history.push('/citrine/organizations/universities')
      form.resetFields()
    } catch (err) {
      console.log(err)
      setHandledError(err)
      handleOpenErrorModal()
    } finally {
      setAddRequestStatus('idle')
    }
  }

  const onSaveUniversityClicked = async () => {
    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(
        addNewUniversity({
          name,
          mission,
          vision,
          goals,
          values,
          phone_number,
          email,
          director_id,
          address,
          city,
          country,
          postal_code,
        }),
      )
      unwrapResult(resultAction)
      setName('')
      setMission('')
      setVision('')
      setGoals('')
      setValues('')
      setPhone_number('')
      setEmail('')
      setDirector('')

      setaddress('')
      setCity('')
      setCountry('')
      setPostal_code('')
      dispatch(fetchUniversities())
      history.push('/citrine/organizations/universities')
    } catch (err) {
      console.error('Failed to save the university: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }

  const onUpdateUniversityClicked = async () => {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(
        universityUpdate({
          id: id,
          modified: {
            name,
            mission,
            vision,
            goals,
            values,
            phone_number,
            email,
            director_id,
            address,
            city,
            country,
            postal_code,
          },
        }),
      )
      unwrapResult(resultAction)
      setName('')
      setMission('')
      setVision('')
      setGoals('')
      setValues('')
      setPhone_number('')
      setEmail('')
      setDirector('')
      setaddress('')
      setCity('')
      setCountry('')
      setPostal_code('')
      dispatch(fetchUniversities())
      history.push('/citrine/organizations/universities')
    } catch (err) {
      console.log('university', universityUpdate)
      console.error('Failed to save the university: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchUniversityById(id))
    }
    dispatch(fetchStaff())
  }, [id])

  const university = useSelector((state) => state.university.university)
  const staffList = useSelector((state) => state.user.staffList)

  useEffect(() => {
    if (!isAddMode) {
      setName(university && university.name)
      setMission(university && university.mission)
      setVision(university && university.vision)
      setGoals(university && university.goals)
      setValues(university && university.values)
      setPhone_number(university && university.phone_number)
      setEmail(university && university.email)
      setDirector(university && university.director_id)

      setaddress(university && university.address)
      setCity(university && university.city)
      setCountry(university && university.country)

      setPostal_code(university && university.postal_code)
    }
  }, [university, dispatch])

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                          <Link to={`/citrine/organizations`}>
                            <span> {t('Organizations')} </span>
                          </Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item>
                          <Link to={`/citrine/organizations/universities`}>
                            <span> {t('Universities')} </span>
                          </Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item>
                          <span> {t('Create')} </span>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Form
                      name="basic"
                      form={form}
                      layout="vertical"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      scrollToFirstError
                    >
                      <GridContainer
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <GridItem xs={6} sm={6} md={6}>
                          <Form.Item
                            label={t('Name')}
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a name!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label={t('Mission')}
                            name="mission"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a mission!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label={t('Vision')}
                            name="vision"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a vision!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label={t('Goals')}
                            name="goals"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter goals!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label={t('Values')}
                            name="values"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter values!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <br />
                          <GridItem xs={12} sm={12} md={6}>
                            <Form.Item
                              label={t('Director')}
                              name="director_id"
                              rules={[
                                {
                                  required: false,
                                  message: 'Please director id',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: 400 }}
                                optionFilterProp="children"
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                // filterOption={(input, option) =>
                                //   option.children
                                //     .toLowerCase()
                                //     .indexOf(input.toLowerCase()) >= 0
                                // }
                              >
                                {staffList &&
                                  staffList.map((staff) => (
                                    <Option key={staff.id} value={staff.id}>
                                      {!staff?.person_details[0]?.avatar ? (
                                        <span
                                          style={{
                                            backgroundColor:
                                              'rgb(143, 102, 130)',
                                            width: '23px',
                                            height: '23px',
                                            color: 'white',
                                            borderRadius: '50%',
                                            paddingLeft: '7px',
                                            marginRight: '15px',
                                            display: 'inline-block',
                                            lineHeight: '23px',
                                          }}
                                        >
                                          {staff?.person_details[0]?.first_name?.charAt(0)?.toUpperCase() + '     '}
                                        </span>
                                      ) : (
                                        <img
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                          src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                                        />
                                      )}
                                      {`${
                                        staff.person_details &&
                                        staff.person_details[0].first_name
                                      } ${
                                        staff.person_details &&
                                        staff.person_details[0].last_name
                                      } `}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </GridItem>
                        </GridItem>

                        <GridItem xs={6} sm={6} md={6}>
                          <GridItem>
                            <Form.Item
                              name="phone_number"
                              label="Phone number"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a phone number!',
                                },
                              ]}
                            >
                              <InputNumber
                                autoSize={true}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </GridItem>
                          <GridItem>
                            <Form.Item
                              name="email"
                              label="Email address"
                              rules={[
                                {
                                  type: 'email',
                                  message: 'The input is not valid E-mail!',
                                },
                                {
                                  required: true,
                                  message: 'Please input your E-mail!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="address"
                              label="address"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter an address!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name="city"
                              label="City"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a city!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <br />
                            <GridItem xs={12} sm={12} md={6}>
                              <Form.Item
                                label={t('Country')}
                                name="country"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Please select country',
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  style={{ width: 400 }}
                                  optionFilterProp="children"
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {Countries.map((c) => (
                                    <Option key={c.name} value={c.name}>
                                      {`${c.code}/ ${c.name}`}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </GridItem>

                            <Form.Item
                              name="postal_code"
                              label="Postal Code"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a postal_code!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </GridItem>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="flex-end">
                        <GridItem>
                          <Form.Item>
                            <Button
                              onClick={() =>
                                history.push(
                                  '/citrine/organizations/universities',
                                )
                              }
                              style={{ marginRight: '20px' }}
                              color="danger"
                            >
                              {t('Cancel')}
                            </Button>
                            <Button
                              type="submit"
                              color="info"
                              className={classes.updateProfileButton}
                            >
                              {t('Save')}
                            </Button>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      {!isAddMode && university && (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <span className={titleClasses.title}>
                      {' '}
                      <Link to={`/citrine/organizations`}>
                        {t('Organizations')}
                      </Link>{' '}
                      <Link to={`/citrine/organizations/universities`}>
                        {' '}
                        {t('/Universities')}
                      </Link>{' '}
                      {t('/Edit')}{' '}
                    </span>
                  </h4>
                </CardHeader>

                <CardBody>
                  <form>
                    <GridContainer
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText={t('Name ')}
                          id="name"
                          //  onChange={onNameChanged}
                          type="text"
                          name="name"
                          value={name}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setName(event.target.value)
                            },
                            value: name,
                          }}
                        />

                        <CustomInput
                          labelText={t('Mission ')}
                          id="mission"
                          value={mission}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a mission!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setMission(event.target.value)
                            },
                            value: mission,
                          }}
                        />

                        <CustomInput
                          labelText={t('Vision ')}
                          id="vision"
                          value={vision}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a vision!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setVision(event.target.value)
                            },
                            value: vision,
                          }}
                        />

                        <CustomInput
                          labelText={t('Goals ')}
                          id="goals"
                          value={mission}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter goals!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setGoals(event.target.value)
                            },
                            value: goals,
                          }}
                        />

                        <CustomInput
                          labelText={t('Values ')}
                          id="values"
                          value={values}
                          //     onChange={onMissionChanged}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter values!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setValues(event.target.value)
                            },
                            value: values,
                          }}
                        />
                        <GridItem xs={12} sm={12} md={6}>
                          <label>{t('Director')}</label>
                          <Select
                            showSearch
                            style={{ width: 400 }}
                            optionFilterProp="children"
                            name="director_id"
                            value={director_id}
                            onChange={onChangeDirector}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            // filterOption={(input, option) =>
                            //   option.children
                            //     .toLowerCase()
                            //     .indexOf(input.toLowerCase()) >= 0
                            // }
                          >
                            {staffList &&
                              staffList.map((staff) => (
                                <Option value={staff && staff.id}>
                                  {!staff?.person_details[0]?.avatar ? (
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(143, 102, 130)',
                                        width: '23px',
                                        height: '23px',
                                        color: 'white',
                                        borderRadius: '50%',
                                        paddingLeft: '7px',
                                        marginRight: '15px',
                                        display: 'inline-block',
                                        lineHeight: '23px',
                                      }}
                                    >
                                      {staff?.person_details[0]?.first_name?.charAt(0)?.toUpperCase() + '     '}
                                    </span>
                                  ) : (
                                    <img
                                      style={{ width: '20px', height: '20px' }}
                                      src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                                    />
                                  )}
                                  {` ${
                                    staff &&
                                    staff.person_details &&
                                    staff.person_details[0] &&
                                    staff.person_details[0].first_name
                                  } 
                          ${' '}
                          ${
                            staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].last_name
                          }`}
                                </Option>
                              ))}
                          </Select>
                        </GridItem>
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <GridItem>
                          <CustomInput
                            labelText={t('Phone number ')}
                            id="Phone_number"
                            //  onChange={onPhoneNumberChanged}
                            type="number"
                            // value={Phone_number}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a phone number!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setPhone_number(event.target.value)
                              },
                              value: phone_number,
                            }}
                          />
                        </GridItem>

                        <GridItem>
                          <CustomInput
                            labelText={t('Email address ')}
                            id="Email"
                            value={email}
                            //     onChange={onEmailChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an email!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setEmail(event.target.value)
                              },
                              value: email,
                            }}
                          />

                          <CustomInput
                            labelText={t('address ')}
                            id="address"
                            value={address}
                            //       onChange={onaddressChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an address!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setaddress(event.target.value)
                              },
                              value: address,
                            }}
                          />

                          <CustomInput
                            labelText={t('City ')}
                            id="City"
                            value={city}
                            //         onChange={onCityChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a city!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setCity(event.target.value)
                              },
                              value: city,
                            }}
                          />

                          <CustomInput
                            labelText={t('Country ')}
                            id="Country"
                            value={country}
                            //         onChange={onCountryChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a country!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setCountry(event.target.value)
                              },
                              value: country,
                            }}
                          />

                          <CustomInput
                            labelText={t('Postal Code ')}
                            id="Posta_code"
                            value={postal_code}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            //       onChange={onPostalCodeChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a postal code!',
                              },
                            ]}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setPostal_code(event.target.value)
                              },
                              value: postal_code,
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          <Stack spacing={2} direction="row">
                            <Button
                                color="info"
                                className={classes.updateProfileButton}
                                onClick={onUpdateUniversityClicked}
                            >
                              {t('Update')}
                            </Button>
                            <Button
                                color="danger"
                                className={classes.updateProfileButton}
                            >
                              <Link to={`/citrine/organizations/universities`}>
                                <span > {t('Cancel')} </span>
                              </Link>
                            </Button>
                          </Stack>
                        </GridItem>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
