// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import EditIcon from '@material-ui/icons/Edit'
import { unwrapResult } from '@reduxjs/toolkit'
import {Breadcrumb, notification, Select} from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import {
  default as defaultAvatar,
  default as defaultImage
} from 'assets/img/default-avatar.png'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import EditAvatarModal from '../../Faculty/CvTech/EditAvatarModal'
// @material-ui/icons
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import moment from 'moment'
import React, {createRef, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  addNewPerson,
  fetchPersonById,
  fetchPersons,
  updatePerson
} from 'reduxToolkit/features/person/PersonsSlice'
import teacher from '../../../../assets/img/teacher.png'
import ErrorModal from "../../../Components/ErrorModal.jsx";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))

export default function AddEditPerson({ match }) {

  const { t } = useTranslation()
  const { id } = match.params
  const isAddMode = isNaN(id)

  const [open, setOpen] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [address, setAdress] = useState('')
  const [email, setEmail] = useState('')
  const [phone_number, setPhone_number] = useState('')
  const [cin, setCin] = useState('')
  const [age, setAge] = useState('')
  const [code, setCode] = useState('')
  const [role, setRole] = useState('')
  const [responsible, setResponsible] = useState('')
  const [avatar, setAvatar] = useState('')
  const [valid, setValid] = useState(false)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [file, setFile] = useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = useState(avatar ? defaultAvatar : defaultImage,)

  const dispatch = useDispatch()
  const history = useHistory()

  const person = useSelector((state) => state.person.person)

  let fileInput = createRef()

  const uploadData = new FormData()

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchPersonById(id))
    }
  }, [id])

  useEffect(() => {
    if (!isAddMode) {
      setFirstName(person && person.first_name)
      setLastName(person && person.last_name)
      setAdress(person && person.address)
      setEmail(person && person.email)
      setPhone_number(person && person.phone_number)
      setCin(person && person.cin)
      setAge(person && person.age)
      setCode(person && person.code)
      setRole(person && person.role)
      setResponsible(person && person.responsible)
    }
  }, [person])

  const { Option } = Select

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const titleClasses = useTitleStyles()
  const classes = useStyles()

  const onFirstNameChanged = (e) => setFirstName(e.target.value)

  const onLastNameChanged = (e) => setLastName(e.target.value)

  const onAdressChanged = (e) => setAdress(e.target.value)

  const onEmailChanged = (e) => setEmail(e.target.value)

  const onPhone_numberChanged = (e) => setPhone_number(e.target.value)

  const onCinChanged = (e) => setCin(e.target.value)

  const onAgeChanged = (e) => setAge(e.target.value)

  const onCodeChanged = (e) => setCode(e.target.value)

  const onRoleChanged = (e) => setRole(e.target.value)

  const onResponsibleChanged = (e) => setResponsible(e.target.value)

  let validation = {
    isEmailAddress: function (str) {
      let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      return pattern.test(str) // returns a boolean
    },
    isNotEmpty: function (str) {
      let pattern = /\S+/
      return pattern.test(str) // returns a boolean
    },
    isNumber: function (str) {
      let pattern = /^\d+\.?\d*$/
      return pattern.test(str) // returns a boolean
    },
    isSame: function (str1, str2) {
      return str1 === str2
    },
  }

  const handleValidation = (e) => {
    setValid(!validation.isNumber(e.target.value))
  }

  const handleEmailValidation = (email) => {
    setValid(validation.isEmailAddress(email))
  }

  const onSaveClicked = async () => {
    console.log(valid)
    if(!valid) {
      return
    }
    const uploadData = new FormData()
    uploadData.append('avatar', avatar)
    uploadData.append('first_name', first_name)
    uploadData.append('last_name', last_name)
    uploadData.append('address', address)
    uploadData.append('email', email)
    uploadData.append('phone_number', phone_number)
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(addNewPerson(uploadData))
      unwrapResult(resultAction)
      setFirstName('')
      setLastName('')
      setAdress('')
      setEmail('')
      setPhone_number('')
      setAvatar('')
      dispatch(fetchPersons())
      openNotificationWithIcon('success', `Added successfully`)
      history.push('/citrine/persons')
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    } finally {
      setAddRequestStatus('idle')
    }
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onUpdateClicked = async () => {
    if(!valid) {
      return
    }
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(
        updatePerson({
          id: id,
          modified: { first_name, last_name, address, email, phone_number },
        }),
      )
      unwrapResult(resultAction)
      dispatch(fetchPersons())
      openNotificationWithIcon('success', `Updated successfully`)
      history.push('/citrine/persons')
    } catch (err) {
    } finally {
      setAddRequestStatus('idle')
    }
  }

  function onChange(date, dateString) {
    let d = moment(date).format('YYYY-MM-DD')
    setAge(d)
  }

  const handleImageChange = (e) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      setFile(file)
      setImagePreviewUrl(reader.result)
      setAvatar(file)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
    handleSubmit(e)
  }

  const handleSubmit = (e) => {
    let idp = 1
    let avatar = avatar
    uploadData.append('avatar', avatar)
    uploadData.append('idp', idp)
  }

  const handleClick = () => {
    fileInput.current.click()
    setAvatar(avatar)
  }

  const handleRemove = () => {
    setFile(null)
    setImagePreviewUrl(avatar ? defaultAvatar : defaultImage)
    fileInput.current.value = null
  }

  return (
    <div>
      {isAddMode && (
        <div>
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/citrine/configurations`}>{t('Configurations')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/persons/`}>{t('Persons List')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Create')}</Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridContainer>
            <Card>
              <CardBody>
                <form>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <GridItem>
                      <GridContainer
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <GridItem xs={12} sm={12} md={3}>
                          <div className="fileinput text-center">
                            <input
                              type="file"
                              onChange={handleImageChange}
                              ref={fileInput}
                            />
                            <div className={'thumbnail'}>
                              <img src={imagePreviewUrl} alt="..." />
                            </div>
                            <div>
                              {file === null ? (
                                <Button
                                  justIcon
                                  color="primary"
                                  round="true"
                                  padding="6px 6px"
                                  fontSize="6px"
                                  onClick={() => {
                                    handleClick()
                                    handleSubmit()
                                  }}
                                >
                                  {avatar ? (
                                    <AddIcon className={classes.icons} />
                                  ) : (
                                    <AddIcon className={classes.icons} />
                                  )}
                                </Button>
                              ) : (
                                <span>
                                  <Button
                                    justIcon
                                    color="primary"
                                    round="true"
                                    onClick={() => handleClick()}
                                  >
                                    <EditIcon className={classes.icons} />
                                  </Button>
                                  {avatar ? <br /> : null}
                                  <Button
                                    justIcon
                                    color="primary"
                                    round="true"
                                    onClick={() => handleRemove()}
                                  >
                                    <DeleteRoundedIcon
                                      className={classes.icons}
                                    />
                                  </Button>
                                </span>
                              )}
                            </div>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={6}>
                              <GridContainer xs={12} sm={12} md={12}>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ width: 1000 }}
                                >
                                  <CustomInput
                                    labelText={t('First Name *')}
                                    id="firstName"
                                    value={first_name}
                                    type="text"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter a first name!',
                                      },
                                    ]}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      disabled: false,
                                      onChange: (event) => {
                                        setFirstName(event.target.value)
                                      },
                                    }}
                                  />
                                </GridItem>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ width: 1000 }}
                                >
                                  <CustomInput
                                    labelText={t('Last Name *')}
                                    id="lastName"
                                    value={last_name}
                                    type="text"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter a last name!',
                                      },
                                    ]}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      disabled: false,
                                      onChange: (event) => {
                                        setLastName(event.target.value)
                                      },
                                    }}
                                  />
                                </GridItem>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ width: 1000 }}
                                >
                                  <CustomInput
                                    labelText={t('Email *')}
                                    id="email"
                                    value={email}
                                    type="email"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter a valid Email!',
                                      },
                                    ]}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      disabled: false,
                                      onChange: (event) => {
                                        setEmail(event.target.value)
                                        handleEmailValidation(event.target.value)
                                      },
                                    }}
                                  />
                                  <span style={{color: 'red'}}>
                                    {email.length > 0 ? valid ? '' : 'Email not valid!' : ''}
                                  </span>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <CustomInput
                                    labelText={t('Adress *')}
                                    id="address"
                                    value={address}
                                    type="text"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter an adress!',
                                      },
                                    ]}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      disabled: false,
                                      onChange: (event) => {
                                        setAdress(event.target.value)
                                      },
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <CustomInput
                                    pattern="[0-9]*"
                                    labelText={t('Phone Number *')}
                                    id="phone_number"
                                    value={phone_number}
                                    type="number"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter a phone number!',
                                      },
                                    ]}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      disabled: false,
                                      onChange: (event) => {
                                        setPhone_number(event.target.value.replace(/\D/g, ''));
                                      },
                                    }}
                                  />
                                  <p style={{ color: 'red' }}>
                                    {valid && 'Field accept only numbers '}
                                  </p>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <GridContainer xs={12} sm={12} md={12}>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <Button
                        onClick={() => {
                          onSaveClicked()
                        }}
                        color="primary"
                      >
                        {t('Save')}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridContainer>
        </div>
      )}
      {!isAddMode && person && (
        <div>
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/citrine/configurations`}>{t('Configurations')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/persons/`}>{t('Persons List')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Edit')}</Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridContainer>
            <Card>
              <form>
                <GridContainer
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={10}>
                        <Button class="btn btn-light" onClick={handleClickOpen}>
                          {person && person.avatar === '' ? (
                            <img src={teacher} width="50%" height="50%" />
                          ) : (
                            <img
                              src={`${person && person.avatar}`}
                              width="50%"
                              height="50%"
                            />
                          )}
                        </Button>
                        <EditAvatarModal
                          person_id={id}
                          person={person}
                          open={open}
                          handleClose={handleClose}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('First Name *')}
                          id="firstName"
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a first name!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setFirstName(event.target.value)
                            },
                            value: first_name,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('Last Name *')}
                          id="lastName"
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a last name!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setLastName(event.target.value)
                            },
                            value: last_name,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('Adress *')}
                          id="address"
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an adress!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setAdress(event.target.value)
                            },
                            value: address,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('Email *')}
                          id="email"
                          type="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a valid Email!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setEmail(event.target.value)
                              handleEmailValidation(event.target.value)
                            },
                            value: email,
                          }}
                        />
                        <span style={{color: 'red'}}>
                                    {!valid ? '' : 'Email not valid!'}
                                  </span>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          pattern="[0-9]*"
                          labelText={t('Phone Number *')}
                          id="phone_number"
                          type="number"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a phone number!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) => {
                              setPhone_number(event.target.value.replace(/\D/g, ''));
                            },
                            value: phone_number
                          }}
                        />
                        <p style={{ color: 'red' }}>
                          {valid && 'Field accept only numbers '}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </form>
            </Card>
          </GridContainer>

          <Button
            onClick={() => {
              onUpdateClicked()
            }}
            color="primary"
          >
            {t(' Update')}
          </Button>
        </div>
      )}
       <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
