import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  peoReportDocuments: [],
  peoReportDocumentsStatus: 'idle',
  peoReportDocument: null,
  peoReportDocumentStatus: 'idle',
  error: null,

}

export const fetchPEOReportDocuments = createAsyncThunk('peoReportDocument/fetchPEOReportDocuments', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/htmlTemplatePEOReport/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchPEOReportDocumentsByProgramId = createAsyncThunk('peoReportDocument/fetchPEOReportDocumentsByProgramId', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/htmlTemplatePEOReport/by_program/?program_id=${id}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchPEOReportDocumentById = createAsyncThunk('peoReportDocument/fetchPEOReportDocumentById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/htmlTemplatePEOReport/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewPEOReportDocument = createAsyncThunk(
  'peoReportDocument/addNewPEOReportDocument',
  async (initialPEOReportDocument) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/htmlTemplatePEOReport/create/`, initialPEOReportDocument, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updatePEOReportDocument = createAsyncThunk(
  'peoReportDocument/updatePEOReportDocument',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/htmlTemplatePEOReport/${data.id}/edit/`, data.updatedPEOReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the PEOReportDocument: ', error)

    }
  }
)

export const deletePEOReportDocument = createAsyncThunk('peoReportDocument/deletePEOReportDocument', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/htmlTemplatePEOReport/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})


const peoReportDocumentsSlice = createSlice({
  name: ' peoReportDocument',
  initialState,
  extraReducers: {
    

    [fetchPEOReportDocumentsByProgramId.pending]: (state, action) => {
      state.peoReportDocumentsStatus = 'loading'
    },
    [fetchPEOReportDocumentsByProgramId.fulfilled]: (state, action) => {
      state.peoReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.peoReportDocuments = action.payload
    },
    [fetchPEOReportDocumentsByProgramId.rejected]: (state, action) => {
      state.peoReportDocumentsStatus = 'failed'
      state.error = action.payload
    },

    [fetchPEOReportDocumentById.pending]: (state, action) => {
      state.peoReportDocumentStatus = 'loading'
    },
    [fetchPEOReportDocumentById.fulfilled]: (state, action) => {
      state.peoReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.peoReportDocument = action.payload
    },
    [fetchPEOReportDocumentById.rejected]: (state, action) => {
      state.peoReportDocumentStatus = 'failed'
      state.error = action.payload
    },
    [addNewPEOReportDocument.fulfilled]: (state, action) => {
    //   state.HTMLTemplates.push(action.payload)
    console.log("PEOReportDocument add action.payload",action.payload)

    },
    [deletePEOReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("PEOReportDocument delete action.payload",action.payload)
    },
    [updatePEOReportDocument.fulfilled]: (state, action) => {
    //   const result = state.HTMLTemplates.filter(HTMLTemplate => HTMLTemplate.id !== action.payload.id);
    //   // console.log("HTMLTemplate id from filter from slice",HTMLTemplate.id)
    //   result.push(action.payload)
    //   state.HTMLTemplate = result
      console.log("PEOReportDocument update action.payload",action.payload)

    },

  },

})


export default peoReportDocumentsSlice.reducer


