import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  actionsApprovals: [],
  actionsApprovalsStatus: 'idle',
  error: null,
}
export const fetchActionsApprovals = createAsyncThunk(
  'ActionApproval/fetchActionApproval',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/actionApprovalProcess/`, {
        headers: { Authorization: `token ${token}` },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const ActionApprovalSlice = createSlice({
  name: 'actionApproval',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchActionsApprovals.pending]: (state, action) => {
      state.actionsApprovalsStatus = 'loading'
    },
    [fetchActionsApprovals.fulfilled]: (state, action) => {
      state.actionsApprovalsStatus = 'succeeded'
      state.actionsApprovals = action.payload
    },
    [fetchActionsApprovals.rejected]: (state, action) => {
      state.actionsApprovalsStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },
  },
})
export default ActionApprovalSlice.reducer
