import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import { notification } from 'antd';

import MoreVert from "@material-ui/icons/MoreVert";
import PLOIndicatorsAndCLOsMapModal from "./PLOIndicatorsAndCLOsMapModal";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal"
import ErrorModal from "./ErrorModal"

import { deletePloIndicator } from "../../reduxToolkit/features/PLOIndicator/PLOIndicatorSpecSlice";
import { fetchPloById } from "../../reduxToolkit/features/PLO/PLOSpecSlice";

import { useTranslation } from "react-i18next";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));


export default function PLOIndicatorDropDownButton(props) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  console.log("props", props.ploInd);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2
    });
  };

  const dispatch = useDispatch();

  const handleDeletePloIndicator = async (id) => {
    dispatch(deletePloIndicator(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log('originalPromiseResult', originalPromiseResult)
        dispatch(fetchPloById(props.PLOId));
        openNotificationWithIcon('success', `Deleted`)

      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!props.ploPage && <PLOIndicatorsAndCLOsMapModal
          handleDropDownButtonClose={handleClose}
          ploInd={props.ploInd}
          PLOId={props.PLOId}
          programId={props.programId}
        />}
        {props.ploPage && <MenuItem
          onClick={() => {
            handleOpenDeleteModal(); handleClose();
          }}>

          <ListItemText primary="Delete" />
        </MenuItem>}
      </StyledMenu>
      <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
      <SuperGeneralDeleteModal
        delete_Element={handleDeletePloIndicator}
        Element_ID={props.ploInd.id}
        open={open}
        handleClose={handleCloseDeleteModal}

      />
    </div>
  );
}
