import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch, useSelector } from 'react-redux'

import { addNewCourseAssessmentMethod } from 'reduxToolkit/features/courseAssessmentMethod/courseAssessmentMethodSpecSlice'

// core components
/// test
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Box from '@mui/material/Box'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import 'assets/css/styles.css'
import clsx from 'clsx'
import 'antd/dist/antd.css'
import MenuItem from '@material-ui/core/MenuItem'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchAssessmentQuestionsMethods } from 'reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import {
  DataGrid,
  GridRowModes,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import moment from 'moment'
import { fetchCloById } from 'reduxToolkit/features/CLO/CloSpecSlice'

import { useTranslation } from 'react-i18next'
import { Form, Input, Select, notification } from 'antd'
import ErrorModal from '../../../Components/ErrorModal'
import { addNewQuestion } from 'reduxToolkit/features/assessmentMethod/QuestionSlice'
import { withStyles } from '@material-ui/core/styles'
import { fetchCourseById } from 'reduxToolkit/features/course/CoursesSlice.js'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import AddIcon from '@material-ui/icons/Add'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import { getGroupsByAssessmentMethodAndCourse } from 'reduxToolkit/features/group/GroupSlice'
import { addNewCourseInstructor } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { fetchCourseInstructorById } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import { MediaBaseUrl } from 'assets/utils/accessToken'
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))
export default function AddAssessmentMethodInstructors(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [instructor_ids, setInstructor_id] = React.useState([])
  const [group_id, setGroup_id] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [submitData, setSubmitData] = useState([])
  const [data, setdata] = useState(
    props.selectedRowData && props.selectedRowData,
  )
  const Rows = []
  const { Option } = Select
  const [anchorEl, setAnchorEl] = React.useState(null)

  var lst = []
  let selected_list = []

  useEffect(() => {
    let obj = {
      course_id: props.course_id,
      assessmentMethod_id: props.Assessmentid,
      semester_id: localStorage.getItem('semester_id'),
    }
    dispatch(getGroupsByAssessmentMethodAndCourse(obj))
    
    dispatch(fetchStaff())
  }, [dispatch])
  const persons = useSelector((state) => state.user.staffList)
  const groups = useSelector((state) => state.group.groupAssessments)
  //console.log(groups, 'groups')
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitData([])
  }
  const onChangeGroup_id = (value) => {
    setGroup_id(value)
  }
  const onChangeInstructor = (value) => {
    setInstructor_id(value)
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
      zIndex: 1000,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    // message.error('Submit failed!');
  }



  const onFinish = async (values) => {
    let assessmentMethod_id = props.Assessmentid
    let course_id = props.course_id
    let obj = {
      exam_id: assessmentMethod_id,
      course_id,
    }
    const resultAction = await dispatch(
      addNewCourseInstructor({
        ...values,
        assessmentMethod_id,
        course_id,
      }),
    )
    if (addNewCourseInstructor.fulfilled.match(resultAction)) {
      
      openNotificationWithIcon('success', `Add successfully`)
      dispatch(fetchCourseInstructorById(obj))
      form.resetFields()
      handleClose()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      }
    }
  }

  //   function onChangeAssessmentMethod_id(value) {
  //     setAssessmentMethod_id(value);
  //     setClo_ids(props.cloId);
  //     setPercentage_note(0);
  //     setDate_of_input(moment().format("yyyy-MM-DD HH:mm:ss"));
  //   }

  /************************************************* */

  ////////////////////////////////////

  //   const handleSubmit = () => {
  //     let labInst = submitData;
  //     var isReady = labInst.some((item) => {
  //       return item.group_code === undefined || item.group_code === "";
  //     });
  //     setReady(isReady);
  //    if (
  //       !isReady
  //     ) {
  //       onFinish();
  //     } else {
  //       setMessage("Groupe code required !!");
  //       console.log("Groupe code required !!");
  //     }

  //   };

  const id = open ? 'simple-popover' : null
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  console.log('group', groups)
  return (
    <div>
      <Button color="primary" onClick={handleClickOpen} startIcon={<AddIcon />}>
        {t('Add')}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Instructors and groups')}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer justifyContent="center">
            <GridItem>{props.assessmentQuestionsMethod_name}</GridItem>
            <GridItem xs={4} sm={4} md={12}>
              <Form
                name="basic"
                layout="vertical"
                //   labelCol={{ span: 8 }}
                //   wrapperCol={{ span: 16 }}
                //   initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                //   autoComplete="off"
                scrollToFirstError
              >
                <GridContainer justifyContent="center">
                  <GridItem>
                    <Form.Item
                      label={t('Instructors')}
                      name="instructor_ids"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the instructor',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        name="instructor_ids"
                        value={instructor_ids}
                        dropdownStyle={{ zIndex: 10000 }}
                        onChange={(val) => onChangeInstructor(val)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }

                        //  defaultValue={props.selectedRowDetails.labInstructor_ids}
                      >
                        {persons &&
                          persons.map((person) => (
                            <Option key={person.id} value={person.id}>
                              {!person?.person_details[0]?.avatar ? (
                                <span
                                  style={{
                                    backgroundColor: 'rgb(143, 102, 130)',
                                    width: '23px',
                                    height: '23px',
                                    color: 'white',
                                    borderRadius: '50%',
                                    paddingLeft: '7px',
                                    marginRight: '15px',
                                    display: 'inline-block',
                                    lineHeight: '23px',
                                  }}
                                >
                                  {person?.person_details[0]?.first_name
                                    .charAt(0)
                                    .toUpperCase() + '     '}
                                </span>
                              ) : (
                                <img
                                  style={{ width: '20px', height: '20px' }}
                                  src={`${MediaBaseUrl}${person?.person_details[0]?.avatar}`}
                                />
                              )}
                              {` ${
                                person &&
                                person.person_details &&
                                person.person_details[0] &&
                                person.person_details[0].first_name
                              } 
                          ${' '}
                          ${
                            person &&
                            person.person_details &&
                            person.person_details[0] &&
                            person.person_details[0].last_name
                          }`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={t('Groups')}
                      name="group_id"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the group name',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        showArrow
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        name="group_id"
                        value={group_id}
                        dropdownStyle={{ zIndex: 10000 }}
                        onChange={(val) => onChangeGroup_id(val)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        //  defaultValue={props.selectedRowDetails.labInstructor_ids}
                      >
                        {groups &&
                          groups.map((group, key) => (
                            <Option key={group.id} value={group.id}>
                              {`${group.name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose()
                            }}
                          >
                            {t('Cancel')}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t('Save')}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
