import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { Form, Input, notification, Select } from 'antd'
import Button from 'components/CustomButtons/Button.js'
import { Link, useHistory } from 'react-router-dom'
import { addNewAuthority } from '../../../../reduxToolkit/features/AccreditationAuthority/AuthoritySlice'
import { unwrapResult } from '@reduxjs/toolkit'
import ErrorModal from 'views/Components/ErrorModal'

const { TextArea } = Input
const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 14,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const AddAuthorityConfig = () => {

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const onFinish = async (values) => {
    try {
      const resultAction = await dispatch(addNewAuthority(values))
      unwrapResult(resultAction)
      handleClose()
      form.resetFields()
      openNotificationWithIcon('success', `Added successfully`)
    } catch (err) {
    }
  }

  const handleClose = () => {
    history.push(`/citrine/configuration/others/Authority`)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  return (
    <div>
      {' '}
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/Authority`}>
                <span> {t('Authority Page')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/AddAuthorityConfig`}>
                <span> {t('Add Authority')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <br />
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name.',
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the description.',
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="description..." />
                </Form.Item>
                <br />

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default AddAuthorityConfig
