import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  colleges: [],
  collegesStatus: 'idle',
  collegeStatus: 'idle',
  error: null,
  college:null,
  id:null
}


export const fetchColleges = createAsyncThunk('college/fetchColleges', async (_,{rejectWithValue}) => {
  try {
    const token=localStorage.getItem('token')
    const {data} = await axios.get(BaseUrl+'/college/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
   

    return data
  } catch (err) {
    let error = err; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
    
  }
  
  })

  export const fetchCollegeById = createAsyncThunk('college/fetchCollegeById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/college/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     console.log(response.data,'response.data')

    return response.data
  })
  export const addNewCollege = createAsyncThunk(
    'college/addNewCollege',
    async (initialCollege) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/college/create/`,initialCollege ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const collegeUpdate = createAsyncThunk(
    'college/collegeUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/college/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedCollege')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the college: ', error)

}
    }
  )
  export const deleteCollege = createAsyncThunk('college/deleteCollege', async (id,{rejectWithValue}) => {
    try {
      const token=localStorage.getItem('token')
    const {data} = await axios.delete(BaseUrl+`/college/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     

    return data
    } catch (err) {
      let error = err; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
    
  })
const  collegeSlice = createSlice({
  name: 'college',
  initialState,
  extraReducers: {
  
    [fetchColleges.pending]: (state, action) => {
        state.collegesStatus = 'loading'
      },
      [fetchColleges.fulfilled]: (state, action) => {
        state.collegesStatus = 'succeeded'
        // Add any fetched posts to the array
        state.colleges = action.payload
      },
      [fetchColleges.rejected]: (state, action) => {
        state.collegesStatus = 'failed'
        state.error = action.payload
      },

      [fetchCollegeById.pending]: (state, action) => {
        state.collegeStatus = 'loading'
      },
      [fetchCollegeById.fulfilled]: (state, action) => {
        state.collegeStatus = 'succeeded'

        // Add any fetched posts to the array
        state.college = action.payload
      },
      [fetchCollegeById.rejected]: (state, action) => {
        state.collegeStatus = 'failed'
        state.error = action.payload
      },
    [addNewCollege.fulfilled]: (state, action) => {
      state.colleges.push(action.payload)
    },
    [collegeUpdate.fulfilled]: (state, action) => {
      console.log(action) 
    },
    [deleteCollege.fulfilled]: (state, action) => {
     console.log(action)
    },
  },

  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
    collegeAdded: {
      reducer(state, action) {
        state. colleges.push(action.payload)
      },
      prepare(name,university_id, mission, phoneNumber, email, director, image, adress, city, postalCode) {
        // omit prepare logic
      }
    },
    collegeUpdated(state, action) {
      const { id,name, mission, phoneNumber, email, director, image, adress, city, postalCode } = action.payload
      const existingCollege = state.colleges.find(college => college.id === id)
      if (existingCollege) {
        existingCollege.name = name
     //   existingCollege.university = university
        existingCollege.mission = mission
        existingCollege.phoneNumber = phoneNumber
        existingCollege.email = email
        existingCollege.director = director
        existingCollege.image = image
        existingCollege.adress = adress
        existingCollege.city = city
        existingCollege.postalCode = postalCode


      }
    }
  }
})

export const { collegeAdded, collegeUpdated, getId } = collegeSlice.actions

export default collegeSlice.reducer

export const selectAllColleges = state => state.college.colleges

// export const selectCollegeBySlug = (state, slug) =>
//   state.college.colleges.find(college => college.slug === slug)