import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const Institution = ({ data }) => {
  return (
    <>
      <h3> Institutional Summary: The Institution</h3>
      <br />
      <p>
        <strong>The institution's name and address :</strong>
        {` ${data.institution.name},  ${data.institution.address}`}
      </p>
      <p>
        <strong>The name and title of the chief executive officer:</strong>
        {` ${data.CEO.name},  ${data.CEO.title}`}
      </p>
      <p>
        <strong>
          The name and title of the person submitting the Self-Study Report:
        </strong>
        {` ${data.SSRSubmitter.name},  ${data.SSRSubmitter.title}`}
      </p>
      <p></p>
      <p>
        <strong>
          The organizations by which the institution is now accredited:
        </strong>
        {` ${data.accredition.organazation}`}
      </p>
      <p>
        <strong>The date of the initial accreditation evaluation:</strong>
        {` ${data.accredition.initialAccreditationEvaluationDate}`}
      </p>
      <p>
        <strong>The date of the most recent accreditation evaluation:</strong>
        {` ${data.accredition.mostRecentAccreditationEvaluationDate}`}
      </p>
    </>
  );
};

export default Institution;
