import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
/******Import native component*****/
import { SchoolRounded } from '@material-ui/icons'
import { DataGrid } from '@mui/x-data-grid'
import { Breadcrumb, Tabs } from 'antd'
import 'antd/dist/antd.css'
/******Import custom component*****/
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
/******Import redux tools*****/
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
/******Import APIs*****/
import {
  fetchQuestions_ByCloId,
  fetchTuplesNoteByQuestions,
  fetchTuplesScoreStudentByCloQuestions,
  fetchTuplesWeight_MarksByClos,
} from '../../../../reduxToolkit/features/assessment/AssessmentSlice'
import { fetchCloById } from '../../../../reduxToolkit/features/CLO/CloSlice'
import { fetchCourseById } from '../../../../reduxToolkit/features/course/CoursesSlice'
import CloSurveyQuestionConfig from './CloSurveyQuestionConfig'
import {
  blackColor,
  cardTitle,
  grayColor,
  hexToRgb,
  primaryColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js'
const ResultCalculClosQuestionNotesFaculty = ({ match }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { id, clo_id } = match.params
  const object = {
    clo_id: clo_id,
    id: id,
  }
  const { TabPane } = Tabs
  const [columns, setColumns] = useState([
    {
      field: 'student_identification',
      headerName: 'Student ID',
      width: 200,
    },
  ])
  const [columns1, setColumns1] = useState([
    {
      field: 'weight_Marks',
      headerName: 'Weight/Marks',
      width: 200,
      name: 'weight_Marks',
    },
  ])
  const [columns3, setColumns3] = useState([
    {
      field: 'student_identification',
      headerName: 'Student ID',
      width: 200,
    },
  ])
  /****************Manipulate STUDENT && WEIGHT/MARKS Columns******************/

  useEffect(() => {
    let arr = []
    dispatch(fetchQuestions_ByCloId(object))
      .then(unwrapResult)
      .then((ClosQuestions) => {
        console.log(ClosQuestions)
        if (ClosQuestions !='You do not have permission') {
          ClosQuestions &&
            ClosQuestions.map((item, id) => {
              let obj = {
                field: `${item}`,
                headerName: `${item}`,
                flex: 1,
              }
              arr = [...arr, obj]

              setColumns([...columns, ...arr])
              setColumns1([...columns1, ...arr])
            })
        } else {
          setColumns([])
          setColumns1([])
        }
      })
  }, [dispatch])
  /********************************************************************************/
  /****************Manipulate SCORE Columns****************************************/

  useEffect(() => {
    let arr = []
    let lastArray = []

    dispatch(fetchCloById(clo_id))
      .then(unwrapResult)
      .then((clo) => {
        let obj = {
          field: `score`,
          headerName: `Score Clo (${clo.code}) `,
          flex: 1,
        }
        arr = [...arr, obj]
        setColumns3([...columns3, ...arr])
      })
  }, [dispatch])
  /******************************************************************************/

  const course = useSelector((state) => state.course.course)
  /************************************************************ */
  const NoteByQuestions = useSelector(
    (state) => state.assessment.NoteByQuestions,
  )
  const TuplesNoteByQuestionsStatus = useSelector(
    (state) => state.assessment.TuplesNoteByQuestionsStatus,
  )
  /**************************************************************** */
  const WeightMarksByClos = useSelector(
    (state) => state.assessment.WeightMarksByClos,
  )
  console.log(WeightMarksByClos)
  const TuplesWeight_MarksByClosStatus = useSelector(
    (state) => state.assessment.TuplesWeight_MarksByClosStatus,
  )
  /**************************************************************** */
  const ScoreStudentByCloQuestions = useSelector(
    (state) => state.assessment.ScoreStudentByCloQuestions,
  )
  const TuplesScoreStudentByCloQuestionsStatus = useSelector(
    (state) => state.assessment.TuplesScoreStudentByCloQuestionsStatus,
  )
  /*******************************************************************
   * Render all notes questions of students arranged by group
   ******************************************************************/
  useEffect(() => {
    dispatch(fetchCourseById(id))
    dispatch(fetchTuplesNoteByQuestions(object))
    dispatch(fetchTuplesWeight_MarksByClos(object))
    dispatch(fetchTuplesScoreStudentByCloQuestions(object))
  }, [dispatch])

  let studentList
  if (TuplesNoteByQuestionsStatus === 'loading') {
    studentList = <div className="loader">Loading...</div>
  } else if (TuplesNoteByQuestionsStatus === 'succeeded') {
    if (NoteByQuestions === 'You do not have permission') {
      studentList = <h5>{t('You do not have permission!')}</h5>
    } else {
      studentList = NoteByQuestions.map((items, key) => (
        <DataGrid
          key={key}
          autoHeight
          rows={items.rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      ))
    }
  } else if (TuplesNoteByQuestionsStatus === 'failed') {
    studentList = <div>{'failed'}</div>
  }
  /********************************************************************/
  /*******************************************************************
   * Render weights and marks of questions
   ******************************************************************/
  let weights_Marks
  if (TuplesWeight_MarksByClosStatus === 'loading') {
    weights_Marks = <div className="loader">Loading...</div>
  } else if (TuplesWeight_MarksByClosStatus === 'succeeded') {
    if (WeightMarksByClos === 'You do not have permission') {
      weights_Marks = <h5></h5>
    } else {
      weights_Marks = WeightMarksByClos?.map((items, key) => (
        <DataGrid
          key={key}
          autoHeight
          rows={items?.rows}
          columns={columns1}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      ))
    }
  } else if (TuplesWeight_MarksByClosStatus === 'failed') {
    weights_Marks = <div>{'failed'}</div>
  }

  /********************************************************************/
  /*******************************************************************
   * Render Score arranged by group
   ******************************************************************/
  let score
  if (TuplesScoreStudentByCloQuestionsStatus === 'loading') {
    score = <div className="loader">Loading...</div>
  } else if (TuplesScoreStudentByCloQuestionsStatus === 'succeeded') {
    if (ScoreStudentByCloQuestions === 'You do not have permission') {
      score = <h5></h5>
    } else {
      score = ScoreStudentByCloQuestions.map((items, key) => (
        <DataGrid
          key={key}
          autoHeight
          rows={items.rows}
          columns={columns3}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      ))
    }
  } else if (TuplesScoreStudentByCloQuestionsStatus === 'failed') {
    score = <div>{'Fetch API call failed'}</div>
  }
  /********************************************************************/

  return (
    <div>
      <div>
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/UserCoursesListAssessment/`}>
                {t('Courses List ')}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/closQuestions_list/course/${id}`}>
                {t('Clos Questions List')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/faculty/result_calcul_Clos_Questions/course/${id}/clo/${clo_id}`}
                style={{ color: 'orange' }}
              >
                {t('Students By Questions')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card justify="space-between">
              <CardHeader
                color="info"
                icon
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardIcon color="primary" style={{ height: '65px' }}>
                  <SchoolRounded />
                </CardIcon>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <div>
                      <Link to={`/citrine/programs_assessment`}>
                        <h4
                          style={{
                            color: '#8F6682',
                            height: '13px',
                            fontSize: '14px',
                            marginRight: '20px',
                          }}
                        >
                          <strong>
                            {course &&
                              course.program_detail &&
                              course.program_detail.map((el) => el.code)}
                          </strong>
                        </h4>
                      </Link>
                      <Link to={`/citrine/courses_assessment/${id}`}>
                        <h4
                          style={{
                            color: '#6C7B9D',
                            height: '13px',
                            fontSize: '14px',
                            marginRight: '20px',
                          }}
                        >
                          <strong>
                            {course && course.code && course.code}
                          </strong>
                        </h4>
                      </Link>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <div>
                      <Link to={`/citrine/programs_assessment`}>
                        <h4
                          style={{
                            color: '#8F6682',
                            fontSize: '14px',
                            height: '13px',
                          }}
                        >
                          <strong>
                            {course &&
                              course.program_detail &&
                              course.program_detail.map(
                                (el) => el.program_degree_name,
                              )}
                          </strong>
                        </h4>
                      </Link>
                      <Link to={`/citrine/courses_assessment/${id}`}>
                        <h4
                          style={{
                            color: '#6C7B9D',
                            fontSize: '14px',
                            height: '13px',
                          }}
                        >
                          <strong>
                            {course && course.title && course.title}
                          </strong>
                        </h4>
                      </Link>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <div style={{ display: 'flex' }}>
                      <h4
                        style={{
                          color: 'grey',
                          fontSize: '14px',
                          height: '13px',
                          marginRight: '20px',
                        }}
                      ></h4>

                      <h4
                        style={{
                          color: 'grey',
                          fontSize: '14px',
                          height: '13px',
                        }}
                      >
                        {/* <strong> {AssessmentName}</strong> */}
                      </h4>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <div
                style={{
                  padding: '10px',
                  borderRadius: '0px',
                  color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
                  background: whiteColor,
                  width: '100%',
                  boxShadow:
                    '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '0',
                  wordWrap: 'break-word',
                  fontSize: '.875rem',
                  // borderRight: "1px solid lightgray"
                }}
              >
                <Tabs type="card">
                  <TabPane tab={t('Students')} key="1">
                    <Card>
                      <CardBody>{studentList}</CardBody>
                    </Card>
                    <Card>
                      <CardBody>{score}</CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tab={t('Configurations')} key="2">
                    <CloSurveyQuestionConfig weights_Marks={weights_Marks} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
export default ResultCalculClosQuestionNotesFaculty
