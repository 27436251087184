// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  primaryColor
} from "../../../../../assets/jss/material-dashboard-pro-react";
import { deleteAnnualProgramAnalysis, fetchAnnualProgramAnalysissByProgram } from "../../../../../reduxToolkit/features/annualProgramAnalysis/annualProgramAnalysisSlice";
import EditAnalysisOfProgram from "../../../../Components/EditAnalysisOfProgram";
import useResearchs from "../../../../Components/useResearchs";



const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const headCells = [
  { id: "Strengths", label: "Strengths" },
  { id: "Areas for Improvement", label: "Areas for Improvement" },
  { id: "Priorities for Improvement", label: "Priorities for Improvement" },
  // { id: "Compensating Actions", label: "Compensating Actions" },
  { id: "delete", label: "" },
];
// const classes = useStyles();
export default function AnalysisOfProgramStatistics(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const dispatch = useDispatch();
  let analysisType = "StudentsStatistics"
  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useResearchs(
    records,
    headCells,
    filterFn
  );
  let program_id =
    props.AnnualProgramReport &&
    props.AnnualProgramReport.programs &&
    props.AnnualProgramReport.programs[0] &&
    props.AnnualProgramReport.programs[0].id;
  let obj = {
    program_id,
    analysisType
  }
  const [array_analysis, setArray_analysis] = useState([]);

  useEffect(() => {
    dispatch(fetchAnnualProgramAnalysissByProgram(obj))
      .then(unwrapResult)
      .then((analysis) => {
        setArray_analysis([...analysis]);
      });
  }, []);

  const analysis = useSelector(
    (state) => state.annualProgramAnalysis.annualProgramAnalysissByProgram
  );
  const fetchAnalysis = () => {
    dispatch(fetchAnnualProgramAnalysissByProgram(obj))
      .then(unwrapResult)
      .then((analysis) => {
        setArray_analysis([...analysis]);
      });
  }

  useEffect(() => {
    dispatch(fetchAnnualProgramAnalysissByProgram(obj));
  }, []);

  const res = useSelector(
    (state) => state.annualProgramAnalysis.annualProgramAnalysissByProgram
  );
  const DeleteFunction = (id) => {
    dispatch(deleteAnnualProgramAnalysis(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchAnnualProgramAnalysissByProgram(obj));
      });
  };
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          {/* <AddAnnualProgramAnalysisModal
            type={analysisType}
            program_id={
              props.AnnualProgramReport &&
              props.AnnualProgramReport.programs &&
              props.AnnualProgramReport.programs[0] &&
              props.AnnualProgramReport.programs[0].id
            }
          /> */}
        </GridItem>
      </GridContainer>
      <Card style={{ marginRight: "10px" }}>
        <CardBody>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={10}>
              <p style={{ height: "150px" }}>
                <strong style={{ color: primaryColor[0] }}>Strengths</strong> :
                <p>{array_analysis &&
                  array_analysis.map((item) => (
                    item.strength
                  ))}
                </p>
              </p>
              <p style={{ height: "150px" }}>  <strong style={{ color: primaryColor[0] }}>Priorities for improvement</strong> :
                <p>{array_analysis &&
                  array_analysis.map((item) => (
                    item.priorities_for_improvement
                  ))}</p>
              </p>
              <p style={{ height: "150px" }}>  <strong style={{ color: primaryColor[0] }}>Areas for improvement</strong> :
                <p>{array_analysis &&
                  array_analysis.map((item) => (
                    item.areas_for_improvement
                  ))}</p>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} style={{ textAlign: "end" }}>
              <EditAnalysisOfProgram program_id={
                props.AnnualProgramReport &&
                props.AnnualProgramReport.programs &&
                props.AnnualProgramReport.programs[0] &&
                props.AnnualProgramReport.programs[0].id
              } fetchAnalysis={fetchAnalysis} type={analysisType} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {/* <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
        <TblHead />
        <TableBody>
          {res &&
            res.map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    borderLeft: "1px solid lightgray",
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      maxWidth: "200px",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item && item.strength}
                  </p>
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.priorities_for_improvement}
                </TableCell>
                <TableCell
                  className="multipleLine"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  {item && item.areas_for_improvement}
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <DeleteOutlined
                    onClick={() => DeleteFunction(item && item.id)}
                    style={{ color: "red" }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TblContainer> */}
    </div>
  );
}
