import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Breadcrumb } from 'antd'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { Form, Input, notification, Select } from 'antd'
import Button from 'components/CustomButtons/Button.js'
import { Link, useHistory,useParams } from 'react-router-dom'
import {updateStateNames,fetchStateNamesById} from 'reduxToolkit/features/StateNames/StateNamesSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import ErrorModal from 'views/Components/ErrorModal'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
const { TextArea } = Input
const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 14,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const EditStateNameConfigPage = () => {
    const {id} = useParams()
    const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { Option } = Select
  const [color, setColor] = useState()
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const onFinish = async (values) => {
    console.log({...values, color})
    dispatch(updateStateNames({...values,color, id}))
      .unwrap()
      .then((res) => {
        handleClose()
        form.resetFields()
        openNotificationWithIcon('success', `Updated successfully`)
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
      })
  }
  const handleClose = () => {
    history.push(`/citrine/configuration/others/StateNamePage`)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleCloseColor = () => {
    setDisplayColorPicker(false )
  };

  const handleChange = (color) => {
    console.log(color)
    setColor(color.hex)
  }
  const styles = reactCSS({
    default: {
      color: {
        width: '100px',
        height: '15px',
        borderRadius: '2px',
        background: `${color}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })
  useEffect(() => {
    dispatch(fetchStateNamesById(id))
      .unwrap()
      .then((res) => {
        setColor(res.color)
        form.setFieldsValue({
          name: res.name
          //color: res.color
        })
      })
  }, [])
  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/configuration/others/StateNamePage`}
              >
                <span>{t('State Name Page')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span> {t('Update')} </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <br />
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name.',
                    },
                  ]}
                >
                  <Input maxLength={300} />
                </Form.Item>
                <Form.Item
                  name="color"
                  label="Color"
                 
                >
                  <div>
                    <div style={styles.swatch} onClick={handleClick}>
                      <div style={styles.color} />
                    </div>
                    {displayColorPicker ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={handleCloseColor} />
                        <SketchPicker color={color} onChange={handleChange} />
                      </div>
                    ) : null}
                  </div>
                  {/* <Input maxLength={30} /> */}
                </Form.Item>

                <br />

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default EditStateNameConfigPage