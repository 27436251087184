import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, notification, Select } from "antd";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAcademicYearById, fetchAcademicYears
} from "../../reduxToolkit/features/academicYear/AcademicYearsSlice";
import { fetchColleges } from "../../reduxToolkit/features/college/CollegesSlice";
import { fetchDepartments } from "../../reduxToolkit/features/department/DepartmentsSlice";
import { fetchGroupStatesByReport } from "../../reduxToolkit/features/groupState/GroupStateSlice";
import {
  addNewKpiReport,
  fetchKpiReports
} from "../../reduxToolkit/features/KpiReport/KpiReportSlice";
import {
  fetchPrograms
} from "../../reduxToolkit/features/program/ProgramsSlice";
import { fetchUniversities } from "../../reduxToolkit/features/university/UniversitiesSlice";
import ErrorModal from 'views/Components/ErrorModal'
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useButtonStyles = makeStyles(buttonStyles);
export default function KPIReportModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles();
  const semester_id = JSON.parse(localStorage.getItem('semester_id'));
  console.log("semester_id", semester_id)
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { Option, OptGroup } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const [semesters, setSemesters] = React.useState([]);


  const [semesterPrograms, setSemesterPrograms] = React.useState([]);
  const [listProgramsBySemester, setListProgramsBySemester] = React.useState();

  console.log(listProgramsBySemester)

  const [isProgramType, setIsProgramType] = React.useState(false);
  const [isUniversityType, setIsUniversityType] = React.useState(false);
  const [isCollegeType, setIsCollegeType] = React.useState(false);
  const [isDepartmentType, setIsDepartmentType] = React.useState(false);


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  useEffect(() => {
    setIsProgramType(false);
    setIsUniversityType(false);
    setIsCollegeType(false);
    setIsDepartmentType(false);
    dispatch(fetchAcademicYears());
    dispatch(fetchUniversities());
    if (semester_id) { dispatch(fetchPrograms(semester_id)); }
    dispatch(fetchColleges());
    dispatch(fetchDepartments());
  }, []);

  const academicYears = useSelector(
    (state) => state.academicYear.academicYears
  );
  const KpiReports = useSelector(
    (state) => state.KpiReport.KpiReports
  );

  console.log(KpiReports);

  const universities = useSelector((state) => state.university.universities);

  const colleges = useSelector((state) => state.college.colleges);
  const departments = useSelector((state) => state.department.departments);

  const { programs } = useSelector((state) => state.program);

  console.log(programs);

  const onChangeYear = (value) => {
    console.log(value)
    dispatch(fetchAcademicYearById(value))
      .unwrap()
      .then((year) => {
        setSemesters([year.semesters]);
      })
      .catch((rejectedValueOrSerializedError) => {
        //setHandledError(rejectedValueOrSerializedError)
        //handleOpenErrorModal()
      })

    console.log(semesters, "semesters");
  };
  const onChangeSemester = (value) => {
    console.log(value)
    dispatch(fetchPrograms(value))
      .then(unwrapResult)
      .then((programs) => {
        console.log(programs)
        setSemesterPrograms([programs]);
        setListProgramsBySemester(programs.results)
      });

    console.log(semesters, "semesters");
  };

  const year = useSelector((state) => state.academicYear.academicYear);

  const onChangeTypeKPI = (value) => {
    //console.log(value, "Type");
    if (value && value === "KpiProgramReport") {
      setIsProgramType(true);
      setIsUniversityType(false);
      setIsCollegeType(false);
      setIsDepartmentType(false);
    } else if (value && value === "KpiUniversityReport") {
      setIsProgramType(false);
      setIsUniversityType(true);
      setIsCollegeType(false);
      setIsDepartmentType(false);
    } else if (value && value === "KpiCollegeReport") {
      setIsProgramType(false);
      setIsUniversityType(false);
      setIsCollegeType(true);
      setIsDepartmentType(false);
    } else if (value && value === "KpiDepartmentReport") {
      setIsProgramType(false);
      setIsUniversityType(false);
      setIsCollegeType(false);
      setIsDepartmentType(true);
    }
  };

  const onFinish = async (values) => {
    // dispatch(addNewKpiReport(values))
    //   .unwrap()
    //   .then((originalPromiseResult) => {
    //     openNotificationWithIcon("success", `Add successfully`)
    //     handleClose()
    //     dispatch(fetchKpiReports())
    //      .unwrap()
    //      .then((kpiReports) => {
    //        props.KpiWorkFlow(kpiReports);
    //      })
    //      .catch((err) => {
    //       setHandledError(err)
    //       handleOpenErrorModal()
    //     })
    //     dispatch(fetchGroupStatesByReport('KPIReport'))
    //     form.resetFields();
    //     setIsProgramType(false);
    //    setIsUniversityType(false);
    //    setIsCollegeType(false);
    //    setIsDepartmentType(false);
    //   })
    //   .catch((rejectedValueOrSerializedError) => {
    //     setHandledError(rejectedValueOrSerializedError)
    //     handleOpenErrorModal()
    //   })


    const resultAction = await dispatch(addNewKpiReport(values));
    if (addNewKpiReport.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      handleClose();
      dispatch(fetchKpiReports())
        .then(unwrapResult)
        .then((kpiReports) => {
          props.KpiWorkFlow(kpiReports);
        });
      dispatch(fetchGroupStatesByReport('KPIReport'));
      form.resetFields();
      setIsProgramType(false);
      setIsUniversityType(false);
      setIsCollegeType(false);
      setIsDepartmentType(false);
    } else {
      if (resultAction.payload) {
        dispatch(fetchGroupStatesByReport('KPIReport'));
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        dispatch(fetchGroupStatesByReport('KPIReport'));
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsProgramType(false);
    setIsUniversityType(false);
    setIsCollegeType(false);
    setIsDepartmentType(false);
  };

  const classes = useStyles();
  console.log(listProgramsBySemester)
  return (
    <div>
      <Button
        color="primary"

        onClick={(event) => {
          handleClickOpen(event);
          //   props.handleDropDownButtonClose();
        }}
      >
        <AddIcon style={{ color: "white", fontSize: "15px" }} />{" "}
        <span>Create</span>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Create KPI Report")}
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="academicYear_id"
                  label="Academic Year"
                  rules={[
                    {
                      required: true,
                      message: "Please input the academic_year field.",
                    },
                  ]}
                >
                  <Select
                    showArrow="true"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    onChange={onChangeYear}
                  >
                    {academicYears &&
                      academicYears.map((Year) => (
                        <Option key={Year.id} value={Year.id}>
                          {Year.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="semester_id"
                  label="Semester"
                  rules={[
                    {
                      required: true,
                      message: "Please input the semester_id field.",
                    },
                  ]}
                >
                  <Select
                    showArrow="true"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    onChange={onChangeSemester}
                  >
                    {year &&
                      year.semesters &&
                      year.semesters.map((semester) => (
                        <Option key={semester.id} value={semester.id}>
                          {semester.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input the type field.",
                    },
                  ]}
                >
                  <Select
                    showArrow="true"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    onChange={onChangeTypeKPI}
                  >
                    <Option value={"KpiProgramReport"}>
                      KPI Program Report
                    </Option>
                    <Option value={"KpiDepartmentReport"}>
                      KPI Department Report
                    </Option>
                    <Option value={"KpiCollegeReport"}>
                      KPI College Report
                    </Option>

                    <Option value={"KpiUniversityReport"}>
                      KPI University Report
                    </Option>

                    <Option value={"AllKpiReport"}>KPI Report</Option>
                  </Select>
                </Form.Item>

                {isProgramType && (

                  <Form.Item
                    name="program_id"
                    label="Program"
                    rules={[
                      {
                        required: true,
                        message: "Please input the program_id field.",
                      },
                    ]}
                  >
                    <Select
                      showArrow="true"
                      style={{ width: 400 }}
                      dropdownStyle={{ zIndex: 10000 }}
                      onChange={onChangeYear}
                    >
                      {programs && programs.map((program) => <Option key={program.id} value={program.id}>{program.program_degree_name} {program.version}</Option>)}
                      {/* {semesterPrograms &&
                        semesterPrograms.map((program) => (
                          <Option key={program.id} value={program.id}>
                            {program.program_degree_name} / V{program.version}
                          </Option>
                        ))} */}
                    </Select>
                  </Form.Item>
                )}
                {isUniversityType && (
                  <Form.Item
                    name="university_id"
                    label="University"
                    rules={[
                      {
                        required: true,
                        message: "Please input the university field.",
                      },
                    ]}
                  >
                    <Select
                      showArrow="true"
                      style={{ width: 400 }}
                      dropdownStyle={{ zIndex: 10000 }}
                      onChange={onChangeYear}
                    >
                      {universities &&
                        universities.map((university) => (
                          <Option key={university.id} value={university.id}>
                            {university.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}

                {isCollegeType && (
                  <Form.Item
                    name="college_id"
                    label="College"
                    rules={[
                      {
                        required: true,
                        message: "Please input the College field.",
                      },
                    ]}
                  >
                    <Select
                      showArrow="true"
                      style={{ width: 400 }}
                      dropdownStyle={{ zIndex: 10000 }}
                      onChange={onChangeYear}
                    >
                      {colleges &&
                        colleges.map((college) => (
                          <Option key={college.id} value={college.id}>
                            {college.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {isDepartmentType && (
                  <Form.Item
                    name="department_id"
                    label="Department"
                    rules={[
                      {
                        required: true,
                        message: "Please input the Department field.",
                      },
                    ]}
                  >
                    <Select
                      showArrow="true"
                      style={{ width: 400 }}
                      dropdownStyle={{ zIndex: 10000 }}
                    //onChange={onChangeYear}
                    >
                      {departments &&
                        departments.map((department) => (
                          <Option key={department.id} value={department.id}>
                            {department.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
