import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  ploIndicatorsStatus: 'idle',
  ploIndicators: [],
  ploIndicatorStatus: 'idle',
  ploIndicator: null,
  ScorePloIndicator: null,
  error: null,
}

export const fetchPloIndicators = createAsyncThunk('ploIndicator/fetchPloIndicators', async () => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + '/ploIndicators/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
  // console.log(response.data,'response.data')

  return response.data
})

export const fetchPloIndicatorById = createAsyncThunk('ploIndicator/fetchPloIndicatorById', async (id) => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + `/ploIndicators/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})

export const addNewPloIndicator = createAsyncThunk(
  'ploIndicator/addNewPloIndicator',
  async (initialPloIndicator) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(BaseUrl + `/ploIndicators/create/`, initialPloIndicator, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const fetchScorePloIndicator = createAsyncThunk(
  'ploIndicator/ftechScorePloIndicator',
  async (initialPloIndicator) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(BaseUrl + `/ploIndicators/score`, initialPloIndicator, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)
export const updatePloIndicator = createAsyncThunk(
  'ploIndicator/updatePloIndicator',
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/ploIndicators/${data.id}/edit/`, data.updatedPloIndicator, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatePloIndicator')
      //console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the plo: ', error)

    }
  }
)

export const deletePloIndicator = createAsyncThunk('ploIndicator/deletePloIndicator', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/ploIndicators/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err.response.data)
  }
})

const ploIndicatorsSlice = createSlice({
  name: 'ploIndicator',
  initialState,
  extraReducers: {

    [fetchPloIndicators.pending]: (state, action) => {
      state.ploIndicatorsStatus = 'loading'
    },
    [fetchPloIndicators.fulfilled]: (state, action) => {
      state.ploIndicatorsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ploIndicators = state.ploIndicators.concat(action.payload)
    },
    [fetchPloIndicators.rejected]: (state, action) => {
      state.ploIndicatorsStatus = 'failed'
      state.error = action.payload
    },



    [fetchScorePloIndicator.pending]: (state, action) => {
      state.ploIndicatorsStatus = 'loading'
    },
    [fetchScorePloIndicator.fulfilled]: (state, action) => {
      state.ploIndicatorsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ScorePloIndicator = action.payload
    },
    [fetchScorePloIndicator.rejected]: (state, action) => {
      state.ploIndicatorsStatus = 'failed'
      state.error = action.payload
    },




    [fetchPloIndicatorById.pending]: (state, action) => {
      state.ploIndicatorStatus = 'loading'
    },
    [fetchPloIndicatorById.fulfilled]: (state, action) => {
      state.ploIndicatorStatus = 'succeeded'

      // Add any fetched posts to the array
      state.ploIndicator = action.payload
    },
    [fetchPloIndicatorById.rejected]: (state, action) => {
      state.ploIndicatorStatus = 'failed'
      state.error = action.payload
    },

    [addNewPloIndicator.fulfilled]: (state, action) => {
      state.ploIndicators.push(action.payload)
    },

    [deletePloIndicator.fulfilled]: (state, action) => {
      console.log(action.payload)
    },
    [updatePloIndicator.fulfilled]: (state, action) => {
      console.log(action.payload)
    },



    PloIndicatorUpdated(state, action) {
      const { id, name, code, weight } = action.payload
      const existingPloIndicator = state.ploIndicators.find(plo => plo.id === id)
      if (existingPloIndicator) {
        existingPloIndicator.name = name
        existingPloIndicator.code = code
        existingPloIndicator.weight = weight


      }
    }
  },

})

export const { PloIndicatorAdded, PloIndicatorUpdated, getId } = ploIndicatorsSlice.actions

export default ploIndicatorsSlice.reducer

export const selectAllPloIndicators = state => state.ploIndicator.ploIndicators
