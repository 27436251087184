import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  students: [],
  student: null,
  studentsStatus: 'idle',
  studentStatus: 'idle',
}
export const fetchStudents = createAsyncThunk(
  'student/fetchStudents',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + '/student/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      //console.log(data)
      return data
    } catch (err) {
      console.log(err.response)
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const fetchStudentById = createAsyncThunk(
  'student/fetchStudentById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/student/${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      //console.log(data)
      return data
    } catch (err) {
      console.log(err.response)
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const deleteStudent = createAsyncThunk(
  'student/deleteStudent',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.delete(BaseUrl + `/student/${id}/delete/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return data
    } catch (err) {
      console.log(err.response)
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const addStudent = createAsyncThunk(
  'student/addStudent',
  async (studentObject, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.post(
        BaseUrl + `/student/addToGroup/`,
        studentObject,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)
export const updateStudent = createAsyncThunk(
  'student/updateStudent',
  async (studentObject, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.put(
        BaseUrl + `/student/${studentObject.id}/edit/`,
        studentObject,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return data
    } catch (err) {
      console.log(err.response)
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const studentSlice = createSlice({
  name: 'student',
  initialState,
  extraReducers: {
    [fetchStudents.fulfilled]:(state,action)=>{
      state.students = action.payload
    },
    [fetchStudentById.fulfilled]:(state,action)=>{
      state.student = action.payload
    }
  },
})

export default studentSlice.reducer
