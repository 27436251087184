import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  deleteAssessmentEvidenceStatus: 'idle',
  attachmentAssessmentEvidence: [],
  attachmentAssessmentEvidenceStatus: 'idle',
  assessmentById: [],
  assessmentMethods: [],
  assessmentQuestionsMethods: [],
  affectEvidenceTypeToAssessmentMethod: null,
  affectEvidenceTypeToAssessmentMethodStatus: 'idle',
  assessmentMethodsStatus: 'idle',
  assessmentQuestionsMethodsStatus: 'idle',
  assessmentMethodStatus: 'idle',
  deleteQuestionStatus: 'idle',
  AssessmentMethodClos: null,
  QuestionsByAssessmentMethodStatus: 'idle',
  QuestionsByAssessmentMethod: [],
  error: null,
  assessmentMethod: null,
  id: null,
  assessmentMethodsByCourseId: [],
  ExamByQuestionAndNotesStatus: 'idle',
  ExamByQuestionAndNotesMethod: []
};

export const createAssessmentMethod = createAsyncThunk("AssessmentMethods/addAssessmentMethods", async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.post(BaseUrl + `/assessmentMethod/generate/`, data,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );

    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

export const deleteMappingAssessmentMethod = createAsyncThunk("AssessmentMethods/deleteMappingAssessmentMethod", async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.post(BaseUrl + `/assessmentMethod/deleteMapping/`, data,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );

    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})
export const fetchAssessmentMethods = createAsyncThunk(
  'AssessmentMethods/fetchAssessmentMethods',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/assessmentMethod/all`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })

      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const fetchAssessmentMethodsByCourseId = createAsyncThunk(
  "AssessmentMethods/fetchAssessmentMethodsByCourseId",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/assessmentMethod/by_course/?course_id=${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      return response.data;
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const fetchAssessmentQuestionsMethods = createAsyncThunk(
  'AssessmentMethods/fetchAssessmentQuestionsMethods',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/examQuestion/calculationQuestionMethod/?course_id=${id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log('response.data from slice', response.data)
      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const GetAssessmentMethodById = createAsyncThunk(
  'AssessmentMethods/GetAssessmentMethodById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/assessmentMethod/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const DeleteAssessmentMethod = createAsyncThunk(
  'AssessmentMethods/DeleteAssessmentMethod',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(
        BaseUrl + `/assessmentMethod/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const fetchAssessmentMethodById = createAsyncThunk(
  'AssessmentMethods/fetchAssessmentMethodById',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/assessmentMethod/?id=${obj.id}&course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const fetchAssessmentMethodInstructorsById = createAsyncThunk(
  'AssessmentMethods/fetchAssessmentMethodInstructorsById',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/assessmentMethod/instructors/?id=${obj.id}&course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const fetchClos_InAssessmentMethodById = createAsyncThunk(
  'AssessmentMethods/fetchClos_InAssessmentMethodById',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl +
        `/assessmentMethod/clos/?id=${obj.id}&course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })


export const fetchQuestions_ByAssessmentMethodById = createAsyncThunk(
  'AssessmentMethods/fetchQuestions_ByAssessmentMethodById',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/examQuestion/?exam_id=${obj.id}&course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

/*************************************************************** 
 * Api to get assessment question by groupe 
***************************************************************/
export const fetchExamByQuestionAndNotes = createAsyncThunk(
  'AssessmentMethods/fetchExamByQuestionAndNotes',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/examByQuestion/analyse/?course_id=${obj.course_id}&exam_id=${obj.id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

/******************************************** */
export const deleteAssessmentMethodQuestions = createAsyncThunk(
  'AssessmentMethods/deleteAssessmentMethodQuestions',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/examQuestion/assessment/delete/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

/******************************************************************* */

export const updateAssessmentMethod = createAsyncThunk(
  'clo/updateAssessmentMethod',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/assessmentMethod/${data.id}/edit/`,
        data.updatedAssessmentMethod,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

/* Affect Evidence Type To Assessment Method */

/* Affect */
export const affectEvidenceTypeToAssessmentMethod = createAsyncThunk(
  'AssessmentMethods/affectEvidenceTypeToAssessmentMethod',
  async (data, { rejectWithValue }) => {

    const token = localStorage.getItem('token')
    const option = {
      headers: {
        Authorization: `token ${token}`,
      },
    }
    try {
      const response = await axios.post(
        BaseUrl + `/evidenceAssessment/create/`,
        data,
        option,
      )

      return response
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })


/*Fetch */
export const fetchAssessmentById = createAsyncThunk(
  'AssessmentMethods/fetchAssessmentById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/evidenceAssessment/byExam/?exam_id=${id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })


export const fetchAffectEvidenceTypeToAssessmentMethod = createAsyncThunk(
  'AssessmentMethods/fetchAffectEvidenceTypeToAssessmentMethod',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/assessmentMethod/all`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

/*Update */
export const updateAffectEvidenceTypeToAssessmentMethod = createAsyncThunk(
  'AssessmentMethods/updateAffectEvidenceTypeToAssessmentMethod',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/assessmentMethod/${data.id}/edit/`,
        data.updatedAssessmentMethod,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

/*Delete */
export const deleteAffectEvidenceTypeToAssessmentMethod = createAsyncThunk(
  'AssessmentMethods/deleteAffectEvidenceTypeToAssessmentMethod',
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(
        BaseUrl +
        `/AssessmentMethods/?exam_id=${obj.id}&course_id=${obj.course_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })


export const addNewAttachmentAssessmentEvidence = createAsyncThunk(
  'AssessmentMethods/addNewAttachmentAssessmentEvidence',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const option = {
        headers: {
          Authorization: `token ${token}`,
        },
      }


      const response = await axios.post(BaseUrl + `/evidence/create/`, data, option)

      return response
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const fetchAttachmentAssessmentEvidence = createAsyncThunk(
  'AssessmentMethods/fetchAttachmentAssessmentEvidence',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const option = {
        headers: {
          Authorization: `token ${token}`,
        },
      }
      const response = await axios.get(BaseUrl + `/evidenceAssessment/byExam/?exam_id=${id}`, option)

      return response
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const deleteAttachmentAssessmentEvidence = createAsyncThunk(
  'AssessmentMethods/deleteAttachmentAssessmentEvidence',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(
        BaseUrl +
        `/evidence/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      console.log('err from slice', err)
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })


const AssessmentMethodsSlice = createSlice({
  name: 'AssessmentMethods',
  initialState,
  extraReducers: {
    [GetAssessmentMethodById.pending]: (state, action) => {
      state.assessmentMethodStatus = 'loading'
    },
    [GetAssessmentMethodById.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
      state.assessmentMethod = action.payload
    },
    [GetAssessmentMethodById.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAssessmentMethodById.pending]: (state, action) => {
      state.assessmentMethodStatus = 'loading'
    },
    [fetchAssessmentMethodById.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
      state.assessmentMethod = action.payload
    },
    [fetchAssessmentMethodById.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [fetchAssessmentMethodInstructorsById.pending]: (state, action) => {
      state.assessmentMethodStatus = 'loading'
    },
    [fetchAssessmentMethodInstructorsById.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
      state.assessmentMethod = action.payload
    },
    [fetchAssessmentMethodInstructorsById.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchClos_InAssessmentMethodById.pending]: (state, action) => {
      state.assessmentMethodStatus = 'loading'
    },
    [fetchClos_InAssessmentMethodById.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
      state.AssessmentMethodClos = action.payload
    },
    [fetchClos_InAssessmentMethodById.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchQuestions_ByAssessmentMethodById.pending]: (state, action) => {
      state.QuestionsByAssessmentMethodStatus = 'loading'
    },
    [fetchQuestions_ByAssessmentMethodById.fulfilled]: (state, action) => {
      state.QuestionsByAssessmentMethodStatus = 'succeeded'
      state.QuestionsByAssessmentMethod = action.payload
    },
    [fetchQuestions_ByAssessmentMethodById.rejected]: (state, action) => {
      state.QuestionsByAssessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    /********************************************************* */
    [fetchExamByQuestionAndNotes.pending]: (state, action) => {
      state.ExamByQuestionAndNotesStatus = 'loading'
    },
    [fetchExamByQuestionAndNotes.fulfilled]: (state, action) => {
      state.ExamByQuestionAndNotesStatus = 'succeeded'
      state.ExamByQuestionAndNotesMethod = action.payload
    },
    [fetchExamByQuestionAndNotes.rejected]: (state, action) => {
      state.ExamByQuestionAndNotesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    /******************************************************** */
    [fetchAssessmentMethodsByCourseId.pending]: (state, action) => {
      state.assessmentMethodStatus = "loading";
    },
    [fetchAssessmentMethodsByCourseId.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = "succeeded";
      state.assessmentMethodsByCourseId = action.payload;
    },
    [fetchAssessmentMethodsByCourseId.rejected]: (state, action) => {
      state.assessmentMethodStatus = "failed";
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAssessmentMethods.pending]: (state, action) => {
      state.assessmentMethodStatus = 'loading'
    },
    [fetchAssessmentMethods.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
      state.assessmentMethods = action.payload
    },
    [fetchAssessmentMethods.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAssessmentQuestionsMethods.pending]: (state, action) => {
      state.assessmentQuestionsMethodsStatus = 'loading'
    },
    [fetchAssessmentQuestionsMethods.fulfilled]: (state, action) => {
      state.assessmentQuestionsMethodsStatus = 'succeeded'
      state.assessmentQuestionsMethods = action.payload
    },
    [fetchAssessmentQuestionsMethods.rejected]: (state, action) => {
      state.assessmentQuestionsMethodsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    /*Fetch Affect */
    [fetchAffectEvidenceTypeToAssessmentMethod.pending]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'loading'
    },
    [fetchAffectEvidenceTypeToAssessmentMethod.fulfilled]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'succeeded'
      state.affectEvidenceTypeToAssessmentMethod = action.payload
    },
    [fetchAffectEvidenceTypeToAssessmentMethod.rejected]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    /*Add Affect */

    [affectEvidenceTypeToAssessmentMethod.pending]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'loading'
    },
    [affectEvidenceTypeToAssessmentMethod.fulfilled]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'succeeded'
    },
    [affectEvidenceTypeToAssessmentMethod.rejected]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAssessmentById.pending]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'loading'
    },
    [fetchAssessmentById.fulfilled]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'succeeded'
      state.assessmentById = action.payload
    },
    [fetchAssessmentById.rejected]: (state, action) => {
      state.affectEvidenceTypeToAssessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewAttachmentAssessmentEvidence.pending]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'loading'
    },
    [addNewAttachmentAssessmentEvidence.fulfilled]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'succeeded'
    },
    [addNewAttachmentAssessmentEvidence.rejected]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [fetchAttachmentAssessmentEvidence.pending]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'loading'
    },
    [fetchAttachmentAssessmentEvidence.fulfilled]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'succeeded'
      state.attachmentAssessmentEvidence = action.payload

    },

    [fetchAttachmentAssessmentEvidence.rejected]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteAssessmentMethodQuestions.pending]: (state, action) => {
      state.deleteQuestionStatus = 'loading'
    },
    [deleteAssessmentMethodQuestions.fulfilled]: (state, action) => {
      state.deleteQuestionStatus = 'succeeded'
    },
    [deleteAssessmentMethodQuestions.rejected]: (state, action) => {
      state.deleteQuestionStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deleteAttachmentAssessmentEvidence.pending]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'loading'
    },
    [deleteAttachmentAssessmentEvidence.fulfilled]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'succeeded'
    },
    [deleteAttachmentAssessmentEvidence.rejected]: (state, action) => {
      state.attachmentAssessmentEvidenceStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [DeleteAssessmentMethod.pending]: (state, action) => {
      state.assessmentMethodStatus = 'loading'
    },
    [DeleteAssessmentMethod.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
    },
    [DeleteAssessmentMethod.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateAssessmentMethod.fulfilled]: (state, action) => { },
  },

  reducers: {
    AssessmentMethodUpdated(state, action) {
      const { id, name, groupAssessment_ids } = action.payload
      const AssessmentMethodClo = state.assessmentMethods.find(
        (assessmentMethod) => assessmentMethod.id === id,
      )
      if (AssessmentMethodClo) {
        AssessmentMethodClo.name = name
        AssessmentMethodClo.groupAssessment_ids = groupAssessment_ids
      }
    },
  },
})

export const {
  getAssessmentId,
  AssessmentMethodAdded,
  AssessmentMethodUpdated,
} = AssessmentMethodsSlice.actions

export default AssessmentMethodsSlice.reducer
