// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import { Form, Input, notification } from 'antd';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { updateAccountPassword } from "../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../Components/ErrorModal";
import { getUserById } from "reduxToolkit/features/user/UserSlice"
const useStyles = makeStyles(styles);

export default function UserProfile() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory();
  const [form] = Form.useForm();

  const [username, setUsername] = useState("")

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2
    });
  };

  const handleLogout = async () => {
    localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('user');
    window.location.href = '/auth/login-page';
  };

  let id = localStorage.getItem('userId')

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id)).unwrap()
        .then((originalPromiseResult) => {
          // handle result here
          setUsername(originalPromiseResult.username)
        })
    }
  }, [])

  const onFinish = async (values) => {
    const updatedAccount = {
      ...values,
      username
    }
    const resultAction = await dispatch(
      updateAccountPassword({ id: id, updatedAccount: updatedAccount })
    );
    if (updateAccountPassword.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Updated`);
      handleLogout();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const onFinishFailed = (errorInfo) => { };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Change your password
              </h4>
            </CardHeader>
            <br />
            <br />
            <CardBody>
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelCol={{ span: 4 }}
                labelAlign="left"
              >
                <Form.Item
                  label="Current password"
                  name="current_password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="New password"
                  name="new_password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 4,
                      message: 'Your new password should contain at least 4 characters',

                    }
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <GridContainer justify="flex-end">
                  <Form.Item
                    xs={12} sm={12} md={12}
                  >
                    <Button
                      onClick={() =>
                        history.push(
                          '/citrine',
                        )
                      }
                      style={{ marginRight: 10 }}
                      color="danger"
                    >
                      {t('Cancel')}
                    </Button>
                    <Button color="rose" type="submit">
                      Update
                    </Button>
                  </Form.Item>
                </GridContainer>
              </Form>
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Company (disabled)"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Username"
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="email-address"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer> */}
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="first-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="City"
                    id="city"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Country"
                    id="country"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Postal Code"
                    id="postal-code"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                  <CustomInput
                    labelText="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                    id="about-me"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5
                    }}
                  />
                </GridItem>
              </GridContainer> */}
              {/* <Clearfix /> */}
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p>
              <Button color="rose" round>
                Follow
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
      <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
    </div>
  );
}
