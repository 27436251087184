import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
    annualProgramReportDocuments: [],
    annualProgramReportDocumentsStatus: "idle",
    annualProgramReportDocument: [],
    annualProgramReportDocumentStatus: "idle",
};

export const fetchAnnualProgramReportDocuments = createAsyncThunk(
  "AnnualProgramReportDocument/fetchAnnualProgramReportDocuments",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/htmlAnnualProgramReport/by_program/?program_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      //console.log(response.data, 'response.data')
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response);
    }
  }
);


export const fetchAnnualProgramReportDocument = createAsyncThunk(
  "researchAndInnovationActivities/fetchAnnualProgramReportDocument",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/htmlAnnualProgramReport/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const addAnnualProgramReportDocument = createAsyncThunk(
  "AnnualProgramReportDocument/addAnnualProgramReportDocument",
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/htmlAnnualProgramReport/create/`,
        toAddActivity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response);
    }
  }
);


export const deleteAnnualProgramReportDocument = createAsyncThunk(
  "AnnualProgramReportDocument/deleteAnnualProgramReportDocument",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/htmlAnnualProgramReport/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


const AnnualProgramReportDocumentsSlice = createSlice({
  name: "AnnualProgramReportDocument",
  initialState,
  extraReducers: {
    [fetchAnnualProgramReportDocuments.pending]: (state, action) => {
      state.annualProgramReportDocumentsStatus = "loading";
    },
    [fetchAnnualProgramReportDocuments.fulfilled]: (state, action) => {
      state.annualProgramReportDocumentsStatus = "succeeded";
      state.annualProgramReportDocuments = action.payload;
    },
    [fetchAnnualProgramReportDocuments.rejected]: (state, action) => {
      state.annualProgramReportDocumentsStatus = "failed";
    },

    [fetchAnnualProgramReportDocument.pending]: (state, action) => {
        state.annualProgramReportDocumentStatus = "loading";
      },
      [fetchAnnualProgramReportDocument.fulfilled]: (state, action) => {
        state.annualProgramReportDocumentStatus = "succeeded";
        state.annualProgramReportDocument = action.payload;
      },
      [fetchAnnualProgramReportDocument.rejected]: (state, action) => {
        state.annualProgramReportDocumentStatus = "failed";
      },
  },
});

export default AnnualProgramReportDocumentsSlice.reducer;
