// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, Form, Input, notification, Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ErrorModal from "views/Components/ErrorModal";
import { addNewSurveyTemplatesTypeConfig, fetchSurveyTemplatesTypeConfigById, fetchSurveyTemplatesTypeConfigs, updateSurveyTemplatesTypeConfig } from "../../../reduxToolkit/features/surveyTemplateTypeConfig/SurveyTemplateTypeConfigSlice";

const useStyles = makeStyles(styles);

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 16,
        span: 16,
    },
};

export default function SurveyTemplateTypeAddEdit({ match }) {

    const { id } = match.params

    const { t } = useTranslation();
    const classes = useStyles();
    const useTitleStyles = makeStyles(titleStyles);

    const titleClasses = useTitleStyles();

    const isAddMode = isNaN(id);

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const history = useHistory();

    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");

    const surveyTemplatesTypeConfig = useSelector((state) => state.surveyTemplatesTypeConfig.surveyTemplatesTypeConfig)

    useEffect(() => {
        dispatch(fetchSurveyTemplatesTypeConfigs())
    }, [])

    useEffect(() => {
        if (!isAddMode) {
            dispatch(fetchSurveyTemplatesTypeConfigById(id))
                .then(unwrapResult)
                .then((x) => {
                    form.setFieldsValue({
                        name: x.name,
                        category: x.category
                    });
                });
        }
    }, [id]);

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const onFinish = async (values) => {
        const resultAction = await dispatch(
            addNewSurveyTemplatesTypeConfig({ ...values })
        );
        if (addNewSurveyTemplatesTypeConfig.fulfilled.match(resultAction)) {
            openNotificationWithIcon("success", `Added successfully`);
            form.resetFields();
            dispatch(fetchSurveyTemplatesTypeConfigs());
            history.push(`/citrine/surveyTemplates/types/`);
        } else {
            if (resultAction.payload) {
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
            }
        }
    };

    const onFinishUpdate = async (values) => {
        const resultAction = await dispatch(
            updateSurveyTemplatesTypeConfig({
                id: id,
                modified: {
                    ...values,
                },
            })
        );
        if (updateSurveyTemplatesTypeConfig.fulfilled.match(resultAction)) {
            openNotificationWithIcon("success", `edit successfully`);
            form.resetFields();
            dispatch(fetchSurveyTemplatesTypeConfigs());
            history.push(`/citrine/surveyTemplates/types/`);
        } else {
            if (resultAction.payload) {
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
            }
        }
    };

    return (
        <div>
            {isAddMode &&
                <div>
                    <GridContainer style={{
                        borderBottom: `1px solid  ${grayColor[15]}`,
                        borderTop: `1px solid  ${grayColor[15]}`,
                    }}
                        justify="space-between"
                        alignItems="center">
                        <GridItem>
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item >
                                    {t('Configurations')}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to={`/citrine/surveyTemplatesNavigationPage`}>
                                        <span > {t('Survey Templates')} </span>
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item >
                                    <Link to={`/citrine/surveyTemplates/types/`} style={{ color: "orange" }}>
                                        {t('Survey Templates Types')}
                                    </Link>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info">
                                        <SchoolRounded />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        <span className={titleClasses.title}>{t('Create ')}</span>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        {...layout}
                                        form={form}
                                        name="control-hooks"
                                        onFinish={onFinish}
                                    >
                                        <Form.Item
                                            name="category"
                                            label="Category"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select Category">

                                                <option key="course" value="course">
                                                    Course
                                                </option>
                                                <option key="program" value="program">
                                                    Program
                                                </option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item {...tailLayout}>
                                            <Button
                                                type="primary"
                                                style={{ marginRight: "20px" }}
                                                color="primary"
                                                onClick={() =>
                                                    history.push(`/citrine/surveyTemplates/types/`)
                                                }
                                                htmlType="submit"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <ErrorModal
                        open={openErrorModal}
                        handleClose={handleCloseErrorModal}
                        handledError={handledError}
                    />
                </div>
            }
            {!isAddMode && surveyTemplatesTypeConfig &&
                <div>
                    <GridContainer style={{
                        borderBottom: `1px solid  ${grayColor[15]}`,
                        borderTop: `1px solid  ${grayColor[15]}`,
                    }}
                        justify="space-between"
                        alignItems="center">
                        <GridItem>
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item >
                                    {t('Configurations')}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to={`/citrine/surveyTemplatesNavigationPage`}>
                                        <span > {t('Survey Templates')} </span>
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item >
                                    <Link to={`/citrine/surveyTemplates/types/`} style={{ color: "orange" }}>
                                        {t('Survey Templates Types')}
                                    </Link>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info">
                                        <SchoolRounded />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        <span className={titleClasses.title}>  {t('Edit')} </span>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        {...layout}
                                        form={form}
                                        name="control-hooks"
                                        onFinish={onFinishUpdate}
                                    >
                                        <Form.Item
                                            name="category"
                                            label="Category"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select Category">
                                                <option key="course" value="course">
                                                    Course
                                                </option>
                                                <option key="program" value="program">
                                                    Program
                                                </option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item {...tailLayout}>
                                            <Button
                                                type="primary"
                                                style={{ marginRight: "20px" }}
                                                color="primary"
                                                onClick={() =>
                                                    history.push(`/citrine/surveyTemplates/types/`)
                                                }
                                                htmlType="submit"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <ErrorModal
                        open={openErrorModal}
                        handleClose={handleCloseErrorModal}
                        handledError={handledError}
                    />
                </div>
            }
        </div>
    );
}
