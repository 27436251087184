import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import token from "assets/utils/accessToken";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  FacilitiesRequiredsConfig: [],
  FacilitiesRequiredStatus: "idle",
  FacilityRequiredStatus: "idle",
  error: null,
  FacilityRequired: null,
  id: null,
};

export const fetchFacilitiesRequiredsConfig = createAsyncThunk(
  "FacilitiesRequiredConfig/fetchFacilitiesRequiredsConfig",
  async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/facilitiesRequiredConfig/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  }
);

export const fetchFacilitiesRequiredConfigById = createAsyncThunk(
  "FacilitiesRequiredConfig/fetchFacilitiesRequiredConfigById",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/facilitiesRequiredConfig/${id}/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const addNewFacilitiesRequiredConfig = createAsyncThunk(
  "FacilitiesRequiredConfig/addNewFacilitiesRequiredConfig",
  async (initialFacilitiesRequired) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/facilitiesRequiredConfig/create/`,
      initialFacilitiesRequired,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const facilityUpdate = createAsyncThunk(
  "FacilitiesRequiredConfigr/facilitiesRequiredConfigUpdate",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/facilitiesRequiredConfig/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
    }
  }
);
export const deleteFacilitiesRequired = createAsyncThunk(
  "FacilitiesRequiredConfig/deleteFacilitiesRequired",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/facilitiesRequiredConfig/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

const FacilitiesRequiredConfigSlice = createSlice({
  name: "FacilitiesRequiredConfig",
  initialState,
  extraReducers: {
    [fetchFacilitiesRequiredsConfig.pending]: (state, action) => {
      state.FacilitiesRequiredStatus = "loading";
    },
    [fetchFacilitiesRequiredsConfig.fulfilled]: (state, action) => {
      state.FacilitiesRequiredStatus = "succeeded";
      state.FacilitiesRequiredsConfig = action.payload;
    },
    [fetchFacilitiesRequiredsConfig.rejected]: (state, action) => {
      state.FacilitiesRequiredStatus = "failed";
      state.error = action.payload;
    },

    [fetchFacilitiesRequiredConfigById.pending]: (state, action) => {
      state.FacilityRequiredStatus = "loading";
    },
    [fetchFacilitiesRequiredConfigById.fulfilled]: (state, action) => {
      state.FacilityRequiredStatus = "succeeded";
      state.FacilityRequired = action.payload;
    },
    [fetchFacilitiesRequiredConfigById.rejected]: (state, action) => {
      state.FacilityRequiredStatus = "failed";
      state.error = action.payload;
    },

    [addNewFacilitiesRequiredConfig.fulfilled]: (state, action) => {
      state.FacilitiesRequireds.push(action.payload);
    },

    [deleteFacilitiesRequired.fulfilled]: (state, action) => {},

    [facilityUpdate.fulfilled]: (state, action) => {},

    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },
    },
  },
});

export const {
  getId,
} = FacilitiesRequiredConfigSlice.actions;

export default FacilitiesRequiredConfigSlice.reducer;

export const selectAllFacilitiesRequiredConfig = (state) =>
  state.FacilitiesRequiredConfig.FacilitiesRequiredsConfig;
