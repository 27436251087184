import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  assessmentMethods: [],
  assessmentMethodsByCourse: [],
  assessmentMethodsStatus: 'idle',
  assessmentMethodStatus: 'idle',
  assessmentMethodByCourseStatus: 'idle',
  error: null,
  assessmentMethod: null,
  defaultQuestionSpecStatus: 'idle',
  defaultQuestionSpec: [],
  id: null,
  assessmentMethodsNotGenerated: [],
}

export const fetchAssessmentMethods = createAsyncThunk(
  'assessmentMethod/fetchAssessmentMethods',
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/assessmentMethodSpec/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchAssessmentMethodsByCourseSpec = createAsyncThunk(
  'assessmentMethod/fetchAssessmentMethodsByCourseSpec',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/assessmentMethodSpec/filter/?courseSpec_id=${id}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchAssessmentMethodsNotGenerated = createAsyncThunk(
  'assessmentMethod/fetchAssessmentMethodsNotGenerated',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/assessmentMethodSpec/notGenerated/?course_id=${id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchAssessmentMethodById = createAsyncThunk(
  'assessmentMethod/fetchAssessmentMethodById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/assessmentMethodSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewAssessmentMethod = createAsyncThunk(
  'assessmentMethod/addNewAssessmentMethod',
  async (initialassessmentMethod, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/assessmentMethodSpec/create/`,
        initialassessmentMethod,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addDefaultQuestionSpec = createAsyncThunk(
  'assessmentMethod/addDefaultQuestionSpec',
  async (toAdd, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/examQuestionSpec/create/`,
        toAdd,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateDefaultQuestionSpec = createAsyncThunk(
  'assessmentMethod/updateDefaultQuestionSpec',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/examQuestionSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const assessmentMethodUpdate = createAsyncThunk(
  'assessmentMethod/assessmentMethodUpdate',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/assessmentMethodSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteAssessmentMethod = createAsyncThunk(
  'assessmentMethod/deleteAssessmentMethod',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/assessmentMethodSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteAssessmentMethodQuestion = createAsyncThunk(
  'assessmentMethod/deleteAssessmentMethodQuestion',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/examQuestionSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const assessmentMethodsSlice = createSlice({
  name: '  assessmentMethod',
  initialState,
  extraReducers: {
    [fetchAssessmentMethods.pending]: (state, action) => {
      state.assessmentMethodsStatus = 'loading'
    },
    [fetchAssessmentMethods.fulfilled]: (state, action) => {
      state.assessmentMethodsStatus = 'succeeded'
      state.assessmentMethods = action.payload
    },
    [fetchAssessmentMethods.rejected]: (state, action) => {
      state.assessmentMethodsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAssessmentMethodsByCourseSpec.pending]: (state, action) => {
      state.assessmentMethodByCourseStatus = 'loading'
    },
    [fetchAssessmentMethodsByCourseSpec.fulfilled]: (state, action) => {
      state.assessmentMethodByCourseStatus = 'succeeded'
      state.assessmentMethodsByCourse = action.payload
    },
    [fetchAssessmentMethodsByCourseSpec.rejected]: (state, action) => {
      state.assessmentMethodByCourseStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },

    [fetchAssessmentMethodsNotGenerated.pending]: (state, action) => {
      state.assessmentMethodsStatus = 'loading'
    },
    [fetchAssessmentMethodsNotGenerated.fulfilled]: (state, action) => {
      state.assessmentMethodsStatus = 'succeeded'
      state.assessmentMethodsNotGenerated = action.payload
    },
    [fetchAssessmentMethodsNotGenerated.rejected]: (state, action) => {
      state.assessmentMethodsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAssessmentMethodById.pending]: (state, action) => {
      state.assessmentMethodStatus = 'loading'
    },
    [fetchAssessmentMethodById.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
      state.assessmentMethod = action.payload
    },
    [fetchAssessmentMethodById.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewAssessmentMethod.fulfilled]: (state, action) => {
      state.assessmentMethods.push(action.payload)
    },
    [addNewAssessmentMethod.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addDefaultQuestionSpec.fulfilled]: (state, action) => {
      state.defaultQuestionSpec.push(action.payload)
    },
    [addDefaultQuestionSpec.rejected]: (state, action) => {
      state.defaultQuestionSpecStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateDefaultQuestionSpec.fulfilled]: (state, action) => {
      state.defaultQuestionSpecStatus = 'succeeded'
    },
    [updateDefaultQuestionSpec.rejected]: (state, action) => {
      state.defaultQuestionSpecStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [assessmentMethodUpdate.fulfilled]: (state, action) => {},
    [assessmentMethodUpdate.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteAssessmentMethod.fulfilled]: (state, action) => {
      state.assessmentMethodStatus = 'succeeded'
      state.assessmentMethods = state.assessmentMethods.splice(
          state.assessmentMethods.findIndex(
              (arrow) => arrow.id === action.payload,
          ),
          1,
      )
    },
    [deleteAssessmentMethod.rejected]: (state, action) => {
      state.assessmentMethodStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },

  reducers: {
    getId: (state, action) => {
      state.id = action.payload
    },

    assessmentMethodUpdated(state, action) {
      const { id, name } = action.payload
      const existingassessmentMethod = state.assessmentMethods.find(
        (assessmentMethod) => assessmentMethod.id === id,
      )
      if (existingassessmentMethod) {
        existingassessmentMethod.name = name
      }
    },
  },
})

export const { getAssessmentId } = assessmentMethodsSlice.actions

export default assessmentMethodsSlice.reducer

export const selectAllAssessmentMethods = (state) =>
  state.assessmentMethod.assessmentMethods
