import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  ConfigSkills: [],
  ConfigSkillsStatus: "idle",
  error: null,
};

export const fetchConfigSkills = createAsyncThunk(
  "ConfigSkills/fetchConfigSkills",
  async (person_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/keyWordSkills/`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);

export const addNewConfigSkills = createAsyncThunk(
  "ConfigSkills/addConfigSkills",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/keyWordSkills/create/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);


export const deleteConfigSkills = createAsyncThunk(
    "ConfigSkills/deleteConfigSkills",
    async (id) => {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/keyWordSkills/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
  
      return response.data;
    }
  );



const ConfigSkillsSlice = createSlice({
  name: "ConfigSkills",
  initialState,
  extraReducers: {
    [fetchConfigSkills.pending]: (state, action) => {
      state.ConfigSkillsStatus = "loading";
    },
    [fetchConfigSkills.fulfilled]: (state, action) => {
      state.ConfigSkillsStatus = "succeeded";
      // Add any fetched posts to the array
      state.ConfigSkills = action.payload;
    },
    [fetchConfigSkills.rejected]: (state, action) => {
      state.ConfigSkillsStatus = "failed";
      state.error = action.payload;
    },

    [addNewConfigSkills.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
  },
});

export default ConfigSkillsSlice.reducer;
// export const  groups = state => state.group.groups
