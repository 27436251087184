import { ArrowRightOutlined } from "@ant-design/icons";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Divider, notification } from "antd";
import "antd/dist/antd.css";
import {
  blackColor,
  hexToRgb,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchExitPoints } from "reduxToolkit/features/exitPoints/ExitPointsSlice";
import { fetchMajorTracks } from "reduxToolkit/features/majorTracks/MajorTracksSlice";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import {
  grayColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchActivitiesProgramSpec } from "../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import { fetchGraduateAttributesSpec } from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSpecSlice";
import { fetchKPIsSpec } from "../../../../reduxToolkit/features/KPI/KPISpecSlice";
import {
  addNewProgramSpecificationReportDocument,
  deleteProgramSpecificationReportDocument,
  fetchProgramSpecificationReportDocumentsByProgramSpecificationId,
} from "../../../../reduxToolkit/features/ProgramSpecificationReportDocument/ProgramSpecificationReportDocumentSlice";
import { fetchStudyPlanReportDocumentsByProgramSpecId } from "../../../../reduxToolkit/features/StudyPlan/StudyPlanReportDocumentSlice";
import { fetchJobs } from "../../../../reduxToolkit/features/jobs/JobsSlice";
import {
  Special_updateProgram,
  fetchProgramById,
  fetchPrograms,
} from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import {
  synchSpecCoursesWithLMS,
  synchSpecAssessmentMethodsWithLMS,
  synchSpecCLOsWithLMS,
  synchDataWithLMS,
} from "../../../../reduxToolkit/features/LMSIntegration/LMSIntegrationSlice";
import DropDownProgramSpecReportButton from "../../../Components/DropDownProgramSpecReportButton";
import DropDownStudyPlanReportButton from "../../../Components/DropDownStudyPlanReportButton";
import ErrorModal from "../../../Components/ErrorModal";
import ProgramCardDropdownButton from "../../../Components/ProgramCardDropdownButton";
import VersionningProgramSpecModal from "../../../Components/VersionningProgramSpecModal";
import CreateReportProgramDocument from "../../../Components/CreateReportProgramDocument";
import CreateReportStudyPlanDocument from "../../../Components/CreateReportStudyPlanDocument";
import Stack from "@mui/material/Stack";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "rgb(0,0,0,0.7)",
    display: "flex",
    flexDirection: "column",
  },
}));

const useStyles = makeStyles(styles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ProgramDetails({ match }) {
  const { programId } = match.params;

  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const cardClasses = useCardStyles();
  const backdropClasses = useBackdropStyles();

  const dispatch = useDispatch();

  const [isEditor, setIsEditor] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const [ping, setPing] = useState(false);

  const program = useSelector((state) => state.programSpec.program);
  const Activities = useSelector(
    (state) => state.Activity.ActivitiesProgramSpec
  );
  const GraduateAttributes = useSelector(
    (state) => state.GraduateAttributeSpec.GraduateAttributes
  );
  const { KPIs } = useSelector((state) => state.KPISpec);
  const jobs = useSelector((state) => state.jobs.jobs);
  const majorTracks = useSelector((state) => state.majorTracks.majorTracks);
  const exitPoints = useSelector((state) => state.exitpoints.exitPoints);
  const staffList = useSelector((state) => state.user.staffList);

  useEffect(() => {
    dispatch(fetchStaff());
  }, []);

  useEffect(() => {
    fetchProgram();
    dispatch(fetchActivitiesProgramSpec(programId));
    dispatch(fetchGraduateAttributesSpec(programId));
    dispatch(
      fetchProgramSpecificationReportDocumentsByProgramSpecificationId(
        programId
      )
    );
    dispatch(fetchStudyPlanReportDocumentsByProgramSpecId(programId));
    dispatch(fetchKPIsSpec(programId));
    dispatch(fetchJobs(programId));
    dispatch(fetchMajorTracks(programId));
    dispatch(fetchExitPoints(programId));
  }, [ping]);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const renderRow = () => {
    if (showActivities) {
      return (
        <GridContainer xs={12} sm={12} md={12}>
          <h4 style={{ marginBottom: "20px" }}>Activities</h4>
          <GridItem key={Element.id} xs={12} sm={12} md={6}>
            {Activities.map((item) => {
              return (
                <>
                  <Divider
                    type="horizontal"
                    orientation="center"
                    style={{ color: "gray" }}
                  >
                    {Moment(item.DueDate).format("YYYY-MM-DD")}
                  </Divider>
                  <Card>
                    <CardBody style={{ backgroundColor: "#F0F0F0" }}>
                      {<b>{item.AssignedTo}</b>}
                      {" changed the "}
                      {<b>{item.Field}</b>}
                      {" from "}
                      {<b>{item.value1}</b>}
                      {" to "}
                      {<b>{item.value2}</b>}
                    </CardBody>
                  </Card>
                  <div>
                    <Divider
                      type="horizontal"
                      orientation="center"
                      style={{ color: "gray" }}
                    >
                      {Moment(
                        item &&
                          item.changes &&
                          item.changes[0] &&
                          item.changes[0].date
                      ).format("YYYY-MM-DD")}
                    </Divider>
                    <div
                      style={{
                        backgroundColor: "#edeff1",
                        borderBottom: "1px solid #cfd5db",
                      }}
                    >
                      <GridContainer xs={12} sm={12} md={12}>
                        <GridItem key={Element.id} xs={12} sm={12} md={2}>
                          <Avatar
                            style={{
                              color: "#f56a00",
                              backgroundColor: "#fde3cf",
                            }}
                          >
                            {item.avatar}
                          </Avatar>
                        </GridItem>
                        <GridItem key={Element.id} xs={12} sm={12} md={10}>
                          <p>
                            <strong>
                              {item &&
                                item.changes &&
                                item.changes[0] &&
                                item.changes[0].user_id}
                            </strong>
                          </p>
                          <strong> Field : {item && item.field}</strong>
                          <ul style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                            <li>
                              {item &&
                                item.changes &&
                                item.changes[0] &&
                                item.changes[0].value1}
                              <ArrowRightOutlined
                                style={{ fontSize: "12px" }}
                              />
                              {item &&
                                item.changes &&
                                item.changes[0] &&
                                item.changes[0].value2}
                            </li>
                          </ul>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </div>
                </>
              );
            })}
          </GridItem>
        </GridContainer>
      );
    } else if (showTasks) {
      return <div>Tasks</div>;
    } else {
      return (
        <div>
          <div style={{ zIndex: 10000 }}>
            {isEditor && (
              <GridContainer>
                <GridItem
                  style={{ backgroundColor: "rgba(0,0,0,0.04)" }}
                  xs={6}
                  sm={6}
                  md={6}
                >
                  <Stack direction="row" alignItems={"center"} spacing={2}>
                    <span
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: primaryColor[0],
                      }}
                    >
                      {t("Program Specifications")}
                    </span>
                    {program?.state !== "Approved" && (
                      <CreateReportProgramDocument
                        programId={program && program.id}
                        handleDropDownButtonClose={handleClose}
                      />
                    )}
                  </Stack>
                  {programSpecReportDocuments &&
                  programSpecReportDocuments.length > 0 ? (
                    programSpecReportDocuments.map(
                      (programSpecReportDocument) => (
                        <Card
                          style={{
                            backgroundColor: grayColor[10],
                            padding: 10,
                          }}
                        >
                          <GridContainer
                            justify="space-between"
                            alignItems="center"
                          >
                            <GridContainer
                              xs={12}
                              sm={12}
                              md={12}
                              alignItems="baseline"
                            >
                              <GridItem xs={10} sm={10} md={10}>
                                <Link
                                  to={`/citrine/programSpec/${programId}/ProgramSpecificationReportDocument/${programSpecReportDocument.id}`}
                                >
                                  <h7> {programSpecReportDocument.name}</h7>
                                </Link>
                              </GridItem>
                              <GridItem>
                                {isEditor && (
                                  <DropDownProgramSpecReportButton
                                    id={programSpecReportDocument.id}
                                    progId={programId}
                                  />
                                )}
                              </GridItem>
                            </GridContainer>
                            <GridItem>
                              <h7>Status: {programSpecReportDocument.state}</h7>
                              <br />
                              <h7>
                                Created By:{" "}
                                {programSpecReportDocument &&
                                  programSpecReportDocument.user_details &&
                                  programSpecReportDocument.user_details
                                    .username}
                              </h7>
                              <br />
                              <h7>
                                Created on:
                                {Moment(
                                  programSpecReportDocument.created_at
                                ).format("YYYY-MM-DD")}
                              </h7>
                              <br />
                              <GridContainer xs={12} sm={12} md={12}>
                                <GridItem xs={12} sm={12} md={12}>
                                  <Link>
                                    V {programSpecReportDocument.version}
                                  </Link>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        </Card>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        color: "gray",
                        textAlign: "center",
                        paddingTop: 40,
                      }}
                    >
                      No data
                    </div>
                  )}
                </GridItem>
                <GridItem
                  style={{ backgroundColor: "rgba(0,0,0,0.04)" }}
                  xs={6}
                  sm={6}
                  md={6}
                >
                  <Stack direction="row" alignItems={"center"} spacing={2}>
                    <span
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: primaryColor[0],
                      }}
                    >
                      {t("Study Plan")}
                    </span>
                    {program?.state !== "Approved" && (
                      <CreateReportStudyPlanDocument
                        programId={program && program.id}
                        handleDropDownButtonClose={handleClose}
                      />
                    )}
                  </Stack>
                  {studyPlanReportDocuments &&
                  studyPlanReportDocuments.length > 0 ? (
                    studyPlanReportDocuments.map((studyPlanReportDocument) => (
                      <Card
                        style={{ backgroundColor: grayColor[10], padding: 10 }}
                      >
                        <GridContainer
                          justify="space-between"
                          alignItems="center"
                        >
                          <GridContainer
                            xs={12}
                            sm={12}
                            md={12}
                            alignItems="baseline"
                          >
                            <GridItem xs={10} sm={10} md={10}>
                              <Link
                                to={`/citrine/programSpec/${programId}/StudyPlanReportDocument/${studyPlanReportDocument.id}`}
                              >
                                <h7>
                                  {studyPlanReportDocument.type}:
                                  {studyPlanReportDocument.name}
                                </h7>
                              </Link>
                            </GridItem>
                            <GridItem
                              style={{ paddingTop: "0px" }}
                              xs={2}
                              sm={2}
                              md={2}
                            >
                              {isEditor && (
                                <DropDownStudyPlanReportButton
                                  id={studyPlanReportDocument.id}
                                  progId={programId}
                                />
                              )}
                            </GridItem>
                          </GridContainer>
                          <GridItem>
                            <h7>Status: {studyPlanReportDocument.state}</h7>
                            <br />
                            <h7>
                              Created By:{" "}
                              {studyPlanReportDocument &&
                                studyPlanReportDocument.user_details &&
                                studyPlanReportDocument.user_details.username}
                            </h7>
                            <br />
                            <h7>
                              Created on:
                              {Moment(
                                studyPlanReportDocument.created_at
                              ).format("YYYY-MM-DD")}
                            </h7>
                            <br />
                            <GridContainer xs={12} sm={12} md={12}>
                              <GridItem xs={12} sm={12} md={12}>
                                <Link>V {studyPlanReportDocument.version}</Link>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </Card>
                    ))
                  ) : (
                    <div
                      style={{
                        color: "gray",
                        textAlign: "center",
                        paddingTop: 40,
                      }}
                    >
                      No data
                    </div>
                  )}
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      );
    }
  };

  const fetchProgram = async () => {
    try {
      await dispatch(fetchProgramById(programId))
        .then(unwrapResult)
        .then((program) => {
          localStorage.setItem("state", program.state);
          setStateName(program.state);
          if (program.state !== "Draft" || !isEditor) {
            setDisabledStatus(true);
          }
        });
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const coordinator =
    program && program.coordinator_id
      ? `${program && program.coordinators_detail[0].first_name}  ${
          program && program.coordinators_detail[0].last_name
        }`
      : "Unknown";

  const studyPlanReportDocuments = useSelector(
    (state) => state.StudyPlanReportDocument.studyPlanReportDocuments
  );

  const programSpecReportDocuments = useSelector(
    (state) =>
      state.ProgramSpecificationReportDocument
        .programSpecificationReportDocuments
  );

  const { pullDataLoading, pullingCourses, pullingAssessments, pullingCLOs } =
    useSelector((state) => state.LMSIntegration);

  const sychWithLMS = async () => {
    console.log("LMS");
    try {
      await dispatch(synchDataWithLMS()).unwrap();
      // handle result here
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.log("error in component", rejectedValueOrSerializedError);
      setHandledError(rejectedValueOrSerializedError);
      handleOpenErrorModal();
    }
  };

  const Approve = () => {
    setShow(true);
    setStateName("Approved");
    localStorage.setItem("state", "Approved");
    dispatch(fetchActivitiesProgramSpec(programId));
    try {
      const resultAction = dispatch(
        Special_updateProgram({
          id: program && program.id,
          updatedProgram: { state: "Approved" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Approved");
      dispatch(fetchActivitiesProgramSpec(programId));
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPrograms());
    }
  };

  const SendforApproval = () => {
    setShow(true);
    dispatch(fetchActivitiesProgramSpec(programId));
    setStateName("Waiting for approval");
    localStorage.setItem("state", "Waiting for approval");
    setDisabledStatus(true);
    try {
      const resultAction = dispatch(
        Special_updateProgram({
          id: program && program.id,
          updatedProgram: { state: "Waiting for approval" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Waiting for approval");
      dispatch(fetchPrograms());
      setDisabledStatus(true);
      dispatch(fetchActivitiesProgramSpec(programId));
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPrograms());
      dispatch(fetchActivitiesProgramSpec(programId));
      setDisabledStatus(true);
    }
  };

  const ResetTODraft = () => {
    setShow(true);
    setStateName("Draft");
    localStorage.setItem("state", "Draft");
    dispatch(fetchActivitiesProgramSpec(programId));
    try {
      const resultAction = dispatch(
        Special_updateProgram({
          id: program && program.id,
          updatedProgram: { state: "Draft" },
        })
      );
      unwrapResult(resultAction);
      setStateName("Draft");
      setDisabledStatus(false);
      dispatch(fetchActivitiesProgramSpec(programId));
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPrograms());
      dispatch(fetchActivitiesProgramSpec(programId));
      setDisabledStatus(false);
    }
  };

  const Archive = () => {
    setShow(true);
    setStateName("Archived");
    dispatch(fetchActivitiesProgramSpec(programId));
    try {
      const resultAction = dispatch(
        Special_updateProgram({
          id: program && program.id,
          updatedProgram: { state: "Archived", active: false },
        })
      );
      unwrapResult(resultAction);
      setStateName("Archived");
      dispatch(fetchPrograms());
      dispatch(fetchActivitiesProgramSpec(programId));
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchPrograms());
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let content;
  if (stateName === "Draft") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <Button
                color="primary"
                size="sm"
                className={classes.marginRight}
                onClick={() => SendforApproval()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Send for approval")}
                </span>
              </Button>
            )}
          </div>
          <ol class="progress-bar" style={{ width: "100%", height: "40px" }}>
            <li
              class="progress-bar__steps current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (
    stateName &&
    stateName.toLowerCase().includes("waiting for approval")
  ) {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <Button
                color="primary"
                size="sm"
                className={classes.marginRight}
                onClick={() => Approve()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Approve")}
                </span>
              </Button>
            )}
          </div>
          <div>
            {isEditor && (
              <Button
                style={{ backgroundColor: "lightgery" }}
                size="sm"
                onClick={() => ResetTODraft()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Reset tO draft")}
                </span>
              </Button>
            )}
          </div>
          <ol class="progress-bar" style={{ height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Approved") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <VersionningProgramSpecModal
                programSpec_id={program && program.id}
                program={program}
              />
            )}
          </div>
          <div>
            {isEditor && (
              <Button
                color="primary"
                size="sm"
                className={classes.marginRight}
                onClick={() => Archive()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Archive")}
                </span>
              </Button>
            )}
          </div>
          <ol class="progress-bar" style={{ width: "100%", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Archived") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "10px",
          }}
        >
          <div>
            {isEditor && (
              <Button
                style={{ backgroundColor: "lightgery" }}
                size="sm"
                className={classes.marginRight}
                onClick={() => ResetTODraft()}
              >
                <span style={{ color: "white", fontSize: "11px" }}>
                  {t("Reset to draft")}
                </span>
              </Button>
            )}
          </div>
          <ol class="progress-bar" style={{ width: "650px", height: "40px" }}>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "20%",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <span class="progress-bar__steps--text" style={{ width: "20%" }}>
                {t("Draft")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{ width: "40%", paddingTop: "10px" }}
            >
              <span class="progress-bar__steps--text" style={{ width: "40%" }}>
                {t("Wainting for approval")}
              </span>
            </li>
            <li
              class="progress-bar__steps not-current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Approved")}</span>
            </li>
            <li
              class="progress-bar__steps current"
              style={{
                width: "30%",
                paddingTop: "10px",
                paddingRight: "0px",
              }}
            >
              <span class="progress-bar__steps--text">{t("Archived")}</span>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  let content2;
  {
    program &&
      program.states_actions &&
      program.states_actions.map((item) => {
        if (stateName === "Draft" && item.state === "Draft") {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("versionning")
                    ) {
                      return (
                        <VersionningProgramSpecModal
                          programSpec_id={program && program.id}
                          program={program}
                        />
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {program &&
                    program.states_actions &&
                    program.states_actions.map((item) => {
                      return item.state === "Draft" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (
          stateName &&
          stateName.toLowerCase().includes("waiting for approval") &&
          item.state &&
          item.state.toLowerCase().includes("waiting for approval")
        ) {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("versionning")
                    ) {
                      return (
                        <VersionningProgramSpecModal
                          programSpec_id={program && program.id}
                          program={program}
                        />
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {program &&
                    program.states_actions &&
                    program.states_actions.map((item) => {
                      return item.state === "waiting for approval" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Approved" && item.state === "Approved") {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("versionning")
                    ) {
                      return (
                        <VersionningProgramSpecModal
                          programSpec_id={program && program.id}
                          program={program}
                        />
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {program &&
                    program.states_actions &&
                    program.states_actions.map((item) => {
                      return item.state === "Approved" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Archived" && item.state === "Archived") {
          content2 = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  //margin: "0px 25px 0px 20px",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset to draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("versionning")
                    ) {
                      return (
                        <VersionningProgramSpecModal
                          programSpec_id={program && program.id}
                          program={program}
                        />
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "100%", height: "40px" }}
                >
                  {program &&
                    program.states_actions &&
                    program.states_actions.map((item) => {
                      return item.state === "Archived" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        }
      });
  }
  console.log("pullDataLoading", pullDataLoading);
  return (
    <div>
      <Backdrop
        className={backdropClasses.backdrop}
        open={pullDataLoading === "pending"}
      >
        <CircularProgress color="inherit" />
        <br />
        <br />

        <div>
          {pullingCourses === "pending" && (
            <p>Pulling courses from LMS, this may take a few seconds...</p>
          )}
          {pullingAssessments === "pending" && (
            <p>Pulling assessments from LMS, this may take a few seconds...</p>
          )}
          {pullingCLOs === "pending" && (
            <p>Pulling CLOs from LMS, this may take a few seconds...</p>
          )}
        </div>
      </Backdrop>
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem xs={12} sm={12} md={8}>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t("Programs")} </span>
          </Link>
          <Link to={`/citrine/programSpec/${program && program.id}`}>
            <span className={titleClasses.title}>
              {`> ${program && program.program_degree_name}`}
            </span>
          </Link>
        </GridItem>
      </GridContainer>

      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={12}>
          <GridContainer xs={12} sm={12} md={12}>
            {content2}
          </GridContainer>
          <GridContainer style={{ border: "none", marginTop: "10px" }}>
            <div
              style={{
                padding: "10px",
                borderRadius: "0px",
                color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                background: whiteColor,
                width: "100%",
                boxShadow:
                  "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                minWidth: "0",
                wordWrap: "break-word",
                fontSize: ".875rem",
              }}
            >
              <Card>
                <GridContainer justify="space-between" alignItems="baseline">
                  <GridItem>
                    <p
                      style={{
                        color: "#fff",
                        backgroundColor: grayColor[0],
                        borderRadius: 15,
                        width: "max-content",
                      }}
                    >
                      <strong style={{ padding: 5 }}>
                        {program && program.code}
                      </strong>
                    </p>
                    <h5 style={{ color: "#8f6682" }}>
                      <span style={{ fontWeight: "bold" }}>Program: </span>
                      <span>{program && program.program_degree_name}</span>
                    </h5>
                    <h6 style={{ color: grayColor[1] }}>
                      <strong>Version </strong> {program && program.version}
                    </h6>
                  </GridItem>

                  <GridItem>
                    {!disabledStatus && (
                      <ProgramCardDropdownButton
                        id={programId}
                        data={program && program}
                        staffList={staffList}
                        ping={ping}
                        setPing={setPing}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </Card>

              <Card>
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={6}>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Coordinator")}:{" "}
                        <span
                          style={{
                            color: `${grayColor[1]}`,
                            fontWeight: "normal",
                          }}
                        >
                          {coordinator}
                        </span>
                      </div>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Interims")}:{" "}
                        <span
                          style={{
                            color: `${grayColor[1]}`,
                            fontWeight: "normal",
                          }}
                        >
                          {program &&
                          program.interims_detail &&
                          program.interims_detail.length > 0 ? (
                            program.interims_detail.map((interim) => (
                              <span className={cardClasses.cardProductTitle}>
                                {`${interim.first_name}  ${interim.last_name}`}
                              </span>
                            ))
                          ) : (
                            <>Undefined</>
                          )}
                        </span>
                      </div>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Field")}:{" "}
                        <span
                          style={{
                            color: `${grayColor[1]}`,
                            fontWeight: "normal",
                          }}
                        >
                          {program &&
                            program.fieldsSpec_detail &&
                            program.fieldsSpec_detail[0] &&
                            program.fieldsSpec_detail[0].name}
                        </span>
                      </div>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Category")}:{" "}
                        <span
                          style={{
                            color: `${grayColor[1]}`,
                            fontWeight: "normal",
                          }}
                        >
                          {program &&
                            program.category_of_the_program_details &&
                            program.category_of_the_program_details[0] &&
                            program.category_of_the_program_details[0].name}
                        </span>
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("University")}:{" "}
                        <span
                          style={{
                            color: `${grayColor[1]}`,
                            fontWeight: "normal",
                          }}
                        >
                          {program &&
                            program.university_detail &&
                            program.university_detail[0] &&
                            program.university_detail[0].name}
                        </span>
                      </div>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Colleges")}:{" "}
                        {program &&
                          program.college_detail &&
                          program.college_detail.map((college, index) => (
                            <span
                              style={{
                                color: `${grayColor[1]}`,
                                fontWeight: "normal",
                              }}
                              key={index}
                            >
                              {college.name}
                              {index !== program.college_detail.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                      </div>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Branches")}:{" "}
                        {program &&
                          program.branches_details &&
                          program.branches_details.map((br, index) => (
                            <span
                              style={{
                                color: `${grayColor[1]}`,
                                fontWeight: "normal",
                              }}
                              key={index}
                            >
                              {br.name}
                              {index !== program.branches_details.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                      </div>
                      <div
                        style={{
                          color: `${primaryColor[0]}`,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Departments")}:{" "}
                        {program &&
                          program.department_detail &&
                          program.department_detail.map((department, index) => (
                            <span
                              style={{
                                color: `${grayColor[1]}`,
                                fontWeight: "normal",
                              }}
                              key={index}
                            >
                              {department.name}
                              {index !== program.department_detail.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                      </div>
                    </GridItem>
                  </GridContainer>
                  <br></br>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      padding: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    <div
                      style={{
                        color: `${primaryColor[0]}`,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Goals")}:{" "}
                      <span
                        style={{
                          color: `${grayColor[1]}`,
                          fontWeight: "normal",
                        }}
                      >
                        {program && program.goals}
                      </span>
                    </div>
                  </GridContainer>
                  <br></br>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      padding: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    <div
                      style={{
                        color: `${primaryColor[0]}`,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Mission")}:{" "}
                      <span
                        style={{
                          color: `${grayColor[1]}`,
                          fontWeight: "normal",
                        }}
                      >
                        {program && program.mission}
                      </span>
                    </div>
                  </GridContainer>
                  <br></br>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      padding: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    <div
                      style={{
                        color: `${primaryColor[0]}`,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Criteria")}:{" "}
                      <span
                        style={{
                          color: `${grayColor[1]}`,
                          fontWeight: "normal",
                        }}
                      >
                        {program && program.criteria}
                      </span>
                    </div>
                  </GridContainer>
                  <br></br>
                  <GridContainer
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "rgb(119 119 119 / 8%)",
                    }}
                  >
                    <GridItem
                      style={{
                        color: `${primaryColor[0]}`,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Admission requirements")}:{" "}
                      {program &&
                        program.admissionsSpec_detail &&
                        program.admissionsSpec_detail.map((admission) => {
                          return (
                            <div
                              style={{
                                color: `${grayColor[1]}`,
                                fontWeight: "normal",
                              }}
                            >
                              *{admission.title}
                            </div>
                          );
                        })}
                    </GridItem>
                  </GridContainer>
                  <br></br>
                  <GridContainer
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "rgb(119 119 119 / 8%)",
                    }}
                  >
                    <GridItem
                      style={{
                        color: `${primaryColor[0]}`,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Graduation requirements")}:{" "}
                      {program &&
                        program.graduationsSpec_detail &&
                        program.graduationsSpec_detail.map((graduation) => {
                          return (
                            <div
                              style={{
                                color: `${grayColor[1]}`,
                                fontWeight: "normal",
                              }}
                            >
                              *{graduation.title}
                            </div>
                          );
                        })}
                    </GridItem>
                    <br />
                  </GridContainer>
                </CardBody>
              </Card>
              <GridContainer style={{ marginBottom: "20px" }}>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/programSpec/${program && program.id}/courses`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>{t("Courses")}</h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {program && program.coursesSpec_count} {""}
                          {""} {t("Course")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/programSpec/${program && program.id}/PLOs`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>
                          {t("Program learning Outcomes")}
                        </h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {program && program.plosSpec_count}
                          {""} {t("PLOs")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/programSpec/${program && program.id}/PEOs`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>
                          {t("Program Educational Objectives")}
                        </h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {program && program.peosSpec_count} {""}
                          {t("PEOs")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/programSpec/${
                      program && program.id
                    }/GraduateAttributes`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>
                          {t("Graduate Attributes")}
                        </h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {GraduateAttributes && GraduateAttributes.length}
                          {""} {t("GAs")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/programSpec/${program && program.id}/KPIs`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>
                          {t("Key Performance Indicators")}
                        </h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {KPIs && KPIs.length} {""}
                          {t("KPIs")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/specification/program/${
                      program && program.id
                    }/jobs`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>{t("Jobs")}</h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {jobs && jobs.length}
                          {""} {t("Jobs")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/specification/program/${
                      program && program.id
                    }/majorTracks`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>
                          {t("Major Tracks")}
                        </h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {majorTracks && majorTracks.length}
                          {t("Major Tracks")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/specification/program/${
                      program && program.id
                    }/exitPoints`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>
                          {t("Exit Points")}
                        </h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {exitPoints && exitPoints.length}
                          {""} {t("Exit Points")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Link
                    to={`/citrine/specification/program/${
                      program && program.id
                    }/surveyTemplates`}
                  >
                    <Card
                      style={{
                        minHeight: "150px",
                        border: "1px solid lightgray",
                      }}
                    >
                      <CardHeader>
                        <h4 className={classes.cardCategory}>
                          {t("Survey Templates")}
                        </h4>
                        <br />
                        <h5 className={classes.cardTitle}>
                          {`${program?.survey_count} `}
                          {t("templates")}
                        </h5>
                      </CardHeader>
                    </Card>
                  </Link>
                </GridItem>
              </GridContainer>
              <br></br>
              <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
              />
            </div>
          </GridContainer>
        </GridItem>
      </GridContainer>

      <GridItem key={Element.id} xs={12} sm={12} md={12}>
        <div
          style={{
            padding: "10px",
            borderRadius: "0px",
            color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
            background: whiteColor,
            width: "100%",
            boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            minWidth: "0",
            wordWrap: "break-word",
            fontSize: ".875rem",
            //height: 650,
            border: "1px solid lightgray",
          }}
        >
          {renderRow()}
        </div>
      </GridItem>
    </div>
  );
}
