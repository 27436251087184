import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import Search from '@material-ui/icons/Search'
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, notification } from 'antd'
import { grayColor, infoColor } from 'assets/jss/material-dashboard-pro-react'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  deleteKPIConfig,
  fetchKPIs,
} from '../../../../../reduxToolkit/features/KPI/KPIConfigSlice'
import ErrorModal from '../../../../Components/ErrorModal'
import SuperGeneralDeleteModal from '../../../../Components/SuperGeneralDeleteModal'
import { Empty } from 'antd'
import DropdownKPIs from './DropDownKPIs'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const KPIConfigExcerpt = ({ KPI }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()
  const cardClasses = useCardStyles()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleDeleteKPI = async (id) => {
    dispatch(deleteKPIConfig(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log('originalPromiseResult', originalPromiseResult)
        dispatch(fetchKPIs())
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          'rejectedValueOrSerializedError',
          rejectedValueOrSerializedError,
        )
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card style={{ maxHeight: '200px', minHeight: '200px' }} cardHover>
        <CardBody>
          <GridContainer alignItems="center">
            <GridItem xs={12} sm={12} md={10}>
              <Link to={`/citrine/configuration/KPI/${KPI && KPI.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  <strong style={{ color: infoColor[0] }}>{KPI.code}</strong>
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <DropdownKPIs id={KPI.id}/>
              
            </GridItem>
          </GridContainer>
          <Link to={`/citrine/configuration/KPI/${KPI && KPI.id}`}>
            <p>{`${KPI.name.substring(0, 200)}...`}</p>
          </Link>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteKPI}
        Element_ID={KPI.id}
        open={open}
        handleClose={handleClose}
      />
    </GridItem>
  )
}

export default function KPIConfigList() {
  const { t } = useTranslation()
  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const [searchFiltred, setSearchFiltred] = useState('')

  const dispatch = useDispatch()

  const KPIsStatus = useSelector((state) => state.KPIConfig.KPIsStatus)

  useEffect(() => {
    dispatch(fetchKPIs())
  }, [dispatch])

  const KPIs = useSelector((state) => state.KPIConfig.KPIs)
  console.log(KPIs, 'KPIs list')
  const filteredContent = KPIs?.filter((KPI) => {
    return searchFiltred.toLowerCase() === ''? KPI: KPI?.name?.toLowerCase()?.includes(searchFiltred) || KPI?.code?.toLowerCase()?.includes(searchFiltred)
  })

  let content

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  if (KPIsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (KPIsStatus === 'succeeded') {
    content =
      filteredContent.length > 0 ? (
        filteredContent &&
        filteredContent.map((KPI) => (
          <KPIConfigExcerpt key={KPI.id} KPI={KPI} />
        ))
      ) : (
        <h4>
          no item(s) found by <b>{searchFiltred}</b> !!
        </h4>
      )
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                {t('Configuration')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {/* <Link to={`/citrine/academicCalender/${id}/KPIProgram/${program && program.id}/KPIs`}> */}
              {t('KPIs')}
              {/* </Link> */}
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
        <GridItem>
          <CustomInput
            formControlProps={{
              className: navBarClasses.top + ' ' + navBarClasses.search,
            }}
            inputProps={{
              onChange: (event) => {
                setSearchFiltred(event.target.value)
              },
              value: searchFiltred,
              placeholder: 'Search',
              inputProps: {
                'aria-label': 'Search',
                className: navBarClasses.searchInput,
              },
            }}
          />

          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
          >
            <Search
              className={
                navBarClasses.headerLinksSvg + ' ' + navBarClasses.searchIcon
              }
            />
          </Button>
          {/* <input
            placeholder="Search"
            style={{
              backgroundColor: '#eeeeee',
              border: 'none',
              borderBottom: '1px solid #BDBDBD',
              marginRight: '50px',
              width: '250px',
              height: '30px',
            }}
            type="text"
            onChange={(e) => setSearchFiltred(e.target.value)}
            value={searchFiltred}
          /> */}
        </GridItem>
      </GridContainer>
      <Link to="/citrine/configuration/KPI/create">
        <Button color="info">{t('Add')}</Button>
      </Link>
      <GridContainer>{content}</GridContainer>
    </div>
  )
}
