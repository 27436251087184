import {
  Avatar
} from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { SchoolRounded } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Breadcrumb, Popover } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import Card from '@material-ui/core/Card';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import './galistpage.css';
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GAReportModal from "views/Components/GAReportModal";
import { fetchGraduateAttributes } from "../../../../reduxToolkit/features/GraduateAttribute/GraduateAttributeSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';


export default function GAListPage({ match }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = match.params;

  const [open, setOpen] = React.useState(false);
  const handleOpenReportModal = () => {
    setOpen(true);
  };
  const handleCloseReportModal = () => {
    setOpen(false);
  };


  useEffect(() => {
    dispatch(fetchProgramById(id));
    dispatch(fetchGraduateAttributes(id));
  }, []);


  const program = useSelector((state) => state.program.program);
  const GraduateAttributes = useSelector(
    (state) => state.GraduateAttribute.GraduateAttributes
  );

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">" params={id}>
          <Breadcrumb.Item>
            <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/program/${id}`}>
              {program &&
                program.program_degree_name &&
                program.program_degree_name}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/program/${id}/GAListPage`}
              style={{ color: "orange" }}
            >
              {t("Graduate Attributes  List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <br></br>

      <Card style={{ height: "fit-content", paddingBottom: 10 }}>
        <CardHeader
          color="info"
          icon
        >
          <GridContainer alignItems="center">

            <GridItem xs={12} sm={2} md={2}>
              <CardIcon
                color="primary"
              >
                <SchoolRounded />
              </CardIcon>
            </GridItem>

            <GridItem xs={12} sm={10} md={10}>
              <GridContainer justifyContent="space-between" alignItems="center" wrap="nowrap">
                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>{program?.code}</strong>
                  </h4>
                  <h4
                    style={{
                      color: "#6C7B9D",
                      height: "13px",
                      fontSize: "14px",
                      marginRight: "20px",
                    }}
                  >
                    <strong>
                      {program?.program_degree_name}
                    </strong>
                  </h4>
                </GridItem>

                <GridItem>
                  <h4
                    style={{
                      color: "#8F6682",
                      fontSize: "14px",
                      height: "13px",
                    }}
                  >
                    <strong> {t("University")} </strong>

                  </h4>
                  <h4
                    style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                  >
                    <strong>
                      {program?.university_detail?.map((el) => el.name)}
                    </strong>
                  </h4>
                </GridItem>

                <GridItem>
                  <CustomDropdown
                    dropPlacement="bottom-end"
                    isButtonIcon
                    dropdownList={[
                      { text: "Rerun GA Report", onClickHandler() { } },
                      { text: "Create GA Report", onClickHandler() { handleOpenReportModal() } },
                    ]}
                  />
                  {/* <p>last refreshed at : 22/02/2024</p> */}
                </GridItem>

              </GridContainer>
            </GridItem>
          </GridContainer>

        </CardHeader>
      </Card>
      <br></br>
      <GridContainer xs={12} sm={12} md={12}>
        {GraduateAttributes &&
          GraduateAttributes.map((item, i) => (
            <GridItem xs={12} sm={12} md={4} key={i}>

              <Card style={{ height: "230px" }}>
                <CardHeader>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={3}>
                      <div
                        style={{
                          backgroundColor: "#8F6682",
                          height: "60px",
                          width: "60px",
                          padding: "0px",
                          //  border: "1px solid red",
                          borderRadius: "3px",
                          textAlign: "center",
                        }}
                      >
                        <AssignmentIcon
                          style={{
                            fontSize: 50,
                            color: "aliceblue",
                            marginTop: "10%",
                          }}
                        />
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={9}>
                      <strong>
                        {t("GA")} {i}
                      </strong>
                      <Link to={`/citrine/PloListPage`}>
                        <strong>
                          <p
                            style={{
                              color: "gray",
                              textAlign: "start",
                              fontSize: "14px",
                            }}
                          >
                            <Link
                              to={`/citrine/program/${id}/GA/${item.id}`}
                            >


                              <Popover content={item.graduateAttributeConfig_details.name} trigger="hover">
                                <div>
                                  {item.graduateAttributeConfig_details.name.slice(0, 100)}...
                                </div>
                              </Popover>


                              {/* <div style={{ overflow: 'auto' }}>
                                 <Tooltip
                                  id="tooltip-top"
                                  title={item.name}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                  style={{ fontSize: "14px" }}
                                >
                                  
                                </Tooltip>
                              </div> */}
                            </Link>
                          </p>
                        </strong>
                      </Link>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  {/* {program && program.assessment_calculation_method === "clo" ? ( */}
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginEnd: "10px",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              backgroundColor: red[600],
                              height: 56,
                              width: 56,
                            }}
                            style={{ backgroundColor: "#6C7B9D" }}
                          >
                            {item.target}
                          </Avatar>
                          <p style={{ marginTop: "10px", fontSize: "14px" }}>
                            {" "}
                            {t("target")}
                          </p>
                        </Grid>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              backgroundColor: orange[600],
                              height: 56,
                              width: 56,
                            }}
                            style={{ backgroundColor: "#6C7B9D" }}
                          >
                            {item.gaIndicators_count}
                          </Avatar>
                          <p style={{ marginTop: "10px", fontSize: "14px" }}>
                            {t("GA Indicators")}
                          </p>
                        </Grid>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              backgroundColor: "info",
                              height: 56,
                              width: 56,
                            }}
                            style={{ backgroundColor: "#6C7B9D" }}
                          >
                            {item.clos_count}
                          </Avatar>
                          <p style={{ marginTop: "10px", fontSize: "14px" }}>
                            {" "}
                            {t("Aligned CLOs")}
                          </p>
                        </Grid>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              backgroundColor: green[600],
                              height: 56,
                              width: 56,
                            }}
                            style={{ backgroundColor: "#6C7B9D" }}
                          >
                            {item.plo_score}
                          </Avatar>
                          <p style={{ marginTop: "10px", fontSize: "14px" }}>
                            {" "}
                            {t("Score")}
                          </p>
                        </Grid>
                      </div>
                    </GridItem>
                  {/* ) :
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginEnd: "10px",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              backgroundColor: red[600],
                              height: 56,
                              width: 56,
                            }}
                            style={{ backgroundColor: "#6C7B9D" }}
                          >
                            {item.target}
                          </Avatar>
                          <p style={{ marginTop: "10px", fontSize: "14px" }}>
                            {" "}
                            {t("Target")}
                          </p>
                        </Grid>

                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              backgroundColor: "info",
                              height: 56,
                              width: 56,
                            }}
                            style={{ backgroundColor: "#6C7B9D" }}
                          >
                            {item.plos_count}
                          </Avatar>
                          <p style={{ marginTop: "10px", fontSize: "14px" }}>
                            {" "}
                            {t("Aligned PLOs")}
                          </p>
                        </Grid>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              backgroundColor: green[600],
                              height: 56,
                              width: 56,
                            }}
                            style={{ backgroundColor: "#6C7B9D" }}
                          >
                            {item.score_by_question && item.score_by_question.toFixed(0)}%
                          </Avatar>
                          <p style={{ marginTop: "10px", fontSize: "14px" }}>
                            {" "}
                            {t("Score GA")}
                          </p>
                        </Grid>
                      </div>
                    </GridItem>
                  } */}
                </CardBody>
              </Card>

            </GridItem>
          ))}
      </GridContainer>
      <GAReportModal
        ProgramID={program && program.id && program.id}
        open={open}
        handleCloseReportModal={handleCloseReportModal}
      />
    </div>
  );
}
