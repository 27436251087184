import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
// core components
import { unwrapResult } from "@reduxjs/toolkit";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchPeoById } from "../../../../reduxToolkit/features/PEO/PEOSpecSlice";
// import { fetchPlos } from '../../../../reduxToolkit/features/PLO/PLOSpecSlice'
import { deletePeoIndicator } from "../../../../reduxToolkit/features/PEOIndicator/PEOIndicatorSpecSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import PEOIndicatorsModal from "../../../Components/PEOIndicatorsModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";






// import ProgramCardDropdownButton from "../Components/ProgramCardDropdownButton"
const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const PEOIndicatorsByPEOExcerpt = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();

  const dispatch = useDispatch();

  const handleDeletePeoIndicator = (id) => {
    dispatch(deletePeoIndicator(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log("originalPromiseResult", originalPromiseResult);
      })
      .then(() => {
        // do additional work
        dispatch(fetchPeoById(props.PEOId));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <GridItem
      xs={12}
      sm={12}
      md={10}
    // key={program.id}
    >
      <Card className={cardClasses.noMarginButtom}>
        <CardHeader>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <h4 className={cardClasses.cardProductTitle}>
                <div> {props.peoInd.name} </div>
              </h4>
              <div>
                {props.peoInd.plosSpec &&
                  props.peoInd.plosSpec.map((plo) => <div> * {plo.name}</div>)}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <Button
                variant="contained"
                color="primary"
                justIcon
                round
                onClick={handleClickOpen}
              >
                <DeleteIcon />
              </Button>

              <SuperGeneralDeleteModal
                delete_Element={handleDeletePeoIndicator}
                Element_ID={props.peoInd.id}
                open={open}
                handleClose={handleClose}
              />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody></CardBody>
      </Card>
    </GridItem>
  );
};

export default function PEOIndicatorsByPEO() {
  const { t } = useTranslation();

  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const cardClasses = useCardStyles();

  let { programId } = useParams();
  let { PEOId } = useParams();

  const dispatch = useDispatch();
  const peoStatus = useSelector((state) => state.peoSpec.peoStatus);
  const error = useSelector((state) => state.peoSpec.error);
  const plosStatus = useSelector((state) => state.ploSpec.plosStatus);

  useEffect(() => {
    dispatch(fetchPeoById(PEOId));
    dispatch(fetchProgramById(programId));
  }, [dispatch]);

  const peo = useSelector((state) => state.peoSpec.peo);
  const program = useSelector((state) => state.programSpec.program);

  console.log("peo", peo);

  let content;

  if (peoStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (peoStatus === "succeeded") {
    if (peo && peo.peoIndicatorsSpec && peo.peoIndicatorsSpec.length) {
      content = peo.peoIndicatorsSpec.map((peoInd) => (
        <PEOIndicatorsByPEOExcerpt peoInd={peoInd} PEOId={PEOId} />
      ));
    } else {
      content = <h5>{t("This PEO has no PEO Indicators to display!")}</h5>;
    }
  } else if (peoStatus === "failed") {
    content = <div>{error}</div>;
  }
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t("Programs")} </span>
          </Link>
          <Link to={`/citrine/programSpec/${program && program.id}`}>
            <span className={titleClasses.title}>
              {" "}
              {`> ${program && program.program_degree_name}`}{" "}
            </span>
          </Link>
          <Link to={`/citrine/programSpec/${program && program.id}/PEOs`}>
            <span className={titleClasses.title}>{t(`> PEOs`)} </span>
          </Link>
          <Link
            to={`/citrine/programSpec/${program && program.id}/PEOs/${peo && peo.id
              }/PEOIndicators`}
          >
            <span className={titleClasses.title}>
              {`> ${peo && peo.code} `}{" "}
            </span>
          </Link>
          <span className={titleClasses.title}> {t("> PEO Indicators")} </span>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <PEOIndicatorsModal
            peo={peo && peo}
            programId={programId}
            PEOId={PEOId}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>{content}</GridContainer>
    </div>
  );
}
