import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import { notification } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteActionTypes, fetchActionTypes } from '../../../../reduxToolkit/features/actionType/ActionTypeSlice.js'
import Button from '../../../../components/CustomButtons/Button'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import { unwrapResult } from '@reduxjs/toolkit'
import EditActionType from './EditActionType.jsx'

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))
export default function ActionTypeDropDown({ actionTypes }) {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState(false)
    const [openErrorModal, setOpenErrorModal] = React.useState(false)
    const [handledError, setHandledError] = React.useState('')
    const [openEdit, setOpenEdit] = React.useState(false)
    const handleOpenEvidence = () => setOpenModalEvidence(true)
    const { t } = useTranslation()
    const [openModalEvidence, setOpenModalEvidence] = useState(false)

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true)
    }
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false)
    }
    const handleOpenDeleteModal = () => {
        setOpen(true)
    }
    const handleCloseDeleteModal = () => {
        setOpen(false)
    }
    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        })
    }
    const handleDeleteItem = async (id) => {
        try {
            const resultAction = await dispatch(deleteActionTypes(id))
            unwrapResult(resultAction)
            //setPing(!ping)
            handleClose()
            openNotificationWithIcon('success', `Deleted successfully`)
            dispatch(fetchActionTypes())
        } catch (err) {
            setHandledError(err)
            handleOpenErrorModal()
        }
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOpenEditModal = () => {
        setOpenEdit(true)
    }

    const handleCloseEditModal = () => {
        setOpenEdit(false)
    }
    return (
        <div>
            <Button
                aria-controls="customized-menu"
                variant="contained"
                color="primary"
                onClick={handleClick}
                justIcon
                round
                aria-haspopup="true"
            >
                <MoreVert />
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* <Link to={`/citrine/configuration/others/EditAuthority/${authority.id}`}> */}

                <MenuItem
                    onClick={() => {
                        handleOpenEditModal()
                        handleClose()
                    }}
                >
                    {t('Edit')}
                </MenuItem>
                {/* </Link> */}
                <MenuItem
                    onClick={() => {
                        handleOpenDeleteModal()
                        handleClose()
                    }}
                >
                    <ListItemText primary="Delete" />
                </MenuItem>
            </StyledMenu>
            {/* <EditAuthority
      authority={authority}
      open={openEdit}
      handleClose={handleCloseEditModal}
      handleOpenEditModal={handleOpenEditModal}
      ping={ping}
      setPing={setPing}
    /> */}
            <EditActionType actionTypes={actionTypes} open={openEdit} handleClose={handleCloseEditModal} handleOpenEditModal={handleOpenEditModal} />
            <SuperGeneralDeleteModal
                delete_Element={handleDeleteItem}
                Element_ID={actionTypes.id}
                open={open}
                handleClose={handleCloseDeleteModal}
            />
        </div>
    )
}
