

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  resourceTypeConfigs: [],
  resourceTypeConfigsStatus: 'idle',
  resourceTypeConfigStatus: 'idle',
  error: null,
  resourceTypeConfig:null,
  id:null
}

export const fetchResourceTypeConfigs = createAsyncThunk('resourceTypeConfig/fetchResourceTypeConfigs', async (param, {rejectWithValue}) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + '/resourceTypeConfig/', {
      headers: {
        'Authorization': `Token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

export const fetchResourceTypeConfigById = createAsyncThunk('resourceTypeConfig/fetchResourceTypeConfigById', async (id, {rejectWithValue}) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/resourceTypeConfig/${id}/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

export const addNewResourceTypeConfig = createAsyncThunk('resourceTypeConfig/addNewResourceTypeConfig', async (initialResourceTypeConfig, {rejectWithValue}) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.post(BaseUrl + `/resourceTypeConfig/create/`, initialResourceTypeConfig, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

export const resourceTypeConfigUpdate = createAsyncThunk('resourceTypeConfig/resourceTypeConfigUpdate', async (data, {rejectWithValue}) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.put(BaseUrl + `/resourceTypeConfig/${data.id}/edit/`, data.modified, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

export const deleteResourceTypeConfig = createAsyncThunk('resourceTypeConfig/deleteResourceTypeConfig', async (id, {rejectWithValue}) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/resourceTypeConfig/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

const  resourceTypeConfigsSlice = createSlice({
  name: ' resourceTypeConfig',
  initialState,
  extraReducers: {
    [fetchResourceTypeConfigs.pending]: (state, action) => {
        state.resourceTypeConfigsStatus = 'loading'
    },
    [fetchResourceTypeConfigs.fulfilled]: (state, action) => {
        state.resourceTypeConfigsStatus = 'succeeded'
        state.resourceTypeConfigs = action.payload
    },
    [fetchResourceTypeConfigs.rejected]: (state, action) => {
        state.resourceTypeConfigsStatus = 'failed'
        if (action.payload) {
          state.error = action.payload
        } else {
          state.error = action.error
        }
    },

    [fetchResourceTypeConfigById.pending]: (state, action) => {
        state.resourceTypeConfigStatus = 'loading'
    },
    [fetchResourceTypeConfigById.fulfilled]: (state, action) => {
        state.resourceTypeConfigStatus = 'succeeded'
        state.resourceTypeConfig = action.payload
    },
    [fetchResourceTypeConfigById.rejected]: (state, action) => {
        state.resourceTypeConfigStatus = 'failed'
        if (action.payload) {
          state.error = action.payload
        } else {
          state.error = action.error
        }
    },

    [addNewResourceTypeConfig.fulfilled]: (state, action) => {
        state.resourceTypeConfigs.push(action.payload)
    },
    [addNewResourceTypeConfig.rejected]: (state, action) => {
      state.resourceTypeConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [resourceTypeConfigUpdate.fulfilled]: (state, action) => {},
    [resourceTypeConfigUpdate.rejected]: (state, action) => {
      state.resourceTypeConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteResourceTypeConfig.fulfilled]: (state, action) => {
        state.resourceTypeConfigStatus = 'succeeded'
        state.resourceTypeConfigs = state.resourceTypeConfigs.splice(state.resourceTypeConfigs.findIndex((arrow) => arrow.id === action.payload), 1);
    },
    [deleteResourceTypeConfig.rejected]: (state, action) => {
      state.resourceTypeConfigStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

  reducers: {
    getId: (state, action) => {
      state.id= action.payload
    },
    resourceTypeConfigUpdated(state, action) {
      const { id,name } = action.payload
      const existingResourceTypeConfig = state.resourceTypeConfigs.find(resourceTypeConfig => resourceTypeConfig.id === id)
      if (existingResourceTypeConfig) {
        existingResourceTypeConfig.name = name
      }
    },
  }
}})

export const { resourceTypeConfigAdded, resourceTypeConfigUpdated, getId} = resourceTypeConfigsSlice.actions

export default resourceTypeConfigsSlice.reducer

export const selectAllResourceTypeConfigs = state => state.resourceTypeConfig.resourceTypeConfigs
