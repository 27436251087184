import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import React, { useEffect } from 'react';
// core components
import { grayColor, primaryColor } from "assets/jss/material-dashboard-pro-react";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  fetchStaffById
} from "../../../../../reduxToolkit/features/user/UserSlice";

import { Breadcrumb } from "antd";
import {
  fetchResearchAndInnovationActivities
} from "../../../../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice";
import ErrorModal from "../../../../Components/ErrorModal";


import ResearchAndInnovationDropdownButton from 'views/Components/ResearchAndInnovationDropdown';




const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const ResearchAndInnovationExcerpt = ({ research, personId,ping,setPing }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch()

  return (


    <GridItem xs={12} sm={12} md={4} key={research.id}>
      <Card style={{ maxHeight: "340px", minHeight: "340px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
          //alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/faculty/teachingStaff/${personId}/researchAndInnovationActivity/${research.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {research && research.title}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <ResearchAndInnovationDropdownButton personId ={personId} id={research.id} program={research} ping={ping} setPing={setPing} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Type Of Research")}:
            <span style={{ fontWeight: "normal" }}> {research && research.type && research.type} </span>
          </div>
          <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
            {t("Authors ")}:
            <span style={{ fontWeight: "normal" }}>
              {/* {`${research.authors_details[0]?.first_name} ${research.authors_details[0]?.last_name}`} */}
              {research && research.authors_details[0] && research.authors_details[0]?.first_name + ' '+research.authors_details[0]?.last_name }
              {/* {research && research.authors_details[0] && research.authors_details[0]?.last_name } */}
              
            </span>
          </div>
          {/* <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
      {t("Institution")}:
      <span style={{ fontWeight: "normal" }}>
       {research && research.institution}
      </span>
    </div>
    <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
      {t("Year")}:
      <span style={{ fontWeight: "normal" }}>
{research.academicYear_details && research.academicYear_details.name && research.academicYear_details.name}
      </span>
    </div> */}

        </CardBody>
        <CardFooter>
          <GridContainer xs={2} sm={2} md={12} >
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "start" }}>
              <span
                style={{
                  color: "#FFCB3B",
                }}
              >

              </span>
            </GridItem>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "end" }}>
              <span
                style={{
                  color: research.state === "Draft" ? grayColor[1] : "#FFCB3B",
                }}
              >
                {research && research.state}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

export default function ResearchAndInnovation({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const { id } = match.params;

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [ping, setPing] = React.useState(false);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchRAndIActivitiesCustomFunction = async (id) => {
    try {
      const resultAction = await dispatch(fetchResearchAndInnovationActivities(id)).unwrap();

      console.log("success");
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    dispatch(fetchStaffById(id));
    fetchRAndIActivitiesCustomFunction(id)
    // dispatch(fetchResearchAndInnovationActivities(id))

  }, [ping])

  const person = useSelector((state) => state.user.staff);
  const researchAndInnovationActivities = useSelector((state) => state.researchAndInnovationActivities.researchAndInnovationActivities);
  const researchAndInnovationActivitiesStatus = useSelector((state) => state.researchAndInnovationActivities.researchAndInnovationActivitiesStatus);
  console.log('researchAndInnovationActivities', researchAndInnovationActivities)

  let content

  if (researchAndInnovationActivitiesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (researchAndInnovationActivitiesStatus === 'succeeded') {

    content = researchAndInnovationActivities && researchAndInnovationActivities.map(research => (
      <ResearchAndInnovationExcerpt ping={ping} setPing={setPing} key={research.id} research={research} personId={id} />
    ))

  } else if (researchAndInnovationActivitiesStatus === 'failed') {
    content = <div>An error has accured</div>
  }



  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff`}>
            {t("Teaching Staff")}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff/${id}`}>
            {`${person && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`}>
            {t("Research and innovation")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "220px"
      }}>
        <Link
          //to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities/create`} 
          to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities/create`}

        >
          <Button color="primary" style={{
            width: "100px"
          }}>
            {t(' Add')}
          </Button>
        </Link>
        {/* <ImportScopusModal person_id={person.id} /> */}

      </div>
      {/* <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={11}></GridItem>
        <GridItem key={Element.id} xs={12} sm={12} md={1}>
          <ImportScopusModal person_id={person.id} 
            
          />
        </GridItem>
      </GridContainer> */}
      <GridContainer
      >
        {
          content
        }
      </GridContainer>





      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}


