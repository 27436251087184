// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import {
  fetchConclusionsDifficultiesByCourseId_And_Type,
  UpdateDifficultiesAction,
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";

import { useDispatch, useSelector } from "react-redux";
import EditCommentsStudentResults from "../../../../Components/EditCommentsStudentResults";



import {
  warningColor,
  primaryColor,
} from "../../../../../assets/jss/material-dashboard-pro-react"




const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
// const classes = useStyles();
export default function Comments(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = React.useState(1);

  const titleClasses = useTitleStyles();

  // console.log('idCourse', props.idCourse)
  const [course_id, setCourse_id] = useState(props.idCourse);
  const [Comment, setComment] = useState("");

  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [array_difficulties_SuggestedAction, setArray_difficulties_SuggestedAction] = useState([]);

  let object = {
    course_id: props.idCourse,
    type: "SR",
  }
  useEffect(() => {
    dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
      .then(unwrapResult)
      .then((difficulties_SuggestedAction) => {
        setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
      });
  }, []);
  const difficulties_SuggestedAction = useSelector(
    (state) => state.ConclusionsDifficulties.ConclusionsDifficultiesList
  );
  const fetchConclusionsDifficulties = () => {
    dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
      .then(unwrapResult)
      .then((difficulties_SuggestedAction) => {
        setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
      });
  }






  return (
    <GridContainer xs={12} sm={12} md={12}>
      <div style={{ width: "100%" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ marginRight: "10px" }}>
            <CardBody>
              <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={10}>
                  <p style={{ height: "150px" }}>
                    <strong style={{ color: primaryColor[0] }}>Comment Students Results</strong> :
                    <p>{array_difficulties_SuggestedAction && array_difficulties_SuggestedAction[0] && array_difficulties_SuggestedAction[0].comment_students_results}</p>
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{ textAlign: "end" }}>
                  <EditCommentsStudentResults idCourse={props.idCourse} fetchConclusionsDifficulties={fetchConclusionsDifficulties} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </div>
    </GridContainer>
  );
}
