import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  actionsApprovalProcessConfig: [],
  actionsApprovalProcessStatusConfig: 'idle',
  actionApprovalProcessConfig: null,
  actionsApprovalProcessStatusConfig: 'idle',
  error: null,
}

export const fetchActionsApprovalProcessConfig = createAsyncThunk(
  'actionApprovalProcess/fetchActionsApprovalProcessConfig',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(BaseUrl + `/actionApprovalProcess/`, {
        headers: { Authorization: `token ${token}` },
      })
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchActionApprovalProcessConfigById = createAsyncThunk(
  'actionApprovalProcess/fetchActionApprovalProcessConfigById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(
        BaseUrl + `/actionApprovalProcess/${id}/`,
        {
          headers: { Authorization: `token ${token}` },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewActionApprovalProcessConfig = createAsyncThunk(
  'actionApprovalProcess/addNewActionApprovalProcessConfig',
  async (authority, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.post(
        BaseUrl + `/actionApprovalProcess/create/`,
        authority,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteActionApprovalProcessConfig = createAsyncThunk(
  'actionApprovalProcess/deleteActionApprovalProcessConfig',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.delete(
        BaseUrl + `/actionApprovalProcess/${id}/delete/`,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateActionApprovalProcessConfig = createAsyncThunk(
  'actionApprovalProcess/updateActionApprovalProcessConfig',
  async (authority, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.put(
        BaseUrl + `/actionApprovalProcess/${authority.id}/edit/`,
        authority,
        { headers: { Authorization: `token ${token}` } },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const ActionApprovalProcessConfigSlice = createSlice({
  name: 'actionApprovalProcessConfig',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchActionsApprovalProcessConfig.pending]: (state, action) => {
      state.actionsApprovalProcessStatusConfig = 'loading'
    },
    [fetchActionsApprovalProcessConfig.fulfilled]: (state, action) => {
      state.actionsApprovalProcessStatusConfig = 'succeeded'
      state.actionsApprovalProcessConfig = action.payload
    },
    [fetchActionsApprovalProcessConfig.rejected]: (state, action) => {
      state.actionsApprovalProcessStatusConfig = 'failed'
      state.error = action.payload ? action.payload : action.error
    },

    [fetchActionApprovalProcessConfigById.pending]: (state, action) => {
      state.actionsApprovalProcessStatusConfig = 'loading'
    },
    [fetchActionApprovalProcessConfigById.fulfilled]: (state, action) => {
      state.actionsApprovalProcessStatusConfig = 'succeeded'
      state.actionApprovalProcessConfig = action.payload
    },
    [fetchActionApprovalProcessConfigById.rejected]: (state, action) => {
      state.actionsApprovalProcessStatusConfig = 'failed'
      state.error = action.payload ? action.payload : action.error
    },
  },
})
export default ActionApprovalProcessConfigSlice.reducer
