import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewCourseReport,
  fetchCourseReports,
} from "../../reduxToolkit/features/CourseImprovement/courseImprovementSlice";

//import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import { notification, Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorModal from "./ErrorModal.jsx";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

export default function CourseReportModal({ open, setOpen, courseID }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [type, setType] = React.useState("NCAAA");

  const handleChange = (event) => {
    console.log(event.target.value);
    setType(event.target.value);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  //add
  const handleSend = async () => {
    const resultAction = await dispatch(
      addNewCourseReport({ course_id: courseID })
    );
    if (addNewCourseReport.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", ` successfully Added`);
      history.push(`/citrine/reports/${type}/course/`);
      dispatch(fetchCourseReports());
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);

        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);

        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"> Create Course Report</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <h4>Accreditation body</h4>
            <RadioGroup
              aria-label="Accreditation body"
              name="accreditation_body"
              value={type}
              onChange={handleChange}
            >
              <FormControlLabel
                value="NCAAA"
                control={<Radio size="small" color="primary" />}
                label="NCAAA"
              />
              <FormControlLabel
                value="CTI"
                control={<Radio size="small" color="primary" />}
                label="CTI"
              />
              <FormControlLabel
                value="ABET"
                control={<Radio size="small" color="primary" />}
                label="ABET"
              />
            </RadioGroup>
          </FormControl>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("Cancel")}
            </Button>
            <Button onClick={handleSend} color="primary">
              {t("Confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
