import { CheckOutlined } from '@ant-design/icons'
import { TableBody, TableCell, TableRow } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Close from '@material-ui/icons/Close'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import GridContainer from 'components/Grid/GridContainer.js'
// core components
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { deleteActionRecommendation } from '../../../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice'
import {
  deleteRecommendation,
  fetchCourseRecommendations,
} from '../../../../../reduxToolkit/features/Recommendations/RecommendationsSlice'
import { fetchStaff } from '../../../../../reduxToolkit/features/user/UserSlice'
import AddCourseRecommendationModal from '../../../../Components/AddCourseRecommendationModal'
import CourseActionRecommendationModal from '../../../../Components/CourseActionRecommendationModal'
import CourseReportRecommendationEditModal from '../../../../Components/CourseReportRecommendationEditModal'
import EditCourseRecommendationModal from '../../../../Components/EditCourseRecommendationModal'
import useTableRecommendation from '../../../../Components/useTableRecommendation'
import '../../improvement.css'
import StarIcon from '@material-ui/icons/Star'
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center',
  },
  cardCategory: {
    margin: '0',
    color: '#999999',
  },
}

const useStyles = makeStyles(styles)

const headRecommendationsCells = [
  { id: 'Recommendations', label: 'Recommendations' },
  { id: 'Actions', label: 'Actions' },
  { id: 'Edit/Delete', label: '' },
]

export default function CourseReportRecommendation(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [records, setRecords] = []
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })
  const [ping, setPing] = useState(false)
  const {
    TblRecommendationContainer,
    TblRecommendationHead,
  } = useTableRecommendation(records, headRecommendationsCells, filterFn)
  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
        </div>
      )
    } else if (value == 2) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
        </div>
      )
    } else if (value == 3) {
      return (
        <div style={{ display: 'flex' }}>
          <StarIcon style={{ fontSize: '20px', color: 'gold' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
          <StarIcon style={{ fontSize: '20px', color: 'silver' }} />
        </div>
      )
    }
  }
  useEffect(() => {
    dispatch(fetchCourseRecommendations(props.course_id))
    dispatch(fetchStaff())
  }, [ping, dispatch])

  const CourseRecommendations = useSelector(
    (state) => state.Recommendations.CourseRecommendations,
  )
  const users = useSelector((state) => state.user.staffList)
  console.log(CourseRecommendations, 'CourseRecommendations')

  const delete_CourseRecommendations = (id) => {
    dispatch(deleteRecommendation(id)).then(() => {
      dispatch(fetchCourseRecommendations(props.course_id))
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const delete_Recommendation = (id) => {
    dispatch(deleteActionRecommendation(id)).then(() => {
      dispatch(fetchCourseRecommendations(props.course_id))
    })
  }
  return (
    <div>
      <div style={{ marginLeft: '20px' }}>
        <AddCourseRecommendationModal
          handleDropDownButtonClose={handleClose}
          course_id={props.course_id}
          resource={'course'}
          resource_id={props.course_id}
        />
      </div>
      <div
        style={{
          margin: '0px 10px 10px 10px ',
          borderRight: '1px solid lightgray',
          borderLeft: '1px solid lightgray',
        }}
      >
        <TblRecommendationContainer
          style={{ marginTop: '0px', paddingTop: '0px' }}
        >
          <TblRecommendationHead />
          <TableBody>
            {CourseRecommendations &&
              CourseRecommendations.map((item) => (
                <TableRow key={item.id}>
                  <TableCell
                    style={{
                      borderRight: '1px solid lightgray',
                    }}
                  >
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={11}>
                        <p
                          style={{
                            display: '-webkit-box',
                            maxWidth: '200px',
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                          }}
                        >
                          {item && item.recommendation}
                        </p>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={1}>
                        <EditCourseRecommendationModal
                          recommendation_id={item.id}
                          course_id={props.course_id}
                        />
                      </GridItem>
                    </GridContainer>
                  </TableCell>

                  <TableCell
                    className="multipleLinePlo"
                    style={{
                      width: '490px',
                      borderRight: '1px solid lightgray',
                    }}
                  >
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={11}></GridItem>

                      <GridItem xs={12} sm={12} md={1}>
                        <CourseActionRecommendationModal
                          handleDropDownButtonClose={handleClose}
                          recommendation_id={item.id}
                          course_id={props.course_id}
                          users={users}
                          CourseReport={props.CourseReport}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer xs={12} sm={12} md={12}>
                      {item &&
                        item.action_recommendations &&
                        item.action_recommendations.map((element) => (
                          <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={10}>
                              <strong>
                                <CheckOutlined />{' '}
                                {element.type_action && element.type_action} :
                              </strong>
                              {element.text && element.text}
                              <br></br>
                              <strong> Assigned to : </strong>
                              {element.assign_to}
                              <br></br>
                              <strong> Start date : </strong>
                              {element.start_date && element.start_date}
                              <br></br>

                              <strong> Due date : </strong>
                              {element.endDate && element.endDate}
                              <br></br>
                              <strong>
                                {' '}
                                Priority :{' '}
                                {RaitingRender(element && element.priorityId)}
                              </strong>

                              <br></br>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={1}>
                              <CourseReportRecommendationEditModal
                                action={element}
                                handleDropDownButtonClose={handleClose}
                                recommendation_id={item.id}
                                course_id={props.course_id}
                                users={users}
                                CourseReport={props.CourseReport}
                                ping={ping}
                                setPing={setPing}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={1}>
                              <DeleteOutlineIcon
                                onClick={() =>
                                  delete_Recommendation(element.id)
                                }
                                style={{
                                  color: 'red',
                                  //fontSize: '16px',
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        ))}
                    </GridContainer>
                  </TableCell>

                  <TableCell
                    className={classes.tableActions}
                    style={{
                      borderRight: '1px solid lightgray',
                    }}
                  >
                    <Tooltip
                      id="tooltip-top-start"
                      title="Remove"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Close"
                        style={{
                          color: 'red',
                          fontSize: '10px',
                        }}
                        className={classes.tableActionButton}
                        onClick={() => delete_CourseRecommendations(item.id)}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' '
                            // classes.close
                          }
                          style={{
                            fontSize: '18px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblRecommendationContainer>
      </div>
    </div>
  )
}
