import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";

export default function Board(props) {

    return (
        <DragDropContext onDragEnd={props.handleDragEnd}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                    height: "96%",
                    marginTop: "10px",
                    backgroundColor: "white"

                }}
            >
                {props.columns?.colums?.map((column) => <Column key={column.id} title={column.title} tasks={column.cards} id={column.id.toString()} renderCardContent={props.renderCardContent} />)}
            </div>
        </DragDropContext>
    );
}
