import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb, notification } from 'antd'
import { Link,useLocation } from 'react-router-dom'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AddStateActionGroupControlModal from 'views/Components/AddStateActionGroupControlModal'
import ErrorModal from 'views/Components/ErrorModal'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { fetchGroupStateActions } from '../../../../reduxToolkit/features/groupStateActions/GroupStateActionsSlice'
import DropDownReportStatesActions from './DropDownReportStatesActions'
const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const GroupStateActionExcerpt = ({ ping, setPing, groupStateAction }) => {
  console.log(groupStateAction)
  const { t } = useTranslation()

  const classes = useStyles()
  const cardClasses = useCardStyles()
  const dispatch = useDispatch()

  return (
    // <GridContainer
    //   md={4}
    //   xs={12}
    //   sm={12}
    //   direction="row"
    //   justify="space-between"
    //   alignItems="center"
    // >
    <GridItem key={groupStateAction.id}>
      <Card product className={classes.cardHover}>
        <CardBody style={{ width: 375, height: 200 }}>
          <GridContainer>
            {/* < GridItem  xs={12} sm={12} md={6}>
                <img src={priceImage1} alt="..." width="100%" height="160" />
              </GridItem> */}

            <GridItem xs={12} sm={12} md={12}>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardProductTitle}>
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                      {/* <Link to={`/citrine/groupActions/states/CourseReport/actions/`} > */}
                      {groupStateAction.name}
                      {/* </Link> */}
                    {/* </a> */}
                  </h4>
                  <h4 className={classes.cardProductTitle}>
                    
                      {groupStateAction && groupStateAction.index && `Index : ${groupStateAction.index}` }
                     
                  </h4>
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  <DropDownReportStatesActions
                    ping={ping}
                    setPing={setPing}
                    id={groupStateAction.id}
                  />
                </GridItem>

                {/* <GridItem xs={12} sm={12} md={2}>
                    <DropDownGroupStateButton id={groupState.id} />
                  </GridItem> */}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
    // </GridContainer>
  )
}

export default function CourseReportStatesActions({ match }) {
  const { id, Report } = match.params
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [open, setOpen] = React.useState(false)
  const [ping, setPing] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const fetchAllGroupStatesActions = async () => {
    try {
      const resultAction = await dispatch(fetchGroupStateActions(id)).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  const groupStateActionsStatus = useSelector(
    (state) => state.groupStateAction.groupStateActionsStatus,
  )
  console.log('groupStateActionsStatus', groupStateActionsStatus)
  const groupStateActions = useSelector(
    (state) => state.groupStateAction.groupStateActions,
  )
  console.log('groupStateActions', groupStateActions)
  const error = useSelector((state) => state.groupStateAction.error)
  useEffect(() => {
    //fetchAllGroupStatesActions()
    dispatch(fetchGroupStateActions(id))
  }, [ping])
  let content =
    groupStateActions &&
    groupStateActions.map((groupStateAction) => (
      <GroupStateActionExcerpt
        key={groupStateAction.id}
        groupStateAction={groupStateAction}
        ping={ping}
        setPing={setPing}
      />
    ))

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/groupActions/`}>{t('Models')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/groupActions/states/${Report}`}>{Report}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{location?.state?.action }</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <AddStateActionGroupControlModal state={parseInt(id)} />

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
