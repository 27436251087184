import { TableBody, TableCell, TableRow } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import StarIcon from "@material-ui/icons/Star";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider, Select, Steps, Tabs } from "antd";
import "antd/dist/antd.css";
import { Tooltip } from 'antd';
import Modal from '@material-ui/core/Modal';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// core components
import { SlCalender } from "react-icons/sl";
import {
  blackColor,
  cardTitle,
  hexToRgb,
  primaryColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaHistory, FaProjectDiagram, FaTasks } from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchActionPlanById, fetchActionPlans, fetchActionPlansbyApprovalProcess, fetchActionsByResourceId, UpdateActionPlan
} from "../../../reduxToolkit/features/ActionPlan/ActionPlanSlice";
import { fetchActivities } from "../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import { fetchAllUsers } from "../../../reduxToolkit/features/user/UserSlice";
import EditAssginTOActionModal from "../../Components/EditAssginTOActionModal";
import useTable from "../../Components/useTable";
import "../../Pages/Improvement/improvement.css";
import ActionplanDetailTable from "./ActionplanDetailTable";
import ActionPlanDetailsDraft from "./ActionPlanDetailsDraft";
import ModalOpen from "./ModalOpen";

const styles2 = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles2 = makeStyles(styles2);
const { Option } = Select;
const { TabPane } = Tabs;
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);

const { Step } = Steps;
const steps = [
  {
    title: "Difficulties",
    content: "First-content",
  },
  {
    title: "Strengths && weaknesses ",
    content: "Second-content",
  },
  {
    title: "Action Plan",
    content: "Last-content",
  },
];
const headCells = [
  { id: "Actions", label: "Actions " },
  { id: "Type", label: "Type" },
  // { id: "Due Date", label: "Due Date" },
  { id: "Priority", label: "Priority" },
  // { id: "Status", label: "Status" },
  { id: "Assignto", label: "Assign To" },
];
export default function ActionPlanDetails({ match }) {
  const { t } = useTranslation();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes = useStyles();

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  const { id, ProgramId } = match.params;
  console.log("id of action plan ", id);
  const dispatch = useDispatch();
  const [current, setCurrent] = React.useState(0);
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [assign_to_id, setAssign_to_id] = useState(null);
  const [openLog, setOpenLog] = useState(true);

  const [showActivities, setShowActivities] = useState(true);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);

  const renderTasks = () => {
    setShowTasks(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowReports(false);
  };
  const renderActivities = () => {
    setShowActivities(true);
    setShowDiagram(false);
    setShowTasks(false);
    setShowReports(false);
  };
  const renderReports = () => {
    setShowReports(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
  };

  //bmpn Modal
  const handleClickOpen = () => {
    setOpen(true);
    setShowDiagram(true);
    setShowActivities(false);
    setShowTasks(false);
    setShowReports(false);

    // let key = "courseReportProcess";
    // dispatch(GetProcessDefinitionByKey("courseReportProcess"));
    // console.log(ProcessDefinition, "ProcessDefinition");
    // dispatch(GetDiagram(ProcessDefinition && ProcessDefinition[0].id));

    // const modeler = new Modeler({
    //   container,
    //   keyboard: {
    //     bindTo: document,
    //   },
    // });
    // modeler
    //   .importXML(diagramBPMN && diagramBPMN.bpmn20Xml)
    //   .then(({ warnings }) => {
    //     if (warnings.length) {
    //       console.log("Warnings", warnings);
    //     }

    //     const canvas = modeler.get("modeling");
    //     canvas.setColor("CalmCustomerTask", {
    //       stroke: "green",
    //       fill: "yellow",
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("error", err);
    //   });
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDiagram(false);
    setShowActivities(true);

    // container = document.getElementById("container").innerHTML = "";
  };
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: `Tab1`,
      children: "faffazfaf",
    },
    {
      key: '2',
      label: `tab2`,
      children: "second 2"
    },

  ];
  const renderRow = () => {
    // const createCourseReportDocument = async () => {
    //   try {
    //     const resultAction = await dispatch(
    //       addNewCourseReportDocument({ ressource: "Course", ressourceId: CourseReport && CourseReport.course[0].id })
    //     )
    //     unwrapResult(resultAction)

    //   } catch (err) {
    //     console.error('Failed to save the CourseReportDocument: ', err)
    //   } finally {
    //     dispatch(fetchCourseReportDocumentsByCourseId(CourseReport && CourseReport.course[0].id))
    //   }
    // }
    if (showActivities) {
      return (
        <div>
          {openLog ? (
            <div>
              {Activities.map((item) => (
                <div>
                  <Divider
                    type="horizontal"
                    orientation="center"
                    style={{ color: "gray" }}
                  >
                    {Moment(item.DueDate).format("YYYY-MM-DD")}
                  </Divider>

                  <div
                    style={{
                      backgroundColor: "#edeff1",
                      borderBottom: "1px solid #cfd5db",
                    }}
                  >
                    <GridContainer xs={12} sm={12} md={12}>
                      <GridItem key={Element.id} xs={12} sm={12} md={2}>
                        <Avatar
                          style={{
                            color: "#f56a00",
                            backgroundColor: "#fde3cf",
                          }}
                        >
                          {item.avatar}
                        </Avatar>
                      </GridItem>
                      <GridItem key={Element.id} xs={12} sm={12} md={10}>
                        <p>
                          <strong>{item.AssignedTo}</strong>
                        </p>
                        {/* <p> {item.ActivityType}</p> */}
                        <ul>
                          <li>{item.Summary}</li>
                        </ul>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {Activities.map((item) => (
                <div>
                  <div
                    style={{
                      // backgroundColor: "#edeff1",
                      // borderBottom: "1px solid #cfd5db",
                      textAlign: "center",
                    }}
                  >
                    <Avatar
                      style={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                        marginTop: "10px",
                      }}
                    >
                      {item.avatar}
                    </Avatar>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    } else if (showTasks) {
      return (
        <div>
          {/* {HistoricTasks &&
            HistoricTasks.map((item) => (
              //.filter((item, index) => index !== 0)
              <div>
                <Divider
                  type="horizontal"
                  orientation="center"
                  style={{ color: "gray" }}
                >
                  {Moment(item.startTime).format("YYYY-MM-DD")} ---{" "}
                  {Moment(item.endTime).format("YYYY-MM-DD")}
                </Divider>

                <div
                  style={{
                    backgroundColor: "#edeff1",
                    borderBottom: "1px solid #cfd5db",
                  }}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem key={Element.id} xs={12} sm={12} md={2}>
                      <Avatar
                        style={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                        }}
                      >
                        {user && user.firstName[0] + user.lastName[0]}
                      </Avatar>
                    </GridItem>
                    <GridItem key={Element.id} xs={12} sm={12} md={10}>
                      <p>
                        <strong>
                          {user && user.firstName + " " + user.lastName}
                        </strong>
                      </p>

                      <ul>
                        <li> Task : {item.name}</li>
                      </ul>

                      <ul>
                        <li>Operation : {item.deleteReason}</li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            ))} */}
          Tasks
        </div>
      );
    } else {
      return (
        <div>
          {/* <Button color="primary" onClick={createCourseReportDocument}>New</Button>
          {courseReportDocuments && courseReportDocuments.map((courseReportDocument) => (
            <Card style={{ backgroundColor: grayColor[8] }}>
              <GridContainer justify="space-between" alignItems="center">
                <Link to={`/citrine/CourseImprovementDetails/${id}/CourseReportDocument/${courseReportDocument.id}`}>
                  <GridItem> Course report document </GridItem>
                </Link>
                <GridItem>
                  <Button
                    color="transparent"
                    simple
                    aria-label="Dashboard"
                    justIcon
                    onClick={() => { handleDeleteCourseReportDocument(courseReportDocument.id) }}
                  >
                    <DeleteForeverIcon />
                  </Button>
                </GridItem>
              </GridContainer>
            </Card>
          ))} */}
          Reports
        </div>
      );
    }
  };

  useEffect(() => {
    dispatch(fetchActionPlanById(id))
      .then(unwrapResult)
      .then((ActionPlan) => {
        setStateName(ActionPlan.state);
        console.log("ActionPlan.program_id", ActionPlan.resource);
        var data = {
          id: ActionPlan.resource_id,
          resource: ActionPlan.resource
        }
        // dispatch(fetchActionsByProgramId(data));
        dispatch(fetchActionsByResourceId(data));

      });

    dispatch(fetchActivities());
    dispatch(fetchAllUsers());
  }, []);

  useEffect(() => {
    dispatch(fetchActionPlansbyApprovalProcess(id))
  }, []);


  const ActionPlan = useSelector((state) => state.ActionPlan.ActionPlan);
  const { ActionPlansbyApprovalProcess } = useSelector((state) => state.ActionPlan);
  const Activities = useSelector((state) => state.Activity.Activities);
  const users = useSelector((state) => state.user.users);
  console.log(ActionPlansbyApprovalProcess.id)
  const ActionsNew = useSelector((state) => state.ActionPlan.ActionsByResourceId)
  const tabId = ActionPlansbyApprovalProcess?.id

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn);

  const InformCoodinator = () => {
    setShow(true);
    setStateName("Inprogress");
    try {
      let modifiedActionPlan = {
        due_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        state: "Inprogress",
      };
      console.log("modifiedActionPlan", modifiedActionPlan);
      const resultAction = dispatch(
        UpdateActionPlan({
          id: id,
          updatedActionPlan: { ...modifiedActionPlan },
        }),
        dispatch(fetchActionPlans())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(dispatch(fetchActionPlans()));
      unwrapResult(resultAction1);
      setStateName("Inprogress");
    } catch (err) {
      console.log("Failed to save the Action Plans: ", err);
    } finally {
    }
  };
  const SendforApproval = () => {
    setShow(true);
    setStateName("WaintingForApproval");
    try {
      let modifiedActionPlan = {
        due_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        state: "WaintingForApproval",
      };
      console.log("modifiedActionPlan", modifiedActionPlan);
      const resultAction = dispatch(
        UpdateActionPlan({
          id: id,
          updatedActionPlan: { ...modifiedActionPlan },
        }),
        dispatch(fetchActionPlans())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(dispatch(fetchActionPlans()));
      unwrapResult(resultAction1);
      setStateName("WaintingForApproval");
    } catch (err) {
      console.log("Failed to save the Action Plan: ", err);
    } finally {
    }
  };

  if (!ActionPlan) {
    return (
      <section>
        <h2>ActionPlan not found!</h2>
      </section>
    );
  }
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const callback = (key) => {
    console.log(key);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClosee = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log("res");
  };
  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
        </div>
      );
    } else if (value == 2) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
        </div>
      );
    } else if (value == 3) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
        </div>
      );
    }
  };

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
    setAssign_to_id(value);
  };
  const updateActionRecommendation = (item) => {
    console.log(`item ${item}`);
    let modified = {
      assign_to_id: assign_to_id,
      description: item.description,
      type_action: item.type_action,
      state: item.state,
      priority: item.priority,
    };
    try {
      console.log(`modified ${modified}`);
      dispatch(
        updateActionRecommendation({
          id: item.id,
          updatedActionRecommendation: { ...modified },
        })
      ).then(unwrapResult);
    } catch (err) {
      console.log("Failed to map plo indicator and clo: ", err);
    }
  };

  const renderCourseReport = () => {
    if (stateName === "Draft") {
      return (
        <div>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem key={Element.id} xs={12} sm={12} md={12}>
              <GridContainer xs={12} sm={12} md={12}>

                <div
                  style={{
                    height: 40,
                    backgroundColor: "white",
                    width: "100%",
                    border: "1px solid lightgray",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0px 25px 0px 20px",
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>

                      <Button
                        color="primary"
                        size="sm"
                        className={classes.marginRight}
                        onClick={() => InformCoodinator()}
                      >
                        <strong style={{ color: "white", fontSize: "11px" }}>
                          {t("Inform Coodinator")}
                        </strong>
                      </Button>
                      <p style={{ marginTop: "10px", marginLeft: "5px" }}>

                        <Tooltip title="Program Name" placement="top">
                          <span style={{ color: "#6C7B9D" }}></span>
                          {ActionPlansbyApprovalProcess?.name}
                        </Tooltip>
                      </p>
                      <p style={{ marginTop: "10px", marginLeft: "10px" }}>

                        <Tooltip title="Due Date" placement="top">
                          <span style={{ color: "#6C7B9D" }}><SlCalender style={{ marginRight: "3px", marginTop: "5px" }} /></span>
                        </Tooltip>
                        {Moment(ActionPlansbyApprovalProcess?.due_date).format("YYYY-MM-DD")}
                      </p>


                    </div>
                    <div>
                      <div className="tabs">
                        <ul className="ulTabs">
                          <li
                            style={{ fontWeight: "bold" }}
                            class="active liTabs "
                          >
                            {t("Draft")}
                          </li>
                          <li className="liTabs">{t("In progress")}</li>
                          <li className="liTabs">
                            {t("Wainting For Approval")}
                          </li>
                          <li className="liTabs">{t("Approved")}</li>
                          <li className="liTabs">{t("Done")}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </GridContainer>
              {ActionsNew &&
                ActionsNew.map((item) => (
                  <GridContainer style={{ border: "none", marginTop: "10px" }}>
                    <div class="scrollable">

                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "0px",
                          color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                          background: whiteColor,
                          width: "100%",
                          boxShadow:
                            "0 1px 4px 0 rgba(" +
                            hexToRgb(blackColor) +
                            ", 0.14)",
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          minWidth: "0",
                          wordWrap: "break-word",
                          fontSize: ".875rem",
                          // borderRight: "1px solid lightgray"
                        }}
                      >


                        <div style={{ paddingLeft: " 10px" }}>

                          <Tabs defaultActiveKey="1" items={ActionPlansbyApprovalProcess?.actionApprovalProcessDetails?.map((it, i) => {
                            const id = String(i);
                            return {
                              label: (<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>Tab-{it?.name} <AssignmentTurnedInIcon style={{ marginTop: "5px", marginLeft: "5px", color: "#6C7B9D" }} onClick={handleOpen} /></div>),
                              key: id,
                              disabled: i === 28,
                              children: <ActionPlanDetailsDraft item={it} id={id} />,
                            };
                          })}
                          />
                          <ModalOpen open={open} onClose={handleClosee} id={tabId} />

                        </div>


                      </div>
                    </div>
                  </GridContainer>
                ))}
            </GridItem>


          </GridContainer>
        </div>
      );
    } else
      if (stateName === "Inprogress") {
        return (
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem key={Element.id} xs={12} sm={12} md={openLog ? 9 : 12}>
                <GridContainer xs={12} sm={12} md={12}>
                  <div
                    style={{
                      height: 40,
                      backgroundColor: "white",
                      width: "100%",
                      border: "1px solid lightgray",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0px 25px 0px 20px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <strong style={{ color: "white", fontSize: "11px" }}>
                            {t(" Send For Approval")}
                          </strong>
                        </Button>
                        {/* <GenerateActionsModal
                        handleDropDownButtonClose={handleClose}
                        PloReport_id={PloReport.id}
                        PloReport={PloReport}
                      /> */}
                      </div>
                      <div>
                        <div className="tabs">
                          <ul className="ulTabs">
                            <li className="liTabs">{t("Draft")}</li>
                            <li
                              style={{ fontWeight: "bold" }}
                              class="active liTabs "
                            >
                              {t("In progress ")}
                            </li>
                            <li className="liTabs">
                              {t("Wainting For Approval")}
                            </li>
                            <li className="liTabs">{t("Approved")}</li>
                            <li className="liTabs"> {t("Done")}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </GridContainer>

                {ActionsNew &&
                  ActionsNew.map((item) => (
                    <GridContainer style={{ border: "none", marginTop: "10px" }}>
                      <div className="scrollable">
                        <div
                          style={{
                            padding: "10px",
                            borderRadius: "0px",
                            color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                            background: whiteColor,
                            width: "100%",
                            boxShadow:
                              "0 1px 4px 0 rgba(" +
                              hexToRgb(blackColor) +
                              ", 0.14)",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "0",
                            wordWrap: "break-word",
                            fontSize: ".875rem",
                            // borderRight: "1px solid lightgray"
                          }}
                        >
                          <GridContainer
                            xs={12}
                            sm={12}
                            md={12}
                            style={{
                              border: "1px solid lightgray",
                              marginLeft: "15px",
                              width: "96%",
                            }}
                          >
                            <GridItem xs={12} sm={12} md={9}>
                              <GridContainer xs={12} sm={12} md={12}>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={10}>
                                    <div
                                      style={{ border: "1px solid lightgray" }}
                                    >
                                      {/* {

       ActionsNew &&
       ActionsNew.map((item) => ( */}
                                      <GridContainer xs={12} sm={12} md={12}>
                                        <GridItem xs={12} sm={12} md={4}>
                                          <h6> {t("Program Name")} </h6>
                                          <p style={{ fontSize: "12px" }}>
                                            {item && item.name && item.name}
                                          </p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                          <h6> {t("Action Plan Due Date")}</h6>
                                          <p style={{ fontSize: "12px" }}>
                                            {item &&
                                              item.due_date &&
                                              Moment(item.due_date).format(
                                                "YYYY-MM-DD"
                                              )}
                                          </p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                          <h6> {t("Action Plan state")}</h6>
                                          <p style={{ fontSize: "12px" }}>
                                            {item && item.state && item.state}
                                          </p>
                                        </GridItem>
                                      </GridContainer>

                                      {/* ))} */}
                                    </div>
                                  </GridItem>
                                </GridContainer>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>

                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <div
                                style={{
                                  margin: "0px 10px 10px 10px ",
                                  borderRight: "1px solid lightgray",
                                  borderLeft: "1px solid lightgray",
                                }}
                              >

                                <TblContainer
                                  style={{ marginTop: "0px", paddingTop: "0px" }}
                                >
                                  <TblHead />
                                  <TableBody>
                                    {/* {

                                  ActionsNew && ActionsNew[0].actions_recommendations &&
                                ActionsNew[0].actions_recommendations.map((item) => ( */}

                                    {item.actions_recommendations.map((item) => (
                                      <TableRow key={item.id}>
                                        <TableCell
                                          style={{
                                            borderRight: "1px solid lightgray",
                                          }}
                                        >
                                          <p
                                            style={{
                                              display: "-webkit-box",
                                              maxWidth: "180px",
                                              WebkitLineClamp: 4,
                                              WebkitBoxOrient: "vertical",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {item && item.description}
                                          </p>
                                        </TableCell>
                                        <TableCell
                                          className="multipleLine"
                                          style={{
                                            borderRight: "1px solid lightgray",
                                          }}
                                        >
                                          {item && item.type_action}
                                        </TableCell>
                                        {/* <TableCell className="multipleLine" style={{ borderRight: '1px solid lightgray' }}>
                                      {Moment(item && item.due_date).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </TableCell> */}
                                        <TableCell
                                          className="multipleLine"
                                          style={{
                                            borderRight: "1px solid lightgray",
                                          }}
                                        >
                                          {RaitingRender(item && item.priority)}
                                        </TableCell>

                                        {/* <TableCell className="multipleLine" style={{ borderRight: '1px solid lightgray' }}>
                                      {item && item.state}
                                    </TableCell> */}

                                        <TableCell>
                                          <GridContainer xs={12} sm={12} md={12}>
                                            <GridItem xs={12} sm={12} md={10}>
                                              <p>
                                                {/* {item && item.assign_to.username} */}
                                                {item && item.assign_to_id}
                                              </p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2}>
                                              <EditAssginTOActionModal
                                                item={item}
                                                users={users}
                                                ActionPlanId={id}
                                              />
                                            </GridItem>
                                          </GridContainer>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                    {/* ))} */}
                                  </TableBody>
                                </TblContainer>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>
                    </GridContainer>
                  ))}
              </GridItem>
              {/* <GridItem key={Element.id} xs={12} sm={12} md={openLog ? 3 : 1}>
              <div
                style={{
                  padding: "10px",
                  borderRadius: "0px",
                  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                  background: whiteColor,
                  width: "100%",
                  boxShadow:
                    "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "0",
                  wordWrap: "break-word",
                  fontSize: ".875rem",
                  height: 615,
                  border: "1px solid lightgray",
                }}
              >
                {openLog ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer
                        justify="space-between"
                        align-items="center"
                      >
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={renderActivities}
                          >
                            <FaHistory
                              color={showActivities ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={renderTasks}
                          >
                            <FaTasks
                              color={showTasks ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={(event) => {
                              handleClickOpen(event);
                            }}
                          >
                            <FaProjectDiagram
                              color={showDiagram ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={renderReports}
                          >
                            <GoReport
                              color={showReports ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                          <Button
                            color="primary"
                            // simple
                            round
                            justIcon
                            onClick={() => setOpenLog(false)}
                          >
                            <KeyboardArrowRightIcon
                              color={showActivities ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        className={classes1.root}
                        class="card"
                        class="scrollable"
                      >
                        <div>{renderRow()}</div>
                      </div>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer justify="space-around">
                        <GridItem>
                          <Button
                            color="primary"
                            // simple
                            round
                            justIcon
                            onClick={() => setOpenLog(true)}
                          >
                            <KeyboardArrowLeftIcon
                              color={showActivities ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        className={classes1.root}
                        class="card"
                        class="scrollable"
                      >
                        <div>{renderRow()}</div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </div>
            </GridItem> */}
            </GridContainer>
          </div>
        );
      } else if (stateName === "WaintingForApproval") {
        return (
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem key={Element.id} xs={12} sm={12} md={openLog ? 9 : 12}>
                <GridContainer xs={12} sm={12} md={12}>
                  <div
                    style={{
                      height: 40,
                      backgroundColor: "white",
                      width: "100%",
                      borderRadius: "3px",
                      border: "1px solid lightgray",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0px 25px 0px 20px",
                      }}
                    >
                      <div>
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <strong style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve Course Report")}
                          </strong>
                        </Button>
                      </div>
                      <div>
                        <div className="tabs">
                          <ul className="ulTabs">
                            <li className="liTabs"> {t("Draft")} </li>
                            <li className="liTabs"> {t("In progress")} </li>
                            <li
                              style={{ fontWeight: "bold" }}
                              class="active liTabs "
                            >
                              {t(" Wainting For Approval")}
                            </li>
                            <li className="liTabs">{t("Approved")}</li>
                            <li className="liTabs"> {t("Done")} </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </GridContainer>
                {/* <GridContainer >
                                <Card style={{ height: 480, marginTop: "10px" }}>
                                    {show ?
                                        <div class="ribbon1 ribbon1-top-right"><span>For Approval</span></div>
                                        : <div></div>
                                    }
                                    <CardBody >

                                    </CardBody>
                                </Card>
                            </GridContainer> */}

                {/* <GridContainer style={{ border: "none", marginTop: "10px" }}>
                <div class="card" class="scrollable">
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "0px",
                      color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                      background: whiteColor,
                      width: "100%",
                      boxShadow:
                        "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "0",
                      wordWrap: "break-word",
                      fontSize: ".875rem",
                      // borderRight: "1px solid lightgray"
                    }}
                  >
                    {show ? (
                      <div class="ribbon1 ribbon1-top-right">
                        <span> {t("For Approval")}</span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={10}>
                        <div style={{ border: "1px solid lightgray" }}>
                          <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={3}>
                              <h6> {t("Instructor")}</h6>
                              <p style={{ fontSize: "12px" }}>
                                Dr.Slaheddine JARBOUI
                              </p>
                              <p style={{ fontSize: "12px" }}>Eng.Murtuza</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <h6> {t("Location")}</h6>
                              <p style={{ fontSize: "12px" }}>Online</p>
                              <p style={{ fontSize: "12px" }}>Greger campus</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <h6> {t("Section Number")}</h6>
                              <p style={{ fontSize: "12px" }}>3406</p>
                              <p style={{ fontSize: "12px" }}>3408</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <h6> {t("Number of Students")}</h6>
                              <p style={{ fontSize: "12px" }}>17</p>
                              <p style={{ fontSize: "12px" }}>18</p>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridContainer> */}
              </GridItem>
              {/* <GridItem key={Element.id} xs={12} sm={12} md={openLog ? 3 : 1}>
              <div
                style={{
                  padding: "10px",
                  borderRadius: "0px",
                  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                  background: whiteColor,
                  width: "100%",
                  boxShadow:
                    "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "0",
                  wordWrap: "break-word",
                  fontSize: ".875rem",
                  height: 615,
                  border: "1px solid lightgray",
                }}
              >
                {openLog ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer
                        justify="space-between"
                        align-items="center"
                      >
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={renderActivities}
                          >
                            <FaHistory
                              color={showActivities ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={renderTasks}
                          >
                            <FaTasks
                              color={showTasks ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={(event) => {
                              handleClickOpen(event);
                            }}
                          >
                            <FaProjectDiagram
                              color={showDiagram ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={2}>
                          <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            onClick={renderReports}
                          >
                            <GoReport
                              color={showReports ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                          <Button
                            color="primary"
                            // simple
                            round
                            justIcon
                            onClick={() => setOpenLog(false)}
                          >
                            <KeyboardArrowRightIcon
                              color={showActivities ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        className={classes1.root}
                        class="card"
                        class="scrollable"
                      >
                        <div>{renderRow()}</div>
                      </div>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer justify="space-around">
                        <GridItem>
                          <Button
                            color="primary"
                            // simple
                            round
                            justIcon
                            onClick={() => setOpenLog(true)}
                          >
                            <KeyboardArrowLeftIcon
                              color={showActivities ? primaryColor[0] : "black"}
                            />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        className={classes1.root}
                        class="card"
                        class="scrollable"
                      >
                        <div>{renderRow()}</div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </div>
            </GridItem> */}
            </GridContainer>
          </div>
        );
      }
  };

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/programs`}> {t("Programs List")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/actionPlan/Workflow/`}>
              {t("Action Workflow")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              style={{ color: "orange" }}
              to={`/citrine/ActionPlanDetails/${id}`}
            >
              {t("Action Plan Details")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      {renderCourseReport()}
    </div>
  );
}
