import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewActionPlan, fetchActionPlans } from "../../../../reduxToolkit/features/ActionPlan/ActionPlanSlice";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));


export default function GenerateActionsPloModal(props) {
  console.log(props)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [actionRecommendation_ids, setActionRecommendation_ids] = useState([]);



  const handleSend = async () => {


    let actionPlan = {
      name: props.GAReport && props.GAReport.programs[0] && props.GAReport.programs[0].program_degree_name,
      program_id: props.GAReport.program && props.GAReport.program,
      semester_id: props.GAReport && props.GAReport.programs[0].semester.id,
      resource: "program",
      resource_id: props?.GAReport?.program,
    }
    dispatch(addNewActionPlan(actionPlan
      , actionRecommendation_ids
    )).then(() => {
      dispatch(fetchActionPlans());
      history.push(`/citrine/actionPlan/Workflow`);
    });


  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#D1CF4B",
          marginLeft: "20px",
          marginTop: "5px",
        }}
        size="sm"
        onClick={(event) => {
          handleClickOpen(event);
          props.handleDropDownButtonClose();
        }}
      >
        <strong style={{ color: "white", fontSize: "11px" }}>
          {t("Generate Actions")}
        </strong>
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth="true" maxWidth="sm">
        <DialogContent>
          <div
            style={{
              margin: "0px 10px 10px 10px ",
            }}
          >
            <h4> {t("Are you sure you want to Generate Action Plan ?")}</h4>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleSend();
            }}
            color="primary"
          >
            {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
