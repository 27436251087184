import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  categories: [],
  categoriesStatus: 'idle',
  categoryStatus: 'idle',
  error: null,
  category: null,
  id: null
}

export const fetchCategories = createAsyncThunk('category/fetchCategories', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/categoryConfig/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchCategoryById = createAsyncThunk('category/fetchCategoryById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/categoryConfig/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewCategory = createAsyncThunk(
  'category/addNewCategory',
  async (initialCategory) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/categoryConfig/create/`, initialCategory, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/categoryConfig/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedCategory')
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the category: ', error)

    }
  }
)

export const deleteCategory = createAsyncThunk('category/deleteCategory', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/categoryConfig/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
const categoriesSlice = createSlice({
  name: ' category',
  initialState,
  extraReducers: {

    [fetchCategories.pending]: (state, action) => {
      state.categoriesStatus = 'loading'
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.categoriesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.categories = action.payload
    },
    [fetchCategories.rejected]: (state, action) => {
      state.categoriesStatus = 'failed'
      state.error = action.payload
    },

    [fetchCategoryById.pending]: (state, action) => {
      state.categoriestatus = 'loading'
    },
    [fetchCategoryById.fulfilled]: (state, action) => {
      state.categoriestatus = 'succeeded'

      // Add any fetched posts to the array
      state.category = action.payload
    },
    [fetchCategoryById.rejected]: (state, action) => {
      state.categoriestatus = 'failed'
      state.error = action.payload
    },
    [addNewCategory.fulfilled]: (state, action) => {
      state.categories.push(action.payload)
    },
    [deleteCategory.fulfilled]: (state, action) => {
      // state.categories.push(action.payload)
    },
    [updateCategory.fulfilled]: (state, action) => {
      const result = state.categories.filter(category => category.id !== action.payload.id);
      // console.log("category id from filter from slice",category.id)
      result.push(action.payload)
      state.categories = result
      console.log("action.payload.id from slice",action.payload)

    },
  },

})


export default categoriesSlice.reducer

 export const selectAllCategories = state => state.category.categories