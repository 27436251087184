import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  courseReportDocuments: [],
  courseReportDocumentsStatus: 'idle',
  courseReportDocument: null,
  courseReportDocumentStatus: 'idle',
  error: null,

}

export const fetchCourseReportDocuments = createAsyncThunk('courseReportDocument/fetchCourseReportDocuments', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/htmlTemplateCourseReport/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchCourseReportDocumentsByCourseId = createAsyncThunk('courseReportDocument/fetchCourseReportDocumentsByCourseId', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/htmlTemplateCourseReport/by_course/?course_id=${id}`,
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchCourseReportDocumentById = createAsyncThunk('courseReportDocument/fetchCourseReportDocumentById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/htmlTemplateCourseReport/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
// export const addNewCourseReportDocument = createAsyncThunk(
//   'courseReportDocument/addNewCourseReportDocument',
//   async (initialCourseReportDocument) => {
//    const token=localStorage.getItem('token')

//     const response = await axios.post(BaseUrl+`/htmlTemplateCourseReport/create/`, initialCourseReportDocument, {
//       headers: {
//         'Authorization': `token ${token}`
//       }
//     })
//     return response.data
//   }
// )
export const addNewCourseReportDocument = createAsyncThunk(
  'courseReportDocument/addNewCourseReportDocument',
  async (initialCourseReportDocument, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/htmlTemplateCourseReport/create/`, initialCourseReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response", error.response);
      return rejectWithValue(error.response);
    }

  }
)
export const updateCourseReportDocument = createAsyncThunk(
  'courseReportDocument/updateCourseReportDocument',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/htmlTemplateCourseReport/${data.id}/edit/`, data.updatedCourseReportDocument, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the CourseReportDocument: ', error)

    }
  }
)

export const deleteCourseReportDocument = createAsyncThunk('courseReportDocument/deleteCourseReportDocument', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/htmlTemplateCourseReport/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})


const courseReportDocumentsSlice = createSlice({
  name: ' courseReportDocument',
  initialState,
  extraReducers: {
    

    [fetchCourseReportDocumentsByCourseId.pending]: (state, action) => {
      state.courseReportDocumentsStatus = 'loading'
    },
    [fetchCourseReportDocumentsByCourseId.fulfilled]: (state, action) => {
      state.courseReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.courseReportDocuments = action.payload
    },
    [fetchCourseReportDocumentsByCourseId.rejected]: (state, action) => {
      state.courseReportDocumentsStatus = 'failed'
      state.error = action.payload
    },

    [fetchCourseReportDocumentById.pending]: (state, action) => {
      state.courseReportDocumentStatus = 'loading'
    },
    [fetchCourseReportDocumentById.fulfilled]: (state, action) => {
      state.courseReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.courseReportDocument = action.payload
    },
    [fetchCourseReportDocumentById.rejected]: (state, action) => {
      state.courseReportDocumentStatus = 'failed'
      state.error = action.payload
    },
    [addNewCourseReportDocument.fulfilled]: (state, action) => {
    //   state.HTMLTemplates.push(action.payload)
    console.log("CourseReportDocument add action.payload",action.payload)

    },
    [addNewCourseReportDocument.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log("CourseReportDocument delete action.payload",action.payload)
    },
    [updateCourseReportDocument.fulfilled]: (state, action) => {
    //   const result = state.HTMLTemplates.filter(HTMLTemplate => HTMLTemplate.id !== action.payload.id);
    //   // console.log("HTMLTemplate id from filter from slice",HTMLTemplate.id)
    //   result.push(action.payload)
    //   state.HTMLTemplate = result
      console.log("CourseReportDocument update action.payload",action.payload)

    },

  },

})


export default courseReportDocumentsSlice.reducer


