
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  indicatorsStatus: 'idle',
  GAIndicators: [],
  indicatorStatus: 'idle',
  GAIndicator:null,
  ScoreGAIndicator:null,
  error: null,
}

export const fetchGAIndicators = createAsyncThunk('Indicator/fetchGAIndicators', async () => {
  const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+'/graduateAttributeIndicators/', 
    {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
      
      )
      // console.log(response.data,'response.data')

    return response.data
  })

  export const fetchGAIndicatorById = createAsyncThunk('Indicator/fetchGAIndicatorById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/graduateAttributeIndicators/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })

  export const addNewGAIndicator = createAsyncThunk(
    'Indicator/addNewIndicator',
    async (initialIndicator) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/graduateAttributeIndicators/create/`,initialIndicator ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )

  export const fetchScoreGAIndicator = createAsyncThunk(
    'Indicator/fetchScoreGAIndicator',
    async (initialPloIndicator) => {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/graduateAttributeIndicators/score`, initialPloIndicator, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    }
  )
  export const updateGAIndicator = createAsyncThunk(
    'Indicator/updateGAIndicator',
    async (data, thunkAPI) => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.put(BaseUrl + `/graduateAttributeIndicators/${data.id}/edit/`, data.updatedGAIndicator, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        console.log(data, 'updatePloIndicator')
        //console.log(response.data, 'response.data')
        return response.data
  
  
      } catch (error) {
        console.error('Failed to update the plo: ', error)
  
      }
    }
  )
//   )

  export const deleteGAIndicator = createAsyncThunk('Indicator/deleteIndicator', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(BaseUrl+`/graduateAttributeIndicators/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    return rejectWithValue(err.response.data)
  }
  })

const  GAIndicatorSlice = createSlice({
  name: 'IndicatorSpec',
  initialState,
  extraReducers: {

    [fetchGAIndicators.pending]: (state, action) => {
        state.indicatorsStatus = 'loading'
      },
      [fetchGAIndicators.fulfilled]: (state, action) => {
        state.indicatorsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.GAindicators = action.payload
      },
      [fetchGAIndicators.rejected]: (state, action) => {
        state.indicatorsStatus = 'failed'
        state.error = action.payload
      },

      [fetchGAIndicatorById.pending]: (state, action) => {
        state.indicatorStatus = 'loading'
      },
      [fetchGAIndicatorById.fulfilled]: (state, action) => {
        state.indicatorStatus = 'succeeded'

        // Add any fetched posts to the array
        state.indicator = action.payload
      },
      [fetchGAIndicatorById.rejected]: (state, action) => {
        state.indicatorStatus = 'failed'
        state.error = action.payload
      },


      [fetchScoreGAIndicator.pending]: (state, action) => {
        state.indicatorStatus = 'loading'
      },
      [fetchScoreGAIndicator.fulfilled]: (state, action) => {
        state.indicatorStatus = 'succeeded'

        // Add any fetched posts to the array
        state.ScoreGAIndicator = action.payload
      },
      [fetchScoreGAIndicator.rejected]: (state, action) => {
        state.indicatorStatus = 'failed'
        state.error = action.payload
      },


      


      [addNewGAIndicator.fulfilled]: (state, action) => {
        state.indicators.push(action.payload)
      },

      [deleteGAIndicator.fulfilled]: (state, action) => {
        console.log(action.payload)
      },

      [updateGAIndicator.fulfilled]: (state, action) => {
        console.log(action.payload)
      },


      GAIndicatorUpdated(state, action) {
        const { id, name, code, weight } = action.payload
        const existingGAIndicator = state.GAIndicators.find(plo => plo.id === id)
        if (existingGAIndicator) {
          existingGAIndicator.name = name
          existingGAIndicator.code = code
          existingGAIndicator.weight = weight
  
  
        }
      }

  },

})


export default GAIndicatorSlice.reducer



