import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';

const initialState = {
  programs: [],
  programsStatus: 'idle',
  allPrograms: [],
  allProgramsStatus: 'idle',
  programsByAcademicYear: [],
  programsByAcademicYearName: [],
  programsByAcademicYearStatus: 'idle',
  programsByAcademicYearIdStatus: 'idle',
  program: null,
  programStatus: 'idle',
  error: null,
  programsByPage: [],
}

export const fetchProgramNextPage = createAsyncThunk('program/fetchProgramNextPage', async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(data,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const fetchProgramsByPage = createAsyncThunk('program/fetchProgramsByPage', async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/program/?semester_id=${data.semester_id}&page=${data.page}&records=${data.records}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const fetchPrograms = createAsyncThunk('program/fetchPrograms', async (id, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const { data } = await axios.get(BaseUrl + `/program/?semester_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return data.results
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const fetchAllPrograms = createAsyncThunk(
  "program/fetchAllPrograms", async (param, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/program/all/`,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const fetchProgramById = createAsyncThunk('program/fetchProgramById', async (id, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/program/${id}/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
  catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response)
  }
})

export const fetchProgramsByAcademicYear = createAsyncThunk('program/fetchProgramsByAcademicYear', async (id) => {
  const token = localStorage.getItem('token')
  const response = await axios.get(BaseUrl + `/program/get/?academicYear_id=${id}`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  return response.data
})

export const fetchProgramsByAcademicYearName = createAsyncThunk(
  "program/fetchProgramsByAcademicYearName",
  async (name) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/program/get/?academicYear_name=${name}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const addNewProgram = createAsyncThunk(
  'program/addNewProgram',
  async (initialProgram) => {
    const token = localStorage.getItem('token')
    const response = await axios.post(BaseUrl + `/program/create/`, initialProgram, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateProgram = createAsyncThunk(
  'program/updateProgram',
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/program/${data.id}/edit/`, data.updatedProgram, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (error) {
      console.error('Failed to update the program: ', error)
    }
  }
)

export const updateProgramPlanningDates = createAsyncThunk(
  "program/updateProgramPlanningDates", async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/program/updatePlanningDates/`, data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const updateSurveyPlanningDates = createAsyncThunk(
  "program/updateSurveyPlanningDates", async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/program/updatePlanning/`, data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const updateSurveyPlanningTemplatee = createAsyncThunk(
  "program/updateSurveyPlanningTemplate", async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/program/update-survey-template/`, data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const updateSurveyPlanningTemplate = createAsyncThunk(
  "program/updateSurveyPlanningTemplate", async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/program/addSurveyTemplate/`, data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const DeleteAffectedSurveyToProgram = createAsyncThunk(
  "program/DeleteAffectedSurveyToProgram", async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/program/deleteSurveyTemplate/`, data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const updateSurveyPlanningTargetGroups = createAsyncThunk(
  "program/updateSurveyPlanningTargetGroups", async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/program/addGroup/`, data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const updateProgramSurveyDates = createAsyncThunk(
  "program/updateProgramSurveyDates", async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/surveyDates/create/`, data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response)
    }
  }
);

export const additionalUpdateProgram = createAsyncThunk(
  'program/additionalUpdateProgram',
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/program/${data.id}/update/`, data.updatedField, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (error) {
      console.error('Failed to update the program: ', error)
    }
  }
)

export const deleteProgram = createAsyncThunk(
  "program/deleteProgram",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(BaseUrl + `/program/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response);
    }
  }
);

/****************************************************************************************** *
 *
 * Add Role and Access to program ass
 * ******************************************************************************/
export const AffectCoordinatorToProgramAss = createAsyncThunk(
  'programAss/AffectCoordinatorToProgramAss',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/program/update/${data.id}/`, data.data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
)

/****************************************************************************************** */

const programsSlice = createSlice({
  name: 'program',
  initialState,
  extraReducers: {
    [fetchProgramNextPage.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchProgramNextPage.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.programsByPage = action.payload
    },
    [fetchProgramNextPage.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramsByPage.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchProgramsByPage.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.programsByPage = action.payload
    },
    [fetchProgramsByPage.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchPrograms.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchPrograms.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.programs = action.payload
    },
    [fetchPrograms.rejected]: (state, action) => {
      state.programsStatus = 'failed'
    },

    [fetchAllPrograms.pending]: (state, action) => {
      state.allProgramsStatus = 'loading'
    },
    [fetchAllPrograms.fulfilled]: (state, action) => {
      state.allProgramsStatus = 'succeeded'
      state.allPrograms = action.payload
    },
    [fetchAllPrograms.rejected]: (state, action) => {
      state.allProgramsStatus = 'failed'
    },

    [fetchProgramById.pending]: (state, action) => {
      state.programStatus = 'loading'
    },
    [fetchProgramById.fulfilled]: (state, action) => {
      state.programStatus = 'succeeded'
      state.program = action.payload
    },
    [fetchProgramById.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramsByAcademicYear.pending]: (state, action) => {
      state.programsByAcademicYearIdStatus = 'loading'
    },
    [fetchProgramsByAcademicYear.fulfilled]: (state, action) => {
      state.programsByAcademicYearIdStatus = 'succeeded'
      state.programsByAcademicYear = action.payload
    },
    [fetchProgramsByAcademicYear.rejected]: (state, action) => {
      state.programsByAcademicYearIdStatus = 'failed'
    },

    [fetchProgramsByAcademicYearName.pending]: (state, action) => {
      state.programsByAcademicYearStatus = 'loading'
    },
    [fetchProgramsByAcademicYearName.fulfilled]: (state, action) => {
      state.programsByAcademicYearStatus = 'succeeded'
      state.programsByAcademicYearName = action.payload
    },
    [fetchProgramsByAcademicYearName.rejected]: (state, action) => {
      state.programsByAcademicYearStatus = 'failed'
    },

    [DeleteAffectedSurveyToProgram.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [DeleteAffectedSurveyToProgram.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.program = action.payload
    },
    [DeleteAffectedSurveyToProgram.rejected]: (state, action) => {
      state.programsStatus = 'failed'
    },

    [addNewProgram.fulfilled]: (state, action) => {
      state.programs.push(action.payload)
    },

    [deleteProgram.fulfilled]: (state, action) => {},

    [updateProgram.fulfilled]: (state, action) => {
      const result = state.programs.filter(program => program.id !== action.payload.id);
      result.push(action.payload)
      state.programs = result
    },

    [additionalUpdateProgram.fulfilled]: (state, action) => {},

    [updateProgramPlanningDates.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [updateProgramPlanningDates.fulfilled]: (state, action) => {
      const result = state.programs.filter(program => program.id !== action.payload.id);
      result.push(action.payload)
      state.programs = result
    },
    [updateProgramPlanningDates.rejected]: (state, action) => {
      state.programsStatus = 'failed'
    },
  },

})


export default programsSlice.reducer

export const selectAllPrograms = state => state.program.programs
