import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.js";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { fetchStandardConfigs, updateStandard } from "../../reduxToolkit/features/standardConfig/StandardConfigSlice.jsx"
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/CustomButtons/Button.js";
import React, { useState } from "react";
import GridItem from "components/Grid/GridItem.js";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTranslation } from "react-i18next";
import { Form, Input, notification, Select } from "antd";
import ErrorModal from "./ErrorModal";
import { useDispatch, useSelector } from "react-redux";
import { CodeOutlined } from "@material-ui/icons";
import { useEffect } from "react";
import { fetchKpiStandards } from "reduxToolkit/features/kpiStandardConfigType/kpiStandardConfigTypeSlice.js";
export default function SuperGeneralUpdateModalStandard(props) {
  console.log(props);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [type, setType] = useState("");
  const [code, setCode] = useState("");
  const [open, setOpen] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const KPIsStandard = useSelector((state) => state.KpiStandard.KpiStandards)
  console.log(KPIsStandard)
  useEffect(() => {
    dispatch(fetchKpiStandards())
  }, [])
  //   const standardConfigsItems = useSelector((state) =>
  //   state.StandardConfig.standardConfigs);

  //   const itemstandard = standardConfigsItems.map((item)=>item)
  // console.log("standardConfigsItems",standardConfigsItems.map((item)=>item) );
  //   console.log(itemstandard);
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };


  //   const onFinish = async ()=>{
  //     setAddRequestStatus("pending");
  //     let updatedStandard = {typee : typee, code : code };
  //     const resultAction = await dispatch(
  //       updateStandard({id:props.Element_ID ,updatedStandard})
  //             )
  //     console.log(resultAction); 


  //     if(updateStandard.fulfilled.match(resultAction)){
  //         const standard = resultAction.payload;
  //         console.log(standard);
  //         console.log("success", `Edited ${standard?.typee}`);
  //         openNotificationWithIcon("success", `Edited ${standard?.typee}`);
  //         handleClose();
  //     }else {
  //         if (resultAction.payload) {
  //           // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
  //           console.log(
  //             "resultAction.payload from component",
  //             resultAction.payload
  //           );
  //           setHandledError(resultAction.payload);
  //           handleOpenErrorModal();
  //           handleClose();
  //         } else {
  //           console.log("resultAction.error  from component", resultAction.error);
  //           setHandledError(resultAction.error);
  //           handleOpenErrorModal();
  //           handleClose();
  //         }
  //       }
  // }

  const onFinish = async (values) => {
    console.log(values);
    let data;
    let id = props.id;
    let detail = "";
    console.log(id);

    data = { ...values, id };
    console.log("data", data);
    try {
      const resultAction = await dispatch(updateStandard(data, detail));
      if (updateStandard.fulfilled.match(resultAction)) {
        // Handle success case
        const standard = resultAction.payload;
        console.log("dd", standard);
        //console.log('success', `Edited ${standard?.overall_analysis_of_standard}`);
        //openNotificationWithIcon('success', `Edited ${standard?.overall_analysis_of_standard}`);
        dispatch(fetchStandardConfigs());
        handleClose();
      } else {
        // Handle error case
        if (resultAction.payload) {
          console.log('resultAction.payload from component', resultAction.payload);
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
          handleClose();
        } else {
          console.log('resultAction.error from component', resultAction.error);
          setHandledError(resultAction.error);
          handleOpenErrorModal();
          handleClose();
        }
      }
    } catch (error) {
      // Handle error case
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // message.error('Submit failed!');
  };
  const { Option } = Select;
  // const layout = {
  //     labelCol: {
  //       span: 3,
  //     },
  //     wrapperCol: {
  //       span: 14,
  //     },
  //   };
  //   const tailLayout = {
  //     wrapperCol: {
  //       offset: 12,
  //       span: 0,
  //     },
  //   };

  return (


    <div>
      {/* <MenuItem
             onClick={handleClickOpen}
           >
               <ListItemText primary="Updatee" />
           </MenuItem> */}
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"

      >
        <DialogTitle id="form-dialog-title">{t("Update")}</DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
          >
            <GridContainer >
              <GridItem xs={12} sm={12} md={12} >
                <Form.Item
                  label={t("type")}
                  name="type"

                  rules={[
                    {
                      required: true,
                      message: "Please select the type",
                    },
                  ]}
                >
                  {/* <Select   showArrow>
                <Option value="mission, vision and strategic planning">Mission, vision and strategic planning</Option>
                <Option value="governance, leadership, and management">Governance, leadership, and management</Option>
                <Option value="teaching and learning">Teaching and learning</Option>
                <Option value="students">Students</Option>
                <Option value="faculty and staff">Faculty and staff</Option>
                <Option value="institutional resources">Institutional resources</Option>
                <Option value="scientific research and innovation">Scientific research and innovation</Option>
                <Option value="community partnerships">Community partnerships</Option>
              </Select> */}
                  <Select style={{ width: '31rem' }} dropdownStyle={{ zIndex: 10000 }} showArrow>

                    {KPIsStandard?.map((item) => (
                      <Option value={item?.id}>{item?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Code")}
                  name="code"
                  rules={[
                    { message: "Please input the code!" },
                  ]}
                >
                  <Input
                    id="code"
                    value={code}
                  //  onChange={(event) => {
                  //    setCode(event.target.value);
                  //  }}
                  />
                </Form.Item>
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={props.handleClose}
                      >
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="info">
                        {t("Update")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
