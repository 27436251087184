import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardBodyStyle from 'assets/jss/material-dashboard-pro-react/components/cardBodyStyle';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileWord } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchProgramById } from 'reduxToolkit/features/program/ProgramSpecSlice';
// import pagesHeaderstyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle";
import { grayColor } from "../../../../../assets/jss/material-dashboard-pro-react";
import { fetchStudyPlanReportDocumentById } from "../../../../../reduxToolkit/features/StudyPlan/StudyPlanReportDocumentSlice";

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useCardBodyStyles = makeStyles(cardBodyStyle);



export default function StudyPlanReportDocumentDetails() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("")

  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const cardClasses = useCardStyles();
  const cardBodyClasses = useCardBodyStyles()
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)

  let { progReportDocumentId } = useParams();
  let { id } = useParams();

  const dispatch = useDispatch()
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchProgram = async () => {
    try {
      const courses = await dispatch(fetchProgramById(id))
        .then(unwrapResult)
        .then((programSpec) => {
          localStorage.setItem("ProgramState", programSpec.state);
        });
      console.log("success", `Fetched`);
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
      console.log("error", `Fetch failed: ${err}`);
    }
  };
  useEffect(() => {
    fetchProgram();
    dispatch(fetchStudyPlanReportDocumentById(progReportDocumentId));
  }, [dispatch]);

  const program = useSelector((state) => state.programSpec.program);
  const studyPlanReportDocumentStatus = useSelector(state => state.StudyPlanReportDocument.studyPlanReportDocumentStatus)

  const studyPlanReportDocument = useSelector(state => state.StudyPlanReportDocument.studyPlanReportDocument)
  console.log(studyPlanReportDocument, "studyPlanReportDocument")


  const exportDocumentToPDF = () => {


    var html = htmlToPdfmake(studyPlanReportDocument && studyPlanReportDocument.htmlTemplates_body);
    console.log("html", html)

    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();

  }

  function exportDocumentToWORD(element, filename = '') {
    var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>`;
    var postHtml = `</body></html>`;
    var html = preHtml + element + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    // Specify link url
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + '.doc' : 'document.doc';

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }
  return (
    <div>
      {/* <GridContainer
                alignItems="center"
                style={{
                    borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
                }}>
                <GridItem>
                    <Link to={`/citrine/studyPlan`}>
                        <span className={titleClasses.title}> {t('Courses')} </span>
                    </Link>

                </GridItem>

            </GridContainer> */}
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>

              {t("Specification")}

            </Breadcrumb.Item>
            <Breadcrumb.Item>

              {t("Specification")}

            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/programSpec/`}>
                {t("Programs List")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                style={{ color: "orange" }}
                to={`/citrine/programSpec/${id}`}
              >
                {program && program.program_degree_name && program.program_degree_name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>

              {t("Study Plan")}

            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={10} justify='flex-end'>
        <GridItem>
          <Link to={`/citrine/programSpec/${id}/StudyPlanReportDocument/${studyPlanReportDocument && studyPlanReportDocument.id}/editor`}>
            <Button
              color="white"
              aria-label="Edit"
              justIcon
              round
            >
              <Edit />
            </Button>
          </Link>
        </GridItem>
        {/* <GridItem>

                    <Button
                        color="white"
                        aria-label="Export to PDF"
                        justIcon
                        round
                        onClick={exportDocumentToPDF}
                    >
                        <FaFilePdf />
                    </Button>
                </GridItem> */}
        <GridItem>
          <Button
            color="white"
            aria-label="Export to Word"
            justIcon
            round
            onClick={() => { exportDocumentToWORD(studyPlanReportDocument && studyPlanReportDocument.html_template && studyPlanReportDocument.html_template, 'word-content') }}
          >
            <FaFileWord />
          </Button>

        </GridItem>
      </GridContainer>
      <GridContainer xs={12} sm={12} md={10}>
        <Card>

          <CardBody className={cardBodyClasses.cardBodyWithMarginTop}>

            <GridContainer justify='center'>
              <GridItem>
                <div dangerouslySetInnerHTML={{ __html: studyPlanReportDocument && studyPlanReportDocument.html_template }} ></div>
              </GridItem>
            </GridContainer>
          </CardBody>

        </Card>
      </GridContainer>
    </div>
  );
}













