import React, {useRef, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useDispatch } from "react-redux";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import "antd/dist/antd.css";

import { Form, Input, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../../Components/ErrorModal";

import { Breadcrumb } from 'antd';
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { Link, useHistory } from "react-router-dom";
import { addReportStatesConfig, fetchReportStatesConfigs } from "../../../../reduxToolkit/features/ReportStatesConfig/ReportStatesConfigSlice";

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 14,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const useButtonStyles = makeStyles(buttonStyles);

export default function AddReportStatesConfigPage(props) {

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = useRef(null);
  const history = useHistory();

  const { Option } = Select;
  const { TextArea } = Input;

  const [open, setOpen] = useState(false);
  const [person_id, setPerson_id] = useState(props.person_id);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push(`/citrine/configuration/others/ReportStatesConfigPage`);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addReportStatesConfig({
        ...values
      })
    );
    if (addReportStatesConfig.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchReportStatesConfigs());
      form.resetFields();
      history.push(`/citrine/configuration/others/ReportStatesConfigPage`);
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  return (
    <div>
      <GridContainer
        md={12}
        xs={12}
        sm={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t("other Configurations")} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/ReportStatesConfigPage`}>
                <span> {t("Report States Page")} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/AddReportStatesConfigPage`}>
                <span> {t("ADD Report States")} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <br></br>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
              name="color"
              label="Color code"
              rules={[
                {
                  required: true,
                  message: "Please enter the color code.",
                },
              ]}
            >
              <Input />
            </Form.Item>
                <br></br>
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
