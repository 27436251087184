import { Tabs } from "antd";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { greyColor } from "assets/jss/material-dashboard-pro-react";
import { Link } from "react-router-dom";
import React from "react";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import Leadership from "./Leadership";
import ProgramBudgetAndFinancialSupport from "./ProgramBudgetAndFinancialSupport";
import Staffing from "./Staffing";
import FacultyHiringAndRetention from "./FacultyHiringAndRetention";
import SupportOfFacultyProfessionalDevelopment from "./SupportOfFacultyProfessionalDevelopment";
import Criterion1Analysis from "../criterion1/Analysis";

const onChange = (key) => {
  console.log(key);
};

const Criterion8 = ({ data }) => (
  <>
    {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      items={[
        {
          label: `Leadership`,
          key: "1",
          children: <Leadership data={data.Leadership} />,
        },
        {
          label: `Program Budget and Financial Support`,
          key: "2",
          children: (
            <ProgramBudgetAndFinancialSupport
              data={data.ProgramBudgetAndFinancialSupport}
            />
          ),
        },
        {
          label: `Staffing`,
          key: "3",
          children: <Staffing data={data.Staffing} />,
        },
        {
          label: `Faculty Hiring and Retention`,
          key: "4",
          children: (
            <FacultyHiringAndRetention data={data.FacultyHiringAndRetention} />
          ),
        },
        {
          label: `Support of Faculty Professional Development`,
          key: "5",
          children: (
            <SupportOfFacultyProfessionalDevelopment
              data={data.SupportOfFacultyProfessionalDevelopment}
            />
          ),
        },
        {
          label: `Analysis`,
          key: "6",
          children: <Criterion1Analysis data={data.Analysis} />,
        },
      ]}
    />
  </>
);
export default Criterion8;
