import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  programVersioning: [],
  programSpecVersioning: [],
  programSpecVersioningStatus: 'idle',
  notGeneratedPrograms: [],
  notGeneratedProgramsStatus: 'idle',
  programLevels: [],
  programLevelsStatus: 'idle',
  programs: [],
  programsStatus: 'idle',
  program: null,
  programStatus: 'idle',
  areaWeightScaleStatus: 'idle',
  error: null,
  coursesStatus: 'idle',
  courses: [],
  programsByCourseSpec: [],
  programsByPage: [],
}

export const fetchProgramNextPage = createAsyncThunk(
  'programSpec/fetchProgramNextPage',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchProgramsByPage = createAsyncThunk(
  'programSpec/fetchProgramsByPage',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl +
          `/programSpec/ByPage/?page=${data.page}&records=${data.records}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPrograms = createAsyncThunk(
  'programSpec/fetchPrograms',
  async (parameter, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + '/programSpec/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchProgramsByCourseSpec = createAsyncThunk(
  'programSpec/fetchProgramsByCourseSpec',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/courseSpec/programsSpec/?id=${id} `,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchApprovedNotGeneratedPrograms = createAsyncThunk(
  'programSpec/fetchApprovedNotGeneratedPrograms',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/programSpec/approved/?semester_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchNotAffectedCourses = createAsyncThunk(
  'programSpec/fetchNotAffectedCourses',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/programSpec/courseSpec_filtered/?programSpec_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchProgramLevels = createAsyncThunk(
  'programSpec/fetchProgramLevels',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/programSpecLevel/?programSpec_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchProgramById = createAsyncThunk(
  'programSpec/fetchProgramById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(BaseUrl + `/programSpec/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      })
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewProgram = createAsyncThunk(
  'programSpec/addNewProgram',
  async (initialProgram, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/programSpec/create/`,
        initialProgram,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const EditProgram = createAsyncThunk(
  'programSpec/EditProgram',
  async (initialProgram, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/programSpec/editLevels/`,
        initialProgram,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateProgram = createAsyncThunk(
  'programSpec/updateProgram',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/programSpec/${data.id}/edit/`,
        data.updatedProgram,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const updateAreaWeightScale = createAsyncThunk(
    'programSpec/updateAreaWeightScale',
    async (data, thunkAPI) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(BaseUrl + `/programSpec/${data.id}/areaWeightScale/update/`, data.areaWeightScale, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (error) {}
    }
)

export const Special_updateProgram = createAsyncThunk(
  'programSpec/Special_updateProgram',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/programSpec/${data.id}/update/`,
        data.updatedProgram,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const AffectCoursesToProgram = createAsyncThunk(
  'programSpec/AffectCoursesToProgram',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/programSpec/affect/courseSpec/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const SpecVersioning = createAsyncThunk(
  'programSpec/specVersioning',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(
        BaseUrl + `/courseSpec/childParent/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const programSpecVersioning = createAsyncThunk(
  'ProgramSpecVersioning',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const { data } = await axios.get(
        BaseUrl + `/programSpec/childParent/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const VersionningPorgramSpec = createAsyncThunk(
  'programSpec/VersionningPorgramSpec',
  async (initialProgram, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(
        BaseUrl + `/programSpec/copy/`,
        initialProgram,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const AffectCoordinatorToProgram = createAsyncThunk(
  'programSpec/AffectCoordinatorToProgram',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(
        BaseUrl + `/programSpec/${data.id}/change/`,
        data.data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteCourse = createAsyncThunk(
  'programSpec/deleteCourse',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/programSpec/delete/courseSpec/`,
        data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteProgramSpec = createAsyncThunk(
  'programSpec/deleteProgramSpec',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/programSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchProgramByTitle = createAsyncThunk(
  'programSpec/fetchProgramByTitle',
  async (name, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/programSpec/search/?name=${name}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const programsSlice = createSlice({
  name: 'programSpec',
  initialState,
  extraReducers: {
    [programSpecVersioning.pending]: (state, action) => {
      state.programSpecVersioningStatus = 'loading'
    },
    [programSpecVersioning.fulfilled]: (state, action) => {
      state.programSpecVersioningStatus = 'succeeded'
      state.programSpecVersioning = action.payload
    },
    [programSpecVersioning.rejected]: (state, action) => {
      state.programSpecVersioningStatus = 'failed'
      state.error = action.payload ? action.payload : action.error
    },

    [fetchPrograms.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchPrograms.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.programs = action.payload
    },
    [fetchPrograms.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramNextPage.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchProgramNextPage.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.programsByPage = action.payload
    },
    [fetchProgramNextPage.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramsByPage.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchProgramsByPage.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.programsByPage = action.payload
    },
    [fetchProgramsByPage.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramsByCourseSpec.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchProgramsByCourseSpec.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.programsByCourseSpec = action.payload
    },
    [fetchProgramsByCourseSpec.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchApprovedNotGeneratedPrograms.pending]: (state, action) => {
      state.notGeneratedProgramsStatus = 'loading'
    },
    [fetchApprovedNotGeneratedPrograms.fulfilled]: (state, action) => {
      state.notGeneratedProgramsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.notGeneratedPrograms = action.payload
    },
    [fetchApprovedNotGeneratedPrograms.rejected]: (state, action) => {
      state.notGeneratedProgramsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramLevels.pending]: (state, action) => {
      state.programLevelsStatus = 'loading'
    },
    [fetchProgramLevels.fulfilled]: (state, action) => {
      state.programLevelsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.programLevels = action.payload
    },
    [fetchProgramLevels.rejected]: (state, action) => {
      state.programLevelsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchNotAffectedCourses.pending]: (state, action) => {
      state.coursesStatus = 'loading'
    },
    [fetchNotAffectedCourses.fulfilled]: (state, action) => {
      state.coursesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.courses = action.payload
    },
    [fetchNotAffectedCourses.rejected]: (state, action) => {
      state.coursesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramById.pending]: (state, action) => {
      state.programStatus = 'loading'
    },
    [fetchProgramById.fulfilled]: (state, action) => {
      state.programStatus = 'succeeded'
      // Add any fetched posts to the array
      state.program = action.payload
    },
    [fetchProgramById.rejected]: (state, action) => {
      state.programStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewProgram.fulfilled]: (state, action) => {
      state.programs.push(action.payload)
    },
    [addNewProgram.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [EditProgram.fulfilled]: (state, action) => {},
    [EditProgram.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateProgram.fulfilled]: (state, action) => {},
    [updateProgram.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateAreaWeightScale.pending]: (state, action) => {
        state.areaWeightScaleStatus = 'loading'
    },
    [updateAreaWeightScale.fulfilled]: (state, action) => {
        state.areaWeightScaleStatus = 'succeeded'
    },
    [updateAreaWeightScale.rejected]: (state, action) => {
        state.areaWeightScaleStatus = 'failed'
        if (action.payload) {
            state.error = action.payload
        } else {
            state.error = action.error
        }
    },

    [Special_updateProgram.fulfilled]: (state, action) => {},
    [Special_updateProgram.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchProgramByTitle.pending]: (state, action) => {
      state.programsStatus = 'loading'
    },
    [fetchProgramByTitle.fulfilled]: (state, action) => {
      state.programsStatus = 'succeeded'
      state.programsByPage = action.payload
    },
    [fetchProgramByTitle.rejected]: (state, action) => {
      state.programsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default programsSlice.reducer

export const selectAllPrograms = (state) => state.programSpec.programs
