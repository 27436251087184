import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  attachments: [],
  attachmentsStatus: 'idle',
  attachmentStatus: 'idle',
  error: null,
  attachment:null,
  id:null
}


export const fetchAttachments = createAsyncThunk('attachment/fetchAttachments', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/attachment/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

  export const fetchAttachmentById = createAsyncThunk('attachment/fetchAttachmentById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/attachment/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     console.log(response.data,'response.data')

    return response.data
  })
  export const addNewAttachment = createAsyncThunk(
    'attachment/addNewAttachment',
    async (uploadData) => {
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/attachment/create/`,uploadData ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const attachmentUpdate = createAsyncThunk(
    'attachment/attachmentUpdate',
    async (data,thunkAPI) => {
      const token=localStorage.getItem('token')
      try{
        const response = await axios.put(BaseUrl+`/attachment/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedAttachment')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the attachment: ', error)

}
    }
  )
  export const deleteAttachment = createAsyncThunk('attachment/deleteAttachment', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/attachment/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
     

    return response.data
  })
const  attachmentSlice = createSlice({
  name: ' attachment',
  initialState,
  extraReducers: {
  
    [fetchAttachments.pending]: (state, action) => {
      state.attachmentsStatus = 'loading'
    },
    [fetchAttachments.fulfilled]: (state, action) => {
      state.attachmentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.attachments = action.payload
    },
    [fetchAttachments.rejected]: (state, action) => {
      state.attachmentsStatus = 'failed'
      state.error = action.payload
    },

      [fetchAttachmentById.pending]: (state, action) => {
        state.attachmentStatus = 'loading'
      },
      [fetchAttachmentById.fulfilled]: (state, action) => {
        state.attachmentStatus = 'succeeded'

        // Add any fetched posts to the array
        state.attachment = action.payload
      },
      [fetchAttachmentById.rejected]: (state, action) => {
        state.attachmentStatus = 'failed'
        state.error = action.payload
      },
    [addNewAttachment.fulfilled]: (state, action) => {
      state.attachments.push(action.payload)
    },
    [attachmentUpdate.fulfilled]: (state, action) => {
      console.log(action) 
    },
    [deleteAttachment.fulfilled]: (state, action) => {
     console.log(action)
    },
  },

  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
    attachmentAdded: {
      reducer(state, action) {
        state. attachments.push(action.payload)
      },
      prepare(name,university_id, mission, phoneNumber, email, director, image, adress, city, postalCode) {
        // omit prepare logic
      }
    },
    attachmentUpdated(state, action) {
      const { id,name, mission, phoneNumber, email, director, image, adress, city, postalCode } = action.payload
      const existingAttachment = state.attachments.find(attachment => attachment.id === id)
      if (existingAttachment) {
        existingAttachment.name = name
     //   existingAttachment.university = university
        existingAttachment.mission = mission
        existingAttachment.phoneNumber = phoneNumber
        existingAttachment.email = email
        existingAttachment.director = director
        existingAttachment.image = image
        existingAttachment.adress = adress
        existingAttachment.city = city
        existingAttachment.postalCode = postalCode


      }
    }
  }
})

export const { attachmentAdded, attachmentUpdated, getId } = attachmentSlice.actions

export default attachmentSlice.reducer

export const selectAllAttachments = state => state.attachment.attachments

// export const selectAttachmentBySlug = (state, slug) =>
//   state.attachment.attachments.find(attachment => attachment.slug === slug)