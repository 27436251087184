import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  CvTechSkills: [],
  CvTechSkillsStatus: "idle",
  error: null,

};

export const fetchCvTechSkillsByPersonId = createAsyncThunk(
  "CvTechSkills/fetchCvTechSkillsByPersonId",
  async (person_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/personSkills/byStaff/?staff_id=${person_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);

export const addNewCvTechSkills = createAsyncThunk(
  "CvTechSkills/addNewCvTechSkills",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/personSkills/create/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);




export const disaffectCvTechSkills = createAsyncThunk(
  "CvTechSkills/disaffectCvTechSkills",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/personSkills/disaffect/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);





export const deleteCvTechSkills = createAsyncThunk(
  "CvTechSkills/deleteCvTechSkills",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(BaseUrl + `/personSkills/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  }
);

const CvTechSkillsSlice = createSlice({
  name: "CvTechSkills",
  initialState,
  extraReducers: {
    [fetchCvTechSkillsByPersonId.pending]: (state, action) => {
      state.CvTechSkillsStatus = "loading";
    },
    [fetchCvTechSkillsByPersonId.fulfilled]: (state, action) => {
      state.CvTechSkillsStatus = "succeeded";
      // Add any fetched posts to the array
      state.CvTechSkills = action.payload;
    },
    [fetchCvTechSkillsByPersonId.rejected]: (state, action) => {
      state.CvTechSkillsStatus = "failed";
      state.error = action.payload;
    },

    [addNewCvTechSkills.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    [disaffectCvTechSkills.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    

   
    [deleteCvTechSkills.fulfilled]: (state, action) => {},
  },
});

export default CvTechSkillsSlice.reducer;
// export const  groups = state => state.group.groups
