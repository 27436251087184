import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from "antd";
import { Link, useParams } from "react-router-dom";
// antdesign components
import { DatePicker, Form, Input, message, notification, Select } from "antd";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
// style for this view
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import { infoColor } from "assets/jss/material-dashboard-pro-react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateCommunityServiceActivity, fetchCommunityServiceById } from "reduxToolkit/features/CommunityServices/CommunityServicesSlice";
import {
  fetchPrograms
} from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchCurrentSemester } from "reduxToolkit/features/semester/SemestersSlice";
import {
  fetchStaff, fetchStaffById, fetchStudents
} from "../../../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../../../Components/ErrorModal";
import TextArea from "antd/lib/input/TextArea";
import { fetchAuthorityAccordingToType, fetchAuthorityConfigs } from "../../../../../reduxToolkit/features/AuthorityConfig/AuthorityConfigSlice";
import { fetchColleges } from "../../../../../reduxToolkit/features/college/CollegesSlice";
import { fetchCourses } from "../../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchDepartments } from "../../../../../reduxToolkit/features/department/DepartmentsSlice";
import moment from 'moment'

const dateFormat = 'YYYY/MM/DD'

export default function EditCommunityServiceActivity({ match }) {
  const { t } = useTranslation();
  let history = useHistory();
  let { id, staffId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();


  const [levelType, setLevelType] = React.useState("");
  const [start_date, setStartDate] = React.useState("");
  const [end_date, setEndDate] = React.useState("");
  const [communityType, setCommunityType] = React.useState("");
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");


  useEffect(() => {
    dispatch(fetchStaffById(staffId));
    dispatch(fetchStaff());
    dispatch(fetchStudents())
    dispatch(fetchAuthorityConfigs());
  }, []);

  useEffect(() => {
    dispatch(fetchCommunityServiceById(id)).unwrap().then((communityService) => {
      console.log("communityService", communityService)
      setLevelType(communityService?.authorityLevelConfig_details?.[0]?.level_id)
      setCommunityType(communityService?.community)
      form.setFieldsValue({
        type: communityService?.type,
        title: communityService?.title,
        start_date: moment(communityService?.start_date, dateFormat),
        end_date: moment(communityService?.end_date, dateFormat),
        theme: communityService?.theme,
        description: communityService?.description,
        location: communityService?.location,
        community: communityService?.community,
        organization_body_ids: communityService?.organization_body_ids?.[0],
        authorityLevelSelect: communityService?.authorityLevelConfig_details?.[0].level,
        authorityLevelConfig_id: communityService?.authorityLevelConfig
      })
      if (communityService?.community == 'students') {
        form.setFieldsValue({
          students_ids: communityService?.students_ids
        })
      } else if (communityService?.community == 'faculty') {
        form.setFieldsValue({
          faculty_ids: communityService?.faculty_ids
        })
      } else if (communityService?.community == 'external participants') {
        form.setFieldsValue({
          external_participants_ids: communityService?.external_participants
        })
      } else if (communityService?.community == 'staff') {
        form.setFieldsValue({
          staff_ids: communityService?.staff_ids
        })
      }
    })
  }, []);

  const staff = useSelector((state) => state.user.staff);
  const programs = useSelector((state) => state.program.programs);
  const courses = useSelector((state) => state.course.courses);
  const staffList = useSelector(
    (state) => state.user.staffList
  );
  const students = useSelector(
    (state) => state.user.students
  );
  const AuthorityConfigs = useSelector((state) => state.authorityConfig.AuthorityConfigs);
  const colleges = useSelector((state) => state.college.colleges);
  const departments = useSelector((state) => state.department.departments);

  //const authorsList = useSelector((state) => state.authorityConfig.AuthorityConfigs)



  function handleActivityLevelTypeChange(communityString) {
    console.log(communityString);
    setLevelType(communityString);
    // dispatch(fetchAuthorityConfigs());
    dispatch(fetchAuthorityAccordingToType(communityString.toString()))

    if (communityString == "department") {
      dispatch(fetchDepartments())

    } else if (communityString == "college") {
      dispatch(fetchColleges())
    }
    else if (communityString == "program") {
      const userId = localStorage.getItem('userId');
      dispatch(fetchCurrentSemester(userId))
        .unwrap()
        .then((semester) => {
          //console.log("semesterId", semester.semester_id);
          dispatch(fetchPrograms(semester.semester_id));
        });
    }
    else if (communityString == "course") {
      dispatch(fetchCourses())
    }
  }

  function handleCommunityTypeChange(communityString) {
    console.log('communityString', communityString);
    setCommunityType(communityString);
    if (communityString == "student") {
      dispatch(fetchStudents())

    } else if (communityString == "faculty") {
      dispatch(fetchStaff())
    }

  }

  function onStartDateChange(value, dateString) {
    setStartDate(dateString);
  }

  function onEndDateChange(value, dateString) {
    setEndDate(dateString);
  }


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Submit failed!");
  };

  const onFinish = async (values) => {

    const toAddFacultyActivity = {
      ...values,
      id
    };

    const resultAction = await dispatch(updateCommunityServiceActivity(toAddFacultyActivity));

    if (updateCommunityServiceActivity.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Updated successfully`);
      history.push(`/citrine/faculty/teachingStaff/${staffId}/communityServicesActivities`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const { Option } = Select;

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${staffId}`}>
              {`${staff?.person_details?.[0]?.first_name} ${staff?.person_details?.[0]?.last_name}`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${staffId}/communityServicesActivities`}
            >
              {t("Community services")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Edit community service
              </small>
            </h3>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Type")}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please select a type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} showArrow>
                        <Option value="training">Training</Option>
                        <Option value="workshop">Workshop</Option>
                        <Option value="event">Event</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Activity Level")}
                      name="authorityLevelConfig_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }} showArrow
                        onChange={handleActivityLevelTypeChange}
                      >
                        {AuthorityConfigs?.map((authority) => {
                          return <Option value={authority.level_details.id}>
                            {authority.level_details.name}
                          </Option>
                        })}
                      </Select>
                    </Form.Item>
                  </GridItem>


                  {/* {levelType == "college" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Colleges")} name="college_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {colleges &&
                            colleges.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)}
                  {levelType == "department" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Departments")} name="department_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {departments &&
                            departments.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)}
                  {levelType == "course" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Courses")} name="course_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {courses &&
                            courses.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.title}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)}
                  {levelType == "program" ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item label={t("Programs")} name="program_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {programs &&
                            programs.map((program) => (
                              <Option key={program.id} value={program.id}>
                                {program.program_degree_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                  ) : (<p></p>)} */}


                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Title")}
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Community")}
                      name="community"
                      rules={[
                        {
                          required: true,
                          message: "Please select the type",
                        },
                      ]}

                    >
                      <Select style={{ width: "100%" }} showArrow
                        // mode="multiple"

                        onChange={handleCommunityTypeChange}
                      >
                        <Option value="students">Students</Option>
                        <Option value="faculty">Faculty</Option>
                        <Option value="staff">Staff</Option>
                        <Option value="external participants">
                          External participants
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  {communityType == "students" ? (

                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item
                        label={t("Students")}
                        name="students_ids"
                      >
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          size={200}
                          showArrow
                        // onChange={handleGraduationRequirements_ids}
                        >
                          {students && students.map((student) => (
                            <Option key={student.id} value={parseInt(student.id)}>
                              {student?.student_details?.first_name} { } {student?.student_details?.last_name}

                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </GridItem>) : (<p></p>)}

                  {communityType == "faculty" ? (<GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Faculty")}
                      name="faculty_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {staffList && staffList.map((staff) => (
                          <Option key={staff.id} value={parseInt(staff.id)}>
                            {staff?.person_details[0]?.first_name} { } {staff?.person_details[0]?.last_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>) : [<p></p>]}

                  {communityType == "external participants" ? (

                    <GridItem xs={12} sm={12} md={12}>
                      <Form.Item
                        label={t("External participants")}
                        name="external_participants_ids"
                        rules={[
                          {
                            // required: true,
                            message: "Please enter the external participants",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>

                  ) : [<p></p>]}


                  {communityType == "staff" ? (<GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Staff")}
                      name="staff_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {staffList.map((staff) => (
                          <Option key={staff.id} value={parseInt(staff.id)}>
                            {staff?.person_details[0]?.first_name} { } {staff?.person_details[0]?.last_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>) : [<p></p>]}

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Start date")}
                      name="start_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the start date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onStartDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("End date")}
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the end date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={onEndDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Description")}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the description",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Theme")}
                      name="theme"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the theme",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Location")}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the location",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Authority Person")}
                      name="authorityLevelConfig_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select the authors",
                        },
                      ]}
                    >
                      <Select
                        // mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {AuthorityConfigs &&
                          AuthorityConfigs.map((authority) => (
                            <Option key={authority.id} value={authority?.role_details?.id}>

                              {authority?.role_details?.role}

                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Organization body")}
                      name="organization_body_ids"
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {staffList.map((staff) => (
                          <Option key={staff.id} value={parseInt(staff.id)}>
                            {staff.person_details[0].first_name} { } {staff.person_details[0].last_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>


                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* </FormControl> */}

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Update")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
