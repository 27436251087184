import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/icons

import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';

import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { fetchSpecificationAprovalDataById, SpecificationAprovalDataUpdate } from '../../../../../reduxToolkit/features/SpecificationAprovalData/SpecificationAprovalDataSlice';

import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

import { Breadcrumb, DatePicker } from 'antd';

import { useTranslation } from 'react-i18next';

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles(styles);

export default function UpdateSpecificationAprovalData({ match }) {
    const { t } = useTranslation();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const classes = useStyles();
    const searchButton =
        navBarClasses.top +
        " " +
        navBarClasses.searchButton


    const { idCourse } = match.params
    const { id } = match.params
    console.log('idCourse  ', idCourse)
    console.log('id  ', id)


    const course = useSelector((state) => state.CourseSpec.courseSpecification)


    const dispatch = useDispatch()
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchSpecificationAprovalDataById(id))
        setCouncil_committee(SpecificationAprovalData.council_committee)
        setReference(SpecificationAprovalData.reference)
        setDate(SpecificationAprovalData.date)
    }, [dispatch, SpecificationAprovalData])

    const SpecificationAprovalData = useSelector((state) => state.SpecificationAprovalData.SpecificationAprovalData)
    console.log('SpecificationAprovalData', SpecificationAprovalData)

    const [council_committee, setCouncil_committee] = useState(council_committee)
    const [reference, setReference] = useState(reference)
    const [date, setDate] = useState(date)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    console.log('council_committee', council_committee)

    const onCouncil_committeeChanged = (e) => setCouncil_committee(e.target.value)
    const oRreferenceChanged = (e) => setReference(e.target.value)
    const onDateChanged = (e) => setDate(e.target.value)




    const canSave =
        [council_committee, reference, date].every(Boolean) && addRequestStatus === 'idle'

    const onUpdateSpecificationAprovalDataClicked = async () => {

        //  if (canSave) {
        try {
            setAddRequestStatus('pending')
            console.log('onupdate')
            const resultAction = await dispatch(
                SpecificationAprovalDataUpdate({ id: id, modified: { council_committee, reference, date } })
            )
            unwrapResult(resultAction)
            setCouncil_committee('')
            setReference('')
            setDate('')


            history.push(`/citrine/courseSpecDetails/${idCourse}`);

        } catch (err) {
            console.error('Failed to update the course: ', err)
        } finally {
            setAddRequestStatus('idle')
        }
        //   }

    }


    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    function onChange(date, dateString) {
        //console.log(date, dateString);
        setDate(dateString)
    }
    function onOk(value) {
        console.log('onOk: ', value);
    }

    return (


        <GridContainer >
            <GridItem>
                <GridItem>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to={`/citrine/CourseSpecificationList/`}>{t('Courses List')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/citrine/courseSpecDetails/${idCourse}`}>{course && course.title && course.title}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link style={{ color: "orange" }} to={`/citrine/UpdateSpecificationAprovalData/${idCourse}/${id}`}>{t('edit')}
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </GridItem>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info" icon>

                    </CardHeader>
                    <CardBody>
                        <form>
                            <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", color: "gray" }}>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText={t("council committee *")}
                                        id="council_committee"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        type="text"
                                        name="council_committee"
                                        value={council_committee}
                                        inputProps={{
                                            onChange: event => {

                                                setCouncil_committee(event.target.value);
                                            },
                                            value: council_committee
                                        }}
                                    />
                                    <CustomInput
                                        labelText={t("reference *")}
                                        id="reference"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        type="text"
                                        name="reference"
                                        value={reference}
                                        inputProps={{
                                            onChange: event => {

                                                setReference(event.target.value);
                                            },
                                            value: reference
                                        }}
                                    />
                                    <GridItem style={{ display: "flex" }}>
                                        <p style={{ marginRight: "20px" }}>{t('Date*')}</p>
                                        <DatePicker
                                            showTime onChange={onChange} onOk={onOk} />
                                    </GridItem>
                                    <GridItem>
                                        <Button color="info"
                                            className={classes.updateProfileButton}
                                            onClick={onUpdateSpecificationAprovalDataClicked}
                                        >
                                            {t('Save')}
                                        </Button>
                                    </GridItem>
                                </GridItem>

                            </GridContainer>
                        </form>

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>







    );

}




