import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from "antd";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import ErrorModal from "../../../Components/ErrorModal";
import { notification } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";


import { fetchUtilizedConfigs, deleteUtilizedConfig } from "../../../../reduxToolkit/features/UtilizedRsearch/utilizedResearchSlice";

const useCardStyles = makeStyles(cardStyles);

export default function UtilizedResearchLabsPage() {
  const cardClasses = useCardStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [open, setOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    setOpen(true);
    setToBeDeleted(id)
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleDeleteItem = async (id) => {
    if (id) {
      dispatch(deleteUtilizedConfig(id))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          // handle result here
          openNotificationWithIcon("success", `Deleted`);
          dispatch(fetchUtilizedConfigs());
        })
        .catch((rejectedValueOrSerializedError) => {
          setHandledError(rejectedValueOrSerializedError);
          handleOpenErrorModal();
        });
    }
  };


  useEffect(() => {
    dispatch(fetchUtilizedConfigs());
  }, []);

  const UtilizedConfigs = useSelector((state) =>
    state.utilizedConfig.UtilizedConfigs
  );

  return (
    <div>
      <GridContainer
        md={12}
        xs={12}
        sm={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t("other Configurations")} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/UtilizedResearchLabsPage`}>
                <span> {t("Utilized Research Page")} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/configuration/others/AddUtilizedResearchLabsPage">
        <Button color="info">{t("  Add")}</Button>
      </Link>
      <GridContainer md={12} xs={12} sm={12}>
        {UtilizedConfigs &&
          UtilizedConfigs.map((keyword) => (
            <GridItem xs={12} sm={12} md={4} key={keyword.id}>
              <Card
                style={{
                  maxWidth: "380px",
                  minWidth: "380px",
                  maxHeight: "10px",
                  minHeight: "100px",
                }}
                cardHover
              >
                <CardHeader>
                  <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <GridItem xs={10} sm={10} md={10}>
                      <h4 className={cardClasses.cardProductTitle}>
                        {`Name : ${keyword.name}`} / {`Code : ${keyword.code}`}
                      </h4>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                      <CustomDropdown
                        dropPlacement="bottom-end"
                        isButtonIcon
                        isFilled
                        iconButtonProps={{ size: "medium" }}
                        dropdownList={[
                          { text: "Delete", onClickHandler() { handleOpenDeleteModal(keyword.id) } },
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={toBeDeleted}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
}
