import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import token from 'assets/utils/accessToken';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  subAreas: [],
  subAreasStatus: 'idle',
  subAreaStatus: 'idle',
  error: null,
  subArea:null,
 
  id:null
}


export const fetchSubAreas = createAsyncThunk('subArea/fetchSubAreas', async () => {
  const token=localStorage.getItem('token')
  const response = await axios.get(BaseUrl+'/subArea/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')
     
    return response.data
  })

  export const fetchSubAreaById = createAsyncThunk('subArea/fetchSubAreaById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/subArea/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewSubArea = createAsyncThunk(
    'subArea/addNewSubArea',
    async (initialSubArea) => {
      console.log('initial subArea', initialSubArea)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/subArea/create/`,initialSubArea ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const subAreaUpdate = createAsyncThunk(
    'subArea/subAreaUpdate',
    async (data,thunkAPI) => {
      
      try{
        const token=localStorage.getItem('token')
        const response = await axios.put(BaseUrl+`/subArea/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedSubArea')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the subArea: ', error)

}
    }
  )

  export const deleteSubArea = createAsyncThunk('subArea/deleteSubArea', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/subArea/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
const  SubAreaSlice = createSlice({
  name: ' subArea',
  initialState,

  extraReducers: {
    [fetchSubAreas.pending]: (state, action) => {
        state.subAreasStatus = 'loading'
      },
      [fetchSubAreas.fulfilled]: (state, action) => {
        state.subAreasStatus = 'succeeded'
        // Add any fetched posts to the array
        state.subAreas = action.payload
      },
      [fetchSubAreas.rejected]: (state, action) => {
        state.subAreasStatus = 'failed'
        state.error = action.payload
      },

      [fetchSubAreaById.pending]: (state, action) => {
        state.subAreaStatus = 'loading'
      },
      [fetchSubAreaById.fulfilled]: (state, action) => {
        state.subAreaStatus = 'succeeded'

        // Add any fetched posts to the array
        state.subArea = action.payload
      },
      [fetchSubAreaById.rejected]: (state, action) => {
        state.subAreaStatus = 'failed'
        state.error = action.payload
      },
      [addNewSubArea.fulfilled]: (state, action) => {
        state.subAreas.push(action.payload)
      },
      [subAreaUpdate.fulfilled]: (state, action) => {
        console.log(action) 
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    subAreaUpdated(state, action) {
      const { id,name } = action.payload
      const existingSubArea = state.subAreas.find(subArea => subArea.id === id)
      if (existingSubArea) {
        existingSubArea.name = name
       

      }
    }
  }
}})

export const { subAreaAdded, subAreaUpdated, getId} = SubAreaSlice.actions

export default SubAreaSlice.reducer

export const selectAllSubAreas = state => state.subArea.subAreas

