import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, notification, Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ErrorModal from "../../../Components/ErrorModal.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import {
  fetchNotGeneratedCoursesByProgram,
  regenerateCourse
} from "../../../../reduxToolkit/features/course/CoursesSlice.js";

export default function AddCourse(props) {

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { Option } = Select;

  const [open, setOpen] = useState(false);
  const [program_id, setProgram_id] = useState(props.program_id);
  const [semester_id, setSemester_id] = useState(props.semester_id);
  const [courses_ids, setcourses_ids] = useState([]);
  const [courses, setcourses] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  useEffect(() => {
    dispatch(fetchNotGeneratedCoursesByProgram(props.program_id))
      .unwrap()
      .then((courses) => {
        setcourses(courses);
      });
  }, []);

  const onChangeCourses_ids = (value) => {
    setcourses_ids(value.join());
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
  };

  const onFinish = async () => {
    const resultAction = await dispatch(
      regenerateCourse({ courses_ids, program_id , semester_id})
    );
    if (regenerateCourse.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      props.refreshCourses();
      openNotificationWithIcon("success", `Regenerated`);
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <MenuItem
        onClick={() => {
          handleClickOpen();
          props.handleDropDownButtonClose();
        }}
      >
        {t("Add Course")}
      </MenuItem>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{t("Add a course")}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Courses")}
                      name="courses_ids"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select courses"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        value={courses_ids}
                        onChange={onChangeCourses_ids}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        dropdownStyle={{ zIndex: 10000 }}
                      >
                        {courses &&
                          courses.map((course) => (
                            <Option key={course.id} value={course.id}>
                              {course.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
