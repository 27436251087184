import Search from "@material-ui/icons/Search";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from "@mui/x-data-grid";
import { Breadcrumb, notification } from "antd";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Link } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  grayColor
} from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchfacultyActivitiesByProgram } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import ErrorModal from "../../../Components/ErrorModal";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Button color="primary" simple>
        <GridToolbarExport
          style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}
        />
      </Button>
    </GridToolbarContainer>
  );
}

export default function ActivitiesByProgram() {
  // const [subLevels, setSubLevels] = useState([]);

  const dispatch = useDispatch();

  let { id } = useParams();
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [search, setSearch] = useState("");

  const fetchfacultyActivities = async () => {
    try {
      const resultAction = await dispatch(
        fetchfacultyActivitiesByProgram(id)
      ).unwrap();
      // handle result here

      setRows(
        resultAction.filter((a) =>
          a.description.toLowerCase().includes(search.toLowerCase())
        )
      );
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const [disabledStatus, setDisabledStatus] = useState(false);

  useEffect(() => {
    fetchfacultyActivities();
  }, [search, dispatch]);

  // useEffect(() => {
  //   dispatch(fetchSubCodeLevels())
  //     .unwrap()
  //     .then((subLevelsObjects) => {
  //       setSubLevels(subLevelsObjects.map((subLevel) => subLevel.level));
  //     });
  // }, []);
  // const subLevelObjects = useSelector(
  //   (state) => state.subLevelClo.SubCodeLevels
  // );

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 100,
      sortable: false,
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "activity_type",
      headerName: "Type",
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "start_date",
      headerName: "Start date",
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "end_date",
      headerName: "End date",
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      headerName: "Details",
      field: "details",
      flex: 1,
      renderCell: (params) => (
        <Link to={`/citrine/faculty/programs/${id}/activities/${params.row.id}`}>
          <Button
            simple
            size="lg"
            color="primary"
          //   onClick={() => {
          //     console.log(params.row.id);
          //   }}
          >
            Details
          </Button>
        </Link>
      ),
    },
  ];

  const { t } = useTranslation();

  const [rows, setRows] = useState(null);

  // const handleRowEditCommit = (event) => {
  //   console.log("event commit", event)
  // };

  // const handleRowEditStop = async (event) => {
  //   console.log("event stop", event);
  //   const courseSpec_id = props.id;
  //   const code = event.row.code;
  //   const title = event.row.title;
  //   const target = event.row.target;
  //   const description = event.row.description;
  //   const subLevelObject =
  //     event.row.subLevelClo_id &&
  //     subLevelObjects.find(
  //       (element) => element.level === event.row.subLevelClo_id
  //     );
  //   const subLevelClo_id = subLevelObject && subLevelObject.id;

  //   if (
  //     event.row.id === "id" &&
  //     courseSpec_id &&
  //     code &&
  //     title &&
  //     target &&
  //     description &&
  //     subLevelClo_id
  //   ) {
  //     const newCLO = {
  //       courseSpec_id,
  //       code,
  //       title,
  //       target,
  //       description,
  //       subLevelClo_id,
  //     };
  //     console.log("newCLO", newCLO);
  //     const resultAction = await dispatch(addNewClo(newCLO));
  //     if (addNewClo.fulfilled.match(resultAction)) {
  //       openNotificationWithIcon("success", `Added successfully`);
  //       fetchClosByCourseMethod();
  //     } else {
  //       if (resultAction.payload) {
  //         setHandledError(resultAction.payload);
  //         handleOpenErrorModal();
  //       } else {
  //         setHandledError(resultAction.error);
  //         handleOpenErrorModal();
  //       }
  //     }
  //   }
  // };

  //   const onCellEditCommit = async (event) => {
  //     console.log("onCellEditCommit", event);
  //     const value = moment(event.value).format("YYYY-MM-DD");
  //     // const value = event.formattedValue
  //     // console.log("value", value);
  //     const field = event.field;
  //     // const updated = {
  //     //   [field]: value,
  //     // };
  //     const id = event.id;

  //     if (value && value !== "Invalid date" && value !== event.row[field]) {
  //       const resultAction = await dispatch(
  //         updateSurveyPlanningDates({ programs_update_ids: id, [field]: value })
  //       );
  //       if (updateSurveyPlanningDates.fulfilled.match(resultAction)) {
  //         // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
  //         dispatch(fetchAllPrograms());
  //         openNotificationWithIcon("success", `Updated successfully`);
  //       } else {
  //         if (resultAction.payload) {
  //           // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
  //           console.log(
  //             "resultAction.payload from component",
  //             resultAction.payload
  //           );
  //           setHandledError(resultAction.payload);
  //           handleOpenErrorModal();
  //         } else {
  //           console.log("resultAction.error  from component", resultAction.error);
  //           setHandledError(resultAction.error);
  //           handleOpenErrorModal();
  //         }
  //       }
  //     }
  //   };

  const [selectionModel, setSelectionModel] = React.useState([]);

  const clos = useSelector((state) => state.cloSpec.closByCourse);

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/faculty/programs`}>{t("Programs List")}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <GridContainer alignItems="baseline">
        <GridItem xs={7} sm={7} md={7}>
          <Link to={`/citrine/faculty/programs/${id}/activities/create`}>
            <Button color="primary">{t("Add")}</Button>
          </Link>
        </GridItem>

        <GridItem xs={4} sm={4} md={4}>
          <CustomInput
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value);
              },
              value: search,
              placeholder: "Search",
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>

        <GridItem xs={1} sm={1} md={1}>
          <Button color="white" aria-label="edit" justIcon round>
            <Search />
          </Button>
        </GridItem>
      </GridContainer>

      {/* <GridContainer justify="flex-end">
          <GridItem>
            <SurveyDropDown
              selectedRows={selectionModel}
              // refreshCourses={refreshCourses}
            />
          </GridItem>
        </GridContainer> */}
      {/* <Button
          color="primary"
          onClick={() => {
            setRows([{ id: "id" }, ...rows]);
          }}
        >
          Add
        </Button>
        <Button color="primary">Save</Button> */}
      <Card simple>
        <CardBody simple>
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              rowHeight={50}
              rows={rows || []}
              columns={columns}
              autoPageSize={true}
              columnBuffer={0}
              // disableColumnMenu
              disableSelectionOnClick
              //   onCellEditCommit={onCellEditCommit}
              showCellRightBorder
              // showColumnRightBorder
              // checkbox
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                console.log("newSelectionModel", newSelectionModel);
              }}
              selectionModel={selectionModel}
            // onRowEditCommit={handleRowEditCommit}
            // onRowEditStop={handleRowEditStop}
            //   components={{
            //     Toolbar: CustomToolbar,
            //   }}
            // componentsProps={{
            //   Toolbar: { color: 'red'},
            // }}
            />
          </div>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
