import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, notification, Select } from 'antd'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Button from 'components/CustomButtons/Button.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  addNewActionPlan,
  fetchActionPlans,
} from '../../reduxToolkit/features/ActionPlan/ActionPlanSlice'
import useTable from '../Components/useTable'
import ErrorModal from 'views/Components/ErrorModal'
import { Radio  } from "antd";
import { warningColor } from 'assets/jss/material-dashboard-pro-react.js'
import DialogActions from "@material-ui/core/DialogActions";
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const useButtonStyles = makeStyles(buttonStyles)
const headCells = [
  { id: 'Actions', label: 'Actions' },
  { id: 'Due Date', label: 'Due Date' },
  { id: 'Assigned To', label: 'Assigned To' },
  { id: 'Priority', label: 'Priority' },
]

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
export default function GenerateActionsCourseModal(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const buttonClasses = useButtonStyles()

  const [open, setOpen] = React.useState(false)
  const [actionRecommendation_ids, setActionRecommendation_ids] = useState([])
  const anchorRef = React.useRef(null)

  const { Option, OptGroup } = Select
  const { TextArea } = Input
  const [form] = Form.useForm()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')
  const [records, setRecords] = []
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })
  const { TblContainer, TblHead } = useTable(records, headCells, filterFn)
  const [major, setMajor] = useState(false);
  const [minor, setMinor] = useState(false);
  const [yes, setYes] = useState(false);
  const [no, setNo] = useState(false);
  useEffect(() => {}, [])

  const ActionRecommendationsCourse = useSelector(
    (state) => state.ActionRecommendation.ActionRecommendationsCourse,
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()

  let actionPlan = {
    name:
      props.CourseReport &&
      props.CourseReport.course &&
      props.CourseReport.course[0] &&
      props.CourseReport.course[0].title,
    program_id:
      props.CourseReport &&
      props.CourseReport.course &&
      props.CourseReport.course[0] &&
      props.CourseReport.course[0].program &&
      props.CourseReport.course[0].program.id,
    semester_id:
      props.CourseReport &&
      props.CourseReport.course &&
      props.CourseReport.course[0] &&
      props.CourseReport.course[0].semester &&
      props.CourseReport.course[0].semester.id,
    resource: 'course',
    resource_id: props.CourseReport && props.CourseReport.id,
  }
  function onChangeMajor(e) {
    console.log(`checked Major = ${e.target.checked}`);
    setMajor(e.target.checked);
  }
  function onChangeMinor(e) {
    console.log(`checked Minor = ${e.target.checked}`);
    setMinor(e.target.checked);
  }
  function onChangeYes(e) {
    console.log(`checked yes = ${e.target.checked}`);
    setYes(e.target.checked);
  }
  function onChangeNo(e) {
    console.log(`checked no = ${e.target.checked}`);
    setNo(e.target.checked);
  }
  const onFinish = async (values) => {
    dispatch(addNewActionPlan(actionPlan, actionRecommendation_ids))
      .unwrap()
      .then((originalPromiseResult) => {
        openNotificationWithIcon('success', `Generate successfully`)
        dispatch(fetchActionPlans())
        history.push(`/citrine/actionPlan/Workflow`)
        form.resetFields()
        handleClose()
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError)
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
        form.resetFields()
        handleClose()
      })
    // const resultAction = await dispatch(
    //   addNewActionPlan(actionPlan, actionRecommendation_ids)
    // );
    // if (addNewActionPlan.fulfilled.match(resultAction)) {
    //   openNotificationWithIcon("success", `Generate successfully`);
    //   dispatch(fetchActionPlans());
    //   history.push(`/citrine/actionPlan/Workflow`);
    //   form.resetFields();
    //   handleClose();
    // } else {
    //   if (resultAction.payload) {
    //     setHandledError(resultAction.payload);
    //     handleOpenErrorModal();
    //     form.resetFields();
    //     handleClose();
    //   } else {
    //     setHandledError(resultAction.error);
    //     handleOpenErrorModal();
    //     form.resetFields();
    //     handleClose();
    //   }
    // }
  }

  return (
    <div>
      <Button
        style={{
          backgroundColor: warningColor[0],
          margin: '5px 10px',
          //marginTop: "5px",
          padding: '6px 10px',
        }}
        size="sm"
        onClick={(event) => {
          handleClickOpen(event)
          props.handleDropDownButtonClose()
        }}
      >
        <strong style={{ color: 'white', fontSize: '11px' }}>
          {t('Generate Actions')}
        </strong>
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth="true" maxWidth="sm">
        <DialogContent>
          <div
            style={{
              margin: '0px 10px 10px 10px ',
            }}
          >
            
              <h4> {t("Are you sure you want to Generate Action Plan ?")}</h4>
           
          
                 
                    
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="info">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              onFinish();
            }}
            color="primary"
          >
            {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
