import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import token from "assets/utils/accessToken";

const initialState = {
  ActionRecommendationsPlo: [],
  ActionRecommendationsPeo: [],
  ActionRecommendationsCourse: [],
  ActionRecommendationsStatus: "idle",
  error: null,
  id: null,
  Actions: null,
  ActionsStatus: "idle",
  ActionsByUser: [],
};

export const fetchActions = createAsyncThunk(
  "ActionRecommendation/fetchActions",
  async (filters, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/actionRecommendation/actions/?program_id=${filters.programs}&assignee_id=${filters.assignees}&college_id=${filters.colleges}&department_id=${filters.departments}&state=${filters.states}&priority_id=${filters.priorities}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchAllActionsRecommendations = createAsyncThunk(
  "ActionRecommendation/fetchAllActionsRecommendations",
  async (params, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/actionRecommendation/all/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchAllActionsRecommendationsByUser = createAsyncThunk(
  "ActionRecommendation/fetchAllActionsRecommendationsByUser",
  async (params, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/actionRecommendation/ByUser/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const addNewActionRecommendation = createAsyncThunk(
  "ActionRecommendation/addNewRActionRecommendation",
  async (initialActionRecommendation, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/actionRecommendation/create/`,
        initialActionRecommendation,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchPloActionsRecommendationsByProgram = createAsyncThunk(
  "ActionRecommendation/fetchAllActionsRecommendationsByProgram",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/plo/actions/?program_id=${obj.program_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchPeoActionsRecommendationsByProgram = createAsyncThunk(
  "ActionRecommendation/fetchPeoActionsRecommendationsByProgram",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/peo/actions/?program_id=${obj.program_id}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchAllActionsRecommendationsByCourse = createAsyncThunk(
  "ActionRecommendation/fetchAllActionsRecommendationsByCourse",
  async (obj, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/course/actions/${obj.course_id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);
export const deleteActionRecommendation = createAsyncThunk(
  "Recommendations/deleteActionRecommendation",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        BaseUrl + `/actionRecommendation/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const updateActionRecommendation = createAsyncThunk(
  "clo/updateActionRecommendation",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/actionRecommendation/${data.id}/edit/`,
        data.updatedActionRecommendation,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);
const ActionRecommendationSlice = createSlice({
  name: "ActionRecommendation",
  initialState,
  extraReducers: {
    [fetchAllActionsRecommendations.pending]: (state, action) => {
      state.ActionsStatus = "loading";
    },
    [fetchAllActionsRecommendations.fulfilled]: (state, action) => {
      state.ActionsStatus = "succeeded";
      state.Actions = action.payload;
    },
    [fetchAllActionsRecommendations.rejected]: (state, action) => {
      state.ActionsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchActions.pending]: (state, action) => {
      state.ActionsStatus = "loading";
    },
    [fetchActions.fulfilled]: (state, action) => {
      state.ActionsStatus = "succeeded";
      state.Actions = action.payload;
    },
    [fetchActions.rejected]: (state, action) => {
      state.ActionsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchAllActionsRecommendationsByUser.pending]: (state, action) => {
      state.ActionsStatus = "loading";
    },
    [fetchAllActionsRecommendationsByUser.fulfilled]: (state, action) => {
      state.ActionsStatus = "succeeded";
      state.ActionsByUser = action.payload;
    },
    [fetchAllActionsRecommendationsByUser.rejected]: (state, action) => {
      state.ActionsStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    // fetch plo ActionsRecommendationsByProgram_And_Semester
    [fetchPloActionsRecommendationsByProgram.pending]: (state, action) => {
      state.ActionPlanStatus = "loading";
    },
    [fetchPloActionsRecommendationsByProgram.fulfilled]: (state, action) => {
      state.ActionPlanStatus = "succeeded";
      state.ActionRecommendationsPlo = action.payload;
    },
    [fetchPloActionsRecommendationsByProgram.rejected]: (state, action) => {
      state.ActionPlanStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    // fetchPeoActionsRecommendationsByProgram_And_Semester
    [fetchPeoActionsRecommendationsByProgram.pending]: (state, action) => {
      state.ActionPlanStatus = "loading";
    },
    [fetchPeoActionsRecommendationsByProgram.fulfilled]: (state, action) => {
      state.ActionPlanStatus = "succeeded";
      state.ActionRecommendationsPeo = action.payload;
    },
    [fetchPeoActionsRecommendationsByProgram.rejected]: (state, action) => {
      state.ActionPlanStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    // fetchAllActionsRecommendationsByCourse
    [fetchAllActionsRecommendationsByCourse.pending]: (state, action) => {
      state.ActionPlanStatus = "loading";
    },
    [fetchAllActionsRecommendationsByCourse.fulfilled]: (state, action) => {
      state.ActionPlanStatus = "succeeded";
      state.ActionRecommendationsCourse = action.payload;
    },
    [fetchAllActionsRecommendationsByCourse.rejected]: (state, action) => {
      state.ActionPlanStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewActionRecommendation.fulfilled]: (state, action) => {},
    [addNewActionRecommendation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteActionRecommendation.fulfilled]: (state, action) => {},
    [deleteActionRecommendation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [updateActionRecommendation.fulfilled]: (state, action) => {
      // console.log(action)
    },
    [updateActionRecommendation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    reducers: {
      ActionRecommendationUpdate(state, action) {
        const {
          id,
          assign_to_id,
          priority,
          due_date,
          description,
          type_action,
        } = action.payload;
        const existingActionRecommendation = state.ActionRecommendations.find(
          (ActionRecommendation) => ActionRecommendation.id === id
        );
        if (existingActionRecommendation) {
          existingActionRecommendation.due_date = due_date;
          existingActionRecommendation.assign_to_id = assign_to_id;
          existingActionRecommendation.priority = priority;
          existingActionRecommendation.description = description;
          existingActionRecommendation.type_action = type_action;
        }
      },
    },
  },
});

export const {
  ActionRecommendationAdded,
  ActionRecommendationUpdate,
} = ActionRecommendationSlice.actions;

export default ActionRecommendationSlice.reducer;
