import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { Form, notification, Select } from 'antd'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ImageAvatar from '../../assets/img/default-avatar.png'
import { addCoordinatorToCourseAss } from '../../reduxToolkit/features/course/CoursesSlice'
import { fetchProgramById } from '../../reduxToolkit/features/program/ProgramsSlice'
import ErrorsModal from './ErrorsModal'
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    color: 'primary',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
export default function CourseInstructorModal(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [open, setOpen] = useState(false)

  const { Option } = Select
  /*   const [coordinator_id, setCoordinator_id] = useState(
      props?.course?.coordinator_id
    );
    const [theoryInstructor_id, setTheoryInstructor_id] = useState(
      props?.course?.theoryInstructor_id
    );
    const [labInstructor_ids, setLabInstructor_id] = useState(
      props?.course?.labInstructor_ids[0] == null
        ? []
        : props?.course?.labInstructor_ids
    );
    const [editors, setEditors_ids] = useState(
      props?.course?.editors[0] == null ? [] : props?.course?.editors
    );
    const [watchers, setWatchers_ids] = useState(
      props?.course?.watchers[0] == null ? [] : props?.course?.watchers
    ); */

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')

  /*******************Handle error and Notifications************/
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  /************************************************************ */
  const cordinator = useRef(null)
  const instructor = useRef(null)
  const lab = useRef(null)
  const editorss = useRef(null)
  const watchers = useRef(null)

  const handleSend = async (values) => {
    let data = {
      courses_ids: props.id,
      data: values,
    }
    handleClose()
    setUpdateRequestStatus('pending')
    dispatch(addCoordinatorToCourseAss(data))
      .unwrap()
      .then((res) => {
        openNotificationWithIcon('success', `Updated`)
        props.fetchProgAss()
        setUpdateRequestStatus('idle')
        dispatch(fetchProgramById(props.program.id))
      })
      .catch((err) => {
        setHandledError(err)
        handleOpenErrorModal()
        handleClose()
        setUpdateRequestStatus('idle')
        dispatch(fetchProgramById(props.program.id))
      })

    /*
    console.log(values)
    try {
      let data = {
        courses_ids: props.id,
        data: values
      };
      console.log(data);
      // console.log(values);
      handleClose();
      setUpdateRequestStatus("pending");
      const resultAction = await dispatch(addCoordinatorToCourseAss(data));

      openNotificationWithIcon("success", `Updated`);
       props.fetchProgAss()
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
      handleClose();
      console.log("Failed to save : ", err);
    } finally {
      setUpdateRequestStatus("idle");
       dispatch(fetchProgramById(props.program.id));
    }
    */
  }
  //console.log(props.course)
  const handleClickOpen = () => {
    setOpen(true)
    // console.log(props.course);
    // console.log(props.program);
  }

  const handleClose = () => {
    setOpen(false)
    /*  setCoordinator_id(props?.course?.coordinator_id);
     setTheoryInstructor_id(props?.course?.theoryInstructor_id);
     setLabInstructor_id(props?.course?.labInstructor_ids);
     setEditors_ids(props?.course?.editors);
     setWatchers_ids(props?.course?.watchers); */
  }

  /*******************************************************************************
   * Handle Watchers , Editors , coordinator , theoryInstructor , labInstructor
   *
   *******************************************************************************/
  /*  function onChangeCoordinator(value) {
     setCoordinator_id(value);
   }
   const onChangeLabInsctructor = (value) => {
     setLabInstructor_id(value);
   };
   function onChangeInstructor(value) {
     setTheoryInstructor_id(value);
   }
 
   function handleChangeEditors(value) {
     setEditors_ids(value);
   }
   function handleChangeWatchers(value) {
     setWatchers_ids(value);
   } */
  /**************************************************************************** */
  //console.log('hello i am in CourseInstructorModal.js')

  useEffect(() => {
    form.setFieldsValue({
      coordinator_id: props?.course?.coordinator_id,
      theoryInstructor_id: props?.course?.theoryInstructor_id,
      labInstructor_ids: props?.course?.labInstructor_ids,
      editors: props?.course?.editors,
      watchers: props?.course?.watchers,
    })
  }, [props.course])
  return (
    <div>
      {/********************************************************************************
       * Add Editors and watchers to course assessment
       * Edit Coordinator and instructor
       *
       * Add Staff List data as an options to all Select fields
       *
       * updated 06/10/2022
       *********************************************************************************/}
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event)
          props.handleDropDownButtonClose()
        }}
        style={{ color: '#AC79B0' }}
      >
        {t('Access Control')}
        {/*  {t("Edit Theory Instructor and Course Coordinator")} */}
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title" color="#AC79B0">
          {t('Access Control')}
        </DialogTitle>
        <DialogContent>
          <Form
            form={form}
            name="control-hooks"
            layout="vertical"
            onFinish={handleSend}
            scrollToFirstError
          >
            <GridContainer>
              {/****************************** Coordinator  ************************************************* */}

              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t('Coordinator')}
                  name="coordinator_id"
                  rules={[
                    { required: true, message: 'Please select a coordinator!' },
                  ]}
                  //  initialValue={coordinator_id}
                >
                  <Select
                    showSearch
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t('Select a Coordinator')}
                    ref={cordinator}
                    onKeyDown={(event) => {
                      if (event.key === 'Tab') {
                        // "Tab" key
                        event.preventDefault() // Prevent the default behavior (moving focus to the next input field)
                        instructor.current.focus() // Focus the next DatePicker component
                      }
                    }}
                    optionFilterProp="children"
                    // onChange={onChangeCoordinator}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {props.staffList &&
                      props.staffList.map((staff, key) => (
                        <Option value={staff && staff.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (
                            <span
                              style={{
                                backgroundColor: 'rgb(143, 102, 130)',
                                width: '23px',
                                height: '23px',
                                color: 'white',
                                borderRadius: '50%',
                                paddingLeft: '7px',
                                marginRight: '15px',
                                display: 'inline-block',
                                lineHeight: '23px',
                              }}
                            >
                              {staff?.person_details[0]?.first_name
                                .charAt(0)
                                .toUpperCase() + '     '}
                            </span>
                          ) : (
                            <img
                              style={{ width: '20px', height: '20px' }}
                              src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                            />
                          )}{' '}
                          {` ${
                            staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].first_name
                          } 
                         
                          ${
                            staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].last_name
                          }`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
              {/****************************** Theory Instructor  ************************************************* */}

              {/* <GridItem xs={12} sm={12} md={12}> */}
              {/* <Form.Item
                  label={t("Theory Instructor")}
                  name="theoryInstructor_id"
             
                > */}
              {/* <Select
                    showSearch
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t("Select a Theory Instructor")}
                    ref={instructor}
                    onKeyDown={(event) => {
                      if (event.key === "Tab") { // "Tab" key
                        event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        lab.current.focus(); // Focus the next DatePicker component
                      }
                    }}
                    optionFilterProp="children"
                  //  onChange={onChangeInstructor}
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  >
                    {props.staffList &&
                      props.staffList.map((staff, key) => (
                        <Option value={staff && staff.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (<span style={{
                            backgroundColor: 'rgb(143, 102, 130)',
                            width: '23px',
                            height: '23px',
                            color: 'white',
                            borderRadius: '50%',
                            paddingLeft: '7px',
                            marginRight: '15px',
                            display: 'inline-block',
                            lineHeight: '23px'
                          }}>{staff?.person_details[0]?.first_name.charAt(0).toUpperCase() + '     '}</span>) : (<img style={{ width: '20px', height: "20px" }} src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`} />)}                          {` ${staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].first_name
                            } 
                          ${" "}
                          ${staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].last_name
                            }`}
                        </Option>
                      ))}
                  </Select> */}
              {/* </Form.Item> */}
              {/* </GridItem> */}
              {/****************************** Lab Instructor  ************************************************* */}

              {/* <GridItem xs={12} sm={12} md={12}> */}
              {/* <Form.Item
                  label={t("Lab Instructor")}
                  name="labInstructor_ids"
              
                >
                  <Select
                    mode="multiple"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t("Select a Lab Instructor")}
                    ref={lab}
                    onKeyDown={(event) => {
                      if (event.key === "Tab") { // "Tab" key
                        event.preventDefault(); // Prevent the default behavior (moving focus to the next input field)
                        editorss.current.focus(); // Focus the next DatePicker component
                      }
                    }}
                    optionFilterProp="children"
                  //onChange={onChangeLabInsctructor}
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  >
                    {
                      props?.staffList?.map((staff, key) => (
                        <Option value={staff?.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (<span style={{
                            backgroundColor: 'rgb(143, 102, 130)',
                            width: '23px',
                            height: '23px',
                            color: 'white',
                            borderRadius: '50%',
                            paddingLeft: '7px',
                            marginRight: '15px',
                            display: 'inline-block',
                            lineHeight: '23px'
                          }}>{staff?.person_details[0]?.first_name.charAt(0).toUpperCase() + '     '}</span>) : (<img style={{ width: '20px', height: "20px" }} src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`} />)}

                          {` ${staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].first_name
                            } 
                        
                        ${staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].last_name
                            }`}
                        </Option>

                      ))}
                  </Select>
                </Form.Item> */}
              {/* </GridItem> */}
              {/****************************** Editors  ************************************************* */}

              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t('Editors')}
                  name="editors"
                  /*  rules={[
                { required: true, message: "Please select Watchers!" },
              ]} */
                  // initialValue={editors}
                >
                  <Select
                    mode="multiple"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t('Select an Editors')}
                    ref={editorss}
                    onKeyDown={(event) => {
                      if (event.key === 'Tab') {
                        // "Tab" key
                        event.preventDefault() // Prevent the default behavior (moving focus to the next input field)
                        watchers.current.focus() // Focus the next DatePicker component
                      }
                    }}
                    optionFilterProp="children"
                    //   onChange={handleChangeEditors}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }

                    //value={coordinator_id}
                  >
                    {props.staffList &&
                      props.staffList.map((staff, key) => (
                        <Option value={staff && staff.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (
                            <span
                              style={{
                                backgroundColor: 'rgb(143, 102, 130)',
                                width: '23px',
                                height: '23px',
                                color: 'white',
                                borderRadius: '50%',
                                paddingLeft: '7px',
                                marginRight: '15px',
                                display: 'inline-block',
                                lineHeight: '23px',
                              }}
                            >
                              {staff?.person_details[0]?.first_name
                                .charAt(0)
                                .toUpperCase() + '     '}
                            </span>
                          ) : (
                            <img
                              style={{ width: '20px', height: '20px' }}
                              src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                            />
                          )}{' '}
                          {` ${
                            staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].first_name
                          } 
                          ${' '}
                          ${
                            staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].last_name
                          }`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
              {/****************************** Watchers  ************************************************* */}

              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t('Watchers')}
                  name="watchers"
                  /*  rules={[
                { required: true, message: "Please select Watchers!" },
              ]} */
                  //initialValue={watchers}
                >
                  <Select
                    mode="multiple"
                    style={{ width: 400 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    placeholder={t('Select a Watchers')}
                    ref={watchers}
                    onKeyDown={(event) => {
                      if (event.key === 'Tab') {
                        // "Tab" key
                        event.preventDefault() // Prevent the default behavior (moving focus to the next input field)
                        cordinator.current.focus() // Focus the next DatePicker component
                      }
                    }}
                    optionFilterProp="children"
                    // onChange={handleChangeWatchers}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }

                    //value={coordinator_id}
                  >
                    {props.staffList &&
                      props.staffList.map((staff, key) => (
                        <Option value={staff && staff.id} key={key}>
                          {!staff?.person_details[0]?.avatar ? (
                            <span
                              style={{
                                backgroundColor: 'rgb(143, 102, 130)',
                                width: '23px',
                                height: '23px',
                                color: 'white',
                                borderRadius: '50%',
                                paddingLeft: '7px',
                                marginRight: '15px',
                                display: 'inline-block',
                                lineHeight: '23px',
                              }}
                            >
                              {staff?.person_details[0]?.first_name
                                .charAt(0)
                                .toUpperCase() + '     '}
                            </span>
                          ) : (
                            <img
                              style={{ width: '20px', height: '20px' }}
                              src={`${MediaBaseUrl}${staff?.person_details[0]?.avatar}`}
                            />
                          )}
                          {` ${
                            staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].first_name
                          } 
                          
                          ${
                            staff &&
                            staff.person_details &&
                            staff.person_details[0] &&
                            staff.person_details[0].last_name
                          }`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
              {/******************************************************************************************* */}
            </GridContainer>
            {/******************************************************************************** */}

            <DialogActions>
              <Button onClick={handleClose} color="danger">
                {t('Cancel')}
              </Button>
              <Button color="info" htmlType="submit" type="primary">
                {t('Save')}
              </Button>
            </DialogActions>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorsModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
