export const analysis = [
  {
    ID: 1,
    question: "question 1 under lecture",
    stronglyAgree: "7 - 20%",
    agree: "2 - 20%",
    subarea: "lecture",
    disagree: "3 - 20%",
    stronglyDisagree: "0 - 0%",
    notApplicable: "2 - 20%",
    answered: 12,
    average: 4,
  },
  {
    ID: 2,
    question: "question 1 under classroom",
    stronglyAgree: "2 - 20%",
    agree: "2 - 20%",
    subarea: "classroom",
    disagree: "3 - 20%",
    stronglyDisagree: "0 - 20%",
    notApplicable: "2 - 20%",
    answered: 15,
    average: 3.5,
  },
  {
    ID: 3,
    question: "question 1 under professor",
    stronglyAgree: "4 - 20%",
    agree: "1 - 20%",
    subarea: "professor",
    disagree: "5 - 20%",
    stronglyDisagree: "5 - 20%",
    notApplicable: "2 - 20%",
    answered: 20,
    average: 3.5,
  },
  {
    ID: 4,
    question: "question 2 under professor",
    stronglyAgree: "4 - 20%",
    agree: "4 - 20%",
    subarea: "professor",
    disagree: "5 - 20%",
    stronglyDisagree: "1 - 20%",
    notApplicable: "2 - 20%",
    answered: 12,
    average: 3.5,
  },
  {
    ID: 5,
    question: "question 2 under lecture",
    stronglyAgree: "10 - 20%",
    agree: "2 - 20%",
    subarea: "lecture",
    disagree: "2 - 20%",
    stronglyDisagree: "9 - 20%",
    notApplicable: "2 - 20%",
    answered: 20,
    average: 3.5,
  },
  {
    ID: 6,
    question: "question 2 under classroom",
    stronglyAgree: "3 - 20%",
    agree: "5 - 20%",
    subarea: "classroom",
    disagree: "9 - 20%",
    stronglyDisagree: "0 - 20%",
    notApplicable: "2 - 20%",
    answered: 10,
    average: 3.5,
  },
  {
    ID: 7,
    question: "question 3 under classroom",
    stronglyAgree: "2 - 20%",
    agree: "3 - 20%",
    subarea: "classroom",
    disagree: "5 - 20%",
    stronglyDisagree: "0 - 20%",
    notApplicable: "2 - 20%",
    answered: 15,
    average: 3.5,
  },
];

export const details = [
  {
      "ID": 1,
      "studentsNumber": 7,
      "totalParticipantsNumber": 5,
      "localParticipantsNumber": 4,
      "foreignParticipantsNumber": 1,
      "maleParticipantsNumber":2,
      "femaleParticipantsNumber":3,
      "responseRate": "80%",
      "totalSatisfactionRate": 2.22,
      "localSatisfactionRate": 1.22,
      "foreignSatisfactionRate": 3.22,
      "maleSatisfactionRate": 3.22,
      "femaleSatisfactionRate": 1.22,
      "average": 3.22,
      "weightedAverageByNationality":3.1,
      "weightedAverageByGender":2.9,
  }
]


export const newdetails =[
  {
      "ID": 1,
      "studentsNumber": 4,
      "totalParticipantsNumber": 4,
      "localParticipantsNumber": 2,
      "foreignParticipantsNumber": 0,
      "maleParticipantsNumber": 0,
      "femaleParticipantsNumber": 0,
      "responseRate": "100 %",
      "totalSatisfactionRate": 2.22,
      "localSatisfactionRate": 1.22,
      "foreignSatisfactionRate": 3.22,
      "maleSatisfactionRate": 3.22,
      "femaleSatisfactionRate": 1.22,
      "average": 3.22,
      "weightedAverageByNationality": 3.1,
      "weightedAverageByGender": 2.9
  }
]


export const allAreasAnalysis =
[
  {
      "area": "Analysis",
      "analysis": [
          {
              "ID": 38,
              "question": "The course materials were made available on Blackboard",
              "subarea": "About the course",
              "stronglyAgree": "0-0%",
              "agree": "1-50%",
              "disagree": "0-0%",
              "stronglyDisagree": "0-0%",
              "notApplicable": "0-0%",
              "answered": 2,
              "average": 1.67
          },
          {
              "ID": 44,
              "question": "Did you use the textbook/ articles/ Internet reading to clarify your understanding of the course?",
              "subarea": "About the course",
              "stronglyAgree": "1-50%",
              "agree": "0-0%",
              "disagree": "0-0%",
              "stronglyDisagree": "-",
              "notApplicable": "-",
              "answered": 2,
              "average": 2.5
          },
          {
              "ID": 37,
              "question": "The course resources I needed were available when I needed them",
              "subarea": "About the course",
              "stronglyAgree": "1-50%",
              "agree": "0-0%",
              "disagree": "0-0%",
              "stronglyDisagree": "0-0%",
              "notApplicable": "0-0%",
              "answered": 2,
              "average": 2.5
          },
          {
              "ID": 36,
              "question": "The course materials were up to date and useful",
              "subarea": "About the course",
              "stronglyAgree": "1-50%",
              "agree": "0-0%",
              "disagree": "0-0%",
              "stronglyDisagree": "0-0%",
              "notApplicable": "0-0%",
              "answered": 2,
              "average": 2.5
          },
          {
              "ID": 26,
              "question": "   The instructor was prepared",
              "subarea": "About the instructor",
              "stronglyAgree": "1-50%",
              "agree": "0-0%",
              "disagree": "0-0%",
              "stronglyDisagree": "0-0%",
              "notApplicable": "0-0%",
              "answered": 2,
              "average": 2.5
          },
          {
              "ID": 27,
              "question": "  The instructor was available for help outside of class",
              "subarea": "About the instructor",
              "stronglyAgree": "1-50%",
              "agree": "0-0%",
              "disagree": "0-0%",
              "stronglyDisagree": "0-0%",
              "notApplicable": "0-0%",
              "answered": 2,
              "average": 2.5
          }
      ]
  }
]
//[
//   {
//     area: "area1",
//     analysis: [
//       {
//         ID: 1,
//         question: "question 1 under lecture",
//         stronglyAgree: "7 - 20%",
//         agree: "2 - 20%",
//         subarea: "lecture",
//         disagree: "3 - 20%",
//         stronglyDisagree: "0 - 0%",
//         notApplicable: "2 - 20%",
//         answered: 12,
//         average: 4,
//       },
//       {
//         ID: 2,
//         question: "question 1 under classroom",
//         stronglyAgree: "2 - 20%",
//         agree: "2 - 20%",
//         subarea: "classroom",
//         disagree: "3 - 20%",
//         stronglyDisagree: "0 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 15,
//         average: 3.5,
//       },
//       {
//         ID: 3,
//         question: "question 1 under professor",
//         stronglyAgree: "4 - 20%",
//         agree: "1 - 20%",
//         subarea: "professor",
//         disagree: "5 - 20%",
//         stronglyDisagree: "5 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 20,
//         average: 3.5,
//       },
//       {
//         ID: 4,
//         question: "question 2 under professor",
//         stronglyAgree: "4 - 20%",
//         agree: "4 - 20%",
//         subarea: "professor",
//         disagree: "5 - 20%",
//         stronglyDisagree: "1 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 12,
//         average: 3.5,
//       },
//       {
//         ID: 5,
//         question: "question 2 under lecture",
//         stronglyAgree: "10 - 20%",
//         agree: "2 - 20%",
//         subarea: "lecture",
//         disagree: "2 - 20%",
//         stronglyDisagree: "9 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 20,
//         average: 3.5,
//       },
//       {
//         ID: 6,
//         question: "question 2 under classroom",
//         stronglyAgree: "3 - 20%",
//         agree: "5 - 20%",
//         subarea: "classroom",
//         disagree: "9 - 20%",
//         stronglyDisagree: "0 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 10,
//         average: 3.5,
//       },
//       {
//         ID: 7,
//         question: "question 3 under classroom",
//         stronglyAgree: "2 - 20%",
//         agree: "3 - 20%",
//         subarea: "classroom",
//         disagree: "5 - 20%",
//         stronglyDisagree: "0 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 15,
//         average: 3.5,
//       },
//     ],
//   },

//   {
//     area: "area2",
//     analysis: [
//       {
//         ID: 1,
//         question: "question 1 under subarea1",
//         stronglyAgree: "7 - 20%",
//         agree: "2 - 20%",
//         subarea: "subarea1",
//         disagree: "3 - 20%",
//         stronglyDisagree: "0 - 0%",
//         notApplicable: "2 - 20%",
//         answered: 12,
//         average: 4,
//       },
//       {
//         ID: 2,
//         question: "question 1 under subarea2",
//         stronglyAgree: "2 - 20%",
//         agree: "2 - 20%",
//         subarea: "subarea2",
//         disagree: "3 - 20%",
//         stronglyDisagree: "0 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 15,
//         average: 3.5,
//       },
//       {
//         ID: 3,
//         question: "question 1 under subarea3",
//         stronglyAgree: "4 - 20%",
//         agree: "1 - 20%",
//         subarea: "subarea3",
//         disagree: "5 - 20%",
//         stronglyDisagree: "5 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 20,
//         average: 3.5,
//       },
//       {
//         ID: 4,
//         question: "question 2 under subarea1",
//         stronglyAgree: "4 - 20%",
//         agree: "4 - 20%",
//         subarea: "subarea1",
//         disagree: "5 - 20%",
//         stronglyDisagree: "1 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 12,
//         average: 3.5,
//       },
//       {
//         ID: 5,
//         question: "question 2 under subarea2",
//         stronglyAgree: "10 - 20%",
//         agree: "2 - 20%",
//         subarea: "subarea2",
//         disagree: "2 - 20%",
//         stronglyDisagree: "9 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 20,
//         average: 3.5,
//       },
//       {
//         ID: 6,
//         question: "question 2 under subarea3",
//         stronglyAgree: "3 - 20%",
//         agree: "5 - 20%",
//         subarea: "subarea3",
//         disagree: "9 - 20%",
//         stronglyDisagree: "0 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 10,
//         average: 3.5,
//       },
//       {
//         ID: 7,
//         question: "question 3 under subarea3",
//         stronglyAgree: "2 - 20%",
//         agree: "3 - 20%",
//         subarea: "subarea3",
//         disagree: "5 - 20%",
//         stronglyDisagree: "0 - 20%",
//         notApplicable: "2 - 20%",
//         answered: 15,
//         average: 3.5,
//       },
//     ],
//   },
// ];


export const chart =
[
  {
      "ID": 88,
      "questionCode": "Q88",
      "question": "Did you use the textbook/ articles/ Internet reading to clarify your understanding of the course?",
      "stronglyAgree": 0,
      "agree": 0,
      "disagree": 0,
      "stronglyDisagree": 2,
      "notApplicable": 0
  },
  {
      "ID": 89,
      "questionCode": "Q89",
      "question": "Overall, I was satisfied with the quality of this course",
      "stronglyAgree": 0,
      "agree": 0,
      "disagree": 2,
      "stronglyDisagree": 0,
      "notApplicable": 0
  },
  {
      "ID": 90,
      "questionCode": "Q90",
      "question": "The lab sessions were organized and clear instructions were given to me",
      "stronglyAgree": 0,
      "agree": 2,
      "disagree": 0,
      "stronglyDisagree": 0,
      "notApplicable": 0
  }
]
// [
//   {
//     questionCode: 'Q1',
//     question: 'Question1',
//     stronglyAgree: 5,
//     agree: 2,
//     disagree: 3,
//     stronglyDisagree: 5,
//     notApplicable: 5,
//   },
//   {
//     questionCode: 'Q2',
//     question: 'Question2',
//     stronglyAgree: 0,
//     agree: 2,
//     disagree: 8,
//     stronglyDisagree: 5,
//     notApplicable: 5,
//   }, 
//   {
//     questionCode: 'Q3',
//     question: 'Question3',
//     stronglyAgree: 0,
//     agree: 2,
//     disagree: 8,
//     stronglyDisagree: 5,
//     notApplicable: 5,
//   }, 
//   {
//     questionCode: 'Q4',
//     question: 'Question4',
//     stronglyAgree: 10,
//     agree: 2,
//     disagree: 2,
//     stronglyDisagree: 3,
//     notApplicable: 3,
//   }, 
//   {
//     questionCode: 'Q5',
//     question: 'Question5',
//     stronglyAgree: 2,
//     agree: 2,
//     disagree: 3,
//     stronglyDisagree: 3,
//     notApplicable: 10,
//   }, 
//   {
//     questionCode: 'Q6',
//     question: 'Question6',
//     stronglyAgree: 8,
//     agree: 6,
//     disagree: 2,
//     stronglyDisagree: 3,
//     notApplicable: 1,
//   }, 
//   {
//     questionCode: 'Q7',
//     question: 'Question7',
//     stronglyAgree: 8,
//     agree: 9,
//     disagree: 1,
//     stronglyDisagree: 1,
//     notApplicable: 1,
//   }, 
//   {
//     questionCode: 'Q8',
//     question: 'Question8',
//     stronglyAgree: 2,
//     agree: 10,
//     disagree: 2,
//     stronglyDisagree: 3,
//     notApplicable: 3,
//   }, 
//   {
//     questionCode: 'Q9',
//     question: 'Question9',
//     stronglyAgree: 5,
//     agree: 5,
//     disagree: 5,
//     stronglyDisagree: 5,
//     notApplicable: 0,
//   }, 
//   {
//     questionCode: 'Q10',
//     question: 'Question10',
//     stronglyAgree: 5,
//     agree: 5,
//     disagree: 0,
//     stronglyDisagree: 5,
//     notApplicable: 5,
//   }, 
//   {
//     questionCode: 'Q11',
//     question: 'Question11',
//     stronglyAgree: 5,
//     agree: 5,
//     disagree: 5,
//     stronglyDisagre: 5,
//   }, 
//   {
//     questionCode: 'Q12',
//     question: 'Question12',
//     stronglyAgree: 9,
//     agree: 9,
//     disagree: 1,
//     stronglyDisagree: 1,
//     notApplicable: 0,
//   }, 
//   {
//     questionCode: 'Q13',
//     question: 'Question13',
//     stronglyAgree: 4,
//     agree: 4,
//     disagree: 4,
//     stronglyDisagree: 4,
//     notApplicable: 4,
//   }, 
//   {
//     questionCode: 'Q14',
//     question: 'Question14',
//     stronglyAgree: 4,
//     agree: 4,
//     disagree: 4,
//     stronglyDisagree: 5,
//     notApplicable: 3,
//   }, 
//   {
//     questionCode: 'Q15',
//     question: 'Question15',
//     stronglyAgree: 3,
//     agree: 5,
//     disagree: 4,
//     stronglyDisagree: 4,
//     notApplicable: 4,
//   }, 
//   {
//     questionCode: 'Q16',
//     question: 'Question16',
//     stronglyAgree: 3,
//     agree: 5,
//     disagree: 2,
//     stronglyDisagree: 5,
//     notApplicable: 5,
//   }, 
//   {
//     questionCode: 'Q17',
//     question: 'Question17',
//     stronglyAgree: 4,
//     agree: 4,
//     disagree: 4,
//     stronglyDisagree: 4,
//     notApplicable: 4,
//   }, 
//   {
//     questionCode: 'Q18',
//     question: 'Question18',
//     stronglyAgree: 5,
//     agree: 5,
//     disagree: 5,
//     stronglyDisagree: 5,
//     notApplicable: 0,
//   }, 
//   {
//     questionCode: 'Q19',
//     question: 'Question19',
//     stronglyAgree: 2,
//     agree: 5,
//     disagree: 0,
//     stronglyDisagree: 3,
//     notApplicable: 10,
//   }, 
//   {
//     questionCode: 'Q20',
//     question: 'Question20',
//     stronglyAgree: 10,
//     agree: 10,
//     disagree: 0,
//     stronglyDisagree: 0,
//     notApplicable: 0,
//   },
// ];