import React, { useEffect, useState } from "react";
// @material-ui/core components


import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons
import { Link } from "react-router-dom";
import {
  ActualLearningHourUpdateSpec, addNewActualLearningHour, fetchActualLearningHourById, fetchActualLearningHoursSpec
} from "../../../../../reduxToolkit/features/ActualLearningHours/ActualLearningHoursSpecSlice";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";


import { useHistory } from "react-router-dom";

import { Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";

import {
  fetchActivities_actualLearningHourSpec_ByCourse,
  fetchTypes_actualLearningHourSpec_ByCourse
} from "../../../../../reduxToolkit/features/ActualLearningHours/ActualLearningHoursSpecSlice";

import { Breadcrumb } from "antd";
import {

  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";

import { Input } from "antd";

import { useTranslation } from "react-i18next";

import { Form, InputNumber, notification } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

export default function CreateActualLearningHour({ match }) {
  const { t, i18n } = useTranslation();


  const { id } = match.params;
  const { idHour } = match.params;
  console.log("idHour", idHour);
  const isAddMode = isNaN(idHour);
  console.log("id from match is add mode", isAddMode);
  const [type_id, setType] = useState("");
  const [learning_hours, setLearning_hours] = useState("");
  const [activity_id, setActivity] = useState("");
  const [courseSpec_id, setCourseSpec_id] = useState(id);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    dispatch(fetchCourseById(id));
  }, [id]);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);



  useEffect(() => {
    dispatch(fetchActivities_actualLearningHourSpec_ByCourse(id)).then(unwrapResult);
    dispatch(fetchTypes_actualLearningHourSpec_ByCourse(id)).then(unwrapResult);
  }, []);



  const Activities = useSelector(
    (state) => state.ActualLearningHourSpec.ActivitiesActualLearningHour
  );
  const types = useSelector(
    (state) => state.ActualLearningHourSpec.TypesActualLearningHour
  );
  console.log("Activities ", Activities);
  console.log("types ", types);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewActualLearningHour({ ...values, courseSpec_id })
    );
    if (addNewActualLearningHour.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchActualLearningHoursSpec(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/ActualLearningHoursViews`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      ActualLearningHourUpdateSpec({
        id: idHour,
        modified: {
          ...values,
        },
      })
    );
    if (ActualLearningHourUpdateSpec.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchActualLearningHoursSpec(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/ActualLearningHoursViews`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchActualLearningHourById(idHour))
        .then(unwrapResult)
        .then((Hour) => {
          console.log(Hour, "Hour");
          form.setFieldsValue({
            type_id: Hour.type_id,
            learning_hours: Hour.learning_hours,
            activity_id: Hour.activity_id,
            courseSpec_id: Hour.courseSpec_id,
          });
        });
    }
  }, [idHour]);

  const res = useSelector(
    (state) => state.ActualLearningHour.ActualLearningHour
  );
  console.log("res ", res);

  function onChangeType(value) {
    //  console.log(`selected ${value}`);
    setType(value);
  }
  function onChangeActivity(value) {
    //console.log(`selected ${value}`);
    setActivity(value);
  }


  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">


                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        style={{ color: "orange" }}
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/ActualLearningHoursViews`}
                      >
                        {t("Actual Learning Hours List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="type_id"
                        label="Type"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select type" allowClear>
                          {types?.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="activity_id"
                        label="Activity"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select Activity" allowClear>
                          {Activities?.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="learning_hours"
                        label="Learning hours"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} min="0" />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/ActualLearningHoursViews`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to={`/citrine/programs`}>{t("Programs List")}</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        style={{ color: "orange" }}
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/ActualLearningHoursViews`}
                      >
                        {t("Actual Learning Hours List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="type_id"
                        label="Type"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select type" allowClear>
                          {types?.map((r) => (
                            <Option key={r.id} value={r.id}>
                              {r.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="activity_id"
                        label="Activity"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select Activity" allowClear>
                          {Activities?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="learning_hours"
                        label="Learning hours"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} min="0" />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/ActualLearningHoursViews`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
