import { makeStyles } from '@material-ui/core/styles'
// core components
import { unwrapResult } from '@reduxjs/toolkit'
import { notification, Table } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { DeletePLOWeight } from '../../../../reduxToolkit/features/PLO/PLOWeightSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramSpecSlice'
import ErrorModal from '../../../Components/ErrorModal'
import PEODropDownButton from '../../../Components/PEODropDownButton'
import PEOModal from '../../../Components/PEOModal'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// import ProgramCardDropdownButton from "../Components/ProgramCardDropdownButton"
const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const PEOExcerpt = ({ peo, key, disabledStatus, isEditor }) => {
  let { programId } = useParams()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()
  const cardClasses = useCardStyles()
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    // {
    //   title: 'Operation',
    //   dataIndex: 'operation',
    //   render: (_, record) => <Button onClick={() => { handleClickOpen(record.id) }} style={{ pointerEvents: isEditor ? '' : 'none' }} color="primary" simple>Delete</Button>,
    // },
  ]
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys)
      setToDeletePLOId(selectedRowKeys[0])
    },
  }

  const data = peo.plosSpec

  const [toDeletePLOId, setToDeletePLOId] = React.useState(null)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (id) => {
    setToDeletePLOId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const ids = {
    ploSpec_id: toDeletePLOId,
    peoSpec_id: peo.id,
  }

  const handleDeletePLO = async (ids) => {
    dispatch(DeletePLOWeight(ids))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        console.log('originalPromiseResult', originalPromiseResult)
        dispatch(fetchProgramById(programId))
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          'rejectedValueOrSerializedError',
          rejectedValueOrSerializedError,
        )
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  return (
    <GridItem
      xs={12}
      sm={12}
      md={11}
      // key={program.id}
    >
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <PEODetailsModal code={peo.code} name={peo.name} />
              {/* <h4 className={cardClasses.cardProductTitle}>
                <div> {peo.name} </div>
              </h4>
              <br /> */}
              <div>
                <br />
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  rowKey={(record) => record.id}
                  rowSelection={rowSelection}
                />
              </div>
            </GridItem>
            {isEditor && (
              <GridItem xs={12} sm={12} md={1}>
                {!disabledStatus && (
                  <PEODropDownButton
                    peo={peo}
                    programId={programId}
                    disabledStatus={disabledStatus}
                    ids={ids}
                    handleDeletePLO={handleDeletePLO}
                  />
                )}
              </GridItem>
            )}
          </GridContainer>
        </CardBody>
      </Card>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />

      <SuperGeneralDeleteModal
        delete_Element={handleDeletePLO}
        Element_ID={ids}
        open={open}
        handleClose={handleClose}
      />
    </GridItem>
  )
}

export default function PEOsPage() {
  const { t } = useTranslation()
  let { programId } = useParams()
  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  const [isEditor, setIsEditor] = useState(true)
  const dispatch = useDispatch()
  const programStatus = useSelector((state) => state.programSpec.programStatus)
  const error = useSelector((state) => state.programSpec.error)

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const [disabledStatus, setDisabledStatus] = useState(false)

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchPEOsByProgram = async () => {
    try {
      const resultAction = await dispatch(fetchProgramById(programId)).unwrap()

      console.log('success')
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  useEffect(() => {
    fetchPEOsByProgram()
    if (localStorage.getItem('state') != 'Draft') {
      setDisabledStatus(true)
    }
  }, [dispatch])

  const program = useSelector((state) => state.programSpec.program)

  console.log('program', program)

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  let content

  if (programStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (programStatus === 'succeeded') {
    if (program && program.peosSpec && program.peosSpec.length) {
      content = program.peosSpec.map((peo) => (
        //program.active &&
        <PEOExcerpt
          key={peo.id}
          peo={peo}
          disabledStatus={disabledStatus}
          isEditor={isEditor}
        />
      ))
    } else {
      content = <h5>{t('This Program has no PEOs to display!')}</h5>
    }
  } else if (programStatus === 'failed') {
    content = <div>An error has accured</div>
  }
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t('Programs')} </span>
          </Link>
          <Link
            to={`/citrine/programSpec/${program && program.id && program.id}`}
          >
            <span className={titleClasses.title}>
              {`> ${
                program &&
                program.program_degree_name &&
                program.program_degree_name
              }`}
            </span>
          </Link>
          <span className={titleClasses.title}>{t(`> PEOs`)} </span>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {isEditor && (
          <GridItem>
            <PEOModal
              programSpec_id={programId}
              disabledStatus={disabledStatus}
            />
          </GridItem>
        )}
      </GridContainer>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

const PEODetailsModal = ({ code, name }) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <React.Fragment>
      <Typography
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleClickOpen()
        }}
      >
        {name && ` ${name}`}
      </Typography>

      <Dialog
        
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              name:
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <Typography>{name}</Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              code:
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <Typography>{code}</Typography>
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-end">
            <GridItem>
              <Button
                color="primary"
                onClick={() => {
                  handleClose()
                }}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
