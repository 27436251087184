// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import { unwrapResult } from "@reduxjs/toolkit";
//import Select from "@material-ui/core/Select";
import { Breadcrumb, Select } from "antd";
// import { fetchAreas } from '../../../../../reduxToolkit/features/area/AreaSpecSlice'
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  addNewAssessmentMode, fetchAssessmentModeById, updateAssessmentMode
} from "../../../../../reduxToolkit/features/assessmentMode/AssessmentModeSpecSlice";





const useTitleStyles = makeStyles(titleStyles);

export default function CreateAssessmentMode({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;

  console.log("id", id);

  const isAddMode = isNaN(id);
  console.log("id from match is add mode", isAddMode);
  const [name, setName] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const dispatch = useDispatch();

  const history = useHistory();

  const { Option } = Select;

  const titleClasses = useTitleStyles();

  // function handleArea_ids(value) {
  //     //  console.log(`selected ${value}`);
  //     setAreaSpec_ids(value);
  // }

  const onSaveAssessmentModeClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(addNewAssessmentMode({ name }));
      unwrapResult(resultAction);
    } catch (err) {
      console.error("Failed to save the assessmentMode: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };
  const onUpdateClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        updateAssessmentMode({ id: id, modified: { name } })
      );
      unwrapResult(resultAction);
    } catch (err) {
      console.error("Failed to save the assessmentMode: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };
  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchAssessmentModeById(id));
    }
  }, [id]);

  const assessmentMode = useSelector(
    (state) => state.assessmentModeSpec.assessmentMode
  );
  console.log("assessmentMode ", assessmentMode);

  useEffect(() => {
    if (!isAddMode) {
      setName(assessmentMode && assessmentMode.name);
    }
  }, [assessmentMode, dispatch]);

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer
              md={12}
              xs={12}
              sm={12}
              direction="row"
              // style={{ width:300 ,height:150}}
              justify="space-between"
              alignItems="center"
            >
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course`}>
                      <span> {t("Course Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course/assessmentMode`}>
                      <span> {t("Assessment Modes")} </span>
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Create")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="name"
                            labelText={t("Name ")}
                            inputProps={{
                              value: name,
                              onChange: (event) => {
                                setName(event.target.value);
                              },
                              assessmentMode: "text",
                            }}
                          />

                          <br />
                          <br />
                          <Link
                            to={`/citrine/configuration/course/assessmentMode`}
                          >
                            <Button
                              color="primary"
                              onClick={onSaveAssessmentModeClicked}
                            >
                              {t("Save")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      {!isAddMode && assessmentMode && (
        <div>
          <div>
            <GridContainer
              md={12}
              xs={12}
              sm={12}
              direction="row"
              // style={{ width:300 ,height:150}}
              justify="space-between"
              alignItems="center"
            >
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course`}>
                      <span> {t("Course Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/course/assessmentMode`}>
                      <span> {t("Assessment Modes")} </span>
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Edit")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="name"
                            labelText={t("Name ")}
                            assessmentMode="text"
                            value={name}
                            inputProps={{
                              value: name,
                              onChange: (event) => {
                                setName(event.target.value);
                              },
                              value: name,
                            }}
                          />

                          <br />
                          <br />
                          <Link
                            to={`/citrine/configuration/course/assessmentMode`}
                          >
                            <Button color="primary" onClick={onUpdateClicked}>
                              {t("Update")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
    </div>
  );
}
