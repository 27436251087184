import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from "@material-ui/core/styles";

// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { useHistory, useParams } from "react-router-dom";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";

import { useTranslation } from "react-i18next";
import { Form, Input, Select, notification, DatePicker, Checkbox } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import {
  addNewEducation,
  fetchEducationsByPersonId,
} from "../../../../../reduxToolkit/features/Education/EducationSlice";

import { fetchStaffById } from "../../../../../reduxToolkit/features/user/UserSlice";

import {
  fetchResearchAndInnovationActivities,
  addResearchAndInnovationActivityFromScopus,
  fetchAllResearchAndInnovationActivities,
} from "../../../../../reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice";
import { fetchResearchAndDevelopmentOutputs } from "../../../../../reduxToolkit/features/ResearchAndDevelopmentActivity/ResearchAndInnovationOutputsSlice"
import { fetchCurrentSemester } from "reduxToolkit/features/semester/SemestersSlice";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

const theme = createTheme({
  overrides: {
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
        padding: 0,
      },
    },
  },
});

export default function ImportScopusModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams()

  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);

  const { Option } = Select;
  const { TextArea } = Input;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [staff_id, setPerson_id] = React.useState(props.person_id);

  const [until_now, setUntil_now] = React.useState(false);

  const [scopuschoice, setScopusChoice] = React.useState("");
  const [webSciencechoice, setWebSciencechoice] = React.useState("");
  const [googleScolar, setgoogleScolar] = React.useState("");

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [openSpinner, setOpenSpinner] = React.useState(false);


  const [semester_id, setSemester_id] = React.useState("");
  const [academicYear_id, setAcademicYear_id] = React.useState("");


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  console.log(props.program_id, "program_id");

  useEffect(() => {
    dispatch(fetchStaffById(id));

    dispatch(fetchCurrentSemester(localStorage.getItem("userId")))
      .unwrap()
      .then((current_semester) => {
        setSemester_id(current_semester.semester_id);
        setAcademicYear_id(current_semester.academicYear_id);
      });


  }, [dispatch]);

  const person = useSelector((state) => state.user.staff);
  //console.log('person', person)

  const onFinish = async (values) => {
    handleClose();
    handleToggleSpinner();


    const toAddActivity = {
      academicYear_id: academicYear_id,
      semester_id: semester_id,
      faculty_id: person.id,
    };
    const resultAction = await dispatch(
      addResearchAndInnovationActivityFromScopus(toAddActivity)
    );

    if (
      addResearchAndInnovationActivityFromScopus.fulfilled.match(resultAction)
    ) {
      openNotificationWithIcon("success", `Add successfully`);

      // handleClose();

      dispatch(fetchResearchAndDevelopmentOutputs(props.person_id));
      handleCloseSpinner();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        // handleClose();
        handleCloseSpinner();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        // handleClose();
        handleCloseSpinner();
      }
    }
  };

  const handleToggleSpinner = () => {
    setOpenSpinner(!openSpinner);
  };
  const handleCloseSpinner = () => {
    setOpenSpinner(false);
  };

  function onChangeCheckboxScopus(e) {
    console.log(`checked = ${e.target.checked}`);
    setScopusChoice(e.target.checked);
  }

  function onChangeCheckboxGoogleScolar(e) {
    // console.log(`checked = ${e.target.checked}`);
    setgoogleScolar(e.target.checked);
  }

  function onChangeCheckboxWebScience(e) {
    // console.log(`checked = ${e.target.checked}`);
    setWebSciencechoice(e.target.checked);
  }
  return (
    <div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openSpinner}
          onClick={handleCloseSpinner}
          style={{ zIndex: 1000 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div>
        <Button
          color="primary"
          // round
          ref={anchorRef}
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={(event) => {
            handleClickOpen(event);
          }}
        // className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
        >
          import
          {/* <SearchIcon /> */}
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth="true"
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            <center>{t("Choose Import Option")}</center>
          </DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Form
                  {...layout}
                  form={form}
                  name="control-hooks"
                  onFinish={onFinish}
                >
                  <center>
                    <Form.Item name="scopus" label="Scopus">
                      <Checkbox
                        onChange={onChangeCheckboxScopus}
                        checked={scopuschoice}
                      ></Checkbox>
                    </Form.Item>
                    <Form.Item name="webScience" label="Web Of Science">
                      <Checkbox
                        onChange={onChangeCheckboxWebScience}
                        checked={webSciencechoice}
                      ></Checkbox>
                    </Form.Item>
                    <Form.Item name="googleScolar" label="Google Scolar">
                      <Checkbox
                        onChange={onChangeCheckboxGoogleScolar}
                        checked={googleScolar}
                      ></Checkbox>
                    </Form.Item>
                  </center>

                  <Form.Item {...tailLayout}>
                    <Button
                      style={{ marginRight: "20px" }}
                      color="danger"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" color="info" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </div>
    </div>
  );
}
