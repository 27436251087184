// import React from "react";
// // react plugin for creating charts
// // react plugin for creating vector maps

// // @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import Tooltip from "@material-ui/core/Tooltip";
// import Icon from "@material-ui/core/Icon";

// // @material-ui/icons
// // import ContentCopy from "@material-ui/icons/ContentCopy";
// import Store from "@material-ui/icons/Store";
// // import InfoOutline from "@material-ui/icons/InfoOutline";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Refresh from "@material-ui/icons/Refresh";
// import Edit from "@material-ui/icons/Edit";
// import Place from "@material-ui/icons/Place";
// import ArtTrack from "@material-ui/icons/ArtTrack";
// import Language from "@material-ui/icons/Language";

// // core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import Danger from "components/Typography/Danger.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts";

// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

// import Barchart from './Barchart/Barchart'
// import Doughnutchart from './Doughnutchart/Doughnutchart'
// import Linechart from './Linechart/Linechart'

// const useStyles = makeStyles(styles);

// export default function Dashboard() {
//   const classes = useStyles();
//   return (
//     <div>
//       <GridContainer>
//         <GridItem xs={12} sm={6} md={6} lg={4}>
//           <Card>
//             <CardHeader color="success" stats icon>
//               <CardIcon color="success">
//                 <Store />
//               </CardIcon>
//               <p className={classes.cardCategory}>Revenue</p>
//               <h3 className={classes.cardTitle}>$34,245</h3>
//             </CardHeader>
//             <CardFooter stats>
//               <div className={classes.stats}>
//                 <DateRange />
//                 Last 24 Hours
//               </div>
//             </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={6} md={6} lg={4}>
//           <Card>
//             <CardHeader color="danger" stats icon>
//               <CardIcon color="danger">
//                 <Icon>info_outline</Icon>
//               </CardIcon>
//               <p className={classes.cardCategory}>Fixed Issues</p>
//               <h3 className={classes.cardTitle}>75</h3>
//             </CardHeader>
//             <CardFooter stats>
//               <div className={classes.stats}>
//                 <LocalOffer />
//                 Tracked from Github
//               </div>
//             </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={6} md={6} lg={4}>
//           <Card>
//             <CardHeader color="info" stats icon>
//               <CardIcon color="info">
//                 <i className="fab fa-twitter" />
//               </CardIcon>
//               <p className={classes.cardCategory}>Followers</p>
//               <h3 className={classes.cardTitle}>+245</h3>
//             </CardHeader>
//             <CardFooter stats>
//               <div className={classes.stats}>
//                 <Update />
//                 Just Updated
//               </div>
//             </CardFooter>
//           </Card>
//         </GridItem>
//       </GridContainer>

//       <GridContainer>
//         <GridItem xs={12} sm={12} md={4}>
//           <Card chart className={classes.cardHover}>
//             <CardHeader color="warning" >
//               {/* <ChartistGraph
//                 className="ct-chart-white-colors"
//                 data={dailySalesChart.data}
//                 type="Line"
//                 options={dailySalesChart.options}
//                 listener={dailySalesChart.animation}
//               /> */}
//               <Barchart/>
//             </CardHeader>
//             <CardBody>
//               <h4 className={classes.cardTitle}>Daily Sales</h4>
//               <p className={classes.cardCategory}>
//                 <span className={classes.successText}>
//                   <ArrowUpward className={classes.upArrowCardCategory} /> 55%
//                 </span>{" "}
//                 increase in today sales.
//               </p>
//             </CardBody>
//             <CardFooter chart>
//               <div className={classes.stats}>
//                 <AccessTime /> updated 4 minutes ago
//               </div>
//             </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={12} md={4}>
//           <Card chart className={classes.cardHover}>
//             <CardHeader color="warning" >
//               {/* <ChartistGraph
//                 className="ct-chart-white-colors"
//                 data={emailsSubscriptionChart.data}
//                 type="Bar"
//                 options={emailsSubscriptionChart.options}
//                 responsiveOptions={emailsSubscriptionChart.responsiveOptions}
//                 listener={emailsSubscriptionChart.animation}
//               /> */}
//               <Doughnutchart/>
//             </CardHeader>
//             <CardBody>
//               <h4 className={classes.cardTitle}>Email Subscriptions</h4>
//               <p className={classes.cardCategory}>Last Campaign Performance</p>
//             </CardBody>
//             <CardFooter chart>
//               <div className={classes.stats}>
//                 <AccessTime /> campaign sent 2 days ago
//               </div>
//             </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={12} md={4}>
//           <Card chart className={classes.cardHover}>
//             <CardHeader color="warning" >
//               {/* <ChartistGraph
//                 className="ct-chart-white-colors"
//                 data={completedTasksChart.data}
//                 type="Line"
//                 options={completedTasksChart.options}
//                 listener={completedTasksChart.animation}
//               /> */}
//               <Linechart/>
//             </CardHeader>
//             <CardBody>
//               <h4 className={classes.cardTitle}>Completed Tasks</h4>
//               <p className={classes.cardCategory}>Last Campaign Performance</p>
//             </CardBody>
//             <CardFooter chart>
//               <div className={classes.stats}>
//                 <AccessTime /> campaign sent 2 days ago
//               </div>
//             </CardFooter>
//           </Card>
//         </GridItem>
//       </GridContainer>

//     </div>
//   );
// }



import React from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import Barchart from './Barchart/Barchart'
import CourseAssessmentsDoughnutchart from './Doughnutchart/CourseAssessmentsDoughnutchart'
import CourseReportsDoughnutchart from './Doughnutchart/CourseReportsDoughnutchart'
import CourseActionsDoughnutchart from './Doughnutchart/CourseActionsDoughnutchart'
import ProgramAssessmentsDoughnutchart from './Doughnutchart/ProgramAssessmentsDoughnutchart'
import ProgramReportsDoughnutchart from './Doughnutchart/ProgramReportsDoughnutchart'
import ProgramActionsDoughnutchart from './Doughnutchart/ProgramActionsDoughnutchart'


import Linechart from './Linechart/Linechart'
import { Margin } from "devextreme-react/chart";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <GridContainer style={{ marginTop: '20px', width: '100%', height: '100%' }} justify="space-between">

      <GridItem xs={12} sm={12} md={6}>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} style={{ padding: "2em 0em 2em", backgroundImage: 'linear-gradient(rgb(254, 254, 254), rgb(239, 239, 239))' }}>
            {/* <GridContainer alignItems="flex-start">
              <GridItem> */}
                <CourseAssessmentsDoughnutchart />
              {/* </GridItem>
            </GridContainer> */}
          </GridItem>

          <GridItem xs={12} sm={12} md={12} style={{ padding: "2em 0em 2em", backgroundImage: 'linear-gradient(rgb(254, 254, 254), rgb(239, 239, 239))' }}>
            {/* <GridContainer alignItems="flex-start">
              <GridItem> */}
                <CourseReportsDoughnutchart />
              {/* </GridItem>
            </GridContainer> */}
          </GridItem>

          <GridItem xs={12} sm={12} md={12} style={{ padding: "2em 0em 2em", backgroundImage: 'linear-gradient(rgb(254, 254, 254), rgb(239, 239, 239))' }}>
            {/* <GridContainer alignItems="flex-start">
              <GridItem> */}
                <CourseActionsDoughnutchart />
              {/* </GridItem>
            </GridContainer> */}
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={12} md={6}>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} style={{ padding: "2em 0em 2em", backgroundImage: 'linear-gradient(rgb(254, 254, 254), rgb(239, 239, 239))' }}>
            {/* <GridContainer alignItems="flex-start">
              <GridItem> */}
                <ProgramAssessmentsDoughnutchart />
              {/* </GridItem>
            </GridContainer> */}
          </GridItem>

          <GridItem xs={12} sm={12} md={12} style={{ padding: "2em 0em 2em", backgroundImage: 'linear-gradient(rgb(254, 254, 254), rgb(239, 239, 239))' }}>
            {/* <GridContainer alignItems="flex-start">
              <GridItem> */}
                <ProgramReportsDoughnutchart />
              {/* </GridItem>
            </GridContainer> */}
          </GridItem>

          <GridItem xs={12} sm={12} md={12} style={{ padding: "2em 0em 2em", backgroundImage: 'linear-gradient(rgb(254, 254, 254), rgb(239, 239, 239))' }}>
            {/* <GridContainer alignItems="flex-start">
              <GridItem> */}
                <ProgramActionsDoughnutchart />
              {/* </GridItem>
            </GridContainer> */}
          </GridItem>
        </GridContainer>

      </GridItem>

    </GridContainer>
  );
}

