// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
// @material-ui/icons
import { Breadcrumb, Select } from "antd";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchFields } from "reduxToolkit/features/field/FieldSpecSlice";
import {
  addNewSpecialization,
  fetchSpecializationById,
  fetchSpecializations,
  updateSpecialization,
} from "reduxToolkit/features/specialization/SpecializationSpecSlice";

export default function CreateSpecializationPage({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;
  const isAddMode = isNaN(id);
  const dispatch = useDispatch();

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const titleClasses = useTitleStyles();
  const history = useHistory();

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [fieldSpec_id, setField_id] = useState("");

  useEffect(() => {
    dispatch(fetchFields());
    if (!isAddMode) {
      dispatch(fetchSpecializationById(id));
    }
  }, []);

  useEffect(() => {
    if (!isAddMode) {
      setName(specialization && specialization.name);
      setCode(specialization && specialization.code);
      setField_id(specialization && specialization.fieldSpec_id);
    }
  }, []);

  const fields = useSelector((state) => state.fieldSpec.fields);
  const specialization = useSelector(
    (state) => state.specializationSpec.specialization
  );

  const onSaveSpecializationClicked = async () => {
    if (name && code && fieldSpec_id) {
      try {
        const resultAction = await dispatch(
          addNewSpecialization({ name, code, fieldSpec_id })
        );
        unwrapResult(resultAction);
        setName("");
        setCode("");
        setField_id("");
        dispatch(fetchSpecializations());
        history.push("/citrine/specializations");
      } catch (err) {
        console.log(err);
      }
    }
  };
  const onUpdateSpecializationClicked = async () => {
    try {
      const resultAction = await dispatch(
        updateSpecialization({ id: id, modified: { name, code, fieldSpec_id } })
      );
      unwrapResult(resultAction);
      setName("");
      setCode("");
      setField_id("");
      dispatch(fetchSpecializations());
      history.push("/citrine/specializations");
    } catch (err) {}
  };

  const onChange = (value) => {
    setField_id(value);
  };

  return (
    <div>
      {isAddMode && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/configuration/program`}>
                  <span> {t("Program Configurations")} </span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/specializations`}
                  style={{ color: "orange" }}
                >
                  {t("Specializations")}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <SchoolRounded />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <span className={titleClasses.title}> {t("Create")} </span>
                </h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={t("Name ")}
                        id="name"
                        type="text"
                        name="name"
                        value={name}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a name!",
                          },
                        ]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setName(event.target.value);
                          },
                        }}
                      />
                      <br />
                      <br />

                      <label>{t("Field ")} </label>
                      <br />
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="field_id"
                        value={fieldSpec_id}
                        onChange={onChange}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {fields.map((field) => (
                          <option key={field.id} value={field.id}>
                            {field.name}
                          </option>
                        ))}
                      </Select>
                      <CustomInput
                        labelText={t("Code ")}
                        id="code"
                        value={code}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a code!",
                          },
                        ]}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setCode(event.target.value);
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="info"
                        className={classes.updateProfileButton}
                        onClick={onSaveSpecializationClicked}
                      >
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      {!isAddMode && specialization && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/configuration/program`}>
                  <span> {t("Program Configurations")} </span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/specializations`}
                  style={{ color: "orange" }}
                >
                  {t("Specializations")}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <SchoolRounded />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <span className={titleClasses.title}> {t("Edit")} </span>
                </h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={t("Name ")}
                        id="name"
                        type="text"
                        name="name"
                        value={name}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a name!",
                          },
                        ]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setName(event.target.value);
                          },
                          value: name,
                        }}
                      />
                      <br />
                      <br />

                      <label>{t("Field ")} </label>
                      <br />
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="field_id"
                        value={fieldSpec_id}
                        onChange={onChange}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {fields.map((field) => (
                          <option key={field.id} value={field.id}>
                            {field.name}
                          </option>
                        ))}
                      </Select>
                      <CustomInput
                        labelText={t("Code ")}
                        id="code"
                        value={code}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        type="text"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a code!",
                          },
                        ]}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            setCode(event.target.value);
                          },
                          value: code,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="info"
                        className={classes.updateProfileButton}
                        onClick={onUpdateSpecializationClicked}
                      >
                        {t("Update")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
