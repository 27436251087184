import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';
import jsonData from '../../../assets/utils/activities'
// console.log(jsonData,"jsonData")
const initialState = {
    ActivitiesProgramSpec:[],
    Activities: [],
    ActivitiesStatus: 'idle',
    ActivitiesStatus: 'idle',
    error: null,
    Activity: null,

    id: null
}


export const fetchActivities = createAsyncThunk('Activities/fetchActivities', async (course_id) => {
    const token = localStorage.getItem('token')
    const response = jsonData
    return response.data
})

export const fetchActivitiesProgramSpec = createAsyncThunk("Activities/fetchActivitiesProgramSpec",async (program_id) => {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/programSpec/activity_log/?programSpec_id=${program_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data,"activity redux")
      return response.data;
    }
);
  
export const fetchActivitiesCourseSpec = createAsyncThunk(
      "Activities/fetchActivitiesProgramSpec",
    
      async (program_id) => {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          BaseUrl + `/programSpec/activity_log/?programSpec_id=9${program_id}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        return response.data;
      }
);

export const addNewActivity = createAsyncThunk(
    'Activity/addNewActivity',
    async (initialActivity) => {
        console.log('initialActivity', initialActivity)
        const token = localStorage.getItem('token')
        const response = await axios.post(BaseUrl + `/activity/create/`, initialActivity, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data
    }
)

export const deleteActivity = createAsyncThunk('Activity/deleteActivity', async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/activity/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')

    return response.data
})
const ActivitySlice = createSlice({
    name: 'ModeOfInstruction',
    initialState,

    extraReducers: {
        [fetchActivities.pending]: (state, action) => {
            state.ActivitiesStatus = 'loading'
        },
        [fetchActivities.fulfilled]: (state, action) => {
            state.ActivitiesStatus = 'succeeded'

            //state.Activities = action.payload
            state.Activities = jsonData

        },
        [fetchActivities.rejected]: (state, action) => {
            state.ActivitiesStatus = 'failed'
            state.error = action.payload
        },



        [fetchActivitiesProgramSpec.pending]: (state, action) => {
            state.ActivitiesStatus = 'loading'
        },
        [fetchActivitiesProgramSpec.fulfilled]: (state, action) => {
            state.ActivitiesStatus = 'succeeded'
            state.ActivitiesProgramSpec = action.payload
        },
        [fetchActivitiesProgramSpec.rejected]: (state, action) => {
            state.ActivitiesStatus = 'failed'
            state.error = action.payload
        },


        

        [addNewActivity.fulfilled]: (state, action) => {
            state.Activities.push(action.payload)
        },
        [deleteActivity.fulfilled]: (state, action) => {
            // state.SpecificationAprovalDatas.push(action.payload)
        },

        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },
        }
    }
})

export const { getId } = ActivitySlice.actions

export default ActivitySlice.reducer



