// import {
//   DataGrid,
//   GridToolbarContainer,
//   GridToolbarExport,
// } from "@mui/x-data-grid";
// import { Breadcrumb, notification } from "antd";
// import { Link } from "react-router-dom";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import Button from "components/CustomButtons/Button.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Search from "@material-ui/icons/Search";

// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import {
//   fetchFacultyActivityImprovementByFacultyActivityId,
//   updateFacultyActivityImprovement,
// } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
// import ErrorModal from "../../../Components/ErrorModal";
// import { useParams } from "react-router-dom";
// import { fetchFacultyActivityById } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
// import { useDispatch, useSelector } from "react-redux";

// import PropTypes from "prop-types";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import clsx from "clsx";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
// import Check from "@material-ui/icons/Check";
// import SettingsIcon from "@material-ui/icons/Settings";
// import GroupAddIcon from "@material-ui/icons/GroupAdd";
// import VideoLabelIcon from "@material-ui/icons/VideoLabel";
// import StepConnector from "@material-ui/core/StepConnector";
// import Typography from "@material-ui/core/Typography";
// import {
//   primaryColor,
//   roseColor,
//   infoColor,
//   grayColor,
// } from "../../../../assets/jss/material-dashboard-pro-react";
// import EventIcon from "@material-ui/icons/Event";
// import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
// import EventAvailableIcon from "@material-ui/icons/EventAvailable";

// export default function WFA(props) {
//   const dispatch = useDispatch();
//   let { activityId } = useParams();
//   const { t } = useTranslation();

//   const [openErrorModal, setOpenErrorModal] = React.useState(false);
//   const [handledError, setHandledError] = React.useState("");

//   const handleOpenErrorModal = () => {
//     setOpenErrorModal(true);
//   };
//   const handleCloseErrorModal = () => {
//     setOpenErrorModal(false);
//   };

//   const fetchfacultyActivityImprovement = async () => {
//     try {
//       const resultAction = await dispatch(
//         fetchFacultyActivityImprovementByFacultyActivityId(activityId)
//       ).unwrap();
//       // handle result here
//     } catch (err) {
//       setHandledError(err);
//       handleOpenErrorModal();
//     }
//   };

//   useEffect(() => {
//     fetchfacultyActivityImprovement();
//   }, []);

//   const facultyActivityImprovement = useSelector(
//     (state) => state.facultyActivity.facultyActivityImprovement
//   );

//   console.log("facultyActivityImprovement", facultyActivityImprovement);

//   const columns = [
//     // {
//     //   field: "id",
//     //   headerName: "id",
//     //   width: 50,
//     //   sortable: false,
//     // },

//     {
//       field: "result",
//       headerName: "Result",
//       flex: 0.25,
//       editable: true,
//       sortable: false,
//     },
//     {
//       field: "weaknesses",
//       headerName: "Weaknesses",
//       flex: 0.25,
//       editable: true,
//       sortable: false,
//       renderCell: (params) => (
//         <div>
//           <Typography variant="body2">
//             {params.value?params.value:""}
//           </Typography>
//         </div>
//       ),
//     },
//     {
//       field: "strengths",
//       headerName: "Strengths",
//       flex: 0.25,
//       editable: true,
//       sortable: false,
//     },
//     {
//       field: "areas_for_improvement",
//       headerName: "Areas for improvement",
//       flex: 0.25,
//       editable: true,
//       sortable: false,
//     },
//     {
//       field: "priorities_for_improvement",
//       headerName: "Priorities for improvement",
//       flex: 0.25,
//       editable: true,
//       sortable: false,
//     },
//   ];

//   const [rows, setRows] = useState([]);

//   const openNotificationWithIcon = (type, msg) => {
//     notification[type]({
//       message: type,
//       description: msg,
//       top: 50,
//       duration: 2,
//     });
//   };

//   const onCellEditCommit = async (event) => {
//     console.log("onCellEditCommit", event);
//     const value = event.value;

//     const field = event.field;

//     const id = event.id;

//     if (value && value !== event.row[field]) {
//       const resultAction = await dispatch(
//         updateFacultyActivityImprovement({
//           id: id,
//           updatedFacultyActivityImprovement: {
//             facultyActivity: activityId,
//             [field]: value,
//           },
//         })
//       );
//       if (updateFacultyActivityImprovement.fulfilled.match(resultAction)) {
//         // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
//         fetchfacultyActivityImprovement();
//         openNotificationWithIcon("success", `Updated successfully`);
//       } else {
//         if (resultAction.payload) {
//           // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
//           setHandledError(resultAction.payload);
//           handleOpenErrorModal();
//         } else {
//           setHandledError(resultAction.error);
//           handleOpenErrorModal();
//         }
//       }
//     }
//   };

//   return (
//     <div>
//       <GridContainer>
//         <GridItem>
//           <Button color="primary">{t("Save")}</Button>
//         </GridItem>
//       </GridContainer>
//       <Card>
//         <CardBody>
//           <div style={{ height: 300, width: "100%", margin: 20 }}>
//             <DataGrid
//               rowHeight={242}
//               rows={facultyActivityImprovement || []}
//               columns={columns}
//               autoPageSize={true}
//               columnBuffer={0}
//               disableColumnMenu
//               disableSelectionOnClick
//               onCellEditCommit={onCellEditCommit}
//               showCellRightBorder
//               showColumnRightBorder
//               hideFooter={true}
//             />
//           </div>
//         </CardBody>
//       </Card>
//       <ErrorModal
//         open={openErrorModal}
//         handleClose={handleCloseErrorModal}
//         handledError={handledError}
//       />
//     </div>
//   );
// }



import { Breadcrumb, notification, Form, Input } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Search from "@material-ui/icons/Search";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchFacultyActivityImprovementByFacultyActivityId,
  updateFacultyActivityImprovement,
} from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import ErrorModal from "../../../Components/ErrorModal";
import { useParams } from "react-router-dom";
import { fetchFacultyActivityById } from "../../../../reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";


import {
  primaryColor,
  roseColor,
  infoColor,
  grayColor,
} from "../../../../assets/jss/material-dashboard-pro-react";


export default function WFA(props) {
  const dispatch = useDispatch();
  let { activityId } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchfacultyActivityImprovement = async () => {
    try {
      const resultAction = await dispatch(
        fetchFacultyActivityImprovementByFacultyActivityId(activityId)
      ).unwrap();
      // handle result here
      console.log("resultAction", resultAction)
      form.setFieldsValue({
        result: resultAction[0].result,
        strengths: resultAction[0].strengths,
        weaknesses: resultAction[0].weaknesses,
        areas_for_improvement: resultAction[0].areas_for_improvement,
        priorities_for_improvement: resultAction[0].priorities_for_improvement,

      });
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    fetchfacultyActivityImprovement();
  }, []);

  const facultyActivityImprovement = useSelector(
    (state) => state.facultyActivity.facultyActivityImprovement
  );

  console.log("facultyActivityImprovement", facultyActivityImprovement);

  const { TextArea } = Input;

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);

    const resultAction = await dispatch(
      updateFacultyActivityImprovement({
        id: facultyActivityImprovement && facultyActivityImprovement[0] && facultyActivityImprovement[0].id,
        updatedFacultyActivityImprovement: {
          ...values,
          facultyActivity: activityId,
        },
      })
    );

    if (updateFacultyActivityImprovement.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon(
        "success",
        `Updated successfully`
      );
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };


  return (
    <div>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Faculty activity improvement details
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={8} sm={8} md={8}>
                    <Form.Item
                      label={t("Result")}
                      name="result"
                      // hasFeedback
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={8} sm={8} md={8}>
                    <Form.Item
                      label={t("Strengths")}
                      name="strengths"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={8} sm={8} md={8}>
                    <Form.Item
                      label={t("Weaknesses")}
                      name="weaknesses"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={8} sm={8} md={8}>
                    <Form.Item
                      label={t("Areas for improvement")}
                      name="areas_for_improvement"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={8} sm={8} md={8}>
                    <Form.Item
                      label={t("Priorities for improvement")}
                      name="priorities_for_improvement"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* </FormControl> */}

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}