import { makeStyles } from "@material-ui/core/styles";
import { Badge, Breadcrumb } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DropDownVCSRCButton from "views/Components/DropDownVCSRCButton";
import {
  fetchVerificationCredibilityStudentsResultsConfigs
} from "../../../../reduxToolkit/features/VerificationCredibilityStudentsResultsConfig/VerificationCredibilityStudentsResultsConfigSlice";






const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);


export default function VerificationCredibilityStudentsResultsConfigsPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const { t } = useTranslation();
  const verificationCredibilityStudentsResultsConfigsStatus = useSelector(
    (state) =>
      state.verificationCredibilityStudentsResultsConfig
        .verificationCredibilityStudentsResultsConfigsStatus
  );
  console.log(
    "status subareas",
    verificationCredibilityStudentsResultsConfigsStatus
  );

  useEffect(() => {

    dispatch(fetchVerificationCredibilityStudentsResultsConfigs());

  }, [dispatch]);

  const verificationCredibilityStudentsResultsConfigs = useSelector(
    (state) =>
      state.verificationCredibilityStudentsResultsConfig
        .verificationCredibilityStudentsResultsConfigs
  );
  console.log("*******subareas", verificationCredibilityStudentsResultsConfigs);

  return (
    <div>
      <GridContainer
        md={12}
        xs={12}
        sm={12}
        direction="row"
        // style={{ width:300 ,height:150}}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course/`}>
                <span> {t("Course Configurations")} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/verificationCredibilityStudentsResultsConfigs`}>
                <span>
                  {" "}
                  {t("Verification Credibility Students Results Configs")}{" "}
                </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Link to="/citrine/createVerificationCredibilityStudentsResultsConfig/:id">
        <Button color="info">{t("Add")}</Button>
      </Link>

      <GridContainer>
        {verificationCredibilityStudentsResultsConfigs &&
          verificationCredibilityStudentsResultsConfigs.map((Element) => (
            <GridItem key={Element.id} xs={12} sm={12} md={4}>
              <Card style={{ height: 200, textAlign: "start" }}>
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={10}>
                      {/* <h4
                        className={classes.cardProductTitle}
                        style={{ textAlign: "start", color: "orange" }}
                      >
                        <a href="#pablo">{t("Verification Credibility Students Results")}</a>
                      </h4> */}
                      <h4
                        className={classes.cardProductTitle}
                        //style={{ textAlign: "start", color: "orange" }}
                      >
                        {/* <a href="#pablo" style={{ color: "orange" }}> */}
                         
                          {Element.name}
                        {/* </a> */}
                      </h4>
                      {Element &&
                        Element.areaSpec_details &&
                        Element.areaSpec_details.length &&
                        Element.areaSpec_details.length ? (
                        Element.areaSpec_details.map((el) => (
                          <Badge
                            className="site-badge-count-109"
                            count={el.name}
                            style={{ backgroundColor: "#52c41a" }}
                          />
                        ))
                      ) : (
                        <span></span>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }}>
                      <DropDownVCSRCButton id={Element.id} />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
