import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { fetchAdmissionRequirements } from "reduxToolkit/features/admissionRequirement/AdmissionRequirementSpecSlice";
import { fetchGraduationRequirements } from "reduxToolkit/features/graduationRequirement/GraduationRequirementSpecSlice";
import { infoColor } from "assets/jss/material-dashboard-pro-react";
import {
  fetchProgramById,
  fetchPrograms,
} from "reduxToolkit/features/program/ProgramsSlice.js";
import { fetchUsersByTypeAndCourseIds } from "reduxToolkit/features/user/UserSlice.js";
import { addNewFacultyActivity } from "reduxToolkit/features/FacultyActivity/FacultyActivitySlice.jsx";
import { fetchStaffById } from "../../../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../../../Components/ErrorModal";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";

import { Form, Input, Select, notification, DatePicker, Checkbox } from "antd";

export default function CreateTeachingLoadActivity(props) {
  return( 
  <div>
   <p></p>
 </div>
 );
}
