import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { GridColumnsContainer } from "@mui/x-data-grid";
import { Form, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addTemplate } from "reduxToolkit/features/course/CoursesSlice";
import { fetchSurveyTemplatesTypeConfigs } from "reduxToolkit/features/surveyTemplateTypeConfig/SurveyTemplateTypeConfigSlice.js";
import { getProgramSurveyTemplates } from "../../../../reduxToolkit/features/Survey/SurveySlice";
import ErrorModal from "../../../Components/ErrorModal.jsx";

export default function AddTemplate(props) {

  const [surveyTemplate_id, setTemplate_id] = useState([]);
  const [courses_ids, setcourses_ids] = useState([]);
  const [value, setValue] = useState(null);

  const templates = useSelector((state) => state.survey.programSurveyTemplates);

  const onChangeTemplate_id = (value) => {
    setTemplate_id(value);
  };

  const { t } = useTranslation();

  const { Option } = Select;

  const [open, setOpen] = useState(false);
  const [templatesTypes, setTemplatesTypes] = useState([])
  const [surveyTemplateTypeConfig_id, setTypeConfig] = useState([])
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const dispatch = useDispatch();

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  useEffect(() => {
    fetchTypes();
  }, []);

  const fetchTypes = async () => {
    dispatch(fetchSurveyTemplatesTypeConfigs()).unwrap().then((res) => {
      res.filter(type => {
        if (type.category === "course") {
          templatesTypes.push(type)
        }
      })
    });
  };

  const fetchCourseSurveyTemplates = async (value) => {
    try {
      const resultAction = await dispatch(getProgramSurveyTemplates(value)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  function onChangeTypeSurvey(value) {
    setTypeConfig(value);
    fetchCourseSurveyTemplates(value);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleKeyDown = (event, ref) => {
    if (event.key === 'Tab') {
      ref.current.focus();
    }
  };

  const onFinishFailed = (errorInfo) => { };

  const onFinish = async () => {
    let courses_ids = props.selectedRows.join();
    const resultAction = await dispatch(
      addTemplate({ courses_ids, surveyTemplate_id })
    );

    if (addTemplate.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      props.refreshCourses();
      openNotificationWithIcon("success", `added`);
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <MenuItem
        disabled={!props.selectedRows.length}
        onClick={() => {
          handleClickOpen();
          props.handleDropDownButtonClose();
        }}
      >
        {t("Update Survey Template")}
      </MenuItem>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title"><center>{t("Survey Template")}</center></DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Type")}
                      name="surveyTemplateTypeConfig_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the type's field",
                        },
                      ]}
                    >
                      <Select
                        value={value}
                        onChange={onChangeTypeSurvey}
                        showSearch
                        dropdownStyle={{ zIndex: 10000 }}
                        ref={input1Ref}
                        onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {templatesTypes && templatesTypes.map((type) => (
                          <Option key={type.id} value={type.id}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Template")}
                      name="surveyTemplate_id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Survey Template"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        ref={input2Ref}
                        onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                        value={surveyTemplate_id}
                        onChange={onChangeTemplate_id}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        dropdownStyle={{ zIndex: 10000 }}
                      >
                        {templates &&
                          templates.map((t) => (
                            <Option key={t.id} value={t.id}>
                              {t.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
                <GridContainer justifyContent="center">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
