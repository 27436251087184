import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Radio, Select, notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { uploadParticipantsProgram } from "reduxToolkit/features/Survey/SurveySlice";
import { fetchCampaignById } from "../../../../reduxToolkit/features/campaign/CampaignsSlice";
import { fetchGroupAssessmentAll } from "../../../../reduxToolkit/features/groupeAssessment/groupeAssessmentSlice.js";
import ErrorModal from "../../../Components/ErrorModal.jsx";
import UploadParticipantsExistingGroup from "./UploadParticipantsExistingGroup";


export default function UploadParticipants(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupAssessment } = useSelector((state) => state.groupAssessment)

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [namee, setNamee] = useState("")
  const [value, setValue] = React.useState(1);
  const [selectedValue, setSelectedValue] = useState(0);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  console.log('Selected value:', selectedValue);

  const { Option } = Select;
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    console.log(selectedFile, "selectedFile");
  };

  useEffect(() => {
    dispatch(fetchGroupAssessmentAll())
  }, [])
  //let semester_id = localStorage.getItem('semester_id');

  const onFinish = async () => {

    let id = props.campaign.id;
    let document = selectedFile;

    const uploadData = new FormData();
    uploadData.append("id", props.campaign.id);
    uploadData.append("document", document);
    const resultAction = await dispatch(uploadParticipantsProgram(uploadData));
    if (uploadParticipantsProgram.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", ` success Added `);
      dispatch(fetchCampaignById(props.id));
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }

    //   else{
    //     const uploadData = new FormData();
    //   uploadData.append("resource_id", resource_id);
    //   uploadData.append("resource", resource);
    //   uploadData.append("model", model);
    //   uploadData.append("document", document);
    //  // uploadData.append("group_id", group_id);

    //   //uploadData.append("semester_id", semester_id);

    //   const resultAction = await dispatch(uploadParticipants(uploadData));
    //   if (uploadParticipants.fulfilled.match(resultAction)) {
    //     openNotificationWithIcon("success", ` success Added `);
    //     dispatch(fetchCampaignById(props.id));
    //     handleClose();
    //   } else {
    //     if (resultAction.payload) {
    //       setHandledError(resultAction.payload);
    //       handleOpenErrorModal();
    //       handleClose();
    //     } else {
    //       setHandledError(resultAction.error);
    //       handleOpenErrorModal();
    //       handleClose();
    //     }
    //   }
    //   }

  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        {t("Upload Participants")}
      </Button>

      <div>
        <Dialog open={open} onClose={handleClose}>
          {value === 1 ? (
            <>
              <DialogTitle>Manage participants</DialogTitle>
              <DialogContent>
                <Form onFinish={onFinish}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogContent>
                        <Radio.Group onChange={onChange} value={value}>
                          <Radio value={1}>{t("Upload Participants")}</Radio>
                          <Radio value={2} style={{ marginRight: "20px" }}>
                            {t("Select Existing Group")}
                          </Radio>
                        </Radio.Group>
                        <br />
                        <br />
                      </DialogContent>
                      <div>
                        <GridItem xs={12} sm={12} md={7}>
                          <input type="file" name="file" onChange={changeHandler} />
                        </GridItem>
                      </div>
                    </GridItem>
                  </GridContainer>

                  <GridContainer justify="flex-end">
                    <GridItem>
                      <GridContainer justify="space-between">
                        <GridItem>
                          <Form.Item>
                            <Button color="danger" onClick={handleClose}>
                              {t("Cancel")}
                            </Button>
                          </Form.Item>
                        </GridItem>
                        <GridItem>
                          <Form.Item>
                            <Button color="info" type="submit">
                              {t("Submit")}
                            </Button>
                          </Form.Item>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </Form>
              </DialogContent>
            </>
          ) : (
            <UploadParticipantsExistingGroup Campaign_id={props.campaign.id} handleClose={handleClose} setSelectedValue={setSelectedValue} selectedValue={selectedValue} onChange={onChange} groupAssessment={groupAssessment} setValue={setValue} value={value} />

          )}
        </Dialog>
      </div>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );

}
