import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, Radio, Select, notification } from 'antd'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  fetchActionTypes,
  updateActionType,
} from '../../../../reduxToolkit/features/actionType/ActionTypeSlice.js'
import { fetchSections } from 'reduxToolkit/features/Section/SectionSlice.js'
import { fetchActionsApprovals } from 'reduxToolkit/features/ActionApproval/ActionApprovalSlice.js'
import { unwrapResult } from '@reduxjs/toolkit'
import ErrorModal from 'views/Components/ErrorModal'
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const useButtonStyles = makeStyles(buttonStyles)

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}
export default function EditActionType({
  ping,
  setPing,
  open,
  handleOpenEditModal,
  handleClose,
  actionTypes,
}) {
    console.log(actionTypes)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttonClasses = useButtonStyles()
  const [form] = Form.useForm()
  const anchorRef = React.useRef(null)

  const { Option, OptGroup } = Select
  const { TextArea } = Input
  const [group_ids, setGroups_ids] = useState('')
  const [name, setName] = useState('')

  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')
  const classes = useStyles()
  function onChange(value) {
    console.log(`selected ${value}`)
    setName(value)
  }
  const history = useHistory()

  const stateNames = useSelector(
    (state) => state.ReportStates.ReportStatesConfigs,
  )
  const groupsList = useSelector((state) => state.userGroup.userGroups)
  const sections = useSelector((state) => state.section.sections)

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  function onChangeGroups_ids(value) {
    console.log(`selected ${value}`)
    setGroups_ids(value)
  }
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [showComponent, setShowComponent] = React.useState(false)
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onFinish = async (values) => {
    try {
      const resultAction = await dispatch(
        updateActionType({ ...values, id: actionTypes.id }),
      )
      unwrapResult(resultAction)
      handleClose()
      dispatch(fetchActionTypes())
      openNotificationWithIcon('success', `update successfully`)
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }
  const actionsApprovals = useSelector(
    (state) => state.actionApproval.actionsApprovals,
  )
  console.log(actionsApprovals)
  useEffect(() => {
    dispatch(fetchActionsApprovals())
    dispatch(fetchSections())
    form.setFieldsValue({
      name: actionTypes?.name,
      code: actionTypes?.code,
      category: actionTypes?.category,
      section_ids: actionTypes?.section_ids,
      generate_version: actionTypes?.generate_version,
      version_type: actionTypes?.version_type,
      actionApprovalProcess : actionTypes?.actionApprovalProcess[0]?.id
    })
    setShowComponent(actionTypes?.generate_version)
  }, [dispatch, actionTypes])
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <center> {t('Update Action Type')}</center>
        </DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <br />
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name.',
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Code.',
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </Form.Item>
                <Form.Item label={t('Category')} name="category">
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Category"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    <Option key={1} value={'course'}>
                      {'course'}
                    </Option>
                    <Option key={1} value={'program'}>
                      {'program'}
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item label={t('Section')} name="section_ids">
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Section"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {sections?.map((section) => (
                      <Option key={section.id} value={section.id}>
                        {section.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('Approval Process')}
                  name="actionApprovalProcess"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the approval process.',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Action"
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {actionsApprovals?.map((actionApproval) => (
                      <Option key={actionApproval.id} value={actionApproval.id}>
                        {actionApproval.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Generate Version"
                  name="generate_version"
                  onChange={(e) => setShowComponent(e.target.value === 'true')}
                >
                  <Radio.Group>
                    <Radio value={true}> Yes </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
                <br />
                {showComponent && (
                  <>
                    <Form.Item label="Generate Version" name="version_type">
                      <Radio.Group>
                        <Radio value="major"> major </Radio>
                        <Radio value="minor"> minor </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </Dialog>
    </div>
  )
}
