// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { Form, Breadcrumb, Select, message, notification, Input } from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
// @material-ui/icons
// core components
/// test
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchMenus } from 'reduxToolkit/features/menu/MenuSlice'
import {
  addNewUserGroup,
  fetchUserGroupById,
  fetchUserGroups,
  updateUserGroup,
} from 'reduxToolkit/features/userGroups/UserGroupSlice'
import GroupDetails from './GroupDetails'
import {
  fetchModelById,
  fetchModels,
} from '../../../reduxToolkit/features/model/ModelSlice'
import ErrorModal from '../../Components/ErrorModal'
//const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
}
export default function AddEditGroup({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const isAddMode = isNaN(id)
  // console.log('id from match is add mode', isAddMode)
  // console.log('routes', routes)
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const titleClasses = useTitleStyles()
  const [name, setName] = useState('')
  const [menus_ids, setMenus_ids] = useState([])
  const [models_ids, setModels_ids] = useState([])
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const onNameChanged = (e) => setName(e.target.value)
  const onMenus_idsChanged = (e) => setMenus_ids(e.target.value)

  const dispatch = useDispatch()
  const { Option } = Select

  const history = useHistory()
  const onSaveClicked = async () => {
    try {
      setAddRequestStatus('pending')

      const resultAction = await dispatch(
        addNewUserGroup({ name, menus_ids, models_ids }),
      )
      unwrapResult(resultAction)
      setName('')
      setMenus_ids('')
      setModels_ids('')
      history.push('/citrine/groups')
    } catch (err) {
      console.log('menus', addNewUserGroup)
      console.error('Failed to save the Group: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }
  const onUpdateClicked = async () => {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(
        updateUserGroup({ id: id, modified: { name, menus_ids, models_ids } }),
      )
      unwrapResult(resultAction)
      setName('')
      setMenus_ids('')
      dispatch(fetchUserGroups())
      history.push('/citrine/groups')
    } catch (err) {
      console.log('groupsUpdated', updateUserGroup)
      console.error('Failed to save the groups: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }

  function onChange(value) {
    console.log(`selected ${value}`)
    setName(value)
  }

  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }

  function onChangeMenus_ids(value) {
    //  console.log(`selected ${value}`);
    setMenus_ids(value)
  }
  function onChangeModels(value) {
    //  console.log(`selected ${value}`);
    setModels_ids(value)
  }

  // const onChangeNameUpdate = React.useCallback((value) => {
  //   console.log(`selected ${value}`)

  //   let name = value
  //   dispatch(updateUserGroup({ id: id, modified: { name, menus_ids, models_ids } })).then(
  //     (res) => {
  //       dispatch(fetchUserGroupById(id))
  //     },
  //   )
  // })
  // const onChangeMenus_idsUpdate = React.useCallback((value) => {
  //   console.log(`selected ${value}`)
  //   let menus_ids = value
  //   dispatch(updateUserGroup({ id: id, modified: { name, menus_ids, models_ids } })).then(
  //     (res) => {
  //       dispatch(fetchUserGroupById(id))
  //     },
  //   )
  // })
  // const onChangeModels_idsUpdate = React.useCallback((value) => {
  //   console.log(`selected ${value}`)
  //   let models_ids = value
  //   dispatch(updateUserGroup({ id: id, modified: { name, menus_ids, models_ids } })).then(
  //     (res) => {
  //       dispatch(fetchUserGroupById(id))
  //     },
  //   )
  // })
  function refresh() {
    dispatch(
      updateUserGroup({ id: id, modified: { name, menus_ids, models_ids } }),
    )
  }

  useEffect(() => {
    dispatch(fetchMenus())
    dispatch(fetchModels())
    if (!isAddMode) {
      dispatch(fetchUserGroupById(id))
    }
  }, [id])

  const group = useSelector((state) => state.userGroup.userGroup)
  const menus = useSelector((state) => state.menu.menus)
  const models = useSelector((state) => state.model.models)
  //  const allGroups = useSelector((state) => state.userGroup.userGroups)
  useEffect(() => {
    // if (!isAddMode) {
    //   setName(group && group.name)
    //   setMenus_ids(group && group.menus_ids)
    //   setModels_ids(group && group.models_ids)
    // }
    form2.setFieldsValue({
      name : group?.name,
      menus_ids : group?.menus_ids,
      models_ids:group?.models_ids
    })
  }, [group, dispatch])
  //console.log('group', group)
  //console.log('menus group', menus_ids)
  function updateName() {
    dispatch(
      updateUserGroup({ id: id, modified: { name, menus_ids, models_ids } }),
    )
  }

  console.log(group)

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    message.error('Submit failed!')
  }

  const onFinish = async (values) => {
    console.log(values)
    const resultAction = await dispatch(addNewUserGroup(values))
    if (addNewUserGroup.fulfilled.match(resultAction)) {
      const group = resultAction.payload
      openNotificationWithIcon('success', `Added ${group.name}`)
      history.push(`/citrine/groups/`)
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }
  const updateGroup = async (values) => {
    console.log(values)
    //updateUserGroup({ id: id, modified: values }),
    dispatch(updateUserGroup({ id: id, modified: values })).unwrap().then(()=>{
      form.resetFields()
      history.push('/citrine/groups')
    }).catch((err)=>{
      setHandledError(err)
        handleOpenErrorModal()
    })
  }
  return (
    <div>
      {isAddMode && (
        <div>
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/citrine/configurations`}>
                  {t('Configurations')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/citrine/groups/`}>{t('Groups List')}</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>{t('Create')}</Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            //   labelCol={{ span: 8 }}
            //   wrapperCol={{ span: 16 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            //   autoComplete="off"
            scrollToFirstError
          >
            <GridContainer>
              <Card>
                {/* <CardHeader>
                                  <h4>Add Group</h4>
                              </CardHeader> */}
                <CardBody>
                  <Form.Item
                    label={t('Name *')}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input the academic year's name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={t('Menus *')}
                    name="menus_ids"
                    rules={[
                      {
                        required: true,
                        message: "Please input the academic year's name",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      style={{ width: '100%', marginTop: 20 }}
                      optionFilterProp="children"
                      placeholder={t('Menus')}
                      name="menus_ids"
                      //  value={menus_ids}
                      onChange={onChangeMenus_ids}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {menus &&
                        menus.map((r) => (
                          <Option key={r.id} value={r.id}>
                            {r.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={t('Models *')}
                    name="models_ids"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the models',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      style={{ width: '100%', marginTop: 20 }}
                      optionFilterProp="children"
                      placeholder={t('models')}
                      name="models_ids"
                      //  value={menus_ids}
                      onChange={onChangeModels}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {models &&
                        models.map((m) => (
                          <Select.Option key={m.id} value={m.id}>
                            {m.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      style={{ marginRight: '20px' }}
                      color="primary"
                      onClick={() => history.push(`/citrine/groups/`)}
                      htmltype="submit"
                    >
                      Cancel
                    </Button>

                    <Button type="submit" color="primary">
                      {t('Save')}
                    </Button>
                  </Form.Item>
                </CardBody>
              </Card>
            </GridContainer>
          </Form>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
          {/* <GroupDetails id={group.id} name={group.name} models={models}/>   */}
        </div>
      )}
      {!isAddMode && group && (
        <div>
         <GridItem xs={12} sm={12} md={11}>
               <Breadcrumb separator=">">
                 <Breadcrumb.Item>
                  <Link to={`/citrine/configurations`}>
                     {t('Configurations')}
                   </Link>
                </Breadcrumb.Item>
                 <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
                 <Breadcrumb.Item>
                   <Link to={`/citrine/groups/`}>{t('Groups List')}</Link>
                 </Breadcrumb.Item>

                <Breadcrumb.Item>{t('Edit')}</Breadcrumb.Item>
               </Breadcrumb>
             </GridItem>
        <Form
          name="basic"
          form={form2}
          layout="vertical"
          //   labelCol={{ span: 8 }}
          //   wrapperCol={{ span: 16 }}
          // initialValues={{ remember: true }}
          onFinish={updateGroup}
          onFinishFailed={onFinishFailed}
          //   autoComplete="off"
          scrollToFirstError
        >
          <GridContainer>
            <Card>
              {/* <CardHeader>
                                <h4>Add Group</h4>
                            </CardHeader> */}
              <CardBody>
                <Form.Item
                  label={t('Name *')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the academic year's name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t('Menus *')}
                  name="menus_ids"
                  rules={[
                    {
                      required: true,
                      message: "Please input the academic year's name",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    style={{ width: '100%', marginTop: 20 }}
                    optionFilterProp="children"
                    placeholder={t('Menus')}
                    name="menus_ids"
                    //  value={menus_ids}
                    //onChange={onChangeMenus_ids}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {menus &&
                      menus.map((r) => (
                        <Option key={r.id} value={r.id}>
                          {r.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('Models *')}
                  name="models_ids"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the models',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    style={{ width: '100%', marginTop: 20 }}
                    optionFilterProp="children"
                    placeholder={t('models')}
                    name="models_ids"
                    //  value={menus_ids}
                    //onChange={onChangeModels}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {models &&
                      models.map((m) => (
                        <Select.Option key={m.id} value={m.id}>
                          {m.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: '20px' }}
                    color="primary"
                    onClick={() => history.push(`/citrine/groups/`)}
                    htmltype="submit"
                  >
                    Cancel
                  </Button>

                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </CardBody>
            </Card>
          </GridContainer>
        </Form>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
        {/* <GroupDetails id={group.id} name={group.name} models={models}/>   */}
      </div>
        // <div>
        //   <GridContainer xs={12} sm={12} md={12}>
        //     <GridItem xs={12} sm={12} md={11}>
        //       <Breadcrumb separator=">">
        //         <Breadcrumb.Item>
        //           <Link to={`/citrine/configurations`}>
        //             {t('Configurations')}
        //           </Link>
        //         </Breadcrumb.Item>
        //         <Breadcrumb.Item>{t('Access Control')}</Breadcrumb.Item>
        //         <Breadcrumb.Item>
        //           <Link to={`/citrine/groups/`}>{t('Groups List')}</Link>
        //         </Breadcrumb.Item>

        //         <Breadcrumb.Item>{t('Edit')}</Breadcrumb.Item>
        //       </Breadcrumb>
        //     </GridItem>
        //     {/* <GridItem xs={12} sm={12} md={1}>
        //             <Button onClick={() => { onUpdateClicked() }} color="primary">
        //                 {t(' Update')}
        //             </Button></GridItem> */}
        //   </GridContainer>
        //   <GridContainer>
        //     <Card>
        //       <CardHeader>Group General informations</CardHeader>
        //       <CardBody>
                
        //         <form>
        //           <GridContainer
        //             xs={12}
        //             sm={12}
        //             md={12}
        //             style={{ display: 'flex', justifyContent: 'space-between' }}
        //           >
        //             {/* <GridItem > */}

        //             {/* <GridContainer xs={12} sm={12} md={12} style={{ justifyContent: "space-between" }}> */}
        //             <GridItem xs={12} sm={12} md={12}>
        //               {/* <label>{t('Name *')} </label>  */}

        //               <CustomInput
        //                 labelText={t('Name *')}
        //                 id="name"
        //                 value={name}
        //                 //   onChange={onChangeNameUpdate}
        //                 type="text"
        //                 formControlProps={{
        //                   fullWidth: true,
        //                 }}
        //                 inputProps={{
        //                   disabled: false,
        //                   onChange: (event) => {
        //                     setName(event.target.value)
        //                     updateName()
        //                   },
        //                   value: name,
        //                 }}
        //               />
        //             </GridItem>
        //             <GridItem xs={12} sm={12} md={12}>
        //               <label>{t('Menus *')} </label>
        //               <Select
        //                 mode="multiple"
        //                 showSearch
        //                 style={{ width: '100%', marginTop: 20 }}
        //                 optionFilterProp="children"
        //                 placeholder={t('Menus')}
        //                 name="menus_ids"
        //                 value={menus_ids}
        //                 onChange={onChangeMenus_ids}
        //                 onFocus={onFocus}
        //                 onBlur={onBlur}
        //                 onSearch={onSearch}
        //                 filterOption={(input, option) =>
        //                   option.children
        //                     .toLowerCase()
        //                     .indexOf(input.toLowerCase()) >= 0
        //                 }
        //               >
        //                 {menus &&
        //                   menus.map((r) => (
        //                     <Option key={r.id} value={r.id}>
        //                       {r.name}
        //                     </Option>
        //                   ))}
        //               </Select>
        //             </GridItem>
        //             <GridItem xs={12} sm={12} md={12}>
        //               <label>{t('Models *')} </label>
        //               <Select
        //                 mode="multiple"
        //                 showSearch
        //                 style={{ width: '100%', marginTop: 20 }}
        //                 optionFilterProp="children"
        //                 placeholder={t('Models')}
        //                 name="models_ids"
        //                 value={models_ids}
        //                 onChange={onChangeModels}
        //                 onFocus={onFocus}
        //                 onBlur={onBlur}
        //                 onSearch={onSearch}
        //                 filterOption={(input, option) =>
        //                   option.children
        //                     .toLowerCase()
        //                     .indexOf(input.toLowerCase()) >= 0
        //                 }
        //               >
        //                 {models &&
        //                   models.map((m) => (
        //                     <Option key={m.id} value={m.id}>
        //                       {m.name}
        //                     </Option>
        //                   ))}
        //               </Select>
        //             </GridItem>
        //           </GridContainer>
        //         </form>
        //       </CardBody>
        //     </Card>
        //   </GridContainer>

        //   <GroupDetails id={group.id} name={group.name} />
        // </div>
      )}
    </div>
  )
}
