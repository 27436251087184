import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  courseAssessmentMethods: [],
  courseAssessmentMethodsStatus: "idle",
  courseAssessmentMethodStatus: "idle",
  error: null,
  courseAssessmentMethod: null,
  id: null,
};

export const fetchCourseAssessmentMethods = createAsyncThunk(
  "courseAssessmentMethodSpec/fetchCourseAssessmentMethods",
  async (param,{rejectWithValue}) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + "/courseAssessmentMethodSpec/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseAssessmentMethodById = createAsyncThunk(
  "courseAssessmentMethodSpec/fetchCourseAssessmentMethodById",
  async (id,{rejectWithValue}) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/courseAssessmentMethodSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, "fetchCourseAssessmentMethodById  response");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchCourseAssessmentMethodById_NEW = createAsyncThunk(
  "courseAssessmentMethodSpec/fetchCourseAssessmentMethodById",
  async (id_var, CallBack,{rejectWithValue}) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/courseAssessmentMethodSpec/${id_var}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      CallBack();
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewCourseAssessmentMethod = createAsyncThunk(
  "courseAssessmentMethodSpec/addNewCourseAssessmentMethod",
  async (initialCourseAssessmentMethod,{rejectWithValue}) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/courseAssessmentMethodSpec/create/`,
        initialCourseAssessmentMethod,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const updateCourseAssessmentMethod = createAsyncThunk(
  "courseAssessmentMethodSpec/updateCourseAssessmentMethod",
  async (data,{rejectWithValue}) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/courseAssessmentMethodSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );

      console.log(response.data, "response.data updatin CAM from slice");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCourseAssessmentMethod = createAsyncThunk(
  "courseAssessmentMethodSpec/deleteCourseAssessmentMethod",
  async (id,{rejectWithValue}) => {
    try{
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/courseAssessmentMethodSpec/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data");

    return response.data;
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
  }
);
const courseAssessmentMethodsSlice = createSlice({
  name: " courseAssessmentMethodSpec",
  initialState,
  extraReducers: {
    [fetchCourseAssessmentMethods.pending]: (state, action) => {
      state.courseAssessmentMethodsStatus = "loading";
    },
    [fetchCourseAssessmentMethods.fulfilled]: (state, action) => {
      state.courseAssessmentMethodsStatus = "succeeded";
      state.courseAssessmentMethods = action.payload;
    },
    [fetchCourseAssessmentMethods.rejected]: (state, action) => {
      state.courseAssessmentMethodsStatus = "failed";
        if (action.payload) {
          state.error = action.payload
        } else {
          state.error = action.error
        }
    },

    [fetchCourseAssessmentMethodById.pending]: (state, action) => {
      state.courseAssessmentMethodStatus = "loading";
    },
    [fetchCourseAssessmentMethodById.fulfilled]: (state, action) => {
      state.courseAssessmentMethodStatus = "succeeded";
      state.courseAssessmentMethod = action.payload;
    },
    [fetchCourseAssessmentMethodById.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [addNewCourseAssessmentMethod.fulfilled]: (state, action) => {
      state.courseAssessmentMethods.push(action.payload);
    },
    [addNewCourseAssessmentMethod.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateCourseAssessmentMethod.fulfilled]: (state, action) => {
    },
    [updateCourseAssessmentMethod.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },

      courseAssessmentMethodUpdated(state, action) {
        const { id, weight, total_marks, assessmentMethod_id } = action.payload;
        const existingCourseAssessmentMethod =
          state.courseAssessmentMethod.find(
            (courseAssessmentMethod) => courseAssessmentMethod.id === id
          );
        if (existingCourseAssessmentMethod) {
          existingCourseAssessmentMethod.weight = weight;
          existingCourseAssessmentMethod.total_marks = total_marks;
          existingCourseAssessmentMethod.assessmentMethod_id =
            assessmentMethod_id;
        }
      },
    },
  },
});

export const {
  courseAssessmentMethodAdded,
  courseAssessmentMethodUpdated,
  getId,
} = courseAssessmentMethodsSlice.actions;

export default courseAssessmentMethodsSlice.reducer;

export const selectAllCourseAssessmentMethods = (state) =>
  state.courseAssessmentMethodSpec.courseAssessmentMethods;
