import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Form, notification, Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  addNewCampaign, fetchCampaigns
} from "../../reduxToolkit/features/campaign/CampaignsSlice";
import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramsSlice";

import { Checkbox, Col, Row } from "antd";
import ErrorModal from "./ErrorModal";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

export default function CreateProgramSurveyModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const program = useSelector((state) => state.program.program);

  console.log(program)
  useEffect(() => {
    dispatch(fetchProgramById(props.ProgramID));
  }, [dispatch]);
  const [form] = Form.useForm();
  // console.log('program by id', program)
  const [checkedValues, setCheckedValues] = useState([]);
  function onChange(checkedValues) {
    console.log("checked = ", checkedValues.join(",").toString());
    setCheckedValues(checkedValues.join(",").toString());
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [open, setOpen] = React.useState(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const { Option } = Select;

  const [updateRequestStatus, setUpdateRequestStatus] = useState("idle");
  const onFinish = async () => {
    const resultAction = await dispatch(
      addNewCampaign({
        resource_id: props.ProgramID,
        resource: "program",
        survey_template: checkedValues
      })
    );
    if (addNewCampaign.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchCampaigns());
      history.push(`/citrine/surveys/studentsSurvey/`);
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
        }}
      >
        <h6>{t("Create Survey")}</h6>
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title"> {t("Create Survey")}</DialogTitle>
        <DialogContent>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
              {program && program.surveyTemplates && program.surveyTemplates && program.surveyTemplates.map((template) =>
                <div>
                  <Row style={{ paddingBottom: "10px" }}>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <Checkbox value={template.id}>{template.survey_name}</Checkbox>
                    </Col>
                  </Row>
                </div>
              )}
              {/* <Row style={{ paddingBottom: "10px" }}>
                <Col span={6}></Col>
                <Col span={18}>
                  <Checkbox value="ses">Student Experience Survey</Checkbox>
                </Col>
              </Row>

              <Row style={{ paddingBottom: "10px" }}>
                <Col span={6}></Col>
                <Col span={18}>
                  <Checkbox value="es">Employer Survey</Checkbox>
                </Col>
              </Row> */}
            </Checkbox.Group>



            <Form.Item {...tailLayout}>
              <Button

                style={{ marginRight: "20px" }}
                color="danger"
                onClick={handleClose}

              >
                Cancel
              </Button>
              <Button type="primary" color="info" htmlType="submit">
                Submit
              </Button>
            </Form.Item>


          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
