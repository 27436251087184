import { unwrapResult } from "@reduxjs/toolkit";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Breadcrumb, notification, Popconfirm } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";
import {
  deleteUserGroup,
  fetchUserGroupById,
  fetchUserGroups
} from "../../../reduxToolkit/features/userGroups/UserGroupSlice";

const useStyles = makeStyles(styles);

export default function GroupList() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const groupsStatus = useSelector((state) => state.userGroup.userGroupsStatus);
  const groups = useSelector((state) => state.userGroup.userGroups);

  const [color, setColor] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [groupToDelete, setGroupToDelete] = useState("");

  useEffect(() => {
    if (groupsStatus === "idle") {
      dispatch(fetchUserGroups());
    }
  }, [])

  const columns = [
    {
      field: "name",
      headerName: "Group Name",
      flex: 0.9,
    },
    {
      field: " ",
      width: 90,
      renderCell: (cellValues) => {
        return (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteClick(cellValues.id)}
            okButtonProps={{
              style: { backgroundColor: "#8F6682", borderColor: "#8F6682" },
            }}
            cancelButtonProps={{
              style: { color: color, borderColor: borderColor },
              onMouseEnter: () => {
                setColor("#8f6682");
                setBorderColor("#8f6682");
              },
              onMouseLeave: () => {
                setColor("");
                setBorderColor("");
              },
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={() => setGroupToDelete(`${cellValues.row.name}`)}
            >
              <DeleteIcon size="medium" />
            </IconButton>
          </Popconfirm>
        );
      },
    },
  ];

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 5,
      zIndex: 1000,
    });
  };

  const handleDeleteClick = (id) => {
    dispatch(deleteUserGroup(id)).then(() => {
      openNotificationWithIcon("success", `${groupToDelete} was deleted successfully`);
      dispatch(fetchUserGroups());
    });
  };

  const handleToggle = (value) => {
    dispatch(fetchUserGroupById(value.id))
      .then(unwrapResult)
      .then((p) => {
        history.push(`/citrine/addEditGroup/${value.id}`);
      });
  };

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t("Access Control")}</Breadcrumb.Item>
            <Breadcrumb.Item>{t("Groups List")}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Link to={`/citrine/addEditGroup/:id`}>
        <Button color="primary">{t("  Add")}</Button>
      </Link>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              color="rose"
              icon
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <GridItem xs={12} sm={12} md={9}>
                <h4 className={classes.cardIconTitle}>{t("Groups List")}</h4>
              </GridItem>
            </CardHeader>
            <CardBody>
              <GridContainer
                style={{
                  height: 590,
                  width: "100%",
                }}
              >
                <DataGrid 
                  disableColumnMenu={true}
                  rows={groups}
                  columns={columns}
                  onRowDoubleClick={handleToggle}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
