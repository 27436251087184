import {CheckOutlined, DownOutlined, UpOutlined} from "@ant-design/icons";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Radio } from "antd";
import "antd/dist/antd.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import StarIcon from "@material-ui/icons/Star";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  grayColor
} from "../../../../assets/jss/material-dashboard-pro-react";
import { deleteActionRecommendation } from "../../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import { fetchCourses } from "../../../../reduxToolkit/features/course/CoursesSlice";
import {
  fetchPlos,
  updatePLOFieldByField
} from "../../../../reduxToolkit/features/PLO/PLOsSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import {
  deleteRecommendation,
  fetchPloRecommendations
} from "../../../../reduxToolkit/features/Recommendations/RecommendationsSlice";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import ActionRecommendationModal from "../../../Components/ActionRecommendationModal";
import AddRecommendationModal from "../../../Components/AddRecommendationModal";
import EditRecommendationModal from "../../../Components/EditRecommendationModal";
import ErrorModal from "../../../Components/ErrorModal";
import useTablePlo from "../../../Components/useTablePlo";
import useTableRecommendation from "../../../Components/useTableRecommendation";
import EditComments from "./EditComments";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

const headPloCells = [
  { id: "Name", label: "Name " },
  { id: "ExternalBenchmark", label: "External Benchmark" },
  { id: "InternalBenchmark", label: "Internal Benchmark" },
  { id: "Target", label: "Target" },
  { id: "ActualBenchmark", label: "Actual Benchmark" },
];

const headRecommendationsCells = [
  { id: "Edit/Delete", label: "" },
  { id: "Recommendations", label: "Recommendations" },
  { id: "Actions", label: "Actions" },
];

export default function Analysis(props) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const program = useSelector((state) => state.program.program);
  const plos = useSelector((state) => state.plo.plos);
  const coursesStatus = useSelector((state) => state.course.coursesStatus);
  const users = useSelector((state) => state.user.staffList);

  useEffect(() => {
    dispatch(fetchProgramById(props.idProgram));
    dispatch(fetchPlos(props.idProgram));
    dispatch(fetchStaff());
  }, [dispatch]);

  useEffect(() => {
    if (coursesStatus === "idle") {
      dispatch(fetchCourses());
    }
  }, [coursesStatus, dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [ploRecommendations, setPloRecommendations] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [shownComments, setShownComments] = useState({});
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const toggleComment = (id) => {
    setShownComments((prevShownComments) => ({
      ...prevShownComments,
      [id]: !prevShownComments[id],
    }));
    dispatch(fetchPloRecommendations(id))
      .then(unwrapResult)
      .then((Recommendations) => {
        setPloRecommendations(Recommendations);
      });
  };

  const [records, setRecords] = [];

  const { TblPloContainer, TblPloHead } = useTablePlo(
    records,
    headPloCells,
    filterFn
  );

  const {
    TblRecommendationContainer,
    TblRecommendationHead,
  } = useTableRecommendation(records, headRecommendationsCells, filterFn);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const delete_PloRecommendations = (id, obiId) => {
    dispatch(deleteRecommendation(id)).then(() => {
      dispatch(fetchPloRecommendations(obiId))
        .then(unwrapResult)
        .then((Recommendations) => {
          setPloRecommendations(Recommendations);
        });
    });
  };

  const delete_Recommendation = (id, objId) => {
    dispatch(deleteActionRecommendation(id)).then(() => {
      dispatch(fetchPloRecommendations(objId))
        .then(unwrapResult)
        .then((Recommendations) => {
          setPloRecommendations(Recommendations);
        });
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onChange = async (e, id) => {
    let data = {
      updatedPLO: {
        strengths: e.target.value === "s" ? true : false,
        weaknesses: e.target.value === "w" ? true : false,
      },
      id: id,
    };

    const resultAction = await dispatch(updatePLOFieldByField(data));

    if (updatePLOFieldByField.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      dispatch(fetchPlos(program && program.id));
      openNotificationWithIcon("success", `updated successfully`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const RaitingRender = (value) => {
    if (value == 1) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
        </div>
      );
    } else if (value == 2) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
        </div>
      );
    } else if (value == 3) {
      return (
        <div style={{ display: "flex" }}>
          <StarIcon style={{ fontSize: "20px", color: "gold" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
          <StarIcon style={{ fontSize: "20px", color: "silver" }} />
        </div>
      );
    }
  };
  return (
    <div>
      <GridContainer xs={12} sm={12} md={12}>
        {plos &&
          plos.map((obj, i) => (
            <div
              key={obj.id}
              style={{
                width: "100%",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <Card key={i}>
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={11}>
                      <strong style={{padding: 5, backgroundColor: 'indianred', borderRadius: 20, color: 'white'}}>PLO No.{i}</strong>
                      <strong>
                        <p
                          style={{
                            color: "gray",
                            textAlign: "start",
                            fontSize: "14px",
                          }}
                        >
                          <Tooltip
                            id="tooltip-top"
                            title={obj.name}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                            style={{ fontSize: "14px", marginTop: 10 }}
                          >
                            <Stack direction="row" spacing={1} alignItems={'center'}>
                              <span style={{color: 'indianred'}}>PLO Name:</span>
                              <strong>{obj.name}</strong>
                            </Stack>
                          </Tooltip>
                        </p>
                      </strong>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={1}
                      style={{ textAlign: "end" }}
                    >
                      {obj.name ? (
                        <>
                          {
                            open ?
                                <UpOutlined onClick={() => {
                                  setOpen(false);
                                  toggleComment(obj.id)
                                }} />
                                 :
                                <DownOutlined onClick={() => {
                                  setOpen(true);
                                  toggleComment(obj.id)
                                }} />
                          }
                        </>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                  <GridContainer xs={12} sm={12} md={12}>
                    {shownComments[obj.id] ? (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div>
                            <TblPloContainer
                              style={{ marginTop: "0px", paddingTop: "0px" }}
                            >
                              <TblPloHead />
                              <TableBody>
                                <TableRow key={obj.id}>
                                  <TableCell>
                                    <p
                                      style={{
                                        display: "-webkit-box",
                                        maxWidth: "200px",
                                        WebkitLineClamp: 4,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {obj && obj.name && obj.name}
                                    </p>
                                  </TableCell>
                                  <TableCell className="multipleLine">
                                    {obj &&
                                      obj.external_benchmark &&
                                      obj.external_benchmark}
                                  </TableCell>
                                  <TableCell className="multipleLine">
                                    {obj &&
                                      obj.external_benchmark &&
                                      obj.internal_benchmark}
                                  </TableCell>
                                  <TableCell className="multipleLine">
                                    {obj && obj.target && obj.target}
                                  </TableCell>
                                  <TableCell className="multipleLine">
                                    {obj &&
                                      obj.plo_score &&
                                      obj.plo_score}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </TblPloContainer>
                            <br></br>
                            <br></br>
                            <GridContainer>
                              <GridItem>
                                <span>Analysis:</span>
                              </GridItem>
                            </GridContainer>
                            <br></br>
                            <div>
                              <Radio.Group
                                onChange={(e) => {
                                  onChange(e, obj.id);
                                }}
                                value={
                                  obj.weaknesses
                                    ? "w"
                                    : obj.strengths
                                      ? "s"
                                      : null
                                }
                              >
                                <Radio value={"s"}>Strength</Radio>
                                <Radio value={"w"}>Weakness</Radio>
                              </Radio.Group>
                              <br></br>
                              <br></br>
                              <GridContainer justify="space-between">
                                <GridItem>
                                  <span>Comments:</span>
                                </GridItem>
                                <GridItem>
                                  <EditComments
                                    id={obj.id}
                                    program={program && program}
                                  />
                                </GridItem>
                              </GridContainer>
                            </div>
                            <div
                              style={{
                                border: `1px solid  ${grayColor[15]}`,
                                padding: "10px",
                                minHeight: "50px",
                              }}
                            >
                              {obj.comments}
                            </div>
                          </div>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Stack direction="row" spacing={1} alignItems={'center'}>
                            <span>Recommendations:</span>
                            <div>
                              <AddRecommendationModal
                                  handleDropDownButtonClose={handleClose}
                                  plo_id={obj.id}
                                  setPloRecommendations={setPloRecommendations}
                                  resource={"Plo"}
                                  resource_id={obj.id}
                              />
                            </div>
                          </Stack>
                          <div
                            style={{
                              margin: "0px 10px 10px 10px ",
                              borderRight: "1px solid lightgray",
                              borderLeft: "1px solid lightgray",
                            }}
                          >
                            <TblRecommendationContainer
                              style={{ marginTop: "0px", paddingTop: "0px" }}
                            >
                              <TblRecommendationHead />
                              <TableBody>
                                {ploRecommendations &&
                                  ploRecommendations.map((item) => (
                                    <TableRow key={item.id}>
                                      <TableCell
                                        style={{
                                          borderRight: "1px solid lightgray",
                                        }}
                                      >
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            maxWidth: "200px",
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {item && item.recommendation}
                                        </p>
                                      </TableCell>
                                      <TableCell
                                        className="multipleLinePlo"
                                        style={{
                                          width: "490px",
                                        }}
                                      >
                                        <GridContainer xs={12} sm={12} md={12}>
                                          <GridItem
                                            xs={12}
                                            sm={12}
                                            md={11}
                                          ></GridItem>
                                          <GridItem xs={12} sm={12} md={1}>
                                            <ActionRecommendationModal
                                              program_id={props.idProgram}
                                              handleDropDownButtonClose={
                                                handleClose
                                              }
                                              recommendation_id={item.id}
                                              plo_id={obj.id}
                                              users={users}
                                              setPloRecommendations={
                                                setPloRecommendations
                                              }
                                              idPloReport={props.idPloReport}
                                            />
                                          </GridItem>
                                        </GridContainer>
                                        <GridContainer xs={12} sm={12} md={12}>
                                          {item &&
                                            item.action_recommendations &&
                                            item.action_recommendations.map(
                                              (element) => (
                                                <GridContainer
                                                  xs={12}
                                                  sm={12}
                                                  md={12}
                                                >
                                                  <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={11}
                                                  >
                                                    <strong>
                                                      <CheckOutlined />{" "}
                                                      {element.type_action && element.type_action}{" "}
                                                      :
                                                    </strong>
                                                    {element.text && element.text}
                                                    <br></br>
                                                    <strong>
                                                      {" "}
                                                      Assigned to :  {" "}
                                                    </strong>
                                                    {element.assign_to}
                                                    <br></br>
                                                    <strong>
                                                      {" "}
                                                      Start date : {" "}
                                                    </strong>
                                                    {element.start_date && element.start_date}
                                                    <br></br>
                                                    <strong>
                                                      {" "}
                                                      Due date : {" "}
                                                    </strong>
                                                    {element.endDate && element.endDate}
                                                    <br></br>
                                                    <strong>
                                                      {" "}
                                                      Priority : {RaitingRender(element && element.priorityId)}
                                                    </strong>
                                                    <br></br>
                                                  </GridItem>
                                                  <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={1}
                                                  >
                                                    <Stack direction="row" spacing={1} justifyContent={'center'} alignItems={'center'}>
                                                      <DeleteOutlineIcon
                                                          onClick={() =>
                                                              delete_Recommendation(
                                                                  element.id,
                                                                  obj.id
                                                              )
                                                          }
                                                          style={{
                                                            color: "red",
                                                            fontSize: "16px",
                                                          }}
                                                      />
                                                    </Stack>
                                                  </GridItem>
                                                </GridContainer>
                                              )
                                            )}
                                        </GridContainer>
                                      </TableCell>
                                      <TableCell
                                          className={classes.tableActions}
                                          style={{
                                            borderRight: "1px solid lightgray",
                                          }}
                                      >
                                        <Stack direction="row" spacing={1} justifyItems={'space-between'} alignItems={'center'}>
                                          <EditRecommendationModal
                                              recommendation_id={item.id}
                                              plo_Id={obj.id}
                                              setPloRecommendations={
                                                setPloRecommendations
                                              }
                                          />

                                          <Tooltip
                                              id="tooltip-top-start"
                                              title="Remove"
                                              placement="top"
                                              classes={{ tooltip: classes.tooltip }}
                                          >
                                            <IconButton
                                                aria-label="Close"
                                                style={{
                                                  color: "red",
                                                  fontSize: "10px",
                                                }}
                                                className={
                                                  classes.tableActionButton
                                                }
                                                onClick={() =>
                                                    delete_PloRecommendations(
                                                        item.id,
                                                        obj.id
                                                    )
                                                }
                                            >
                                              <Close
                                                  className={
                                                      classes.tableActionButtonIcon +
                                                      " "
                                                  }
                                                  style={{
                                                    fontSize: "18px",
                                                  }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </TblRecommendationContainer>
                          </div>
                        </GridItem>
                      </GridContainer>
                    ) : null}
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          ))}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
