import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { DatePicker, Form, notification, Select, Table } from 'antd';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { fetchAuthorities } from "../../../../reduxToolkit/features/AccreditationAuthority/AuthoritySlice";
import { addNewPlannedAccreditationDate, deletePlannedAccreditationDate, fetchPlannedAccreditationDatesByProgramId } from "../../../../reduxToolkit/features/PlannedAccreditationDates/PlannedAccreditationDatesSlice";
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import "./style.css";








// import ProgramCardDropdownButton from "../Components/ProgramCardDropdownButton"
const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

export default function PlannedAccreditationDates(props) {

    const { t } = useTranslation();
    let { programId } = useParams();

    const dispatch = useDispatch()

    const classes = useStyles();
    const cardClasses = useCardStyles();


    const fetchAllPlannedAccreditationDates = async () => {

        try {
            const resultAction = await dispatch(fetchPlannedAccreditationDatesByProgramId(programId)).unwrap()

            console.log('success')
        } catch (err) {
            setHandledError(err)
            handleOpenErrorModal()
        }
    }

    useEffect(() => {
        fetchAllPlannedAccreditationDates()
    }, [dispatch])

    const data = useSelector((state) => state.plannedAccreditationDate.plannedAccreditationDates)

    const columns = [

        {
            title: 'Date of next comprehensive review',
            dataIndex: 'date_of_next_comprehensive_review',
            key: 'date_of_next_comprehensive_review',
        },
        {
            title: 'Authority',
            dataIndex: 'authority',
            key: 'authority',
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => <Button onClick={() => { handleClickOpen(record.id) }} color="primary" simple>Delete</Button>,
        },

    ];



    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const [open, setOpen] = React.useState(false);
    const [toDeletePlannedAccreditationDateId, setToDeletePlannedAccreditationDateId] = React.useState(null);

    const handleClickOpen = (id) => {
        setToDeletePlannedAccreditationDateId(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleDeletePlannedAccreditationDate = async (id) => {
        dispatch(deletePlannedAccreditationDate(id))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                // handle result here
                console.log('originalPromiseResult', originalPromiseResult)
                dispatch(fetchPlannedAccreditationDatesByProgramId(programId))
                openNotificationWithIcon('success', `Deleted`)

            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
                setHandledError(rejectedValueOrSerializedError)
                handleOpenErrorModal()
            })
    }

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };

    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <AddPlannedAccreditationDateModal />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Table columns={columns} dataSource={data && data} />
                <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
                <SuperGeneralDeleteModal
                    delete_Element={handleDeletePlannedAccreditationDate}
                    Element_ID={toDeletePlannedAccreditationDateId}
                    open={open}
                    handleClose={handleClose}

                />
            </GridItem>
        </GridContainer>



    );
}

function AddPlannedAccreditationDateModal() {
    let { programId } = useParams();

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        dispatch(fetchAuthorities())
    }, [dispatch])

    const authorities = useSelector((state) => state.authority.authorities)

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };
    const { Option } = Select;


    // const [kpiConfig_id, setkpiConfig_id] = React.useState([]);

    // function handleChange(value) {
    //     console.log("value", value);
    //     setkpiConfig_id(value)
    // }

    const [authority_id, setAuthority_id] = useState(null)

    const [date_of_next_comprehensive_review, setDate_of_next_comprehensive_review] = useState('')

    function onPlannedAccreditationDateChange(value, dateString) {
        setDate_of_next_comprehensive_review(dateString)
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    }

    const programSpec_id = programId

    const onFinish = async () => {
        const resultAction = await dispatch(addNewPlannedAccreditationDate({ programSpec_id, authority_id, date_of_next_comprehensive_review }))

        if (addNewPlannedAccreditationDate.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk

            dispatch(fetchPlannedAccreditationDatesByProgramId(programId))

            // history.push(`/citrine/programSpec/${programSpec_id}/KPIs`);
            openNotificationWithIcon('success', `Affected successfully`)
            handleClose()


        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                console.log('resultAction.payload from component', resultAction.payload)
                setHandledError(resultAction.payload)
                handleOpenErrorModal()
                handleClose()
            } else {
                console.log('resultAction.error  from component', resultAction.error)
                setHandledError(resultAction.error)
                handleOpenErrorModal()
                handleClose()
            }
        }

    }


    return (
        <div>

            <Button color='primary'
                onClick={(event) => {
                    handleClickOpen(event)
                }}>

                Add
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}>
                <DialogTitle id="form-dialog-title">{t('Add planned Accreditation Date')}</DialogTitle>
                <DialogContent>

                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        scrollToFirstError
                    >

                        <GridContainer>


                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t('Date of next comprehensive review')}
                                    name="date_of_next_comprehensive_review"
                                >
                                    <DatePicker onChange={onPlannedAccreditationDateChange} />
                                </Form.Item>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Form.Item
                                    label={t("Authority")}
                                    name="authority_id"
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ zIndex: 5 }}
                                        dropdownStyle={{ zIndex: 2000 }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={value => {
                                            setAuthority_id(value);
                                        }}
                                    >
                                        {authorities && authorities.length && authorities.map((authority) => (
                                            <Option key={authority.id} value={authority.id}>
                                                {authority.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>

                        </GridContainer>


                        {/* </FormControl> */}
                        <GridContainer justify="flex-end">
                            <GridItem>

                                <Form.Item>
                                    <GridContainer>
                                        <GridItem>
                                            <Button color="primary" onClick={() => { handleClose() }}>
                                                {t('Cancel')}
                                            </Button>
                                        </GridItem>
                                        <GridItem>
                                            <Button type="submit" color="primary">
                                                {t('Save')}
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </Form.Item>

                            </GridItem>
                        </GridContainer>

                    </Form>
                </DialogContent>
            </Dialog>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError} />
        </div>
    );
}