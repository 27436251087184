import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import TranscriptsFiles from "../criterion1/TranscriptsFiles";

const CourseSyllabi = ({ data }) => {

  return (
    <>
      <h3>CURRICULUM: Course Syllabi</h3>
      <p className={styles.reportParagraphTitle}>
        Upload and preview the course syllabi using the tool below:
      </p>

      <br/>
      <TranscriptsFiles/>
    </>
  );
};

export default CourseSyllabi;
