import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  totalLevels: [],
  totalLevelsStatus: 'idle',
  totalLevelStatus: 'idle',
  error: null,
  totalLevel: null,
  id: null
}

export const fetchTotalLevels = createAsyncThunk('totalLevel/fetchTotalLevels', async () => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+'/totalLevelsConfig/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
   console.log(response.data,'response.data')

  return response.data
})

export const fetchTotalLevelById = createAsyncThunk('totalLevel/fetchTotalLevelById', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.get(BaseUrl+`/totalLevelsConfig/${id}/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
export const addNewTotalLevel = createAsyncThunk(
  'totalLevel/addNewTotalLevel',
  async (initialTotalLevel) => {
   const token=localStorage.getItem('token')

    const response = await axios.post(BaseUrl+`/totalLevelsConfig/create/`, initialTotalLevel, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateTotalLevel = createAsyncThunk(
  'totalLevel/updateTotalLevel',
  async (data, thunkAPI) => {
    const token=localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl+`/totalLevelsConfig/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedTotalLevel')
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the totalLevel: ', error)

    }
  }
)

export const deleteTotalLevel = createAsyncThunk('totalLevel/deleteTotalLevel', async (id) => {
  const token=localStorage.getItem('token')

  const response = await axios.delete(BaseUrl+`/totalLevelsConfig/${id}/delete/`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})
const totalLevelsSlice = createSlice({
  name: ' totalLevel',
  initialState,
  extraReducers: {

    [fetchTotalLevels.pending]: (state, action) => {
      state.totalLevelsStatus = 'loading'
    },
    [fetchTotalLevels.fulfilled]: (state, action) => {
      state.totalLevelsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.totalLevels = action.payload
    },
    [fetchTotalLevels.rejected]: (state, action) => {
      state.totalLevelsStatus = 'failed'
      state.error = action.payload
    },

    [fetchTotalLevelById.pending]: (state, action) => {
      state.totalLevelStatus = 'loading'
    },
    [fetchTotalLevelById.fulfilled]: (state, action) => {
      state.totalLevelStatus = 'succeeded'

      // Add any fetched posts to the array
      state.totalLevel = action.payload
    },
    [fetchTotalLevelById.rejected]: (state, action) => {
      state.totalLevelStatus = 'failed'
      state.error = action.payload
    },
    [addNewTotalLevel.fulfilled]: (state, action) => {
      state.totalLevels.push(action.payload)
    },
    [deleteTotalLevel.fulfilled]: (state, action) => {
      // state.totalLevels.push(action.payload)
    },
    [updateTotalLevel.fulfilled]: (state, action) => {
      const result = state.totalLevels.filter(totalLevel => totalLevel.id !== action.payload.id);
      // console.log("totalLevel id from filter from slice",totalLevel.id)
      result.push(action.payload)
      state.totalLevels = result
      console.log("action.payload.id from slice",action.payload)

    },
  },

})

export default totalLevelsSlice.reducer

 export const selectAllTotalLevels = state => state.totalLevel.totalLevels
