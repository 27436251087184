import { ListItemText, Menu, MenuItem, withStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Button, notification } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteStudenSurvey from '../StudentSurvey/DeleteStudenSurvey.jsx'
import { deleteCampaign ,fetchCampaigns} from "../../../../reduxToolkit/features/campaign/CampaignsSlice.js"
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
));

export default function DropDownButtonStudenSurvey(props) {


    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    const handleOpenDeleteModal = () => {
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };
   
  const dispatch = useDispatch();

  const handleDeleteCourse = async () => {
    // let obj = {
    //   id: props.programId,
    //   courseSpec_delete_id: props.courseID
    // }
    dispatch(deleteCampaign(props.id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Deleted`);
        dispatch(fetchCampaigns())
        console.log("originalPromiseResult", originalPromiseResult);
        //dispatch(fetchCourses());
     
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };

    return (
        <div>
            <Button
                // aria-describedby={id}
                aria-controls="customized-menu"
                variant="contained"
                color="primary"
                onClick={handleClick}
                justIcon
                shape="circle"
                aria-haspopup="true"
                style={{width:'40px',height:'40px' ,marginTop:'-100px', backgroundColor:"#8F6682"}}
            >
                <MoreVert style={{marginTop:'3px',color:"white"}}/>
            </Button>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleOpenDeleteModal();
                        handleClose();
                    }}
                >
                    <ListItemText primary="Delete" />
                </MenuItem>
            </StyledMenu>
            <DeleteStudenSurvey
                    open={open}
                    handleClose={handleCloseDeleteModal}
                    handleDeleteCourse={handleDeleteCourse}
                    id={props.id}
                /> 
        </div>
    )
}
