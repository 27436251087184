import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { GridColumnsContainer } from "@mui/x-data-grid";
import { Form, InputNumber, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateCLOSuccessCriteria } from "reduxToolkit/features/course/CoursesSlice.js";
import ErrorModal from "../../../Components/ErrorModal.jsx";

export default function UpdateCLOSuccessCriteria(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { Option } = Select;

    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const onFinish = async (values) => {

        const resultAction = await dispatch(
            updateCLOSuccessCriteria({ course_id: props.selectedRows[0], CLOSuccessCriteria: values })
        );

        if (updateCLOSuccessCriteria.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            props.refreshCourses();
            openNotificationWithIcon("success", `updated successfully`);
            handleClose();
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        }
    };

    return (
        <div>
            <MenuItem
                disabled={!props.selectedRows.length}
                onClick={() => {
                    handleClickOpen();
                    props.handleDropDownButtonClose();
                }}
            >
                {t("Update CLO Success Criteria")}
            </MenuItem>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle id="form-dialog-title"><center>{t("CLO Success Criteria")}</center></DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={10} sm={10} md={12}>
                            <Form
                                name="basic"
                                layout="vertical"
                                onFinish={onFinish}
                                scrollToFirstError
                            >
                                <GridContainer justifyContent="center">
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Form.Item
                                            label={t("CLO Success Criteria")}

                                            name="clo_Success_Criteria"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input the CLO Success Criteria",
                                                },
                                            ]}
                                        >
                                            <InputNumber min={0} max={100} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="center">
                                    <GridItem>
                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            handleClose();
                                                        }}
                                                    >
                                                        {t("Cancel")}
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t("Save")}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                            </Form>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    );
}
