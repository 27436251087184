import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import {deleteTypesConfig} from "../TypesConfig/TypesConfigSlice";
const initialState = {

  researchDirectionConfigs: [],
  researchDirectionConfigsStatus: 'idle',
  researchDirectionConfigsByType: [],
  researchDirectionConfigsByTypeStatus: 'idle',
  researchDirectionConfig: null,
  researchDirectionConfigStatus: 'idle',

}

export const fetchResearchDirectionConfigs = createAsyncThunk('researchDirectionConfig/fetchResearchDirectionConfigs', async (id,{ rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + '/researchDirectionConfig/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    return response.data
  } catch (err) {
    let error = err
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response)
  }
})

export const addResearchDirectionConfig = createAsyncThunk(
  'researchDirectionConfig/addResearchDirectionConfig',
  async (toAddActivity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/researchDirectionConfig/create/`, toAddActivity, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  }
)

export const deleteResearchDirectionConfig = createAsyncThunk(
    'researchDirectionConfig/deleteResearchDirectionConfig',
    async (id, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.delete(BaseUrl + `/researchDirectionConfig/${id}/delete/`, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        return response?.data
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
      }
    })

const researchDirectionConfigSlice = createSlice({
  name: 'researchDirectionConfig',
  initialState,
  extraReducers: {
    [fetchResearchDirectionConfigs.pending]: (state, action) => {
      state.researchDirectionConfigsStatus = 'loading'
    },
    [fetchResearchDirectionConfigs.fulfilled]: (state, action) => {
      state.researchDirectionConfigsStatus = 'succeeded'
      state.researchDirectionConfigs = action.payload
    },
    [fetchResearchDirectionConfigs.rejected]: (state, action) => {
      state.researchDirectionConfigsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteResearchDirectionConfig.fulfilled]: (state, action) => {},
    [deleteResearchDirectionConfig.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default researchDirectionConfigSlice.reducer

