import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Form, Input, message, notification, Select } from 'antd'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import { greyColor } from 'assets/jss/material-dashboard-pro-react';
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ErrorModal from '../../Components/ErrorModal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCoursesByUserAndSemester } from 'reduxToolkit/features/course/CoursesSlice'
import { Link as MuiLink } from '@material-ui/core';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

export default function PushLMSData() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const { Option } = Select
    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [handledError, setHandledError] = useState('')

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true)
    }
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false)
    }

    useEffect(() => {
        dispatch(fetchCoursesByUserAndSemester())

    }, [])

    const coursesByUserAndSemester = useSelector((state) => state.course.coursesByUserAndSemester)

    const onFinishFailed = (errorInfo) => {
        message.error('Submit failed!')
    }

    const onFinish = async (values) => {
        // const resultAction = await dispatch(
        //   addNewProgram(values),
        // )
        // if (addNewProgram.fulfilled.match(resultAction)) {
        //   const progarm = resultAction.payload
        //   openNotificationWithIcon(
        //     'success',
        //     `Added ${progarm.program_degree_name}`,
        //   )
        //   history.push('/citrine/programSpec')
        // } else {
        //   if (resultAction.payload) {
        //     // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        //     setHandledError(resultAction.payload)
        //     handleOpenErrorModal()
        //   } else {
        //     setHandledError(resultAction.error)
        //     handleOpenErrorModal()
        //   }
        // }
    }
    return (
        <>
            <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10 }}>Push LMS Data</p>
            <div style={{ fontSize: "20px", padding: 10, borderBottom: `1px solid ${greyColor[0]}`, borderTop: `1px solid ${greyColor[0]}` }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/citrine/admin">
                        Administrator Panel
                    </Link>
                    <Link to="/citrine/admin">
                        Data Integration
                    </Link>
                    <Typography color="text.primary">Push LMS Data</Typography>
                </Breadcrumbs>
            </div>
            <br />

            <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
            >
                <GridContainer direction="row" justify="center" alignItems="center">

                    <GridItem xs={12} sm={12} md={10}>
                        <p style={{ fontSize: 16 }}>
                            Choose a course to push its data to Citrine
                        </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t('Course')}
                                            name="course_id"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please choose a course",
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                                name="course_id"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {coursesByUserAndSemester.map((course) => (
                                                    <Option key={course.id} value={course.id}>
                                                        {course.title}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </GridItem>
                                    <br />
                                    <GridItem xs={12} sm={12} md={12}>
                                        <p style={{ fontWeight: 400 }}>Last pull was on: <span style={{ fontWeight: 200 }}>{new Date().toLocaleString()}</span></p>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <GridContainer justify="flex-end" alignItems="baseline" >
                            <GridItem>
                                <MuiLink
                                    color={primaryColor[0]}
                                    // component="button"
                                    variant="body2"
                                    onClick={() => {
                                        console.log("downloaded");
                                    }}> Download last CSV
                                </MuiLink>
                            </GridItem>
                            <GridItem>
                                <Form.Item>
                                    <Button type="submit" color="primary">
                                        {t('PUSH')}
                                    </Button>
                                </Form.Item>
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                </GridContainer>

            </Form>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </>
    );
}