import { Avatar } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { SchoolRounded } from "@material-ui/icons";
import { Breadcrumb } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import Card from '@material-ui/core/Card';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
// react plugin for creating charts
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
//chartist
import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchPloById,
} from "../../../../reduxToolkit/features/PLO/PLOsSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import WeightCloModal from "../../../Components/WeightCloModal";
import WeightIndicatorModal from "../../../Components/WeightIndicatorModal";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Button from "../../../../components/CustomButtons/Button";
import {
  primaryColor,
  greyColor,
} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));


export default function PloDetails({ match }) {

  const { id, idPlo } = match.params;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openEditPLOIndWeightModal, setOpenEditPLOIndWeightModal] = useState(false);
  const [openEditCLOWeightModal, setOpenEditCLOWeightModal] = useState(false);

  const [ploInd, setPloInd] = useState();
  const [clo, setClo] = useState();

  const columns = [
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'target', headerName: 'Target', flex: 1 },
    { field: 'weight', headerName: 'Weight', flex: 1 },
    { field: 'score', headerName: 'Score', flex: 1 },
    {
      field: 'actions', headerName: 'Actions', flex: 1,
      renderCell: (params) => (
        <Tooltip
          id="tooltip-top"
          title="Edit CLO's Weight"
          placement="bottom"
        >
          <Button
            round
            justIcon
            simple
            onClick={() => handleOpenEditCLOWeightModal(params.row)}
          >
            <EditIcon fontSize="sm" color="action" />
          </Button>
        </Tooltip>
      ),
    },

  ];


  useEffect(() => {
    dispatch(fetchProgramById(id));
    dispatch(fetchPloById(idPlo)).then(unwrapResult).then((plo) => {
    });
  }, []);


  const program = useSelector((state) => state.program.program);
  const plo = useSelector((state) => state.plo.plo);



  const handleOpenEditPLOIndWeightModal = (ploIndicator) => {
    setOpenEditPLOIndWeightModal(true);
    setPloInd(ploIndicator)
    console.log(ploIndicator)
  };
  const handleCloseEditPLOIndWeightModal = () => {
    setOpenEditPLOIndWeightModal(false);
  };

  const handleOpenEditCLOWeightModal = (clo) => {
    setOpenEditCLOWeightModal(true);
    setClo(clo)
    console.log(clo)
  };
  const handleCloseEditCLOWeightModal = () => {
    setOpenEditCLOWeightModal(false);
  };
  return (
    <>
      <Breadcrumb separator=">" params={id}>
        <Breadcrumb.Item>
          <Link to={`/citrine/programs_assessment`}>{t("Programs List")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/program/${id}`}>
            {program?.program_degree_name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/program/${id}/PloListPage/`}>
            {t("Plo List")}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/citrine/program/${id}/Plo/${idPlo}`}
            style={{ color: "orange" }}
          >
            {t("Plo Details")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br></br>

      <Card style={{ height: "fit-content", paddingBottom: 5 }}>
        <CardHeader
          color="info"
          icon
        >
          <GridContainer alignItems="baseline">
            <GridItem xs={1} sm={1} md={1}>
              <CardIcon
                color="primary"
              >
                <SchoolRounded />
              </CardIcon>
            </GridItem>

            <GridItem xs={11} sm={11} md={11}>
              <GridContainer alignItems="center">
                <GridItem xs={12} sm={6} md={6}>
                  <GridContainer justifyContent="space-between" alignItems="center">
                    <GridItem>
                      <h4
                        style={{
                          color: "#8F6682",
                          height: "13px",
                          fontSize: "14px",
                          marginRight: "20px",
                        }}
                      >
                        <strong>{program?.code}</strong>
                      </h4>
                      <h4
                        style={{
                          color: "#6C7B9D",
                          height: "13px",
                          fontSize: "14px",
                        }}
                      >
                        <strong>
                          {program?.program_degree_name}
                        </strong>
                      </h4>
                    </GridItem>
                    <GridItem>
                      <h4
                        style={{
                          color: "#8F6682",
                          fontSize: "14px",
                          height: "13px",
                        }}
                      >
                        <strong> {t("University")} </strong>

                      </h4>
                      <h4
                        style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                      >
                        <strong>
                          {program?.university_detail?.map((el) => el.name)}
                        </strong>
                      </h4>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <GridContainer justifyContent="space-between" alignItems="center">
                    <GridItem>
                      <h4
                        style={{
                          color: "#8F6682",
                          fontSize: "14px",
                          height: "13px",
                        }}
                      >
                        <strong> {t("College")} </strong>

                      </h4>
                      <h4
                        style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                      >
                        <strong>
                          {program?.college_detail?.map((el) => el.name)}
                        </strong>
                      </h4>
                    </GridItem>
                    <GridItem>
                      <h4
                        style={{
                          color: "#8F6682",
                          fontSize: "14px",
                          height: "13px",
                        }}
                      >
                        <strong> {t("Department")} </strong>

                      </h4>
                      <h4
                        style={{ color: "#6C7B9D", fontSize: "14px", height: "13px" }}
                      >
                        <strong>
                          {program?.department_detail?.map((el) => el.name)}
                        </strong>
                      </h4>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>

        </CardHeader>
      </Card>

      <br></br>
      <p style={{ fontFamily: "Georgia", fontSize: "16px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>PLO</p>

      <Card>
        <CardBody>
          <p style={{ fontSize: "14px" }}>
            {plo?.name}
          </p>
          <GridContainer alignItems="baseline" justifyContent="space-around">
            <GridItem>
              <GridContainer direction="column" justifyContent="center" alignItems="center" >
                <GridItem>
                  <Avatar
                    style={{
                      height: "50px",
                      width: "50px"
                    }}
                  >
                    {plo?.achievement_courses?.toFixed(0)}%
                  </Avatar>
                </GridItem>
                <GridItem>
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {t("Achievement")}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              <GridContainer direction="column" justifyContent="center" alignItems="center" >
                <GridItem>
                  <Avatar
                    style={{
                      height: "50px",
                      width: "50px"
                    }}
                  >
                    {plo?.target}
                  </Avatar>
                </GridItem>
                <GridItem>
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {t("Target")}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              <GridContainer direction="column" justifyContent="center" alignItems="center" >
                <GridItem>
                  <Avatar
                    style={{
                      height: "50px",
                      width: "50px"
                    }}
                  >
                    {plo?.courses_count}
                  </Avatar>
                </GridItem>
                <GridItem>
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {t("Aligned Courses")}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              <GridContainer direction="column" justifyContent="center" alignItems="center" >
                <GridItem>
                  <Avatar
                    style={{
                      height: "50px",
                      width: "50px"
                    }}
                  >
                    {plo?.plo_score?.toFixed()}%
                  </Avatar>
                </GridItem>
                <GridItem>
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    {t("Score")}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <p style={{ fontFamily: "Georgia", fontSize: "16px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>PLO Indicators</p>

      <Card >
        <CardBody>
          <GridContainer>
            {plo?.ploIndicators?.map((ploIndicator) => (
              <GridItem xs={12} sm={12} md={12} key={ploIndicator.id} style={{ border: "1px solid #EEEEEE", borderRadius: 4, margin: 4 }} >
                <GridContainer alignItems="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer alignItems="center">
                      <GridItem xs={11} sm={11} md={11} >
                        <strong>{ploIndicator.code}: </strong>
                        <Tooltip
                          id="tooltip-top"
                          title={ploIndicator.name}
                          placement="bottom"
                        >
                          <span>
                            {ploIndicator.name.slice(0, 100)}...
                          </span>
                        </Tooltip>
                      </GridItem>
                      <GridItem xs={1} sm={1} md={1} >
                        <Tooltip
                          id="tooltip-top"
                          title="Edit PLO Indicator's weight"
                          placement="bottom"
                        >
                          <Button
                            round
                            justIcon
                            simple
                            onClick={() => handleOpenEditPLOIndWeightModal(ploIndicator)}
                          >
                            <EditIcon fontSize="sm" color="action" />
                          </Button>
                        </Tooltip>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer alignItems="baseline" justifyContent="space-around">
                      <GridItem>
                        <GridContainer direction="column" justifyContent="center" alignItems="center" >
                          <GridItem>
                            <Avatar
                              style={{
                                height: "50px",
                                width: "50px", backgroundColor: primaryColor[0]
                              }}
                            >
                              {ploIndicator.target}
                            </Avatar>
                          </GridItem>
                          <GridItem>
                            <p style={{ marginTop: "10px", fontSize: "12px" }}>
                              {t("Target")}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem>
                        <GridContainer direction="column" justifyContent="center" alignItems="center" >
                          <GridItem>
                            <Avatar
                              style={{
                                height: "50px",
                                width: "50px", backgroundColor: primaryColor[0]
                              }}
                            >
                              {ploIndicator.alignment_clos}
                            </Avatar>
                          </GridItem>
                          <GridItem>
                            <p style={{ marginTop: "10px", fontSize: "12px" }}>
                              {t("Aligned CLOs")}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem>
                        <GridContainer direction="column" justifyContent="center" alignItems="center" >
                          <GridItem>
                            <Avatar
                              style={{
                                height: "50px",
                                width: "50px", backgroundColor: primaryColor[0]
                              }}
                            >
                              {ploIndicator?.plo_indicators_score?.toFixed()}%
                            </Avatar>
                          </GridItem>
                          <GridItem>
                            <p style={{ marginTop: "10px", fontSize: "12px" }}>
                              {t("Score")}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem>
                        <GridContainer direction="column" justifyContent="center" alignItems="center" >
                          <GridItem>
                            <Avatar
                              style={{
                                height: "50px",
                                width: "50px", backgroundColor: primaryColor[0]
                              }}
                            >
                              {ploIndicator.weight}
                            </Avatar>
                          </GridItem>
                          <GridItem>
                            <p style={{ marginTop: "10px", fontSize: "12px" }}>
                              {t("weight")}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>

                <div style={{ height: 300, width: '100%' }}>
                  <DataGrid rows={ploIndicator.clos} columns={columns} disableRowSelectionOnClick />
                </div>

                <WeightCloModal
                  openEditCLOWeightModal={openEditCLOWeightModal}
                  handleCloseEditCLOWeightModal={handleCloseEditCLOWeightModal}
                  cloID={clo?.id}
                  clo={clo}
                  ploID={plo?.id}
                  ploIndicatorID={clo?.ploIndicator_id}
                />

              </GridItem>
            ))}
          </GridContainer>
        </CardBody>
      </Card >
      <WeightIndicatorModal
        handleCloseEditPLOIndWeightModal={handleCloseEditPLOIndWeightModal}
        openEditPLOIndWeightModal={openEditPLOIndWeightModal}
        ploIndicator={ploInd}
        ploID={plo?.id}
      />
    </>
  );
}
