import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import Button from "components/CustomButtons/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  DeleteSelectedCoursePlanning,
  fetchCourses,
} from "reduxToolkit/features/course/CoursesSlice.js";
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
// import AddCourse from "./AddCourse.jsx";
import UpdateDates from "./UpdateDates.jsx";
import UpdatePESSurveyTemplate from "./UpdatePESSurveyTemplate.jsx";
//import UpdateSESSurveyTemplate from "./UpdateSESSurveyTemplate.jsx";
import UpdatePESTargetGroups from "./UpdatePESTargetGroups.jsx";
import UpdateSESTargetGroups from "./UpdateSESTargetGroups.jsx";
import { fetchProgramById, DeleteAffectedSurveyToProgram } from "reduxToolkit/features/program/ProgramsSlice";

const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

export default function SurveyDropDown(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const handleOpenDeleteModal = () => {
    setOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setOpen(false);
  };
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleDelete = async () => {
    let templates_ids = props.selectedRows.join();

    let program_id = props.program_id;
    dispatch(DeleteAffectedSurveyToProgram({ program_id, templates_ids }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Deleted`);
        //props.refreshCourses();
        console.log("originalPromiseResult", originalPromiseResult);
        dispatch(fetchProgramById(props.program_id));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };
  console.log('!props.selectedRows.length',typeof props.selectedRows.length)
  return (
    <div>
      <Button
        // aria-describedby={id}
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
        style={{ marginRight: "50px" }}
      >
        <MoreVert />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <AddCourse
          handleDropDownButtonClose={handleClose}
          refreshCourses={props.refreshCourses}
        /> */}
        <UpdateDates
          disabled={!props.selectedRows.length}
          handleDropDownButtonClose={handleClose}
          selectedRows={props.selectedRows}
          program_id={props.program_id}
        />
        {/* <UpdatePESSurveyTemplate
          disabled={!(props.selectedRows.length===1)}
          handleDropDownButtonClose={handleClose}
          selectedRows={props.selectedRows}
          program_id={props.program_id}
        /> */}
        {/* <UpdateSESSurveyTemplate
          disabled={!props.selectedRows.length}
          handleDropDownButtonClose={handleClose}
          selectedRows={props.selectedRows}
        /> */}
        {/* <MenuItem
          disabled={!props.selectedRows.length}
          onClick={() => {
            handleClose();
          }}
        >
          <ListItemText primary="SES survey template" />
        </MenuItem> */}
        {/* <UpdatePESTargetGroups
          disabled={(!props.selectedRows.length)}
            // || (props.selectedRows.length>1)}
          handleDropDownButtonClose={handleClose}
          selectedRows={props.selectedRows}
          program_id={props.program_id}
        /> */}
        {/* <MenuItem
          disabled={!props.selectedRows.length}
          onClick={() => {
            handleClose();
          }}
        >
          <ListItemText primary="PES target groups" />
        </MenuItem> */}
        {/* <UpdateSESTargetGroups
          disabled={(!props.selectedRows.length) || (props.selectedRows.length>1)}
          handleDropDownButtonClose={handleClose}
          selectedRows={props.selectedRows}
        /> */}
        <MenuItem
          disabled={!props.selectedRows.length}
          onClick={() => {
            handleOpenDeleteModal();
            //handleDeleteClicked();
            handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDelete}
        Element_ID={props.id}
        program_id={props.program_id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
}
