import { DataGrid } from "@mui/x-data-grid";
import { Select } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";
import { Breadcrumb, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTeachingLoadList,
  updateTeachingLoad
} from "../../../../../reduxToolkit/features/TeachingLoad/TeachingLoadSlice";
import { fetchStaffById } from "../../../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../../../Components/ErrorModal";

const { Option } = Select;

export default function TeachingLoad({ match }) {

  const { id } = match.params;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  useEffect(() => {
    dispatch(fetchTeachingLoadList(id))
      .then(unwrapResult)
      .then((TeachingLoadList) => {
        console.log(`TeachingLoadList`, TeachingLoadList);
        setRows([...TeachingLoadList])
      });
  }, []);

  useEffect(() => {
    dispatch(fetchStaffById(id));
  }, []);

  const person = useSelector((state) => state.user.staff);
  const TeachingLoadList = useSelector(
    (state) => state.TeachingLoad.TeachingLoadList
  );

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onCellEditCommit = async (event) => {
    const value =
      event.field === "credit_hours" ||
        event.field === "classroom" ||
        event.field === "time_slot"
        ? event.value
        : null;
    const field = event.field;
    const updated = {
      [field]: value,
    };

    const id = event.id;
    if (value) {
      const resultAction = await dispatch(
        updateTeachingLoad({ id: id, updated: updated })
      );
      if (updateTeachingLoad.fulfilled.match(resultAction)) {
        openNotificationWithIcon("success", `Updated successfully`);
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
        }
      }
    } else {
      openNotificationWithIcon("error", `Empty Field`);
    }
  };

  return (
    <div>
      <GridItem style={{ marginBottom: "5px" }}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/teachingLoadActivities`}
            >
              {t("Teaching load")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <Card>
        <div style={{ width: "100%", height: 460, padding: "10px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            autoPageSize={true}
            columnBuffer={0}
            disableColumnMenu={true}
            onCellEditCommit={onCellEditCommit}
            showCellRightBorder
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 80]}
            pagination
          />
        </div>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}

const columns = [
  {
    field: "course_code",
    headerName: "Code",
    width: 110,
    sortable: false,
  },
  {
    field: "course_title",
    headerName: "Course",
    width: 180,
    sortable: false,
  },
  {
    field: "program_name",
    headerName: "Program",
    width: 210,
    sortable: false,
  },
  {
    field: "teaching_mode",
    headerName: "Teaching Mode",
    width: 130,
    sortable: false,
  },
  {
    field: "groups",
    headerName: "Student Group",
    width: 130,
    sortable: false,
  },
  {
    field: "number_of_students",
    headerName: "Number Of Students",
    width: 110,
    sortable: false,
  },
  {
    field: "credit_hours",
    headerName: "Credit Hours",
    width: 120,
    editable: true,
    sortable: false,
  },
  {
    field: "classroom",
    headerName: "Room",
    width: 130,
    editable: true,
    sortable: false,
  },
  {
    field: "time_slot",
    headerName: "Time Slot",
    width: 130,
    editable: true,
    sortable: false,
  },
];
