import React, { useEffect, useState } from 'react'
// @material-ui/core components
import { useDispatch, useSelector } from 'react-redux'
import { fetchCloById } from '../../../../../reduxToolkit/features/CLO/CloSpecSlice'
// @material-ui/icons

// core components
import { DataGrid } from '@mui/x-data-grid'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import { Link } from 'react-router-dom'

import { Breadcrumb } from 'antd'

import { useTranslation } from 'react-i18next'

import ErrorModal from '../../../../Components/ErrorModal'
import { fetchCodeLevelById } from 'reduxToolkit/features/CodeLevel/CodeLevelConfigSlice'
import { fetchSubCodeLevelById } from 'reduxToolkit/features/SubCodeLevel/SubCodeLevelConfigSlice'
import EditDefaultQuestion from "./EditDefaultQuestion";

export default function CourseCloDetails({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const dispatch = useDispatch()

  const [disabledStatus, setDisabledStatus] = useState(false)

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const fetchClo = () => {
    dispatch(fetchCloById(id))
        .unwrap()
        .catch((err) => {
          setHandledError(err)
          setOpenErrorModal(true)
        })
  }

  const fetchCloSpec = () => {
    try {
      dispatch(fetchCloById(id))
        .unwrap()
        .then((res) => {
          dispatch(fetchCodeLevelById(res.levelClo_id))
            .unwrap()
            .then((res) => {
            })
            .catch((err) => {
              console.log(err)
            })
          dispatch(fetchSubCodeLevelById(res.subLevelClo_id))
            .unwrap()
            .then((res) => {
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    fetchCloSpec()
    if (localStorage.getItem('CourseState') != 'Draft') {
      setDisabledStatus(true)
    }
  }, [dispatch])

  const clo = useSelector((state) => state.cloSpec.clo)
  const codeLevel = useSelector((state) => state.CodeLevel.CodeLevel)
  const subCodeLevel = useSelector((state) => state.subLevelClo.SubCodeLevel)

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/CourseSpecificationList/`}>
              {t('Courses List')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseSpecDetails/${clo &&
                clo.courseSpec_detail &&
                clo.courseSpec_detail.id &&
                clo.courseSpec_detail.id
                }`}
            >
              {clo &&
                clo.courseSpec_detail &&
                clo.courseSpec_detail.title &&
                clo.courseSpec_detail.title}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courses/${clo && clo.courseSpec_detail && clo.courseSpec_detail.id
                }/clos`}
            >
              {t('Clos List')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              style={{ color: 'orange' }}
              to={`/citrine/courseCloDetails/${clo && clo.id && clo.id}`}
            >
              {clo && clo.code && clo.code}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <GridContainer
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <GridItem xs={12} sm={12} md={3}>
                <div>{t('Course')}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                {clo &&
                  clo.courseSpec_detail &&
                  clo.courseSpec_detail.title &&
                  clo.courseSpec_detail.title}
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div>{t('CLO')}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <div> {clo && clo.title && clo.title}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div>{t('Code')}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <div> {clo && clo.code && clo.code}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div>{t('Description')}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <div>{clo && clo.description && clo.description}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div>{t('Teaching Strategy')}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <div>
                  {clo &&
                    clo.teaching_strategies_details?.map((item, index) => {
                      let isLast = index === (clo.teaching_strategies_details.length) - 1;
                      return isLast ? item : `${item}, `;
                    })}
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div>{t('Level/Sub Level')}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <div>
                  {codeLevel && codeLevel.level && codeLevel.level}
                  {subCodeLevel &&
                    subCodeLevel.level &&
                    ` / ${subCodeLevel.level}`}
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div>{t('Target')}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <div>{clo && clo.target && clo.target}</div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <EditDefaultQuestion clo={clo} courseId={id} fetchClo={fetchClo} />
      </GridItem>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </GridContainer>
  )
}
