import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
export default function SurveyTemplateConfList() {
    const { t } = useTranslation();

    const titleClasses = useTitleStyles();
    const cardClasses = useCardStyles();
    return (
        <>
            <div>
                <GridContainer
                    alignItems="center"
                    style={{
                        borderTop: `1px solid  ${grayColor[15]}`,
                        minHeight: "50px",
                        maxHeight: "50px",
                    }}
                >
                    <GridItem>
                        {/* <Link to={`/citrine/surveyTemplates`}> */}
                        <span className={titleClasses.title}>
                            {" "}
                            {t("Survey templates Configuration")}{" "}
                        </span>
                        {/* </Link> */}
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card
                            style={{ maxWidth: "380px", minWidth: "380px", height: 150 }}
                            cardHover
                        >
                            <CardHeader>
                                <GridContainer
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="baseline"
                                >
                                    <GridItem xs={10} sm={10} md={10}>
                                        <Link to={`/citrine/surveyTemplates/`}>
                                            <h4 className={cardClasses.cardProductTitle}>
                                                Surveys Templates
                                            </h4>

                                        </Link>
                                    </GridItem>


                                </GridContainer>
                            </CardHeader>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card
                            style={{ maxWidth: "380px", minWidth: "380px", height: 150 }}
                            cardHover
                        >
                            <CardHeader>
                                <GridContainer
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="baseline"
                                >
                                    <GridItem xs={10} sm={10} md={10}>
                                        <Link to={`/citrine/surveyTemplates/types/`}>
                                            <h4 className={cardClasses.cardProductTitle}>
                                                Surveys types
                                            </h4>

                                        </Link>
                                    </GridItem>


                                </GridContainer>
                            </CardHeader>
                        </Card>
                    </GridItem>
                   
                </GridContainer>
                {/* <SuperGeneralDeleteModal
delete_Element={handleDelete}
Element_ID={props.id}
open={open}
handleClose={handleCloseDeleteModal}
/> */}
            </div>
        </>
    )
}
