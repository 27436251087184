import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
// core components
import { unwrapResult } from '@reduxjs/toolkit'
import { Form, notification, Select } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import {
  addNewKPISpec,
  deleteKPISpec,
  fetchKPIsSpec,
  fetchNotAffectedKPIConfigToProgram,
} from '../../../../reduxToolkit/features/KPI/KPISpecSlice'

import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramSpecSlice'
import ErrorModal from '../../../Components/ErrorModal'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import { fetchAllKPIs } from "../../../../reduxToolkit/features/KPI/KPIProgramSlice";
import {
  fetchKPIs,
} from '../../../../reduxToolkit/features/KPI/KPIConfigSlice'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)

const KPIExcerpt = ({ KPI, disabledStatus, isEditor }) => {
  const { t } = useTranslation()

  let { programId } = useParams()



  return (
    <GridItem xs={12} sm={12} md={10}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              {/* <Link to={`/citrine/programSpec/${programId}/KPIs/${KPI.id}`} > */}
              <Typography>{KPI.name}</Typography>
              {/* </Link> */}
            </GridItem>
            {isEditor && (
              <GridItem xs={1} sm={1} md={1}>
                {!disabledStatus && (
                  <DropDownKPI programId={programId} id={KPI.id} />
                )}
              </GridItem>
            )}
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function KPISpecList() {
  const { t } = useTranslation()
  const { programId } = useParams();

  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const dispatch = useDispatch()
  const [isEditor, setIsEditor] = useState(true)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const [disabledStatus, setDisabledStatus] = useState(false)
  const [searchFiltred, setSearchFiltred] = useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }


  const fetchKPIs = async () => {
    try {
      const resultAction = await dispatch(fetchKPIsSpec(programId))
        // const resultAction = await dispatch(fetchAllKPIs())
        .unwrap()
        .then((originalPromiseResult) => {
          // handle result here
        })
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  useEffect(() => {
    dispatch(fetchProgramById(programId))
    // fetchKPIsByProgram()
    fetchKPIs()
    if (localStorage.getItem('state') != 'Draft') {
      setDisabledStatus(true)
    }
  }, [])

  const program = useSelector((state) => state.programSpec.program)
  const { KPIs, KPIsStatus } = useSelector((state) => state.KPISpec)

  // const { AllKPIs, KPIsStatus } = useSelector((state) => state.KPIProgram)
  //  const { KPIs, KPIsStatus } = useSelector((state) => state.KPISpec)
  const filteredContent = KPIs?.filter((KPI) => {
    return searchFiltred.toLowerCase() === ''
      ? KPI
      : KPI?.name?.toLowerCase()?.includes(searchFiltred) ||
      KPI?.code?.toLowerCase()?.includes(searchFiltred)
  })
  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  let content

  if (KPIsStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (KPIsStatus === 'succeeded') {
    if (filteredContent && filteredContent.length) {
      content = filteredContent.map((KPI) => (
        <KPIExcerpt
          key={KPI.id}
          KPI={KPI}
          disabledStatus={disabledStatus}
          isEditor={isEditor}
        />
      ))
    } else {
      content = <h5>{t('This Program has no KPIs to display!')}</h5>
    }
  }

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t('Programs')} </span>
          </Link>
          <Link
            to={`/citrine/programSpec/${program && program.id && program.id}`}
          >
            <span className={titleClasses.title}>
              {' '}
              {`> ${program &&
                program.program_degree_name &&
                program.program_degree_name
                }`}{' '}
            </span>
          </Link>
          <span className={titleClasses.title}>{t(`> KPIs`)} </span>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ justifyContent: 'space-between' }}>
        <GridItem>
          {isEditor && (
            <GridItem>
              {!disabledStatus && <KPIModal programSpec_id={programId} />}
            </GridItem>
          )}
        </GridItem>

        <GridItem>
          <input
            placeholder="Search"
            style={{
              marginRight: '220px',
              backgroundColor: '#eeeeee',
              border: 'none',
              borderBottom: '1px solid #BDBDBD',
              marginTop: '10px',
            }}
            type="text"
            onChange={(e) => setSearchFiltred(e.target.value)}
            value={searchFiltred}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        {content}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

function KPIModal({ programSpec_id }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    //    dispatch(fetchNotAffectedKPIConfigToProgram(programSpec_id))
    // dispatch(fetchAllKPIs())
    dispatch(fetchKPIs())

  }, [])

  const filteredKPIconfig = useSelector(
    // (state) => state.KPISpec.filteredKPIconfig,
    // (state) => state.KPIProgram.AllKPIs,
    (state) => state.KPIConfig.KPIs,

  )?.filter(item => item.kpiCategoryConfig_detail.includes("Program"))

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const { Option } = Select

  const [kpiConfig_ids, setkpiConfig_id] = React.useState([])

  function handleChange(value) {
    console.log('value', value)
    setkpiConfig_id(value)
  }

  const onFinish = async () => {
    const resultAction = await dispatch(
      addNewKPISpec({ programSpec_id, kpiConfig_ids }),
    )
    if (addNewKPISpec.fulfilled.match(resultAction)) {
      dispatch(fetchKPIsSpec(programSpec_id))
      //dispatch(fetchNotAffectedKPIConfigToProgram(programSpec_id))
      // dispatch(fetchAllKPIs())
      history.push(`/citrine/programSpec/${programSpec_id}/KPIs`)
      openNotificationWithIcon('success', `Affected successfully`)
      handleClose()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        handleClose()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        handleClose()
      }
    }
  }

  return (
    <div>
      <Button
        color="primary"
        onClick={(event) => {
          handleClickOpen(event)
        }}
      >
        Add KPI
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{t('Add KPI')}</DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t('KPI')}
                  name="kpiConfig_id"
                  rules={[{ required: true, message: 'Please select one KPI' }]}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ zIndex: 5 }}
                    dropdownStyle={{ zIndex: 2000 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChange}
                  >
                    {filteredKPIconfig &&
                      filteredKPIconfig.length &&
                      filteredKPIconfig.map((KPI) => (
                        <Option key={KPI.id} value={KPI.id}>
                          {`${KPI.code}`} {'/'} {`${KPI.description}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        {t('Cancel')}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="primary">
                        {t('Save')}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))
const DropDownKPI = ({ id, programId }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const handleOpenDeleteModal = () => {
    setOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setOpen(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const ids = { kpiConfig_id: id, programSpec_id: parseInt(programId) }
  const handleDeleteItem = async (ids) => {
    dispatch(deleteKPISpec(ids))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(fetchKPIsSpec(programId))
        //dispatch(fetchNotAffectedKPIConfigToProgram(programId))
        // dispatch(fetchAllKPIs())
        openNotificationWithIcon('success', `Deleted`)
      })
      .catch((rejectedValueOrSerializedError) => {
        setHandledError(rejectedValueOrSerializedError)
        handleOpenErrorModal()
      })
  }
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal()
            handleClose()
          }}
        >
          <ListItemText primary="Delete" />{' '}
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={ids}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}
