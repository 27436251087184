import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Button from "components/CustomButtons/Button.js";
import React from "react";
// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";



import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { deleteSpecificationAprovalData, fetchSpecificationAprovalDatas } from '../../reduxToolkit/features/SpecificationAprovalData/SpecificationAprovalDataSlice';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);
const useButtonStyles = makeStyles(buttonStyles);


export default function DropDownButtonSepcificationApproval(props) {
    const { t } = useTranslation();
    const buttonClasses = useButtonStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };
    const dispatch = useDispatch();
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        console.log("res")
        console.log(props.idCourse)
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const classes = useStyles();



    const deleteSpecificationAproval = (id) => {
        dispatch(deleteSpecificationAprovalData(id))
        dispatch(fetchSpecificationAprovalDatas(props.courseID))
    }

    return (

        <span>
            <Button
                color="info"
                round
                ref={anchorRef}
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
            >
                <MoreVert />
            </Button>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" >
                                    <MenuItem >
                                        <Link to={`/citrine/UpdateSpecificationAprovalData/${props.idCourse}/${props.SepcificationApproval_ID}`}>
                                            {t("Edit")}
                                        </Link>

                                    </MenuItem>

                                    <MenuItem>
                                        <Link >
                                            <p onClick={() => deleteSpecificationAproval(props.SepcificationApproval_ID)}>      {t("Delete")}</p>
                                        </Link>
                                    </MenuItem>

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}

            </Popper>


        </span>
    );
}