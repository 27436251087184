import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DropDownAssessmentMethodButton from 'views/Components/DropDownAssessmentMethodButton'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import {
  deleteAssessmentMethod,
  fetchAssessmentMethods,
  getAssessmentId,
  selectAllAssessmentMethods,
} from '../../../../reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const AssessmentMethodExcerpt = ({ assessmentMethod }) => {
  const { t } = useTranslation()
  //console.log(assessmentMethod)
  const classes = useStyles()
  const dispatch = useDispatch()
  const sendId = (id) => {
    dispatch(getAssessmentId(id))
  }
  const deleteUniv = (id) => {
    dispatch(deleteAssessmentMethod(id))
  }

  return (
    <GridContainer
      md={6}
      xs={12}
      sm={12}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <GridItem key={assessmentMethod.id}>
        <Card product className={classes.cardHover}>
          <CardBody style={{ width: 375, height: 200 }}>
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={12} md={10}>
                <h4 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <Link
                      to={`/citrine/assessmentMethodDetails/${
                        assessmentMethod && assessmentMethod.id
                      }/${assessmentMethod.name}`}
                    >
                      {' '}
                      {assessmentMethod && assessmentMethod.name}
                    </Link>
                  </a>
                </h4>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <DropDownAssessmentMethodButton id={assessmentMethod.id} />
              </GridItem>
            </GridContainer>
            <React.Fragment style={{marginTop:'-50px'}}>
            <h5>
              {assessmentMethod &&
                assessmentMethod.mode_spec_details &&
                assessmentMethod.mode_spec_details.name &&
                `Assessment mode: ${assessmentMethod.mode_spec_details.name}`}
            </h5>
            <h5>
              {assessmentMethod &&
                assessmentMethod.defaultQuestionNumber &&
                `Default question number: ${assessmentMethod.defaultQuestionNumber}`}
            </h5>
            <h5>
              {assessmentMethod &&
                assessmentMethod.defaultQuestionCode &&
                `Default question code: ${assessmentMethod.defaultQuestionCode}`}
            </h5>
            </React.Fragment>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default function AssessmentMethodsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const assessmentMethodsStatus = useSelector(
    (state) => state.assessmentMethod.assessmentMethodsStatus,
  )
  console.log('status assessmentMethodsStatus', assessmentMethodsStatus)

  useEffect(() => {
    if (assessmentMethodsStatus === 'idle') {
      dispatch(fetchAssessmentMethods())
    }
  }, [assessmentMethodsStatus, dispatch])

  const assessmentMethods = useSelector(selectAllAssessmentMethods)
  console.log('*******subareas', assessmentMethods)
  const error = useSelector((state) => state.assessmentMethod.error)

  let content

  if (assessmentMethodsStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (assessmentMethodsStatus === 'succeeded') {
    content =
      assessmentMethods &&
      assessmentMethods.map((assessmentMethod) => (
        <AssessmentMethodExcerpt
          key={assessmentMethod.id}
          assessmentMethod={assessmentMethod}
        />
      ))
  } else if (assessmentMethodsStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                {' '}
                {t('Course Configurations')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/assessmentMethods`}
                style={{ color: 'orange' }}
              >
                {' '}
                {t('Assessment Method List')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Link to="/citrine/createAssessmentMethod/:id">
        <Button color="info">{t('  Add')}</Button>
      </Link>

      <GridContainer>{content}</GridContainer>
    </div>
  )
}
