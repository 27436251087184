// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Badge, Breadcrumb } from "antd";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
//import Card from '@material-ui/core/Card';
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
// react plugin for creating charts
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CourseLogo from "../../../../assets/img/CourseLogo.png";
import NoDataFound from "../../../../assets/img/NoDataFound.png";
import { SchoolRounded } from "@material-ui/icons";
//chartist
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import {
    fetchInstructorCourseAssessmentGroup
} from "../../../../reduxToolkit/features/CourseInstructor/CourseInstructorSlice";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardIcon from "components/Card/CardIcon.js";
import { unwrapResult } from "@reduxjs/toolkit";
import DropDownButton from "../../../Components/DropDownButtonGoToAssessment";
import DropDownButtonUserView from "views/Components/DropDownButtonUserView";
import { Tabs } from 'antd';
import Code from "components/Typography/Code.js";
import Info from "components/Typography/Info.js";
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
// import MoreVert from '@material-ui/icons/MoreVert'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { notification } from 'antd'
import Button from "components/CustomButtons/Button.js";
import {
    primaryColor,
    warningColor,
    whiteColor,
    greyColor
} from "assets/jss/material-dashboard-pro-react.js";
import AvatarsGroup from "components/Avatar/AvatarsGroup"
import Avatar from "components/Avatar/Avatar"
import { SlCalender } from "react-icons/sl";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import SuperGeneralDeleteModal from "views/Components/SuperGeneralDeleteModal";
import ErrorModal from "views/Components/ErrorModal";
import { DataGrid, GridRowEditStopReasons } from '@mui/x-data-grid';
import EditQuestion from 'views/Components/EditQuestionModal_Instructor'
import {
    deleteAssessmentMethodQuestions,
} from 'reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
import AddQuestion from 'views/Components/AddQuestionModal_instructor'
import StudentDetailsModal from './StudentDetails'
export default function InstructorCourseAssessmentGroupConfigPage() {

    // ==========================================================================================
    // states
    const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false)
    const [openErrorModal, setOpenErrorModal] = React.useState(false)
    const [handledError, setHandledError] = React.useState('')
    const [rows, setRows] = React.useState();
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState();
    const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);
    const [addModalIsOpen, setAddModalIsOpen] = React.useState(false);
    const [studenModalIsOpen, setStudenModalIsOpen] = React.useState(false)

    //
    // ==========================================================================================

    // ==========================================================================================
    // hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let { id } = useParams()

    //
    // ==========================================================================================

    // ==========================================================================================
    // refs
    //
    // ==========================================================================================

    // ==========================================================================================
    // constants


    //
    // ==========================================================================================

    // ==========================================================================================
    // useEffects
    useEffect(() => {

        dispatch(fetchInstructorCourseAssessmentGroup(id));

    }, []);
    //
    // ==========================================================================================

    // ==========================================================================================
    // redux
    const data = useSelector((state) => state.CourseInstructor.instructorCourseAssessmentGroup);
    const instructorCourseAssessmentGroupStatus = useSelector((state) => state.CourseInstructor.instructorCourseAssessmentGroupStatus);

    //
    // ==========================================================================================

    // ==========================================================================================
    // event handlers

    const onChange = (key) => {
        console.log(key);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true)
    }
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false)
    }
    const handleCloseStudentModal = () => {
        setStudenModalIsOpen(false)
    }
    const handleOpenDeleteModal = () => {
        setDeleteModalIsOpen(true)
    }
    const handleCloseDeleteModal = () => {
        setDeleteModalIsOpen(false)
        setSelectionModel([])
        setSelectedRow(null)
    }

    const handleOpenEditModal = () => {
        setEditModalIsOpen(true);
    };

    const handleCloseEditModal = () => {
        setEditModalIsOpen(false);
        setSelectionModel([])
        setSelectedRow(null)
    };

    const handleOpenAddModal = () => {
        setAddModalIsOpen(true);
    };

    const handleCloseAddModal = () => {
        setAddModalIsOpen(false);
        setSelectionModel([])
        setSelectedRow(null)
    };
    //
    // ==========================================================================================

    // ==========================================================================================
    // functions
    const fn2 = (value) => {

    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        })
    }

    const deleteQuestion = async () => {
        console.log(selectionModel[0])
        try {
            const resultAction = await dispatch(
                deleteAssessmentMethodQuestions({ questions_delete_ids: selectionModel.toString() }),
            )
            unwrapResult(resultAction)
            handleCloseDeleteModal()
            openNotificationWithIcon('success', `Deleted`)
            dispatch(fetchInstructorCourseAssessmentGroup(id));
        } catch (err) {
            setHandledError(err)
            handleOpenErrorModal()
        }
    }

    //
    // ==========================================================================================

    // ==========================================================================================
    // conditional rendering

    const groupColumns = [
        {
            field: 'avatar',
            headerName: 'Avatar',
            renderCell: (params) => <Avatar url={params.value} />,
            width: 100,
        },
        {
            field: 'student_identification',
            headerName: 'Student identification',
            renderCell: (params) => (
                <Button simple
                    // link
                    color='info'
                    size='lg'
                // onClick={() => setStudenModalIsOpen(true)}
                >
                    {params.value}
                </Button>
            ),
            width: 200
        },
        {
            field: 'first_name',
            headerName: 'First name',
            width: 200
        },
        {
            field: 'last_name',
            headerName: 'Last name',
            width: 200,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },

    ];

    let questionsTabContent;
    let groupTabContent;

    if (instructorCourseAssessmentGroupStatus === 'loading') {
        questionsTabContent = <div>Loading...</div>
        groupTabContent = <div>Loading...</div>
    } else if (instructorCourseAssessmentGroupStatus === 'succeeded') {
        if (data === "You do not have permission") {
            questionsTabContent = <div>You do not have permission to access the questions list</div>;
            groupTabContent = <div>You do not have permission to access the students list</div>;

        }
        else {


            if (data) {
                questionsTabContent = <div style={{ height: 500, width: '100%' }}>
                    <GridContainer justifyContent="flex-end">
                        <GridItem>
                            <CustomDropdown
                                isButtonIcon
                                dropdownList={[
                                    { text: "Add", onClickHandler() { handleOpenAddModal() } },
                                    { text: "Edit", onClickHandler() { handleOpenEditModal() }, disabled: selectionModel.length !== 1 },
                                    { text: "Delete", onClickHandler() { handleOpenDeleteModal() }, disabled: selectionModel.length == 0 },
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                    <DataGrid
                        editMode="row"
                        rows={data.questions.length ? data.questions : []}
                        columns={questionsColumns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectionModel(newSelectionModel);
                        // }}
                        onSelectionModelChange={(newSelectionModel) => {
                            console.log(newSelectionModel)
                            setSelectionModel(newSelectionModel);
                            setSelectedRow(data.questions.find(element => element.id == newSelectionModel[0]))

                        }}
                        selectionModel={selectionModel}
                    // experimentalFeatures={{ newEditingApi: true }}
                    // onRowEditStop={(params, event) => {
                    //     if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                    //         event.defaultMuiPrevented = true;
                    //     }
                    //     console.log(params)
                    // }}
                    // processRowUpdate={processRowUpdate}

                    />
                </div>



                groupTabContent =
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            // editMode="row"
                            rows={data.group_details.students.length ? data.group_details.students : []}
                            columns={groupColumns}
                            // checkboxSelection
                            disableRowSelectionOnClick
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectionModel(newSelectionModel);
                        // }}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectionModel(newSelectionModel);
                        //     setSelectedRow(data.questions.find(element => element.id == newSelectionModel[0]))

                        // }}
                        // selectionModel={selectionModel}
                        // experimentalFeatures={{ newEditingApi: true }}
                        // onRowEditStop={(params, event) => {
                        //     if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                        //         event.defaultMuiPrevented = true;
                        //     }
                        //     console.log(params)
                        // }}
                        // processRowUpdate={processRowUpdate}

                        />
                    </div>

            } else {
                questionsTabContent = <div>No assessment Method is found.</div>;
                groupTabContent = <div>No assessment Method is found.</div>;
            }
        }
    }
    const items = [
        {
            key: '1',
            label: `Question`,
            children: questionsTabContent
        },
        {
            key: '2',
            label: `Group`,
            children: groupTabContent
        },

    ];
    //
    // ==========================================================================================

    // ==========================================================================================
    // console logs
    console.log("selectionModel", selectionModel)
    console.log("selectedRow", selectedRow)

    //
    // ==========================================================================================
    return (
        <>
            {/* <Card withPadding withScroll style={{height:"500px"}}> */}


            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to="/citrine/faculty/CoursesAssessmentsGroups/">

                        Courses’ Assessments’ Groups
                    </Link>

                </Breadcrumb.Item>
                <Breadcrumb.Item>

                    <Link to={`/citrine/faculty/coursesAssessmentsGroups/${id}/config`}>
                        {`${data?.assessmentMethod_details?.name} ${data?.course_details?.code}`}
                    </Link>

                </Breadcrumb.Item>

                <Breadcrumb.Item>

                    <Link to={`/citrine/faculty/coursesAssessmentsGroups/${id}/config`}>
                        {` ${data?.group_details?.group_name}`}
                    </Link>

                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    Config
                </Breadcrumb.Item>

            </Breadcrumb>

            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />


            {/* </Card> */}
            <StudentDetailsModal
                open={studenModalIsOpen}
                handleClose={handleCloseStudentModal}
            />
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
            <SuperGeneralDeleteModal
                delete_Element={deleteQuestion}
                //  Element_ID={id}
                open={deleteModalIsOpen}
                handleClose={handleCloseDeleteModal}
            />
            <EditQuestion
                editModalIsOpen={editModalIsOpen}
                course_id={data?.course_id}
                assessmentMethod_id={data?.assessmentMethod_id}
                //   disabled={disabled}
                id={id}
                selectedRowData={selectedRow}
                handleCloseEditModal={handleCloseEditModal}
            />
            <AddQuestion
                addModalIsOpen={addModalIsOpen}
                course_id={data?.course_id}
                assessmentMethod_id={data?.assessmentMethod_id}
                id={id}
                handleCloseAddModal={handleCloseAddModal}
            />
        </>
    )
}
const questionsColumns = [
    {
        field: 'question',
        headerName: 'Question',
        width: 200
    },
    {
        field: 'actual_weight',
        headerName: 'Actual weight',
        type: 'number',
        width: 100
    },
    {
        field: 'correction_mark',
        headerName: 'Correction mark',
        type: 'number',
        width: 100,
    },
    {
        field: 'clo_code',
        headerName: 'Clo',
        // width: 500,
    },
];
