import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { notification, Table } from 'antd'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { grayColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { DeleteCLOSpecWeight } from '../../../../reduxToolkit/features/CLO/WeightCloSpecSlice'
import { fetchPloById } from '../../../../reduxToolkit/features/PLO/PLOSpecSlice'
import { fetchPloIndicatorById } from '../../../../reduxToolkit/features/PLOIndicator/PLOIndicatorSpecSlice'
import { fetchProgramById } from '../../../../reduxToolkit/features/program/ProgramSpecSlice'
import ErrorModal from '../../../Components/ErrorModal'
import PLOIndicatorDropDownButton from '../../../Components/PLOIndicatorDropDownButton'
import SuperGeneralDeleteModal from '../../../Components/SuperGeneralDeleteModal'
import Stack from "@mui/material/Stack";

const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const PLOIndicatorExcerpt = (props) => {
    const dispatch = useDispatch()

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Level',
            dataIndex: 'levelClo',
            key: 'levelClo',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => (
                <Button
                    disabled={props.program?.state === 'Approved' || props.program?.state === 'Archived'}
                    onClick={() => {
                        handleClickOpen(record.id)
                    }}
                    color="primary"
                    simple
                >
                    Delete
                </Button>
            ),
        },
    ]

    const data = props.ploInd.closSpec

    const [toDeleteCLOId, setToDeleteCLOId] = useState(null)
    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [handledError, setHandledError] = useState('')

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true)
    }

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false)
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = (id) => {
        setToDeleteCLOId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const ids = {
        cloSpec_id: toDeleteCLOId,
        PLOISpec_id: props.ploInd.id,
    }

    const handleDeleteCLO = async (ids) => {
        dispatch(DeleteCLOSpecWeight(ids))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                // handle result here
                dispatch(fetchPloIndicatorById(props.ploInd.id))
                openNotificationWithIcon('success', `Deleted`)
            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                console.log(
                    'rejectedValueOrSerializedError',
                    rejectedValueOrSerializedError,
                )
                setHandledError(rejectedValueOrSerializedError)
                handleOpenErrorModal()
            })
    }

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        })
    }

    return (
        <GridItem
            xs={12}
            sm={12}
            md={12}
        // key={program.id}
        >
            <Card>
                <CardBody>
                    <GridContainer justifyContent="flex-end">
                        <GridItem>
                            {!props.disabledStatus && (
                                <PLOIndicatorDropDownButton
                                    ploInd={props.ploInd}
                                    PLOId={props.PLOId}
                                    programId={props.programId}
                                />
                            )}
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Table columns={columns} dataSource={data} />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>

            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
            <SuperGeneralDeleteModal
                delete_Element={handleDeleteCLO}
                Element_ID={ids}
                open={open}
                handleClose={handleClose}
            />
        </GridItem>
    )
}

export default function PLOIndicator() {
    const { t } = useTranslation()
    const titleClasses = useTitleStyles()
    const cardClasses = useCardStyles()

    let { PLOId, programId, PLOIndId } = useParams()

    const dispatch = useDispatch()

    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [handledError, setHandledError] = useState('')

    const [disabledStatus, setDisabledStatus] = useState(false)

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true)
    }
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false)
    }

    const fetchPLOIndicator = () => {
        try {
            dispatch(fetchPloIndicatorById(PLOIndId))
                .unwrap()
                .then((ploInd) => {
                })
                .catch((err) => console.log(err))

        } catch (err) {
            setHandledError(err)
            handleOpenErrorModal()
        }
    }

    useEffect(() => {
        fetchPLOIndicator()
        dispatch(fetchProgramById(programId))
        dispatch(fetchPloById(PLOId))
        if (localStorage.getItem('state') != 'Draft') {
            setDisabledStatus(true)
        }
    }, [dispatch])

    const ploIndicatorStatus = useSelector((state) => state.ploIndicatorSpec.ploIndicatorStatus)
    const ploIndicator = useSelector((state) => state.ploIndicatorSpec.ploIndicator)
    const program = useSelector((state) => state.programSpec.program)
    const plo = useSelector((state) => state.ploSpec.plo)

    let content

    if (ploIndicatorStatus === 'loading') {
        content = <div className="loader">{t('Loading...')}</div>
    } else if (ploIndicatorStatus === 'succeeded') {
        if (ploIndicator) {
            content =
                <PLOIndicatorExcerpt
                    ploInd={ploIndicator}
                    PLOId={PLOId}
                    program={program}
                    programId={programId}
                    disabledStatus={disabledStatus}
                />
        }
    }

    return (
        <div>
            <GridContainer
                style={{
                    borderBottom: `1px solid  ${grayColor[15]}`,
                    borderTop: `1px solid  ${grayColor[15]}`,
                }}
                justify="space-between"
                alignItems="center"
            >
                <GridItem>
                    <Link to={`/citrine/programSpec`}>
                        <span className={titleClasses.title}> {t('Programs')} </span>
                    </Link>
                    <Link to={`/citrine/programSpec/${program && program.id}`}>
                        <span className={titleClasses.title}>
                            {' '}
                            {`> ${program && program.program_degree_name}`}{' '}
                        </span>
                    </Link>
                    <Link to={`/citrine/programSpec/${program && program.id}/PLOs`}>
                        <span className={titleClasses.title}>{t(`> PLOs`)} </span>
                    </Link>
                    <Link
                        to={`/citrine/programSpec/${program && program.id}/PLOs/${plo && plo.id
                            }/PLOIndicators`}
                    >
                        <span className={titleClasses.title}>
                            {`> ${plo && plo.code} `}{' '}
                        </span>
                    </Link>
                    <span className={titleClasses.title}> {t(`> PLO Indicators`)} </span>
                    <Link
                        to={`/citrine/programSpec/${program && program.id}/PLOs/${plo && plo.id
                            }/PLOIndicators/${ploIndicator && ploIndicator.id
                            }`}
                    >
                        <span className={titleClasses.title}>
                            {`> ${ploIndicator && ploIndicator.code} `}{' '}
                        </span>
                    </Link>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Stack direction="row" spacing={2} alignItems={'center'}>
                                        <h5 className={cardClasses.cardProductTitle}>
                                            {ploIndicator &&
                                                ploIndicator.name &&
                                                ploIndicator.code &&
                                                `${ploIndicator.name} (${ploIndicator.code})`}
                                        </h5>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>Target: </span>
                                            <span>{ploIndicator?.target}</span>
                                        </div>
                                        <div>
                                            <span style={{fontWeight: "bold"}}>Weight: </span>
                                            <span>{ploIndicator?.weight}</span>
                                        </div>
                                    </Stack>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
                    {content}
                </GridItem>
            </GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    )
}
