import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from '@material-ui/core/MenuItem';
import { Form, Input, notification, Select } from "antd";
import { MediaBaseUrl } from "assets/utils/accessToken";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import {
  updateActionRecommendation
} from "../../../reduxToolkit/features/ActionRecommendation/ActionRecommendationSlice";
import ErrorModal from "../../Components/ErrorModal";
const { Option } = Select;
const { TextArea } = Input;
export default function ReAssign(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);

  const handleKeyDown = (event, ref) => {
    if (event.key === 'Tab' && ref && ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    dispatch(fetchStaff());
    form.setFieldsValue({
      assign_to_id: props.assign_to_id
    })
  }, []);
  const users = useSelector((state) => state.user.staffList);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    const updatedActionRecommendation = { ...values, state_id: 2 }

    console.log("values: ", values);

    const resultAction = await dispatch(
      updateActionRecommendation({
        id: props.id,
        updatedActionRecommendation: updatedActionRecommendation,
      })
    );

    if (updateActionRecommendation.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon("success", `Updated`);
      props.fetchAllActions();
      handleClose();
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };



  return (
    <div>
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
          props.handleClose();
        }}
        style={{ color: "#AC79B0" }}
      >
        {t("Re-Assign")}
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {t("Re-Assign")}
        </DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            form={form}

          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Assignee")}
                  name="assign_to_id"
                  rules={[
                    {
                      required: true,
                      message: "Please input the program's code",
                    },
                  ]}
                  
                >
                  <Select
                    placeholder="Please select a user"
                    style={{ width: 540 }}
                    dropdownStyle={{ zIndex: 10000 }}
                    ref={input1Ref}
                    onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                  >
                    {users && users.map((user) => {
                      return (
                        <Option key={user.id} value={user.id}>
                          {!user?.person_details[0]?.avatar ? (<span style={{
                            backgroundColor: 'rgb(143, 102, 130)',
                            width: '23px',
                            height: '23px',
                            color: 'white',
                            borderRadius: '50%',
                            paddingLeft: '7px',
                            marginRight: '15px',
                            display: 'inline-block',
                            lineHeight: '23px'
                          }}>{user?.person_details[0]?.first_name.charAt(0).toUpperCase() + '     '}</span>) : (<img style={{ width: '20px', height: "20px" }} src={`${MediaBaseUrl}${user?.person_details[0]?.avatar}`} />)}
                          {user.person_details && user.person_details[0].first_name} {""} {user.person_details && user.person_details[0].last_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Reasons")}
                  name="re_assign_reasons"
                  onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                >
                  <TextArea  ref={input2Ref}/>
                </Form.Item>
              </GridItem>
            </GridContainer>

            {/* </FormControl> */}
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="primary">
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
