import React, { useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer.js'
import { fetchActionTypes } from '../../../../reduxToolkit/features/actionType/ActionTypeSlice.js'
import { Breadcrumb } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GridItem from 'components/Grid/GridItem.js'
import Button from '../../../../components/CustomButtons/Button'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import ActionTypeDropDown from './ActionTypeDropDown.jsx'
const useCardStyles = makeStyles(cardStyles)

export default function ActionTypePage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchActionTypes())
  }, [dispatch])
  const { actionTypes } = useSelector((state) => state.ActionType)
  console.log(actionTypes)
  const cardClasses = useCardStyles()
  return (
    <div>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others`}>
                <span> {t('other Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/others/actiontypepage`}>
                <span> {t('actionTypePage')} </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Link to={`/citrine/configuration/others/addActionTypeConfig`}>
        <Button color="info">{t('Add')}</Button>
      </Link>

      <GridContainer>
        {actionTypes &&
          actionTypes.map((el) => (
            <GridItem xs={12} sm={12} md={4} key={el.id}>
              <Card
                style={{ maxHeight: '200px', minHeight: '200px' }}
                cardHover
              >
                <CardHeader>
                  <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <GridItem xs={12} sm={12} md={10}>
                      <h4 className={cardClasses.cardProductTitle}>
                        {`${el?.name}: ${el?.code}`}
                      </h4>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                      <ActionTypeDropDown actionTypes={el} />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                <h5 className={cardClasses.cardProductTitle}>{el.version_type && `version: ${el.version_type}`}</h5>
                <h5 className={cardClasses.cardProductTitle}>{el.category && `category: ${el.category}`}</h5>

                  {/* <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <GridItem xs={12} sm={12} md={10}>
                      <h4 className={cardClasses.cardProductTitle}>
                        {`${el?.name}: ${el?.code}`}
                      </h4>
                    </GridItem>

                    <GridItem xs={2} sm={2} md={2}>
                      <ActionTypeDropDown actionTypes={el} />
                    </GridItem>
                  </GridContainer> */}
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  )
}
