import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  ploReportDocuments: [],
  ploReportDocumentsStatus: 'idle',
  ploReportDocument: null,
  ploReportDocumentStatus: 'idle',
  error: null,
}

export const fetchPLOReportDocuments = createAsyncThunk(
  'ploReportDocument/fetchPLOReportDocuments',
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + '/htmlTemplatePLOReport/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      console.log(response.data, 'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPLOReportDocumentsByProgramId = createAsyncThunk(
  'ploReportDocument/fetchPLOReportDocumentsByProgramId',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/htmlTemplatePLOReport/by_program/?program_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const fetchPLOReportDocumentById = createAsyncThunk(
  'ploReportDocument/fetchPLOReportDocumentById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/htmlTemplatePLOReport/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      // console.log(response.data,'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const addNewPLOReportDocument = createAsyncThunk(
  'ploReportDocument/addNewPLOReportDocument',
  async (initialPLOReportDocument, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/htmlTemplatePLOReport/create/`,
        initialPLOReportDocument,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const updatePLOReportDocument = createAsyncThunk(
  'ploReportDocument/updatePLOReportDocument',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(
        BaseUrl + `/htmlTemplatePLOReport/${data.id}/edit/`,
        data.updatedPLOReportDocument,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      console.log(response.data, 'response.data')
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

export const deletePLOReportDocument = createAsyncThunk(
  'ploReportDocument/deletePLOReportDocument',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/htmlTemplatePLOReport/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      // console.log(response.data,'response.data')

      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  },
)

const ploReportDocumentsSlice = createSlice({
  name: ' ploReportDocument',
  initialState,
  extraReducers: {
    [fetchPLOReportDocuments.pending]: (state, action) => {
      state.ploReportDocumentsStatus = 'loading'
    },
    [fetchPLOReportDocuments.fulfilled]: (state, action) => {
      state.ploReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ploReportDocuments = action.payload
    },
    [fetchPLOReportDocuments.rejected]: (state, action) => {
      state.ploReportDocumentsStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },



    [fetchPLOReportDocumentsByProgramId.pending]: (state, action) => {
      state.ploReportDocumentsStatus = 'loading'
    },
    [fetchPLOReportDocumentsByProgramId.fulfilled]: (state, action) => {
      state.ploReportDocumentsStatus = 'succeeded'
      // Add any fetched posts to the array
      state.ploReportDocuments = action.payload
    },
    [fetchPLOReportDocumentsByProgramId.rejected]: (state, action) => {
      state.ploReportDocumentsStatus = 'failed'
      state.error = action.payload
    },

    [fetchPLOReportDocumentById.pending]: (state, action) => {
      state.ploReportDocumentStatus = 'loading'
    },
    [fetchPLOReportDocumentById.fulfilled]: (state, action) => {
      state.ploReportDocumentStatus = 'succeeded'

      // Add any fetched posts to the array
      state.ploReportDocuments = action.payload
    },
    [fetchPLOReportDocumentById.rejected]: (state, action) => {
      state.ploReportDocumentStatus = 'failed'
      state.error = action.payload
    },
    [addNewPLOReportDocument.fulfilled]: (state, action) => {
      //   state.HTMLTemplates.push(action.payload)
      console.log('PLOReportDocument add action.payload', action.payload)
    },
    [deletePLOReportDocument.fulfilled]: (state, action) => {
      // state.HTMLTemplates.push(action.payload)
      console.log('PLOReportDocument delete action.payload', action.payload)
    },
    [updatePLOReportDocument.fulfilled]: (state, action) => {
      //   const result = state.HTMLTemplates.filter(HTMLTemplate => HTMLTemplate.id !== action.payload.id);
      //   // console.log("HTMLTemplate id from filter from slice",HTMLTemplate.id)
      //   result.push(action.payload)
      //   state.HTMLTemplate = result
      console.log('PLOReportDocument update action.payload', action.payload)
    },
    [addNewPLOReportDocument.rejected]: (state, action) => {
      state.ploReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [deletePLOReportDocument.rejected]: (state, action) => {
      state.ploReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updatePLOReportDocument.rejected]: (state, action) => {
      state.ploReportDocumentStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default ploReportDocumentsSlice.reducer
