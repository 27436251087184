import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, Radio, Select, notification } from 'antd'
import buttonStyles from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchApprovalLevels, updateApprovalLevel } from '../../../../reduxToolkit/features/Configuration/ApprovalLevel/ApprovalLevelSlice'

import { unwrapResult } from '@reduxjs/toolkit'
import ErrorModal from 'views/Components/ErrorModal'


const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 18,
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 12,
        span: 0,
    },
}
export default function UpdateApprovalLevel({
    updateModalIsOpen,
    setUpdateModalIsOpen,
    toBeUpdated,
    handleOpenErrorModal,
    setHandledError
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [form] = Form.useForm()


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        })
    }
    const onFinish = async (values) => {
        try {
            const resultAction = await dispatch(
                updateApprovalLevel({ ...values, id: toBeUpdated.id }),
            )
            unwrapResult(resultAction)
            openNotificationWithIcon('success', `update successfully`)
            setUpdateModalIsOpen(false)
            dispatch(fetchApprovalLevels())
        } catch (err) {
            setHandledError(err)
            handleOpenErrorModal()
        }
    }
    useEffect(() => {
        form.setFieldsValue({
            name: toBeUpdated?.name,
            code: toBeUpdated?.code,
        })
    }, [toBeUpdated])
    return (
        <div>
            <Dialog
                open={updateModalIsOpen}
                onClose={() => { setUpdateModalIsOpen(false) }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <center> {t('Update Approval Level')}</center>
                </DialogTitle>
                <DialogContent style={{ width: '600px' }}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Form
                                {...layout}
                                form={form}
                                name="control-hooks"
                                onFinish={onFinish}

                            >
                                <br />

                                <Form.Item
                                    name="code"
                                    label="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the Code.',
                                        },
                                    ]}

                                >
                                    <Input maxLength={300} />
                                </Form.Item>

                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the name.',
                                        },
                                    ]}
                                >
                                    <Input maxLength={300} />
                                </Form.Item>


                                <Form.Item {...tailLayout}>
                                    <Button
                                        style={{ marginRight: '20px' }}
                                        color="danger"
                                        onClick={() => { setUpdateModalIsOpen(false) }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" color="info">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>
        </div>
    )
}
