// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchColleges } from 'reduxToolkit/features/college/CollegesSlice'
import { fetchDepartments } from 'reduxToolkit/features/department/DepartmentsSlice'
import { fetchUniversities } from 'reduxToolkit/features/university/UniversitiesSlice'
import {
  grayColor,
  primaryColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
// @material-ui/icons
import {
  addNewCourse,
  fetchCourses,
} from '../../../../reduxToolkit/Course/coursesSpecSlice'
import { fetchAreas } from '../../../../reduxToolkit/features/area/AreaSpecSlice'
import { fetchSubAreas } from '../../../../reduxToolkit/features/subarea/SubAreaSpecSlice'
import { fetchLocations } from "../../../../reduxToolkit/features/location/LocationSlice";
import ErrorModal from '../../../Components/ErrorModal'

const useStyles = makeStyles(styles)


export default function CreateCourseSpec(props) {

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const { TextArea } = Input
  const dispatch = useDispatch()
  const { Option } = Select

  //states
  const [student_academic_counseling_and_support, setStudent_academic_counseling_and_support,] = useState('student_academic_counseling_and_support')
  const [follow_up, setFollow_up,] = useState('follow_up')
  const [coordinator_id, setCoordinator_id] = useState()
  const [areaSpec_ids, setAreaSpec_ids] = useState([])
  const [subAreaSpec_ids, setSubAreaSpec_ids] = useState([])
  const [field, setField] = useState(false)
  const [mappedLevels, setMappedLevels] = useState([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  //global states
  const universities = useSelector((state) => state.university.universities)
  const departments = useSelector((state) => state.department.departments)
  const courses = useSelector((state) => state.CourseSpec.coursesSpecification)
  const areas = useSelector((state) => state.areaSpec.areas)
  const subAreas = useSelector((state) => state.subAreaSpec.subAreas)
  const colleges = useSelector((state) => state.college.colleges)
  const { currentUser } = useSelector((state) => state.user);
  const { locations } = useSelector((state) => state.locations);


  //handlers
  const handleFetchLocations = async () => {
    try {
      await dispatch(fetchLocations()).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const onChangeSubArea = (ids) => {
    setSubAreaSpec_ids(ids)
  }

  const onChangeType = (value) => {
    if (value === 'Field Experience') {
      setField(true)
    } else {
      setField(false)
    }
  }

  //fetch data
  useEffect(() => {
    dispatch(fetchUniversities())
    dispatch(fetchDepartments())
    dispatch(fetchColleges())
    dispatch(fetchAreas())
    dispatch(fetchSubAreas())
    dispatch(fetchCourses())
    handleFetchLocations();

  }, [])

  useEffect(() => {
    setCoordinator_id(currentUser?.staff_details?.id)
  }, []);

  //set areas 
  useEffect(() => {
    // extract preselected subareas objects from subAreaSpec_ids and areas objects
    const preselectedSubAreas = subAreas?.filter((subArea) => subAreaSpec_ids?.includes(subArea.id))

    // extract preselected areaSpec_ids from preselectedSubAreas
    const preAreaSpec_ids =
      preselectedSubAreas?.map((subArea) => {
        return subArea.areaSpec_id
      })

    setAreaSpec_ids(preAreaSpec_ids)

    // extract preselected areas objects from areaSpec_ids and areas objects
    let preselectedAreas =
      areas?.filter((area) => preAreaSpec_ids?.includes(area.id))

    // extract preselected levels from preselected preselectedAreas
    setMappedLevels([... new Set(preselectedAreas?.map(
      (area) =>
        area.levels?.map((level) => level),
    ).flat())])
  }, [subAreaSpec_ids])



  const onFinishFailed = (errorInfo) => {
    message.error('Submit failed!')
  }

  const onFinish = async (values) => {
    let course
    course = { ...values, areaSpec_ids, coordinator_id, student_academic_counseling_and_support, follow_up }
    const resultAction = await dispatch(addNewCourse(course))
    if (addNewCourse.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const course = resultAction.payload
      openNotificationWithIcon('success', `Added ${course.title}`)
      history.push('/citrine/CourseSpecificationList')
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  console.log("mappedLevels: ", mappedLevels)
  return (
    <div>
      <GridContainer
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                to={`/citrine/CourseSpecificationList/`}
              >
                {t('Courses')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/addCourseSpec/`}
              >
                {t('Create')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ prerequisites_ids: [], co_requisites_ids: [] }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Course General Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Code')}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={8}>
                    <Form.Item
                      label={t('Title')}
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Credit hours')}
                      name="credit_hours"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's credit hours",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      name="number_of_weeks"
                      label="Number of weeks "
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <InputNumber autoSize={true} style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Sub Area')}
                      name="subAreaSpec_ids"
                      rules={[
                        {
                          required: false,
                          message: "Please input the course's area and subarea",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeSubArea}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {subAreas &&
                          subAreas.map((subarea) => (
                            <Option key={subarea.id} value={subarea.id}>
                              {`${subarea.areaSpec_detail.name} /  ${subarea.name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Level')}
                      name="level_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's level",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {mappedLevels?.map((level) => <Option key={level.id} value={level.id}>
                          {level.name}
                        </Option>
                        )
                        }
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Category')}
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's category",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="Required">Required</Option>
                        <Option value="Elective">Elective</Option>
                        <Option value="Selected Elective">Selected Elective</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Prerequisites')}
                      name="prerequisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {courses.map((course) => (
                          <Option key={course.id} value={course.id}>
                            {course.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Co_requisites')}
                      name="co_requisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {courses.map((course) => (
                          <Option key={course.id} value={course.id}>
                            {course.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Type')}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onChange={onChangeType}
                      >
                        <Option value="Conventional Course">
                          Conventional Course
                        </Option>
                        <Option value="Final Year Project">
                          Final Year Project
                        </Option>
                        <Option value="Senior Design">Senior Design</Option>
                        <Option value="Field Experience">
                          Field Experience
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Program Structure')}
                      name="program_structure'"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the course's Program Structure",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={'Institution Requirements'}>
                          {'Institution Requirements'}
                        </Option>
                        <Option value={'College Requirements'}>
                          {'College Requirements'}
                        </Option>
                        <Option value={'Program Requirements'}>
                          {'Program Requirements'}
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Description')}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's description",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="" maxLength={100000} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Main objectives')}
                      name="main_objectives"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's main objectives",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="" maxLength={100000} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {field && (
              <div>
                <h3>
                  <small style={{ color: `${primaryColor[0]}` }}>
                    Dates and times allocation of field experience activities
                  </small>
                </h3>
              </div>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {field && (
              <div>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_days"
                          label="Number of days"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_hours"
                          label="Number of hours"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t(
                            'Decision-making procedures for identifying appropriate locations for field experience',
                          )}
                          name="decision_making"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Decision-making procedures for identifying appropriate locations for field experience",
                            },
                          ]}
                        >
                          <TextArea rows={4} placeholder="" maxLength={100} />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Supervision and Follow-up Mechanism')}
                          name="follow-up"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Supervision and Follow-up Mechanism",
                            },
                          ]}
                        >
                          <TextArea rows={4} placeholder="" maxLength={100} />
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Institutional Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Institution')}
                      name="institution_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's institution",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {universities.map((university) => (
                          <Option key={university.id} value={university.id}>
                            {university.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Colleges')}
                      name="college_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's colleges",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {colleges.map((c) => (
                          <Option key={c.id} value={c.id}>
                            {c.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Department')}
                      name="department_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's department",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {departments.map((department) => (
                          <Option key={department.id} value={department.id}>
                            {department.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Campus Location")}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the course's campus location",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        {locations?.map((location) => (
                          <Option key={location.id} value={location.id}>
                            {location.location}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
