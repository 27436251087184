import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  subAreas: [],
  subAreasStatus: 'idle',
  subAreaStatus: 'idle',
  error: null,
  subArea:null,
  id:null
}

export const fetchSubAreas =
createAsyncThunk('subAreaSpec/fetchSubAreas', async (param, { rejectWithValue }) => {
  const token=localStorage.getItem('token')
  try {
  const response = await axios.get(BaseUrl+'/subAreaSpec/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       return response.data;
      } catch (err) {
        let error = err;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response);
      }
    }
  );

  export const fetchSubAreaById =
  createAsyncThunk('subAreaSpec/fetchSubAreaById', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.get(BaseUrl+`/subAreaSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

  export const addNewSubArea = createAsyncThunk(
    'subAreaSpec/addNewSubArea',
    async (initialSubArea, { rejectWithValue }) => {
      const token=localStorage.getItem('token')
      try{
      const response = await axios.post(BaseUrl+`/subAreaSpec/create/`,initialSubArea ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }}
  );

  export const subAreaUpdate = createAsyncThunk(
    'subAreaSpec/subAreaUpdate',
    async (data,{ rejectWithValue }) => {
      try{
        const token=localStorage.getItem('token')
        const response = await axios.put(BaseUrl+`/subAreaSpec/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

  export const deleteSubArea =
  createAsyncThunk('subAreaSpec/deleteSubArea', async (id, { rejectWithValue }) => {
    const token=localStorage.getItem('token')
    try {
    const response = await axios.delete(BaseUrl+`/subAreaSpec/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

const  SubAreaSlice = createSlice({
  name: ' subAreaSpec',
  initialState,
  extraReducers: {
    [fetchSubAreas.pending]: (state, action) => {
      state.subAreasStatus = 'loading'
    },
    [fetchSubAreas.fulfilled]: (state, action) => {
      state.subAreasStatus = 'succeeded'
      state.subAreas = action.payload
    },
    [fetchSubAreas.rejected]: (state, action) => {
      state.subAreasStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchSubAreaById.pending]: (state, action) => {
      state.subAreaStatus = 'loading'
    },
    [fetchSubAreaById.fulfilled]: (state, action) => {
      state.subAreaStatus = 'succeeded'
      state.subArea = action.payload
    },
    [fetchSubAreaById.rejected]: (state, action) => {
      state.subAreaStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewSubArea.fulfilled]: (state, action) => {
      state.subAreas.push(action.payload)
    },
    [addNewSubArea.rejected]: (state, action) => {
      state.subAreaStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [subAreaUpdate.fulfilled]: (state, action) => {},
    [subAreaUpdate.rejected]: (state, action) => {
      state.subAreaStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [deleteSubArea.fulfilled]: (state, action) => {},
    [deleteSubArea.rejected]: (state, action) => {
      state.subAreaStatus = 'failed'
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  reducers: {
    getId: (state, action) => {state.id= action.payload},
    subAreaUpdated(state, action) {
      const { id,name } = action.payload
      const existingSubArea = state.subAreas.find(subArea => subArea.id === id)
      if (existingSubArea) {
        existingSubArea.name = name
      }
    }
  }
}})

export const { subAreaAdded, subAreaUpdated, getId} = SubAreaSlice.actions

export default SubAreaSlice.reducer

export const selectAllSubAreas = state => state.subAreaSpec.subAreas

