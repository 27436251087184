import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';
const initialState = {
  clos: [],
  closStatus: 'idle',
  cloStatus: 'idle',
  error: null,
  clo: null,
  id: null,
  allClos: [],
  allClosStatus: 'idle',
  closByCourseStatus: 'idle',
  closByCourse: [],
  closByProgramStatus: 'idle',
  closByProgram: []
}


export const getCLOsByProgram = createAsyncThunk('cloSpec/getCLOsByProgram', async (programId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/cloSpec/by_programSpec/?programSpec_id=${programId}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    console.log(response.data, 'response.data')
    return response.data

  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
})
export const fetchCloById = createAsyncThunk('cloSpec/fetchCloById', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/cloSpec/${id}/`, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })
    console.log(response.data, 'response.data clo spec')
    ///console.log(response.data)
    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchcourseAssessmentMethodClo = createAsyncThunk('cloSpec/fetchcourseAssessmentMethodClo',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/courseAssessmentMethodClo/${id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })

      // console.log(response.data)
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  })
export const fetchClos = createAsyncThunk('cloSpec/fetchClos', async (param, { rejectWithValue }) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(BaseUrl + '/cloSpec/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})

export const fetchClosByCourse = createAsyncThunk('cloSpec/fetchClosByCourse', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(BaseUrl + `/cloSpec/by_courseSpec/?courseSpec_id=${id}`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})
export const addNewClo = createAsyncThunk(
  'cloSpec/addNewClo',
  async (initialClo, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(BaseUrl + `/cloSpec/create/`, initialClo, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data

    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
)

export const updateClo = createAsyncThunk(
  'cloSpec/updateClo',
  async (data, { rejectWithValue }) => {
    console.log(data.updatedClo, "data.updatedClo")
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/cloSpec/${data.id}/edit/`, data.updatedClo, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data


    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
)

export const mapPlosToClo = createAsyncThunk(
  'cloSpec/mapPlosToClo',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/cloSpec/mappingPloSpec/`, data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data


    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
)
export const fetchAllClos = createAsyncThunk('cloSpec/fetchAllClos', async (param, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + '/cloSpec/',
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})
export const deleteCLO = createAsyncThunk('cloSpec/deleteClo', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/cloSpec/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    // console.log(response.data,'response.data')

    return response.data

  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    console.log("error.response from inside the CAT", error.response);
    return rejectWithValue(error.response);
  }
})
const CloSlice = createSlice({
  name: ' cloSpec',
  initialState,
  extraReducers: {

    [fetchClos.pending]: (state, action) => {
      state.closStatus = 'loading'
    },
    [fetchClos.fulfilled]: (state, action) => {
      state.closStatus = 'succeeded'
      state.clos = action.payload
    },
    [fetchClos.rejected]: (state, action) => {
      state.closStatus = 'failed'
      state.error = action.payload
    },

    [fetchClosByCourse.pending]: (state, action) => {
      state.closByCourseStatus = 'loading'
    },
    [fetchClosByCourse.fulfilled]: (state, action) => {
      state.closByCourseStatus = 'succeeded'
      // Add any fetched posts to the array
      state.closByCourse = action.payload
    },
    [fetchClosByCourse.rejected]: (state, action) => {
      state.closByCourseStatus = 'failed'
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload
        // console.log("action.payload from inside extra add plo reducer", action.payload)
      } else {
        state.error = action.error
        //console.log("action.error from inside extra add plo reducer", action.error)
      }
    },

    [fetchCloById.pending]: (state, action) => {
      state.cloStatus = 'loading'
    },
    [fetchCloById.fulfilled]: (state, action) => {
      state.cloStatus = 'succeeded'

      // Add any fetched posts to the array
      state.clo = action.payload
    },
    [fetchCloById.rejected]: (state, action) => {
      state.cloStatus = 'failed'
      state.error = action.payload
    },
    [addNewClo.fulfilled]: (state, action) => {
      state.clos.push(action.payload)
    },
    [addNewClo.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [updateClo.fulfilled]: (state, action) => {
      // state.clos[payload.id] = payload
    },

    [updateClo.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [mapPlosToClo.fulfilled]: (state, action) => {
      // state.clos[payload.id] = payload
    },

    [mapPlosToClo.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchAllClos.pending]: (state, action) => {
      state.allClosStatus = 'loading'
    },
    [fetchAllClos.fulfilled]: (state, action) => {
      state.allClosStatus = 'succeeded'
      // Add any fetched posts to the array
      state.allClos = state.allClos.concat(action.payload)
    },
    [fetchAllClos.rejected]: (state, action) => {
      state.allClosStatus = 'failed'
      state.error = action.payload
    },
  },

})


export default CloSlice.reducer

export const selectAllClos = state => state.cloSpec.clos