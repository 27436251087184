import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
import token from "assets/utils/accessToken";

const initialState = {
  VerificationOfCredibilitiesSpec: [],
  VerificationOfCredibilitySpecStatus: "idle",
  VerificationOfCredibilitySpecStatus: "idle",
  error: null,
  VerificationOfCredibilitySpec: null,
  VerificationOfCredibilitiesByCourse: [],
  id: null,
};

export const fetchVerificationOfCredibilitiesSpec = createAsyncThunk(
  "VerificationOfCredibility/fetchVerificationOfCredibilitiesSpec",
  async (courseSpec_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
          `/verificationCredibilityStudentsResultsSpec/?courseSpec_id=${courseSpec_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchVerificationOfCredibilitiesByCourse = createAsyncThunk(
  "VerificationOfCredibility/fetchVerificationOfCredibilitiesByCourse",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
          `/verificationCredibilityStudentsResultsSpec/areaSpec_filtered/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewVerificationOfCredibilitySpec = createAsyncThunk(
  "VerificationOfCredibility/addNewVerificationOfCredibilitySpec",
  async (initialVerificationOfCredibilitySpec, { rejectWithValue }) => {
    console.log(
      "initialVerificationOfCredibilitySpec",
      initialVerificationOfCredibilitySpec
    );
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/verificationCredibilityStudentsResultsSpec/create/`,
        initialVerificationOfCredibilitySpec,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const fetchVerificationOfCredibilitySpecById = createAsyncThunk(
  "VerificationOfCredibility/fetchVerificationOfCredibilityById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/verificationCredibilityStudentsResultsSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const verificationOfCredibilitySpecUpdate = createAsyncThunk(
  "verificationOfCredibility/verificationOfCredibilityUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl +
          `/verificationCredibilityStudentsResultsSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedVerificationCredibility");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteVerificationOfCredibilitySpec = createAsyncThunk(
  "VerificationOfCredibility/deleteVerificationOfCredibilitySpec",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/verificationCredibilityStudentsResultsSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const VerificationOfCredibilitySpecSlice = createSlice({
  name: "VerificationOfCredibility",
  initialState,

  extraReducers: {
    [fetchVerificationOfCredibilitiesSpec.pending]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "loading";
    },
    [fetchVerificationOfCredibilitiesSpec.fulfilled]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "succeeded";

      state.VerificationOfCredibilitiesSpec = action.payload;
      //state.TeachingStrategiesSpec =  jsonData
    },
    [fetchVerificationOfCredibilitiesSpec.rejected]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchVerificationOfCredibilitySpecById.pending]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "loading";
    },
    [fetchVerificationOfCredibilitySpecById.fulfilled]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "succeeded";

      // Add any fetched posts to the array
      state.VerificationOfCredibilitySpec = action.payload;
    },
    [fetchVerificationOfCredibilitySpecById.rejected]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [fetchVerificationOfCredibilitiesByCourse.pending]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "loading";
    },
    [fetchVerificationOfCredibilitiesByCourse.fulfilled]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "succeeded";

      // Add any fetched posts to the array
      state.VerificationOfCredibilitiesByCourse = action.payload;
    },
    [fetchVerificationOfCredibilitiesByCourse.rejected]: (state, action) => {
      state.VerificationOfCredibilitySpecStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [verificationOfCredibilitySpecUpdate.fulfilled]: (state, action) => {
      console.log("update succeeded!!!");
    },
    [addNewVerificationOfCredibilitySpec.fulfilled]: (state, action) => {
      state.VerificationOfCredibilitiesSpec.push(action.payload);
    },
    [deleteVerificationOfCredibilitySpec.fulfilled]: (state, action) => {
      // state.SpecificationAprovalDatas.push(action.payload)
    },
    [verificationOfCredibilitySpecUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewVerificationOfCredibilitySpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteVerificationOfCredibilitySpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },
    },
  },
});

export const { getId } = VerificationOfCredibilitySpecSlice.actions;

export default VerificationOfCredibilitySpecSlice.reducer;
