import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardBodyStyle from 'assets/jss/material-dashboard-pro-react/components/cardBodyStyle';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileWord } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchPEOReportDocumentById } from "../../../../../reduxToolkit/features/PEOReportDocument/PEOReportDocumentSlice";



const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const useCardBodyStyles = makeStyles(cardBodyStyle);



export default function PEOReportDocumentDetails() {
    const { t } = useTranslation();
    const [search, setSearch] = useState("")

    const classes = useStyles();
    const navBarClasses = useNavBarStyles();
    const titleClasses = useTitleStyles();
    const cardClasses = useCardStyles();
    const cardBodyClasses = useCardBodyStyles()
    // const headerClasses = usePagesHeaderstyle();
    // console.log('token', token)

    let { id, type, docId } = useParams();

    const dispatch = useDispatch()

    const peoReportDocumentStatus = useSelector(state => state.peoReportDocument.peoReportDocumentStatus)
    useEffect(() => {
        dispatch(fetchPEOReportDocumentById(docId))
    }, [dispatch])

    const peoReportDocument = useSelector(state => state.peoReportDocument.peoReportDocument)
    console.log(peoReportDocument, "peoReportDocument")


    const exportDocumentToPDF = () => {

        // const doc = new jsPDF();

        // let parser = new DOMParser();
        // let parsedHtml = parser.parseFromString(peoReportDocument && peoReportDocument.htmlTemplates_body && peoReportDocument.htmlTemplates_body, 'text/html');
        // console.log("parsedHtml", parsedHtml)
        var html = htmlToPdfmake(peoReportDocument && peoReportDocument.htmlTemplates_body);
        console.log("html", html)

        const documentDefinition = { content: html };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).open();

    }

    function exportDocumentToWORD(element, filename = '') {
        var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>`;
        var postHtml = `</body></html>`;
        var html = preHtml + element + postHtml;

        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });

        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

        // Specify file name
        filename = filename ? filename + '.doc' : 'document.doc';

        // Create download link element
        var downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = url;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    }
    return (
        <div>
            {/* <GridContainer
                alignItems="center"
                style={{
                    borderTop: `1px solid  ${grayColor[15]}`, minHeight: "50px", maxHeight: "50px"
                }}>
                <GridItem>
                    <Link to={`/citrine/programSpec`}>
                        <span className={titleClasses.title}> {t('Programs')} </span>
                    </Link>

                </GridItem>

            </GridContainer> */}
            <GridContainer xs={12} sm={12} md={10} justify='flex-end'>
                {/* <GridItem>
                    <Link to={`/citrine/reports/${type}/PEO/${id}/documents/${docId}/edit`}>
                        <Button
                            color="white"
                            aria-label="Edit"
                            justIcon
                            round
                        >
                            <Edit />
                        </Button>
                    </Link>
                </GridItem> */}
                {/* <GridItem>

                    <Button
                        color="white"
                        aria-label="Export to PDF"
                        justIcon
                        round
                        onClick={exportDocumentToPDF}
                    >
                        <FaFilePdf />
                    </Button>
                </GridItem> */}
                <GridItem>
                    <Button
                        color="white"
                        aria-label="Export to Word"
                        justIcon
                        round
                        onClick={() => { exportDocumentToWORD(peoReportDocument && peoReportDocument.htmlTemplates_body && peoReportDocument.htmlTemplates_body, 'word-content') }}
                    >
                        <FaFileWord />
                    </Button>

                </GridItem>
            </GridContainer>
            <GridContainer xs={12} sm={12} md={10}>
                <Card>

                    <CardBody className={cardBodyClasses.cardBodyWithMarginTop}>

                        <GridContainer justify='center'>
                            <GridItem>
                                <div dangerouslySetInnerHTML={{ __html: peoReportDocument && peoReportDocument.htmlTemplates_body }} ></div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>

                </Card>
            </GridContainer>
        </div>
    );
}













