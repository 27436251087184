import React, { useEffect, useState, useRef, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// core components
import { unwrapResult } from '@reduxjs/toolkit'
import { Breadcrumb, notification, Table } from 'antd'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { fetchAssessmentQuestionsMethods } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'

//import DropDownQuestionAssessment from "../../../Components/DropDownQuestionAssessment";
import ErrorModal from '../../../Components/ErrorModal'
import DropDownAssessment from './DropDownAssessment'
import {
  CalculScoreCourse,
  fetchCourseById,
} from '../../../../reduxToolkit/features/course/CoursesSlice'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'

import { ScoreCourseByQuestion } from '../../../../reduxToolkit/features/assessment/AssessmentSlice'
import { fetchCourseInstructorById } from 'reduxToolkit/features/CourseInstructor/CourseInstructorSlice'
import saveAs from 'file-saver'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core'
const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)

const AssessmentQuestionMethodExcerpt = ({
  assessmentQuestionsMethod,
  key,

  course_id,
}) => {
  console.log(assessmentQuestionsMethod)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      name: 'question',
    },
    {
      title: 'Clo',
      dataIndex: 'code',
      name: 'code',
    },
    {
      title: 'Actual weight',
      dataIndex: 'actual_weight',
      name: 'actual_weight',
    },
    {
      title: 'Correction mark',
      dataIndex: 'correction_mark',
      name: 'correction_mark',
    },
  ]
  // const columns = [
  //   {
  //     title: 'Question',
  //     dataIndex: 'question',
  //   },
  //   {
  //     title: 'Clo',
  //     dataIndex: 'code',
  //   },
  //   {
  //     title: 'Actual weight',
  //     dataIndex: 'actual_weight',
  //   },
  //   {
  //     title: 'Correction mark',
  //     dataIndex: 'correction_mark',
  //   },
  // ]

  const [selectedRows, setSelectedRows] = React.useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [disabled, setDisabled] = React.useState(true)
  const [disabledDelete, setDisabledDelete] = useState(true)
  const [listInstructors, setListInstructors] = React.useState([])
  const [listGroups, setListGroups] = React.useState([])

  const handleDisable = (selectedRows) => {
    selectedRows.length === 1 ? setDisabled(false) : setDisabled(true)
  }
  const handleDisableDelete = (selectedRows) => {
    selectedRows.length > 0 ? setDisabledDelete(false) : setDisabledDelete(true)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRowData(Object.assign({}, selectedRow[0]))
      setSelectedRows(selectedRowKeys.join(','))
      handleDisable(selectedRowKeys)
      handleDisableDelete(selectedRowKeys)
    },
  }
  const courseInstructors = useSelector(
    (state) => state.CourseInstructor.CourseInstructors,
  )
  const instructorsList = courseInstructors.map((el) =>
  el.group_details.instructors.map(({ first_name, last_name, avatar }) => (
    <MenuItem>
      <Avatar title={'assignee'} src={MediaBaseUrl + `${avatar}`} />
      &nbsp; &nbsp;
      {first_name} {last_name}
    </MenuItem>
  )),
)
  const CourseInstructorStatus = useSelector(
    (state) => state.CourseInstructor.CourseInstructorStatus,
  )
  const persons = useSelector((state) => state.user.staffList)
  console.log(persons)
  const [selectionType, setSelectionType] = useState('checkbox')
  const data = assessmentQuestionsMethod.rows
  const exporterRef = useRef(null)
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx',
      )
    })
  }
  useEffect(() => {
    dispatch(
      fetchCourseInstructorById({
        course_id,
        exam_id: assessmentQuestionsMethod?.id,
      }),
    ).then((res) => {
      console.log(res.payload)
      if (res?.payload?.length > 0) {
        const constructors_IDS = res?.payload?.map((el) => {
          return el?.group_details?.instructors[0]?.id
        })
        const listGroup = res?.payload?.map((el) => (
          <>
            <MenuItem>{el?.group_details?.group_name}</MenuItem>
          </>
        ))

        const listInst = persons
          ?.filter((el) => constructors_IDS?.includes(el.person_id))
          .map((el) => el.person_details[0])
          .map(({ first_name, last_name, avatar }) => (
            <>
              <MenuItem>
                <Avatar title={'assignee'} src={MediaBaseUrl + `${avatar}`}>
                  {first_name
                    .split(' ')
                    .map((word) => word[0])
                    .join('')}
                </Avatar>
                &nbsp; &nbsp;
                {first_name} {last_name}
              </MenuItem>
            </>
          ))
        setListInstructors(listInst)
        setListGroups(listGroup)
      }
    })
  }, [dispatch])

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              {assessmentQuestionsMethod &&
              assessmentQuestionsMethod.mode
                .toLowerCase()
                .includes('theory') ? (
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Link
                      to={`/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${
                        assessmentQuestionsMethod &&
                        assessmentQuestionsMethod.id
                      }/${
                        assessmentQuestionsMethod &&
                        assessmentQuestionsMethod.name
                      }`}
                    >
                      <Typography>
                        {assessmentQuestionsMethod.name} (
                        {assessmentQuestionsMethod.mode})
                      </Typography>
                    </Link>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography>
                      Exam date :{assessmentQuestionsMethod.assessment_due_date}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {listInstructors && listInstructors}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {listGroups && listGroups}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Link
                      to={`/citrine/faculty/assessmentsQuestionsDetailsByGroup/course/${course_id}/assessmentMethod/${
                        assessmentQuestionsMethod &&
                        assessmentQuestionsMethod.id
                      }/${
                        assessmentQuestionsMethod &&
                        assessmentQuestionsMethod.name
                      }`}
                    >
                      <Typography>
                        {assessmentQuestionsMethod.name} (
                        {assessmentQuestionsMethod.mode})
                      </Typography>
                    </Link>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    {listInstructors && listInstructors}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {listGroups && listGroups}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={11}>
              {assessmentQuestionsMethod &&
              assessmentQuestionsMethod.mode
                .toLowerCase()
                .includes('theory') ? (
                <Link
                  to={`/citrine/faculty/assessmentsQuestionsDetails/course/${course_id}/assessmentMethod/${
                    assessmentQuestionsMethod && assessmentQuestionsMethod.id
                  }/${
                    assessmentQuestionsMethod && assessmentQuestionsMethod.name
                  }`}
                  style={{ display: 'flex' }}
                >
                  <Typography>
                    {assessmentQuestionsMethod.name} (
                    {assessmentQuestionsMethod.mode})
                  </Typography>
                  <strong style={{ marginLeft: '100px' }}>
                    {' '}
                    Exam date : {assessmentQuestionsMethod.assessment_due_date}
                  </strong>
           
                </Link>
              ) : (
                <Link
                  to={`/citrine/faculty/assessmentsQuestionsDetailsByGroup/course/${course_id}/assessmentMethod/${
                    assessmentQuestionsMethod && assessmentQuestionsMethod.id
                  }/${
                    assessmentQuestionsMethod && assessmentQuestionsMethod.name
                  }`}
                  style={{ display: 'flex' }}
                >
                  <Typography>
                    {assessmentQuestionsMethod.name} (
                    {assessmentQuestionsMethod.mode})
                  </Typography>
                </Link>
              )}
              <br />

              <div>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                />
              </div>
            </GridItem> */}

            <GridItem xs={12} sm={12} md={1}>
              <DropDownAssessment
                course_id={course_id}
                assessmentMethod_id={assessmentQuestionsMethod.id}
                assessmentQuestionsMethod_name={assessmentQuestionsMethod.name}
                selectedRows={selectedRows}
                disabled={disabled}
                selectedRowData={selectedRowData}
                disabledDelete={disabledDelete}
                //programId={ProgramId}
                exportData={startExport}
              />
              {/* <DropDownQuestionAssessment
                course_id={course_id}
                assessmentMethod_id={assessmentQuestionsMethod.id}
                assessmentQuestionsMethod_name={assessmentQuestionsMethod.name}
                selectedRows={selectedRows}
                disabled={disabled}
                selectedRowData={selectedRowData}
              /> */}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <GridExporter
        ref={exporterRef}
        rows={data}
        columns={columns}
        onSave={onSave}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      {/* <SuperGeneralDeleteModal
        delete_Element={handleDeletePlo}
        Element_ID={assessmentQuestionsMethod.id}
        open={open}
        handleClose={handleClose}
      /> */}
    </GridItem>
  )
}

export default function AssessmentQuestionListFaculty({ match }) {
  const { t } = useTranslation()

  const { id } = match.params

  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()

  const dispatch = useDispatch()

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const AssessmentQuestionsMethods = async () => {
    try {
      const resultAction = await dispatch(
        fetchAssessmentQuestionsMethods(id),
      ).unwrap()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    }
  }

  const error = useSelector((state) => state.AssessmentMethods.error)
  const assessmentQuestionsMethods = useSelector(
    (state) => state.AssessmentMethods.assessmentQuestionsMethods,
  )
  console.log('assessmentQuestionsMethods', assessmentQuestionsMethods)
  const assessmentQuestionsMethodsStatus = useSelector(
    (state) => state.AssessmentMethods.assessmentQuestionsMethodsStatus,
  )

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton
  useEffect(() => {
    dispatch(fetchStaff())
    AssessmentQuestionsMethods()
    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        let ScoreCourse = {
          course_id: id,
          semester_id: course && course.semester_id,
        }
        dispatch(CalculScoreCourse(ScoreCourse))
        dispatch(ScoreCourseByQuestion(ScoreCourse))
      })
  }, [dispatch])
  let content

  if (assessmentQuestionsMethodsStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (assessmentQuestionsMethodsStatus === 'succeeded') {
    if (assessmentQuestionsMethods && assessmentQuestionsMethods != 'You do not have permission') {
      content = assessmentQuestionsMethods.map((assessmentQuestionsMethod) => (
        <AssessmentQuestionMethodExcerpt
          course_id={id}
          key={assessmentQuestionsMethod.id}
          assessmentQuestionsMethod={assessmentQuestionsMethod}
        />
      ))
    } else if (assessmentQuestionsMethods && assessmentQuestionsMethods === 'You do not have permission'
    ) {
      content = <h5>{t('You do not have permission!')}</h5>
    } else if (
      assessmentQuestionsMethods &&
      assessmentQuestionsMethods.length
    ) {
      content = assessmentQuestionsMethods.map((assessmentQuestionsMethod) => (
        <AssessmentQuestionMethodExcerpt
          course_id={id}
          key={assessmentQuestionsMethod.id}
          assessmentQuestionsMethod={assessmentQuestionsMethod}
        />
      ))
    } else {
      content = (
        <h5>
          {t('This course has no assessment Questions Methods to display!')}
        </h5>
      )
    }
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/UserCoursesListAssessment/`}>
              {t('Courses List ')}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/assessmentsQuestions_list/course/${id}`}
              style={{ color: 'orange' }}
            >
              {t('Assessments Questions ')}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <GridContainer>{content}</GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
