import { Tabs } from 'antd';
import Card from "components/Card/Card"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import { greyColor } from 'assets/jss/material-dashboard-pro-react';
import { Link } from "react-router-dom"
import React from 'react';
import HTMLEditor from "components/HTMLEditor/HTMLEditor";


const AppendixA = ({data}) => (
    <>
        {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

        <div>AppendixA</div>
    </>

);
export default AppendixA;