import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/icons

import { Link } from 'react-router-dom';
import { fetchCourseById, fetchCourses } from '../../../../../reduxToolkit/Course/coursesSpecSlice';
import { addNewRange, fetchRangeById, fetchRanges, rangeUpdate } from '../../../../../reduxToolkit/features/range/RangeSpecSlice';
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Form } from 'antd';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { useHistory } from 'react-router-dom';

import { SchoolRounded } from "@material-ui/icons";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import { Breadcrumb, Input, InputNumber, notification } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import { useTranslation } from 'react-i18next';

import ErrorModal from "../../../../Components/ErrorModal";


const useStyles = makeStyles(styles);

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};
// const classes = useStyles();
export default function CreateRangePage({ match }) {
  const { t } = useTranslation();
  const { IdC } = match.params
  const { IdR } = match.params
  const [form] = Form.useForm();
  console.log('IdC', IdC)

  const isAddMode = isNaN(IdR);
  console.log('id from match is add mode', isAddMode)
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const dispatch = useDispatch()

  const coursesStatus = useSelector(state => state.CourseSpec.coursesSpecificationStatus)
  console.log('coursesStatus', coursesStatus)
  useEffect(() => {
    dispatch(fetchCourses())
  }, [dispatch]
  )
  const courses = useSelector((state) => state.CourseSpec.coursesSpecification)
  console.log('courses', courses)
  const titleClasses = useTitleStyles();
  const [type, setType] = useState('')
  const [start_of_interval, setStart_of_interval] = useState('')
  const [end_of_interval, setEnd_of_interval] = useState('')
  const [courseSpec_id, setCourseSpec_id] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const canSave =
    [type, start_of_interval, end_of_interval, courseSpec_id].every(Boolean) && addRequestStatus === 'idle'
  const history = useHistory();


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewRange({ ...values, courseSpec_id: IdC })
    );
    if (addNewRange.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchRanges(IdC));
      history.push(`/citrine/specification/course/${IdC}/ranges`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      rangeUpdate({
        id: IdR,
        modified: {
          ...values,
        },
      })
    );
    if (rangeUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchRanges(IdC));
      history.push(`/citrine/specification/course/${IdC}/ranges`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };


  function onChangeCourse(value) {
    //  console.log(`selected ${value}`);
    setCourseSpec_id(value);
  }




  const courseSelected = useSelector((state) => state.CourseSpec.courseSpecification)

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchRangeById(IdR))
        .then(unwrapResult)
        .then((x) => {
          console.log(x, "x");
          form.setFieldsValue({
            type: x.type,
            start_of_interval: x.start_of_interval,
            end_of_interval: x.end_of_interval,
            course_id: x.courseSpec_id,
          });
        });
    }
  }, [IdR]);

  const range = useSelector((state) => state.rangeSpec.range)
  console.log('range ', range)


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);



  const fetchAllModeOfInstructions = async () => {
    try {
      // const resultAction = await dispatch(fetchModeOfInstructions(id)).unwrap();
      dispatch(fetchCourseById(IdC));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    // fetchAllModeOfInstructions();
    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);


  return (

    <div>
      <GridContainer

        md={12}
        xs={12} sm={12}
        direction="row"
        justify="space-between"
        alignItems="center"
      >

      </GridContainer>
      {!IdR ?
        <div>
          <GridItem>
            <Breadcrumb separator=">">
             

              <Breadcrumb.Item>
                <Link to={`/citrine/CourseSpecificationList/`}>
                  {t("Courses List")}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/courseSpecDetails/${course && course.id && course.id
                    }`}
                >
                  {course && course.title && course.title}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/specification/course/${course && course.id && course.id}/ranges`}
                >
                  {t("ranges List")}
                </Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <Link
                  to={`/citrine/specification/course/${course && course.id && course.id}/ranges/create/IdR`}
                  style={{ color: "orange" }}
                >
                  {t("Create")}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {t('Create')}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="type"
                      label="Type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      name="start_of_interval"
                      label="Start of interval"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      name="end_of_interval"
                      label="End of interval"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>


                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/specification/course/${course && course.id && course.id}/ranges`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>


                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
        :
        <div>
          <GridItem>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/citrine/CourseSpecificationList/`}>
                  {t("Courses List")}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/courseSpecDetails/${course && course.id && course.id
                    }`}
                >
                  {course && course.title && course.title}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/citrine/specification/course/${course && course.id && course.id}/ranges`}
                >
                  {t("ranges List")}
                </Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <Link
                  to={`/citrine/specification/course/${course && course.id && course.id}/ranges/${IdR}/edit`}
                  style={{ color: "orange" }}
                >
                  {t("edit")}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </GridItem>
          <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <span> {t('Edit')} </span>
                  </h4>
                </CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinishUpdate}
                  >
                    <Form.Item
                      name="type"
                      label="Type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      name="start_of_interval"
                      label="Start of interval"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      name="end_of_interval"
                      label="End of interval"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>



                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/specification/course/${course && course.id && course.id}/ranges`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      }

    </div>

  );
}


