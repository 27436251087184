import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  surveys: [],
  surveyResponsesStatus: 'idle',
  surveyResponseStatus: 'idle',
  error: null,
  surveyResponses: [],
  surveyResponse: null,

  questionStatus: 'idle',
  questions: []
}

export const fetchSurveyResponses = createAsyncThunk('survey/fetchSurveyResponses', async () => {
  const token = localStorage.getItem('token')

  const response = await axios.get(BaseUrl + '/surveyResponse/',
    {
      headers: {
        'Authorization': `Token ${token}`
      }
    }

  )
  console.log(response.data, 'response.data')

  return response.data
})

export const fetchSurveyResponseById = createAsyncThunk('survey/fetchSurveyResponseById', async (id) => {
  const token = localStorage.getItem('token')

  const response = await axios.get(BaseUrl + `/surveyResponse/${id}`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})

export const fetchQuestionsBySurveyResponse = createAsyncThunk('survey/fetchQuestionsBySurveyResponse', async (id) => {
  const token = localStorage.getItem('token')

  const response = await axios.get(BaseUrl + `/surveyWeight/?surveyResponse_id=${id}`, {
    headers: {
      'Authorization': `token ${token}`
    }
  })
  // console.log(response.data,'response.data')

  return response.data
})

export const addNewSurveyResponse = createAsyncThunk(
  'survey/addNewSurveyResponse',
  async (initialSurveyResponse, { rejectWithValue }) => {
    try {
    //const token = localStorage.getItem('token')
    console.log('addNewSurveyResponse')
    const response = await axios.post(BaseUrl + `/surveyResponse/create/`, initialSurveyResponse, {
      headers: {
       // 'Authorization': `token ${token}`
      }
    })
    return response.data
  
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    console.log('error.response from inside the CAT', error.response)
    return rejectWithValue(error.response)
  }
}
)

export const sendSurveyToStudents = createAsyncThunk(
  'survey/sendSurveyToStudents',
  async (survey) => {
    const token = localStorage.getItem('token')

    const response = await axios.post(BaseUrl + `/surveyResponse/create/`, survey, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  }
)

export const updateSurveyResponse = createAsyncThunk(
  'survey/updateSurveyResponse',
  async (data, thunkAPI) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/surveyResponse/${data.id}/edit/`, data.updatedTemplate, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(data, 'updatedTemplate')
      console.log(response.data, 'response.data')
      return response.data


    } catch (error) {
      console.error('Failed to update the template: ', error)

    }
  }
)


export const updateQuestionWeight = createAsyncThunk(
  'survey/updateQuestionWeight',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')

    try {
      const response = await axios.put(BaseUrl + `/surveyWeight/${data.id}/edit/`, data.updatedQuestion, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data


    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)

// export const deleteCampaign = createAsyncThunk('campaign/deleteCampaign', async (id, { rejectWithValue }) => {
//   const token=localStorage.getItem('token')
//   try {
//   const response = await axios.delete(BaseUrl+`/campaign/${id}/delete/`, {
//       headers: {
//         'Authorization': `token ${token}`

//       }
//     })
//     // console.log(response.data,'response.data')

//   return response.data
// } catch (err) {
//   // Use `err.response.data` as `action.payload` for a `rejected` action,
//   // by explicitly returning it using the `rejectWithValue()` utility
//   return rejectWithValue(err.response.data)
// }
// })
const SurveyResponseSlice = createSlice({
  name: 'survey',
  initialState,
  extraReducers: {

    [fetchSurveyResponses.pending]: (state, action) => {
      state.surveyResponsesStatus = 'loading'
    },
    [fetchSurveyResponses.fulfilled]: (state, action) => {
      state.surveyResponsesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.surveyResponses = action.payload
    },
    [fetchSurveyResponses.rejected]: (state, action) => {
      state.surveyResponsesStatus = 'failed'
      state.error = action.payload
    },


    [fetchSurveyResponseById.pending]: (state, action) => {
      state.surveyResponseStatus = 'loading'
    },
    [fetchSurveyResponseById.fulfilled]: (state, action) => {
      state.surveyResponseStatus = 'succeeded'
      // Add any fetched posts to the array
      state.surveyResponse = action.payload
    },
    [fetchSurveyResponseById.rejected]: (state, action) => {
      state.surveyResponseStatus = 'failed'
    },


    [fetchQuestionsBySurveyResponse.pending]: (state, action) => {
      state.questionStatus = 'loading'
    },
    [fetchQuestionsBySurveyResponse.fulfilled]: (state, action) => {
      state.questionStatus = 'succeeded'
      // Add any fetched posts to the array
      state.questions = action.payload
    },
    [fetchQuestionsBySurveyResponse.rejected]: (state, action) => {
      state.questionStatus = 'failed'
    },


    [addNewSurveyResponse.fulfilled]: (state, action) => {
      state.surveyResponses.push(action.payload)
    },
    [addNewSurveyResponse.rejected]: (state, action) => {
      state.surveyResponseStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
    [sendSurveyToStudents.fulfilled]: (state, action) => {
      state.surveys.push(action.payload)
    },
    // [deleteCampaign.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    // },
    [updateSurveyResponse.fulfilled]: (state, action) => {
      // const result = state.surveyResponses.filter(template => template.id !== action.payload.id);
      // result.push(action.payload)
      // state.surveyResponses = result
      console.log("action.payload.id from slice", action.payload)

    },
  },
})


export default SurveyResponseSlice.reducer