import CardHeader from "components/Card/CardHeader.js";

import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider, notification, Tabs } from "antd";
import "antd/dist/antd.css";
// core components
import {
  blackColor,
  cardTitle,
  grayColor,
  hexToRgb, infoColor, primaryColor, roseColor, whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/diagram-js.css";
import Modeler from "bpmn-js/lib/Modeler";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FaHistory,
  FaProjectDiagram,
  FaTasks
} from "react-icons/fa";

import AttachFileIcon from "@material-ui/icons/AttachFile";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchActivities } from "../../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import {
  GetDiagram,
  GetHistoricTasks,
  GetProcessDefinitionByKey,
  GetUserProfile
} from "../../../../../reduxToolkit/features/camunda/CamundaSlice";
import {
  fetchStaff, fetchStaffById,
  updateStateStaff
} from "../../../../../reduxToolkit/features/user/UserSlice";
import CompleteCourseReportModal from "../../../../Components/CompleteCourseReportModal";
import "../cvTech.css";

import Modal from "../Modal";

import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/diagram-js.css";


//********************* */

import { useParams } from "react-router-dom";
import {
  fetchResearchAndInnovationActivityById,
  updateResearchAndInnovationActivity
} from "reduxToolkit/features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice";
import ErrorModal from "../../../../Components/ErrorModal";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import clsx from "clsx";


import CreateIcon from "@material-ui/icons/Create";
import DoneIcon from "@material-ui/icons/Done";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

// import VisibilityIcon from '@material-ui/icons/Visibility';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CardFooter from "components/Card/CardFooter";

import UploadResearchInnovation from "views/Components/Uploaders/UploadResearchandInnovation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const theme = createTheme({
  overrides: {
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
        padding: 0,
      },
    },
  },
});
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg,${infoColor[0]} 0%,${primaryColor[0]} 50%,${roseColor[0]} 100%)`,
  },
});

//******************************** */

const styles2 = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles2 = makeStyles(styles2);

const { TabPane } = Tabs;
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const useStyles = makeStyles(styles);

const ResearchAndInnovationExcerpt = ({ research, personId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const dispatch = useDispatch();

  return (

    <GridItem xs={12} sm={12} md={12} key={research.id}>
      <Card>
        <GridContainer justify="end" alignItems="baseline">
          <GridItem>
            <h5
              style={{
                // fontWeight: "bold",
                // color: `${primaryColor[0]}`,
                paddingLeft: 20,
              }}
            >
              {" "}
              {research && research.title}{" "}
            </h5>
          </GridItem>
        </GridContainer>
      </Card>
      <Card style={{ maxHeight: "400px", minHeight: "400px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link
                to={`/citrine/faculty/teachingStaff/${personId}/researchAndInnovationActivity/${research.id}`}
              >
                <h4 className={cardClasses.cardProductTitle}>
                  {/* {research && research.name} */}
                </h4>
              </Link>
            </GridItem>
            {/* <GridItem xs={2} sm={2} md={2}>
        <ProgramCardDropdownButton id={program.id} program={program} />
      </GridItem> */}
          </GridContainer>
        </CardHeader>

        <CardBody smallCardBody>
          <GridContainer xs={12} sm={12} md={12}>
            <GridContainer
              xs={12}
              sm={12}
              md={12}
              style={{
                flexDirection: "column",
              }}
            >

              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("College")}:
                <span style={{ fontWeight: "normal", color: "black" }}>
                  {research &&
                    research.colleges_details &&
                    research.colleges_details[0] &&
                    `${research.colleges_details[0].name}`}
                </span>
              </div>

              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Departement")}:
                <span style={{ fontWeight: "normal", color: "black" }}>
                  {research && research.departments_details &&
                    research.departments_details[0] &&
                    research.departments_details[0].name
                  }
                </span>
              </div>

              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Type Of Research")}:
                <span style={{ fontWeight: "normal", color: "black" }}>
                  {" "}
                  {research && research.type && research.type}{" "}
                </span>
              </div>

              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Member")}:
                {/* <span style={{ fontWeight: "normal", color: "black" }}>
                  {`${research.authors_details.map((item) =>{
                      item.first_name
                  })} ${research.authors_details[0].last_name}`}
                </span> */}
              </div>
            </GridContainer>
            {/* <GridContainer
              xs={12}
              sm={12}
              md={6}
              style={{
                flexDirection: "column",
              }}
            >
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Member")}:
                <span style={{ fontWeight: "normal", color: "black" }}>
                  {`${research.authors_details[0].first_name} ${research.authors_details[0].last_name}`}
                </span>
              </div>

              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Title")}:
                <span style={{ fontWeight: "normal", color: "black" }}>
                  {`${research.title}`}
                </span>
              </div> 


            </GridContainer> */}

            <br />
            <br />
            <br />
            <br />




            {research.type == "Review-Editing" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Category")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.category_name && research.category_name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Publisher")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.publisherConfig_details[0] && research.publisherConfig_details[0].name && research.publisherConfig_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>





              </div>





            ) : (<p></p>)

            }

            {research.type == "Research Proposals" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Role")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.roleConf_details[0] && research.roleConf_details[0].role && research.roleConf_details[0].role}
                  </span>
                </div>
                {research && research.proposed_project_leader_details[0] &&
                  <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                    {t("Proposed Project Leader")}:
                    <span style={{ fontWeight: "normal", color: "black" }}>

                      {`${research.proposed_project_leader_details[0].first_name} ${research.proposed_project_leader_details[0].last_name}`}

                    </span>
                  </div>
                }

                {research && research.investigator_name_internal_details[0] &&
                  <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                    {t("Investigator Name Internal")}:
                    <span style={{ fontWeight: "normal", color: "black" }}>



                      {`${research.investigator_name_internal_details[0].first_name} ${research.investigator_name_internal_details[0].last_name}`}

                    </span>
                  </div>
                }


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Investigator Name External")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.investigator_name_external && research.investigator_name_external}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title of proposal")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title_of_proposal && research.title_of_proposal}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Budget")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.budget && research.budget}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>





              </div>





            ) : (<p></p>)

            }

            {research.type == "Conference Presentation" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Conference Presenter")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.conference_presenter && research.conference_presenter}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Conference Paper Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.conference_paper_title && research.conference_paper_title}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Conference Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.conference_title && research.conference_title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Location")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.location && research.location}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Indexed")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.indexed && research.indexed}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Peer Reviewed")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.peer_reviewed && research.peer_reviewed}
                  </span>
                </div>





              </div>





            ) : (<p></p>)

            }


            {research.type == "Memberships" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Role")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.roleConf_details[0].role && research.roleConf_details[0].role}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Organization")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.organization && research.organization}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>





              </div>





            ) : (<p></p>)

            }


            {research.type == "Research Grants" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Role")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.roleConf_details[0].role && research.roleConf_details[0].role}
                  </span>
                </div>
                {research && research.project_leader_internal &&
                  <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                    {t("Project Leader (internal)")}:
                    <span style={{ fontWeight: "normal", color: "black" }}>
                      {`${research.project_leader_internal_details[0].first_name} ${research.project_leader_internal_details[0].last_name}`}

                    </span>
                  </div>
                }


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Project Leader (external)")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.project_leader_external && research.project_leader_external}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Project title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.project_title && research.project_title}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Budget")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.budget && research.budget}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>





              </div>





            ) : (<p></p>)

            }



            {research.type == "Workshop-Seminar" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>
                {research && research.seminar_presenter_internal &&
                  <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                    {t("Seminar Presenter (internal)")}:
                    <span style={{ fontWeight: "normal", color: "black" }}>
                      {`${research.seminar_presenter_internal_details[0].first_name} ${research.seminar_presenter_internal_details[0].last_name}`}

                    </span>
                  </div>
                }

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Seminar Presenter (external)")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.seminar_presenter_external && research.seminar_presenter_external}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Workshop/Seminar title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.workshop_seminar_title && research.workshop_seminar_title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Seminar Paper Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.seminar_paper_title && research.seminar_paper_title}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Workshop Location")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.workshop_location && research.workshop_location}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>







              </div>





            ) : (<p></p>)

            }



            {research.type == "Review Letter" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Role")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.roleConf_details[0].role && research.roleConf_details[0].role}
                  </span>
                </div>
                {research && research.research_review_leader_internal &&


                  <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                    {t("Research review leader (internal)")}:
                    <span style={{ fontWeight: "normal", color: "black" }}>
                      {`${research.research_review_leader_internal_details[0].first_name} 
${research.research_review_leader_internal_details[0].last_name}`}


                    </span>
                  </div>
                }


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research review leader (external)")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.research_review_leader_external && research.research_review_leader_external}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research review project organization")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.research_review_project_organization && research.research_review_project_organization}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title of review letter")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title_of_review_letter && research.title_of_review_letter}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>







              </div>





            ) : (<p></p>)

            }


            {research.type == "Professional Magazines" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title of article")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title_of_article && research.title_of_article}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Magazine name")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.magazine_name && research.magazine_name}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("issue No.")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.issue_no && research.issue_no}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>







              </div>





            ) : (<p></p>)

            }


            {research.type == "Journal Editorials" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Role")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.roleConf_details[0].role && research.roleConf_details[0].role}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Journal name")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.journal && research.journal}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Type")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.typesConfig_details[0].type && research.typesConfig_details[0].type}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>







              </div>





            ) : (<p></p>)

            }



            {research.type == "Acad.-Professional meetings" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Role")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.roleConf_details[0].role && research.roleConf_details[0].role}
                  </span>
                </div>
                {research && research.meeting_head_internal &&
                  <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                    {t("Meeting head (internal)")}:
                    <span style={{ fontWeight: "normal", color: "black" }}>
                      {`${research.meeting_head_internal_details[0].first_name} 
${research.meeting_head_internal_details[0].last_name}`}
                    </span>
                  </div>
                }


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Meeting head (external)")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.meeting_head_external && research.meeting_head_external}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title of meeting")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title_of_meeting && research.title_of_meeting}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Organization")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.organization && research.organization}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Location")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.location && research.location}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("From Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.from_date && research.from_date}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("To Date")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.to_date && research.to_date}
                  </span>
                </div>


                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>





              </div>





            ) : (<p></p>)

            }


            {research.type == "Thesis Supervesion" ? (
              <div>
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.title && research.title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Thesis Title")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.thesis_title && research.thesis_title}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("UG/PG")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.ug_pg_details[0].name && research.ug_pg_details[0].name}
                  </span>
                </div>

                {research && research.student_ids &&
                  <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                    {t("Students")}:

                    {research.student_details.map((student) => (
                      <div>
                        <span style={{ fontWeight: "normal", color: "black" }}>
                          {`${student.first_name} 
${student.last_name}`}
                        </span>
                        <br />
                      </div>
                    ))

                    }
                  </div>
                }
                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Research Direction")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.researchDirection_details[0] && research.researchDirection_details[0].name && research.researchDirection_details[0].name}
                  </span>
                </div>

                <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                  {t("Additional Information")}:
                  <span style={{ fontWeight: "normal", color: "black" }}>
                    {research && research.additional_information && research.additional_information}
                  </span>
                </div>







              </div>





            ) : (<p></p>)

            }





          </GridContainer>
        </CardBody>

        <CardFooter>
          <GridContainer xs={2} sm={2} md={12}>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "start" }}>
              <span
                style={{
                  color: "#FFCB3B",
                }}
              >
                {/* V {program && program.version} */}
              </span>
            </GridItem>
            <GridItem xs={2} sm={2} md={6} style={{ textAlign: "end" }}>
              <span
                style={{
                  color: research.state === "Draft" ? grayColor[1] : "#FFCB3B",
                }}
              >
                {/* {research && research.state} */}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function Activity({ match }) {
  const { t } = useTranslation();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes = useStyles();

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  const { id } = match.params;
  // console.log("id of course report ", id)
  const dispatch = useDispatch();
  const [current, setCurrent] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);

  const [showActivities, setShowActivities] = useState(true);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);

  const [open, setOpen] = React.useState(false);

  //bpmn
  const [diagram, diagramSet] = useState("");
  var container = document.getElementById("container");
  const [dynamicTask, setDynamicTask] = useState([]);

  const ProcessInstanceStatus = useSelector(
    (state) => state.camunda.ProcessInstanceStatus
  );
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const CourseReport = useSelector((state) => state.CourseReport.CourseReport);
  const Activities = useSelector((state) => state.Activity.Activities);
  const HistoricTasks = useSelector((state) => state.camunda.HistoricTasks);
  const diagramBPMN = useSelector((state) => state.camunda.Diagram);
  const ProcessDefinition = useSelector(
    (state) => state.camunda.ProcessDefinition
  );

  //************************** */

  const [activeStep, setActiveStep] = React.useState(0);
  const [activeState, setActiveState] = React.useState(null);

  const steps =
    activeState === "Draft" || activeState === "Waiting for review"
      ? ["Draft", "Waiting for review"]
      : activeState === "Reviewed"
        ? ["Draft", "Waiting for review", "Reviewed"]
        : activeState === "Rejected"
          ? ["Draft", "Waiting for review", "Rejected"]
          : [];

  const icons =
    activeState === "Draft" || activeState === "Waiting for review"
      ? {
        1: <CreateIcon />,
        2: <HourglassEmptyIcon />,
      }
      : activeState === "Reviewed"
        ? {
          1: <CreateIcon />,
          2: <HourglassEmptyIcon />,
          3: <DoneIcon />,
        }
        : activeState === "Rejected"
          ? {
            1: <CreateIcon />,
            2: <HourglassEmptyIcon />,
            3: <CloseIcon />,
          }
          : [];

  let { activityId } = useParams();
  let currentLanguage = localStorage.getItem("i18nextLng");

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchfacultyActivity = () => {
    dispatch(fetchResearchAndInnovationActivityById(activityId))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setActiveState(originalPromiseResult.state);
        setActiveStep(steps.indexOf(originalPromiseResult.state));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };

  useEffect(() => {
    fetchfacultyActivity();
  }, [activeState]);

  const researchActivity = useSelector(
    (state) =>
      state.researchAndInnovationActivities.researchAndInnovationActivity
  );
  const reseatchActivityStatus = useSelector(
    (state) =>
      state.researchAndInnovationActivities.researchAndInnovationActivityStatus
  );

  console.log("researchActivity", researchActivity);

  let contentt;

  if (reseatchActivityStatus === "loading") {
    contentt = <div className="loader">Loading...</div>;
  } else if (reseatchActivityStatus === "succeeded") {
    contentt = researchActivity && (
      <ResearchAndInnovationExcerpt
        key={researchActivity.id}
        research={researchActivity}
        personId={id}
      />
    );
  } else if (reseatchActivityStatus === "failed") {
    contentt = <div>An error has accured</div>;
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const UpdateState = async (value) => {
    let data = {
      updatedResearchAndInnovationActivity: { state: value },
      id: activityId,
    };
    const resultAction = await dispatch(
      updateResearchAndInnovationActivity(data)
    );

    if (updateResearchAndInnovationActivity.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      fetchfacultyActivity();
      openNotificationWithIcon("success", `updated successfully`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const handleSendForReview = () => {
    UpdateState("Waiting for review");
  };

  const handleAccept = () => {
    UpdateState("Reviewed");
  };

  const handleReject = () => {
    UpdateState("Rejected");
  };

  const handleReset = () => {
    UpdateState("Draft");
  };

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  //********************* */

  useEffect(() => {
    let key = "teachingStaffProcess";
    dispatch(GetProcessDefinitionByKey("teachingStaffProcess"))
      .then(unwrapResult)
      .then((ProcessDefinition) => {
        dispatch(GetDiagram(ProcessDefinition[0].id))
          .then(unwrapResult)
          .then((diagramBPMN) => {
            var str = diagramBPMN && diagramBPMN.bpmn20Xml;
            // console.log(str, "xml");
            // to remove space
            str = typeof str === "string" ? str.replace(/>\s*/g, ">") : ""; // Remove space after >
            str = typeof str === "string" ? str.replace(/\s*</g, "<", ">") : ""; // Remove space before <

            //console.log(str, "str");

            // to remove \n
            str =
              typeof str === "string"
                ? str.replace(new RegExp("\\n", "g"), "")
                : "";
            //console.log(str, "str1");

            // to parse xml
            var parser = new DOMParser();
            const xmlDoc = parser.parseFromString(str, "text/xml");
            // console.log(xmlDoc, "xmlDoc");

            var BpmnProcess = xmlDoc.getElementsByTagName("bpmn:process");
            console.log(BpmnProcess, "BpmnProcess");
            let Tasks = [];
            for (var i = 0; i < BpmnProcess.length; i++) {
              for (var j = 0; j < BpmnProcess[i].childNodes.length; j++) {
                var el = BpmnProcess[i].childNodes[j];
                if (el.tagName === "bpmn:userTask") {
                  console.log(el.attributes[1].nodeValue, "task");
                  Tasks.push(el.attributes[1].nodeValue);
                  console.log(Tasks, "Tasks");
                  setDynamicTask([...Tasks]);
                  console.log(dynamicTask, "dynamicTask");
                }
              }
            }
          });
      });
  }, []);

  useEffect(() => {
    // container = document.getElementById("container").innerHTML = "";
    let key = "teachingStaffProcess";
    dispatch(GetProcessDefinitionByKey("teachingStaffProcess"))
      .then(unwrapResult)
      .then((ProcessDefinition) => {
        dispatch(GetDiagram(ProcessDefinition[0].id))
          .then(unwrapResult)
          .then((diagramBPMN) => {
            diagramSet(diagramBPMN && diagramBPMN.bpmn20Xml);
          });
      });
  }, [diagram]);

  useEffect(() => {
    dispatch(fetchStaffById(id));
    dispatch(fetchActivities());
  }, [dispatch]);

  const user = useSelector((state) => state.camunda.user);
  const person = useSelector((state) => state.user.staff);

  useEffect(() => {
    setStateName(person && person.person_details[0].state && person.person_details[0].state);
  }, [person]);

  const SendForReview = () => {
    setStateName("Waiting for review");
    try {
      let modifiedPerson = {
        state: "Waiting for review",
      };
      console.log("modifiedPerson", modifiedPerson);
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedStaff: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Waiting for review");
    } catch (err) {
      console.log("Failed to save the person: ", err);
    } finally {
    }
  };

  const MakeReviewed = () => {
    setStateName("Reviewed");
    try {
      let modifiedPerson = {
        state: "Reviewed",
      };
      console.log("modifiedPerson", modifiedPerson);
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Reviewed");
    } catch (err) {
      console.log("Failed to save the person: ", err);
    } finally {
    }
  };

  const useCardStyles = makeStyles(cardStyles);


  const ResetTODraft = () => {
    setStateName("Created");
    try {
      let modifiedPerson = {
        state: "Created",
      };
      console.log("modifiedPerson", modifiedPerson);
      const resultAction = dispatch(
        updateStateStaff({
          id: id,
          updatedPerson: { ...modifiedPerson },
        }),
        dispatch(fetchStaff())
      );
      unwrapResult(resultAction);
      const resultAction1 = dispatch(fetchStaff());
      unwrapResult(resultAction1);
      setStateName("Created");
    } catch (err) {
      console.log("Failed to save the person: ", err);
    } finally {
    }
  };

  const submitComplete = () => {
    if (stateName === "Created") {
      SendForReview();
    }
    if (stateName.toLowerCase().includes("waiting for review")) {
      MakeReviewed();
    }
  };

  if (!person) {
    return (
      <section>
        <h2> {t("faculty not found!")}</h2>
      </section>
    );
  }
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const callback = (key) => {
    console.log(key);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //bmpn Modal
  const handleClickOpen = () => {
    setShow(true);

    setOpen(true);
    setShowDiagram(true);
    setShowActivities(false);
    setShowTasks(false);
    setShowReports(false);
    setShowAttachment(false);

    if (diagram.length > 0) {
      const modeler = new Modeler({
        container,
        keyboard: {
          bindTo: document,
        },
      });
      modeler
        .importXML(diagram)
        .then(({ warnings }) => {
          if (warnings.length) {
            console.log("Warnings", warnings);
          }

          const canvas = modeler.get("modeling");
          canvas.setColor("CalmCustomerTask", {
            stroke: "green",
            fill: "yellow",
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDiagram(false);
    setShowActivities(true);

    container = document.getElementById("container").innerHTML = "";
  };

  const renderRow = () => {
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: "gray" }}
              >
                {Moment(item.DueDate).format("YYYY-MM-DD")}
              </Divider>

              <div
                style={{
                  backgroundColor: "#edeff1",
                  borderBottom: "1px solid #cfd5db",
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={3}>
                    <Avatar
                      style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={9}>
                    <p>
                      <strong>{item.AssignedTo}</strong>
                    </p>
                  </GridItem>
                </GridContainer>

                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={12}>
                    <ul>
                      <li>{item.Summary}</li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (showTasks) {
      return (
        <div>
          {HistoricTasks &&
            HistoricTasks.map((item) => (
              //.filter((item, index) => index !== 0)
              <div>
                <Divider
                  type="horizontal"
                  orientation="center"
                  style={{ color: "gray" }}
                >
                  {Moment(item.startTime).format("YYYY-MM-DD")} ---{" "}
                  {Moment(item.endTime).format("YYYY-MM-DD")}
                </Divider>

                <div
                  style={{
                    backgroundColor: "#edeff1",
                    borderBottom: "1px solid #cfd5db",
                  }}
                >
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem key={Element.id} xs={12} sm={12} md={2}>
                      <Avatar
                        style={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                        }}
                      >
                        {user && user.firstName[0] + user.lastName[0]}
                      </Avatar>
                    </GridItem>
                    <GridItem key={Element.id} xs={12} sm={12} md={10}>
                      <p>
                        <strong>
                          {user && user.firstName + " " + user.lastName}
                        </strong>
                      </p>

                      <ul>
                        <li> Task : {item.name}</li>
                      </ul>

                      <ul>
                        <li>Operation : {item.deleteReason}</li>
                      </ul>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            ))}
        </div>
      );
    } else if (showDiagram) {
      return (
        <div>
          <button onClick={(event) => handleClickOpen(event)}>
            Show Diagram
          </button>
          <Modal title="My Modal" onClose={() => setShow(false)} show={show}>
            rrrrrr
            {/* <div className="App">
              <div
                id="container"
                style={{
                  border: "1px solid #000000",
                  height: "90vh",
                  width: "90vw",
                  margin: "auto",
                }}
              ></div>
            </div> */}
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          {/* <UploadAttachmentPerson id={person && person.id} /> */}
          <UploadResearchInnovation
            id={researchActivity && researchActivity.id}
          />
        </div>
      );
    }
  };
  const renderTasks = () => {
    setShowTasks(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowReports(false);
    setShowAttachment(false);
    dispatch(GetUserProfile());
    dispatch(GetHistoricTasks(CourseReport.process_identification));
  };
  const renderActivities = () => {
    setShowActivities(true);
    setShowDiagram(false);
    setShowTasks(false);
    setShowAttachment(false);
    setShowReports(false);
  };
  const renderDiagram = () => {
    setShowReports(false);
    setShowActivities(false);
    setShowDiagram(true);
    setShowTasks(false);
    setShowAttachment(false);
  };

  const renderAttachment = () => {
    setShowReports(false);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
    setShowAttachment(true);
  };

  let content;
  if (stateName === "Created") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === "Created" ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  } else if (
    stateName &&
    stateName.toLowerCase().includes("waiting for review")
  ) {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <div>
            <Button
              style={{ backgroundColor: "lightgery" }}
              size="sm"
              className={classes.marginRight}
              onClick={() => ResetTODraft()}
            >
              <span style={{ color: "white", fontSize: "11px" }}>
                {t("Reset tO draft")}
              </span>
            </Button>
          </div>
          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el && el.toLowerCase().includes("waiting for review") ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  } else if (stateName === "Reviewed") {
    content = (
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "100%",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            //margin: "0px 25px 0px 20px",
            paddingLeft: "10px",
          }}
        >
          <div>
            <CompleteCourseReportModal
              submitComplete={submitComplete}
              CourseReport={CourseReport}
              handleDropDownButtonClose={handleClose}
            />
          </div>

          <ol class="progress-bar" style={{ width: "60%", height: "40px" }}>
            {dynamicTask &&
              dynamicTask.map((el) => {
                return el === "Reviewed" ? (
                  <li
                    class="progress-bar__steps current"
                    style={{
                      width: "auto",
                      paddingTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                ) : (
                  <li
                    class="progress-bar__steps not-current"
                    style={{ width: "auto", paddingTop: "10px" }}
                  >
                    <span class="progress-bar__steps--text">{el}</span>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div>
      <GridItem>
        {/* <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.first_name} ${person && person.last_name}`}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb> */}

        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>{t("Faculty")}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff`}>
              {t("Teaching Staff")}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/citrine/faculty/teachingStaff/${id}`}>
              {`${person && person.person_details[0].first_name} ${person && person.person_details[0].last_name}`}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/teachingStaff/${id}/researchAndInnovationActivities`}
            >
              {t("Research and innovation")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <div>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem key={Element.id} xs={12} sm={12} md={9}>
            <div className={classes.root}>
              {activeState && (
                <div>
                  <Card>
                    <GridContainer alignItems="center">
                      <GridItem xs={3} sm={3} md={3}>
                        {activeState === "Draft" && (
                          <div>
                            <Button
                              color="primary"
                              onClick={handleSendForReview}
                            >
                              Send for review
                            </Button>
                          </div>
                        )}
                        {activeState === "Waiting for review" && (
                          <div>
                            <Button color="info" onClick={handleReset}>
                              Reset
                            </Button>
                            <Button color="primary" onClick={handleAccept}>
                              Accept
                            </Button>
                            <Button color="primary" onClick={handleReject}>
                              Reject
                            </Button>
                          </div>
                        )}
                        {activeState === "Reviewed" && (
                          <div>
                            <Button color="info" onClick={handleReset}>
                              Reset
                            </Button>
                          </div>
                        )}
                        {activeState === "Rejected" && (
                          <div>
                            <Button color="info" onClick={handleReset}>
                              Reset
                            </Button>
                          </div>
                        )}
                      </GridItem>
                      <GridItem xs={9} sm={9} md={9}>
                        <ThemeProvider theme={theme}>
                          <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            connector={
                              currentLanguage === "ar" ? (
                                false
                              ) : (
                                <ColorlibConnector />
                              )
                            }
                          >
                            {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                  {label}
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </ThemeProvider>
                      </GridItem>
                    </GridContainer>
                  </Card>
                </div>
              )}

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem key={Element.id} xs={12} sm={12} md={12}>
                  {contentt}
                </GridItem>
              </GridContainer>
              <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
              />
            </div>
          </GridItem>

          <GridItem key={Element.id} xs={12} sm={12} md={3}>
            <div
              style={{
                padding: "10px",
                borderRadius: "0px",
                color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                background: whiteColor,
                width: "100%",
                boxShadow:
                  "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                minWidth: "0",
                wordWrap: "break-word",
                fontSize: ".875rem",
                height: "82vh",
                border: "1px solid lightgray",
              }}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer justify="space-around">
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderActivities}
                      >
                        <FaHistory
                          color={showActivities ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderTasks}
                      >
                        <FaTasks
                          color={showTasks ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderDiagram}
                      >
                        <FaProjectDiagram
                          color={showDiagram ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderAttachment}
                      >
                        <AttachFileIcon
                          color={showAttachment ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div class="card scrollable">
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "0px",
                        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "0",
                        wordWrap: "break-word",
                        fontSize: ".875rem",
                        height: "68vh",
                      }}
                    >
                      {renderRow()}
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
