import {createSlice, nanoid, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';

const initialState = {
    TeachingStrategiesApprovementConfig: [],
    TeachingStrategiesConfigStatus: 'idle',
    error: null,
    TeachingStrategieConfig: null,
    id: null
}

export const fetchTeachingStrategiesConfig =
    createAsyncThunk('TeachingStrategieConfig/fetchTeachingStrategiesConfig',
        async (param, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.get(BaseUrl + `/teachingStrategiesConfig/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

export const addNewTeachingStrategieConfig = createAsyncThunk(
    'TeachingStrategieConfig/addNewTeachingStrategieConfig',
    async (initialTeachingStrategieConfig, {rejectWithValue}) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.post(BaseUrl + `/teachingStrategiesConfig/create/`, initialTeachingStrategieConfig, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const fetchTeachingStrategieConfigById =
    createAsyncThunk('TeachingStrategieConfig/fetchTeachingStrategieConfigById',
        async (id, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.get(BaseUrl + `/teachingStrategiesConfig/${id}/`, {
                    headers: {
                        'Authorization': `token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

export const teachingStrategieUpdate = createAsyncThunk(
    'TeachingStrategieConfig/teachingStrategieConfigUpdate',
    async (data, {rejectWithValue}) => {
        try {
            const token = localStorage.getItem('token')
            const response = await axios.put(BaseUrl + `/teachingStrategiesConfig/${data.id}/edit/`, data.modified, {
                headers: {
                    'Authorization': `token ${token}`
                }
            })
            return response.data
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response);
        }
    })

export const deleteTeachingStrategie =
    createAsyncThunk('TeachingStrategieConfig/deleteTeachingStrategie',
        async (id, {rejectWithValue}) => {
            const token = localStorage.getItem('token')
            try {
                const response = await axios.delete(BaseUrl + `/teachingStrategiesConfig/${id}/delete/`, {
                    headers: {
                        'Authorization': `token ${token}`
                    }
                })
                return response.data
            } catch (err) {
                let error = err;
                if (!error.response) {
                    throw err;
                }
                return rejectWithValue(error.response);
            }
        })

const TeachingStrategieConfigSlice = createSlice({
    name: 'TeachingStrategieConfig',
    initialState,
    extraReducers: {
        [fetchTeachingStrategiesConfig.pending]: (state, action) => {
            state.TeachingStrategiesConfigStatus = 'loading'
        },
        [fetchTeachingStrategiesConfig.fulfilled]: (state, action) => {
            state.TeachingStrategiesConfigStatus = 'succeeded'
            state.TeachingStrategiesApprovementConfig = action.payload
        },
        [fetchTeachingStrategiesConfig.rejected]: (state, action) => {
            state.TeachingStrategiesConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [fetchTeachingStrategieConfigById.pending]: (state, action) => {
            state.TeachingStrategieConfigStatus = 'loading'
        },
        [fetchTeachingStrategieConfigById.fulfilled]: (state, action) => {
            state.TeachingStrategieConfigStatus = 'succeeded'
            state.TeachingStrategieConfig = action.payload
        },
        [fetchTeachingStrategieConfigById.rejected]: (state, action) => {
            state.TeachingStrategieConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [addNewTeachingStrategieConfig.fulfilled]: (state, action) => {
            state.TeachingStrategiesConfig.push(action.payload)
        },
        [addNewTeachingStrategieConfig.rejected]: (state, action) => {
            state.TeachingStrategieConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [deleteTeachingStrategie.fulfilled]: (state, action) => {},
        [deleteTeachingStrategie.rejected]: (state, action) => {
            state.TeachingStrategieConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        [teachingStrategieUpdate.fulfilled]: (state, action) => {},
        [teachingStrategieUpdate.rejected]: (state, action) => {
            state.TeachingStrategieConfigStatus = 'failed'
            if (action.payload) {
                state.error = action.payload
            } else {
                state.error = action.error
            }
        },

        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },
        }
    }
})

export const {getId} = TeachingStrategieConfigSlice.actions

export default TeachingStrategieConfigSlice.reducer



