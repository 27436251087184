import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";

const MissionStatement = ({ data }) => {
  return (
    <>
      <h3> PROGRAM EDUCATIONAL OBJECTIVES: Mission Statement</h3>

      <p className={styles.reportParagraphTitle}>
        The following is the institutional mission statement:
      </p>

      <p>{data?.institutionMissionStatement}</p>

      <br />

      <p className={styles.reportParagraphTitle}>
        The following is the college mission statement:
      </p>

      <p>{data?.collegeMissionStatement}</p>

      <br />

      <p className={styles.reportParagraphTitle}>
        The following is the program mission statement:
      </p>

      <p>{data?.programMissionStatement}</p>
    </>
  );
};

export default MissionStatement;
