import React, { useEffect, useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch } from 'react-redux'

// core components
/// test
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'

import 'antd/dist/antd.css'

import Dialog from '@material-ui/core/Dialog'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Form, Input, notification, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import ErrorModal from '../../../Components/ErrorModal'

import { FormOutlined } from '@ant-design/icons'
import { warningColor } from '../../../../assets/jss/material-dashboard-pro-react'
import { fetchKpiReportById } from '../../../../reduxToolkit/features/KpiReport/KpiReportSlice'
import { updateStandard } from '../../../../reduxToolkit/features/standardConfig/StandardConfigSlice.jsx'

import { fetchAll_standards_KPIsCollege } from '../../../../reduxToolkit/features/KPI/KPICollegeSlice.jsx'
import { fetchAll_standards_KPIsDepartment } from '../../../../reduxToolkit/features/KPI/KPIDepartmentSlice'
import { fetchAll_standards_KPIsProgram } from '../../../../reduxToolkit/features/KPI/KPIProgramSlice'
import { fetchAll_standards_KPIsUniversity } from '../../../../reduxToolkit/features/KPI/KPIUniversitySlice.jsx'

const { TextArea } = Input

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
}

const useTitleStyles = makeStyles(titleStyles)
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))

export default function EditStandardsOverAnalysisModal(props) {
  console.log(props)
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const courseId = props.courseId
  const [form] = Form.useForm()

  const [course_id, setCourse_id] = useState(props.course_id)

  const { Option } = Select

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  useEffect(() => {
    dispatch(fetchAll_standards_KPIsCollege())
    dispatch(fetchAll_standards_KPIsDepartment())
    dispatch(fetchAll_standards_KPIsProgram())
    dispatch(fetchAll_standards_KPIsUniversity())
    form.setFieldsValue({
      overall_analysis_of_standard: props.overall_analysis_of_standard,
    })
  }, [])

  const onFinish = async (values) => {
    console.log(props.standard.standard_type_code)
    console.log(props.standard.standard_type_name)
    const updated = {
      ...values,
      code: props.standard.standard_type_code,
      name: props.standard.standard_type_name,
    }
    console.log(updated, 'updated')
    console.log(props.standard.id, 'props.standard.id')
    const resultAction = await dispatch(
      updateStandard({
        id: props.standard.id,
        ...values,
        code: props.standard.standard_type_code,
        type: props.standard.standard_type_name,
      }),
    )
    if (updateStandard.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `Edit successfully`)

      dispatch(fetchAll_standards_KPIsCollege())
      dispatch(fetchAll_standards_KPIsDepartment())
      dispatch(fetchAll_standards_KPIsProgram())
      dispatch(fetchAll_standards_KPIsUniversity())

      dispatch(fetchKpiReportById(props.kpiReport_id))

      //form.resetFields();
      handleClose()
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
        // form.resetFields();
        handleClose()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
        // form.resetFields();
        handleClose()
      }
    }
  }

  return (
    <div>
      <FormOutlined
        onClick={handleClickOpen}
        style={{ color: warningColor[0], fontSize: '18px' }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t('Edit Overall Analysis of standard')}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                //{...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="overall_analysis_of_standard"
                  rules={[
                    {
                      required: true,
                      message:
                        'Please input the overall_analysis_of _standard field.',
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Enter Overall Analysis" />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: '20px' }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
