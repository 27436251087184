function isDate2GreaterThanDate1(date1, date2) {
    // Check if either input is not a Date object or a string
    if (!(date1 instanceof Date || typeof date1 === 'string') ||
        !(date2 instanceof Date || typeof date2 === 'string')) {
        console.log("Invalid date input");
        return false;
    }

    const parseDate = (date) => {
        if (date instanceof Date) {
            return date;
        } else {
            const parsedDate = new Date(date);
            return isNaN(parsedDate.getTime()) ? null : parsedDate;
        }
    };

    const parsedDate1 = parseDate(date1);
    const parsedDate2 = parseDate(date2);

    // Check if either parsed date is null (invalid date string)
    if (!parsedDate1 || !parsedDate2) {
        console.log("Invalid date input");
        return false;
    }
    console.log("parsedDate2.getTime()", parsedDate2.getTime())
    console.log("parsedDate1.getTime()", parsedDate1.getTime())

    return parsedDate2.getTime() >= parsedDate1.getTime();
}
export default isDate2GreaterThanDate1