// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Select, notification, Form } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchVerificationOfCredibilities,
} from "../../../../../reduxToolkit/features/VerificationOfCredibility/VerificationOfCredibilitySlice";
import useTable from "../../../../Components/useTable";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import {
  fetchConclusionsDifficultiesByCourseId_And_Type,
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";
import EditVerificationOfCredibilityDifficultiesAction from "../../.././../Components/EditVerificationOfCredibilityDifficultiesAction";

import {
  primaryColor,
} from "../../../../../assets/jss/material-dashboard-pro-react";

const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 14,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 14,
    span: 0,
  },
};

const headCells = [
  { id: "N°", label: "N°" },
  { id: "Method(s) of Verification", label: "Method(s) of Verification" },
];

export default function VerificationOfCredibility(props) {

  const [records, setRecords] = [];

  const { Option } = Select;
  const [form] = Form.useForm();

  let object = {
    course_id: props.idCourse,
    type: "VC",
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const titleClasses = useTitleStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const course = useSelector((state) => state.course.course);
  const VerificationOfCredibilities = useSelector(
      (state) =>
          state.VerificationOfCredibilityAssessment.VerificationOfCredibilities
  );
  const difficulties_SuggestedAction = useSelector((state) => state.ConclusionsDifficulties.ConclusionsDifficultiesList);

  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [value, setValue] = useState(1);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [
    verificationCredibilityStudentsResults_ids,
    setVerificationOfCredibilities_ids,
  ] = useState("");
  const [array_difficulties_SuggestedAction, setArray_difficulties_SuggestedAction] = useState([]);

  const {
    TblContainer,
    TblHead,
  } = useTable(records, headCells, filterFn);

  useEffect(() => {
    dispatch(fetchVerificationOfCredibilities(props.idCourse))
        .then(unwrapResult)
        .then((VerificationOfCredibilities) => {
          setVerificationOfCredibilities_ids(
              VerificationOfCredibilities.map((el) => el.id)
          );
        });
  }, []);

  useEffect(() => {
    dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
        .then(unwrapResult)
        .then((difficulties_SuggestedAction) => {
          setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
        });
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchConclusionsDifficulties =()=>{
    dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
    .then(unwrapResult)
      .then((difficulties_SuggestedAction) => {
        setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
      });
  }
  return (
    <GridContainer xs={12} sm={12} md={12}>
      <div
        // class="card" class="scrollable"
        style={{ width: "100%" }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={10}>
              <TblContainer style={{ marginTop: "0px", paddingTop: "0px" }}>
                <TblHead />
                <TableBody>
                  {VerificationOfCredibilities &&
                    VerificationOfCredibilities.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell
                          style={{
                            borderRight: "1px solid lightgrey",
                            borderLeft: "1px solid lightgrey",
                          }}
                        >
                          {item && item.id}
                        </TableCell>
                        <TableCell
                          style={{ borderRight: "1px solid lightgrey" }}
                        >
                          {item &&
                            item.method_verification_name &&
                            item.method_verification_name}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}></GridItem>
            <GridItem xs={12} sm={12} md={2}></GridItem>
          </GridContainer>
        </GridItem>
        <Card style={{ marginRight: "10px" }}>
          <CardBody>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={10}>
                <p style={{ height: "150px" }}>
                  <strong style={{ color: primaryColor[0] }}>
                    Conclusions
                  </strong>{" "}
                  :
                  <p>
                    {array_difficulties_SuggestedAction &&
                      array_difficulties_SuggestedAction[0] &&
                      array_difficulties_SuggestedAction[0].conclusions}
                  </p>
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={2} style={{ textAlign: "end" }}>
                <EditVerificationOfCredibilityDifficultiesAction
                  idCourse={props.idCourse}
                  fetchConclusionsDifficulties={fetchConclusionsDifficulties}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </GridContainer>
  );
}
