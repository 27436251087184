import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAnnualProgramReport,
  fetchAnnualProgramReports,
} from "../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
//import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { notification, Select } from "antd";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import ErrorModal from "./ErrorModal.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";

export default function AnnualProgramReportModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [type, setType] = React.useState("NCAAA");
  const handleChange = (event) => {
    console.log(event.target.value);
    setType(event.target.value);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = async () => {
    const resultAction = await dispatch(
      addNewAnnualProgramReport({ program_id: props.ProgramID })
    );
    if (addNewAnnualProgramReport.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", ` successfully Added`);
      history.push(`/citrine/reports/${type}/annualProgram`);
      dispatch(fetchAnnualProgramReports());
      handleClose();
    } else {
      console.log("resultAction", resultAction);
      if (resultAction.payload) {
        setHandledError(resultAction.payload);

        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);

        handleOpenErrorModal();
        handleClose();
      }
    }
  };

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
        }}
      >
        <h6>{t("Create Program Report")}</h6>
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Create Annual Program Report")}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <h4>Accreditation body</h4>
            <RadioGroup
              aria-label="Accreditation body"
              name="accreditation_body"
              value={type}
              onChange={handleChange}
            >
              <FormControlLabel
                value="NCAAA"
                control={<Radio size="small" color="primary" />}
                label="NCAAA"
              />
              <FormControlLabel
                value="CTI"
                control={<Radio size="small" color="primary" />}
                label="CTI"
              />
              <FormControlLabel
                value="ABET"
                control={<Radio size="small" color="primary" />}
                label="ABET"
              />
            </RadioGroup>
          </FormControl>

          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                handleSend();
              }}
              style={{ paddingRight: "30px" }}
              color="danger"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleSend();
              }}
              color="info"
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
