import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  KPIs: [],
  standards_KPIsUniversity: [],
  KPIsStatus: "idle",
  KPI: null,
  KPIStatus: "idle",
  error: null,
  filteredKPIconfig: [],
  filteredKPIconfigStatus: "idle",
  standards_KPIsUniversityStatus: "idle",
};

export const fetchKPIsUniversity = createAsyncThunk(
  "KPIUniversity/fetchKPIsUniversity",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/kpiUniversity/?university_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchAll_standards_KPIsUniversity = createAsyncThunk(
  "KPIUniversity/fetchAll_standards_KPIsUniversity",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/kpiUniversity/standards/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const fetchNotAffectedKPIConfigToUniversity = createAsyncThunk(
  "KPIUniversity/fetchNotAffectedKPIConfigToUniversity",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        BaseUrl + `/kpiUniversity/kpiConfig_filtered/?university_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

// export const fetchKPIProgramById = createAsyncThunk('KPIUniversity/fetchKPIProgramById', async (id, { rejectWithValue }) => {
//   try {

//     const token = localStorage.getItem('token')
//     const response = await axios.get(BaseUrl + `/kpiProgram/${id}/`, {
//       headers: {
//         'Authorization': `token ${token}`
//       }
//     })
//     // console.log(response.data,'response.data')

//     return response.data
//   } catch (err) {
//     let error = err // cast the error for access
//     if (!error.response) {
//       throw err
//     }
//     // We got validation errors, let's return those so we can reference in our component and set form errors
//     console.log('error.response from inside the CAT', error.response)
//     return rejectWithValue(error.response)
//   }
// })

export const addNewKPIUniversity = createAsyncThunk(
  "KPIUniversity/addNewKPIUniversity",
  async (initialKPIUniversity, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/kpiUniversity/create/`,
        initialKPIUniversity,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteKPIUniversity = createAsyncThunk(
  "KPIUniversity/deleteKPIUniversity",
  async (ids, { rejectWithValue }) => {
    
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + `/kpiUniversity/delete/`,ids,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

const KPIUniversitySlice = createSlice({
  name: "KPIUniversity",
  initialState,
  extraReducers: {
    [fetchKPIsUniversity.pending]: (state, action) => {
      state.KPIsStatus = "loading";
    },
    [fetchKPIsUniversity.fulfilled]: (state, action) => {
      state.KPIsStatus = "succeeded";
      // Add any fetched posts to the array
      state.KPIs = action.payload;
    },
    [fetchKPIsUniversity.rejected]: (state, action) => {
      state.KPIsStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [fetchNotAffectedKPIConfigToUniversity.pending]: (state, action) => {
      state.filteredKPIconfigStatus = "loading";
    },
    [fetchNotAffectedKPIConfigToUniversity.fulfilled]: (state, action) => {
      state.filteredKPIconfigStatus = "succeeded";
      // Add any fetched posts to the array
      state.filteredKPIconfig = action.payload;
    },
    [fetchNotAffectedKPIConfigToUniversity.rejected]: (state, action) => {
      state.filteredKPIconfigStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [fetchAll_standards_KPIsUniversity.pending]: (state, action) => {
      state.standards_KPIsUniversityStatus = "loading";
    },
    [fetchAll_standards_KPIsUniversity.fulfilled]: (state, action) => {
      state.standards_KPIsUniversityStatus = "succeeded";
      // Add any fetched posts to the array
      state.standards_KPIsUniversity = action.payload;
    },
    [fetchAll_standards_KPIsUniversity.rejected]: (state, action) => {
      state.standards_KPIsUniversityStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },

    [addNewKPIUniversity.rejected]: (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload.statusText
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },
  },
});

export default KPIUniversitySlice.reducer;
