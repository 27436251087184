import { makeStyles } from "@material-ui/core/styles";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import pagesHeaderstyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle";
import { grayColor, primaryColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchCollegeById } from '../../../../reduxToolkit/features/college/CollegesSlice';



const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);


const DepartmentExcerpt = ({ department }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();


  return (
    <GridItem xs={12} sm={6} md={4}>
      <Card style={{ height: 300 }} >
        <CardHeader>
          <GridContainer justify="center">
            <GridItem>
              <h3 style={{ color: `${primaryColor[0]}` }}>
                {department.name}
              </h3>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem>
              <h4>
                {`${department.mission.substring(0, 100)}...`}
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem>
              <Link to={`/citrine/organizations/departments/${department.id}`} ><Button color='primary'>View</Button></Link>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function DepartmentsByCollege({ match }) {
  const { t } = useTranslation();

  const dispatch = useDispatch()

  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();
  const classes = useStyles();

  const { id } = match.params

  const collegeStatus = useSelector((state) => state.college.collegeStatus)

  useEffect(() => {
    dispatch(fetchCollegeById(id))
  }, [id, dispatch])

  const college = useSelector((state) => state.college.college)

  let content

  if (collegeStatus === 'loading') {
    content = <div className="loader">{t('Loading...')}</div>
  } else if (collegeStatus === 'succeeded') {
    content = college && college.departments && college.departments.length && college.departments.map(department => (
      <DepartmentExcerpt key={department.id} department={department} />
    ))
  }

  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <span>
            <Link to={`/citrine/organizations`}>{t('Organizations')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/colleges`}> {t('/Colleges')}</Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/colleges/${college && college.id}`}> {`/${college && college.name}`} </Link>
          </span>
          <span>
            <Link to={`/citrine/organizations/colleges/${id}/departments`}> {t('/Departments')} </Link>
          </span>
        </GridItem>
      </GridContainer>

      <GridContainer >
        {content}
      </GridContainer>
    </div>
  );
}













