import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (loginData, thunkAPI) => {
    try {
      const response = await axios.post(
        BaseUrl + "/accounts/login/",
        loginData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserById = createAsyncThunk("user/getUserById", async (id) => {
  if (!id) {
    return
  }
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + `/user/${id}/`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
});

export const getCurrentUserByToken = createAsyncThunk(
  "user/getCurrentUserByToken",
  async (rejectWithValue) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `/accounts/user-details/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + "/user/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
});

export const fetchStudents = createAsyncThunk(
  "user/fetchStudents",
  async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + "/student/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  }
);

export const fetchStaff = createAsyncThunk("user/fetchStaff", async () => {
  const token = localStorage.getItem("token");

  const response = await axios.get(BaseUrl + "/staff/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
});

export const fetchStaffFaculty = createAsyncThunk("user/fetchStaffFaculty", async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + "/staff/faculty/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
});

export const addNewStaff = createAsyncThunk(
  "user/addNewStaff",
  async (initialUser) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/staff/create/`,
      initialUser,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const updateStaff = createAsyncThunk(
  "user/updateStaff",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/staff/${data.id}/edit/`,
        data.updated,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
    }
  }
);

export const fetchStaffById = createAsyncThunk(
  "user/fetchStaffById",
  async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    const token = localStorage.getItem('token')

    try {
      const response = await axios.get(BaseUrl + `/staff/${id}`,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data)
    }
  }
);

export const updateStateStaff = createAsyncThunk(
  "user/updateStateStaff",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/staff/${data.id}/teachingStaff/update/`,
        data.updatedPerson,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const addNewUser = createAsyncThunk(
  "user/addNewUser",
  async (initialUser, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/accounts/register/`,
        initialUser,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    }
    catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  });

export const selectedUserById = createAsyncThunk(
  "user/selectedUserById",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/user/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/user/logout/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/user/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) { }
  }
);

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAllUsers",
  async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/user/by_group/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const deleteUser = createAsyncThunk("user/deleteUser", async (id) => {
  const token = localStorage.getItem("token");
  const response = await axios.delete(BaseUrl + `/user/${id}/delete/`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
});

export const updateAccountPassword = createAsyncThunk(
  "user/updateAccountPassword",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/accounts/${data.id}/edit/`,
        data.updatedAccount,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response);
    }
  }
);

export const fetchUsersByTypeAndCourseIds = createAsyncThunk(
  "user/fetchUsersByTypeAndCourseIds",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
        `/user/participants/?type=${data.type}&courses_ids=${data.ids}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    currentUser: null,
    userId: null,
    studentsStatus: "idle",
    status: null,
    isLoggedOut: false,
    users: [],
    students: null,
    usersStatus: "idle",
    userStatus: "idle",
    error: null,
    user: null,
    id: null,
    staffStatus: "idle",
    staffList: [],
    staffByIdStatus: "idle",
    staff: null,
    staffFaculty: [],
    isLoggedIn: false,
    loading: 'idle',
    currentRequestId: undefined,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.userStatus = "idle";
      return state;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("userId", action.payload.id);
      // localStorage.setItem("username", action.payload.username);
    },

    [getUserById.pending]: (state, action) => {
      state.userStatus = "loading";
    },
    [getUserById.fulfilled]: (state, action) => {
      state.userStatus = "succeeded";
      state.user = action.payload;
      state.status = action.payload.status;
    },
    [getUserById.rejected]: (state, action) => {
      state.userStatus = "failed";
    },

    [getCurrentUserByToken.pending]: (state, action) => {
      state.isFetching = true;
    },
    [getCurrentUserByToken.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentUser = action.payload;
    },
    [getCurrentUserByToken.rejected]: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },

    [fetchUsers.pending]: (state, action) => {
      state.usersStatus = "loading";
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.usersStatus = "succeeded";
      state.users = action.payload;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.usersStatus = "failed";
      state.error = action.payload;
    },

    [fetchStaff.pending]: (state, action) => {
      state.staffStatus = "loading";
    },
    [fetchStaff.fulfilled]: (state, action) => {
      state.staffStatus = "succeeded";
      state.staffList = action.payload;
    },
    [fetchStaff.rejected]: (state, action) => {
      state.staffStatus = "failed";
      state.error = action.payload;
    },

    [fetchStaffFaculty.pending]: (state, action) => {
      state.staffStatus = "loading";
    },
    [fetchStaffFaculty.fulfilled]: (state, action) => {
      state.staffStatus = "succeeded";
      state.staffFaculty = action.payload;
    },
    [fetchStaffFaculty.rejected]: (state, action) => {
      state.staffStatus = "failed";
      state.error = action.payload;
    },

    [fetchStaffById.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchStaffById.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.staff = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchStaffById.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },

    [updateStateStaff.fulfilled]: (state, action) => { },
    [updateStateStaff.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchStudents.pending]: (state, action) => {
      state.studentsStatus = "loading";
    },
    [fetchStudents.fulfilled]: (state, action) => {
      state.studentsStatus = "succeeded";
      state.students = action.payload;
    },
    [fetchStudents.rejected]: (state, action) => {
      state.studentsStatus = "failed";
      state.error = action.payload;
    },

    [fetchAllUsers.pending]: (state, action) => {
      state.userStatus = "loading";
    },
    [fetchAllUsers.fulfilled]: (state, action) => {
      state.userStatus = "succeeded";
      state.users = action.payload;
    },
    [fetchAllUsers.rejected]: (state, action) => {
      state.userStatus = "failed";
    },

    [selectedUserById.pending]: (state, action) => {
      state.userStatus = "loading";
    },
    [selectedUserById.fulfilled]: (state, action) => {
      state.userStatus = "succeeded";
      state.user = action.payload;
    },
    [selectedUserById.rejected]: (state, action) => {
      state.userStatus = "failed";
    },

    [addNewUser.fulfilled]: (state, action) => {
      state.users.push(action.payload);
    },
    [addNewUser.rejected]: (state, action) => {
      state.userStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [logoutUser.rejected]: (state, action) => { },
    [logoutUser.fulfilled]: (state, action) => {
      state.isLoggedOut = true;
    },

    [updateUser.fulfilled]: (state, action) => {
      const result = state.users.filter(
        (user) => user.id !== action.payload.id
      );
      result.push(action.payload);
      state.users = result;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
