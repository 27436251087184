import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  Educations: [],
  EducationsStatus: "idle",
  EducationStatus: "idle",
  error: null,
  Education: null,
};

export const fetchEducationsByPersonId = createAsyncThunk(
  "education/fetchEducationsByPersonId",
  async (person_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/education/byStaff/?staff_id=${person_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);

export const addNewEducation = createAsyncThunk(
  "education/addNewEducation",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/education/create/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateEducation = createAsyncThunk(
  "education/UpdateEducation",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/education/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "education");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the education: ", error);
    }
  }
);


export const deleteEducation = createAsyncThunk(
  'education/deleteEducation', async (id) => {
  const token=localStorage.getItem('token')
  const response = await axios.delete(BaseUrl+`/education/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
   

  return response.data
})



const EducationSlice = createSlice({
  name: "education",
  initialState,
  extraReducers: {
    [fetchEducationsByPersonId.pending]: (state, action) => {
      state.EducationsStatus = "loading";
    },
    [fetchEducationsByPersonId.fulfilled]: (state, action) => {
      state.EducationsStatus = "succeeded";
      // Add any fetched posts to the array
      state.Educations = action.payload;
    },
    [fetchEducationsByPersonId.rejected]: (state, action) => {
      state.EducationsStatus = "failed";
      state.error = action.payload;
    },

    [addNewEducation.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },

    [UpdateEducation.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
     [deleteEducation.fulfilled]: (state, action) => {},
  },
});

export default EducationSlice.reducer;
// export const  groups = state => state.group.groups
