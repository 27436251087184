import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MoreVert from "@material-ui/icons/MoreVert";
import Search from "@material-ui/icons/Search";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";
import { deleteSurveyTemplatesTypeConfig, fetchSurveyTemplatesTypeConfigs } from "../../../reduxToolkit/features/surveyTemplateTypeConfig/SurveyTemplateTypeConfigSlice";
import SuperGeneralDeleteModal from "../../Components/SuperGeneralDeleteModal";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const SurveyTemplateDropDownButton = ({ template }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteModal = () => {
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleDelete = async () => {
    dispatch(deleteSurveyTemplatesTypeConfig(template.id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        openNotificationWithIcon("success", `Deleted`);
        dispatch(fetchSurveyTemplatesTypeConfigs())
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      });
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Link to={`/citrine/surveyTemplates/types/${template.id}/edit`}>
            <ListItemText primary="Edit" />
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal();
            handleClose();
          }}
        >
          <ListItemText primary="Delete" />
        </MenuItem>
      </StyledMenu>
      <SuperGeneralDeleteModal
        delete_Element={handleDelete}
        Element_ID={template.id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
};

const useStyles = makeStyles(styles);
const useNavBarStyles = makeStyles(navBarStyles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const TemplateExcerpt = ({ template }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();

  return (
    <GridItem xs={12} sm={12} md={4} key={template.id}>
      <Card
        style={{ maxWidth: "380px", minWidth: "380px", height: 150 }}
        cardHover
      >
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/surveyTemplatesTypes/${template.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {`${template.category} survey template`}
                </h4>
                <h4 className={cardClasses.cardProductTitle}>
                  {`${template.name}`}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <SurveyTemplateDropDownButton template={template} />
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
    </GridItem>
  );
};

export default function SurveyTemplates() {

  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const classes = useStyles();
  const navBarClasses = useNavBarStyles();
  const titleClasses = useTitleStyles();

  const dispatch = useDispatch();

  const surveyTemplatesTypesStatus = useSelector((state) => state.surveyTemplatesTypeConfig.surveyTemplatesTypeConfigsStatus);
  const surveyTemplatesTypes = useSelector((state) => state.surveyTemplatesTypeConfig.surveyTemplatesTypeConfigs);

  useEffect(() => {
    if (surveyTemplatesTypesStatus === "idle") {
      dispatch(fetchSurveyTemplatesTypeConfigs());
    }
  }, []);

  let content;

  const searchButton = navBarClasses.top + " " + navBarClasses.searchButton;

  if (surveyTemplatesTypesStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (surveyTemplatesTypesStatus === "succeeded") {
    content =
      surveyTemplatesTypes &&
      surveyTemplatesTypes
        .filter((template) => {
          if (search === "") {
            return template;
          } else if (
            template.name.toLowerCase().includes(search.toLowerCase())
          ) {
            return template;
          }
        })
        .map((template) => (
          <TemplateExcerpt key={template.id} template={template} />
        ));
  }

  return (
    <div>
      <GridContainer
        alignItems="center"
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
          minHeight: "50px",
          maxHeight: "50px",
        }}
      >
        <GridItem>
          <Link to={`/citrine/surveyTemplatesNavigationPage`}>
            <span className={titleClasses.title}>
              {t("Survey Templates Configuration")}
            </span>
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer justify="space-between" alignItems="center">
        <GridItem>
          <Link to="/citrine/surveyTemplates/types/add">
            <Button color="primary">{t("Add")}</Button>
          </Link>
        </GridItem>
        <GridItem>
          <CustomInput
            inputProps={{
              onChange: (event) => {
                setSearch(event.target.value);
              },
              value: search,
              placeholder: "Search",
            }}
          />
          <Button color="white" justIcon round className={searchButton}>
            <Search
              className={
                navBarClasses.headerLinksSvg + " " + navBarClasses.searchIcon
              }
            />
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>{content}</GridContainer>
    </div>
  );
}
