import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourseById
} from "../../../../../reduxToolkit/Course/coursesSpecSlice";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";

import DropDownALHSButton from "../../../../Components/DropDownALHSButton";


import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { fetchActualLearningHoursSpec } from "../../../../../reduxToolkit/features/ActualLearningHours/ActualLearningHoursSpecSlice";



import { notification } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../../../Components/ErrorModal";


const useStyles = makeStyles(styles);

export default function ActualLearningHourList({ id }) {
  const { t } = useTranslation();

  const classes = useStyles();


  const dispatch = useDispatch();


  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);


  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };


  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  }


  useEffect(() => {
    dispatch(fetchActualLearningHoursSpec(id));
    dispatch(fetchCourseById(id));

    if (localStorage.getItem("CourseState") != "Draft") {
      setDisabledStatus(true);
    }
  }, [dispatch]);


  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const ActualLearningHours = useSelector(
    (state) => state.ActualLearningHourSpec.ActualLearningHours
  );



  return (
    <div>

      <GridContainer>
        <GridItem>
          {disabledStatus ? <p></p> :
            <Link
              to={`/citrine/specification/course/${course && course.id && course.id
                }/ActualLearningHours/create`}
            >
              <Button color="info" className={classes.updateProfileButton}>
                {t("Add")}
              </Button>
            </Link>
          }
        </GridItem>
      </GridContainer>

      <GridContainer>
        {ActualLearningHours &&
          ActualLearningHours.map((Element) => (
            <GridItem key={Element.id} xs={12} sm={12} md={4}>
              <Card style={{ height: 150 }}>
                <CardBody>
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={10}>
                      <h4
                        className={classes.cardProductTitle}
                        style={{ color: "orange" }}
                      >
                        <a
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ color: "orange" }}
                        >
                          {t("Type")}:
                          {Element &&
                            Element.type_details &&
                            Element.type_details[0].name}
                        </a>
                      </h4>
                      <h4
                        className={classes.cardProductTitle}

                      >
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          {t("Activity ")}:
                          {Element &&
                            Element.activity_details &&
                            Element.activity_details[0].name}
                        </a>
                      </h4>
                      <h4
                        className={classes.cardProductTitle}

                      >
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          {t("Learning Hours")} : {Element.learning_hours}
                        </a>
                      </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }}>
                      {disabledStatus ? <p></p> :
                        <DropDownALHSButton
                          ActualLearningHour_ID={Element.id}
                          courseID={course && course.id}
                        />
                      }
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
