import React, { useState } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(0),
        '& thead th': {
            fontWeight: '600',
            color: "white",
            backgroundColor: "#AF83B7",
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
}))

export default function useTableRecommendation(records, headRecommendationsCells, filterFn) {

    const classes = useStyles();

    const pages = [5, 10, 25]
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(pages[page])
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()

    const TblRecommendationContainer = props => (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                {props.children}
            </Table>
        </TableContainer>
    )

    const TblRecommendationHead = () => {

        return (<TableHead>
            <TableRow>
                {
                    headRecommendationsCells.map(headCell => (
                        <TableCell key={headCell.id}

                            style={{ padding: "8px", textAlign: "center", borderRight: "1px solid lightgray" }}
                        >
                            {headCell.label}
                        </TableCell>))
                }
            </TableRow>
        </TableHead>)
    }

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // }

    // const handleChangeRowsPerPage = event => {
    //     setRowsPerPage(parseInt(event.target.value, 10))
    //     setPage(0);
    // }

    // const TblPagination = () => (<TablePagination
    //     component="div"
    //     page={page}
    //     rowsPerPageOptions={pages}
    //     rowsPerPage={rowsPerPage}
    //     count={records.length}
    //     onChangePage={handleChangePage}
    //     onChangeRowsPerPage={handleChangeRowsPerPage}
    // />)

    // function descendingComparator(a, b, orderBy) {
    //     if (b[orderBy] < a[orderBy]) {
    //         return -1;
    //     }
    //     if (b[orderBy] > a[orderBy]) {
    //         return 1;
    //     }
    //     return 0;
    // }

    return {
        TblRecommendationContainer,
        TblRecommendationHead,
        // TblPagination,
    }
}