import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
  ActualLearningHours: [],
  ActualLearningHourStatus: 'idle',
  error: null,
  ActualLearningHour: null,
  id: null,
}

export const fetchActualLearningHoursConfig = createAsyncThunk(
  ' ActualLearningHourConfig/fetch ActualLearningHours',
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/actualLearningActivityConfig/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const fetchActualLearningHoursConfigById = createAsyncThunk(
  'ActualLearningHourConfig/actualLearningHourById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(
        BaseUrl + `/actualLearningActivityConfig/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const addNewActualLearningHour = createAsyncThunk(
  'ActualLearningHourConfig/addNewActualLearningHour',
  async (initialActualLearningHour, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(
        BaseUrl + `/actualLearningActivityConfig/create/`,
        initialActualLearningHour,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const actualLearningHourUpdate = createAsyncThunk(
  'ActualLearningHourConfig/actualLearningHourUpdate',
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(
        BaseUrl + `/actualLearningActivityConfig/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

export const deleteActualLearningHour = createAsyncThunk(
  'ActualLearningHourConfig/deleteActualLearningHour',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.delete(
        BaseUrl + `/actualLearningActivityConfig/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      )
      return response.data
    } catch (err) {
      let error = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response)
    }
  },
)

const ActualLearningHourConfigSlice = createSlice({
  name: 'ActualLearningHourConfig',
  initialState,
  extraReducers: {
    [fetchActualLearningHoursConfig.pending]: (state, action) => {
      state.ActualLearningHourStatus = 'loading'
    },
    [fetchActualLearningHoursConfig.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = 'succeeded'
      state.ActualLearningHours = action.payload
    },
    [fetchActualLearningHoursConfig.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchActualLearningHoursConfigById.pending]: (state, action) => {
      state.ActualLearningHourStatus = 'loading'
    },
    [fetchActualLearningHoursConfigById.fulfilled]: (state, action) => {
      state.ActualLearningHourStatus = 'succeeded'
      state.ActualLearningHour = action.payload
    },
    [fetchActualLearningHoursConfigById.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewActualLearningHour.fulfilled]: (state, action) => {
      state.ActualLearningHours.push(action.payload)
    },
    [addNewActualLearningHour.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteActualLearningHour.fulfilled]: (state, action) => {},
    [deleteActualLearningHour.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [actualLearningHourUpdate.fulfilled]: (state, action) => {
      console.log(action)
    },
    [actualLearningHourUpdate.rejected]: (state, action) => {
      state.ActualLearningHourStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    reducers: {
      getId: (state, action) => {
        state.id = action.payload
      },
    },
  },
})

export const {
  getId,
} = ActualLearningHourConfigSlice.actions

export default ActualLearningHourConfigSlice.reducer

export const selectAllFacilitiesRequired = (state) =>
  state.ActualLearningHourConfig.ActualLearningHours
