import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  CounselingAndSupportActivities: [],
  CounselingAndSupportActivitiesStatus: "idle",
  CounselingAndSupportActivitiesStatus: "idle",
  error: null,
  CounselingAndSupportActivity: null,
  CounselingAndSupportActivitiesByProgram: [],
  CounselingAndSupportActivitiesByProgramStatus: "idle"
};


export const fetchCounselingAndSupportActivitiesByProgramId = createAsyncThunk(
  "CounselingAndSupportActivities/fetchCounselingAndSupportActivitiesByProgramId",
  async (program_id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/counselingAndSupportAp/?annualProgramReport_id=${program_id}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log(response.data, "response.data of groups");

    return response.data;
  }
);
export const addNewCounselingAndSupportActivity = createAsyncThunk(
  "CounselingAndSupportActivities/addNewCounselingAndSupportActivity",
  async (body, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(BaseUrl + "/counselingAndSupportAp/create/", body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data of groups");

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateCounselingAndSupportActivity = createAsyncThunk(
  "CounselingAndSupportActivities/UpdateCounselingAndSupportActivity",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/counselingAndSupportAp/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "education");
      console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      console.error("Failed to update the education: ", error);
    }
  }
);

export const deleteCounselingAndSupportActivity = createAsyncThunk(
  "CounselingAndSupportActivities/deleteCounselingAndSupportActivity",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(BaseUrl + `/counselingAndSupportAp/${id}/delete/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });

    return response.data;
  }
);

const CounselingAndSupportActivitiesSlice = createSlice({
  name: "CounselingAndSupportActivities",
  initialState,
  extraReducers: {

    [fetchCounselingAndSupportActivitiesByProgramId.pending]: (state, action) => {
      state.CounselingAndSupportActivitiesByProgramStatus = "loading";
    },
    [fetchCounselingAndSupportActivitiesByProgramId.fulfilled]: (state, action) => {
      state.CounselingAndSupportActivitiesByProgramStatus = "succeeded";
      // Add any fetched posts to the array
      state.CounselingAndSupportActivitiesByProgram = action.payload;
    },
    [fetchCounselingAndSupportActivitiesByProgramId.rejected]: (state, action) => {
      state.CounselingAndSupportActivitiesByProgramStatus = "failed";
      state.error = action.payload;
    },
    
    [addNewCounselingAndSupportActivity.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },

    [UpdateCounselingAndSupportActivity.fulfilled]: (state, action) => {
      // state.groups.push(action.payload);
    },
    [deleteCounselingAndSupportActivity.fulfilled]: (state, action) => {},
  },
});

export default CounselingAndSupportActivitiesSlice.reducer;
// export const  groups = state => state.group.groups
