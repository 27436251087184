import React, { lazy, Suspense, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MailOutline from '@material-ui/icons/MailOutline'
import { unwrapResult } from '@reduxjs/toolkit'
import { Input } from 'antd'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  fetchSurveyTemplateById,
  updateSurveyTemplate,
} from '../../../reduxToolkit/features/Survey/SurveySlice'
import { Breadcrumb, notification } from 'antd'
import LazyLoad from 'react-lazyload'

//import ReactJson from 'react-json-view'
const ReactJson = lazy(() => import('react-json-view'))

const useStyles = makeStyles(styles)
const useTitleStyles = makeStyles(titleStyles)
const { TextArea } = Input
const SurveyTemplateEditor2 = ({ match }) => {
  const { t } = useTranslation()
  const { id } = match.params

  const [stringifiedTemplate, setStringifiedTemplate] = useState('')
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [test, setTest] = useState({ text: 'testing' })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchSurveyTemplateById(id))
      .then(unwrapResult)
      .then((template) => {
        setStringifiedTemplate(JSON.parse(template.template))
        setType(template.type)
        setName(template.name)
      })
  }, [dispatch])

  const surveyTemplate = useSelector((state) => state.survey.surveyTemplate)

  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const canSave =
    [stringifiedTemplate, type, name].every(Boolean) &&
    addRequestStatus === 'idle'

  const onSaveTemplateClicked = async () => {
    const template = JSON.parse(stringifiedTemplate)
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(
          updateSurveyTemplate({
            id: id,
            updatedTemplate: { template, type, name },
          }),
        )

        unwrapResult(resultAction)
      } catch (err) {
        console.log('Failed to save the template: ', err)
      } finally {
        setAddRequestStatus('idle')
      }
      // dispatch(fetchSurveyTemplates())
      // console.log("stringifiedTemplate", stringifiedTemplate)
      // console.log("parsedTemplate", template)
    }
  }

  const editSurvey = ({updated_src}) => {
    console.log(updated_src)
  }
  const addSurvey = (add) => {
    console.log(add)
  }
  const deleteSurvey = (deleteS) => {
    console.log(deleteS)
  }

  const classes = useStyles()
  const titleClasses = useTitleStyles()
  return (
    <GridContainer>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/surveyTemplates`}>{t('Survey templates')}</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>{surveyTemplate?.name}</Breadcrumb.Item>

          <Breadcrumb.Item>{t('edit')}</Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {t('Update Survey Template')}
            </h4>
          </CardHeader>
          <CardBody>

            <Suspense fallback={<div>Loading Template ...</div>}>
              {stringifiedTemplate && (
                <LazyLoad>
                  <ReactJson
                  src={stringifiedTemplate}
                  theme="monokai"
                  displayDataTypes={false}
                  onDelete={deleteSurvey}
                  onAdd={addSurvey}
                  onEdit={editSurvey}
                />
                </LazyLoad>
              )}
            </Suspense>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default SurveyTemplateEditor2
