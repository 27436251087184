import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  VariationsCourses: [],
  VariationsCoursesStatus: "idle",
  VariationsCourseStatus: "idle",
  error: null,
  VariationsCourse: null,
  id: null,
};

export const fetchVariationsCourses = createAsyncThunk(
  "VariationsCoursesSlice/fetchVariationsCourses",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/coursesVariations/?program_id=${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response.data, "response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const addNewVariationsCourse = createAsyncThunk(
  "VariationsCoursesSlice/addNewVariationsCourse",
  async (initialObject, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/coursesVariations/create/`,
        initialObject,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteVariationsCourse = createAsyncThunk(
  "VariationsCoursesSlice/deleteVariationsCourse",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/coursesVariations/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      // console.log(response.data,'response.data')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
const VariationsCoursesSlice = createSlice({
  name: " level",
  initialState,
  extraReducers: {
    [fetchVariationsCourses.pending]: (state, action) => {
      state.VariationsCoursesStatus = "loading";
    },
    [fetchVariationsCourses.fulfilled]: (state, action) => {
      state.VariationsCoursesStatus = "succeeded";
      state.VariationsCourses = action.payload;
    },
    [fetchVariationsCourses.rejected]: (state, action) => {
      state.VariationsCoursesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [addNewVariationsCourse.fulfilled]: (state, action) => {
      state.VariationsCourses.push(action.payload);
    },
    [deleteVariationsCourse.fulfilled]: (state, action) => {
      // state.levels.push(action.payload)
    },

    [addNewVariationsCourse.rejected]: (state, action) => {
      state.VariationsCourseStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteVariationsCourse.rejected]: (state, action) => {
      state.PlannedCoursesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
  },
});
export default VariationsCoursesSlice.reducer;
