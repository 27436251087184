import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import { Breadcrumb, notification } from "antd";
import Card from "components/Card/Card.js";
// @material-ui/core components
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import CardFooter from "components/Card/CardFooter";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import {
  importDataFromLMS,
  loadLMSImportedData,
} from "../../../../reduxToolkit/features/LMSIntegration/LMSIntegrationSlice";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import {
  grayColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import ErrorModal from "views/Components/ErrorModal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "rgb(0,0,0,0.7)",
    display: "flex",
    flexDirection: "column",
  },
}));
export default function CoursesFromLMS() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const backdropClasses = useBackdropStyles();

  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
    },
  ];

  const data = {
    id: 107,
    type: "courseSpec",
    created_at: "2024-03-28T07:39:44.515578Z",
    jsonField: [
      {
        title: "TemplateCourse",
        code: "1111111111",
        description: "TemplateCourse",
        student_academic_counseling_and_support: "To be complited ...",
        level_id: 0,
        credit_hours: 0,
        institution_id: 0,
        follow_up: "To be complited ...",
        type: "Conventional Course",
        id: "_5473_1",
      },
      {
        title: "Training Course",
        code: "a.abdulrahmanubt.edu.sa",
        description: "Training Course",
        student_academic_counseling_and_support: "To be complited ...",
        level_id: 0,
        credit_hours: 0,
        institution_id: 0,
        follow_up: "To be complited ...",
        type: "Conventional Course",
        id: "_4169_1",
      },
    ],
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  // const programStatus = useSelector((state) => state.programSpec.programStatus);

  // useEffect(() => {
  //   if (programStatus === "idle") {
  //     dispatch(fetchProgramById(programId));
  //   }
  // }, []);

  // const program = useSelector((state) => state.programSpec.program);

  const importDataFromLMSHandler = async () => {
    try {
      const res = await dispatch(importDataFromLMS()).unwrap();
      // handle result here
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.log("error in component", rejectedValueOrSerializedError);
      setHandledError(rejectedValueOrSerializedError);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    importDataFromLMSHandler();
  }, []);
  const loading = useSelector((state) => state.LMSIntegration.loading);
  const importedLMSCourses = useSelector(
    (state) => state.LMSIntegration.importedLMSData
  )?.find((item) => item.type === "courseSpec")?.jsonField;

  let parsedImportedLMSCourses;
  if (importedLMSCourses) {
    parsedImportedLMSCourses = JSON.parse(importedLMSCourses);
    console.log("parsedImportedLMSCourses", parsedImportedLMSCourses);
  }

  console.log("selectionModel", selectionModel);

  const loadLMSSelectedCourses = async () => {
    const resultAction = await dispatch(
      loadLMSImportedData({
        key: "list_course_to_load",
        value: JSON.stringify(selectionModel),
      })
    );
    console.log("resultAction", resultAction);
    if (loadLMSImportedData.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      if (resultAction?.payload === "succeeded") {
        openNotificationWithIcon("success", `Loaded courses successfully`);
        history.push("/citrine/CourseSpecificationList");
      } else {
        openNotificationWithIcon("error", `Loading courses failed`);
      }
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <GridContainer
        style={{
          width: "100%",
          height: "10%",
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList`}>
                {t(`Courses`)}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t(`Import from LMS`)}</Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <Card style={{ width: "100%", height: "80%" }}>
        <div style={{ width: "100%", height: "100%", padding: "20px" }}>
          <DataGrid
            rows={parsedImportedLMSCourses || []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel} // disableSelectionOnClick
            // experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Card>

      <GridContainer
        style={{ width: "100%", height: "10%" }}
        justifyContent="flex-end"
      >
        <GridItem>
          <Button
            color="primary"
            disabled={selectionModel.length == 0}
            onClick={loadLMSSelectedCourses}
          >
            Import
          </Button>
        </GridItem>
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <Backdrop
        className={backdropClasses.backdrop}
        open={loading === "pending"}
      >
        <CircularProgress color="inherit" />
        <br />
        <br />

        <p>Loading LMS selected courses, this may take a few minutes...</p>
      </Backdrop>
    </div>
  );
}
