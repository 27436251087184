import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";

import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  deleteCourseReport,
  fetchCourseReports,
} from "../../reduxToolkit/features/CourseImprovement/courseImprovementSlice";
import { useTranslation } from "react-i18next";
import AccessAndRoleModal from "./AccessAndRoleModal";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal";

const useButtonStyles = makeStyles(buttonStyles);

export default function DropDownButtonCourseReport(props) {
  const { t } = useTranslation();
  const buttonClasses = useButtonStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const delete_CourseReport = (id) => {
    try {
      dispatch(deleteCourseReport(id))
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchCourseReports())
            .then(unwrapResult)
            .then((PloReports) => {
              props.orderReportsAccordingToStates();
            });
        });
    } catch (err) {
      console.log("Failed to delete  the delete courseReports : ", err);
    } finally {
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosebtn = () => {
    setAnchorEl(null);
  };
  //const [op_en, setOpen] = useState(Boolean(anchorEl));
  const op_en = Boolean(anchorEl);
 // console.log(anchorEl)
  /*****************************************************************/
  return (
    <span>
      <Button
        info
        round
        ref={anchorRef}
        aria-controls={op_en ? "basic-menu" : undefined}
        aria-expanded={op_en ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="true"
        onClick={handleClick}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
        id="basic-button"
      >
        <MoreVert />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={op_en}
        onClose={handleClosebtn}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >

        <AccessAndRoleModal
          id={props.id}
          handleDropDownButtonClose={handleClosebtn}
          NameOfComponentUsed="Course-Report"
          interface='reports'
          disabled={false}
          data={props.data}
          staffList={props.staffList}
        />

        <MenuList id="basic-menu">
          <MenuItem>
            <p onClick={() => delete_CourseReport(props.id)}>
              {t("Delete")}
            </p>
          </MenuItem>
        </MenuList>
      </Menu>
    </span>
  );
}
