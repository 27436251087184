import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import MenuItem from "@material-ui/core/MenuItem";
import "antd/dist/antd.css";

import { fetchAssessmentQuestionsMethods } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import { Form, Input, InputNumber, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import { addNewQuestion } from "../../reduxToolkit/features/assessmentMethod/QuestionSlice";
import ErrorModal from "./ErrorModal";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};



export default function AddQuestionAssessmentModal(props) {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { Option } = Select;


  const assessmentMethod_id = props.assessmentMethod_id
  const course_id = props.course_id

  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    props.handleCloseAnchor()
  };

  const handleClose = () => {
    setOpen(false);
    props.handleCloseAnchor()
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewQuestion({
        ...values, assessmentMethod_id, course_id
      })
    );
    if (addNewQuestion.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchAssessmentQuestionsMethods(props.course_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>Add question</MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Question")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="question"
                  label="question"
                  rules={[
                    {
                      required: true,
                      message: "Please input the question field.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="actual_weight"
                  label="Actual weight"
                  rules={[
                    {
                      required: true,
                      message: "Please input the actual weight field.",
                    },
                    {
                      type: "number",
                      min: 1,
                      message: "Value should not be equal to or less than 0.",
                    },
                  ]}
                >
                  <InputNumber min={1} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="correction_mark"
                  label="Correction mark"
                  rules={[
                    {
                      required: true,
                      message: "Please input the actual weight field.",
                    },
                    {
                      type: "number",
                      min: 1,
                      message: "Value should not be equal to or less than 0.",
                    },
                  ]}
                >
                  <InputNumber min={1} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="clo_id"
                  label="Clo"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Clo field.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Clo "
                    dropdownStyle={{ zIndex: 10000 }}
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {props.course?.clos.map((clo) => (
                      <Option key={clo.id} value={clo.id}>
                        {`${clo.code}--${clo.title}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    style={{ marginRight: "20px" }}
                    color="primary"
                    onClick={handleClose}
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
