import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Form, Input, notification, Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// @material-ui/core components
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {

  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";
// @material-ui/icons
import {
  addNewTeachingStrategieSpec,
  fetchTeachingStrategieById, fetchTeachingStrategiesByCourse,
  fetchTeachingStrategiesSpec, teachingStrategieUpdate
} from "../../../../../reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import ErrorModal from "../../../../Components/ErrorModal";












const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};


export default function CreateTeachingStrategies({ match }) {
  const { t } = useTranslation();


  const dispatch = useDispatch();
  const history = useHistory();

  const { Option } = Select;


  const { id } = match.params;
  const { idTS } = match.params;
  const [form] = Form.useForm();
  console.log("idTS", idTS);
  const isAddMode = isNaN(idTS);
  console.log("id from match is add mode", isAddMode);
  const [field, setField] = useState(false)
  const [course_type, setCourse_type] = useState('')
  const [value, setValue] = React.useState(1);
  const [
    planned_teaching_strategy_id,
    setplanned_teaching_strategies,
  ] = useState("");
  const [courseSpec_id, setCourseSpec_id] = useState(id);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");


  useEffect(() => {
    dispatch(fetchCourseById(id)).then(unwrapResult).then((course) => {
      setCourse_type(course.type)
      if (course.type == 'Field Experience') {
        setField(true)
        console.log('Field Experience', field)
      }
      else {
        setField(false);
      }
    });
    dispatch(fetchTeachingStrategiesByCourse(id));
  }, [dispatch]);
  const TeachingStrategiesList = useSelector(
    (state) => state.TeachingStrategieSpec.TeachingStrategieByCourse
  );
  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const TeachingStrategiesSpecStatus = useSelector(
    (state) => state.TeachingStrategieSpec.TeachingStrategiesSpecStatus
  );

  console.log("TeachingStrategiesList", TeachingStrategiesList);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewTeachingStrategieSpec({ ...values, courseSpec_id })
    );
    if (addNewTeachingStrategieSpec.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchTeachingStrategiesSpec(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/TeachingStrategies`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };
  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      teachingStrategieUpdate({
        id: idTS,
        modified: {
          ...values,
        },
      })
    );
    if (teachingStrategieUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchTeachingStrategiesSpec(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/TeachingStrategies`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchTeachingStrategieById(idTS))
        .then(unwrapResult)
        .then((TS) => {
          console.log(TS, "TS");
          form.setFieldsValue({
            planned_teaching_strategy_id: TS.planned_teaching_strategy_id,
            courseSpec_id: TS.courseSpec_id,
          });
        });
    }
  }, [idTS]);

  const res = useSelector(
    (state) => state.TeachingStrategieSpec.TeachingStrategieSpec
  );
  console.log("res ", res);

  function onChangeplanned_teaching_strategies(value) {
    //  console.log(`selected ${value}`);
    setplanned_teaching_strategies(value);
  }

  return (
    <div>
      {isAddMode && field && (
        <div>
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/TeachingStrategies`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Training Activities List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="planned_teaching_strategy_id"
                        label="Planned Training Activity"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {TeachingStrategiesList &&
                            TeachingStrategiesList.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/TeachingStrategies`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && field && (
        <div>
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                  
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/TeachingStrategies`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Training Activities List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="planned_teaching_strategy_id"
                        label="Planned Training Activity"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {TeachingStrategiesList &&
                            TeachingStrategiesList.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/TeachingStrategies`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {isAddMode && !field && (
        <div>
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/TeachingStrategies`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Teaching Strategies List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="planned_teaching_strategy_id"
                        label="Planned Teaching Strategy"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {TeachingStrategiesList &&
                            TeachingStrategiesList.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/TeachingStrategies`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && !field && (
        <div>
          <div>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                   
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/TeachingStrategies`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Teaching Strategies List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="planned_teaching_strategy_id"
                        label="Planned Teaching Strategy"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {TeachingStrategiesList &&
                            TeachingStrategiesList.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/TeachingStrategies`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
