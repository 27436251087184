import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
const initialState = {
  standards_KPIsDepartment: [],
  standards_KPIsDepartmentStatus: "idle",
};

export const fetchAll_standards_KPIsDepartment = createAsyncThunk(
  "KPIDepartment/fetchAll_standards_KPIsDepartment",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/kpiDepartment/standards/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

const KPIDepartmentSlice = createSlice({
  name: "KPIDepartment",
  initialState,
  extraReducers: {
    [fetchAll_standards_KPIsDepartment.pending]: (state, action) => {
      state.standards_KPIsDepartmentStatus = "loading";
    },
    [fetchAll_standards_KPIsDepartment.fulfilled]: (state, action) => {
      state.standards_KPIsDepartmentStatus = "succeeded";
      // Add any fetched posts to the array
      state.standards_KPIsDepartment = action.payload;
    },
    [fetchAll_standards_KPIsDepartment.rejected]: (state, action) => {
      state.standards_KPIsDepartmentStatus = "failed";
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload;
        console.log(
          "action.payload from inside extra add plo reducer",
          action.payload
        );
      } else {
        state.error = action.error;
        console.log(
          "action.error from inside extra add plo reducer",
          action.error
        );
      }
    },
  },
});

export default KPIDepartmentSlice.reducer;
