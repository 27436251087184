import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchAcademicYears } from '../../reduxToolkit/features/academicYear/AcademicYearsSlice'
import { fetchSemesters } from '../../reduxToolkit/features/semester/SemestersSlice'
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import MenuOpen from "@material-ui/icons/MenuOpen"

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {


  const dispatch = useDispatch()

  const semestersStatus = useSelector(state => state.semester.semestersStatus)
  const academicYearsStatus = useSelector(state => state.academicYear.academicYearsStatus)
  const userStatus = useSelector(state => state.user.userStatus)
  const academicYears = useSelector(state => state.academicYear.academicYears)
  const currentUser = useSelector(state => state.user.currentUser)
  const semesters = useSelector(state => state.semester.semesters)

  const useStyles = makeStyles(styles);

  React.useEffect(() => {
    if (academicYearsStatus === 'idle') {
      dispatch(fetchAcademicYears())
    }
    if (semestersStatus === 'idle') {
      dispatch(fetchSemesters())
    }
  }, [])

  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <Menu className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MenuOpen className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          {/* <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button> */}
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">

          <Button
            className={classes.appResponsive}
            justIcon
            round
            color="white"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};
