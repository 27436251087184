import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import token from 'assets/utils/accessToken';
import BaseUrl from '../../../assets/utils/accessToken'
const initialState = {
  admissionRequirements: [],
  admissionRequirementsStatus: 'idle',
  admissionRequirementStatus: 'idle',
  error: null,
  admissionRequirement:null,
  id:null
}


export const fetchAdmissionRequirements = createAsyncThunk('admissionRequirement/fetchAdmissionRequirements', async () => {
  const token=localStorage.getItem('token')
  const response = await axios.get(BaseUrl+'/admissionRequirement/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
       console.log(response.data,'response.data')

    return response.data
  })

  export const fetchAdmissionRequirementById = createAsyncThunk('admissionRequirement/fetchAdmissionRequirementById', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.get(BaseUrl+`/admissionRequirement/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
  export const addNewAdmissionRequirement = createAsyncThunk(
    'admissionRequirement/addNewAdmissionRequirement',
    async (initialAdmissionRequirement) => {
      console.log('initial admissionRequirement', initialAdmissionRequirement)
      const token=localStorage.getItem('token')
      const response = await axios.post(BaseUrl+`/admissionRequirement/create/`,initialAdmissionRequirement ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })
      console.log(response.data)
      return response.data
    }
  )
  export const admissionRequirementUpdate = createAsyncThunk(
    'admissionRequirement/admissionRequirementUpdate',
    async (data,thunkAPI) => {
      
      try{
        const token=localStorage.getItem('token')
        const response = await axios.put(BaseUrl+`/admissionRequirement/${data.id}/edit/`,data.modified ,{
        headers: {
          'Authorization': `token ${token}`
        }
      })   
      console.log(data,'updatedAdmissionRequirement')
      console.log(response.data,'response.data')   
      return response.data


    } catch (error) {
console.error('Failed to update the admissionRequirement: ', error)

}
    }
  )

  export const deleteAdmissionRequirement = createAsyncThunk('admissionRequirement/deleteAdmissionRequirement', async (id) => {
    const token=localStorage.getItem('token')
    const response = await axios.delete(BaseUrl+`/admissionRequirement/${id}/delete/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      // console.log(response.data,'response.data')

    return response.data
  })
const  admissionRequirementsSlice = createSlice({
  name: ' admissionRequirement',
  initialState,
  extraReducers: {
    [fetchAdmissionRequirements.pending]: (state, action) => {
        state.admissionRequirementsStatus = 'loading'
      },
      [fetchAdmissionRequirements.fulfilled]: (state, action) => {
        state.admissionRequirementsStatus = 'succeeded'
        // Add any fetched posts to the array
        state.admissionRequirements = action.payload
      },
      [fetchAdmissionRequirements.rejected]: (state, action) => {
        state.admissionRequirementsStatus = 'failed'
        state.error = action.payload
      },

      [fetchAdmissionRequirementById.pending]: (state, action) => {
        state.admissionRequirementStatus = 'loading'
      },
      [fetchAdmissionRequirementById.fulfilled]: (state, action) => {
        state.admissionRequirementStatus = 'succeeded'

        // Add any fetched posts to the array
        state.admissionRequirement = action.payload
      },
      [fetchAdmissionRequirementById.rejected]: (state, action) => {
        state.admissionRequirementStatus = 'failed'
        state.error = action.payload
      },
      [addNewAdmissionRequirement.fulfilled]: (state, action) => {
        state.admissionRequirements.push(action.payload)
      },
      [admissionRequirementUpdate.fulfilled]: (state, action) => {
        console.log(action) 
      },
      


  reducers: {
    getId:
        (state, action) => 
        {
            state.id= action.payload
        },
    
  
    admissionRequirementUpdated(state, action) {
      const { id,name } = action.payload
      const existingAdmissionRequirement = state.admissionRequirements.find(admissionRequirement => admissionRequirement.id === id)
      if (existingAdmissionRequirement) {
        existingAdmissionRequirement.name = name
      }
    }
  }
}})

export const { admissionRequirementAdded, admissionRequirementUpdated, getId} = admissionRequirementsSlice.actions

export default admissionRequirementsSlice.reducer

export const selectAllAdmissionRequirements = state => state.admissionRequirement.admissionRequirements
