import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import TextField from '@material-ui/core/TextField'
import Button from 'components/CustomButtons/Button.js'
import { ActualLearningHourUpdate } from '../../../../../reduxToolkit/features/ActualLearningHours/ActualLearningHourSlice'
import {notification} from "antd"
import ErrorModal from '../../../../Components/ErrorModal'
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const DropDownContainer = styled('div')`
  width: 15em;
`

const DropDownHeader = styled('div')`
  margin-bottom: 0.5em;
  padding: 0.4em 2em 0.4em 0em;
  font-weight: 500;
  font-size: 1.3rem;
`

const ActualHours = ({ actualLearningHours,ping,setPing }) => {
  
  const [isOpen, setIsOpen] = useState(null)
  const [planned_hours, setHour] = useState()
  const [planned_hours1, setHour1] = useState()
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')
  const dispatch = useDispatch()
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const toggling = (event) => {
    setIsOpen(event.currentTarget)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const handleSubmit = async () => {
    const data = {
      learning_hours: actualLearningHours.learning_hours,
      type_id: actualLearningHours.type_id,
      course_id: actualLearningHours.course_id,
      activity_id: actualLearningHours.activity_id,
      actual_hours: planned_hours,
      id: actualLearningHours.id,
    }
    try {
      await dispatch(ActualLearningHourUpdate(data)).unwrap()
      setPing(!ping)
      setHour1(planned_hours)
      openNotificationWithIcon("success", `Updated successfully`)
    } catch (error) {
        console.log(error)
      setHour1(actualLearningHours.actual_hours)
      setHour(actualLearningHours.actual_hours)
      setHandledError(error)
      handleOpenErrorModal()
    }
    setIsOpen(false)
  }
  useEffect(() => {
    setHour1(actualLearningHours.actual_hours)
    setHour(actualLearningHours.actual_hours)
  }, [actualLearningHours.actual_hours])
  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling}>
        <div
          style={{
            fontSize: '12px',
          }}
        >
          {planned_hours1}
        </div>
      </DropDownHeader>
      <StyledMenu
        id="customized-menu"
        anchorEl={isOpen}
        keepMounted
        open={Boolean(isOpen)}
        onClose={() => setIsOpen(false)}
      >
        <MenuItem>
          <TextField
            style={{ width: '120px' }}
            inputProps={{ type: 'number', min: '0', max: '100' }}
            errorText={'coco !!!'}
            label="Actual Hours"
            value={planned_hours}
            onChange={(e) => setHour(e.target.value)}
          />
        </MenuItem>

        <MenuItem>
          <Button
            style={{ margin: '0px' }}
            onClick={handleSubmit}
            type="submit"
            color="facebook"
          >
            Edit
          </Button>
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </DropDownContainer>
  )
}

export default ActualHours