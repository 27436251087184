import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'
import token from 'assets/utils/accessToken';

const initialState = {
    VerificationOfCredibilities: [],
    VerificationOfCredibilitiesStatus: 'idle',
    VerificationOfCredibilitiesStatus: 'idle',
    error: null,
    VerificationOfCredibility: null,

    id: null
}


export const fetchVerificationOfCredibilities = createAsyncThunk('VerificationOfCredibilityAssessment/fetchVerificationOfCredibilities', async (course_id) => {
    const token = localStorage.getItem('token')
    const response = await axios.get(BaseUrl + `/verificationCredibilityStudentsResults/?course_id=${course_id}`, {
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    console.log(response.data, 'response.data')
    // const response = jsonData
    return response.data
})


export const addNewVerificationOfCredibility = createAsyncThunk(
    'VerificationOfCredibilityAssessment/addNewVerificationOfCredibility',
    async (initialVerificationOfCredibility) => {
        console.log('initialVerificationOfCredibility', initialVerificationOfCredibility)
        const token = localStorage.getItem('token')
        const response = await axios.post(BaseUrl + `/conclusionsDifficulties/create/`, initialVerificationOfCredibility, {
            headers: {
                'Authorization': `token ${token}`
            }
        })
        console.log(response.data)
        return response.data
    }
)

export const deleteVerificationOfCredibility = createAsyncThunk('VerificationOfCredibilityAssessment/deleteVerificationOfCredibility', async (id) => {
    const token = localStorage.getItem('token')
    const response = await axios.delete(BaseUrl + `/verificationCredibilityStudentsResults/${id}/delete/`, {
        headers: {
            'Authorization': `token ${token}`
        }
    })
    console.log(response.data, ' delete response.data')

    return response.data
})
const VerificationOfCredibilitySlice = createSlice({
    name: 'VerificationOfCredibilityAssessment',
    initialState,

    extraReducers: {
        [fetchVerificationOfCredibilities.pending]: (state, action) => {
            state.TeachingStrategiesStatus = 'loading'
        },
        [fetchVerificationOfCredibilities.fulfilled]: (state, action) => {
            state.TeachingStrategiesStatus = 'succeeded'

            state.VerificationOfCredibilities = action.payload
            //state.TeachingStrategiesSpec =  jsonData

        },
        [fetchVerificationOfCredibilities.rejected]: (state, action) => {
            state.TeachingStrategiesStatus = 'failed'
            state.error = action.payload
        },


        [addNewVerificationOfCredibility.fulfilled]: (state, action) => {
            state.TeachingStrategies=action.payload
        },
        [deleteVerificationOfCredibility.fulfilled]: (state, action) => {
            // state.SpecificationAprovalDatas.push(action.payload)
        },

        reducers: {
            getId:
                (state, action) => {
                    state.id = action.payload
                },
        }
    }
})

export const { getId } = VerificationOfCredibilitySlice.actions

export default VerificationOfCredibilitySlice.reducer



