import React, { useState } from 'react'
import {DataGrid, GridCellEditStopReasons, GridCellModes, GridEditInputCell} from '@mui/x-data-grid'
import { notification } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import {
  addDefaultQuestionSpec,
  updateDefaultQuestionSpec,
} from 'reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec'
import { fetchCloById } from 'reduxToolkit/features/CLO/CloSpecSlice'
import ErrorModal from '../../../../Components/ErrorModal'
import DropDownClosQuestions from './DropDownClosQuestions'

function VerifFields(params) {
  if (params.value == null || params.value == undefined) {
    return clsx('super-app-theme--cell')
  }
  return clsx('super-app', {
    negative: params.value == '',
    positive: params.value != '',
  })
}

const DefaultQuestion = (props) => {

  //hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //useSelectors
  const clo = useSelector((state) => state.cloSpec.clo)

  //states
  const [selectionModel, setSelectionModel] = useState([])
  const [cellModesModel, setcellModesModel] = useState({});

  //constants

  const columns = [
    {
      field: 'question',
      headerName: 'Questions',
      editable: true,
      flex: 0.5,

      cellClassName: (params) => VerifFields(params),
    },
    {
      field: 'correction_mark',
      headerName: 'Mark',
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value < 1;
        return { ...params.props, error: hasError };
      },
      flex: 0.2,
      type: 'number',
      cellClassName: (params) => VerifFields(params),
      renderEditCell: (params) => {
        return (<GridEditInputCell
            {...params}
            inputProps={{
              max: 1000,
              min: 1,
            }}
        />)
      },
    },
    {
      field: 'actual_weight',
      headerName: 'Weight',
      editable: true,
      flex: 0.2,
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value < 1;
        return { ...params.props, error: hasError };
      },
      type: 'number',
      renderEditCell: (params) => (
          <GridEditInputCell
              {...params}
              inputProps={{
                max: 1000,
                min: 1,
              }}
          />
      ),
      cellClassName: (params) => VerifFields(params),
    },
  ]

  const handleAddRow = async () => {
    const toAdd = { courseSpec_id: props.courseSpec_id, cloSpec_id: props.cloSpec_id, assessmentMethodSpec_id: props.ass_id, question: "question", correction_mark: 0, actual_weight: 0 }
    const resultAction = await dispatch(addDefaultQuestionSpec(toAdd))
    if (addDefaultQuestionSpec.fulfilled.match(resultAction)) {
      openNotificationWithIcon('success', `Added successfully!`)
      const result = await dispatch(fetchCloById(props.cloSpec_id))
      unwrapResult(result)
      if (resultAction?.payload?.id) {
        setcellModesModel(
          { [resultAction?.payload?.id]: { question: { mode: GridCellModes.Edit } } }
        );
      }
    } else {
      if (resultAction.payload) {
        props.setHandledError(resultAction.payload)
        props.setOpenErrorModal(true)
      } else {
        props.setHandledError(resultAction.error)
        props.setOpenErrorModal(true)
      }
      props.fetchClo()
    }
  };

  const selectionModelChange = (newSelectionModel) => {
    setSelectionModel((prevModel) =>
      newSelectionModel.filter((id) => !prevModel.includes(id)),
    )
  }

  const onCellEditStop = async (params, event) => {
    if (params.colDef.type === 'number') {
      const {value} = event?.target;
      if (value < 1)
        return
    }
    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
      event.defaultMuiPrevented = true;
    } else {
      const resultAction = await dispatch(updateDefaultQuestionSpec({ id: params.id, modified: { [params.field]: event?.target?.value } }))
      if (updateDefaultQuestionSpec.fulfilled.match(resultAction)) {
        openNotificationWithIcon('success', `update successfully!`)
        await dispatch(fetchCloById(props.cloSpec_id))
      } else {
        if (resultAction.payload) {
          props.setHandledError(resultAction.payload)
          props.setOpenErrorModal(true)
        } else {
          props.setHandledError(resultAction.error)
          props.setOpenErrorModal(true)
        }
        props.fetchClo()
      }
    }
  }

  //functions
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 5,
      zIndex: 1000,
    })
  }

  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={11}>
            <div>
              <h5>{props.assMethode_Name}</h5>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={1}>
            <DropDownClosQuestions selectionModel={selectionModel} addRow={handleAddRow} fetchClo={props.fetchClo} />
          </GridItem>
        </GridContainer>

        <div style={{ width: '100%', marginTop: 20 }}>
          <DataGrid
            autoHeight
            rows={props.rows?.length ? props.rows : []}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5, 50]}
            onCellEditStop={onCellEditStop}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            onSelectionModelChange={selectionModelChange}
            selectionModel={selectionModel}
            cellModesModel={cellModesModel}
            onCellModesModelChange={(newModel) => setcellModesModel(newModel)}
          />
        </div>
      </CardBody>
    </Card>
  )
}

const EditDefaultQuestion = (props) => {

  //hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { clo, courseId, fetchClo } = props

  //states
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  return (
    <div>
      <GridContainer>
        {clo?.assessmentMethodSpec_names?.map((item) => {
          return (
            <DefaultQuestion
              rows={item.questions}
              key={item.id}
              assMethode_Name={item.name}
              ass_id={item.id}
              courseSpec_id={courseId}
              cloSpec_id={clo && clo.id}
              fetchClo={fetchClo}
              setHandledError={setHandledError}
              setOpenErrorModal={setOpenErrorModal}
            />
          )
        })}
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}

export default EditDefaultQuestion
