import { makeStyles } from '@material-ui/core';
import { Breadcrumb } from 'antd';
import 'antd/dist/antd.css';
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader';
import Button from "components/CustomButtons/Button.js";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    grayColor,
    primaryColor
} from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchHTMLTemplates, fetchHTMLTemplatesReports, fetchHTMLTemplatesSurveyMails } from '../../../../reduxToolkit/features/HTMLTemplate/HTMLTemplateSlice.jsx';
import MailTempDropDownButton from './MailTempDropDownButton.jsx';
import MailTemplates from './MailTemplates.jsx';
const useStyles = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const ListTemplate = (props) => {

    const { t } = useTranslation();
    const cardClasses = useCardStyles();

    return (
        <GridItem xs={12} sm={6} md={4} key="">
            <Card style={{ maxHeight: "200px", minHeight: "200px" }} cardHover>
                <CardHeader>
                    <GridContainer
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <GridItem xs={10} sm={10} md={10}>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <MailTempDropDownButton id={props.item.id} data={props.item} />
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody smallCardBody>
                    <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                        {t("Name")}:
                        <span style={{ fontWeight: "normal" }}>
                            {" "}
                            {props?.item?.name}{" "}
                        </span>
                    </div>
                    <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                        {t("Type")}:
                        <span style={{ fontWeight: "normal" }}>
                            {" "}
                            {props?.item?.type}{" "}
                        </span>
                    </div>
                </CardBody>
            </Card>
        </GridItem>
    )
}

export default function SurveyMails() {

    const { t } = useTranslation();
    const dispatch = useDispatch()

    const [temp, setTemp] = useState([]);
    const [open, setOpen] = useState(false);

    const HTMLTemplates = useSelector((state) => state.HTMLTemplate.HTMLTemplates)
    const {HTMLTemplatesSurveyMails} = useSelector((state) => state.HTMLTemplate)
    const HTMLTemplatesStatus = useSelector((state) => state.HTMLTemplate.HTMLTemplatesStatus)

    useEffect(() => {
        dispatch(fetchHTMLTemplatesSurveyMails()).then(res => {
            setTemp(res?.payload);
        })
    }, [])

    if (HTMLTemplatesStatus === 'loading') {
        return <div>Loading...</div>;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  return (
    <div>
    <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
    }}
        justify="space-between"
        alignItems="center">
        <GridItem>
            <Breadcrumb separator=">">
                <Breadcrumb.Item >
                    <Link to={`/citrine/HTMLTemplates`}>
                        <span > {t('Configurations')} </span>
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/citrine/surveyTemplates/SurveyMails/`}>
                        <span > {t('Survey Mails Report')} </span>
                    </Link>
                </Breadcrumb.Item>
            </Breadcrumb>
        </GridItem>
            <Button onClick={handleClickOpen} color="primary">{t("Add")}</Button>
        <GridContainer>
            {HTMLTemplatesSurveyMails?.map((item) => {
                return (
                    <>
                        <ListTemplate item={item} />
                    </>
                )
            })}
            <MailTemplates open={open} onClose={handleClose}/>
        </GridContainer>
    </GridContainer>
</div>
  )
}
