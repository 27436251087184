

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { unwrapResult } from '@reduxjs/toolkit';
import navBarStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchModelById} from "../../../reduxToolkit/features/model/ModelSlice";
import { modelGroupPermissionUpdate } from "../../../reduxToolkit/features/modelGroupPermission/ModelGroupPermissionSlice";
import {
    fetchUserGroupById, fetchUserGroups, permissionsToGroup
} from '../../../reduxToolkit/features/userGroups/UserGroupSlice';


export default function GroupDetails(props) {
    const { t } = useTranslation();
    const useStyles = makeStyles(styles);
    const useNavBarStyles = makeStyles(navBarStyles);
    const useTitleStyles = makeStyles(titleStyles);
    const history = useHistory();
    const [addRequestStatus, setAddRequestStatus] = useState('idle')
    const dispatch = useDispatch()
    const id = props.id;
    const name = props.name;
    const onSaveClicked = async () => {
        try {
            setAddRequestStatus('pending')
            console.log('onsave')
            const resultAction = await dispatch(
                permissionsToGroup({ id, name })
            )
            unwrapResult(resultAction)
            dispatch(fetchUserGroups()).then(() => {
                history.push(`/citrine/groups/`)
            });
        } catch (err) {
            console.error('Failed to save permissions : ', err)
        } finally {
            setAddRequestStatus('idle')
        }


    }
    useEffect(() => {
        dispatch(fetchUserGroupById(id))
    }, [id])
    const group = useSelector((state) => state.userGroup.userGroup)
   
    let modelList = [];
    group && group.models && group.models.map((item) => {
        modelList.push(item)
    })
    const [checked, setChecked] = React.useState([]);

    const classes = useStyles();


    const columns = [

        {
            field: 'name',
            headerName: 'Model Name',
            width: 300,
            valueGetter: (params) => params.value,


        },
        {
            field: 'create',
            headerName: 'create',
            width: 200,
            type: 'boolean',
            editable: true,



        },
        {
            field: 'read',
            headerName: 'read',
            width: 200,
            type: 'boolean',
            // valueGetter: (params) => params.value,
            editable: true,

        },
        {
            field: 'update',
            headerName: 'update',
            width: 200,
            type: 'boolean',
            //  valueGetter: (params) => params.value,
            editable: true,

        },
        {
            field: 'remove',
            headerName: 'delete',
            width: 200,
            type: 'boolean',
            //   valueGetter: (params) => params.value,
            editable: true,

        },
    ];



    const [editRowsModel, setEditRowsModel] = React.useState({});




    const handleEditRowsModelChange = React.useCallback((model) => {
        console.log('model coming from change', model)
        setEditRowsModel(model);
        let editedId = Object.keys(model);
        console.log('model id coming from change', editedId)
        let editedValue = Object.values(model);
        console.log('model id coming from change', editedValue)
        const x = Object.assign({}, editedValue[0]);
        console.log('xxxxxxxxxxxx++++++++', x);
        let editedCol = Object.keys(x);
        console.log('editedCol++++++++', editedCol);
        let editedColValue = Object.values(x);
        console.log('editedColValues++++++++', editedColValue);

        const y = Object.assign({}, editedColValue[0]);
        let value = Object.values(y);
        console.log('value++++++++', value);
        dispatch(fetchModelById(editedId)).then(unwrapResult)
            .then((m) => {
                console.log('model in the last use effect', m);
                let name = m.name
                var s = editedCol.toString();
                var v = value.toString();

                if (v === "true") {

                    if (s === "create") {

                        let create = true
                        let read = m.read
                        let update = m.update
                        let remove = m.remove

                        dispatch(
                            modelGroupPermissionUpdate({ id: editedId, modified: { create, update, read, remove, group_id: id, model_id:editedId  } }),
                        )
                        dispatch(fetchUserGroupById(id))

                    }
                    else {
                        if (s === "read") {
                            let read = true
                            let create = m.create
                            let update = m.update
                            let remove = m.remove

                            dispatch(
                                modelGroupPermissionUpdate({ id: editedId, modified: { create, update, read, remove } }),
                            )
                            dispatch(fetchUserGroupById(id))
                        }
                        else {
                            if (s === "update") {
                                let update = true
                                let read = m.read
                                let create = m.create
                                let remove = m.remove

                                dispatch(
                                    modelGroupPermissionUpdate({ id: editedId, modified: {  create, update, read, remove } }),
                                )
                                dispatch(fetchUserGroupById(id))
                            }
                            else {
                                if (s === "remove") {
                                    let remove = true
                                    let create = m.create
                                    let update = m.update
                                    let read = m.read

                                    dispatch(
                                        modelGroupPermissionUpdate({ id: editedId, modified: { create, update, read, remove } }),
                                    )
                                    dispatch(fetchUserGroupById(id))
                                }
                            }

                        }

                    }
                }
                else {
                    if (v === "false") {
                        console.log('false if vvv', v)
                        if (s === "create") {

                            let create = false
                            let read = m.read
                            let update = m.update
                            let remove = m.remove

                            dispatch(
                                modelGroupPermissionUpdate({ id: editedId, modified: {  create, update, read, remove } }),
                            )
                            dispatch(fetchUserGroupById(id))

                        }
                        else {
                            if (s === "read") {
                                let read = false
                                let create = m.create
                                let update = m.update
                                let remove = m.remove

                                dispatch(
                                    modelGroupPermissionUpdate({ id: editedId, modified: { create, update, read, remove } }),
                                )
                                dispatch(fetchUserGroupById(id))
                            }
                            else {
                                if (s === "update") {
                                    let update = false
                                    let read = m.read
                                    let create = m.create
                                    let remove = m.remove

                                    dispatch(
                                        modelGroupPermissionUpdate({ id: editedId, modified: {  create, update, read, remove } }),
                                    )
                                    dispatch(fetchUserGroupById(id))
                                }
                                else {
                                    if (s === "remove") {
                                        let remove = false
                                        let create = m.create
                                        let update = m.update
                                        let read = m.read

                                        dispatch(
                                            modelGroupPermissionUpdate({ id: editedId, modified: {  create, update, read, remove } }),
                                        )
                                        dispatch(fetchUserGroupById(id))
                                    }
                                }
                            }
                        }
                    }
                }

            });


    }, []);
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'asc',
        },
    ]);


    return (
        <GridContainer xs={12} sm={12} md={12}>
            {/* <GridItem>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item >
                        <Link to={`/citrine/configurations`} >
                            {t('Configurations')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('Access Control')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/groups/`}>{t('Groups List')}
                        </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        {t('Details of Group')} {name}

                    </Breadcrumb.Item>

                </Breadcrumb>
            </GridItem> */}

            <GridItem xs={12} sm={12} md={12}>

                <Card>
                    <CardHeader>Group Permissions on models</CardHeader>
                    <CardBody>

                        <GridContainer style={{
                            height: 500,
                            width: '100%',
                        }}>
                            <DataGrid
                                rows={modelList && modelList}
                                columns={columns}
                                pageSize="50"
                                // disableSelectionOnClick
                                //rowsPerPageOptions={[]}
                               // hideFooterPagination
                                // editMode="row"
                                // Options={{singleClickEdit: true}}
                                //suppressClickEdit
                                sortModel={sortModel}
                                //  onSortModelChange={(model) => setSortModel(model)}
                                editRowsModel={editRowsModel}
                                onEditRowsModelChange={handleEditRowsModelChange}
                                components={{
                                    BooleanCellFalseIcon: CheckBoxOutlineBlankIcon,
                                    BooleanCellTrueIcon: CheckBoxIcon,
                                    singleClickEdit: true
                                }}
                            />

                        </GridContainer>

                    </CardBody>
                    {/* <CardFooter>
                        <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={9}>

                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <Button width="10px" color="primary" className={classes.marginRight}
                                    onClick={() => onSaveClicked()}
                                >
                                    {t('Affect Permissions to Group')}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardFooter> */}
                </Card>
            </GridItem>

        </GridContainer>
    );
}


