import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'components/CustomButtons/Button.js'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { Select } from 'antd'
import FormControl from '@material-ui/core/FormControl'
import { fetchTypeById } from 'reduxToolkit/features/type/TypeSlice'
import { fetchLevelById } from 'reduxToolkit/features/level/LevelSlice'
import { useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'
import ListItemText from '@material-ui/core/ListItemText'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { notification } from 'antd'
import { fetchAssessmentById } from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'

import { fetchEvidenceTypeStatesConfigs } from '../../../../reduxToolkit/features/EvidenceType/EvidenceTypeSlice'
import {
  fetchAffectEvidenceTypeToAssessmentMethod,
  affectEvidenceTypeToAssessmentMethod,
} from '../../../../reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice'
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
    color: 'primary',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}))
export default function AddEvidenceModal({ id }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [evidenceType, setEvidenceType] = useState('')
  const [requiredEvidence, setRequiredEvidence] = useState(true)
  const personsStatus = useSelector((state) => state.user.usersStatus)
  const { Option } = Select
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle')
  const handleSend = () => {}
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }
  const types = useSelector(
    (state) => state.EvidenceTypeStatesConfig.EvidenceTypeStatesConfigs,
  )
  const addEvidenceToAssessment = async () => {
    try {
      await dispatch(
        affectEvidenceTypeToAssessmentMethod({
          required: requiredEvidence,
          evidenceType_id: evidenceType,
          assessmentMethod_id: parseInt(id),
          
        }),
      )
      openNotificationWithIcon('success', `Add successfully`)
    } catch (error) {
      console.log('failed to add : ', error)
    }
    await dispatch(fetchAssessmentById(id))
    handleCloseAddEvidence()
  }

  const classes = useStyles()

  const [openModalAddEvidence, setOpenModalAddEvidence] = useState(false)
  const handleOpenAddEvidence = () => setOpenModalAddEvidence(true)
  const handleCloseAddEvidence = () => setOpenModalAddEvidence(false)

  useEffect(() => {
    dispatch(fetchAssessmentById(id))
  }, [dispatch, id])
  return (
    <div>
      <Button
        onClick={() => {
          handleOpenAddEvidence()
        }}
        color="info"
      >
        {t(' Add')}
      </Button>

      <Dialog
        open={openModalAddEvidence}
        onClose={handleCloseAddEvidence}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title" color="#AC79B0">
          {t('Add')}
        </DialogTitle>
        <DialogContent>
          <InputLabel>{t('Select Evidence Type')}</InputLabel>
          <br />
          <Select
            style={{ width: 400 }}
            dropdownStyle={{ zIndex: 10000 }}
            placeholder={t('Select Evidence Type')}
            optionFilterProp="children"
            onChange={(e) => {
              setEvidenceType(e)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={evidenceType}
          >
            {types &&
              types.map((item) => <Option value={item.id}>{item.type}</Option>)}
          </Select>
          <FormGroup>
            <FormControlLabel
              onChange={(e) => {
                setRequiredEvidence(e.target.checked)
              }}
              control={<Checkbox defaultChecked />}
              label="Required"
            />
          </FormGroup>

          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddEvidence} color="danger">
            {t('Cancel')}
          </Button>
          <Button onClick={addEvidenceToAssessment} color="info">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
