import React, { useState, useEffect, Fragment } from "react";

import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { Tabs } from "antd";
import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";
// import "./improvement.css"

import {
  fetchAnnualProgramReportById,
  UpdateAnnualProgramReport,
} from "../../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import Moment from "moment";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import teacher from "../../../../../assets/img/teacher.png";
import {
  warningColor,
  whiteColor,
} from "../../../../../assets/jss/material-dashboard-pro-react";
import {
  UserOutlined,
  PhoneOutlined,
  FileDoneOutlined,
  FormOutlined,
} from "@ant-design/icons";
import EditPersonContactInformation from "../../../../Components/EditPersonContactInformation";
import EditProfessionalSummaryModal from "./EditProfessionalSummaryModal"
import EditContactInformationModal from "./EditContactInformationModal"
const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

export default function ProfessionalSummary({ person }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();
  console.log('persoooon conntact information', person)
  const callback = (key) => {
    console.log(key);
  };

  return (
    <div>
      <p
        style={{
          paddingLeft: "10px",
          color: warningColor[0],
          fontSize: "14px",
        }}
      >
        <strong style={{ color: warningColor[0] }}>
          <PhoneOutlined style={{ color: warningColor[0] }} />
          Contact Information
        </strong>
      </p>
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        style={{
          borderRadius: "6px",
          backgroundColor: whiteColor,
          border: "1px solid lightgrey",
        }}
      >
        <GridItem xs={12} sm={12} md={6}>
          <div>
            <strong> Work Mobile : {person && person.work_mobile }</strong>
          </div>
          <div>
            <strong> Work Phone : {person && person.work_phone}</strong>
          </div>
          <div>
            <strong> Address : {person && person.person_details[0].adress && person.person_details[0].adress}</strong>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <div>
            <strong> Work Email : {person && person.work_email} </strong>
          </div>
          <div>
            <strong> Work Location :  {person && person.work_location}</strong>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={1} style={{ textAlign: "end" }}>
          <EditContactInformationModal   person_id={person && person.id}   person={person}/>
        </GridItem>
      </GridContainer>
      <br></br>
      <p
        style={{
          paddingLeft: "10px",
          color: warningColor[0],
          fontSize: "14px",
        }}
      >
        <strong style={{ color: warningColor[0] }}>
          <FileDoneOutlined style={{ color: warningColor[0] }} />
          Biography
        </strong>
      </p>
      <GridContainer
        xs={12}
        sm={12}
        md={12}
        style={{
          borderRadius: "6px",
          backgroundColor: whiteColor,
          border: "1px solid lightgrey",
        }}
      >
        <GridItem xs={12} sm={12} md={11}>
        {person && person.professional_summary}
        </GridItem>

        <GridItem xs={12} sm={12} md={1} style={{ textAlign: "end" }}>
        <EditProfessionalSummaryModal  person_id={person && person.id} person={person} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
