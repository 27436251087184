import "@lourenci/react-kanban/dist/styles.css";
import {
  TableBody, TableCell, TableRow
} from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addNewAttachment } from "../../../../../reduxToolkit/features/attachment/AttachmentsSlice";
import {
  addNewdistributionOfGrades,
  fetchGradesAndStatus
} from "../../../../../reduxToolkit/features/DistributionOfGrades/DistributionOfGradesSlice";
import useDistributionGradesTable from "../../../../Components/useDistributionGradesTable";
import useGradesTable from "../../../../Components/useGradesTable";
import "../../improvement.css";
import Comments from "../Comments";
import GridContainer from "components/Grid/GridContainer.js";
const { TabPane } = Tabs;
const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};
const headRecommendationsCells = [
  { id: "", label: "" },
  { id: "A+", label: "A+" },
  { id: "A", label: "A" },
  { id: "B+", label: "B+" },
  { id: "B", label: "B" },
  { id: "C+", label: "C+" },
  { id: "C", label: "C" },
  { id: "D+", label: "D+" },
  { id: "D", label: "D" },
  { id: "F", label: "F" },
  { id: "Denied", label: "Denied" },
  { id: "Inprogress", label: "Inprogress" },
  { id: "Incomplete", label: "Incomplete" },
  { id: "Pass", label: "Pass" },
  { id: "Fail", label: "Fail" },
  { id: "Withdrawn", label: "Withdrawn" },
];

const headRecommendationsCells1 = [
  { id: "Grades", label: "Grades" },
  { id: "Status", label: "Status" },
];
const useStyles = makeStyles(styles);
export default function StudentsStatisticsDetails(props) {
  console.log(props.AnnualProgramReport)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [records, setRecords] = [];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblRecommendationContainer, TblRecommendationHead } =
    useDistributionGradesTable(records, headRecommendationsCells, filterFn);
  const { TblContainer, TblHead } = useGradesTable(
    records,
    headRecommendationsCells1,
    filterFn
  );

  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [gradesAndStatus, setGradesAndStatus] = useState([]);

  useEffect(() => {
    let obj = {
      course_id: props.AnnualProgramReport.id,
    };
    dispatch(fetchGradesAndStatus(obj))
      .then(unwrapResult)
      .then((Grades) => {
        setGradesAndStatus([...Grades]);
      });
  }, []);

  const Grades = useSelector(
    (state) => state.DistributionOfGrades.GradesAndStatus
  );
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    console.log(selectedFile, "selectedFile");
  };

  const onFinish = () => {
    let resource_id = props.AnnualProgramReport.program;
    let resource = "grade";
    let model = "DistributionOfGrades";
    let document = selectedFile;
    const uploadData = new FormData();
    uploadData.append("resource_id", resource_id);
    uploadData.append("resource", resource);
    uploadData.append("model", model);
    uploadData.append("document", document);
    let objGrades = {
      course_id: props.idCourse,
    };
    let grades = [];
    dispatch(addNewAttachment(uploadData)).then(() => {
      dispatch(addNewdistributionOfGrades(objGrades)).then(() => {
        dispatch(fetchGradesAndStatus(objGrades))
          .then(unwrapResult)
          .then((Grades) => {
            console.log(Grades, "Grades");
            setGradesAndStatus(Grades);
            console.log(gradesAndStatus, "gradesAndStatus");
          });
      });
    });
  };
  let comment_resource = "StudentsStatistics"
  return (
    <div>
      {/* <GridItem xs={12} sm={12} md={8} style={{ display: "flex" }}>
        <input
          type="file"
          name="file"
          onChange={changeHandler}
          style={{ marginTop: "10px" }}
        />
        <Button
          onClick={() => {
            onFinish();
          }}
          color="info"
        >
          {t("Upload")}
        </Button>

      </GridItem> */}

      {/* <div
        style={{
          margin: "0px 20px 10px 0px ",
          borderRight: "1px solid lightgray",
          borderLeft: "1px solid lightgray",
        }}
      >
        <TblHead />
        <TblRecommendationContainer
          style={{ marginTop: "0px", paddingTop: "0px" }}
        >
          <TblRecommendationHead />

          <TableBody>
            <TableRow>
              <TableCell style={{ borderRight: "1px solid lightgray" }}>
                <strong>Number Of Students</strong>
              </TableCell>

              {gradesAndStatus &&
                gradesAndStatus.map((item) => (
                  <TableCell style={{ borderRight: "1px solid lightgray" }}>{item.number}</TableCell>
                ))}
            </TableRow>

            <TableRow>
              <TableCell style={{ borderRight: "1px solid lightgray" }}>
                <strong>Percentage</strong>
              </TableCell>

              {gradesAndStatus &&
                gradesAndStatus.map((item) => (
                  <TableCell style={{ borderRight: "1px solid lightgray" }}>{item.percentage}</TableCell>
                ))}
            </TableRow>
          </TableBody>
        </TblRecommendationContainer>
      </div> */}
      <GridContainer xs={12} sm={12} md={12}>

        <GridItem key={Element.id} xs={12} sm={12} md={12}>
          <Comments
            AnnualProgramReport={props.AnnualProgramReport &&
              props.AnnualProgramReport} type={comment_resource} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
